<template>
  <v-row justify="center">
    <info-ball color="red" />
    <div class="inactive-info-text-container">
      <div>
        <p class="inactive-info-text">
          {{ $t("rental_process_inactive") }}
        </p>
      </div>

      <div>
        <p class="inactive-info-text">
          {{ `${$t("Reason")}: ${reason}` }}
        </p>
      </div>
    </div>
  </v-row>
</template>

<script>
import InfoBall from '../InfoBall.vue'
export default {
  name: "InactivationReason",
  components: {InfoBall},
  props: {
    reason: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
.inactive-info-text {
  margin: 0px !important;
  color: red !important;
  font-weight: bold;
  font-size: 14px !important;
}

.inactive-info-text-container {
  margin-left: 10px !important;
}
</style>
