var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.addAreaDialog),callback:function ($$v) {_vm.addAreaDialog=$$v},expression:"addAreaDialog"}},[_c('AriaLive'),_c('BaseModal',{on:{"cancel":function($event){return _vm.cancel()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Add site area'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(
              _vm.buildingsForCurrentSite.length > 0 &&
                _vm.addAreaDialog === true
            )?_vm._l((_vm.buildingsForCurrentSite),function(building){return _c('div',{key:building.building_code},[_vm._v(" "+_vm._s(building.building_code + ' ( ' + building.building_name + ' )')+" "),(building.has_dwg)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('Has dwgs'))+" ")]):_vm._e(),_c('v-text-field',{staticClass:"form-field",attrs:{"number":"","step":"any","label":_vm.$t('Gross area'),"readonly":_vm.buildingData[building.building_code].has_any_dwgs || _vm.buildingData[building.building_code].floors.length > 1},model:{value:(
                  _vm.buildingData[building.building_code].new_gross_area
                ),callback:function ($$v) {_vm.$set(_vm.buildingData[building.building_code], "new_gross_area", _vm._n($$v))},expression:"\n                  buildingData[building.building_code].new_gross_area\n                "}}),_c('v-text-field',{staticClass:"form-field",attrs:{"number":"","step":"any","label":_vm.$t('Net floor area'),"readonly":_vm.buildingData[building.building_code].has_any_dwgs || _vm.buildingData[building.building_code].floors.length > 1},model:{value:(
                  _vm.buildingData[building.building_code]
                    .new_total_facility_area
                ),callback:function ($$v) {_vm.$set(_vm.buildingData[building.building_code]
                    , "new_total_facility_area", _vm._n($$v))},expression:"\n                  buildingData[building.building_code]\n                    .new_total_facility_area\n                "}}),_c('v-autocomplete',{staticClass:"form-field",attrs:{"small":"","items":_vm.parties,"clearable":"","item-text":"name","item-value":"id","label":_vm.$t('Tenant'),"placeholder":_vm.$t('Tenant'),"menu-props":"offsetY","readonly":_vm.buildingData[building.building_code].has_any_dwgs ||
                    !(
                      0 +
                      _vm.buildingData[building.building_code]
                        .new_total_facility_area >
                      0
                    ) ||
                    !(
                      0 +
                      _vm.buildingData[building.building_code].new_gross_area >
                      0
                    )},model:{value:(_vm.buildingData[building.building_code].new_tenant),callback:function ($$v) {_vm.$set(_vm.buildingData[building.building_code], "new_tenant", $$v)},expression:"buildingData[building.building_code].new_tenant"}}),(!_vm.buildingData[building.building_code].has_any_dwgs)?_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('div',{staticClass:"text-xs-end"},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('PDF pictures saved'))+"   ")])],1),_c('div',{staticClass:"text-xs-end"},[_c('v-col',[_c('v-checkbox',{attrs:{"disabled":_vm.buildingData[building.building_code].has_any_dwgs ||
                          !(
                            0 +
                            _vm.buildingData[building.building_code]
                              .new_total_facility_area >
                            0
                          ) ||
                          !(
                            0 +
                            _vm.buildingData[building.building_code].new_gross_area >
                            0
                          ),"false-value":0,"true-value":1},model:{value:(_vm.buildingData[building.building_code].new_pdf),callback:function ($$v) {_vm.$set(_vm.buildingData[building.building_code], "new_pdf", $$v)},expression:"buildingData[building.building_code].new_pdf"}})],1)],1)]):_vm._e()],1)}):_vm._e()],2)]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid || _vm.saving,"text":"","rounded":""},on:{"click":_vm.save}},[(!_vm.saving)?_c('span',[_vm._v(_vm._s(_vm.$t('Save')))]):_c('v-progress-circular',{attrs:{"size":20,"width":3,"indeterminate":"","color":"primary"}})],1)]},proxy:true}])})],1),_c('v-spacer'),(_vm.disabled === false)?_c('v-btn',{staticClass:"ma-0 mt-2",attrs:{"small":"","outlined":"","rounded":"","block":"","disabled":_vm.disableSiteAreaButton},on:{"click":function($event){return _vm.showAddAreasDialog()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add site area')))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }