<template>
  <v-card>
    <v-card-text>
      {{ $t(toolInfo) }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ViewerToolInfo',
  props: {
    value: {
      default: () => {
        return ''
      },
      type: String,
    },
    activeTool: {
      default: () => {
        return null
      },
      type: String
    },
  },
  data () {
    return {
      tools: [
        { name: 'Calculate area', info: 'areaMeasurement' },
        { name: 'Measure distance', info: 'measurement' },
        { name: 'Free pen', info: 'polyline' },
        { name: 'Text input', info: 'rectangle' },
        { name: 'Remove markup', info: 'clear' },
      ],
    }
  },
  computed: {
    toolInfo () {
      const tool = this.tools.find(tool => tool.name === this.activeTool)
      if (tool) {
        return 'viewerToolInfo.' + tool.info
      }
      return ''
    }
  },
}
</script>

<style scoped>
</style>