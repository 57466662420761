import moment from "moment"
// Used for calculating widget "red line" data for widget site.leasing.ending_contracts_graph.
// At the moment of writing this, this widget is in two views: components/tabs/Leasing/RenegotiationsAndRenewals (multi site) and views/RentalContracts (single site)
export function rentableAreaUsageRate (futureOccupancyrates, userSites = null) {
  let data = futureOccupancyrates.map(occupancyRate => {
    let year = moment(occupancyRate.date).get('year')
    let filteredOccupancyRates = occupancyRate.site_occupancy_rates
    if (userSites != null) {
      filteredOccupancyRates = filteredOccupancyRates.filter(siteOccupancyRate => userSites.map(site => site.id_site).includes(siteOccupancyRate.id_site))
    }
    let totalArea = filteredOccupancyRates.reduce((siteAcc, siteCurr) => siteAcc += siteCurr.unit_usage_occupancy_rates.reduce((acc, curr) => acc + curr.total_area, 0), 0)
    let rentedArea = filteredOccupancyRates.reduce((siteAcc, siteCurr) => siteAcc += siteCurr.unit_usage_occupancy_rates.reduce((acc, curr) => acc + curr.rented_area, 0), 0)
    let rentedPercentage = rentedArea/totalArea * 100
  return { year: year, all: rentedPercentage, fixedTerm: rentedPercentage, permanent: rentedPercentage}
  })
  return data
}