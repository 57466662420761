<template>
  <v-row
    class="header-column flex-column"
    no-gutters
  >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row 
            align="center"
            no-gutters
          >
            <v-icon>location_on</v-icon>
            {{ $t('Site') + ':' + ' ' + currentSite.name }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="siteMetadata"
            :data="currentSite"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MetadataForm from '../MetadataForm'
export default {
  components: {
    MetadataForm
  },
  computed: {
    ...mapState('sites', ['siteMetadata']),
    ...mapGetters('sites', ['currentSite' ]),
  }
}
</script>

<style>
</style>