!<template>
  <td

    v-if="header.value === 'prospect_description'"
    :key="header.value"
    class="min-width"
  >
    <v-row
      class="flex-nowrap"
      no-gutters
    >
      <span
        :class="{ linked: item.id_prospect != null }"
        @click="additionalAction(header, item)"
      >
        {{ formatData(item, header.value, header.format) }}
      </span>
      <v-btn
        v-if="item.prospect_type"
        fab
        x-small
        elevation="0"
        class="no-hover mx-1"
        :class="item.prospect_type"
      >
        <v-icon>
          {{ item.prospect_type }}
        </v-icon>
      </v-btn>
    </v-row>
  </td>
  <td

    v-else-if="header.value === 'process_title'"
    :key="header.value"
    class="min-width"
  >
    <span
      :class="{ linked: item.id_process != null }"
      @click="additionalAction(header, item)"
    >
      {{ formatData(item, header.value, header.format) }}
    </span>
    <v-btn
      v-if="item.prospect_type"
      fab
      x-small
      elevation="0"
      class="no-hover mx-1"
      :class="item.prospect_type"
    >
      <v-icon>
        {{ item.prospect_type }}
      </v-icon>
    </v-btn>
  </td>
  <td
    v-else-if="header.value === 'renegotiations' && item.layer === 0"
    :key="header.value"
  >
    <renegotiations-column
      :header="header"
      :item="item"
      :items="items"
    />
  </td>
  <td
    v-else-if="header.value === 'termination_reason' && item.layer === 0"
    :key="header.value"
  >
    <termination-reason-column
      :header="header"
      :item="item"
    />
  </td>
  <td
    v-else-if="header.value === 'renewal' && item.layer === 0"
    :key="header.value"
  >
    <renewal-column
      :header="header"
      :item="item"
      :items="items"
    />
  </td>
  <td
    v-else-if="header.value === 'prospect_status' && item.layer === 0"
    :key="header.value"
    class="text-center"
  >
    <prospect-status-column :item="item" />
  </td>
  <td
    v-else-if="header.value === 'units_passed' && item.layer === 0"
    :key="header.value"
  >
    <div
      v-if="item.passedReason != null"
      class="text-center"
    >
      {{ item.passedReason }}
    </div>
    <v-menu
      v-else
      :offset-y="true"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          outlined
          rounded
          text
          class="lowercase-button"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('Choose reason') }} 
          <v-icon right>
            expand_more
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="reason in definitionsByGroupLabel('prospect.abandonedreason')"
            :key="reason.id"
            @click="choosePassedOption(header, item, reason)"
          >
            <v-list-item-title>{{ $t(reason.label) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </td>
  <td
    v-else-if="header.value === 'corporation_name'"
    :key="header.value"
    @click="additionalAction(header, props.item)"
  >
    <span class="linked">
      {{ formatData(props.item, header.value, header.format) }}
    </span>
    <v-icon
      v-if="props.item.key_account_customer"
      color="warning"
    >
      grade
    </v-icon>
  </td>
  <td
    v-else-if="header.value === 'tenant'"
    :key="header.value"
  >
    <TenantColumn
      :header="header"
      :props="props"
    />
  </td>
  <td
    v-else-if="header.value === 'endDate' && (title === $t('leasing.ending_contracts_fixed_term') || title === $t('leasing.ending_contracts_permanent'))" 
    :key="header.value"
  >
    {{ formatData(item, header.value, header.format) }} 
    <v-icon
      v-if="endDateCheck(item) != null"
      :color="endDateCheck(item) === 'error' ? 'warning' : 'error'"
    >
      {{ endDateCheck(item) }}
    </v-icon>
  </td>
  <td
    v-else-if="header.value === 'success_likelihood' && item.layer === 0"
    class="text-center"
  >
    <SuccessLikelihoodColumn
      :header="header"
      :props="props"
      :title="title"
    /> 
  </td>
  <td
    v-else-if="header.value === 'rental_type_translated'"
    :key="header.value"
  >
    <rental-type-column 
      :item="item"
      :header="header"
    />
  </td>
  <td v-else-if="header.value === 'rentalSituation'">
    <RentalSituationColumn 
      :header="header"
      :props="props"
      :item="item"
    />
  </td>
  <td v-else-if="header.value === 'rentingProcessTitle'">
    <renting-process-title-column 
      :header="header"
      :props="props"
      :item="item"
    />
  </td>
</template>

<script>
import helpers from '../../helpers'
import { mapActions, mapGetters, mapState } from 'vuex'
import TenantColumn from "./LeasingColumns/TenantColumn.vue"
import RentalSituationColumn from "./LeasingColumns/RentalSituationColumn.vue"
import SuccessLikelihoodColumn from "./LeasingColumns/SuccessLikelihoodColumn.vue"
import RenegotiationsColumn from './LeasingColumns/RenegotiationsColumn.vue'
import TerminationReasonColumn from './LeasingColumns/TerminationReasonColumn.vue'
import RentalTypeColumn from './LeasingColumns/RentalTypeColumn.vue'
import RenewalColumn from './LeasingColumns/RenewalColumn.vue'
import ProspectStatusColumn from './LeasingColumns/ProspectStatusColumn.vue'
import RentingProcessTitleColumn from './LeasingColumns/RentingProcessTitleColumn.vue'

export default {
    name: 'LeasingColumns',
    components: {
        TenantColumn,
        RentalSituationColumn,
        SuccessLikelihoodColumn,
        RenegotiationsColumn,
        TerminationReasonColumn,
        RentalTypeColumn,
        RenewalColumn,
        ProspectStatusColumn,
        RentingProcessTitleColumn
    },
    props: {
        header: { type: Object, default: null },
        item: { type: Object, default: null },
        props: { type: Object, default: null },
        title: { type: String, default: ''},
        items: { type: Array, default: () => []}
    },
    computed: {
        ...mapGetters('app', ['definitionsByGroupLabel']),
        ...mapState('leasing', ['prospects', 'prospectsLoading'])
    },
    methods: {
        ...mapActions('leasing', [
          'changeProspectModalState', 
          'changeRenegotiationsModalState', 
          'passUnits', 
          'getCorporationExtraById', 
          ]),
        ...mapActions('rentalProcess', ['openRentalProcess']),

        formatData (row, header, format, isLink = false) {
        const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
        return helpers.format.formatData(value, format)
        },


        choosePassedOption (header, item, choice) {
            const idUnits = item.unitIds.replace('"', '').split(', ').map(item => parseInt(item))
            this.passUnits({id_units: idUnits, reason: choice.id}).then(res => {
                item.passedReason = this.definitionsByGroupLabel('prospect.abandonedreason').find(item => item.id === res).label
                this.$emit("changePproperty", {})
            })
        },

        async additionalAction (header, item) {
            switch (header.value) {
                case 'prospect_description':
                    if(item.id_process){
                      this.openRentalProcess({ id: item.id_process })
                    }
                    else if(this.prospects.length === 0 && this.prospectsLoading === false) 
                    {
                      this.$emit('getProspects', () => this.changeProspectModalState(item.id_prospect))
                    }
                    else
                    {
                      this.changeProspectModalState(item.id_prospect)
                    }
                    break
                case 'corporation_name':
                    this.getCorporationExtraById({id: item.id_corporation})
                    break
                case 'process_title':
                    this.openRentalProcess({id: item.id_process})
                    break
                default:
                    break
            }
        },
        endDateCheck (item) {
            if (item.endDate == null) {
              return null
            }
            const oneMonthFromNow = new Date().setMonth(new Date().getMonth() + 1)
            const sixMonthsFromNow = new Date().setMonth(new Date().getMonth() + 6)
            // If active and less than one month to go, show alert icon
            if (item.prospect_status === 'Active' && new Date(item.endDate) <= oneMonthFromNow) {
                return 'error'
            }
            // If no action taken and less than one month to go, show alert icon
            if (item.prospect_status === 'ProspectPending' && new Date(item.endDate) <= sixMonthsFromNow) {
                return 'cancel'
            }
             return null
        },        
    }

}
</script>

<style scoped>
.active {
  background-color: var(--c-color-accent) !important;
}
.inactive {
  background-color: #ff5252 !important;
}
.closed,
.success {
  background-color: #4caf50 !important;
}
.awaitingallocation {
  background-color: #fb8c00 !important;
  height: 35px !important;
}
.prospectpending {
  background-color: #707070 !important;
}
.notrelevant {
  background-color: #acabab !important;
}
.spa {
  background-color: #4caf50 !important;
  color: white;
}
.autorenew {
  background-color: var(--c-color-accent) !important;
  color: white;
}
.icecream {
  background-color: #f389d6 !important;
  color: white !important;
}
.construction {
  background-color: #9b2ac9 !important;
  color: white;
}
.no-hover {
  pointer-events: none;
}
.lowercase-button {
  text-transform: unset;
}

.no-background-hover::before {
  background-color: transparent !important;
}
.extra-height {
  height: 35px !important;
}
.min-width {
  min-width: 200px;
}
</style>