<template>
  <BaseModal
    @cancel="cancel"
  >
    <template #title>
      {{ $t('Task comments') }}
    </template>
    <template #content>
      <TaskComments
        :task="task"
        :comment-dialog="true"
        @saveComment="saveComment"
      />
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        color="primary"
        :disabled="task.finished"
        @click="saveAndClose"
      >
        <span>{{ $t('Save and close') }}</span>
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '../general/BaseModal'
import TaskComments from './TaskComments'
import { mapActions } from 'vuex'
export default {
  name: 'TaskCommentDialog',
  components: { 
    TaskComments,
    BaseModal, 
  },
  props: {
    task: Object
  },
  data () {
    return {
      newComment: ''
    }
  },
  methods: {
    ...mapActions('ltp', ['saveNewComment']),
    cancel () {
      this.$emit('commentDialogHandling', false)
    },
    saveAndClose () {
      const params = { id: this.task.id, newComment: this.newComment }
      this.saveNewComment(params).then(() =>
        this.$emit('commentDialogHandling', false)
      )
    },
    saveComment (comment) {
      this.newComment = comment
    }
  }
}
</script>
<style scoped>
</style>
