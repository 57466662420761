<template>
  <div>
    <v-dialog
      v-model="historyOpen"
      max-width="550"
      persistent
    >
      <BaseModal
        @cancel="historyDialog"
      >
        <template #title>
          {{ $t('Schedule changes') }}
        </template>
        <template #content>
          <div
            v-for="(taskSchedule, i) in sortedSchedules"
            :key="taskSchedule.scheduleId"
          >
            <v-card
              flat
              :style="{ marginBottom: '1em', marginTop: '1em' }"
            >
              <div>
                <span style="font-weight: bold">{{
                  `${$t('New schedule')}: `
                }}</span>
                {{ showChange(taskSchedule.toStart, taskSchedule.toEnd) }}
              </div>
              <div>
                <span style="font-weight: bold">{{
                  `${$t('Previous schedule')}: `
                }}</span>
                {{ showChange(taskSchedule.fromStart, taskSchedule.fromEnd) }}
              </div>
              <div>
                <span style="font-weight: bold">{{ `${$t('Comments')}: ` }}</span>
                {{ taskSchedule.plannedYearChangeReason }}
              </div>
              <div>
                <span style="font-weight: bold">{{
                  `${$t('Changed')}: `
                }}</span>
                {{ showSubmitter(taskSchedule) }}
              </div>
            </v-card>
            <v-divider v-if="i + 1 < sortedSchedules.length" />
          </div>
        </template>
        <template #footer />
      </BaseModal>
    </v-dialog>
    <br>
    <v-container>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('Task scheduling') }}
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          v-if="task.id"
          cols="12"
        >
          <v-text-field
            :label="$t('Original year')"
            :value="getOriginalYear"
            readonly
          />
        </v-col>
        <v-col 
          v-if="getLatestChange"
          cols="12"
        >
          <v-text-field
            :label="$t('Lastly transfered')"
            :value="getLatestChange"
            readonly
          />
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model.number="execYear"
            :label="task.id ? $t('New execution year') : $t('Execution year')"
            :items="yearSelection"
            :readonly="task.finished"
          />
        </v-col>
        <v-col 
          v-if="task.id"
          cols="12"
        >
          <v-textarea
            v-model="scheduling.information"
            :label="$t('Rescheduling reason')"
            :readonly="task.finished || scheduling.startYear === execYear"
            @change="$emit('scheduleChange', scheduling)"
          />
        </v-col>
      </v-row>
      <div
        class="text-end"
      >
        <v-btn
          v-if="task.id"
          small
          rounded
          depressed
          color="primary"
          :disabled="saveDisabled"
          @click="saveChange"
        >
          <span v-if="!loading">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            class="primary"
          />
        </v-btn>
      </div>
      <v-row
        class="section-textfield"
        no-gutters
      >
        <v-col cols="12">
          <h5 class="text--secondary my-4">
            {{ $t('Execution months') }}
          </h5>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="scheduling.startMonth"
            :label="$t('Mth')"
            :items="months"
            item-text="text"
            item-value="value"
            :readonly="task.finished"
            @change="$emit('scheduleChange', scheduling)"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model.number="scheduling.startYear"
            :label="$t('Year')"
            :items="yearSelection"
            readonly
          />
        </v-col>
        <v-row
          align="center"
          justify="center"
        >
          <span style="font-size: 24pt; color: #444">
            -
          </span>
        </v-row>
        <v-col cols="2">
          <v-select
            v-model="scheduling.endMonth"
            :label="$t('Mth')"
            :items="months"
            item-text="text"
            item-value="value"
            :readonly="task.finished"
            @change="$emit('scheduleChange', scheduling)"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model.number="scheduling.endYear"
            :label="$t('Year')"
            :items="yearSelection.filter((y) => y >= scheduling.startYear)"
            :readonly="task.finished"
            @change="$emit('scheduleChange', scheduling)"
          />
        </v-col>
        <v-col 
          v-if="task.implementationStatus === 3"
          cols="12"
        >
          <v-text-field
            :label="$t('Status')"
            :value="$t('Acknowledge')"
            readonly
          />
        </v-col>
        <v-col 
          v-else
          cols="12"
        >
          <v-select
            v-model="scheduling.implementationStatus"
            :label="$t('Status')"
            :items="statuses.map(s => ({...s, name: $t(s.name)}))"
            item-value="value"
            item-text="name"
            :readonly="task.finished"
            class="mt-6"
            @change="$emit('scheduleChange', scheduling)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mb-4"
        >
          <a
            v-if="task.id"
            color="blue"
            dark
            @click="historyDialog"
          >
            {{ $t('Look transfer history') }}
          </a>
        </v-col>
      </v-row>
      <v-spacer />
      <v-divider />
      <v-spacer />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BaseModal from '../general/BaseModal'

export default {
  name: 'TaskScheduling',
  components: { BaseModal },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      months: [
        { text: 1, value: 0 },
        { text: 2, value: 1 },
        { text: 3, value: 2 },
        { text: 4, value: 3 },
        { text: 5, value: 4 },
        { text: 6, value: 5 },
        { text: 7, value: 6 },
        { text: 8, value: 7 },
        { text: 9, value: 8 },
        { text: 10, value: 9 },
        { text: 11, value: 10 },
        { text: 12, value: 11 }
      ],
      historyOpen: false,
      scheduling: {
        startMonth: new Date(this.task.implementationStart).getMonth(),
        startYear: new Date(this.task.implementationStart).getFullYear(),
        endMonth: new Date(this.task.implementationEnd).getMonth(),
        endYear: new Date(this.task.implementationEnd).getFullYear(),
        information: undefined,
        implementationStatus: this.task.implementationStatus
      },
      execYear: new Date(this.task.implementationStart).getFullYear(),
      loading: false
    }
  },
  computed: {
    ...mapGetters('ltp', ['statuses']),
    saveDisabled: function () {
      return (
        this.scheduling.startYear === this.execYear ||
        !this.scheduling.information ||
        this.loading
      )
    },
    sortedSchedules: function () {
      return this.task.schedules
        ? [...this.task.schedules].sort((a, b) => {
          return (
            new Date(b.submitted).getTime() - new Date(a.submitted).getTime()
          )
        })
        : []
    },
    yearSelection: function () {
      const currentYear = new Date().getFullYear()
      const startingYear = 1900
      const years = []
      for (let i = startingYear; i < currentYear + 15; i++) {
        years.push(i)
      }
      return years
    },
    getOriginalYear () {
      return new Date(this.task.originalPlannedTime).getFullYear()
    },
    getLatestChange () {
      if (this.task.schedules && this.task.schedules.length > 0) {
        const dates = this.task.schedules.map(schedule =>
          new Date(schedule.submitted).getTime()
        )
        const latestDate = Math.max(...dates)
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
        return new Date(latestDate).toLocaleDateString('fi-FI', options)
      } else {
        return null
      }
    }
  },
  watch: {
    execYear: function () {
      if (!this.task.id) {
        this.scheduling.startYear = this.execYear
        this.scheduling.endYear = this.execYear
      }
    }
  },
  methods: {
    ...mapActions('ltp', ['saveScheduleChange']),
    historyDialog () {
      this.historyOpen = !this.historyOpen
    },
    async saveChange () {
      this.loading = true
      const start = new Date(
        this.execYear,
        this.scheduling.startMonth,
        15
      ).toISOString()
      // change end year respectively
      const endYear =
        this.scheduling.endYear + this.execYear - this.scheduling.startYear
      const end = new Date(endYear, this.scheduling.endMonth, 15).toISOString()
      const change = {
        plannedYearChangeReason: this.scheduling.information,
        start,
        end
      }
      const params = { id: this.task.id, change }
      await this.saveScheduleChange(params)
      this.scheduling.information = undefined
      this.scheduling.startMonth = new Date(
        this.task.implementationStart
      ).getMonth()
      this.scheduling.startYear = new Date(
        this.task.implementationStart
      ).getFullYear()
      this.scheduling.endMonth = new Date(
        this.task.implementationEnd
      ).getMonth()
      this.scheduling.endYear = new Date(
        this.task.implementationEnd
      ).getFullYear()
      this.loading = false
    },
    showChange (from, to) {
      const startYear = new Date(from)
        .getFullYear()
        .toString()
        .slice(-2)
      const endYear = new Date(to)
        .getFullYear()
        .toString()
        .slice(-2)
      const startMonth = ('0' + (new Date(from).getMonth() + 1)).slice(-2)
      const endMonth = ('0' + (new Date(to).getMonth() + 1)).slice(-2)
      return `${startMonth}/${startYear} ${this.$t(
        'to'
      )} ${endMonth}/${endYear}`
    },
    showSubmitter (schedule) {
      const options = {
        dateStyle: 'medium',
        timeStyle: 'short'
      }
      return `${new Intl.DateTimeFormat('fi-FI', options).format(new Date(schedule.submitted))} ${schedule.submitter}`
    }
  },
}
</script>
<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.section-link {
  padding-top: 1em;
  padding-bottom: 1em;
}
.buttons {
  display: flex;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>
