//Contains functions for making coding easier
export default 
{
  /** 
  * Generates a map of arrays keyed by a specified field in an array of objects.
  *
  * @param {Array<Object>} arrayOfObjects - The array of objects to process.
  * @param {string} keyField - The field name to use as the key in the map.
  * @returns {Map} A Map where keys are values from the specified key field,
  *                and values are arrays of objects with the same key value.
  */
  generateMapFromObjectArray (arrayOfObjects, keyField) {
    const keyedMap = new Map();

    for (const obj of arrayOfObjects) {
        const keyValue = obj[keyField];
        if (!keyedMap.has(keyValue)) {
            keyedMap.set(keyValue, []);
        }
        keyedMap.get(keyValue).push(obj);
    }

    return keyedMap;
  }
}
