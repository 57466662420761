import moment from "moment"

export function calculatePcsCountSum (targets) {
  return targets.reduce((acc, obj) => acc + obj.agreedAmount, 0)
}

/**
 * Calculate the number of months left on a contract.
 * @param {string} contractEndDate - The contract end date.
 * @param {string} contractFirstPossibleEndDate - The first possible end date of the contract.
 * @param {string} currentDate - The current date (From circle calendar).
 * @return {number|null} The number of months left on the contract, or null if any date is null.
 */
export function getContractDurationLeft (contractEndDate, contractFirstPossibleEndDate, currentDate) {
  // If any date is null, return null
  if (contractEndDate === null || contractFirstPossibleEndDate === null || currentDate === null) {
    return null
  }

  // Parse the dates and check for invalid inputs
  const endDate = Date.parse(contractEndDate)
  const firstEndDate = Date.parse(contractFirstPossibleEndDate)
  const curDate = Date.parse(currentDate)
  if (isNaN(endDate) || isNaN(firstEndDate) || isNaN(curDate)) {
    return null
  }

  // If the contract end date is before the first possible end date, return the difference between the end date and the current date
  if (endDate < firstEndDate) {
    return monthDiff(new Date(endDate), new Date(curDate))
  }

  // Otherwise, return the difference between the first possible end date and the current date
  return monthDiff(new Date(firstEndDate), new Date(curDate))
}

/**
 * Calculate the difference between two dates in months.
 * @param {Date} a - The first date.
 * @param {Date} b - The second date.
 * @return {number} The difference between the first and second date in months.
 */
function monthDiff (a, b) {
  // Calculate the difference in years
  const years = a.getFullYear() - b.getFullYear();

  // Convert the difference from years to months and add the difference in months
  return a.getMonth() - b.getMonth() + years * 12;
}

/**
 * Calculate the number of days between two dates.
 * @param {string} fromDate - The start date.
 * @param {string} toDate - The end date.
 * @return {number|null} The number of days between the start and end date, or null if either date is null.
 */
export function getDaysBetweenDates (fromDate, toDate) {
  // If either date is null, return null
  if (fromDate === null || toDate === null) {
    return null
  }

  // Parse the dates and check for invalid inputs
  const fromDateObj = Date.parse(fromDate)
  const toDateObj = Date.parse(toDate)
  if (isNaN(fromDateObj) || isNaN(toDateObj)) {
    return null
  }

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(toDateObj - fromDateObj)

  // Convert the difference from milliseconds to days
  const msPerDay = 1000 * 60 * 60 * 24
  return Math.round(diffInMs / msPerDay)
}
/**
 * Compares two dates to determine if the first date is earlier than the second date.
 *
 * @param {string} date1 - The first date to compare, in a format recognized by the Date constructor.
 * @param {string} date2 - The second date to compare, in a format recognized by the Date constructor.
 * @returns {boolean} - Returns true if the first date is earlier than the second date, otherwise false.
 */
export function compareDates (date1, date2) {
  // If either date is null, return false
  if (date1 === null || date2 === null) {
    return false;
  }

  let result1 = formatDate(date1);
  let result2 = formatDate(date2);

  // If either date is not a valid date, return false
  if (result1.isNotCorrect || result2.isNotCorrect) {
    return false;
  }

  // Return true if the first date is earlier than the second
  return result1.formattedDate <= result2.formattedDate
}

/**
 * Formats a date string into a standardized date format and checks if the date is valid.
 *
 * @param {string} dateString - The date string to format. It can be in "DD.MM.YYYY", "DD/MM/YYYY", or "YYYY-MM-DD" format.
 * @returns {Object} - An object containing:
 *   - {string|Date} formattedDate: The formatted date string or Date object.
 *   - {boolean} isNotCorrect: A flag indicating whether the date string is not valid.
 */
export function formatDate (dateString) {
  let isNotCorrect = false
  const momentDay = moment(dateString, "DD.MM.YYYY")
  const momentDayWithSlash = moment(dateString, "DD/MM/YYYY")
  const momentDayWithDash = moment(dateString, "YYYY-MM-DD")
  let newTime = new Date();

  // Verify correct time format
  if (momentDayWithDash.isValid() && dateString.includes('-') && !dateString.includes(':')) {
    newTime = momentDayWithDash.endOf('day').toDate()
  } else if (momentDay.isValid() && dateString.includes('.') && !dateString.includes('-')) {
    newTime = momentDay.endOf('day').toDate()
  } else if (momentDayWithSlash.isValid() && dateString.includes('/') && !dateString.includes('-')) {
    newTime = momentDayWithSlash.endOf('day').toDate()
  } else {
    isNotCorrect = isNaN(Date.parse(dateString))
    if (!isNotCorrect) {
      newTime = moment(dateString).endOf('day').toDate()
    }
  }
  return { formattedDate: newTime, isNotCorrect: isNotCorrect }
}