<template>
  <v-container>
    <v-dialog
      v-model="createAlertDialog"
      persistent
      max-width="550"
    >
      <AriaLive />
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <BaseModal
          @cancel="close()"
        >
          <template #title>
            <h2 class="text-h5">
              {{ edit ? $t('alert.edit_alert') : $t('alert.add_alert') }}
            </h2>
          </template>
          <template #content>
            <v-form
              ref="form"
              @submit.prevent="validateForm"
            >
              <div v-if="!edit || createdBySelf">
                <h3>
                  {{ $t('alert.task') }}
                </h3>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="alertType"
                    :items="alertTypes"
                    :label="$t('alert.connect_to')"
                    :error-messages="errors[0]"
                    :disabled="edit"
                    :hint="$t('Required')"
                    persistent-hint
                    item-text="text"
                    item-value="type"
                    class="form-field mb-10"
                    required
                  />
                </validation-provider>
                <template v-if="alertType == 'CONTRACT'">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="idContract"
                      :items="contractNumbers.data"
                      :loading="contractNumbers.fetching"
                      :label="$t('Contract number')"
                      :error-messages="errors[0]"
                      :disabled="edit"
                      :hint="$t('Required')"
                      persistent-hint
                      item-text="sopimusnro"
                      item-value="id"
                      class="form-field mb-10"
                      required
                    />
                  </validation-provider> 
                </template>
                <template v-else-if="alertType == 'SITE'">
                  <!-- SITES -->
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="selectedSite"
                      :disabled="allSites.length === 0 || edit"
                      clearable
                      :error-messages="errors[0]"
                      :label="$t('Site')"
                      :items="allSites"
                      item-text="name"
                      return-object
                      :placeholder="$t('Site')"
                      class="form-field mb-10"
                      :hint="$t('Required')"
                      persistent-hint
                      required
                    />
                  </validation-provider> 
                  <v-spacer />
                  <!-- BUILDINGS -->
                  <v-autocomplete
                    v-model="selectedBuilding"
                    :disabled="!selectedSite || allBuildings.length === 0 || edit"
                    clearable
                    :label="$t('Building')"
                    :items="allBuildings"
                    :loading="buildingsLoading"
                    item-text="building_name"
                    hide-details
                    return-object
                    class="form-field"
                    :placeholder="$t('Building')"
                  />
                  <!-- FLOORS -->
                  <v-autocomplete
                    v-model="selectedFloor"
                    :disabled="!selectedBuilding || allFloors.length === 0 || edit"
                    clearable
                    :label="$t('Floor')"
                    :items="allFloors"
                    :loading="floorsLoading"
                    item-text="floor_name"
                    hide-details
                    return-object
                    class="form-field"
                    :placeholder="$t('Floor')"
                  />
                  <!-- UNITS -->
                  <v-autocomplete
                    v-model="selectedUnits"
                    :disabled="!selectedFloor || allUnits.length === 0 || edit"
                    :label="$t('Units')"
                    :items="allUnits"
                    :loading="unitsLoading"
                    item-text="unit_code"
                    multiple
                    clearable
                    small-chips
                    hide-details
                    return-object
                    class="form-field mb-10"
                    :placeholder="$t('Units')"
                  >
                    <template #selection="data">
                      <v-chip
                        :key="JSON.stringify(data.id)"
                        :disabled="edit"
                        class="v-chip--select-multi"
                        :close="!edit"
                        small
                        @click.stop
                        @click:close="removeUnit(data.item.id)"
                        @input="data.parent.selectItem(data.item)"
                      >
                        {{ data.item.unit_code }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </template>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|alertTextMax:400"
                >
                  <v-textarea
                    v-model="alertText"
                    class="form-field"
                    :placeholder="$t('alert.text')"
                    :label="$t('alert.text')"
                    rows="3"
                    counter="400"
                    :error-messages="$t(errors[0])"
                    :hint="$t('Required')"
                    persistent-hint
                    required
                  />
                </validation-provider>
                <DatePicker
                  :label="$t('alert.task_date')"
                  :rules="'required'"
                  :initial-date="taskDate"
                  :hint="$t('Required')"
                  required
                  class="mt-6"
                  @dateupdated="taskDateUpdated"
                />
                <h3 class="mt-10">
                  {{ $t('alert.send') }}
                </h3>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="alertReceivers"
                    :items="sortedAlertUsers"
                    :loading="alertUsers.fetching"
                    :label="$t('alert.receiver')"
                    :error-messages="errors[0]"
                    class="form-field mb-6"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                    small-chips
                    :hint="$t('Required')"
                    persistent-hint
                    required
                  >
                    <template #selection="data">
                      <v-chip
                        :key="JSON.stringify(data.id)"
                        class="v-chip--select-multi"
                        close
                        small
                        @click.stop
                        @click:close="removeUser(data.item.id)"
                        @input="data.parent.selectItem(data.item.id)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </validation-provider>
                <DatePicker
                  :label="$t('alert.alert_date')"
                  :rules="'required'"
                  :initial-date="alertDate"
                  :hint="$t('Required')"
                  required
                  class="mt-6"
                  @dateupdated="alertDateUpdated"
                />
                <v-checkbox
                  v-model="sendEmail"
                  class="mt-8"
                  hide-details
                  :label="$t('alert.send_email')"
                />
              </div>
              <div v-if="edit && (createdBySelf || alertSelf)">
                <h3 class="mt-6">
                  {{ $t('alert.status_title') }}
                </h3>
                <v-select
                  v-model="alertStatus"
                  :items="alertStatuses"
                  :label="$t('alert.status')"
                  item-text="label"
                  item-value="id"
                  class="form-field"
                />

                <validation-provider
                  v-slot="{ errors }"
                  rules="alertTextMax:400"
                >
                  <v-textarea
                    v-model="alertStatusText"
                    class="form-field"
                    :label="$t('alert.status_text')"
                    counter="400"
                    rows="3"
                    :error-messages="$t(errors[0])"
                  />
                </validation-provider>
              </div>
            </v-form>
            <v-alert
              :value="hasError"
              type="error"
              class="form-field"
              dismissible
            >
              {{ errorMessage }}
            </v-alert>
          </template>
          <template #footer>
            <v-btn
              :disabled="invalid || saving"
              rounded
              depressed
              class="primary"
              @click="validateForm"
            >
              <span v-if="!saving">{{ $t('Save') }}</span>
              <v-progress-circular
                v-else
                :size="20"
                :width="3"
                indeterminate
                color="grey"
              />
            </v-btn>
          </template>
        </BaseModal>
      </validation-observer>
    </v-dialog>
  </v-container>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
import AriaLive from './AriaLive.vue'
import DatePicker from './general/DatePicker.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'

extend('alertTextMax', {
  ...max,
  message: 'alert.text_maximum'
})

export default {
  name: 'AddAlert',
  components: {
    AriaLive,
    BaseModal,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    editObject: {
      type: Object,
      default: null
    },
    view: {
      type: String,
      default: 'dashboard'
    }
  },
  data () {
    return {
      loading: false,
      saving: false,
      hasError: false,
      errorMessage: null,
      createAlertDialog: true,
      contractNumbers: {
        fetching: false,
        data: []
      },
      alertUsers: {
        fetching: false,
        data: []
      },
      allSites: [],
      allBuildings: [],
      allFloors: [],
      allUnits: [],
      buildingsLoading: false,
      floorsLoading: false,
      unitsLoading: false,
      selectedSite: null,
      selectedBuilding: null,
      selectedFloor: null,
      selectedUnits: [],
      alertType: undefined,
      idContract: undefined,
      alertText: undefined,
      taskDate: undefined,
      alertDate: undefined,
      sendEmail: false,
      alertReceivers: [],
      createdBySelf: false,
      alertSelf: false,
      alertStatus: null,
      alertStatusText: undefined,
    }
  }, 
  computed: {
    ...mapState('app', ['currentDate', 'sites']),
    ...mapGetters('app', [
      'hasApplicationPermissionByName',
      'definitionsByGroupLabel',
      'definitionById',
      'definitionByLabel',
    ]),
    alertStatuses () {
      return this.definitionsByGroupLabel('alert.status')
        .map(status => {
          return {
            label: this.$t(status.label),
            id: status.id
          }
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })
    },
    alertTypes () {
      if (this.contractRights) {
        return [ { text: this.$t('alert.to_contract'), type:"CONTRACT" }, { text: this.$t('alert.to_site'), type: "SITE" } ]
      } else {
        return [ { text: this.$t('alert.to_site'), type: "SITE" } ]
      }
    },
    contractRights () {
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') || this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')
    },
    sortedAlertUsers () {
      return this.alertUsers.data.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    selectedSite: function (newSite) {
      this.allBuildings = []
      this.selectedBuilding = null
      if (newSite) {
        this.buildingsLoading = true
        this.$rambollfmapi.buildings
          .list({ query: { siteId: newSite.id_site } })
          .then(res => {
            this.allBuildings = res
            this.buildingsLoading = false
            if (this.edit && this.editObject.idBuilding) {
              this.selectedBuilding = res.find(b => b.id_building = this.editObject.idBuilding)
            }
          })
      }
    },
    selectedBuilding: function (newBuilding) {
      this.allFloors = []
      this.selectedFloor = null
      if (newBuilding) {
        this.floorsLoading = true
        this.$rambollfmapi.buildings
          .floors(newBuilding.building_code)
          .list()
          .then(res => {
            this.allFloors = res
            this.floorsLoading = false
            if (this.edit && this.editObject.idFloor) {
              this.selectedFloor = res.find(f => f.id == this.editObject.idFloor)
            }
          })
      }
    },
    selectedFloor: function (newFloor) {
      // UNITS
      this.allUnits = []
      this.selectedUnits = []
      if (newFloor) {
        this.unitsLoading = true
        this.$rambollfmapi.floors
          .units(newFloor.id)
          .list()
          .then(res => {
            this.allUnits = res
            this.unitsLoading = false
            if (this.edit && this.editObject.idUnits.length) {
              this.selectedUnits = res.filter(u => this.editObject.idUnits.includes(u.id))
            }
          })
      }
    },
    alertType (value) {
      if (value === 'CONTRACT') {
        this.selectedSite = null
        this.selectedBuilding = null
        this.selectedFloor = null
        this.selectedUnits = []
      } else if (value != 'CONTRACT') {
        this.idContract = undefined
      }
    }
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('HALYTYKSET')) {
      this.close()
    }
    if (this.contractRights || this.edit) {
        await this.getContractNumbers()
      }
    await this.getAllSites()
    this.alertStatus = this.definitionByLabel('alert.not_completed').id
    if (this.edit && this.editObject) {
      this.alertType = this.editObject.alertType == "CONTRACT" ? "CONTRACT" : "SITE"
      this.idContract = this.editObject.idContract
      if (this.alertType != "CONTRACT") {
        this.selectedSite = this.allSites.find(site => site.id_site === this.editObject.id_site)
      }
      this.alertText = this.editObject.alertText
      this.taskDate = this.editObject.taskDate
      this.alertDate = this.editObject.alertDate
      this.sendEmail = this.editObject.sendEmail
      this.alertReceivers = this.editObject.alertReceivers.map(r => r)
      this.alertStatus = this.editObject.alertStatus
      this.alertStatusText = this.editObject.alertStatusText
      this.createdBySelf = this.editObject.createdBySelf
      this.alertSelf = this.editObject.alertSelf
    }
    this.getAlertUsers()
  },
  methods: {
    ...mapActions('dashboard', ['updateSingleWidgetData']),
    async getContractNumbers () {
      this.contractNumbers.fetching = true
      const data = await this.$rambollfmapi.alerts.contracts()
      if (data) {
        this.contractNumbers.data = data
      }
      this.contractNumbers.fetching = false
    },
    getDefinitionLabelById (id) {
      const def = this.definitionById(id)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    close () {
      this.saving = false
      this.createAlertDialog = false
      this.hasError = false
      this.errorMessage = null
      this.$refs.form.reset()
      this.$emit('close')
    },
    async validateForm (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.saving = true
        this.hasError = false
        const result = this.edit ? await this.updateAlert() : await this.createNewAlert()
        if (result && result.name === 'Error') {
          this.hasError = true
          this.errorMessage = this.edit ? this.$t('alert.update_alert_error') : this.$t('alert.create_alert_error')
          this.saving = false
          this.$log.error(result)
        } else {
          if (this.view == "dashboard") {
            const widgetName = 'dashboard.alerts'
            this.updateSingleWidgetData(widgetName)
          } else {
            this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
            this.$emit("alertSaved")
          }
          this.close()
        }
      }
    },
    async createNewAlert () {
      const newAlert = {
        alertType: this.alertType,
        alertText: this.alertText,
        alertDate: this.alertDate,
        taskDate: this.taskDate,
        sendEmail: this.sendEmail,
        alertReceivers: this.alertReceivers,
        alertStatus: this.alertStatus,
        alertStatusText: this.alertStatusText,
      }

      if (this.alertType === 'CONTRACT') {
        newAlert.idContract = this.idContract
      } else if (this.alertType != 'CONTRACT') {
        newAlert.id_site = this.selectedSite.id_site
        newAlert.idBuilding = this.selectedBuilding?.id_building
        newAlert.idFloor = this.selectedFloor?.id
        newAlert.idUnits = this.selectedUnits.map(u => u.id)
        newAlert.alertType = this.getAlertType(newAlert)
      }

      return await this.$rambollfmapi.alerts.create(newAlert)
    },
    async updateAlert () {
      const original = JSON.parse(JSON.stringify(this.editObject))
      const observer = this.$jsonpatch.observe(original)

      original.alertText = this.alertText 
      original.taskDate = this.taskDate
      original.alertDate = this.alertDate 
      original.sendEmail = this.sendEmail
      original.alertReceivers = this.alertReceivers
      original.alertStatus = this.alertStatus
      original.alertStatusText = this.alertStatusText

      const patch = this.$jsonpatch.generate(observer)
      return await this.$rambollfmapi.alerts.update(this.editObject.id, patch)
    },
    getAllSites () {
      const idSite = this.$route.params.id_site
      if (idSite) {
        this.allSites = this.sites.filter(s => s.id_site === Number(idSite))
        this.selectedSite = this.allSites.find(
          site => Number(site.id_site) === Number(idSite)
        )
      } else {
        this.allSites = this.sites
      }
    },
    removeUnit (unitId) {
      const unitIdx = this.selectedUnits.findIndex(unit => unit.id === unitId)
      this.selectedUnits.splice(unitIdx, 1)
    },
    removeSpace (spaceId) {
      const spaceIdx = this.selectedSpaces.findIndex(space => space.id === spaceId)
      this.selectedSpaces.splice(spaceIdx, 1)
    },
    removeUser (userId) {
      const userIdx = this.alertReceivers.findIndex(u => u === userId)
      this.alertReceivers.splice(userIdx, 1)
    },
    async getAlertUsers () {
      this.alertUsers.fetching = true
      const data = await this.$rambollfmapi.alerts.getAlertUsers()
      if (data) {
        this.alertUsers.data = data
      }
      this.alertUsers.fetching = false
    },
    taskDateUpdated (val) {
      this.taskDate = val
    },
    alertDateUpdated (val) {
      this.alertDate = val
    },
    getAlertType (alert) {
      if (alert.idUnits.length) {
        return "UNIT"
      } else if (alert.idFloor) {
        return "FLOOR"
      } else if (alert.idBuilding) {
        return "BUILDING"
      }
      return "SITE"
    }
  }
}
</script>
<style scoped>
</style>


