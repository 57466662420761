import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import messages from '../lang/messages'

Vue.use(VueI18n)

const defaultLocale = process.env.DEFAULT_LOCALE

export const i18n = new VueI18n({
  locale: getLocale() , // set locale
  fallbackLocale: 'fi',
  messages, // set locale messages
  silentTranslationWarn: true
})

const loadedLanguages = Object.keys(messages) // our default languages that are preloaded

export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
  return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(setI18nLanguage(lang))
}

function setI18nLanguage (lang) {
  i18n.locale = lang
  setLocaleToLocalStorage(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  window.location.reload()
  return lang
}

function getLocale () {
  if(localStorage.getItem('locale')) {
    return localStorage.getItem('locale')
  } else if (defaultLocale) {
    return defaultLocale
  } else {
    return 'fi'
  } 
}

function setLocaleToLocalStorage (lang) {
  localStorage.setItem('locale', lang)
}