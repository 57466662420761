/**
 * Class for capital rent calculations
 */
export class CapitalRentCalculator {
  constructor (rentalStatuses = []) {
    this.addedCapitalRents = new Map()
    this.grossRentAreas = rentalStatuses.filter(rs => rs.contractType === 'Gross Rent').reduce((acc, cur) => {

      const { area, contractNumber } = cur

      const grossRentArea = acc.get(contractNumber)

      if (grossRentArea) {
        grossRentArea.area += area
      } else {
        acc.set(contractNumber, { area })
      }

      return acc
    }, new Map())
  }

  /**
     * Calculates capital rent for the contract. Will return 0 if capitalRent for the contract is already used.
     * This is due to the fact that capitalRent is contract based value, but rental status object contains unit based values.
     * So if we just multiply rs.capitalRent, we will get unrealistically big values.
     * @param {Object}} rs - Rental status object
     * @param {Object}} site  - Site object
     * @returns {Number} Return 0 if has already returned capital rent for contract. Otherwise will return capital rent.
     */
  calculate (rs, site) {

    const { contractNumber, capitalRent } = rs

    if (this.addedCapitalRents.has(contractNumber)) {
      return 0
    }

    this.addedCapitalRents.set(contractNumber, capitalRent)

    if (site && rs.contractType === 'Gross Rent') {

      const { upkeep_rent_budget_vat: upkeepRentBudgetVat } = site

      const contractArea = this.grossRentAreas.get(contractNumber)

      if (!contractArea || !upkeepRentBudgetVat) {
        return capitalRent
      }

      const { area } = contractArea

      return capitalRent - area * upkeepRentBudgetVat

    }

    return capitalRent
  }
  /**
     * Calculates capital rent per square for rental status
     * @param {Object}} rs - Rental status object
     * @returns {Number} Returns capital rent per square. Returns null if actual area is not available.
     */

  calculatePerSquare (rs) {

    const { actualArea, capitalRent } = rs

    if (typeof actualArea !== 'undefined' && actualArea) {
      return (capitalRent / actualArea)
    }
    return null;

  }
}