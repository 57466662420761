export default function CarparkChangesIgnoredWidget () {
    return {
      id: "dataimport.list_changes",
      type: "ObjectTable",
      data: {
        headers: [{ text: "Contract number", value: "contract_number" }],
        items: [],
      },
      maximized: [false],
      loading: false,
      dataFetcher: (context) => {
        if (context.changedContracts) {
          return context.changedContracts
        }
  
        return []
      },
      shouldShow: (context) => {
        return context.changedContracts && context.changedContracts.length
      },
    }
  }
  