<template>
  <div>
    <v-main id="content">
      <v-dialog
        v-model="searchOpen"
        max-width="1000"
        persistent
      >
        <DetailedSearch @close="searchDialog" />
      </v-dialog>
      <v-dialog
        v-model="filterOpen"
        max-width="1000"
        persistent
      >
        <TaskListTableFilter
          :show-save-selection="true"
          :stored-views="esgPermission ? storedViews.filter(sv => sv.level === reportingLevel) : esgFilteredViews"
          :selected-view-string="storedView ? storedView.name : null"
          @close="filterOpen = false"
          @save-selection="saveStoredView"
          @select-view="selectView"
          @delete-view="deleteSelectedView"
        />
      </v-dialog>
      <v-dialog
        v-model="warningOpen"
        max-width="550"
        persistent
      >
        <WarningDialog
          @ignore="ignoreChanges"
          @save="saveChanges"
          @close="closeChanges"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogOpen"
        persistent
        scrollable
        max-width="600"
      >
        <LTPTaskDialog
          v-if="dialogOpen"
          :dialog-step="dialogStep"
          :task="openTask"
          :schedule="schedule"
          @taskDialogHandling="handleTaskDialog"
        />
      </v-dialog>
      <v-dialog
        v-model="commentDialogOpen"
        persistent
        max-width="600"
      >
        <TaskCommentDialog
          v-if="commentDialogOpen"
          :task="openTask"
          @commentDialogHandling="handleCommentDialog"
        />
      </v-dialog>
      <nav :aria-label="$t('SubMenu')">
        <v-row 
          align="center"
          no-gutters 
        >
          <v-col>
          <v-tabs 
            class="pl-5"
            show-arrows
          >
            <v-tab
              :to="{ name: 'planning.dashboard' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('Dashboard') }}
            </v-tab>
            <v-tab
              :to="{ name: 'planning.tasklist' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('Task List') }}
            </v-tab>
          </v-tabs>
          </v-col>
          <v-col class="text-right mr-3">
            <FilteringStatusChip    v-if="siteFiltersByTypes('all').length > 0" />
            <CdsButton
              icon-left="search"
              :type="detailedSearchActive ? 'primary' : 'tertiary'"
              hide-text
              class="ma-1"
              @click.stop="openSearch"
            >
              {{ $t('Detailed Search') }}
            </CdsButton>
            <CdsButton
              v-if="tabLocation != 'planning.dashboard'"
              icon-left="edit"
              :type="editable ? 'primary' : 'tertiary'"
              hide-text
              class="ma-1"
              @click.stop="openEdit"
            >
              {{ $t('Modify') }}
            </CdsButton>
            <CdsButton
              v-if="tabLocation != 'planning.dashboard'"
              icon-left="filter_list"
              :type="columnFilter.length === 0 ? 'tertiary' : 'primary' "
              hide-text
              class="ma-1"
              @click.stop="openFilter"
            >
              {{ $t('Modify') }}
            </CdsButton>
            <CdsButton
              v-if="tabLocation != 'planning.dashboard'"
              icon-left="add"
              type="accent"
              hide-text
              class="ma-1"
              @click.stop="handleTaskDialog(1, true)"
            >
              {{ $t('New LTP task') }}
            </CdsButton>
            </v-col>
          </v-row>
          <v-divider />
        </nav>
      <div class="ma-4">
        <Dashboard v-if="tabLocation == 'planning.dashboard'" />
        <TaskList
          v-if="tabLocation != 'planning.dashboard'"
          :edit="editable"
          @taskDialogHandling="handleTaskDialog"
          @continue="handleContinue"
          @commentDialogHandling="handleCommentDialog"
        />
      </div>
    </v-main>
    <Alert
      :y="null"
      :show="showAlert"
      :result="actionResult"
      :message="alertMessage"
    />
  </div>
</template>

<script>
import Dashboard from '../components/LongTermPlanning/Dashboard'
import DetailedSearch from '../components/LongTermPlanning/DetailedSearch'
import LTPTaskDialog from '../components/LongTermPlanning/LTPTaskDialog'
import TaskList from '../components/LongTermPlanning/TaskList'
import TaskListTableFilter from '../components/LongTermPlanning/TaskListTableFilter'
import WarningDialog from '../components/LongTermPlanning/WarningDialog'
import TaskCommentDialog from '../components/LongTermPlanning/TaskCommentDialog'
import FilteringStatusChip from '../components/general/FilteringStatusChip'
import Alert from '../components/Alert'
import CdsButton from '../components/CircleDesignSystem/CdsButton'
import helpers from '../helpers'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'LongTermPlanning',
  components: {
    Dashboard,
    DetailedSearch,
    LTPTaskDialog,
    TaskList,
    TaskListTableFilter,
    WarningDialog,
    TaskCommentDialog,
    FilteringStatusChip,
    Alert,
    CdsButton,
  },
  beforeRouteLeave (to, from, next) {
    if (this.to || this.tabLocation == 'planning.dashboard' || !this.edited) {
      next()
    } else {
      this.to = to
      this.warningOpen = true
    }
  },
  metaInfo () {
    return {
      title: `${this.$t('LTP')} ·`,
    }
  },
  data () {
    return {
      newTask: {
        buildingId: '',
        projectNumber: null,
        taskName: '',
        taskCategory: '',
        taskCode: '',
        taskDescription: '',
        originalBudget: undefined,
        originalPlannedTime: new Date().toISOString(),
        implementationStatus: 0,
        designer: '',
        builder: '',
        supervisor: '',
        contractor: '',
        finished: false,
        feedback: null,
        finishedTime: null,
        implementationStart: new Date((new Date()).getFullYear(), 0).toISOString(), // January of this year
        implementationEnd: new Date((new Date()).getFullYear(), 11).toISOString(), // December of this year
        budget: undefined,
        budgetSpent: undefined,
        currencyId: 1
      },
      schedule: {
        startMonth: 0,
        endMonth: 11,
        startYear: new Date().getFullYear(),
        endYear: new Date().getFullYear(),
        finishedTime: undefined
      },
      openTask: {},
      searchOpen: false,
      openingSearch: false,
      filterOpen: false,
      openingFilter: false,
      editable: false,
      editClicked: false,
      dialogStep: 1,
      dialogOpen: false,
      openingDialog: false,
      to: null,
      warningOpen: false,
      commentDialogOpen: false,
      reportingLevel: 'tasklist',
      actionResult: null,
      showAlert: false,
      alertMessage: null,
      storedView: null,
      selectedView: null,
      tabLocation: null
    }
  },
  computed: {
    ...mapState('app', [
      'sites', 
      'buildings', 
      'storedViews'
    ]),
    ...mapState('ltp', [
      'tasks',
      'filteredTasks',
      'ltpBuildings',
      'edited',
      'columnFilter',
      'detailedSearchActive',
      'loadingStatus',
      'esgCategories',
      'esgClasses',
      'esgCodes',
      'capexCategories',
      'capexClasses',
      'capexCodes'
    ]),
    ...mapGetters('app', [
      'siteFiltersByTypes',
      'hasApplicationPermissionByName'
    ]),
    ...mapGetters('ltp', ['getTaskById']),
    esgPermission () {
      return this.hasApplicationPermissionByName('PTS_ESG_TEHTAVAT')
    },
    availableStoredViews () {
      return this.storedViews.filter(sv => sv.level === this.reportingLevel)
    },
    esgFilteredViews () {
      const esgHeaders = helpers.ltpConstants.esgHeaders().map(header => {return header.value})
      const filteredViews = []
      if (this.storedViews.length > 0) {
        this.storedViews
        .filter(sv => sv.level === this.reportingLevel)
        .map(view => {
          const foundEsgHeaders = []
          view.stored_fields.forEach(column => {
            if(esgHeaders.includes(column.field)){
              foundEsgHeaders.push(column.field)
            }
          })
          if (foundEsgHeaders.length === 0) {
            filteredViews.push(view)
          }
        }) 
      }
      return filteredViews
    }
  },
  watch: {
    sites: async function () {
      await this.setLoadingStatus(true)
      await this.getBuildings()
      this.createData()
    },
    filteredTasks: function () {
      if (this.openTask.id) {
        this.openTask = this.getTaskById(this.openTask.id)
      }
    },
    showAlert: function (boolean) {
      if (boolean) {
        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    },
    selectedView: function (view) {
      this.storedView = view ? this.storedViews.filter(sv => sv.level === this.contractLevel && sv.id === view.id)[0] : null
    }
  },
  async mounted () {
    this.updateTabLocation()
    if (this.sites.length < 1) {
      // if user refreshes view
      this.getBuildings()
      this.getTasks()
      await this.getSites()
      this.createData()
      await this.getStoredViews('ltp')
    } else {
      this.setLoadingStatus(true)
      await this.getBuildings()
      await this.getTasks()
      this.createData()
      await this.getStoredViews('ltp')
    }
    await this.getLTPClassesAndCodes()
  },
  methods: {
    ...mapActions('app', [
      'getSites', 
      'getBuildings', 
      'getStoredViews'
    ]),
    ...mapActions('ltp', [
      'getTasks',
      'setFilteredTasks',
      'setLoadingStatus',
      'setLTPBuildings',
      'getLTPClassesAndCodes'
    ]),
    ...mapMutations('ltp', ['setEdited', 'setSaveStatus']),
    openEdit () {
      if (this.edited && this.editable) {
        this.editClicked = true
        this.warningOpen = true
      } else {
        this.editable = !this.editable
      }
    },
    openFilter () {
      if (this.edited) {
        this.openingFilter = true
        this.warningOpen = true
      } else {
        this.filterOpen = true
      }
    },
    openSearch () {
      if (this.edited) {
        this.openingSearch = true
        this.warningOpen = true
      } else {
        this.searchOpen = true
      }
    },
    ignoreChanges () {
      this.setEdited(false)
      this.warningOpen = false
      this.handleContinue()
    },
    saveChanges () {
      this.setSaveStatus(true)
      this.warningOpen = false
    },
    handleContinue () {
      this.setSaveStatus(false)
      if (this.to) {
        this.$router.push(this.to)
      } else if (this.openingSearch) {
        this.openingSearch = false
        this.searchOpen = true
      } else if (this.editClicked) {
        this.editClicked = false
        this.editable = false
      } else if (this.openingFilter) {
        this.openingFilter = false
        this.filterOpen = true
      } else if (this.openingDialog) {
        this.openingDialog = false
        this.dialogOpen = true
      }
    },
    closeChanges () {
      this.warningOpen = false
      this.openingSearch = false
      this.editClicked = false
      this.to = null
    },
    searchDialog (value) {
      this.searchOpen = value
    },
    createData () {
      // filter buildings
      const filteredBuildings = this.buildings.filter(building => {
        return (
          this.sites.findIndex(site => site.id_site === building.id_site) > -1
        )
      })

      // filter tasks
      const filteredTasks = this.tasks.filter(task => {
        return (
          filteredBuildings.findIndex(
            building => building.building_code === task.buildingId
          ) > -1
        )
      })
      this.setLTPBuildings(filteredBuildings)
      this.setFilteredTasks(filteredTasks)
    }, // predefined task is newTask
    handleTaskDialog (step, dialogOpen, task = this.newTask) {
      // check if unsaved changes
      this.openTask = task
      this.dialogStep = step
      if (dialogOpen && this.edited) {
        this.openingDialog = true
        this.warningOpen = true
      } else {
        this.dialogOpen = dialogOpen
      }
    },
    handleCommentDialog (commentDialogOpen, task = this.newTask) {
      this.openTask = task
      this.commentDialogOpen = commentDialogOpen
    },
    async saveStoredView (name, selectedHeaders, confirm, privacy = true) {
      this.confirmation = false
      const exists = this.availableStoredViews.includes(name)
      if (exists && !confirm) {
          this.confirmation = true
          this.actionResult = 'warning'
          this.alertMessage = this.$t('Warning') + ': ' + name + ' ' + this.$t('will be overwritten')
          this.storedView.stored_fields = selectedHeaders.map(h => {
          return {
              field: h,
              id_storedview: this.storedView.id
          }
        })
      } else {
          try {
            if (exists) {
              // Modify existing
              await this.$rambollfmapi.storedviews.put(this.storedView.id, selectedHeaders)
            } else {
              // Create new        
              const data = {
                  parent_level: 'ltp',
                  level: this.contractLevel,
                  name: name,
                  privacy: privacy
              }
              
              await this.$rambollfmapi.storedviews.post(data, selectedHeaders)
            }
            this.$emit("close-filter")
            this.actionResult = 'success'
            this.alertMessage = this.$t('Save success')
          } catch (error) {
            this.actionResult = 'error'
            this.alertMessage = this.$t('Error while saving')
          }
      }
      this.showAlert = true
      if (this.actionResult === 'success') {
          await this.getStoredViews('ltp')
          this.selectedView = name
      }
    },
    selectView (view) {
      if (view) {
        this.selectedView = this.availableStoredViews.find(v => v.name === view.name)
      } else {
        this.selectedView = null
      }
    },
    async deleteSelectedView (id) {
      const view = this.availableStoredViews.find(x => x.id === id)
      if (confirm(this.$t('storedview.deletion') + ' ' + view.name + '?' + '\n' + (view.private ? this.$t('storedview.deletion.private') : this.$t('storedview.deletion.public')))) {
        try {
            await this.$rambollfmapi.storedviews.delete(id)
            this.actionResult = 'success'
            this.alertMessage = this.$t('Succesfull delete')
            this.selectedView = null
        } catch (error) {
            this.actionResult = 'error'
            this.alertMessage = this.$t('Unsuccesfull delete')
        }
        await this.getStoredViews(this.parentLevel)
        this.showAlert = true
      }
    },
    updateTabLocation () {
      // Initialize first tab to be open
      if (this.$router.history.current.name === 'planning') {
        this.$router.push({name: 'planning.dashboard'})
      }
      this.tabLocation = this.$router.history.current.name
    }
  },

}
</script>

<style>
.dashboard {
  background-color: white !important;
}
</style>
