<template>
  <div style="position: relative">
    <v-menu 
      v-if="data.sets ? data.sets.length > 1 : false "
      left 
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          small
          absolute
          right
          icon
          style="background: rgba(255, 255, 255, 0.8) !important"
          v-on="on"
        >
          <v-icon>menu</v-icon>
          <span class="d-sr-only">{{ $t('Menu') }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="set in sets"
          :key="set.title"
          @click="selectSet(set)"
        >
          <v-list-item-title>{{ set.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <VerticalBarChart
      v-if="GraphData !== null"
      :data="GraphData"
      :options="barOptions"
      class="graph"
      style="height: 340px"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import VerticalBarChart from '../VerticalBarChart'
export default {
  components: {
    VerticalBarChart
  },
  props: ['data'],
  data () {
    const that = this
    return {
      currentSet: null,
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          display: false,
          labels: {
            fontColor: '#8b8b8b'
          }
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              if (that.data.multiLabelTooltip === 'siteName') {
                return that.getSiteName(data.labels[tooltipItem[0].index])
              } else {
                return ""
              }
            },
            // Only round the decimals if the data has any decimals
            label: function (tooltipItem, data) {
              if (
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] %
                  1 !==
                0
              ) {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                     data.datasets[tooltipItem.datasetIndex].data[
                       tooltipItem.index
                     ],1
                  )
                )
              } else {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ]
                  )
                )
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: this.data.xLabel,
                labelString: this.data.xLabel
              },
              gridLines: {
                color: 'white',
                zeroLineColor: 'white'
              },
              ticks: {
                minRotation: 45,
                fontColor: '#8b8b8b'
              },
              stacked: true
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: this.data.yLabel,
                labelString: this.data.yLabel
              },
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: '#8b8b8b'
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#8b8b8b',
                callback: function (value, index, values) {
                   return helpers.humanize.thousand_separator(value)
                }
              },
              stacked: true
            }
          ]
        }
      }
    }
  },
  computed: {
    sets: function () {
      // Check for sets
      if (this.data.sets.length === 0) {
        return []
      }
      return this.data.sets.filter(set => {
        const param = set.parameters.length > 0 ? set.parameters[0] : null

        // count is an exception as it does not require parameters
        if (param === null && set.method === 'count') {
          return true
        }

        const data = this.data.items.length > 0 ? this.data.items[0] : null

        if (data === null || typeof data[param] === 'undefined') {
          return false
        }
        return true
      })
    },
    objects: function () {
      if (Array.isArray(this.data.items)) {
        if (this.currentSet !== null) {
          // If set is defined we do the following:
          // 1. First, we group the data by using groupBy field
          // 2. Now the grouped data contains an object in which keys are values found using the groupBy field
          // 3. For each group, data is aggregated using the provided 'method'. It can be 'count' or 'sum' at this point
          // 3.1. Aggregation uses the 'method' with 'parameters'. The values are extracted using the parameter fields into tuples
          // 3.2. The current method 'count' is basically the length of the parameter array
          // 3.3. The current method 'sum' is just summing the array values together. Each tuple contains only one value.
          // 4. Now we have an object where each grouped value has the result and can be fed to the graph

          const groupedData = this.data.items.reduce((acc, cur) => {
            // group
            const key = cur[this.currentSet.groupBy]
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)

            return acc
          }, {})

          const items = Object.keys(groupedData).reduce((acc, key) => {
            const dataSet = groupedData[key]
            const paramValues = dataSet.map(d => {
              const values = []
              this.currentSet.parameters.forEach(param => {
                values.push(d[param])
              })
              return values
            })
            const method = this.currentSet.method

            if (method === 'count') {
              acc[key] = paramValues.length
            } else if (method === 'sum') {
              acc[key] = paramValues.reduce((acc, cur) => acc + cur[0], 0)
            }

            return acc
          }, {})

          return items
        } else {
          // No sets defined. Using the default approach (counting)
          return this.data.items.reduce((acc, cur) => {
            const dataField = this.data.field
            if (cur[dataField] === null) {
              return acc
            }
            if (typeof acc[cur[dataField]] === 'undefined') {
              acc[cur[dataField]] = 0
            }
            acc[cur[dataField]] += 1

            return acc
          }, {})
        }
      } else if (this.data.items === null || this.data.items === undefined) {
        return []
      } else {
        return this.data.items
      }
    },
    GraphData: function () {
      const objectKeysArray = [...Object.keys(this.objects)]
      return {
        labels: [...Object.keys(this.objects)],
        datasets: [
          {
            label: this.data.label,
            backgroundColor: this.getThemeColorArray(objectKeysArray.length),
            borderColor: 'white',
            data: [
              ...Object.keys(this.objects).map(
                key => this.objects[key] /* .count */
              )
            ]
          }
        ]
      }
    }
  },
  mounted: function () {
    if (typeof this.data.sets !== 'undefined') {
      this.selectSet(this.data.sets[0])
    }
  },
  methods: {
    selectSet (set) {
      this.currentSet = set
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${204 + 45 * index}, 82%, 72%)`
    },
    getSiteName (address) {
      const siteName = this.data.items.find(item => {
        return item.siteAddress === address
      }).siteName
      return siteName || address
    }
  }
}
</script>
