import { render, staticRenderFns } from "./EsgCertificateForm.vue?vue&type=template&id=79547d94&scoped=true"
import script from "./EsgCertificateForm.vue?vue&type=script&lang=js"
export * from "./EsgCertificateForm.vue?vue&type=script&lang=js"
import style0 from "./EsgCertificateForm.vue?vue&type=style&index=0&id=79547d94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79547d94",
  null
  
)

export default component.exports