var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"dashboard"},[_c('v-col',{attrs:{"lg":"8","md":"12","cols":"12"}},[_c('SituationAndTarget',{attrs:{"title":_vm.$t(_vm.situationWidget.id),"data":{
        ..._vm.situationWidget.data,
        items: _vm.selectedData
      },"target-coefficiency":_vm.data.targetCoefficiency,"loading":_vm.loading,"current-set":_vm.selectedSet,"current-group":_vm.selectedGroup,"party-links":_vm.partyLinks,"inspection-year":_vm.inspectionYear,"lower-bound-quarter-dates":_vm.lowerBoundQuarterDates},on:{"changeSet":_vm.changeSet,"update":function($event){return _vm.$emit('onUpdate')}}})],1),_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('SituationAndTargetGraph',{attrs:{"title":_vm.$t(_vm.situationGraph.id),"data":{
        ..._vm.situationGraph.data,
        items: _vm.selectedData
      },"target-coefficiency":_vm.data.targetCoefficiency,"loading":_vm.loading,"current-date":_vm.currentDate,"current-set":_vm.selectedSet,"current-group":_vm.selectedGroup,"lower-bound-quarter-dates":_vm.lowerBoundQuarterDates},on:{"changeGroup":_vm.changeGroup}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }