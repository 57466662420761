import { render, staticRenderFns } from "./StatusPortfolioSelection.vue?vue&type=template&id=6fd07e45&scoped=true"
import script from "./StatusPortfolioSelection.vue?vue&type=script&lang=js"
export * from "./StatusPortfolioSelection.vue?vue&type=script&lang=js"
import style0 from "./StatusPortfolioSelection.vue?vue&type=style&index=0&id=6fd07e45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd07e45",
  null
  
)

export default component.exports