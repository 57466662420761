import JsPDF from "jspdf"

/**
 * Options object for controlling how the Pdf should look. 
 * @typedef {Object} PDFOptions
 * @property {Number} documentWidth - The width of the document
 * @property {Number} contentMargin - The margin of the document from left and right.
 * @property {Number} startY - y-coordinate of the top of this component.
 * @property {String} font - Font of the document. Check doc.getFontList() for available fonts
 * @property {Number} fontStyleNormal - The normal font style. This may change based on the selected font.
 * @property {Number} width - The width of the row
 * @property {Number} height - The height of the row
 */

/**
 * Options object for controlling how the Pdf should look. 
 * @typedef {Object} PDFColumn
 * @property {String} title - Title of the column. If not defined, will be omitted
 * @property {String} value - Value of the column. If not defined, will be omitted
 * @property {Number} span - Number in percentage (eg 33), for how much this space 
 * this column should take on the row. 100 meaning, will take up whole row. If ommitted, 
 * relative space is calculated based on the other columns on the row.
 * @property {String} type - The type of the column. Currently undefined === default, baseline === baseline
 */

/**
 * Constructs pdf document based on the provided information and saves it automatically to user's computer.
 * @param {String} documentName - The name of the document.
 * @param {Object} contract - Contract object
 */
export function createContractTerminationPdf (documentName, contract) {
  // By default JsPDF uses mm as the coordinates unit.
  const doc = new JsPDF("portrait")

  // A4 dimensions are 210 x 297:
  const options = {
    documentWidth: 210,
    // documentHeight: 297,
    startY: 10,
    contentMargin: 5,
    // Font information
    font: "helvetica",
    // Font style names. These may change based on the selected font. Use doc.getFontList() to get definition.
    fontStyleNormal: "normal",
  }

  const sectionMargin = 5

  createHeader(doc, options, contract)

  options.startY += 15

  const sections = [
    landlordSection(contract),
    tenantSection(contract),
    siteSection(contract),
    contractEndingSection(contract),
    terminationReasonSection(contract),
    landlordSignatureSection(contract),
    noticeSection(contract),
    notificationSection(contract),
  ]

  sections.forEach((section) => {
    const yPointer = createSection(doc, options, section.title, section.data)
    options.startY = yPointer + sectionMargin
  })

  // For debugging I don't want to save the files....
  // doc.output("dataurlnewwindow")

  doc.save(documentName)
  return doc.output("blob")
}

function landlordSection (contract) {
  const { landlord, landlordContactPerson } = contract

  let values = {
    name: "",
    phone_number: "",
    address: "",
    city: "",
    postal_code: "",
    contactPersonName: "",
    contactPersonNumber: "",
  }

  if (landlord) {
    values = {
      ...landlord,
    }
  }

  if (landlordContactPerson) {
    values.contactPersonName = landlordContactPerson.name
    values.contactPersonNumber = landlordContactPerson.phone_number
  }

  return {
    title: "Vuokranantaja",
    data: [
      [
        {
          title: "Nimi",
          value: values.name || "",
          type: "baseline",
        },
        {
          title: "Puhelin",
          value: values.phone_number || "",
          type: "baseline",
        },
      ],
      [
        {
          title: "Osoite",
          value: `${values.address}, ${values.postal_code} ${values.city}`,
          type: "baseline",
        },
      ],
      [
        {
          title: "Yhteyshenkilö",
          value: values.contactPersonName || "",
          type: "baseline",
        },
        {
          title: "Puhelin",
          value: values.contactPersonNumber || "",
          type: "baseline",
        },
      ],
    ],
  }
}

function tenantSection (contract) {
  const {
    tenant: { name, address, postal_code, city },
  } = contract

  return {
    title: "Vuokralainen",
    data: [
      [
        {
          title: "Nimi",
          value: name,
          type: "baseline",
        },
      ],
      [
        {
          title: "Osoite",
          value: `${address}, ${postal_code} ${city}`,
          type: "baseline",
        },
      ],
    ],
  }
}

function siteSection (contract) {
  const firstColWidth = 66

  const { selectedUnits, contractSpaceusage } = contract

  let siteName = ""
  let address = ""
  let area = 0

  if (selectedUnits && selectedUnits[0]) {
    siteName = selectedUnits[0].site
    address = selectedUnits[0].address
    area = selectedUnits.reduce((acc, cur) => acc + (cur.agreedArea || 0), 0)
  }

  return {
    title: "Vuokrauskohde",
    data: [
      [
        {
          title: "Nimi",
          value: siteName || "",
          span: firstColWidth,
          type: "baseline",
        },
        {
          title: "Käyttötarkoitus",
          value: contractSpaceusage || "",
          type: "baseline",
        },
      ],
      [
        {
          title: "Osoite",
          value: address || "",
          span: firstColWidth,
          type: "baseline",
        },
        {
          title: "Pinta-ala noin",
          value: `${area} m\u00B2`,
          type: "baseline",
        },
      ],
    ],
  }
}

function contractEndingSection (contract) {
  const { terminationDateFormatted = "" } = contract

  return {
    title: "Sopimuksen päättyminen",
    data: [
      [
        {
          value: "Yllä mainittu sopimus irtisanotaan päättyväksi.",
          type: "baseline",
        },
        {
          value: `Päättymispäivä ${terminationDateFormatted}`,
          type: "baseline",
        },
      ],
    ],
  }
}

function terminationReasonSection (contract) {
  const { terminationDescription } = contract

  return {
    title: "Päättämisen peruste",
    data: [
      [
        {
          value: terminationDescription,
        },
      ],
    ],
  }
}

function landlordSignatureSection (contract) {
  const {
    landlord: { name },
  } = contract

  return {
    title: "Vuokranantajan allekirjoitus",
    data: [
      [
        {
          title: "Päiväys",
        },
        {
          title: "Vuokranantaja",
          value: name,
        },
      ],
    ],
  }
}

function noticeSection () {
  return {
    title: "Tiedoksisaanti",
    data: [
      [
        {
          value:
            "Olen tänään saanut edellä olevan ilmoituksen tiedoksi ja \nvastaanottanut siitä jäljennöksen.",
        },
        {},
      ],
      [
        {
          title: "Päiväys",
        },
        {
          title: "Vuokralaisen allekirjoitus",
        },
      ],
    ],
  }
}

function notificationSection (contract) {
  const {
    tenant: { name },
    otherTenant,
  } = contract

  let addendum = ""

  if (otherTenant && otherTenant.name) {
    addendum = ` ja ${otherTenant.name}`
  }

  return {
    title: "Tiedoksianto",
    data: [
      [
        {
          value: `Todistamme, että ${name}${addendum} on tänään saanut tiedoksi ylläolevan ilmoituksen ja, \nettä hän on samalla saanut jäljennöksen siitä.`,
        },
      ],
      [
        {
          title: "Päiväys",
          span: 33,
        },
        {
          title: "Läsnä olleiden todistajien allekirjoitukset",
        },
      ],
    ],
  }
}

/**
 * Creates header for the pdf document.
 * @param {JsPDF} doc - PDF document
 * @param {Object} options
 * @param {Number} options.documentWidth - The width of the document
 * @param {Number} options.startY - y-coordinate of the top of this component.
 * @param {String} options.font - Font of the document. Check doc.getFontList() for available fonts
 * @param {Number} options.fontStyleNormal - The normal font style. This may change based on the selected font.
 * @param {Object} contract - Contract object
 */
function createHeader (doc, options, contract) {
  const { documentWidth, startY, font, fontStyleNormal } = options

  const { contractNumber } = contract

  doc.setFontSize(16)
  doc.setFont(font, fontStyleNormal, "bold")
  doc.text(
    "Vuokrasopimuksen irtisanomis-/purkuilmoitus",
    documentWidth / 2,
    startY,
    {
      align: "center",
    }
  )

  doc.setFontSize(10)
  doc.setFont(font, fontStyleNormal, "normal")

  doc.text(`(${contractNumber})`, documentWidth / 2, startY + 6, {
    align: "center",
  })
}

/**
 * Creates section based on the provided information.
 * Data array:
 * Array of rows.
 * [
 *   [
 *     {
 *       title: "", // Title string
 *       value: "", // Value string
 *       // Optional value. If specified, this column will take width based on this percentage value. For example if the width of the section is
 *       // 200 and the span of the col is 50, the width of the column is 100. If this value is omitted, width is calculated automatically.
 *       span: 50,
 *     }
 *   ]
 * ]
 *
 * @param {JsPDF} doc - PDF document
 * @param {PDFOptions} options - Options object
 * @param {Array} data - Data array. Spec on summary.
 * @return {Number} - The lowest y-coordinate of this component
 */
function createSection (doc, options, title, data) {
  const { startY, documentWidth, contentMargin, font, fontStyleNormal } =
    options

  doc.setFont(font, fontStyleNormal, "bold")
  doc.setFontSize(14)

  doc.text(title, contentMargin + 2, startY, { baseline: "top" })

  const sectionHeight = 10
  const sectionWidth = documentWidth - 2 * contentMargin

  let yPointer = 0

  data.forEach((row, index) => {
    const rowOptions = {
      ...options,
      startY: startY + 6 + sectionHeight * index,
      width: sectionWidth,
      height: sectionHeight,
    }

    yPointer = createRow(doc, rowOptions, row)
  })

  return yPointer
}

/**
 * Creates row based on provided info.
 *
 * Row array:
 * [
 *   {
 *     title: "",
 *     value: "",
 *     span: "" // Optional
 *   }
 * ]
 *
 * @param {JsPDF} doc - PDF Document
 * @param {PDFOptions} options - Options object
 * @param {PDFColumn[]} row - Row array
 * @returns {Number} - yPointer. Lowest y-coordinate
 */

function createRow (doc, options, row) {
  const { contentMargin, width, height, startY } = options

  doc.setFontSize(8)

  const { spanSum, colsWithoutSpan } = row.reduce(
    (acc, col) => {
      if (col.span) {
        acc.spanSum += col.span
      } else {
        acc.colsWithoutSpan += 1
      }

      return acc
    },
    { spanSum: 0, colsWithoutSpan: 0 }
  )

  const defaultSpan = (100 - spanSum) / colsWithoutSpan

  let x = contentMargin

  row.forEach((col) => {
    const { span } = col

    let colWidth = 0

    if (span) {
      colWidth = (span / 100) * width
    } else {
      colWidth = (defaultSpan / 100) * width
    }

    doc.rect(x, startY, colWidth, height)

    createCol(doc, options, col, x)

    x += colWidth
  })

  return startY + height
}

/**
 * Creates a col based on the col type. 
 * @param {JsPDF} doc - PDF Document
 * @param {PDFOptions} options - Options
 * @param {PDFColumn} col - Column object
 * @param {Number} xStart - The start x-coordinate of this component
 */
function createCol (doc, options, col, xStart) {
  const { type } = col

  if (type === "baseline") {
    baselineCol(doc, options, col, xStart)
  } else {
    defaultCol(doc, options, col, xStart)
  }
}

/**
 * Creates default col. This column has title on upper left corner and the value is inserted under the title.
 * @param {JsPDF} doc - PDF Document
 * @param {PDFOptions} options - Options object
 * @param {PDFColumn} col - Column object
 * @param {Number} xStart - The start x-coordinate of this component
 */
function defaultCol (doc, options, col, xStart) {
  const { startY, fontStyleNormal, font } = options
  const { title, value } = col

  let textX = xStart + 2
  let textY = startY + 1

  if (title) {
    doc.setFont(font, fontStyleNormal, "bold")
    doc.text(title, textX, textY, { baseline: "top" })
    textY += 4
  }

  if (value) {
    doc.setFont(font, fontStyleNormal, "normal")
    doc.text(value, textX, textY, { baseline: "top" })
  }
}
/**
 * Baseline column. This column has title at the start of the row and value comes after the title on same y-coordinate. 
 * @param {JsPDF} doc - PDF Document
 * @param {PDFOptions} options - Options object
 * @param {PDFColumn} col - Column object
 * @param {Number} xStart - The start x-coordinate of this component
 */
function baselineCol (doc, options, col, xStart) {
  const { startY, height, fontStyleNormal, font } = options
  const { title, value } = col

  const textY = startY + height / 2
  let textX = xStart + 2

  if (title) {
    doc.setFont(font, fontStyleNormal, "bold")
    doc.text(title, textX, textY, { baseline: "middle" })
    textX += 22
  }

  if (value) {
    doc.setFont(font, fontStyleNormal, "normal")
    doc.text(value, textX, textY, { baseline: "middle" })
  }
}
