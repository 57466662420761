<template>
  <BaseModal
    @cancel="resetChanges"
  >
    <template #title>
      {{ $t('Import from spreadsheet') }}
    </template>
    <template #content>
      <div
        v-if="loading"
        style="text-align: center; padding: 100px"
      >
        <v-progress-circular
          size="64"
          indeterminate
          color="primary"
        />
      </div>
      <div
        v-else-if="isSavingChanges || isSavingNewContracts"
        style="text-align: center; padding: 100px"
      >
        <div
          class="text-h6"
          style="margin-bottom: 2em"
        >
          {{ `${ isSavingNewContracts ? $t('dataimport.saving_rows') : $t('dataimport.handling_rows') }` }}
          <p> {{ `${itemsLength}` }} </p>
        </div>
      </div>
      <div v-else-if="importSuccess">
        <div v-if="hasChanges || errors">
          <import-changes-view 
            :widgets="changesWidgets"
            :items-length="totalItems"
            :items-not-changed="itemsNotChangedCount"
            :errors="errors"
            :changes-list="changesList"
          />
        </div>
        <div v-else-if="savingFailed">
          <h3> {{ $t('dataimport.saving_failed') }} </h3>
          <br>
          <h4>{{ $t('dataimport.successful_rows') + " " + savedContractsCount }}</h4>
          <h4>{{ $t('dataimport.successful_changes') + " " + updatedContractsCount }}</h4>
          <h4>{{ $t('dataimport.failed_rows') + " " + newContractsErrors.length }}</h4>
          <h4>{{ $t('dataimport.failed_updates') + " " + updatingErrors.length }}</h4>
          <div v-if="overlapErrors.length > 0">
            <br>
            <span>{{
              $t('dataimport.overlapping_contracts')
            }}</span>
            <ul>
              <li
                v-for="(error, idx) in overlapErrors"
                :key="idx"
              >
                {{ viewErrorText(error) }}
              </li>
            </ul>
          </div>
          <div v-if="savingErrors.length > 0">
            <br>
            <span>{{
              $t('dataimport.saving_errors')
            }}</span>
            <ul>
              <li
                v-for="(error, idx) in savingErrors"
                :key="idx"
              >
                {{ viewErrorText(error) }}
              </li>
            </ul>
          </div>
        </div>
        <span v-else>{{ $t('dataimport.no_changes') }}</span>
      </div>
      <div v-else>
        <h3>{{ $t('dataimport.import_failed') }}</h3>
        <h4
          v-if="
            importErrors.uneditable && importErrors.uneditable.length > 0
          "
        >
          {{
            $t('dataimport.not_editable') +
              ': ' +
              importErrors.uneditable.join(', ')
          }}
        </h4>
        <h4
          v-if="
            importErrors.unexisting && importErrors.unexisting.length > 0
          "
        >
          {{
            $t('dataimport.not_existing') +
              ': ' +
              importErrors.unexisting.join(', ')
          }}
        </h4>
      </div>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-btn
        v-if="!savingFailed"
        rounded
        outlined
        text
        @click="resetChanges"
      >
        {{ isSavingNewContracts || isSavingChanges ? $t('Abort') : $t('Cancel') }}
      </v-btn>
      <v-btn
        v-if="importSuccess && hasChanges"
        rounded
        depressed
        :disabled="isSavingChanges"
        color="primary"
        @click="saveChanges()"
      >
        <span v-if="!isSavingChanges">
          {{ $t('Save changes') }}
        </span>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-btn>
      <v-btn
        v-else
        rounded
        depressed
        :disabled="isSavingNewContracts || isSavingChanges || loading"
        class="primary"
        @click="resetChanges"
      >
        <span v-if="!isSavingNewContracts && !isSavingChanges && !loading">
          {{ $t('OK') }}
        </span>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'

import moment from 'moment'
import ImportChangesView from './Modals/Views/ImportChangesView.vue'

import CarparkChangesDetectedWidget from "./Modals/Widgets/CarparkChangesDetectedWidget"
import CarparkChangesIgnoredWidget from "./Modals/Widgets/CarparkChangesIgnoredWidget"
import CarparkNewItemsWidget from "./Modals/Widgets/CarparkNewItemsWidget"
import { stringToBoolean } from "../../helpers/mappers/booleanString"
import { compareDates } from '../../helpers/contract'

export default {
  name: 'ContractImportModal',
  components: { BaseModal, ImportChangesView },
  props: {
    headers: { type: Array, default: () => [] },
    identifier: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    results: { type: Object, default: null },
    loading: { type: Boolean, default: false},
    requiredFields: { type: Array, default: () => [] },
    newContractsErrors: { type: Array, default: () => [] },
    updatingErrors: { type: Array, default: () => [] },
    isSavingChanges: { type: Boolean, default: false},
    isSavingNewContracts: { type: Boolean, default: false}
  },
  data () {
    return {
      savingFailed: false,

      importSuccess: false,

      updatingItems: false,

      newContracts: [],
      contractChanges: [],

      importErrors: [],
      errors: [],

      overlapErrors: [],
      savingErrors: [],

      itemsLength: 0,
      totalItems: 0,
      errorRowsCount: 0,
      itemsNotChangedCount: 0, 
      savedContractsCount: 0,
      updatedContractsCount: 0,

      changesWidgets: [
        CarparkChangesIgnoredWidget(),
        CarparkChangesDetectedWidget(),
        CarparkNewItemsWidget()
      ]
    }
  },
  computed: {
    hasChanges () {
      return this.contractChanges.length > 0 || this.newContracts.length > 0
    },
    changesList () {
      return [
        {changes: this.contractChanges, text: this.$t('dataimport.list_changes')},
        {changes: this.newContracts, text: this.$t('dataimport.new_contracts')}
      ]
    },
  },
  async mounted () {
    const data = {
      items: this.items,
      headers: this.headers
    }
    const firstIdentifier = this.headers.findIndex(h => h.value === this.identifier)
    if (firstIdentifier > -1 ) {
      const importData = this.modifyItems(data, this.results.data, firstIdentifier)
      this.itemsLength = importData.items.length
      this.importSuccess = importData.success
      this.importErrors = importData.errors
      this.errors = []
      if (importData.success) {
        await this.handleUpdate(importData.items)
      }
      this.savingFailed = false
    } else {
       // identifier not found from headers, so cant save data
      this.savingFailed = true
    }
    this.$emit('changeSavingChanges', false)
  },
  methods: {
    modifyItems (widgetData, data, primaryId) {
      const primaryHeaderIdentifier = widgetData.headers[primaryId].text
      const primaryKeyIdentifier = widgetData.headers[primaryId].value

      let success = true
      const errors = { uneditable: [], unexisting: [] }
      const modifiedItems = []

      data = data.filter(item => item[primaryHeaderIdentifier])

      // generate new items
      const items = JSON.parse(JSON.stringify(data))
      
      items.forEach((item) => {

        // item to be returned
        const modifiedItem = {}

        // find corresponding item from existing data 
        const dataItem = widgetData.items.find(s => {
          return (item[primaryHeaderIdentifier] === s[primaryKeyIdentifier])
        })
        // replace only identifiable row fields
        widgetData.headers.forEach(header => {
          if ('ignoreHeader' in header && header.ignoreHeader) {
            // bypass this header
            return
          }
          // verify that data has all the same header fields that widget has
          try {
            if (!(Object.prototype.hasOwnProperty.call(item, header.text))) {
              success = false
              errors.unexisting.push(header.text)
            }
          } catch (err) {
            success = false
            return
          }
          // check if wrong fields have been edited
          if (!header.editable) {
            // if header is not editable copy original values
            modifiedItem[header.value] = item[header.value]
            // check only cases where fields are not empty, undefined or null
            if (dataItem && (dataItem[header.value] || item[header.text])) {
              // If the field has not been edited.
              const stillEqual = dataItem[header.value] === item[header.text]
              success = stillEqual ? success : false
              // The uneditable field differs from the original.
              if (!stillEqual) {
                errors.uneditable.push(header.text)
              }
            }
          }
          // replace value if header is defined as editable
          if (header.editable) {
            // update only fields that have content
            if (item[header.text]) {
              let value = item[header.text]
              value = value === 'FALSE' || value === 'false' ? false : value
              value = value === 'TRUE' || value === 'true' ? true : value
              modifiedItem[header.value] = value !== '' ? value : null
            } else {
              modifiedItem[header.value] = null
            }
          }
        })
        modifiedItems.push(modifiedItem)
      })

      // Remove duplicates from errors
      errors.uneditable = Array.from(new Set(errors.uneditable))
      errors.unexisting = Array.from(new Set(errors.unexisting))

      return { items: modifiedItems, success, errors }

    },
    async handleUpdate (items) {

      const reports = this.items

      const changedReports = []
      const newContracts = []
      let unChangedRows = 0
      let errRows = 0

      // Lets group items by contract number and check that all editable rows have mostly same data
      // (Only start and end dates should differ between rows)
      const itemsByContractNumber = items.reduce((acc, curr) => {
        acc[curr.contract_number] ? acc[curr.contract_number].push(curr) : acc[curr.contract_number] = [curr]
        return acc}, 
        {}
      );
      this.headers.filter(header => header.value !== 'start_date' && header.value !== 'end_date' && header.value !== 'carpark_code_long' ).forEach(header => {
        if (header.editable && !header.ignoreHeader) {
          Object.keys(itemsByContractNumber).forEach(contractNumber => {
            if (!itemsByContractNumber[contractNumber].every(row => row[header.value] === itemsByContractNumber[contractNumber][0][header.value]))
            {
              this.errors.push({
                label: this.$t('Contract number'),
                value: `${contractNumber} ${this.$t('dataimport.values_differ_between_rows')}`,
              })
              items = items.filter(item => item.contract_number !== contractNumber)
            }
          })
        }
      })

      this.importErrors.unexisting.forEach(s => {
        errRows +=1
        const errArr = s.split(",")
        this.errors.push({
          carparkCodeLong: errArr[0],
          value: this.$t('dataimport.building_or_unit_not_found'),
          label: this.headers.find(header => header.value === 'carpark_code_long').text
        })
      })
      
      this.totalItems = items.length

      items.forEach(modifiedItem => {
        if (modifiedItem.end_date && !compareDates(modifiedItem.start_date, modifiedItem.end_date)) {
          this.errors.push({
            carparkCodeLong: modifiedItem.carpark_code_long,
            value: this.$t('dataimport.end_date_before_start_date'),
            label: this.headers.find(header => header.value === 'end_date').text
          })
          return
        }
        let newContract = false
        const report = reports.find(r => (r.carpark_code_long === modifiedItem.carpark_code_long && r.contract_number === modifiedItem.contract_number))
        if (!report) {
          newContract = true
        }
        const reportCopy = {}
        if (report) {
          this.headers.forEach(header => {
            reportCopy[header.value] = report[header.value]
          })
          reportCopy.id_party = report.id_party
          modifiedItem.id_car_park_contract = report.id_party
          reportCopy.id_car_park_contract = report.id_car_park_contract
          modifiedItem.id_car_park_contract = report.id_car_park_contract
        } else {
          this.headers.forEach(header => {
            reportCopy[header.value] = null
          })
        }
        const reportObserver = this.$jsonpatch.observe(reportCopy)
        Object.keys(reportCopy).forEach(key => {
          const propertyHeader = this.headers.find(h => h.value === key)
          // replace only editable fields
          if (propertyHeader && (propertyHeader.editable && !propertyHeader.ignoreHeader)) {
            reportCopy[key] = modifiedItem[key]
          }
        })
        const errorObject = {
          carparkCodeLong: reportCopy.carpark_code_long,
        }
        let inputFieldError = false
        // validate input fields that they are in correct format
        this.headers.forEach(header => {
          let isNotCorrect
          if (reportCopy[header.value]) {
            if (header.format === 'Date') {
              const momentDay = moment(reportCopy[header.value], "DD.MM.YYYY")
              const momentDayWithSlash = moment(reportCopy[header.value], "DD/MM/YYYY")
              const momentDayWithDash = moment(reportCopy[header.value], "YYYY-MM-DD")
              let newTime = new Date()
              // verify correct time format
              if(momentDayWithDash.isValid() && reportCopy[header.value].includes('-') && !reportCopy[header.value].includes(':')) {
                newTime = momentDayWithDash.endOf('day').toDate()
              } else if (momentDay.isValid() && reportCopy[header.value].includes('.') && !reportCopy[header.value].includes('-')) {
                newTime = momentDay.endOf('day').toDate()
              } else if (momentDayWithSlash.isValid() && reportCopy[header.value].includes('/') && !reportCopy[header.value].includes('-')) {
                newTime = momentDayWithSlash.endOf('day').toDate()             
              } else {
                isNotCorrect = isNaN(Date.parse(reportCopy[header.value]))
                if (!isNotCorrect) {
                  newTime = moment(reportCopy[header.value]).endOf('day').toDate()
                }
              }
              // for existing contracts only update values if needed
              if (!newContract) {
                const originalTime = moment(report[header.value]).endOf('day').toDate().getTime()
                reportCopy[header.value] = newTime.getTime() !== originalTime ? newTime : report[header.value]
              } else {
                reportCopy[header.value] = newTime
              }
            }
            if (header.format === 'Area' || header.format === 'Euro' || header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
              // export function converts all decimal dots to commas, convert them back
              reportCopy[header.value] = String(
                reportCopy[header.value]
              ).replace(',', '.')
              // ignore NaN notice periods
              isNotCorrect = isNaN(Number(reportCopy[header.value]))
            }

            if (isNotCorrect) {
              inputFieldError = true
              this.errors.push({
                ...errorObject,
                value: reportCopy[header.value] + this.$t('dataimport.incorrect_input'),
                label: header.text
              })
              errRows += 1
              // return original value
              if (!newContract) {
                reportCopy[header.value] = report[header.value]
              } else {
                reportCopy[header.value] = null
              }
            }
          }

          if (header.format === 'Area' || header.format === 'Euro' || header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
            // export function converts all numbers to strings, convert them back to numbers if needed
            reportCopy[header.value] = reportCopy[header.value]
              ? Number(reportCopy[header.value])
              : null
          }
        })

        if (!inputFieldError) {
          const reportPatch = this.$jsonpatch.generate(reportObserver)
          if (reportPatch.length) {
            let error = false
            this.requiredFields.forEach(field => {
              if (!reportCopy[field]) {
                error = true
                this.errors.push({
                  ...errorObject,
                  value: this.$t('Required'),
                  label: this.headers.find(header => header.value === field).text
                })
                errRows += 1
              }
            })
            if (!error) {
              if (!newContract && report.id_car_park_contract && report.id_party) {
                  changedReports.push(reportCopy)
              } else if (newContract && this.checkBusinessId(reportCopy)) {
                // we created a new contract
                newContracts.push(reportCopy)
              }
            }
          } else {
            unChangedRows += 1
          }
        }
      })
      this.itemsNotChangedCount = unChangedRows
      this.errorRowsCount = errRows
      // Change all boolean rows back to true/false values
      changedReports.forEach((value, index, arr) => {
        arr[index].validity_note = stringToBoolean(arr[index].validity_note)
        arr[index].vat_responsibility = stringToBoolean(arr[index].vat_responsibility)
        arr[index].contract_turnover_tieup = stringToBoolean(arr[index].contract_turnover_tieup)
        arr[index].payments_different_validity_periods = stringToBoolean(arr[index].payments_different_validity_periods)
      })
      newContracts.forEach((value, index, arr) => {
        arr[index].validity_note = stringToBoolean(arr[index].validity_note)
        arr[index].vat_responsibility = stringToBoolean(arr[index].vat_responsibility)
        arr[index].contract_turnover_tieup = stringToBoolean(arr[index].contract_turnover_tieup)
        arr[index].payments_different_validity_periods = stringToBoolean(arr[index].payments_different_validity_periods)
      })

      this.contractChanges = changedReports
      this.newContracts = newContracts


    },
    checkBusinessId (contract) {
      return contract.business_id && contract.business_id.replace(/\s/g,'')
    },
    resetChanges () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$emit('resetChanges')
    },
    async saveChanges () {
      this.$emit('saveContracts', this.newContracts, this.contractChanges, ()  => this.handleSaving())
    },
    handleSaving () {
      const errors = this.updatingErrors.concat(this.newContractsErrors)
      this.overlapErrors = errors.filter(error => error.type === 'Overlapping')
      this.savingErrors = errors.filter(error => error.type !== 'Overlapping')
      if (errors.length === 0) {
        this.resetChanges()
      } else {
        this.updatedContractsCount = this.contractChanges.length - this.updatingErrors.length
        this.savedContractsCount = this.newContracts.length - this.newContractsErrors.length
        this.savingFailed = true
        this.setDefaults()
      }
      this.$emit('refreshData')
    },
    setDefaults () {
      this.contractChanges = []
      this.newContracts = []
    },
    viewErrorText (error) {
      const { identifier, label, value} = error
      return `${identifier ?? ''} - ${label}: ${value}`
    },
  }
}
</script>

<style>
</style>