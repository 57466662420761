<template>
  <div
    id="site-additional-info"
  >
    <sortable-widget-layout
      :widgets="widgets"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SortableWidgetLayout from '../components/widgets/SortableWidgetLayout.vue'

export default {
  name: 'AdditionalInfo',
  components: {
    SortableWidgetLayout,
  },
  data () {
    return {
      // Widgets
      widgetOrder: [],
      largeWidgets: [],
      smallWidgets: [],
      draggableColor: null,
      widgets: [
        {
          id: 'site.energy',
          type: 'MetadataForm',
          data: {
            icon: null,
            source: 'joinedData',
            titleSource: null,
            metadataSource: 'joinedMetadata',
            metadata: null,
            category: 'Energy'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.ltmp_year_overview_fimx',
          type: 'VerticalStackedGraph',
          data: {
            subtitle: [
              this.$t('Euros for whole site'),
              this.$t('Euros/Net floor area (€/m2)')
            ],
            cumulative: [
              this.$t('Cumulative euros'),
              this.$t('Average euros/Net floor area')
            ],
            headers: {
              total: [
                { text: this.$t('fimx.investment'), value: 'totalInvestment', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'totalEnergyInvestment', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'totalMaintenance', color: '#FFD042'},
              ],
              suggestion: [
                { text: this.$t('fimx.investment'), value: 'investmentSuggestion', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentSuggestion', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceSuggestion', color: '#FFD042'},
              ],
              inProcess: [
                { text: this.$t('fimx.investment'), value: 'investmentInProcess', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentInProcess', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceInProcess', color: '#FFD042'},
              ],
              completed: [
                { text: this.$t('fimx.investment'), value: 'investmentCompleted', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentCompleted', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceCompleted', color: '#FFD042'},
              ]
            },
            sets: [
              { text: this.$t('fimx_set.total'), value: 'total'},
              { text: this.$t('fimx_set.suggestion'), value: 'suggestion'},
              { text: this.$t('fimx_set.in_process'), value: 'inProcess'},
              { text: this.$t('fimx_set.completed'), value: 'completed'}
            ],
            items: [],
            column: 'year',
            areaColumn: 'area',
            yUnit: ' €',
            report: 'ltmpYearOverview'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.estate_construction_projects',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Project type'), value: 'projectType' },
              { text: this.$t('Project state'), value: 'projectState' },
              { text: this.$t('Construction project name'), value: 'projectName' },
              { text: this.$t('Project description'), value: 'projectDescription' },
              { text: this.$t('Project start date'), value: 'projectStartDate', format: 'Date' },
              { text: this.$t('Estimated finish date'), value: 'estimatedFinishDate', format: 'Date' },
              { text: this.$t('Confirmed completion'), value: 'confirmedFinishDate', format: 'Date' },
              { text: this.$t('Project lead'), value: 'projectLead' },
              { text: this.$t('Project lead email'), value: 'projectLeadEmail' },
              { text: this.$t('Project supervisor'), value: 'projectSupervisor'},
              { text: this.$t('NOI handling'), value: 'noiHandling' },
              { text: this.$t('Contractor'), value: 'contractor' },
              { text: this.$t('Do not show in site guide'), value: 'hideInSiteGuide', format: 'Boolean' },
            ],
            items: [],
            report: 'GetEstateConstructionProjects',
            sortBy: 'projectStartDate',
            sortDesc: true
          },
          small: true,
          maximized: [false]
        },
        {
          id: 'site.damage_insurance_event',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Damage type'), value: 'damageType' },
              { text: this.$t('Damage state'), value: 'damageState' },
              { text: this.$t('Damage name'), value: 'damageName' },
              { text: this.$t('Damage date'), value: 'damageDate', format: 'Date' },
              { text: this.$t('Damage description'), value: 'damageDescription' },
              { text: this.$t('Damage notability'), value: 'damageNotability' }
            ],
            items: [],
            report: 'GetDamageAndInsuranceEvents',
            sortBy: 'damageDate',
            sortDesc: true
          },
          small: true,
          maximized: [false]
        },
        {
          id: 'site.esg_energy_adjustment',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Adjustment state'), value: 'adjustmentState' },
              { text: this.$t('Adjustment name'), value: 'adjustmentName' },
              { text: this.$t('Adjustment type'), value: 'adjustmentType' },
              { text: this.$t('Creation date'), value: 'creationDate', format: 'Date' },
              { text: this.$t('Finishing date'), value: 'finishingDate', format: 'Date' },
              { text: this.$t('Cost estimate'), value: 'CostEstimate', format: 'Money' }
            ],
            items: [],
            report: 'GetEsgEnergyAdjustmentActions',
            sortBy: 'creationDate',
            sortDesc: true
          },
          small: true,
          maximized: [false]
        },
        {
          id: 'site.falcony',
          type: 'DataTableVertical',
          loading: true,
          data: {
            headers: [
              { text: this.$t('audits date'), value: 'date', format: 'Date' },
              { text: this.$t('audits general rating'), value: 'general_rating_site', format: 'Number' },
            ],
            footers: [],
            items: [],
            report: 'GetAuditsReportsForSite',
            sortDesc: true,
            averageTotalRow: true,
          },
          small: true,
          maximized: [false]
        }
      ],
      currentSiteEstates: {},
      currentSiteStructures: {},
      currentSiteBuildings: {}
    }
  },
  computed: {
    ...mapState('sites', [
      'siteMetadata',
      'buildingMetadata',
      'estateMetadata',
      'loadedReports',
      'currentSiteId',
      'siteServices',
      'sitePermissions'
    ]),
    ...mapState('app', ['currentDate', 'userWidgets', 'userInfo', 'sites']),
    ...mapGetters('sites', [
      'buildingsForCurrentSite',
      'estatesForCurrentSite',
      'structuresForCurrentSite',
      'reportForCurrentSite',
      'currentSite',
    ]),
    ...mapGetters('app', [
      'widgetPermissions',
      'hasApplicationPermissionByName'
    ]),
    hasWritePermission () {
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
    siteId () {
      return Number(this.$route.params.id_site)
    },
    isDraggable () {
      return this.$store.state.app.sortableWidgets
    },
    joinedMetadata () {
    // Filter out certifications
    let filteredBuildingMetadata = Object.keys(this.buildingMetadata).filter(key =>
          !['cert_build', 'cert_class_build', 'cert_usage', 'cert_class_usage'].includes(key)).reduce((obj, key) =>
          {
              obj[key] = this.buildingMetadata[key];
              return obj
          }, {}
      )

      const joined = JSON.parse(
        JSON.stringify({
          ...this.siteMetadata,
          ...this.estateMetadata,
          ...filteredBuildingMetadata,
          ...this.spacesMetadata
        })
      )

      Object.keys(joined).forEach(key => {
        joined[key].editType = 'None'
      })

      return joined
    },
    joinedData () {
      let joined = { ...this.currentSite }
      for (const prop in this.currentSiteEstates) {
        joined = { ...joined, ...this.currentSiteEstates[prop].original }
      }
      for (const prop in this.currentSiteBuildings) {
        joined = { ...joined, ...this.currentSiteBuildings[prop].original }
      }
      return joined
    }
  },
  watch: {
    currentDate: function (newDate) {
      const date = this.$store.state.app.currentDate
      if (
        new Date(newDate).toISOString().substr(0, 10) !==
        date.toISOString().substr(0, 10)
      ) {
        this.initChanges()
        this.updateWidgetData()
      }
    },
    currentSite: {
      handler: async function () {
        // Make a copy of the currentSite
        this.$log.info('Current site changed. updating site info...')
        await this.initView()
      },
      deep: false
    },
    loadedReports: function () {
      this.updateWidgetData()
    },
    isDraggable: function () {
      if (this.isDraggable) {
        this.draggableColor = '#8b8b8b'
      } else {
        this.draggableColor = null
      }
    },
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('KOHDE_LISATIEDOTLEHTI')) {
      this.$router.push({ name: 'sites.info' })
    }
    await this.initView()
  },
  methods: {
    ...mapActions('sites', [
      'loadSiteAdditionalReports']),
    async initView () {
      if (this.currentSite) {
        this.loadSiteAdditionalReports(this.currentSiteId)
        await this.initChanges()
        await this.updateWidgetData()
        this.$store.commit('app/setSortableWidgets', false)
      }
    },
    initChanges: function () {
      if (this.currentSite === undefined) {
        this.$log.warn('Current site was not defined while initializing...')
        return
      }
      this.estatesForCurrentSite.forEach(estate => {
        this.currentSiteEstates[estate.estate_id] = {
          original: JSON.parse(JSON.stringify(estate))
        }
      })

      this.structuresForCurrentSite.forEach(structure => {
        this.currentSiteStructures[structure.structure_id] = {
          original: JSON.parse(JSON.stringify(structure))
        }
      })

      this.buildingsForCurrentSite.forEach(building => {
        this.currentSiteBuildings[building.building_code] = {
          original: JSON.parse(JSON.stringify(building))
        }
      })
    },
    getFieldName: function (field) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)
      return field
    },
    updateWidgetData () {
      this.widgets.forEach((w, idx) => {
        if (w.data.report !== undefined) {
          // if report is defined in widget, use the data from store
          w.data.items = this.reportForCurrentSite(w.data.report)     
          if (w.data.items === null || w.data.items === undefined) {
            w.data.items = []
          } else {
            w.loading = false
          }
        } else if (w.data.source !== undefined && w.data.source !== null) {
          // if source is defined in widget, use the data from component
          w.data.items = this[w.data.source]
          w.loading = false
        }

        // Set metadata
        if (w.data.metadataSource !== null) {
          w.data.metadata = this[w.data.metadataSource]
        } else if (
          w.data.metadataLabels !== null &&
          w.data.metadataLabels !== undefined
        ) {
          w.data.metadata = {}
          w.data.metadataLabels.forEach(label => {
            w.data.metadata[label.value] = {
              isShown: true,
              category: w.data.category ? w.data.category : 'None',
              isDefinition: label.isDefinition ? label.isDefinition : false,
              format: label.format ? label.format : 'None'
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped>
#site-additional-info {
  padding: 0.5em;
  padding-top: 0;
}

#site-additional-info > .col {
  padding: 0.5em;
  padding-top: 0;
}

#site-additional-info > .col > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#site-additional-info .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#site-additional-info .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#site-additional-info .subpanel:last-child {
  margin-bottom: 0.5em;
}

#site-additional-info .widget {
  padding-top: 1em;
}
</style>
