<template>
  <div>
    <v-row      
      justify="space-between"
      align="center"
      no-gutters
    >
      <v-menu
        v-if="data.itemSetTypes && data.itemSetTypes.length > 1"
      >
        <template #activator="{ on }">
          <v-btn
            outlined
            small
            right
            icon
            style="background: rgba(255, 255, 255, 0.8) !important"
            v-on="on"
          >
            <v-icon>menu</v-icon>
            <span class="d-sr-only">{{ $t('Menu') }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="set in itemSetTypes"
            :key="set"
            :class="{ 'active': currentItemSet === set}"
            @click="selectItemSet(set)"
          >
            <v-list-item-title>{{ $t(set) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row
        v-if="currentSet"
        justify="center"
        align="center"
        no-gutters
      >
        <h3>{{ currentItemSet || currentSet.title }}</h3>
      </v-row>
      <v-menu
        v-if="data.sets && data.sets.length > 1"
      >
        <template
          v-if="data.setsAreValueAreaType"
          #activator="{ on }"
        >
          <v-btn
            outlined
            small
            icon
            v-on="on"
          >
            <span v-if="data.itemSetsButtonText && data.itemSetsButtonText.length > 0">'
              {{ data.itemSetsButtonText }}
            </span>
            <span v-else>
              <v-icon>menu</v-icon> 
              <span class="d-sr-only">{{ $t('Menu') }}</span>
            </span>
          </v-btn>
        </template>
        <template
          v-else
          #activator="{ on }"
        >
          <v-btn
            outlined
            small
            absolute
            right
            icon
            style="background: rgba(255, 255, 255, 0.8) !important"
            v-on="on"
          >
            <v-icon>{{ getIcon(currentSet) }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="set in sets"
            :key="set.title"
            :class="{ 'active': currentSet === set}"
            @click="selectSet(set)"
          >
            <v-list-item-title>{{ set.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <HorizontalBarChart
      v-if="GraphData !== null"
      :data="GraphData"
      :options="barOptions"
      :plugins="plugins"
      :style="{height: graphHeight, minHeight: '200px'}"
      class="graph"
    />
    <div
      v-if="data.customSort"
      class="pa-0"
      style="height: 35px"
    >
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            outlined
            text
            small
            left
            absolute
            class="no-transform"
            v-on="on"
          >
            <span
              v-for="(value, index) in data.sortOptions"
              :key="value"
              :class="{ highlighted: value == currentSortOption}"
            >
              {{ $t(value) }}
              <span v-if="index != data.sortOptions.length - 1">/</span>
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="option in data.sortOptions"
            :key="option.label"
            :class="{ 'active': currentSortOption === option}"
            @click="selectSortOption(option)"
          >
            <v-list-item-title>{{ $t( option) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div 
      v-if="currentSet && currentSet.summary"
      class="pa-0 text-center"
      style="height: 35px"
    >
      <span class="text-subtitle-1 font-weight-bold">
        {{ getSummaryLine() }}
      </span>
    </div>
  </div>
</template>
<script>
import helpers from '../../helpers/'
import HorizontalBarChart from '../HorizontalBarChart'
export default {
  name: 'HorizontalGroupedBarDataGraph',
  components: {
    HorizontalBarChart: HorizontalBarChart
  },
  props: {
      data: {type: Object, default: null},
      widgetId: {type: String, default: null},
      plugins: {type: Array, default: function () { return [] }}
  },
  data () {
    return {
      currentSet: null,
      currentItemSet: null,
      currentSortOption: null,
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: '#8b8b8b'
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
                // Add thousand separator and label to tooltip

                const label = data.datasets[tooltipItem.datasetIndex].label

                const format = data.datasets[tooltipItem.datasetIndex].format

                const value = tooltipItem.xLabel

                if(format){
                  return `${label}: ${helpers.format.formatData(value, format)}`
                }

                let tooltip =  label + ': ' + helpers.humanize.thousand_separator(value);
                
                // Add format specific extras
                if (format === 'Area') {
                    tooltip += ' m\u00B2'
                }
                if (format === 'Euro') {
                    tooltip += ' €'
                }
                if (format === 'MoneyPerSquare') {
                    tooltip += ' €/m\u00B2'
                }
                if (format === 'Percentage') {
                    tooltip += ' %'
                }

                return tooltip
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: 'white'
              },
              ticks: {
                fontColor: '#8b8b8b',
                fontSize: 10,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return helpers.humanize.thousand_separator(value)
                }
              },
              stacked: false
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: 'white',
                zeroLineColor: 'white'
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#8b8b8b'
              },
              stacked: false
            }
          ]
        },
        onClick: this.graphClick,
        onHover: this.graphHover
      }
    }
  },
  computed: {
    sets: function () {
      // Check for sets
      if (this.data.sets.length === 0) {
        return []
      }
      return this.data.sets
    },
    itemSetTypes: function () {
      if (this.data.itemSetTypes.length === 0) {
        return []
      }
      return this.data.itemSetTypes
    },
    groups: function () {
        if (this.currentSet !== null) {
            return this.currentSet.groups
        }
        else {
            return []
        }
    },
    objects: function () {
      if (Array.isArray(this.data.items) && this.currentSet != null) {

          // Group data by given parameter, key being the groupBy value
          const groupedData = this.data.items.reduce((acc, cur) => {
            const key = cur[this.currentSet.groupBy]
            if (typeof key === 'undefined' || key === null) {
              return acc
            }
            if (typeof acc[key] === 'undefined') {
              acc[key] = {...cur}
            }

            return acc
          }, {})

          let sortBy = this.data.customSort ? this.data.sortFunction : this.currentSet.sortField

          // Get largest values by given field and limit
          return this.getLargestValues(groupedData, this.data.limit, sortBy)
        
      } else {
        return []
      }
        
    },
    GraphData: function () {
      const objectKeysArray = [...Object.keys(this.objects)]
      const objectValuesArray = [...Object.values(this.objects)]
      return {
        labels: [...Object.keys(this.objects).filter(o => {
          if (Object.keys(this.objects[o] > 0)) {
            return o
          }
        })],
        datasets: [
          ...this.groups.map(grp => {
            let colorFromData = this.checkIfColorIsInData(objectValuesArray)
            return {
              label: grp.label,
                backgroundColor: colorFromData ? this.getColorsFromData(objectValuesArray) : grp.color ? grp.color : this.getThemeColorArray(objectKeysArray.length),
                format: this.currentSet.format,
                data: [
                  ...Object.keys(this.objects).map(key => this.objects[key][grp.value])
                ],
                ids: this.data.clickable ? [
                  ...Object.keys(this.objects).map(key => this.objects[key].id)
                ] : null
            }
        })
        ]
      }
    },
    graphHeight: function () {
      return (Object.keys(this.objects).length*50).toString() + 'px'
    },
    divHeight: function () {
      // Add extra height for sort options if needed
      let extraHeight = 0
      if (this.data.customSort) {
        extraHeight += 45
      }
      if (this.currentSet && this.currentSet.summary) {
        extraHeight += 35
      }
      return ((Object.keys(this.objects).length*50) + extraHeight).toString() + 'px'
    },
    shownDataTotal: function () {
      let total =  this.groups.reduce((grpAcc, grp) => {
        const grpTotal = Object.keys(this.objects).reduce((objAcc, obj) => objAcc += this.objects[obj][grp.value], 0)
        return grpAcc + grpTotal
      }, 0)
      return total
    },
    totalPercentage: function () {
      let total = this.groups.reduce((grpAcc, grp) => {
        const grpTotal = this.data.items.reduce((itemAcc, item) => itemAcc += item[grp.value], 0)
        return grpAcc + grpTotal
      }, 0)
      let totalPercentage = this.shownDataTotal / total
      return totalPercentage * 100
    }
  },
  mounted: function () {
    if (typeof this.data.sets !== 'undefined') {
      this.selectSet(this.data.sets[0])
    }

    if(this.data.itemSetTypes && this.data.itemSetTypes.length > 0){
      this.currentItemSet = this.data.itemSetTypes[0]
    }

    // showProgress property is to show percentual progress in HorizontalBarDataGraph
    if (this.data.showProgress) {
      // Set the x axes max scale to 100
      this.barOptions.scales.xAxes[0].ticks.max = 100
    }
    // If there is custom sort, there must be at least 1 sort option
    if (this.data.customSort) {
      this.currentSortOption = this.data.sortOptions[0]
    }
    if (this.data.customLegend) {
      this.setCustomLabelGenerator(this.data.legends)
    }
  },
  methods: {
    selectSet (set) {
      this.currentSet = set
      this.barOptions.scales.xAxes[0].ticks.callback = function (value) {
        return helpers.format.formatData(value, set.format)
      }
    },
    selectItemSet (set) {
      this.currentItemSet = set
      this.$emit('change-item-set', {set, id: this.widgetId})
    },
    selectSortOption (option) {
      this.currentSortOption = option
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${204 + 45 * index}, 82%, 72%)`
    },
    getLargestValues (object, limit = 13, sortBy) {
      let entries = Object.entries(object)
      // Sort the entries by value from highest to lowest by given field
      if (typeof sortBy === 'function') {
        entries = entries.filter(entry => entry[1][this.currentSet.sortField] != null)
        entries = entries.sort((a,b) => sortBy(a[1], b[1], this.currentSortOption, this.currentSet))
      }
      else if (sortBy != null)
        entries = entries.filter(entry => entry[1][sortBy] != null)
        entries = entries.sort((a, b) => b[1][sortBy] - a[1][sortBy]);
      // ...then slice it based on given or default limit parameter (default is 13).
      // Determines how many values we showcase in bargraph
      entries = entries.slice(0, limit)
      // Lastly, turn the sliced and sorted array into an object
      const result = Object.fromEntries(entries)
      return result
    },
    checkIfColorIsInData (objectArray)
    {
      if(objectArray.length > 0)
      {
        //Expect that whole data is colored if one is colored
        if (objectArray[0].color !== undefined)
        {
          return true
        }
      }
      return false
    },
    getColorsFromData (objectArray)
    {
      return objectArray.map(x => {
        return x.color
      })
    },
    getIcon (set)
    {
      if(set === null || set.icon === undefined)
      {
        return 'menu'
      }
      
      return set.icon
    },
    setCustomLabelGenerator (customLegend) {
      this.barOptions.legend.display = true
      this.barOptions.legend.labels.generateLabels = function () {
        return customLegend
      }
    },
    getSummaryLine () {
      return `Top${this.data.limit} ${this.$t('Total').toLowerCase()} ${helpers.format.formatData(this.shownDataTotal, this.currentSet.format)} ${this.$t('Portion of chosen portfolio')} ${helpers.format.formatData(this.totalPercentage, 'Percentage') }`
    },
    graphClick (event, chartElement) {
      if (this.data.clickable && chartElement?.length > 0) {
        this.$emit('click', chartElement)
      }
    },
    graphHover (event, chartElement) {
      if (this.data.clickable) {
        event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    },
  }
}
</script>

<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.firstMenuButton {
  margin-right: 50px;
}
.highlighted {
  color: var(--c-color-accent) !important;
}
.no-transform {
  text-transform: none;
}
</style>
