import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { i18n } from './i18n'

Vue.use(Vuetify)
const opts = {
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#2A74B5',
        secondary: '#2A74B5',
        accent: '#05326E',
        heath: '#62294B',
        info: '#2A74B5',
        error: '#d73843',
      },
    },
  },
}

export default new Vuetify (opts)
