<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
  >
    <BaseModal
      :is-loading="loading"
      @cancel="emit"
    >
      <template #title>
        {{ $t('marketing.title') + ' ' + selectedUnit.unit_code }}
      </template>
      <template #content>
        <v-form ref="marketingDialogForm">
          <v-row>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h6 mb-1">
                  {{ $t('Marketing') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('marketing.switch.hint') }}
                </v-list-item-subtitle>
                <div>
                  <v-switch
                    v-model="isEnabled"
                    :aria-label="isEnabled ? `${$t('Marketing')} ${$t('marketing.switch.on')}` : `${$t('Marketing')} ${$t('marketing.switch.off')}`"
                    :label="isEnabled ? $t('marketing.switch.on') : $t('marketing.switch.off')"
                  />
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`required`"
                  >
                    <v-text-field
                      v-model="rent"
                      :label="$t('marketing.rent_request')"
                      :hint="$t('Required')"
                      persistent-hint
                      :error-messages="errors[0]"
                      type="number"
                      class="form-fields"
                      required
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`required`"
                  >
                    <v-textarea
                      v-model="description"
                      :label="$t('marketing.description')"
                      :hint="$t('Required')"
                      persistent-hint
                      :error-messages="errors[0]"
                      name="description"
                      class="form-fields mt-10"
                      required
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`required`"
                  >
                    <v-select
                      v-model="idContact"
                      :items="contacts"
                      item-text="name"
                      item-value="id"
                      :label="$t('marketing.contact')"
                      :hint="$t('Required')"
                      persistent-hint
                      :error-messages="errors[0]"
                      name="contact"
                      class="form-fields"
                      required
                    />
                  </ValidationProvider>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-row>
                  <v-col>
                    <v-list-item-title class="text-h6 mb-1">
                      {{ $t('marketing.select_photos') }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-5">
                      <div v-if="allPhotos.length > 0">
                        {{ $t('marketing.no_photos') }}
                      </div>
                      <div v-else>
                        {{ $t('marketing.select_photos_description') }}
                      </div>
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    v-if="isPhotos"
                    cols="12"
                    md="2"
                    class="d-flex align-center justify-end"
                  >
                    <v-btn
                      rounded
                      depressed
                      @click="ToggleAllPhotos"
                    >
                      {{ toggleText }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card
                  v-if="isPhotos"
                  class="my-0 pa-4"
                >
                  <v-row>
                    <v-col
                      v-for="photo in allPhotos"
                      :key="photo.idPhoto"
                      class="d-flex flex-column child-flex"
                      cols="12"
                      sm="6"
                      md="3"
                    >
                      <v-img
                        v-if="thumbsUp[photo.idPhoto]"
                        :src="photo.photoThumbUrl"
                        aspect-ratio="1"
                        cover
                        class="grey lighten-2"
                      >
                        <template #placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-row>
                        </template>
                      </v-img>
                      <v-checkbox
                        v-model="selectedPhotoIds"
                        :label="photo.filename"
                        :value="photo.idPhoto"
                        :disabled="!photo.photoThumbUrl"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-list-item-content>
            </v-list-item>
            <div
              v-if="selectedPhotos.length > 0"
              class="my-4"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 mb-1">
                    {{ $t('marketing.organize_photos') }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-2">
                    {{ $t('marketing.organize_photos_description') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list>
                <draggable
                  v-if="selectedPhotos.length > 0"
                  v-model="selectedPhotos"
                  draggable=".draggable"
                  ghost-class="ghost"
                >
                  <v-list-item
                    v-for="(photo, index) in selectedPhotos"
                    :key="photo.idPhoto"
                    class="mb-4 draggable"
                  >
                    <v-card class="card">
                      <v-row class="flex-nowrap">
                        <v-col
                          class="d-flex child-flex justify"
                          cols="3"
                          sm="4"
                          md="2"
                        >
                          <v-img
                            v-if="thumbsUp[photo.idPhoto]"
                            :src="getPhotoUrl(photo.idPhoto)"
                            aspect-ratio="1"
                            cover
                            class="grey lighten-2 ml-4"
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col
                          class="d-flex child-flex justify-space-between"
                          align-self="center"
                        >
                          <div>
                            <div>
                              {{ photo.filename }}
                              <v-chip
                                small
                                class="ma-2"
                              >
                                {{ $t('photos.quality_' + photo.quality) }}
                              </v-chip>
                            </div>
                            <div>
                              {{ photo.buildingName }}
                            </div>
                            <div>{{ photo.floorName }}</div>
                            <div>{{ photo.unitName }}</div>
                          </div>
                          <div class="d-flex align-center justify-end pr-4">
                            <v-btn
                              v-if="index > 0"
                              icon
                              small
                              class="action-button"
                              @click="movePhoto(index, index - 1)"
                            >
                              <v-icon>keyboard_arrow_up</v-icon>
                              <span class="d-sr-only">{{ $t('MoveUp') }}</span>
                            </v-btn>
                            <v-btn
                              v-if="index < selectedPhotos.length - 1"
                              icon
                              small
                              class="action-button"
                              @click="movePhoto(index, index + 1)"
                            >
                              <v-icon>keyboard_arrow_down</v-icon>
                              <span class="d-sr-only">{{ $t('MoveDown') }}</span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-list-item>
                </draggable>
              </v-list>
            </div>
          </v-row>
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="invalid || loading || saving"
          rounded
          depressed
          class="primary"
          @click="editMarketing"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="white"
          />
        </v-btn>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import BaseModal from '../general/BaseModal'
import { ValidationProvider, ValidationObserver, } from 'vee-validate'
import draggable from 'vuedraggable'

export default {
  name: 'MarketingModal',
  components: {
    BaseModal,
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  props: {
    selectedUnit: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      idMarketing: null,
      idUnit: null,
      isSite: null,
      isEnabled: false,
      description: null,
      rent: null,
      thumbsUp: {},
      selectedPhotoIds: [],
      selectedPhotos: [],
      existingPhotos: [],
      allPhotos: [],
      loading: false,
      saving: false,
      valid: true,
      contacts: [],
      idContact: null,
      allParties: [],
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'classificationIdByName', 'definitionLabelById']),
    ...mapState('app', ['allSitesParties']),
    toggleText () {
      return this.selectedPhotoIds.length < this.allPhotos.length ? this.$t('marketing.select_all') : this.$t('marketing.reset_all')
    },
    isPhotos () {
      return this.allPhotos.length > 0
    },
  },
  watch: {
    selectedPhotoIds () {
      // remove
      this.selectedPhotos = this.selectedPhotos.filter(sp => this.selectedPhotoIds.includes(sp.idPhoto))
      // add
      this.selectedPhotos = [
        ...this.selectedPhotos,
        ...this.allPhotos
          .filter(item => this.selectedPhotoIds.includes(item.idPhoto))
          .filter(nsp => !this.selectedPhotos.map(p => p.idPhoto).includes(nsp.idPhoto))
          .map(nsp => ({
            ...nsp,
            id: this.existingPhotos.find(ep => ep.idPhoto === nsp.idPhoto)?.id,
            photoOrder: 0,
          }))
      ]
    },
  },
  async mounted () {
    this.loading = true
    await this.getMarketing()
    await this.getThumbnails()
    await this.getContacts()
    this.loading = false
  },
  methods: {
    async getContacts () {
      if (this.selectedUnit.id_site <= 0) {
        return;
      }
      const result = await this.$rambollfmapi.sites
        .partyLinks(this.selectedUnit.id_site)
        .list()

      this.contacts = result
        .filter(pl => {
          const annotation = this.definitionLabelById(pl.id_annotation);
          return annotation === 'Vuokravastaava';
        })
        .map(pl => {
          const party = this.allSitesParties.find(ap => ap.id === pl.id_party)
          return {
            name: party?.name,
            email: party?.email,
            phoneNumber: party?.phoneNumber,
            id: party?.id,
          }
        });
    },
    async getThumbnails () {
      if (this.selectedUnit.id_site > 0) {
        const result = await this.$rambollfmapi.sites.photos(this.selectedUnit.id_site).list()
        const photos = result.items.filter(i => i.photo_category !== 441)
        this.thumbsUp = {}
        photos.forEach(photo => {
          if (!photo.photoThumbUrl) {
            this.getPhotoThumbnailer(photo)
          } else {
            this.$set(this.thumbsUp, photo.idPhoto, !this.thumbsUp[photo.idPhoto])
          }
        })
        this.allPhotos = photos
      }
    },
    async getPhotoThumbnailer (photo) {
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.photos
        .download(photo.idPhoto, progress, true)
        .then(res => {
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          photo.photoThumbUrl = URL.createObjectURL(blob)
          this.$set(this.thumbsUp, photo.idPhoto, !this.thumbsUp[photo.idPhoto]) // fix v-img render problem v.42
        })
        .catch(() => {
          this.photoLoadingError = true
        })
    },
    getPhotoUrl (idPhoto) {
      return this.allPhotos.find(p => p.idPhoto === idPhoto)?.photoThumbUrl
    },
    ToggleAllPhotos () {
      if (this.selectedPhotoIds.length < this.allPhotos.length) {
        this.selectedPhotoIds = this.allPhotos.map(up => up.idPhoto)
      } else {
        this.selectedPhotoIds = []
      }
    },
    movePhoto (fromIndex, toIndex) {
      this.selectedPhotos.splice(toIndex, 0, this.selectedPhotos.splice(fromIndex, 1)[0])
    },
    emit () {
      if (confirm(this.$t('marketing.close_confirmation'))) {
        this.$emit('close')
      }
    },
    async getMarketing () {
      const marketing = await this.$rambollfmapi.marketing.getMarketing(this.selectedUnit.id_unit)
      if (marketing?.idMarketing) {
        this.id = marketing.idMarketing
        this.idUnit = marketing.idUnit
        this.idContact = marketing.idContact
        this.isEnabled = marketing.isEnabled
        this.description = marketing.description
        this.rent = marketing.rent
        // Sort array with photo order
        marketing.photos.sort((a, b) => a.photoOrder > b.photoOrder ? 1 : -1)
        this.existingPhotos = marketing.photos
        this.selectedPhotos = marketing.photos
        this.selectedPhotoIds = marketing.photos.map(p => p.idPhoto)
      } else {
        this.idUnit = this.selectedUnit.id_unit
      }
    },
    async editMarketing () {
      this.saving = true
      const marketing = {
        idMarketing: this.id,
        idUnit: this.idUnit,
        idContact: this.idContact,
        isEnabled: this.isEnabled,
        description: this.description,
        rent: this.rent,
        photos: this.selectedPhotos.map((p, i) => ({
          id: p.id,
          idPhoto: p.idPhoto,
          idMarketing: this.id,
          idUnit: this.idUnit,
          photoOrder: i,
        }))
      }
      if (marketing.idMarketing) {
        await this.$rambollfmapi.marketing.update(marketing)
      } else {
        await this.$rambollfmapi.marketing.create(marketing)
      }

      this.saving = false
      this.$emit('updateWidget')
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.v-list-item__content {
  overflow: inherit;
}

.v-input--selection-controls>>>.v-label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.draggable {
  cursor: move;
}

.ghost {
  opacity: 0.5;
}

.card {
  padding: 16px 0;
  width: 100%;
}
</style>
