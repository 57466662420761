!<template>
  <v-card>
    <BaseModal
      @cancel="close"
    >
      <template #title>
        {{ $t("leasing.new_print") }}
      </template>
      <template #content>
        <v-row class="pa-1">
          <v-col cols="7">
            <h3 class="text-body-2 font-weight-bold">
              {{ $t('leasing.brochure_units') }}
            </h3>
          </v-col>
          <v-col
            cols="5"
            class="text-body-2"
          >
            {{ selectedUnitsString }}
          </v-col>
        </v-row>
        <v-row class="pa-1">
          <v-col cols="7">
            <h3 class="text-body-2 font-weight-bold">
              {{ $t("leasing.total_area") }}
            </h3>
          </v-col>
          <v-col
            cols="5"
            class="text-body-2"
          >
            {{ getValueWithUnit(totalArea, 'Area') }} 
          </v-col>
        </v-row>
        <v-select
          v-model="printChoice"
          :items="printOptions"
          item-text="label"
          :label="$t('Select attachment') + ' *'"
          return-object
          class="form-field"
        />
      </template>
      <template #footer>
        <v-btn
          type="submit"
          rounded
          depressed
          class="continue-button"
          :disabled="printChoice == null"
          @click="validate"
        >
          {{ $t('Continue') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseModal from '../general/BaseModal'
import format from '../../helpers/format'

export default {
    name: 'PrintSelectionForm',
    components: {
      BaseModal,
    },
    props: {
      selectedUnits: {
        type: Array,
        default: () => []
      },
      selectedFloor: {
        type: String,
        default: ''
      },
      isShown: {
        type: Boolean,
        default: false
      },
      totalArea: {
            type: Number,
            default: 0
      },
      selectedUnitsString: {
            type: String,
            default: ''
      },
    },
    data () {
        return {
            printOptions: [
              {value: 'unit-brochure', label: this.$t('Unit brochure')},
              {value: 'rental-contract', label: this.$t('Attachment to rental contract')},
              /*{value: 'rental-offer', label: this.$t('leasing.rental_offer') + ' (ei vielä käytössä)'}*/
            ],
            printChoice: null,
        }
    },
    computed: {
      ...mapGetters('app', [
      'hasApplicationPermissionByName',
      ]),
    },
    watch: {
      isShown: function (shown) {
        if (!shown) {
          this.printChoice = null
        }
      }
    },
    async mounted () {
    },
    methods: {
      close () {
        this.$emit('close')
      },
      validate (e) {
        e.preventDefault()
        this.$emit('print', this.printChoice.value, null)
      },
      getValueWithUnit (value, formatting) {
      return format.formatData(value, formatting)
      },
    }  
}
</script>

<style scoped>
.large-padding {
  padding: 50px !important;
}
.test >>> .v-input__control .v-input__slot {
  align-items: flex-start;
}
.continue-button {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>