<template>
  <v-row
    justify="space-between"
    class="pb-2"
  >
    <v-col
      cols="6"
      :sm="inactive ? 4 : 6"
    >
      <v-row justify="start">
        <v-btn
          v-if="prevStage !== null && !inactive"
          rounded
          text
          outlined
          @click="() => $emit('updateStage', prevStage)"
        >
          <v-icon right>
            arrow_back_ios
          </v-icon>
          {{ $vuetify.breakpoint.mdAndUp ? $t(prevStage.label) : "" }}
        </v-btn>
        <v-btn
          v-if="!closed && stageNumber < 6"
          :disabled="inactive"
          text
          rounded
          outlined
          class="error"
          color="white"
          depressed
          @click="() => $emit('passivate')"
        >
          {{ $t("prospect.passivate") }}
        </v-btn>
      </v-row>
    </v-col>
    <v-col
      v-if="inactive"
      cols="12"
      sm="4"
    >
      <inactivation-reason :reason="inactiveReason" />
    </v-col>
    <v-col
      :cols="inactive ? 12 : 6"
      :sm="inactive ? 4 : 6"
    >
      <v-row
        justify="end"
        align="center"
      >
        <v-btn
          :disabled="!dataChanged"
          rounded
          depressed
          class="primary"
          :loading="saveLoading"
          @click="() => $emit('save')"
        >
          {{ $t("Save") }}
        </v-btn>
        <v-btn
          v-if="inactive"
          rounded
          depressed
          class="add-site-btn blue-button"
          @click="() => $emit('activate')"
        >
          {{ $t("prospect.activate") }}
        </v-btn>
        <v-btn
          v-if="!inactive && !closed"
          rounded
          text
          outlined
          @click="() => $emit('updateStage', nextStage)"
        >
          {{ $vuetify.breakpoint.mdAndUp ? $t(nextStage.label) : "" }}
          <v-icon right>
            arrow_forward_ios
          </v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import InactivationReason from "./InactivationReason.vue"
import { mapGetters } from "vuex"
export default {
  name: "RentalProcessButtonRow",
  components: {
    InactivationReason,
  },
  props: {
    inactive: { type: Boolean },
    inactiveReason: { type: String, default: "" },
    closed: { type: Boolean },
    dataChanged: { type: Boolean },
    saveLoading: { type: Boolean },
    stage: { type: String, default: null },
    stageNumber: { type: Number, default: 0 }
  },
  emits: ["updateStage", "save", "passivate", "activate"],
  computed: {
    ...mapGetters("app", ["definitionsByGroupLabel", "settings"]),
    prevStage () {
      let next = null

      const stages = this.definitionsByGroupLabel(
        "leasing.rental_process_stage"
      )

      switch (this.stage) {
        case "2. Prospect":
          next = { ...stages.find((stage) => stage.label === "1. Lead") }
          break
        case "3. NeedsAssessment":
          next = { ...stages.find((stage) => stage.label === "2. Prospect") }
          break
        case "4. Proposal":
          next = {
            ...stages.find((stage) => stage.label === "3. NeedsAssessment"),
          }
          break
        case "5. ContractNegotiation":
          next = { ...stages.find((stage) => stage.label === "4. Proposal") }
          break
        case "6. Won":
          next =  {  ...stages.find((stage) => stage.label === "5. ContractNegotiation") }
          break
        case "7. RentAdministration":
          next =  {  ...stages.find((stage) => stage.label === "6. Won") }
          break
      case "8. Closed":
          next =  {  ...stages.find((stage) => stage.label === "7. RentAdministration") }
          break
      }

      return next
    },
    // Next stage computed from current stage
    nextStage () {
      let next = {
        id: -1,
        label: "",
      }
      const stages = this.definitionsByGroupLabel(
        "leasing.rental_process_stage"
      )
      switch (this.stage) {
        case "1. Lead":
          next = { ...stages.find((stage) => stage.label === "2. Prospect") }
          break
        case "2. Prospect":
          next = {
            ...stages.find((stage) => stage.label === "3. NeedsAssessment"),
          }
          break
        case "3. NeedsAssessment":
          next = { ...stages.find((stage) => stage.label === "4. Proposal") }
          break
        case "4. Proposal":
          next = { ...stages.find((stage) => stage.label === "5. ContractNegotiation") }
          break
        case "5. ContractNegotiation":
          next =  {  ...stages.find((stage) => stage.label === "6. Won"), openAddMFilesIds: this.settings.hasMFilesIntegration }
          break
        case "6. Won":
          next =  {  ...stages.find((stage) => stage.label === "7. RentAdministration") }
          break
        case "7. RentAdministration":
          next =  {  ...stages.find((stage) => stage.label === "8. Closed") }
          break
      }

      return next
    },
  },
}
</script>

<style scoped></style>
