<template>
  <v-expansion-panels
    class="my-2"
    multiple
    :value="[0]"
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="py-1 pl-2">
        {{ $t('Other information') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getOtherMetadataForUnit(unit)"
          :data="getOtherDataForUnit(unit)"
          :with-status-icon="true"
        />
        <v-textarea
          v-model="getUnitExtraInformation(unit.id).extra_information"
          :class="readonly ? 'readonly-extra-info-box' : 'extra-info-box'"
          :disabled="readonly"
          small
          hide-details
          :label="$t('Unit extra information')"
          @change="unitExtraInformationChange(unit.id, getUnitExtraInformation(unit.id).extra_information)"
        />
        <v-row 
          v-if="getUnitExtraInformation(unit.id).edited"
          style="right: 0; bottom: 0; position: sticky"
        >
          <v-spacer />
          <v-btn
            v-if="!isSavingChanges && !readonly"
            color="primary"
            rounded
            outlined
            style="background: white !important;"
            @click="saveUnitExtraInformationChange(unit.id)"
          >
            {{ $t('Save changes') }}
          </v-btn>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MetadataForm from '../../components/MetadataForm'
import moment from 'moment'
export default {
  name: 'UnitExtraInformation',
  components: { MetadataForm },
  props: {
    unit: {
      type: Object,
      default: null
    },
    unitVisibilityRights: {
      type: Object, 
      default: null
    },
    renting: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: null
    },
    unitExtraInformations: { 
      type: Array,
      default: () => [] },
  },
  data () {
      return {
        isSavingChanges: false,
        savingFailed: false,
      }
  },
  computed: {
    ...mapGetters('app', [
      'definitionLabelById',
    ]),
  },
  methods: {
    ...mapActions('dashboard', ['updateUnitInformation']),
    getOtherMetadataForUnit () {
      let unitOtherMetadata = {
        id: {
          ...this.showUnitProp('id')
        },
        apartment_type: {
          ...this.showUnitProp('apartment_type')
        }, 
        apartment_type_string: {
          ...this.showUnitProp('apartment')
        },
        premise_type: {
          ...this.showUnitProp('usage')
        },
        vyname: {
          ...this.showUnitProp('vyname')
        },
        vynro: {
          ...this.showUnitProp('vynro')
        },
        tenant: {
          ...this.showUnitProp('tenant')
        },
        vat_status: {
          ...this.showUnitProp('alv')
        },
        unit_version_end_date: {
          ...this.showUnitProp('end_date')
        },
        contract_area: {
          ...this.showUnitProp('contract_area')
        },
        contract_expiration_date: {
          ...this.showUnitProp('tenant'),
          format: "Date"
        }
      }
      return unitOtherMetadata
    },
    getOtherDataForUnit (unit) {
      let dataOtherForUnit = {
        id: unit.id,
        apartment_type: unit.apartment_type,
        premise_type:
          unit.usage !== null ? this.$t(unit.usage) : this.$t('Undefined'),
        rental_status: this.getRentalStatus(unit),
        tenant: unit.tenant,
        vat_status: this.getVATStatus(unit.alv),
        unit_version_end_date: unit.end_date,
        contract_type: this.getContractType(unit),
        contract_area: unit.contract_area,
        contract_expiration_date: unit.contract_end_date,
        contract_number: unit.contract_number,
        extra_information: unit.unit_note,
        apartment_type_string: this.getApartmentString(unit),
        vyname: unit.vyname,
        vynro: unit.vynro,
      }
      return dataOtherForUnit
    },
    showUnitProp (prop) {
      const metadata = this.unitVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
      } else {
        return { isShown: false, category: 'None', order: 999, format: 0 }
      }
    },
    getRentalStatus (unit) {
      moment.locale('fi')
      const targetDate = moment(unit.contract_end_date)

      const threeMonthsToGo = moment()
        .add(3, 'months')
        .calendar()
      const sixMonthsToGo = moment()
        .add(6, 'months')
        .calendar()
      const nineMonthsToGo = moment()
        .add(9, 'months')
        .calendar()
      const twelveMonthsToGo = moment()
        .add(12, 'months')
        .calendar()

      if (!unit.tenant && unit.not_rentable === 'rentable') {
        return this.$t('Empty unit')
      } else if (
        (unit.tenant && !unit.contract_end_date) ||
        (unit.tenant &&
          unit.contract_end_date &&
          targetDate.isAfter(moment(twelveMonthsToGo, 'DD.MM.YYYY'), 'day'))
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 12 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(nineMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 9 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(sixMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 6 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(threeMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 3 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isBefore(moment(threeMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return (
          this.$t('Rented') + ' (' + this.$t('Valid less than 3 months') + ')'
        )
      } else {
        return null
      }
    },
    getVATStatus (status) {
      let vatStatus
      status === true
        ? (vatStatus = this.$t('Yes, VAT responsible'))
        : status === false
          ? (vatStatus = this.$t('Not VAT responsible'))
          : (vatStatus = this.$t('Undefined'))

      return vatStatus
    },
    getContractType (unit) {
      if (unit.tenant && unit.contract_end_date) {
        return this.$t('Fixed term')
      } else if (unit.tenant && !unit.contract_end_date) {
        return this.$t('Permanent')
      } else {
        return ''
      }
    },
    getApartmentString (unit) {
      var apartmentType = ''
      if (unit.apartment) {
        apartmentType += this.definitionLabelById(unit.apartment)
      }
      if (unit.kitchen) {
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + this.definitionLabelById(unit.kitchen)
        } else {
          apartmentType += this.definitionLabelById(unit.kitchen)
        }
      }
      if (unit.sauna) {
        if (apartmentType.length > 0) {
          apartmentType += ' + s'
        } else {
          apartmentType += 's'
        }
      }
      if (unit.balcony) {
        const balcony = this.definitionLabelById(unit.balcony)
        if (balcony !== this.$t('No')) {
          if (apartmentType.length > 0) {
            apartmentType += ' + ' + balcony
          } else {
            apartmentType += balcony
          }
        }
      }
      if (unit.additional_info_1) {
        const info = this.definitionLabelById(unit.additional_info_1)
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + info
        } else {
          apartmentType += info
        }
      }
      if (unit.additional_info_2) {
        const info = this.definitionLabelById(unit.additional_info_2)
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + info
        } else {
          apartmentType += info
        }
      }
      return apartmentType
    },
    getUnitExtraInformation (unitId) {
      return this.unitExtraInformations.find(unit => unit.id === unitId)
    },
    unitExtraInformationChange (unitId, changedText) {
      if(!this.readonly) {
        const changedUnitIndex = this.unitExtraInformations.findIndex(unit => unit.id === unitId)
        this.unitExtraInformations[changedUnitIndex].edited = true
        this.unitExtraInformations[changedUnitIndex].extra_information = changedText
      }
    },
    async saveUnitExtraInformationChange (unitId) {
      this.isSavingChanges = true
      const time = this.$store.state.app.currentDate
      const changedUnitIndex = this.unitExtraInformations.findIndex( unit => unit.id === unitId)
      
      const patchObj = [
        {
          op: 'replace',
          path: '/description',
          value: this.unitExtraInformations[changedUnitIndex].extra_information
        }
      ]

      const data = {
            unitId: unitId,
            time: time,
            patch: patchObj
          }

      try {
        await this.updateUnitInformation(data)
      } catch {
          this.savingFailed = true
          this.isSavingChanges = false
        }

      if(this.savingFailed === false) {
        this.unitExtraInformations[changedUnitIndex].edited = false
        this.$emit('update')
        this.isSavingChanges = false
      }
    }
  }
}
</script>
<style scoped>
.extra-info-box {
  border: 2px solid #eeeff5 !important;
  margin: 0.2em;
  color: black;
  font-size: 13px;
}
.extra-info-box >>> label {
  color: black;
}
.readonly-extra-info-box {
  border: 2px solid #eeeff5 !important;
  margin: 0.2em;
  pointer-events: none;
  color: black;
  font-size: 13px;
}
.readonly-extra-info-box >>> label {
  color: black;
}
</style>