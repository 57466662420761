<template>
  <div>
    <div
      v-if="prospect != null && loading === false"
      :class="{closed: prospect.status === 'Closed', passive: prospect.status === 'Inactive'}"
    >
      <v-overlay :value="loadingSite">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-dialog
        v-model="showAddSiteDialog"
        max-width="550"
        persistent
      >
        <v-card>
          <AddSiteDialog 
            :sites="selectableSites"
            :is-shown="showAddSiteDialog"
            :edit-site="editSite"
            :is-edit="editSite !== null"
            @close="closeAddSiteDialog"
            @add="addSite"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showInactivationDialog"
        max-width="550"
        persistent
      >
        <v-card>
          <InactivationModal
            :inactivation-reasons="inactivationReasons"
            title="prospect.passivate_prospect"
            @close="closeInactivationModal($event)"
            @passivate="closeInactivationModal($event)"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showAllocationDialog"
        width="650"
        persistent
      >
        <v-card>
          <AllocationModal
            :prospect-id="prospect.id_prospect"
            :prospect-status="prospect.status"
            :is-shown="showAllocationDialog"
            :site-ids="selectedSites.map(site => site.id_site)"
            @close="() => showAllocationDialog = false"
            @update="emitUpdate"
          />
        </v-card>
      </v-dialog>
      <!-- Delete confirmation dialog -->
      <v-dialog
        v-model="showDeleteDialog"
        max-width="550"
        persistent
      >
        <BaseModal
          @cancel="showDeleteDialog = false"
        >
          <template #title>
            {{ $t("prospect.delete_title") }}
          </template>
          <template #content>
            <p class="text-center font-weight-bold text-subtitle-1">
              {{ $t("prospect.delete_text") }}
            </p>
          </template>
          <template #footer>
            <v-btn
              rounded
              depressed
              color="error"
              @click="deleteProspect"
            >
              {{ $t("prospect.delete_confirm") }}
            </v-btn>
          </template>
        </BaseModal>
      </v-dialog>
      <!-- Activate confirmation dialog -->
      <v-dialog
        v-model="showConfirmationDialog"
        max-width="550"
        persistent
      >
        <BaseModal
          @cancel="showConfirmationDialog = false"
        >
          <template #title>
            {{ $t("prospect.activate_prospect") }}
          </template>
          <template #content>
            <p class="text-center font-weight-bold text-subtitle-1">
              {{ $t("prospect.activate_confirmation_question") }}
              <br>
              {{ $t("prospect.activate_confirmation_contract") }}
            </p>
          </template>
          <template #footer>
            <v-btn
              rounded
              depressed
              class="primary"
              @click="changeState('Active')"
            >
              {{ $t("prospect.activate") }}
            </v-btn>
          </template>
        </BaseModal>
      </v-dialog>
      <!-- History dialog -->
      <v-dialog
        v-model="showHistoryDialog"
        max-width="550"
        persistent
      >
        <BaseModal
          @cancel="showHistoryDialog = false"
        >
          <template #title>
            {{ $t("prospect.history") }}
          </template>
          <template #content>
            <p
              v-for="event in events"
              :key="event.id"
            >
              <b>{{ $t(event.event_type) }}:</b> {{ getHumanreadableDate(event.modified_date) }} {{ event.user }}
            </p>
            <!-- Since prospect event log was implemented after prospects, we need to add created and last modified data from prospect if they're missing in log -->
            <p
              v-if="events.findIndex(event => event.event_type === 'Prospekti luotu') < 0"
            >
              <b>{{ $t('Prospekti luotu') }}:</b> {{ getHumanreadableDate(prospect.created) }} 
            </p>
            <p
              v-if="events.findIndex(event => event.event_type === 'Prospekti päivitetty') < 0"
            >
              <b>{{ $t('Prospekti päivitetty') }}:</b> {{ getHumanreadableDate(prospect.last_modified) }} 
            </p>
          </template>
          <template #footer />
        </BaseModal>
      </v-dialog>
      <BaseModal
        @cancel="close"
      >
        <template #title>
          {{ prospect.prospect_description }}
          <div
            v-if="prospect.renegotiations"
            class="text-caption py-2 mr-5 ml-3 pr-5"
          >
            <v-icon
              v-if="prospect.rental_contract_validity === 'määräaikainen'"
              class="autorenew"
              color="blue"
            />
            <v-icon
              v-else-if="prospect.rental_contract_validity === 'toistaiseksi voimassaoleva'"
              class="icecream"
            />
            <span v-if="prospect.rental_contract_validity === 'määräaikainen'"> {{ $t('prospect.this_renegotiations') }} </span>
            <span v-else-if="prospect.rental_contract_validity === 'toistaiseksi voimassaoleva'"> {{ $t('prospect.this_renewal') }} </span>
          </div>
        </template>
        <template #content>
          <validation-observer 
            ref="observer" 
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              @submit.prevent="validate"
            >
              <v-row
                  
                justify="center"
              >
                <v-row align="center">
                  <v-col
                    v-if="prospect.status !== 'Closed' && prospect.status !== 'Inactive'"
                    cols="5"
                    class="mx-1"
                  >
                    <v-col class="section-textfield">
                      <h4 class="text--secondary">
                        {{ $t('prospect.success_likelihood') + ":" }}
                      </h4>
                    </v-col>
                  </v-col>
                  <v-col
                    v-if="prospect.status !== 'Closed' && prospect.status !== 'Inactive'"
                    cols="5"
                    class="mx-1"
                  >
                    <v-btn
                      :class="{'likelihood_high': prospect.success_likelihood === 'High' || prospect.success_likelihood == null,
                               'no-hover': prospect.success_likelihood === 'High'}"
                      fab
                      small
                      elevation="0"
                      @click="changeSuccessLikelihood('High')"
                    >
                      <v-icon
                        size="48px"
                      >
                        sentiment_satisfied_alt
                      </v-icon>
                    </v-btn>
                    <v-btn
                      :class="{'likelihood_intermediate': prospect.success_likelihood === 'Intermediate' || prospect.success_likelihood == null,
                               'no-hover': prospect.success_likelihood === 'Intermediate'}"
                      fab
                      small
                      elevation="0"
                      @click="changeSuccessLikelihood('Intermediate')"
                    >
                      <v-icon
                        size="48px"
                      >
                        sentiment_neutral
                      </v-icon>
                    </v-btn>
                    <v-btn
                      :class="{'likelihood_low': prospect.success_likelihood === 'Low' || prospect.success_likelihood == null,
                               'no-hover': prospect.success_likelihood === 'Low'}"
                      fab
                      small
                      elevation="0"
                      @click="changeSuccessLikelihood('Low')"
                    >
                      <v-icon
                        size="48px"
                      >
                        sentiment_very_dissatisfied
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-else
                    cols="10"
                    class="mx-1"
                  />
                  <v-col cols="1">
                    <v-btn
                      fab
                      x-small
                      class="blue-button"
                      elevation="0"
                      @click="showHistoryDialog = true"
                    >
                      <v-icon size="24px">
                        history
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="1"
                    class="mx-1"
                  >
                    <v-tooltip
                      top
                    >
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            :disabled="!hasApplicationPermissionByName('PROSPEKTI_POISTO')"
                            icon
                            x-large
                            @click="openDeleteDialog"
                          >
                            <v-icon>
                              delete
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t('prospect.delete_info') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-col
                  cols="12"
                  class="mx-1"
                >
                  <v-select
                    v-model="selectedCompletion"
                    :items="completionQuarters"
                    :label="$t('prospect.estimated_completion')"
                    item-text="label"
                    item-value="value"
                    return-object
                    class="form-fields"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="mx-1"
                >
                  <v-text-field
                    v-model="estimatedValue"
                    :label="$t('prospect.estimated_value')"
                    class="form-fields mb-2"
                    type="number"
                  >
                    <template #append>
                      <InfoBall
                        :info="$t('prospect.estimated_value_info')"
                        top
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="mx-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required|prospect_max:80"
                  >
                    <v-text-field
                      v-model="description"
                      :label="$t('prospect.company_need') + ' *'"
                      :error-messages="$t(errors)"
                      class="form-fields"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-4 px-2"
                >
                  <v-row align="center">
                    <v-col class="section-textfield">
                      <h4 class="text--secondary">
                        {{ $t('prospect.potential_business_party') + ":" }}
                      </h4>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        :disabled="typeof selectedBusinessParty != 'object' || selectedBusinessParty == null || selectedBusinessParty == undefined"
                        :loading="corporationLoading"
                        outlined
                        rounded
                        text
                        block
                        @click="openCorporationModal"
                      >
                        {{ $t('prospect.corporation_info') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="mx-1 mt-2"
                >
                  <v-combobox
                    v-model="selectedBusinessParty"
                    :items="tenantCorporations"
                    :label="$t('Tenant corporation')"
                    :loading="allBusinessParties.fetching"
                    :hint="(typeof selectedBusinessParty === 'string' && selectedBusinessParty.length > 0 )? $t('prospect.new_tenant_corporation') : ''"
                    item-text="name"
                    persistent-hint
                    @change="autoComplete"
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <validation-provider
                    ref="business_id_provider"
                    v-slot="{ errors }"
                    name="Business id"
                    mode="passive"
                  >
                    <v-text-field
                      v-model="businessId"
                      :label="(typeof selectedBusinessParty === 'string' && selectedBusinessParty.length > 0 )? $t('Business ID') + ' *' : $t('Business ID')"
                      :readonly="typeof selectedBusinessParty === 'object'"
                      :class="{extra_space: errors.length > 0}"
                      :error-messages="$t(errors)"
                      class="form-fields mx-1"
                      @change="validateBusinessId($event)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-if="selectedBusinessParty != null && typeof selectedBusinessParty === 'object'" 
                    v-model="selectedBusinessParty.liaison"
                    :label="$t('prospect.party_liaison')"
                    readonly
                    class="form-fields mx-1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="mx-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Prospect liaison"
                    rules="prospect_max:80"
                  >
                    <v-text-field
                      v-model="prospectLiaison"
                      :label="$t('prospect.prospect_liaison')"
                      :error-messages="$t(errors)"
                      class="form-fields"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="email|prospect_max:80"
                  >
                    <v-text-field
                      v-model="email"
                      :label="$t(`prospect.prospect_liaison_email`)"
                      :error-messages="errors"
                      class="form-fields mx-1"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone number"
                    rules="phoneNumber|prospect_max:80"
                  >
                    <v-text-field
                      v-model="phoneNumber"
                      :label="$t(`prospect.prospect_liaison_phone_number`)"
                      :error-messages="$t(errors)"
                      class="form-fields mx-1"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-6 px-2"
                >
                  <v-col class="section-textfield">
                    <h4 class="text--secondary">
                      {{ $t('prospect.potential_site') }}:
                    </h4>
                    <br>
                  </v-col>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  class="px-1"
                >
                  <v-row
                    align-content="start"
                    justify="start"
                    class="flex-column"
                  >
                    <v-col
                      v-for="site in selectedSites"
                      :key="site.id_site"
                      class="d-flex my-1"
                    >
                      <v-chip 
                        class="site-chip"
                        @click="editSelectedSite(site)"
                      >
                        {{ site.name }}
                        <br>
                        {{ site.units.length > 0 ? "(" + (site.units.length > 1 ? site.units.length + " " + $t("prospect.chosen_units") : "1 " + $t("Unit").toLowerCase()) + ")" : "" }}
                      </v-chip>
                      <v-spacer /> 
                      <v-icon
                        right
                        @click="deleteSite(site.id_site)"
                      >
                        delete
                      </v-icon>
                    </v-col>
                    <v-col>
                      <v-btn
                        text
                        rounded
                        class="add-site-btn blue-button"
                        @click="showAddSiteDialog = true"
                      >
                        {{ $t("Add site") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  class="px-1"
                >
                  <v-row
                    align-content="start"
                    justify="start"
                    class="flex-column"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="prospect.location_municipalities"
                      rules="required"
                      mode="eager"
                    >
                      <v-autocomplete
                        ref="city_field"
                        v-model="selectedCities"
                        :items="cities.data"
                        :label="$t('prospect.location_municipalities') + ' *'"
                        :error-messages="$t(errors)"
                        :loading="cities.fetching"
                        multiple
                        small-chips
                        item-text="municipality"
                        item-value="id"
                        return-object
                        class="multi-select-field"
                        @change="resetCitySearch"
                      >
                        <template #selection="data">
                          <v-chip
                            :key="JSON.stringify(data.id)"
                            class="v-chip--select-multi"
                            close
                            @click.stop
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item.municipality }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Area"
                      rules="decimal|required"
                    >
                      <v-text-field
                        v-model="area"
                        :label="$t('prospect.area_requirement') + ' *'"
                        :error-messages="$t(errors)"
                        class="form-fields"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Unit usage"
                      rules="required"
                    >
                      <v-autocomplete
                        ref="usage_field"
                        v-model="selectedUnitUsages"
                        :items="unitUsages"
                        :label="$t('Usage types') + ' *'"
                        :error-messages="$t(errors)"
                        multiple
                        small-chips
                        item-text="label"
                        item-value="id"
                        return-object
                        class="multi-select-field form-fields"
                        @change="resetUsageSearch"
                      >
                        <template #selection="data">
                          <v-chip
                            :key="JSON.stringify(data.id)"
                            class="v-chip--select-multi"
                            close
                            @click.stop
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item.label }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="pa-2"
                >
                  <v-col class="section-textfield">
                    <h4 class="text--secondary">
                      {{ $t('Comments') + ":" }}
                    </h4>
                    <br>
                  </v-col>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  class="px-1"
                >
                  <v-row
                    align-content="start"
                    justify="start"
                    class="flex-column"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Prospect responsible"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="prospectResponsible"
                        :label="$t('prospect.prospect_responsible') + ' *'"
                        :items="leasingUsers.filter(user => user != secondaryProspectResponsible)"
                        :error-messages="$t(errors)"
                        item-text="name"
                        item-value="id_user"
                        return-object
                        append-icon=""
                      />
                    </validation-provider>
                    <v-autocomplete
                      v-model="secondaryProspectResponsible"
                      :label="$t('prospect.secondary_responsible')"
                      :items="leasingUsers.filter(user => user != prospectResponsible)"
                      item-text="name"
                      item-value="id_user"
                      return-object
                      append-icon=""
                      clearable
                      class="form-fields"
                    />
                    <v-select
                      v-model="selectedLeadOrigin"
                      :items="leadOrigins"
                      :label="$t('prospect.lead_origin') + ' *'"
                      return-object
                      item-text="label"
                      item-value="id"
                      class="form-fields mb-2"
                    >
                      <template #selection="{ item }">
                        {{ $t(`${item.label}`) }}
                      </template>
                      <template #item="{item}">
                        {{ $t(`${item.label}`) }}
                      </template>
                    </v-select>
                    <v-textarea
                      v-if="selectedLeadOrigin && selectedLeadOrigin.label === 'Muu liidi'"
                      v-model="leadComments"
                      :label="$t('prospect.lead_comments')"
                      rows="2"
                      auto-grow
                      class="mb-0"
                    />
                    <validation-provider
                      v-if="selectedLeadOrigin && selectedLeadOrigin.label === 'Liidi välittäjältä'"
                      v-slot="{ errors }"
                      name="prospect.lead_mediator"
                      rules="required"
                    >
                      <v-combobox
                        v-if="selectedLeadOrigin && selectedLeadOrigin.label === 'Liidi välittäjältä'"
                        v-model="leadMediator"
                        :items="mediatorBusinesses"
                        :label="$t('Välittäjäyritys') + ' *'"
                        :hint="mediatorHintText"
                        :error-messages="$t(errors)"
                        :class="{extra_space: mediatorHintText.length > 0}"
                        item-text="name"
                        class="form-fields"
                        persistent-hint
                        clearable
                        @change="autoCompleteLeadMediator"
                      />
                    </validation-provider>
                    <validation-provider
                      v-if="selectedLeadOrigin && selectedLeadOrigin.label === 'Liidi välittäjältä'"
                      ref="mediator_business_id_provider"
                      v-slot="{ errors }"
                      :rules="{required: (typeof leadMediator === 'string' && leadMediator.length > 0 )}"
                      name="Mediator business id"
                    >
                      <v-text-field
                        v-model="leadMediatorBusinessId"
                        :label="$t('prospect.lead_mediator_business_id') + ' *'"
                        :readonly="typeof leadMediator === 'object' && leadMediator != null"
                        :error-messages="$t(errors)"
                        class="form-fields"
                        @change="validateMediatorBusinessId($event)"
                      />
                    </validation-provider>
                  </v-row>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  class="px-1"
                >
                  <v-textarea
                    v-model="comments"
                    :label="$t('Comments')"
                    rows="6"
                    auto-grow
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
                justify="end"
              >
                <h3
                  v-if="showUpdatedMessage"
                  class="success-text"
                >
                  {{ $t('prospect.updated') }}
                </h3>
                <v-btn
                  :disabled="invalid || !dataChanged"
                  :loading="saving"
                  type="submit"
                  rounded
                  depressed
                  color="primary"
                  @click="validate"
                >
                  {{ $t('Save') }}
                </v-btn>
              </v-row>
              <br>
            </v-form>
          </validation-observer>
          <v-row>
            <v-col
              v-for="widget in widgets"
              :key="widget.id"
              class="widget"
            >
              <DynamicWidget
                :type="widget.type"
                :title="$t(widget.id)"
                :data="widget.data"
                :loading="widget.loading"
                @changePproperty="updateProspects"
              />
            </v-col>
          </v-row>
          <v-expansion-panels
            v-model="contractExpand"
            class="my-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="py-1 pl-2">
                {{ $t('site.rental_contracts') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="!allSitesRentalStatusesLoading && !futureRentalStatusesLoading && !pastRentalStatusesLoading">
                <ContractDataForm
                  :data="getWidgetContracts()"
                  :metadata="getContractMetadataForUnit()"
                  :site-title="true"
                /> 
              </v-expansion-panel-content>
              <div
                v-else
                style="text-align: center; margin: 1em"
              >
                <v-progress-circular
                  :size="50"
                  style="margin: auto"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
          <Notes
            :single-process="prospect"
          />
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-col
            cols="1"
            class="px-1"
          >
            <v-icon
              v-if="prospect.status === 'Inactive'"
              color="error"
            >
              error
            </v-icon>
            <v-icon
              v-if="prospect.status === 'Closed'"
              color="success"
            >
              emoji_events
            </v-icon>
          </v-col>
          <v-col
            v-if="prospect.status === 'Inactive'"
            class="pl-0 pr-1 text-subtitle-2 error--text"
          >
            {{ $t('prospect.this_inactive') }}
            <br>
            {{ $t('Reason') }}: {{ $t(inactivationReasonString) }}
          </v-col>
          <v-col
            v-else-if="prospect.status === 'Closed' && prospect.ignore"
            cols="2"
            class="pl-0 pr-1 text-subtitle-2 success--text"
          >
            {{ $t('Done') }} ({{ $t('prospect.no_report') }})
          </v-col>
          <v-col
            v-else-if="prospect.status === 'Closed'"
            cols="2"
            class="pl-0 pr-1 text-subtitle-2 success--text"
          >
            {{ $t("prospect.activate_confirmation_contract") }}
          </v-col>
          <v-col
            v-else-if="prospect.status === 'AwaitingAllocation'"
            cols="2"
            class="pl-0 pr-1 text-subtitle-2 success--text"
          >
            {{ $t("prospect.awaiting_allocation") }}
          </v-col>
          <v-col
            cols="3"
            class="px-2"
          >
            <v-btn
              outlined
              rounded
              text
              block
              @click="close"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="px-2"
          >
            <v-btn
              v-if="prospect.status === 'AwaitingAllocation'"
              :loading="closing"
              rounded
              depressed
              block
              color="success"
              @click="() => showAllocationDialog = true"
            >
              {{ $t('Allocate') }}
            </v-btn>
            <v-btn
              v-else
              :disabled="prospect.status === 'Closed' || prospect.status === 'Inactive'"
              :loading="closing"
              rounded
              depressed
              block
              color="primary"
              @click="() => showAllocationDialog = true"
            >
              <v-icon>emoji_events</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="px-2"
          >
            <v-btn
              v-if="prospect.status === 'Active'"
              :loading="inactivating"
              rounded
              depressed
              block
              color="error"
              @click="showInactivationDialog = true"
            >
              {{ $t("prospect.passivate") }}
            </v-btn>
            <v-btn
              v-else
              :loading="activating"
              rounded
              depressed
              block
              class="blue-button"
              @click="activateProspect"
            >
              {{ $t('prospect.activate') }}
            </v-btn>
          </v-col>
        </template>
      </BaseModal>
    </div>
    <!-- Empty modal card to make closing smoother -->
    <div v-else>
      <v-card>
        <v-btn
          large
          icon
          right
          absolute
          style="margin-top: 0.8em"
          @click="close"
        >
          <v-icon>close</v-icon>
          <span class="d-sr-only">{{ $t('Close') }}</span>
        </v-btn>
        <v-card-title 
          class="text-h5 grey lighten-2 py-8" 
          primary-title
        />
        <v-card-text class="empty-div">
          <v-progress-circular
            :size="50"
            style="margin: auto"
            color="primary"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { max } from "vee-validate/dist/rules"
import AddSiteDialog from './AddSiteDialog'
import InactivationModal from './InactivationModal'
import Notes from './Notes'
import AllocationModal from './AllocationModal'
import { i18n } from '../../plugins/i18n'
import DynamicWidget from '../DynamicWidget'
import ContractDataForm from './ContractDataForm'
import humanize from '../../helpers/humanize'
import BaseModal from '../general/BaseModal'
import InfoBall from '../InfoBall.vue'
import moment from 'moment'

extend ('phoneNumber', {
    message: 'prospect.phone_number_is_not_valid',
    validate (value, args) {
        const mobilereg = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/);
        return mobilereg.test(value); 
}})

extend("decimal", {
  validate: (value, { decimals = '*' } = {}) => {
    const separator = i18n.locale === 'fi' ? ',' : '.'
    if (value === null || value === undefined || value === '' || value.length > 10) {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'prospect.area_must_be_a_number'
})


extend('prospect_max', {
   message: 'prospect.input_max_length',
  ...max,
})


export default {
  name: "ProspectModal",
  components: { AddSiteDialog, ValidationObserver, ValidationProvider, InactivationModal, Notes, AllocationModal, DynamicWidget, ContractDataForm, BaseModal, InfoBall },
  props: { 
    prospect: {type: Object, default: null},
    isShown: {type: Boolean, default: false},
    userInfo: {type: Object, default: null}
  },
  data () {
    return {
      loading: false,
      allBusinessParties: {
        data: [],
        fetching: false
      },
      selectedBusinessParty: null,
      showAddSiteDialog: false,
      cities: {
        data: [],
        fetching: false
      },
      description: "",
      prospectLiaison: "",
      phoneNumber: "",
      email: "",
      selectedCities: [],
      area: "",
      selectedUnitUsages: [],
      prospectResponsible: null,
      secondaryProspectResponsible: null,
      selectedLeadOrigin: null,
      leadMediator: "",
      leadComments: "",
      leadMediatorBusinessId: "",
      comments: "",
      selectedSites: [],
      editSite: null,
      loadingSite: false,
      showUpdatedMessage: false,
      saving: false,
      closing: false,
      activating: false,
      inactivating: false,
      showConfirmationDialog: false,
      showInactivationDialog: false,
      businessId: "",
      showAllocationDialog: false,
      showDeleteDialog: false,
      contractExpand: 0,
      showHistoryDialog: false,
      events: [],
      selectedCompletion: null,
      estimatedValue: null,
      widgets: [
        {
          id: 'prospect.site_status',
          type: 'ObjectTable',
          data: {
            headers: [
              {  text: 'Site',  value: 'siteName' },
              { text: 'site.units', value: 'units',},
              { text: 'leasing.rentable_area', value: 'totalArea', format: 'Area' },
              { text: 'leasing.status', value: 'status' },
              { text: 'Passed', value: 'units_passed' }
            ],
            browseHeaders: [
              {  text: 'Site',  value: 'siteName' },
              { text: 'leasing.rentable_area', value: 'totalArea', format: 'Area' },
              { text: 'site.units', value: 'units',},
              { text: 'leasing.status', value: 'status' },
            ],
            footers: [{ text: i18n.t('Total')}, {}, {value: 'totalArea', format: 'Area' }, {}, {}],
            items: [],
          },
          small: false,
          loading: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName']),
    ...mapGetters('leasing', ['leasingUsers', 'prospectRentalStatus']),
    ...mapState('app', ['availableSites', 'currentDate']),
    ...mapState('leasing', ['corporationLoading', 'municipalities', 'allSitesRentalStatuses', 'allSitesRentalStatusesLoading', 'futureRentalStatuses', 'futureRentalStatusesLoading', 'pastRentalStatusesLoading']),
    unitUsages () {
      return this.definitionsByGroupLabel('unit.usage_types')
    },
    leadOrigins () {
      return this.definitionsByGroupLabel('prospect.leadorigin')
    },
    abandonedReasons () {
      return this.definitionsByGroupLabel('prospect.abandonedreason')
    },
    // Attribute that tells us whether form data has changed and Save-button should be available
    // TODO Find out if there's easier way to do this.
    // (Possibly activate button on any change and not check if value changes back.)
    dataChanged () {
      if (!this.isShown) {
        return false
      }

      const simpleFieldsSame =  this.description === this.prospect.prospect_description 
        && this.prospectLiaison === this.prospect.contact_person 
        && this.phoneNumber === this.prospect.contact_tel
        && this.email === this.prospect.contact_email 
        && this.comments === this.prospect.information 
        && this.estimatedValue === this.prospect.estimated_value

      if (!simpleFieldsSame) {
        return true
      }

      if (this.area.toString().replace(',','.') !== this.prospect.unit_area_preference.toString()) {
        return true
      }

      if (this.prospect.cities.length !== this.selectedCities.length) {
        return true
      }

      if (!this.prospect.cities.every(city => this.selectedCities.map(item => item.id).includes(city))) {
        return true
      }

      if (this.prospect.unit_type_preference.length !== this.selectedUnitUsages.length) {
        return true
      }

      if (!this.prospect.unit_type_preference.every(usage => this.selectedUnitUsages.map(item => item.id).includes(usage))) {
        return true
      }
      if (this.prospectResponsible != null) {
        if (this.prospectResponsible.id_user !== this.prospect.owner) {
          return true
        }
      }
      if (this.secondaryProspectResponsible != null) {
        if (this.secondaryProspectResponsible.id_user !== this.prospect.secondary_owner) {
          return true
       }
      }

      if (this.secondaryProspectResponsible == null && this.prospect.secondary_owner != null) {
        if (this.leasingUsers.find(user => user.id_user === this.prospect.secondary_owner) != null) {
          return true
        }
      }

      if (this.selectedLeadOrigin.id !== this.prospect.lead_origin) {
        return true
      }

      if (this.selectedLeadOrigin.label === 'Liidi välittäjältä') {
        if (typeof this.leadMediator !== 'object') {
          return true
        }
        else if (this.leadMediator == null) {
          return true
        }
        else if (this.leadMediator.id !== this.prospect.lead_mediator) {
          return true
        }
      }
      else if (this.selectedLeadOrigin.label === 'Muu liidi') {
        if (this.leadComments !== this.prospect.lead_information) {
          return true
        }
      }

      // Sites changed
      if (this.selectedSites.length !== this.prospect.sites.length) {
        return true
      }

      for (let i = 0; i < this.selectedSites.length; i++) {
        const site = this.selectedSites[i]
        const siteIndex = this.prospect.sites.findIndex(item => item.id_site === site.id_site)
        if (siteIndex < 0) {
          return true
        }
        else {
          if (site.units.length !== this.prospect.sites[siteIndex].units.length) {
            return true
          }
          if (!site.units.every(unit => this.prospect.sites[siteIndex].units.map(item => item.id_unit).includes(unit.id_unit))) {
            return true
          }
        }
      }

      // Another or new party
      if (this.selectedBusinessParty != null) {
        if (typeof this.selectedBusinessParty === 'object' ) {
            if (this.selectedBusinessParty.id !== this.prospect.id_osapuoli) {
            return true
          }  
        }
        // If new party is typed in return true
        else if  (typeof this.selectedBusinessParty === 'string') {
          if (this.selectedBusinessParty.length !== 0) {
            return true
          }
          // Also if party string is empty and prospect has a party, the user wants to set party as null
          else if (this.prospect.id_osapuoli != null){
            return true
          }
        }
      }

      if (this.selectedCompletion !== null) {
        const prospectEstimation = moment(this.prospect.estimated_completion).endOf('quarter').add(-1, 'days')
        const selectedEstimation = moment(this.selectedCompletion?.value)
        if (!prospectEstimation.isSame(selectedEstimation))  {
          return true
        }
      }


      return false
    },
    selectableSites () {
      if (this.selectedSites.length !== 0 )  {
        const selectedIds = this.selectedSites.map(item => item.id_site)
        let returnedSites =  this.availableSites.filter(site => !selectedIds.includes(site.id_site))
        if (this.editSite) {
            returnedSites.unshift(this.editSite.site)
        }
        return returnedSites
      }
      else {
        return this.availableSites
      }
    },
    inactivationReasonString () {
      if (this.prospect.status === 'Inactive') {
        return this.definitionsByGroupLabel('prospect.abandonedreason').find(item => item.id === this.prospect.status_description).label
      }
      else {
        return null
      }
    },
    mediatorBusinesses () {
      return this.allBusinessParties.data.filter(party => party.mediator_company === true)
    },
    tenantCorporations () {
      return this.allBusinessParties.data.filter(party => party.tenant_corporation === true)
    },
    mediatorHintText: function () {
      if (this.leadMediator == null) {
        return ''
      }
      else if (typeof this.leadMediator === 'string' && this.leadMediator.length > 0 ) {
        return this.$t('prospect.new_mediator')
      }
      else if (typeof this.leadMediator === 'object' && this.leadMediator.mediator_company === false ) {
        return this.$t('prospect.changing_to_mediator')
      }
      return ''
    },
    completionQuarters () {
      const startYear = new Date(this.currentDate).getFullYear();
      const quarters =  new Array(8).fill(1).map((item, index) => {
        const year = startYear + Math.floor(index/4)
        const time = moment();
        time.set('quarter', index+1)
        time.set('year', year)
        // To get date within quarter we take start of it and add extra day to avoid any time zone issues
        time.endOf('quarter').add(-1, 'days')

        return {
          label: `Q${index%4 + 1} ${year}`,
          value: new Date(time)
        }
      })
      // If currently selected quarters is not within selectable quarters, add it to the list
      if (this.selectedCompletion && !quarters.some(quarter => moment(this.selectedCompletion.value).isSame(moment(quarter.value))))
      {
        quarters.unshift(this.selectedCompletion)
      }
      return quarters

    },
    inactivationReasons () {
      return this.definitionsByGroupLabel('prospect.abandonedreason')
    }
  },
  watch: {
    isShown: async function (shown) {
      if (shown) {
        this.loading = true
        this.getCities()
        if (this.availableSites.length === 0) {
          await this.getSites()
        }
        await this.setProspectData()
        this.loadWidgetData()
        this.loading = false
      }
      else {
        this.reset()
      }
    },
     prospect: {
       deep: true,
       async handler (newValue) {
         if (this.isShown) {
           this.loadWidgetData()
           this.events = await this.getProspectEvents({prospectId: newValue.id_prospect})
         } 
         
       }
     },
    //  After loading validate fields
     loading (newValue) {
       if (!newValue) {
         this.$nextTick(() => {
            this.$refs?.observer?.validate()
          })
       }
     }
  },
  async mounted () {
    this.loading = true
    this.getCities()
    if (this.availableSites.length === 0) {
      await this.getSites()
    }
    await this.setProspectData()
    this.events = await this.getProspectEvents({prospectId: this.prospect.id_prospect})
    this.loadWidgetData()
    this.loading = false
  },
  // We need to make sure prospect modal state is set to null when navigating from the modal to somewhere else on site
  beforeDestroy () {
    this.changeProspectModalState(null)
  },
  methods: {
    ...mapActions('leasing', [
      'changeProspectModalState', 
      'getLeasingUsers', 
      'updateProspect', 
      'changeProspectStatus', 
      'getProspects', 
      'getCorporationExtraById', 
      'changeProspectSuccessLikelihood',
      'removeProspect',
      'getProspectEvents'
    ]),
    ...mapActions('app', ['getSites']),
    close () {
      if (this.dataChanged && this.loading === false) {
        if (confirm(this.$t('leasing.offer_close_confirmation'))) {
          this.changeProspectModalState(null)
        }
      }
      else {
        this.changeProspectModalState(null)
      }
    },
    reset () {
      this.$refs?.observer?.reset()
      Object.assign(this.$data, this.$options.data());
    },
    async setProspectData () {
      await this.getBusinessParty()
      this.description = this.prospect.prospect_description
      this.prospectLiaison = this.prospect.contact_person
      this.phoneNumber = this.prospect.contact_tel
      this.email = this.prospect.contact_email
      this.comments = this.prospect.information
      this.area = this.$i18n.locale === 'fi' ? this.prospect.unit_area_preference.toString().replace('.', ',') : this.prospect.unit_area_preference
      this.leadComments = this.prospect.lead_information

      this.selectedCities = this.cities.data.filter(city => this.prospect.cities.includes(city.id))

      this.selectedUnitUsages = this.unitUsages.filter(usage => this.prospect.unit_type_preference.includes(usage.id))

      this.prospectResponsible = this.leasingUsers.find(user => user.id_user === this.prospect.owner)
      
      if (this.prospect.secondary_owner) {
        const foundSecondaryUser = this.leasingUsers.find(user => user.id_user === this.prospect.secondary_owner)
        this.secondaryProspectResponsible = foundSecondaryUser
      }

      this.selectedLeadOrigin = this.leadOrigins.find(origin => origin.id === this.prospect.lead_origin)
      if (this.selectedLeadOrigin.label === 'Liidi välittäjältä') {
        this.leadMediator = this.allBusinessParties.data.find(party => party.id === this.prospect.lead_mediator)
        this.leadMediatorBusinessId = this.leadMediator.business_id
      }
      this.leadComments = this.prospect.lead_information != null ? this.prospect.lead_information : ''

      try {
            this.selectedSites = this.prospect.sites.map(item => {
          item.name = this.availableSites.find(site => site.id_site == item.id_site).name
          return item
          }
        )
      }
      catch (error) {
        alert(this.$t('leasing.missing_site'))
        this.close()
      }

      this.estimatedValue = this.prospect.estimated_value
      if (this.prospect.estimated_completion)  {
        const time = moment(this.prospect.estimated_completion).endOf('quarter').add(-1, 'days')
        const selectedCompletion = { label: `Q${time.get('quarter')} ${time.get('year')}`, value: new Date(time)}
        this.selectedCompletion = selectedCompletion
      }
    },
    async getCities () {
      this.cities.fetching = true
      const data = this.municipalities
      this.cities.data = data.sort((prev, next) => prev.municipality.localeCompare(next.municipality))
      this.cities.fetching = false
    },
    async getBusinessParty () {
      this.allBusinessParties.fetching = true
      const data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true } })
      this.allBusinessParties.data = data
      const foundParty = data.find(party => party.id === this.prospect.id_osapuoli)
      this.selectedBusinessParty = foundParty
      if (foundParty) {
        this.businessId = foundParty.business_id
      }
      this.allBusinessParties.fetching = false
    },
    async validate (event) {
      event.preventDefault()
      this.validateBusinessId(this.businessId)
      this.validateMediatorBusinessId(this.leadMediatorBusinessId)
      if (this.$refs.observer.validate()) {
        this.saving = true

        // Check if trying to create party and mediator with same business ids
        if (typeof this.selectedBusinessParty === 'string' && this.selectedLeadOrigin.label === 'Liidi välittäjältä' && typeof this.leadMediator === 'string' && this.businessId.trim() === this.leadMediatorBusinessId.trim())
        {
          alert(this.$t('prospect.same_business_ids'))
          this.saving = false
          return
        }

        // If lead from mediator, create new mediator if necessary
        let mediatorId = null
        let newTenantCorporation = null
        let newMediator = null
        if (this.selectedLeadOrigin.label === 'Liidi välittäjältä') {
          if (this.leadMediator != null && typeof this.leadMediator === 'object') {
            mediatorId = this.leadMediator.id
          }
          else if (typeof this.leadMediator === 'string' && this.leadMediator.length > 0) {
            newMediator = this.createNewParty(this.leadMediator, this.leadMediatorBusinessId, false, true)
            if (newMediator == null) {
              this.saving = false
              return
            }
          }
          else {
            alert(this.$t('prospect.mediator_missing'))
            this.saving = false
            return
          }
        }

        // Create new party if required
        let idOsapuoli = null
        if (this.selectedBusinessParty != null && typeof this.selectedBusinessParty === 'object') {
          idOsapuoli = this.selectedBusinessParty.id
        }
        else if (typeof this.selectedBusinessParty === 'string') {
          if (this.selectedBusinessParty.length !== 0) {
            newTenantCorporation = this.createNewParty(this.selectedBusinessParty, this.businessId, true, false)
            if (newTenantCorporation == null) {
              this.saving = false
              return
            }
          }
          else {
            idOsapuoli = null
          }
        }

        const prospect = this.createNewProspect(idOsapuoli, mediatorId, newTenantCorporation, newMediator)
        this.updateProspect(prospect).then(res => {
          if (res.id_prospect) {
              this.showUpdatedMessage = true
              setTimeout(() => {
                this.showUpdatedMessage = false
              }, 5000)
              this.setProspectData()
              this.loadWidgetData()
              this.$emit('update')
            }
            else {
              alert(this.$t("error.unexpected"))
            }
          }
        ).finally(() => this.saving = false)

        
      }
    },
    autoComplete (value) {
      if (value && typeof value === 'object') {
        this.businessId = value.business_id
      }
      else {
        this.businessId = "";
      }
      this.$refs.business_id_provider.reset()
    },
    autoCompleteLeadMediator (value) {
      if (value == null) {
        this.leadMediatorBusinessId = ''
      }
      else if (typeof value === 'object') {
        this.leadMediatorBusinessId = value.business_id
      }
      else if (value) {
        this.leadMediatorBusinessId = ''
      }
      this.$refs.mediator_business_id_provider.reset()
    },
    async editSelectedSite (site) {
      this.loadingSite = true
      const selectedSite = this.availableSites.find(item => item.id_site === site.id_site)
      let selectedBuilding = null
      const selectedFloors = []
      let selectedUnits = []

      // Units currently don't have knowledge of their buildings or floors so we have to fetch and find that info.
      // TODO Make either AddSiteDialog more efficient or change backend so that prospects or units know their floors and buildings.
      if (site.units.length > 0) {
        this.loadingSite = true

        const buildings = await this.$rambollfmapi.buildings.list({ query: { siteId: site.id_site } })

        const buildingPromises = buildings.map(async building => {
          const { building_code: buildingCode } = building

          const floors = await this.$rambollfmapi.buildings.floors(buildingCode).list()

          const floorPromises = floors.map(async floor => {
            const { id: floorId } = floor

            const units = await this.$rambollfmapi.floors.units(floorId).list()

            const foundUnits = units.filter(item => site.units.findIndex(unit => unit.id_unit === item.id) !== -1)

            if (foundUnits.length > 0) {
              selectedBuilding = building
              selectedFloors.push(floor)
              selectedUnits = selectedUnits.concat(foundUnits)
            }
          })

          await Promise.all(floorPromises)
        })

        await Promise.all(buildingPromises)
      }

      this.editSite = {
        site: selectedSite,
        building: selectedBuilding,
        floors: selectedFloors,
        units: selectedUnits
      }

      this.showAddSiteDialog = true
      this.loadingSite = false
    },
    closeAddSiteDialog () {
      this.editSite = null
      this.showAddSiteDialog = false
    },
    addSite (newSite, isEdit, oldSiteIndex = -1) {
      if (newSite && newSite.site) {
        const editedUnits = newSite.units.map(unit => ({
            id_unit: unit.id
          }))

        const newSiteEdited = {
          id_site: newSite.site.id_site,
          name: newSite.site.name,
          units: editedUnits
        }

        // Also add site city to list of cities
        if (!this.selectedCities.map(item => item.id).includes(newSite.site.municipality)) {
          this.selectedCities = this.selectedCities.concat(this.cities.data.filter(city => city.id === newSite.site.municipality))
        }

        if (isEdit) {
          const index = this.selectedSites.findIndex(item => item.id_site === oldSiteIndex)
          this.selectedSites.splice(index, 1, newSiteEdited);
        }
        else {
          this.selectedSites.push(newSiteEdited)
        }
      }
      this.editSite = null
      this.showAddSiteDialog = false
    },
    deleteSite (siteId) {
      const index = this.selectedSites.findIndex(item => item.id_site === siteId)
      this.selectedSites.splice(index, 1);
    },
    createNewProspect (tenantId, mediatorId, newTenantCorporation, newMediator) {
      const areaCleaned = this.area.toString().replace(',', '.')
      const areaPreference = (Math.floor(Number(areaCleaned)*100))/100

      const prospectSites = this.selectedSites.map(site => {
        let siteUnits = []
        siteUnits = site.units.map(unit => { 
          return {id_unit: unit.id_unit }
        })

        return {
          id_site: site.id_site,
          units: siteUnits
        }
      })

      const cities = this.selectedCities.map(city => city.id)
      const unitUsages = this.selectedUnitUsages.map(usage => usage.id)

      let leadInfo = ""
      if (this.selectedLeadOrigin.label === 'Muu liidi') {
        leadInfo = this.leadComments.trim()
      }

            
      let tenantCorporationName = null
      let tenantCorporationBusinessId = null

      if (newTenantCorporation) {
        tenantCorporationName = newTenantCorporation.name
        tenantCorporationBusinessId = newTenantCorporation.business_id
      }

      let mediatorName = null
      let mediatorBusinessId = null

      if (newMediator) {
        mediatorName = newMediator.name
        mediatorBusinessId = newMediator.business_id
      }

      const prospect = {
        id_prospect: this.prospect.id_prospect,
        prospect_description: this.description,
        id_osapuoli: tenantId,
        contact_person: this.prospectLiaison,
        contact_tel: this.phoneNumber,
        contact_email: this.email,
        unit_area_preference: areaPreference,
        information: this.comments,
        real_estate_development: this.realEstateDevelopment,
        unit_type_preference: unitUsages,
        sites: prospectSites,
        cities: cities,
        lead_origin: this.selectedLeadOrigin.id,
        lead_information: leadInfo,
        lead_mediator: mediatorId,
        owner: this.prospectResponsible ? this.prospectResponsible.id_user : this.userInfo.id,
        secondary_owner: this.secondaryProspectResponsible ? this.secondaryProspectResponsible.id_user : null,
        osapuoli_business_id: tenantCorporationBusinessId,
        osapuoli_name: tenantCorporationName,
        mediator_business_id: mediatorBusinessId,
        mediator_name: mediatorName,
        estimated_completion: this.selectedCompletion?.value,
        estimated_value: this.estimatedValue
      }

      return prospect
    },
    async changeState (newState, reason) {
      if (newState === 'Active') {
        this.activating = true
        this.showConfirmationDialog = false
        await this.changeProspectStatus({prospectId: this.prospect.id_prospect, data: {state: 0, reason: null}})
        this.activating = false
      }
      else if (newState === 'Inactive') {
        this.inactivating = true
        await this.changeProspectStatus({prospectId: this.prospect.id_prospect, data: {state: 1, reason: reason}})
        this.inactivating = false
      }
      else if (newState === 'Closed') {
        this.closing = true
        await this.changeProspectStatus({prospectId: this.prospect.id_prospect, data: {state: 2, reason: null}})
        this.closing = false
      }
      this.$emit('update')
    },
    activateProspect () {
      if (this.prospect.status === 'Inactive') {
        this.changeState('Active')
        this.$emit('update')
      }
      else {
        this.showConfirmationDialog = true
      }
    },
    closeInactivationModal (reason) {
      if (reason) {
        this.showInactivationDialog = false
        this.changeState('Inactive', reason)
      }
      else {
        this.showInactivationDialog = false
      }
    },
    getDefaultMetadata (obj) {
      return Object.keys(obj).reduce((obj, key) => {
        obj[key] = {
          category: 'None',
          isShown: 'true',
          type: 'String'
        }
        if (key === 'key_account_customer' || key === 'customership_secret') {
          obj[key].type = 'Boolean'
        }
        return obj
      }, {})
    },
    resetCitySearch () {
      this.$refs.city_field.lazySearch = ''
    },
    resetUsageSearch () {
      this.$refs.usage_field.lazySearch = ''
    },
    createNewParty (partyName, partyBusinessId, tenant = false, mediator = false) {
      if (partyBusinessId.length === 0) {
        alert(this.$t("prospect.check_business_id"))
        return null
      }
      else if (this.allBusinessParties.data.findIndex(item => item.business_id === partyBusinessId.trim()) !== -1) {
        alert(this.$t("prospect.business_id_exists"))
        return null
      }
      else if (partyBusinessId.length !== 0 && partyName.length !== 0) {
        const newParty =  {
          name: partyName,
          business_id: partyBusinessId.trim(),
          tenant_corporation: tenant,
          mediator_company: mediator,
        }
        return newParty
      }
    },
    validateBusinessId (value) {
      let message = ''
      if (value.length > 0) {
        const foundParty = this.allBusinessParties.data.find(party => party.business_id === value.trim())
        if (foundParty) {
          if (foundParty.tenant_corporation) {
            message = this.$t('prospect.business_id_exists_tenant') + ' ' + foundParty.name
          }
          else {
            message = this.$t('prospect.business_id_exists_not_tenant') + ' ' + foundParty.name
          }
          this.$refs.business_id_provider.applyResult({
            errors: [message],
            valid: false, 
            failedRules: {} 
          });
        }
        else {
          this.$refs.business_id_provider.applyResult({
            errors: [], 
            valid: true,
            failedRules: {} 
          });
        }
      }
    },
    validateMediatorBusinessId (value) {
      if (value.length > 0) {
        const foundParty = this.allBusinessParties.data.find(party => party.business_id === value.trim())
        if (foundParty != null) {
          this.leadMediator = foundParty
        }
      }
    },

    loadWidgetData () {
      if (this.isShown) {
        this.widgets.forEach(widget => {
          switch(widget.id) {
          case 'prospect.site_status':
            this.loadSiteStatusData()
            break;
          default:
            break;
          }
        })
      }
    },

    async loadSiteStatusData () {
      const widget = this.widgets.find(widget => widget.id === 'prospect.site_status')
      widget.loading = true
      let data = []
      let prospectStatus = null

      // If prospect status is awaiting allocation or closed, same status is set for all unit combos
      if (this.prospect.status === 'AwaitingAllocation' || this.prospect.status === 'Closed') {
        prospectStatus = this.$t(this.prospect.status)
      }

      this.prospect.sites.filter(site => site.units.length > 0).forEach(site => {
          
        // First filter out units with annex printed
        const annexPrintedUnits = site.units.filter(unit => unit.annex != null)
        if (annexPrintedUnits.length > 0) {
          const printoutIds = new Set(annexPrintedUnits.map(item => item.annex))
          printoutIds.forEach(id => {
            const idAnnexPrintedUnits = annexPrintedUnits.filter(item => item.annex === id)

            data.push({
              id_site: site.id_site,
              siteName: this.availableSites.find(item => item.id_site === site.id_site).name,
              units: idAnnexPrintedUnits.reduce((acc, curr) => acc + curr.unitCode + ', ', '').slice(0, -2),
              unitIds: idAnnexPrintedUnits.map(item => item.idProspectUnit),
              totalArea: idAnnexPrintedUnits.reduce((acc, curr) => acc + curr.area, 0),
              status: prospectStatus ? prospectStatus : this.$t('prospect.annex_printed'),
              passedReason: idAnnexPrintedUnits[0].passedReason != null ? this.definitionsByGroupLabel('prospect.abandonedreason').find(item => item.id === idAnnexPrintedUnits[0].passedReason).label : null
            })
          })
        }
      
        // Second filter out units with offer draft made
        const offerDraftUnits = site.units.filter(unit => !annexPrintedUnits.map(item => item.id_unit).includes(unit.id_unit))
        .filter(unit => unit.offer != null)
        if (offerDraftUnits.length > 0) {
          const printoutIds = new Set(offerDraftUnits.map(item => item.offer))
          printoutIds.forEach(id => {
            const idOfferDraftUnits = offerDraftUnits.filter(item => item.offer === id)

            data.push({
              id_site: site.id_site,
              siteName: this.availableSites.find(item => item.id_site === site.id_site).name,
              units: idOfferDraftUnits.reduce((acc, curr) => acc + curr.unitCode + ', ', '').slice(0, -2),
              unitIds: idOfferDraftUnits.map(item => item.idProspectUnit),
              totalArea: idOfferDraftUnits.reduce((acc, curr) => acc + curr.area, 0),
              status: prospectStatus ? prospectStatus : this.$t('prospect.offer_draft'),
              passedReason: idOfferDraftUnits[0].passedReason != null ? this.definitionsByGroupLabel('prospect.abandonedreason').find(item => item.id === idOfferDraftUnits[0].passedReason).label : null
            })
          })
        }

        // Lastly units with no operations done to them
        const noOperationsUnits = site.units.filter(unit => !annexPrintedUnits.map(item => item.id_unit).includes(unit.id_unit))
          .filter(unit => !offerDraftUnits.map(item => item.id_unit).includes(unit.id_unit))
        if (noOperationsUnits.length > 0) {
          data.push({
              id_site: site.id_site,
              siteName: this.availableSites.find(item => item.id_site === site.id_site).name,
              units: noOperationsUnits.reduce((acc, curr) => acc + curr.unitCode + ', ', '').slice(0, -2),
              unitIds: noOperationsUnits.map(item => item.idProspectUnit),
              totalArea: noOperationsUnits.reduce((acc, curr) => acc + curr.area, 0),
              status: prospectStatus ? prospectStatus : this.$t('prospect.awaiting_action'),
              passedReason: noOperationsUnits[0].passedReason != null ? this.definitionsByGroupLabel('prospect.abandonedreason').find(item => item.id === noOperationsUnits[0].passedReason).label : null
          })
        }
      })

      widget.loading = false
      widget.data.items = data
    },
    getWidgetContracts () {
      const corporationContracts = this.prospectRentalStatus(this.prospect)
      corporationContracts
      .map(rs => {
        return {
        contractNumber: rs.contractNumber,
        contract_party: rs.contract_party,
        contract_area: rs.contract_area,
        curPartyStartDate: rs.curPartyStartDate,
        contractFirstPossibleEndDate: rs.contractFirstPossibleEndDate,
        curPartyEndDate: rs.curPartyEndDate,
        tenantNotice:rs.tenantNotice,
        landlordNotice: rs.landlordNotice,
        huom: rs.huom,
        vat: rs.vat,
        contract_note: rs.contract_note,
        signature_date: rs.signature_date,
        validity: rs.validity,
        siteName: rs.siteName,
        unitName: rs.unitName,
        area: rs.area
        }
      })
      if(!corporationContracts)
      {
        return []
      }
      let rows = []

      corporationContracts.forEach(contract => {
        if (contract.contractNumber) {
          const index = rows.findIndex(row => row.contractNumber === contract.contractNumber)
          if (index >= 0) {          
            rows[index].actualArea += contract.area
            rows[index].contractUnits += contract.unitName + ", "
          } 
          else {
            let newContract = contract
            newContract.actualArea = contract.area
            newContract.contractUnits = contract.unitName + ", "
            rows.push(newContract)
          }
        }
      })

      rows.forEach(row => {
        row.contractUnits = row.contractUnits.slice(0, -2)
        row.vat ? row.vat = true : row.vat = false
      })

      // Sort by contract start date
      rows.sort((prev, next) => prev.contractNumber.localeCompare(next.contractNumber))
      return rows
    },
    getContractMetadataForUnit () {
      return {
        contractUnits: {
          name: 'Units',
          format: 'None'
        },
        contract_party: {
          format: 'None',
          name: 'Contract party'
        },
        validity: {
          name: 'Type of contract validity',
          format: 'None',
          groupLast: true
        },
        actualArea: { 
          name: 'Rented Actual area',
          format: 'Area'
        },
        contract_area: {
          format: 'Area',
          name: 'Total contract area',
          groupLast: true
        },
        signature_date: {
          name: 'Date of signing the contract',
          format: 'Date'
        },
        curPartyStartDate: {
          name: 'Start date',
          format: 'Date'
        },
        contractFirstPossibleEndDate: {
          name: 'Contract first possible end date',
          format: 'Date'
        },
        curPartyEndDate: {
          name: 'End date',
          format: 'Date',
          groupLast: true
        },
        tenantNotice: {
          name: 'Agreement notice period (Tenant)',
          format: 'None'
        },
        landlordNotice: {
          name: 'Agreement notice period (Lanlord)',
          format: 'None'
        },
        huom: {
          name: 'Validity notice',
          format:'TruthValue'
        },
        vat: {
          name: 'VAT-responsibility',
          format: 'YesNoUnknown',
          groupLast: true
        },
        contract_note: {
          name: 'Comments',
          format: 'None'
        }
      }
    },
    updateProspects () {
      this.getProspects();
    },
    openCorporationModal () {
      this.getCorporationExtraById({id: this.selectedBusinessParty.id})
    },
    async changeSuccessLikelihood (newLikelihood) {
      if (newLikelihood === this.prospect.success_likelihood) {
        return
      }
      await this.changeProspectSuccessLikelihood({prospectId: this.prospect.id_prospect, data: newLikelihood})
      this.$emit('update')
    },
    emitUpdate () {
      this.$emit('update')
    },
    openDeleteDialog () {
      //if (this.hasApplicationPermissionByName('PROSPEKTI_POISTO')) {
        this.showDeleteDialog = true
      //}
    },
    deleteProspect () {
      this.removeProspect(this.prospect.id_prospect)
      this.changeProspectModalState(null)
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'time')
    },
  }
}
</script>

<style scoped>
.form-fields {
  margin-top: 1.5em !important;
  display: flex;
}
.add-site-btn {
  width: 90%;
  margin: 10px 10px !important;
}
.multi-select-field {
  height: auto !important;
}
.multi-select-field >>> .v-input__slot {
  min-height: 20px;
}
.empty-div {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.passive {
  border: 5px solid #ff5252;
}
.closed {
  border: 5px solid #4caf50;
}
.site-chip {
  text-decoration: underline;
  font-weight: bold;
  justify-content: center;
  min-width: 90%;
  height: 50px !important;
  cursor: pointer !important;
}
.success-text {
  color: #47ccaa;
}
.large-padding {
  padding: 50px !important;
}
.extra_space {
  margin-bottom: 25px !important;
}
.likelihood_high {
  background-color: #bddaaa !important;
}
.likelihood_intermediate {
  background-color: #ffe682 !important;
}
.likelihood_low {
  background-color: #f27d7d !important;
}
.no-hover {
  pointer-events: none;
}
.icecream {
  color: #f389d6;
}
.extra_space {
  margin-bottom: 20px !important;
}
/* :disabled="!hasApplicationPermissionByName('PROSPEKTI_POISTO')" */
</style>