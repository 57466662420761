import { render, staticRenderFns } from "./GeneralSituation.vue?vue&type=template&id=2e41f202&scoped=true"
import script from "./GeneralSituation.vue?vue&type=script&lang=js"
export * from "./GeneralSituation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e41f202",
  null
  
)

export default component.exports