import saveAs from './saveAs'
import csvStringify from 'csv-stringify'
import csvParse from 'csv-parse/lib/sync'

export default {
  export (headers, data, filename, format = 'utf-8') {
    if (typeof filename === 'undefined') {
      filename = 'export.csv'
    }
    csvStringify(
      [headers, ...data],
      {
        quoted_string: true,
        delimiter: ';',
        bom: true,
        cast: {
          number: value => {
            return ('' + value).replace('.', ',')
          },
          string: value => {
            if(value.includes('&amp;')){
              return value.replace('&amp;', '&')
            }
            if(value.includes('&#x27;')){
              return value.replace('&#x27;', `${"'"}`)
            }
            return value
          }
        }
      },
      (err, csvStringData) => {
        if (err === undefined) {
          var blob = new Blob([csvStringData], {
            type: `text/csv;charset=${format ?? 'utf-8'};`
          })
          saveAs(blob, filename)
        }
      }
    )
  },
  parseData (rawData, separator = ';') {
    try {
      let columns
      const data = csvParse(rawData, {
        delimiter: separator,
        quoted_string: true,
        columns: (headers) => {
          columns = headers
          return headers
        }
      })
      return { columns: columns, data: data }
    } catch (error) {
      const errorMessage = 'dataimport.error_parsing_data'
      
      return { error: errorMessage } 
    }
  },
  async import (blob, format = 'utf-8') {
    const blobReader = new FileReader()

    return new Promise((resolve, reject) => {
      blobReader.onerror = () => {
        blobReader.abort()
        reject(new Error('Failed to load file'))
      }
      blobReader.onload = (event) => {
        resolve(this.parseData(event.target.result))
      }
      blobReader.readAsText(blob, format ?? 'utf-8')
    })
  }
}
