<template>
  <v-row
    class="print-header"
    justify="space-between"
    align="center"
    no-gutters
  >
    <div>
      <h1>{{ title }}</h1>
      <div
        v-if="notice"
        class="notice-text"
      >
        {{ $t('Notice') + ' ' + $t(notice) }}
      </div>
    </div>
    <div>
      <h3>
        {{ site.commercial_name }}
      </h3>
      <span>{{ site.address + ', ' + site.post_office }}</span>
      <div>
        <a :href="site.wwwurl">{{ site.wwwurl }}</a>
      </div>
    </div>
    <CustomerLogo
      class="customer-logo"
      :size="40"
    />
  </v-row>
</template>
<script>
import CustomerLogo from '../../components/CustomerLogo'
export default {
  name: 'PrintUnitBrochure',
  components: {
    CustomerLogo,
  },
  props: {
    site: {
      default: () => {
        return {}
      },
      type: Object
    },
    title: {
      default: '',
      type: String
    },
    notice: {
      default: '',
      type: String
    },
  },
}
</script>
<style scoped>
.print-header {
  border-bottom: black solid 0.1mm;
}
.notice-text {
  font-style: italic;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>