<template>
  <v-tab-item :key="1">
    <p>{{ $t('rentReview.clickingTrashInfo') }}</p>
    <DynamicWidget
      :data="rentReviewItems.data"
      :type="rentReviewItems.type"
      :title="rentReviewItems.title"
      :loading="rentReviewItems.loading"
    >
      <template
        #customComponent="{ item }"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              right
              @click="downloadReviewPdf(item)"
              v-on="on"
            >
              download
            </v-icon>
          </template>
          <span>
            {{ $t('rentReview.pdfInfo') }}</span>
        </v-tooltip>
        <v-icon
          right
          @click="deleteRow(item)"
        >
          delete
        </v-icon>
      </template>
    </DynamicWidget>
  </v-tab-item>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import DynamicWidget from '../DynamicWidget'
import RentReviewPdfService from './Services/RentReviewPdfService'

export default {
  name: "RentReviewTableTab",
  components: {
    DynamicWidget
  },
  props: {
    rentReviews: {
      type: Array,
      default: () => undefined
    },
    rentReviewsLookup: {
      type: Object,
      default: () => undefined
    },
    rentReviewTypes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    searchCriteria: {
      type: Object,
      default: () => undefined
    },
  },
  data () {
    return {
      rentReviewItems: {
        type: 'DataTable',
        title: this.$t('rentReview.reviews'),
        loading: this.loading,
        data: {
          headers: [
            { text: this.$t('Contract number'), value: 'contractNumber' },
            { text: this.$t('Tenant'), value: 'tenant'},
            { text: this.$t('Clientele'), value: 'clientele'},
            { text: this.$t('create_rent_contract.payment_domain'), value: 'paymentDomainName' },
            { text: this.$t('typeOfPayment'), value: 'typeOfPayment' },
            { text: this.$t('rentRoll.reviewType'), value: 'rentReviewType' },
            { text: this.$t('rentReview.beforeReview'), value: 'netSum', format: 'Euro' },
            { text: this.$t('rentReview.afterReview'), value: 'reviewedNetSum', format: 'Euro' },
            { text: this.$t('change'), value: 'increasePercentage', format: 'Percentage' },
            { text: this.$t('rental_status.basic_rent'), value: 'basicRent', format: 'Euro' },
            { text: this.$t('create_rent_contract.index_month'), value: 'indexDate' },
            { text: this.$t('create_rent_contract.minimum_increase'), value: 'minimumIncrease', format: 'Percentage' },
            { text: this.$t('create_rent_contract.maximum_increase'), value: 'maximumIncrease', format: 'Percentage' },
            { text: this.$t('Actions'), value: 'customComponent' },
          ],
          items: [],
          sortBy: 'contractNumber',
          exportCsv: true,
          rowsPerPage: 10,
        }
      },
      rentPaymentDomains: [],
    }
  },
  computed: {
    ...mapGetters('app', ['customerLogo']),
    ...mapState('app', ['currentDate', 'buildings'])
  },
  watch: {
    rentReviews: {
      handler: function (newRentReviews) {
        this.setTableData(newRentReviews)
      },
      deep: true,
    },
    loading (newVal) {
      this.rentReviewItems.loading = newVal
    }
  },
  async mounted () {
    this.getCustomerLogo()
  },
  methods: {
    ...mapActions('app', ['getCustomerLogo']),
    setTableData (data) {
      if (data) {
        this.rentReviewItems.data.items = data
      }
    },
    deleteRow (row) {
      this.$emit('deleteRow', row)
    },
    async downloadReviewPdf (row) {
      const contracts = await this.$rambollfmapi.rentpayments.rentReviews.getContracts([ row.contractNumber ], this.currentDate)
      const contractMap = new Map(contracts.map(c => [c.id, c]))

      if (contracts?.length > 0) {
        await RentReviewPdfService.createRentReviewPdf(
          [{
            rentReviews: this.rentReviewsLookup[row.contractNumber],
            contract: contractMap.get(row.idContract),
          }],
          this.searchCriteria.rentReviewMonth,
          this.customerLogo,
          row.contractNumber
        )
      }
    },
  },
}
</script>

<style>
</style>