<template>
  <div>
    <v-tab-item
      :key="4"
    >
      <h3 class="form-headers">
        {{ $t('create_rent_contract.guarantees') }}
      </h3>
      <v-dialog
        v-model="showGuaranteeModal"
        persistent
        eager
      >
        <GuaranteeModal
          :guarantee="guaranteeToEdit"
          :contract-id="contractId"
          :visible="showGuaranteeModal"
          :selected-currency="selectedCurrency"
          @guaranteeAdded="onGuaranteeAdded"
          @guaranteeUpdated="onGuaranteeUpdated"
          @close="onSingleGuaranteeModalClosed"
        />
      </v-dialog>

      <v-form
        v-if="!showGuaranteeModal"
        ref="form1"
        class="pt-2"
      >
        <v-expansion-panels
          v-model="panels"
          class="my-2"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('Guarantees') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
              
                v-if="guarantees.length > 0"
                class="v-data-table theme--light scrollable"
              >
                <table :style="{ width: '100%'}">
                  <thead>
                    <th class="column">
                      {{ $t('GuaranteeType') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeDescription') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeSum') }}
                    </th>
                    <th class="column">
                      {{ $t('Delivered') }}
                    </th>
                    <th class="column">
                      {{ $t('Returned') }}
                    </th>
                    <th class="column">
                      {{ $t('PresentValue') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeAgreedDateOfDelivery') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeDateOfDelivery') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeDateOfExpiration') }}
                    </th>
                    <th class="column">
                      {{ $t('GuaranteeDateOfReturn') }}
                    </th>
                    <th class="column">
                      {{ $t('Actions') }}
                    </th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(guarantee, index) in guarantees"
                      :key="index"
                    >
                      <td>
                        {{ $t(guarantee.guaranteeType) }}
                      </td>

                      <td>
                        {{ guarantee.guaranteeDescription }}
                      </td>

                      <td class="col-align-right">
                        {{ getGuaranteeSum(guarantee) }}
                      </td>

                      <td class="col-align-right">
                        {{ formatSum(getDelivered(guarantee)) }}
                      </td>

                      <td class="col-align-right">
                        {{ formatSum(getReturned(guarantee)) }}
                      </td>

                      <td class="col-align-right">
                        {{ getPresentValue(guarantee) }}
                      </td>

                      <td class="col-align-right">
                        {{ getDate(guarantee.agreedDateOfDelivery) }}
                      </td>

                      <td class="col-align-right">
                        {{ getDateOfDelivery(guarantee) }}
                      </td>

                      <td class="col-align-right">
                        {{ getDate(guarantee.dateOfExpiration) }}
                      </td>

                      <td class="col-align-right">
                        {{ getDateOfReturn(guarantee) }}
                      </td>
  
                      <td>
                        <v-row justify="center">
                          <slot
                            name="row-actions"
                            :row="guarantee"
                          >
                            <v-btn 
                              icon
                              small
                              @click="editGuarantee(guarantee)"
                            >
                              <v-icon small>
                                edit
                              </v-icon>
                              <span class="d-sr-only">{{ $t('Modify') }}</span>
                            </v-btn>
                            <v-btn 
                              icon
                              small
                              @click="deleteGuarantee(guarantee)"
                            >
                              <v-icon small>
                                delete
                              </v-icon>
                              <span class="d-sr-only">{{ $t('Delete') }}</span>
                            </v-btn>
                          </slot>
                        </v-row>
                      </td>
                    </tr>

                    <tr v-if="guarantees.length>0">
                      <td>
                        {{ $t('Total') }}
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td class="col-align-right">
                        {{ formatSum(getTotalGuaranteesSum()) }}
                      </td>
                      <td class="col-align-right">
                        {{ formatSum(getTotalDeliveredSum()) }}
                      </td>
                      <td class="col-align-right">
                        {{ formatSum(getTotalReturnedSum()) }}
                      </td>
                      <td class="col-align-right">
                        {{ getTotalPresentValueSum() }}
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-else
                class="d-flex justify-center align-center mt-4"
              >
                <v-icon class="mr-1">
                  info
                </v-icon>
                {{ $t('create_rent_contract.no_guarantees') }}
              </div>
              <div class="text-right mt-3 mr-3">
                <v-btn
                  rounded
                  depressed
                  color="secondary"
                  @click="onAddNewGuarantee"
                >
                  <v-icon left>
                    add
                  </v-icon>
                  {{ $t('AddGuarantee') }}
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>
  import helpers from './../../../../helpers'
  import GuaranteeModal from './GuaranteeModal.vue'
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  
  export default {
    name: "RentContractGuaranteesTab",
    components: {
        GuaranteeModal
    },
    props: {
      contractId: {
        type: Number,
        default: undefined
      },

      guarantees: {
        type: Array,
        default: () => []
      },
      selectedCurrency: {
        type: Object,
        default: () => {
          return {
            'id': 1,
            'name': 'currency.EUR',
            'code': 'EUR',
            'symbol': '€',
            'icon': 'euro'
          }
        },
      },
    },
    data () {
      return (
        {
          panels: [0],
          showGuaranteeModal: false,
          guaranteeToEdit: undefined
        }
      )
    },
    computed: {
      ...mapGetters('app', ['definitionLabelById']),
    },
    methods: {
      onGuaranteeAdded (guarantee) {
        // create pseudo id
        guarantee.id = Math.floor(Math.random() * 1000000000)
        this.guarantees.push(guarantee)
        this.showGuaranteeModal = false
        this.$emit("guaranteesUpdated", this.guarantees)
      },
      onGuaranteeUpdated (guarantee) {
        const guaranteeIdx = this.guarantees.findIndex(p => p.id === guarantee.id)      
        this.guarantees[guaranteeIdx] = guarantee
        this.$emit("guaranteesUpdated", this.guarantees)
        this.showGuaranteeModal = false
      },
      onSingleGuaranteeModalClosed () {
        this.showGuaranteeModal = false
      },

      getGuaranteeSum (guarantee) {
        return this.formatSum(guarantee.guaranteeSum)
      },
      getDelivered (guarantee) {
        return guarantee?.delivered.reduce((a, b) => (a + Number(b.deliverySum)), 0)
      },
      getReturned (guarantee) {
        return guarantee?.returned.reduce((a, b) => (a + Number(b.returnSum)), 0)
      },
      getPresentValue (guarantee) {
        return this.formatSum(Number(this.getDelivered(guarantee)) - Number(this.getReturned(guarantee)))
      },
      getTotalGuaranteesSum () {
        return this.guarantees.reduce((a, b) => (b.guaranteeSum ? a + Number(b.guaranteeSum) : a + 0), 0)
      },
      getTotalDeliveredSum () {
        const totals = this.guarantees
          .flatMap(g => g.delivered)
          .map(d => d.deliverySum)
          .reduce((a, b) => a + Number(b), 0)
        return totals
      },
      getTotalReturnedSum () {
        const totals = this.guarantees
          .flatMap(g => g.returned)
          .map(d => d.returnSum)
          .reduce((a, b) => a + Number(b), 0)
        return totals
      },
      getTotalPresentValueSum () {
        return this.formatSum(this.getTotalDeliveredSum() - this.getTotalReturnedSum())
      },

      getDateOfDelivery (guarantee) {
        return guarantee.delivered
          .map(d => (this.getDate(d.dateOfDelivery)))
          .join(', ')
      },
      getDateOfReturn (guarantee) {
        return guarantee.returned
          .map(d => (this.getDate(d.dateOfReturn)))
          .join(', ')
      },

      getDate (date) {
        return date ? helpers.humanize.date(moment(date).format(), 'datetime') : ""
      },
      formatSum (sum) {
        return helpers.humanize.amount_long(sum ? Number(sum).toFixed(2) : 0) + ' ' + this.selectedCurrency.symbol
      },
      
      async deleteGuarantee (guarantee) {
        if (confirm(this.$t('Confirm remove'))) {
          const guaranteeIdx = this.guarantees.findIndex(p => p.id === guarantee.id)
          this.guarantees.splice(guaranteeIdx, 1)
          this.$emit("guaranteesUpdated", this.guarantees)
        }
      },
      editGuarantee (guarantee) {

        this.guaranteeToEdit = guarantee;
        this.showGuaranteeModal = true
      },
      onAddNewGuarantee () {
        this.guaranteeToEdit = undefined
        this.showGuaranteeModal = true
      }
    }
  }
</script>
  
<style scoped>
.btn {
  text-transform: unset !important;
}
.scrollable {
  overflow-x: scroll;
}
>>> .v-dialog {
  width: 100%;
}

@media screen and (min-width: 768px) {
  >>> .v-dialog {
    width: 750px;
  }
}
</style>