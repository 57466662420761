<template>
  <v-col class="graph">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-content>
          <div slot="header">
            {{ $t('Could not find a widget') }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>
<script>
export default {
  props: ['type']
}
</script>
<style scoped>
div {
  color: palevioletred;
}
</style>
