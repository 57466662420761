<template>
  <v-container
    v-if="hasApplicationPermissionByName('SAAVUTETTAVUUSSELOSTE')" 
    class="py-10 px-5"
  >
    <v-row justify="center">
      <v-col md="8">
        <h1>{{ $t('AccessibilityStatement') }}</h1>

        <p>
          Tämä saavutettavuusseloste koskee Rambollin kiinteistönhallintajärjestelmä Circleä. Palvelua koskee välillisesti laki digitaalisten palvelujen tarjoamisesta (306/2019), jossa edellytetään, että julkisten digitaalisten palveluiden on oltava saavutettavia.
        </p>

        <p>Tavoitteenamme on tarjota digitaalisia palveluita, jotka ovat saavutettavia kaikille käyttäjille. Tässä saavutettavuusselosteessa kerromme, millaisia puutteita palvelun saavutettavuudessa esiintyy ja miten voit antaa meille palautetta sekä kehittämisehdotuksia sivuston saavutettavuuteen liittyen. Tämä saavutettavuusseloste on julkaistu 4.10.2023.</p>

        <p>Kuvaus sivuston saavutettavuudesta perustuu kiinteistönhallintajärjestelmä Circlen kehittäjien ja ulkopuolisen asiantuntijaorganisaation arvioon.</p>

        <h2>Digipalvelun saavutettavuuden tila</h2>
        <p>Palvelu täyttää saavutettavuusvaatimukset osittain.</p>

        <h2>Ei saavutettava sisältö</h2>
        <p>Saavutettavuuspuutteet ovat ryhmitelty seuraavien kokonaisuuksien alle:</p>
        <ul>
          <li>havaittava</li>
          <li>hallittava</li>
          <li>ymmärrettävä</li>
          <li>toimintavarma</li>
          <li>muut saavutettavuuspuutteet</li>
        </ul><br>

        <h3>Havaittava</h3>

        <h4>Lomakkeen vaiheita ei ole merkitty ohjelmallisesti </h4>
        <p>Lomakkeen eri vaiheista kertovat elementit antavat virheellistä tietoa itsestään ohjemallisesti ja ne ovat merkitty pelkällä värillä. (1.3.1, 1.4.1)</p>

        <h4>Teksti ei pysy luettavana, kun sitä suurennetaan</h4>
        <p>Kaikki palvelun tekstit eivät pysy luettavan ja toiminnallisuudet käytettävänä, kun tekstiä suurennetaan 200 prosenttiin. (1.4.4)</p>

        <h4>Osa palvelun sisällöstä ei ole luettavissa tai käytettävissä kapealla näytöllä</h4>
        <p>Kun näyttöä kavennetaan 320 pikselin levyiseksi, ei osaa teksteistä pysty lukemaan eikä kaikkia toiminnallisuuksia käyttämään. (1.4.10)</p>

        <h3>Hallittava</h3>

        <h4>Näppäimistön kohdistusjärjestyksessä puutteita</h4>
        <p>Palvelussa on kalenterielementtejä, jotka saavat kohdistuksen väärässä järjestyksessä. Palvelussa on valikoita, joissa kohdistusjärjestys on puutteellinen apuvälineillä. (2.4.3)</p>

        <h3>Ymmärrettävä</h3>

        <p>Ei havaittuja puutteita.</p>

        <h3>Toimintavarma</h3>

        <h4>Elementtien nimissä ja rooleissa on puutteita</h4>
        <p>Palvelussa on painikkeita ja valintaruutuja, joilla on puutteelliset nimet ja/tai roolit. Elementtien käyttötarkoitus ei selviä käyttäjälle ohjelmallisesti. (4.1.2)</p>
      
        <h4>Valintaruutujen tila ei selviä käyttäjälle ohjelmallisesti</h4>
        <p>Palvelussa on valintaruutuja, joiden tila ja tilan muutokset eivät selviä ohjelmallisesti apuvälinekäyttäjälle. (4.1.2)</p>

        <h3>Muut saavutettavuuspuutteet</h3> 
        <p>Palvelussa on karttoja, jotka eivät ole saavutettavia. Kartat eivät kuulu saavutettavuuslainsäädännön piiriin.</p>

        <h2>Huomasitko saavutettavuuspuutteen digipalvelussamme? </h2>
        <p>
          Voit antaa meille saavutettavuuspalautetta sähköpostilla osoitteeseen <a href="mailto:circle@ramboll.fi">circle@ramboll.fi</a>. Kerro viestissäsi mistä kohdasta on kyse ja kuvaile ongelma mahdollisimman tarkasti.
        </p>
        <p>
          Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli palvelun ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, <a
            href="https://www.saavutettavuusvaatimukset.fi/kayttajan-oikeudet/"
            target="_blank"
          >voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon<span class="d-sr-only">(Linkki vie ulkoiseen palveluun ja avautuu uuteen ikkunaan)</span></a>. Etelä-Suomen Aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.
        </p>

        <h3>Valvontaviranomaisen yhteystiedot</h3>
        <p>
          Etelä-Suomen aluehallintovirasto<br>
          Saavutettavuuden valvonnan yksikkö<br>
          <a
            href="https://www.saavutettavuusvaatimukset.fi"
            target="_blank"
          >www.saavutettavuusvaatimukset.fi <span class="d-sr-only">(Linkki vie ulkoiseen palveluun ja avautuu uuteen ikkunaan)</span></a><br>
          <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a><br>
          puhelinnumero vaihde 0295 016 000
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccessibilityStatement',
  metaInfo () {
    return {
      title: `${this.$t('AccessibilityStatement')} ·`,
    }
  },
  computed: {
    ...mapGetters('app', [
      'hasApplicationPermissionByName',
    ]),
  },
}
</script>

<style scoped>
</style>
