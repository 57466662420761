<template>
  <!-- <div v-for="route in routes" v-bind:key="route.label">
      {{ route.label }}
    </div> -->
  <v-menu
    :disabled="!hasApplicationPermissionByName('HALLINTASIVUT')"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <!--  slot="activator" -->
      <v-btn
        :disabled="!hasApplicationPermissionByName('HALLINTASIVUT')"
        icon
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>settings</v-icon>
        <span class="d-sr-only">{{ $t('Settings') }}</span>
      </v-btn>
    </template>
    <v-list
      nav
      dense
    >
      <div
        v-for="item in items.filter(item => !item.disabled || !checkPermissions(...item.disabled))"
        :key="item.key"
      >
        <v-subheader v-if="item.subheader">
          {{ item.subheader }}
        </v-subheader>
        <!-- if no permission, disable disablable rows and hide otherwise -->
        <v-list-item
          v-else-if="
            item.disablable === true && !checkPermissions(...item.permission)
          "
          :disabled="true"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.header }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else-if="checkPermissions(...item.permission)"
          :to="item.link"
          :disabled="false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.header }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else-if="item.header === $t('Organization sites') && isSuperAdmin"
          :to="item.link"
          :visible="false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.header }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default
{
  data () {
    return {
      // menu items: header, icon, permissions, name, visibility: disablable = visible but disabled
      items: [
        {
          key: 1,
          subheader: this.$t('System settings'),
          disablable: false
        },
        {
          key: 2,
          header: this.$t('Party register'),
          icon: 'group',
          permission: ['OSAPUOLIREKISTERI'],
          link: { name: 'settings.parties' },
          disablable: true
        },
        {
          key: 3,
          header: this.$t('Status portfolios'),
          icon: 'store',
          permission: ['SIS_TILASALKUT'],
          link: { name: 'settings.statusportfolios' },
          disablable: false
        },
        {
          key: 4,
          header: this.$t('Purposes of use'),
          icon: 'developer_board',
          permission: ['SIS_KAYTTOTARKOITUKSET'],
          link: { name: 'settings.purposesofuse' },
          disablable: false
        },
        {
          key: 12,
          header: this.$t('Customizable additional data fields'),
          icon: 'edit_note',
          permission: ['TIETOKENTAT_MUOKKAUS'],
          link: { name: 'settings.additionaldatafields' },
          disablable: false
        },
        {
          key: 5,
          header: this.$t('Cost centers'),
          icon: 'business',
          permission: ['SIS_KUSTANNUSPAIKAT'],
          link: { name: 'settings.costcenters' },
          disablable: false
        },
        {
          key: 6,
          header: this.$t('Cost types'),
          icon: 'monetization_on',
          permission: ['SIS_VUOKRALASKENTA'],
          link: { name: 'settings.costtypes' },
          disablable: false
        },
        {
          key: 7,
          header: this.$t('Service requests'),
          icon: 'error',
          permission: ['RAMBOLL_TILAUS', 'ZOAN_TILAUS'],
          link: { name: 'settings.servicerequests' },
          disablable: true
        },
        {
          key: 8,
          header: this.$t('Portfolio management'),
          icon: 'work',
          permission: ['SALKKUHALLINTA'],
          link: { name: 'settings.portfoliomanagement' },
          disablable: false
        },
        {
          key: 9,
          subheader: this.$t('User settings')
        },
        {
          key: 10,
          header: this.$t('Users'),
          icon: 'person',
          permission: ['KAYTTAJAHALLINTA'],
          link: { name: 'settings.users' },
          disablable: true
        },
        {
          key: 11,
          header: this.$t('User groups'),
          icon: 'group',
          permission: ['KAYTTAJAHALLINTA'],
          link: { name: 'settings.usergroups' },
          disablable: true
        },
      ]
    }
  },
  computed: {
    ...mapGetters('app', [
      'hasApplicationPermissionByName'
    ]),
    isSuperAdmin () {
      return this.$store.state.app.superAdmin === true
    },
  },
  methods: {
    // check all permissions for the case, one per case is enough
    checkPermissions (...args) {
      let allPermissions = false
      for (const arg of args) {
        if (this.hasApplicationPermissionByName(arg) === true) {
          allPermissions = true
        }
      }
      return allPermissions
    },
  },
}
</script>

<style scoped>
</style>
