<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template #activator="{ on: dialog }">
        <v-tooltip top>
          <template #activator="{ on: tooltip }">
            <v-icon
              class="error-tile-icon"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
            >
              error
            </v-icon>
          </template>
          <span> {{ $t("Error info") }}</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("Error info") }}
          <v-spacer />
          <v-icon
            @click="dialog = false"
          >
            close
          </v-icon>
        </v-card-title>

        <v-card-text> 
          <v-textarea
            id="tocopy"
            rows="20"
            class="borderless infotext"
            readonly
            :value="info"
          /> 
        </v-card-text>
        <v-card-actions />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props: {
    info: {
      default: '',
      type: String
    }
  },
    data () {
      return {
        dialog: false,
        attrs: null,
        }
    },
}
</script>
<style scoped>
.error-tile-icon {
  color: white;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}
.infotext {
  background: white !important;
  border: none !important;
}
</style>
