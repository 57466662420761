<template>
  <div
    class="map-container"
    :style="overWriteMapStyle"
  >
    <div
      v-if="loading"
      style="text-align: center; padding: 2em;"
    >
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
    <div
      v-show="!loading"
      :id="mapId"
      class="map"
      :style="overWriteStyleWithoutMargin"
      tabindex="0"
    />
    <transition name="fade">
      <div
        v-if="map"
        v-show="layerControlVisible"
        class="rb-layer-control-popup"
        :style="{left: layerControlLeft + 'px', top: layerControlTop + 'px'}"
      >
        <v-card>
          <v-list>
            <v-list-item
              v-for="layer in mapLayers"
              :key="layer.name"
            >
              <v-list-item-content @click="changeLayerVisibility(layer.name)">
                <v-switch
                  v-model="layer.visible"
                  color="blue"
                />
              </v-list-item-content>
              <v-list-item-title>
                {{ layer.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="toggleLayerPopup()"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </transition>
  </div>
</template>
<script>
import proj4 from 'proj4'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { register } from 'ol/proj/proj4.js'
import { get, getTransform, transform } from 'ol/proj.js'
import OSM from 'ol/source/OSM'
import TileWMS from 'ol/source/TileWMS'
import Vector from 'ol/source/Vector'
import { extend, getTopRight, getBottomLeft } from 'ol/extent'
import Control from 'ol/control/Control'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import TextStyle from 'ol/style/Text'
import IconStyle from 'ol/style/Icon'
import Fill from 'ol/style/Fill'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Select from 'ol/interaction/Select'
import Pointer from 'ol/interaction/Pointer'

import 'ol/ol.css'

import markerImage from '../assets/map_marker.png'
const PROJECTION = 'EPSG:3857'

export default {
  props: [
    'center',
    'maps',
    'zoom',
    'controls',
    'centerProjection',
    'marker',
    'mapId',
    'loading',
    'overWriteMapStyle'
  ],
  data () {
    return {
      map: null,
      layerControlVisible: false,
      layerControlLeft: 100,
      layerControlTop: 100,
      bbox: [],
      currentBuildingRegistry: null,
      currentRambollProjects: null,
      currentRambollStats: {
        cities: null
      },
      selectedFeature: null,
      previousEvent: null
    }
  },
  computed: {
    filteredRambollStats () {
      const stats = this.currentRambollStats
      return stats
    },
    hasMunicipalityAreasLayer () {
      if (
        this.maps.findIndex(m => m.type === 'MunicipalityAreasFinland') >= 0
      ) {
        return true
      }
      return false
    },
    hasRambollProjectsLayer () {
      if (this.maps.findIndex(m => m.type === 'RambollProjects') >= 0) {
        return true
      }
      return false
    },
    hasBuildingRegistryLayer () {
      if (this.maps.findIndex(m => m.type === 'BuildingRegistryFinland') >= 0) {
        return true
      }
      return false
    },
    hasBuildingsLayer () {
      if (this.maps.findIndex(m => m.type === 'BuildingsFinland') >= 0) {
        return true
      }
      return false
    },
    hasPostalCodeAreasLayer () {
      if (this.maps.findIndex(m => m.type === 'PostalCodeAreasFinland') >= 0) {
        return true
      }
      return false
    },
    mapLayers () {
      if (this.map === null) {
        return []
      }
      const layerGroup = this.map.getLayerGroup()
      const layers = layerGroup.getLayers().getArray()

      return layers.map(layer => {
        return {
          name: layer.get('name'),
          title: layer.get('title'),
          visible: layer.getVisible()
        }
      })
    },
    overWriteStyleWithoutMargin () {
      if (this.overWriteMapStyle) {
        const style = {}
        for (var key in this.overWriteMapStyle) {
          if (key.toString().indexOf('margin') === -1) {
            style[key] = this.overWriteMapStyle[key]
          }
        }
        return style
      }
      return null
    },
    mapPoints () {
      if (this.maps === null) {
        return []
      } else {
        return this.maps.filter(map => map.type === "Points").map(p => p.points)
      }
    }
  },
  watch: {
    mapPoints: {
      handler (maps) {
        maps.forEach(points => {
          if(points.x && points.y) {
            this.zoomMap(points)
          }
        })
      }
    },
    maps: {
      handler (maps) {
        if (maps === null) {
          return
        }
        maps.forEach(map => {
          if (map.type === 'Points') {
            this.addPointsLayer(map)
          }
          if (map.type === 'OSM') {
            const layer = new TileLayer({
              source: new OSM({
                projection: 'EPSG:3857'
              }),
              opacity: map.opacity,
              title: map.title,
              name: map.name,
              projection: 'EPSG:3857'
            })

            const existingLayer = this.findLayer(
              this.map.getLayerGroup(),
              'name',
              map.name
            )
            if (existingLayer !== null) {
              this.map.removeLayer(existingLayer)
            }
            this.map.addLayer(layer)
          }
        })
      }
    },
    /**
     * We need  to watch buildings for changes and trigger changed() method
     * for the appropriate layers so that change is immediate
     */
    filteredBuildings: function () {
      const existingLayer = this.findLayer(
        this.map.getLayerGroup(),
        'name',
        'Buildings layer'
      )
      if (existingLayer != null) {
        existingLayer.changed()
      }
    },
    centerProjection: function () {
      const center = this.transform(this.center, this.centerProjection)
      this.map.getView().setCenter(center)
      this.onMoveEnd()

      this.$emit('centered')
    },
    center: function () {
      const center = this.transform(this.center, this.centerProjection)
      this.map.getView().setCenter(center)
      this.onMoveEnd()

      this.$emit('centered')
    },
    zoom: function (zoomLevel) {
      this.map.getView().setZoom(zoomLevel)
      this.onMoveEnd()
    },
    marker: {
      handler () {
        this.addMarkerLayer()
      },
      deep: true
    }
  },
  mounted: function () {
    this.initializeProjections()

    const zoom = typeof this.zoom === 'undefined' ? 13 : Number(this.zoom)
    let center = this.center
    if (typeof center === 'undefined' || center === null) {
      center = [0, 0]
    } else {
      center = [Number(center[0]), Number(center[1])]
    }
    center = this.transform(center, this.centerProjection)
    const layers = this.createLayers()

    // Define map
    this.map = new Map({
      layers: layers,
      target: this.mapId ? this.mapId : 'rb-map',
      view: new View({
        center: center,
        zoom: zoom,
        projection: PROJECTION
      })
    })

    if (this.maps !== null) {
      this.maps.forEach(map => {
        if (map.points) {
          this.addPointsLayer(map)
        }
      })
    }

    this.map.getLayerGroup().set('name', 'Root')

    // Attach drag/zoom event
    this.map.on('moveend', this.onMoveEnd)

    // Postrender event
    this.map.on('rendercomplete', () => this.$emit('render-finish'));

    // Show controls if defined
    if (typeof this.controls !== 'undefined') {
      // Add the custom layer selection control
      this.map.addControl(this.layerControl())
    }

    // Attach selection event
    const select = new Select({
      style: this.selectStyleFunction
    })

    this.map.addInteraction(select)

    select.on('select', e => {
      this.$emit('select', e)
    })

    this.map.addInteraction(
      new Pointer({
        handleUpEvent: evt => {
          if (this.selectedFeature !== null) {
            const coord = this.selectedFeature.getGeometry().getCoordinates()
            this.selectedFeature = null
            this.previousEvent = evt
            this.$emit('markermove', coord)
          }
        },
        handleDownEvent: evt => {
          var feature = this.map.forEachFeatureAtPixel(evt.pixel, function (
            feature
          ) {
            return feature
          })
          if (feature && feature.get('name') === 'Marker') {
            this.selectedFeature = feature
            this.previousEvent = evt
            return true
          }

          return false // prevents map dragging
        },
        handleDragEvent: evt => {
          if (this.selectedFeature !== null) {
            const prevCoordinate = this.previousEvent.coordinate
            const deltaX = evt.coordinate[0] - prevCoordinate[0]
            const deltaY = evt.coordinate[1] - prevCoordinate[1]

            const geometry = this.selectedFeature.getGeometry()
            geometry.translate(deltaX, deltaY)
            this.previousEvent = evt
          }
        }
      })
    )

    if (this.maps !== null) {
      // Add layers
      this.addMarkerLayer()
      if (this.hasBuildingsLayer) {
        this.addBuildingsLayer()
      }
    }

    // this.setProjection('EPSG:3879', [68.9, 24.5, 59.94, 25.5]) // Used by Espoo maps
    // this.setProjection('EPSG:4326', [90, -180, -90, 180]) // GPS system (WGS 84)
    // this.setProjection('EPSG:3857', [85.06, -180.0, -85.06, 180.0]) // Web Mercator
  },
  methods: {
    /**
     * Change layer visibility. Searches layers by name.
     */
    changeLayerVisibility (name) {
      const existingLayer = this.findLayer(
        this.map.getLayerGroup(),
        'name',
        name
      )
      if (existingLayer != null) {
        existingLayer.setVisible(!existingLayer.getVisible())
      }
    },
    /**
     * This method initializes custom projections not bundled with Open Layers
     */
    initializeProjections () {
      // Use proj4 to define projections
      proj4.defs(
        'EPSG:3879',
        '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
      )
      proj4.defs(
        'EPSG:3067',
        '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
      )

      // Set Open Layers to use this proj4 instance
      register(proj4)
    },
    /**
     * Set the desired projection for the current map
     */
    setProjection (code, bbox) {
      const newProj = get(code)
      const fromLonLat = getTransform.getTransform('EPSG:4326', newProj)

      // very approximate calculation of projection extent
      const ext = extend.applyTransform(
        [bbox[1], bbox[2], bbox[3], bbox[0]],
        fromLonLat
      )
      newProj.setExtent(ext)

      const newView = new View({
        projection: newProj
      })

      // Set map
      this.map.setView(newView)
      newView.fit(ext)
    },
    /**
     * Creates layers based on the maps array in model
     */
    createLayers () {
      const layers = []
      if (this.maps === null) {
        return []
      }
      this.maps.forEach(map => {
        let layer = null
        if (map.type === 'OSM') {
          layer = new TileLayer({
            source: new OSM({
              projection: 'EPSG:3857'
            }),
            opacity: map.opacity,
            title: map.title,
            name: map.name,
            projection: 'EPSG:3857'
          })
        }

        if (map.type === 'WMS') {
          layer = new TileLayer({
            source: new TileWMS(map.source),
            opacity: map.opacity,
            title: map.title,
            name: map.name,
            projection: 'EPSG:3857',
            visible: false
          })
        }

        if (map.type === 'CustomSingleTarget') {
          layer = new VectorLayer({
            name: map.name,
            title: map.name,
            projection: 'EPSG:3857',
            source: new Vector({
              features: [
                new Feature({
                  geometry: new Point(this.transform(map.point, map.projection))
                })
              ]
            }),
            style: new Style({
              image: new IconStyle({
                scale: 0.3,
                src: process.env.BASE_URL + '/static/images/pin_round.png'
              })
            }),
            visible: true
          })
        }

        if (map.type === 'PostalCodeAreasFinland') {
          // addPostalCodeAreasLayer()
        }

        if (layer !== null) {
          layers.push(layer)
        }
      })
      return layers
    },

    /**
     * Find layer by given key value pair
     */
    findLayer (layer, key, value) {
      if (layer.get(key) === value) {
        return layer
      }

      // Find recursively if it is a group
      if (layer.getLayers) {
        const layers = layer.getLayers().getArray()
        const len = layers.length
        let result
        for (var i = 0; i < len; i++) {
          result = this.findLayer(layers[i], key, value)
          if (result) {
            return result
          }
        }
      }

      return null
    },

    /**
     * This method toggles the layer selection popup and
     * positions the popup next to the button
     */
    toggleLayerPopup () {
      if (this.layerControlVisible) {
        this.layerControlVisible = false
      } else {
        const element = document.getElementsByClassName('rb-layer-control')[0]
        const rect = element.getBoundingClientRect()

        this.layerControlLeft = rect.left + 48
        this.layerControlTop = rect.top
        this.layerControlVisible = true
      }
    },

    /**
     * Generates layer control for open layers (and needed dom elements)
     */
    layerControl () {
      // generate dom elements
      const button = document.createElement('button')
      button.innerHTML =
        '<i aria-hidden="true" class="icon material-icons">layers</i>'

      button.addEventListener('click', this.toggleLayerPopup, false)
      button.addEventListener('touchstart', this.toggleLayerPopup, false)
      
      const element = document.createElement('div')
      element.className =
        'rb-layer-control ol-unselectable ol-control theme--dark'
      element.appendChild(button)

      return new Control({
        element: element
      })
    },

    transform (point, projection) {
      if (typeof projection === 'undefined') {
        projection = 'EPSG:4326' // gps
      }
      // Original: EPSG:3879
      return transform(point, projection, PROJECTION)
    },
    addMarkerLayer () {
      const layerName = 'Marker layer'

      if (typeof this.marker === 'undefined' || this.marker === null) {
        return
      }
      let coord = [this.marker.longitude, this.marker.latitude]
      coord = transform(coord, this.marker.projection, 'EPSG:3857')
      const iconFeature = new Feature({
        geometry: new Point(coord),
        name: 'Marker'
      })

      const vectorSource = new Vector({
        features: [iconFeature]
      })

      const vectorLayer = new VectorLayer({
        name: layerName,
        title: layerName,
        projection: 'EPSG:3857',
        source: vectorSource,
        zIndex: 2000, // TOP
        style: new Style({
          image: new IconStyle({
            scale: 0.5,
            src: markerImage,
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction'
          })
        })
      })

      const existingLayer = this.findLayer(
        this.map.getLayerGroup(),
        'name',
        layerName
      )
      if (existingLayer != null) {
        this.map.removeLayer(existingLayer)
      }
      this.map.addLayer(vectorLayer)
    },

    /**
     * Add buildings data from model info
     */
    addBuildingsLayer () {
      const features = []
      this.filteredBuildings.forEach(m => {
        let coord = [Number(m.Longitude), Number(m.Latitude)]
        if (isNaN(coord[0]) || isNaN(coord[1])) {
          return
        }
        coord = transform(coord, 'EPSG:4326', 'EPSG:3857')

        // FIXME this should be moved somewhere else
        if (typeof this.center === 'undefined') {
          if (m.Latitude !== 0 && m.Longitude !== 0) {
            this.map.getView().setCenter(coord)
          }
        }

        // Vector
        features.push(
          new Feature({
            geometry: new Point(coord),
            buildingId: m.BuildingId
          })
        )
      })

      const vectorSource = new Vector({
        features: features
      })

      const vectorLayer = new VectorLayer({
        name: 'Buildings layer',
        title: this.$i18n.t('Buildings layer'),
        projection: 'EPSG:3857',
        source: vectorSource,
        style: this.styleFunction,
        zIndex: 1000 // TOP
      })

      this.map.addLayer(vectorLayer)
    },

    // Add a general point layer
    addPointsLayer (layer) {
      const features = []
      layer.points.sort((a, b) => {
        return a.y < b.y ? 1 : -1
      })
      layer.points.forEach(m => {
        let coord = [Number(m.x), Number(m.y)]
        if (isNaN(coord[0]) || isNaN(coord[1])) {
          return
        }
        coord = transform(coord, m.projection, 'EPSG:3857')
        // Vector
        features.push(
          new Feature({
            geometry: new Point(coord),
            data: m
          })
        )
      })

      const vectorSource = new Vector({
        features: features
      })

      const vectorStyle = (feature, resolution) => {
        const text = feature.get('data').name
        const style = new Style({
          image: new IconStyle({
            scale: 0.5,
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: markerImage // process.env.BASE_URL + '/static/images/pin_round_selected.png'
          })
        })
        if (resolution < 1.5) {
          style.setText(
            new TextStyle({
              textAlign: 'left',
              textBaseline: 'middle',
              font: '14px sans-serif',
              text: text,
              fill: new Fill({
                color: 'black'
              }),
              stroke: new Stroke({
                color: 'white',
                width: 4
              }),
              offsetX: 20,
              offsetY: 0,
              rotation: 0
            })
          )
        }
        return style
      }

      const vectorLayer = new VectorLayer({
        name: layer.name,
        title: layer.title,
        projection: 'EPSG:3857',
        source: vectorSource,
        style: vectorStyle,
        zIndex: 1000 // TOP
      })

      // Readd the layer to map
      const existingLayer = this.findLayer(
        this.map.getLayerGroup(),
        'name',
        layer.name
      )
      if (existingLayer != null) {
        vectorLayer.setVisible(existingLayer.getVisible())
        this.map.removeLayer(existingLayer)
      }
      this.map.addLayer(vectorLayer)
    },

    /**
     * Style function for selection
     */
    selectStyleFunction (feature, resolution) {
      if (typeof feature !== 'undefined') {
        if (feature.values_.name === 'Marker') {
          return null
        }

        let newStyle = this.styleFunction(feature, resolution)

        let strokeColor = 'red'
        let fillColor = '#ff9999aa'

        const buildingId = feature.get('buildingId')
        const postalCode = feature.get('postalCode')
        // let postalCode = feature.get('postalCode')
        if (typeof buildingId !== 'undefined') {
          const style = new IconStyle({
            scale: 0.3,
            src: markerImage // process.env.BASE_URL + '/static/images/pin_round_selected.png'
          })

          newStyle.setImage(style)
        } else if (typeof postalCode !== 'undefined') {
          strokeColor = 'red'
          fillColor = '#ff999900'

          newStyle = new Style({
            stroke: new Stroke({
              color: strokeColor,
              width: 2
            }),
            fill: new Fill({
              color: fillColor
            })
          })
        } else {
          // newStyle = this.getMunicipalityLayerStyle(feature, resolution)
          /* newStyle = new IconStyle({
            scale: 0.3,
            src: markerImage, //process.env.BASE_URL + '/static/images/pin_round_selected.png'
          })
*/
          // newStyle.setImage(style)
          // strokeColor = 'red'
          // fillColor = '#ff999900'
          // newStyle.getStroke().setColor(strokeColor)
        }
        return newStyle
      }

      return feature.style_
    },

    /**
     * Style function for building atm
     */
    styleFunction (feature, resolution) {
      const strokeColor = 'red'
      const fillColor = '#ff9999aa'
      const buildingId = feature.get('buildingId')
      const projectName = feature.get('projectName')
      let defaultStyle = null
      // when building id is defined
      if (typeof buildingId !== 'undefined' && buildingId !== null) {
        defaultStyle = new Style({
          image: this.getBuildingStyleForBuildingId(
            buildingId,
            strokeColor,
            fillColor,
            resolution
          )
        })
        if (resolution < 1.5) {
          defaultStyle.setText(
            new TextStyle({
              textAlign: 'left',
              textBaseline: 'middle',
              font: '14px sans-serif',
              text: this.getBuildingNameForBuildingId(buildingId),
              fill: new Fill({
                color: 'black'
              }),
              stroke: new Stroke({
                color: 'white',
                width: 2
              }),
              offsetX: 20,
              offsetY: 0,
              rotation: 0
            })
          )
        }
      } else if (typeof projectName !== 'undefined' && projectName !== null) {
        defaultStyle = new Style({
          image: new IconStyle({
            scale: 0.3,
            src: process.env.BASE_URL + '/static/images/pin_round_green.png'
          })
        })
        if (resolution < 200) {
          defaultStyle.setText(
            new TextStyle({
              textAlign: 'left',
              textBaseline: 'middle',
              font: '14px sans-serif',
              text: projectName,
              fill: new Fill({
                color: 'black'
              }),
              stroke: new Stroke({
                color: 'white',
                width: 4
              }),
              offsetX: 20,
              offsetY: 0,
              rotation: 0
            })
          )
        }
      }

      return defaultStyle
    },
    removeLayerByName (name, map) {
      if (typeof map === 'undefined') {
        map = this.map
      }
      const existingLayer = this.findLayer(map.getLayerGroup(), 'name', name)
      if (existingLayer != null) {
        this.map.removeLayer(existingLayer)
      }
    },
    onMoveEnd (evt) {
      let map = this.map
      if (typeof evt !== 'undefined') {
        map = evt.map
      }
      const ext = map.getView().calculateExtent(map.getSize())

      const topRight = getTopRight(ext)
      const bottomLeft = getBottomLeft(ext)

      const newExt = [bottomLeft[0], topRight[1], topRight[0], bottomLeft[1]]
      this.$emit('viewportchange', newExt)

      // For screen readers
      const olLayer = document.getElementsByClassName('ol-layer')[0]
      if (olLayer !== undefined) {
        const canvas = olLayer.getElementsByTagName('canvas')[0]
        canvas.setAttribute("role", "img")
        canvas.setAttribute("aria-label", this.$t('MapAriaLabel'))
      }
    },
    zoomMap (ticketPoints) {
      if (ticketPoints.length > 0) {
        // initialize variables
        let ticketMidPointsArr = []
        let ticketPointXarr = []
        let ticketPointYarr = []
        let ticketPointsFullArr = []

        // add buildings x and y coordinates to arrays and transform projection
        for (let i = 0; i < ticketPoints.length; i++) {
          let xCoord = ticketPoints[i].x
          let yCoord = ticketPoints[i].y
          let ticketPointsXYarr = [xCoord, yCoord]
          let ticketPointsXY = this.transform(ticketPointsXYarr, 'EPSG:3879')
          ticketPointsFullArr.push(ticketPointsXY)  
        }

        // separate x and y coordinates to arrays
        for (let j = 0; j < ticketPointsFullArr.length; j++) {
          let ticketPointX = ticketPointsFullArr[j][0]
          let ticketPointY = ticketPointsFullArr[j][1]
          ticketPointXarr.push(ticketPointX)
          ticketPointYarr.push(ticketPointY)
        }

        // get tickets middlepoints
        let latdifferenece = Math.max.apply(Math, ticketPointXarr) - Math.min.apply(Math, ticketPointXarr)
        let temp = (latdifferenece / 2).toFixed(4)
        let latitudeMid = parseFloat(Math.min.apply(Math, ticketPointXarr)) + parseFloat(temp)
        let longidifferenece = Math.max.apply(Math, ticketPointYarr) - Math.min.apply(Math, ticketPointYarr)
        temp = (longidifferenece / 2).toFixed(4)
        var longitudeMid = parseFloat(Math.min.apply(Math, ticketPointYarr)) + parseFloat(temp)

        // set center & zoom
        ticketMidPointsArr.push(latitudeMid, longitudeMid)
        let center = ticketMidPointsArr
        this.map.mapCenter = center
        this.map.mapProjection = 'EPSG:3879'
        let view = this.map.getView()
        let extent = [Math.min.apply(Math, ticketPointXarr), Math.min.apply(Math, ticketPointYarr), Math.max.apply(Math, ticketPointXarr), Math.max.apply(Math, ticketPointYarr)]
        view.fit(extent, this.map.getSize())
        let zoom = view.getZoom()
        if (zoom > 14.5) {
          view.setZoom(14)
        } else {
          view.setZoom(zoom - 0.5)
        }
      }
    }
  }
}
</script>
<style>
.map:-moz-full-screen {
  height: 100%;
}
.map:-webkit-full-screen {
  height: 100%;
}
.map:-ms-fullscreen {
  height: 100%;
}
.map:fullscreen {
  height: 100%;
}
.map {
  min-height: 400px;
  width: 100%;
  height: 100%;
  /*width: 100%;*/
}
.map .rb-layer-control {
  left: 0.5rem;
  /*top: 13rem;
  left: 1.5rem; */
}
.map .ol-zoom .ol-zoom-in,
.map .ol-zoom .ol-zoom-out {
  width: 36px;
  height: 36px;
  background-color: #009de0;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}
.map .rb-layer-control button {
  width: 36px;
  height: 36px;
  background-color: #009de0;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}
.map-container {
  height: 100%;
  width: 100%;
  background: #eeeff5;
  border-radius: 1em;
  border: 2px solid #eeeff5;
}
.rb-layer-control-popup {
  position: fixed;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
