<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ tableData.title }}
            </h1>
          </v-card-title>
        </div>
        <TableVertical
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="createFooters"
          :pagination.sync="viewDataPagination"
        />
      </v-card>
    </v-dialog>
    <div class="v-data-table__wrapper">
      <table
        class="v-data-table theme--light"
        style="border-spacing: 0px;"
        mobile-breakpoint="0"
      >
        <tbody v-if="getTotalRows(data) > 0">
          <tr
            v-for="header in headers"
            :key="header.value"
          >
            <th
              :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
              class="v-data-table-header"
              style="font-size: 12px"
            >
              {{ header.text }}
            </th>
            <td
              v-for="(item, index) in items"
              :key="index"
            >
              <v-row class="row align-center vertical_icon">
                <v-row
                  :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
                  class="col-align-left flex-column"
                  style="padding-left: 1em; padding-right: 1em;"
                >
                  <span
                    :key="index"
                  >
                    {{ formatData(item[header.value], header.format) }}
                  </span>
                </v-row>
                <v-row
                  class="col-align-right flex-column"
                >
                  <StatusIcon
                    :field="getObjectWithUnit(item[header.value], header.value)"
                    :data="getValueWithUnit(item[header.value], header.value)"
                  />
                </v-row>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody
          v-else
        >
          <tr
            v-for="header in headers"
            :key="header.value"
          >
            <th
              :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
              class="columnheader"
              style="font-size: 12px"
            >
              {{ header.text }}
            </th>
            <td>
              {{ $t('audits no data') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="v-data-footer"
      style="border-top: 2px solid #eeeff5;"
    >
      <div class="v-data-footer__pagination">
        <ImportButton
          v-if="importing"
          :headers="data.headers"
          @dataUpdate="$emit('dataUpdated', $event)"
        />&nbsp;
        <v-btn
          v-if="importing"
          small
          outlined
          rounded
          @click="exportAsCsv(data.headers, data.items)"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>&nbsp;
        <v-btn
          small
          outlined
          rounded
          @click="viewData(title, data.headers, data.items)"
        >
          {{ $t('Browse') }}
        </v-btn>
        {{ data.items.length ? 1 : 0 }}-{{ data.items.length.toLocaleString('fi-FI') }} {{ $t('of') }}
        {{ data.items.length.toLocaleString('fi-FI') }}
      </div>
      <div class="v-data-footer__icons-before">
        <v-btn
          class="v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size-default"
          disabled="disabled"
          aria-label="Previous page"
          text
        >
          <span class="v-btn__content">
            <i
              aria-hidden="true"
              class="v-icon notranslate material-icons theme--light"
            >chevron_left</i>
          </span>
        </v-btn>
      </div>
      <div class="v-data-footer__icons-after">
        <v-btn
          class="v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size-default"
          disabled="disabled"
          aria-label="Next page"
          text
        >
          <span class="v-btn__content">
            <i
              aria-hidden="true"
              class="v-icon notranslate material-icons theme--light"
            >chevron_right</i>
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from '../../helpers'
import TableVertical from '../TableVertical'
import ImportButton from '../../components/ImportButton'
import StatusIcon from '../../components/StatusIcon'

export default {
  components: {
    TableVertical,
    ImportButton,
    StatusIcon
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      showTableDataDialog: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        rowsPerPage: -1,
        page: 1,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
        rowsPerPage: this.data.browseRowsPerPage
          ? this.data.browseRowsPerPage
          : this.data.rowsPerPage
            ? this.data.rowsPerPage
            : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      importing: this.data.importing ? this.data.importing : false
    }
  },
  computed: {
    items: function () {
      return this.data.items.map(item => {
        const thisItem = JSON.parse(JSON.stringify(item))
        return thisItem
      })
    },
    headers: function () {
      return this.data.headers.map(h => {
        return {
          text: this.$t(h.text),
          value: h.value,
          headerFormat: h.headerFormat,
          format: h.format,
        }
      })
    }
  },
  methods: {
    getValueWithUnit (value, header) {
      if (header === 'general_rating_site') {
        value = helpers.format.valueWithUnit(value, header)
        return value.bold()
      } else {
        value = helpers.format.valueWithUnit(value, header)
        return value
      }
    },
    getHeaderValue (header, headerText) {
      if (header === 'general_rating_site') {
        return headerText.bold()
      } else {
        return headerText
      }
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    getTotalRows (data) {
      var totalRows = data.items.length
      if (totalRows !== 0) {
        return totalRows
      } else {
        return 0
      }
    },
    getFooterValue (data, headerName, text, unit, average) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (headerName === 'general_rating') {
        var totalCount = 0
        var averageRating = 0
        var sitesWithAudit = 0
        for (let i = 0; i < data.length; i++) {
          var singleRate = data[i].general_rating
          if (singleRate !== null) {
            totalCount += singleRate
            sitesWithAudit += 1
          }
        }
        averageRating = totalCount / sitesWithAudit
        if (isNaN(averageRating)) {
          averageRating = 0
        }
        value = helpers.format.valueWithUnit(averageRating, unit)
        return value
      }
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      if (typeof unit !== 'undefined') {
        value = helpers.format.valueWithUnit(value, unit)
      }
      return value
    },
    viewData (title, headers, data) {
      // this.$root.$emit('view-data', title, headers, data)
      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          return h
        }),
        rows: this.data.items
      }
      this.viewDataOptions = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc
      }
      this.showTableDataDialog = true
    },
    createFooters (headers, rows) {
      return helpers.table.createFooters(headers, rows, this.data.browseFooters, this.data.footers)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    exportAsCsv (originalHeaders, originalData) {
      const headers = [...JSON.parse(JSON.stringify(originalHeaders))]
      const data = [...JSON.parse(JSON.stringify(originalData))]

      const csvHeaders = headers.map(h => this.$t(h.text))
      const csvData = data.map(d => {
        const row = []
        headers.forEach(h => {
          let val = d[h.value]
          val = val === undefined ? '' : val
          val = this.getValueWithUnitForCsvExport(val, h.value, false)
          row.push(val)
        })
        return row
      })
      helpers.csv.export(csvHeaders, csvData)
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      return value
    },
    getTruncatedStringFromArray (value, header, encode) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header, encode)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }
      return itemStr
    },
    formatData (value, format) {
      return helpers.format.formatData(value, format)
    }

  }
}
</script>
<style>
.vertical_icon {
  padding-right: 0.5em;
}
</style>
