!<template>
  <td
    v-if="header.value === 'prospect_type_text'"
    :key="header.value"
  >
    <v-row
      align="center"
      class="flex-nowrap"
      no-gutters
    >
      <v-col
        cols="8"
      >
        {{ $t(props.item[header.value]) }}
      </v-col>
      <v-col
        v-if="props.item.prospect_type"
        cols="4"
        class="text-left"
      >
        <v-btn
          fab
          x-small
          elevation="0"
          class="no-hover"
          :class="props.item.prospect_type"
        >
          <v-icon>
            {{ props.item.prospect_type }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </td>

  <td
    v-else-if="header.value === 'prospect_fullscreen_status' || header.value === 'prospect_status'"
    :key="header.value"
    class="text-center"
  >
    <prospect-status-column :item="props.item" />
  </td>
  <td
    v-else-if="header.value === 'corporation_name'"
    :key="header.value"
  >
    <v-row
      align="center"
    >
      <v-col
        cols="8"
        class="linked px-0"
        @click="additionalAction(header, props.item)"
      >
        {{ formatData(props.item, header.value, header.format) }}
      </v-col>
      <v-col
        v-if="props.item.key_account_customer"
        cols="4"
        class="px-0 text-left"
      >
        <v-icon color="warning">
          grade
        </v-icon>
      </v-col>
    </v-row>
  </td>
  <td
    v-else-if="header.value === 'tenant'"
    :key="header.value"
  >
    <TenantColumn
      :header="header"
      :props="props"
    /> 
  </td>
  <td
    v-else-if="header.value === 'success_likelihood'"
    :key="header.value"
    class="text-center"
  >
    <SuccessLikelihoodColumn
      :header="header"
      :props="props"
      :title="title"
    /> 
  </td>
  <td
    v-else-if="header.value === 'prospect_description'"
    :key="header.value"
    class="linked"
    @click="additionalAction(header, props.item)"
  >
    {{ formatData(props.item, header.value, header.format) }}
  </td>
  <td
    v-else-if="header.value === 'renegotiations'"
    :key="header.value"
  >
    <renegotiations-column
      :header="header"
      :item="props.item"
      :items="items"
    />
  </td>
  <td
    v-else-if="header.value === 'renewal'"
    :key="header.value"
  >
    <renewal-column
      :header="header"
      :item="props.item"
      :items="items"
    />
  </td>
  <td
    v-else-if="header.value === 'termination_reason' && props.item.layer === 0"
    :key="header.value"
  >
    <termination-reason-column
      :header="header"
      :item="props.item"
    />
  </td>
  <td 
    v-else-if="header.value === 'confirmed' || header.value === 'prediction' || header.value === 'target' || header.value === 'currentFree'"
    :key="header.value"
    class="text-right"
  >
    <v-tooltip
      v-if="showTooltip(props.item, header.value)"
      right
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          {{ formatDataNull(props.item, header.value, header.format) }}
        </div>
      </template>
      <div>
        <span
          v-for="row in props.item.subItems[header.value]"
          :key="row.text"
        >{{ row.text + ': ' + formatWithValue(row.value, header.format) }}  <br></span>
      </div>
    </v-tooltip>
    <span v-else> {{ formatDataNull(props.item, header.value, header.format) }}</span>
  </td>
  <td
    v-else-if="header.value === 'rental_type_translated'"
    :key="header.value"
  >
    <rental-type-column
      :item="props.item"
      :header="header"
    />
  </td>
  <td v-else-if="header.value === 'rentalSituation'">
    <RentalSituationColumn 
      :header="header"
      :props="props"
      :item="props.item"
      :browse="header.noDescription ? !header.noDescription : true"
    />
  </td>
  <td v-else-if="header.value === 'unit_code_time_alert'">
    <unit-code-time-alert-column 
      :props="props" 
      :header="header"
    />
  </td>
  <td v-else-if="header.value === 'unit_end_date_alert'">
    <unit-end-date-alert 
      :props="props" 
      :header="header"
    />
  </td>
  <td v-else-if="header.value === 'rentingProcessTitle' || header.value === 'process_title'">
    <renting-process-title-column 
      :header="header"
      :props="props"
      :item="item"
    />
  </td>
</template>

<script>
import helpers from '../../helpers'
import { mapActions, mapState } from 'vuex'
import TenantColumn from './LeasingColumns/TenantColumn.vue'
import RentalSituationColumn from "./LeasingColumns/RentalSituationColumn.vue"
import SuccessLikelihoodColumn from "./LeasingColumns/SuccessLikelihoodColumn.vue"
import RentalTypeColumn from './LeasingColumns/RentalTypeColumn.vue'
import TerminationReasonColumn from './LeasingColumns/TerminationReasonColumn.vue'
import ProspectStatusColumn from './LeasingColumns/ProspectStatusColumn.vue'
import UnitCodeTimeAlertColumn from './LeasingColumns/UnitCodeTimeAlertColumn'
import UnitEndDateAlert from './LeasingColumns/UnitEndDateAlert.vue'
import RenewalColumn from './LeasingColumns/RenewalColumn.vue'
import RenegotiationsColumn from './LeasingColumns/RenegotiationsColumn.vue'
import RentingProcessTitleColumn from './LeasingColumns/RentingProcessTitleColumn.vue'


export default {
    name: "LeasingTableColumns",
    components: {
      TenantColumn,
      RentalSituationColumn,
      SuccessLikelihoodColumn,
      RentalTypeColumn,
      TerminationReasonColumn,
      ProspectStatusColumn,
      UnitCodeTimeAlertColumn,
      UnitEndDateAlert,
      RenewalColumn,
      RenegotiationsColumn,
      RentingProcessTitleColumn

    },
    props: {
      header: { type: Object, default: null },
      item: { type: Object, default: null },
      props: { type: Object, default: null },
      title: { type: String, default: ''},
      items: { type: Array, default: () => []}
    },
    data () {
        return {

        }
    },
    computed: {
        ...mapState('leasing', ['prospects', 'prospectsLoading']),
    },
    methods: {
        ...mapActions('leasing', ['getCorporationExtraById', 'changeProspectModalState']),
        ...mapActions('rentalProcess', ['openRentalProcess']),
        formatData (row, header, format, isLink = false) {
            const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
            return helpers.format.formatData(value, format)
        },
        formatDataNull (row, header, format, isLink = false) {
           const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
            if (value == null) {
              return '-'
            }
            return helpers.format.formatData(value, format)
        },
        formatWithValue (value, format) {
          return helpers.format.formatData(value, format)
        },
        additionalAction (header, item) {
            switch (header.value) {
              case 'corporation_name':
                this.getCorporationExtraById({id: item.id_corporation ?? item.id})
                break
              case 'prospect_description':
                if(item.id_process){
                  this.openRentalProcess({ id: item.id_process })
                }
                else if(this.prospects.length === 0 && this.prospectsLoading === false) 
                {
                  this.$emit('getProspects', () => this.changeProspectModalState(item.id_prospect))
                }
                else
                {
                  this.changeProspectModalState(item.id_prospect)
                }
                break
              default:
                break
            }
        },
        showTooltip (item, header) {
          if (item.subItems != null && item.subItems[header] != null && item.subItems[header].length > 0) {
            return true
          }
          return false
        }
    }
}
</script>

<style scoped>
.spa {
  background-color: #4caf50 !important;
  color: white !important;
}
.autorenew {
  background-color: var(--c-color-accent) !important;
  color: white !important;
}
.icecream {
  background-color: #f389d6 !important;
  color: white !important;
}
.construction {
  background-color: #9b2ac9 !important;
  color: white !important;
}
.no-hover {
  pointer-events: none;
}
.active {
  background-color: var(--c-color-accent) !important;
}
.inactive {
  background-color: #ff5252 !important;
}
.closed,
.success {
  background-color: #4caf50 !important;
}
.awaitingallocation {
  background-color: #fb8c00 !important;
  height: 35px !important;
}
.prospectpending {
  background-color: #707070 !important;
}
.notrelevant {
  background-color: #acabab !important;
}
.high_likelihood {
  background-color: #bddaaa !important;
}
.intermediate_likelihood {
  background-color: #ffe682 !important;
}
.low_likelihood {
  background-color: #f27d7d !important;
}
.transparent {
  background-color: transparent !important;
}
.extra-height {
  height: 35px !important;
}
</style>