<template>
  <div class="v3viewer">
    <div :id="containerId" />
    <v-row
      class="toolbar"
    >
      <v-spacer />
      <v-btn
        text
        rounded
        outlined
        :input-value="v32DMode"
        @click="toggle2DMode"
      >
        {{
          $t('Flat')
        }}
      </v-btn>
      <v-btn
        v-for="model in currentModels"
        :key="model.name"
        text
        rounded
        outlined
        :input-value="model.visible"
        :disabled="model.loading"
        @click="v3ToggleModel(model)"
      >
        <v-icon>layers</v-icon>
        {{ $t(model.discipline + ' Model') }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import loglevel from 'loglevel'
import scriptjs from 'scriptjs'

export default {
  props: {
    mapStyle: {
      type: String,
      default: 'None'
    },
    sessionToken: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '1,1,1,1' // white
    },
    availableModels: {
      default: () => {
        return []
      },
      type: Array
    },
    models: {
      default: () => {
        return []
      },
      type: Array
    },
    floor: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      v3: null,
      containerId: 'v3container',
      currentModels: [],
      v32DMode: false,
      modelSelections: [],
      v3ModelOptions: {
        shadows: false,
        ambientOcclusion: false,
        showProperties: false,
        contextMenu: true,
        projectIconUrl: null,
        projectIconScale: 0.5
      },
      localVektorioEnv: null
    }
  },
  computed: {
    v3cssUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_CSS_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_CSS_URL : process.env.VUE_APP_V3_CSS_URL_GCP
    },
    v3scriptUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_SCRIPT_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_SCRIPT_URL : process.env.VUE_APP_V3_SCRIPT_URL_GCP
    }
  },
  watch: {
    async availableModels (models) {
      loglevel.info('models updated', models)
      await this.removeModels()
      this.currentModels = []
      this.initializeModels()
      this.updateModelSelections()
    },
    floor (floor) {
      loglevel.info('floor changed', floor)
      this.selectFloor(floor)
      this.colorByCategory()
    },
    category (category) {
      loglevel.info('category changed', category)
      this.colorByCategory()
    }
  },
  beforeDestroy: function () {
    // We need to destroy v3 before leaving because it will break any further initializations
    if (typeof this.v3 !== 'undefined' && this.v3 !== null) {
      this.v3.clear()
    }
  },
  async mounted () {
    loglevel.info('V3 IFC component mounted!')
    if(window.location.hostname.includes('localhost'))
    {
      this.localVektorioEnv = await this.$rambollfmapi.accounts.vektorio.localenvironment.get()
    }
    // bootstrap V3 viewer
    const self = this
    if (typeof window.V3 === 'undefined') {
      scriptjs(this.v3scriptUrl, () => {
        self.initialize()
      })
    } else {
      self.initialize()
    }
  },
  methods: {
    toggle2DMode () {
      this.v32DMode = !this.v32DMode
      this.v3.set2DMode(this.v32DMode)
    },
    selectLayers () {},
    initializeV3 () {
      loglevel.info(
        'Initializing V3 in container ' +
          this.containerId +
          ' with token ' +
          this.sessionToken
      )
      this.v3 = new window.V3(this.containerId, {
        shadows: false,
        ambientOcclusion: false,
        showProperties: false,
        contextMenu: false,
        projectIconUrl: '',
        projectIconScale: 0.5,
        serverToken: process.env.VUE_APP_V3_WRAPPER_SERVER_TOKEN
      })
      this.v3.set2DMode(false)
      this.v3.setRotateWithLeftButton(false)
      this.v3.setSessionToken(this.sessionToken)
      this.v3.setMapStyle(
        window.V3.MapStyleNone,
        false,
        this.backgroundColor.split(',')
      )
      // let the user know that v3 is ready to be used
      this.$emit('ready', this.v3)
    },
    v3ToggleModel (model) {
      return new Promise((resolve, reject) => {
        // find existing model
        const existing = this.currentModels.find(
          m => !m.loading && m.name === model.name
        )
        if (existing !== undefined) {
          if (existing.visible) {
            // remove model from v3
            this.v3.removeModel(existing.projectId, existing.modelHandle)
            existing.visible = false
          } else {
            existing.loading = true
            this.v3AddModel(model, {
              ...this.v3ModelOptions,
              backendModelId: model.modelId,
              transparentBackfaceCulling: model.discipline !== 'Space'
            })
              .then(async loadedModel => {
                existing.loading = false
                existing.visible = true
                existing.projectId = loadedModel[0].projectId
                existing.modelHandle = loadedModel[0].modelHandle
                const spaces = await this.v3SearchElementIdByIfcClass(
                  loadedModel[0].projectId,
                  'IfcSpace'
                )
                existing.spaces = spaces
                // toggle floors
                if (this.floor !== undefined) {
                  this.selectFloor(this.floor)
                  this.colorByCategory()
                }
                resolve()
              })
              .catch(reject)
          }
        } else {
          reject(new Error('No model loaded'))
        }
      })
    },
    v3AddModel (model, options) {
      return new Promise((resolve, reject) => {
        this.v3.addModel(
          model.name,
          model.url,
          options,
          undefined,
          loadedData => {
            if (loadedData.error) {
              loglevel.error('Error importing model:', loadedData)
              reject(loadedData.error)
            }
            loglevel.info('Loaded model', loadedData)

            resolve(loadedData)
          }
        )
      })
    },
    v3GetStoreys (projectId) {
      return new Promise((resolve, reject) => {
        this.v3.getStoreys(projectId, data => {
          resolve(data)
        })
      })
    },
    v3SearchElementIdByIfcClass (projectId, ifcClass) {
      return new Promise((resolve, reject) => {
        this.$log.info('query spaces', projectId, ifcClass)
        this.v3.searchElements(projectId, [['Ifc-Class', ifcClass]], resolve)
      })
    },
    v3SearchElementIdByIfcGlobalId (projectId, ifcGlobalId) {
      return new Promise((resolve, reject) => {
        this.v3.searchElements(
          projectId,
          [['Ifc-GlobalId', ifcGlobalId]],
          resolve
        )
      })
    },
    v3SearchElementIdByProperty (projectId) {
      return new Promise((resolve, reject) => {
        this.v3.searchElements(
          projectId,
          [
            [
              'Properties.Pset_BuildingElementProxyCommon.Reference.value',
              'Passive RFID Sensor'
            ]
          ],
          resolve
        )
      })
    },
    async removeModels () {
      for (const model of this.currentModels) {
        await this.v3.removeModel(model.projectId, model.modelHandle)
      }
    },
    async initializeModels () {
      if (this.v3 === null) {
        loglevel.warn('V3 not yet defined')
        return
      }

      if (this.models === undefined || this.models.length === 0) {
        loglevel.warn('No models provided')
        return
      }

      loglevel.info('Adding ' + this.models.length + ' models...')
      loglevel.info(this.models)

      for (const model of this.models) {
        // Is this model already loaded?
        const existing = this.currentModels.find(
          m => !m.loading && m.name === model.name
        )
        if (existing === undefined) {
          loglevel.info('Model ' + model.name + ' not yet loaded. loading...')
          const newModel = {
            ...model,
            loading: true,
            visible: false
          }
          this.currentModels.push(newModel)
          const loadedModel = await this.v3AddModel(model, {
            ...this.v3ModelOptions,
            backendModelId: model.modelId,
            transparentBackfaceCulling: model.discipline !== 'Space'
          })
          // Load related data
          const storeys = await this.v3GetStoreys(loadedModel[0].projectId)
          newModel.storeys = storeys[0].storeys
          const spaces = await this.v3SearchElementIdByIfcClass(
            loadedModel[0].projectId,
            'IfcSpace'
          )
          newModel.spaces = spaces
          newModel.loading = false
          newModel.visible = true // loading makes the model visible
          newModel.modelHandle = loadedModel[0].modelHandle
          newModel.projectId = loadedModel[0].projectId

          loglevel.info(this.floor)
          if (this.floor !== undefined) {
            this.selectFloor(this.floor)
            this.colorByCategory()
          }
          // Let the user know that the model is actually loaded
          this.$emit('model-loaded', {
            model: newModel
          })
        } else {
          if (existing.loading) {
            loglevel.info('Model ' + model.name + ' is loading. Wait...')
          } else {
            loglevel.info('Model ' + model.name + ' is already loaded.')
          }
        }
      }
    },
    updateModelSelections () {
      this.modelSelections = this.availableModels.map(m => {
        return {
          ...m,
          selected: false
        }
      })
    },
    initialize () {
      this.initializeV3()
      this.initializeModels()
      this.updateModelSelections()

      loglevel.info('Initialization ready')
      this.$emit('ready', {})
    },
    selectFloor (floor) {
      loglevel.info('selecting floor', floor)
      this.currentModels
        .filter(m => m.visible && !m.loading)
        .forEach(m => {
          let storey = null
          if (floor === null) {
            // Show all stories
            loglevel.info('Showing all stories')
            m.storeys.forEach((s, idx) => {
              this.v3.showStorey(
                [
                  {
                    projectId: m.projectId,
                    storeys: m.storeys,
                    modelHandle: m.modelHandle
                  }
                ],
                0,
                idx
              )
            })
          } else {
            // Show only the storey that matches the name of floor
            storey = m.storeys.find(s => s.name.toUpperCase().replace(' ', '') === floor.floor_name.toUpperCase().replace(' ', ''))
            if (!storey) {
              loglevel.error('No storey found for floor:', floor.floor_name)
            }
            loglevel.info('hiding all stories except:', storey)
            m.storeys.forEach((s, idx) => {
              if (!storey || s.name !== storey.name) {
                this.v3.hideStorey(
                  [
                    {
                      projectId: m.projectId,
                      storeys: m.storeys,
                      modelHandle: m.modelHandle
                    }
                  ],
                  0,
                  idx
                )
              } else {
                this.v3.showStorey(
                  [
                    {
                      projectId: m.projectId,
                      storeys: m.storeys,
                      modelHandle: m.modelHandle
                    }
                  ],
                  0,
                  m.storeys.findIndex(as => as.name === storey.name)
                )
              }
            })
            this.v3.forceUpdate()
          }
          this.$emit('floor-updated')
        })
    },
    colorByCategory () {
      loglevel.info('coloring', this.category)
      this.currentModels
        .filter(m => m.visible && !m.loading)
        .forEach(m => {
          m.spaces.forEach(s => {
            // const cat = s.Properties.Pset_SpaceCommon.Category.value
            // const value = s.Properties.Pset_SpaceCommon.Reference.value
            if (typeof s.Properties['Ramboll Circle'] !== 'undefined') {
              this.v3.setElementColor(s.InternalId, [0, 0, 0, 0.1])
              if (this.category === null) {
                return
              }
              const unitNumber = s.Properties['Ramboll Circle'].Huoneistonumero
                ? s.Properties['Ramboll Circle'].Huoneistonumero.value
                : s.Properties['Ramboll Circle'].Huonenumero.value
              // const unitUsage =
              //   s.Properties['Ramboll Circle']['Käyttötarkoitus'].value
              this.category.values.forEach(c => {
                // category has multiple rooms
                const exists = c.elements.find(e => unitNumber.includes(e))
                if (exists) {
                  const color = [
                    ...c.color.split(',').map(c => Number(c) / 255),
                    0.9
                  ]
                  this.v3.setElementColor(s.InternalId, color)
                }
              })
            }
          })
        })
    }
  }
}
</script>
<style scoped>
.v3viewer {
  position: relative;
  height: 99%;
}

.v3viewer > div {
  position: relative;
}

.v3viewer .toolbar {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: none !important;
}
.v3viewer .toolbar .v-btn {
  background: white;
}
</style>
