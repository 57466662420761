<template>
  <BaseModal
    :is-loading="isLoading"
    @cancel="$emit('cancel')"
  >
    <template #title>
      {{ $t('site.building_project') }}
    </template>
    <template #content>
      <v-expansion-panels
        :value="[0, 1]"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row
              align="center"
              no-gutters
            >
              <v-icon class="mr-2">
                business
              </v-icon>
              {{ $t('site.building_projects.header') }}
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list
              dense
              class="info-box"
            >
              <v-list-item
                v-for="item in buildingProject"
                :key="item.text"
                class="tile"
              >
                <v-list-item-content class="tile-content">
                  {{ translateText(item.header) }}
                </v-list-item-content>
                <v-list-item-content class="tile-content">
                  <span v-if="item.header === 'external_url'">
                    <a
                      :href="item.value"
                      target="_blank"
                      rel="noreffer"
                    >
                      {{ item.value }}
                    </a>
                  </span>
                  <span v-else>
                    {{ formatData(item) }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="buildingProjectSchedules.length > 0">
          <v-expansion-panel-header>
            <v-row
              align="center"
            >
              <v-icon class="mr-2">
                access_time
              </v-icon>
              {{ $t('building_projects.schedules') }}
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list
              dense
              class="info-box"
            >
              <v-list-item
                v-for="item in buildingProjectSchedules"
                :key="item.id"
                class="tile"
              >
                <v-list-item-content class="tile-content">
                  {{ $t('building_projects.' + item.type) }}
                </v-list-item-content>
                <v-list-item-content class="tile-content">
                  <span>
                    <span v-if="item.done_date == null">{{ humanizeDate(item.target_date, 'date') }}</span>
                    <span v-else>{{ humanizeDate(item.done_date, 'date') }}<v-icon>check</v-icon>
                    </span>
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="valueInfo.length > 0">
          <v-expansion-panel-header>
            <v-row
              align="center"
              justify="start"
            >
              <v-icon class="mr-2">
                info
              </v-icon>
              {{ $t('building_projects.buildingprojectvalueinformation') }}
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list
              dense
              class="info-box"
            >
              <v-list-item>
                <v-list-item-content class="value-info-header value-info-header-id">
                  {{ $t('building_projects.buildingprojectvalueinformation_identifier') }}
                </v-list-item-content>
                <v-list-item-content class="value-info-header value-info-header-name">
                  {{ $t('building_projects.buildingprojectvalueinformation_name') }}
                </v-list-item-content>
                <v-list-item-content class="value-info-header value-info-header-value">
                  {{ $t('building_projects.buildingprojectvalueinformation_value') }}
                </v-list-item-content>
                <v-list-item-content class="value-info-header value-info-header-date">
                  {{ $t('building_projects.buildingprojectvalueinformation_begin_date') }}
                </v-list-item-content>
                <v-list-item-content class="value-info-header value-info-header-date">
                  {{ $t('building_projects.buildingprojectvalueinformation_end_date') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in valueInfo"
                :key="index"
                class="tile"
              >
                <v-list-item-content class="tile-content-10 value-info-id">
                  {{ item.id }}
                </v-list-item-content>
                <v-list-item-content class="tile-content-45">
                  {{ item.name }}
                </v-list-item-content>
                <v-list-item-content class="tile-content-15 value-info-value">
                  {{ item.value }}
                </v-list-item-content>
                <v-list-item-content class="tile-content-15 value-info-date">
                  {{ formatDate(item.begin_date) }}
                </v-list-item-content>
                <v-list-item-content class="tile-content-15 value-info-date">
                  {{ formatDate(item.end_date) }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex'
import BaseModal from './general/BaseModal.vue'
import helpers from '../helpers'

export default {
  name:'BuildingProjectValueInformation',
  components: {
    BaseModal
  },
  props: {
    buildingProjectId: {
      default: 0,
      type: Number, 
    },
    buildingProject: {
      default: () => {
        return null
      },
      type: Array
    }
  },
  data () {
    return {
      isLoading: undefined,
      valueInfo: [],
      buildingProjectSchedules: [],
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
  },
  watch: {
    buildingProject: {
      handler: async function () {
        this.isLoading = true
        await this.valueInformationsTable()
        await this.retrieveBuildingProjectSchedules()
        this.isLoading = false
      },
      deep: true
    }
  },
  async mounted () {
    this.isLoading = true
    await this.valueInformationsTable()
    await this.retrieveBuildingProjectSchedules()
    this.isLoading = false
  },
  methods: {
    humanizeDate: helpers.humanize.date,
    async retrieveBuildingProjectSchedules () {
      // Validate inputs
      if (this.buildingProjectId === undefined || this.buildingProjectId === null) {
        return;
      }
      let buildingProject = await this.$rambollfmapi.buildingProjects.get(this.buildingProjectId, this.currentDate);
      if (buildingProject.project_schedules !== null) {
        this.buildingProjectSchedules = buildingProject.project_schedules
          .filter(s => (s.target_date !== null || s.done_date !== null)) // There must be date!
          .filter(s => s.type !== 'Activation' && s.type !== 'Unknown') // No Activation type schedules
          .sort((a, b) => {
            // Sort schedules by this preferred order
            let preferredOrder = [
              'Requirement',
              'Project',
              'Purchase',
              'Acceptance',
              'GuaranteeRelease',
              'RequirementAcceptance',
              'Suspension',
              'Unknown'
            ]

            let idxA = preferredOrder.indexOf(a.type)
            let idxB = preferredOrder.indexOf(b.type)

            return idxA - idxB
          })
      } else {
        return null
      }
    },
    async valueInformationsTable () {
      this.valueInfo = []
      let result = await this.$rambollfmapi.buildingProjects.getValueInformation(this.buildingProjectId, this.currentDate)
      result.map(vi => {
        this.valueInfo.push({
          id: vi.buildingprojectvalueinformation_identifier,
          name: vi.buildingprojectvalueinformation_name,
          value: vi.value,
          begin_date: vi.start_date,
          end_date: vi.end_date
        })
      })
    },
    formatData (item) {
      if (item.format) {
        return helpers.format.formatData(item.value, item.format)
      } else {
        return this.$t(item.value)
      }
    },
    formatDate (date) {
      if (date == undefined) {
        return "-"
      } else {
        return helpers.format.formatData(date, 'Date')
      }
    },
    translateText (text) {
      let translatedText = text

      switch (text) {
        case 'building_identifier':
          translatedText = this.$t('Building identifier')
        break
        case 'building_code':
          translatedText = this.$t('Building code')
        break
        case 'buildingproject_identifier':
          translatedText = this.$t('Buildingproject identifier')
        break
        case 'buildingproject_number':
          translatedText = this.$t('Buildingproject number')
        break
        case 'name':
          translatedText = this.$t('Name')
        break
        case 'purpose_of_use_with_denomination':
          translatedText = this.$t('Purpose of use with denomination')
        break
        case 'unit_area':
          translatedText = this.$t('Unit area')
        break
        case 'landlord':
        case 'owner':
          translatedText = this.$t('Owner')
        break
        case 'ownership_relation':
        case 'buildingproject_ownership_relation':
          translatedText = this.$t('Buildingproject ownership relation')
        break
        case 'property_maintenance_name':
          translatedText = this.$t('Property maintenance name')
        break
        case 'property_maintenance_phone':
          translatedText = this.$t('Property maintenance phone')
        break
        case 'property_maintenance_email':
          translatedText = this.$t('Property maintenance email')
        break
        case 'situation_code':
          translatedText = this.$t('Situation code')
        break
        case 'situation_description':
          translatedText = this.$t('Situation description')
        break
        case 'project_type':
          translatedText = this.$t('BuildingProjectType')
        break
        case 'project_type_description':
          translatedText = this.$t('BuildingProjectTypeDescription')
        break
        case 'status':
          translatedText = this.$t('BuildingProjectStatus')
        break
        case 'customer':
          translatedText = this.$t('Customer')
        break
        case 'financing_type':
          translatedText = this.$t('FinancingType')
        break
        case 'total_financing_provision':
          translatedText = this.$t('TotalFinancingProvision')
        break
        case 'acceptance_date':
          translatedText = this.$t('AcceptanceDate')
        break
        case 'external_url':
          translatedText = this.$t('ExternalUrl')
        break
        case 'start_date':
          translatedText = this.$t('Start date')
        break
        case 'end_date':
          translatedText = this.$t('End date')
        break
        default:
          translatedText = this.$t('building_projects.' + text)
      }

      return translatedText
    }
  }
}
</script>

<style scoped>
.value-info-id {
  font-weight: normal !important;
  max-width: 10% !important;
  border: 1px solid #ebeef8;
  border-top: 2px solid #ebeef8;
  border-bottom: 2px solid #ebeef8;
}
.value-info-value {
  font-weight: normal !important;
  max-width: 20% !important;
}
.value-info-date {
  font-weight: normal !important;
  max-width: 15% !important;
}
.value-info-header {
  background-color: #b3e5fc;
  font-size: 14px;
  opacity: 0.6;
  padding-top: 1.25em !important;
  padding-left: 0.5em !important;
  margin-bottom: -0.15em !important;
}
.value-info-header-id {
  max-width: 10%;
}
.value-info-header-name {
  min-width: 45%;
}
.value-info-header-value {
  max-width: 15%;
}
.value-info-header-date {
  max-width: 15%;
}
</style>