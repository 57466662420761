<template>
  <v-col class="shrink">
    <v-menu
      v-model="actionMenuOpen"
      :close-on-content-click="false"
      :close-on-click="false"
      top
      offset-y
    >
      <template
        #activator="{ on }"
      >
        <v-btn
          inline-flex
          text
          icon
          outlined
          @click="actionMenuOpen = !actionMenuOpen"
          v-on="on"
        >
          <v-icon>menu</v-icon>
          <span class="d-sr-only">{{ $t('Menu') }}</span>
        </v-btn>
      </template>
      <v-list
        shrink
      >
        <template v-for="item in actionMenuItems">
          <!-- Color selection -->
          <v-list-item
            v-if="item.name === 'Select color'"
            :key="item.name"
          >
            <v-tooltip
              left
            >
              <template #activator="{ on: onTooltip }">
                <v-menu
                  left
                  nudge-left="50"
                >
                  <template
                    #activator="{ on: onMenu }"
                  >
                    <v-btn
                      id="color-picker-activator"
                      :style="{
                        backgroundColor: selectedColor,
                      }"
                      tiny
                      v-on="{ ...onMenu, ...onTooltip }"
                    />
                  </template>
                  <v-color-picker
                    v-model="selectedColor"
                    dot-size="25"
                    @click.native.stop
                  />
                </v-menu>
              </template>
              <span>{{ $t(item.name) }}</span>
            </v-tooltip>
          </v-list-item>

          <!-- Rectangle text creation -->
          <v-list-item
            v-else-if="item.name === 'Text input'"
            :key="item.name"
          >
            <v-tooltip
              left
            >
              <template #activator="{ on: onTooltip }">
                <v-menu
                  left
                  nudge-left="50"
                >
                  <template
                    #activator="{ on: onMenu }"
                  >
                    <v-icon v-on="{ ...onMenu, ...onTooltip }">
                      text_fields
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-text class="pb-0">
                      <v-textarea
                        v-model="textRectangleValue"
                        @click.native.stop
                      />
                    </v-card-text>
                    <v-btn
                      rounded
                      depressed
                      class="primary"
                      @click="startMenuAction(item.name, textRectangleValue)"
                    >
                      {{ $t('viewerToolSelector.addText') }}
                    </v-btn>
                  </v-card>
                </v-menu>
              </template>
              <span>{{ $t(item.name) }}</span>
            </v-tooltip>
          </v-list-item>

          <!-- The rest -->
          <v-list-item
            v-else
            :key="item.name"
            :disabled="!item.icon"
            shrink
            @click="startMenuAction(item.name)"
          >
            <v-tooltip
              v-if="item.icon"
              left
            >
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                >
                  {{ item.icon }}
                </v-icon>
              </template>
              <span>{{ $t(item.description ?? item.name) }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-col> 
</template>

<script>
export default {
  name: 'ViewerToolSelector',
  data () {
    return {
      actionMenuItems: [
        { name: 'Select color', icon: '' },
        { name: 'Calculate area', icon: 'square_foot' },
        { name: 'Measure distance', icon: 'straighten' },
        { name: 'spacer-1', icon: null },
        { name: 'Free pen', icon: 'create' },
        { name: 'Text input', icon: 'text_fields' },
        { name: 'Remove markup', icon: 'clear' },
        { name: 'spacer-2', icon: null },
        { name: 'Download', icon: 'get_app' },
        { name: 'Save', description: 'v3viewer.saveView', icon: 'save' },
        { name: 'Clear', icon: 'delete' }
      ],
      selectedColor: '#FF0000',
      actionMenuOpen: false,
      textRectangleValue: '',
    }
  },
  watch: {
    selectedColor: function () {
      this.$emit('colorPicked', this.selectedColor)
    }
  },
  methods: {
    startMenuAction (tool, text) {
      this.actionMenuOpen = false
      this.$emit('toolSelected', tool, text)
    }
  }
}
</script>

<style scoped>
.active_icon {
  color: #009df0 !important;
}
.default_icon {
  color: #797766 !important;
}
#color-picker-activator {
  border-radius: 2em;
  border: 0.15rem solid #797766;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  min-width: 24px;
  justify-content: end;
}
.rectangle-text-card {
  position: fixed;
}
</style>