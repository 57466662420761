<template>
  <div style="padding: 0.5em">
    <v-row
      justify="end"
      no-gutters
    >
      <v-btn
        v-if="edit && !addingnew"
        text
        rounded
        class="error"
        :disabled="saving || deleting"
        @click="deleteField"
      >
        <span>{{ $t('Delete field') }}
        </span>
      </v-btn>
    </v-row>
    <v-expansion-panels
      :value="[0]"
      multiple
    >
      <v-expansion-panel
        expand
        class="no-padding"
      >
        <v-expansion-panel-header v-if="!addingnew">
          {{ $t('Additional information field') +' '+ (dataindex + 1) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-if="saving"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <v-list
            v-else
            dense
            class="info-box"
          >
            <v-list-item class="tile">
              <v-list-item-content :class="edit ? 'tile-content-40' : 'tile-content'">
                {{ $t('Field enabled') }}
              </v-list-item-content>
              <v-list-item-content :class="edit ? 'tile-content-60' : 'tile-content'">
                <v-switch
                  v-model="handledItems.enabled"
                  :readonly="!edit"
                  hide-details="true"
                  class="slim without-bg"
                  dense
                  @change="changeItem('enabled')"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="tile">
              <v-list-item-content :class="edit ? 'tile-content-40' : 'tile-content'">
                {{ $t('Field name') }}
              </v-list-item-content>
              <v-list-item-content
                v-if="!edit"
                :class="edit ? 'tile-content-60' : 'tile-content'"
              >
                {{ handledItems.name }}
              </v-list-item-content>
              <v-list-item-content
                v-else
                class="tile-content-60"
              >
                <v-text-field
                  v-model="handledItems.name"
                  :placeholder="$t('Field name')"
                  required
                  class="borderless"
                  append-icon="edit"
                  @change="changeItem('name')"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(field, idx) in handledItems.customizableDataFieldValues"
              :key="handledItems.name + '_' + idx + '_' + field.id"
              class="tile"
            >
              <v-list-item-content :class="edit ? 'tile-content-40' : 'tile-content'">
                {{ $t('Field value') }}
              </v-list-item-content>
              <v-list-item-content
                v-if="!edit"
                class="tile-content"
              >
                {{ field.value }}
              </v-list-item-content>
              <v-list-item-content
                v-else
                :class="handledItems.customizableDataFieldValues.length > 1 ? 'tile-content' : 'tile-content-60'"
              >
                <v-text-field
                  v-model="field.value"
                  :placeholder="$t('Field name')"
                  required
                  class="borderless"
                  append-icon="edit"
                  @change="changeItem('fieldValue', field)"
                />
              </v-list-item-content>
              <v-list-item-content 
                v-if="edit && handledItems.customizableDataFieldValues.length > 1"
                class="tile-content-10"
              >
                <v-icon
                  color="red"
                  @click="removeFieldValue(field.id, idx)"
                >
                  close
                </v-icon>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div>
      <v-row
        justify="start"
        align-content="start"
        no-gutters
      >
        <v-btn
          v-if="edit"
          outlined
          rounded
          :disabled="saving || deleting"
          color="blue"
          @click="addValueField"
        >
          <span v-if="!saving || !deleting">{{ $t('Add value') }}</span>
        </v-btn>
      </v-row>
      <v-row
        align-content="start"
        justify="end"
        no-gutters
      >
        <v-btn
          v-if="edit && addingnew"
          text
          outlined
          rounded
          :disabled="saving || deleting"
          @click="cancelFieldCreation"
        >
          <span>{{ $t('Cancel') }}</span>
        </v-btn>
        <v-btn
          v-if="edit"         
          rounded
          depressed
          :disabled=" !modifiedInfo || saving || deleting || !handledItems.name || handledItems.customizableDataFieldValues.length === 1 && !handledItems.customizableDataFieldValues[0].value "
          color="primary"
          @click="saveChanges"
        >
          <span v-if="!saving">{{ addingnew ? $t('Save') : $t('Save changes') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdditionalDataFieldsForm',
  props: {
    data: {
      type: Object,
      default: null
    },
    dataindex: {
      type: Number,
      default: null
    },
    addingnew: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      saving: false,
      deleting: false,
      defaultName: this.$t('internalrent.name_the_cost'),
      handledItems: {},
      modifiedName: false,
      modifiedEnabled: false,
      modifiedFieldValue: false,
      customizableDataFieldValues: []
    }
  },
  computed: {
    modifiedInfo () {
      return this.modifiedName || this.modifiedEnabled || this.modifiedFieldValue
    }
  },
  watch: {
    data: {
      handler: function (newVals) {
        this.handledItems = { ...newVals }  
      }
    },
  },
  mounted () {
    this.handledItems = { ...this.data }
  },
  methods: {
    changeItem (name) {
      switch(name) {
        case 'enabled':
        if(this.data.enabled !== this.handledItems.enabled) {
          this.modifiedEnabled = true
        } else {
          this.modifiedEnabled = false
        }
        break
      case 'name':
        if(this.data.name !== this.handledItems.name) {
          this.modifiedName = true
        } else {
          this.modiedName = false
        }
        break
      case 'fieldValue':
        this.modifiedFieldValue = true
        break
      default:
        break
      }
    },
    saveChanges () {
      this.saving = true
      const fieldValues = this.handledItems.customizableDataFieldValues.filter(fieldValue => fieldValue.value !== '' && fieldValue.value !== null)
      const itemsForSaving = { ...this.handledItems,  customizableDataFieldValues: fieldValues}
      this.$emit('saveChanges', itemsForSaving)
      this.resetModifications()
      this.handledItems = {}
      this.saving = false
    },
    deleteField () {
      this.deleting = true
      this.$emit('deleteData', this.handledItems)
      this.deleting = false
    },
    addValueField () {
      this.handledItems.customizableDataFieldValues.push({ value: ''})
      this.modifiedFieldValue = true
    },
    cancelFieldCreation () {
      this.handledItems = {}
      this.$emit('cancel')
    },
    removeFieldValue (id, index) {
      const idx = id ? this.handledItems.customizableDataFieldValues.findIndex(fieldValue => fieldValue.id === id) : index
      this.handledItems.customizableDataFieldValues.splice(idx,1)
      this.modifiedFieldValue = true
    },
    resetModifications () {
      this.modifiedName = false
      this.modifiedEnabled = false
      this.modifiedFieldValue = false
    }
  }
}
</script>
<style scoped>
.slim {
  margin-top: 0px;
}
</style>
