<template>
  <v-card>
    <v-card-title
      class="text-h5 grey lighten-2"
      primary-title
    >
      {{ $t('Allocation code extra info') }}
      <v-spacer />
      <v-btn
        large
        icon
        @click="closeDialog"
      >
        <v-icon>close</v-icon>
        <span class="d-sr-only">{{ $t('Close') }}</span>
      </v-btn>
    </v-card-title>
    <v-col style="padding: 0.5em">
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          expand
          class="no-padding"
        >
          <v-expansion-panel-header>
            <!-- {{ $t('Allocation code extra info') }} -->
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="loading"
              style="text-align: center; padding: 2em;"
            >
              <v-progress-circular
                size="64"
                indeterminate
                color="primary"
              />
            </div>
            <v-data-table
              v-else
              :headers="allocationExtraInfoData.headers"
              :items="allocationExtraInfoData.items"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              class="elevation-0"
            >
              <template 
                slot="footer.page-text"
                slot-scope="item"
              >
                {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                {{ item.itemsLength.toLocaleString('fi-FI') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'AllocationCodeExtraInfo',
    props: {
        allocationCode: {
            type: String,
            default: function () {
                return ''
            }
        },
        selectedDate: {
            type: Date,
            default: function () {
              return new Date()
            }
        }
    },
    data () {
        return {
          loading: false,
          allocationExtraInfoData: {
            headers: [
              {
                text: this.$t('Post office code'),
                value: 'allocationCode'
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenterCode'
              },
              {
                text: this.$t('Cost center name'),
                value: 'costcenter'
              },
              {
                text: this.$t('Cost center description'),
                value: 'costcenterAnnotation'
              },
              {
                text: this.$t('Person count'),
                value: 'personCount',
                format: 'Integer'
              },
            ],
            items: [],
            footers: []
          },
          options: {
            sortDesc: [false],
            page: 1,
            itemsPerPage: -1,
            sortBy: [''],
            totalItems: 0
      }
        }
    },
    computed: {
    ...mapState('app', ['currentDate'])
    },
    async mounted () {
      this.loading = true
      const items = await this.getAllocationExtraInfo({ allocationCode: this.allocationCode, /* costcenterCode: this.costcenterCode, */ time: this.selectedDate})
      this.allocationExtraInfoData.items = items
      this.loading = false
    },
    methods: {
        ...mapActions('personnelAllocation', ['getAllocationExtraInfo']),
        closeDialog () {
            this.$emit('close')
        }
    }
}
</script>