<template>
  <v-btn
    :color="color"
    class="rental-process-button pl-4 pr-8"
    dark
    @click="() => $emit('click', id)"
  >
    <v-row
      class="ml-0 pr-3"
      align="center"
      no-gutters
    >
      <v-icon
        v-if="icon"
        class="rental-process-button-icon"
      >
        {{ icon }}
      </v-icon>
      <p
        v-if="text"
        class="mb-0"
      >
        {{ text }}
      </p>
    </v-row>
  </v-btn>
</template>

<script>

export default {
  name: "RentalProcessButton",
  props: {
    id: { type: Number, default: null },
    color: { type: String, default: null },
    icon: { type: String, default: null },
    text: { type: String, default: null },
    subSelection: { type: Array, default: null },
    showSelect: { type: Boolean, default: false }
  },
  emits: ["click", "onSubSelect"],
};
</script>

<style scoped>
.rental-process-button {
  clip-path: polygon(
    0% 0%,
    calc(100% - 25px) 0%,
    100% 48%,
    100% 52%,
    calc(100% - 25px) 100%,
    0% 100%,
    0% 0%
  );
  min-width: 100px !important;
}
.rental-process-button-text {
  color: white;
  margin-bottom: 0;
}
.rental-process-button-icon {
  color: white;
}
</style>
