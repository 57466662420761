export function unitPermissionValidity () {
  
    return {
      id: "dashboard.unit_permission_validity",
      type: "ObjectTable",
      loading: true,
      data: {
        headers: [
             { text: "Site identifier", value: "identifierSite" },
             { text: "Site name", value: "site_name"},
             { text: "Address", value: "address"},
             { text: "Building tunniste", value: "internalBuildingCode" },
             { text: "Building name", value: "buildingName" },
             { text: "Unit name", value: "unitName"},            
             { text: "Usage", value: "usage" },
             { text: "Unit area", value: "unitArea", format: "Area" },
             { text: "Temporary permit validity", value: "temporaryUnitPermitDate", format: "Date", status: true },
             { text: "Temporary permit desc", value: "temporaryUnitPermitDescription", hideOverflow: true },
             { text: "Actions", value: "customComponent", actionHeader: true }
          ],
        sortBy: "temporaryUnitPermitDate",
        sortDesc: false,
        items: [],
        showEditControl: true,
      }
     }
  }
  
  