<template>
  <div>
    <p v-if="isFixedTerm">
      {{ $t('leasing.prediction_info_text_2') }}
    </p>
    <p v-else>
      {{ $t('leasing.prediction_info_text_3') }}
    </p>
    <p>
      {{ $t('leasing.prediction_info_text_4') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PredictionInfoText',
  props: {
    isFixedTerm: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
p {
  font-style: italic;
}
</style>
