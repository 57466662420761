// Variable lists for different UI lists, like dropdowns

import { i18n } from '../plugins/i18n'

export default {
  monthItems () {
    return [
      {label: i18n.t('January'), id: 1},
      {label: i18n.t('February'), id: 2},
      {label: i18n.t('March'), id: 3},
      {label: i18n.t('April'), id: 4},
      {label: i18n.t('May'), id: 5},
      {label: i18n.t('June'), id: 6},
      {label: i18n.t('July'), id: 7},
      {label: i18n.t('August'), id: 8},
      {label: i18n.t('September'), id: 9},
      {label: i18n.t('October'), id: 10},
      {label: i18n.t('November'), id: 11},
      {label: i18n.t('December'), id: 12}
    ]	
  },
  monthItemswithSuffix () {
    return [
		{label: `1 ${i18n.t('Mth')}`, id: 1},
		{label: `2 ${i18n.t('Mth')}`, id: 2},
		{label: `3 ${i18n.t('Mth')}`, id: 3},
		{label: `4 ${i18n.t('Mth')}`, id: 4},
		{label: `5 ${i18n.t('Mth')}`, id: 5},
		{label: `6 ${i18n.t('Mth')}`, id: 6},
		{label: `7 ${i18n.t('Mth')}`, id: 7},
		{label: `8 ${i18n.t('Mth')}`, id: 8},
		{label: `9 ${i18n.t('Mth')}`, id: 9},
		{label: `10 ${i18n.t('Mth')}`, id: 10},
		{label: `11 ${i18n.t('Mth')}`, id: 11},
		{label: `12 ${i18n.t('Mth')}`, id: 12}
    ]	
  },
  monthsNumbers () {
	return [
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12
	]
  }
}
