<template>
  <BaseModal
    @cancel="cancel"
  >
    <template #title>
      {{ $t('Transfer spaces') }}
    </template>
    <template #content>
      <v-row
        justify="space-between"
      >
        <v-col
          md="6"
        >
          <v-row
            class="align-stretch flex-column"
            justify="space-around"
          >
            <v-row
              align="center"
              justify="space-around"
            >
              <v-col style="text-align: center">
                <h2 class="text--secondary">
                  {{ $t('Spaces to transfer') }}
                </h2>
              </v-col>
            </v-row>
            <v-row
              v-if="unit != null"
              align="center"
              justify="space-around"
            >
              <v-card
                width="100%"
                class="mx-auto info-card"
                height="100%"
              >
                <div
                  v-for="unit in unitsInSpaces"
                  :key="unit.id"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      view_compact
                    </v-icon>
                    <span class="text-h6">{{ unit.unit_code + ' ' + unit.name }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-list
                      dense
                      class="info-box"
                    >
                      <v-list-item class="tile">
                        <v-list-item-content class="tile-content">
                          {{ $t('Transfering spaces from unit') }}
                        </v-list-item-content>
                      </v-list-item>
                      <div class="scrollable">
                        <table class="v-datatable v-table them--light">
                          <thead>
                            <th class="smallColumn">
                              {{ $t('Space name') }}
                            </th>
                            <th class="smallColumn">
                              {{ $t('Purpose of use') }}
                            </th>
                            <th class="smallColumn">
                              {{ $t('Cost center by unit') }}
                            </th>
                            <th class="smallColumn">
                              {{ $t('Unit area') }}
                            </th>
                            <th class="smallColumn">
                              {{ $t('Allocated unit area') }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="space in getSpacesByUnit(unit.id)"
                              :key="space.id"
                            >
                              <td>{{ space.name }}</td>
                              <td>{{ space.purpose_of_use }}</td>
                              <td>{{ unit.vynro }}&nbsp;{{ unit.vyname }}</td>
                              <td>{{ space.unit_area.toFixed(2) + ' m²' }}</td>
                              <td>{{ getAllocatedArea(space.id) + ' m²' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <v-list-item class="tile">
                        <v-list-item-content class="tile-content">
                          {{
                            $t('Change in unit area')
                          }}
                        </v-list-item-content>
                        <v-list-item-content class="tile-content">
                          <span>
                            {{ getOldAreaForUnit(unit.id) + ' m²' }}
                            <v-icon small>arrow_forward_ios</v-icon>
                            {{ getNewAreaForUnit(unit.id, false) + ' m²' }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="tile">
                        <v-list-item-content class="tile-content">
                          {{
                            $t('Change in allocated unit area')
                          }}
                        </v-list-item-content>
                        <v-list-item-content class="tile-content">
                          <span>
                            {{ getAllocatedUnitAreaByUnit(unit.id, true) + ' m²' }}
                            <v-icon small>arrow_forward_ios</v-icon>
                            {{ getNewAllocatedUnitAreaForUnit(unit.id, true) + ' m²' }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="doesUnitLoseAllSpaces(unit.id)"
                        class="tile"
                      >
                        <v-list-item-content class="tile-content">
                          <span>
                            <v-icon color="red">error_outline</v-icon>
                            {{ $t('Unit will be removed') }}
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </div>
              </v-card>
              <div />
            </v-row>
            <!-- SPACES WITHOUT UNITS -->
            <v-row
              v-if="getSpacesByUnit(null).length > 0"
              align="center"
              justify="space-around"
            >
              <v-card
                width="100%"
                class="mx-auto info-card"
                height="100%"
              >
                <v-card-title>
                  <v-icon
                    large
                    left
                  >
                    meeting_room
                  </v-icon>
                  <span class="text-h6">{{ $t('Spaces without unit') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-list
                    dense
                    class="info-box"
                  >
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Spaces transfering') }}
                      </v-list-item-content>
                    </v-list-item>
                    <div class="scrollable">
                      <table class="v-datatable v-table them--light">
                        <thead>
                          <th class="smallColumn">
                            {{ $t('Space name') }}
                          </th>
                          <th class="smallColumn">
                            {{ $t('Purpose of use') }}
                          </th>
                          <th class="smallColumn">
                            {{ $t('Cost center by unit') }}
                          </th>
                          <th class="smallColumn">
                            {{ $t('Unit area') }}
                          </th>
                          <th class="smallColumn">
                            {{ $t('Allocated unit area') }}
                          </th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="space in getSpacesByUnit(null)"
                            :key="space.id"
                          >
                            <td>{{ space.name }}</td>
                            <td>{{ space.purpose_of_use }}</td>
                            <td>{{ }}</td>
                            <td>{{ space.unit_area.toFixed(2) + ' m²' }}</td>
                            <td>{{ getAllocatedArea(space.id) + ' m²' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Unit area') }}
                      </v-list-item-content>
                      <v-list-item-content class="tile-content">
                        {{
                          getSpacesByUnit(null).reduce(
                            (acc, space) => acc + space.unit_area,
                            0
                          ).toFixed(2) + ' m²'
                        }}
                      </v-list-item-content> 
                    </v-list-item>
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Allocated unit area') }}
                      </v-list-item-content>
                      <v-list-item-content class="tile-content">
                        {{ getAllocatedAreaBySpace() + ' m²' }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-row>
          </v-row>
        </v-col>
        <v-col
          class="d-flex align-center"
        >
          <v-row
            align="center"
            justify="space-around"
            class="flex-column"
          >
            <v-icon size="64">
              arrow_forward
            </v-icon>
          </v-row>
        </v-col>
        <v-col
          md="5"
        >
          <v-row
            align="center"
            justify="space-around"
            class="flex-column"
          >
            <v-row
              align="center"
              justify="start"
            >
              <v-col style="text-align: center">
                <h2 class="text--secondary">
                  {{ $t('Target') }}
                </h2>
              </v-col>
            </v-row>
              
            <v-card
              width="100%"
              class="mx-auto info-card pb-4"
              height="100%"
            >
              <v-radio-group
                v-model="changeType"
                row
                class="pl-2 font-weight-bold mt-4 ml-2"
              >
                <v-radio
                  default
                  :label="$t('Unit')"
                  value="unit"
                  class="font-weight-regular px-2"
                />
                <v-radio
                  :label="$t('No unit')"
                  value="costcenter"
                  class="font-weight-regular px-2"
                />
              </v-radio-group>
              <v-card-title>
                <v-icon
                  large
                  left
                >
                  view_compact
                </v-icon>
                <span
                  class="text-h6"
                  width="100%"
                >
                  <v-select
                    v-if="changeType === 'unit'"
                    v-model="targetUnitCode"
                    :items="unitCodeSelection"
                  />
                  <v-select
                    v-else
                    v-model="targetCostcenter"
                    :items="costcenterSelection"
                  />
                </span>
              </v-card-title>
              <v-card-text>
                <v-list
                  dense
                  class="info-box"
                >
                  <v-list-item class="tile">
                    <v-list-item-content
                      v-if="changeType === 'unit'"
                      class="tile-content"
                    >
                      {{ $t('Transfering spaces to unit') }}
                    </v-list-item-content>  
                  </v-list-item>
                  <div
                    v-if="changeType === 'unit'"
                    class="scrollable"
                  >
                    <table class="v-datatable v-table them--light">
                      <thead>
                        <th class="smallColumn">
                          {{ $t('Space name') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Purpose of use') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Cost center by unit') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Unit area') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Allocated unit area') }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="space in selectedSpaces"
                          :key="space.id"
                        >
                          <td>{{ space.name }}</td>
                          <td>{{ space.purpose_of_use }}</td>
                          <td>{{ targetUnit != null ? targetUnit.vynro : '' }}&nbsp;{{ targetUnit != null ? targetUnit.vyname : '' }}&nbsp;</td> 
                          <td>{{ space.unit_area.toFixed(2) + ' m²' }}</td>
                          <td>{{ getAllocatedArea(space.id) + ' m²' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <v-list-item
                    v-if="changeType === 'unit'"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{ $t('Change in unit area') }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <span>
                        {{ getOldAreaForUnit(targetUnitId) + ' m²' }}
                        <v-icon small>arrow_forward_ios</v-icon>
                        {{ getNewAreaForUnit(targetUnitId, true) + ' m²' }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="changeType === 'unit'"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{ $t('Change in allocated unit area') }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <span>
                        {{ getAllocatedUnitAreaByUnit(targetUnitId, true) + ' m²' }}
                        <v-icon small>arrow_forward_ios</v-icon>
                        {{ transferingAllocatedUnitArea(targetUnitId) + ' m²' }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Space transfer date')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content transferDate">
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            :label="$t('Date')"
                            :value="formatDate(transferDate)"
                            append-icon="calendar_today"
                            v-on="on"
                            @change="onTargetUnitStartDateChangedField"
                          />
                        </template>
                        <v-date-picker
                          v-model="transferDate"
                          no-title
                          scrollable
                          first-day-of-week="1"
                          :locale="$i18n.locale"
                          @change="onTargetUnitStartDateChangedPicker"
                        />
                      </v-menu>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <template>
        <v-btn
          :disabled="!isTransferDateValid || saving || (unit != null && getSpacesByUnit(null).length > 0)"
          color="success"
          text
          class="primary"
          rounded
          @click="transferSpaces()"
        >
          {{ $t('Transfer') }}
          <v-progress-circular
            v-if="saving"
            size="16"
            indeterminate
          />
        </v-btn>
      </template>
    </template>
  </BaseModal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import BaseModal from '../../components/general/BaseModal.vue'
import moment from 'moment'
export default {
  name: 'UnitTransferForm',
  components: { BaseModal },
  props: {
    unitTransferDialog: Boolean,
    unit: {
      type: Object,
      default: null
    },
    units: {
      type: Array,
      default: undefined
    },
    spaces: {
      type: Array,
      default: undefined
    },
    selectedSpaces: {
      type: Array,
      default: undefined
    },
    floor: {
      type: Object,
      default: null
    },
    partyLinks: {
      type: Array,
      default: undefined
    },
    parties: {
      type: Array,
      default: undefined
    },
    editUnitNotNewVersion: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      saving: false,
      targetUnitCode: '',
      targetCostcenter: '',
      transferDate: undefined,
      isTransferDateValid: true,
      startDateMenu: false,
      unitCodeSelection: undefined,
      costcenterSelection: undefined,
      costcentersBySpaces: undefined,
      allocatedAreaAll: 0,
      unitsInSpaces: [],
      allSpaces: [],
      targetUnitId: undefined,
      targetUnit: null,
      changeType: 'unit',
      costcenters: undefined
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById', 'definitionByLabel']),
    ...mapGetters('sites', ['currentSite']),
    ...mapState('app', ['currentDate']),
    transferingUnitArea () {
      return this.selectedSpaces
        .map(s => Number(s.unit_area))
        .reduce((acc, cur) => acc + cur, 0)
        .toFixed(2)
    }
  },
  watch: {
    unit: function () {
      // switching between a space already in an unit and a space without will mess unit_code selection list
      // unless it is updated each time the user selects another space
      this.unitCodeSelectionList()
    },
    targetUnitCode: function () {
      if (typeof this.targetUnitCode !== 'undefined' && this.targetUnitCode !== '') {
        const targetUnitCode = this.targetUnitCode.split(' ')[0]
        const unit = this.units.filter(unit => unit.unit_code === targetUnitCode)
        this.targetUnitId = unit.length > 0 ? unit[0].id : undefined
        this.targetUnit = unit.length > 0 ? unit[0] : null
      } else {
        this.targetUnitId = undefined
        this.targetUnit = null
      }
    },
  },
  async mounted () {
    await this.getSpacesBySite()
    this.unitsBySpaces()
    await this.costCentersBySpaces()
    this.setStartDate()
    this.unitCodeSelectionList()
    await this.getCostCenter()
  },
  methods: {
    cancel () {
      this.setStartDate()
      this.$emit('close')
    },
    unitCodeSelectionList () {
      if (this.unit) {
        let notSelectedUnits =
          this.units.filter(unit => this.unitsInSpaces.map(
            unitInSpaces => unitInSpaces.id).indexOf(unit.id) === -1)
        const unitCodesAndNames = notSelectedUnits.map(unit => {
          return unit.unit_code + ' ' + unit.name }).
            filter(currentUnit => currentUnit != this.unit.unit_code + ' ' + this.unit.name)
        this.unitCodeSelection = unitCodesAndNames
      } else {
        this.unitCodeSelection = this.units ? this.units.map(unit => 
          { return unit.unit_code + ' ' + unit.name }) : undefined
      }
    },
    setStartDate () {
      const date = moment(this.currentDate, "YYYY-MM-DD")
      this.transferDate = date.isValid() ? date.format("YYYY-MM-DD") : undefined
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    onTargetUnitStartDateChangedField (val) {
      const date = moment(val, "DD.MM.YYYY")
      this.isTransferDateValid = date.isValid()
      this.transferDate = date.isValid() ? date.format("YYYY-MM-DD") : undefined
    },
    onTargetUnitStartDateChangedPicker (val) {
      const date = moment(val, "YYYY-MM-DD")
      this.isTransferDateValid = date.isValid()
      this.transferDate = date.isValid() ? date.format("YYYY-MM-DD") : undefined
    },
    doesUnitLoseAllSpaces (id) {
      if (
        this.getSpacesByUnit(id).length === this.getAllSpacesByUnit(id).length
      ) {
        return true
      } else {
        return false
      }
    },
    getNewAreaForUnit (id, isTargetUnit) {
      if (typeof id === 'undefined'){
        return 0
      }
      const currentArea = this.getOldAreaForUnit(id)
      
      if (isTargetUnit){
        let allAddedArea = 0
        if (this.getSpacesByUnit(null).length > 0)
        {
          const addedArea = this.getSpacesByUnit(null)
            .reduce((acc, space) => acc + space.unit_area, 0)
          allAddedArea += addedArea
        }
        for (let i = 0; i < this.units.length; i++ ){
          let unitId = this.units[i].id
          const addedArea = this.getSpacesByUnit(unitId)
            .map(s => Number(s.unit_area))
            .reduce((acc, cur) => acc + cur, 0)
          allAddedArea += addedArea
        }
        return (Number(currentArea) + Number(allAddedArea)).toFixed(2)
      } else {
        const lostArea = this.getSpacesByUnit(id)
          .map(s => Number(s.unit_area))
          .reduce((acc, cur) => acc + cur, 0)
        return (currentArea - lostArea).toFixed(2)
      }
    },
    getOldAreaForUnit (id){
      let spacesForUnit = this.getAllSpacesByUnit(id)
      let unitAreaAll = 0
      for (let i = 0; i < spacesForUnit.length; i++) {
        let spaceId = spacesForUnit[i].id
        let space = spacesForUnit.filter(space => space.id === spaceId)
        let unitArea = space[0].unit_area
        unitAreaAll += unitArea
      }
      return Number(unitAreaAll).toFixed(2)
    },
    getSpacesByUnit (id) {
      return this.selectedSpaces.filter(space => space.id_unit === id)
    },
    getAllSpacesByUnit (id) {
      return this.allSpaces.filter(space => space.id_unit === id)
    },
    getAllocatedAreaBySpace (){
      let space = this.getSpacesByUnit(null)
      if (typeof this.costcentersBySpaces !== 'undefined'){
        let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace == space[0].id)
        let allocatedArea = costcenterBySpace.length !== 0 ? Number(costcenterBySpace[0].allocatedUnitArea).toFixed(2) : 0.0
        return allocatedArea
      }
      return null
    },
    async transferSpaces () {
      this.saving = true

      let unitsBySpacesList = this.unitsInSpaces
      const sourceAreasList = []
      let costcenterCode = this.targetCostcenter.split(' ')[0]
      let spaceCostcenter = this.costcenters.find(cc => cc.code === costcenterCode)

      if (unitsBySpacesList.length > 0) {
        for (let i = 0; i < unitsBySpacesList.length; i++) {
          const sourceAreas = new Object

          let unitId = unitsBySpacesList[i].id
          let sourceUnitArea = Number(this.getOldAreaForUnit(unitId)).toFixed(2)
        
          let spaces = this.selectedSpaces.filter(space => space.id_unit === unitId)
          const removedUnitArea = spaces
               .map(s => Number(s.unit_area))
               .reduce((acc, cur) => acc + cur, 0).toFixed(2)
        
          let newUnitArea = Number(Number(sourceUnitArea) - Number(removedUnitArea)).toFixed(2)
          let newAllocatedUnitArea = Number(this.getNewAllocatedUnitAreaForUnit(unitId, true)).toFixed(2)

          sourceAreas.unit_id = unitId
          sourceAreas.space_ids = spaces.map(space => space.id)
          sourceAreas.sourceNewUnitArea = Number(newUnitArea)
          sourceAreas.sourceNewAllocatedUnitArea = Number(newAllocatedUnitArea)
          sourceAreas.costcenter = spaceCostcenter
          sourceAreasList.push(sourceAreas)
        }
      } else {
        const sourceAreas = new Object
        sourceAreas.unit_id = 0
        sourceAreas.space_ids = this.selectedSpaces.map(space => space.id)
        sourceAreas.sourceNewUnitArea = 0
        sourceAreas.sourceNewAllocatedUnitArea = 0
        sourceAreas.costcenter = spaceCostcenter
        sourceAreasList.push(sourceAreas)
      }

      let targetUnitId = 0
      let targetNewUnitArea = 0
      let targetNewAllocatedUnitArea = 0

      if (this.changeType === 'unit') { 
        targetUnitId = this.units.find(unit => unit.unit_code == this.targetUnitCode.split(' ')[0]).id
        let targetUnitArea = Number(this.getOldAreaForUnit(targetUnitId)).toFixed(2)
      
        const addedUnitArea = this.selectedSpaces
          .map(s => Number(s.unit_area))
          .reduce((acc, cur) => acc + cur, 0).toFixed(2)
      
        let newUnitAreaFixed = Number(Number(targetUnitArea) + Number(addedUnitArea)).toFixed(2)
        let newAllocatedUnitAreaFixed = Number(this.getNewAllocatedUnitAreaForUnit(targetUnitId, false)).toFixed(2)

        targetNewUnitArea = Number(newUnitAreaFixed)
        targetNewAllocatedUnitArea = Number(newAllocatedUnitAreaFixed)
      }
      const transferDate = this.transferDate

      // noNewVersion option is only used in Espoo for the moment
      await this.$rambollfmapi.units.transferSpaces(targetUnitId, sourceAreasList, transferDate, this.editUnitNotNewVersion, targetNewUnitArea, targetNewAllocatedUnitArea)

      this.saving = false
      this.setStartDate()
      this.$emit('updated')
      this.$emit('close')
    },
    getAllocatedArea (spaceId) {
      if (typeof this.costcentersBySpaces !== 'undefined'){
        let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace == spaceId)
        let allocatedArea = costcenterBySpace.length !== 0 ? Number(costcenterBySpace[0].allocatedUnitArea).toFixed(2) : 0.0
        return allocatedArea
      }
      return null
    },
    getAllocatedUnitAreaByUnit (unitId, isSourceUnit){
      if (typeof this.costcentersBySpaces !== 'undefined') {
        let allocatedAreaAll = 0
        let spacesForUnit = this.getAllSpacesByUnit(unitId)
        for (let i = 0; i < spacesForUnit.length; i++) {
          let spaceId = spacesForUnit[i].id 
          let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace === spaceId)
          let allocatedArea = costcenterBySpace.length !== 0 ? Number(costcenterBySpace[0].allocatedUnitArea) : 0.0
          allocatedAreaAll += allocatedArea
        }
        if (isSourceUnit){
          return Number(allocatedAreaAll).toFixed(2)
        } else {
          return Number(allocatedAreaAll)
        }
      }
      return null
    },
    getNewAllocatedUnitAreaForUnit (unitId, isSourceUnit){
      const currentAllocatedUnitArea = Number(this.getAllocatedUnitAreaByUnit(unitId, false))
      let lostAllocatedAreaAll = 0
      if (typeof this.costcentersBySpaces !== 'undefined') {
        if (isSourceUnit){
          let filteredSpaces = this.selectedSpaces.filter(space => space.id_unit === unitId)
          for (let i = 0; i < filteredSpaces.length; i ++) {
            let spaceId = filteredSpaces[i].id
            let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace === spaceId)
            let lostAllocatedArea = costcenterBySpace.length !== 0 ? costcenterBySpace[0].allocatedUnitArea : 0.00
            lostAllocatedAreaAll += lostAllocatedArea
          }
        } else {
          for (let i = 0; i < this.selectedSpaces.length; i ++) {
            let spaceId = this.selectedSpaces[i].id
            let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace === spaceId)
            let lostAllocatedArea = costcenterBySpace.length !== 0 ? costcenterBySpace[0].allocatedUnitArea : 0.00
            lostAllocatedAreaAll += lostAllocatedArea
          }
        }
        if (isSourceUnit){
          let newAllocatedUnitArea = currentAllocatedUnitArea - lostAllocatedAreaAll
          return newAllocatedUnitArea.toFixed(2)
        } else {
          let newAllocatedUnitArea = currentAllocatedUnitArea + lostAllocatedAreaAll
          return newAllocatedUnitArea
        }
      }
      return null
    },
    async getSpacesBySite (){
      let siteId = this.currentSite.id_site
      let spaces = await this.$rambollfmapi.spaces.siteSpaces(siteId)
      this.allSpaces = spaces
    },
    async costCentersBySpaces () {
      if (this.unitsInSpaces.length > 0){
        let unitIds = this.unitsInSpaces.map(unit => { return unit.id })
        let costcentersByUnits = await this.$rambollfmapi.spaces.allocatedAreaByUnits(unitIds, this.currentDate)
        this.costcentersBySpaces = costcentersByUnits
      } else {
        let spaceIds = this.selectedSpaces.map(space => { return space.id })
        let costentersBySpaces = await this.$rambollfmapi.spaces.allocatedAreaBySpaces(spaceIds, this.currentDate)
        this.costcentersBySpaces = costentersBySpaces
      }
    },
    transferingAllocatedUnitArea (unitId) {
      if (typeof this.costcentersBySpaces !== 'undefined' && typeof unitId !== 'undefined') {
        let allocatedAreaAll = 0
        for (let i = 0; i < this.selectedSpaces.length; i++) {
          let spaceId = this.selectedSpaces[i].id
          let costcenterBySpace = this.costcentersBySpaces.filter(ccbs => ccbs.idSpace === spaceId)
          let allocatedArea = costcenterBySpace.length !== 0 ? costcenterBySpace[0].allocatedUnitArea : 0.0
          allocatedAreaAll += allocatedArea
        }
        allocatedAreaAll += this.getAllocatedUnitAreaByUnit(unitId)
        this.allocatedAreaAll = Number(allocatedAreaAll)
        return Number(allocatedAreaAll).toFixed(2)
      }
      return 0 
    },
    unitsBySpaces (){
      let unitsBySpacesList = []
      let unitsBySpacesListModified = []
      for (let i = 0; i < this.selectedSpaces.length; i++) {
        let unitId = this.selectedSpaces[i].id_unit
        let unitBySpace = this.units.filter(u => u.id === unitId)
        if (unitBySpace.length !== 0) {
          let unitAlreadyInlist = unitsBySpacesList.map(ubsl => ubsl[0]).filter(units => units.id === unitId)
          if (unitAlreadyInlist.length < 1) {
            unitsBySpacesList.push(unitBySpace)
          }        
        }
      }
      unitsBySpacesListModified = unitsBySpacesList.map(ubsl => ubsl[0])
      this.unitsInSpaces = unitsBySpacesListModified
    },
    async getCostCenter () {
      let costcenters = await this.$rambollfmapi.organizations.costCenters().list()
      if (typeof costcenters != null || typeof costcenters != 'undefined') {
        this.costcenters = costcenters
        const costcenterList = costcenters.filter(cc => cc.code === 'JYVALL' || cc.code === 'JYVNOT')
        const selectionList = costcenterList.map(ccl => { return ccl.code + ' ' + ccl.name })
        this.costcenterSelection = selectionList
      } else {
        return this.costcenterSelection
      }  
    }
  }
}
</script>

<style scoped>
.smallColumn {
  padding: 20px;
  white-space: nowrap;
}
tbody tr td {
  padding: 5px 0px 5px 20px !important;
}
.v-datatable tbody tr td v-text-field {
  padding: 20px 5px !important;
}
.info-card >>> .v-card__title {
  padding: 0 1em;
}
.info-card >>> .v-card__text {
  padding: 0.2em 1em;
}
.info-card {
  margin: 1em;
  width: 100% !important;
  max-width: none !important;
}
.v-list__tile__content >>> .first-tile-content {
  border-top-left-radius: 2em !important;
  border-top-right-radius: 2em !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-btn:disabled {
  background: none !important;
  border: 1px solid #e0e0e0 !important;
}
.transferDate {
  padding-top: var(--r-spacing-m) !important;
}
</style>
