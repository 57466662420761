<template>
  <v-footer
    padless
    class="app-footer my-5 text-body-2"
  >
    <span>
      © {{ new Date().getFullYear() }} Circle by Ramboll
    </span>
    <slot />
  </v-footer>
</template>
<script>
  export default {
    name: 'AppFooter',
  }
</script>
<style scoped>
.app-footer {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-footer::v-deep > * {
  margin: 0 8px;
}
</style>
