<template>
  <div>
    <v-dialog
      v-model="editDialog"
      width="550"
      persistent
    >
      <v-card>
        <PartyRegisterForm
          v-if="editDialog"
          :edit-party="editedItem"
          :editing="editing"
          :view-only="isReadOnly"
          :cost-centers="costCenters"
          :companies="companies"
          :items-object="itemsObject"
          :user-organizations="organizations"
          :definitions="definitions"
          :has-external-orgs="hasExternalOrgs"
          @childToParent="onChildClick"
          @close="onChildClick"
          @refresh="getParties"
        />
      </v-card>
    </v-dialog>
    <BaseView>
      <template #selections>
        <h1 class="text-h4">
          {{ $t('Parties') }}
        </h1>
      </template>
      <template #buttons>
        <v-tooltip left>
          <template #activator="{ on }">
            <div v-on="on">
              <CdsButton
                icon-left="remove_red_eye"
                :type="showActiveAssignmentsOnly ? 'primary' : 'tertiary'"
                hide-text
                @click="toggleShowActiveAssignmentsOnly"
              >
                {{ showActiveAssignmentsOnly ? $t('Show all parties') : $t('Show active parties only') }}
              </CdsButton>
            </div>
          </template>
          <span>{{ showActiveAssignmentsOnly ? $t('Show all parties') : $t('Show active parties only') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <div v-on="on">
              <CdsButton
                v-if="isReadOnly === false"
                icon-left="add"
                type="accent"
                hide-text
                @click="addNewParty"
              >
                {{ $t('Create a new party') }}
              </CdsButton>
            </div>
          </template>
          <span>{{ $t('Create a new party') }}</span>
        </v-tooltip>
      </template>
      <template #main>
        <v-row no-gutters>
          <v-col
            v-for="group in itemsObject"
            :key="group.id"
            cols="12"
            class="party-panel"
          >
            <v-expansion-panels
              :value="expansionPanels"
              multiple
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row
                    align="center"
                    justify="start"
                    no-gutters
                  >
                    <v-progress-circular
                      v-if="group.loading"
                      size="24"
                      indeterminate
                      color="grey"
                      class="mr-1"
                    />
                    <v-icon
                      v-else
                      class="mr-1"
                    >
                      {{ group.icon }}
                    </v-icon>
                    {{ group.state }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table
                    :rows="group.data"
                    :headers="group.headers"
                    :show-controls="enable"
                    :is-read-only="isReadOnly"
                    :pagination.sync="pagination"
                    :show-active-assignments-only="showActiveAssignmentsOnly"
                    class="party-table"
                    @event_child="eventChild"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-spacer />
          </v-col>
        </v-row>
      </template>
    </BaseView>
  </div>
</template>
<script>
import PartyRegisterForm from '../components/PartyRegisterForm'
import BaseView from '../components/general/BaseView'
import CdsButton from '../components/CircleDesignSystem/CdsButton'
import Table from '../components/Table.vue'
import { mapGetters, mapState } from 'vuex'
import helpers from '../helpers'
export default {
  name: 'Parties',
  components: {
    PartyRegisterForm: PartyRegisterForm,
    Table,
    BaseView,
    CdsButton,
  },
  metaInfo () {
    return {
      title: `${this.$t('Parties')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      partiesData: [],
      resourceParties: [],
      costCenters: [],
      definitions: [],
      externalOrganizations: [],
      editDialog: false,
      pagination: { sortDesc: [false], itemsPerPage: 100, sortBy: ['name'] },
      itemsObject: [{
        state: this.$t('Business party'),
        id: 0,
        icon: 'business_center',
        loading: false,
        data: [],
        assignments: [],
        headers: [
        ...( this.hasExternalOrgs ? [{ text: this.$t('User Organization'), value: 'organization' }] : []),
          { text: this.$t('Name'), value: 'name' },
          { text: this.$t('Business ID'), value: 'business_id' },
          { text: this.$t('Liaison'), value: 'liaison' },
          { text: this.$t('Phone number'), value: 'phone_number' },
          { text: this.$t('Email'), value: 'email' },
          { text: this.$t('Industry classification'), value: 'industry_classification_label' },
          { text: this.$t('Tenant corporation'), value: 'tenant_corporation', format: 'TruthValue' },
          { text: this.$t('Mediator company'), value: 'mediator_company', format: 'TruthValue' },
          { text: this.$t('Personnel'), value: 'personnel', format: '' },
          { text: this.$t('Assignments'), value: 'assignments' },
          { text: this.$t('Comments'), value: 'comment', format: '' }
        ]
      },
      {
        state: this.$t('Person party'),
        id: 1,
        icon: 'face',
        loading: false,
        data: [],
        assignments: [],
        headers: [
        ...( this.hasExternalOrgs ? [{ text: this.$t('User Organization'), value: 'organization', format: '' }] : []),
          { text: this.$t('Name'), value: 'name', format: '' },
          { text: this.$t('Email'), value: 'email', format: '' },
          { text: this.$t('Job description'), value: 'job_description', format: '', isDefinition: true },
          { text: this.$t('Cost center'), value: 'cost_center', format: '' },
          { text: this.$t('Company'), value: 'company', format: '' },
          { text: this.$t('Assignments'), value: 'assignments', format: '' },
          { text: this.$t('Comments'), value: 'comment', format: '' }
        ]
      }
      ],
      editing: false,
      editedItem: null,
      newParty: {
        name: null,
        business_id: null,
        phone_number: null,
        company_id: null,
        job_description: null,
        person_count: null,
        cost_center: null,
        unit: null,
        email: null,
        industry_classification: null,
        company: null,
        liaison: null,
        tenant_corporation: null,
        mediator_company: false,
        id_country: 1,
      },
      expansionPanels: [],
      showActiveAssignmentsOnly: true
    }
  },
  computed: {
    ...mapGetters('app', ['applicationPermissions']),
    ...mapState('app', ['currentDate']),
    isReadOnly () {
      if (this.permission && this.permission.accessLevel === 1) {
        return false
      }
      return true
    },
    enable () {
      if (this.permission && this.permission.accessLevel === 1) {
        return true
      }
      return false
    },
    permission () {
      return this.applicationPermissions.find(
        p => p.id === '20.01'
      )
    },
    organizations () {
      const deletionOrg = {
        comment: null,
        is_admin: false,
        id: 0,
        name: this.$t('No organization link')
      }
      return this.externalOrganizations.concat(deletionOrg)
    },
    hasExternalOrgs () {
      if (this.externalOrganizations.length > 0) {
        return true
      }
      return false
    }
  },
  watch: {
    currentDate: function () {
      this.$rambollfmapi.definitions.list()
        .then(res => {
          this.definitions = res
        })

      this.$rambollfmapi.organizations.costCenters().list()
        .then(res => {
          this.costCenters = res.map(cc => {
            return {
              cost_center_identifier: cc.code,
              cost_center_name: cc.code + ' - ' + cc.name
            }
          })
        })
      this.$rambollfmapi.parties.list({ query: { party_type: 0 } })
		.then(res => {
          this.companies = res.map(comp => {
            return {
              company_id: comp.company_id,
              company_name: comp.name
            }
          })
        })
      this.$rambollfmapi.userOrganizations.getExternal()
      .then(res => {
        this.externalOrganizations = res
      })

      this.getParties()
    }
  },
  async mounted () {
    this.$rambollfmapi.definitions.list()
    .then(res => {
        this.definitions = res
      })

    this.$rambollfmapi.organizations.costCenters().list()
      .then(res => {
        this.costCenters = res.map(cc => {
          return {
            cost_center_identifier: cc.code,
            cost_center_name: cc.code + ' - ' + cc.name
          }
        })
      })
      this.$rambollfmapi.parties.list({ query: { party_type: 0 } })
		.then(res => {
          this.companies = res.map(comp => {
            return {
              company_id: comp.id,
              company_name: comp.name
            }
          })
        })
    this.$rambollfmapi.userOrganizations.getExternal()
    .then(res => {
      this.externalOrganizations = res
    })

    this.getParties()
  },
  methods: {
    eventChild: function (item, method) {
      this.$log.info(method)
      if (method === 'edit') {
        this.editItem(item)
      }
      if (method === 'delete') {
        this.deleteItem(item)
      }
    },
    deleteItem (item) {
      const partyType = item.type
      if (confirm(this.$t('Confirm party delete'))) {
        this.$rambollfmapi.parties.delete(item.id).then((response) => {
          if (typeof(response) == "number") {
            if (response == -1) {
              alert(this.$t('Party has unspecified links or technical error occured'))
            }
            else
            {
              alert(this.$t('Party has active links'))
            }
          } else {
            this.getParties(partyType)
          }
        })
      }
    },
    editItem (item) {
      this.editedItem = Object.assign({}, this.findParty(item.id))
      this.editedItem = helpers.format.unescapeHtmlFromObjectStrings(this.editedItem)
      this.editing = true
      this.editDialog = true
    },
    onChildClick (value) {
      this.editDialog = value
    },
    close () {
      this.currentVisibility = false
      this.editing = false
      this.$emit('close')
      this.getParties()
    },
    findParty (id) {
      let found
      this.itemsObject.forEach(item => {
        item.data.forEach(party => {
          if (party.id === id) {
            found = party
          }
        })
      })
      return found
    },
    async getParties (partyType = null) {
      if (partyType === null) {
        for (var i = 0; i < this.itemsObject.length; i++) {
          await this.getPartiesByType(this.itemsObject[i].id)
          await this.checkForOrganizationHeader(this.itemsObject[i].id)
        }
        this.getCompanyPersonnel()
      } else {
        await this.getPartiesByType(partyType)
        await this.checkForOrganizationHeader(partyType)
        this.getCompanyPersonnel()
      }
    },
    async getPartiesByType (partyType) {
      this.itemsObject[partyType].loading = true
      let data = await this.$rambollfmapi.parties.list({ query: { party_type: partyType, visibility_status: true, time: this.currentDate, reduced_data: true } })
      const assignments = await this.$rambollfmapi.parties.assignments(partyType)
      data.forEach(d => {
        d = helpers.format.escapeHtmlFromObjectStrings(d)
        if (d.organizationLink !== null) {
          d.organization = this.getOrgName(d.organizationLink.id_parent) 
        }       
        if (d.company_id !== null) {
        d.company = this.getCompanyById(d.company_id)
        }
        d.assignments = []
      });
      data = await this.getPartyAssignmentStrings(assignments, data)
      this.itemsObject[partyType].data = data
      this.itemsObject[partyType].loading = false
    },
    getCompanyPersonnel () {
      const companies = this.itemsObject[0].data
      const persons = this.itemsObject[1].data

      this.itemsObject[0].data = companies.map(c => {
        const returnValue = c
              const results = persons.filter(p => p.company_id === c.id)
              returnValue.personnel = results.map(r => r.name)

        return returnValue
      })
    },
    async checkForOrganizationHeader (partyType) {
      if (this.hasExternalOrgs) {
        const headers = this.itemsObject[partyType].headers
        if (partyType === 0) {
          if (!headers.some(h => h.text === this.$t('User Organization'))) {
            this.itemsObject[partyType].headers.unshift({ text: this.$t('User Organization'), value: 'organization' })
          }
        }
        else if (partyType === 1) {
          if (!headers.some(h => h.text === this.$t('User Organization'))) {
            this.itemsObject[partyType].headers.unshift({ text: this.$t('User Organization'), value: 'organization', format: '' })
          }
        }
      }
    },
    async getPartyAssignmentStrings (assignmentsList, data) {
      // generate object from parties to avoid slow iterating in a loop
      const partyObj = {}
      data.forEach(d => partyObj[d.id] = d)
      // add assignments to parties
      assignmentsList.forEach(a => {
        if (partyObj[a.id]) {
          partyObj[a.id].assignments.push(a)
        }
      })
      // convert object back to array before returning it
      return Object.entries(partyObj).map(([key, value]) => value); 
    },
    getCompanyById (id) {
      if (this.companies.length > 0) {
          return this.companies.find(o => o.company_id === id).company_name 
        }  
    },
    getOrgName (id){
      if (this.externalOrganizations.length > 0) {
        return this.externalOrganizations.find(o => o.id === id).name 
      }      
    },
    toggleShowActiveAssignmentsOnly () {
      this.showActiveAssignmentsOnly = !this.showActiveAssignmentsOnly
    },
    addNewParty () {
      this.editDialog = true
      this.editedItem = this.newParty
      this.editing = false
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  width: 100%;
  right: 0;
  top: -111px;
  position: absolute;
  background-color: white;
}

.layout-white {
  background-color: white;
}

.form-fields {
  margin-top: 20px !important;
}

.party-panel {
  margin-bottom: 0.5em;
}

.party-table {
  margin: 1em;
}
</style>
