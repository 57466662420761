import moment from 'moment'

export function endingContractsGraph (
  rentalStatus,
  futureRentalStatus,
  pastRentalStatus,
  rentalProcesses,
  currentDate,
  userSites = null,
  defNegotitions
) {

  let data = []
  let allStatuses = rentalStatus
    .concat(futureRentalStatus)
    .concat(pastRentalStatus)
  
  if (userSites != null) {
    allStatuses = allStatuses.filter(rs => userSites.map(site => site.id_site).includes(rs.siteId))
  }

  // We add data for six years
  let currentYear = moment(currentDate).get('year')
  for (let i = currentYear; i < currentYear + 6; i++) {
    data.push({
      year: i,
      allPermanentArea: 0,
      allPermanentRent: 0,
      allFixedTermArea: 0,
      allFixedTermRent: 0,
      fixedTermNotRelevantArea: 0,
      fixedTermStartLaterArea: 0,
      fixedTermPendingArea: 0,
      fixedTermInactiveArea: 0,
      fixedTermLeadArea: 0,
      fixedTermProspectArea: 0,
      fixedTermNeedsAssessmentArea: 0,
      fixedTermProposalArea: 0,
      fixedTermContractNegotiationArea: 0,
      fixedTermWonArea: 0,
      fixedTermRentAdministrationArea: 0,
      fixedTermClosedArea: 0,
      fixedTermPendingRent: 0,
      fixedTermNotRelevantRent: 0,
      fixedTermStartLaterRent: 0,
      fixedTermInactiveRent: 0,
      fixedTermLeadRent: 0,
      fixedTermProspectRent: 0,
      fixedTermNeedsAssessmentRent: 0,
      fixedTermProposalRent: 0,
      fixedTermContractNegotiationRent: 0,
      fixedTermWonRent: 0,
      fixedTermRentAdministrationRent: 0,
      fixedTermClosedRent: 0,
      permanentPendingArea: 0,
      permanentNotRelevantArea: 0,
      permanentStartLaterArea: 0,
      permanentInactiveArea: 0,
      permanentLeadArea: 0,
      permanentProspectArea: 0,
      permanentNeedsAssessmentArea: 0,
      permanentProposalArea: 0,
      permanentContractNegotiationArea: 0,
      permanentWonArea: 0,
      permanentRentAdministrationArea: 0,
      permanentClosedArea: 0,
      permanentPendingRent: 0,
      permanentNotRelevantRent: 0,
      permanentStartLaterRent: 0,
      permanentInactiveRent: 0,
      permanentLeadRent: 0,
      permanentProspectRent: 0,
      permanentNeedsAssessmentRent: 0,
      permanentProposalRent: 0,
      permanentContractNegotiationRent: 0,
      permanentWonRent: 0,
      permanentRentAdministrationRent: 0,
      permanentClosedRent: 0,
    })
  }

  let fixedTermStatuses = allStatuses.filter(
    (rs) =>
      rs.curPartyEndDate != null &&
      rs.curPartyId != 0 &&
      rs.contractNumber != null &&
      rs.validity === 'määräaikainen'
  )
  let permanentStatuses = allStatuses.filter(
    (rs) =>
      (rs.curPartyEndDate != null || rs.contractFirstPossibleEndDate != null) &&
      rs.curPartyId != 0 &&
      rs.contractNumber != null &&
      rs.validity === 'toistaiseksi voimassaoleva'
  )
  fixedTermStatuses.forEach((rs) => {
    const negotiationStatus = defNegotitions.find((item) => item.id === rs.defNegotiationStatus)
    let endYear = moment(rs.curPartyEndDate).get('year')
    let dataPoint = data.find((item) => item.year === endYear)
    if (dataPoint != null) {
      let marketRent = rs.market_rent != null ? rs.market_rent : 0
      const rentalProcess = rentalProcesses.find(process => process.id === rs.id_prospect_renegotiations)
      
      if (rentalProcess) {
        const stage = rentalProcess.status === 'Inactive' ? rentalProcess.status : rentalProcess.stage.split('. ')[1]
        dataPoint['fixedTerm' + stage + 'Area'] += rs.area
        dataPoint['fixedTerm' + stage + 'Rent'] += rs.area * marketRent
      } else if (negotiationStatus) {
        const status = negotiationStatus.label === 'Ei ajankohtainen' ? 'NotRelevant' : 'StartLater'
        dataPoint['fixedTerm' + status + 'Area'] += rs.area
        dataPoint['fixedTerm' + status + 'Rent'] += rs.area * marketRent
      } else {
        dataPoint['fixedTermPendingArea'] += rs.area
        dataPoint['fixedTermPendingRent'] += rs.area * marketRent
      }

      dataPoint['allFixedTermArea'] += rs.area
      dataPoint['allFixedTermRent'] += rs.area * marketRent
    }
  })
  permanentStatuses.forEach((rs) => {
    const negotiationStatus = defNegotitions.find((item) => item.id === rs.defNegotiationStatus)
    let endDate =
      rs.curPartyEndDate != null
        ? rs.curPartyEndDate
        : rs.contractFirstPossibleEndDate
    let endYear = moment(endDate).get('year')
    let dataPoint = data.find((item) => item.year === endYear)
    if (dataPoint != null) {
      let marketRent = rs.market_rent != null ? rs.market_rent : 0
      const rentalProcess = rentalProcesses.find(process => process.id === rs.id_prospect_renegotiations)
      
      if (rentalProcess) {
        const stage = rentalProcess.status === 'Inactive' ? rentalProcess.status : rentalProcess.stage.split('. ')[1]
        dataPoint['permanent' + stage + 'Area'] += rs.area
        dataPoint['permanent' + stage + 'Rent'] += rs.area * marketRent
      } else if (negotiationStatus) {
        const status = negotiationStatus.label === 'Ei ajankohtainen' ? 'NotRelevant' : 'StartLater'
        dataPoint['permanent' + status + 'Area'] += rs.area
        dataPoint['permanent' + status + 'Rent'] += rs.area * marketRent
      } else {
        dataPoint['permanentPendingArea'] += rs.area
        dataPoint['permanentPendingRent'] += rs.area * marketRent
      }

      dataPoint['allPermanentArea'] += rs.area
      dataPoint['allPermanentRent'] += rs.area * marketRent
    }
  })

  data.sort((a, b) => a.year - b.year)

  return data
}


/**
* Checks if given value is in the range of time selection. The range can contain multiple different ranges determined by
* lower and upper bounds. The values of these lists should match indexes. Aka. first bound is lowerBounds[0] < x < upperBounds[0]
* If either of the list is empty, we use current year as the time range.
* @param {Date} value - Date compatible value, which is checked against this.lowerBoundQuarterDates and this.upperBoundQuarterDates
* @param {Date[]} lowerBounds - List of date objects. Lower bounds
* @param {Date[]} upperBounds - List of date objects. Upper bounds
* @return {Boolean} - true if value is in range, false otherwise
*/
export function isInQuarterSelection (value, lowerBounds, upperBounds) {
 const date = new Date(value)

 if (
   lowerBounds?.length > 0 &&
   lowerBounds.length === upperBounds?.length
 ) {
   const bound = lowerBounds.find((bound, i) => {
     return date >= bound && date <= upperBounds[i]
   })

   return bound !== undefined
 }

 const selectedYear = new Date().getFullYear()

 const startOfYear = new Date(
   moment().set('year', selectedYear).startOf('year')
 )
 const endOfYear = new Date(
   moment().set('year', selectedYear).endOf('year')
 )

 return date > startOfYear && date < endOfYear
}

export function calculatePcsCountSum (targets) {
  return targets.reduce((acc, obj) => acc + obj.agreedAmount, 0)
}