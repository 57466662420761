<template>
  <div>
    <p>
      {{ $t("leasing.confirmed_positive_info_text") }}
    </p>
  </div>
</template>

<script>


export default {
  name: 'ConfirmedPositive',
}
</script>

<style scoped>
p {
  font-style: italic;
}
</style>
