<template>
  <v-dialog 
    v-model="dialog"
    persistent
    max-width="550"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        :aria-label="$t('New user group')"
        v-bind="attrs"
        @click.stop="dialog = true"
        v-on="on"
      >
        <v-icon>group_add</v-icon>
      </v-btn>
    </template>
    <v-card>
      <BaseModal
        @cancel="emit"
      >
        <template #title>
          {{ $t('New user group') }}
        </template>
        <template #content>
          <v-expansion-panels>
            <v-expansion-panel
              :value="[allApplications !== name]"
              multiple
            >
              <v-expansion-panel-header>
                <v-icon>group</v-icon>
                {{ $t("New user group") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-4 py-4">
                <p>
                  {{ $t('User Organization') + ': ' }}
                  {{ orgName }}
                </p>
              
                <v-text-field
                  v-model="name"
                  :label="$t('Name')"
                />
                <v-text-field
                  v-model="description"
                  :label="$t('Description')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template #footer>
          <v-btn
            :disabled="indicatorRunning"
            rounded
            depressed
            color="primary"
            this.dialog="true"
            @click="saveUserRights(); dialog = false; indicatorRunning = true"
          >
            {{ $t("Save") }}
          </v-btn>
        </template>
      </BaseModal>
      <Alert
        :show="showIndicator"
        :result="operationResult"
        :message="indicatorMessage"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import Alert from '../components/Alert'
import BaseModal from './general/BaseModal'
export default {
  components: {
    Alert,
    BaseModal,
  },
  props: { parentData: Boolean, editUser: Object, editing: Boolean, userOrganization: Object },
  data () {
    return {
      childMessage: null,
      editable: false,
      currentVisibility: false,
      lookRights: [],
      editRights: [],
      userGroups: [],
      allApplications: [],
      list: [],
      name: null,
      description: null,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      dialog: false,
    }
  },
  computed: {
    customerId () {
      return this.$store.state.app.userInfo.customer_id
    },
    orgName () {
      if(this.userOrganization){
        return this.userOrganization.name
      }
      return ""
    }
  },
  watch: {
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  beforeMount () {
    this.getallApplications()
  },
  methods: {
    emit (event) {
      this.resetForm()
      this.dialog = false
      this.$emit('childToParent', false)
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    close () {
      this.currentVisibility = false
      this.resetForm()
      this.$emit('close')
    },
    resetForm (){
      this.name = ""
      this.description = ""
    },
    async saveUserRights (userGroup, id) {
      const look = this.lookRights
      const edit = this.editRights
      const values = []
      if (edit.length < 1) {
        look.forEach(e1 => values.push({ name: e1, value: 0 }))
      } else {
        look.forEach(e1 =>
          edit.forEach(e2 => {
            if (e1 !== e2 && !values.includes(e1)) {
              values.push({ name: e1, accessLevel: 0 })
            }
            if (e1 === e2) {
              values.push({ name: e1, accessLevel: 1 })
            }
          })
        )
      }
      let newGroup = {
        groupName: this.name,
        description: this.description,
        iduserorganization: this.userOrganization.id
      }
      const data = {
        applicationPermissions: values,
        groupName: this.name,
        description: this.description,
        group: newGroup
      }

      await this.$rambollfmapi.permissions
        .groups()
        .post(data)
        .then(response => {
          this.$emit('saved')
          this.indicatorRunning = false
          this.close()
        })
        .catch(() => {
          this.showIndicator = true
          this.indicatorMessage = this.$t('Unsuccesfull save')
          this.operationResult = 'error'
        })
    },
    filterUserRights (values) {
      if (values.length === 0) {
        return this.allApplications
      }
      let newList = []
      newList = JSON.parse(JSON.stringify(this.allApplications))

      for (var i = 0; i < values.length; i++) {
        const found = values[i]
        newList.splice(found, 1)
      }
      return newList
    },
    async getallApplications () {
      this.allApplications = await this.$rambollfmapi.permissions.applications()
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}

.form-fields {
  margin-top: 20px !important;
}

.v-input--is-disabled {
  color: grey !important;
  background-color: lightgrey;
}
.checkbox-width {
  min-width: 25% !important;
}

.noPadding {
  padding: 0;
  margin: 8px;
}
</style>
