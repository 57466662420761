export default function UnitChangesIgnoredWidget () {
  return {
    id: "dataimport.changes_ignored",
    type: "ObjectTable",
    data: {
      headers: [
        { text: "Building code", value: "buildingCode" },
        { text: "Unit", value: "unitName" },
        { text: "Contract number", value: "contractNumber"},
        { text: "Description", value: "value" },
      ],
      items: [],
    },
    maximized: [false],
    loading: false,
    dataFetcher: (context) => {
      return context.errors
    },
    shouldShow: (context) => {
      return context.errors && context.errors.length
    },
  }
}
