<template>
  <ViewAndSelections
    contract-level="ESR"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
        <ImportButton
          v-if="importing"
          :additional-date-format="true"
          :headers="headers"
          :required-fields="getRequiredFields()"
          :disabled="loading"
          format="windows-1252"
          @dataUpdate="saveFile"
        />
      </v-row>
      <import-report-modal 
        :data-update-dialog="dataUpdateDialog"
        :updating-items="updatingItems"
        :saving-new-contracts="savingNewContracts"
        :items-length="itemsLength"
        :import-success="importSuccess"
        :import-errors="importErrors"
        :saving-failed="savingFailed"
        :is-saving-changes="isSavingChanges"
        :items-not-changed="itemsNotChanged"
        :has-changes="hasChanges"
        :changes-list="changesList"
        :saved-contracts="savedContracts"
        :overlap-errors="overlapErrors"
        :saving-errors="savingErrors"
        :other-errors="otherErrors"
        :errors="errors"
        :updated-contracts="updatedContracts"
        :failed-updates="failedUpdates"
        :saving-errors-count="savingErrorsCount"
        :loading="loading"
        @close="resetChanges"
        @save="saveChanges"
      />
    </template>
    <template
      #table="props"
    >
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="viewHeaders"
        :footers="debouncedFooters"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
        :stored-views="props.props.storedViews.filter(sv => sv.level === 'ESR')"
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import helpers from '../../helpers'
import Table from '../Table.vue'
import ImportButton from '../ImportButton.vue'
import ViewAndSelections from './ViewAndSelections.vue'
import ImportReportModal from './Modals/ImportReportModal.vue'
import { getBooleanString, stringToBoolean, stringToNumber } from "../../helpers/mappers/booleanString"
import { compareDates } from '../../helpers/contract'

export default {
  name: 'FairyContractLevel',
  components: {
    Table,
    ImportButton,
    ViewAndSelections,
    ImportReportModal
  },
  props: {
    siteId: { type: Number, default: undefined },
    importing: { type: Boolean, default: false },
  },
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      dataUpdateDialog: false,
      requiredFields: ['contractNumber', 'curPartyName', 'curPartyStartDate', 'business_id'],
      updatingItems: false,
      savingNewContracts: false,
      itemsLength: 0,
      importSuccess: false,
      importErrors: { unexisting: [], uneditable: [] },
      savingFailed: false,
      isSavingChanges: false,
      itemsNotChanged: 0,
      rentalStatusChanges: [],
      newContracts: [],
      savedContracts: 0,
      overlapErrors: [],
      savingErrors: [],
      otherErrors: [],
      errors: [],
      updatedContracts: 0,
      failedUpdates: 0,
      savingErrorsCount: 0,
      footerDefinitions: [
        { text: this.$t('Total') },
        { value: 'area', unit: 'area', format: 'Area', type: "sum" },
        { value: 'contract_area', unit: 'area', format: 'Area', type: "sum" },
        { value: 'capitalRent', unit: 'Rent', format: 'Euro', type: "sum" },
        { value: 'maintenanceRent', unit: 'Rent', format: 'Euro', type: "sum" },
        { value: 'maintenanceRentEqualization', unit: 'Rent', format: 'Euro', type: "sum" },
        { value: 'changeoverRent', unit: 'number', format: 'Number', type: "sum" },
        { value: 'separateAllowances', unit: 'number', format: 'Number', type: "sum" },
        { value: 'discounts', unit: 'number', format: 'Number', type: "sum" },
        { value: 'openBillsTotal', unit: 'number', format: 'Number', type: "sum" },
        { value: 'basicRent', unit: 'Rent', format: 'Euro', type: "sum" }
      ],
      pagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['possessor'], 
      },
      exporting: {},
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('app', ['buildingRecursionLoadingState', 'sites']),
    ...mapState('haltia', ['haltiaRentalStatuses', 'futureHaltiaRentalStatuses', 'haltiaRentalStatusesLoading', 'futureHaltiaRentalStatusesLoading']),
    siteIds () {
      return this.sites.map(site => site.id_site)
    },
    loading () {
      return this.haltiaRentalStatusesLoading || this.futureHaltiaRentalStatusesLoading
    },
    isMobile () {
      return visualViewport.width <= 760
    },
    headers () {
      return [
        // The spread operator (...) does nothing if the array is empty but otherwise adds the object inside array to the returning array
        // This way empty object or undefined is not added into the returning array if siteId is not undefined
        ...(this.siteId === undefined ? [
          {
            value: 'site_identifier',
            text: this.$t('Site identifier'),
            format: ''
          }
        ] : []),
        {
          value: 'siteName',
          text: this.$t('Site name'),
          format: '',
          ignoreHeader: true
        },
        {
          value: 'longUnitCodes',
          text: this.$t('Unit code long'),
          format: '',
          ignoreHeader: true
        },
        {
          value: 'buildingCode',
          text: this.$t('Building code'),
          format: ''
        },
        {
          value: 'unitName',
          text: this.$t('Unit'),
          format: ''
        },
        {
          value: 'contractNumber',
          text: this.$t('Contract number'),
          format: '',
          editable: true
        },
        {
          value: 'curPartyName',
          text: this.$t('Tenant'),
          format: '',
          editable: true
        },
        {
          value: 'business_id',
          text: this.$t('Business ID'),
          format: '',
          editable: true
        },
        {
          value: 'validity',
          text: this.$t('Validity'),
          format: '',
          editable: true
        },
        {
          value: 'signatureDate',
          text: this.$t('Contract date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'curPartyStartDate',
          text: this.$t('Contract start date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'contractFirstPossibleEndDate',
          text: this.$t('Contract first possible end date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'curPartyEndDate',
          text: this.$t('Contract end date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'tenantFirstPossibleEndDate',
          text: this.$t('rental_status.tenant_first_possible_end_date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'tenantNotice',
          text: this.$t('Agreement notice period (Tenant)'),
          format: 'Number',
          editable: true
        },
        {
          value: 'landlordFirstPossibleEndDate',
          text: this.$t('rental_status.landlord_first_possible_end_date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'landlordNotice',
          text: this.$t('Agreement notice period (Lanlord)'),
          format: 'Number',
          editable: true
        },
        {
          value: 'huom',
          text: this.$t('Validity notice'),
          format: '',
          editable: true
        },
        {
          value: 'contract_note',
          text: this.$t('leasing.additional_fixed_term_info'),
          format: '',
          editable: true
        },
        {
          value: 'area',
          text: this.$t('Unit area'),
          format: 'Area',
          ignoreHeader: true
        },
        {
          value: 'contract_area',
          text: this.$t('Contract area'),
          format: 'Area',
          editable: true
        },
        {
          value: 'additionalInfo',
          text: this.$t('Contract details'),
          format: '',
          editable: true
        },
        {
          value: 'vat_status',
          text: this.$t('VAT-responsibility'),
          format: '',
          editable: true
        },
        {
          value: 'contractType',
          text: this.$t('leasing.contract_type'),
          format: '',
          editable: true
        },
        {
          value: 'industry',
          text: this.$t('leasing.industry'),
          format: '',
          editable: true
        },
        {
          value: 'contract_status',
          text: this.$t('Contract status'),
          format: '',
          editable: true
        },
        {
          value: 'paymentsDifferentValidityperiods',
          text: this.$t('haltia.Maksuissa poikkeavat voimassaoloajat'),
          format: '',
          boolean: true,
          editable: true
        },
        {
          value: 'capitalRent',
          text: this.$t('Capital rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'maintenanceRent',
          text: this.$t('Maintenance rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'maintenanceRentEqualization',
          text: this.$t('rental_status.maintenance_rent_equalization'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'changeoverRent',
          text: this.$t('rental_status.changeover_rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'relocationworkleaseStartDate',
          text: this.$t('haltia.Muutostyövuokran alkamispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'relocationworkleaseEndDate',
          text: this.$t('haltia.Muutostyövuokran päättymispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'marketingFee',
          text: this.$t('haltia.Markkinointimaksu'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'separateAllowances',
          text: this.$t('rental_status.separate_allowances'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'discounts',
          text: this.$t('rental_status.discounts'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'discountsStartDate',
          text: this.$t('haltia.Alennusten alkamispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'discountsEndDate',
          text: this.$t('haltia.Alennusten päättymispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'licenseFees',
          text: this.$t('haltia.Lisenssimaksut'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'rentalResponsibility',
          text: this.$t('haltia.Vuokravastuu'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'contractTurnoverTieup',
          text: this.$t('haltia.Sopimuksella liikevaihtosid. vuokra'),
          format: '',
          boolean: true,
          editable: true
        },
        {
          value: 'turnoverRelatedRent',
          text: this.$t('haltia.Liikevaihtosidonnainen vuokra'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'turnoverPercentage',
          text: this.$t('haltia.Liikevaihtoprosentti'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'turnoverRelatedInfo',
          text: this.$t('Turnover related info'),
          format: '',
          editable: true,
        },
        {
          value: 'openBillsTotal',
          text: this.$t('rental_status.open_bills_total'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'basicRent',
          text: this.$t('rental_status.basic_rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'rentReviewType',
          text: this.$t('rental_status.rent_review_type'),
          format: '',
          editable: true
        },
        {
          value: 'indexType',
          text: this.$t('rental_status.index_type'),
          format: '',
          editable: true
        },
        {
          value: 'basicIndexPointNumber',
          text: this.$t('rental_status.basic_index_point_number'),
          format: 'Number',
          editable: true
        },
        {
          value: 'reviewMonths',
          text: this.$t('rental_status.review_months'),
          format: '',
          editable: true
        },
        {
          value: 'latestIndexYear',
          text: this.$t('rental_status.latest_index_year'),
          format: 'Number',
          editable: true
        },
        {
          value: 'latestIndexMonth',
          text: this.$t('rental_status.latest_index_month'),
          format: 'Number',
          editable: true
        },
        {
          value: 'latestIndexPointNumber',
          text: this.$t('rental_status.latest_index_point_number'),
          format: 'Number',
          editable: true
        },
        {
          value: 'minimumIncrease',
          text: this.$t('rental_status.minimum_increase'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'fixedIncrease',
          text: this.$t('haltia.Kiinteä korotus (prosentti)'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'checkBased',
          text: this.$t('haltia.Tarkistus perustuu'),
          format: '',
          editable: true
        },
        {
          value: 'rentcheckStartDate',
          text: this.$t('haltia.Vuokrantarkistus alkaa'),
          format: 'Date',
          editable: true
        },
      ]
    },
    viewData () {
      const rentalStatus = this.haltiaRentalStatuses

      const data = rentalStatus.map(rs => ({
        ...rs,
        vat_status: getBooleanString(rs.vat),
        paymentsDifferentValidityperiods: getBooleanString(rs.paymentsDifferentValidityperiods),
        contractTurnoverTieup: getBooleanString(rs.contractTurnoverTieup),
        huom: getBooleanString(rs.huom),
        signatureDate: rs.signature_date
      }))

      const rows = []
      data.forEach(d => {
        if (d.contractNumber) {
          const index = rows.findIndex(row => row.contractNumber === d.contractNumber)
          if (index >= 0) {          
            const buildingIndex = rows[index].unitNames.findIndex(b => b.buildingCode === d.buildingCode)
            if (buildingIndex >= 0) {
              rows[index].unitNames[buildingIndex].units.push(d.unitName)
            } else {
              rows[index].unitNames.push({buildingCode: d.buildingCode, units: [d.unitName]})
            }

            
            if(d.unit_code_long && d.unit_code_long !== ""){
              rows[index].longUnitCodeList.push(d.unit_code_long)
            }
            rows[index].area += d.area
          } else {
            const newRow = {...d}
            newRow.unitNames = [{buildingCode: d.buildingCode, units: [d.unitName]}]
            newRow.longUnitCodeList = [d.unit_code_long]
            rows.push(newRow)
          }
        }
      })

      rows.forEach(row => {
        row.unitName = row.unitNames.reduce((a,b, index) => {
          let text = a
          if (index > 0) {
            text += '<br/>'
          }
          return `${text}<b>${b.buildingCode}:</b> ${b.units.join(', ')}`
        }, '') 
        
        row.longUnitCodes = row.longUnitCodeList.join(",\n")

      })
      return rows
    },
    viewHeaders () {
      return this.headers.filter(header => header.value !== 'buildingCode')
    },
    hasChanges () {
      return this.rentalStatusChanges.length || this.newContracts.length
    },
    changesList () {
      return [
        {changes: this.rentalStatusChanges, text: this.$t('dataimport.list_changes')},
        {changes: this.newContracts, text: this.$t('dataimport.new_contracts')}
      ]
    },
  },
  watch: {
    buildingRecursionLoadingState (newValue) {

      if (!newValue) {
        this.fetchData()
      }
    },
  },
  mounted () {
    if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions('haltia', ['fetchHaltiaRentalStatuses', 'fetchFutureHaltiaRentalStatuses']),
    debouncedFooters (headers, data) {
      clearTimeout(this.timer)
      const footerSums = []
      const timeout = 500
      this.timer = setTimeout(() => {      
        const footerValues = this.footerDefinitions
        const calculatedFooterValues = {}
        footerValues.forEach(footer => {
          const value = footer.value
          let result = null
          switch (footer.type) {
            case 'sum': {
              result = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              break
            }
            case 'weightedAverage': {
              const weight = footer.weight
              const dataWeights = data.map(r => 
                ({
                  value: isNaN(r[value]) ? 0 : Number(r[value]),
                  weight: isNaN(r[weight]) ? 0 : Number(r[weight]) 
                })
              )
              const [sum, weightSum] = dataWeights.reduce((acc, curr) => {
                if (curr.value) {
                  acc[0] += curr.value * curr.weight
                  acc[1] += curr.weight
                }
                return acc
              }, [0, 0])
              result = weightSum ? (sum/weightSum).toFixed(2) : null
              break
            }
            case 'average': {
              const sum = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              const itemCount = data.filter(item => item[value] != null).length
              result = itemCount ? sum / itemCount : null 
              break
            }
            default: {
              return null
            }
          }
          if (result) {
            calculatedFooterValues[value] = result
          }
        })
        footerSums.push(calculatedFooterValues)
      }, timeout);
      return footerSums
    },
    fetchData () {
      if (this.siteId) {
        this.fetchFutureHaltiaRentalStatuses(this.siteId)
        this.fetchHaltiaRentalStatuses(this.siteId)
        return
      }
      this.fetchFutureHaltiaRentalStatuses()
      this.fetchHaltiaRentalStatuses()
    },
    getRequiredFields () {
      return this.headers.filter(header => this.requiredFields.includes(header.value)).map(header => header.text)
    },
    getAllRentalUnits () {
      return this.addStatuses([...this.haltiaRentalStatuses, ...this.futureHaltiaRentalStatuses])
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType
      
      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('fairy_contract')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    async saveFile (results) {
      this.dataUpdateDialog = true
      this.updatingItems = true
      const data = {
        items: this.getAllRentalUnits(),
        headers: this.headers
      }
      // find correct identifier indexes
      const firstIdentifier = this.headers.findIndex(h => h.value === 'buildingCode')
      const secondIdentifier = this.headers.findIndex(h => h.value === 'unitName')
      if (firstIdentifier > -1 && secondIdentifier > -1 ) {
        const items = helpers.dataTable.modifyItems(data, results.data, firstIdentifier, secondIdentifier, true)
        this.itemsLength = items.items.length
        this.importSuccess = items.success
        this.importErrors = items.errors
        this.errors = []
        this.rentalStatusChanges = []
        if (items.success) {
          await this.handleUpdate(items.items)
        }
        this.savingFailed = false
      } else {
        // identifiers not found from headers, check headers code and select correct identifier values
        this.savingFailed = true
      }
      this.isSavingChanges = false
      this.updatingItems = false
    },
    async handleUpdate (items) {
      const reports = this.getAllRentalUnits()

      let changedReports = []
      let newRentalContracts = []
      let unChangedRows = 0

      this.importErrors.unexisting.forEach(s => {
        const errArr = s.split(",")
        this.errors.push({
          buildingCode: errArr[0],
          unitName: errArr[1],
          value: this.$t('dataimport.building_or_unit_not_found'),
          label: this.headers.find(header => header.value === 'unitName').text
        })

      })

      items.forEach(modifiedItem => {
        if (modifiedItem.curPartyEndDate && !compareDates(modifiedItem.curPartyStartDate, modifiedItem.curPartyEndDate)) {
          this.errors.push({
            buildingCode: modifiedItem.buildingCode,
            unitName: modifiedItem.unitName,
            contractNumber: modifiedItem.contractNumber,
            value: this.$t('dataimport.end_date_before_start_date'),
            label: this.headers.find(header => header.value === 'curPartyEndDate').text
          })
          return
        }
        // use unitId here to identify correct item (could also use buildingCode and unitName combination)
        let newContract = false
        let report = reports.find(r => (r.buildingCode === modifiedItem.buildingCode && r.unitName === modifiedItem.unitName && r.contractNumber === modifiedItem.contractNumber))
        if (!report) {
          newContract = true
          report = reports.find(r => (r.buildingCode === modifiedItem.buildingCode && r.unitName === modifiedItem.unitName))
        }

        if(!report){
          return
        }

        const reportCopy = {}
        this.headers.forEach(header => {
          reportCopy[header.value] = report[header.value]
        })
        reportCopy.unitId = report.unitId
        reportCopy.curUnitPartyId = report.curUnitPartyId
        reportCopy.contractId = report.contractId
        reportCopy.curPartyId = report.curPartyId
        modifiedItem.unitId = report.unitId
        modifiedItem.curUnitPartyId = report.curUnitPartyId
        modifiedItem.contractId = report.contractId
        modifiedItem.curPartyId = report.curPartyId
        // Tätä arvoa verrataan reportCopy arvoon. reportCopy:n arvo on
        // sama arvo kuin API:lta tulee (true, false, null)
        // muutetaan tämä vastaamaan tuota.
        modifiedItem.huom = stringToBoolean(modifiedItem.huom)

        // changing tenant corporation name is not allowed
        if (report.business_id == modifiedItem.business_id && report.tenant_corporation) {
          modifiedItem.curPartyName = report.curPartyName
        }
        
        const reportObserver = this.$jsonpatch.observe(reportCopy)
        Object.keys(reportCopy).forEach(key => {
          const propertyHeader = this.headers.find(h => h.value === key)
          // replace only editable fields
          if (propertyHeader && (propertyHeader.editable && !propertyHeader.ignoreHeader)) {
            reportCopy[key] = modifiedItem[key]
          }
        })

        const errorObject = {
          contractNumber: reportCopy.contractNumber,
          buildingCode: reportCopy.buildingCode,
          unitName: reportCopy.unitName
        }
        let inputFieldError = false

        // validate input fields that they are in correct format
        this.headers.forEach(header => {
          let isNotCorrect
          if (reportCopy[header.value] !== null && reportCopy[header.value] !== undefined) {
            if (header.format === 'Date') {
              // ignore dates that are not recognized as date format
              const momentDay = moment(reportCopy[header.value], "DD.MM.YYYY")
              const momentDayWithSlash = moment(reportCopy[header.value], "DD/MM/YYYY")
              const momentDayWithDash = moment(reportCopy[header.value], "YYYY-MM-DD")
              const originalTime = moment(report[header.value]).endOf('day').toDate().getTime()
              let newTime = new Date()
              // verify correct time format
              if(momentDayWithDash.isValid() && reportCopy[header.value].includes('-') && !reportCopy[header.value].includes(':')) {
                newTime = momentDayWithDash.endOf('day').toDate()
              } else if (momentDay.isValid() && reportCopy[header.value].includes('.') && !reportCopy[header.value].includes('-')) {
                newTime = momentDay.endOf('day').toDate()
              } else if (momentDayWithSlash.isValid() && reportCopy[header.value].includes('/') && !reportCopy[header.value].includes('-')) {
                newTime = momentDayWithSlash.endOf('day').toDate()             
              } else {
                isNotCorrect = isNaN(Date.parse(reportCopy[header.value]))
                if (!isNotCorrect) {
                  newTime = moment(reportCopy[header.value]).endOf('day').toDate()
                }
              }
              // no need to patch if only hours or minutes are different
              reportCopy[header.value] = newTime.getTime() !== originalTime ? newTime : report[header.value]
            }
            if (header.format === 'Area' || header.format === 'Euro' || header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
              // export function converts all decimal dots to commas, convert them back
              reportCopy[header.value] = String(
                reportCopy[header.value]
              ).replace(',', '.')
              // ignore NaN notice periods
              isNotCorrect = isNaN(Number(reportCopy[header.value]))
            }
            if (header.boolean) {
              reportCopy[header.value] = stringToNumber(reportCopy[header.value])
            }

            if (isNotCorrect) {
              inputFieldError = true
              this.errors.push({
                ...errorObject,
                value: reportCopy[header.value] + this.$t('dataimport.incorrect_input'),
                label: header.text
              })
              // return original value
              reportCopy[header.value] = report[header.value]
            }
          }


          // What is the purpose of this clause? If the current value is null or undefined and header is one of below, 
          // assign null as a value??? Wtf... Basically converts undefined values to nulls...
          if (header.format === 'Area' || header.format === 'Euro' || header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
            // export function converts all numbers to strings, convert them back to numbers if needed
            reportCopy[header.value] = reportCopy[header.value]
              ? Number(reportCopy[header.value])
              : null
          }
        })

        if (!inputFieldError) {
          
          const reportPatch = this.$jsonpatch.generate(reportObserver)
          if (reportPatch.length) {
            let error = false
            
            this.requiredFields.forEach(field => {
              if (!reportCopy[field]) {
                error = true
                this.errors.push({
                  ...errorObject,
                  value: this.$t('Required'),
                  label: this.headers.find(header => header.value === field).text
                })

              }
            })

            if (!error) {
              // replace original signature date with a possible change
              reportCopy.signature_date = reportCopy.signatureDate

              if (!newContract && report.contractId && report.curUnitPartyId) {
                if (!reportCopy.business_id) {
                  this.errors.push({
                    ...errorObject,
                    value: reportCopy.business_id, // note that business ID is missing
                    label: this.headers.find(header => header.value === 'business_id').text
                  })
                } else {
                  changedReports.push(reportCopy)
                }
              } else if (newContract && this.checkBusinessIdAndName(reportCopy)) {
                // we created a new contract
                newRentalContracts.push(reportCopy)
              } else if (!reportCopy.business_id || !reportCopy.business_id.replace(/\s/g,'')) {
                this.errors.push({
                    ...errorObject,
                    value: reportCopy.business_id ? reportCopy.business_id : '',
                    label: this.headers.find(header => header.value === 'business_id').text
                })
              } else if (!reportCopy.curPartyName || !reportCopy.curPartyName.replace(/\s/g,'')) {
                this.errors.push({
                    ...errorObject,
                    value: reportCopy.curPartyName ? reportCopy.curPartyName : '',
                    label: this.headers.find(header => header.value === 'curPartyName').text
                })
              }
            }
          } else {
            unChangedRows += 1
          }
        }
      
      })
      this.itemsNotChanged = unChangedRows
      if (this.updatingItems) {
        const areaCheck = []
        // Set vat-value to match the text in vat_status
        // and contract areas must match within the same contract number

        // Re assigning the variable inside loop which is going through the values of said variable...
        changedReports.forEach((value, index, arr) => {
          arr[index].vat = stringToNumber(arr[index].vat_status)
          changedReports = this.checkContractArea(areaCheck, value, changedReports)
        })

        newRentalContracts.forEach((value, index, arr) => {
          arr[index].vat = stringToNumber(arr[index].vat_status)
          newRentalContracts = this.checkContractArea(areaCheck, value, newRentalContracts)
        })

        this.rentalStatusChanges = changedReports
        this.newContracts = newRentalContracts
      }
    },
    handleErrors (errors) {
      if (errors.length > 0) {
        const startDate = this.headers.find(header => header.value === 'curPartyStartDate').text
        const endDate = this.headers.find(header => header.value === 'curPartyEndDate').text
        const contractNumber = this.headers.find(header => header.value === 'contractNumber').text
        const partyName = this.headers.find(header => header.value === 'curPartyName').text
        errors.forEach( err => {
          const errorObject = {
            buildingCode: err.buildingCode,
            unitName: err.unitName
          }

          switch (err.label) {
            case 'overlapping': {
              const start = err.startDate ? moment(new Date(err.startDate)).format('DD.MM.YYYY') : '-'
              const end = err.endDate ? moment(new Date(err.endDate)).format('DD.MM.YYYY') : '-'
              this.overlapErrors.push({
                ...errorObject,
                value: `${err.contractNumber}, ${startDate}: ${start}, ${endDate}: ${end}`,
                label: `${err.newContract ? this.$t("dataimport.new_contract") : ""}${contractNumber}`
              })
              break
            }
            case 'startDate':
              this.savingErrors.push({
                ...errorObject,
                value: this.$t('dataimport.not_existing'),
                label: startDate
              })
              break
            case 'unitStatus':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}, ${startDate}: ${moment(new Date(err.startDate)).format('DD.MM.YYYY')}, ${this.$t('dataimport.unit_in_wrong_status')}: ${this.getDefinitionLabelById(parseInt(err.value, 10))}`,
                label: contractNumber
              })
              break
            case 'patchError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.unexpected_connection_error'),
                label: contractNumber
              })
              break
            case 'partyError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractPartyName + ". " + this.$t('dataimport.party_creation_error') + " " + this.$t('dataimport.try_again'),
                label: partyName
              })
              break
            case 'contractError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.contract_creation_error') + " " + this.$t('dataimport.try_again'),
                label: contractNumber
              })
              break
            case 'linkError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractPartyName + ". " + this.$t('dataimport.party_linking_error') + " " + this.$t('dataimport.try_again'),
                label: partyName
              })
              break
            case 'unitNotFound':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${this.$t('dataimport.unit_not_found')}`,
                label: contractNumber
              })
              break
            case 'unknownError':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${err.value}`,
                label: contractNumber
              })
              break
            case 'contractNotFound':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${this.$t('dataimport.contract_not_found')}`,
                label: contractNumber
              })
              break
            case 'terminationDateError':
              this.otherErrors.push({
                label: this.$t('dataimport.termination_dates_not_saved')
              })
              break
            default:
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.unexpected_connection_error'),
                label: contractNumber
              })
          }
        })
      }
    },
    handleOk () {
      this.dataUpdateDialog = false
      this.errors = []
    },
    async saveChanges () {
      this.isSavingChanges = true
      try {
        if (this.rentalStatusChanges.length > 0) {        
          await this.saveRentalStatusChanges()
        }
        await this.addNewRentalContracts()
      } catch {
        this.savingFailed = true
        this.resetChanges()
        this.dataUpdateDialog = true
      }
      if (this.overlapErrors.length < 1 && this.savingErrors.length < 1 && this.otherErrors.length < 1) {
        this.resetChanges()
      } else {
        this.setDefaults()        
        this.isSavingChanges = false
        this.savingFailed = true
        this.dataUpdateDialog = true
      }
      this.fetchData()
    },
    resetChanges () {
      this.isSavingChanges = false
      this.updatingItems = false
      this.savingNewContracts = false
      this.dataUpdateDialog = false
      this.setDefaults()
      this.errors = []
      this.overlapErrors = []
      this.savingErrors = []
      this.otherErrors = []
    },
    setDefaults () {
      this.rentalStatusChanges = []
      this.newContracts = []
    },
    async saveRentalStatusChanges () {
      let errors = []
      try {
        errors = await this.$rambollfmapi.units.rentalStatus().update(this.rentalStatusChanges, true)
        if (errors.name === 'Error') {
          throw Error
        }
      } catch (err) {
        this.savingErrors.push({
          label: this.$t('dataimport.unexpected_connection_error')
        })
      }
      this.updatedContracts = this.rentalStatusChanges.length - errors.length
      this.failedUpdates = errors.length
      this.handleErrors(errors)
    },
    async addNewRentalContracts () {
      this.savingNewContracts = true
      this.itemsLength = this.newContracts.length
      let errors = []
      try {
        errors = await this.$rambollfmapi.units.rentalStatus().post(this.newContracts)
        if (errors.name === 'Error') {
          throw Error
        }
      } catch (err) {
        this.savingErrors.push({
          label: this.$t('dataimport.unexpected_connection_error')
        })
      }
      this.savedContracts = this.newContracts.length - errors.length
      this.savingErrorsCount = errors.length
      this.handleErrors(errors)
      this.savingNewContracts = false
    },
    addStatuses (statuses) {
      return statuses.map(rs => ({
        ...rs,
        signatureDate: rs.signature_date,
        vat_status: getBooleanString(rs.vat)
      }))
    },
    checkBusinessIdAndName (contract) {
      return contract.business_id && contract.business_id.replace(/\s/g,'') && contract.curPartyName && contract.curPartyName.replace(/\s/g,'')
    },
    checkContractArea (areaArray, value, contracts) {
      if (!areaArray[value.contractNumber]) {
        areaArray[value.contractNumber] = value.contract_area
      } else if (areaArray[value.contractNumber] !== value.contract_area) {
        const errorObject = {
          buildingCode: value.buildingCode,
          unitName: value.unitName
        }
        this.errors.push({
          ...errorObject,
          value: this.$t('dataimport.area_check') + value.contractNumber,
          label: this.$t('Contract area')
        })
        // remove all changes which are with the same contract number 
        return contracts.filter(item => item.contractNumber !== value.contractNumber) 
      }
      return contracts
    },
  }
}
</script>

<style>
</style>