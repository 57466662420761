<template>
  <v-dialog
    v-model="addNewLinkDialog"
    persistent
    max-width="550"
  >
    <AriaLive />
    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ $t('Add link') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            v-model="title"
            required
            :rules="[requiredIfEmpty]"
            class="form-field"
            :label="$t('Title')"
            :hint="$t('Required')"
            persistent-hint
            @input="indicatorMessage = ''"
          />
          <v-text-field
            v-model="link"
            :rules="[requiredIfEmpty]"
            class="form-field"
            :label="$t('Link')"
            :hint="$t('Required')"
            persistent-hint
            required
            @input="indicatorMessage = ''"
          />
          <Alert 
            v-if="indicatorMessage"
            :show="showIndicator"
            result="error"
            :message="indicatorMessage"
          />
        </v-form>
      </template>
      <template #footer>
        <v-btn
          text
          rounded
          class="primary"
          @click="save"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>
<script>

import BaseModal from './general/BaseModal'
import Alert from './Alert'
import AriaLive from './AriaLive.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BaseModal,
    Alert,
    AriaLive
  },
  props: {
    addNewLinkDialog: {
       type: Boolean,
       default: false
    }
  },
  data () {
    return {
      saving: false,
      title: '',
      link: '',
      showIndicator: false,
      indicatorMessage: '',
    }
  },
  computed: {
    ...mapState('sites', [
      'currentSiteLinks'
    ]),
  },
  methods: {
    ...mapActions('sites',[
      'saveNewSiteLink',
      'getSiteLinks'
    ]),
    requiredIfEmpty (value) {
      return value == '' ? this.$t('Required') : true;
    },
    siteLinkExists () {
      return this.currentSiteLinks.some(sl => sl.link.toLowerCase() === this.link.toLowerCase() && sl.title.toLowerCase() === this.title.toLowerCase())
    },
    resetValidation (){
      this.$refs.form.resetValidation()
      this.showIndicator = false
      this.indicatorMessage = ''
    },
    reset () {
      this.link = '';
      this.title = '';
      this.resetValidation()
    },
    async save () {
      if (this.title == '' || this.link == '') {
        this.$refs.form.validate()
        return
      }
      if (this.siteLinkExists()) {
        this.showIndicator = false
        this.$refs.form.validate()
        this.indicatorMessage = this.$t('siteLinkExists')
        this.showIndicator = true
        return
      }
      if (this.saving) {
        return
      }
      this.saving = true;
      await this.saveNewSiteLink({ 'title': this.title, 'link': this.link })
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
      this.saving = false
      this.reset()

      this.$emit('refreshLinkData')
      this.$emit('close')
    },
    cancel () {
      this.reset()
      this.$emit('close')
    }
  }
}
</script>