import jsonpatch from 'fast-json-patch'
/**
 * JSON PATCH support. See https://github.com/Starcounter-Jack/JSON-Patch for more information
 *
 * Example:
 *
 * import jsonpatch from './jsonpatch'
 * Vue.use(jsonpatch)
 *
 * @param {Vue} Vue
 */
function install (Vue) {
  Vue.jsonpatch = jsonpatch
  Vue.prototype.$jsonpatch = jsonpatch
}

export default {
  install: install
}
