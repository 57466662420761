const mapCorporationsByBusinessId = (corporations) => {
  return corporations.reduce((entryMap, corpo) => {
    corpo.business_id
      ?.split(/[&,\r\n\n\r]/)
      .filter((bId) => bId.length > 0)
      .forEach((bId) => {
        entryMap.set(bId.trim(), corpo);
      });
    return entryMap;
  }, new Map());
};

const mapCorporationsByCorporationBusinessId = (corporations) => {
  return corporations.reduce((entryMap, corpo) => {
    corpo.corporations_business_id
      ?.split(/[&,\r\n\n\r]/)
      .filter((bId) => bId.length > 0)
      .forEach((bId) => {
        entryMap.set(bId.trim(), corpo);
      });
    return entryMap;
  }, new Map());
};

const mapCorporationsByContractNumbers = (corporations) => {
  return corporations.reduce((entryMap, corpo) => {
    corpo.corporation_contract_numbers
      ?.split(/[&,\r\n\n\r]/)
      .filter((cn) => cn.length > 0)
      .forEach((cn) => {
        entryMap.set(cn.trim(), corpo);
      });
    return entryMap;
  }, new Map());
};

const mapCorporationsByPartyId = (corporations) => {
  return corporations.reduce((entryMap, corpo) => {
    entryMap.set(corpo.id, corpo);
    return entryMap
  }, new Map());
};

// Class implementation so that we can use the same (or change) underlying datastructure if needed
class CorporationLinker {
  constructor (corporations) {
    // Using map for more efficient search. Should not cause major memory issue as we are storing data as reference.
    this.byId = mapCorporationsByPartyId(corporations);
    this.byBusinessId = mapCorporationsByBusinessId(corporations);
    this.byCorporationBusinessId = mapCorporationsByCorporationBusinessId(corporations);
    this.byContractNumber = mapCorporationsByContractNumbers(corporations);
  }

  find ({ partyId, businessId, contractNumber }) {
    let corporation = null;

    if (partyId) {
      corporation = this.byId.get(businessId);
    }

    if (corporation) {
      return corporation;
    }

    if (businessId) {
      corporation = this.byBusinessId.get(businessId) || this.byCorporationBusinessId.get(businessId);
    }

    if (corporation) {
      return corporation;
    }

    if (contractNumber) {
      corporation = this.byContractNumber.get(contractNumber);
    }

    if (corporation) {
      return corporation;
    }

    // Okay I tried everything. I give up...
    return null;
  }
}

module.exports = {
  mapCorporationsByBusinessId,
  mapCorporationsByContractNumbers,
  CorporationLinker,
};
