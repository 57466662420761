import { render, staticRenderFns } from "./EditableMetadataForm.vue?vue&type=template&id=3ae8c958&scoped=true"
import script from "./EditableMetadataForm.vue?vue&type=script&lang=js"
export * from "./EditableMetadataForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae8c958",
  null
  
)

export default component.exports