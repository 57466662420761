var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"value":[0],"multiple":""}},[_c('v-expansion-panel',{class:{ 'hide-expansion-panel': _vm.noExpansionPanel }},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("Filters"))+" ")]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[(_vm.tableId)?_c('SkipTo',{attrs:{"label":_vm.$t('skip-to-table'),"to":'#' + _vm.tableId}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.headers
                    .filter((h) => h.actionHeader !== true && h.hideFilter !== true)
                    .map((h) => {
                      return {
                        text: _vm.$t(h.text),
                        value: h.value,
                        isDefinition: h.isDefinition,
                        type: h.format,
                      }
                    })),function(header){return _c('v-col',{key:header.value,staticClass:"widthAdjust"},[(header.type == 'Date')?_c('v-text-field',{attrs:{"value":_vm.filterValues[header.value],"label":header.text,"clearable":""},on:{"change":(e) => _vm.onFilterValueChange(header.value, e),"input":(e) => _vm.onFilterValueChange(header.value, e)}}):(header.type == 'Time')?_c('v-text-field',{attrs:{"value":_vm.filterValues[header.value],"label":header.text,"clearable":""},on:{"change":(e) => _vm.onFilterValueChange(header.value, e),"input":(e) => _vm.onFilterValueChange(header.value, e)}}):_c('v-text-field',{attrs:{"value":_vm.filterValues[header.value],"label":header.text,"type":header.type,"clearable":""},on:{"input":(e) => _vm.onFilterValueChange(header.value, e)}})],1)}),1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }