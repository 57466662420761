<template>
  <div
    v-if="photo"
  >
    <v-row
      no-gutters
      justify="space-between"
      class="text-h5 grey lighten-2"
    >
      <div
        v-if="photo.photoPublicLink"
        align="start"
        justify="start"
      >
        <span
          color="primary"
          class="public-link"
        >
          {{ photo.filename }}
        </span>
      </div>
      <div
        align="end"
        justify="end"
      >
        <v-btn
          v-if="photo.filename !== null"
          small
          depressed
          rounded
          right
          color="primary"
          @click="download(photo.idPhoto)"
        >
          <span>
            {{ $t('Download') }}
          </span>
        </v-btn>
        <v-btn
          :disabled="!browserSupportsShare"
          small
          icon
          right
          @click="share(photo.photoPublicLink)"
        >
          <v-icon>share</v-icon>
          <span class="d-sr-only">{{ $t('Share-content') + ' ' + photo.filename }}</span>
        </v-btn>
        <v-btn
          small
          icon
          right
          @click="copyToClipboard(photo.photoPublicLink)"
        >
          <v-icon>content_copy</v-icon>
          <span class="d-sr-only">{{ $t('Copy') + ' ' + photo.filename }}</span>
        </v-btn>
        <v-btn
          v-if="hasWritePermission"
          small
          icon
          right
          @click="edit(photo.idPhoto)"
        >
          <v-icon>edit</v-icon>
          <span class="d-sr-only">{{ $t('Edit') + ' ' + photo.filename }}</span>
        </v-btn>
        <v-btn
          v-if="hasWritePermission"
          small
          icon
          right
          @click="remove(photo)"
        >
          <v-icon>delete</v-icon>
          <span class="d-sr-only">{{ $t('Delete') + ' ' + photo.filename }}</span>
        </v-btn>
        <v-btn
          small
          icon
          right
          @click="close"
        >
          <v-icon>close</v-icon>
          <span class="d-sr-only">{{ $t('Close') }}</span>
        </v-btn>
      </div>
    </v-row>
    <div
      align="center"
      justify="center"
      class="photo-background"
    >
      <div
        v-if="isLoading"
        
        align="center"
        justify="center"
      >
        <v-progress-circular
          :size="64"
          indeterminate
          color="primary"
        />
      </div>
      <div
        v-else
      >
        <div>
          <v-carousel
            v-model="carouselIdx"
            :show-arrows="photos.length > 1"
            :continuous="false"
            :cycle="false"
            hide-delimiters
            height="70vh"
            max="60vw"
          >
            <v-carousel-item
              v-for="photoItem in photos"
              :key="photoItem.idPhoto"
              :src="photoItem.previewerPhotoUrl"
              reverse-transition="fade-transition"
              transition="fade-transition"
            />
          </v-carousel>
        </div>
        <v-col
          v-if="photoLoadingError"
          class="align-center justify-center"
        >
          {{ $t('Photo download failed') }}
        </v-col>
      </div>
    </div>
    <v-row
      class="text-h5 grey lighten-2 color"
      justify="space-between"
      no-gutters
    >
      <v-col
        class="extra-information pa-4"
      >
        <v-row>
          <v-col cols="auto">
            {{ $t('Date') }}:
          </v-col>
          <v-col>
            {{ photoUploaded }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            {{ $t('Building') }}:
          </v-col>
          <v-col>
            {{ photo.buildingName }}
          </v-col>
        </v-row>
      </v-col>
      <v-divider
        inset
        vertical
      />
      <v-col
        class="extra-information pa-4"
      >
        <v-row>
          <v-col cols="auto">
            {{ $t('Photographer') }}:
          </v-col>
          <v-col>
            {{ photo.author }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            {{ $t('Permission') }}:
          </v-col>
          <v-col>
            {{ photoAccessRight }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            {{ $t('photos.chooses') }}:
          </v-col>
          <v-col>
            {{ photoChooses }}
          </v-col>
        </v-row>
      </v-col>
      <v-divider
        inset
        vertical
      />
      <v-col
        class="extra-information pa-4"
      >
        <v-row>
          <v-col cols="auto">
            {{ $t('Tags') }}:
          </v-col>
          <v-col>
            {{ photoTags }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            {{ $t('Extra info') }}:
          </v-col>
          <v-col>
            {{ photo.additionalInformation }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import humanize from '../helpers/humanize'
import { mapGetters } from 'vuex'
export default {
  name: 'PhotoPreViewer',
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    hasWritePermission: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    photoLoadingError: {
      type: Boolean,
      default: false
    },
    photoIdx: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      carouselIdx: null
    }
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById']),
    browserSupportsShare () {
      return navigator.canShare
    },
    photo () {
      return this.photos[this.carouselIdx]
    },
    photoTags () {
      return this.photo.tags.map(x => x.name).join(', ')
    },
    photoUploaded () {
      return humanize.date(this.photo.uploaded, 'date')
    },
    photoAccessRight () {
      return this.$t(this.definitionLabelById(this.photo.photo_access_right))
    },
    photoChooses () {
      let values = []
      if (this.photo.toMainImage) {
        values.push(this.$t('photos.toMainImage'))
      }
      if (this.photo.toOutputImage1) {
        values.push(this.$t('photos.toOutputImage1'))
      }
      if (this.photo.toOutputImage2) {
        values.push(this.$t('photos.toOutputImage2'))
      }
      if (this.photo.toRentalPortal) {
        values.push(this.$t('photos.toRentalPortal'))
      }
      return values.join(', ')
    }
  },
  watch: {
    carouselIdx: async function () {
      this.$emit('changeOrLoad', this.carouselIdx)
      
    },
    photoIdx: function () {
      // Set index for carousel based to photo selection
    this.carouselIdx = this.photoIdx
    }
  },
  async mounted () {
    // Set index for carousel based to photo selection
    this.carouselIdx = this.photoIdx
  },
  methods: {
    close () {
      this.$emit('close')
    },
    download (photoId) {
      this.$emit('download', photoId)
    },
    edit (photoId) {
      this.$emit('edit', photoId)
    },
    remove (photo) {
      this.$emit('remove', photo)
    },
    async share (publicUrl) {
      const photoLink = { url: publicUrl }
        try {
          await navigator.share(photoLink)
        } catch (error) {
          this.$log.error('error: ', error)
        }
    },
    copyToClipboard (publicUrl) {
      navigator.clipboard.writeText(publicUrl)
    }
  }
}
</script>
<style scoped>
.public-link {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 8px;
  font-size: 14px;
}
.photo-background {
  background: white;
}
.extra-information {
  font-size: 14px;
}
</style>