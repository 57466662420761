<template>
  <div style="position: relative">
    <v-menu
      v-if="data.sets"
      left
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          small
          absolute
          right
          icon
          style="background: rgba(255, 255, 255, 0.8) !important"
          v-on="on"
        >
          <v-icon>menu</v-icon>
          <span class="d-sr-only">{{ $t('Menu') }}</span>
        </v-btn>
      </template>
    </v-menu>
    <TimeLineChart
      v-if="GraphData !== null"
      :data="GraphData"
      :options="barOptions"
      class="graph"
      style="height: 340px"
    />
  </div>
</template>
<script>
import TimeLineChart from '../TimeLineChart'
import helpers from '../../helpers'
export default {
  components: {
    TimeLineChart
  },
  props: ['data'],
  data () {
    const that = this
    return {
      currentSet: null,
      months: helpers.ltpConstants.months(),
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          display: false,
          labels: {
            fontColor: '#8b8b8b'
          }
        },
        scales: {
          xAxes: [{
            type: 'category',
            scaleLabel: {
              display: this.data.xLabel,
              labelString: this.data.xLabel
            },
            gridLines: {
              color: '#8b8b8b',
              zeroLineColor: '#8b8b8b',
              drawTicks: false
            },
            ticks: {
              fontColor: '#8b8b8b',
              labelOffset: 70
            },
            stacked: true
          }],
          yAxes: [{
            scaleLabel: {
              display: this.data.yLabel,
              labelString: this.data.yLabel
            },
            gridLines: {
              color: 'white',
              zeroLineColor: '#8b8b8b'
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#8b8b8b'
            },
            stacked: true
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let label = that.$t('Period') + ': '
              const taskStart = new Date(that.data.tasks[tooltipItem.index].implementationStart)
              const taskEnd = new Date(that.data.tasks[tooltipItem.index].implementationEnd)
              label += `${taskStart.getFullYear()} ${that.$t(that.months[taskStart.getMonth()])} - ${taskEnd.getFullYear()} ${that.$t(that.months[taskEnd.getMonth()])} `
              return label
            },
            footer: function (tooltipItem, data) {
              return `${that.$t('Building code')}: ${that.data.tasks[tooltipItem[0].index].buildingId}`
            }
          },
          footerFontStyle: 'normal'
        }
      }
    }
  },
  computed: {
    GraphData: function () {
      return {
        xLabels: this.months,
        yLabels: [...this.data.items.map(item => item.label)],
        datasets: [{
          label: this.data.label,
          backgroundColor: this.getThemeColorArray(this.data.items.length),
          borderColor: 'white',
          data: [...this.data.items.map(item => item.months)]
        }]
      }
    }
  },
  methods: {
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${(204 + 45 * index)}, 82%, 72%)`
    }
  }
}
</script>
