<template>
  <v-card>
    <v-snackbar
      v-model="show"
      :bottom="b === 'bottom'"
      :left="x === 'left'"
      :right="x === 'right'"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
      :timeout="timeout"
      :color="result"
      aria-live="assertive"
    >
      {{ message }}
      <v-btn
        v-if="hasButton"
        small
        icon
        @click="show = false"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  props: {
    show: Boolean,
    message: {
      default: '',
      type: String
    },
    result: {
      default: 'success',
      type: String
    },
    y: {
      default: 'top',
      type: String
    },
    x: {
      default: null,
      type: String
    },
    b: {
      default: 'bottom',
      type: String
    },
    timeout: {
      default: 4000,
      type: Number
    },
    hasButton: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      mode: '',
    }
  },
}
</script>
<style scoped>
.v-snackbar {
  text-align: center;
  border-bottom-right-radius: 0.5em;
}
</style>
