<template>
  <div>
    <p>
      {{ $t('leasing.current_free_info_text_1') }}
    </p>
    <p>
      {{ $t('leasing.current_free_info_text_2') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CurrentFreeInfoText',
}
</script>

<style scoped>
p {
  font-style: italic;
}
</style>
