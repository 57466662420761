export default {
  /**
   * Determines if row should be filtered from the table or not
   *
   * @param {Number} index - Index of the filter in options field.
   * @param {Object} filter - Data set's filter object, rules to filter rows.
   * @param {Object} item - Item row to be filtered.
   * @returns {Boolean} Returns boolean if row should be filtered out or not.
   */
  filterRow (index, filter, item) {
    if (index != null && filter != null) {
      const typeArray = Array.isArray(filter.type) ? filter.type : [filter.type]
      const valueArray = Array.isArray(filter.options[index].value)
        ? filter.options[index].value
        : [filter.options[index].value]
      const headerArray = Array.isArray(filter.header)
        ? filter.header
        : [filter.header]
      const lessThan = filter.options[index]?.lessThan

      for (let i = 0; i < headerArray.length; i++) {
        if (valueArray[i]) {
          switch (typeArray[i]) {
            case "equal":
              // Is type of object
              if (typeof item[headerArray[i]] === 'object' && valueArray[i] !== item[headerArray[i]]?.label) {
                return true;
              }
              if (valueArray[i] != item[headerArray[i]]) {
                return true
              }
              break
            case "lessOrEqual":
              if (valueArray[i] > item[headerArray[i]]) {
                return true
              }
              break
            case "notEqual":
              // Is type of object
              if (typeof item[headerArray[i]] === 'object' && valueArray[i] === item[headerArray[i]]?.label) {
                return true;
              }
              if (valueArray[i] === item[headerArray[i]]) {
                return true
              }
              break
            case "date":
              if (lessThan) {
                if (valueArray[i] <= new Date(item[headerArray[i]])) {
                  return true
                }
              } else if (valueArray[i] > new Date(item[headerArray[i]])) {
                return true
              }
              break
            case "like": {
              const itemValue = item[headerArray[i]]
                
              if (!itemValue || itemValue === "" || !itemValue.includes(valueArray[i])) {
                return true
              }
              break
            }

            default:
              break
          }
        }
      }
      return false
    }
  },
}
