<template>
  <span v-if="customerLogo">
    <!-- When using svg images you should make sure, that the file does not contain width or height.
    The aspect ratio should be controller by the viewbox and the height comes from the prop. -->
    <span
      v-if="customerLogoContentType === 'mime/svg+xml'"
      v-html="svgLogo"
    />
    <img
      v-else-if="customerLogoContentType === 'image/png'"
      :src="dataUri"
      :style="imgStyles"
      alt="logo"
    >
    <picture />
  </span>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    size: {
      default: 64,
      type: Number
    }
  },
  computed: {
    ...mapGetters('app', ['customerLogo', 'customerLogoContentType']),
    imgStyles: function () {
      return {
        height: this.size + 'pt',
        width: this.getWidth(this.size)
      }
    },
    dataUri: function () {
      return (
        'data:' + this.customerLogoContentType + ';base64,' + this.customerLogo
      )
    },
    svgLogo: function () {
      if (this.customerLogo && this.customerLogoContentType === 'mime/svg+xml') {
        var parsedLogo = this.customerLogo.split('<svg')[1]
        return '<svg style="height: ' + this.imgStyles.height + '; width: ' + this.imgStyles.width + ';" ' + parsedLogo
      }
      return this.customerLogo
    }
  },
  methods: {
    ...mapActions('app', ['getCustomerLogo']),
    getWidth (size) {
      if (this.customerLogo && this.customerLogoContentType === 'mime/svg+xml') {
        const svgSize = this.customerLogo.split('viewBox="')[1].split('"')[0]
        const svgHeight = parseInt(svgSize.split(' ')[3])
        const svgWidth = parseInt(svgSize.split(' ')[2])
        if (!isNaN(svgHeight) && !isNaN(svgWidth)) {
          return (size * svgWidth / svgHeight).toString() + 'pt'
        }
      }
      return 'auto'
    }
  },
  async mounted () {
    await this.getCustomerLogo()
  },
  data () {
    return {}
  }
}
</script>
<style scoped>
</style>
