
<template>
  <div>
    <v-dialog
      v-model="showSeparatePaymentModal"
      persistent
      eager
      width="85%"
    >
      <SeparatePaymentModal
        :payment="paymentToEdit"
        :selected-units="selectedUnits"
        :contract="contract"
        :contract-id="contractId"
        :visible="showSeparatePaymentModal"
        :rent-period-start-date="rentPeriodStartDate"
        :rent-payment-domains="rentPaymentDomains"
        :is-out-rent="isOutRent"
        :is-internal-contract="isInternalContract"
        :selected-buildings="selectedBuildings"
        :selected-currency="selectedCurrency"
        @paymentAdded="onPaymentAdded"
        @paymentUpdated="onPaymentUpdated"
        @close="onSeparatePaymentModalClosed"
      />
    </v-dialog>

    <v-form
      v-if="!showSeparatePaymentModal"
      ref="form1"
      class="pt-2"
    >
      <v-expansion-panels
        v-model="panels"
        class="my-2"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('create_rent_contract.separate_payments') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="calculatedPayments.length > 0"
              class="v-data-table theme--light scrollable"
            >
              <table
                :style="{ width: '100%'}"
              >
                <thead>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_target') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.rent_contract_table_area') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_discount') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_start_date') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_end_date') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_rent_review') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_domain') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_net_sum') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_legend') }}
                  </th>
                  <th class="column">
                    {{ $t('Last created invoice date') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.actions') }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(payment, index) in calculatedPayments"
                    :key="index"
                  >
                    <td>
                      {{ getPaymentTarget(payment) }}
                    </td>

                    <td class="col-align-right">
                      {{ getPaymentTotalArea(payment) }}
                    </td>

                    <td>
                      {{ $t(payment.discountPayment ? 'true' : 'false') }}
                    </td>

                    <td class="col-align-right">
                      {{ getPaymentStartDate(payment) }}
                    </td>

                    <td class="col-align-right">
                      {{ getPaymentEndDate(payment) }}
                    </td>

                    <td>
                      {{ getRentReviewType(payment) }}
                    </td>

                    <td>
                      {{ getPaymentDomain(payment) }}
                    </td>

                    <td class="col-align-right">
                      {{ getNetSum(payment) }}
                    </td>

                    <td>
                      {{ payment.legend }}
                    </td>

                    <td class="col-align-right">
                      {{ getBilledUntilDate(payment) }}
                    </td>

                    <td>
                      <v-row justify="center">
                        <slot
                          name="row-actions"
                          :row="payment"
                        >
                          <v-btn
                            icon
                            small
                            @click="editPayment(payment)"
                          >
                            <v-icon small>
                              edit
                            </v-icon>
                            <span class="d-sr-only">{{ $t('Modify') }}</span>
                          </v-btn>
                          <v-btn
                            v-if="isRemovable(payment)"
                            icon
                            small
                            @click="deletePayment(payment)"
                          >
                            <v-icon
                              small
                            >
                              delete
                            </v-icon>
                            <span class="d-sr-only">{{ $t('Delete') }}</span>
                          </v-btn>
                        </slot>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center mt-4"
            >
              <v-icon class="mr-1">
                info
              </v-icon>
              {{ $t('create_rent_contract.no_separate_payments') }}
            </div>
            <div class="text-right mt-3 mr-3">
              <v-btn
                text
                rounded
                outlined
                depressed
                class="blue-button"
                @click="onAddNewPayment"
              >
                {{ $t('create_rent_contract.new_separate_payment') }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>

<script>

import helpers from './../../../../../helpers'
import services from './Services'
import SeparatePaymentModal from './SeparatePaymentModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: "SeparatePaymentsTable",
  components: {
    SeparatePaymentModal
  },
  props: {
    rentPeriodStartDate: {
      type: String,
      default: () => null
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    contractId: {
      type: Number,
      default: null
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    separatePayments: {
      type: Array,
      default: () => []
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    rentPaymentDomains: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    isInternalContract: {
      type: Boolean,
      default: () => false
    },
    selectedBuildings: {
      type: Array,
      default: () => []
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return (
      {
        panels: [0],
        showSeparatePaymentModal: false,
        paymentToEdit: null,
        calculatedPayments: []
      }
    )
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById']),
  },
  watch: {
    isActive () {
      if (this.isActive) {
        this.calculatePaymentSum()
      }
    },
    selectedUnits () {
      if (this.isActive) {
        this.calculatePaymentSum()
      }
    },
    separatePayments () {
      this.calculatePaymentSum()
    }
  },
  async mounted () {
    await this.calculatePaymentSum();
  },
  methods: {
    async calculatePaymentSum () {
      this.calculatedPayments = await services.calculateSumsService.calculatePaymentSum(this.separatePayments, this.rentPaymentDomains, this.selectedUnits, this.$rambollfmapi)
    },
    isRemovable (payment) {
      return payment?.id < 0 ||
        (this.contract?.isDraft && !payment?.latestBillingDate)
    },
    getPaymentTarget (payment) {
      return services.tableColumnsService.getTargetUnits(payment, this.selectedUnits)
    },
    getPaymentStartDate (payment) {
      return services.tableColumnsService.getDate(payment?.startDate)
    },
    getPaymentEndDate (payment) {
      return services.tableColumnsService.getDate(payment?.endDate)
    },
    onPaymentAdded (payment) {
      // create pseudo id
      payment.id = Math.floor(Math.random() * -1000000000)
      this.separatePayments.push(payment)
      this.calculatedPayments = this.separatePayments
      this.showSeparatePaymentModal = false

      this.$emit("paymentsUpdated", this.separatePayments);      
    },
    onPaymentUpdated (payment) {
      const paymentIdx = this.separatePayments.findIndex(p => p.id === payment.id)
      this.separatePayments[paymentIdx] = payment

      this.showSeparatePaymentModal = false
      this.calculatedPayments = this.separatePayments

      this.$emit("paymentsUpdated", this.separatePayments);
    },
    onSinglePaymentModalClosed () {
      this.showSeparatePaymentModal = false
    },
    onSeparatePaymentModalClosed () {
      this.showSeparatePaymentModal = false
    },
    getNetSum (payment) {
      return (payment?.netSum !== undefined && payment?.netSum !== null) ? helpers.humanize.amount_long(Number(payment.netSum).toFixed(2)) + ' ' + this.selectedCurrency.symbol : ''
    },
    getPaymentDomain (payment) {
      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
      if (paymentDomain[0] === undefined) return '';
      let name = paymentDomain[0].name
      let defDomainLabel = name
      let vat = paymentDomain[0].vatPercent
      return this.$t(defDomainLabel) + ' ' + this.$t('VAT') + ' ' + this.$t(vat) + '%'
    },
    getRentReviewType (payment) {
      const defDomainLabel = this.definitionLabelById(payment.defRentReviewType)
      return this.$t(defDomainLabel)
    },
    async deletePayment (payment) {
      const paymentIdx = this.separatePayments.findIndex(p => p.id === payment.id)
      this.separatePayments.splice(paymentIdx, 1)      
      this.$emit("paymentsUpdated", this.separatePayments)
      await this.calculatePaymentSum()
    },
    editPayment (payment) {
      this.paymentToEdit = payment;
      this.showSeparatePaymentModal = true
    },
    onAddNewPayment () {
      this.paymentToEdit = null
      this.showSeparatePaymentModal = true
    },
    getPaymentTotalArea (payment) {
      return payment ? helpers.format.formatData(services.tableColumnsService.getTotalArea(payment, this.selectedUnits), 'Area') : ''
    },
    getBilledUntilDate (payment) {
      var billedUntilDate = payment.billedUntilDate ? new Date(payment.billedUntilDate) : null
      return billedUntilDate ? helpers.format.formatData(billedUntilDate, 'MonthAndYear') : ''
    },
  }
}
</script>

<style>
.btn {
  text-transform: unset !important;
}

.red-button {
  background: #ff0000 !important;
  color: white !important;
}

.scrollable {
  overflow-x: scroll;
}
</style>