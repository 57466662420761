/**
 * This is a wrapper to hide v3 client api
 *
 * The wrapper is reponsible for communicating with v3 api.
 *
 * The wrapper should allow the easy use of layers to its consumes. As such V3 does not directly offer layers
 * rather the elements are grouped to layers by adding a layer id as prefix to the element id (e.g. "1(£)123")
 *
 * No direct DOM manipulation should be added here.
 *
 * Should wrapper keep track of selected layers? -> Most likely
 * Should wrapper keep track of models?
 *
 */
class V3Wrapper {
  initialized = null
  v3 = null
  windowV3 = null
  elementCache = {}
  models = []
  clearMode = false
  containerId = ''
  createdMarkups = []
  createdRectangles = []

  // Callback
  toolFinishedCallback = function () {}

  elementLayers = []
  selectedElementLayer = null
  loadedData = null
  dwgSpaceData = null
  defaultConfiguration = {
    shadows: false,
    ambientOcclusion: false,
    showProperties: false,
    contextMenu: false,
    projectIconUrl: '',
    projectIconScale: 0.5,
    lowerDetailsWhileMoving: false,
    lowerResolutionWhileMoving: false,
    enableMeasuring: true,
    automaticModelAlignment: false,
    serverToken: null
  }

   constructor (V3, options, config, localEnv) {
    this.initialized = false
    this.configure(V3, options, config, localEnv)
  }

  async configure (V3, options, config, localEnv) {
    config = config ?? this.defaultConfiguration

    if(!localEnv)
    {
      config.serverToken = process.env.VUE_APP_V3_WRAPPER_SERVER_TOKEN
    }
    else
    {
      config.serverToken = localEnv == "Azure" ? process.env.VUE_APP_V3_WRAPPER_SERVER_TOKEN : process.env.VUE_APP_V3_WRAPPER_SERVER_TOKEN_GCP
    }

    this.windowV3 = V3
    this.containerId = options.containerId
    this.v3 = new V3(options.containerId, config)

    this.v3.set2DMode(true)
    this.v3.setRotateWithLeftButton(false)
    this.v3.setSelectionColor(options.selectedSpaceColor)
    this.v3.setSessionToken(options.sessionToken)
    this.v3.setMapStyle(
      V3[options.mapStyle],
      false,
      options.backgroundColor
    )
    this.elementLayers = options.elementLayers

    this.v3.onSelectElement(options.onSelection) // TODO do some some tasks first in wrapper and then call options.OnSelection
    this.v3.onRenderingFinished(options.onRenderingFinished)
    this.v3.onMarkup((newMarkup) => this.onMarkup(newMarkup))
    this.v3.setCameraOptions({rotation:false})

    // Set up callbacks, to internally survey the state of v3
    this.v3.addEventListener('areaMeasurementChanged', () => this.areaMeasurementChangedCallback())
    this.v3.addEventListener('measurementChanged', () => this.measurementChangedCallback())

    this.initialized = true
  }

  modelOptions (backendModelId) {
    return {
      rotationZ: 0,
      lighting: { mode: 'unlit' },
      scaling: [1, 1, 1],
      modelCoordinateSystem: 'ForceZeroOrigin',
      backendModelId: backendModelId
    }
  }

  loadModel (model) {
    this.v3.addModel(
      model.name,
      model.url,
      this.modelOptions(model.backendModelId),
      undefined,
      async loadedData => {
        if(!loadedData) {
          return
        }
        if (loadedData.error) {
          return loadedData.error
        }
        this.loadedData = loadedData[0]
        this.dwgSpaceData = await this.getSectionProperties(
          loadedData[0].projectId
        )
        this.getModelLayers()

        this.setSelectionIgnoredItems(model.name)
      }
    )
  }

  flyToModel (model, distance, lengthInSeconds) {
    this.v3.flyToProject(
      model,
      0,
      180,
      distance,
      lengthInSeconds
    )
  }

  setMapStyle (style, realisticGroundHeight, backgroundColor) {
    this.v3.setMapStyle(
      this.windowV3[style],
      realisticGroundHeight,
      backgroundColor
    )
  }

  clear () {
    this.v3.clear()
  }

  zoom (direction) {
    this.v3.zoom(direction)
  }

  getLayerElements (layerId, projectId) {
    return new Promise((resolve, reject) => {
      const currentProjectId = projectId
      if (isNaN(layerId)) {
        this.v3.searchElementIds(
          currentProjectId,
          ['Properties.Pset_SpaceCommon.Reference.value', layerId],
          elements => {
            this.elementCache[currentProjectId] = { layerId: elements }
            resolve(elements)
          },
          reject
        )
      } else if ( // TODO if ja else if on lähes samat lukuunottamatta '(£)'-merkkijono lisäystä. Nämä voinee siis yhdistä
        typeof this.elementCache[currentProjectId] === 'undefined' ||
        typeof this.elementCache[currentProjectId][layerId] === 'undefined'
      ) {
        this.v3.searchElementIds(
          currentProjectId,
          ['Properties.Pset_SpaceCommon.Reference.value', layerId + '(£)'],
          elements => {
            this.elementCache[currentProjectId] = { layerId: elements }
            resolve(elements)
          },
          reject
        )
      } else {
        resolve(this.elementCache[currentProjectId][layerId])
      }
    })
  }

  async hideLayers (layers, projectId) {
    for (const layer of layers) {
      const elements = await this.getLayerElements(layer, projectId)
      elements.forEach(element => {
        this.setElementColor(element, [0, 0, 0, 0])
      })
    }
  }

  /**
   * Callback for what happens when an area measurement has been finished with.
   */
  areaMeasurementChangedCallback () {
    this.toolFinishedCallback()
  }

  /**
   * Callback for what happens when a measurement has been finished with
   */
  measurementChangedCallback () {
    this.toolFinishedCallback()
  }

  setElementColor (element, color) {
    this.v3.setElementColor(element, color)
  }

  /**
   * 
   * @param {*} element 
   * @param {*} hitPoint 
   * @param {*} multiSelectElements 
   * @returns Since the functionality from V3Viewer has not been fully moved, the return value tells us if we
   * should stop processing the onSelection in V3Viewer after running onSelection in the wrapper.
   */
  onSelection (element, hitPoint, multiSelectElements) {
    // element data handling should be done here but emit should be done by calling class

    // If we are in clearMode we look through all the markups in the view and if one of them was clicked, we remove it.
    if (this.clearMode) {
      const markups = this.v3.getMarkups()
      const selectedMarkup = markups[element?.iconId]
      const selectedRectangle = this.createdRectangles.find(x => x.entity.id === element?.iconId)

      if (typeof(selectedMarkup) !== 'undefined') {
        this.createdMarkups = this.createdMarkups.filter(x => x.id !== selectedMarkup.id)
        this.v3.removeMarkup(selectedMarkup.id)
      }

      if (typeof(selectedRectangle) !== 'undefined') {
        this.createdRectangles = this.createdRectangles.filter(x => x.entity.id !== selectedRectangle.entity.id)
        this.v3.removeMarkup(selectedRectangle.entity.id)
      }

      return true
    }

    return false
  }

  resetElementColors (projectid) {
    this.v3.resetElementColors(projectid)
  }

  setSelectionIgnoredItems (modelName) {
    this.v3.searchElementIds(
      modelName,
      ['Properties.Pset_SpaceCommon.GrossPlannedArea.value', '0.'], // käytetäänkö mieluummin Properties.Pset_ElementInfo.Selectable?
      internalIds => {
        this.v3.setSelectionIgnoredItems(
          modelName,
          undefined,
          internalIds
        )
      }
    )
  }

  getSectionProperties (projectId) {
    const that = this
    return new Promise(resolve => {
      this.v3.searchElements(
        projectId,
        [['Properties.Pset_SpaceCommon.GrossPlannedArea.value', '1.']],
        function (elements) {
          const splitter = '(£)'
          const dwgData = {}
          let iterativeId = 0
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i].Properties.Pset_SpaceCommon
            if (typeof element.Reference === 'undefined') {
              continue
            }
            const ref = element.Reference.value

            let tempGroup
            let tempId
            const tempGlobalId = elements[i]['Ifc-GlobalId']
            const tempInternalId = elements[i].InternalId
            const tempOriginalColor = JSON.parse(elements[i]['Ifc-Name'])
              ._v3_line_color

            if (ref.indexOf(splitter) >= 0) {
              const refSplit = ref.split(splitter)
              tempGroup = refSplit[0]
              if (that.elementLayers.indexOf(tempGroup) === -1) {
                that.elementLayers.push(tempGroup)
              }
              tempId = refSplit[1]
            } else {
              tempGroup = ref
              tempId = iterativeId++
            }
            if (dwgData[tempGroup] === undefined) {
              dwgData[tempGroup] = []
            }
            // Data should only include items from spaces(1) and units(2) groups
            // EDIT 10.1.2020 - Data now includes car spaces(101) and outdoor areas(102) as well
            if (
              tempGroup === '1' ||
              tempGroup === '2' ||
              tempGroup === '101' ||
              tempGroup === '102' ||
              tempGroup === '106'
            ) {
              dwgData[tempGroup].push({
                layer: tempGroup,
                element_id: tempId,
                global_id: tempGlobalId,
                internal_id: tempInternalId,
                original_color: tempOriginalColor
              })
            }
          }
          if (that.elementLayers.length > 0) {
            that.selectedElementLayer = that.elementLayers[0]
          }
          resolve(dwgData)
        }
      )
    })
  }

  getModelLayers () {
    const projectId = this.loadedData.projectId
    const modelHandle = this.loadedData.modelHandle

    return new Promise((resolve, reject) => {
      this.v3.getDistinctValues(
        projectId,
        modelHandle,
        'Properties.Pset_SpaceCommon.Reference.value',
        layers => {
          resolve(layers)
        },
        reject
      )
    })
  }

  async selectAllElementsFromLayer (layer) {
    const elements = await this.getLayerElements(layer, this.loadedData.projectId)
    elements.forEach(element => {
      this.v3.selectElementByInternalId(element)
    })
  }

  beginRectangleSelection () {
    this.v3.beginRectangleSelection()
  }

  getStoredView () {
    return this.v3.storedView()
  }

  getAllMarkups () {
    return this.createdMarkups
  }

  removeMarkup () {

  }

  removeAllMarkups () {
    this.v3.removeAllMarkups()
  }

  removeAllMeasurements () {
    this.v3.removeMeasurements()
  }

  /**
   * Method that is called after creating new markup and having finished with a markup
   * @param {*} newMarkup - When you use the polylineMarkup, this first contains the storedView containing the markup.
   * After finishing, this method is called multiple times for each polyline and finally with undefined
   */
  onMarkup (newMarkup) {
    if (typeof(newMarkup) === 'undefined') {
      this.toolFinishedCallback()
    } else {
      this.createdMarkups.push(newMarkup)
    }
  }

  setToolFinishedCallback (callback) {
    this.toolFinishedCallback = callback
  }

  setMarkupColor (hexColor) {
    this.v3.setMarkupOptions({
      colors: {
        lineMarkup: hexColor,
        measurement: hexColor,
      }
    })
  }

  /**
   * Start using a tool, where you select multiple points inside the viewer and you are then shown
   * the area inside these points.
   */
  startAreaMeasurementTool () {
    this.v3.startAreaMeasurement({ uniformHeight: true })
  }

  /**
   * Start using a tool, where you select two points and are shown the distance between these points
   */
  startDistanceMeasurementTool () {
    this.v3.startMeasurement({ uniformHeight: true })
  }

  /**
   * Start using a tool, where you can freely draw inside the viewer
   */
  startPenTool () {
    this.v3.startPolylineMarkup({ selectPlane: false })
  }

  /**
   * Start using a tool, where you draw a rectangle with the given text inside of it.
   */
  startRectangleTool (text) {
    this.v3.startRectangleMarkup(
      { text: text, callback: (newRect) => { this.createdRectangles.push(newRect); this.toolFinishedCallback() } }
    )
  }

  /**
   * Start using a tool, where you can select shapes and measurements created with other tools and remove them
   */
  startEraserTool () {
    // Add event listener for right click, so we can use that event for disabling clearMode.
    // That way it works in the same way as the other tools.
    const element = window.document.getElementById(this.containerId)
    element.addEventListener('contextmenu', () => { this.toolFinishedCallback() }, { once: true })

    this.clearMode = true
  }
}

export default V3Wrapper
