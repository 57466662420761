import { i18n } from '../../plugins/i18n'

export function carparks (carparks, sites) {
    const carParksBySite = {}
    const freeCarParksBySite = {}
    const marketRentsForRentedBySite = {}
    const freeMarketRentsBySite = {}
    
    const data = sites.map(s => {
      const summary = { car_parks: 0, free_car_parks: 0, market_rent_for_rented: 0, free_market_rent: 0, ecars_charge_stations: 0, landlords: '', rental_type: '', carpark_type: '' }
      const carParksForSite = carparks.filter(cp => cp.idSite === s.id_site && cp.rentingtype === 'Single')
      const carParksForSiteSummary = carParksForSite.reduce((total, cur) => {

        const eChargingStations = cur.charge_in === 'Latausmahdollisuus' ? cur.num_of_car_spaces : 0

        total.car_parks += cur.num_of_car_spaces
        total.free_car_parks += cur.num_of_free_car_spaces
        total.market_rent_for_rented += ((cur.num_of_car_spaces - cur.num_of_free_car_spaces) * cur.market_rent_per_car_space)
        total.free_market_rent += (cur.num_of_free_car_spaces * cur.market_rent_per_car_space)
        total.ecars_charge_stations += eChargingStations
        total.landlords = total.landlords && cur.renter && !total.landlords.includes(cur.renter) ? i18n.t('Multiple') : cur.renter ? cur.renter : ''
        total.rental_type = total.rental_type && cur.rentingtype && !total.rental_type.includes(cur.rentingtype) ? 'Multiple' : cur.rentingtype ? cur.rentingtype : ''
        total.carpark_type = total.carpark_type && cur.type && !total.carpark_type.includes(cur.type) ? i18n.t('Multiple') : cur.type ? cur.type : ''
        return total
      }, summary)
      
      carParksBySite[s.id_site] = carParksForSiteSummary.car_parks
      freeCarParksBySite[s.id_site] = carParksForSiteSummary.free_car_parks
      marketRentsForRentedBySite[s.id_site] = carParksForSiteSummary.market_rent_for_rented
      freeMarketRentsBySite[s.id_site] = carParksForSiteSummary.free_market_rent
      
      return {
        siteName: s.name,
        id_site: s.id_site,
        site_identifier: s.site_identifier,
        car_parks: carParksForSiteSummary.car_parks,
        free_car_parks: carParksForSiteSummary.free_car_parks,
        usage_rate: (carParksForSiteSummary.car_parks - carParksForSiteSummary.free_car_parks) / carParksForSiteSummary.car_parks * 100,
        market_rent_for_rented: carParksForSiteSummary.market_rent_for_rented,
        free_market_rent: carParksForSiteSummary.free_market_rent,
        economic_usage_rate: (carParksForSiteSummary.market_rent_for_rented / (carParksForSiteSummary.free_market_rent + carParksForSiteSummary.market_rent_for_rented)) * 100,
        ecars_charge_stations: carParksForSiteSummary.ecars_charge_stations,
        landlords: carParksForSiteSummary.landlords,
        rental_type: i18n.t(carParksForSiteSummary.rental_type),
        carpark_type: carParksForSiteSummary.carpark_type
      }
    })

    const totalCarParks = Object.values(carParksBySite).reduce((a,b) => a + b, 0)
    const totalFreeCarParks = Object.values(freeCarParksBySite).reduce((a,b) => a + b, 0)
    const totalUsageRate = (totalCarParks - totalFreeCarParks) / totalCarParks * 100

    const totalMarketRentForRented = Object.values(marketRentsForRentedBySite).reduce((a,b) => a + b, 0)
    const totalFreeMarketRent = Object.values(freeMarketRentsBySite).reduce((a,b) => a + b, 0)
    const totalEconomicUsageRate = (totalMarketRentForRented / (totalFreeMarketRent + totalMarketRentForRented)) * 100
    
    return data.filter(s => s.car_parks > 0).map(s =>
      {
        s.total_usage_rate = totalUsageRate,
        s.total_economic_usage_rate = totalEconomicUsageRate
        return s
      })
  }


export function siteCarparks (carparkSites, rentingType) {
    // First calculate totals for footer
    const totalCarparks = carparkSites.reduce((acc, curr) => acc + curr.carparks, 0) 
    const totalRentedCarparks = carparkSites.reduce((acc, curr) => acc + curr.carparks_rented, 0)
    const totalUsageRate = totalRentedCarparks / totalCarparks * 100

    const totalMarketRent = rentingType === 'Area' ? carparkSites.reduce((acc, curr) => acc + curr.carparks_market_rent_total, 0) : carparkSites.reduce((acc, curr) => acc + curr.privileges_market_rent_total, 0)
    const totalRentedMarketRent = carparkSites.reduce((acc, curr) => acc + curr.market_rent_rented, 0)
    const totalEconomicUsageRate = totalRentedMarketRent / totalMarketRent * 100

    let totalMaxReservationRate = 0
    if (rentingType === 'Privilege') {
      const totalParkingRightsMax = carparkSites.reduce((acc, curr) => acc + curr.max_parking_rights, 0)
      totalMaxReservationRate = totalParkingRightsMax / totalCarparks
    }

    let data = carparkSites.map(cp => {
      const type = cp.types.length === 1 ? cp.types[0] : i18n.t('Multiple')
      const renter = cp.renters.length === 1 ? cp.renters[0] : i18n.t('Multiple')

      const carparksBySite = {
        id_site: cp.id_site,
        siteName: cp.site_name,
        site_identifier: cp.site_identifier,
        carpark_zones: cp.areas,
        carparks: cp.carparks,
        usage_rate: cp.carparks_rented / cp.carparks * 100,
        market_rent_rented: cp.market_rent_rented,
        charging_spots_amount: cp.charging_spots_amount,
        renter,
        type,
        total_economic_usage_rate: totalEconomicUsageRate,
        total_usage_rate: totalUsageRate
      }

      // Some fields are calculated from different fields depending on renting type
      if (rentingType === 'Area') {
        carparksBySite.carparks_free = cp.carparks - cp.carparks_rented
        
        carparksBySite.market_rent_free = cp.market_rent_rented === null && cp.carparks_market_rent_total === null ? null : cp.carparks_market_rent_total - cp.market_rent_rented
        carparksBySite.economic_usage_rate = cp.market_rent_rented / cp.carparks_market_rent_total * 100
      }
      else if (rentingType === 'Privilege') {
        carparksBySite.max_parking_rights = cp.max_parking_rights
        carparksBySite.parking_rights_given = cp.carparks_rented
        carparksBySite.parking_rights_remain = cp.max_parking_rights ? cp.max_parking_rights - cp.carparks_rented : null
        carparksBySite.max_reservation_rate = cp.max_parking_rights / cp.carparks
        
        carparksBySite.market_rent_free = cp.market_rent_rented === null && cp.privileges_market_rent_total === null ? null : cp.privileges_market_rent_total - cp.market_rent_rented
        carparksBySite.economic_usage_rate = cp.market_rent_rented / cp.privileges_market_rent_total * 100

        carparksBySite.total_max_reservation_rate = totalMaxReservationRate
      }

      return carparksBySite
    })
    
    return data
  }