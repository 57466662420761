<template>
  <v-tab-item :key="0">
    <h3>{{ $t('ChooseRentReviewCriteria') }}</h3>
    <ValidationObserver ref="observer">
      <v-form ref="form">
        <v-row
          class="mt-4"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selectedRentReviewType"
              :items="rentReviewTypes"
              item-text="label"
              item-value="id"
              clearable
              :label="$t('create_rent_contract.payment_rent_review')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <DatePicker
              :label="$t('RentalCheckMonth')"
              :value="formatDate(selectedMonth)"
              :hint="$t('Required')"
              :default-hint="false"
              :rules="'required'"
              required
              class="mt-1 ml-0"
              :display-month-only="true"
              :initial-date="selectedMonth"
              :calendar-type="'month'"
              @dateupdated="selectedDateUpdated"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selectedContractType"
              :items="contractTypes"
              item-text="label"
              item-value="id"
              clearable
              :label="$t('ContractType')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selectedTypeForContract"
              :items="TypesForContract"
              item-text="label"
              item-value="name"
              clearable
              :label="$t('leasing.contract_type')"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="selectedContractNumbers"
              small-chips
              clearable
              deletable-chips
              multiple
              hide-details
              :items="contractNumbers"
              :label="$t('Contract number')"
              :menu-props="{ maxHeight: 400 }"
            >
              <template #prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="selectAll('contractNumbers')"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ allSelected ? $t('site_filters.deselect') : $t('site_filters.select') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selectedClientele"
              :items="clienteleList"
              item-text="label"
              item-value="id"
              clearable
              :label="$t('Clientele')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-if="governanceEntityList.length > 0"
              v-model="selectedGovernanceEntity"
              :items="governanceEntityList"
              item-text="label"
              item-value="id"
              clearable
              :label="$t('Governance entity')"
            />
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-tab-item>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '../general/DatePicker.vue'

export default {
  name: "RentReviewSearchTab",
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker
  },
  props: {
    rentReviewTypes: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      rentReviewMonths: [],
      TypesForContract : [
        {
          label: this.$t("Internal"),
          name: "internal"
        },
        {
          label: this.$t("External"),
          name: "external"
        },
        {
          label: this.$t("Sublease"),
          name: "sublease"
        }
      ],
      selectedMonth: undefined,
      selectedRentReviewType: undefined,
      selectedContractType: undefined,
      selectedTypeForContract: undefined,
      selectedContractNumbers: [],
      selectedClientele: undefined,
      selectedGovernanceEntity: undefined,
      buildingMetadata: {},
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById', 'definitionByLabel', 'definitionsByGroupLabel', 'definitionsByGroupId']),
    ...mapState('billing', ['searchKeys']),
    contractTypes () {
      return this.definitionsByGroupLabel('contract.additionalinfo_contracttypes')
    },
    contractNumbers () {
      return this.searchKeys.contractNumbers.filter(c => c !== this.$t('InvoicesWithNoContract'))
    },
    clienteleList () {
      return this.definitionsByGroupLabel('party.clientele')
    },
    governanceEntityList () {      
      return this.buildingMetadata?.governance_entity ? this.definitionsByGroupLabel('building.governanceentity').map(d => ({
        id: d.id,
        label: this.$t(d.label)
      })) : []
    },
    allSelected () {
      return this.selectedContractNumbers.length == this.searchKeys.contractNumbers.length
    },
    someSelected () {
      return this.selectedContractNumbers.length > 0 && !this.allSelected
    },
    icon () {
      if (this.allSelected) return 'check_box'
      if (this.someSelected) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
  },
  async mounted () {
    this.selectedRentReviewType = this.definitionsByGroupLabel('rent_payments.rent_review')
      .filter(type => type.label === 'PaymentRentReviewIndex')
      .map(type => type.id)[0]
    await this.loadMetadata()
  },
  methods: {
    ...mapActions('sites', ['loadMetaData']),
    async loadMetadata () {
      const buildingMetadata = await this.$rambollfmapi.buildings.reports().metadata()
      this.buildingMetadata = buildingMetadata
    },
    selectedDateUpdated (value) {
      this.selectedMonth = value
    },
    selectAll () {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedContractNumbers = []
        } else {
          this.selectedContractNumbers = this.searchKeys.contractNumbers.slice()
        }
      })
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("MM/YYYY") : undefined
    },
    GetRightContractType (name)
    {
      const cts = new Map();
      cts.set('internal',this.$t('Internal'))
      cts.set('external',this.$t('External'))
      cts.set('sublease',this.$t('Sublease'))
      const found = cts.get(name)
      return found
    },
    async executeSearch () {
      if (await this.$refs.observer.validate()) {    
        let typeForContractSelected = this.selectedTypeForContract !== undefined &&
          this.selectedTypeForContract !== ""
        this.$emit('goToRentReviews', {
          rentReviewMonth: new Date(this.selectedMonth + '-01'),
          defRentReviewType: this.selectedRentReviewType,
          defContractType: this.selectedContractType,
          isOutRent: typeForContractSelected ? this.selectedTypeForContract === 'external' : undefined,
          contractType: this.GetRightContractType(this.selectedTypeForContract),
          isSubLease: typeForContractSelected ? this.selectedTypeForContract === 'sublease' : undefined,
          defClientele: this.selectedClientele,
          defGovernanceEntity: this.selectedGovernanceEntity,
          contractNumbers: this.selectedContractNumbers.length > 0 ? this.selectedContractNumbers.reduce((f, s) => `${f},${s}`) : ""
        })
      }
    }
  }
}
</script>

<style scoped>
>>> .v-select__slot {
  min-height: 34px;
}

.v-select--chips {
  height: auto !important;
}
</style>