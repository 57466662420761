<template>
  <div class="kanban-widget-column">
    <v-container
      fluid
      class="fill-height"
    >
      <v-row
        justify="center"
        align-content="center"
      >
        <v-col
          cols="12"
          class="kanban-widget-column-header d-flex justify-center"
        >
          {{ title }}
        </v-col>
      </v-row>
      <v-row
        dense
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop"
      >
        <v-col>
          <div class="kanban-widget-column-content">
            <v-container fluid>
              <kanban-widget-chip
                v-for="(row, index) in rows"
                :key="row.title + row.subTitle + index"
                :title="row.title"
                :subtitle="row.subTitle"
                :icon="row.icon"
                :enable-modification="enableModification"
                @dragstart="onDragStart"
                @click="$emit('click', { header: row.header, row: row.item})" 
              />
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-if="footer"
          class="kanban-widget-column-footer d-flex justify-center"
          cols="12"
        >
          {{ footer }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import KanbanWidgetChip from "./KanbanWidgetChip.vue"
export default {
  components: { KanbanWidgetChip },
  props: {
    dataGroup: { type: Object, default: () => ({}) },
    // Not used currently. Still added some handleres etc. for future needs.
    enableModification: { type: Boolean, default: false },
  },
  data () {
    return {}
  },
  computed: {
    rows () {
      if (this.dataGroup && this.dataGroup.items && this.dataGroup.header) {
        const { title, subTitle, icon } = this.dataGroup.header

        return this.dataGroup.items.map((item) => {
          return {
            title: item[title],
            subTitle: item[subTitle],
            icon: item[icon],
            item,
            header: this.dataGroup.header
          }
        })
      }

      return []
    },
    title () {
      if (this.dataGroup && this.dataGroup.header && this.dataGroup.header.text) {
        return this.$t(this.dataGroup.header.text)
      }
      return ""
    },
    footer () {

      if(this.dataGroup && this.dataGroup.header && this.dataGroup.header.footer){
        return this.dataGroup.header.footer(this.dataGroup.items)
      }

      return null
    }
  },
  methods: {
    /**
     * Handler for drag start
     * @param {DragEvent} e
     */
    onDragStart () {},
    /**
     * Handler for drag stop
     * @param {DragEvent} e
     */
    onDrop () {},
  },
}
</script>
<style scoped>
.kanban-widget-column {
  min-width: 300px;
  background-color: #eeeff5;
  border-radius: 24px;
}

.kanban-widget-column-header {
  margin-top: 10px;
  font-size: 14px;
  color: #8b8b8b;
  text-transform: uppercase;
}

.kanban-widget-column-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 700px;
}

.kanban-widget-column-footer {
  color: #8b8b8b;
  font-size: 12px;
  margin-bottom: 10px;
}
</style>
