<template>
  <v-row
    align="center"
  >
    <div class="mr-1">
      {{ formatData(props.item, header.value, header.format, true) }}
    </div>
    <div v-if="showAlert">
      <info-ball
        icon="error"
        color="warning"
        :bottom="true"
      />
    </div>
  </v-row>
</template>

<script>
import InfoBall from "../../InfoBall.vue"
import helpers from '../../../helpers'

export default {
  name: "UnitEndDateAlert",
  components: { InfoBall },
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
  },
  computed: {
    showAlert () {
      const { unit_end_date } = this.props.item

      if (unit_end_date) {
        return true
      }

      return false
    },
  },
  methods: {
        formatData (row, header, format, isLink = false) {
      const value = isLink
        ? helpers.format.escapeHtml(row[header])
        : row[header]
      return helpers.format.formatData(value, format)
    },
  },
}
</script>

<style scoped></style>
