<template>
  <v-dialog
    v-model="showDialog"
    max-width="550"
    persistent
  >
    <v-card>
      <div class="dialog-content">
        <div class="text-h5 lighten-2">
          {{ $t(title) }}
        </div>
        <v-card
          elevation="0"
        >
          <slot name="content" />
        </v-card>
      </div>
      <v-card
        elevation="0"
        class="dialog-footer"
      >
        <v-row
          justify="space-between"
          no-gutters
        >
          <slot name="hideCancel">
            <v-btn
              text
              rounded
              outlined
              @click="cancel"
            >
              {{ $t('Return') }}
            </v-btn>
          </slot>
          <v-row
            justify="end"
            no-gutters
          >
            <v-btn
              v-if="negative"
              depressed
              rounded
              color="error"
              @click="confirm(false)"
            >
              {{ $t(negative) }}
            </v-btn>
            <v-btn
              v-if="positive"
              depressed
              rounded
              color="primary"
              @click="confirm(true)"
            >
              {{ $t(positive) }}
            </v-btn>
          </v-row>
          <slot name="footer" />
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
/*
Example use of this component
  // place **<ConfirmDialog ref="confirmDialog" />** to your vue component
    try {
      const ok = await this.$refs.confirmDialog.show({
        title: 'title here', //-- Required
        negative: 'negative button text here', //red button -- optional, remove this line if not needed
        positive: "positive text here", //green button -- optional, , remove this line if not needed
      })
      if (ok) {
        // do something if positive button clicked
      } else {
        // do something if negative button clicked, remove if not needed
      }
    } catch (err) {
        return // or do something if cancel button clicked
    }
*/
  name: 'ConfirmDialog',
  props: {},
  data () {
    return {
      negative: undefined,
      positive : undefined,
      title: undefined,

      showDialog: false,

      resolvePromise: undefined,
      rejectPromise: undefined,
  }
  },
  methods: {   
    show (opts = {}) {
      this.title = opts.title
      this.negative = opts.negative
      this.positive = opts.positive
      this.showDialog = true

      return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
      })
    },
    confirm (value) {
      this.showDialog = false
      this.resolvePromise(value)
    },
    cancel () {
      this.showDialog = false
      this.rejectPromise('Canceled')
    }
  }
}
</script>
<style scoped>
.dialog-content {
  padding: 10%;
}
.dialog-footer {
  padding: 5% 7%;
  padding-top: 0;
  position: sticky;
}
</style>
