<template>
  <div>
    <v-dialog
      :value="shownCorporation && showCorporationModal"
      persistent
      width="700"
    >
      <v-card>
        <CorporationModal
          :corporation="shownCorporation"
          :rental-processes="rentalProcesses"
          :is-shown="showCorporationModal"
        />
      </v-card>
    </v-dialog>
    <div
      id="site-rentingprocesses"
      class="ma-4"
    >
      <SortableWidgetLayout
        :widgets="widgets"
        @onCustomEvent="onCustomEvent"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SortableWidgetLayout from '../components/widgets/SortableWidgetLayout'
import CorporationModal from '../components/Leasing/CorporationModal'
import { RentingProcesses, RentingProcessWorkQueue } from '../widgets/site'
import { toRentingWidgetData } from "../widgets/leasing/rentingWidget"
import { toRentingProcessWorkQueueData, getRentingProcessWorkQueueHeaders } from "../widgets/leasing/rentingProcessWorkQueue"

export default {
  name: 'RentingProcesses',
  components: {
    SortableWidgetLayout,
    CorporationModal
  },
  metaInfo () {
    return {
      title: `${this.$t('leasing.rentingProcess')} ·`,
    }
  },
  data () {
    return {
      widgets: [
        RentingProcesses(),
        RentingProcessWorkQueue()
      ],
    }
  },
  computed: {
    ...mapState('leasing', ['leasingUsers', 'municipalities', 'shownCorporation', 'showCorporationModal',]),
    ...mapState('sites', ['currentSiteId', 'loadedBuildings']),
    ...mapState('rentalProcess', ['rentalProcesses']),
    ...mapState('app', ['sites']),
    ...mapGetters('app', ['hasApplicationPermissionByName', 'definitionsByGroupLabel', 'definitionById']),

    rentalProcessStages () {
      return this.definitionsByGroupLabel('leasing.rental_process_stage')
    },
  },
  watch: {
    loadedBuildings: {
      handler: async function () {
        await this.initView()
      },
      deep: true,
    },
    rentalProcesses: function () {
      this.updateWidgetData()
    },
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('LEASING')) {
      this.$router.push({ name: 'sites.info' })
    }
    await this.initView()
  },
  methods: {
    ...mapActions('rentalProcess', ['getRentalProcess', 'openRentalProcess']),
    ...mapActions('leasing', ['getMunicipalities', 'getLeasingUsers']),
    async initView () {
      await this.getMunicipalities()
      await this.getLeasingUsers()
      await this.getRentalProcess({siteId: this.currentSiteId})
      this.updateWidgetData()
      this.$store.commit('app/setSortableWidgets', false)
    },
    updateWidgetData () {
      this.widgets.forEach((w) => {
        if (w.data.source !== undefined && w.data.source !== null) {
          // if source is defined in widget, use the data from component
          if (typeof this[w.data.source] === 'function') {
            w.data.items = this[w.data.source]()
          }
          else {
            w.data.items = this[w.data.source]
          }
          w.loading = false
        }

        if (w.data.dynamicHeaders) {
          w.data.headers = this.getWidgetHeaders(w)
        }

        if (w.data.dynamicFilterOptions  && w.data.items.length) {
          w.data.filter.options = this.getWidgetFilters(w)
        }
      })
    },
    rentingProcesses () {
      return toRentingWidgetData(this.rentalProcesses, this.leasingUsers, this.getDefinitionLabelById, this.municipalities)
    },
    rentingProcessQueue () {
      return toRentingProcessWorkQueueData(this.rentalProcesses, this.sites)
    },
    getWidgetHeaders (widget) {
      switch (widget.id) {
        case 'site.renting_process_work_queue':
          return getRentingProcessWorkQueueHeaders(this.rentalProcessStages)
        default:
          return widget.data.headers
      }
    },
    getWidgetFilters (widget) {
        switch (widget.id) {
          case 'site.renting_processes': {
            widget.data.filter.header = "stage"
            const itemsFromFilteredColumn = widget.data.items.reduce((uniqueValues, currentItem) => {
              if(currentItem[widget.data.filter.header] && !uniqueValues.includes(currentItem[widget.data.filter.header]))
              {
                uniqueValues.push(currentItem[widget.data.filter.header])
              }
              return uniqueValues
            }, [])
            const filterOptions = [{ text: 'prospect.show_all', value: null }].concat(itemsFromFilteredColumn.sort().map(i => {
              return { text: i, value: i }
            }))
            return filterOptions
          }
          default:
            return []
        }
    },
    onCustomEvent (event){
      const { eventName, row } = event

      if(eventName === "onClickRentingProcessWorkQueue"){
        this.openRentalProcess(row)
      }
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },
  },
}
</script>
<style scoped>
#site-rentingprocesses {
  padding: 0.5em;
  padding-top: 0;
}

#site-rentingprocesses > .col {
  padding: 0.5em;
  padding-top: 0;
}

#site-rentingprocesses > .col > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#site-rentingprocesses .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#site-rentingprocesses .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#site-rentingprocesses .subpanel:last-child {
  margin-bottom: 0.5em;
}

#site-rentingprocesses .widget {
  padding-top: 1em;
}
</style>
