export default {
  Dashboard () {
      return [
        {
            id: 'dashboard.dashboard.lazytenants',
            type: 'LazyTable',
            loading: true,
            data: {
                headers: [
                    { text: 'Tenant', value: 'current_tenant_name' },
                    { text: 'Site', value: 'site_name'
                    },
                    {
                        text: 'Unit',
                        value: 'unit_name'
                    },
                    {
                        text: 'Net floor area',
                        value: 'unit_area'
                    },
                    {
                        text: 'Usages',
                        value: 'unit_class'
                    },
                    {
                        text: 'Start date',
                        value: 'start_date'
                    },
                    {
                        text: 'End date',
                        value: 'end_date'
                    }
                ],
                links: {
                    site_name: function (row) {
                      return {
                        name: 'sites.info',
                        params: { id_site: row.id_site}
                      }
                    }
                  },
                source: 'lazytenants'
            }
        }]
  }
}