<template>
  <v-expansion-panels
    class="my-2"
    multiple
    :value="[0]"
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="py-1 pl-2">
        {{ $t('Basic information') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getBasicMetadataForUnit(unit)"
          :data="getBasicDataForUnit(unit)"
          :with-status-icon="true"
          @openContract="$emit('openContract', $event)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import MetadataForm from '../../components/MetadataForm.vue'
export default {
    name: 'UnitBasicInformation',
    components: { MetadataForm },
    props: {
      unit: {
        type: Object,
        default: null
      },
      show: { type: Object, default: null },
      unitExtraInformations: { type: Array, default: () => [] },
      unitVisibilityRights: { type: Object, default: null },
      contractVisibilityRights: { type: Object, default: null },
      layer: { type: String, default: '' },
      readonly: { type: Boolean, default: null },
      contractLinks: { type: Array, default: () => []}
    },
    data () {
      return {
        isSavingChanges: false
      }
    },
    methods: {
      getBasicDataForUnit (unit) {
        const contracts = this.contractLinks.filter(link => link.id_unit === unit.id)
          .map(c => {
            c.value = c.contract_number
            c.type = 'Contract'
            return c
          })

        return {
          unit_rental_status:
          unit.not_rentable !== null
              ? this.getUnitRentalStatus(unit.not_rentable) : this.$t('Undefined'),
          unit_area: this.getUnitAreaValue(unit),
          total_unit_area_from_spaces: this.getUnitAreaValue(unit),
          premise_type: unit.usage !== null ? 
              this.$t(unit.usage) : this.$t('Undefined'),
          unit_version_end_date: unit.end_date,
          unit_version_start_date: unit.start_date,
          unit_name: unit.name,
          unit_identifier: unit.unit_identifier,
          empty_space_contract_area: unit.empty_space_contract_area,
          unit_code_long: unit.unit_code_long,
          contract_number: contracts,
          allocated_unit_area: unit.allocated_unit_area
        }
      },
    getBasicMetadataForUnit (unit) {
      return {
        unit_code_long: {
          ...this.showUnitProp("unit_code_long")
        },
        unit_rental_status: {
          ...this.showUnitProp('not_rentable')
        },
        unit_area: {
            ...this.showUnitProp('area'),
            format: 'Area'
        },
        total_unit_area_from_spaces: {
          ...this.showUnitProp('total_unit_area_from_spaces'),
          format: 'Area'
        },
        premise_type: {
             ...this.showUnitProp('usage')
        },
        unit_version_end_date: {
          ...this.showUnitProp('end_date'),
          isShown: unit.end_date != null,
          format: 'Date'
        },
        unit_version_start_date: {
          ...this.showUnitProp('start_date'),
          isShown: unit.start_date != null,
          format: 'Date'
        },
        unit_name: {
          ...this.showUnitProp('name')
        },
        unit_identifier: {
          ...this.showUnitProp('unit_identifier')
        },
        empty_space_contract_area: {
          ...this.showUnitProp('empty_space_contract_area'),
          isShown: (this.unitVisibilityRights['empty_space_contract_area']?.isShown ?? false)
        },
        contract_number: {
          ...this.showContractProp('contract_number')
        },
        allocated_unit_area: {
          ...this.showUnitProp('allocated_unit_area'),
        }
      }
    },
    showUnitProp (prop) {
      const metadata = this.unitVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
      } else {
        return { isShown: false, category: 'None', order: 999, format: 0 }
      }
    },
    showContractProp (prop) {
      const metadata = this.contractVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        // show all contract data after unit data, so we increase order with 1000
        return { isShown, category, order: order + 1000, format, type: 'Array' }
      } else {
        return { isShown: false, category: 'None', order: 999, format: 0 }
      }
    },
    getUnitRentalStatus (defId) {
      if (defId === null) {
        return this.$t('rentable')
      } else {
        return this.$t(defId)
      }
    },
    getUnitAreaValue (unit) {
      // If we have Huone layer active...
      if (this.layer === '1') {
        // ...return the value of summed unit area value from connected spaces
        return unit.area_from_spaces
      }
      // Otherwise, return unit area as usual
      return unit.area
    }
  }
}
</script>
<style scoped>
</style>