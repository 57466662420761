import { i18n } from "../plugins/i18n"

export function AlertsWidget (widgetName) {
  const dashboardDefault = { text: i18n.t('alert.own_active'), value: [true, null, 'alert.completed', null ] }
  const siteInfoDefault = { text: i18n.t('alert.all_active'), value: [null, false, 'alert.completed', null ] }
  const defaultFilter = widgetName === 'dashboard.alerts' ? dashboardDefault : siteInfoDefault

  return {
    id: widgetName,
    type: 'DataTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Linking'), value: 'linking' },
        { text: i18n.t('alert.identifier'), value: 'alertIdentifier', event: 'showCreateRentContractModal' },
        { text: i18n.t('Site'), value: 'site_name' },
        { text: i18n.t('alert.text'), value: 'alertText', hideOverflow: true },
        { text: i18n.t('alert.due_date'), value: 'taskDate', format: 'Date', status: true },
        { text: i18n.t('alert.alert_date'), value: 'alertDate', format: 'Date' },
        { text: i18n.t('alert.status'), value: 'alertStatus' },
        { text: i18n.t('alert.status_text'), value: 'alertStatusText', hideOverflow: true },
        { text: i18n.t('Actions'), value: 'customComponent' }
      ],
      browseHeaders: [
        { text: i18n.t('Linking'), value: 'linking' },
        { text: i18n.t('alert.identifier'), value: 'alertIdentifier', event: 'showCreateRentContractModal' },
        { text: i18n.t('Site'), value: 'site_name' },
        { text: i18n.t('alert.text'), value: 'alertText', hideOverflow: true },
        { text: i18n.t('alert.due_date'), value: 'taskDate', format: 'Date' },
        { text: i18n.t('alert.alert_date'), value: 'alertDate', format: 'Date' },
        { text: i18n.t('alert.status'), value: 'alertStatus' },
        { text: i18n.t('alert.status_text'), value: 'alertStatusText', hideOverflow: true },
        { text: i18n.t('alert.created_by'), value: 'createdByUserName', },
        { text: i18n.t('alert.receivers'), value: 'alertReceiverNames', },
        { text: i18n.t('alert.completed_by'), value: 'alertCompletedName', },
        { text: i18n.t('Actions'), value: 'customComponent' }
      ],
      filter: {
        header: ['alertSelf', 'createdBySelf', 'alertStatus'],
        type: ['equal', 'equal', 'notEqual', 'equal'],
        options: [
          dashboardDefault,
          siteInfoDefault,
          { text: i18n.t('alert.own_created'), value: [null, true], lessThan: false  },
          { text: i18n.t('alert.all_past'), value: [null, false, 'alert.not_completed'], isObject: true }
        ],
        defaultFilter
      },
      customFormatters: {},
      footers: [],
      items: [],
      sortBy: 'taskDate',
      report: 'GetAlerts'
    }
  }
}
