<template>
  <v-tooltip
    v-bind="$attrs"
    :attach="attach"
    :bottom="bottom"
    :disabled="tooltipDisabled"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        size="24"
        :color="color"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
    </template>
    <div class="max-width">
      {{ info }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "InfoBall",
  props: {
    info: {
      type: String,
      default: "",
    },
    // v-tooltip takes any type prop... This is just passing through so we can define
    // different component for the attachment point.
    // eslint-disable-next-line vue/require-prop-types
    attach: {
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    icon: { type: String, default: "info" },
    color: { type: String, default: "" },
  },
  computed: {
    tooltipDisabled () {
      return !this.info
    }
  },
}
</script>

<style scoped>
.max-width {
  max-width: 25em;
}
</style>
