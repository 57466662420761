<template>
  <v-main id="customizable-additional-data-fields">
    <div>
      <v-dialog
        v-model="dataFieldDialog"
        persistent
        max-width="550"
      >
        <BaseModal
          @cancel="cancelFieldModifying"
        >
          <template #title>
            {{ $t('Create new additional information field') }}
          </template>
          <template #content>
            <AdditionalDataFieldsForm
              v-if="dataFieldDialog"
              :data="openAdditionalDataField"
              :addingnew="true"
              :edit="true"
              @cancel="cancelFieldModifying"
              @saveChanges="saveChanges"
            />
          </template>
          <template #hideCancel>
            <p />
          </template>
        </BaseModal>
      </v-dialog>
      <v-toolbar
        flat
        tile
      >
        <v-toolbar-title>
          <h2>{{ $t('Customizable additional data fields') }}</h2>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            :class="{selected: edit}"
            @click="handleEdit"
          >
            <v-icon>create</v-icon>
          </v-btn>
          <v-btn
            v-if="additionalDataFields.length < 5 "
            icon
            @click="openDataFieldDialog"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <div
      v-if="loading"
    >
      <v-progress-circular
        :size="100"
        :width="3"
        indeterminate
        color="primary"
      />
    </div>
    <v-row
      v-else
      class="dashboard"
    >
      <v-col
        v-for="(additionalDataField, index) in additionalDataFields"
        :key="additionalDataField.id"
        lg="4"
        md="6"
        sm="12"
        cols="12"
      >
        <AdditionalDataFieldsForm
          :data="additionalDataField"
          :dataindex="index"
          :edit="edit"
          @deleteData="deleteData"
          @saveChanges="saveChanges"
        />
      </v-col>
    </v-row>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </v-main>
</template>
<script>
import AdditionalDataFieldsForm from '../components/general/AdditionalDataFieldsForm'
import BaseModal from '../components/general/BaseModal.vue'
import Alert from '../components/Alert'
export default {
  name: 'StatusPortfolios',
  components: { AdditionalDataFieldsForm, Alert, BaseModal, },
  data () {
    return {
      additionalDataFields: [],
      openAdditionalDataField: {},
      edit: false,
      loading: true,
      dataFieldDialog: false,
      indicatorMessage: '',
      showIndicator: false,
      operationResult: '',
      yPosition: 'top'
    }
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.showIndicator = false
          this.indicatorMessage = ''
          this.operationResult = ''
        }, 4000)
      }
    }
  },
  async mounted (){
    this.getCustomizableDataFields()
    this.loading = false
  },
  methods: {
    async getCustomizableDataFields () {
      const result = await this.$rambollfmapi.customizabledatafields.get()
      if(result.name === 'Error') {
        this.showFailMessage(this.$t('Loading customizabledatafields failed'))
      } else {
        this.additionalDataFields = result.items
      }     
    },
    async deleteData (item) {
      if(confirm(this.$t('Are you sure you wish to delete additional data field?'))) {
        const itemIndex = this.additionalDataFields.findIndex(dataField => dataField.id === item.id)
        this.loading = true
        const deleteResult = await this.$rambollfmapi.customizabledatafields.delete(item.id)
        if(deleteResult.name === 'Error') {
          this.showFailMessage(this.$t('Delete failed'))
          this.loading = false
        } else {
          this.additionalDataFields.splice(itemIndex, 1);
          this.dataFieldDialog = false
          this.loading = false
          this.showSuccessMessage(this.$t('Succesfull delete'))
        }
      }
    },
    openDataFieldDialog () {
      this.openAdditionalDataField =
      {
        enabled: true,
        name: null,
        customizableDataFieldValues:
        [
          {
            value: null
          }
        ]
      }
      this.dataFieldDialog = true
    },
    async saveChanges (items) {
      if( items.id ) {
        this.loading = true
        const index = this.additionalDataFields.findIndex(dataField => dataField.id === items.id)
        const updateResult = await this.$rambollfmapi.customizabledatafields.put(items.id, items)
        if(updateResult.name === 'Error') {
          this.showFailMessage(this.$t('Update failed'))
          this.loading = false
        } else {
          this.additionalDataFields.splice(index, 1, updateResult)
          this.loading = false
          this.showSuccessMessage(this.$t('Succesfull save'))
        } 
      } else {
        this.loading = true
        const newFieldCreationResult = await this.$rambollfmapi.customizabledatafields.post(items)
        if(newFieldCreationResult.name === 'Error') {
          this.showFailMessage(this.$t('Unsuccesfull save'))
          this.loading = false
        } else {
          this.additionalDataFields.push(newFieldCreationResult)
          this.dataFieldDialog = false
          this.loading = false
          this.showSuccessMessage(this.$t('Succesfull save'))
        }
      }
    },
    refresh (){
      this.loading = true
      this.getCustomizableDataFields()
      this.loading = false
    },
    cancelFieldModifying () {
      this.dataFieldDialog = false
      this.openAdditionalDataField = []
    },
    showFailMessage (msg) {
      this.indicatorMessage = msg
      this.showIndicator = true;
      this.operationResult = "error"
      this.yPosition = 'top'
    },
    showSuccessMessage (msg) {
      this.indicatorMessage = msg
      this.showIndicator = true
      this.operationResult = 'success'
      this.yPosition = 'bottom'
    },
    handleEdit () {
      if(this.edit) {
        this.refresh()
      }
      this.edit = !this.edit
    }
  }
}
</script>
<style scoped>
</style>
