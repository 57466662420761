import RambollFMAPI from '../api/rambollfm'
import { i18n } from '../plugins/i18n'
import constants from './constants'
import { SiteFilterController, filterTypes } from '../helpers/filters/SiteFilterController'

export default {
  namespaced: true,
  state: {
    buildings: [],
    cemeteries: [],
    structures: [],
    contracts: [],
    managementRegions: [],
    municipalities: [],
    userInfo: null,
    userGroups: [],
    userWidgets: [],
    portfolios: [],
    availableSites: [],
    availableSiteParties: [],
    allSitesParties: [],
    sites: [],
    sitesLoading: false,
    siteMetadata: {},
    siteFilters: {
      search: '',
      portfolios: [],
      sites: [],
      parties: [],
      partyFilters: {},
      site_status: [],
      site_classification: []
    },
    filterType: filterTypes()[0], // 'AND'
    parties: [],
    enterpriseParties: [],
    partiesLinks: [],
    requestItems: { sites: [], buildings: [], floors: [], spaces: [], units: [] },
    currentDate: new Date(),
    currentBuilding: null,
    apiEndpoint: null,
    apiToken: null,
    estates: [],
    tags: [],
    definitions: [],
    sortableWidgets: false,
    authorizationError: false, // if true, the authorization error dialog is shown
    favourites: [],
    classifications: constants.partyClassifications(),
    popupOpen: false,
    spaces: [],
    units: [],
    floors: [],
    storedViews: [],
    dwgsAvailable: false,
    customerLogo: null,
    customerLogoContentType: null,
    printingClass: null,
    reportMetadata: {
      unit: {},
      space: {}
    },
    contractMetadata: {
      contract: {},
      contractRow: {},
      usageFee: {}
    },
    contractData: {
      contract: [],
      contractRow: [],
      usageFee: []
    },
    photoMetadata: {},
    purposeZones: [],
    superAdmin: false,
    hasExternalOrgs: false,
    purposesOfUse: [],
    cleaningAreas: [],
    fireAreas: [],
    spaceMetaData: {},
    unitMetaData: {},
    dataTableHeaderProps: {
      sortByText: i18n.t('Sort by information')
    },    
    defaultFiltersLoaded: false,
    recursion: 0,
    recursionLimit: 10000000,
    siteLimitForFetchingAllSites: 5000000,
    siteLimitForFilters: 500,
    siteIds: { idRecursion: 0, sites: []},
    buildingIds: { idRecursion: 0, buildings: []},
    idRecursion: 0,
    buildingRecursionLoadingState: false,
    siteRecursionLoadingState: false,
    contractConditionsTemplates: [],
    settings: {},
    ariaLive: '',
    definitionsByIdMap: new Map(),
    alertUsers: [],
    currencies: [],
    countries: [],
  },
  getters: {
    definitionsByGroupLabel: (state) => (label) => {
      return state.definitions.filter(def => def.annotationGroup.label === label)
    },
    definitionsByGroupId: (state) => (id) => {
      return state.definitions.filter(def => def.annotationGroup.id === id)
    },
    definitionLabelById: (state) => (id) => {
      const definition = state.definitionsByIdMap.get(id)
      if (definition === undefined) {
        return ''
      }
      return definition.label
    },
    definitionById: (state) => (id) => {
      return state.definitionsByIdMap.get(id)
    },
    definitionByLabel: (state) => (label) => {
      return state.definitions.find(def => def.label === label)
    },
    siteFiltersByTypes: (state) => (types) => {
      if (!Array.isArray(types) && types !== undefined) {
        types = [types]
      }

      if (types.includes('all')) {
        types = Object.keys(state.siteFilters)
      }

      return Object.keys(state.siteFilters).filter(type => types.includes(type)).reduce((acc, type) => {
        if(type === "partyFilters"){
          Object.keys(state.siteFilters[type]).forEach(party => {
            acc.push(...state.siteFilters[type][party])
          })
        } else {
          acc.push(...state.siteFilters[type])
        }
        return acc
      }, [])
    },
    sitesPerPage: (state) => (page, itemAmount) => {
      return state.sites.slice(
        (page - 1) * itemAmount,
        page * itemAmount
      )
    },
    widgetPermissions: (state) => {
      return [...new Set([...state.userGroups.map(ug => ug.widgetPermissions).reduce((acc, cur) => [...acc, ...cur], [])])]
    },
    applicationPermissions: (state) => {
      return [...new Set([...state.userGroups.map(ug => ug.applicationPermissions).reduce((acc, cur) => [...acc, ...cur], [])])]
    },
    sitePermissions: (state) => {
      return [...new Set([...state.userGroups.map(ug => ug.sitePermissions).reduce((acc, cur) => [...acc, ...cur], [])])]
    },
    sortableWidgets: (state) => {
      return state.sortableWidgets
    },
    hasApplicationPermissionByName: (state) => (name) => {
      const appPermissions = state.userGroups.map(ug => ug.applicationPermissions).reduce((acc, cur) => [...acc, ...cur], [])
      const permission = appPermissions.find(p => p.name.toLowerCase() === name.toLowerCase())

      if (permission) {
        return true
      }
      return false
    },
    hasWidgetPermissionByName: (state) => (name) => {
      const widgetPermissions = state.userGroups.map(ug => ug.widgetPermissions).reduce((acc, cur) => [...acc, ...cur], [])
      const permission = widgetPermissions.find(p => p.name.toLowerCase() === name.toLowerCase())

      return permission ? true : false
    },
    favourites: (state) => {
      return state.favourites
    },
    classificationIdByName: (state) => (name) => {
      try {
        return state.classifications.find(c => c.name === name).id
      } catch {
        return -1
      }
    },
    classificationNameById: (state) => (id) => {
      try {
        return state.classifications.find(c => c.id === id).name
      } catch {
        return 'Undefined'
      }
    },
    classificationByName: (state) => (name) => {
      try {
        return state.classifications.find(c => c.name === name)
      } catch (error) {
        return []
      }
    },
    classificationById: (state) => (id) => {
      try {
        return state.classifications.find(c => c.id === id)
      } catch {
        return []
      }
    },
    siteFilterParams: (state) => {
      // At the moment api does not support multiple or options for other than id
      // We take the first value from list for all those unsupported parameters
      // we filter party, status and classification in frontend so we set them undefined here
      return {
        ids: state.siteFilters.sites.length === 0 ? undefined : state.siteFilters.sites.join(','),
        partyName: undefined,
        portfolio: state.siteFilters.portfolios.length === 0 ? undefined : state.siteFilters.portfolios[0],
        status: undefined,
        classification: undefined,
        time: state.currentDate.toISOString()
      }
    },
    serviceRequestWindowState: (state) => {
      return state.serviceRequestWindowState
    },
    storedViews: (state) => {
      return state.storedViews
    },
    dwgsAvailable: (state) => {
      return state.dwgsAvailable
    },
    customerLogo: (state) => {
      return state.customerLogo
    },
    customerLogoContentType: (state) => {
      return state.customerLogoContentType
    },
    siteName: (state) => (siteId) => {
      const found = state.sites.find(x => x.id_site === siteId)
      if (found === undefined) {
        return '-'
      }
      return found.name
    },
    getAvailablePurposesOfUse: state => {
      return state.purposesOfUse.filter(purpose => purpose.selectable)
    },
    spaceMetaData: (state) => {
      return state.spaceMetaData
    },
    unitMetaData: (state) => {
      return state.unitMetaData
    },
    dataTableHeaderDefinitions: (state) => {
      return state.dataTableHeaderProps
    },
    contractConditionsTemplates: (state) => {
      return state.contractConditionsTemplates
    },
    settings: (state) => {
      return state.settings
    },
    ariaLive: (state) => {
      return state.ariaLive
    },
    alertUsers: (state) => {
      return state.alertUsers
    },
    currencies: (state) => {
      return state.currencies
    },
    countries: (state) => {
      return state.countries
    },
    factaDisabled: (state) => {
      return !state.userGroups.some(group => 
        group.applicationPermissions.some(app => app.id === '9011')
      )
    },
  },
  mutations: {
    /**
     * setBuildings mutates state to contain unique list fo buildings
     * This method will only add and remove buildings as needed
     *
     * @param state
     * @param buildings
     * @param cemeteries //102 129
     * @param sites
     * @param estates
     * @returns {undefined}
     */
    setBuildings (state, buildings) {
      // remove
      state.buildings = state.buildings.filter(b => buildings.map(b => b.building_code).indexOf(b.building_code) >= 0)

      // add
      const toAdd = buildings.filter(b => state.buildings.map(b => b.building_code).indexOf(b.building_code) < 0)

      state.buildings = [...state.buildings, ...toAdd]
    },
    pushBuildings (state, buildings) {
      state.buildingIds.buildings.push(...buildings)
    },
    setCemeteries (state, cemeteries) {
      // remove
      state.cemeteries = state.cemeteries.filter(g => cemeteries.map(g => g.id).indexOf(g.id) >= 0)

      // add
      const toAdd = cemeteries.filter(g => state.cemeteries.map(g => g.id).indexOf(g.id) < 0)

      state.cemeteries = [...state.cemeteries, ...toAdd]
    },
    pushCemeteries (state, cemetery) {
      state.cemeteries.push(...cemetery)
    },
    clearCemeteries (state) {
      state.cemeteries = []
    },
    setStructures (state, structures) {
      state.structures = structures
    },
    setMunicipalities (state, municipalities) {
      state.municipalities = municipalities
    },
    setManagementRegions (state, managementRegions) {
      state.managementRegions = managementRegions
    },
    setContracts (state, contracts) {
      state.contracts = contracts
    },
    setUserInfo (state, { userInfo, userGroups, userWidgets, superAdmin }) {
      state.userInfo = userInfo
      state.userGroups = userGroups
      state.userWidgets = [...userWidgets, { name: 'dashboard.map', id: -1, page: -1, type: -1 }]
      state.superAdmin = superAdmin.data !== undefined ? superAdmin.data : superAdmin
    },
    setExternalOrgStatus (state, { hasExternalOrgs }) {
      state.hasExternalOrgs = hasExternalOrgs
    },
    setPortfolios (state, portfolios) {
      state.portfolios = portfolios
    },
    setSites (state, { sites, loading }) {

      const { siteFilters, allSitesParties, filterType } = state
      // if partyfilters applied, filter sites with selected parties
      const siteFilterController = new SiteFilterController(sites, siteFilters, { allSitesParties, skipFilters: ['portfolios'], filterType })

      state.sites = siteFilterController.filter()
      state.sitesLoading = loading
    },
    filterSites (state) {
      state.sitesLoading = true

      const { siteFilters, allSitesParties, availableSites, filterType } = state

      let sites = [...availableSites]
      // if partyfilters applied, filter sites with selected parties
      const siteFilterController = new SiteFilterController(sites, siteFilters, { allSitesParties, filterType })
      sites = siteFilterController.filter()

      state.sites = sites
      state.idRecursion += 1
      state.siteIds.idRecursion = state.idRecursion
      state.siteIds.sites = sites.map(site => site.id_site )
      state.sitesLoading = false
    },
    // get sites with filtered ids
    getFilteredSites (state) {
      state.sitesLoading = true

      const { siteFilters, allSitesParties, filterValues, filterType } = state

      let sites = [...filterValues]

      const siteFilterController = new SiteFilterController(sites, siteFilters, { allSitesParties, filterType })
      sites = siteFilterController.filter()

      state.sites = sites
      state.idRecursion += 1
      state.siteIds.idRecursion = state.idRecursion
      state.siteIds.sites = sites.map(site => site.id_site )
      state.sitesLoading = false
    },
    setEstates (state, estates) {
      state.estates = estates
    },
    setParties (state, parties) {
      state.parties = parties
    },
    setEnterpriseParties (state, enterpriseParties) {
      state.enterpriseParties = enterpriseParties
    },
    setPartiesLinks (state, partiesLinks) {
      state.partiesLinks = partiesLinks
    },
    setCurrentDate (state, date) {
      state.currentDate = new Date(date)
    },
    setCurrentBuilding (state, name) {
      state.currentBuilding = name
    },
    setDefinitions (state, definitions) {
      state.definitions = definitions
      state.definitionsByIdMap = new Map(definitions.map(def => [def.id, def]));
    },
    setTags (state, tags) {
      state.tags = tags
    },
    setApiEndpoint (state, endpoint) {
      state.apiEndpoint = endpoint
    },
    setApiToken (state, token) {
      state.apiToken = token
    },
    setRequestItems (state, params) {
      if (state.requestItems[params.level] !== undefined) {
        state.requestItems[params.level] = JSON.parse(JSON.stringify(params.items))
      }
    },
    setSortableWidgets (state, boolean) {
      state.sortableWidgets = boolean
    },
    setAuthorizationError (state, hasError) {
      state.authorizationError = hasError === true
    },
    setFavourites (state, params) {
      state.favourites = params
    },
    clearAuthorizationErrors (state) {
      state.authorizationError = false
    },
    setSiteFilters (state, { type, value }) {
      state.siteFilters[type] =  type === "partyFilters" ? value : [...value]
    },
    setMassSiteFilters (state, updateObjects) {
      for (var obj of updateObjects)
      {
        state.siteFilters[obj.type] = obj.type === "partyFilters" ? obj.value : [...obj.value]
      }
    },
    clearSiteFilters (state) {
      Object.keys(state.siteFilters).forEach(filter => {
        state.siteFilters[filter] = []
      })
    },
    setAvailableSites (state, { sites, metadata }) {
      state.availableSites = sites
      state.siteMetadata = metadata
    },
    setSiteMetadata (state, metadata) {
      state.siteMetadata = metadata
    },
    setAvailableSiteIds (state, sites) {
      state.siteIds = sites
    },
    pushAvailableSiteIds (state, sites) {
      state.siteIds.sites.push(...sites)
    },
    setAvailableSitesParties (state, { parties }) {
      state.availableSiteParties = parties
    },
    setAllSitesParties (state, { parties }) {
      state.allSitesParties = parties
    },
    setPopupOpen (state, boolean) {
      state.popupOpen = boolean
    },
    setDwgsAvailable (state, boolean) {
      state.dwgsAvailable = boolean
    },
    setSpaces (state, {spaces, customizable, spaceCustomFields}) {
      // if organization has customizable fields, add data
      if(customizable.count > 0)
      {
        let customValues = []
        // put custom values to simple array
        customizable.items.forEach (e => customValues = [...customValues, ...e.customizableDataFieldValues])
        // add custom values to space items with key values custom_id
        spaceCustomFields.forEach(sc => {
          let custom = customValues.find(c => c.id === sc.idValue)
          if (custom !== null && custom !== undefined)
          {
            let value = custom.value
            let space = spaces.find(sp => sp.id === sc.idSpace)
            if (space !== null && space !== undefined)
            {
              space['custom_'+sc.idField] = value
            }
          }
        })
        // for headers
        state.customizable = customizable
      }
      state.spaces = spaces
    },
    setUnits (state, units) {
      state.units = units
    },
    setFloors (state, floors) {
      state.floors = floors
    },
    setStoredViews (state, storedViews) {
      state.storedViews = storedViews
    },
    setCustomerLogo (state, customerLogo) {
      state.customerLogo = customerLogo
    },
    setCustomerLogoContentType (state, customerLogoContentType) {
      state.customerLogoContentType = customerLogoContentType
    },
    setPrintingClass (state, printingClass) {
      state.printingClass = printingClass
    },
    setReportMetadata (state, metadata) {
      state.reportMetadata.unit = metadata.unit
      state.reportMetadata.space = metadata.space
      state.reportMetadata.customizable = metadata.customizable
    },
    setContractMetadata (state, metadata) {
      state.contractMetadata.contract = metadata.contract
      state.contractMetadata.contractRow = metadata.contractRow
      state.contractMetadata.usageFee = metadata.usageFee
    },
    setContractData (state, data) {
      state.contractData.contract = data
    },
    setContractRowData (state, data) {
      state.contractData.contractRow = data
    },
    setUsageFeeData (state, data) {
      state.contractData.usageFee = data
    },
    setPhotoMetadata (state, metadata) {
      state.photoMetadata = metadata
    },
    setPurposeZones (state, purposeZones) {
      state.purposeZones = purposeZones
    },
    setPurposes (state, purposes) {
      state.purposesOfUse = purposes
    },
    setCleaningAreas (state, cleaningAreas) {
      state.cleaningAreas = cleaningAreas
    },
    setFireAreas (state, fireAreas) {
      state.fireAreas = fireAreas
    },
    setspaceMetaData (state, metadata) {
      state.spaceMetaData = metadata
    },
    setUnitMetaData (state, metadata) {
      state.unitMetaData = metadata
    },
    setDefaultFiltersLoaded (state) {
      state.defaultFiltersLoaded = true
    },
    setRecursion (state, recursion) {
      state.recursion = recursion
    },
    resetRecursion (state) {
      state.recursion = 0
    },
    newRecursionId (state) {
      state.idRecursion++
    },
    newBuildingRecursion (state) {
      state.buildingIds = { idRecursion: state.idRecursion, buildings: []}
    },
    setBuildingRecursionLoadingState (state, loading) {
      state.buildingRecursionLoadingState = loading
    },
    setSiteRecursionLoadingState (state, loading) {
      state.siteRecursionLoadingState = loading
    },
    setFilterValues (state, filterValues) {
      state.filterValues = filterValues
    },
    setContractConditionsTemplates (state, conditionsTemplates) {
      state.contractConditionsTemplates = conditionsTemplates
    },
    setFilterType (state, filterType) {
      state.filterType = filterType
    },
    setSettings (state, settings) {
      state.settings = settings
    },
    setAriaLive (state, message) {
      state.ariaLive = message
    },
    setAlertUsers (state, alertUsers) {
      state.alertUsers = alertUsers
    },
    setCurrencies (state, currencies) {
      state.currencies = currencies
    },
    setCountries (state, countries) {
      state.countries = countries
    },
  },
  actions: {
    async getBuildings ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildings = await api.buildings.filteredList(params)
      commit('setBuildings', buildings)
    },
    async getRecursiveBuildings ({ commit, state }) {
      commit('newBuildingRecursion')
      commit('setBuildingRecursionLoadingState', true)
      for (var i = 0; i < state.siteIds.sites.length; i += state.recursionLimit) {
        commit('setBuildingRecursionLoadingState', true)
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        var siteIds = state.siteIds.sites.slice(i, (state.siteIds.sites.length > (i + state.recursionLimit)) ? (i + state.recursionLimit) : (state.siteIds.sites.length))
        const params = { query: { siteids: siteIds } }
        const buildings = await api.buildings.listBySites(params)
        if (state.buildingIds.idRecursion === state.idRecursion) {
          commit('pushBuildings', buildings)
        } else {
          break
        }
      }
      setTimeout(() => commit('setBuildingRecursionLoadingState', false))
    },
    async getCemeteries ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const cemeteries = await api.graveyards.cemeteries.get(params)
      commit('setCemeteries', cemeteries)
    },
    async getContracts ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const contracts = await api.rent.contracts().list(params)
      commit('setContracts', contracts)
    },
    async getDefinitions ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const definitions = await api.definitions.list(params)
      commit('setDefinitions', definitions)
    },
    async getUserInfo ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const [
        userInfo,
        userGroups,
        userWidgets,
        superAdmin,
      ] = await Promise.all([
        api.accounts.userinfo(),
        api.accounts.currentgroups(),
        api.accounts.widgets.get(),
        api.accounts.superAdmin(),
      ])
      commit('setUserInfo', { userInfo, userGroups, userWidgets, superAdmin })
    },
    async checkExternalOrgs ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.userOrganizations.hasExternals()
      commit('setExternalOrgStatus', { result })
    },
    async getPortfolios ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const portfolios = await api.definitions.list('site.portfolio')
      commit('setPortfolios', portfolios)
    },
    async getRecursiveSites ({commit, state}) {
      commit('setRecursion', 0)
      commit('newRecursionId')
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = await api.sites.metadata()
      commit('setSiteMetadata', metadata)
      const idRecursion = state.idRecursion
      var recursion = state.recursion
      commit('setAvailableSiteIds', { idRecursion: idRecursion, sites: []})

      while (recursion == state.recursion) {
        try {
          commit('setSiteRecursionLoadingState', true)
          const params = { query: { recursion: recursion, limit: state.recursionLimit}}
          const sites = await api.sites.ids(params)
          
          // If we started new recursion before api call finished
          if (idRecursion !== state.idRecursion) {
            break
          }

          commit('pushAvailableSiteIds', sites)
          params.query.recursion++
          recursion++
          if (sites.length == state.recursionLimit) {
            commit('setRecursion', recursion)
          } else {
            // Else just in case of infinite
            break
          }
        } catch (err) {
          break
        }
      }
      commit('setSiteRecursionLoadingState', false)
    },
    async initializeParties ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const parties = await api.reports.postSite('GetAllPartiesBySiteList', state.siteIds.sites)
      const sites = state.availableSites
      commit('setAvailableSitesParties', { parties })
      commit('setAllSitesParties', { parties })
      commit('setSites', { sites })
    },
    async getSites ({ commit, state, getters }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      commit('setSites', { sites: [], loading: true })
      commit('setAvailableSitesParties', { parties: [] })

      const sites = await api.sites.filteredList(params)

      const metadata = await api.sites.metadata()
      commit('setAvailableSites', { sites, metadata })
      const parties = await api.reports.postSite('GetAllPartiesBySiteList', state.siteIds.sites)
      commit('setAvailableSitesParties', { parties })
      commit('setSites', { sites, loading: false })
    },
    async getEstates ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const estates = await api.estates.list(params)
      commit('setEstates', estates)
    },
    async getStructures ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const structures = await api.structures.list(params)
      commit('setStructures', structures)
    },
    async getMunicipalities ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const municipalities = await api.municipalities.list()
      commit('setMunicipalities', municipalities)
    },
    async getManagementRegions ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const managementRegions = await api.municipalities.managementRegions()
      commit('setManagementRegions', managementRegions)
    },
    async getParties ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const parties = await api.parties.list({ query: { visibility_status: true } })
      commit('setParties', parties)
    },
    async getEnterpriseParties ( {commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const enterpriseParties = await api.parties.list({ query: { party_type: 0 } })
      commit('setEnterpriseParties', enterpriseParties)
    },
    async definitionByLabelLinks ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const parties = await api.definitions.partiesLinks()
      commit('setPartiesLinks', parties)
    },
    async setCurrentDate ({ commit, dispatch, state }, newDate) {
      // Current date is changed. This will affect data shown by the UI
      // This action is here just because if any http requests need to
      // be done. For example: update all contracts

      commit('setCurrentDate', newDate)
      dispatch('getRecursiveSites')

      dispatch('getCemeteries')
    },
    async setCurrentBuilding ({ commit, state }, newBuilding) {
      commit('setCurrentBuilding', newBuilding)
    },
    async getTags ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const tags = await api.tags.list()
      commit('setTags', tags)
    },
    async setRequestItems ({ commit, state }, params) {
      commit('setRequestItems', params)
    },
    async getFavourites ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const fav = await api.accounts.favourites().get()
      commit('setFavourites', fav)
    },
    async getSpaces ({ commit, state }, time) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const spaces = await api.spaces.reports().getAll(time)
      const customizable = await api.customizabledatafields.get()
      //let spaceIds = spaces.map(a => a.id)
      const spaceCustomFields = await api.customizabledatafields.spacesByOrg()
      commit('setSpaces', {spaces, customizable, spaceCustomFields})
    },
    async getUnits ({ commit, state }, time) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const units = await api.units.reports().getAll(time)
      commit('setUnits', units)
    },
    async getFloors ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const floors = await api.floors.list(params)
      commit('setFloors', floors)
    },
    async getStoredViews ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const storedViews = await api.storedviews.list(params)
      commit('setStoredViews', storedViews)
    },
    async getCustomerLogo ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const customerLogo = await api.accounts.logo.get()
      if (customerLogo.status === 200) {
        commit('setCustomerLogo', customerLogo.data)
        commit('setCustomerLogoContentType', customerLogo.headers['content-type'])
      }
    },
    async getReportMetadata ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = {}
      metadata.unit = await api.units.reports().metadata()
      metadata.space = await api.spaces.reports().metadata()
      metadata.customizable = await api.customizabledatafields.get()
      commit('setReportMetadata', metadata)
    },
    async getContractMetadata ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = {}
      metadata.contract = await api.contracts.contract.metadata()
      metadata.contractRow = await api.contracts.contractRow.metadata()
      metadata.usageFee = await api.contracts.usageFee.metadata()
      commit('setContractMetadata', metadata)
    },
    async getContractData ({commit, state}, time) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const data = await api.contracts.contract.contractreports.get(time)
      commit('setContractData', data)
    },
    async getContractRowData ({commit, state}, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const data = await api.contracts.contractRow.reports.get(params)
      commit('setContractRowData', data.items)
    },
    async getUsageFeeData ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const data = await api.contracts.usageFee.get()
      commit('setUsageFeeData', data)
    },
    async getPhotoMetadata ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = await api.photos.metadata()
      commit('setPhotoMetadata', metadata)
    },
    async getPurposeZones ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const purposeZones = await api.definitions.purposezones()
      commit('setPurposeZones', purposeZones)
    },
    async getPurposes ({ commit, state }, all) {
      const allpurposes = all !== undefined ? all : true
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const purposes = await api.internalrent.purposesofuse().list(allpurposes)
      commit('setPurposes', purposes)
    },
    async getCleaningAreas ( { commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const cleaningAreas = await api.definitions.cleaningarea()
      commit('setCleaningAreas', cleaningAreas)
    },
    async getFireAreas ( { commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const fireAreas = await api.definitions.firearea().all()
      commit('setFireAreas', fireAreas)
    },
    async getspaceMetaData ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = await api.spaces.metadata()
      commit('setspaceMetaData', metadata)
    },
    async getUnitMetaData ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = await api.units.metadata()
      commit('setUnitMetaData', metadata)
    },
    // get site list with reduced info for filtering with large number of sites
    async getFilterValues ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const filterValues = await api.sites.filterValues()
      commit('setFilterValues', filterValues)
    },
    async getSiteMetadata ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const metadata = await api.sites.metadata()
      commit('setSiteMetadata', metadata)
    },
    async getContractConditionsTemplates ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const conditionsTemplates = await api.contracts.conditionsTemplates.get()
      commit('setContractConditionsTemplates', conditionsTemplates)
    },
    async getSettings ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const settings = await api.settings.get()
      commit('setSettings', settings)
    },
    async getAlertUsers ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const alertUsers = await api.alerts.getAlertUsers()
      commit('setAlertUsers', alertUsers)
    },
    addAriaLive ({ commit }, message) {
      commit('setAriaLive', '')
      setTimeout(() => commit('setAriaLive', message))
    },
    async getCurrencies ({ commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currencies = await api.settings.currencies().get()
      commit('setCurrencies', currencies)
    },
    async getCountries ({ commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.municipalities.countries()
      const countries = result.map(c => {
        return {
          id: c.idMaa,
          name: i18n.t(c.maaVal),
          code: c.koodi,
        }
      })
      commit('setCountries', countries)
    },
  }
}
