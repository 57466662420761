<template>
  <div
    class="slider"
  >
    <div class="text-body-2 grey--text lighten-2">
      {{ text }}
    </div>
    <v-range-slider
      v-model="slider"
      :min="metadata.min"
      :max="metadata.max"
      :step="metadata.step"
      color="accent"
      dense
      hide-details
      @change="dataChanged"
    />
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    values: { type: Array, default: () => [0, 1]},
    metadata: { type: Object, default: () => {} }
  },
  emits: ['change'],
  data () {
    return {
      slider: [0, 1]
    }
  },
  computed: {
    text () {
      let text = `${this.$t(this.metadata.name)} ${this.slider[0]}-${this.slider[1]}`
      if (this.metadata.overflow && this.slider[1] === this.metadata.max) {
        text += '+'
      }
      if (this.metadata.suffix) {
        text += ` ${this.metadata.suffix}`
      }
      return text
    },
  },
  watch: {
     values: function (newValues) {
      this.slider = [newValues[0], newValues[1]]
    }
  },
  mounted () {
    this.slider = [this.values[0], this.values[1]]
  },
  methods: {
    dataChanged () {
      this.$emit('change', this.slider)
    }
  }
}
</script>

<style scoped>
.slider {
  width: 100%;
}
</style>