<template>
  <div>
    <SkipTo
      :label="$t('skip-to-settings-content')"
      to="#settings-content"
    />
    <v-tabs 
      class="pl-5"
      show-arrows
    >
      <v-tab
        :to="{name: 'settings.parties'}"
        :disabled="!hasApplicationPermissionByName('OSAPUOLIREKISTERI')"
      >
        {{ $t('Parties') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('SIS_TILASALKUT')"
        :to="{name: 'settings.statusportfolios'}"
      >
        {{ $t('Status portfolios') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('SIS_KAYTTOTARKOITUKSET')" 
        :to="{name: 'settings.purposesofuse'}"
      >
        {{ $t('Purposes of use') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')"
        :to="{name: 'settings.additionaldatafields'}"
      >
        {{ $t('Customizable additional data fields') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('SIS_KUSTANNUSPAIKAT')"
        :to="{name: 'settings.costcenters'}"
      >
        {{ $t('Cost centers') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('SIS_VUOKRALASKENTA')"
        :to="{name: 'settings.costtypes'}"
      >
        {{ $t('Cost types') }}
      </v-tab>
      <v-tab
        :to="{name: 'settings.servicerequests'}"
        :disabled="!hasApplicationPermissionByName('RAMBOLL_TILAUS') && !hasApplicationPermissionByName('ZOAN_TILAUS')"
      >
        {{ $t('Service requests') }}
      </v-tab>
      <v-tab
        v-if="hasApplicationPermissionByName('SALKKUHALLINTA')"
        :to="{name: 'settings.portfoliomanagement'}"
      >
        {{ $t('Portfolio management') }}
      </v-tab>
      <v-tab
        :to="{name: 'settings.users'}"
        :disabled="!hasApplicationPermissionByName('KAYTTAJAHALLINTA')"
      >
        {{ $t('Users') }}
      </v-tab>
      <v-tab
        :to="{name: 'settings.usergroups'}"
        :disabled="!hasApplicationPermissionByName('KAYTTAJAHALLINTA')"
      >
        {{ $t('User groups') }}
      </v-tab>
    </v-tabs>
    <v-divider />
    <div id="settings-content">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SkipTo from '../components/general/SkipTo.vue';
export default {
  name: 'Settings',
  components: { SkipTo },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName'
    ])
  },
  watch: {},
  beforeMount () {},
  methods: {}
}
</script>
<style scoped>
.menu {
  text-transform: none;
}
.menu .v-btn__content {
  min-width: 200px !important;
}
</style>
