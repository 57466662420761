import { render, staticRenderFns } from "./PhotoChoosesForm.vue?vue&type=template&id=2004b720&scoped=true"
import script from "./PhotoChoosesForm.vue?vue&type=script&lang=js"
export * from "./PhotoChoosesForm.vue?vue&type=script&lang=js"
import style0 from "./PhotoChoosesForm.vue?vue&type=style&index=0&id=2004b720&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2004b720",
  null
  
)

export default component.exports