<template>
  <v-tab-item
    :key="1"
  >
    <v-form
      ref="form1"
      class="mt-6"
    >
      <div
        v-for="question in questions"
        :key="question.id"
      >
        <v-select
          v-model="question.selectedAnswerId"
          :label="$t(question.id + '. ' + question.question_text)"
          :items="question.answers"
          item-text="answer_text"
          item-value="id"
          class="mb-6"
          clearable
          :disabled="savingDisabled"
          @change="onDataChanged()"
        />
      </div>
    </v-form>
  </v-tab-item>
</template>

<script>

export default {
  name: "QuestionsTab",
  components: {
    
  },
  props: {
    savingDisabled: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: undefined
    },
    buildingRating: {
      type: Object,
      default: () => undefined
    },
  },
  emits: ['updated'],
  data () {
    return {
      list: this.questions,
    }
  },
  methods: {
   async onDataChanged () {
      const newQuestions = this.questions.map(q => ({
        ...q,
        selectedAnswer: {
          id: q.selectedAnswer?.id,
          id_buildingrating: this.buildingRating.id,
          id_answer: q.answers.find(a => a.id === q.selectedAnswerId)?.id,
          id_question: q.id,
        }
      }))
      this.$emit('updated', newQuestions)
    }
  }
}
</script>

<style scoped>
</style>