export default function CarparkChangesIgnoredWidget () {
    return {
      id: "dataimport.changes_ignored",
      type: "ObjectTable",
      data: {
        headers: [
          { text: "Carpark code long", value: "carparkCodeLong" },
          { text: "Description", value: "value" },
        ],
        items: [],
      },
      maximized: [false],
      loading: false,
      dataFetcher: (context) => {
        return context.errors
      },
      shouldShow: (context) => {
        return context.errors && context.errors.length
      },
    }
  }
  