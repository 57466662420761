export default {
  size (size) {
    size = Number(size)
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  },
  date (str, type = 'datetime') {
    // Check for date
    const iso8601regex = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i
    if (iso8601regex.test(str)) {
      const date = new Date(str)
      if (type === 'datetime') {
        return date.toLocaleString('fi-FI', {
          timeZone: 'Europe/Helsinki',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      } else if (type === 'time') {
        return date.toLocaleString('fi-FI', {
          timeZone: 'Europe/Helsinki',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        })
      } else if (type === 'year') {
        return date.toLocaleString('fi-FI', { year: 'numeric'})
      } else {
        return date.toLocaleString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' })
      }
    }
    return '-'
  },
  amount_short (amount) {
    amount = Number(amount)

    if (amount === 0 || amount === Infinity) {
      return Number(0).toFixed(2)
    }
    const i = Math.floor(Math.log(amount) / Math.log(1000))
    if (i < 0) {
      return amount
    }
    return (amount / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['', 'k', 'M', 'Q'][i]
  },
  amount_long (amount, decimalAmount = 2, thousandSeparator = ' ') {
    if (isNaN(amount)) {
      return '-';
    }
    const isNegative = amount.toString()[0] === '-'

    const num = isNegative ? Number(amount.toString().slice(1)) : Number(amount)

    const [integerPart, decimalPart] = num.toFixed(decimalAmount).split('.')
  
    // Required output:
    // '10000' -> '000' '10'
    // How this approach works
    // '12345' -> '54321' -> '543' '21' -> '21', '543' -> '12', '345'
    const pieces = integerPart
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .reverse()
      .map((p) => p.split('').reverse().join(''))
  
    let amountString = pieces.join(thousandSeparator)
  
    if (isNegative) {
      amountString = '-' + amountString
    }
  
    if (decimalPart) {
      amountString = amountString + ',' + decimalPart
    }
  
    return amountString
  },
  thousand_separator (amount, decimalAmount = 0, thousandSeparator = ' ') {
    amount = Number(amount)
    
    const integerPart = Math.trunc(amount).toFixed(0)
    const decimalPart = amount.toFixed(decimalAmount).split('.')[1]

    const pieces = integerPart.split('').reverse().join('').match(/.{1,3}/g).reverse().map(p => p.split('').reverse().join(''))

    if (decimalPart) {
      return pieces.join(thousandSeparator) + ',' + decimalPart
    } else {
      return pieces.join(thousandSeparator)
    }
  },

  /**
   * Formats the given area value.
   *
   * If the area is not a number, it returns a dash ('-').
   * Otherwise, it rounds the area to the nearest 0.5 and formats it using the `amount_long` method.
   *
   * @param {number} area - The area value to format.
   * @returns {string} The formatted area value.
   */
  area (area) {
    if (isNaN(area)) {
      return '-';
    }
    return this.amount_long((Math.round(area * 2) / 2).toFixed(1))
  },

  /**
   * Formats a given amount as a currency string.
   *
   * @param {number|string} amount - The amount to be formatted. Can be a number or a string that represents a number.
   * @returns {string} - The formatted currency string. If the input is not a valid number, returns '-'.
   */
  currency (amount) {
    if (isNaN(amount)) {
      return '-';
    }
    return this.amount_long(Number(amount).toFixed(2))
  },
}
