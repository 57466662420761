<template>
  <v-row
    v-if="!browse"
    align-center
    justify-center
  >
    <v-tooltip
      right
      color="black"
      :disabled="header.noDescription"
    >
      <template #activator="{on, attrs}">
        <v-icon 
          v-if="status !== null"
          x-large 
          :color="status.color"
          v-bind="attrs"
          v-on="on"
        >
          {{ status.icon }}
        </v-icon>
      </template>
      <span>{{ status.description }}</span>
    </v-tooltip>
  </v-row>
  <v-row
    v-else
    align-center
  >
    <v-col
      xs9
      class="linked px-0"
    >
      {{ formatData(props.item, header.value, header.format) }}
    </v-col>
    <v-col
      v-if="status !== null"
      xs3
      class="px-0 text-left"
    >
      <v-icon 
        :color="status.color"
      >
        {{ status.icon }}
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../../../helpers'

export default {
  name: 'UnitVersionStatusColumn',
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
    item: { type: Object, default: null },
    browse: { type: Boolean, default: false}
  },
  computed: {
    status: function () {
      const { value } = this.header
      const item = this.item

      const col = item[value]

      switch (col) {
        case 'unit active and one row':
          return { icon: 'check_circle', color: 'success', description: this.$i18n.t('unitStatus.active_and_one_row') }
        case 'unit with end date':
          return { icon : 'error', color : '#FFC0CB', description : this.$i18n.t('unitStatus.unit_end_date_description') }
        case 'unit with multiple rows':
          return { icon: 'error', color: 'yellow', description: this.$i18n.t('unitStatus.multiple_units_description') }
        case 'unit starting in future':
          return { icon: 'error', color: 'error', description: this.$i18n.t('unitStatus.future_unit_start_date_description') }
        default:
          return { icon: 'error', color: 'grey', description: this.$i18n.t('Undefined') }
      }
    }
  },
  methods: {
    formatData (row, header, format) {
      return helpers.format.formatData(this.$i18n.t(row[header]), format)
    },
  },
}
</script>

<style scoped></style>
