import { i18n } from "../plugins/i18n"

export function RatesByUnitStatusAndTarget () {
  return {
    id: "dashboard.rates_by_unit_status_and_target",
    type: "DataTable",
    loading: true,
    data: {
      infoText: i18n.t("dashboard.rates_by_unit_status_and_target.info"),
      headers: [
        { text: i18n.t("Unit Status"), value: "unitStatus" },
        { text: i18n.t("Target goal"), value: "targetGoal" },
        {
          text: i18n.t("Facility base"),
          value: "netFloorArea",
          format: "Area",
        },
        {
          text: i18n.t("Facility base KTI"),
          value: "facilityBaseArea",
          format: "Area",
        },
        {
          text: i18n.t("leasing.rented_room_area"),
          value: "rentedArea",
          format: "Area",
        },
        { text: i18n.t("Empty Units"), value: "emptyArea", format: "Area" },
        { text: i18n.t("Vacant units"), value: "freeArea", format: "Area" },
        {
          text: i18n.t("Utilization rate"),
          value: "utilizationRate",
          format: "Percentage",
        },
        {
          text: i18n.t("Vacancy rate"),
          value: "vacancyRate",
          format: "Percentage",
        },
      ],
      footers: [
        { text: i18n.t("Total") },
        {},
        { value: "netFloorArea", unit: "area", format: "Area" },
        { value: "facilityBaseArea", unit: "area", format: "Area" },
        { value: "rentedArea", unit: "area", format: "Area" },
        { value: "emptyArea", unit: "area", format: "Area" },
        { value: "freeArea", unit: "area", format: "Area" },
        {
          value: "utilizationRate",
          numeratorSum: "rentedArea",
          denominatorSum: "netFloorArea",
          unit: "percentage",
          format: "Percentage",
        },
        {
          value: "vacancyRate",
          numeratorSum: "freeArea",
          denominatorSum: "facilityBaseArea",
          unit: "percentage",
          format: "Percentage",
        },
      ],
      items: [],
      sortBy: "unitStatus",
      sortDesc: false,
    },
  }
}
