<template>
  <div>
    <nav :aria-label="$t('SubMenu')">
      <v-col
        class="d-flex mb-4"
        cols="3"
      >
        <h1
          id="site-content"
          class="d-sr-only"
        >
          {{ $t('Facility management') }}
        </h1>
        <v-combobox
          v-model="selectedBuilding"
          :items="buildingsData"
          :label="$t('Pick a building')"
          item-text="building_name"
          item-value="building_name"
          prepend-icon="home"
        />
        <v-combobox
          v-if="showV3DevEnvironmentChanger"
          v-model="selectedEnvironment"
          :items="['Azure', 'GCP']"
          :label="$t('selectEnvironment')"
          prepend-icon="language"
        />
        <InfoBall
          v-if="showV3DevEnvironmentChanger"
          :info="$t('LocalV3DevInfo')"
          right
        />
      </v-col>
    </nav>
    <RambollFMViewer
      v-if="selectedBuilding"
      :session-token="v3ApiToken"
      :building-code="selectedBuilding.building_code"
      :building-data="selectedBuilding"
      :api-endpoint="endpoint"
      :current-date="currentDate"
      :rental-status="rentalStatusesByBuilding"
      :readonly="!hasWritePermission"
      :floor-from-parent="selectedFloor"
      :estate-dwg-only="estateDwgOnly"
      :use-v-t-h="useVTH"
      :use-v3="useV3"
    />
  </div>
</template>
<script>
import RambollFMViewer from '../modules/v3viewer'
import VueCookies from 'vue-cookies'
import InfoBall from '../components/InfoBall.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FacilityManagement',
  components: {
    RambollFMViewer,
    InfoBall
  },
  metaInfo () {
    return {
      title: `${this.$t('Facility management')} ·`,
    }
  },
  data () {
    return {
      endpoint: this.$rambollfmapi.endpoint,
      buildingSearchText: '',
      buildingsData: [],
      selectedBuilding: null,
      rentalStatuses: [],
      estateDwgOnly: false,
      selectedFloor: null,
      useVTH: false,
      useV3: false,
      selectedEnvironment: null,
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    hasWritePermission () {
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
    v3ApiToken () {
      return this.$cookies.get('v3ViewerToken')
    },
    currentDate () {
      return this.$store.state.app.currentDate
    },
    rentalStatusesByBuilding () {
      return this.rentalStatuses.filter(
        status => status.buildingCode !== this.selectedBuilding.buildingCode
      )
    },
    showV3DevEnvironmentChanger () {
      return window.location.hostname.includes('localhost')
    }
  },
  watch: {
    selectedBuilding: function (newBuilding) {
      this.$store.commit('app/setRequestItems', {
        level: 'buildings',
        items: [newBuilding.building_code]
      })
    },
    selectedEnvironment: async function (newEnv, oldEnv) {
      if(oldEnv != null)
      {
        await this.$rambollfmapi.accounts.vektorio.localenvironment.post(newEnv)
        await this.deleteVektorioToken()
        await this.getVektorioToken()
        location.reload();
      }
    }
  },
  async mounted () {
    if (this.hasApplicationPermissionByName('KOHDE_POHJAKUVAT')) {
      if (!VueCookies.isKey('v3ViewerToken')) {
        // get v3ViewerToken only if it has not been fetched before
        await this.getVektorioToken()
      }
      if(window.location.hostname.includes('localhost'))
      {
        this.selectedEnvironment = await this.$rambollfmapi.accounts.vektorio.localenvironment.get()
      }
      const idSite = this.$route.params.id_site
      const idUnit = this.$route.query.suite_id
      this.fetchRentalStatuses(idSite)
      const buildData = []
      const buildings = await this.$rambollfmapi.buildings.list({ query: { siteId: idSite } })
      for (const bd of buildings.filter(b => b.name !== null)) {
        const floors = await this.$rambollfmapi.buildings.floors(bd.building_code).list()
        floors.forEach(f => {
          if (f.has_dwg || f.has_xml) {
            buildData.push(bd)
            if (this.selectedBuilding === null) {
              this.selectedBuilding = bd
            }
          }
          // setting viewer choice
          if (f.has_dwg) {
            this.useVTH = false
            this.useV3 = true
          }
          if (f.has_xml) {
            this.useVTH = true
            this.useV3 = false
          }
        })
      }
      // if floors did not have dwg, then we check if estates have
      if (this.selectedBuilding === null) {
        for (const bd of buildings.filter(b => b.name !== null)) {
          const estate = await this.$rambollfmapi.estates.get(bd.id_estate, this.currentDate)
          if(estate.has_dwg) {
            // setting viewer choice
            this.useV3 = true
            this.useVTH = false
            buildData.push(bd)
            if (this.selectedBuilding === null) {
              this.selectedBuilding = bd
              this.estateDwgOnly = true
            }
          }
        }
      }
      // remove duplicates
      this.buildingsData = [...new Set(buildData)]

      // get floors
      if(idUnit !== undefined)
      {
        const siteFloors = await this.$rambollfmapi.floors.list({ query: { site_id: idSite } })
        let floorUnits = []
        outer: for(let [i, floor] of siteFloors.entries())
        {
          floorUnits[i]= await this.$rambollfmapi.floors.units(floor.id).list()
          for(let [,unit] of floorUnits[i].entries())
          {
            if(unit.id===idUnit)
            {
              this.selectedFloor = floor
              this.selectedUnit = unit
              break outer
            }
          }
        }
      let foundBuilding = this.buildingsData.find(element => element.building_code === this.selectedFloor.building_code)
      if (foundBuilding !== undefined)
        this.selectedBuilding = foundBuilding
      }
    }  
  },
  methods: {
    async fetchRentalStatuses (idSite) {
       const result = await this.$rambollfmapi.reports.post('rentalstatus', [idSite])
       this.rentalStatuses = result
    },
    async getVektorioToken () {
      try {
        const v3ViewerToken = await this.$rambollfmapi.accounts.vektorio.session.get()
        if (v3ViewerToken.name && v3ViewerToken.name === 'Error') {
          throw new Error(v3ViewerToken)
        }
        VueCookies.set('v3ViewerToken', v3ViewerToken, '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      } catch (error) {
        this.$log.error(error)
        if (VueCookies.isKey('v3ViewerToken')) {
          VueCookies.remove('v3ViewerToken')
        }
      }
    },
    async deleteVektorioToken () {
      try {
        if (VueCookies.isKey('v3ViewerToken')) {
          const v3ViewerToken = VueCookies.get('v3ViewerToken')
          VueCookies.remove('v3ViewerToken')
          if (v3ViewerToken) {
            await this.$rambollfmapi.accounts.vektorio.session.delete(v3ViewerToken)
          }
        }
      } catch (error) {
        this.$log.error(error)
      }
    }
  }
}
</script>

<style scoped>
.headline {
  text-align: center;
  margin-top: 2em;
}
</style>
