// Used for calculating widget data for widget leasing.my_rental_goals.
// At the moment of writing this, this widget is in one view. components/tabs/Leasing/OwnActive
import moment from 'moment'
//Used for calculating widget data for widget leasing.

// Returns true if contract info has a contract signed in the current year
function hasContractSignedThisYear (contract_info) {
    const contractSigningDate = contract_info
        .map(contract => contract.ContractSigningDate)
        .filter(date => date !== null)
    return contractSigningDate.some(date => moment(date).year() === moment().year())
}

export function loadRentalGoals (
    processes,
    responsibleSites,
    goals,
  ) {

    // If there are no responsible sites, return an empty array
    if(responsibleSites.length < 1) {
        return [];
    }

    // Filter the processes to include only those that have a site in the responsibleSites array and are not 'Inactive'
    const filteredProcesses = processes.filter(process => 
        process.sites.some(site => responsibleSites.includes(site.id_site)) && process.status !== 'Inactive'
    );

    // Initialize an object to hold arrays of processes for each stage
    const stages = {
        '2. Prospect': [],
        '3. NeedsAssessment': [],
        '4. Proposal': [],
        '5. ContractNegotiation': [],
        '6. Won': [],
        '7. RentAdministration': [],
        '8. Closed': []
    };

    // Iterate over the filtered processes
    filteredProcesses.forEach(process => {
        // If the process's stage is not a key in the stages object, skip this iteration
        if (!stages.hasOwnProperty(process.stage)) {
            return
        }

        // If the process is in the '8. Closed' stage and does not have a contract signed in the current year, skip this iteration
        if (process.stage === '8. Closed' && !hasContractSignedThisYear(process.contract_info)) {
            return
        }

        // If the process passed all the checks, add it to the array for its stage in the stages object
        stages[process.stage].push(process)
    });

    // Calculate the sum of process area for each stage and store the results in an object
    const values = {
        prospect: sumProspectsArea(stages['2. Prospect']),
        needsAssessment: sumProspectsArea(stages['3. NeedsAssessment']),
        proposal: sumProspectsArea(stages['4. Proposal']),
        contractNegotiation: sumProspectsArea(stages['5. ContractNegotiation']),
        won: sumProspectsArea(stages['6. Won']),
        rentAdministration: sumProspectsArea(stages['7. RentAdministration']),
        closed: sumProspectsArea(stages['8. Closed']),
    }
    
    const totalTargets = {
        all: 0,
        new_negotiation: 0,
        all_renegotiations: 0,
        fixed_renegotiations: 0,
        permanent_renewals: 0
    }

    responsibleSites.forEach(site => {
        let goal = goals.get(site)
        if (goal) {
            totalTargets.all += (goal.new_negotiation + goal.renewal + goal.renegotiation)
            totalTargets.new_negotiation += goal.new_negotiation
            totalTargets.all_renegotiations += (goal.renewal + goal.renegotiation)
            totalTargets.fixed_renegotiations += goal.renegotiation
            totalTargets.permanent_renewals += goal.renewal
        }
    })

    let remaining = {}

    Object.keys(totalTargets).forEach(k => {
        remaining[k] = totalTargets[k] - values.rentAdministration[k] - values.closed[k] - values.won[k] - values.contractNegotiation[k] - values.proposal[k] - values.needsAssessment[k] - values.prospect[k]
    })

    values.remaining = {}
    //Remaining can't be less than 0
    Object.keys(remaining).forEach(k => {
        values.remaining[k] = remaining[k] < 0 ? 0 : remaining[k]
    })

    //This is just needed to show the current time color in the legend
    values.currentTime =
    {
        all: 0,
        new_negotiation: 0,
        all_renegotiations: 0,
        fixed_renegotiations: 0,
        permanent_renewals: 0
    }

    const pieObjects = [
        { key: 'remaining', text: 'leasing.still_missing' },
        { key: 'closed', text: '8. Closed' },
        { key: 'rentAdministration', text: '7. RentAdministration' },
        { key: 'won', text: '6. Won' },
        { key: 'contractNegotiation', text: '5. ContractNegotiation' },
        { key: 'proposal', text: '4. Proposal' },
        { key: 'needsAssessment', text: '3. NeedsAssessment' },
        { key: 'prospect', text: '2. Prospect' },
        { key: 'currentTime', text: 'leasing.now' }
    ]
    
    pieObjects.forEach(o => {
        o.nameAll = o.text
        o.nameNew = o.text
        o.nameAllReneg = o.text
        o.nameFixedReneg = o.text
        o.namePermanent = o.text

        Object.assign(o, values[o.key])
    });
    return pieObjects
  }

  function sumProspectsArea (prospects) 
  {
    let totalArea = {
        all: 0,
        new_negotiation: 0,
        all_renegotiations: 0,
        fixed_renegotiations: 0,
        permanent_renewals: 0,
    }
    prospects.forEach(p => {
        let partOfArea =
        {
            all: true,
            new_negotiation: false,
            all_renegotiations: false,
            fixed_renegotiations: false,
            permanent_renewals: false,
        }

        if (p.rental_contract_validity === 'toistaiseksi voimassaoleva') 
        {
            partOfArea.permanent_renewals = true
            partOfArea.all_renegotiations = true
        } 
        else if (p.rental_contract_validity === 'määräaikainen') 
        {
            partOfArea.fixed_renegotiations = true
            partOfArea.all_renegotiations = true
        } 
        else 
        {
            partOfArea.new_negotiation = true
        }

        Object.keys(totalArea).forEach(key => {
            if(partOfArea[key])
            {
                if(p.contract_info.count() > 0)
                {
                    totalArea[key] += p.contract_info.reduce((sum, contract) => sum + contract.contractArea, p.area_preference);
                }
                else if(p.area_preference !== null)
                {
                    totalArea[key] += p.area_preference
                }
            }  
        });

    });
    return totalArea
  }
  