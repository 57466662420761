<template>
  <div id="site-costs">
    <h1
      id="site-content"
      class="d-sr-only"
    >
      {{ $t('Budget and expense information') }}
    </h1>
    <BaseView>
      <template #selections>
        <v-select
          v-model="selectedYear"
          :label="$t('Year')"
          :items="availableYears"
        />
        <v-select
          v-model="selectedCostDomain"
          :label="$t('costs.cost_domain')"
          :items="availableCostDomains"
          class="ml-2"
        />
      </template>
      <template #main>    
        <div
          v-for="(widget) in widgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :style="{ 'border-color': draggableColor }"
            :maximized="widget.maximized"
          />
        </div>
      </template>
    </BaseView>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import helpers from '../helpers'
import DynamicWidget from '../components/DynamicWidget.vue'
import BaseView from '../components/general/BaseView.vue'

export default {
  name: 'BudgetAndExpenses',
  components: {
    DynamicWidget,
    BaseView,
  },
  metaInfo () {
    return {
      title: `${this.$t('Budget and expense information')} ·`,
    }
  },
  data () {
    return {
      selectedYear: null,
      selectedCostDomain: null,
      allCosts: [],
      draggableColor: null,
      widgets: [
        {
          id: 'site.costs_by_cost_domain',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site' },
              { text: this.$t('costs.cost_domain'), value: 'costDomain' },
              { text: this.$t('costs.cost_domain_text'), value: 'costDomainText' },
              { text: this.$t('costs.total'), value: 'total', format: 'Euro' },
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'total',  format: 'Euro' },
            ],
            items: [],
            sortDesc: true
          },
          maximized: [true]
        },
        {
          id: 'site.costs',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site' },
              { text: this.$t('Building'), value: 'buildingName'},
              { text: this.$t('costs.co_receipt_number'), value: 'coReceiptNumber' },
              { text: this.$t('costs.recording_date'), value: 'recordingDate', format: 'Date'},
              { text: this.$t('costs.reference_billing_number'), value: 'referenceBillingNumber' },
              { text: this.$t('costs.vendor'), value: 'vendor' },
              { text: this.$t('costs.purchase_order_number'), value: 'purchaseOrderNumber' },
              { text: this.$t('costs.cost_domain'), value: 'costDomain' },
              { text: this.$t('costs.cost_domain_text'), value: 'costDomainText' },
              { text: this.$t('costs.quantity'), value: 'quantity', format: 'Euro' },
              { text: this.$t('costs.label'), value: 'name' },
              { text: this.$t('costs.vat_code'), value: 'vatCode' },
              { text: this.$t('costs.vat_code_text'), value: 'vatCodeText' },
              { text: this.$t('costs.external_link'), value: 'externalLink', format: 'externalLink' },
            ],
            customFormatters: {
              quantity: (items) => {
                var cur =  ""
                switch (items.currency) {
                  case 'EUR':
                      cur = '€'
                    break;
                  case 'SEK':
                      cur = 'kr'
                    break;
                  case 'USD':
                      cur = '$'
                    break;
                  default:
                    break;
                }    
                return helpers.humanize.thousand_separator(Number(items.quantity), 2) + ' ' + cur
              },
            },
            items: [],
            sortDesc: true,
            sortBy: 'recordingDate',
            hideTotalRow: true,
          },
          maximized: [true]
        },
      ],
    }
  },
  computed: {
    ...mapState('app', [ 'userWidgets', 'userInfo', 'sites', 'currentDate', '']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),    
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite']),
    availableYears () {
      const years = this.allCosts.map(c => (new Date(c.recordingDate)).getFullYear())
      return [...new Set(years)]
    },
    availableCostDomains () {
      const costDomains = this.allCosts.map(c => c.costDomain)
      const costDomainItems = [...new Set(costDomains)]
        .map(costDomain => ({
          text: costDomain.replace(/^0+/g, '') + ' ' + this.allCosts.find(c => c.costDomain === costDomain)?.costDomainText,
          value: costDomain,
        })).sort((a, b) => a.value - b.value)
      return [
        {
          text: this.$t('Kaikki'),
          value: null,
        },
        ...costDomainItems,
      ]
    },
  },
  watch: {
    // Watch for changes and update widgets
    selectedYear: function () {
      this.populateWidgets()
    },
    selectedCostDomain: function () {
      this.populateWidgets()
    },
    currentSite: function () {
      this.populateWidgets()
    },
    currentDate: function (value) {
      const currentYear = value.getFullYear()
      this.selectedYear = currentYear
    },
  },
  async mounted () {
     if (!this.hasApplicationPermissionByName('KOHDE_BUDJETJAKULULEHTI')) {
       this.$router.push({ name: 'sites.info' })
     }
     else {
       await this.initView()
     }
  },
  methods: {
    populateWidgets () {
      const costs = this.allCosts
        .filter(c => (new Date(c.recordingDate)).getFullYear() === this.selectedYear)
        .filter(c => c.costDomain === this.selectedCostDomain || this.selectedCostDomain === null)
        .filter(c => c.costDomain.startsWith('00003') || c.costDomain.startsWith('00004'))
        .sort((a,b) => a.costDomain - b.costDomain)
      this.widgets[0].loading = true
      // Populate totals
      this.widgets[0].data.items = costs
        .reduce((a, c) => {
          let existing = a.find(cost => cost.costDomain === c.costDomain)
          if (existing === undefined) {
            existing = {costDomain: c.costDomain, rows: []}
            a.push(existing)
          } 
          existing.rows.push(c)
          return a;
        }, []) // Group
        .map(g => ({
          ...g.rows[0],
          site: this.currentSite?.name,
          costDomain: g.rows[0].costDomain.replace(/^0+/g, ''),
          total: -g.rows.map(c2 => c2.quantity).reduce((a,b) => a + b, 0),
        }))
        
      this.widgets[0].loading = false

      this.widgets[1].loading = true
      // Populate all costs
      this.widgets[1].data.items = costs
        .map(c => ({
          ...c,
          costDomain: c.costDomain.replace(/^0+/g, ''),
          site: this.currentSite?.name,
          building: this.buildingsForCurrentSite.find(b => b.id_building === c.id_building)?.name,
          quantity: -c.quantity,
        }))
      this.widgets[1].loading = false
    },
    async initView () {
      const idSite = this.$route.params.id_site

      // Load data
      const costs = await this.$rambollfmapi.sites.costs().list(idSite);
      this.allCosts = costs; // store costs
      this.selectedYear = this.currentDate.getFullYear()
      this.populateWidgets();
    },
  },
}

</script>
<style scoped>
</style>
