<template>
  <div>
    <v-icon>
      visibility
    </v-icon>
    <span class="px-2 font-weight-bold">{{ $t('Currency') }}:</span>
    <span> {{ selectedCurrency.symbol }} </span>
  </div>
</template>

<script>
export default {
  name: 'CurrencyEdit',
  props: {
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  }
}
</script>