// Vuokraussuunnitelma
// Vuokraussuunnitelma muokattu (pvm)
// Kommentit (kohdesivun kommentti-laatikko)

export const LeasePlan = () => ({
  id: 'leasing.lease_plan',
  type: 'ObjectTable',
  data: {
    headers: [
      { text: 'fund', value: 'fund' },
      { text: 'Site', value: 'name' },
      { text: 'Identifier', value: 'site_identifier' },
      { text: 'Rent priority', value: 'rent_priority' },
      { text: 'Site manager', value: 'siteManager' },
      { text: 'Rental liability (employee)', value: 'employeeLiability' },
      { text: 'Rental liability', value: 'partnerLiability' },
      { text: 'Rent type', value: 'rental_contract_type_string' },
      { text: 'Site state', value: 'site_state' },
      { text: 'leasing.number_of_tenants', value: 'number_of_tenants', format: 'Amount' },
      { text: 'Free areas', value: 'free_area', format: 'Area' },
      { text: 'Economic usage rate %', value: 'economicUsageRate', format: 'Percentage' },
      { text: 'Technical usage rate %', value: 'technicalUsageRate', format: 'Percentage' },
      { text: 'Lease plan', value: 'lease_plan', hideOverflow: true },
      { text: 'Lease plan updated', value: 'lease_plan_date', format: 'Date' },
      { text: 'Comment', value: 'description', hideOverflow: true }
    ],
    items: [],
    sortDesc: false,
    itemsPerPage: 20,
    dynamicFilterOptions: true,
    filter: {
      header: 'rent_priority',
      type: 'equal',
      options: [
        { text: 'prospect.show_all', value: null },
      ],
      defaultFilter: {
        text: 'prospect.show_all',
        value: null,
      },
    }
  },
  maximized: [true],
  loading: true,
  large: true
})