<template>
  <v-container>
    <v-menu
      :close-on-content-click="false"
      :disabled="disabled"
      offset-y
      min-width="0"
    >
      <template #activator="{ on }">
        <ValidationProvider
          v-slot="{ errors }"
          :immediate="immediate"
          :rules="rules"
        >
          <v-text-field
            :value="dateFormatted"
            :label="label"
            :class="defineStyle()"
            :aria-label="ariaLabel || `${label} ${$t('create_rent_contract.in_the_format')} ${$t('date_format')}`"
            :error-messages="errors[0]"
            :hint="`${hint} ${hint && defaultHint ? '(' + $t('date_format') + ')' : ''}`"
            :hide-details="hideDetails"
            :dark="isDateDifferent"
            persistent-hint
            :clearable="clearable"
            :disabled="disabled"
            prepend-icon="calendar_today"
            class="form-field"
            v-on="on"
            @change="onDateChanged"
          />
        </ValidationProvider>
      </template>
      <div class="date-picker">
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          first-day-of-week="1"
          :type="calendarType"
          :allowed-dates="allowedDates"
          :disabled="disabled"
          :locale="$i18n.locale"
        />
        <CdsButton
          v-if="isDateDifferent === true"
          type="primary"
          @click="$emit('setToday')"
        >
          {{ $t('Return to current date') }}
        </CdsButton>
      </div>
    </v-menu>
  </v-container>
</template>
<script>
import moment from 'moment'
import CdsButton from '../CircleDesignSystem/CdsButton.vue';
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'DatePicker',
  components: {
    CdsButton,
    ValidationProvider
  },
  props: {
    ariaLabel: { 
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    rules: {
      type: String,
      default: undefined
    },
    immediate: {
      type: Boolean,
      default: undefined
    },
    initialDate: {
      type: String,
      default: undefined
    },
    displayMonthOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    defaultHint: {
      type: Boolean,
      default: true
    },
    calendarType: {
      type: String,
      default: undefined
    },
    headerVersion: {
      type: Boolean,
      default: false
    },
    allowedDates: {
      type: Function,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    isDateDifferent: {
      type: Boolean,
      default: false
    },
  },
  emits: ['dateupdated'],
  data () {
    return {
      date: undefined,
      dateFormatted: undefined,
    }
  },
  watch: {
    date (value) {
      this.dateFormatted = this.formatDate(value)
      if (this.validateDate(value) != this.validateDate(this.initialDate)) {
        this.$emit('dateupdated', this.date)
      }
    },
    initialDate (value) {
      this.date = this.validateDate(value)
    }
  },
  mounted () {
    if (this.initialDate) {
      this.date = this.validateDate(this.initialDate)
    }
  },
  methods: {
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      if (this.displayMonthOnly === true) {
        const result = formatted && formatted.isValid() ? formatted.format("MM/YYYY") : undefined
        return result
      } else {
        const result = formatted && formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
        return result
      }
    },
    onDateChanged (value) {
      let changedDate
      const dotSeparator = "."
      const slashSeparator = "/"
      if (value && value.includes(dotSeparator)) {
        changedDate = this.parseDate(value, dotSeparator)
      } else if (value && value.includes(slashSeparator)) {
        changedDate = this.parseDate(value, slashSeparator)
      }
      this.date = this.validateDate(changedDate)
    },
    parseDate (date, separator) {
      if (!date) return null
      let [day, month, year] = date.split(separator)
      if (isNaN(day) || isNaN(month) || isNaN(year) || day.length > 2 || month.length > 2 || year.length > 4) {
        return null
      }
      return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`
    },
    validateDate (val) {
      const date = moment(val, "YYYY-MM-DD")
      const result = date && date.isValid() ? date.format("YYYY-MM-DD") : undefined
      return result
    },
    defineStyle () {
      if (this.headerVersion === true) {
        if (this.isDateDifferent === true) {
        return 'header-style secondary'
          } else {
            return 'header-style'
          }
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.date-picker {
  display: flex;
  flex-direction: column;
  background-color: var(--r-color-white);
}

.form-field {
	margin-top: 0 !important;
}

.header-style {
  font-weight: 500;
  font-size: var(--r-font-size-s);
}
.header-style >>> .v-input__prepend-outer {
  margin-right: var(--r-spacing-xxxs);
}
.header-style >>> .v-icon.v-icon {
  font-size: var(--r-sizing-m);
}

@media screen and (max-width: 600px) {
  .header-style.v-input:has(input:focus) {
    position: absolute;
    background: var(--r-color-white);
    inset: 20px 4px;
    z-index: 1;
  }
}

</style>
