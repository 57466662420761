<template>
  <div
    v-if="item.id_prospect != null"
    class="my-2"
  >
    {{ currentValue }}
  </div>
  <v-menu
    v-else
    :offset-y="true"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        rounded
        text
        class="lowercase-button"
        v-bind="attrs"
        v-on="on"
      >
        {{ currentValue ? currentValue : $t("Choose reason") }}
        <v-icon right>
          expand_more
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="option in getOptions()"
          :key="option.id"
          @click="updateContractState(item, option.id)"
        >
          <v-list-item-title>{{ $t(option.label) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TerminationReasonColumn",
  props: {
    header: { type: Object, default: null },
    item: { type: Object, default: null },
  },
  data: function () {
    return {
      selectedValue: null,
    }
  },
  computed: {
    ...mapGetters("app", ["definitionsByGroupLabel", "definitionById"]),
    currentValue () {
      if (this.selectedValue) {
        const reason = this.definitionById(this.selectedValue)
        if (reason) {
          return reason.label
        }
      }

      return this.item.termination_reason
    },
  },
  methods: {
    ...mapActions("leasing", ["patchRentalStatus"]),
    getOptions () {
      return this.definitionsByGroupLabel("terminated_contract_status")
    },

    async updateContractState (item, value) {
      this.selectedValue = value

      const patch = [
        {
          op: "replace",
          path: "defTerminationReason",
          value,
        },
      ]

      this.patchRentalStatus({
        patch,
        unitId: item.unitId,
        curUnitPartyId: item.curUnitPartyId,
        contractNumber: item.contractNumber,
      })
    },
  },
}
</script>

<style scoped>
.lowercase-button {
  text-transform: unset;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
