<template>
  <td
    class="column py-4"
    style="vertical-align: middle;"
  >
    <template v-if="fieldType === 'multi_select'">
      <v-select
        v-model="model"
        :items="items"
        item-text="label"
        item-value="id"
        multiple
      >
        <template 
          slot="selection" 
          slot-scope="{ item, index }"
        >
          <span v-if="!index">{{ item.label }}</span>
          <span
            v-else-if="index === 1"
            class="grey--text text-caption"
          >
            &nbsp;(+{{ model.length - 1 }} {{ $t('Others') }})
          </span>
        </template>
      </v-select>
    </template>
    <template v-else-if="fieldType === 'dropdown'">
      <v-select
        v-model="model"
        :items="items"
        item-text="label"
        item-value="id"
        :clearable="fieldClearable"
      />
    </template>
    <template v-else-if="fieldType === 'short_text_field'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="fieldType === 'long_text_field'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="fieldType === 'limited_text_field'">
      <v-text-field
        v-model="model"
        clearable
        maxlength="100"
        type="text"
        counter
      />
    </template>
    <template v-else-if="fieldType === 'custom_length_limited_text_field'">
      <v-text-field
        v-model="model"
        :maxlength="fieldLength"
        clearable
        type="text"
        counter
      />
    </template>
    <template v-else-if="fieldType === 'url_field'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="fieldType === 'boolean'">
      <v-row
        no-gutters
        justify="center"
      >
        <v-switch
          v-model="model"
          class="switch-margin"
          hide-details="true"
        />  
      </v-row>
    </template>
    <template v-else-if="fieldType === 'date'">
      <v-menu>
        <template #activator="{ on }">
          <v-text-field
            v-model="model"
            readonly
            clearable
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="model"
          no-title
          scrollable
          first-day-of-week="1"
          :locale="$i18n.locale"
        />
      </v-menu>   
    </template>
    <template v-else-if="fieldType === 'number_field'">
      <v-text-field
        v-model="model"
        :rules="rules"
        clearable
        type="number"
      />
    </template>
    <template v-else-if="fieldType === 'text_area'">
      <v-textarea
        v-model="model"
        :rows="rows"
        clearable
        counter
        :maxlength="fieldLength ?? 4000"
      />
    </template>
  </td>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GenericModifyField',
  props: {
    fieldType: {
      type: String,
      required: true
    },
    items: {
        type: Array,
        default: () => []
    },
    value: {},
    rules: {
      type: Array,
      default: () => []
    },
    fieldLength: {
      type: Number,
      default: null
    },
    rows: {
      type: Number,
      default: 4
    },
    fieldClearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('app', []),
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        if(this.fieldType === 'date') {
          this.model = this.RemoveTimeFromDate(this.value)
        }
        else {
          this.model = this.value
        }

      }
    }
  },
  methods: {
      RemoveTimeFromDate (model) {
        if(model)
        return model.slice(0, 10)
      }
  }
}
</script>
