<template>
  <div>
    <AriaLive />
    <BaseModal
      @cancel="emit"
    >
      <template #popupButton>
        <v-btn
          large
          icon
          absolute
          right
          style="margin-top: 0.8em; margin-right: 4em;"
          @click="popup()"
        >
          <v-icon>open_in_new</v-icon>
          <span class="d-sr-only">{{ $t('OpenInNewWindow') }}</span>
        </v-btn>
      </template>
      <template #title>
        {{ $t('Edit spaces') }}
      </template>
      <template #content>
        <v-form
          v-if="spaces"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="validate"
        >
          <v-card
            elevation="0"
          >
            <v-row
              justify="start"
              align="center"
            >
              <v-col
                class="column"
                lg="2"
                md="3"
                sm="6"
                cols="12"
              >
                <v-text-field
                  v-model="startDate"
                  type="date"
                  :label="$t('Start date for the changes')"
                />
              </v-col>
              <v-row>
                <span
                  v-if="rejectedSpaces.length > 0"
                  class="rejected_spaces"
                >
                  {{ getRejectedSpacesMessage() }}
                </span>
              </v-row>
            </v-row>
          </v-card>
          <br>
          <v-container 
            v-if="isLoading"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
              style="margin: 3em"
            />
          </v-container>
          <v-container
            v-else
          >
            <v-card 
              v-show="groupUpdate"
              elevation="0"
            >
              <table class="v-datatable v-data-table v-table them--light table-overflow">
                <thead>
                  <th class="column">
                    {{ $t('Group update') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                  <template v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')">
                    <th
                      v-for="field in customizableEnabledFieds"
                      :key="field.id"
                      class="customizable-field"
                    >
                      {{ field.name }}
                    </th>
                  </template>
                </thead>
                <tbody>
                  <tr>
                    <th class="column">
                      {{ $t('New values') }}
                    </th>
                    <template
                      v-for="field in filteredFields"
                    >
                      <SpaceModifyField
                        :key="field.value"
                        v-model="groupModifiedFields[field.value]"
                        :field="field"
                        :purposes-of-use="purposesOfUse"
                        :cleaning-areas="cleaningAreaChoices"
                        :fire-areas="fireAreaChoices"
                        :costcenters="costcenters"
                        :space-categories="spaceCategories"
                        :departments="departments"
                        :item-areas="itemAreas"
                      />
                    </template>
                    <template
                      v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')"
                    >
                      <td
                        v-for="field in customizableEnabledFieds"
                        :key="field.id"
                      >
                        <v-autocomplete
                          v-model="groupModifiedFields.dataFields[field.id]"
                          class="customizable-field"
                          :placeholder="getPlaceholderValue(field.value)"
                          :items="field.customizableDataFieldValues"
                          item-text="value"
                          item-value="id"
                          clearable
                        />
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <th />
                    <template
                      v-for="field in filteredFields"
                    >
                      <td
                        :key="field.value"
                        style="border: 0 !important"
                      >
                        <v-btn
                          outlined
                          rounded
                          block
                          @click="setToAllFields(field)"
                        >
                          {{
                            $t('Set to all')
                          }}
                        </v-btn>
                      </td>
                    </template>
                    <template
                      v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')"
                    >
                      <td
                        v-for="field in customizableEnabledFieds"
                        :key="field.id"
                        style="border: 0 !important"
                      >
                        <v-btn
                          outlined
                          block
                          @click="setCustomizableDataValueToAllFields(field.id)"
                        >
                          {{
                            $t('Set to all')
                          }}
                        </v-btn>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </v-card>
            <v-card
              elevation="0"
            >
              <table class="v-datatable v-data-table v-table them--light table-overflow">
                <thead>
                  <th
                    v-if="isWidgetFields"
                    class="column"
                  >
                    {{ $t('Building') }}
                  </th>
                  <th
                    v-if="isWidgetFields"
                    class="column"
                  >
                    {{ $t('Floor') }}
                  </th>
                  <th
                    v-if="!filteredFields.find(field => field.value === 'name') || !isWidgetFields"
                    class="column"
                  >
                    {{ $t('Space number') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                  <template v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')">
                    <th
                      v-for="field in customizableEnabledFieds"
                      :key="field.id"
                      class="customizable-field"
                    >
                      {{ field.name }}
                    </th>
                  </template>
                  <th
                    v-if="
                      hasApplicationPermissionByName(
                        'HUONE_HENKILO_KPAIKKA_LISAYS'
                      )
                    "
                    class="column"
                    style="width: 20em"
                  >
                    {{ $t('Users') }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="space in spaces"
                    :key="space.id"
                  >
                    <th
                      v-if="isWidgetFields"
                      class="column"
                    >
                      {{ space.building_name }}
                    </th>
                    <th
                      v-if="isWidgetFields"
                      class="column"
                    >
                      {{ space.floor_name }}
                    </th>
                    <th
                      v-if="!filteredFields.find(field => field.value === 'name') || !isWidgetFields"
                      class="column"
                    >
                      {{ space.name }}
                    </th>
                    <template
                      v-for="field in filteredFields"
                    >
                      <SpaceModifyField
                        v-if="field.value === 'purpose_zone' && modifiedSpaces[space.id] !== undefined"
                        :key="field.value"
                        v-model="modifiedSpaces[space.id][field.value]"
                        :field="field"
                        :placeholder="getPurposeZonePlaceholders(space)"
                        :purposes-of-use="purposesOfUse"
                        :cleaning-areas="cleaningAreaChoices"
                        :fire-areas="fireAreaChoices"
                        :costcenters="costcenters"
                        :space-categories="spaceCategories"
                        :departments="departments"
                        :item-areas="itemAreas"
                        @input="handleFieldInput($event, space.id, field.value)"
                      />
                      <SpaceModifyField
                        v-else-if="modifiedSpaces[space.id] !== undefined"
                        :key="field.value"
                        v-model="modifiedSpaces[space.id][field.value]"
                        :field="field"
                        :placeholder="space[field.value]"
                        :purposes-of-use="purposesOfUse"
                        :cleaning-areas="cleaningAreaChoices"
                        :fire-areas="fireAreaChoices"
                        :costcenters="costcenters"
                        :space-categories="spaceCategories"
                        :departments="departments"
                        :item-areas="itemAreas"
                        :data="space[field.value]"
                        @input="handleFieldInput($event, space.id, field.value)"
                      />
                    </template>
                    <template v-if="hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS') && modifiedSpaces[space.id]">
                      <td
                        v-for="field in customizableEnabledFieds"
                        :key="field.id"
                        class="customizable-field"
                      >
                        <v-autocomplete
                          v-if="modifiedSpaces[space.id].dataFields"
                          v-model="modifiedSpaces[space.id].dataFields[field.id]"
                          :placeholder="getPlaceholderValue(field.value)"
                          :items="field.customizableDataFieldValues"
                          item-text="value"
                          item-value="id"
                          clearable
                        />
                      </td>
                    </template>
                    <td
                      v-if="
                        hasApplicationPermissionByName(
                          'HUONE_HENKILO_KPAIKKA_LISAYS'
                        )
                      "
                      class="customizable-field"
                    >
                      <space-user-modify-form
                        v-model="spaceUsers[space.id]"
                        :selected-space="selectedSpace"
                        :users="getUsersForSpace(space)"
                        :space="space"
                        :modified-space="modifiedSpaces[space.id]"
                        :has-internal-rent-permission="hasApplicationPermissionByName('SISAINENVUOKRAUS')"
                        :links="links"
                        :people="people"
                        :costcenters="costcenters"
                        :parties="parties"
                        :links-in-some-space="linksInSomeSpace"
                        @change="updateChanges"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-container>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <columns-chooser
          v-model="visibleColumns"
          :stored-view-parent="'facilitymanagement'"
          :stored-view-level="'space_modify_form'"
          :headers="editableFields"
          :show-save-selection="true"
          header-value="value"
          header-text="header"
          :small="false"
        />
        <v-btn
          v-if="spaces.length > 1"
          text
          outlined
          rounded
          :class="{ active: groupUpdate }"
          @click="groupUpdate = !groupUpdate"
        >
          {{ $t('Group update') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          outlined
          rounded
          @click="emit"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <p 
          v-if="canSave == false"
          class="mt-4 ml-4 form-fields validation-error"
        >
          {{ $t('Check cost center shares') }}
        </p>
        <v-btn
          :disabled="!valid || isSaving"
          rounded
          depressed
          color="primary"
          type="submit"
          @click="validate"
        >
          {{ $t('Save') }}
          <v-progress-circular
            v-if="isSaving"
            size="16"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import moment from 'moment'
import SpaceModifyField from './SpaceModifyField.vue'
import SpaceUserModifyForm from './SpaceUserModifyForm.vue'
import ColumnsChooser from '../../components/general/ColumnsChooser.vue'
import BaseModal from '../../components/general/BaseModal.vue'
import AriaLive from '../../components/AriaLive.vue'

export default {
  name: 'SpaceModifyForm',
  components: {
    SpaceModifyField,
    ColumnsChooser,
    SpaceUserModifyForm,
    BaseModal,
    AriaLive
  },
  props: {
    spaceModifyDialog: Boolean,
    parties: {
      type: Array,
      default: () => []
    },
    spaces: {
      type: Array,
      default: () => []
    },
    links: {
      type: Array,
      default: () => []
    },
    linksInSomeSpace: {
      type: Array,
      default: () => []
    },
    customizableFields: {
      type: Array,
      default: () => []
    },
    spaceVisibilityRights: {
      type: Object,
      default: null
    },
    disabled: Boolean,
    costcenters: {
      type: Array,
      default: () => []
    },
    inPopup: Boolean,
    preModifiedSpaces: {
      type: Object,
      default: () => {}
    },
    isWidgetFields: Boolean,
    spaceCategories: {
      type: Array,
      default: () => {}
    },
    departments: {
      type: Array,
      default: () => {}
    },
    itemAreas: {
      type: Array,
      default: () => {}
    }
  },
  data () {
    return {
      startDate: null,
      groupUpdate: false,
      groupModifiedFields: { shares: [], dataFields: {} },
      spaceUsers: {},
      modifiedSpaces: {},
      modifiedUsersPatch: {},
      selectedSpace: null,
      deletedLinks: [],
      addedUsers: {},
      valid: true,
      isSaving: false,
      childMessage: null,
      currentVisibility: false,
      shares: [],
      canSave: true,
      fields: [
        {
          header: 'Space number',
          value: 'name',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Space name',
          value: 'space_name',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Net room area',
          value: 'area',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Purpose of use',
          value: 'id_purpose',
          show: true,
          style: { 'min-width': '25em' }
        },
        {
          header: 'Clarified purpose of use',
          value: 'clarified_purpose_of_use',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Käyttötarkoitusvyöhyke',
          value: 'purpose_zone',          
          style: { 'min-width': '45em' }
        },
        {
          header: 'Floor material',
          value: 'floor_material',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Cleaning layer',
          value: 'cleaning_area',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Cleaning frequency',
          value: 'cleaning_frequency',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Cleaning layer low',
          value: 'cleaning_area_low',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Fire area',
          value: 'fire_area',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Movement area',
          value: 'movement_area',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Additional cost (€/m2/mth)',
          value: 'additional_cost',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Cleaning details',
          value: 'cleaning_details',
          show: true,
          style: { 'min-width': '22em' }
        },
        {
          header: 'Cost center and person count',
          value: 'shares',
          show: true,
          style: { 'min-width': '35em' }
        },
        {
          header: 'Person count',
          value: 'number_of_personnel',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Number of workstations',
          value: 'number_of_workstations',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Meter number',
          value: 'meter_number',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Classification',
          value: 'classification',
          show: true,
          style: { 'min-width': '15em' }
        },
         {
          header: 'Capacity quantity',
          value: 'capacity_quantity',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Capacity unit',
          value: 'capacity_unit',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Contract number (SAP)',
          value: 'contract_number_sap',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Shared space code',
          value: 'shared_space_code',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Space category',
          value: 'space_category',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Department',
          value: 'department',
          show: true,
          style: { 'min-width': '15em' }
        },
        {
          header: 'Item area',
          value: 'item_area',
          show: true,
          style: { 'min-width': '15em' }
        }
      ],
      rejectedSpaces: [],
      visibleColumns: [],
      isLoading: true,
      hasMounted: false
    }
  },
  computed: {
    ...mapState('app', [
      'userGroups',
      'purposesOfUse',
      'cleaningAreas',
      'fireAreas'
    ]),
    ...mapGetters('app', [
      'hasApplicationPermissionByName',
      'definitionByLabel'
    ]),
    customizableEnabledFieds () {
      return this.customizableFields.filter(x => x.enabled)
    },
    cleaningAreaChoices () {
      return this.cleaningAreas
    },
    fireAreaChoices () {
      return this.fireAreas
    },
    people () {
      return this.parties.filter(p => p.type === 1)
    },
    editableFields () {
      return this.fields.filter(field => field.show)
    },
    filteredFields () {
      return this.fields.filter(field => field.show && this.visibleColumns.includes(field.value))
    }
  },
  watch: {
    userGroups: {
      deep: true,
      handler (value) {
        this.setVisibilities();
        this.updateSpaces(this.spaces)
      }
    },
    spaceVisibilityRights: {
      deep: true,
      immediate: true,
      handler (oldVal, newVal) {
        this.setVisibilities()
      }
    },
    spaces: {
      deep: true,
      immediate: true,
      async handler (newVal, oldVal) {
        if (this.hasMounted) {
          this.isLoading = true
          this.updateSpaces(newVal)
        }
        
      }
    },
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getPurposes(false)
    await this.getCleaningAreas()
    await this.getFireAreas()
    await this.updateSpaces(this.spaces)
    this.hasMounted = true
  },
  methods: {
    ...mapActions('app', ['getPurposes', 'getCleaningAreas', 'getFireAreas']),
    emit (event) {
      this.$emit('childToParent', false)
      this.$emit('update')
      this.close()
    },
    close () {
      this.currentVisibility = false
      this.$emit('close')
    },
    handleFieldInput ($event, space_id, field)
    {
      this.modifiedSpaces[space_id][field] = $event
    },
    async validate () {
      this.canSave = true
      if (this.visibleColumns.includes("shares")) {
        for (const key in this.modifiedSpaces) {
          let sum = 0;
          const value = this.modifiedSpaces[key];
          if (Array.isArray(value.shares)) {
            for (var i = 0; i < value.shares.length; i++) {
              if (value.shares[i].share == null) {
                sum += 100;
              } else {
                sum += parseFloat(value.shares[i].share);
              }
            }
            if (Math.round(sum) != 100) {
              this.canSave = false
            }
          } else {
            this.canSave = true
          }
        }
      }

      if (this.canSave ==  true) {
        this.isSaving = true
        await this.patch()
        this.isSaving = false
        this.$emit('close')
      }
    },
    addCostcenterRow (id) {
      this.modifiedSpaces[id].shares.push({
        id: 0,
        share: null
      })
    },
    getPurposeName (id) {
      if (id > 0) {
        return this.purposesOfUse.find(x => x.id === id).title
      }
      return ''
    },
    updateChanges (values) {
      this.$set(this.addedUsers, values.spaceId, values.addedUsers)
      this.deletedLinks = this.deletedLinks.filter(link => link.idSpace === values.spaceId)
      this.deletedLinks = [...this.deletedLinks, ...values.deletedLinks]
    },
    canEdit (object) {
      return (
        object !== undefined && object.isShown && object.editType !== 'None'
      )
    },
    userRemoved (userLink) {
      if (this.deletedLinks.find(link => link.id === userLink.id)) {
        return true
      } else {
        return false
      }
    },
    setVisibilities () {
      this.visibleColumns = []
      this.fields.forEach(field => {
        if (field.value === 'shares') {
          field.show = this.hasApplicationPermissionByName(
            'HUONE_HENKILO_KPAIKKA_MUOKKAUS'
          )
        } else if (field.value === 'purpose_zone') {
          field.show = this.hasApplicationPermissionByName('SIS_KTV')
        } else if (field.value === 'name' && !this.isWidgetFields) {
          field.show = false
        } else if (field.value === 'area' && !this.isWidgetFields) {
          field.show = false
        } else {
          field.show = this.canEdit(this.spaceVisibilityRights[field.value])
        }

        if (field.show) {
          this.visibleColumns.push(field.value)
        }
      })
    },
    async updateSpaces (spaces){
      this.startDate = moment(this.$store.state.app.currentDate).format(
          'YYYY-MM-DD'
        )

        const oldModifications = JSON.parse(JSON.stringify(this.modifiedSpaces))
        this.modifiedSpaces = {}
        let customizableFieldValues = []

        if (this.hasApplicationPermissionByName('SISAINENVUOKRAUS')) {
          this.shares = await this.$rambollfmapi.internalrent
            .shares()
            .list(spaces.map(x => x.id), this.$store.state.app.currentDate)
        }

        if (this.hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')) {
          customizableFieldValues = await this.$rambollfmapi.customizabledatafields.spaces(spaces.map(x => x.id))
        }

        this.rejectedSpaces = []
        Object.keys(oldModifications).filter(key => !spaces.find(s => s.id.toString() === key)).forEach(key => {
          if (Object.keys(oldModifications[key]).find(header => header !== 'shares' && header !== 'name' && oldModifications[key][header])) {
            this.rejectedSpaces.push(oldModifications[key].name)
          }
        })

        spaces.forEach(element => {
          if (oldModifications && oldModifications[element.id]){
            // if we already have unsaved modifications for selected space use those
            this.$set(this.modifiedSpaces, element.id, oldModifications[element.id])
          }
          else if(this.preModifiedSpaces && this.preModifiedSpaces[element.id]){
            // if premodified data from dialog form contain modifications, use it as starting point
            this.$set(this.modifiedSpaces, element.id, JSON.parse(JSON.stringify(this.preModifiedSpaces[element.id])))
          } else {
            // otherwise set modifications for space empty
            this.$set(this.modifiedSpaces, element.id, {name: element.name})

            if (this.hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS')) {
              this.$set(
                this.modifiedSpaces[element.id],
                'dataFields',
                customizableFieldValues.filter(x => x.idSpace === element.id).reduce((acc, cur) => {
                  acc[cur.idField] = cur.idValue
                  return acc
                }, {})
              )
            }
            
            if (
              this.hasApplicationPermissionByName('SISAINENVUOKRAUS')
            ) {
              const sharesForSpace = this.shares.filter(x => {
                return x.idSpace === element.id
              })
              let parsedStringified = JSON.parse(JSON.stringify(sharesForSpace))
              this.$set(
                this.modifiedSpaces[element.id],
                'shares',
                parsedStringified
              )              
              this.spaces.filter(space => space.id == element.id)[0].shares = parsedStringified
              if (
                this.hasApplicationPermissionByName(
                  'HUONE_HENKILO_KPAIKKA_LISAYS'
                )
              ) {
                if (sharesForSpace.length === 1) {
                  const costcenter = this.costcenters.find(
                    cc => cc.id === sharesForSpace[0].idCostcenter
                  )
                  if (this.getUsersForSpace(element).length === 0) {
                    this.$set(
                      this.modifiedSpaces[element.id],
                      'costcenterInsteadOfUser',
                      costcenter.id
                    )
                  }
                }
              }
            }
          }
        })
        this.isLoading = false
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedSpaces)) {
          this.modifiedSpaces[key][field.value] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.value])
          )
        }
      })
    },
    setCustomizableDataValueToAllFields (fieldId) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedSpaces)) {
          this.$set(
            this.modifiedSpaces[key].dataFields,
            fieldId,
            JSON.parse(
              JSON.stringify(this.groupModifiedFields.dataFields[fieldId])
            )
          )
        }
      })
    },
    getUsersForSpace (space) {
      const linksInSpace = this.links.filter(l => l.id_parent === space.id)
      const userList = linksInSpace.reduce((acc, cur) => {
        cur.party = this.people.find(p => p.id === cur.id_party)
        acc.push(cur)
        return acc
      }, [])
      userList.sort((a, b) => {
        if (a.party.name < b.party.name) return -1
        else return 1
      })
      return userList
    },
    async patch () {
      const time = this.startDate

      try {
        for (const link of this.deletedLinks) {
          await this.$rambollfmapi.spaces
            .partyLinks(link.id_parent)
            .delete(link.id_parent, link.id)
        }

        const addedLinks = []
        // Check if there's a space user definition...
        const userDef = this.definitionByLabel('User')
        // If user definition was found and it has clear ID, perform link save
        if (userDef !== undefined) {
          if (userDef.id !== null) {
            Object.keys(this.addedUsers).forEach(key => {
              const addUser = this.addedUsers[key]
              addUser.forEach(user => {
                const newLink = {
                  id_party: user.id,
                  id_annotation: userDef.id,
                  group: 6,
                  id_parent: key,
                  start_date: time
                }
                addedLinks.push(newLink)
              })
            })
            for (const newLink of addedLinks) {
              await this.$rambollfmapi.spaces
                .partyLinks(newLink.id_parent)
                .post(newLink.id_parent, newLink)

              const partylinks = await this.$rambollfmapi.spaces
                .partyLinks(newLink.id_parent)
                .list()

              const partyIds = partylinks.map(pl => pl.id_party)
              const parties = this.parties.filter(p => partyIds.includes(p.id))
              const costcenterCodes = parties
                .filter(p => p.cost_center !== null)
                .map(p => p.cost_center)
              const costcenters = this.costcenters.filter(cc =>
                costcenterCodes.includes(cc.code)
              )

              const costcenterIds = [...new Set(costcenters.map(cc => cc.id))].map(
                cc => {
                  return { idCostcenter: cc }
                }
              )

              if (this.hasApplicationPermissionByName('SISAINENVUOKRAUS')) {
                await this.$rambollfmapi.internalrent
                  .shares()
                  .put(parseInt(newLink.id_parent), time, costcenterIds)
              }
            }
          }
        }
        for (const space of this.spaces) {
          // Handle assigning new ktv:s
          if (
            this.modifiedSpaces[space.id].purpose_zone
          ) {
            this.modifiedSpaces[space.id].id_purpose_zone1 = this.modifiedSpaces[space.id].purpose_zone.zone1
            this.modifiedSpaces[space.id].id_purpose_zone2 = this.modifiedSpaces[space.id].purpose_zone.zone2
            this.modifiedSpaces[space.id].id_purpose_zone3 = this.modifiedSpaces[space.id].purpose_zone.zone3
          }

          const linkPatch = []

          if (this.modifiedSpaces[space.id]) {
            Object.keys(this.modifiedSpaces[space.id]).forEach(key => {
              if (this.hasApplicationPermissionByName('TIETOKENTAT_MUOKKAUS') && key === 'dataFields') {
                this.$rambollfmapi.spaces.customizabledatafields(space.id, this.modifiedSpaces[space.id].dataFields)
              }
              else if (
                this.hasApplicationPermissionByName('SISAINENVUOKRAUS') &&
              key === 'shares'
              ) {
              // Do a quick comparison to determine if the costcenters for the space require updating.
                const formerShares = this.shares.filter(x => {
                  return x.idSpace === space.id
                })
                if (this.modifiedSpaces[space.id].shares === undefined)
                {
                  return
                }
                const diff = this.$jsonpatch.compare(
                  formerShares,
                  this.modifiedSpaces[space.id].shares
                )
                if (diff.length > 0) {
                  let spaceShares = this.modifiedSpaces[space.id].shares
                  for(let iSpaceShare=0; iSpaceShare < spaceShares.length; iSpaceShare++)
                  {
                     spaceShares[iSpaceShare].share = parseFloat(spaceShares[iSpaceShare].share)
                  }
                  this.$rambollfmapi.internalrent
                    .shares()
                    .put(space.id, time, spaceShares)
                }
              } else if (
                this.hasApplicationPermissionByName('SISAINENVUOKRAUS') &&
                key === 'costcenterInsteadOfUser'
              ) {
              // Do a quick comparison to determine if the costcenters for the space require updating.
                const formerCostcenters = this.shares.filter(x => {
                  if (x.idSpace === space.id) {
                    return x.idCostcenter
                  }
                })
                const currentCoscenter = [this.modifiedSpaces[space.id].costcenterInsteadOfUser]
                const diff = this.$jsonpatch.compare(
                  formerCostcenters,
                  currentCoscenter
                )
                if (diff.length > 0) {
                  this.modifiedSpaces[space.id].shares = currentCoscenter ? [{ idCostcenter: currentCoscenter[0], idSpace: space.id, shares: null }] : []
                  this.$rambollfmapi.internalrent
                    .shares()
                    .put(space.id, time, this.modifiedSpaces[space.id].shares)
                }
              } else if (key === 'costcenterInsteadOfUser' || key === 'shares' || (key === 'name' && this.isWidgetFields)) {
              // Nada
              } else if (key !== 'purpose_zone' && this.modifiedSpaces[space.id][key] !== undefined) {
                linkPatch.push({
                  op: 'replace',
                  path: '/' + key,
                  value: this.modifiedSpaces[space.id][key],
                  time: time
                })
              }
            })
          }
          if (linkPatch.length > 0) {
            await this.$rambollfmapi.spaces.patch(space.id, time, linkPatch)
          }
        }
        Object.keys(this.modifiedSpaces).forEach(key => {
          let space = this.spaces.filter(space => space.id == key)[0]
          Object.keys(this.modifiedSpaces[key]).forEach(spaceKey => {
            if (spaceKey !== "shares" || this.modifiedSpaces[key][spaceKey] !== undefined)
            {
              space[spaceKey] = this.modifiedSpaces[key][spaceKey]
            }
          })
        })
        this.$emit('saved', 'success')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.space_added_window_closed'))
      } catch (err) {
        this.$emit('saved', 'error')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }

      this.$emit('update')
      this.isSaving = false

      Object.keys(this.modifiedSpaces).forEach(key => {
        this.modifiedSpaces[key] = {}
      })

      this.deletedLinks = []
      this.addedUsers = []
    },
    isAssigned (party) {
      return this.linksInSomeSpace.includes(party.id)
    },
    popup (){
      this.$emit('popup', this.modifiedSpaces);
      this.close();
    },
    getPurposeZonePlaceholders (space) {
      const zones = {
        zone1: space.id_purpose_zone1,
        zone2: space.id_purpose_zone2,
        zone3: space.id_purpose_zone3
      }
      return zones  
    },
    getPlaceholderValue (value) {
      if (value === null || value === undefined) {
        return '-'
      } else if (!isNaN(value)) {
        return String(value)
      }
      return value
    },
    getRejectedSpacesMessage () {
      var string = this.$t('Space selection updated. Changes for space/spaces') 
      this.rejectedSpaces.forEach((id, idx) => {
        if (idx === 0) {
          string += ' ' + id
        } else {
          string += ', ' + id
        }
      })
      string += ' ' + this.$t('were rejected')
      return string
    }
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
.costcenter-add {
  padding-top: 1em;
  color: var(--c-color-accent);
}
.rounded {
  border-radius: 2em !important;
}
.rounded .v-card {
  border-radius: 2em !important;
}
.user-edit-dialog {
  max-height: 100%;
}
tbody tr th .icon-column {
  padding: 0 !important;
}
.rejected_spaces {
  color: red;
}
.customizable-field {
  min-width: 20em;
}
</style>
