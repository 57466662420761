
<template>
  <div>
    <v-tab-item
      :key="3"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <h3 class="ml-2">
          {{ $t('create_rent_contract.contract_signing') }}
        </h3>
        <div
          v-if="contractSigningState !== 'ContractStateSigned' && contractSigningState !== 'ContractStateStarting'"
        >
          <v-btn
            rounded
            depressed
            class="blue-button"
            @click="$emit('downloadContract')"
          >
            <v-icon small>
              download
            </v-icon>
            {{ $t('create_rent_contract.download_the_contract_for_signature') }}
          </v-btn>

          <p class="form-fields mt-2 ml-2 mb-2">
            {{ $t('NoElectronicSigningInfo') }}              
          </p>
        </div>

        <h4 class="mt-4 mb-2 ml-2">
          {{ $t('create_rent_contract.signatures') }}
        </h4>
        
        <v-row>
          <v-col>
            <v-file-input
              ref="fileInput"
              v-model="signedContract"
              class="form-fields mt-2 ml-2"
              clear-icon="$clear"
              :label="$t('create_rent_contract.signed_contract')"
              type="file"
              accept=".pdf, .odt, .doc, .docx"
              @change="onDataChanged()"
            />
          </v-col>
          <v-col
            v-if="contract !== undefined && contract.contractPDFId !== null && signedContract !== undefined"
            cols="12"
            md="3"
            class="mr-2"
          >
            <v-btn
              rounded
              depressed
              block
              class="blue-button mt-2"
              @click="downloadSignedContract()"
            >
              <v-icon left>
                download
              </v-icon>
              {{ $t('Download signed contract') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="signedPlace"
              :label="$t('create_rent_contract.signed_place')"
              class="form-fields mt-2 ml-2"
              @change="onDataChanged()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col 
            cols="12"
          >
            <v-menu
              class="mt-2 ml-2"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="formatDate(signedDate)"
                  :label="$t('create_rent_contract.signed_date')"
                  class="form-fields mt-2 ml-2"
                  prepend-icon="calendar_today"
                  v-on="on"
                  @change="onSignedDateChange"
                />
              </template>
              <v-date-picker
                v-model="signedDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
                @change="onDataChanged()"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>
import moment from 'moment'
import helpers from '../../../../helpers'

const getValidDate = (val) => {
  const date = moment(val, "DD.MM.YYYY");
  return date.isValid() ? date.format("YYYY-MM-DD") : undefined
}

export default {
  name: "NoElectronicSigningTabVue",
  components: {
  },
  props: {
    contract: {
      type: Object,
      default: () => undefined
    },
    contractpdfid: {
      type: Number,
      default: undefined
    },
    contractSigningState: {
      type: String,
      default: undefined
    },
  },
  data () {
    return {
      signedContract: undefined,
      signedPlace: undefined,
      signedDate: undefined
    }
  },
  watch: {
    contract () {
      if (this.contract) {
        this.updateDataFromExistingContract()
      }
    },
    contractpdfid () {
      this.contract.contractPDFId = this.contractpdfid
    }
  },
  async mounted () {
    if (this.contract) {
      this.setSignedContract()
    }
  },
  methods: {
    async setSignedContract () {
      if (this.contract.contractPDFId != undefined && this.contract.contractPDFId != null) {
        const pdfFile = await this.$rambollfmapi.documents.versions(this.contract.contractPDFId).list()

        if (pdfFile[0] && pdfFile[0].filename != 'blob') {
          this.signedContract = {
            lastModified: '',
            lastModifiedDate: '',
            name: pdfFile[0].filename,
            size: pdfFile[0].size,
            type: pdfFile[0].fileType,
            webkitRelativePath: ""
          }
        }
      }
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    downloadSignedContract () {
      const id = this.contract.contractPDFId
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      const self = this
      this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          self.isRetrievingFile = false
          self.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const filename = res.headers["content-disposition"].match(/filename\s*=\s*(.+);/i)[1].replaceAll('"', '');
          helpers.saveAs(blob, filename)
        })
        .catch(() => {
          this.isRetrievingFile = false
          // this.showFileInfo('Dokumenttia ei pystytä lataamaan', 'error')
        })
    },
    onSignedDateChange (val) {
      this.signedDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    async updateDataFromExistingContract () {
      this.signedPlace = this.contract.signedPlace
      this.signedDate = this.contract.signedDate
      await this.setSignedContract()
      if ( this.signedPlace && this.signedDate && this.signedContract) {
        this.$emit('ready', true)
      }
    },
    async onDataChanged () {
      const data = {
        signedContract: this.signedContract,
        signedPlace: this.signedPlace,
        signedDate: this.signedDate,
      }
      this.$emit('updated', data)
    }
  }
}
</script>

<style scoped>
</style>