<template>
  <v-card 
    elevation="0"
  >
    <v-card-subtitle
      class="text-center text-h6"
    >
      {{ $t('Breeam certication ad title') }}
    </v-card-subtitle>
    <v-card-text 
      class="text-center"
    >
      {{ $t('Breeam certifcation ad text') }} <p><a href="https://fi.ramboll.com/yhteystiedot/tarjoukset">https://fi.ramboll.com/yhteystiedot/tarjoukset</a> {{ $t('or send us an email at') }} <a href="mailto:tarjoukset@ramboll.fi.">tarjoukset@ramboll.fi</a></p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
    name: 'BreeamCertificationAdCard'
}
</script>
<style scoped>
</style>

