<template>
  <div>
    <v-dialog
      v-if="showConfirmationModal"
      v-model="showConfirmationModal"
      persistent
      eager
      width="60%"
    >
      <v-card>
        <ContractConfirmTerminationModal
          :contract-id="contractId"
          :termination-date="new Date(terminationDate)"
          @close="closeConfirmationModal"
          @terminateContract="terminateContract"
        />
      </v-card>
    </v-dialog>
    <BaseModal
      @cancel="close()"
    >
      <template #title>
        {{ $t('contractTermination.terminationOrRescission') }}
      </template>
      <template #content>
        <ValidationObserver ref="observer">
          <v-form>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="formatDate(contractStartDate)"
                  :label="$t('new_handinvoice.contract_startdate')"
                  class="form-fields mt-4 ml-2"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="formatDate(contractEndDate)"
                  :label="$t('new_handinvoice.contract_enddate')"
                  class="form-fields mt-4 ml-2"
                  readonly
                />
              </v-col>
            </v-row>
            <v-menu
              offset-y
              :close-on-content-click="false"
              :readonly="contract && contract.isDraft === false"
              min-width="0"
            >
              <template #activator="{ on }">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required|terminationDate: ${contractStartDate}, ${terminationDate}`"
                >
                  <v-text-field
                    v-model="terminationDateFormatted"
                    :label="$t('contract.contract end date')"
                    :aria-label="$t('contract.contract end date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                    :hint="$t('date_format')"
                    class="form-fields mt-4 ml-2"
                    prepend-icon="calendar_today"
                    required
                    :error-messages="errors[0]"
                    @blur="terminationDate = parseDate(terminationDateFormatted)"
                    v-on="on"
                  />
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="terminationDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
                :allowed-dates="allowedTerminationDates"
              />
            </v-menu>
            <v-row>
              <v-col
                cols="3"
                class="d-flex align-center"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-radio-group
                    v-model="terminationType"
                    mandatory
                    class="pl-2 font-weight-bold mt-4 ml-2"
                    :error-messages="errors[0]"
                  >
                    <template #label>
                      <div>{{ $t('pdf.Contract') }}</div>
                    </template>
                    <v-radio
                      v-for="type in terminationTypes"
                      :key="type.id"
                      :label="$t(type.label)"
                      :value="type.id"
                      default
                      class="font-weight-regular px-2"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col
                cols="3"
                class="d-flex align-center"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-radio-group
                    v-model="terminatingParty"
                    mandatory
                    class="pl-2 font-weight-bold mt-4 ml-2"
                    :error-messages="errors[0]"
                  >
                    <template #label>
                      <div>{{ $t('contractTermination.terminatingParty') }}</div>
                    </template>
                    <v-radio
                      default
                      :label="$t('Landlord')"
                      :value="landlordId"
                      class="font-weight-regular px-2"
                    />
                    <v-radio
                      default
                      :label="$t('Tenant')"
                      :value="tenantId"
                      class="font-weight-regular px-2"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="description"
                >
                  <v-textarea
                    v-model="terminationDescription"
                    class="form-fields ml-2 mr-2"
                    counter="2000"
                    :placeholder="$t('Reasoning')"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-col
          class="d-flex justify-space-between"
        >
          <v-btn
            text
            rounded
            outlined
            depressed
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <div>
            <v-btn
              text
              rounded
              outlined
              depressed
              @click="$emit('terminationPdf', { terminationDateFormatted, terminationDescription, type: 'preview' })"
            >
              {{ $t("create_rent_contract.preview") }}
            </v-btn>
            <v-btn
              text
              rounded
              outlined
              depressed
              class="blue-button"
              @click="openConfirmationModal()"
            >
              {{ $t('Continue') }}
            </v-btn>
          </div>
        </v-col>
      </template>
    </BaseModal>
  </div>
</template>
    
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { i18n } from "../../../../plugins/i18n"
import BaseModal from '../../../general/BaseModal'
import ContractConfirmTerminationModal from './ContractConfirmTerminationModal'

extend('terminationDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['contractStartDate', 'terminationDate'],
  validate: (_, { contractStartDate, terminationDate }) => {
    const startDate = new Date(contractStartDate)
    startDate.setHours(0, 0, 0, 0)
    const newDate = new Date(terminationDate)
    newDate.setHours(0, 0, 0, 0)
    return newDate > startDate
  }
});

extend('description', {
  params: ['contractStartDate', 'terminationDate'],
  validate: (value) => {
    return !value || value.length <= 2000
  }
});

export default {
  name: "ContractTerminationModal",
  components: {
    BaseModal,
    ValidationObserver,
    ValidationProvider,
    ContractConfirmTerminationModal,
  },
  props: {
    contract: {
      type: Object,
      default: () => undefined
    },
    contractStartDate: {
      type: Date,
      default: () => undefined
    },
    contractEndDate: {
      type: Date,
      default: () => undefined
    },
    contractId: {
      type: Number,
      default: undefined
    },
  },
  data () {
    return {
      terminationDate: undefined,
      terminationType: undefined,
      terminatingParty: undefined,
      terminationDescription: undefined,
      showConfirmationModal: false,
      landlordId: undefined,
      tenantId: undefined,
      terminationTypes: [],
      terminationDateFormatted: undefined,
      historyEvents : {
        contractTerminated: 7,
      },
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
    terminationData () {
      return {
        idContract: this.contractId,
        endDate: this.terminationDate,
        defTerminationType: this.terminationType,
        terminatingParty: this.terminatingParty,
        Description: this.terminationDescription,
      }
    }
  },
  watch: {
    terminationDate () {
      this.terminationDateFormatted = this.formatDate(this.terminationDate)
    },
  },
  mounted () {
    this.landlordId = this.contract.landlordId
    this.tenantId = this.contract.tenantId
    this.terminationTypes = this.definitionsByGroupLabel('contract.terminationType').sort((a, b) => a.id - b.id)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closeConfirmationModal () {
      this.showConfirmationModal = false
    },
    async openConfirmationModal () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.showConfirmationModal = true
      }
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    allowedTerminationDates (date) {
      const startDate = new Date(this.contractStartDate)
      startDate.setHours(0, 0, 0, 0)
      const newDate = new Date(date)
      newDate.setHours(0, 0, 0, 0)
      return newDate > startDate
    },
    async terminateContract () {
      
      await this.$rambollfmapi.contracts.contractTermination.terminate(this.terminationData)
      this.$emit(
        'terminationPdf', 
        { 
          terminationDateFormatted: this.terminationDateFormatted, 
          terminationDescription: this.terminationDescription, 
          type: 'final' 
        }
      )
      this.$emit('getUpdatedData')
      this.closeConfirmationModal()
      this.close()
    },
  }
}
</script>

<style scoped>
</style>