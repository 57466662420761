<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
  >
    <template #activator="{ on }">
      <v-text-field
        :value="getFormatedDate(value)"
        :label="$t(label)"
        prepend-inner-icon="calendar_today"
        readonly
        class="date-field"
        v-on="on"
      >
        <template
          v-if="info || status"
          #append
        >
          <InfoBall
            v-if="info"
            :info="$t(info)"
            right
          />
          <v-icon
            v-if="status"
            :color="status.color"
          >
            {{ status.icon }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="getDateIsoString(value)"
      no-title
      scrollable
      :disabled="disabled"
      first-day-of-week="1"
      :locale="$i18n.locale"
      :type="type"
      @change="(x) => $emit('change', x)"
    />
  </v-menu>
</template>

<script>
import InfoBall from './../InfoBall'

import helpers from '../../helpers'

export default {
  name: 'DateSelector',
  components: {
    InfoBall
  },
  props: {
    label: { type: String, default: '' },
    info: { type: String, default: '' },
    value: { type: String, default: null },
    type: { type: String, default: 'date' },
    status: { type: Object, default: null},
    disabled: {type: Boolean, default: false}
  },
  emits: ['change'],
  methods: {
    getDateIsoString (value) {
      if (value === null) {
        value = new Date()
      } 
      let date = new Date(value + 'Z')
      return date.toISOString().substring(0, 10)
    },
    getFormatedDate (value) {
      if (value === null) {
        return ''
      }
      let dateObject = new Date(value)
      if (this.type === 'month') {
        return `${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`
      }
      return helpers.humanize.date(dateObject.toISOString(), 'date')
    },
  }
}
</script>

<style scoped>
.date-field {
  background-color: white !important;
}
</style>