import { i18n } from "../../../plugins/i18n"

export const RentRollWidget = () => ({
  id: "leasing.rentroll",
  type: "ObjectTable",
  data: {
    headers: [
      // General info
      {
        text: i18n.t("Site name"),
        value: "site_name",
      },
      {
        text: i18n.t("Floor"),
        value: "floor",
      },
      {
        text: i18n.t("Unit code"),
        value: "unit_code_time_alert",
        leasing: true,
      },
      {
        text: i18n.t("Unit code long"),
        value: "unit_code_long",
      },
      {
        text: i18n.t("rentRoll.area"),
        value: "area",
        format: "Area",
      },
      {
        text: i18n.t("Usage types"),
        value: "usage_type",
      },
      {
        text: i18n.t("Rental situation"),
        value: "rental_situation",
      },
      {
        text: i18n.t("Tenant"),
        value: "tenant",
      },
      {
        value: "tenant_category",
        text: i18n.t("Tenant category"),
        format: "",
        editable: false,
        ignoreHeader: true,
      },
      {
        value: "credit_rating",
        text: i18n.t("Credit rating"),
        format: "",
        editable: false,
        ignoreHeader: true,
      },
      {
        text: i18n.t("rentRoll.contractNum"),
        value: "contract_number",
      },
      {
        text: i18n.t("Industry"),
        value: "industry",
      },
      {
        text: i18n.t("rentRoll.contractType"),
        value: "contract_type",
      },
      {
        text: i18n.t("Contract area"),
        value: "contract_area",
        format: "Area",
      },
      {
        text: i18n.t("rentRoll.sameContractNumber"),
        value: "contract_area_calculated",
        format: "Area",
      },
      // Rental period info
      {
        text: i18n.t("rentRoll.durationLeft"),
        value: "contract_duration_left",
        format: "Number",
      },
      {
        text: i18n.t("rentRoll.contractStart"),
        value: "contract_start_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.contractEnd"),
        value: "contract_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.validityNotice"),
        value: "huom",
      },
      {
        text: i18n.t("rentRoll.details"),
        value: "contract_details",
      },
      {
        text: i18n.t("rentRoll.noticeTenant"),
        value: "tenant_notice",
        format: "Number",
      },
      {
        text: i18n.t("rentRoll.noticeLandlord"),
        value: "landlord_notice",
        format: "Number",
      },
      {
        text: i18n.t("rentRoll.firstEndDate"),
        value: "contract_first_possible_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.tenantFirstEndDate"),
        value: "tenant_first_possible_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.landlordFirstEndDate"),
        value: "landlord_first_possible_end_date",
        format: "Date",
      },
      // Capital rent
      {
        text: i18n.t("rentRoll.capitalRent"),
        value: "capital_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.capitalRentContract"),
        value: "capital_rent_contract",
        format: "Euro",
      },
      /*{
            text: i18n.t('rentRoll.LV'),
            value: '',
            format: 'Number'
          },*/
      {
        text: i18n.t("Unit yearly rent"),
        value: "capital_rent_yearly",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.capitalRentSquare"),
        value: "capital_rent_per_square",
        format: "Euro",
      },
      // Other payments
      {
        text: i18n.t("rentRoll.maintenanceRent"),
        value: "maintenance_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceYearly"),
        value: "maintenance_rent_yearly",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceSquare"),
        value: "maintenance_rent_per_square",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.changeoverUnit"),
        value: "changeover_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.changeoverContract"),
        value: "changeover_rent_contract",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.separateAllowance"),
        value: "separate_allowances_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.separateContract"),
        value: "separate_allowances_contract",
        format: "Euro",
      },
      // Indexing
      {
        text: i18n.t("rentRoll.reviewType"),
        value: "rent_review_type",
      },
      {
        text: i18n.t("Index type"),
        value: "index_type",
      },
      {
        text: i18n.t("rentRoll.reviewMonth"),
        value: "review_months",
      },
      {
        text: i18n.t("rentRoll.minimumIncrease"),
        value: "minimum_increase",
        format: "Percentage",
      },
      // Discounts
      {
        text: i18n.t("rental_status.discounts"),
        value: "discounts",
        format: "Euro",
      },
      // VAT responsibility
      {
        text: i18n.t("rentRoll.vat_responsibility"),
        value: "vat_status",
      },
      // Unit general information
      {
        text: i18n.t("Unit Status"),
        value: "unitStatus",
      },
      {
        text: i18n.t("Apartment type"),
        value: "apartment_type",
      },
      {
        text: i18n.t("Unit extra info"),
        value: "unit_extra_information",
        hideOverflow: true,
      },
      {
        text: i18n.t("Latest unit note"),
        value: "latest_unit_note",
        hideOverflow: true,
      },
      {
        text: i18n.t("rentRoll.unitVersionStart"),
        value: "unit_start_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.unitVersionEnd"),
        value: "unit_end_date_alert",
        format: "Date",
        leasing: true,
      },
      {
        text: i18n.t("rentRoll.webVisibility"),
        value: "web_visibility",
      },
      // Unit commercial info
      {
        text: i18n.t("rentRoll.marketRentSquare"),
        value: "market_rent_per_square",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.marketRentUnit"),
        value: "market_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.rentTarget"),
        value: "rent_target",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.listRentApartment"),
        value: "list_rent_apartment",
        format: "Euro",
      },
      {
        text: i18n.t("Validity"),
        value: "validity",
      },
      {
        text: i18n.t("rentRoll.clarifiedExpence"),
        value: "clarified_ti_expense",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.estimatedExpence"),
        value: "estimated_ti_expense",
        format: "Euro",
      },
      {
        text: i18n.t("Vacancy rate assumption"),
        value: "vacancy_rate_assumption",
      },
      {
        text: i18n.t("Vacancy rate assumption updated"),
        value: "vacancy_rate_assumption_date",
        format: "Date",
      },
      {
        text: i18n.t("Lease plan"),
        value: "lease_plan",
        hideOverflow: true,
      },
      {
        text: i18n.t("rentRoll.commercialInfoUpdated"),
        value: "commercial_info_date",
        format: "Date",
      },
    ],
    footers: [
      { text: i18n.t("Total") },
      {},
      {},
      {},
      { value: "area", type: "sum", format: "Area" },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {
        value: "contract_duration_left",
        type: "weightedAverage",
        weight: "area",
        format: "Number"
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      { value: "capital_rent_unit", type: "sum", format: "Euro" },
      {},
      { value: "capital_rent_yearly", type: "sum", format: "Euro" },
      { value: "capital_rent_per_square", type: "average", format: "Euro" },
      { value: "maintenance_rent_unit", type: "sum", format: "Euro" },
      { value: "maintenance_rent_yearly", type: "sum", format: "Euro" },
      { value: "maintenance_rent_per_square", type: "average", format: "Euro" },
      { value: "changeover_rent_unit", type: "sum", format: "Euro" },
      {},
      { value: "separate_allowances_unit", type: "sum",  format: "Euro" },
      {},
      {},
      {},
      {},
      {},
      { value: "discounts", type: "sum", format: "Euro" },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      { value: "market_rent_per_square", type: "average", format: "Euro" },
      { value: "market_rent_unit", type: "sum", format: "Euro" },
      { value: "rent_target", type: "average", format: "Euro" },
      { value: "list_rent_apartment", type: "sum", format: "Euro" },
      {},
      { value: "clarified_ti_expense", type: "average", format: "Euro" },
      { value: "estimated_ti_expense", type: "average", format: "Euro" },
      {},
      {}
    ],
    items: [],
    sortDesc: false,
    itemsPerPage: 20,
    dynamicFilterOptions: true,
  },
  maximized: [false],
  loading: true,
  large: true,
})

export const FullRentRollWidget = () => ({
  id: "leasing.fullRentroll",
  type: "ObjectTable",
  data: {
    headers: [
      // General info
      {
        value: 'site_identifier',
        text: i18n.t('Site identifier'),
      },
      {
        text: i18n.t("Site name"),
        value: "site_name",
      },
      {
        text: i18n.t("Commercial name"),
        value: "site_commercial_name",
      },
      {
        text: i18n.t("Building code"),
        value: "building_code",
      },
      {
        text: i18n.t("Floor"),
        value: "floor",
      },
      {
        text: i18n.t("Facility code"),
        value: "facility_code",
      },
      {
        text: i18n.t("Facility code long"),
        value: "facility_code_long",
      },
      {
        text: i18n.t("Facility area"),
        value: "area",
        format: "Area",
      },
      {
        text: i18n.t("Premise type"),
        value: "usage_type",
      },
      {
        text: i18n.t("Rental situation"),
        value: "rental_situation",
      },
      {
        text: i18n.t("Unit Status"),
        value: "unit_status",
        format: "Translation",
      },
      {
        text: i18n.t('Renter'),
        value: 'renter',
      },
      {
        text: i18n.t('Unit version status'),
        value: 'unit_version_status',
      },    
      {
        text: i18n.t("rentRoll.unitVersionStart"),
        value: "unit_start_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.unitVersionEnd"),
        value: "unit_end_date",
        format: "Date",
        leasing: true,
      },
      {
        text: i18n.t("rentRoll.contractNum"),
        value: "contractNumber",
      },
      {
        text: i18n.t("Tenant"),
        value: "tenant_corporation",
      },
      {
        text: i18n.t("Contract party"),
        value: "tenant",
      },
      {
        text: i18n.t("Business ID"),
        value: "business_id",
      },
      {
        text: i18n.t("Tenant category"),
        value: "tenant_category",
      },
      {
        text: i18n.t("Credit rating"),
        value: "credit_rating",
      },
      {
        text: i18n.t("Industry"),
        value: "industry",
      },
      {
        text: i18n.t("leasing.contract_type"),
        value: "contract_type",
      },
      {
        text: i18n.t("Contract area"),
        value: "contract_area",
        format: "Area",
      },
      {
        text: i18n.t("rentRoll.sameContractNumber"),
        value: "contract_area_calculated",
        format: "Area",
      },
      // Rental period info
      {
        text: i18n.t("rentRoll.durationLeft_mm"),
        value: "contract_duration_left_mm",
        format: "Integer",
      },
      {
        text: i18n.t("rentRoll.durationLeft_yy"),
        value: "contract_duration_left_yy",
        format: "NumberTwoDecimals",
      },
      {
        text: i18n.t('Contract date'),
        value: 'signatureDate',
        format: 'Date',
      },
      {
        text: i18n.t("Validity"),
        value: "validity",
      },
      {
        text: i18n.t("rentRoll.contractStart"),
        value: "contract_start_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.contractEnd"),
        value: "contract_end_date",
        format: "Date",
      },
      {
        text: i18n.t("leasing.additional_fixed_term_info"),
        value: "contract_details",
        hideOverflow: true,
      },
      {
        value: 'contractNote',
        text: i18n.t('Contract details'),
        hideOverflow: true,
      },
      {
        text: i18n.t("rentRoll.noticeTenant1"),
        value: "tenant_notice",
        format: "Integer",
      },
      {
        text: i18n.t("rentRoll.noticeLandlord"),
        value: "landlord_notice",
        format: "Integer",
      },
      {
        text: i18n.t("rentRoll.firstEndDate"),
        value: "contract_first_possible_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.tenantFirstEndDate"),
        value: "tenant_first_possible_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.landlordFirstEndDate"),
        value: "landlord_first_possible_end_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.capitalRent_mm"),
        value: "capital_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceRent"),
        value: "maintenance_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceRent_equalization"),
        value: "maintenance_rent_equalization_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.changeoverFacility"),
        value: "changeover_rent_unit",
        format: "Euro",
      },
      {
        value: 'relocationworkleaseStartDate',
        text: i18n.t('rentRoll.relocationWorkStart'),
        format: 'Date',
      },
      {
        value: 'relocationworkleaseEndDate',
        text: i18n.t('rentRoll.relocationWorkEnd'),
        format: 'Date',
      },
      {
        text: i18n.t("rentRoll.facilityMarketinFee"),
        value: "marketing_fee_unit",
      },
      {
        text: i18n.t("rentRoll.facilitySeparateAllowance"),
        value: "separate_allowances_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.facilityDiscounts"),
        value: "discounts",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.discountStart"),
        value: "discountsStartDate",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.discountEnd"),
        value: "discountsEndDate",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.rentalResponsibility"),
        value: "rental_responsibility_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractTurnoverTieup"),
        value: "contractTurnoverTieup",
        format: "Boolean",
      },
      {
        text: i18n.t("Turnover percentage"),
        value: "turnoverPercentage",
        format: "Percentage",
      },
      {
        text: i18n.t("rentRoll.turnoverRelatedInfo"),
        value: "turnoverRelatedInfo",
        hideOverflow: true,
      },
      {
        text: i18n.t("rentRoll.turnoverRentPreviousMonth"),
        value: "turnover_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.facilityOpenBills"),
        value: "open_bills_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.facilityBasicRent"),
        value: "basic_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.facilityCapitalRent"),
        value: "capital_rent_yearly",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceYearly"),
        value: "maintenance_rent_yearly",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.capitalRentSquare"),
        value: "capital_rent_per_square",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.maintenanceSquare"),
        value: "maintenance_rent_per_square",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractCapitalRentMonth"),
        value: "capital_rent_contract",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractMaintenanceRent"),
        value: "contractMaintenanceRent",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractMaintenanceRentEqualization"),
        value: "maintenanceRentEqualization",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractrelocationWorkRent"),
        value: "changeover_rent_contract",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractMarketingFee"),
        value: "marketing_fee_contract",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractSeparateAllowance"),
        value: "separate_allowances_contract",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractDiscounts"),
        value: "contractDiscounts",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractRentalResponsibility"),
        value: "rental_responsibility",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractTurnoverTieupPreviousMonth"),
        value: "contractTurnoverRent",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractOpenBills"),
        value: "contractOpenBills",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.contractBasicRent"),
        value: "contractBasicRent",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.reviewType"),
        value: "rent_review_type",
      },
      {
        text: i18n.t("Index type"),
        value: "index_type",
      },
      {
        text: i18n.t("rental_status.basic_index_point_number"),
        value: "basic_index_point_number",
      },
      {
        text: i18n.t("rentRoll.reviewMonth1"),
        value: "review_months",
      },
      {
        text: i18n.t("rentRoll.lastIndexYear"),
        value: "last_index_year",
      },
      {
        text: i18n.t("rentRoll.lastIndexMonth"),
        value: "last_index_month",
      },
      {
        text: i18n.t("rentRoll.lastIndexValue"),
        value: "last_index_value",
      },
      {
        text: i18n.t("rentRoll.minimumIncrease"),
        value: "minimum_increase",
        format: "Percentage",
      },
      {
        text: i18n.t("rentRoll.fixedIncrease"),
        value: "fixed_increase",
        format: "Percentage",
      },
      {
        text: i18n.t("Review basis"),
        value: "review_basis",
      },
      {
        text: i18n.t("rentRoll.rentReviewStart"),
        value: "rent_review_start_date",
        format: "Date",
      },
      {
        text: i18n.t("Vat status"),
        value: "vat_responsibility",
        format: "Boolean",
      },
      {
        text: i18n.t("Apartment type"),
        value: "apartment_type",
      },
      {
        text: i18n.t("rentRoll.facilityRelatedInfo"),
        value: "facility_extra_information",
      },
      {
        text: i18n.t("Latest unit note"),
        value: "latest_unit_note",
        hideOverflow: true,
      },
      {
        text: i18n.t("rentRoll.webMarketingStatus"),
        value: "web_visibility",
      },
      {
        text: i18n.t("rentRoll.marketRentSquare"),
        value: "market_rent_per_square",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.marketRentUnit"),
        value: "market_rent_unit",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.rentTarget"),
        value: "rent_target",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.listRentApartment"),
        value: "list_rent_apartment",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.clarifiedExpence"),
        value: "clarified_ti_expense",
        format: "Euro",
      },
      {
        text: i18n.t("rentRoll.estimatedExpence"),
        value: "estimated_ti_expense",
        format: "Euro",
      },
      {
        text: i18n.t("Vacancy rate assumption"),
        value: "vacancy_rate_assumption",
      },
      {
        text: i18n.t("Vacancy rate assumption updated"),
        value: "vacancy_rate_assumption_date",
        format: "Date",
      },
      {
        text: i18n.t("Num of car spaces"),
        value: "carparkCount",
        format: "Integer",
      },
      {
        text: i18n.t("Num of parking privileges"),
        value: "parkingPrivileges",
        format: "Integer",
      },
      {
        text: i18n.t("Handicap spot"),
        value: "handicapSpot",
        format: "Translation",
      },
      {
        text: i18n.t("Plug in spot"),
        value: "plugInSpot",
        format: "Translation",
      },
      {
        text: i18n.t("Charge in"),
        value: "chargeIn",
        format: "Translation",
      },
      {
        text: i18n.t("Lease plan"),
        value: "lease_plan",
      },
      {
        text: i18n.t("Lease_plan_updated"),
        value: "lease_plan_date",
        format: "Date",
      },
      {
        text: i18n.t("rentRoll.commercialInfoUpdated"),
        value: "commercial_info_date",
        format: "Date",
      },
      {
        text: i18n.t("last_contract_end_date"),
        value: "last_contract_end_date",
        format: "Date",
      },
      {
        text: i18n.t("Empty days"),
        value: "empty_days",
        format: "Integer",
      },
      {
        text: i18n.t("Last tenant"),
        value: "last_tenant",
        format: "Translation",
      },
      {
        text: i18n.t("Advertising phrase"),
        value: "advertising_phrase",
      },
      {
        text: i18n.t("rentRoll.rentalprocessstage1"),
        value: "rental_processes_stage1",
      },
      {
        text: i18n.t("rentRoll.rentalprocessstage2-5"),
        value: "rental_processes_stage2_5",
      },
      {
        text: i18n.t("rentRoll.rentalprocessstage6-7"),
        value: "rental_processes_stage6_7",
      },
      {
        text: i18n.t("Estate identifier"),
        value: "estate_identifier",
      },
      {
        text: i18n.t("Completed year"),
        value: "completed_year",
      },
      {
        text: i18n.t("Last improvement year"),
        value: "last_improvement_year",
      },
      {
        text: i18n.t("Evaluator"),
        value: "evaluator",
      }
    ],
    footers: [
      { value: "area", type: "sum", format: "Area" },
      { value: "contract_area", type: "sum", format: "Area" },
      { value: "contract_area_calculated", type: "sum", format: "Area" },
      { value: "contract_duration_left_mm", type: "average", format: "NumberTwoDecimals"},
      { value: "contract_duration_left_yy", type: "average", format: "NumberTwoDecimals" },
      { value: "tenant_notice", type: "average", format: "NumberTwoDecimals" },
      { value: "landlord_notice", type: "average", format: "NumberTwoDecimals" },
      { value: "capital_rent_unit", type: "sum", format: "Euro" },
      { value: "maintenance_rent_unit", type: "sum", format: "Euro" },
      { value: "maintenance_rent_equalization_unit", type: "sum", format: "Euro" },
      { value: "changeover_rent_unit", type: "sum", format: "Euro" },
      { value: "marketing_fee_unit", type: "sum", format: "Euro" },
      { value: "separate_allowances_unit", type: "sum", format: "Euro"},
      { value: "discounts", type: "sum", format: "Euro" },
      { value: "rental_responsibility_unit", type: "sum", format: "Euro" },
      { value: "turnover_rent_previous_month", type: "sum", format: "Euro" },
      { value: "open_bills_unit", type: "sum", format: "Euro" },
      { value: "basic_rent_unit", type: "sum", format: "Euro" },
      { value: "capital_rent_unit", type: "sum", format: "Euro" },
      { value: "capital_rent_yearly", type: "sum", format: "Euro" },
      { value: "maintenance_rent_yearly", type: "sum", format: "Euro" },
      { value: "capital_rent_per_square", type: "average", format: "Euro" },
      { value: "maintenance_rent_per_square", type: "average", format: "Euro" },
      { value: "minimum_increase", type: "average", format: "Percentage"},
      { value: "fixed_increase", type: "average", format: "Percentage" },
      { value: "market_rent_per_square", type: "average", format: "Euro" },
      { value: "market_rent_unit", type: "sum", format: "Euro" },
      { value: "rent_target", type: "average", format: "Euro"},
      { value: "list_rent_apartment", type: "sum", format: "Euro" },
      { value: "clarified_ti_expense", type: "average", format: "Euro" },
      { value: "estimated_ti_expense" , type: "average", format: "Euro" },
      { value: "carparkCount", type: "sum", format: "Number" },
      { value: "parkingPrivileges", type: "sum", format: "Number" },
      { value: "empty_days", type: "average", format: "Number" },
    ],
    items: [],
    sortDesc: false,
    itemsPerPage: 20,
    dynamicFilterOptions: true,
    hideTotalRow: true,
  },
  maximized: [false],
  loading: true,
  large: true,
})