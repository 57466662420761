<template>
  <v-btn
    :href="to"
    rounded
    depressed
    color="primary"
    class="skip-to"
  >
    {{ label }}
  </v-btn>
</template>
<script>
  export default {
    name: 'SkipTo',
    props: {
      to: { 
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      }
    },
  }
</script>
<style scoped>
  .skip-to {
    position: absolute;
    left: 0;
    top: var(--r-spacing-m);
    z-index: 1;
    transform: translateX(-150%);
    transition: transform 0.25s;
  }
  .skip-to:focus {
    transform: translateX(0.5rem);
  }
</style>
