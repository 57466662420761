<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('DisableAutomaticBilling') }}
    </template>
    <template #content>
      <v-textarea
        v-model="automaticBillingReasoning"
        class="form-fields"
        rows="2"
        :label="$t('create_rent_contract.automatic_billing_reasoning')"
      />
    </template>
    <template #footer>
      <v-btn
        class="primary"
        text
        rounded
        outlined
        depressed
        @click="save"
      >
        {{ $t('Save') }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../general/BaseModal'

export default {
  name: "DisableAutomaticBillingModal",
  components: {
    BaseModal,
  },
  props: {
    contractId: {
      type: Number,
      default: undefined
    },
    invoiceId: {
      type: Number,
      default: undefined
    },
  },
  data: function () {
    return {
      automaticBillingReasoning: ""
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      const contract = {
        id: this.contractId,
        automaticBillingAllowed: true,
        automaticBillingReasoning: this.automaticBillingReasoning,
      }
      if (!contract.id) {
        const invoiceIds = [this.invoiceId]
        await this.$rambollfmapi.invoices.blockInvoices(invoiceIds)
      } else {
        await this.$rambollfmapi.contracts.contract.updateAutomaticBilling(contract)
      }
      this.$emit('refreshData')
      this.close()
    },
  },
}
</script>
<style scoped>
.v-expansion-panels {
  border: none;
}
</style>