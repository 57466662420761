<template>
  <v-row
    v-if="!browse"
    align="center"
    justify="center"
    no-gutters
  >
    <v-tooltip
      right
      color="black"
      :disabled="header.noDescription"
    >
      <template #activator="{on, attrs}">
        <v-icon 
          v-if="icon !== null"
          x-large 
          :color="icon.color"
          v-bind="attrs"
          v-on="on"
        >
          {{ icon.icon }}
        </v-icon>
      </template>
      <span>{{ description }}</span>
    </v-tooltip>
  </v-row>
  <v-row
    v-else
    align="center"
  >
    <v-col
      cols="9"
      class="linked px-0"
    >
      {{ formatData(props.item, header.value, header.format) }}
    </v-col>
    <v-col
      v-if="icon !== null"
      cols="3"
      class="px-0 text-left"
    >
      <v-icon 
        :color="icon.color"
      >
        {{ icon.icon }}
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../../../helpers'

export default {
  name: 'RentalSituationColumn',
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
    item: { type: Object, default: null },
    browse: { type: Boolean, default: false}
  },
  computed: {
    icon: function () {
      const { value } = this.header
      const item = this.item

      const col = item[value]

      if (col === 'contract_concluding') {
        return { icon: 'error', color: 'warning' }
      }
      else if (col === 'rented') {
        return { icon: 'check_circle', color: 'success' }
      } else if (col === 'in_process') {
        return { icon: 'error', color: 'yellow' }
      } else if (col === 'free_over_100') {
        return { icon: 'error', color: 'error' }
      } else if (col === 'free_under_100') {
        return { icon: 'error', color: '#FFC0CB' } // error
      }
      return null
    },
    description: function () {
      const { value } = this.header
      const item = this.item

      const col = item[value]

      if(col){
        return this.$i18n.t(col + "_description")
      }

      return ""
    }
  },
  methods: {
    formatData (row, header, format) {
      return helpers.format.formatData(this.$i18n.t(row[header]), format)
    },
  },
}
/**
    <v-col
      xs8
      class="px-0"
    >
      {{ formatData(props.item, header.value, header.format) }}
    </v-col>

 */
</script>

<style scoped></style>
