<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    data: function () {
      this.update()
    }
  },
  mounted () {
    // Overwriting base render method with actual data.

    this.allOptions = {
      ...this.options
    }

    this.update()
  },
  methods: {
    update () {
      const dataLabels = this.data.xLabels.map(d => {
        return this.$t(d)
      })
      this.renderChart({ datasets: this.data.datasets, xLabels: dataLabels, yLabels: this.data.yLabels }, this.allOptions)
    }
  }
}
</script>
