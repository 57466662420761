!<template>
  <div>
    <v-row
      v-if="shownNotifications.length > 0 && !loading"
      class="flex-column"
      align="center"
      justify="center"
    >
      <v-col class="px-0 pb-0 pt-3 full-width">
        <v-slide-y-transition group>
          <Notification
            v-for="notification in shownNotifications"
            :key="notification.id ? notification.id : notification.id_site + '_' + notification.id_unit"
            :notification="notification"
            @remove="removeNotification"
          />
        </v-slide-y-transition>  
      </v-col>
      <v-col v-if="notifications.length > 3">
        <v-btn
          v-if="shownNotificationAmount < notifications.length"
          outlined
          rounded
          text
          class="ma-0"
          @click="showAllNotifications()"
        >
          {{ $t('Show') + " " + (notifications.length - shownNotificationAmount) + " " + $t(showMoreNotificationString) }}
        </v-btn>
        <v-btn
          v-else
          outlined
          rounded
          text
          @click="resetNotificationsAmount"
        >
          {{ $t('Show less') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Notification from './Notification'

export default {
    name: 'NotificationList',
    components: { Notification },
    props: {
        notifications: {type: Array, default: () => []},
        loading: {type: Boolean, default: false}
    },
    data () {
        return {
            shownNotificationAmount: 3,

        }
    },
    computed: {
        shownNotifications () {
          return this.notifications.slice(0, this.shownNotificationAmount)
        },
        showMoreNotificationString () {
          if (this.notifications.length - this.shownNotificationAmount === 1) {
            return 'leasing.one_more_notification'
          }
          else {
            return 'leasing.more_notifications'
          }
        }
    },
    methods: {
        ...mapActions('leasing', ['deleteUserNotification']),
        removeNotification (notificationId, siteId, unitId, eventType) {
          this.deleteUserNotification({ notificationId, siteId, unitId, eventType })
        },
        showAllNotifications () {
          this.shownNotificationAmount = this.notifications.length
        },
        resetNotificationsAmount ()  {
          this.shownNotificationAmount = 3
        }
    }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>