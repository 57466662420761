/* eslint-disable quote-props */
export default {
  // viewer controls
  'BRANDI_TEKSTI': {
    en: 'Brand text',
    fi: 'Brändi teksti'
  },
  'KAYTTOTARKOITUKSET': {
    en: 'Purposes of use',
    fi: 'Käyttötarkoitukset'
  },
  'AUTOPAIKAT_KH': {
    en: 'Parking spaces (floor)',
    fi: 'Autopaikat (KH-kuva)'
  },
  'AUTOPAIKAT_A': {
    en: 'Parking spaces (plan)',
    fi: 'Autopaikat (Asema)'
  },
  'TURVA_MERK': {
    en: 'Safety markings',
    fi: 'Turvallisuusmerkinnät'
  },
  'KALUSTEET': {
    fi: 'Furniture',
    en: 'Kalusteet'
  },
  'PALO_OSASTOT': {
    en: 'Fire sections',
    fi: 'Palo-osastot'
  },
  'KUSTANNUSPAIKAT_HSTO': {
    en: 'Cost centres (unit)',
    fi: 'Kustannuspaikat (hsto)'
  },
  'KUSTANNUSPAIKAT_HUONE': {
    en: 'Cost centres (room)',
    fi: 'Kustannuspaikat (huone)'
  },
  'AUTOPAIKAT_HLO': {
    en: 'Parking spaces (pers.)',
    fi: 'Autopaikat (Henkilö)'
  },
  'KWHALUEET': {
    en: 'kWh-areas',
    fi: 'kWh-alueet'
  },
  'VUOKRALAISET_HUONE': {
    en: 'Tenants/room',
    fi: 'Vuokralaiset/huone'
  },
  'VUOKRALAISET_HSTO': {
    en: 'Tenants/leased area',
    fi: 'Vuokralaiset/vuokra,-ala'
  },
  'HENKILOT': {
    en: 'Persons',
    fi: 'Henkilöt'
  },
  'RYHMITTELY_VALITTU': {
    en: 'Select layer to be hatched',
    fi: 'Valitse rasteroitava taso'
  },
  'RASTERIRAJA': {
    en: 'Boundary line',
    fi: 'Tilaraja'
  },
  'PIILOTAHUONETUNNISTE': {
    en: 'Room numbers',
    fi: 'Huonetunnisteet'
  },
   'TTNRO': {
    en: 'Contract number (SAP)',
    fi: 'Contract number (SAP)'
  }
}
