<template>
  <div>
    <v-tab-item
      :key="8"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <ValidationObserver ref="observer">
          <h3 class="form-headers">
            {{ $t('create_rent_contract.conditions') }}
          </h3>

          <ValidationProvider
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="templateId"
              :items="contractConditionsTemplates"
              item-text="name"
              item-value="id"
              class="form-fields ml-2 mr-2"
              :label="$t('create_rent_contract.contract_conditions_templates')"
              @change="onTemplateChanged()"
            />
            <span
              class="validation-error ml-2"
            >
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :rules="`${isOutRent ? 'required' : ''}`"
          >
            <v-textarea
              v-model="text"
              class="form-fields ml-2 mr-2"
              :aria-label="$t('create_rent_contract.contract_conditions')"
              rows="12"
              required
              :hint="$t('Required')"
              persistent-hint
              :error-messages="errors[0]"
              @change="onDataChanged()"
            />
          </ValidationProvider>
        </ValidationObserver>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: "RentContractConditionsTab",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    },
    contractConditions: {
      type: Object,
      default: () => undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    }
  },
  data () {
    return (
      {
        id: undefined,
        templateId: undefined,
        text: undefined,
        isPageValid: false,
        requiredField: [
          v => v != undefined && v.trim() != ""
        ]
      }
    )
  },
  computed: {
    ...mapGetters('app', ['contractConditionsTemplates'])
  },
  watch: {
    async isActive () {
      if (Object.keys(this.$refs).length > 0) {
        this.isPageValid = await this.$refs.observer.validate()
        this.$emit("isPageValidChanged", this.isPageValid)
      }
    },
    contractConditions () {
      if (this.contractConditions.id) {
        this.updateFromExistingConditions()
      }
    }
  },
  methods: {
    getTemplateText () {
      if (!this.templateId) {
        return null
      }
      return this.contractConditionsTemplates
        .filter((template) => template.id === this.templateId)?.[0].text
    },
    updateFromExistingConditions () {
      this.id = this.contractConditions.id
      this.templateId = this.contractConditions.templateId
      this.text = this.contractConditions.text ?? this.getTemplateText()
    },
    async onTemplateChanged () {
      this.text = this.getTemplateText()

      const data = {
        id: this.id,
        templateId: this.templateId,
        text: this.getTemplateText()
      }

      this.$emit('updated', data)
    },
    async onDataChanged () {

      const data = {
        id: this.id,
        templateId: this.templateId,
        text: this.text,
      }

      this.$emit('updated', data)
    },
  }
}
</script>

<style scoped>
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
</style>