import { i18n } from '../plugins/i18n'

export default {
  types () {
    return [
      { id: 0, title: 'Ramboll order', icon: 'border_style', addPermission: 'RAMBOLL_TILAUS', listPermission: 'RAMBOLL_TILAUKSET' },
      { id: 1, title: 'System defect', icon: 'report_problem', addPermission: 'RAMBOLL_TILAUS', listPermission: 'RAMBOLL_TILAUKSET' },
      { id: 2, title: 'Lost password', icon: 'security', addPermission: 'RAMBOLL_TILAUS', listPermission: 'RAMBOLL_TILAUKSET' },
      { id: 3, title: 'Zoan order', icon: 'perm_media', addPermission: 'ZOAN_TILAUS', listPermission: 'ZOAN_TILAUKSET' }
    ]
  },
  deliveryFormats () {
    return ['pdf', 'powerpoint', i18n.t('zoan.web_site')]
  }
}
