import RambollFMAPI from '../api/rambollfm'

export default {
  namespaced: true,
  state: {
    quarterlyOpenBills: [],
    quarterlyOpenBillsLoading: false,
  },
  getters: {
  },
  mutations: {
    setQuarterlyOpenBills (state, openBills) {
      state.quarterlyOpenBills = openBills
      state.quarterlyOpenBillsLoading = false
    },
    setQuarterlyOpenBillsLoading (state, loading) {
      state.quarterlyOpenBillsLoading = loading
    },
  },
  actions: {
    async fetchQuarterlyOpenBills ({ commit, rootState }) {

      const { app: { currentDate, apiEndpoint, apiToken, buildingIds: { buildings } } } = rootState

      commit('setQuarterlyOpenBillsLoading', true)

      const api = new RambollFMAPI(apiEndpoint, apiToken)
      const quarterlyOpenBills = await api.reports.post('quarterlyopenbills', buildings, currentDate)

      commit('setQuarterlyOpenBills', quarterlyOpenBills)
    }
  },
}