<template>
  <div>
    <v-dialog
      v-model="exportMessage"
      width="550px"
      modal
    >
      <v-card>
        <v-card-title
          :class="titleClass"
          class="text-h5"
        >
          {{ $t('fm_export.export_to_fm') }}
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 1em"
            @click="exportMessage = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
        </v-card-title>

        <v-card-text>{{ exportResultText }}</v-card-text>
      </v-card>
    </v-dialog>
    <v-tooltip
      :disabled="!tooltip"
      right
    >
      <v-btn
        slot="activator"
        :disabled="disabled"
        :loading="loading"
        @click="sendDataFM()"
      >
        {{ $t('fm_export.export_to_fm') }}
        <v-icon v-show="icon">
          {{ icon }}
        </v-icon>
      </v-btn>

      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import RambollFMAPIv2 from '../../api/rambollfmv2'
import VueCookies from 'vue-cookies'

export default {
  name: 'ExportTenantToFm',
  props: {
    buildingCode: String,
    area: Number,
    tenant: String,
    startDate: String,
    endDate: String,
    linkId: Number
  },
  data: function () {
    return {
      endpoint: process.env.VUE_APP_RAMBOLLFMV2_ENDPOINT,
      token: VueCookies.get('token'),
      exportMessage: false,
      exportResultText: '',
      titleClass: {
        exportError: false
      },
      disabled: false,
      loading: false,
      tooltip: '',
      icon: null
    }
  },

  watch: {
    linkId: function (val) {
      this.toggleButtonByLinkExist()
    }
  },
  mounted: function () {
    this.toggleButtonByLinkExist()
  },
  methods: {
    async sendDataFM () {
      this.disabled = true
      this.loading = true
      var lease = {
        area: this.area,
        tenant_name: this.tenant,
        start_date: this.startDate,
        end_date: this.endDate,
        building_code: this.buildingCode,
        circle_id: this.linkId
      }

      var api = new RambollFMAPIv2(this.endpoint, this.token)

      var loginResult = await api.accounts.login(this.token)

      if (loginResult.data !== undefined) {
        const sessionId = loginResult.data.session_id
        try {
          var result = await api.rent.save(sessionId, lease)
          if (result.status === 200) {
            this.showMessage(false, this.$t('fm_export.export_to_fm_success'))
          } else {
            this.showMessage(
              true,
              `${this.$t('fm_export.export_to_fm_failed')} "${
                result.response.data.message
              }"`
            )
          }
        } catch (error) {
          this.showMessage(
            true,
            `${this.$t('fm_export.export_to_fm_failed')} ${error.message}`
          )
        }
      } else {
        this.showMessage(true, this.$t('fm_export.export_to_fm_failed'))
      }
      this.loading = false
    },

    async loginToFM () {
      var api = new RambollFMAPIv2(this.endpoint, this.token)
      await api.accounts
        .login(this.token)
        .then(result => {
          this.fmSessionId = result.data.session_id
        })
        .catch(err => {
          this.loginFailed()
          throw err
        })
    },
    loginFailed () {
      this.loading = false
      this.disabled = true
      this.icon = 'info'
      this.tooltip = this.$t('fm_export.login_failed')
    },
    showMessage (isError, message) {
      this.titleClass.error = isError
      this.exportMessage = true
      this.exportResultText = message
    },
    async linkExistsInFm () {
      this.loading = true
      if (!this.fmSessionId) {
        await this.loginToFM()
      }
      if (this.fmSessionId) {
        var api = new RambollFMAPIv2(this.endpoint, this.token)
        const result = await api.rent
          .getWithLinkId(this.fmSessionId, this.linkId)
          .catch(function () {
            this.loginFailed()
          })
        this.loading = false
        return result.data
      }
    },
    async toggleButtonByLinkExist () {
      this.icon = ''
      var linkExist = await this.linkExistsInFm()
      this.disabled = linkExist
      if (linkExist) {
        this.tooltip = this.$t('fm_export.lease_already_exists')
      } else {
        this.tooltip = ''
      }
    }
  }
}
</script>
<style scoped>
.exportError {
  background-color: red;
}
</style>
