<template>
  <BaseModal
    @cancel="emit"
  >
    <template #title>
      {{ $t('Modify temporary permit validity (building)') + ' ' + building.building_name }}
    </template>
    <template #content>
      <v-form ref="permissionValidityForm">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h6 mb-1">
              {{ $t('Permission definition') + ":" }}
            </v-list-item-title>
            <div>
              <v-textarea
                v-model="permissionValidityDesc"
                :label="$t('Permission definition')"
                name="permissionValidityDesc"
                class="form-fields"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-row>
              <v-col>
                <v-list-item-title class="text-h6 mb-1">
                  {{ $t('Temporary permit validity') + ":" }}
                </v-list-item-title>
                <DatePicker
                  :label="$t('Temporary permit validity')"
                  :rules="'required'"
                  :initial-date="permissionValidityDate"
                  class="form-fields mt-4 ml-2 mb-2"
                  @dateupdated="permissionValidityDateUpdated"
                />
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        class="primary"
        @click="editBuildingPermissionValidity"
      >
        <span v-if="!saving">{{ $t('Save') }}</span>
        <v-progress-circular
          v-else
          :size="20"
          :width="3"
          indeterminate
          color="white"
        />
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import { mapState } from "vuex"
import BaseModal from '../general/BaseModal.vue'
import DatePicker from '../general/DatePicker.vue'

export default {
  name: 'ModifyBuildingPermissionValidityModal',
  components: {
    BaseModal,
    DatePicker
  },
  props: {
    building: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      idBuilding: this.building.id_building,
      permissionValidityDesc: this.building.temporary_permit_desc,
      permissionValidityDate: this.building.temporary_permit_validity,
      text: null,
      saving: false,
      valid: true
    }
  },
  computed: {
    ...mapState("app", ["currentDate"]),
  },
  methods: {
    emit () {
      if (confirm(this.$t('marketing.close_confirmation'))) {
        this.$emit('close')
      }
    },
    permissionValidityDateUpdated (value) {
      this.permissionValidityDate = value
    },
    async editBuildingPermissionValidity () {
      this.saving = true
      const building = {
        buildingCode: this.building.building_code,
        permissionValidityDesc: this.permissionValidityDesc,
        permissionValidityDate: this.permissionValidityDate,
      }
      const patch = []
      if (building.buildingCode) {
            patch.push({
            op: 'replace',
            path: '/temporary_permit_desc',
            value: this.permissionValidityDesc
          },
          {
            op: 'replace',
            path: '/temporary_permit_validity',
            value: this.permissionValidityDate
          })

        await this.$rambollfmapi.buildings.patch(building.buildingCode, this.currentDate, patch)
      } 
      this.saving = false
      this.$emit('updateWidget')
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.v-list-item__content {
  overflow: inherit;
}
</style>
