<template>
  <v-dialog
    v-model="addSpaceDialog"
    persistent
    max-width="550"
  >
    <AriaLive />
    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ $t('Create space') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-select
            v-model="addSpace.idBuilding"
            class="form-field"
            :rules="[v => !!v || $t('Required')]"
            :label="$t('Building')"
            :hint="$t('Required')"
            persistent-hint
            item-text="building_name"
            item-value="id_building"
            :items="filteredBuildings"
            required
          />
          <v-select
            v-model="addSpace.idFloor"
            :disabled="!(floorsForCurrentBuilding && floorsForCurrentBuilding.length)"
            :rules="[v => !!v || $t('Required')]"
            class="form-field"
            :label="$t('Floor')"
            :hint="$t('Required')"
            persistent-hint
            item-text="floor_name"
            item-value="id"
            :items="floorsForCurrentBuilding"
            required
          />
          <v-text-field
            v-model="addSpace.idSpace"
            :rules="[v => !!v || $t('Required')]"
            :label="$t('Space number')"
            :hint="$t('Required')"
            persistent-hint
            class="form-field"
            required
          />
          <SpaceDataDialog
            :metadata="spaceMetaData"
            :data="saveData"
          />
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="!valid"
          depressed
          rounded
          color="primary"
          @click="saveSpace()"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="disabled === false"
        small
        outlined
        rounded
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('Create space') }}
      </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import SpaceDataDialog from './SpaceDataDialog'
import BaseModal from './general/BaseModal'
import AriaLive from './AriaLive.vue'

  export default {
      components: { 
        SpaceDataDialog, 
        BaseModal, 
        AriaLive
      },
    data () {
        return {
            disabled: true,
            loading: false,
            saving: false,
            valid: true,
            addSpaceDialog: false,
            currentSpace: {},
            addSpace: {
                idBuilding: null,
                idSpace: null,
            },
            saveData: {
                building_code: '',
                name: '',

            },
            filteredBuildings: []
        }
    },
    
    computed: {
    ...mapState('app', ['currentDate', 'spaceMetaData', 'purposeZones', 'currentDate']),
    ...mapState('internalRent', ['purposesOfUse']),
    ...mapGetters('app', ['spaceMetaData', 'hasApplicationPermissionByName']),
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite', 'floorsForCurrentBuilding', 'unitsForCurrentBuildingFloor',])

     },
     
    watch: {
    'addSpace.idBuilding': async function (selectedBuildingId) {
      if(this.addSpaceDialog === false){
        this.addSpace.idBuilding = null
        return
      }
      if(selectedBuildingId!=='') {
        this.addSpace.idFloor = null
        this.addSpace.idUnit = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
        const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === selectedBuildingId).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
      }
    },
    'addSpace.idFloor': function (selectedFloorId) {
      if(this.selectedFloorId!=='') {
        this.addSpace.idUnit = null
        this.setUnitsForCurrentBuildingFloor([])
        this.loadUnitsForCurrentBuildingFloor(selectedFloorId)
      }
    }
  },
    mounted: async function () {
        if( this.hasApplicationPermissionByName('HUONEEN_LISAYS') ) 
        {
           this.disabled = false
        }
        await this.getspaceMetaData()
        await this.getPurposeZones()
        await this.getPurposes()
        this.filteredBuildings = this.buildingsForCurrentSite.filter(building => building.has_dwg === false)
        
    },
    methods: {
     
    ...mapActions('app', ['getspaceMetaData', 'getPurposeZones']),
    ...mapActions('sites', ['loadFloorsForCurrentBuilding', 'loadUnitsForCurrentBuildingFloor']),
    ...mapActions('internalRent', ['getPurposes']),
    ...mapMutations('sites', ['setFloorsForCurrentBuilding', 'setUnitsForCurrentBuildingFloor']),

    cancel () {
      this.addSpace.idFloor = undefined
      this.addSpaceDialog = false
      this.$refs.form.reset()
    },
     clearForm () {
      this.$refs.form.resetValidation()
      this.addSpace.idBuilding=''
      this.addSpace.idSpace=''
      this.saveData = {}
    },
    saveSpace ()
    {
        if (this.saving) {
            return
        }
        this.saveData.building_code = this.addSpace.idBuilding
        this.saveData.id_floor = this.addSpace.idFloor
        this.saveData.name = this.addSpace.idSpace
        this.saveData.start_date = this.currentDate
       
         if (this.$refs.form.validate()) {
            const spaceToSave = JSON.parse(JSON.stringify(this.saveData))
            if (spaceToSave.start_date !== null) {
                 spaceToSave.start_date = new Date(this.currentDate.setUTCHours(0,0,0,0))
            }
            this.saving = true
            this.$rambollfmapi.spaces
                .post(spaceToSave)
                .then(res => {
                    this.addSpaceDialog = false
                    this.clearForm()
                    this.saving = false
                    this.$emit('saved', true)
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
              this.$store.dispatch('app/addAriaLive', this.$t('aria_live.space_added_window_closed'))
        }
    }
  }
}
</script>
<style scoped>
</style>
