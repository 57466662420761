<template>
  <div>
    <div
      v-if="isFixedTerm" 
      class="info-text"
    >
      <p>
        {{ $t("leasing.confirmed_negative_info_text_1") }}
      </p>
    </div>
    <div 
      v-else
      class="info-text"
    >
      <p>
        {{ $t("leasing.confirmed_negative_info_text_2") }}
      </p>
    </div>
    <div class="info-text">
      <p>
        {{ $t("leasing.confirmed_negative_info_text_3") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmedPositive',
  props: {
    isFixedTerm: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.info-text {
  padding-top: 5px;
  padding-bottom: 5px;
}
p {
  font-style: italic;
}
</style>
