<template>
  <div>
    <v-dialog
      v-model="hasError"
      max-width="550"
      persistent
    >
      <v-alert
        :value="hasError"
        type="error"
        dismissible
        @click="closeError"
      >
        {{ $t('Upload failed') + ' ' + uploadErrors.length + ' ' + $t('of the files') }}
        <!-- list grouped collected errors -->
        <div
          v-for="error in distinctErrors"
          :key="error"
        >
          <br>
          {{ error }}
          <br>
          <div
            v-for="file in filesWithError(error)"
            :key="file"
          >
            {{ file ? file : '' }}
          </div>
        </div>
        <!-- end of error listing -->
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="photoUploadDialogVisible"
      persistent
      max-width="550"
    >
      <PhotoUploadForm
        v-if="photoUploadDialogVisible"
        :editing="selectedPhoto !== null"
        :data="selectedPhoto"
        @updatePhotoList="addOrUpdatePhoto"
        @close="closeForm"
      />
    </v-dialog>
    <v-dialog
      v-model="photoChoosesDialogVisible"
      persistent
      @keydown.esc="closeForm"   
    >
      <div :style="choosesColStyle">
        <PhotoChoosesForm
          v-if="photoChoosesDialogVisible"
          :editing="selectedPhoto !== null"
          :data="selectedPhoto"
          :files="files"
          :quality="getPhotoQuality(selectedPhoto.size)"
          @updatePhotoList="addOrUpdatePhoto"
          @close="closeForm"
        />
      </div>
    </v-dialog>    

    <v-dialog
      v-model="photoPreViewerVisible"
      persistent
      max-width="80vw"
    >
      <PhotoPreViewer
        v-if="photoPreViewerVisible"
        :photos="photosFromSelectedCategory"
        :has-write-permission="hasWritePermission"
        :is-loading="isRetrievingFile"
        :photo-loading-error="photoLoadingError"
        :photo-idx="selectedPhotoIdx"
        @close="closePhotoPreViewer"
        @download="downloadPreviewerPhoto"
        @edit="edit"
        @remove="remove"
        @changeOrLoad="changeOrLoadPhoto"
      />
    </v-dialog>
    
    <v-row
      class="px-4 align-center"
    >
      <v-col 
        md="4"
      >
        <h1 id="site-content">
          {{ $t('Photos') }}
        </h1>
        <p>
          {{ $t('Photos warning') }}
        </p>
      </v-col>
      <v-col 
        md="8"
      >
        <v-row>
          <v-select
            v-model="selectedPhotoFileType"
            :items="photoFileTypes"
            :placeholder="$t('photos.all_types')"
            style="min-width:200px;"
            class="mr-4"
            return-object
          />
          <v-text-field
            v-model="searchValue"
            color="primary"
            single-line
            hide-details
            clearable
            append-icon="search"
            style="min-width:200px;"
            class="mr-4"
            :placeholder="$t('Search...')"
          />
          <v-btn
            outlined
            large
            icon
            color="primary"
          >
            <v-icon>refresh</v-icon>
            <span class="d-sr-only">{{ $t('Refresh') }}</span>
          </v-btn>
          <v-btn
            v-if="hasWritePermission"
            outlined
            large
            icon
            color="primary"
            @click="photoUploadDialogVisible = true"
          >
            <v-icon>add</v-icon>
            <span class="d-sr-only">{{ $t('AddPhoto') }}</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <div
      v-if="isLoading"
      style="text-align: center; padding: 2em;"
    >
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
    <!-- List of uploads -->
    <div
      class="doc-table"
      style="margin-top: 1rem"
    >
      <!-- rows -->
      <div
        v-for="(sec, sectionIndex) in photoSections"
        :key="sec.id"
        :style="{'padding-top': sec.id === carbageSectionId ? '2em' : ''}"
        style="padding: 0.5em 1em" 
        @dragover="hasWritePermission && dragover($event)"
        @dragleave="hasWritePermission && dragleave($event)"
        @drop="hasWritePermission && drop($event, sec.id)"
      >
        <input
          v-if="hasWritePermission"
          ref="file" 
          type="file"
          accept=".jpeg, .jpg, .tif, .gif, .png, .bmp"
          style="display: none"
          @change="onChange"
        >
        <v-expansion-panels
          :value="[sectionPhotos(sec.id).length ? 0 : null]"
          multiple
        >
          <v-expansion-panel
            class="no-padding"
          >
            <v-expansion-panel-header>
              <v-row
                align="center"
                justify="start"
                no-gutters
              >
                <v-icon v-if="sec.id === carbageSectionId">
                  delete
                </v-icon>
                {{ $t(sec.label) }}  
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-if="isLoading"
                style="text-align: center; padding: 2em;"
              >
                <v-progress-circular
                  size="64"
                  indeterminate
                  color="primary"
                />
              </div>
              <v-data-table
                v-else
                :header-props="dataTableHeaderDefinitions"
                :headers="headers"
                :items="sectionPhotos(sec.id)"
                class="elevation-0 scrollable"
                mobile-breakpoint="0"
                :options.sync="options[sectionIndex]"
                :footer-props="{
                  itemsPerPageOptions: [],
                }"
              >
                <!-- viewsize-scroll -->
                <template
                  slot="item"
                  slot-scope="props"
                >
                  <tr             
                    :key="props.item.idPhoto"
                    :draggable="hasWritePermission"
                    @dragstart="startDrag($event, props.item.idPhoto, sec.id)"
                  >
                    <td>
                      <v-img
                        v-if="thumbsUp[props.item.idPhoto]"
                        :src="props.item.photoThumbUrl"
                        contain
                        max-height="15vh"
                        max-width="10vw"
                        @click="openPhotoPreViewer(props.item, sec.id, sectionIndex)"
                      />
                      <div
                        v-else
                        style="text-align: center; width: 75%"
                      >
                        <v-progress-circular                    
                          size="64"
                          indeterminate
                          color="primary"
                        />
                      </div>
                    </td>
                    <td>
                      {{ props.item.filename }}
                    </td>
                    <td>
                      {{ getHumanReadableDate(props.item.uploaded) }}
                    </td>
                    <td>
                      {{ getPhotoSize(props.item.size) }}
                    </td>
                    <td>
                      {{ $t(`photos.quality_${getPhotoQuality(props.item.size)}`) }}
                    </td>
                    <td>
                      <v-row
                        class="justify-content-center flex-column"
                        align="center"
                      >
                        <v-tooltip
                          right
                          color="red"
                          :disabled="!(!props.item.inSite && activeSwitchIds.length >= maxPhotosCount)"
                        >
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-switch
                                v-model="props.item.inSite"
                                :aria-label="$t('photos.in_targetpage')"
                                :disabled="sec.id === damagesSectionId || sec.id === carbageSectionId || isDisabled(props.item.idPhoto)"
                                @change="changePhotoinSite(props.item)"
                              />
                            </div>
                          </template>
                          <span v-if="isDisabled(props.item.photoId)">{{ $t('Photos in site restriction') }}</span>
                        </v-tooltip>
                      </v-row>
                    </td>
                    <td 
                      v-if="userHasRentalPrintoutRights"
                      class="doc-tags"
                    >
                      <div
                        :id="'choosescol' + props.item.idPhoto"
                      >                      
                        <v-chip
                          v-for="imgKey in selectionChips(props.item)"
                          :key="imgKey + props.item.idPhoto"
                          small
                          outlined
                        >
                          {{ $t('photos.' + imgKey) }}
                        </v-chip>
                        <v-btn
                          v-if="hasWritePermission && sec.id !== damagesSectionId && sec.id !== carbageSectionId"
                          icon
                          @click="editChooses(props.item.idPhoto)"
                        >
                          <v-icon>edit</v-icon>
                          <span class="d-sr-only">{{ $t('Modify') }}</span>
                        </v-btn>
                      </div>
                    </td>
                    <td>
                      {{ props.item.unitName === null ? getLinkingText(props.item) : props.item.unitName }}
                    </td>
                    <td class="doc-tags">
                      <div
                        style="display: block; max-width: 100%"
                      >
                        <v-chip
                          v-for="tag in props.item.tags"
                          :key="tag.id_tag"
                          small
                          outlined
                        >
                          {{ tag.name }}
                        </v-chip>
                      </div>
                    </td>
                    <td>
                      <span>{{ props.item.additionalInformation }}</span>
                    </td>
                    <td>
                      <v-btn
                        v-if="props.item.filename !== null"
                        small
                        depressed
                        rounded
                        color="primary"
                        @click="download(props.item.idPhoto)"
                      >
                        {{ $t('Download') }}
                      </v-btn>
                      <v-btn
                        v-if="hasWritePermission"
                        icon
                        @click="edit(props.item.idPhoto)"
                      >
                        <v-icon>edit</v-icon>
                        <span class="d-sr-only">{{ $t('Modify') }}</span>
                      </v-btn>
                      <v-btn
                        v-if="hasWritePermission"
                        small
                        icon
                        @click="remove(props.item)"
                      >
                        <span v-if="sec.id === carbageSectionId">
                          <v-icon color="red">
                            close
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Close') }}</span>
                        </span>
                        <span v-else>
                          <v-icon>
                            delete
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Delete') }}</span>
                        </span>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template
                  slot="footer.page-text"
                  slot-scope="item"
                >
                  <v-btn
                    v-if="sectionPhotos(sec.id).length > 5"
                    small
                    outlined
                    rounded
                    @click="showAll(sectionIndex)"
                  >
                    {{
                      options[sectionIndex].itemsPerPage === 5 ? $t('Show all') : $t('Show less')
                    }}
                  </v-btn>
                  
                  {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                  {{ item.itemsLength.toLocaleString('fi-FI') }}
                </template>
                <template #footer>
                  <div
                    v-if="isUploading && selectedCategory === sec.id"
                    style="text-align: center"
                  >
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="15"
                      :value="uploadProgressPercentage"
                      :indeterminate="uploadProgressPercentage === 100"
                      color="primary"
                    >
                      {{ uploadProgressPercentage }}
                    </v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-icon>info</v-icon>
                  {{ $t('photos.no_photos_in_section') }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Alert from '../components/Alert'
import PhotoUploadForm from '../components/PhotoUploadForm'
import PhotoChoosesForm from '../components/PhotoChoosesForm'
import PhotoPreViewer from '../components/PhotoPreViewer'
import helpers from '../helpers'
export default {
  name: 'Photos',
  components: {
    Alert,
    PhotoPreViewer,
    PhotoUploadForm,
    PhotoChoosesForm
  },
  metaInfo () {
    return {
      title: `${this.$t('Photos')} ${this.currentSite?.name ? ' · ' + this.currentSite?.name : ''} · ${this.$t('Sites')} ·`,
    }
  },
  data () {
    return {
      headers: [
        { text: this.$t('Photo'), value: 'photoThumbUrl', sortable: false},
        { text: this.$t('Name'), value: 'filename'},
        { text: this.$t('Date'), value: 'uploaded'},
        { text: this.$t('Size'), value: 'size'},
        {
          text: this.$t('photos.quality'),
          value: 'quality'
        },
        {
          text: this.$t('photos.in_targetpage'),
          value: 'inSite'
        },
        {
          text: this.$t('photos.chooses'),
          value: false
        },
        {
          text: this.$t('Linking'),
          value: 'unitName'
        },
        { text: this.$t('Tags'), value: 'tags'},
        {
          text: this.$t('photos.additional_information'),
          value: 'additionalInformation'
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          sortable: false
        },
      ],
      filteredPhotos: [],
      options: [],
      documentFileTypes: [],
      documentClasses: [],
      files: [],
      filesCount: 0,
      userHasEditRights: false,
      userHasRentalPrintoutRights: false, 
      photoUploadDialogVisible: false,
      photoChoosesDialogVisible: false,
      isLoading: true,
      selectedPhoto: null,
      photoPreViewerVisible: false,
      choosesColStyle: {position: "absolute", top: '0px', left: '0px'},
      isRetrievingFile: false,
      previewerPhotoUrl: null,
      currentSite: null,
      photoLoadingError: false,
      carbageSectionId: 0,
      photoSections: [],      
      thumbsUp: {},
      hasError: null,
      errorText: "",
      uploadProgressPercentage: 0,
      isUploading: false,
      selectedCategory: null,
      photoPublicLink: null,
      downloadedPreviewerPhotos: [],
      searchValue: null,
      parties: null,
      photoFileTypes: [],
      selectedPhotoFileType: { text: this.$t('photos.all_types'), value: null },
      damagesSectionId: 0,
      photosFromSelectedCategory: [],
      selectedPhotoIdx: null,
      errorFilename: '',
      uploadErrors: [], // Array to store received upload errors
      imageKeys: ['toRentalPortal', 'toMainImage', 'toOutputImage1', 'toOutputImage2', 'moodImage', 'logoImage', 'unitMainImage', 'unitMoodImage'],
      currentCemeteryId: 0,
      activeSwitchIds: [],
      maxPhotosCount: 5,

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupId', 'hasApplicationPermissionByName', 'photoMetadata', 'dataTableHeaderDefinitions']),
    ...mapGetters('sites', ['publicLinkForPhoto', 'cemeteriesForCurrentSite', 'linkedSectionsAndGraves']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    ...mapState('app', ['photoMetadata',  'userInfo', 'currentDate']),
    hasWritePermission () {
      if (!this.userHasEditRights) {
        return false
      }
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
    photoCategories () {
      const photoCategoryId = this.photoMetadata.photo_category.definitionGroupId
      const photoSections =  this.definitionsByGroupId(photoCategoryId).map(section => { return {...section, label: this.$t(section.label)}})
      return photoSections
    },
    photoTypes () {
      const photoTypeId = this.photoMetadata.photo_type.definitionGroupId
      const photoTypes = this.definitionsByGroupId(photoTypeId).map(photoType => { return {...photoType, label: this.$t(photoType.label)}})
      return photoTypes
    },
    accessRights () {
      const accessRightId = this.photoMetadata.photo_access_right.definitionGroupId
      const accessRights = this.definitionsByGroupId(accessRightId).map(accessRight => { return {...accessRight, label: this.$t(accessRight.label)}})
      return accessRights
    },
    distinctErrors () {
      const errors = this.uploadErrors.map(error => { return error.text })
      const distincts = [...new Set(errors)]
      return distincts
    }
  },
  watch: {
    files: async function () {
      this.getFileTypesFromFiles()
      await this.createFilterStringsForFiles(this.files)
      await this.filterPhotosBySearchValue()
    },
    searchValue: async function () {
      await this.filterPhotosBySearchValue()
    },
    selectedPhotoFileType: async function () {
      await this.filterPhotosBySearchValue()
    },
    uploadErrors: async function () {   
      if (this.uploadErrors.length > 0) {
        this.hasError = true
      }   
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
      }
    }
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('VALOKUVAN_LISAYS')) {
      this.$router.push({ name: 'sites.info'})
    } else {
      this.isLoading = true
      await this.getPhotoMetadata()
      const parties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })
      this.parties = parties.filter(party => party.type === 0 | party.type === 1)
      await this.update()
      this.photoSectionsMethod()
      this.userHasEditRights = true
    
      if (this.hasApplicationPermissionByName('VALOKUVA_VUOKRAUS')) {
        this.userHasRentalPrintoutRights = true
      } else {
        let choosetext = this.$t('photos.chooses')
        if(this.headers[6].text == choosetext ){
          this.headers.splice(6,1)
        }
      }
      this.files.forEach(file => {
       if (file.inSite && file.idPhoto) {
          this.activeSwitchIds.push(file.idPhoto)
        }
      })
    }
  },
  methods: {
    ...mapActions('app', ['getPhotoMetadata']),
    ...mapActions('sites', ['loadLinkedSectionsAndGraves']),
    filesWithError (error) {
      const files = this.uploadErrors.filter(e => e.text === error).map(err => { return err.file })
      return files
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    getLinkingText (rowData) {
      if (this.cemeteriesForCurrentSite.length && rowData.idCemetery) {
        // If multiple cemeteries exist, and currently loaded cemetery is different, do a new API call
        // I don't know if this situation ever happens
        if (this.currentCemeteryId !== rowData.idCemetery) {
          this.currentCemeteryId = rowData.idCemetery
          this.loadLinkedSectionsAndGraves(rowData.idCemetery)
        }
        const cemetery = this.cemeteriesForCurrentSite.find(c => c.id === rowData.idCemetery)
        
        if (typeof cemetery !== 'undefined' && cemetery !== null) {
          const cemetaryName = cemetery.name
          const foundLinkedSectionAndGrave = this.linkedSectionsAndGraves.find(({ idPhoto }) => idPhoto === rowData.idPhoto)
          
          if(foundLinkedSectionAndGrave) {
          let sectionName = foundLinkedSectionAndGrave.sectionIdentifier ?? ""
          let graveName = foundLinkedSectionAndGrave.graveIdentifier ?? ""

          return this.$t('Cemetery') + ": " + cemetaryName + (sectionName === "" ? "" : (", " + this.$t('Graveyard section') + ": " + sectionName)) + (graveName === "" ? "" : (", " + this.$t('Grave') + ": " + graveName))
        }        
      }
      }

      return "-"
    },
    photoSectionsMethod () {
      // define the wanted custom section order
      const sortOrder = ['General','Marketing','Visualization material','Damages','Trash']

      const photoCategoryId = this.photoMetadata.photo_category.definitionGroupId
      this.photoSections =  this.definitionsByGroupId(photoCategoryId)
      .sort((a,b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label)) // sort the sections by custom section order
      .map(section => { 
        if( section.label === 'Trash' ) {
          this.carbageSectionId = section.id
        }
        if( section.label === 'Damages' ) {
          this.damagesSectionId = section.id
        }
        return {...section, label: this.$t(section.label)}
        })
      
      // Append default paginations for each section
      this.photoSections.map( section =>  this.options.push({
        itemsPerPage: 5,
        page: 1,
        sortBy: ['filename'],
        sortDesc: [false]
      }))
    },
    async update () {
      this.filteredPhotos = []
      this.files = []
      this.isLoading = true
      const idSite = this.currentSiteId
        ; ({
          items: this.files,
          count: this.filesCount
        } = await this.$rambollfmapi.sites
          .photos(idSite)
          .list(null, null, null, null, this.currentDate))
      this.currentSite = await this.$rambollfmapi.sites.get(idSite)
      this.getFileTypesFromFiles()
      if (this.cemeteriesForCurrentSite.length) {
        this.currentCemeteryId = this.cemeteriesForCurrentSite[0].id
        await this.loadLinkedSectionsAndGraves(this.cemeteriesForCurrentSite[0].id) 
      }
      this.isLoading = false
    },
    async filterPhotosBySearchValue () {
      const searchValue = this.searchValue
      this.filteredPhotos = []
      if (!this.files) {
        this.files = []
      }
      
      let sourceSet = this.files

      if(this.selectedPhotoFileType.value !== null) {
        sourceSet = this.filterPhotosByFileType(sourceSet)
      }

      if (searchValue) {
        sourceSet = sourceSet.filter(file => {
        return file.searchString.toLowerCase().includes(searchValue.toLowerCase())     
        })
      }
      this.thumbsUp = {}
      sourceSet.forEach(photo => {
        if(!photo.photoThumbUrl) {
          this.getPhotoThumbnailer(photo)
        } else {
          this.$set(this.thumbsUp, photo.idPhoto, !this.thumbsUp[photo.idPhoto])
        }
      })
      this.filteredPhotos = sourceSet
    },
    async changePhoto (photo) {
      try{
        await this.$rambollfmapi.photos.put(photo.idPhoto, photo);
      }catch (err) {
        this.hasError = true
        this.errorText=this.$t("Error!")+' '+this.$t(err)
        await this.update()
      }
    },
    async changePhotoinSite (photo) {
      await this.changePhoto (photo)
      this.toggleSwitch(photo)
    },
    toggleSwitch (photo) {
      if (photo.inSite) {
        if (this.activeSwitchIds.length < this.maxPhotosCount) {
          this.activeSwitchIds.push(photo.idPhoto)
        }
      } else {
        const index = this.activeSwitchIds.indexOf(photo.idPhoto)
        if (index !== -1) {
          this.activeSwitchIds.splice(index, 1)
        }
      }
    },
    isDisabled (idPhoto) {
      return this.activeSwitchIds.length >= this.maxPhotosCount && this.activeSwitchIds.indexOf(idPhoto) === -1
    },
    download (id) {
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      const self = this
      this.$rambollfmapi.photos
        .download(id, progress)
        .then(res => {
          self.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const file = this.filteredPhotos.find(file => {
            return file.idPhoto === id
          })
          helpers.saveAs(blob, file.filename)
        })
        .catch(() => {
        })
    },
    edit (id) {
      this.selectedPhoto = this.files.find(file => file.idPhoto === id)
      if (this.cemeteriesForCurrentSite.length > 0) {
        this.selectedPhoto.idCemetery = this.cemeteriesForCurrentSite[0].id
      }
      this.photoUploadDialogVisible = true
      this.photoChoosesDialogVisible = false
    },
    editChooses (id) {
      let top = document.querySelector('#choosescol'+id).getBoundingClientRect().top
      let left = document.querySelector('#choosescol'+id).getBoundingClientRect().left
      // "position: absolute; top: 0; left: 0;"
      // https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles
      this.choosesColStyle = {position: "absolute", top: Math.trunc(top)+'px', left: Math.trunc(left)+'px'}
      this.selectedPhoto = this.files.find(file => file.idPhoto === id)
      this.photoPreViewerVisible = false
      this.photoUploadDialogVisible = false
      this.photoChoosesDialogVisible = true
    },
    async remove (photo) {
      if( photo.photo_category !== this.carbageSectionId){
        // change photo_category to trash
        photo.photo_category = this.carbageSectionId
        photo.inSite = false
        photo.toMainImage = false
        photo.toRentalPortal = false
        photo.toOutputImage1 = false
        photo.toOutputImage2 = false
        photo.unitMainImage = false
        photo.unitMoodImage = false
        await this.$rambollfmapi.photos.put(photo.idPhoto, photo);
        if(this.activeSwitchIds.includes(photo.idPhoto)) {
          const index = this.activeSwitchIds.indexOf(photo.idPhoto)
          if (index !== -1)
            this.activeSwitchIds.splice(index, 1)
        }
      } else {
         this.photoPreViewerVisible = false
        // delete photo photo permamently
        if (confirm(this.$t('Are you sure you wish to delete this photo?'))) {
          await this.$rambollfmapi.photos.delete(photo.idPhoto)
          this.files = this.files.filter(p => p.idPhoto !== photo.idPhoto)
        }
      }
    },
    getPhotoSize (size) {
      const sizekB = size / 1024
      return sizekB > 1024? `${Math.round(sizekB/ 1024)} ${this.$t("photos.Abbr_megabyte")}` : `${Math.round(sizekB)} ${this.$t("photos.Abbr_kilobyte")}`
    },
    getPhotoQuality (size) {
        const sizeMt = Math.round(size / 1024 / 1024)
        if(sizeMt <= 2) {
          return "low"
        }
        if(sizeMt > 2 && sizeMt <= 5) {
          return "normal"
        }
        return "high"
    },
    closeForm () {
      this.selectedPhoto = null
      this.photoUploadDialogVisible = false
      this.photoChoosesDialogVisible = false
    },
    sectionPhotos (category) {
      return this.filteredPhotos.filter(photo => photo.photo_category === category)
    },
    async openPhotoPreViewer (photo, sectionId, sectionsIndex) {
      this.photosFromSelectedCategory = this.sectionPhotos(sectionId)
      const sectionPagination = this.options[sectionsIndex]

      if (sectionPagination.sortDesc[0]) {
        this.photosFromSelectedCategory.sort((a, b) => {
        return a[sectionPagination.sortBy[0]] < b[sectionPagination.sortBy[0]] ? 1 : -1
      })
      } else {
        this.photosFromSelectedCategory.sort((a, b) => {
        return a[sectionPagination.sortBy[0]] > b[sectionPagination.sortBy[0]] ? 1 : -1
      })
      }

      this.selectedPhotoIdx = this.photosFromSelectedCategory.findIndex(photoFromCategory => photoFromCategory.idPhoto === photo.idPhoto)
      const selectedPhoto = this.photosFromSelectedCategory[this.selectedPhotoIdx]
      this.photoPreViewerVisible = true
      this.isRetrievingFile = true
      this.photoLoadingError = false
      
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.photos
        .download(selectedPhoto.idPhoto, progress)
        .then(res => {
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          this.photosFromSelectedCategory[this.selectedPhotoIdx].previewerPhotoUrl = URL.createObjectURL(blob)
          this.photosFromSelectedCategory[this.selectedPhotoIdx].photoPublicLink = this.publicLinkForPhoto(selectedPhoto.publicId)
          this.downloadedPreviewerPhotos[this.selectedPhotoIdx] = { idPhoto: selectedPhoto.idPhoto, blob, fileName: selectedPhoto.filename }
          this.isRetrievingFile = false
        })
        .catch(() => {
          this.isRetrievingFile = false
          this.photoLoadingError = true
        })
    },
    closePhotoPreViewer () {
      this.photoPreViewerVisible = false
      this.downloadedPreviewerPhotos = []
      this.photoLoadingError = false
      this.selectedPhotoIdx = null
    },
    updatePreviewerPhoto (photo) {
      const updatedPhotoIdx = this.photosFromSelectedCategory.findIndex(categoryPhoto => categoryPhoto.idPhoto === photo.idPhoto)
      this.photosFromSelectedCategory.splice(updatedPhotoIdx, 1, photo)
      this.downloadedPreviewerPhotos.splice(updatedPhotoIdx, 1)

      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.photos
        .download(photo.idPhoto, progress)
        .then(res => {
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          this.photosFromSelectedCategory[updatedPhotoIdx].previewerPhotoUrl = URL.createObjectURL(blob)
          this.photosFromSelectedCategory[updatedPhotoIdx].photoPublicLink = this.publicLinkForPhoto(photo.publicId)
          this.downloadedPreviewerPhotos[updatedPhotoIdx] = { idPhoto: photo.idPhoto, blob, fileName: photo.filename }
          this.isRetrievingFile = false
        })
        .catch(() => {
          this.isRetrievingFile = false
          this.photoLoadingError = true
        })
    },
    async getPhotoThumbnailer (photo) {
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.photos
        .download(photo.idPhoto, progress, true)
        .then(res => {
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          photo.photoThumbUrl = URL.createObjectURL(blob)          
          this.$set(this.thumbsUp, photo.idPhoto, !this.thumbsUp[photo.idPhoto]) // fix v-img render problem v.42
        })
        .catch(() => {
          this.photoLoadingError = true
        })
    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('drag-bg')) {
        event.currentTarget.classList.add('drag-bg')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('drag-bg')
    },
    async drop (event, id) {
      event.currentTarget.classList.remove('drag-bg')
      event.preventDefault()
      const movingPhoto = event.dataTransfer.getData("photoId")
      const startCategory = event.dataTransfer.getData("category")    
      // moving photo from category to another
      if(movingPhoto && startCategory !== id.toString()) {        
        const photo = this.files.find(photo => photo.idPhoto.toString() === movingPhoto)
        photo.photo_category = id
        // Remove photo from Site gallery carousel if moved to carbage or damages category
          if(photo.photo_category === this.carbageSectionId || photo.photo_category === this.damagesSectionId) {
            photo.inSite = false
            photo.toMainImage = false
            photo.toRentalPortal = false
            photo.toOutputImage1 = false
            photo.toOutputImage2 = false
            photo.unitMainImage = false
            photo.unitMoodImage = false
          
            if(this.activeSwitchIds.includes(photo.idPhoto)) {
              const index = this.activeSwitchIds.indexOf(photo.idPhoto)
              if (index !== -1)
                this.activeSwitchIds.splice(index, 1)
            }
          }
        const updatedPhoto = await this.$rambollfmapi.photos.put(photo.idPhoto, photo)
        updatedPhoto.photoThumbUrl = photo.photoThumbUrl
        this.addOrUpdatePhoto(updatedPhoto)  
        return
      } else if(!movingPhoto) {
        // add new photo
        this.selectedCategory = id
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()
      }
    },
    startDrag (event, photoId, category) {
      event.dataTransfer.setData("photoId", photoId)
      event.dataTransfer.setData("category", category)     
      event.dataTransfer.effectAllowed = 'move'
    },
    async onChange () {      
      this.isUploading = true
      const photos = this.$refs.file.files
      if(!photos || !photos.length) {
        this.isUploading = false
        return
      }
      else if(photos.length > 100) {
        this.isUploading = false
        const message = this.$t('Too many files. Maximum amount is 100 at time.')
        const error = {
          text: message,
          file: null
        }
        this.uploadErrors.push(error)       
      }
      
      for(const photo of photos) {
        if (photo.size > 576716800) {
          this.isUploading = false
          const message = this.$t('File is too large. Maximum size is:') + ' 550 MB. ' + this.$t('Name of incorrect files') + ':'
          const errorFile = ' - ' + photo.name
          const error = {
            text: message,
            file: errorFile
          }
          this.uploadErrors.push(error)
        } else {
          let quality = this.getPhotoQuality()
          const newPhoto = {
            tags: [],
            idKohde: this.currentSiteId,
            uploaded: new Date(),
            photo_category: this.selectedCategory,
            filename: photo.name,
            size: photo.size,
            quality 
          } 
          const result = await this.$rambollfmapi.photos.post(newPhoto)
          if(!result.tags) {
            result.tags = []
          }
          if(result) {
            try {
              const uploaded = await this.$rambollfmapi.photos.upload(
                          result.idPhoto,
                          photo,
                          progress => {
                            this.uploadProgressPercentage = progress.percentage
                          }
                        )            
              if (uploaded.name === 'Error') {
                if(uploaded.response && uploaded.response.data.indexOf('not allowed') >= 0) {
                  const error = new Error(uploaded.response.data)
                  error.name = 'UnallowedTypeError'
                  throw error
                } else if (uploaded.response && uploaded.response.data.indexOf('Invalid file signature') >= 0) {
                  const error = new Error(uploaded.response.data)
                  error.name = 'InvalidSignatureError'
                  throw error
                } else {
                  throw new Error('Error with Axios')
                }                            
              }
              this.addOrUpdatePhoto(result)                        
            } catch (err) {
              this.isUploading = false
              await this.$rambollfmapi.photos.delete(result.idPhoto)
              let message = ''
              if (err.name === 'UnallowedTypeError' || err.name === 'InvalidSignatureError') {
                message =  this.$t('Unallowed type') + '. ' + this.$t('Name of incorrect files') + ':'   
              } else {
                message = this.$t('error.unexpected')
              }
              const errorFile = ' - ' + result.filename
              const error = {
                text: message,
                file: errorFile
              }
              this.uploadErrors.push(error)
            }
          }
        }
      }
      this.isUploading = false
    },
    closeError () {
      this.errorText = ""
      this.errorFilename = ''
      this.hasError = false
      this.isUploading = false
    },    
    getHumanReadableDate (date) {
      return helpers.humanize.date(date)
    },
    addOrUpdatePhoto (photo, changedCategory = false) {
      this.showIndicator = true
      this.operationResult = 'success'
      this.update()
      
      if(this.photoPreViewerVisible && !changedCategory) {
        this.updatePreviewerPhoto(photo)
      }

      // close photo previewer if photos category is changed when previwer is open 
      if(this.photoPreViewerVisible && changedCategory) {
        this.closePhotoPreViewer()
      }
    },
    downloadPreviewerPhoto (photoId) {
      const targetPhoto = this.downloadedPreviewerPhotos.find(photo => photo && photo.idPhoto === photoId)
      const {blob, fileName} = targetPhoto
      helpers.saveAs(blob, fileName)
    },
    async createFilterStringsForFiles (files) {
      for (const file of files) {
        const fileName = file.filename
        const uploadDate = file.uploaded ? this.getHumanReadableDate(file.uploaded) : " "
        const photoSize = this.getPhotoSize(file.size)
        const photoQuality = this.$t(`photos.quality_${file.quality}`)
        const inTargetPage = file.inSite ?  this.$t('photos.in_targetpage') : " "
        const photoType = file.photo_type !== null ? this.photoTypes.find(type => type.id === file.photo_type).label : " "
        const accessRight = file.photo_access_right !== null ? this.accessRights.find(accessRight => accessRight.id === file.photo_access_right).label : " "
        const party = file.idParty ? this.parties.find(party => party.id === file.idParty).name : " "
        const buildingName = file.buildingName ? file.buildingName : " "
        const floorName = file.floorName ? file.floorName : " "
        const unitCode = file.unitName ? file.unitName : " "
        const additionalInformation = file.additionalInformation ? file.additionalInformation : " "
        const author = file.author ? file.author : " "
        const toRentalPortal = file.toRentalPortal ? this.$t('photos.toRentalPortal'): " "
        const toMainImage = file.toMainImage ? this.$t('photos.toMainImage'): " "
        const toOutputImage1 = file.toOutputImage1 ? this.$t('photos.toOutputImage1'): " "
        const toOutputImage2 = file.toOutputImage2 ? this.$t('photos.toOutputImage2'): " "
         
        let searchString = fileName + " " + uploadDate + " " + photoSize + " " + photoQuality + " " + inTargetPage + " " + additionalInformation 
        + " " + author + " " + photoType + " " + accessRight + " " + party + " " + buildingName + " " + floorName + " " + unitCode + " " 
        + toRentalPortal + " " + toMainImage + " " + toOutputImage1 + " " + toOutputImage2
        file.tags.forEach(tag => {
          searchString += " " + tag.name
          })

        file.searchString = searchString
      }
    },
    getFileTypesFromFiles () {
      const allFiles = { text: this.$t('photos.all_types'), value: '' }
      const fileTypesFromPhotos = this.files.map(file => {
      const tempFile = file.filename.split('.')
      const filesuffix = tempFile.pop()
        return filesuffix
        })
        .filter((type, idx, array) => array.indexOf(type) === idx)
        .map( type => { return { text: type, value: '.' + type}})
      this.photoFileTypes = [allFiles, ...fileTypesFromPhotos]
    },
    filterPhotosByFileType (sourceFiles) {
      return sourceFiles.filter(file => file.filename.includes(this.selectedPhotoFileType.value))
    },
    showAll (sectionIndex) {
      if (this.options[sectionIndex].itemsPerPage === 5) {
        this.options[sectionIndex].itemsPerPage = -1
      } else {
        this.options[sectionIndex].itemsPerPage = 5
      }
    },
    async changeOrLoadPhoto (photoIdx) {
      this.selectedPhotoIdx = photoIdx
      const nextPhoto = this.photosFromSelectedCategory[this.selectedPhotoIdx]
      const nextPhotoDownload = this.downloadedPreviewerPhotos[this.selectedPhotoIdx]

      if(!nextPhotoDownload) {
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.photos
        .download(nextPhoto.idPhoto, progress)
        .then(res => {
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          this.photosFromSelectedCategory[this.selectedPhotoIdx].previewerPhotoUrl = URL.createObjectURL(blob)
          this.photosFromSelectedCategory[this.selectedPhotoIdx].photoPublicLink = this.publicLinkForPhoto(nextPhoto.publicId)
          this.downloadedPreviewerPhotos[this.selectedPhotoIdx] = { idPhoto: nextPhoto.idPhoto, blob, fileName: nextPhoto.filename }
          this.isRetrievingFile = false
        })
        .catch(() => {
          this.isRetrievingFile = false
          this.photoLoadingError = true
        })
      }
    },
    selectionChips (item) {
      return this.imageKeys.filter(key => item[key])
    }
  }
}
</script>
<style scoped>
.toolbar-text {
  color: #4d99cd;
  font-weight: bold;
}
.document-count {
  font-weight: bold;
}
.doc-headers {
  flex-direction: row;
  display: flex;
}
.doc-headers span {
  padding: 0.5em;
  background: #eef8ff;
  margin: 1px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(0, 0, 90, 0.7);
}
.doc-headers span:first-child {
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  background: #efefef;
  padding-left: 2em;
  border-top-left-radius: 2em;
}
.doc-headers span:last-child {
  border-top-right-radius: 2em;
}
.no-documents {
  background-color: #eef8ff !important;
  color: #111 !important;
  padding: 1em;
  vertical-align: baseline;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.doc-row {
  flex-direction: row;
  display: flex;
  width: 100%;
  border: 2px solid #eef8ff;
}
.doc-row:last-child {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.doc-tags {
  color: #4d99cd !important;
}
.doc-tags .v-chip--outlined {
  max-width: inherit;
}
.doc-tags .v-chip.v-chip.v-chip--outlined {
  border: 1px solid #4d99cd !important;
}
.doc-tags .v-chip__content {
  color: #4d99cd !important;
  overflow: hidden;
}
.doc-tags .v-btn.v-btn--flat {
  background-color: #4d99cd !important;
  color: white !important;
}
.doc-row > span:first-child {
  padding-left: 2em;
}
.doc-row > span {
  vertical-align: middle;
  margin: 1px;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: rgba(0, 0, 90, 0.7);
}
.doc-column {
  width: 100px;
  flex-grow: 2;
}
.doc-column-narrow {
  width: 50px;
  flex-grow: 1;
}
.doc-column-wide {
  width: 200px;
  flex-grow: 3;
}
.doc-headers,
.doc-column,
.doc-column-narrow,
.doc-column-wide {
  cursor: pointer;
}
.drag-bg {
  border-style: solid;
  border-color: var(--c-color-accent);
  background-color: var(--c-color-accent);
}
</style>
