<template>
  <div>
    <div>
      <v-btn
        v-if="!hide"
        large
        icon
        absolute
        right
        style="margin-top: 0.8em"
        @click="$emit('close')"
      >
        <v-icon>close</v-icon>
        <span class="d-sr-only">{{ $t('Close') }}</span>
      </v-btn>
      <v-card-title
        v-if="!hide"
        primary-title
        style="width:80%"
      >
        <h1>{{ $t(title) }}</h1>
      </v-card-title>
    </div>
    <!-- TÄHÄN ISO TAULUKKO -->
    <v-row no-gutters>
      <v-col
        cols="12"
        class="filters"
      >
        <!-- If this is added, filters panel is closed when using mobile: :multiple="!$vuetify.breakpoint.mobile" -->
        <v-expansion-panels
          :value="[0]"
          multiple
        >
          <v-expansion-panel
            :class="{ 'hide-expansion-panel': hide }"
          >
            <v-expansion-panel-header>
              {{ $t('Filters') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col
                  v-for="header in currentVisibleHeaders"
                  :key="header.value"
                  class="widthAdjust"
                >
                  <v-select
                    v-if="header.format == 'Array'"
                    v-model.lazy="filters[header.dataField ? header.dataField : header.value]"
                    :label="$t(header.text)"
                    :items="getFilterContent(header.value)"
                    clearable
                    validate-on-blur
                    item-text="text"
                    item-value="value"
                  />
                  <v-text-field
                    v-else-if="header.format == 'Date'"
                    v-model.lazy="filters[header.value]"
                    :label="$t(header.text)"
                    validate-on-blur
                  />
                  <v-text-field
                    v-else
                    v-model.lazy="filters[header.value]"
                    :label="$t(header.text)"
                    :type="header.format"
                    validate-on-blur
                    clearable
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels
          :value="[0]"
          multiple
        >
          <v-expansion-panel
            :class="{ 'hide-expansion-panel': hide }"
          >
            <v-expansion-panel-header>
              {{ $t('Information') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <lazy-table
                :source="source"
                :search-criteria="searchCriteria"
                :pagination.sync="pagination"
                :headers="currentVisibleHeaders"
                :custom-styles="customStyles"
                :custom-formatters="customFormatters"
                :icons="icons"
                :links="links"
                :filters="filters"
                :export-available="typeof(hide) !== 'undefined' ? !hide : true"
                :data-headers="dataHeaders"
                :widget-id="widgetId"
                :count="count"
                :table-footers="tableFooters"
                :hide="!!hide"
                :trigger-get-all-data="triggerGetAllData"
                :show-dialog="showDialog"
                mobile-breakpoint="0"
                @updateColumns="closeColumnsChooser"
                @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
                @getAllFilteredData="$emit('getAllFilteredData', $event)"
              >
                <template
                  #table-buttons="props"
                >
                  <slot
                    :get-all-filtered-data="props.getAllFilteredData" 
                    name="table-buttons"
                  />
                </template>
              </lazy-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LazyTable from '../general/LazyTable'
export default {
  components: {
    LazyTable,
  },
  props: {
    widgetId: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: null
    },
    searchCriteria: {
      type: Object,
      default: () => {
        return {
          siteIds: [],
          buildingIds: [],
          cemeteryIds: []
        }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    customStyles: {
      type: Object,
      default: () => {}
    },
    customFormatters: {
      type: Object,
      default: () => {}
    },
    icons: {
      type: Object,
      default: () => {}
    },
    links: {
      type: Object,
      default: () => {}
    },
    count: {
      type: Number,
      default: 0
    },
    tableFooters: {
      type: Array,
      default: () => []
    },
    hide: {
      type: Boolean,
      default: () => undefined
    },
    customPagination: {
      type: Object,
      default: undefined
    },
    triggerGetAllData: {
      type: Number,
      default: 0
    },
    customFilterContent: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pagination: {
        page: 1,
        itemsPerPage: this.customPagination?.itemsPerPage ?? 100,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortBy: this.customPagination?.sortBy ?? [],
        sortDesc: this.customPagination?.sortDesc ?? []
      },
      filters: {},
      showVisibleColumnsDialog: false,
      dataHeaders: [],
      visibleHeaders: [],
      filterValues: {},
      nameOfSelection: null,
      needsConfirm: false,
    }
  },
  computed: {
    currentVisibleHeaders () {
      const headers = this.dataHeaders.filter(
        h => this.visibleHeaders.indexOf(h.value) >= 0
      )
      return headers
    },
  },
  mounted () {
    this.updateHeaders(this.headers)
  },
  methods: {
    updateHeaders (headers) {
      this.dataHeaders = JSON.parse(JSON.stringify(headers))
      this.visibleHeaders = this.dataHeaders.map(h => h.value)
      this.filterValues = this.dataHeaders.reduce((acc, cur) => {
        acc[cur.value] = null
        return acc
      }, {})
    },
    closeColumnsChooser: function (visibleHeadersEdit) {
      this.visibleHeaders = JSON.parse(JSON.stringify(visibleHeadersEdit))
    },
    getFilterContent (value) {
      if (this.customFilterContent.hasOwnProperty(value)) {
        return this.customFilterContent[value]
      }
      return []
    },
  }
}
</script>

<style>
.hide-expansion-panel .v-expansion-panel-header {
  display: none !important;
}
</style>