import axios from 'axios'

class RambollFMAPIv2 {
  constructor (endpoint, accessToken) {
    this.endpoint = endpoint.charAt(endpoint.length - 1) === '/' ? endpoint : endpoint + '/'
    this.accessToken = accessToken

    this.APIv2 = axios.create()

    this.initializeAxios()

    this.accounts = {
      login: async (token) => {
        const url = endpoint + '/api/login'
        const result = await this.APIv2.post(url, {
          api_key: token
        })

        return result
      }
    }
    this.buildings = {

      list: (sessionId) => {
        // const queryParams = typeof params !== 'undefined' ? params.query : {}
        const url = endpoint + '/api/buildings?session_id=' + sessionId
        return this.APIv2.get(url)
      }
    }

    this.rent = {
      save: async (sessionId, lease) => {
        const url = endpoint + '/api/rents?session_id=' + sessionId
        const result = await this.APIv2.post(url, {
          building_code: lease.building_code,
          area: lease.area,
          tenant_name: lease.tenant_name,
          start_date: lease.start_date,
          end_date: lease.end_date,
          circle_id: lease.circle_id
        }).catch(function (error) {
          if (error.response) {
            return error
          }
        })

        return result
      },
      getWithLinkId: async (sessionId, linkId) => {
        const url = endpoint + '/api/rents/GetWithCircleId/' + linkId + '?session_id=' + sessionId
        const result = await this.APIv2.get(url)
        return result
      }
    }
  }

  initializeAxios () {
    // Add a request interceptor
    this.APIv2.interceptors.request.use((config) => {
      // auth
      if (typeof this.accessToken !== 'undefined' && this.accessToken !== null) {
        config.headers.Authorization = 'Bearer ' + this.accessToken
      }

      // configuration done
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })
  }

  async extractData (axiosPromise) {
    const results = await axiosPromise

    if (typeof results.headers['x-total-count'] !== 'undefined') {
      return {
        items: results.data,
        count: results.headers['x-total-count']
      }
    }

    return results.data
  }
}

export default RambollFMAPIv2
