<template>
  <div id="cost-types">
    <v-row
      class="layout-white flex-column"
    >
      <v-toolbar
        flat
        tile
      >
        <v-toolbar-title>
          <h1>{{ $t('Cost types') }}</h1>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row>
            <v-btn
              icon
              :class="{selected: edit}"
              @click="onClickEdit"
            >
              <v-icon>edit</v-icon>
              <span class="d-sr-only">{{ $t('Modify') }}</span>
            </v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-row>
    <v-row
      class="dashboard"
    >
      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <CostTypeTable
          :data="maintenanceCharges"
          :loading="loading"
          :hide-edit="true"
          :edit="edit"
          @savedUpdate="updateData"
          @pushId="pushId"
        />
      </v-col>
      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <CostTypeTable
          :data="tripleNetRent"
          :loading="loading"
          :hide-edit="true"
          :edit="edit"
          @savedUpdate="updateData"
          @pushId="pushId"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CostTypeTable from '../components/InternalRent/CostTypeTable'

export default {
  name: 'StatusPortfolios',
  components: { CostTypeTable },
  metaInfo () {
    return {
      title: `${this.$t('Cost types')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      tripleNetRent: {
        allDataIds: [],
        title: this.$t('internalrent.triple_net_rent'),
        headers: [
          {
            text: this.$t('internalrent.cost_variety'),
            value: 'name',
            editable: true
          },
          {
            text: this.$t('In use'),
            value: 'systemDefault',
            editable: false,
            switch: true
          }
        ],
        footers: [],
        items: [],
        sortBy: [],
        sortDesc: [true],
        itemsPerPage: 17
      },
      maintenanceCharges: {
        allDataIds: [],
        title: this.$t('internalrent.maintenance_charges'),
        headers: [
          {
            text: this.$t('internalrent.cost_variety'),
            value: 'name',
            editable: true
          },
          {
            text: this.$t('In use'),
            value: 'systemDefault',
            editable: false,
            switch: true
          }
        ],
        footers: [],
        items: [],
        sortBy: [],
        sortDesc: [true],
        itemsPerPage: 17
      },
      edit: false,
      loading: true
    }
  },
  computed: {
    ...mapState('internalRent', ['expenses'])
  },
  mounted: async function () {
    if (!this.expenses.length) {
      await this.getExpenses()
    }
    this.setExpenseData()
    this.loading = false
  },
  methods: {
    ...mapActions('internalRent', ['getExpenses']),
    async setExpenseData () {
      this.tripleNetRent.items = this.expenses.filter(
        expense => expense.tripleNet
      )
      this.maintenanceCharges.items = this.expenses.filter(
        expense => !expense.tripleNet
      )
      this.maintenanceCharges.allDataIds = []
      this.tripleNetRent.allDataIds = []
      this.expenses.forEach(expense => {
        this.maintenanceCharges.allDataIds.push(expense.id)
        this.tripleNetRent.allDataIds.push(expense.id)
      })
    },
    async updateData () {
      this.edit = false
      this.loading = true
      await this.getExpenses()
      await this.setExpenseData()
      this.loading = false
    },
    onClickEdit () {
      this.edit ? this.updateData() : this.edit = true
    },
    pushId (id) {
      this.maintenanceCharges.allDataIds.push(id)
      this.tripleNetRent.allDataIds.push(id)
    },
  }
}
</script>
