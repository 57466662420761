<template>
  <v-card>
    <div
      v-if="isLoading"
      class="is-loading"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
    <div class="text-h5 grey lighten-2">
      <v-btn
        large
        icon
        :disabled="cancelDisabled"
        absolute
        right
        style="margin-top: 0.8em"
        @click="cancel()"
      >
        <v-icon>close</v-icon>
        <span class="d-sr-only">{{ $t('Close') }}</span>
      </v-btn>
      <slot name="popupButton" />
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
        style="width:82%"
      >
        <slot name="title" />
      </v-card-title>
    </div>
    <v-card
      class="modalStyle"
    >
      <slot name="content" />
    </v-card>
    <v-card
      elevation="0"
      class="modalFooter"
    >
      <v-divider />
      <br>
      <v-row
        align="center"
        justify="space-between"
        no-gutters
      >
        <CdsAlert
          v-if="hasError"
          type="danger"
          size="small"
          :description="errorMessage"
        />
        <div class="c-modal__actions">
          <slot name="hideCancel">
            <v-btn
              :disabled="cancelDisabled"
              text
              rounded
              outlined
              @click="cancel()"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <slot name="footer" />
        </div>
      </v-row>
    </v-card>
  </v-card>
</template>
<script>
import CdsAlert from '../CircleDesignSystem/CdsAlert.vue'

export default {
  name: 'BaseModal',
  components: {
    CdsAlert,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    cancelDisabled: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
  },
  data () {
    return {
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
  }
}
</script>
<style scoped>
.is-loading {
  position: absolute;
  inset: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.c-modal__actions {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-grow: 1;
}
</style>
