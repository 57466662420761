import { i18n } from '../plugins/i18n'
export default {
    Dashboard () {
        return [
            {
                id: 'dashboard.use_of_space_history',
                type: 'DataTable',
                loading: true,
                data: {
                    headers: [
                        { text: 'Reporting period', value: 'reportingPeriod' },
                        { text: 'Area of government', value: 'administration' },
                        { text: 'User Organization',  value: 'organization' },
                        { text: 'Agreed area, avg.', value: 'agreedArea', format: 'Area' },
                        { text: 'Ktv1 Office unit area', value: 'officeUnitArea', format: 'Area'  },
                        { text: 'Personnel', value: 'personnelcount', format: 'Amount' },
                        { text: 'HTV', value: 'htvcount', format: 'NumberTwoDecimals' },
                        { text: 'Ktv1 Office HTV', value: 'officeHtvcount', format: 'NumberTwoDecimals' },
                        { text: 'Overall space efficiency', value: 'overallEfficiencycount', format: 'NumberTwoDecimals' },
                        { text: 'Office space efficiency', value: 'officeEfficiencycount', format: 'NumberTwoDecimals' },
                    ],
                    footers: [
                        { text: i18n.t('Total') },
                        {},
                        {},
                        { value: 'agreedArea', format: 'Area' },
                        { value: 'officeUnitArea', format: 'Area' },
                        { value: 'personnelcount', format: 'Amount' },
                        { value: 'htvcount', format: 'NumberTwoDecimals' },
                        { value: 'officeHtvcount', format: 'NumberTwoDecimals'},
                        { value: 'overallEfficiencycount', format: 'NumberTwoDecimals' },
                        { value: 'officeEfficiencycount', format: 'NumberTwoDecimals' }
                    ],
                    items: [],
                }
            },
            {
                id: 'dashboard.purpose_zone_history',
                type: 'DataTable',
                loading: true,
                data: {
                    headers: [
                        { text: 'Reporting period', value: 'reportingPeriod' },
                        { text: 'Area of government', value: 'administration' },
                        { text: 'User Organization',  value: 'organization' },
                        { text: 'Purpose of use zone', value: 'purposeOfUseZone' },
                        { text: 'Space area', value: 'spaceArea', format: 'Area' },
                    ],
                    footers: [
                        { text: i18n.t('Total') },
                        {},
                        {},
                        {},
                        { value: 'spaceArea', format: 'Area' }
                    ],
                    items: [],
                }
            },
            {
                id: 'dashboard.contracts_history',
                type: 'DataTable',
                loading: true,
                data: {
                    headers: [
                        { text: 'Reporting period', value: 'reportingPeriod' },
                        { text: 'Area of government', value: 'administration' },
                        { text: 'User Organization',  value: 'organization' },
                        { text: 'Type of asset', value: 'typeOfAsset' },
                        { text: 'Amount of contracts', value: 'contractscount', format: 'Amount' },
                        { text: 'Annual rent', value: 'annualRent', format: 'Euro' },
                        { text: 'Agreed area, avg.', value: 'agreedArea', format: 'Area' }
                    ],
                    footers: [
                        { text: i18n.t('Total') },
                        {},
                        {},
                        {},
                        { value: 'contractscount', format: 'Amount' },
                        { value: 'annualRent', format: 'Euro' },
                        { value: 'agreedArea', format: 'Area' }
                    ],
                    items: [],
                }
            },
            {
                id: 'dashboard.expenses_history',
                type: 'DataTable',
                loading: true,
                data: {
                    headers: [
                        { text: 'Reporting period', value: 'reportingPeriod' },
                        { text: 'Area of government', value: 'administration' },
                        { text: 'User Organization',  value: 'organization' },
                        { text: 'internalrent.cost_variety', value: 'expenseVariety' },
                        { text: 'Account', value: 'expensePlace' },
                        { text: '€', value: 'euroCost', format: 'Euro' },
                        { text: '€/HTV', value: 'euroHtvCost', format: 'Euro' },
                        { text: '€/htm2', value: 'euroHtmCost', format: 'Euro' },
                    ],
                    footers: [
                        { text: i18n.t('Total') },
                        {},
                        {},
                        {},
                        {},
                        { value: 'euroCost', format: 'Euro' },
                        { value: 'euroHtvCost', format: 'Euro' },
                        { value: 'euroHtmCost', format: 'Euro' },
                    ],
                    items: [],
                }
            },
            {
               id: 'dashboard.energy_consumption_and_emissions_history',
               type: 'DataTable',
               loading: true,
               data: {
                   headers: [
                        { text: 'Reporting period', value: 'reportingPeriod' },
                        { text: 'Area of government', value: 'administration' },
                        { text: 'User Organization', value: 'organization' },
                        { text: 'Energy consumption type', value: 'energyConsumptionType' },
                        { text: 'Energy consumption MWh', value: 'energyConsumptionMwh', format: 'NumberTwoDecimals' },
                        { text: 'Emissions kgCO2', value: 'emissionsKgco2', format: 'NumberTwoDecimals' }
                   ],
                   footers: [
                    { text: i18n.t('Total') },
                    {},
                    {},
                    {},
                    { value: 'energyConsumptionMwh', format: 'NumberTwoDecimals' },
                    { value: 'emissionsKgco2', format: 'NumberTwoDecimals' }
                   ],
                   items: []
               }
            }
        ]
    }
  }