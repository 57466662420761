import humanize from '../helpers/humanize'
export default {
  Dashboard () {
  return [
      {
        id: 'dashboard.graveyard.selected_cemeteries',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Cemetery name', value: 'name' },
            { text: 'Address', value: 'address' },
            { text: 'Graves count', value: 'grave_count', format: 'Amount' },
            { text: 'Vacant graves count', value: 'vacant_count', format: 'Amount' },
            { text: 'Upcoming burials count', value: 'upcoming_burials_count', format: 'Amount' }
          ],
          customStyles: null,
          icons: null,
          links: {
            name: function (row) {
              return {
                name: 'sites.info',
                params: { id_site: row.id_site}
              }
            }
          },
          source: 'SelectedCemeteries'
        }
      },
      {
        id: 'dashboard.graveyard.upcoming_burials',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Cemetery name', value: 'cemetery_name' },
            { text: 'Grave identifier', value: 'grave_identifier' },
            { text: 'Burial date', value: 'burial_date', format: 'Date' },
            { text: 'Buried deceased', value: 'deceased_name' },
          ],
          links: {
            cemetery_name: function (row) {
              return {
                name: 'sites.info',
                params: { id_site: row.id_site}
              }
            }
          },
          source: 'upcomingBurials'
        }
      },
      {
        id: 'dashboard.graveyard.vacant_graves',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Cemetery name', value: 'cemetery_name' },
            { text: 'Vacant grave identifier', value: 'grave_identifier' },
            { text: 'Last buried', value: 'deceased_name' },
            { text: 'Possession has ended', value: 'possession_end_year', format: 'Number' },
            { text: 'Reservation end date', value: 'reservation_end_date', format: 'Date' }
          ],
          links: {
            cemetery_name: function (row) {
              return {
                name: 'sites.info',
                params: { id_site: row.id_site}
              }
            }
          },
          source: 'vacantGraves'
        }
      },
      {
        id: 'dashboard.graveyard.buried_deceased',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Buried departed', value: 'deceased_name' },
            { text: 'Cemetery name', value: 'cemetery_name' },
            { text: 'Grave identifier', value: 'grave_identifier' },
            { text: 'Burial date', value: 'burial_date', format: 'Date' },
            { text: 'Possession end date', value: 'possession_end_year', format: 'Number' },
            { text: 'Customer number', value: 'customer_number' },
          ],
          customStyles: null,
          customFormatters: null,
          icons: null,
          links: {
            cemetery_name: function (row) {
              return {
                name: 'sites.info',
                params: { id_site: row.id_site}
              }
            }
          },
          source: 'buriedDeceased'
        }
      },
      {
        id: 'dashboard.graveyard.reserve_situation_graves',
        type: 'PieDataGraph',
        data: {
          items: [],
        },
        small: true,
        loading: true
      },
      {
        id: 'dashboard.graveyard.free_graves_per_graveyards',
        type: 'HorizontalBarDataGraph',
        data: {
          items: [],
          field: 'cemetery_name'
        },
        small: true,
        loading: true,
      }
    ]
  },
  Sites () {
    return [
      {
        id: 'site.graveyard.upcoming_burials',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Graveyard section', value: 'section' },
            { text: 'Grave identifier', value: 'grave_identifier' },
            { text: 'Burial date', value: 'burial_date', format: 'Date' },
            { text: 'Buried deceased', value: 'deceased_name' },
          ],
          items: [],
          source: 'upcomingBurials'
        }
      },
      {
        id: 'site.graveyard.active_services',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Grave identifier', value: 'grave_identifier' },
            { text: 'Last buried', value: 'deceased_name' },
            { text: 'Service identifier', value: 'service_identifier' },
            { text: 'Service description', value: 'description' },
            { text: 'Service description2', value: 'description2' },
            { text: 'Service count', value: 'count', format: 'Amount' },
            { text: 'Service start date', value: 'start_date', format: 'Date' },
            { text: 'Service end date', value: 'end_date', format: 'Date' }
          ],
          items: [],
          source: 'ActiveServices'
        }
      },
      {
        id: 'site.graveyard.vacant_graves',
        type: 'LazyTable',
        loading: true,
        data: {
          headers: [
            { text: 'Graveyard section', value: 'section' },
            { text: 'Vacant grave identifier', value: 'grave_identifier' },
            { text: 'Last buried', value: 'deceased_name' },
            { text: 'Possession has ended', value: 'possession_end_year', format: 'Number' },
            { text: 'Reservation end date', value: 'reservation_end_date', format: 'Date' }
          ],
          items: [],
          source: 'VacantGraves'
        }
      }
    ]
  }
}