!<template>
  <!-- View for creating a renegotiations prospect -->
  <v-card
    v-if="contract && !contract.no_renegotiations_modal"
  >
    <BaseModal
      @cancel="close"
    >
      <template #title>
        <span v-if="contract && !contract.type_renewal">{{ $t("leasing.begin_renegotiations") }}</span>
        <span v-else>{{ $t("leasing.begin_renewal") }}</span>
      </template>
      <template #content>
        <p class="text-center font-weight-bold text-subtitle-1">
          <span v-if="contract && !contract.type_renewal">{{ $t("leasing.renegotiations_explanation") }}</span>
          <span v-else>{{ $t("leasing.renewal_explanation") }}</span>
        </p>
        <validation-observer 
          ref="observer" 
          v-slot="{ invalid }"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Description"
            rules="required"
          >
            <v-text-field
              v-model="description"
              :label="$t('prospect.prospect_description') + ' *'"
              :error-messages="$t(errors)"
              class="my-6"
            />
          </validation-provider>
          <v-autocomplete
            v-model="selectedUnits"
            :label="$t('Units')"
            item-text="unit_code"
            item-value="id"
            :items="allUnits"
            multiple
            small-chips
            hide-details
            return-object
            class="my-6 location-selector"
            append-icon=""
            :loading="unitsLoading"
            :disabled="unitsLoading"
          >
            <template #selection="data">
              <v-chip
                :key="JSON.stringify(data.id)"
                class="v-chip--select-multi"
                close
                @click.stop
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item.unit_code }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="prospectResponsible"
            :label="$t('prospect.prospect_responsible')"
            :items="leasingUsers.filter(user => user != secondaryProspectResponsible)"
            item-text="name"
            item-value="id_user"
            return-object
            append-icon=""
            :loading="usersLoading"
            class="my-5"
          />
          <v-autocomplete
            v-model="secondaryProspectResponsible"
            :label="$t('prospect.secondary_responsible')"
            :items="leasingUsers.filter(user => user != prospectResponsible)"
            item-text="name"
            item-value="id_user"
            return-object
            append-icon=""
            clearable
            class="form-fields mb-5"
          />
        </validation-observer>
        <v-progress-linear
          v-if="partiesLoading" 
          indeterminate 
          color="primary"
        />
        <p
          v-if="leaseResponsible != null && partiesLoading === false"
          class="text-subtitle-1"
        >
          <span class="font-weight-bold">{{ $t('leasing.site_lease_responsible') }}:</span> {{ leaseResponsible }}
        </p>
      </template>
      <template #footer>
        <v-btn
          rounded
          depressed
          class="primary"
          :disabled="usersLoading || unitsLoading"
          :loading="prospectSaving"
          @click="save"
        >
          {{ $t("Yes") }}
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
  <!-- View for choosing no renegotiations -->
  <v-card v-else>
    <no-renegotiations-modal
      :contract="contract"
      :prospect-saving="prospectSaving"
      @close="close"
      @save="save($event)"
    />
  </v-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseModal from '../general/BaseModal'
import NoRenegotiationsModal from './NoRenegotiationsModal.vue';

export default {
    name: 'RenegotiationsModal',
    components: { BaseModal, ValidationProvider, ValidationObserver, NoRenegotiationsModal},
    props: {
        contract: {
            type: Object,
            default: null
        },
        isShown: {
            type: Boolean,
            default: false
        },
        userInfo: {
          type: Object,
          default: null
        },
    },
    data () {
        return {
            description: '',
            prospectResponsible: null,
            secondaryProspectResponsible: null,
            allUnits: [],
            selectedUnits: [],
            unitsLoading: false,
            usersLoading: false,
            partiesLoading: false,
            prospectSaving: false,
            unitPromise: null,
        }
    },
    computed: {
      ...mapGetters('leasing', ['leasingUsers']),
      ...mapGetters('app',  ['definitionByLabel', 'definitionsByGroupLabel']),
      ...mapState('app', ['sites', 'currentDate']),
      ...mapState('sites', ['loadedParties', 'loadedPartyLinks']),

      unitUsages () {
        return this.definitionsByGroupLabel('unit.usage_types')
      },
      leaseResponsible () {
        const resposibleDefId = this.definitionByLabel('Vuokravastuu (työntekijä)')?.id
        const partyLinks = this.loadedPartyLinks.filter(partyLink => partyLink.id_annotation === resposibleDefId).map(partyLink => partyLink.id_party)
        if (partyLinks.length > 0) {
          return this.loadedParties.filter(party => partyLinks.includes(party.id)).map(party => party.name).join(", ")
        }
        return null
      },
    },
    watch: {
        isShown: async function (shown) {
            if (!shown) {
                this.reset()
            }
            else {
                let description = this.contract.tenant
                if (this.contract.type_renewal) {
                  description += ' uusimisneuvottelu'
                }
                else {
                  description += ' jatkoneuvottelu'
                }
                this.description = description
                this.unitsLoading = true
                this.usersLoading = true
                this.getLeasingUsers().finally(() => this.usersLoading = false)
                this.partiesLoading = true
                this.loadSiteParties(this.contract.id_site).finally(() => this.partiesLoading = false)
                this.unitPromise =  this.getUnitsForSite()
            }
        },
        leasingUsers: function (newValue) {
          this.prospectResponsible = newValue.find(item => item.id_user === this.userInfo.id)
        },
    },
    async mounted () {
      let description = this.contract.tenant
      if (this.contract.type_renewal) {
        description += ' uusimisneuvottelu'
      }
      else {
        description += ' jatkoneuvottelu'
      }
      this.description = description
      this.unitsLoading = true
      this.usersLoading = true
      this.getLeasingUsers().finally(() => this.usersLoading = false)
      this.partiesLoading = true
      this.loadSiteParties(this.contract.id_site).finally(() => this.partiesLoading = false)
      this.unitPromise  = this.getUnitsForSite()
    },
    methods: {
      ...mapActions('leasing', ['changeRenegotiationsModalState', 'getLeasingUsers', 'saveNewProspect', 'updateContractData']),
      ...mapActions('sites', ['loadSiteParties']),

      close () {
          this.changeRenegotiationsModalState(null)
      },
      reset () {
          if (this.$refs.observer) {
            this.$refs.observer.reset()
          }
          Object.assign(this.$data, this.$options.data());
      },
      async save (data) {
        this.prospectSaving = true
        await this.unitPromise
        

        const siteUnits = this.selectedUnits.map(unit => { 
          return {id_unit: unit.id }
        })

        // Total area of selected units
        const totalArea = this.selectedUnits.reduce((acc, curr) => acc + curr.area, 0)

        // Find unit usage type
        const unitUsages = []
        this.selectedUnits.forEach((unit) => {
          if (unit.usage_types != null) {
            unit.usage_types.forEach(usage => {
              if (!unitUsages.includes(usage)) {
                unitUsages.push(usage)
              }
            })
          }
        })

        // If tenant is not a corporation, we have to fetch the actual tenant corporation based on contract number
        let osapuoli
        if (this.contract.tenant_corporation) {
          osapuoli = this.contract.id_tenant
        }
        else {
          const tenant = await this.$rambollfmapi.parties.tenantCorporation.tenantCorporationByContract(this.contract.contractNumber)
          if (tenant.id == null) {
            alert(`${this.$t('Error!')  } ${  this.$t('leasing.tenant_corporation_not_found')}`)
            this.close()
            return
          }
          
            osapuoli = tenant.id
          
        }
        const cities = this.sites.find(site => site.id_site === this.contract.id_site).municipality

        const prospect = {
          prospect_description: this.description,
          id_osapuoli: osapuoli,
          sites: [{
            id_site: this.contract.id_site,
            units: siteUnits
          }],
          cities: [cities],
          unit_area_preference: totalArea,
          lead_origin: this.definitionByLabel('Olemassa oleva vuokrasuhde').id,
          unit_type_preference: unitUsages,
          owner: this.prospectResponsible ? this.prospectResponsible.id_user : this.userInfo.id,
          secondary_owner: this.secondaryProspectResponsible ? this.secondaryProspectResponsible.id_user : null,
          renegotiations: true,
          contract_id: this.contract.contractId
        }

        // In case we're choosing no renegotiations, also add passed status, reason and passed date
        if (this.contract.no_renegotiations_modal) {
          prospect.status = 1
          prospect.status_description = data.inactivationReasonChoice.id
          prospect.passed_date = new Date(data.passedDate)
        }

        await this.addProspect(prospect)
        this.$emit('update')
      },
      async getUnitsForSite () {

        let siteUnits = []

        const buildings =  await this.$rambollfmapi.buildings.list({ query: { siteId: this.contract.id_site } })

        for (let i = 0; i < buildings.length; i++) {

            const floors = await this.$rambollfmapi.buildings.floors(buildings[i].building_code).list()

            for (let j = 0; j < floors.length; j++) {

                const units = await this.$rambollfmapi.floors.units(floors[j].id, this.currentDate).extra();

                siteUnits = siteUnits.concat(units);
            }
        }

        const newSelectedUnits = []

        if (this.contract) {
          this.contract.units.forEach(unit => {
              const foundUnit = siteUnits.find(item => item.id == unit.id)
              if (foundUnit) {
                newSelectedUnits.push(foundUnit)
              }
          })
          this.selectedUnits = newSelectedUnits
        }

        this.allUnits = siteUnits
        if (newSelectedUnits.length > 0) {
          this.unitsLoading = false
        }
        
      },
      addNewRenegotiations (contractId, prospect) {
        this.updateContractData({ contractId, prospect })
      },
      addProspect (prospect) {
        this.saveNewProspect(prospect).then(res => {
            if (!res.id_prospect) {
                alert(this.$t("error.unexpected"))
            }
            else {
              this.addNewRenegotiations(this.contract.contractId, res)
            }
          }
        )
        .finally(() => {
          this.prospectSaving = false
          this.close()
        })
      }
    },
    
}
</script>

<style scoped>
.location-selector {
  height: auto !important;
}
::v-deep .v-select__selections {
  min-height: 75px !important;
}
</style>