<template>
  <div>
    <v-dialog
      v-model="editDialog"
      max-width="550"
      persistent
    >
      <v-card>
        <EditNoteDialog
          :note="editingNote"
          :is-shown="editDialog"
          :edit-saving="editSaving"
          @save="updateNote"
          @close="closeEditNote"
        />
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ $t("prospect.notebook") }}
            </h1>
          </v-card-title>
        </div>
        <Table
          :rows="tableData"
          :headers="tableDataHeaders"
          :show-controls="true"
          :hide="true"
          @remove-row="deleteNote"
          @edit-row="editNote"
        />
      </v-card>
    </v-dialog>
    <v-expansion-panels
      v-model="expand"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            align="center"
            no-gutters
          >
            <v-col>
              {{ $t("prospect.notebook") }} 
            </v-col>
            <span
              v-if="info"
              class="float-right"
            >
              <InfoBall
                :info="$t(info)"
                left
              />
            </span>
          </v-row>
        </v-expansion-panel-header>
  
        <v-expansion-panel-content
          class="notes"
        >
          <div
            v-if="prospectNotesLoading"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <div v-else>
            <div
              class="notes-main-layout pa-0 ma-0"
            >
              <div
                v-if="!singleSelected"
                class="toolbar"
              >
                <v-menu
                  v-if="!noteType"
                  dense
                  class="dropdown"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      class="dropdown-button"
                      v-on="on"
                    >
                      <v-icon>
                        {{ selectionIcon }}
                      </v-icon>
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in dropdown"
                      :key="item.value"
                      @click="selection = item.value"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-icon
                  v-else
                  class="dropdown-button"
                >
                  {{ selectionIcon }}
                </v-icon>
                <v-divider
                  vertical
                />
                <v-autocomplete
                  v-if="!singleSelected && selection == 'sites'"
                  v-model="selectedSite"
                  :items="sites"
                  :loading="sitesLoading"
                  :placeholder="$t('prospect.select_site')"
                  :class="selectedRentalProcess ? 'note-unselected' : '' "
                  item-text="name"
                  item-value="id_site"
                  hide-details
                  return-object
                  clearable
                  class="mx-2 mt-2 mb-1 dropdown-select"
                  @change="selectNotes('site')"
                />
                <v-autocomplete
                  v-if="!singleSelected && selection == 'rentalProcesses'"
                  v-model="selectedRentalProcess"
                  :items="filteredRentalProcesses"
                  :loading="prospectsLoading"
                  :placeholder="$t('leasing.select_rental_process')"
                  :class="selectedSite ? 'note-unselected' : '' "
                  :item-text="getRentalProcessTitle"
                  item-value="id"
                  hide-details
                  return-object
                  clearable
                  class="mx-2 mt-2 mb-1 dropdown-select"
                  @change="selectNotes('rentalProcesses')"
                />
             
                <v-autocomplete
                  v-if="!singleSelected && selection == 'customers'"
                  v-model="selectedCorporation"
                  :items="corporations"
                  :loading="corporationsLoading"
                  :placeholder="$t('prospect.select_corporation')"
                  :class="selectedCorporation ? 'note-unselected' : '' "
                  item-text="corporation_name"
                  item-value="id"
                  hide-details
                  return-object
                  clearable
                  class="mx-2 mt-2 mb-1 dropdown-select"
                  @change="selectNotes('corporation')"
                />
              </div>
                
              <div
                v-if="(selection == 'sites' && !singleSelected) || (singleSite && !singleUnit)"
                class="toolbar"
              >
                <v-icon class="dropdown-button">
                  view_compact
                </v-icon>
                <v-divider
                  vertical
                />
                <v-autocomplete
                  v-model="selectedUnit"
                  :items="units"
                  :loading="unitsLoading"
                  :disabled="unitsLoading"
                  :placeholder="$t('Unit')"
                  :class="selectedUnit ? 'note-unselected' : '' "
                  item-text="unit_code"
                  item-value="id"
                  hide-details
                  return-object
                  clearable
                  class="mx-2 mt-2 mb-1 dropdown-select"
                  @change="selectNotes('unit')"
                />
              </div>
              <div
                v-if="linkToSelected.length > 0 && !singleSelected"
                cols="12"
                class="mx-2 my-1 linked text-subtitle-1"
                @click="openLink"
              >
                {{ linkToSelected }}
              </div>
              <div>
                <div
                  v-if="selectedRentalProcess == null && selectedSite == null && selectedCorporation == null && !singleSelected" 
                  :class="!singleSelected ? 'notes-panel--large' : 'notes-panel--medium'"
                  class="mt-4 mx-4"
                >
                  <NotesSummary
                    :notes="filteredNotes"
                    :user-info="userInfo"
                    :sites="sites"
                    :rental-processes="filteredRentalProcesses"
                    :corporations="corporations"
                    :loading="prospectNotesLoading || sitesLoading || corporationsLoading || prospectsLoading"
                    :note-type="noteType"
                    @openNotes="openNotes"
                  />
                </div>
                <v-row
                  v-else
                  ref="notesArea"
                  :class="!singleSelected ? 'notes-panel--large' : 'notes-panel--medium'"
                  justify="center"
                  align-content="start"
                  class="ma-2"
                >
                  <v-col
                    v-for="note in shownNotes"
                    :key="note.id"
                    cols="12"
                  >
                    <v-card
                      class="note-card pa-3"
                    >
                      <v-card-title class="pa-0 ma-0">
                        <v-row justify="space-between">
                          <v-col class="text-caption font-weight-light text-left">
                            {{ note.user }}
                          </v-col>
                          <v-col
                            v-if="singleSite && !singleUnit"
                            class="text-caption font-weight-light text-center"
                          >
                            {{ note.unit_name }}
                          </v-col>
                          <v-col class="text-caption font-weight-light text-right">
                            {{ formatDate(note.modified_date) }}
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <span class="note-value">{{ note.comment }}</span>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="!addButtonHidden"
              >
                <div class="new-note">
                  <div
                    class="pa-0 ma-0"
                  >
                    <v-form>
                      <v-card
                        :class="!showNewNoteField ? 'note-no-border' : ''"
                        elevation="0"
                        class="note-card mx-1 mt-4 mb-2"
                      >
                        <v-card-title class="ma-0 pa-1">
                          <v-textarea
                            v-if="showNewNoteField"
                            ref="newNoteTextArea"
                            v-model="newNote"
                            rows="4"
                            solo
                            flat
                            hide-details
                            auto-grow
                            class="ma-0 pa-1 note-no-border"
                            @keydown.enter.exact.prevent
                            @keyup.enter.exact="addNote"
                          />
                          <v-btn
                            :loading="addingNote"
                            :disabled="addButtonHidden"
                            :aria-label="$t('prospect.add_note')"
                            large
                            rounded
                            class="add-note-btn ma-0 note-no-border"
                            @submit="addNote"
                            @click="addNote"
                          >
                            <div v-if="showNewNoteField && newNote.length > 0">
                              {{ $t("prospect.add_note") }}
                            </div>
                            <v-icon v-else-if="showNewNoteField && newNote.length === 0">
                              close
                            </v-icon>
                            <v-icon v-else>
                              add
                            </v-icon>
                          </v-btn>
                        </v-card-title>
                      </v-card>
                    </v-form>
                  </div>
                </div>
              </div>
              <v-divider />
              <v-row
                justify="end"
                no-gutters
              >
                <div class="v-data-footer__pagination theme--light">
                  <div class="v-datatable__actions pt-4">
                    <v-btn
                      small
                      rounded
                      outlined
                      @click="showTableDataDialog = true"
                    >
                      {{ $t('Browse') }}
                    </v-btn>
                    <b
                      v-if="shownNotes.length > 0"
                      class="text-caption font-weight-bold"
                    >
                      {{ shownNotes.length > 0 ? 1 : 0 }}-{{ shownNotes.length.toLocaleString('fi-FI') }}
                    </b>
                  </div>
                </div>
              </v-row>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import humanize from '../../helpers/humanize'
import sanitizeHtml from 'sanitize-html'
import Table from '../Table'
import EditNoteDialog from './EditNoteDialog'
import NotesSummary from './NotesSummary'
import InfoBall from './../InfoBall'
import { getRentalProcessTitle } from '../../helpers/leasing/rentalprocess'
import ConfirmDialog from '../general/ConfirmDialog.vue'

export default {
    name: "Notes",
    components: { Table, EditNoteDialog, NotesSummary, InfoBall, ConfirmDialog },
    props: {
      // Prospect value if notes are shown for single prospect (i.e. in  prospect modal)
      singleProcess: {
        type: Object,
        default: null
      },
      // Site value if notes are shown for single site (i.e. in site page)
      singleSite: {
        type: Object,
        default: null
      },
      // Corporation value if notes are shown for single corporation. Currently in CorporationModal
      singleCorporation: {
        type: Object,
        default: null
      },
      // Unit value if notes are shown for single unit. Currently in Facility Management
      singleUnit: {
        type: Object,
        default: null
      },
      // String value determining if only one selection should be shown (customers/sites/prospects)
      noteType: {
        type: String,
        default: ''
      },
      maximized: {
        type: Array,
        default: function (){
          return [true]
        }
      },
      info: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        expand: 0,
        selectedSite: null,
        selectedRentalProcess: null,
        selectedCorporation: null,
        selectedUnit: null,
        showNewNoteField: false,
        newNote: "",
        addingNote: false,
        showTableDataDialog: false,
        editingNote: null,
        editDialog: false,
        editSaving: false,
        selection: "sites",
        selectionIcon: "house",
        units: [],
        unitsLoading: false,
      }
    },
    computed: {
      ...mapState('app', ['currentDate', 'userInfo', 'sites', 'sitesLoading', 'settings']),
      ...mapState('leasing', [
        'prospects', 
        'prospectNotes', 
        'prospectsLoading', 
        'prospectNotesLoading',
        'corporations',
        'corporationsLoading',
      ]),
      ...mapState('rentalProcess', ['rentalProcesses']),
      ...mapGetters('leasing', ['filteredProspects']),
      ...mapGetters('rentalProcess', ['filteredRentalProcesses']),
      shownNotes () {
        if (this.selectedUnit) {
          return this.prospectNotes.filter(note => note.id_hsto === this.selectedUnit.id).sort((a,b) => new Date(a.modified_date) - new Date(b.modified_date))
        }
        else if (this.selectedSite) {
          if (this.singleSite) {
             return this.prospectNotes.filter(note => note.id_kohde === this.selectedSite.id_site).sort((a,b) => new Date(a.modified_date) - new Date(b.modified_date))
          }
          else {
            return this.prospectNotes.filter(note => note.id_kohde === this.selectedSite.id_site && note.id_hsto === null).sort((a,b) => new Date(a.modified_date) - new Date(b.modified_date))
          }
        }
        else if (this.selectedRentalProcess) {
          return this.prospectNotes.filter(note => note.id_prospect === this.selectedRentalProcess.id).sort((a,b) => new Date(a.modified_date) - new Date(b.modified_date))
        }
        else if (this.selectedCorporation){
          return this.prospectNotes.filter(note => note.id_osapuoli === this.selectedCorporation.id).sort((a,b) => new Date(a.modified_date)- new Date(b.modified_date))      
        }
        else {
          return []
        }
      },
      singleSelected: function () {
          return this.singleProcess || this.singleSite  || this.singleCorporation || this.singleUnit 
      },
      tableData () {
        if (this.prospectNotesLoading) {
          return  []
        }

        return this.filteredNotes.map(note => {

          const siteName = this.sites.find(site => site.id_site === note.id_kohde)?.name
          const corporationName =  this.corporations.find(corporation => corporation.id === note.id_osapuoli)?.corporation_name
          const processName = getRentalProcessTitle(this.rentalProcesses.find(process => process.id === note.id_prospect))

          const sanitiziedNote = this.sanitizeNote(note.comment)
          const prospectNote = `<span class=hide-long-text>${sanitiziedNote}</span>`
          
          return {
            noteId: note.id_prospect_note,
            comment: prospectNote,
            prospectId: note.id_prospect,
            siteName,
            siteId: note.id_kohde,
            processName,
            corporationId: note.id_osapuoli,
            corporationName,
            modifiedDate: note.modified_date,
            editor: note.user,
            created: note.created,
            unitName: note.unit_name
          }
      })
        
      },
      tableDataHeaders () {
            const headers = []  

            if (!this.singleSelected) {
              headers.push({
                text: this.$t("Tenant corporation"),
                value: "corporationName"
              })
              headers.push({
                text: this.$t('Site'),
                value: "siteName"
              })
              headers.push({
                text: this.$t('Rental_process'),
                value: 'processName'
              })
            }
            if (this.singleSite || !this.singleSelected) {
              headers.push({
                text: this.$t('Unit'),
                value: 'unitName'
              })
            }

            headers.push({ 
                text: this.$t('prospect.note'),
                value: 'comment', 
            })
            
            headers.push({ text: this.$t('Last modified'),
                value: 'modifiedDate',
                format: 'Time'
            })
              
            headers.push({
                text: this.$t('prospect.editor'),
                value: 'editor',
            })

            headers.push({
                text: this.$t('Created'),
                value: 'created',
                format: 'Time'
            })
              
          return headers
      },
      addButtonHidden () {
        if (this.selectedSite || this.selectedRentalProcess || this.selectedCorporation || this.selectedUnit) {
          return false
        }
        return true
      },
      linkToSelected () {
        if (this.singleSelected) {
          return ''
        }
        if (this.selectedRentalProcess != null) {
          return getRentalProcessTitle(this.selectedRentalProcess)
        }
        else if (this.selectedCorporation != null) {
          return this.selectedCorporation.corporation_name
        }
        else if (this.selectedSite != null) {
          if (this.selectedUnit != null) {
            return this.selectedUnit.unit_code
          }
          return this.selectedSite.name
        }
        else {
          return ''
        }
      },
      filteredNotes () {
        const corporationIds = this.corporations.map(corporation => corporation.id)
        const siteIds = this.sites.map(site => site.id_site)
        const prospectIds = this.filteredRentalProcesses.map(process => process.id)

        return this.prospectNotes.filter(note => {
          // If single selected return notes only for it
          if (this.singleProcess) {
            return note.id_prospect === this.singleProcess.id
          }
          if (this.singleUnit) {
            return note.id_hsto === this.singleUnit.id
          }
          if (this.singleSite) {
            return note.id_kohde === this.singleSite.id_site
          }
          if (this.singleCorporation) {
            return note.id_osapuoli === this.singleCorporation.id
          }

          if (this.noteType) {
            switch (this.noteType) {
              case 'customers':
                return note.id_osapuoli !== null
              default:
                break
            }
          }

          // Only return notes for current sites, corporations and user prospects
          return (siteIds.includes(note.id_kohde) || note.id_kohde == null) &&
            (prospectIds.includes(note.id_prospect) || note.id_prospect == null) &&
            (corporationIds.includes(note.id_osapuoli) || note.id_osapuoli == null) 
        })
      },
      dropdown () {
        const items = [
          { text: this.$t("Sites"), value: "sites"},
          { text: this.$t("leasing.rental_events"), value: "rentalProcesses"},
        ]
        if (this.settings?.crmNotesAdditionalOptions?.includes('customers')) {
          items.push({ text: this.$t("leasing.customers"), value: "customers" })
        }
        return items
      },
    },
    watch: {
      singleSite: async function (newVal) {
        this.selectedSite = newVal
      },
      singleCorporation: async function (newVal){
        this.selectedCorporation = newVal
      },
      singleProcess: async function (newVal){
        this.selectedRentalProcess = newVal
      },
      singleUnit: async function (newVal){
        this.selectedUnit = newVal
      },
      maximized: async function (newVal){
        this.maximizedToExpand(newVal)        
      },
      selection: function () {
        if (this.selection == "sites") {
              this.selectionIcon = "house"
        }
        else if (this.selection == "rentalProcesses") {
              this.selectionIcon = "person"
        }
        else if (this.selection == "customers") {
              this.selectionIcon = "handshake"
        }
      },
      // Get units for site when it changes
      selectedSite: function (newValue) {
        this.selectedUnit = this.singleUnit ?? null
        if (newValue) {
          this.getUnits(newValue.id_site)
        }
      },
      // Scroll notes to bottom when they change
      shownNotes: function (newValue) {
        if (newValue.length > 0) {
          this.$nextTick(() => {
            const container = this.$refs.notesArea
            if (container) {
              container.scrollTop = container.scrollHeight
            }
          });
        }
      },
    },
    async mounted () {
      await this.open()
    },
    methods: {
      getRentalProcessTitle,
      ...mapActions('leasing', [
        'getProspectNotes', 
        'saveNewProspectNote', 
        'updateProspectNote', 
        'deleteProspectNote',
        'getCorporationExtraById', 
      ]),
      ...mapActions('rentalProcess', ['openRentalProcess']),
      async open () {
        await this.getProspectNotes()
        // If showing notes for single object, set the object
        if (this.singleProcess) {
          this.selectedRentalProcess = this.singleProcess
        }
        if (this.singleSite) {
          this.selectedSite = this.singleSite
        }
        if (this.singleCorporation) {
          this.selectedCorporation = this.singleCorporation
        }
        if (this.singleUnit) {
          this.selectedUnit = this.singleUnit
        }

        // If showing selection for only single type, set type
        if (this.noteType) {
          this.selection = this.noteType
        }
        
        this.maximizedToExpand(this.maximized)
      },
      selectNotes (type) {
        if (type === 'site') {
          this.selectedRentalProcess = null
          this.selectedCorporation = null
        }
        if (type === 'prospect') {
          this.selectedSite = null
          this.selectedCorporation = null
        }

        if(type === 'corporation'){
          this.selectedSite = null
          this.selectedRentalProcess = null
        }
        this.showNewNoteField = false
        this.newNote = ""
      },
      maximizedToExpand (newVal){
        if(!newVal || newVal.length === 0){
          return
        } 

        // Only consider booleans.
        if(newVal[0] === false){
          this.expand = 1
        }
        else if(newVal[0] === true){
          this.expand = 0
        }
      },
      async addNote () {
        if (!this.showNewNoteField) {
          this.showNewNoteField = true
          this.$nextTick(() => this.$refs.newNoteTextArea.focus())
        }
        else if (this.newNote) {
          this.addingNote = true;
          const siteId = this.selectedSite?.id_site ?? null
          const corporationId = this.selectedCorporation?.id ?? null
          const unitId = this.selectedUnit?.id ?? null
          let prospectId = this.selectedRentalProcess?.id ?? null
          if (this.newNote.length > 4000) {
            alert(this.$t("prospect.note_too_long"))
            this.addingNote = false;
          }
          else {
            const createdNote = {
              id_prospect: prospectId,
              id_kohde: siteId,
              id_osapuoli: corporationId,
              id_hsto: unitId,
              comment: this.newNote
            }
            await this.saveNewProspectNote(createdNote)
            this.addingNote = false;
            this.newNote = ""
            this.showNewNoteField = false;
          }
        }
        else {
          this.showNewNoteField = false;
        }
      },
      formatDate (date) {
        return humanize.date(date, 'time')
      },
      async deleteNote (note) {
        try {
          const ok = await this.$refs.confirmDialog.show({
            title: 'prospect.delete_note_confirmation',
            negative: 'Yes',
          })
          if (!ok) {
            await this.deleteProspectNote(note.noteId)
          }
        } catch (err) {
          return
        }
      },
      editNote (note) {
        const originalNote = this.prospectNotes.find(item => item.id_prospect_note === note.noteId)
        this.editingNote = { ...note}
        this.editingNote.comment = originalNote.comment
        this.editDialog = true
      },
      closeEditNote () {
        this.editDialog = false
        this.editingNote = null
      },
      async updateNote (comment, id) {
        this.editSaving = true
        await this.updateProspectNote({comment, id_prospect_note: id})
        this.editDialog = false
        this.editSaving = false
        this.editingNote = null
      },
      sanitizeNote (comment) {
         return sanitizeHtml(comment, { allowedTags: [], allowedAttributes: {}})
      },
      async openNotes (note) {
        if (note.id_prospect != null) {
          this.selection = "rentalProcesses"
          this.selectedRentalProcess = this.rentalProcesses.find(process => process.id === note.id_prospect)
        } else if (note.id_kohde != null) {
          this.selection = "sites"
          this.selectedSite = this.sites.find(site => site.id_site === note.id_kohde)
          if (note.id_hsto != null) {
            await this.getUnits(this.selectedSite.id_site)
            this.selectedUnit = this.units.find(unit => unit.id === note.id_hsto)
          }
        } else if (note.id_osapuoli != null) {
          this.selection = "customers"
          this.selectedCorporation = this.corporations.find(corporation => corporation.id === note.id_osapuoli)
        }
      },
      async openLink () {
        if (this.selectedRentalProcess != null) {
           this.openRentalProcess({id: this.selectedRentalProcess.id})
        }
        else if (this.selectedCorporation != null) {
          this.getCorporationExtraById({id: this.selectedCorporation.id})
        }
        else if (this.selectedSite != null) {
          if (this.selectedUnit != null) {
            const plan = await this.$rambollfmapi.sites.get(this.selectedSite.id_site)
            if (plan.has_dwg === true)
            {
              this.$router.push({
                name: 'sites.facilitymanagement',
                params: { id_site: this.selectedSite.id_site},
                query: { suite_id: this.selectedUnit.id}
              })
            }
            else
            {
              this.$router.push({
                name: 'sites.info',
                params: { id_site: this.selectedSite.id_site}
            })
            }
          }
          else {
            this.$router.push({
              name: 'sites.info',
              params: { id_site: this.selectedSite.id_site}
            })
          }
        }
      },
      async getUnits (siteId) {
        this.units = []
        this.unitsLoading = true
        let units = []
        const result = await this.$rambollfmapi.sites.units(siteId).list()
        if (!result.isAxiosError) {
          units = result
        }
        this.units = units
        this.unitsLoading = false
      }
    }
}
</script>

<style scoped>
.notes {
  font-weight: 400;
  width: 100%;
}
.add-note-btn {
  width: 100%;
  padding: 20px;
  background-color: var(--c-color-accent) !important;
  color: white !important;
}
.add-note-btn:disabled {
  background-color: #eeeff5 !important;
}
.note-card {
  border: 2px solid var(--c-color-accent) !important;
  border-radius: 20px !important;
}
.new-note {
  margin-bottom: auto;
}
.notes-panel--large {
  min-height: 450px;
  max-height: 650px;
  overflow: auto;
}
.notes-panel--medium {
  min-height: 250px;
  max-height: 450px;
  overflow: auto;
}
.note-no-border {
  border: none !important;
  word-break: normal !important;
}
.note-unselected {
  border-color: grey !important;
}
.note-select >>> input {
  text-align: center;
  color: var(--c-color-accent) !important;
}
.note-select-menu {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}
.note-value {
  white-space: pre-line;
  text-align: justify;
}
.toolbar {
  margin: 15px;
  padding: 0 5px 0 15px !important;
  border: 1px solid var(--c-color-accent) !important;
  border-radius: 50px;
  background-color: #ffffff !important;
}
.dropdown {
  border: 0px !important;
}
.dropdown-select {
  border: 0 !important;
}
.dropdown-button {
  width: 10%;
  margin-left: 10px !important;
  margin-right: 20px !important;
}
</style>