<template>
  <v-expansion-panels
    :value="widget.maximized[0] === true ? [0] : []"
    multiple
    :style="{ 'border-color': draggableColor }"
  >
    <!-- v-model doesn't work the same anymore on v-expansion-panel inside v-expansion-panels, so workaround onclick -->
    <v-expansion-panel
      v-for="(source, idx) in Array.isArray(widget.data.items)
        ? widget.data.items
        : widget.data.items !== undefined
          ? [widget.data.items]
          : []"
      :key="widget.id + '-' + idx"
      class="no-padding"
      @click="onExpansionPanelClick(widget)"
    >
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
          no-gutters
        >
          <v-icon v-if="widget.data.icon !== null">
            {{
              widget.data.icon
            }}
          </v-icon>
          {{
            widget.data.titleSource
              ? source[widget.data.titleSource]
              : $t(widget.id)
          }}
          <span
            v-if="
              (Array.isArray(widget.data.items)
                ? widget.data.items
                : [widget.data.items]
              ).length > 1
            "
          >({{ idx + 1 }} / {{ widget.data.items.length }})</span>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row v-if="widget.loading">
          <v-spacer />
          <v-col class="shrink">
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
              style="margin: 3em"
            />
          </v-col>
          <v-spacer />
        </v-row>
        <div v-else>
          <MetadataForm
            v-if="widget.type === 'MetadataForm'"
            :metadata="widget.data.metadata"
            :data="source"
            :category="widget.data.category"
            :disabled="!hasWritePermission"
            :multiline="widget.data.multiline"
            :with-status-icon="true"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import MetadataForm from './MetadataForm'

export default {
  name: 'ExpansionMetaDataForm',
  components: {
    MetadataForm
  },
  props: {
    widget: {
        type: Object,
        default: null
    },
    hasWritePermission: {
        type: Boolean,
        default: false
    },
    draggableColor: {
        type: String,
        default: null
    }
  },
  data () {
    return {}
  },
  computed: {
  },
  async mounted () {
  },
  methods: {
    onExpansionPanelClick (widget) {
      if(widget.maximized[0] === true) {
        widget.maximized[0] = false
      } else {
        widget.maximized[0] = true
      }
    },
  }
}
</script>
<style scoped>
</style>
