<template>
  <SpaceModifyForm
    :in-popup="true"
    :space-visibility-rights="spaceVisibilityRights"
    :is-widget-fields="isWidgetFields"
    :links="spacePartyLinks"
    :links-in-some-space="partyInSomeLink"
    :spaces="spaces"
    :parties="parties"
    :customizable-fields="customizableFields"
    :purposes-of-use="purposesOfUse"
    :cleaning-areas="cleaningAreas"
    :fire-areas="fireAreas"
    :costcenters="costcenters"
    :space-shares="spaceShares"
    :disabled="readonly"
    :pre-modified-spaces="modifiedSpaces"
    :contract-number-sap="contractNumberSap"
    :space-categories="spaceCategories"
    :departments="departments"
    :item-areas="itemAreas"
    @update="postMessageUpdate"
    @close="spaceModifyDialog = false"
  />
</template>
<script>
import { mapMutations } from 'vuex'

import SpaceModifyForm from '../modules/v3viewer/SpaceModifyForm'

export default {
  name: 'SpaceModifyPopup',
  components: {
    SpaceModifyForm: SpaceModifyForm
  },
  data: function () {
    return {
      isWidgetFields: null,
      spaceVisibilityRights: {},
      spacePartyLinks: [],
      partyInSomeLink: [],
      spaces : [],
      parties: [],
      purposesOfUse : [],
      cleaningAreas: [],
      fireAreas: [],
      costcenters: [],
      modifiedSpaces: {},
      readonly: false,
      spaceShares: null,
      capacityQuantity: null,
      capacityUnit: null,
      customizableFields: [],
      contractNumberSap: [],
      spaceCategories: [],
      departments: [],
      itemAreas: [],
    };
  },
  computed: {},
  mounted () {
    this.setPopupOpen(true)
    window.addEventListener('message', this.receiveMessage)
    window.opener.postMessage("READY", "*");
  },
  methods: {
    ...mapMutations('app', ['setPopupOpen']),
    receiveMessage (event) {
      if (event.data.spaces) {
        this.isWidgetFields =  event.data.isWidgetFields,
        this.spacePartyLinks = event.data.spacePartyLinks
        this.partyInSomeLink = event.data.partyInSomeLink
        this.spaces = event.data.spaces
        this.spaceShares = event.data.spaceShares
        this.parties = event.data.parties
        this.purposesOfUse = event.data.purposesOfUse
        this.cleaningAreas = event.data.cleaningAreas
        this.fireAreas = event.data.fireAreas
        this.costcenters = event.data.costcenters
        this.spaceVisibilityRights = event.data.spaceVisibilityRights
        this.modifiedSpaces = event.data.modifiedSpaces
        this.capacityQuantity = event.data.capacityQuantity
        this.capacityUnit = event.data.capacityUnit
        this.readonly = event.data.readonly
        this.customizableFields = event.data.customizableFields
        this.contractNumberSap = event.data.contractNumberSap
        this.spaceCategories = event.data.spaceCategories
        this.departments = event.data.departments
        this.itemAreas = event.data.itemAreas
      }
    },
    postMessageUpdate () {
      window.opener.postMessage("UPDATE", "*");
      window.close()
    }
  }
}
</script>
<style scoped>
</style>
