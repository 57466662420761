<script>
import { Line, mixins } from 'vue-chartjs'
import chartjsPluginAnnotation from "chartjs-plugin-annotation";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  mounted () {
    this.addPlugin([chartjsPluginAnnotation]);
    this.update()
  },
  methods: {
    update () {
      this.renderChart(this.chartData, { ...this.options, annotation: { ...this.options.annotation } })
    },
  }
}
</script>
