<template>
  <div>
    <div
      v-if="!isDraggable"
      class="toolbar dashboard justify-end"
    >
      <v-btn
        v-if="realizationToBudgetBtn"
        outlined
        rounded
        text
        @click="openRealizationToBudgetDialog"
      >
        {{ $t('internal.create_from_realization') }}
      </v-btn>
      
      <v-btn
        v-if="costCenterEditing"
        icon
        @click="openOrCloseEdit"
      >
        <v-icon>edit</v-icon>
        <span class="d-sr-only">{{ $t('Modify') }}</span>
      </v-btn>
            
      <v-menu 
        v-if="showEdit"
        left 
      >
        <template #activator="{ on }">
          <v-btn
            outlined
            icon
            style="background: rgba(255, 255, 255, 0.8) !important"
            v-on="on"
          >
            <v-icon>menu</v-icon>
            <span class="d-sr-only">{{ $t('Menu') }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="set in sets"
            :key="set.title"
            @click="selectSet(set)"
          >
            <v-list-item-title>{{ setTitle(set) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog 
      v-model="massEditDialogOpen"
      persistent
      max-width="550"
    >
      <BaseModal
        @cancel="closeMassDialog()"
      >
        <template #title>
          {{ $t('internalrent.mass_expense_dialog') }}
        </template>
        <template #content>
          <v-list>
            <v-list-item
              v-for="expense in selectedExpense"
              :key="expense.idExpense"
            >
              <v-list-item-content>
                <v-list-item-title>{{ expense.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  v-model.number="expense.expenseValue"
                  append-icon="edit"
                  type="number"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template #footer>
          <v-tooltip
            top
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  depressed
                  rounded
                  color="primary"
                  @click="addYearly()"
                >
                  {{ $t('internalrent.monthly') }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t('internalrent.monthly_tooltip') }}</span>
          </v-tooltip>
          <v-tooltip
            top
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  depressed
                  rounded
                  color="primary"
                  @click="addMonthly()"
                >
                  {{ $t('internalrent.yearly') }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t('internalrent.yearly_tooltip') }}</span>
          </v-tooltip>
        </template>
      </BaseModal>
    </v-dialog>
    <div
      class="mb-8"
    >
      <v-expansion-panels
        :value="expand[0] ? [0] : []"
        :style="{ 'border-color': draggableColor }"
        multiple
      >
        <v-expansion-panel
          class="no-padding"
          @click="onExpansionPanelClick"
        >
          <v-expansion-panel-header>
            {{ data.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="loading"
              style="text-align: center; padding: 2em;"
            >
              <v-progress-circular
                size="64"
                indeterminate
                color="primary"
              />
            </div>
            <v-data-table
              v-else
              :header-props="dataTableHeaderDefinitions"
              :headers="data.headers"
              :items="data.items"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              class="elevation-0"
              mobile-breakpoint="0"
            >
              <template
                slot="item"
                slot-scope="props"
              >
                <tr>
                  <td
                    v-for="header in data.headers"
                    :key="header.value"
                  >
                    <v-text-field
                      v-if="edit && header.editable && !isSaving"
                      v-model.number="editItems[props.item.idExpense][header.value].amount"
                      append-icon="edit"
                      class="borderless"
                      type="number"
                      @change="dataItemChange(props.item.idExpense, header.value)"
                    />
                    <div
                      v-else
                      :class="{ 'col-align-right': isRightAligned(header.format) }"
                    >
                      {{ getValue(props.item[header.value], header.format) }}
                    </div>
                  </td>
                </tr>
              </template>

              <template slot="body.append">
                <tr>
                  <td
                    v-for="(footer, idx) in data.footers"
                    :key="idx"
                  >
                    <div
                      :class="{ 'col-align-right': isRightAligned(footer.format) }"
                      v-html="Sanitize(getFooterValue(data.items, footer.value, footer.text, footer.unit, footer.average, footer.format))"
                    />
                  </td>
                </tr>
              </template>
              <template
                slot="footer.page-text"
                slot-scope="item"
              >
                {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                {{ item.itemsLength.toLocaleString('fi-FI') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row
        v-if="!isDraggable"
        justify="end"
        style="right: 0; bottom: 0; position: sticky; z-index: 1"
      >
        <v-btn
          v-if="edit && !noSaveBtn"
          :disabled="isSaving"
          rounded
          depressed
          color="secondary"
          @click="openOrCloseEdit"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="edit && !noSaveBtn"
          :disabled="!edited"
          rounded
          depressed
          color="primary"
          @click="saveChanges"
        >
          <span v-if="!isSaving">{{ $t('Save changes') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
import helpers from '../../helpers'
import { mapGetters, mapState } from 'vuex'
import BaseModal from '../general/BaseModal.vue'
import TableVerticalVue from '../TableVertical.vue'
export default {
  name: 'ExpenseTable',
  components: {
    BaseModal
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    noSaveBtn: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Array,
      default: function () { return [true] }
    },
    id: {
      type: String,
      default: ''
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    realizationToBudgetBtn: {
      type: Boolean,
      default: false
    },
    costCenterEditing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expand: [],
      draggableColor: null,
      editable: false,
      edited: false,
      options: {
        sortDesc: this.data.sortDesc ? this.data.sortDesc : [false],
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 5,
        sortBy: this.data.sortBy ? this.data.sortBy : [],
        totalItems: 0
      },
      editItems: [],
      changedItems: [],
      sets: [
        {
          id: 1,
          title: 'Modify'
        },
        {
          id: 2,
          title: 'Whole year'
        }
      ],
      massEditDialogOpen: false,
      selectedExpense: null,
      massValue: 0
    }
  },
   computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    ...mapState('internalRent', ['inspectionMonthValues'])
  },
  watch: {
    edit: function () {
      // Set the data before enabling the editing
      this.setDataForEditableFields()
      this.editable = this.edit
    },
    'data.headers': function () {
      if (!this.loading && !this.isSaving) {
        // update the editable data when headers are changed (inspection year is changed)
        this.setDataForEditableFields()
      }
    },
    isDraggable: function () {
      if (this.isDraggable === true) {
        this.draggableColor = '#8b8b8b'
      } else {
        this.draggableColor = null
      }
    },
    expand: {
      handler: function (val) {
        if(this.expand !== this.isOpen) {
          this.$emit('changeOpenStatus', val)
        }
      }
    },
    isOpen: { 
      handler: function (val) {
        this.expand = val
      }
    }
  },
  mounted () {
    this.setDataForEditableFields()
    this.expand = this.isOpen
  },
  methods: {
    openOrCloseEdit () {
      this.editable = !this.editable
      this.$emit('editing', this.id, this.editable)
    },
    getValue (exampleValue, format) {
      if (typeof exampleValue === 'boolean') {
        if (exampleValue) {
          return 'X'
        } else {
          return ''
        }
      }

      if (format) {
        return helpers.format.formatData(exampleValue, format)
      }

      return exampleValue
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    getFooterValue (data, headerName, text, unit, average, format) {
      if (typeof format !== 'undefined') {
        return helpers.format.formatData(text, format)
      }

      if (!isNaN(text)) {
        return text.toFixed(2)
      }

      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      if (typeof unit !== 'undefined') {
        value = helpers.format.valueWithUnit(value, unit)
      }
      return value
    },
    setDataForEditableFields () {
      // Set the editable data to editable items object
      const editableItems = {}
      this.data.items.forEach(item => {
        editableItems[item.idExpense] = {}
        this.data.headers.forEach(header => {
          if (header.editable) {
            editableItems[item.idExpense][header.value] = {
              idExpense: item.idExpense,
              amount: item[header.value]
            }
          }
        })
      })
      this.editItems = editableItems
    },
    dataItemChange (expenseId, header) {
      this.edited = true
      if (!this.editItems[expenseId][header]) {
        this.editItems[expenseId][header] = 0
      }
    },
    async saveChanges () {
      const list = Object.keys(this.editItems).map(item => {
        Object.keys(this.editItems[item]).map(month => {
          // replace empty value with 0
          if(this.editItems[item][month].amount === "") {
            this.editItems[item][month].amount = 0
          }
        })
        return this.editItems[item]
      })
      this.$emit('saveChanges', list, this.id)
      this.edited = false
    },
    onExpansionPanelClick (event) {
      if(event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
        this.expand[0] = false
      } else {
        this.expand[0] = true
      }
    },
    openRealizationToBudgetDialog () {
      const list = Object.keys(this.editItems).map(item => {
        Object.keys(this.editItems[item]).map(month => {
          // replace empty value with 0
          if(this.editItems[item][month].amount === "") {
            this.editItems[item][month].amount = 0
          }
        })
        // Append expense id to expense object to get the expense name when creating data for budget creation from realization dialog
        this.editItems[item].idExpense = Number(item)
        return this.editItems[item]
      })
      this.$emit('openRealizationToBudgetDialog', list)
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    },
    selectSet (set) {
      if (set.id === 1) {
        this.openOrCloseEdit()
      } else if (set.id === 2) {
        this.massEditDialogOpen = true
        this.selectedExpense = this.data.items

        // Fill out total expenses from different monthly values for dialog
        for (const expense of this.selectedExpense) {
          expense.expenseValue = this.inspectionMonthValues.map(x => expense[x]).reduce((acc, cur) => acc + cur, 0).toFixed(4)
        }
        
        if (!this.editable) {
          this.openOrCloseEdit()
        }
      }
    },
    setTitle (set) {
      if (set.id === 1 && this.editable !== false) {
        return this.$t('Cancel')
      }
      else { return this.$t(set.title) }
    },
    closeMassDialog () {
      this.massEditDialogOpen = false
      this.selectedExpense = null
      // reset the expense values upon closing
      this.data.items.forEach(element => {
        element.expenseValue = 0
      })
    },
    addYearly () {
      this.selectedExpense.forEach(expense => {
        let value = expense.expenseValue
        this.inspectionMonthValues.forEach(month => {
          this.editItems[expense.idExpense][month].amount = value
        })
      })

      this.edited = TableVerticalVue

      this.closeMassDialog()
    },
    addMonthly () {
      this.selectedExpense.forEach(expense => {
        let value = expense.expenseValue / 12
        this.inspectionMonthValues.forEach(month => {
          this.editItems[expense.idExpense][month].amount = value
        })
      })
      
      this.edited = TableVerticalVue

      this.closeMassDialog()
    }
  }
}
</script>
<style scoped>
</style>
