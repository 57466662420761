import { i18n } from "../../../plugins/i18n"

export function endingPermanent (followupOptions) {
  const defaultFilterOption = followupOptions.find(
    (option) => option.label === "Ei vaadi toimenpiteitä"
  )?.label

  const defaultFilter = {
    text: i18n.t("Filter out no followup actions"),
    value: defaultFilterOption,
  }

  return {
    id: "contract.ending_permanent_12mo",
    type: "ObjectTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contract_number",
          event: "showCreateRentContractModal",
        },
        { text: "tenantName", value: "tenantName" },
        { text: "Renter", value: "renter" },
        { text: "Site name", value: "site" },
        { text: "Site identifier", value: "site_identifier" },
        { text: "Unit", value: "units" },
        { text: "Landlord contact person", value: "landlord_contact_person" },
        {
          text: "new_handinvoice.contract_startdate",
          value: "start_date",
          format: "Date",
        },
        {
          text: "new_handinvoice.contract_enddate",
          value: "end_date",
          format: "Date",
        },
        {
          text: "Contract direction",
          value: "contract_direction",
        },
        {
          text: "Contract terminated",
          value: "terminationTypeTr",
        },
        {
          text: "Terminator",
          value: "terminatingPartyTr",
        },
        {
          text: "Termination reason",
          value: "contractTerminationDescription",
          hideOverflow: "true",
        },
        {
          text: "Follow-up measures",
          value: "followupAction",
          type: "dropdown",
          chooses: () => {
            return [
                "Ei vaadi toimenpiteitä",
                "Uusiminen työn alla"
              ]
          },
        },
      ],
      filter: {
        header: "followupAction",
        type: "notEqual",
        // Magic value All because it should not match any values which could be in followupAction field.
        options: [defaultFilter, { text: i18n.t("Show all"), value: "All" }],
        defaultFilter: defaultFilter,
      },
      sortBy: "end_date",
      sortDesc: false,
      items: [],
    },
    dataFetcher: (context) => {
      const { endingContracts } = context

      return endingContracts
        .filter((item) => item.isFixedTerm === false)
        .map((item) => {
          const followupAction =
            followupOptions.find(
              (option) => item.defFollowupAction === option.id
            )?.label || null

          return {
            ...item,
            // Required for contract modal
            contractId: item.id,
            isOutRent: item.is_out_rent,            
            // tenant is reserverd value
            tenantName: item.tenant,
            // Some translations etc.
            contract_direction: item.is_out_rent
              ? i18n.t("Renting out")
              : i18n.t("Renting in"),
            terminationTypeTr: i18n.t(item.terminationType),
            terminatingPartyTr: i18n.t(item.terminatingParty),
            followupAction,
          }
        })
    },
    isLoading: (context) => {
      const { endingContractsLoading } = context
      return endingContractsLoading
    },
  }
}
