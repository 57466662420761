<template>
  <!-- Adding tabindex="0" to div below, makes it selectable with tab. -->
  <div
    class="kanban-widget-chip-container d-flex align-center"  
    :draggable="enableModification"
    @dragstart="onDragStart($event)"
    @click="$emit('click')"
  >
    <v-avatar
      v-if="icon !== null"
      elevation="0"
      :size="35"
      class="no-hover ml-2"
      :color="icon.backgroundColor"
    >
      <v-icon :color="icon.color">
        {{ icon.icon }}
      </v-icon>
    </v-avatar>
    <div class="kanban-widget-chip-text-container">
      <div class="kanban-widget-chip-title">
        {{ title }}
      </div>
      <div class="kanban-widget-chip-subtitle">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    icon: { type: Object, default: null },
    enableModification: { type: Boolean, default: false }
  },
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    onDragStart (event){
      this.$emit("dragstart", event)
    }
  },
}
</script>
<style scoped>
.kanban-widget-chip-container {
  height: 60px;
  background-color: white;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: var(--r-spacing-s);
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
}

.kanban-widget-chip-container:hover,
.kanban-widget-chip-container:focus {
	box-shadow: 0px 4px 8px 0px rgba(39, 57, 67, 0.2);
}

.kanban-widget-chip-text-container {
  margin-left: 10px;
}

.kanban-widget-chip-title {
  font-size: 16px;
  color: #8b8b8b;
  font-weight: bold;
}

.kanban-widget-chip-subtitle {
  font-size: 12px;
  color: #8b8b8b;
}
</style>
