<template>
  <v-row
    id="statusportfolio"
  >
    <template v-if="portfolio">
      <v-col
        lg="3"
        md="3"
        sm="12"
        cols="12"
      >
        <div
          v-for="widget in smallWidgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widget.loading"
          />
        </div>
      </v-col>
      <v-col
        lg="9"
        md="9"
        sm="12"
        cols="12"
      >
        <div
          v-for="widget in largeWidgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widget.loading"
          />
        </div>
      </v-col>
    </template>
    <template v-else>
      <h2>{{ $t("internalrent.select_portfolio") }}</h2>
    </template>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DynamicWidget from '../DynamicWidget'
import helpers from '../../helpers'

export default {
  name: 'StatusPortfolio',
  components: {
    DynamicWidget
  },
  data () {
    return {
      organizationItems: [],
      reportData: {},
      allWidgets: [
        {
          id: 'internalrent_portfolio.areas_by_domains',
          type: 'PieDataGraph',
          data: {
            items: [],
            sets: [
              {
                title: this.$t('Allocated area'),
                groupBy: 'domain',
                method: 'sum',
                parameters: ['area']
              }
            ],
            label: 'mainUsage',
            field: 'mainUsage',
            report: 'spacesByMainUsages'
          },
          small: true,
          loading: true
        },
        {
          id: 'internalrent_portfolio.costcenters_by_expense_type',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'costcentersBySpace'
          },
          bgnHeaders: [
            { text: this.$t('Building name'), value: 'buildingName' },
            { text: this.$t('Floor'), value: 'floorName' },
            { text: this.$t('Room'), value: 'spaceNumber', readAs: 'text' },
            { text: this.$t('Purpose of use'), value: 'purpose' },
            {
              text: this.$t('Business unit'),
              value: 'businessUnit',
              readAs: 'text'
            },
            { text: this.$t('Domain'), value: 'domain', readAs: 'text' },
            {
              text: this.$t('Cost center'),
              value: 'costcenterCode',
              readAs: 'text'
            },
            {
              text: this.$t('Cost center name'),
              value: 'costcenterName',
              readAs: 'text'
            },
            { text: this.$t('Net room area'), value: 'roomArea', format: 'Area' },
            { text: this.$t('Share'), value: 'share', format: 'Percentage' },
            { text: this.$t('Share from room area'), value: 'shareFromArea', format: 'Area' },
            { text: this.$t('Allocated area'), value: 'allocatedArea', format: 'Area' }
          ],
          endHeaders: [
            {
              text: this.$t('Total (€/mth)'),
              value: 'totalRentByMonth',
              format: 'Euro'
            },
            {
              text: this.$t('Total (€/m2/mth)'),
              value: 'totalRentBySquare',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost (€/mth)'),
              value: 'additionalCost',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost for purpose'),
              value: 'additionalCostForPurpose',
              format: 'Euro'
            }
          ],
          bgnFooters: [
            { text: this.$t('Total') },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            { value: 'allocatedArea', format: 'Area' }
          ],
          endFooters: [
            { value: 'totalRentByMonth', format: 'Euro' },
            {},
            { value: 'additionalCost', format: 'Euro' },
            { value: 'additionalCostForPurpose', format: 'Euro' }
          ],
          loading: true
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['buildings', 'currentDate']),
    ...mapState('internalRent', [
      'costcenters',
      'expenses',
      'portfolio',
      'rules'
    ]),
    smallWidgets () {
      return this.allWidgets.filter(w => w.small === true)
    },
    largeWidgets () {
      return this.allWidgets.filter(w => w.small !== true)
    }
  },
  methods: {
    ...mapActions('internalRent', [
      'getCostcenters',
      'getPortfolio',
      'getRules',
      'updateRules'
    ]),
    updateWidgetData () {
      this.allWidgets.forEach((w, idx) => {
        w.data.items = this.fetchReport(w.id)
        if (w.data.items === null) {
          w.data.items = []
        }
        w.loading = false
      })
    },
    updateWidgetHeaders (widgetId, expenses) {
      const widget = this.allWidgets.find(x => x.id === widgetId)

      const newHeaders = []
      const newFooters = []

      for (var idExpense of expenses) {
        newHeaders.push({
          text: this.expenses.find(x => x.id === idExpense).name,
          value: String(idExpense) + 'Rent',
          format: 'Euro'
        })
        newFooters.push({
          value: String(idExpense) + 'Rent',
          format: 'Euro'
        })
      }

      widget.data.headers = [
        ...widget.bgnHeaders,
        ...newHeaders,
        ...widget.endHeaders
      ]

      widget.data.footers = [
        ...widget.bgnFooters,
        ...newFooters,
        ...widget.endFooters
      ]
    },
    fetchReport (widget) {
      if (!this.portfolio) {
        return []
      }
      if (widget === 'internalrent_portfolio.spaces_by_main_usages') {
        return this.reportData.CountMainUsagesByPortfolio.filter(
          x => x.idPortfolio === this.portfolio.id
        ).map(x => {
          return {
            mainUsage: this.$t('pou.' + x.mainUsage),
            count: x.count,
            area: x.area
          }
        })
      } else if (widget === 'internalrent_portfolio.areas_by_domains') {
        return this.reportData.CostcentersBySpace.map(x => {
          return {
            domain: x.domainCode + ' - ' + x.domainName,
            area: x.allocatedArea
          }
        })
      } else if (
        widget === 'internalrent_portfolio.costcenters_by_expense_type'
      ) {
        const expenseIds = []

        // Get ids of distinct expenses
        this.reportData.CostcentersBySpace.map(x => x.expenses).forEach(x => {
          x.forEach(y => {
            if (!expenseIds.includes(y.idExpense)) {
              expenseIds.push(y.idExpense)
            }
          })
        })
        this.updateWidgetHeaders(widget, expenseIds)
        const data = this.reportData.CostcentersBySpace.map(x => {
          const expenses = x.expenses
          for (const expense of expenses) {
            x[String(expense.idExpense) + 'Rent'] = expense.amount
          }
          x.domain = x.domainCode + ' - ' + x.domainName
          x.businessUnit = x.businessUnitCode + ' - ' + x.businessUnitName
          return x
        })
        return data
      } else {
        return []
      }
    },
    getValueWithUnit (value, header) {
      if (header.indexOf('date') >= 0) {
        return helpers.humanize.date(value, 'date')
      }

      if (header.indexOf('area') >= 0) {
        return value + ' m&sup2;'
      }

      if (
        header.indexOf('capitalRent') >= 0 ||
        header.indexOf('maintenanceRent') >= 0 ||
        header.indexOf('totalRentByMonth') >= 0
      ) {
        if (isNaN(Number(value))) {
          return '-'
        }
        return Number(value).toFixed(2) + ' €'
      }

      if (header.indexOf('trend') >= 0) {
        return (value > 0 ? '+' : '') + Number(value).toFixed(0) + '%'
      }

      return value
    }
  },
  watch: {
    portfolio: async function () {
      for (const widget of this.allWidgets) {
        widget.loading = true
      }
      this.reportData = await this.$rambollfmapi.internalrent.reports({
        time: this.currentDate,
        idPortfolio: this.portfolio.id,
        portfolioIds: [ this.portfolio.id ],
        reports: ['ExpensesBySpace', 'CostcentersBySpace']
      })
      this.updateWidgetData()
    },
    currentDate: function (newDate) {
      for (const widget of this.allWidgets) {
        widget.loading = true
      }
      this.$rambollfmapi.internalrent
        .reports({
          time: this.currentDate,
          idPortfolio: this.portfolio.id,
          portfolioIds: [ this.portfolio.id ],
          reports: [
            'CountMainUsagesByPortfolio',
            'ExpensesBySpace',
            'CostcentersBySpace'
          ]
        })
        .then(res => {
          this.reportData = res
          this.updateWidgetData()
        })
    }
  },
  async mounted () {
    this.organizationItems = await this.$rambollfmapi.organizations
      .costCenters()
      .list()
    if (this.portfolio) {
      this.reportData = await this.$rambollfmapi.internalrent.reports({
        time: this.currentDate,
        idPortfolio: this.portfolio.id,
        portfolioIds: [ this.portfolio.id ],
        reports: [
          'CountMainUsagesByPortfolio',
          'ExpensesBySpace',
          'CostcentersBySpace'
        ]
      })
      this.updateWidgetData()
    }
  }
}
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}
#statusportfolio > .col {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}
#statusportfolio .widget:not(:first-child) {
  padding-top: 1em;
}
</style>
