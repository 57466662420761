import { prospectTypeColors } from "../../constants/colors"
import { getProspectType } from "../../helpers/leasing/prospect"
import { i18n } from "../../plugins/i18n"

export function rentingProcessWorkQueue () {
  return {
    id: "leasing.renting_process_work_queue",
    type: "KanbanWidget",
    data: {
      headers: [],
      items: [],
      dynamicHeaders: true,
      filter: {
        header: "filterField",
        type: "like",
        options: [],
      },
      dynamicFilterOptions: true,
      filterHeaders: [
        { text: 'Search corporations', value: "generalSearch" },
      ]
    },
    large: true,
    maximized: [false],
    loading: true,
  }
}

export function getRentingProcessWorkQueueHeaders (stages) {
  return stages.filter(s => s.id !== 595).map((stage) => ({
    text: stage.label,
    title: "corporation",
    subTitle: "area_and_sites",
    icon: "icon",
    event: "onClickRentingProcessWorkQueue",
    selector: (item) => item.stage === stage.label,
    footer: (items) => {

      const areaSum = items.reduce((acc, cur) => {
        if(cur && cur.area_preference){
          return acc + cur.area_preference
        }
        return acc
      }, 0)

      return `${items.length} ${i18n.t("pcs")} / ${areaSum} m\u00B2`
    }
  }))
}

export function toRentingProcessWorkQueueData (processes, sites) {
  const sitesById = sites.reduce((acc, cur) => {
    acc[cur.id_site] = cur
    return acc
  }, {})

  return processes
    .filter(
      (process) => process.status !== "Closed" && process.status !== "Inactive"
    )
    .map((process) => {
      let area_and_sites = ""

      if (process.area_preference) {
        area_and_sites = `${process.area_preference} m\u00B2`
      }

      if (process.nameless_search) {
        process.corporation = process.tag
      }

      let filterField = "No assigned site"

      if (process.sites && process.sites.length > 0) {
        filterField = process.sites
          .map((linkedSite) => {
            const site = sitesById[linkedSite.id_site]

            if (site && site.rent_priority) {
              return site.rent_priority
            }

            return ""
          })
          .join(",")

        // Also add first site name and site count if necessary
        if (process.area_preference) {
          area_and_sites += ", "
        }
        const firstSite = sitesById[process.sites[0].id_site]
        if (firstSite) {
          const name = firstSite.commercial_name && firstSite.commercial_name.trim() !== "" ? firstSite.commercial_name  : firstSite.name
          area_and_sites += `${name}`
        }
        if (process.sites.length > 1) {
          area_and_sites += ` (+${process.sites.length - 1})`
        }
      }

      let icon = null
      const type = getProspectType(process)
      if (type) {
        icon = {
          icon: type.typeIcon,
          backgroundColor: prospectTypeColors[type.typeText],
          color: "white",
        }
      }

      return {
        ...process,
        area_and_sites,
        icon,
        filterField,
      }
    })
    .sort((a, b) => {
      if (a.area_preference && b.area_preference) {
        const areaDifference = b.area_preference - a.area_preference
        if (areaDifference !== 0) {
          return areaDifference
        }
        return compareByCorporation(a, b)
      }
      if (a.area_preference) {
        return -1
      }
      if (b.area_preference) {
        return 1
      }
      return compareByCorporation(a, b)
    })
}

function compareByCorporation (a, b) {
  if (a.corporation && b.corporation) {
    return a.corporation.localeCompare(b.corporation, i18n.locale, { sensitivity: 'base' })
  }
  if (a.corporation) {
    return -1
  }
  if (b.corporation) {
    return 1
  }
  return 0
}
