<template>
  <v-expansion-panels
    class="my-2"
    multiple
    :value="[0]"
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="py-1 pl-2">
        {{ $t('site.rental_contracts_by_organization') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-for="onecontract in getContracts(contracts)"
          :key="onecontract.contract_number"
        >
          <v-expansion-panels
            class="my-2 innerpanel"
            multiple
            :value="[0]"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="subheader">
                {{ onecontract.contract_number + ' ' + onecontract.party }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <MetadataForm
                  :metadata="getBasicMetadataForUnit(unit)"
                  :data="getBasicDataForUnit(unit, onecontract)"
                  :with-status-icon="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import MetadataForm from '../../components/MetadataForm'
export default {
    name: 'UnitContractInformation',
    components: { MetadataForm },
    props: {
      unit: {
        type: Object,
        default: null
      },
      show: { type: Object, default: null },
      unitExtraInformations: { type: Array, default: () => [] },
      unitVisibilityRights: { type: Object, default: null },
      layer: { type: String, default: '' },
      readonly: { type: Boolean, default: null },
      contract: { type: Object, default: null},
      contracts: { type: Array, default: () => []}
    },
    data () {
      return {
        isSavingChanges: false
      }
    },
    methods: {
      getBasicDataForUnit (unit, onecontract) {
        let contractOrg = onecontract //s.find (x => x.unit_id = unit.id)
        let fields = {
           user_Organization : contractOrg.organization,
           leased_percentage : this.getLeasePercentage(contractOrg.rent_percentage),
           start_date: contractOrg.start_date,
           contract_area: contractOrg.contract_area

          }
          return fields
      },
    getBasicMetadataForUnit (unit) {
        let metaData= {
            user_Organization: {
                format:'None',
                isShown: true,
                category: 'None'
            },
            contract_area: {
                format:'Area',
                isShown: true,
                category: 'None'
            },
            start_date: {
                format:'Date',
                isShown: true,
                category: 'None'
            },
            end_date: {
                format:'Date',
                isShown: true,
                category: 'None'
            },
            leased_percentage: {
                format:'None',
                isShown: true,
                category: 'None'
            },
        }
      return metaData
    },
    getLeasePercentage (percentage)
    {
        if (percentage === null || percentage === 0)
        {
            return 100
        }
        else
        {
            return percentage
        }
    },
    getContracts (contracts)
    {
      // get data from one contract row for contract
      contracts = contracts.filter((value, index, self) =>
        index === self.findIndex((t) => (
        t.contract_number === value.contract_number
        ))
      )
      return contracts
    }
  }
}
</script>
<style scoped>
.subheader {
  font-weight: bold;
  background-color: transparent;
  border-style: none;
}
.innerpanel {
  border-style: none;
}
</style>