<template>
  <v-chip
    v-if="item.prospect_status === 'AwaitingAllocation'"
    class="ma-1"
    small
    :class="getClass(item)"
    text-color="white"
  >
    {{ $t('prospect.awaiting') }} <br>
    {{ $t('prospect.allocation') }}
  </v-chip>
  <v-chip
    v-else-if="item.prospect_status === 'Done (no report)'"
    class="ma-1 closed extra-height"
    small
      
    text-color="white"
  >
    {{ $t('Done') }} <br>
    ({{ $t('prospect.no_report') }})
  </v-chip>
  <v-chip
    v-else-if="!!getBackgroundColor(item.prospect_status)"
    small
    :style="{ backgroundColor: getBackgroundColor(item.prospect_status) }"
    text-color="white"
  >
    {{ $t(item.prospect_status) }}
  </v-chip>
  <v-chip
    v-else
    class="ma-1"
    small
    :class="getClass(item)"
    text-color="white"
  >
    {{ $t(item.prospect_status) }}
  </v-chip>
</template>

<script>
import { rentalProcessStageColors } from '../../../constants/colors'

export default {
  name: "ProspectStatusColumn",
  props: {
    item: { type: Object, default: null },
  },
  methods: {
    getClass (item){
      const classValue = item?.prospect_status?.replace(' ', '').toLowerCase()
      return classValue
    },
    getBackgroundColor (value){
      return rentalProcessStageColors[value]
    },
  }
}
</script>

<style scoped>
.eiajankohtainen {
  background-color: #acabab !important;
}

.aloitamyöhemmin {
  background-color: #78adcf !important;
}
</style>
