<template>
  <div
    style="position: relative"
    class="info-box-form"
  >
    <v-progress-circular 
      v-if="responsibilitiesLoading"
      size="64"
      indeterminate
      color="primary"
      style="margin: 3em"
    />
    <v-list
      v-else
      dense
      class="info-box"
    >
      <v-list-item
        v-for="lp in listedParties"
        :key="lp.id"
        class="tile"
      >
        <v-list-item-avatar>
          <v-icon
            color="white"
            class="icon-background"
          >
            face
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content
          class="responsibilities-box tile-content"
        >
          <div class="name">
            {{ lp.party.name }}
          </div>
          <div>{{ $t(definitionLabelById(lp.id_annotation)) }}</div>
          <div v-if="lp.party.liaison">
            {{ lp.party.liaison }}
          </div>
          <div v-if="lp.party.phone_number">
            <v-icon small>
              call
            </v-icon>
            {{ lp.party.phone_number }}
          </div>
          <div v-if="lp.party.email">
            <v-icon small>
              email
            </v-icon>
            {{ lp.party.email }}
          </div>
          <div v-if="lp.party.comment">
            <v-icon small>
              notes
            </v-icon>
            {{ lp.party.comment }}
          </div>
        </v-list-item-content>
        <v-list-item-content
          v-if="!disabled"
          class="tile-action"
          style="min-width: auto !important; max-width: 50px"
        >
          <div>
            <v-btn
              icon
              @click="openDialog(lp)"
            >
              <v-icon>edit</v-icon>
              <span class="d-sr-only">{{ $t('Modify') }}</span>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog
      v-model="responsibilityDialog"
      persistent
      max-width="550"
    >
      <AriaLive />
      <BaseModal
        @cancel="responsibilityDialog = false"
      >
        <template #title>
          {{ $t('Responsibility') }}
        </template>
        <template #content>
          <v-combobox
            v-model="newParty.annotation"
            :items="responsibilities"
            class="form-field"
            item-text="label"
            item-value="id"
            :label="$t('Responsibility')"
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              {{ $t(data.item.label) }}
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              {{ $t(data.item.label) }}
            </template>
          </v-combobox>
          <v-combobox
            v-model="newParty.party"
            :items="allParties"
            class="form-field"
            item-text="name"
            item-value="id"
            :label="$t('Party')"
          />
        </template>
        <template #footer>
          <CdsButton
            v-if="modifying"
            type="danger"
            @click="removeResponsibility(newParty.partyLink)"
          >
            {{ $t('Remove responsibility') }}
          </CdsButton>
          <CdsButton
            type="accent"
            @click="editResponsibility()"
          >
            <span v-if="modifying">{{ $t('Edit responsibility') }}</span>
            <span v-else>{{ $t('Add responsibility') }}</span>
          </CdsButton>
        </template>
      </Basemodal>
      <template #activator="{on}">
        <div class="ma-2">
          <CdsButton
            v-if="siteId"
            type="secondary"
            :disabled="disabled"
            icon-left="add"
            block
            class="ma-0"
            v-on="on"
            @click="openDialog()"
          >
            {{ $t('Add responsibility') }}
          </CdsButton>
        </div>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CdsButton from './CircleDesignSystem/CdsButton.vue'
import BaseModal from './general/BaseModal'
import AriaLive from './AriaLive.vue'
export default {
  components: {
    AriaLive,
    BaseModal,
    CdsButton
  },
  props: ['parties', 'partyLinks', 'siteId', 'disabled', 'responsibilitiesLoading'],
  data () {
    return {
      newParty: {
        party: null,
        partyLink: null,
        annotation: null
      },
      allParties: [],
      allPartyLinks: [],
      responsibilityDialog: false,
      modifying: false
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'classificationIdByName', 'definitionLabelById']),
    responsibilities () {
      return [
        ...this.definitionsByGroupLabel('site.responsibility'),
        ...this.definitionsByGroupLabel('role')
      ].sort((a, b) => {
        return this.$t(a.label) < this.$t(b.label) ? -1 : 1
      })
    },
    listedParties () {
      if (this.partyLinks === undefined) {
        return []
      }
      return this.partyLinks.map(link => {
        return {
          ...link,
          party: this.parties.find(p => p.id === link.id_party)
        }
      })
    }
  },
  async mounted () {
    this.allParties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })
  },
  methods: {
    openDialog (link) {
      this.initDialog()
      this.modifying = false
      if (typeof link !== 'undefined') {
        this.$log.info(
          'Editing party ',
          link.party,
          link
        )
        // Open responsibility dialog with this party
        this.newParty.party = link.party
        this.newParty.partyLink = link
        this.newParty.annotation = this.responsibilities.find(
          r =>
            r.id ===
            link.id_annotation
        )
        this.$log.info(this.newParty)
        this.modifying = true
      }

      this.responsibilityDialog = true
    },
    initDialog () {
      this.newParty = {
        party: null,
        partyLink: null,
        annotation: null
      }
    },
    cancel () {
      if (this.responsibilityDialog === true) {
        this.responsibilityDialog = false
      }
      if (this.isButtonPushed === true) {
        this.isButtonPushed = false
      }
    },
    removeResponsibility (partyLink) {
      this.$rambollfmapi.sites
        .partyLinks(this.siteId)
        .delete(this.siteId, partyLink.id)
        .then(response => {
          this.responsibilityDialog = false
          this.initDialog()
          this.$store.dispatch('app/addAriaLive', this.$t('Succesfull delete'))
          this.$emit('update')
        })
    },
    editResponsibility () {
      if (this.modifying) {
        // let's make the diff
        const original = JSON.parse(JSON.stringify(this.newParty.partyLink))
        const observer = this.$jsonpatch.observe(original)
        original.id_annotation = this.newParty.annotation.id
        original.id_party = this.newParty.party.id

        const patch = this.$jsonpatch.generate(observer)

        this.$rambollfmapi.sites
          .partyLinks(this.siteId)
          .patch(this.siteId, original.id, undefined, patch)
          .then(response => {
            this.responsibilityDialog = false
            this.initDialog()
            this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
            this.$emit('update')
          })
      } else {
        this.$rambollfmapi.sites
          .partyLinks(this.siteId)
          .post(this.siteId, {
            id_annotation: this.newParty.annotation.id,
            id_party: this.newParty.party.id,
            group: this.classificationIdByName('Responsibility')
          })
          .then(response => {
            this.responsibilityDialog = false
            this.initDialog()
            this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
            this.$emit('update')
          })
      }
    }
  }
}
</script>
<style scoped>
.icon-background {
  background: #64b5f6;
}
</style>
