export default {
  namespaced: true,
  state: {
    errors: [],
    errorCount: 0
  },
  getters: {
    getErrors: state => {
      return state.errors
    }
  },
  mutations: {
    setNewError (state, error) {
      // Increment error count and use it as the unique key value for error.
      // Vue components can then also use it as key.
      state.errorCount++
      error.key = state.errorCount
      state.errors = [...state.errors, error]
    },
    clearOneError (state, errorKey) {
      state.errors = state.errors.filter(x => x.key !== errorKey)
    },
    clearAllErrors (state) {
      state.errors = []
    }
  },
  actions: {
    addError ({ commit }, error) {
      if(Array.isArray(error))
        commit('setNewError', { title: error[0], info: error[1] })
      else
        commit('setNewError', { title: error})
    },
    clearError ({ commit }, errorKey) {
      commit('clearOneError', errorKey)
    },
    clearErrors ({ commit }) {
      commit('clearAllErrors')
    }
  }
}
