<template>
  <div>
    <v-row
      id="site"
      class="layout-white"
      no-gutters
    >
      <AriaLive />
      <h1 class="d-sr-only">
        {{ $t('User groups') }}
      </h1>
      <v-col
        v-if="isLoading"
        style="text-align: center; padding: 2em"
      >
        <v-progress-circular
          :width="7"
          :size="80"
          indeterminate
          color="primary"
        />
        <strong>{{ $t('Loading. Please wait...') }}</strong>
      </v-col>
      <v-col
        v-else
        class="body-text"
      >
        <CdsButton
          v-if="$vuetify.breakpoint.mobile"
          type="tertiary"
          :aria-label="$t('Open usergroup selection view')"
          icon-left="menu"
          @click="toggleMenu"
        >
          {{ $t('Menu') }}
        </CdsButton>
        <v-row no-gutters>
          <v-col
            v-show="menuVisible"
            lg="3"
            cols="12"
          >
            <v-navigation-drawer
              class="organizationNavigation"
              permanent
              width="auto"
              touchless
            >
              <v-toolbar
                text
                tile
              >
                <v-toolbar-title>
                  {{ $t('User Organizations') }}
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items v-if="canModifyOrganizations">
                  <NewUserOrganizationDialog
                    @saved="showIndicator = true; operationResult='success'; indicatorMessage = $t('Succesfull save'); getOrganizations()"
                    @failure="showIndicator = true; operationResult='error'; indicatorMessage = $t('Unsuccesfull save')"
                  />  
                </v-toolbar-items>
              </v-toolbar>
              <div 
                v-for="(org, index) in organizations"
                :key="org.id"
              >
                <v-toolbar
                  :class="isHilited(org.id) ? 'active': 'bg-gray'"
                  text
                  tile
                >
                  <v-toolbar-title>
                    {{ index +1 }}. {{ org.name }}
                  </v-toolbar-title> 
                  <v-spacer />
                  <v-toolbar-items v-if="org.name !== 'INTEGRATIONS'">
                    <NewUserGroupDialog
                      :user-organization="org"
                      @saved="showIndicator = true; operationResult='success'; indicatorMessage = $t('Succesfull save'); getOrganizations()"
                      @failure="showIndicator = true; operationResult='error'; indicatorMessage = $t('Unsuccesfull save')"
                    />
                  </v-toolbar-items>
                </v-toolbar>
                <v-list
                  dense
                  class="pt-0"
                >
                  <v-list-item
                    v-for="ug in userGroups.filter(item => org.groups.find(el => el.id == item.groupId))"
                    :key="ug.groupId"
                    :to="{ name: 'settings.usergroups', params: { id_group: ug.groupId }}"
                    @click="toggleMenu"
                    @keyup.enter="toggleMenu"
                  >
                    <v-list-item-content class="groupIcon">
                      <v-icon>group</v-icon>
                    </v-list-item-content>

                    <v-list-item-content>                 
                      <v-list-item-title>{{ ug.groupName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-navigation-drawer>
          </v-col>
          <v-col
            lg="9"
            cols="12"
          >
            <div
              v-if="editingOrg()"
            >
              <v-card flat>
                <v-card-title
                  primary-title
                  class="text-h5"
                >
                  {{ $t('Organization information') }}
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selectedOrg.name"
                        :label="$t('Name')"
                        class="custom_field"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="selectedOrg.comment"
                        :label="$t('Description')"
                        class="custom_field"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div
              v-if="userGroup"
              :key="userGroup.groupId"
            >
              <v-card flat>
                <v-card-title
                  primary-title
                  class="text-h5"
                >
                  {{ $t('Group information') }}
                </v-card-title>

                <v-card-text>
                  <v-text-field
                    v-model="userGroup.groupName"
                    :label="$t('Name')"
                    class="custom_field"
                  />

                  <v-text-field
                    v-model="userGroup.description"
                    :label="$t('Description')"
                    class="custom_field"
                  />
                </v-card-text>
              </v-card>
              <v-col
                v-if="isOrgAdmin(userGroup)"
                style="margin-left: 0.5em; padding: 0.5em"
                class="text-center font-weight-bold"
              >
                {{ $t('Organization permit warning') }}
              </v-col>
              <v-col style="padding: 0.5em">
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Application permissions') }}
                  </v-card-title>
                  <v-card-text v-if="canModifyUsergroup && userGroup && userGroups">
                    <v-autocomplete
                      v-model="userGroup.applicationPermissions"
                      :items="getApplications()"
                      :label="$t('Application permissions')"
                      item-text="name"
                      multiple
                      small-chips
                      hide-details
                      return-object
                    >
                      <template #selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          class="v-chip--select-multi"
                          close
                          @click:close="removeAppPermission(data.item.id)"
                          @click="userGroup.applicationPermissions[data.index].accessLevel === 0 ? userGroup.applicationPermissions[data.index].accessLevel = 1 : userGroup.applicationPermissions[data.index].accessLevel = 0;"
                          @click.stop
                          @input="data.parent.selectItem(data.item)"
                        >
                          <v-icon
                            v-if="userGroup.applicationPermissions[data.index].accessLevel === 0"
                          >
                            visibility
                          </v-icon>
                          <v-icon
                            v-if="userGroup.applicationPermissions[data.index].accessLevel === 1"
                          >
                            edit
                          </v-icon>
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                    <CdsButton
                      type="tertiary"
                      class="selection_button"
                      @click="userGroup.applicationPermissions.length === 0 ? userGroup.applicationPermissions = [...getApplications()] : userGroup.applicationPermissions = []"
                    >
                      {{ $t('Add or delete all') }}
                    </CdsButton>
                    <CdsButton
                      type="tertiary"
                      class="selection_button"
                      @click="userGroup.applicationPermissions.forEach(p => {p.accessLevel === 0 ? p.accessLevel = 1 : p.accessLevel = 0; return p})"
                    >
                      {{ $t('Add or delete write access') }}
                    </CdsButton>
                  </v-card-text>
                  <v-card-text v-else>
                    <template v-for="appPerm in userGroup.applicationPermissions.filter(app => getApplications().find(ugapp => ugapp.id === app.id))">
                      <v-chip
                        :key="JSON.stringify(appPerm)"
                        :disabled="true"
                        class="v-chip--select-multi"
                      >
                        <v-icon
                          v-if="appPerm.accessLevel === 0"
                        >
                          visibility
                        </v-icon>
                        <v-icon
                          v-if="appPerm.accessLevel === 1"
                        >
                          edit
                        </v-icon>
                        {{ appPerm.name }}
                      </v-chip>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-if="canModifyUsergroup && hasDocumentControlAccess"
                style="padding: 0.5em"
              >
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Document permissions') }}
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                      v-model="documentFullAccess"
                      class="full-width"
                    >
                      <v-card
                        card
                        style="width: 100%"
                      >
                        <v-card-title>
                          <v-radio
                            :key="true"
                            :label="$t('Allow access to all documents')"
                            :value="true"
                          />
                        </v-card-title>
                        <v-card-text
                          v-if="documentFullAccess"
                          style="margin-left: 1em"
                        >
                          <v-checkbox
                            v-model="documentFullAccessWrite"
                            :label="$t('Write access')"
                          />
                        </v-card-text>
                      </v-card>

                      <v-card
                        card
                        style="width: 100%"
                      >
                        <v-card-title>
                          <v-radio
                            :key="false"
                            :label="$t('Allow access to certain documents')"
                            :value="false"
                          />
                        </v-card-title>
                        <v-card-text
                          v-if="!documentFullAccess"
                          style="margin-left: 1em"
                        >
                          <v-dialog
                            v-model="documentShowAddNewRuleDialog"
                            persistent
                            max-width="550"
                          >
                            <template #activator="{ on }">
                              <CdsButton
                                type="tertiary"
                                icon-left="playlist_add"
                                v-on="on"
                              >
                                {{ $t('Add rule-based permission') }}
                              </CdsButton>
                            </template>
                            <BaseModal
                              @cancel="closeDocumentRuleDialog()"
                            >
                              <template #title>
                                {{ $t('Add a new rule') }}
                              </template>
                              <template #content>
                                <v-form
                                  ref="form"
                                  v-model="valid"
                                  lazy-validation
                                >
                                  <v-col class="section-textfield">
                                    <strong class="text--secondary">
                                      {{ $t('Add documents that fulfill the following criteria.') }}
                                    </strong>
                                  </v-col>
                                  <br>
                                  <v-row>
                                    <v-col
                                      v-for="option in dynamicDocumentPermissionOptions"
                                      :key="option.fieldName"
                                      cols="6"
                                    >
                                      <v-select
                                        v-if="option.fieldName ==='mimeType'"
                                        v-model="documentCurrentRule[option.fieldName]"
                                        :items="option.options"
                                        :label="option.fieldText"
                                        style="height: 36px !important"
                                        small
                                        flat
                                      />
                                      <v-text-field
                                        v-else
                                        v-model="documentCurrentRule[option.fieldName]"
                                        :label="option.fieldText"
                                        style="height: 36px !important"
                                        small
                                        flat
                                      />
                                    </v-col>
                                    <v-col
                                      class="shrink"
                                      style="padding-left: 1em; flex-justify: right"
                                    >
                                      <v-spacer />
                                      <v-checkbox 
                                        v-model="writeAccess"
                                        :label="$t('Write access')"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </template>
                              <template #footer>
                                <CdsButton
                                  type="primary"
                                  icon-left="add"
                                  @click="addDynamicDocumentPermission"
                                >
                                  {{ $t('Add rule') }}
                                </CdsButton>
                              </template>
                            </BaseModal>
                          </v-dialog>
                          <v-dialog
                            v-model="documentShowAddNewPermissionsDialog"
                            persistent
                            max-width="550"
                          >
                            <template #activator="{ on }">
                              <CdsButton
                                type="tertiary"
                                icon-left="add"
                                v-on="on"
                              >
                                {{ $t('Add permissions') }}
                              </CdsButton>
                            </template>
                            <BaseModal
                              @cancel="closePermissionDialog()"
                            >
                              <template #title>
                                {{ $t('Add new document permissions') }}
                              </template>
                              <template #content>
                                <v-col class="section-textfield">
                                  <strong class="text--secondary">
                                    {{ $t('Allow access to the selected documents') }}
                                  </strong>
                                </v-col>
                                <br>
                                <Search
                                  :endpoint="$store.state.app.apiEndpoint + 'documents/'"
                                  persistent
                                  multiple
                                  @select="(selection) => { documentPermissions = selection.results !== null ? selection.results.map(item => { return { name: item.title, id: item.idDocument }}) : [] }"
                                >
                                  <template
                                    slot="row"
                                    slot-scope="{result}"
                                  >
                                    {{ result.title }} ({{ result.type }})
                                  </template>
                                </Search>
                                <v-checkbox
                                  :label="$t('Write access')"
                                  style="margin-left: 1em"
                                />
                              </template>
                              <template #footer>
                                <CdsButton
                                  type="primary"
                                  icon-left="add"
                                  @click="userGroup.documentPermissions = [...userGroup.documentPermissions, ...documentPermissions]; documentShowAddNewPermissionsDialog = false"
                                >
                                  {{ $t('Add permissions') }}
                                </CdsButton>
                              </template>
                            </BaseModal>
                          </v-dialog>
                          <v-row class="flex-column">
                            <v-col>
                              <Table
                                :key="userGroup.dynamicDocumentPermissions + userGroup.documentPermissions"
                                :headers="documentPreviewHeaders"
                                :show-filters="false"
                                :pagination="documentPreviewPagination"
                                :data-source="previewDocuments"
                                no-expansion-panel
                                show-controls
                                @remove-row="(row) => { let idx = userGroup.documentPermissions.find(d => d.title === row.title); if (idx >= 0) { userGroup.documentPermissions.splice(idx, 1)} }"
                              >
                                <template
                                  slot="row-actions"
                                  slot-scope="{row}"
                                >
                                  <v-chip
                                    v-if="userGroup.documentPermissions.findIndex(d => Number(d.id) === Number(row.idDocument)) >= 0"
                                    small
                                    close
                                    @input="userGroup.documentPermissions.splice(userGroup.documentPermissions.findIndex(d => Number(d.id) === Number(row.idDocument)), 1)"
                                  >
                                    <v-icon>description</v-icon>
                                    {{ $t('Permission') }}
                                  </v-chip>
                                  <v-chip
                                    v-else
                                    small
                                  >
                                    <v-icon>playlist_add_check</v-icon>
                                    {{ $t('From rule') }}
                                  </v-chip>
                                </template>
                              </Table>
                            </v-col>
                            <v-col>
                              <v-chip
                                v-for="(permission, idx) in userGroup.dynamicDocumentPermissions"
                                :key="idx"
                                close
                                @click:close="removeDocumentPermission(idx)"
                              >
                                <v-icon v-if="permission.accessLevel === 0">
                                  visibility
                                </v-icon>
                                <v-icon v-if="permission.accessLevel === 1">
                                  edit
                                </v-icon>
                                {{ getDynamicPermissionText(permission) }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-else-if="hasDocumentControlAccess"
                style="padding: 0.5em"
              >
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Document permissions') }}
                  </v-card-title>
                  <v-card-text>
                    <v-card
                      v-if="documentFullAccess"
                      card
                      style="width: 100%"
                    >
                      <v-card-title>
                        {{ $t('Access to all documents') }}
                      </v-card-title>
                      <v-card-text
                        v-if="documentFullAccess"
                        style="margin-left: 1em"
                      >
                        {{ $t('Write access') }}
                      </v-card-text>
                    </v-card>

                    <v-card
                      v-else
                      card
                      style="width: 100%"
                    >
                      <v-card-title>
                        {{ $t('Access to certain documents') }}
                      </v-card-title>
                      <v-card-text
                        v-if="!documentFullAccess"
                        style="margin-left: 1em"
                      >
                        <v-row>
                          <v-col>
                            <Table
                              :key="userGroup.dynamicDocumentPermissions + userGroup.documentPermissions"
                              :headers="documentPreviewHeaders"
                              :show-filters="false"
                              :pagination="documentPreviewPagination"
                              :data-source="previewDocuments"
                              no-expansion-panel
                              show-controls
                              @remove-row="(row) => { let idx = userGroup.documentPermissions.find(d => d.title === row.title); if (idx >= 0) { userGroup.documentPermissions.splice(idx, 1)} }"
                            >
                              <template
                                slot="row-actions"
                                slot-scope="{row}"
                              >
                                <v-chip
                                  v-if="userGroup.documentPermissions.findIndex(d => Number(d.id) === Number(row.idDocument)) >= 0"
                                  small
                                  close
                                  @input="userGroup.documentPermissions.splice(userGroup.documentPermissions.findIndex(d => Number(d.id) === Number(row.idDocument)), 1)"
                                >
                                  <v-icon>description</v-icon>
                                      &nbsp;
                                  {{ $t('Permission') }}
                                </v-chip>
                                <v-chip
                                  v-else
                                  small
                                >
                                  <v-icon>playlist_add_check</v-icon>
                                      &nbsp;
                                  {{ $t('From rule') }}
                                </v-chip>
                              </template>
                            </Table>
                          </v-col>
                          <v-col>
                            <v-chip
                              v-for="(permission, idx) in userGroup.dynamicDocumentPermissions"
                              :key="idx"
                              close
                              @input="userGroup.dynamicDocumentPermissions.splice(idx, 1)"
                            >
                              <v-icon v-if="permission.accessLevel === 0">
                                visibility
                              </v-icon>
                              <v-icon v-if="permission.accessLevel === 1">
                                edit
                              </v-icon>
                                  &nbsp;
                              {{ getDynamicPermissionText(permission) }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Automatic right display. ONLY FOR 'INTEGRATIONS' ORGANIZATION -->
              <v-col v-if="isIntegrationOrg">
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Automatic rights') }}
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                      v-model="userGroup.group.automaticRights"
                      row
                      disabled
                    >
                      <v-radio
                        :label="$t('No automatic rights')"
                        :value="0"
                        class="noPadding"
                      />
                      <v-radio
                        :label="$t('View')"
                        :value="1"
                        class="noPadding"
                      />
                      <v-radio
                        :label="$t('Edit')"
                        :value="2"
                        class="noPadding"
                      />
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- End of automatic rights display -->

              <v-col>
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Site permissions') }}
                  </v-card-title>
                  <v-card-text>
                    <v-progress-circular
                      v-if="hierarchyLoading"
                      :size="50"
                      style="margin: auto"
                      color="primary"
                      indeterminate
                    />
                    <GroupHierarchyRights
                      v-else
                      v-model="currentUserGroup"
                      :admin-user-group="organizationAdminUserGroup"
                      :organizations="organizations"
                      @modify="modifyHierarchyPermissions"
                    />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col style="padding: 0.5em">
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="text-h5"
                  >
                    {{ $t('Widget permissions') }}
                  </v-card-title>
                  <v-card-text v-if="canModifyUsergroup">
                    <v-autocomplete
                      v-model="userGroup.widgetPermissions"
                      :items="getWidgets()"
                      :item-text="widgetLabel"
                      :label="$t('Widget permissions')"
                      item-value="name"
                      multiple
                      small-chips
                      hide-details
                      deletable-chips
                      return-object
                    >
                      <template #selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          class="v-chip--select-multi"
                          close
                          @click:close="removeWidgetPermission(data.item.id)"
                          @click="userGroup.widgetPermissions[data.index].accessLevel === 0 ? userGroup.widgetPermissions[data.index].accessLevel = 1 : userGroup.widgetPermissions[data.index].accessLevel = 0;"
                          @click.stop
                          @input="data.parent.selectItem(data.item)"
                        >
                          {{ widgetLabel(data.item) }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                    <CdsButton
                      type="tertiary"
                      class="selection_button"
                      @click="userGroup.widgetPermissions.length === 0 ? userGroup.widgetPermissions = [...getWidgets()] : userGroup.widgetPermissions = []"
                    >
                      {{ $t('Add or delete all') }}
                    </CdsButton>
                  </v-card-text>
                  <v-card-text v-else>
                    <template v-for="widgetPerm in userGroup.widgetPermissions">
                      <v-chip
                        :key="JSON.stringify(widgetPerm)"
                        :disabled="true"
                        class="v-chip--select-multi"
                      >
                        {{ widgetLabel(widgetPerm) }}
                      </v-chip>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-divider />
              <div
                v-if="canModifyUsergroup"
                style="text-align: right; padding:10px;"
              >
                <CdsButton
                  :disabled="indicatorRunning"
                  type="danger"
                  icon-left="delete"
                  @click="onConfirmDeletion(userGroup.groupId)"
                >
                  {{ $t("Delete") }}
                </CdsButton>
                <CdsButton
                  :disabled="indicatorRunning"
                  type="primary"
                  @click="saveUserGroup(userGroup.groupId, userGroup); indicatorRunning = true"
                >
                  {{ $t("Save") }}
                </CdsButton>
              </div>
              <div style="height: 0.5em" />
            </div>
          </v-col>
          <Alert
            :show="showIndicator"
            :result="operationResult"
            :message="indicatorMessage"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NewUserGroupDialog from '../components/NewUserGroupDialog.vue'
import NewUserOrganizationDialog from '../components/NewUserOrganizationDialog.vue'
import GroupHierarchyRights from '../components/GroupHierarchyRights'
import Table from '../components/Table.vue'
import Search from '../components/Search.vue'
import Alert from '../components/Alert.vue'
import BaseModal from '../components/general/BaseModal.vue'
import AriaLive from '../components/AriaLive.vue'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    NewUserGroupDialog,
    GroupHierarchyRights,
    NewUserOrganizationDialog,
    Table,
    Search,
    Alert,
    BaseModal,
    AriaLive,
    CdsButton
  },
  metaInfo () {
    return {
      title: `${this.$t('User groups')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      currentUserGroup: null,
      userGroups: [],
      organizations: [],
      allApplications: [],
      allApplicationAccessLevels: [],
      allSites: [],
      allBuildings: [],
      allStructures: [],
      allCemeteries: [],
      allWidgets: [],
      allDocuments: [],
      documentPreviewHeaders: [
        { text: this.$t('Title'), value: 'title', format: '' },
        { text: this.$t('Document type'), value: 'type', format: '' },
        { text: this.$t('DocFileType'), value: 'mimeType', format: '' }
      ],
      documentPermissions: [],
      documentPreviewPagination: {
        sortDesc: true,
        itemsPerPage: 5
      },
      documentCurrentRule: {},
      documentFullAccess: false,
      documentFullAccessWrite: false,
      documentShowAddNewRuleDialog: false,
      documentShowAddNewPermissionsDialog: false,
      showIndicator: false,
      operationResult: '',
      hilitedOrg: {},
      indicatorMessage: '',
      indicatorRunning: false,
      selectedOrg: {},
      hierarchyLoading: true,
      modifiedRights: { 
        sites: [],
        buildings: [],
        structures: [],
        cemeteries: []
      },
      writeAccess: false,
      menu: false,
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('app', ['siteIds']),
    dynamicDocumentPermissionOptions () {
      // In the future these values are fetched from database
      return [
        {
          fieldName: 'mimeType',
          fieldText: this.$t('DocFileType'),
          options: [
            { 'text': 'pdf', 'value': 'application/pdf' },
            { 'text': 'dwg', 'value': "'image/vnd.dwg', 'application/octet-stream', 'application/acad', 'image/x-dwg'" },
            { 'text': 'txt', 'value': 'text/plain' }
          ]
        },
        {
          fieldName: 'type',
          fieldText: this.$t('Document type'),
          options: null
        }
      ]
    },
    userGroup () {
      const groupId = this.$route.params.id_group
      const usergroup = this.userGroups.find(g => Number(g.groupId) === Number(groupId))
      return usergroup 
    },
    organizationAdminUserGroup () {
      const group = this.hilitedOrg.groups.find(group => group.is_admin)
      if (group === null) {
        return null
      } else {
        const groupId = group.id
        const usergroup = this.userGroups.find(g => Number(g.groupId) === Number(groupId))
        return usergroup 
      }
    },
    isLoading () {
      if (this.userGroups.length > 0) {
        return false
      }
      return true
    },
    customerId () {
      return this.$store.state.app.userInfo.customer_id
    },
    canModifyOrganizations () {
      return this.organizations.filter(o => o.is_admin == true).length > 0
    },
    canModifyUsergroup () {
      if (this.organizations.filter(o => o.is_admin == true).length > 0) {
        return true
      } else if (this.userGroup.group.isAdmin !== true && this.userGroups.filter(ug => ug.group.idUserOrganization === this.userGroup.group.idUserOrganization && ug.group.isAdmin === true).length > 0) {
        return true
      }
      return false
    },
    isIntegrationOrg () {
      if (this.hilitedOrg.name === 'INTEGRATIONS'){
        return true
      }
      return false
    },
    hasDocumentControlAccess () {
      return this.hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA')
    },
    menuVisible () {
      return this.$vuetify.breakpoint.mobile ? this.menu : true;
    }
  },
  watch: {
    'userGroup.dynamicDocumentPermissions': {
      handler: function () {
        if (this.userGroup !== undefined) {
          if (this.hasDocumentControlAccess) {
            if (this.userGroup.dynamicDocumentPermissions.length === 1) {
              const permission = this.userGroup.dynamicDocumentPermissions[0]
              if (permission.type === null && permission.mimeType === null) {
                this.documentFullAccess = true
                this.documentFullAccessWrite = permission.accessLevel !== 0
              }
            }

          }
        }
      },
      deep: true
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    userGroup: function () {
      this.hilitedOrg = this.organizations.filter(o => o.id == this.userGroup.group.idUserOrganization)[0]
      this.getCurrentUserGroup()
    },
    writeAccess: function () {
      this.documentCurrentRule.accessLevel = this.writeAccess ? 1 : 0
    }
  },
  async mounted () {
    this.getAllApplications()
    this.getAllWidgets()
    this.getOrganizations();
    if(this.hasDocumentControlAccess) {
      this.getAllDocuments()
    }
  },
  methods: {
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    async previewDocuments (pagination) {
      return this.$rambollfmapi.permissions
        .preview()
        .documents(this.userGroup, {
          query: {
            limit: pagination.itemsPerPage,
            offset: (pagination.page - 1) * pagination.itemsPerPage,
            sortBy: pagination.sortby
          }
        })
    },
    getCurrentUserGroup () {
      const groupId = this.$route.params.id_group
      this.currentUserGroup = this.userGroups.find(g => Number(g.groupId) === Number(groupId))
      this.currentUserGroup.applicationPermissions = this.userGroup.applicationPermissions.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
    getDynamicPermissionText (cur) {
      const params = Object.keys(cur)
        .map(key => {
          return { key: key, val: cur[key] }
        })
        .filter(
          obj =>
            obj.key !== 'accessLevel' &&
            obj.val !== undefined &&
            obj.val !== null
        )
      if (params.length === 0) {
        return this.$t('full access')
      }
      return params.map(p => 
        p.key === 'mimeType' ? 
        this.$t('DocFileType') + ": " + this.getDocumentExtensionFromMimeType(this.$t(p.val)) : 
        this.$t('Document type') + ': ' + this.$t(p.val))
        .join(', ')
    },
    getDynamicFullPermissionText (permissions) {
      if (typeof permissions === 'undefined') {
        return ''
      }

      return (
        'with ' +
        permissions
          .reduce((acc, cur) => {
            const accessLevel =
              cur.accessLevel === 0 ? 'read-only access' : 'read-write access'
            const params = Object.keys(cur)
              .map(key => {
                return { key: key, val: cur[key] }
              })
              .filter(
                obj =>
                  obj.key !== 'accessLevel' &&
                  obj.val !== undefined &&
                  obj.val !== null
              )
            acc.push(
              accessLevel +
                ' when ' +
                params.map(p => p.key + ' is ' + p.val).join(' and ')
            )
            return acc
          }, [])
          .join(' or ')
      )
    },
    widgetLabel (item) {
      return (
        (item.page === 1 ? '(Dashboard) '
          : item.page === 3 ? '(' + this.$t('Internal Rent') + ') '
          : item.page === 4 ? '(' + this.$t('Billing') + ') '
          : item.page === 5 ? '(' + this.$t('Contracts') + ') '
          : item.page === 6 ? '(' + this.$t('Leasing') + ') '
          : '(' + this.$t('Target site') + ') ')
        .concat(this.$i18n.t(item.name))
      )
    },
    onConfirmDeletion (userGroupId) {
      if (confirm(this.$t('Confirm delete (Usergroup)'))) {
        this.indicatorRunning = true
        this.deleteUserGroup(userGroupId)
      }
    },
    async deleteUserGroup (userGroupId) {
      const deleteGroupResult = await this.$rambollfmapi.permissions
        .groups()
        .delete(userGroupId)

      if(deleteGroupResult.name === 'Error') {
          this.showIndicator = true
          this.operationResult = 'error'
          this.indicatorMessage = this.$t('Unsuccesfull delete')
      } else {
          this.showIndicator = true
          this.operationResult = 'success'
          this.indicatorMessage = this.$t('Succesfull delete')
          this.getOrganizations()
      }
    },
    async saveUserGroup (id, group) {
      if (this.documentFullAccess) {
        group.dynamicDocumentPermissions = [
          {
            type: null,
            mimeType: null,
            accessLevel: this.documentFullAccessWrite ? 1 : 0
          }
        ]
        group.documentPermissions = []
      }

      const userGroupSave = await this.$rambollfmapi.permissions
        .groups()
        .put(id, group)

      /*const hieararchySave = */ await this.$rambollfmapi.permissions
        .groups()
        .patchHierarchy(id, this.modifiedRights)

      if(userGroupSave.name === 'Error') {
        this.showIndicator = true
        this.operationResult = 'error'
        this.indicatorMessage = this.$t('Unsuccesfull save')
      } else {
        this.showIndicator = true
        this.operationResult = 'success'
        this.indicatorMessage = this.$t('Succesfull save')
        //This rerenders building permissions
        this.getOrganizations();
      }

      group.dynamicDocumentPermissions = []
      group.documentPermissions = []

    },
    filterUserRights (values) {
      if (values.length === 0) {
        return this.allApplications
      }
      let newList = []
      newList = JSON.parse(JSON.stringify(this.allApplications))

      for (var i = 0; i < values.length; i++) {
        newList.filter(function (el) {
          return el.id !== values[i].id
        })
      }
      return newList
    },
    getApplicationAccessLevel (groupId, permissionId) {
      this.allApplicationAccessLevels = this.userGroups.reduce((acc, cur) => {
        acc[cur.groupId] = cur.applicationPermissions.reduce((acc, cur) => {
          acc[cur.id] = cur
          return acc
        })
        return acc
      }, {})

      const permission = this.allApplicationAccessLevels[groupId][permissionId]
      if (typeof permission !== 'undefined') {
        return permission.accessLevel
      }
      return 0
    },
    async getOrganizations () {
      this.hierarchyLoading = true
      let orgs = await this.$rambollfmapi.userOrganizations.list()
      let usergroups = await this.$rambollfmapi.accounts.usergroups.get()
      orgs = orgs.map((item) => {
        item.groups = usergroups.filter(g => g.id_userorganization == item.id)
        return item
      })
      // Since it was hoped that integration related organization would always be the second one but we can't ensure that
      // we could create the organization with ID of 2, we have perform slight index shifting so that at least in front-end
      // it appears below ADMIN organization
      if (orgs.some(o => o.name === 'INTEGRATIONS')) {
        var integrationIndex = orgs.findIndex(o => o.name === 'INTEGRATIONS')
        orgs = this.changeIndex(orgs, integrationIndex, 1); // Use custom method to shuffle index as desired
      }
      this.organizations = orgs;
      this.userGroups = await this.$rambollfmapi.permissions.groups().list()

      this.hierarchyLoading = false
    },
    changeIndex (arr, from, to) {
      var el = arr[from];
      arr.splice(from, 1);
      arr.splice(to, 0, el);
      return arr
    },
    async getAllApplications () {
      this.allApplications = (await this.$rambollfmapi.permissions.applications()).sort(
        (a, b) => {
          return a.name < b.name ? -1 : 1
        }
      )
    },
    async getAllWidgets () {
      this.allWidgets = (await this.$rambollfmapi.permissions.widgets()).sort(
        (a, b) => {
          if (a.name.split('.')[0] === b.name.split('.')[0]) {
            return this.$t(a.name) < this.$t(b.name) ? -1 : 1
          }
          return a.name.split('.')[0] < b.name.split('.')[0] ? -1 : 1
        }
      )
    },
    async getAllDocuments () {
      this.allDocuments = (await this.$rambollfmapi.permissions.documents()).sort(
        (a, b) => {
          return a.name < b.name ? -1 : 1
        }
      )
    },
    editingOrg (){
      if(!this.dialog){ // dont update selectedorg if adding group
        const orgId = this.$route.query.orgId
        this.selectedOrg = this.organizations.filter(org => org.id == orgId)[0]
        return this.selectedOrg ? true : false
      }
      return false  
    },
    isHilited (orgId){
      if(this.hilitedOrg){
        return this.hilitedOrg.id == orgId
      }
      return false
    },
    isOrgAdmin (userGroup) {
      if (userGroup.group.idUserOrganization !== 1 && userGroup.group.isAdmin === true) {
        return true
      }
      return false
    },
    getApplications () {
      var ugPermissions = []
      if (this.organizations.filter(o => o.is_admin == true).length > 0 && this.userGroup.group.isAdmin === true) {
        return this.allApplications
      } else if (this.userGroups.filter(ug => ug.isAdmin === true)) {
        ugPermissions = this.userGroups.filter(ug => ug.group.idUserOrganization === this.userGroup.group.idUserOrganization && ug.group.isAdmin === true)[0].applicationPermissions
        return this.allApplications.filter(app => ugPermissions.find(ug => ug.id === app.id))
      } else {
        ugPermissions = this.userGroup.group.applicationPermissions
        return this.allApplications.filter(app => ugPermissions.find(ug => ug.id === app.id))
      }
    },
    getWidgets () {
      var widgetPermissions = []
      if (this.organizations.filter(o => o.is_admin == true).length > 0 && this.userGroup.group.isAdmin === true) {
        return this.allWidgets
      } else if (this.userGroups.filter(ug => ug.isAdmin === true)) {
        widgetPermissions = this.userGroups.filter(ug => ug.group.idUserOrganization === this.userGroup.group.idUserOrganization && ug.group.isAdmin === true)[0].widgetPermissions
        return this.allWidgets.filter(app => widgetPermissions.find(ug => ug.id === app.id))
      } else {
        widgetPermissions = this.userGroup.group.widgetPermissions
        return this.allWidgets.filter(app => widgetPermissions.find(ug => ug.id === app.id))
      }
    },
    removeAppPermission (appPermId) {
      const appPermissionIdx = this.userGroup.applicationPermissions.findIndex(appPermission => appPermission.id === appPermId)
      this.userGroup.applicationPermissions.splice(appPermissionIdx, 1)
    },
    removeWidgetPermission (widgetId) {
      const widgetIdx = this.userGroup.widgetPermissions.findIndex(w => w.id === widgetId)
      this.userGroup.widgetPermissions.splice(widgetIdx, 1)
    },
    removeDocumentPermission (idx) {
      this.userGroup.dynamicDocumentPermissions.splice(idx, 1)
    },
    closeDocumentRuleDialog () {
      this.documentShowAddNewRuleDialog = false
      this.$refs.form.reset()
    },
    closePermissionDialog () {
      this.documentShowAddNewPermissionsDialog = false
    },
    modifyHierarchyPermissions (rights) {
      this.modifiedRights = rights
    },
    addDynamicDocumentPermission () {
      if(this.userGroup.dynamicDocumentPermissions.length > 0) {
        this.userGroup.dynamicDocumentPermissions = []
      }

      this.userGroup.dynamicDocumentPermissions.push(this.documentCurrentRule) 
      this. documentCurrentRule = {} 
      this.documentShowAddNewRuleDialog = false
      this.writeAccess = false
    },
    getDocumentExtensionFromMimeType (mimeType) {
      switch(mimeType) {
        case('application/pdf'):
          return 'pdf'
        case("'image/vnd.dwg', 'application/octet-stream', 'application/acad', 'image/x-dwg'"):
          return 'dwg'  
        case('text/plain'):
          return 'txt'
      }
    },
    toggleMenu () {
      this.menu = !this.menu
    },
  }
}
</script>
<style scoped>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}

.checkbox-width {
  min-width: 25% !important;
}

.noPadding {
  padding: 0;
  margin: 8px;
}

.fullscreen-settings {
  padding: 0px;
  height: 100%;
  width: 100%;
  right: 0;
  top: -111px;
  position: absolute;
  background-color: white;
}

.layout-white {
  background-color: white;
}

.v-text-field {
  height: auto !important;
}

.custom_field {
  height: 36px !important;
  display: inline-block;
  width: 48.5%;
}

div.v-input__control {
  flex-grow: 1 !important;
}

.active {
  background-color: var(--r-color-cyan-10) !important;
}

.bg-gray {
  background-color: rgb(235, 235, 235) !important;
}

.clickable:hover {
  cursor: pointer;
}

.organizationNavigation {
  width: 100%;
}

.groupIcon {
  max-width: 90px;
}

@media screen and (max-width: 600px) {
  .custom_field {
    width: 100%;
  }
  .selection_button {
    width: 100%;
  }
  .organizationNavigation {
    width: 100%;
  }
}
</style>
