<template>
  <div>
    <v-dialog
      v-model="showEditDialog"
      max-width="550"
      persistent
    >
      <v-card>
        <BaseModal
          @cancel="close()"
        >
          <template #title>
            {{ title }}
          </template>
          <template #content>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-container
                class="px-2 my-4"
              >
                <v-row no-gutters>
                  <v-btn
                    v-if="!copyFields.find(field => field.is_main_contract === true)"
                    rounded
                    depressed
                    class="mx-auto blue-button"
                    @click="addNewMainIdField()"
                  >
                    <v-icon small>
                      add
                    </v-icon>
                    {{ $t('leasing.main_contract_id') }}
                  </v-btn>
                </v-row>
                <v-row
                  v-for="field in copyFields"
                  :key="field.id"
                  no-gutters
                >
                  <v-text-field 
                    v-if="field.editType === 'Number'"
                    v-model="field.value"
                    :label="$t(field.label)"
                    :rules="[rules]"
                    :maxlength="field.maxLength"
                    class="form-field"
                  />
                  <v-btn
                    icon
                    large
                    class="mt-5"
                    @click="removeField(field.id)"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </v-row>
                <v-row no-gutters>
                  <v-btn
                    v-if="copyFields.length < 6"
                    rounded
                    depressed
                    class="mx-auto blue-button"
                    @click="addNewOtherIdField"
                  >
                    <v-icon small>
                      add
                    </v-icon>
                    {{ $t('leasing.other_contract_id') }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
            <v-alert
              :value="errorMessage !== null"
              :type="warning ? 'warning' : 'error'"
              class="form-field"
            >
              {{ errorMessage }}
            </v-alert>
          </template>
          <template #footer>
            <v-btn
              :disabled="!valid"
              :loading="saving"
              type="submit"
              rounded
              depressed
              color="primary"
              @click="save"
            >
              {{ warning ? $t('Yes') : $t('Save') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-card>
    </v-dialog>
    <div class="inline-block ml-2 mt-2">
      <v-icon @click="showEditDialog = true">
        edit
      </v-icon>
      <span class="px-2 font-weight-bold">{{ title }}:</span>
      <span>{{ valuesAsString }}</span>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'
import lodash from 'lodash'
import { checkRulesForMFilesIds } from "../../helpers/leasing/rentalprocess"

export default {
  name: 'EditMFilesIds',
  components: {
    BaseModal,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    values: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      copyFields: [],
      showEditDialog: false,
      valid: true,
      saving: false,
      errorMessage: null,
      warning: false,
      newId: null,
    }
  },
  computed: {
    valuesAsString () {
      if (!this.values) {
        return ''
      }
      return this.fields.map(field => {
        if (field.editType === 'Number') {
          return String(this.values[field.id])
        }
        return ''
      }).filter(Boolean).join(', ')
    }
  },
  watch: {
    showEditDialog: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    }
  },
  mounted () {
    this.open()
  },
  methods: {
    addNewOtherIdField () {
      this.newId += 1
      this.copyFields.push({
        id: `N${this.newId}`,
        label: 'leasing.other_contract_id',
        is_main_contract: false, 
        editType: 'Number',
        value: null,
      })
    },
    addNewMainIdField () {
      this.copyFields.unshift({
        id: 'mainId',
        label: 'leasing.main_contract_id',
        is_main_contract: true, 
        editType: 'Number',
        value: null,
      })
    },
    rules (field) {
      this.errorMessage = null
      this.warning = false
      if (field) {
        return checkRulesForMFilesIds(field)
      }
      return this.$t('Required')
    },
    async validateField () {
      if (!this.copyFields.map(field => field.value).some(value => value.toString().length > 5 && value.toString().length < 8)) {
        this.errorMessage = this.$t('leasing.no_fields')
        return false
      }

      const modifiedFields = this.copyFields.reduce((acc, cur) => {
        if (String(cur.value).length > 0 && String(cur.value) !== String(this.values[cur.id])) {
          acc.push(cur.value)
        } 
        return acc
      }, [])

      if (modifiedFields.length > 0 && !this.warning) {
        try {
          const res = await this.$rambollfmapi.leasing.rentalProcesses.mFileIds.checkUsed(modifiedFields)
          if (res?.length === 0) {
            return true
          }
          this.warning = true
          const usedIds = res.map(mf => mf.mfiles_id).join(", ")
          this.errorMessage = this.$t('The same ID is also assigned to another rental. IDs:') + usedIds + this.$t('leasing.continue')
        } catch(err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
        return false
      }
      return true
    },
    removeField (id) {
      this.copyFields.splice(this.copyFields.findIndex(item => item.id === id), 1)
    },
    async open () {
      this.copyFields = lodash.cloneDeep(this.fields)
      this.copyFields.forEach(field => field.value = this.values ? this.values[field.id] : null)
    },
    async save () {
      this.saving = true
      if (await this.validateField()) {
        this.$emit('save', this.copyFields)
        this.showEditDialog = false
      }
      this.saving = false
    },
    close () {
      this.warning = false
      this.errorMessage = null
      this.showEditDialog = false
    },
  }
}
</script>

<style scoped>
</style>