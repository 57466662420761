<template>
  <v-row
    class="dashboard"
  >
    <v-col cols="12">
      <v-card
        elevation="0"
        class="outerBox mt-2 ml-3"
      >
        <div
          class="selection"
        >
          <v-select
            v-model="selectedFreeUnitHandler"
            :label="$t('leasing.free_unit_handling')"
            return-object
            item-text="text"
            item-value="value"
            :items="freeUnitsHandlers"
          />
        </div>
        <div
          class="selection"
          style="margin-left:5px;"
        >
          <v-select
            v-model="selectedSiteUnderConstructionHandler"
            :label="$t('leasing.site_under_construction_handling')"
            return-object
            item-text="text"
            item-value="value"
            :items="siteUnderConstructionHandlers"
          />
        </div>
        <div class="notice">
          <InfoBall
            :info="$t('leasing.site_under_construction_definition')"
            top
          />
        </div>
      </v-card>
    </v-col>
    <v-col
      md="6"
      cols="12"
    >
      <v-row>
        <v-col
          v-for="widget in leftWidgets"
          :key="widget.id"
          :lg="widget.small ? 6 : 12"
          cols="12"
        >
          <DynamicWidget
            v-if="widget.id != null"
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widgetLoading(widget.id)"
            :maximized="widget.maximized"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      md="6"
      cols="12"
    >
      <v-row>
        <v-col
          v-for="widget in rightWidgets"
          :key="widget.id"
          :lg="widget.small ? 6 : 12"
          cols="12"
        >
          <DynamicWidget
            v-if="widget.id != null"
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widgetLoading(widget.id)"
            :maximized="widget.maximized"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import DynamicWidget from '../../DynamicWidget'
import LeasingWidgets from '../../../widgets/leasing'
import moment from 'moment'
import humanize from '../../../helpers/humanize'
import InfoBall from '../../InfoBall'
import { activeProcessesColor, ownedFreeUnitsProgressColors } from '../../../constants/colors' 
import { calculateUsageRate } from "../../../helpers/leasing/occupancyRates"
import { getRentalProcessTitle } from '../../../helpers/leasing/rentalprocess'

export default {
  name: 'RentableUnits',
  components: { 
    DynamicWidget,
    InfoBall
  },
  props: {
    userSites: { type: Array, default: () => []},
  },
  data () {
    return {
      leftWidgets: [...LeasingWidgets.RentableUnitsLeft()],
      rightWidgets: [...LeasingWidgets.RentableUnitsRight()],
      freeUnitsHandlers: [
        { text: this.$t('prospect.show_all'), value: 'all' },
        { text: this.$t('leasing.excluding_next_or_ending'), value: 'excludeEndingOrNext' },
      ],
      siteUnderConstructionHandlers : [
        { text: this.$t('prospect.show_all'), value: 'all' },
        { text: this.$t('leasing.excluding_sites_under_construction'), value: 'excludeSitesUnderConstruction' },
        { text: this.$t('leasing.only_sites_under_construction'), value: 'onlySitesUnderConstruction' },
      ],
      selectedFreeUnitHandler: { text: this.$t('leasing.excluding_next_or_ending'), value: 'excludeEndingOrNext' },
      selectedSiteUnderConstructionHandler: { text: this.$t('leasing.excluding_sites_under_construction'), value: 'excludeSitesUnderConstruction' },
      // If the processes store variable changes we will update this variable. This is made for 
      // finding process for rentalStatus. 
      processesByUnit: {}
    }
  },
  computed: {
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'userInfo',
      'currentDate'
    ]),
    ...mapState('leasing', [
      'rentalStatuses',
      'currentRentalStatusesLoading',
      'quarterlyUsage',
      'quarterlyUsageLoading',
      'leasingSummaries',
      'leasingSummariesLoading',
      'prospectNotes',
    ]),
    ...mapState('rentalProcess', [
      'rentalProcessLoading',
      'rentalProcesses',
    ]),
    ...mapGetters('app', [
      'definitionsByGroupLabel',
      'siteFiltersByTypes',
    ]),
    unitUsages () {
      return this.definitionsByGroupLabel('unit.usage')
    },
    unitTypes () {
      return this.definitionsByGroupLabel('unit.apartmenttype')
    },
    // We load needed definitions locally for improved performance
    unitDefinitions () {
      return this.definitionsByGroupLabel('unit.unit_condition')
        .concat(
          this.definitionsByGroupLabel('unit.move_readiness'), 
          this.definitionsByGroupLabel('unit.meterstatus'),
          this.definitionsByGroupLabel('unit.technical_attributes'),
          this.definitionsByGroupLabel('unit.renting_attributes'),
          this.definitionsByGroupLabel('unit.usage_types')
        )
    },
    siteDefinitions () {
      return this.definitionsByGroupLabel('site.purposeofuse')
        .concat(
          this.definitionsByGroupLabel('site.sitestatus'), 
          this.definitionsByGroupLabel('building.energy'),
          this.definitionsByGroupLabel('site.solarenergy'),
          this.definitionsByGroupLabel('site.contracttype'),
          this.definitionsByGroupLabel('unit.usage')
        )
    },
    computedEmptyUnits () {
      const filteredSites = this.filterSites(this.userSites, this.currentDate, true)

      const siteIds = filteredSites.map(site => site.id_site)

      const emptyUnits = this.getEmptyUnits(this.unitStatusFiltered)

      return emptyUnits.filter(rs => siteIds.includes(rs.siteId))
    },

    /**
     * Vuokraus-välilehden graaffeissa ei saisi laskea mukaan huoneistoja, joiden huoneistostatus joku seuraavista:
     * ”Ei tiedossa”
     * ”Ei vuokrattava”
     * ”Koy, julkinen tila”
     * ”Koy, tekninen tila”
     *
     * This function will filter out the other units.
     */
    unitStatusFiltered () {
      // Listing  not permitted unit statuses. Added comments also for permitted if in future some of those will not be permitted.
      const notPermittedUnitStatuses = [
        'notrentable.unknown', // Ei tiedossa
        'notrentable', // Ei vuokrattava
        'notrentable.koy.public', // Koy, julkinen tila
        'notrentable.koy.technical', // Koy, tekninen tila
        // rentable,
        // rentable.koy,
        // notrentable.repairs,
      ]

      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
      const permittedDefinitions = notPermittedUnitStatuses
        .map(status => {
          const def = rentalDefs.find(rentalDef => rentalDef.label === status)

          if (def) {
            return def.id
          }
          return null
        })
        .filter(def => def !== null)

      return this.rentalStatuses.filter(rs => !permittedDefinitions.includes(rs.unitStatus))
    }
  },
  watch: {
    userSites: function () {
      this.loadWidgetData()
    },
    rentalProcesses: function () {
      this.orderProcessesByUnit()
      this.loadWidgetData()
    },
    rentalStatuses:  {
      deep: true,
      handler: function () {
        this.loadWidgetData()
      }
    },
    selectedFreeUnitHandler: function () {
      const OwnedFreeWidget = this.leftWidgets.concat(this.rightWidgets).find(x => x.id === 'leasing.owned_free_units_progress')
      if(OwnedFreeWidget)
      {
        if (this.selectedFreeUnitHandler.value === 'excludeEndingOrNext' ) {
          OwnedFreeWidget.data.useSecondaryDifferenceLabel = false;
        }
        else if (this.selectedFreeUnitHandler.value === 'all')
        {
          OwnedFreeWidget.data.useSecondaryDifferenceLabel = true;
        }
      }
      this.loadWidgetData()
    },
    quarterlyUsage: function () {
      this.loadWidgetData()
    },
    selectedSiteUnderConstructionHandler: function () {
      this.loadWidgetData()
    },
    leasingSummaries: function () {
      this.loadWidgetData()
    }
  }, 
  mounted () {
    this.orderProcessesByUnit()
    this.loadWidgetData()
  },
  methods: {
    loadWidgetData () {
      const widgets = this.leftWidgets.concat(this.rightWidgets)
      widgets.forEach(widget => {
        // First load any extra data widget might need (dynamic sets/headers, extra data)
        if (widget.data.dynamicHeaders) {
          widget.data.headers = this.dataHeadersForWidget(widget.id)
        }
        if (widget.data.dynamicSets) {
          widget.data.sets = this.dataSetsForWidget(widget.id)
        }
        if (widget.data.lineItems) {
          widget.data.lineItems = this.extraDataForWidget(widget.id)
        }
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }

        widget.data.items = this.dataForWidget(widget.id)
      })
    },
    legendForWidget (id) {
      try {
        switch(id) {
          case 'leasing.most_vacant_space_per_site':
            return this.loadMostVacantSpacePerSiteLegend()
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    loadMostVacantSpacePerSiteLegend (){
      return Object.entries(activeProcessesColor).map(([key, value]) => 
        ({
          text: this.$t(key),
          fillStyle: value,
          hidden: false,
          lineWidth: 1,
          strokeStyle: '#fff',
        })
      )
    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.free_units_by_size':
        case 'leasing.free_units_by_type':
        case 'leasing.free_usage_types':
        case 'leasing.most_vacant_space_per_site':
          return this.sitesLoading || this.currentRentalStatusesLoading || this.rentalProcessLoading
        case 'leasing.empty_units':
          return this.sitesLoading || this.currentRentalStatusesLoading|| this.rentalProcessLoading
        case 'leasing.owned_free_units_progress':
          return this.sitesLoading || this.quarterlyUsageLoading
        case 'leasing.sites_occupancy_rates':
          return this.sitesLoading || this.leasingSummariesLoading || this.rentalProcessLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }

        switch (id) {
          case 'leasing.most_vacant_space_per_site':
            return this.loadMostVacantSpacePerSite(this.userSites, this.unitStatusFiltered, this.rentalProcesses)
          case 'leasing.empty_units':
            return this.emptyUnitsData(this.computedEmptyUnits, this.prospectNotes)
          case 'leasing.owned_free_units_progress':
            return this.freeUnitsProgress(this.quarterlyUsage, this.userSites)
          case 'leasing.sites_occupancy_rates':
            return this.loadLeasingSummaries(this.leasingSummaries, this.userSites)
          case 'leasing.free_usage_types':
            return this.vacantUnitsByUsage(this.computedEmptyUnits)
          case 'leasing.free_units_by_size': 
            return this.vacantUnitsBySize(this.computedEmptyUnits, this.unitUsages)
          case 'leasing.free_units_by_type': 
            return this.vacantUnitsByType(this.computedEmptyUnits)
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process data for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    dataHeadersForWidget (id) {
      try {
        switch(id) {
          case 'leasing.owned_free_units_progress':
            return this.freeUnitsHeaders()
          default:
            return {}
        }
      }
      catch (err) {
        this.$log.error('Error trying to process headers for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    dataSetsForWidget (id) {
      try {
        switch(id) {
          case 'leasing.owned_free_units_progress':
            return this.freeUnitsSets(this.userSites)
          case 'leasing.free_units_by_size':
            return this.freeUnitsBySizeSets(this.unitUsages)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process sets for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    extraDataForWidget (id) {
      try {
        switch(id) {
          case 'leasing.owned_free_units_progress':
            return this.freeUnitsLine(this.quarterlyUsage, this.userSites)
          default:
            return []
        }
      }
      catch (err) {
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }

    },
    vacantUnitsByUsage (emptyUnits) {
      const data = emptyUnits.map(rs => ({
        market_rent: rs.market_rent * rs.area,
        area: rs.area,
        unitUsage: rs.unitUsage,
      }))

      // First we must calculate totals for all usage types for both sets.
      const groupedByUsage = data.reduce((acc, rs) => {
        if (acc[rs.unitUsage]) {
          acc[rs.unitUsage].market_rent += rs.market_rent
          acc[rs.unitUsage].area += rs.area
          acc[rs.unitUsage].unitCount += 1
        } else {
          acc[rs.unitUsage] = {
            market_rent: rs.market_rent,
            area: rs.area,
            unitCount: 1,
            unitUsage: rs.unitUsage
          }
        }
        return acc
      }, {})

      // Now let's find 7 largest for each set (area & market_rent).
      const largestArea = Object.values(groupedByUsage)
        .sort((a, b) => b.area - a.area) // Sort desc
        .slice(0, 7) // Get first 7 items
        .map(rs => rs.unitUsage) // Return only usage type

      const largestMarketRent = Object.values(groupedByUsage)
        .sort((a, b) => b.market_rent - a.market_rent)
        .slice(0, 7)
        .map(rs => rs.unitUsage)

      const largestUnitCount = Object.values(groupedByUsage)
        .sort((a, b) => b.unitCount - a.unitCount) // Sort desc
        .slice(0, 7) // Get first 7 items
        .map(rs => rs.unitUsage) // Return only usage type

      // Now let's construct the data for PieDataGraph. The graph will construct the slices by grouping data by defined variable.
      // We can construct data model which will include both required sets by defining two different usages. One for
      // market_rent and one for area.
      return data.map(rs => {
        const areaUsage = largestArea.includes(rs.unitUsage) ? rs.unitUsage : 'Other'
        const marketRentUsage = largestMarketRent.includes(rs.unitUsage) ? rs.unitUsage : 'Other'
        const unitCountUsage = largestUnitCount.includes(rs.unitUsage) ? rs.unitUsage : 'Other'

        return {
          market_rent: rs.market_rent,
          area: rs.area,
          areaUsage,
          marketRentUsage,
          unitCountUsage
        }

      })
    },
    vacantUnitsBySize (emptyUnits, unitUsages){
      // We have to group the items by data ranges. Let's generate those and then map emptyUnits.

      // So we have to define lower and upper bound for the value. If either is missing, then we don't consider that in the comparison.
      // Upper bound should not be inclusive eg. 50 <= x < 100
      const dataRanges = [
        [null, 50], // Below 50
        [50, 100], // 51 - 100
        [100, 250], // 101 - 250
        [250, 500], // 251 - 500
        [500, 1000], // 501 - 1000
        [1000, null]
      ]

      const underText = this.$t("leasing.under")
      const overText = this.$t("leasing.over")

      // Let's create list of objects, each containing one data range.
      const dataSet = dataRanges.map( range => {

        let label = ""
        if(range[0] === null){
          label = `${underText} ${range[1]} m\u00B2`
        }
        else if (range[1] === null){
          label = `${overText} ${range[0]} m\u00B2`
        }
        else {
          label = `${range[0]} - ${range[1]} m\u00B2`
        }

        const usageObject = unitUsages.reduce((acc, cur) => {
          acc[cur.label] = 0
          return acc
        }, {}) 

        return {
          ...usageObject,
          "Kaikki": 0, // Add also all category
          label,
          range,
        }

      })

      // Now that we have our data set, let's populate it.
      emptyUnits.forEach(rs => {
        
        const { area, unitUsage } = rs

        const set = dataSet.find(set => {
          
          const { range } = set

          if (range[0] === null ){
            return area < range[1]
          }

          if (range[1] === null){
            return range[0] <= area
          }
        
          return range[0] <= area && area < range[1]
        })

        if(set && set[unitUsage] !== undefined){
          set[unitUsage] += 1
          set["Kaikki"] += 1
        }

      })

      return dataSet

    },
    vacantUnitsByType (emptyUnits) {
      const data = emptyUnits.map(rs => ({
        area: rs.area,
        apartment: rs.apartment,
      }))
      return data
    },
    calculateSiteAreas (rentalStatuses) {
      return rentalStatuses.reduce((acc, cur ) => {
        const site = acc.get(cur.siteName)
        if (site) {
          site.area += cur.area
        } else {
          acc.set(cur.siteName, { area: cur.area })
        }
        return acc
      }, new Map())
    },
    calculateEmptyUnits (rentalStatuses, siteAreas) {
      return this.getEmptyUnits(rentalStatuses).map(rs => {
        // Let's use this to calculate unit portion of vacancy ratio.
        const siteTotalArea = siteAreas.get(rs.siteName).area
        return {
          siteName: rs.siteName,
          siteId : rs.siteId,
          unitArea: rs.area,
          unitId: rs.unitId,
          marketRent: rs.market_rent,
          totalRent: rs.market_rent * rs.area,
          freeAreaPortion: ( rs.area / siteTotalArea ) * 100
        }
      })
    },
    groupUnitsBySite (units) {
      const siteData = [];
      units.forEach(unit => {
        const site = siteData.find(item => item.siteId === unit.siteId);
        if (site) {
          site.vacant_space_area += unit.unitArea;
          site.vacant_space_eur += unit.totalRent;
          site.freeAreaPortion += unit.freeAreaPortion;
          site.unitCount += 1;
        } else {
          siteData.push({
            siteName: unit.siteName,
            siteId: unit.siteId,
            vacant_space_area: unit.unitArea,
            vacant_space_eur: unit.totalRent,
            freeAreaPortion: unit.freeAreaPortion,
            active_prospects_area: 0,
            active_prospects_eur: 0,
            unitCount: 1
          });
        }
      });
      return siteData;
    },  
    loadMostVacantSpacePerSite (sites, rentalStatuses, processes) {
      const siteIds = this.filterSites(sites, this.currentDate, true).map(site => site.id_site)
      const filteredRentalStatuses = rentalStatuses.filter(item => siteIds.includes(item.siteId))
      // So we have to calculate the ratio of vacant units area to all units area per site. Normally this would be caluclated by site.free_area / site.area. Problem is, 
      // the used graph does the grouping from rentalStatuses using defined variable siteName. Also the graph uses same data to display different information. 
      // Solution for this is to calculate subset ratio, which can then be summed in HorizontalGroupedBarDataGraph. Some maths:
      // free/site.total_area => sum(vacant_unit.area)/site.total_area => sum(vacant_rs.area/site.total_area)

      // We have to calculate total area for each site.
      const siteAreas = this.calculateSiteAreas(filteredRentalStatuses)
      // Data for empty units
      const emptyUnits = this.calculateEmptyUnits(filteredRentalStatuses,siteAreas)
      // Group empty unit data by site
      const siteData = this.groupUnitsBySite(emptyUnits)
      // Add processes unit data
      const filteredProcesses = processes
        .filter(process => process.sites.every(site => sites.some(sitesItem => sitesItem.id_site === site.id_site)))
        .filter(process => this.siteFiltersByTypes('all').length > 0 ? process.sites.length !== 0 : true)

      this.filterProcessesByStages(filteredProcesses)
        .flatMap(process => process.sites)
        .forEach(site => {
          const foundSite = siteData.find(item => item.siteId === site.id_site);
          if (!foundSite) {
            return
          }
          const newSite = {...foundSite};
          newSite.active_prospects_area += site.siteUnitArea;
          if (site.siteUnitMarketRent) {
            newSite.active_prospects_eur += site.siteUnitMarketRent;
          }
          siteData.splice(siteData.findIndex(item => item.siteId === foundSite.siteId), 1, newSite);
        })
      return siteData
    },
    emptyUnitsData (emptyUnits, prospectNotes) {
      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')

      const filteredRentalStatuses = emptyUnits.filter(rs => rs.site_ownership_status === 'Omistuksessa')

      // Get last modified unit notes and create easily accessible object from them
      const unitNotes = prospectNotes.filter(note => note.id_hsto !== null)
      unitNotes.sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date))
      const notesByUnit = unitNotes.reduce(
        (noteObject, note) => {
          if (!noteObject[note.id_hsto]) {
            noteObject[note.id_hsto] = note.comment
          }
          return noteObject
        },
        {}
      )

      const data = filteredRentalStatuses.map(rs => {
        const siteStatus = this.siteDefinitions.find(sf => sf.id === rs.site_status)
        const rentalStatus = rentalDefs.find(rd => rd.id === rs.unit_not_rentable)
        const moveReadiness = this.unitDefinitions.find(ud => ud.id === rs.move_readiness)

        const unitNote = notesByUnit[rs.unitId] ?? ''

        let processDescription = null
        let processId = null
        let subItems = []
        let processesString = null
        
        // Get active processes for the unit
        const activeProcessesForUnit = this.filterProcessesByStages(this.processesByUnit[rs.unitId])
        if (activeProcessesForUnit) {
          if (activeProcessesForUnit.length == 1) {
            processDescription = getRentalProcessTitle(activeProcessesForUnit[0])
            processesString = activeProcessesForUnit[0].stage
            processId = activeProcessesForUnit[0].id
          }
          else {
            subItems = activeProcessesForUnit.map(process => ({
              process_title: getRentalProcessTitle(process),
              id_process: process.id,
            }))
            processesString = activeProcessesForUnit.map(process => process.stage).join(', ')
          }
        }

        const visibilityPrevented = this.getPreventationStatus(rs)

        let unit = {
          site_name: rs.siteName,
          site_identifier: rs.site_identifier,
          building_name: rs.buildingName,
          unit_name: rs.unitName,
          unit_area: rs.area,
          unit_class: rs.unitUsage,
          unit_id: rs.unitId,
          next_tenant_name: rs.nextPartyName,
          next_tenant_start_date: rs.nextPartyStartDate,
          previous_tenant_name: rs.prevPartyName,
          previous_tenant_end_date: rs.prevPartyEndDate,
          id_site : rs.siteId,
          unitIdentifier : rs.unitId,
          unit_market_rent: rs.unitMarketRent,
          usage: rs.unitUsage,
          site_status: siteStatus ? siteStatus.label : null,
          site_state: rs.site_state,
          unit_rental_status: rentalStatus ? this.$t(rentalStatus.label) : null,
          move_readiness: moveReadiness ? moveReadiness.label : null,
          campaign_active: rs.campaign_active ? this.$t('Yes') : this.$t('No'),
          reservation_status: this.getReservationStatus(rs),
          visibility_prevented: visibilityPrevented,
          preventation_end_date_exists: visibilityPrevented ? "prevented" : "not prevented",
          reservation_reasoning: visibilityPrevented ? rs.reservation_reasoning : '',
          master_unit: rs.master_unit ? this.getUnitCodeById(rs.master_unit, filteredRentalStatuses) : this.$t('master_unit.not_merged'),
          market_rent: rs.market_rent,
          clarified_ti_expense: rs.clarified_ti_expense,
          estimated_ti_expense: rs.estimated_ti_expense,
          advertising_phrase: rs.advertising_phrase,
          unit_end_date: rs.curUnitEndDate,
          unit_code_long: rs.unit_code_long,
          rentalSituation: this.rentalSituation(rs),
          process_title: processDescription,
          id_process: processId,
          unitNote,
          showToggle: subItems.length > 1,
          processesString
        }
        if (subItems.length > 1) {
          unit.process_title = this.$t('prospect.several')
          unit.subItems = subItems
        }
        return unit
      })

      return data
    },
    /**
     * Returns the current rental situation. Specification from US 6882
     * rented: vihreä: tilassa tulevaisuudessa alkava vuokrasopimus TAI tilaan kohdistuu voitettu tai kohdistusta odottava vuokrausprosessi (ts. ei tarvitse enää tehdä vuokraustoimia)
     * in_process: keltainen: tilassa EI tulevaisuudessa alkavaa sopimusta, mutta on yksi tai useampi aktiivinen vuokrausprosessi
     * free_over_100: haalean punainen: tila 100 m2 tai yli + tilassa EI tulevaisuudessa alkavaa sopimusta eikä yhtään aktiivista vuokrausprosessia
     * free_under_100: punainen:  tila alle 100m2 + tilassa EI tulevaisuudessa alkavaa sopimusta eikä yhtään aktiivista vuokrausprosessia
     * @param {Object} rentalStatus - Rental status object
     * @returns String enum of the current rental situation. Possible values: ["rented", "in_process", "free_over_100", "free_under_100", null]
     */
    rentalSituation (rentalStatus) {
      const processes = this.processesByUnit[rentalStatus.unitId]
      
      let awaitingAllocation = null
      let activeProcess = null

      if(processes){
        const wonStages = ['6', '7']
        const activeStages = ['2', '3', '4', '5']
        awaitingAllocation = processes.find(process => wonStages.includes(process.stage.charAt(0)))
        activeProcess = processes.find(process => activeStages.includes(process.stage.charAt(0)))
      }
      // vihreä: tilassa tulevaisuudessa alkava vuokrasopimus TAI tilaan kohdistuu voitettu tai kohdistusta odottava prosessi (ts. ei tarvitse enää tehdä vuokraustoimia)
      // Voitetulle prospektille on lähtökohtaisesti allokoitu vuokrasopimus, joten ensimmäinen kohta kattaa "voitetun vuokrausprosessin"
      if(rentalStatus.nextPartyId || awaitingAllocation){
        return 'rented'
      }
      // in_process: keltainen: tilassa EI tulevaisuudessa alkavaa sopimusta, mutta on yksi tai useampi aktiivinen vuokrausprosessi
      if(!rentalStatus.nextPartyId && activeProcess){
        return 'in_process'
      }

      // free_over_100: haalean punainen: tila 100 m2 tai yli + tilassa EI tulevaisuudessa alkavaa sopimusta eikä yhtään aktiivista vuokrausprosessia
      if(rentalStatus.area > 100 && !rentalStatus.nextPartyId && !activeProcess){
        return 'free_over_100'
      }
      // free_under_100: punainen:  tila alle 100m2 + tilassa EI tulevaisuudessa alkavaa sopimusta eikä yhtään aktiivista vuokrausprosessia
      if(rentalStatus.area <= 100 && !rentalStatus.nextPartyId && !activeProcess){
        return 'free_under_100'
      }

      return null

    },
    freeUnitsProgress (quarterlyUsage, sites) {
      const data = []

      quarterlyUsage.forEach((quarter, index) => {

        const filteredSites = this.filterSites(sites, quarter.date, true)
        const filteredSiteIds = filteredSites.map(site => site.id_site)

        const filteredSiteOccupancyRates = quarter.site_occupancy_rates.filter(or => filteredSiteIds.includes(or.id_site))

        // Last quarter is special case in terms of dateString
        let dateString = ''
        if (index === 0) {
          dateString = moment(quarter.date).format('D.M.YYYY')
        } else {
          const quarterNumber = moment(quarter.date).get('quarter')
          const year = moment(quarter.date).get('year')
          dateString = `Q${quarterNumber} ${year}`
        }

        const quarterData = {
          quarter: dateString,
        }

        filteredSiteOccupancyRates.forEach(siteOccupancy => {
          siteOccupancy.unit_usage_occupancy_rates.forEach(unitUsagesOccupancyRate => {
            // Add data based on site classification
            this.addClassificationData(quarterData, unitUsagesOccupancyRate, unitUsagesOccupancyRate.id_usage)
            this.addClassificationData(quarterData, unitUsagesOccupancyRate, 'All')
          })

          siteOccupancy.unit_type_occupancy_rates
            .filter(unitTypeOccupancyRate => unitTypeOccupancyRate.type !== null)
            .forEach(unitTypeOccupancyRate => {
              // Add data based on site classification
              this.addClassificationData(quarterData, unitTypeOccupancyRate, unitTypeOccupancyRate.id_type)
            })

        })
        // Add free area percentage data
        let freeArea = 0
        let totalArea = 0
        filteredSiteOccupancyRates.forEach(siteOccupancyRate => {
          freeArea += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => acc + curr.free_area, 0)
          totalArea += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => acc + (curr.total_area - curr.renovation_area) , 0)
        })
        quarterData.freeAreaPercentageAll = freeArea / totalArea * 100

        // Add free area percentage data per unit usage
        const siteData = {}
        filteredSiteOccupancyRates.forEach(siteOccupancyRate => {
          siteOccupancyRate.unit_usage_occupancy_rates.forEach(unitUsagesOccupancyRate => {
            this.addFreeClassificationData(siteData, unitUsagesOccupancyRate, unitUsagesOccupancyRate.id_usage)
          })
        })
        Object.keys(siteData).forEach(key => {
          quarterData[`freeAreaPercentage${key}`] = siteData[key].freeArea / siteData[key].totalArea * 100
        })

        data.unshift(quarterData)
      })

      return data
    },
    freeUnitsHeaders () {
      const headers = {}
      // Then add data for all unit usages
      const classifications = [
        { id: 'All' },
        ...this.unitUsages,
        ...this.unitTypes
      ]
      classifications.forEach(classification => {
        headers[classification.id] = [
          // Areas
          { text: this.$t('leasing.rented_room_area'), value: `rentedArea${classification.id}`, color: ownedFreeUnitsProgressColors.rented, group: 'area' },
          { text: this.$t('leasing.leased_or_ending_in_future'), value: `leasedInFutureArea${classification.id}`, color: ownedFreeUnitsProgressColors.rentedOrEndingInFuture, group: 'area' },
          { text: this.$t('leasing.renovations_area'), value: `renovationsArea${classification.id}`, color: ownedFreeUnitsProgressColors.renovations, group: 'area' },
          { text: this.$t('leasing.free_area'), value: `freeArea${classification.id}`, color: ownedFreeUnitsProgressColors.free, group: 'area' },
          { text: this.$t('Vacant (web visibility prevented)'), value: `visibilityPreventedArea${classification.id}`, color: ownedFreeUnitsProgressColors.visibilityPrevented, group: 'area' },
          // Rent
          { text: this.$t('leasing.rented_room_area'), value: `rentedRent${classification.id}`, color: ownedFreeUnitsProgressColors.rented, group: 'rent' },
          { text: this.$t('leasing.leased_or_ending_in_future'), value: `leasedInFutureRent${classification.id}`, color: ownedFreeUnitsProgressColors.rentedOrEndingInFuture, group: 'rent' },
          { text: this.$t('leasing.renovations_area'), value: `renovationsRent${classification.id}`, color: ownedFreeUnitsProgressColors.renovations, group: 'rent' },
          { text: this.$t('leasing.free_area'), value: `freeRent${classification.id}`, color: ownedFreeUnitsProgressColors.free, group: 'rent' },
          { text: this.$t('Vacant (web visibility prevented)'), value: `visibilityPreventedRent${classification.id}`, color: ownedFreeUnitsProgressColors.visibilityPrevented, group: 'rent' },
          // Unit count
          { text: this.$t('leasing.rented_room_area'), value: `rentedCount${classification.id}`, color: ownedFreeUnitsProgressColors.rented, group: 'count' },
          { text: this.$t('leasing.leased_or_ending_in_future'), value: `leasedInFutureCount${classification.id}`, color: ownedFreeUnitsProgressColors.rentedOrEndingInFuture, group: 'count' },
          { text: this.$t('leasing.renovations_area'), value: `renovationsCount${classification.id}`, color: ownedFreeUnitsProgressColors.renovations, group: 'count' },
          { text: this.$t('leasing.free_area'), value: `freeCount${classification.id}`, color: ownedFreeUnitsProgressColors.free, group: 'count' },
          { text: this.$t('Vacant (web visibility prevented)'), value: `visibilityPreventedUnits${classification.id}`, color: ownedFreeUnitsProgressColors.visibilityPrevented, group: 'count' },
          // Headers for additional tooltip info
          { text: this.$t('leasing.total_rentable_area'), value: `totalRentableArea${classification.id}`, color: '#000', tooltipOnly: true, unit: 'm\u00B2', decimals: 2 },
          { text: this.$t('leasing.area_free'), value: `freeArea${classification.id}`, color: '#000', tooltipOnly: true, unit: 'm\u00B2', decimals: 2 },
          { text: this.$t('leasing.leased_or_ending_in_future'), value: `leasedInFutureArea${classification.id}`, color: '#000', tooltipOnly: true, unit: 'm\u00B2', decimals: 2 },
          { text: this.$t('Vacant (web visibility prevented)'), value: `visibilityPreventedArea${classification.id}`, color: '#000', tooltipOnly: true, unit: 'm\u00B2', decimals: 2 },
          { text: this.$t('leasing.renovations_area'), value: `renovationsArea${classification.id}`, color: '#000', tooltipOnly: true, unit: 'm\u00B2', decimals: 2 },
          { text: this.$t('leasing.free_market_rent_total'), value: `freeRent${classification.id}`, color: '#000', tooltipOnly: true, unit: '€', decimals: 2 },
          { text: this.$t('leasing.free_units_amount'), value: `freeUnits${classification.id}`, color: '#000', tooltipOnly: true, unit: 'kpl', decimals: 0 },
          { text: this.$t('leasing.free_area_percentage'), value: `freeAreaPercentage${classification.id}`, color: '#000', tooltipOnly: true, unit: '%', decimals: 2 }
        ]
      })

      return headers
    },
    freeUnitsSets () {
      const sets = []

      const classifications = this.unitUsages.filter(u => this.CheckIfQuarterlyUsageIncludesClassification(u.label))
      classifications.forEach(classification => {
        let classificationString = classification.label
        // If we found duplicate strings, we need to alter string slightly to avoid issues with keys in VerticalStackedGraph
        // The reason why duplicates may appear is because site might have unit_usage definition by mistake
        if (sets.find(item => item.text === this.$t(classificationString))) {
          classificationString = classificationString + ' '
        }
        sets.push({ text: this.$t(classificationString), value: classification.id })
      })

      this.unitTypes.forEach(type => {
        const { label, id } = type

        sets.push({ text: `${this.$t('dashboard.apartment')}: ${label}`, value: id })
      })

      sets.sort((a, b) => b.text.localeCompare(a.text))

      // Add all set
      sets.push({ text: this.$t('prospect.show_all'), value: 'All' })

      return sets.reverse()
    },
    freeUnitsBySizeSets (unitUsages){
      
      const usageLabels = unitUsages.map(usage => usage.label)

      usageLabels.sort()

      const allUsageLabels = ["Kaikki", ...usageLabels]

      return allUsageLabels.map(usage => {
        return {
          title: usage,
          groupBy: "label",
          method: "sum",
          parameters: [usage]
        }
      })
    },
    CheckIfQuarterlyUsageIncludesClassification (usageLabel)
    {
      for(let qu of this.quarterlyUsage)
      {
        let filteredSites = this.filterSites(this.userSites, qu.date, true)
        const filteredSiteIds = filteredSites.map(site => site.id_site)
        let filteredSiteOccupancyRates = qu.site_occupancy_rates.filter(ocr => filteredSiteIds.includes(ocr.id_site))
        
        for(let sor of filteredSiteOccupancyRates)
        {
          let exists = sor.unit_usage_occupancy_rates.find(uuor => uuor.usage_type === usageLabel 
          && !this.checkIfAllOccupancyRateValuesAreZero(uuor))
          if(exists)
          {
            return true
          }
        } 
      }
      return false
    },
    checkIfAllOccupancyRateValuesAreZero (unitOccupancyRate)
    {
      let valueFields = { ...unitOccupancyRate, id_usage: 0, usage_type: 0}
      if(Object.values(valueFields).every(x => x == 0))
      {
        return true
      }
      return false 
    },
    addClassificationData (quarterData, siteData, classification) {
      // Since we create all new keys at the same time, we need to check if just one of them exists already
      if (quarterData['rentedArea' + classification] == null) {
        quarterData['rentedArea' + classification] = 0
        quarterData['leasedInFutureArea' + classification] = 0
        quarterData['freeArea' + classification] = 0
        quarterData['renovationsArea' + classification] = 0
        quarterData['rentedRent' + classification] = 0
        quarterData['leasedInFutureRent' + classification] = 0
        quarterData['freeRent' + classification] = 0
        quarterData['renovationsRent' + classification] = 0
        quarterData['freeUnits' + classification] = 0

        quarterData['totalRentableArea' + classification] = 0
        quarterData[`rentedCount${classification}`] = 0
        quarterData[`leasedInFutureCount${classification}`] = 0
        quarterData[`renovationsCount${classification}`] = 0
        quarterData[`freeCount${classification}`] = 0

        quarterData['visibilityPreventedArea' + classification] = 0
        quarterData['visibilityPreventedRent' + classification] = 0
        quarterData['visibilityPreventedUnits' + classification] = 0

      }

      quarterData['rentedArea' + classification] += siteData.rented_area
      quarterData['leasedInFutureArea' + classification] += siteData.leased_or_ending_in_future_area
      quarterData['freeArea' + classification] += siteData.free_area - siteData.visibility_prevented_area
      quarterData['renovationsArea' + classification] += siteData.renovation_area
      quarterData['rentedRent' + classification] += siteData.rented_rent
      quarterData['leasedInFutureRent' + classification] += siteData.leased_or_ending_in_future_rent
      quarterData['freeRent' + classification] += siteData.free_rent - siteData.visibility_prevented_market_rent
      quarterData['renovationsRent' + classification] += siteData.renovations_rent
      quarterData['freeUnits' + classification] += siteData.free_units - siteData.visibility_prevented_units
      quarterData['totalRentableArea' + classification] += siteData.rented_area + siteData.free_area + siteData.leased_or_ending_in_future_area

      quarterData[`rentedCount${classification}`] += siteData.rented_units
      quarterData[`leasedInFutureCount${classification}`] += siteData.leased_or_ending_in_future_units
      quarterData[`renovationsCount${classification}`] += siteData.renovation_units
      quarterData[`freeCount${classification}`] += siteData.free_units

      quarterData['visibilityPreventedArea' + classification] += siteData.visibility_prevented_area
      quarterData['visibilityPreventedRent' + classification] += siteData.visibility_prevented_market_rent
      quarterData['visibilityPreventedUnits' + classification] += siteData.visibility_prevented_units
    },
    freeUnitsLine (quarterlyUsage, sites) {
      const data = []

      quarterlyUsage.forEach((quarter, index) => {
        let dateString = ''
        if (index === 0) {
          dateString = moment(quarter.date).format('D.M.YYYY')
        } else {
          const quarterNumber = moment(quarter.date).get('quarter')
          const year = moment(quarter.date).get('year')
          dateString = `Q${quarterNumber} ${year}`
        }
        const quarterData = { quarter: dateString, All: 0 }

        const filteredSites = this.filterSites(sites, quarter.date, true)
        const siteIds = filteredSites.map(item => item.id_site)

        const filteredSiteOccupancyRates = quarter.site_occupancy_rates.filter(or => siteIds.includes(or.id_site))
        // Calculate and add data for all classifications first
        let rentedArea = 0
        let total = 0
        filteredSiteOccupancyRates.forEach(siteOccupancyRate => {
          rentedArea += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => {
            let additionalArea = 0
            // If future leases and ending units are considered free, add them to rented total
            if (this.selectedFreeUnitHandler.value === 'all') {
              additionalArea = curr.leased_or_ending_in_future_area
            }

            return acc + curr.rented_area + additionalArea
          }, 0)
          total += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => {
            let additionalArea = 0
            // If future leases and ending units are excluded, exclude them also from the total area
            if (this.selectedFreeUnitHandler.value === 'excludeEndingOrNext') {
              additionalArea = curr.leased_or_ending_in_future_area
            }
            return acc + curr.total_area - additionalArea - curr.renovation_area
          }, 0)
        })

        if (total === 0) {
          quarterData.All = 0
        } else {
          quarterData.All = rentedArea / total * 100
        }

        // // Then add data for specific classifications
        const siteData = {}
        filteredSiteOccupancyRates.forEach(siteOccupancyRate => {
          siteOccupancyRate.unit_usage_occupancy_rates.forEach(unitUsagesOccupancyRate => {
            this.addClassificationLineData(siteData, unitUsagesOccupancyRate, unitUsagesOccupancyRate.id_usage)
          })

          siteOccupancyRate.unit_type_occupancy_rates
            .filter(unitTypeOccupancyRate => unitTypeOccupancyRate.type !== null)
            .forEach(unitTypeOccupancyRate => {
              this.addClassificationLineData(siteData, unitTypeOccupancyRate, unitTypeOccupancyRate.id_type)
            })

        })
        Object.keys(siteData).forEach(key => {
          if (siteData[key].totalArea === 0) {
            quarterData[key] = 0
          } else {
            quarterData[key] = siteData[key].rentedArea / siteData[key].totalArea * 100
          }
        })

        data.unshift(quarterData)

      })

      return data
    },
    addClassificationLineData (data, siteData, classification) {
      // Check if key already exists, otherwise add it 
      if (data[classification] == null) {
        data[classification] = {rentedArea: 0, totalArea: 0}
      }
      data[classification].rentedArea += siteData.rented_area
      if (this.selectedFreeUnitHandler.value === 'excludeEndingOrNext' ) {
        data[classification].rentedArea += siteData.leased_or_ending_in_future_area
      }
      data[classification].totalArea += siteData.total_area
    },
    addFreeClassificationData (data, siteData, classification) {
      // Check if key already exists, otherwise add it 
      if (data[classification] == null) {
        data[classification] = {freeArea: 0, totalArea: 0}
      }
      data[classification].freeArea += siteData.free_area
      data[classification].totalArea += siteData.total_area
    },
    filterSites (sites, date, filterOwned = false) {
      let filteredSites = sites
      // Filter by ownership status if needed
      if (filterOwned) {
        filteredSites = filteredSites.filter(site => site.ownership_status === 'Omistuksessa')
      }
      // Filter by estimated or confirmed completion date
      filteredSites = filteredSites.filter(site => {return this.checkUnderConstruction(site, date)})
      return filteredSites
    },
    checkUnderConstruction (site, date) {
      let include = false
      if (this.selectedSiteUnderConstructionHandler.value === 'excludeSitesUnderConstruction') {
        include = false
      }
      else if (this.selectedSiteUnderConstructionHandler.value === 'onlySitesUnderConstruction') {
        include = true
      }
      else {
        return true
      }
      const thisDate = new Date(date)
      if (site.confirmed_completion != null) {
        if (new Date(site.confirmed_completion) > thisDate) {
          return include
        }
      }
      else if (site.estimated_completion != null) {
        if (new Date(site.estimated_completion) > thisDate) {
          return include
        }
      }
      return !include
    },
    getUnitCodeById (masterUnitId, data) {
      let unit = data.find(item => item.unitId === masterUnitId)
      if (unit) {
        return unit.unitName
      }
      else {
        return ''
      }
    },
    getReservationStatus (unit) {
      const reserved = unit.reservation_end_date && moment(this.currentDate).isBefore(unit.reservation_end_date)
      const prevented = unit.preventation_end_date && moment(this.currentDate).isBefore(unit.preventation_end_date)
      const contractInAllocation = this.contractInAllocation(unit)
      if (reserved) {
        return this.$t('Reserved\n') + ' (' + this.$t('reservation ends') + ' ' + humanize.date(unit.reservation_end_date) + ')'
      } else if (prevented) {
        return this.$t('Visibility prevented')
      } else if (contractInAllocation) {
        return this.$t('Contract in allocation') + ' (' + this.$t('ends after allocation') + ')'
      } else {
        return this.$t('No reservations')
      }
    },
    contractInAllocation (unit){
      const awaitingAllocationForSiteAndUnit = this.rentalProcesses.filter(process => 
        process.stage === '6. Won' && 
        process.status !== "Inactive" && 
        process.sites.filter(site => 
          site.id_site === unit.siteId && 
          site.units.filter(siteUnit => siteUnit.id_unit === unit.unitId).length)
        .length)
      return Boolean(awaitingAllocationForSiteAndUnit.length)
    },
    filterProcessesByStages (rentalProcesses) {
      if (!rentalProcesses) {
        return null
      }
      const validStages = ['2', '3', '4', '5']; // Valid stage values
      return rentalProcesses.filter(process => { return validStages.includes(process.stage.charAt(0)) && process.status === 'Active'})
    },
    // Creates this.processesByUnit variable from this.rentalProcesses variable.
    // In this variable the processes are ordered by units for faster lookups.
    orderProcessesByUnit (){
      const processesOrderedByUnit = {}
      this.rentalProcesses
        .filter(process => process.status === 'Active')
        .forEach(process => {
          const units = process.sites.reduce( (acc, cur ) => {
            if(cur.units && cur.units.length > 0){
              acc.push(...cur.units)
            }
            return acc
          }, [])

        units.forEach(unit => {
          if (processesOrderedByUnit[unit.id_unit]){
            processesOrderedByUnit[unit.id_unit].push(process)
          } else {
            processesOrderedByUnit[unit.id_unit] = [process]
          }
        })
      })
      this.processesByUnit = processesOrderedByUnit
    },
    loadLeasingSummaries (leasingSummaries, userSites) {
      // Summarize process areas by site id
      const processAreasBySite = this.calculateProcessAreasBySite()

      // Only show selected sites
      let siteIds = this.filterSites(userSites, this.currentDate, true).map(site => site.id_site)

      // Value to tell if ending units and units with future tenants are counted as free units
      const futureOrEndingAsFree = !(this.selectedFreeUnitHandler.value === 'excludeEndingOrNext')

      // Map leasingSummaries into new values
      let data = leasingSummaries.filter(item => siteIds.includes(item.id_site)).map(item => {

        // Basic data start from leasing summaries
        let returnValue = {
          ...item,
          free_area_total: item.free_area,
          free_market_rent_total: item.free_market_rent
        }

        // If future contracts or ending units are not counted as free, reduce them from the total area
        if (!futureOrEndingAsFree) {
          returnValue.total_area -= item.leased_or_ending_in_future_area
          returnValue.total_market_rent -= item.leased_or_ending_in_future_rent
        }

        // Usage rates
        let usageRates = calculateUsageRate(item, futureOrEndingAsFree)
        returnValue.technical_usage_percentage = usageRates.technicalUsageRate
        returnValue.economic_usage_percentage = usageRates.economicUsageRate

        // Process areas from processes
        returnValue.active_prospects_area = processAreasBySite[item.id_site] ?? 0

        // Calculate average rent
        returnValue.average_rent = item.total_market_rent / item.total_area

        // Calculate net total for current and previous year
        returnValue.net_total_current_year = item.rented_area - item.rented_area_start_of_current_year
        returnValue.net_total_previous_year = item.rented_area_start_of_current_year - item.rented_area_start_of_previous_year

        // Also add status icons based on economic usage rates (using same names as free units widget for simplicity)
        if (returnValue.economic_usage_percentage > 95) {
          returnValue.rentalSituation = 'rented'
        }
        else if (returnValue.economic_usage_percentage >= 80) {
          returnValue.rentalSituation = 'in_process'
        }
        else if (returnValue.economic_usage_percentage >=70) {
          returnValue.rentalSituation = 'free_under_100'
        }
        else {
          returnValue.rentalSituation = 'free_over_100'
        }
        return returnValue
      }) 

      // Also add total usage rates for footers
      // NOTE Since footer values are sums of rows, we only add it to the first row!
      if (data.length > 0) {
        let totals = data.reduce((acc, curr) => {
          acc.totalArea += curr.total_area
          acc.freeArea += curr.free_area_total
          acc.totalMarketRent += curr.total_market_rent
          acc.freeMarketRent += curr.free_market_rent_total
          return acc
        }, 
        {
          totalArea: 0,
          freeArea: 0,
          totalMarketRent: 0,
          freeMarketRent: 0
        }
        )

        data[0].total_technical_usage_percentage = (((totals.totalArea-totals.freeArea) / totals.totalArea) * 100).toFixed(2)
        data[0].total_economic_usage_percentage = (((totals.totalMarketRent-totals.freeMarketRent) / totals.totalMarketRent) * 100).toFixed(2)
      }


      return data
    },
    calculateProcessAreasBySite () {
      const processAreasBySite = {}
      this.filterProcessesByStages(this.rentalProcesses).forEach(process => {
        process.sites.forEach(site => {
          if(processAreasBySite[site.id_site]){
            processAreasBySite[site.id_site] += process.area_preference
          }
          else {
            processAreasBySite[site.id_site] = process.area_preference
          }
        })
      })

      return processAreasBySite
    },

    /**
     * Return rentable vacant units. Also respects the selection of selectedFreeUnitHandler which is used to determine if we want to show unit which are rented in future. 
     * @param {object[]} rentalStatuses - List of rental status objects
     * @return {object[]} - List of rentable vacant rental statuses
     */
    getEmptyUnits (rentalStatuses){
      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
      const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)
      
      let filteredRentalStatuses = rentalStatuses

      // Filter out rentalstatuses with units that have end date or next tenant
      if (this.selectedFreeUnitHandler.value === 'excludeEndingOrNext' ) {
        filteredRentalStatuses = filteredRentalStatuses.filter(item => item.nextPartyId == 0 && item.curUnitEndDate == null)
      }

      return filteredRentalStatuses.filter(rs => rs.curPartyId === 0 && rentableDefs.includes(rs.unitStatus))

    },

    /**
     * Return boolean indicating if visibility is prevented for unit.
     * @param {object} rentalStatus - Rental status objec
     * @return {boolean} unit visibility prevented value
     */
    getPreventationStatus (rentalStatus) {
      const preventationEndDate = rentalStatus.preventation_end_date
      if (preventationEndDate) {
        if (moment(preventationEndDate).isAfter(this.currentDate)) {
          return true
        }
        return false
      }
      return false
    }
  }
}
</script>
<style scoped>
.widget {
  padding-top: 0;
}
.type-select {
  width: 700px;
}
.selection {
  max-width: 47%;
}
.outerBox {
  display: flex;
}
.notice {
  margin-left: 5px;
  margin-top: 12px;
}

@media screen and (max-width: 740px) {
  .selection {
    max-width: 100%;
    margin-left: 0px !important;
  }
  .outerBox {
    display: block;
  }
  .notice {
    text-align: center;
  }
}
</style>
