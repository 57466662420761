<template>
  <v-expansion-panels
    v-if="carpark"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
        >
          <div
            style="text-align: right"
          >
            {{ $t('Other information') }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getMetadataForCarPark(carpark)"
          :data="getDataForCarpark(carpark)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import MetadataForm from '../../../components/MetadataForm'
import { SHOW_CARPARK_AREA } from '../../../constants/config'
import format from '../../../helpers/format'

export default {
  name: 'CarparkAdditionalInfo',
  components: {
    MetadataForm
  },
  props: {
    carpark: { type: Object, default: null },
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
  },
  methods: {
    translatePlugin (plugin) {
      if (plugin === true) {
        return this.$t('Yes')
      }

      if (plugin === false) {
        return this.$t('No')
      }

      return null
    },
    getChargeType (charge) {

      const chargeDefinitions = this.definitionsByGroupLabel('carpark.charge')

      const chargeDef = chargeDefinitions.find(def => def.id === charge)

      return chargeDef ? chargeDef.label : '-'
    },
    getDataForCarpark (carpark) {
      if (carpark.rental_type === 'Single') {
        return {
          area: format.formatData(carpark.area, 'Area'),
          charge_in: this.getChargeType(carpark.charge_in),
          charging_power: carpark.charging_power,
          charging_info: carpark.charging_info,
          plug_in: this.translatePlugin(carpark.plug_in),
          handicap_spot: this.translatePlugin(carpark.invalid_spot),
          extra_info: carpark.extra_info,
        }
      }

      if (carpark.rental_type === 'AreaRent') {
        return {
          area: format.formatData(carpark.area, 'Area'),
          charging_spots: carpark.charging_spots_amount,
          charging_power: carpark.charging_power,
          charging_info: carpark.charging_info,
          plug_in: this.translatePlugin(carpark.plug_in),
          handicap_spot: this.translatePlugin(carpark.invalid_spot),
          extra_info: carpark.extra_info,
        }
      }

      if (carpark.rental_type === 'Privilege') {
        return {
          charging_spots_privilege: carpark.charging_spots_amount,
          charging_power: carpark.charging_power,
          charging_info: carpark.charging_info,
          plug_in: this.translatePlugin(carpark.plug_in),
          extra_info: carpark.extra_info,
        }
      }

      return {}
    },
    getMetadataForCarPark (carpark) {

      if (carpark.rental_type === 'Single') {
        return {
          area: { isShown: SHOW_CARPARK_AREA, category: 'None' },
          charge_in: { isShown: true, category: 'None' },
          charging_power: { isShown: true, category: 'None' },
          charging_info: { isShown: true, category: 'None' },
          plug_in: { isShown: true, category: 'None' },
          handicap_spot: { isShown: true, category: 'None' },
          extra_info: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
        }
      }

      if (carpark.rental_type === 'AreaRent') {
        return {
          area: { isShown: SHOW_CARPARK_AREA, category: 'None' },
          charging_spots: { isShown: true, category: 'None', format: 'Amount' },
          charging_power: { isShown: true, category: 'None' },
          charging_info: { isShown: true, category: 'None' },
          plug_in: { isShown: true, category: 'None' },
          handicap_spot: { isShown: true, category: 'None' },
          extra_info: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
        }
      }

      if (carpark.rental_type === 'Privilege') {
        return {
          charging_spots_privilege: { isShown: true, category: 'None', format: 'Amount' },
          charging_power: { isShown: true, category: 'None' },
          charging_info: { isShown: true, category: 'None' },
          plug_in: { isShown: true, category: 'None' },
          extra_info: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
        } 
      }

      return {}
    }
  }
}
</script>
<style scoped>
</style>
