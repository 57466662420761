<template>
  <v-row
    justify="space-between"
  >
    <v-col>
      {{ $t('rightlevel.' + right) }}
    </v-col>
    <v-col
      justify="end"
    >
      <v-icon
        class="text-right"
        :color="statusIcon[right].color"
      >
        {{ statusIcon[right].icon }}
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
    props: {
        right: {
            type: String,
            default: () => 'None'
        }
    },
    data () {
      return {
        statusIcon: {
          Full: {
            icon: 'check_circle',
            color: 'success'
          },
          Partial: {
            icon: 'error',
            color: 'warning'
          },
          None: {
            icon: 'cancel',
            color: 'error'
          }
        }
      }
    }
}
</script>

<style scoped>
</style>