import RambollFMAPI from '../../api/rambollfm'

export const fetchReport = async (rootState, report, siteId, time) => {
  const {
    app: { currentDate, apiEndpoint, apiToken, buildings },
    sites: { loadedBuildings }
  } = rootState

  let filteredBuildings = buildings

  if (siteId) {
    // loadedBuildings is not subject to global filters. Per request when in site view, global filters should not apply. 
    // 28.04.2023
    filteredBuildings = loadedBuildings.filter((building) => building.id_site === siteId)
  }

  let buildingIds = filteredBuildings.map((building) => building.id_building)

  if (buildingIds === undefined || buildingIds.length === 0) {
    buildingIds = await api.buildings.list()
  }
  const fetchTime = time ? time : currentDate

  const api = new RambollFMAPI(apiEndpoint, apiToken)
  const data = await api.reports.post(
    report,
    buildingIds,
    fetchTime
  )

  return data
}

export const fetchLeasingReports = async (rootState, reports, time, siteId = null, start = null, end = null) => {
  const {
    app: { currentDate, apiEndpoint, apiToken, buildings, availableSites },
    sites: { loadedBuildings }
  } = rootState

  let filteredBuildings = buildings

  if (siteId) {
    filteredBuildings = loadedBuildings.filter((building) => building.id_site === siteId)
  }

  const buildingIds = filteredBuildings.map((building) => building.id_building)

  const siteIds = availableSites.map(site => site.id_site)

  const fetchTime = time ? time : currentDate

  const api = new RambollFMAPI(apiEndpoint, apiToken)
  const data = await api.leasing.reports.list(
    reports,
    buildingIds,
    siteIds,
    fetchTime,
    start,
    end
  )

  return data
}