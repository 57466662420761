<template>
  <v-row>
    <v-autocomplete
      v-model="lvl1"
      label="KTV1"
      :placeholder="getPlaceholderForLevel(1)"
      :items="purposeZonesForLevel(1)"
      item-text="name"
      item-value="id"
      persistent-placeholder
      @change="selectLvl1"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <template #selection="{ item }">
        <span style="overflow: hidden; white-space: nowrap">{{ $t(item.name) }}</span>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="lvl2"
      label="KTV2"
      :placeholder="getPlaceholderForLevel(2)"
      :items="purposeZonesForLevel(2)"
      item-text="name"
      item-value="id"
      persistent-placeholder
      @change="selectLvl2"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-if="hasProperParent(2, item)">
            {{ $t(item.name) }}
          </v-list-item-title>
          <v-list-item-title
            v-else
            :style="{color: 'lightgrey'}"
          >
            {{ $t(item.name) }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template #selection="{ item }">
        <span style="overflow: hidden; white-space: nowrap">{{ $t(item.name) }}</span>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="lvl3"
      label="KTV3"
      :placeholder="getPlaceholderForLevel(3)"
      :items="purposeZonesForLevel(3)"
      item-text="name"
      item-value="id"
      persistent-placeholder
      @change="selectLvl3"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-if="hasProperParent(3, item)">
            {{ $t(item.name) }}
          </v-list-item-title>
          <v-list-item-title
            v-else
            :style="{color: 'lightgrey'}"
          >
            {{ $t(item.name) }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template #selection="{ item }">
        <span style="overflow: hidden; white-space: nowrap">{{ $t(item.name) }}</span>
      </template>
    </v-autocomplete>
    <v-btn
      icon
      style="padding-top: 0.8em"
      @click="clear"
    >
      <v-icon>clear</v-icon>
    </v-btn>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'PurposeZoneInput',
  props: {
    value: {
      type: Object,
      default: null
    },
    placeholder: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      levelDepth: 3,
      lvl1: null,
      lvl2: null,
      lvl3: null,
      lvl1Placeholder: {},
      lvl2Placeholder: {},
      lvl3Placeholder: {},
      undefinedId: null,
      sortedZones: []
    }
  },
  computed: {
    ...mapState('app', ['purposeZones']),
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        this.model = this.value
        if(newVal) {
          this.lvl1 = newVal.zone1
          this.lvl2 = newVal.zone2
          this.lvl3 = newVal.zone3
        }
        this.assignPlaceholders()
      }
    },
    purposeZones: {
      deep: true,
      handler: function () {
        this.initialize()
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    clear () {
      this.lvl1 = null
      this.lvl2 = null
      this.lvl3 = null
      this.model = null
      this.levelDepth = 3
    },
    setModel () {
       this.model = {
        zone1: this.lvl1,
        zone2: this.lvl2,
        zone3: this.lvl3
      }
    },
    selectLvl1 () {
      this.levelDepth = 1
      const item = this.purposeZones.find(pz => pz.id === this.lvl1)
      if (item.id === this.undefinedId) {
        this.lvl1 = null
      }
      this.lvl2 = null
      this.lvl3 = null
      this.setModel()
    },
    selectLvl2 () {
      this.levelDepth = 2
      this.lvl3 = null
      const item = this.purposeZones.find(pz => pz.id === this.lvl2)
      if (item.id !== this.undefinedId && this.lvl2 > 0) {
        this.lvl1 = item.idParent ? item.idParent : this.lvl2
      } else {
        this.lvl2 = null
        this.lvl1 = this.lvl1 ? this.lvl1 : this.lvl1Placeholder ? this.lvl1Placeholder.id : null
      }
      this.setModel()
    },
    selectLvl3 () {
      this.levelDepth = 3
      const item = this.purposeZones.find(pz => pz.id === this.lvl3)
      if (item.id !== this.undefinedId && this.lvl3 > 0) {
        if(item.level < 3) {
          this.lvl2 = this.lvl3
        } else {
          this.lvl2 = item.idParent
        }
        const pzLvl2 = this.purposeZones.find(pz => pz.id === this.lvl2)
        this.lvl1 = pzLvl2.idParent ? pzLvl2.idParent : this.lvl2
      } else {
        this.lvl3 = null
        this.lvl2 = this.lvl2 ? this.lvl2 : this.lvl2Placeholder ? this.lvl2Placeholder.id : null
        this.lvl1 = this.lvl1 ? this.lvl1 : this.lvl1Placeholder ? this.lvl1Placeholder.id : null 
      }
      this.setModel()
    },
    hasProperParent (level, item) {
      const {lvl1, lvl2, lvl1Placeholder, lvl2Placeholder} = this
      const idParent = item && item.idParent ? item.idParent : null
      const itemId = item && item.id ? item.id : null
      if (level === 2) {
        if (lvl1) {
          return idParent === lvl1 || itemId === lvl1
        }
        if(!lvl1Placeholder) {
          return item.level === 2
        }
        return idParent === lvl1Placeholder.id || itemId === lvl1Placeholder.id
      } else if (level === 3) {
        if (lvl2) {
          return idParent === lvl2 || itemId === lvl2
        }
        if (lvl1) {
          return idParent === lvl1 || itemId === lvl1
        }
        if(!lvl2Placeholder) {
          return item.level === 3
        }
        return idParent === lvl2Placeholder.id || itemId === lvl2Placeholder.id
      } else {
        return true
      }
    },
    purposeZonesForLevel (level) {
      return this.sortedZones.filter(pz => pz.level <= level)
    },
    getPlaceholderForLevel (level) {
      if (level === 1 && this.levelDepth >= 1) {
        return this.lvl1Placeholder && this.lvl1Placeholder.name
          ? this.$t(this.lvl1Placeholder.name)
          : '-'
      } else if (level === 2 && this.levelDepth >= 2) {
        return this.lvl2Placeholder && this.lvl2Placeholder.name
          ? this.$t(this.lvl2Placeholder.name)
          : '-'
      } else if (level === 3 && this.levelDepth >= 3) {
        return this.lvl3Placeholder && this.lvl3Placeholder.name
          ? this.$t(this.lvl3Placeholder.name)
          : '-'
      } else {
        return '-'
      }
    },
    assignPlaceholders () {
      if(this.placeholder) {
        this.lvl1Placeholder = this.purposeZones.find(pz => pz.id === this.placeholder.zone1)
        this.lvl2Placeholder = this.purposeZones.find(pz => pz.id === this.placeholder.zone2)
        this.lvl3Placeholder = this.purposeZones.find(pz => pz.id === this.placeholder.zone3)
      }
    },
    initialize () {
      this.undefinedId = this.purposeZones.find(pz => pz.name === "Ei määritelty" || pz.name === "ktv.undefined").id
      this.sortedZones = [...this.purposeZones].sort((a,b) => {
        if (a.level < b.level) {
          return -1
        }
        if (a.level > b.level) {
          return 1
        }
        return 0
      })
    }
  }
}
</script>
<style scoped>
</style>
