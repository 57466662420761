<template>
  <div>
    <div
      v-if="loading"
      style="text-align: center; margin: 2em"
    >
      <v-progress-circular
        style="margin: auto"
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
    <metadata-form
      v-else
      :metadata="metadata"
      :data="editedObject"
      :category="data.category"
      :disabled="isDisabled()"
      @change="generatePatch"
    />
    <v-row
      v-if="patch.length > 0 && !loading"
      justify="space-around"
    >
      <v-btn
        outlined
        rounded
        depressed
        @click="resetChanges"
      >
        {{ $t('Undo') }}
      </v-btn>
      <v-btn
        class="primary"
        outlined
        rounded
        depressed
        @click="saveChanges"
      >
        {{ $t('Save changes') }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MetadataForm from '../MetadataForm.vue'
export default {
  components: {
    MetadataForm,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    title: {
      type: String,
      default: function () {
        return ''
      },
    },
  },
  data: function () {
    return {
      item: null,
      editedObject: null,
      metadata: {},
      patch: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    ...mapState('app', ['currentDate']),
  },
  watch: {
    currentSiteId: function () {
      this.loadData()
    },
    currentDate: function () {
      this.loadData()
    },
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true

      this.patch = []
      this.editedObject = null

      // Assign metadata either from the widget data or fetch it from a provided function.
      if (this.data.metadata) {
        this.metadata = this.data.metadata
      } else if (this.data.getMetadata) {
        this.metadata = await this.data.getMetadata(this)
      } else {
        this.$log.error(
          'Metadata is not assigned for widget: ',
          this.data.title
        )
      }
      this.item = await this.data.getData(this)
      this.editedObject = JSON.parse(JSON.stringify(this.item))
      this.loading = false
    },
    generatePatch () {
      this.patch = this.$jsonpatch.compare(this.item, this.editedObject)
    },
    resetChanges () {
      this.patch = []
      this.editedObject = JSON.parse(JSON.stringify(this.item))
    },
    async saveChanges () {
      if (this.data && this.data.validate) {
        if (!this.data.validate(this.editedObject)) {
          return
        }
      }

      this.loading = true
      if (this.data.patchData) {
        await this.data.patchData(this)
      } else if (this.data.putData) {
        await this.data.putData(this)
      }
      await this.loadData()
    },
    isDisabled () {
      if (this.data.isDisabled) {
        return this.data.isDisabled(this)
      }
      return false
    },
  },
}
</script>

<style scoped></style>
