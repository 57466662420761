<template>
  <div>
    <v-list
      v-if="data"
      dense
      class="info-box"
    >
      <v-list-item
        v-for="row in data.items"
        :key="row.id"
        :class="{
          tile: true
        }"
      >
        <v-list-item-content
          class="tile-content align-self-stretch"
        >
          {{ $t(row.title) }}
        </v-list-item-content>
        
        <v-list-item-content
          class="tile-content"
        >
          <a
            :href="addHttpsIfProtocolNotThere(row.link)"
            target="_blank"
          >
            {{ row.link }}</a>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-btn 
      v-if="data.buttonVisible"
      small
      outlined
      rounded
      content
      style="margin-top: 0.8em"
      class="theme-light btn-add-link"
      @click="addNewLink()"
    >
      <span>
        {{ $t("Add link") }}
      </span>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      items: [],
      buttonVisible: true
    }
  },
  methods: {
    addNewLink () {
        this.$emit("addNewLinkDialogOpen")
    },
    addHttpsIfProtocolNotThere (link) {
      if (!link.startsWith('https://') && !link.startsWith('http://'))
        return 'https://' + link;
      return link;
    }
  }
}
</script>
<style scoped>
.btn-add-link {
  margin-left: 1.5em;
  margin-right: 1.5em;
}
</style>
