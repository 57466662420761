<template>
  <div>
    <LineChart
      v-if="!!data"
      :chart-data="chartData"
      :options="options"
      :height="200"
    />
  </div>
</template>

<script>
import LineChart from '../LineChart'

export default {
  components: {
    LineChart
  },
  props: {
    data: {
      type: Object,
      default: () => undefined,
    }
  },
  computed: {
    chartData () {
      var datasets = []
      var items = this.data?.items?.length > 0 && !this.data?.placeholderOnly
        ? this.data.items
        : (this.data?.placeholderItems ?? [])
      if (this.data?.lines?.length > 0 && items?.length > 0) {
        for (let i = 0; i < this.data.lines.length; i++) {
          var line = this.data.lines[i]
          if ((!line.xField && !line.xValue) || (!line.yField && !line.yValue)) continue

          var dataset = {
            label: line.label ?? '',
            backgroundColor: line.backgroundColor ?? line.color ?? '#7DC3F2',
            borderColor: line.color ?? '#7DC3F2',
            fill: line.fill ?? false,
            borderDash: line.dashed ? [5, 5] : undefined,
            pointRadius: line.pointRadius ?? 2,
            tension: line.tension ?? 0.25,
            data: items?.map(obj => ({
              x: line.xValue ?? obj[line.xField],
              y: line.yValue ?? obj[line.yField],
            })) ?? []
          }
          datasets.push(dataset)
        }
      }
      var labels = items?.map(obj => obj[this.data?.xAxis?.field]) ?? []
      
      return { datasets, labels }
    },
    options () {
      let options = JSON.parse(JSON.stringify(this.data?.options ?? {}))

      // Top-level hover behaviour
      options.hover = {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      }
      options.tooltips = options.hover // copy hover behaviour to tooltips

      if (this.data?.options?.tooltips?.callbacks !== undefined) {
        options.tooltips.callbacks = this.data?.options?.tooltips?.callbacks 
      }

      options.scales = options.scales ?? {}
      options.scales.yAxes = [{ ticks: {}, grace: '10%' }, ...this.data?.options.scales.yAxes]
      options.scales.xAxes = [{ ticks: {} }]
      options.scales.grace = '10%'

      let callback = this.data?.yAxis?.callback
      if (callback != null) {
        options.scales.yAxes[0].ticks.callback = callback
      }

      let maxTicksLimit = this.data?.yAxis?.maxTicksLimit
      if (maxTicksLimit != null) {
        options.scales.yAxes[0].ticks.maxTicksLimit = maxTicksLimit
      }

      let minY = this.data?.yAxis?.min
      if (minY != null) {
        options.scales.yAxes[0].ticks.min = minY
      }

      let maxY = this.data?.yAxis?.max
      if (maxY != null) {
        options.scales.yAxes[0].ticks.max = maxY
      }

      let minX = this.data?.xAxis?.min
      if (minX != null) {
        options.scales.xAxes[0].ticks.min = minX
      }

      let maxX = this.data?.xAxis?.max
      if (maxX != null) {
        options.scales.xAxes[0].ticks.max = maxX
      }

      let yAxisLabel = this.data?.yAxis?.label
      if (yAxisLabel) {
        options.scales.yAxes[0].scaleLabel = {
          display: true,
          labelString: yAxisLabel
        }
      }

      let xAxisLabel = this.data?.xAxis?.label
      if (xAxisLabel) {
        options.scales.xAxes[0].scaleLabel = {
          display: true,
          labelString: xAxisLabel
        }
      }

      return options
    },
  },
}
</script>
