import * as log from 'loglevel'

function install (Vue, options) {
  if (typeof options !== 'undefined') {
    if (typeof options.logLevel === 'string') {
      log.setLevel(options.logLevel)
    }
  }
  Vue.loglevel = log
  Vue.prototype.$log = log
}

export default {
  install: install
}
