<template>
  <v-row
    justify="space-between"
    align="center"
    no-gutters
    class="c-view px-4 pt-6"
  >
    <v-col
      lg="4"
      cols="12"
    >
      <v-row
        no-gutters
        class="flex-nowrap"
      >
        <slot name="selections" />
      </v-row>
    </v-col>
    <v-col
      lg="8"
      cols="12"
      class="toolbar dashboard"
    >
      <v-row 
        justify-xs="start"
        justify-lg="end"
      >
        <slot name="buttons" />
      </v-row>
    </v-col>
    <v-col
      cols="12"
      class="c-view__main mt-4"
    >
      <slot name="main" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'BaseView',
  data () {
    return {
    }
  },
}
</script>
<style scoped>
.c-view__main:empty {
  display: none;
}
</style>