<template>
  <div class="c-metadataform">
    <v-list
      v-if="data"
      dense
      class="info-box"
    >
      <v-list-item
        v-for="field in fields"
        :key="field.name"
        :class="{
          tile: field.editType !== 'TextArea' || !multiline,
          'tile tile-full-height': field.editType === 'TextArea' && multiline,
          'mb-3': field.groupLast
        }"
      >
        <v-list-item-content
          v-if="field.category !== 'Description' && field.category !== 'LeasePlan' && field.category !== 'AdditionalInfo' && field.editType !== 'TextArea' && field.editType !== 'ReadOnlyTextAreaw'"
          class="tile-content align-self-stretch"
        >
          {{ field.label ? $t(field.label) : $t(getFieldName(field)) }}
        </v-list-item-content>
        <v-list-item-content class="tile-content align-self-stretch">
          <v-autocomplete
            v-if="field.name === 'neighborhood'"
            v-model="data['district']"
            :items="neighborhoods"
            :label="$t('Neighborhood') + ' ' + field.isRequired ? $t('Required') : ''"
            small
            item-text="name"
            item-value="locationAreaNumber"
            :class="field.isRequired ? 'borderless mb-4' : 'borderless'"
            :hint="field.isRequired ? $t('Required') : ''"
            persistent-hint
            :append-outer-icon="field.editType === 'DropDown' ? 'edit' : ''"
            :disabled="field.editType !== 'DropDown' || disabled"
            return-object
          />
          <v-select 
            v-else-if="field.name === 'rating_class'"
            v-model="data['rating_class']"
            :items="buildingRatingClasses"
            :label="$t('building rating class')"
            small
            item-text="name"
            item-value="id"
            class="borderless"
            :append-outer-icon="field.editType === 'DropDown' ? 'edit' : ''"
            :disabled="field.editType !== 'DropDown' || disabled"
          />
          <v-select
            v-else-if="field.name === 'building_class' && Array.isArray(field.classificationOptions) && !managedByIntegration"
            v-model="data['building_class']"
            :items="field.classificationOptions.filter(co => co.classificationLevel === 3)"
            :label="$t('building class')"
            small
            item-text="nimi"
            item-value="id"
            class="borderless"
            append-outer-icon="edit"
            @change="changeBuildingClassificationId(data['building_class'])"
          />
          <span
            v-else-if="field.name === 'building_class' && managedByIntegration"
            small
            :value="data['building_class_name']"
            class="borderless"
          >
            <span>{{ data['building_class_name'] }}</span>
          </span>
          <v-select 
            v-else-if="field.name === 'main_class' && Array.isArray(field.siteClassificationOptions)"
            v-model="data['main_class']"
            :items="field.siteClassificationOptions"
            :label="$t('Main class')"
            small
            item-text="nimi"
            item-value="id"
            class="borderless"
            append-outer-icon="edit"
            @change="$emit('change', { data:data, field:fields.find(field => field.name == 'main_class'), value: data['main_class'] })"
          />
          <v-text-field
            v-else-if="field.editType === 'TextField' && !disabled && !managedByIntegration"
            v-model="data[field.name]"
            :aria-label="$t(getFieldName(field))"
            :counter="field.maxlength"
            :maxlength="field.maxlength"
            dense
            class="borderless mb-2"
            append-icon="edit"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-text-field
            v-else-if="
              field.editType === 'TextField' && 
                !disabled && 
                ['building_name','address_other','post_office','new_comment','temporary_permit_desc', 'completed_year'].includes(field.name) 
            "
            v-model="data[field.name]"
            :aria-label="$t(getFieldName(field))"
            :maxlength="field.maxlength"
            :counter="field.maxlength"
            dense
            class="borderless mb-2"
            append-icon="edit"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <DateField
            v-else-if="field.editType === 'Date' && !disabled && (!managedByIntegration || field.name === 'temporary_permit_validity')"
            v-model="data[field.name]"
            :aria-label="$t(getFieldName(field))"
            class="borderless"
            :append-icon="'edit'"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <NumericField
            v-else-if="
              field.editType === 'Number' && field.type !== 'Boolean' && !disabled && !managedByIntegration
            "
            v-model="data[field.name]"
            class="borderless"
            :field="field"
            :placeholder="data[field.name]"
            :aria-label="$t(getFieldName(field))"
            :append-icon="'edit'"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-switch
            v-else-if="field.editType === 'Switch' && !disabled"
            v-model="data[field.name]"
            class="switch-margin"
            hide-details="true"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-select
            v-else-if="field.editType === 'Checkbox' && !disabled && (!managedByIntegration || field.name === 'temporary_relocation')"
            v-model="data[field.name]"
            :label="$t(getFieldName(field)) + ' ' + field.isRequired ? $t('Required') : ''"
            small
            :items="checkboxItems"
            item-text="text"
            item-value="value"
            :class="field.isRequired ? 'mb-4' : ''"
            class="borderless"
            :hint="field.isRequired ? $t('Required') : ''"
            persistent-hint
            append-outer-icon="edit"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-combobox
            v-else-if="!disabled && field.name === 'tags'"
            v-model="data[field.name]"
            item-text="name"
            item-value="id"
            :items="tags"
            :label="$t(getFieldName(field))"
            class="borderless"
            style="height: auto"
            clearable
            multiple
            small-chips
            deletable-chips
            @change="onTagsChange(data[field.name])"
          />

          <v-combobox
            v-else-if="field.editType === 'DropDown' && !disabled && field.name === 'owner'"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            :items="parties"
            class="borderless"
            item-text="name"
            item-value="id"
            :disabled="disabled"
            append-outer-icon="edit"
            return-object           
            @change="changeBusinessIdInField(data[field.name]),$emit('change', { data, field, value: variableForOwner})"
          />
          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && field.name === 'id_parking_company'"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            :items="enterpriseParties"
            class="borderless"
            item-text="name"
            item-value="id"
            :disabled="disabled"
            append-outer-icon="edit"
            return-object
            @change="changeEnterprisePartyIdInField (data[field.name])"
          />
          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && ['district'].includes(field.name)"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            :items="
              getDefinitionOptionsById(field.definitionGroupId, field.isRequired)
            "
            item-text="label"
            item-value="id"
            class="borderless"
            append-outer-icon="edit"
            :disabled="disabled"
            @change="changeMdistrict(data[field.name]),$emit('change', { data, field, value: data[field.name] })"
          />
          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && ['building_exact_usage','lclassification','life_cycle','objective','governance_entity','rental_condition_class','legal_entity','maintenance'].includes(field.name)"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            :items="
              getDefinitionOptionsById(field.definitionGroupId, field.isRequired)
            "
            item-text="label"
            item-value="id"
            class="borderless"
            append-outer-icon="edit"
            :disabled="disabled"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
          <v-select 
            v-else-if="field.editType === 'DropDown' && !disabled && ['waste_fractions_collected'].includes(field.name)"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            multiple
            :items="getDefinitionOptionsById(field.definitionGroupId, field.isRequired)"
            item-text="label"
            item-value="id"
            class="borderless"
            style="height: auto"
            append-outer-icon="edit"
            :disabled="disabled"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && !managedByIntegration"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            small
            :items="
              getDefinitionOptionsById(field.definitionGroupId, field.isRequired)
            "
            item-text="label"
            item-value="id"
            class="borderless"
            append-outer-icon="edit"
            :disabled="disabled"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <template 
            v-else-if="field.editType === 'TextArea' && 
              !['Description', 'LeasePlan', 'AdditionalInfo' ].includes(field.category) && 
              !disabled && 
              !field.singleLine"
          >
            <p class="font-weight-bold mt-4">
              {{ $t(getFieldName(field)) }}
            </p>
            <v-textarea
              v-model="data[field.name]"
              :label="$t(getFieldName(field))"
              :single-line="false"
              :class="field.isRequired ? 'borderless mb-4' : 'borderless extrainfo-text'"
              :hint="field.isRequired ? $t('Required') : ''"
              persistent-hint
              :maxlength="useMaxlength(field)"
              :counter="showCounter(field)"
              append-outer-icon="edit"
              :disabled="disabled"
              :rows="getRows()"
              @change="$emit('change', { data, field, value: data[field.name] })"
            />
          </template>
        
          <v-textarea
            v-else-if="field.editType === 'TextArea' && !disabled"
            v-model="data[field.name]"
            :label="$t(getFieldName(field))"
            :single-line="field.category === 'Description' || field.category === 'LeasePlan'"
            class="borderless"
            hide-details
            append-outer-icon="edit"
            :maxlength="useMaxlength(field)"
            :disabled="disabled"
            :rows="getRows()"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
          <template v-else-if="field.editType === 'ReadOnlyTextAreaw' && !disabled">
            <strong>{{ $t(getFieldName(field)) }}</strong>
            <v-textarea
              v-model="data[field.name]"
              :label="$t(getFieldName(field))"
              :maxlength="field.maxlength"
              class="borderless extrainfo-text white"
              hide-details
              readonly
              flat
              solo
              :disabled="disabled"
              :rows="getRows()"
            />
          </template>
          <span
            v-else
            small
            :value="data[field.name]"
            class="borderless"
          >
            <span v-if="field.definitionGroupId > 0">{{
              getDefinitionLabelForGroup(
                field.definitionGroupId,
                data[field.name]
              )
            }}</span>
            <span v-else-if="field.isDefinition === true">
              <span
                v-if="field.type === 'Array'"
              >
                <span
                  v-for="value in data[field.name]"
                  :key="value.value ? value.value : value"
                >
                  {{ getDefinitionLabel(value) }}
                </span>
              </span>
              <v-row
                v-else-if="field.hasIcon"
                align="center"
              >
                <v-col
                  cols="11"
                  class="borderless"
                >
                  {{ getDefinitionLabel(data[field.name]) }}
                </v-col>
                <StatusIcon                
                  :field="field"
                  :data="getDefinitionLabel(data[field.name])"
                />
              </v-row>
              <span
                v-else
              >
                {{ getDefinitionLabel(data[field.name]) }}
              </span>
            </span>
            <span v-else-if="field.type === 'Boolean'">
              <span v-if="data[field.name] === true">{{ $t('Yes') }}</span>
              <span v-else-if="data[field.name] === false">{{ $t('No') }}</span>
              <span v-else>-</span>
            </span>
            <span
              v-else-if="field.type === 'Hyperlink'"
              class="linked"
              @click="openLink(data[field.name])"
            >
              {{ data[field.name] }}
            </span>
            <span
              v-else-if="field.type === 'Array' || field.type === 'List`1'"
            >
              <span
                v-for="value in data[field.name]"
                :key="getKey(value)"
              >
                <span
                  v-if="typeof value === 'object'"
                  style="display: block;"
                >
                  <span
                    v-if="value.type === 'Hyperlink'"
                    class="linked"
                    @click="openLink(value.url ? value.url : value.value)"
                  >
                    {{ value.value }}
                  </span>
                  <span
                    v-else-if="value.type === 'DocHyperlink'"
                    class="linked"
                    @click="downloadDocument(value)"
                  >
                    {{ value.value }}
                  </span>
                  <button
                    v-else-if="value.type === 'Contract' || field.name === 'contract_identifiers'"
                    class="linked"
                    @click="$emit('openContract', value)"
                  >
                    {{ value.contract_number }}
                  </button>
                </span>
                <span v-else>
                  {{ value }}
                </span>
              </span>
            </span>
            <span v-else>
              <v-row
                v-if="withStatusIcon"
                align="center"
                justify="space-between"
                no-gutters
              >
                <v-col class="borderless line-breaks-allowed">
                  <span v-if="field.max">
                    {{ `${data[field.name]}/${field.max}` }}
                  </span>
                  <span v-else>
                    {{ getValueWithUnit(data[field.name], field.format) }}
                  </span>
                </v-col>
                <StatusIcon
                  v-if="data.building_code"
                  :field="field"
                  :data="data[field.name]"
                  :has-dwgs="buildingHasDwg"
                  :managed-by-integration="data.managed_by_intg"
                />
                <StatusIcon
                  v-else
                  :field="field"
                  :data="data[field.name]"
                  :has-dwgs="hasDwgs"
                />
              </v-row>
              <span
                v-else-if="field.type === 'Boolean' && data[field.name] === true"
              >{{ $t('Yes') }}</span>
              <span
                v-else-if="field.type === 'Boolean' && data[field.name] === false"
              >{{ $t('No') }}</span>
              <span v-else>{{
                getValueWithUnit(data[field.name], field.format)
              }}</span>
            </span>
          </span>
        </v-list-item-content> 
      </v-list-item>
    </v-list>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import { mapGetters,mapState } from 'vuex'
import format from '../helpers/format'
import StatusIcon from '../components/StatusIcon'
import Alert from '../components/Alert'
import NumericField from '../components/NumericField'
import DateField from '../components/DateField'
import saveAs from '../helpers/saveAs'

export default {
  components: {
    StatusIcon,
    Alert,
    NumericField,
    DateField
  },
  props: {
    metadata: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    category: {
      type: String,
      default: 'None'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    },
    withStatusIcon: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    },
    neighborhoods: {
      type: Array,
      default: () => []
    },
    buildingRatingClasses: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      checkboxItems: [
        { text: this.$t('Yes'), value: true },
        { text: this.$t('No'), value: false },
        { text: this.$t('Undefined'), value: null }
      ],
      variableForOwner: '',
      variableForMdistrict: '',
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      inBeforeMount: false
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById', 'definitionByLabel']),
    ...mapState('app', ['parties', 'enterpriseParties']),
    managedByIntegration () {
      return this.data.managed_by_intg ? this.data.managed_by_intg : false
    },
    fields () {
      if (this.metadata === null) {
        return []
      }
      const rFields = Object.keys(this.metadata)
        .map(key => ({ ...this.metadata[key], name: key }))
        .filter(v => v.isShown)
        .filter(v => v.category === this.category)
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1
          }
          if (a.order > b.order) {
            return 1
          }
          return 0
        })
      return rFields
    },
    buildingHasDwg () {
      if (this.data.building_code) {
        if (this.data.has_dwg) {
          return true
        }
      }
      return false
    },
    hasDwgs () {
      return this.$store.state.app.dwgsAvailable
    }
  },
  watch: {
    data: function () {},
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  beforeMount () {
    this.inBeforeMount = true
    if (Object.keys(this.metadata).filter(k => k === 'building_class').length === 1) {
      this.changeBuildingClassificationId(this.data['building_class'])
    }
    this.inBeforeMount = false
  },
  methods: {
    onlyNumeric (value) {
      if (value !== '') {
        if (!/^\d*(\.\d{1,2})?$/.test(value)) {
          this.indicatorRunning = true
          this.indicatorMessage = this.$t('Field accepts only numeric values')
          this.operationResult = 'error'
          this.showIndicator = true
        }
      }
    },
    changeMdistrict (id)
    {
      var value = this.getDefinitionLabel(id)
      
      if(value.startsWith("1"))
        {
          this.variableForMdistrict  = "1 ETELÄINEN SUURPIIRI"
        }  
      else if(value.startsWith("2"))
        {
          this.variableForMdistrict= "2 LÄNTINEN SUURPIIRI"
        }
      else if(value.startsWith("3"))
        {
          this.variableForMdistrict = "3 KESKINEN SUURPIIRI"
        }
      else if(value.startsWith("4"))
        {
          this.variableForMdistrict = "4 POHJOINEN SUURPIIRI"
        }
      else if(value.startsWith("5"))
        {
          this.variableForMdistrict = "5 KOILLINEN SUURPIIRI"
        }
      else if(value.startsWith("6"))
        {
          this.variableForMdistrict = "6 KAAKKOINEN SUURPIIRI"
        }
      else if(value.startsWith("7"))
        {
          this.variableForMdistrict = "7 ITÄINEN SUURPIIRI"
        }
      else if(value.startsWith("8"))
        {
          this.variableForMdistrict = "8 ÖSTERSUNDOMIN SUURPIIRI"
        }
      else
        {
          this.variableForMdistrict = " "
        }
      var majorId = this.definitionByLabel(this.variableForMdistrict).id
      this.data['major_district'] = majorId
      this.$emit('change', { data:this.data, field:this.fields.find(field => field.name == 'major_district'), value: majorId })
    },
    getDefinitionLabelForGroup (groupId, definitionId) {
      const definitions = this.getDefinitionOptionsById(groupId)
      const definition = definitions.find(
        d => Number(d.id) === Number(definitionId)
      )
      if (definition) {
        return definition.label
      } else {
        return ''
      }
    },
    getFieldName (field) {
      let fieldName = field.name
      if (field.label && field.label?.length > 0) {
        return field.label
      }

      if (field.name === 'tags') {
        return 'Category'
      }

      // guess the name
      fieldName = fieldName.split('_').join(' ')
      fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
    
      if (Object.keys(this.data).indexOf('unit_code') > -1) {
        if (fieldName === 'Id') {
          fieldName = `${this.$t('Unit')} ${fieldName}`
        }
      }
      if (Object.keys(this.data).indexOf('rental_type') > -1) {
        if (fieldName === 'Market rent car space') {
          fieldName = fieldName.replace('car', '€/car')
        }
      }

      return fieldName
    },
    getDefinitionOptionsById (definitionId, isRequired) {
      if (definitionId === 0) {
        return []
      }
      var defs = this.definitionsByGroupId(definitionId)
        .map(d => {
          return {
            id: d.id,
            annotationGroup: d.annotationGroup,
            label: this.$t(d.label)
          }
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
            return 0
        })
      if (isRequired === false) {
        defs.unshift({
          id: null,
          annotationGroup: null,
          label: this.$t('undefined')
        })
      }

      return defs
    },
    getDefinitionLabel (id) {
      return this.definitionLabelById(id)
    },
    getValueWithUnit (value, formatting) {
      return format.formatData(value, formatting)
    },
    getRows () {
      if (this.multiline) {
        return 5
      } else {
        return 3
      }
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    showCounter (field) {
      const TEXT_AREA_NAMES_WITH_COUNTER = new Set([
        'site_desc', 
        'area_description', 
        'identification_badges', 
        'visitors', 
        'goods_reception', 
        'postal_service', 
        'accessibility_description', 
        'moving', 
        'parking', 
        'cycle_storage_description'
      ]);
      return TEXT_AREA_NAMES_WITH_COUNTER.has(field?.name) ? field.maxlength : false
    },
    useMaxlength (field) {
      const TEXT_AREA_WITH_MAXLENGTH = new Set([
        'additional_info',
      ]);
      return !TEXT_AREA_WITH_MAXLENGTH.has(field?.name) ? field.maxlength : false
    },
    openLink (link) {
      window.open(link)
    },
    changeBuildingClassificationId (newValue) {
      if (newValue === null || newValue === undefined) {
        return
      }
      let classification = this.metadata['building_class'].
        classificationOptions.filter(co => co.id === newValue)[0]
      this.data['building_class_name'] = classification.nimi
      let subClassification = this.metadata['building_class'].
        classificationOptions.filter(co => co.classificationLevel === 2 && 
        co.id === classification.idParent)[0]
      this.data['building_sub_class'] = subClassification.nimi
      let mainClassification = this.metadata['building_class'].
        classificationOptions.filter(co => co.classificationLevel === 1 && 
        co.id === subClassification.idParent)[0]
      this.data['building_main_class'] = mainClassification.nimi
      if (!this.inBeforeMount) {
        this.$emit('change', { data:this.data, field:this.fields.find(field => field.name == 'building_class'), value: newValue }) 
      }
    },
    async downloadDocument (doc) {
      const progress = {
        retrievalProgress: 0
      }
      this.$rambollfmapi.documents
        .download(doc.docId, progress)
        .then(res => {
          const blob = new Blob([res.data], { type: 'octet/stream' })
          saveAs(blob, doc.value)
        })
        .catch(() => {
          alert(this.$t('Document download failed'))
        })
    },
    changeBusinessIdInField (obj)
    {
      var parTies = this.parties
      var target = parTies.filter(x => x.id === obj.id);
      var businessID = target[0]['business_id']
      this.data['businessId'] = businessID
      this.variableForOwner = target[0]['name']
      this.$emit('change', { data:this.data, field:this.fields.find(field => field.name == 'businessId'), value: businessID })     
    },
    changeEnterprisePartyIdInField (obj) {
      this.$emit('change', { data:this.data, field:this.fields.find(field => field.name == 'id_parking_company'), value: obj.id }) 
    },
    onTagsChange (tags) {
      let updatedTags = []
      if (tags != null && tags.length > 0) {
        tags.forEach(tag => {
          let updatedTag = null
          if (typeof tag === 'string') {
            updatedTag = { name: tag }
          } else {
            updatedTag = { ...tag }
          }

          if (updatedTag != null) {
            updatedTags.push(updatedTag)
          }
        })
      }

      this.$emit('change', { data: this.data, field: this.fields.find(field => field.name == 'tags'), value: updatedTags })
    },
    getKey (value) {
      if (value.value) {
        return value.value
      } else if (value.id) {
        return value.id
      } else if (value.contract_number) {
        return value.contract_number
      } else {
        return value
      }
    },
  }
}
</script>
<style scoped>
.switch-margin {
  margin: auto;
}
.extrainfo-text {
  font-weight: normal;
}
.line-breaks-allowed {
  white-space: pre-line;
}
.c-metadataform >>> .v-list-item {
  border: none;
  border-bottom: 2px solid var(--r-color-mountain-5);
}
.c-metadataform >>> .v-list-item:last-child {
  border: none;
}
</style>
