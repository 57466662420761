<template>
  <div>
    <!-- Prevent visibility modal-->
    <v-dialog
      :value="currentModal === 'PreventVisibility'"
      persistent
      width="550"
    >
      <BaseModal
        @cancel="setModal(null)"
      >
        <template #title>
          {{ $t('Prevent visibility') }}
        </template>
        <template #content>
          <div>
            <div>
              <p>{{ $t('Prevent visibility info text') }}</p>
            </div>
            <div>
              <v-text-field
                v-model="reasoning"
                :label="$t('Reasoning')"
                class="form-field"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <v-btn
            :class="[reasoning ? 'primary' : 'disabled' ]"
            rounded
            depressed
            :disabled="reasoning ? false : true"
            @click="onPreventConfirm"
          >
            {{ $t('Confirm') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <!-- Force publishing modal -->
    <v-dialog
      :value="currentModal === 'ForcePublishing'"
      persistent
      width="550"
    >
      <BaseModal
        @cancel="setModal(null)"
      >
        <template #title>
          {{ $t('Rented unit: Force publishing to web') }}
        </template>
        <template #content>
          <div>
            <div>
              <p>{{ $t('Force publishing info text') }}</p>
            </div>
            <div>
              <v-text-field
                v-model="reasoning"
                :label="$t('Reasoning')"
                class="form-field"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <v-btn
            :class="[reasoning ? 'primary' : 'disabled' ]"
            rounded
            depressed
            :disabled="reasoning ? false : true"
            @click="onForcePublishingConfirm"
          >
            {{ $t('Confirm') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          {{ $t('Reservation status') + ':' }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-text-field
          v-model="statusText"
          class="status_text"
          readonly
          :append-icon="prevented ? 'cancel' : ''"
        />
      </v-list-item>
      <div
        v-if="prevented || publishingForced"
        class="reasoning-container"
      >
        <p class="reasoning-text">
          {{ reasoningText }}
        </p>
        <p class="reasoning-text">
          {{ reasoningModifierText }}
        </p>
        <p class="reasoning-text">
          {{ reasoningModifiedText }}
        </p>
      </div>
      <div style="padding: 0 16px">
        <v-btn
          :class="reserved ? 'visibility_handler_active' : 'visibility_handler'"
          outlined
          rounded
          text
          @click="reserve"
        >
          {{ reserved ? $t('Remove reservation') : $t('Reserve 30 days') }}
        </v-btn>
        <v-btn
          :class="
            prevented ? 'visibility_handler_active' : 'visibility_handler'
          "
          outlined
          rounded
          text
          @click="onPreventButton"
        >
          {{
            prevented
              ? $t('Remove preventation')
              : $t('Prevent visibility')
          }}
        </v-btn>
      </div>
      <div style="padding: 0 44px 0 16px">
        <v-btn
          :class="
            publishingForced
              ? 'visibility_handler_active'
              : 'visibility_handler'
          "
          :disabled="forcedPublishingDisabled"
          outlined
          rounded
          text
          block
          @click="onForcePublishingButton"
        >
          {{
            publishingForced
              ? $t('Remove forced publishing')
              : $t('Rented unit: Force publishing to web')
          }}
        </v-btn>
      </div>
    </v-list>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import humanize from '../../helpers/humanize'
import BaseModal from '../general/BaseModal'
export default {
  name: 'UnitWebVisibilityField',
  components: {
    BaseModal,
  },
  props: {
    selectedUnit: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data () {
    return {
      currentModal: null,
      reasoning: '', // User inputs this before he may submit prevent or force publishing.
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapState('leasing', ['prospects']),
    statusText () {
      if (this.reserved) {
        return (
          this.$t('Reserved') +
          ' (' +
          this.$t('reservation ends') +
          ' ' +
          humanize.date(this.selectedUnit.reservation_end_date) +
          ')'
        )
      } else if (this.prevented) {
        return (
          this.$t('Visibility prevented')
        )
      } else if (this.contractInAllocation) {
        return (
          this.$t('Contract in allocation') +
          ' (' +
          this.$t('ends after allocation') +
          ')'
        )
      } else if (this.publishingForced) {
        const forcedPublishEnd = moment(
          this.selectedUnit.forced_publish_date
        ).subtract(180, 'days')
        return (
          this.$t('Forced publish') +
          ' (' +
          this.$t('publishing ends') +
          ' ' +
          forcedPublishEnd.format('DD.MM.YYYY') +
          ')'
        )
      } else {
        return this.$t('No reservations')
      }
    },
    reserved () {
      return (
        this.selectedUnit.reservation_end_date &&
        moment(this.currentDate).isBefore(
          this.selectedUnit.reservation_end_date
        )
      )
    },
    prevented () {
      return (
        this.selectedUnit.preventation_end_date &&
        moment(this.currentDate).isBefore(
          this.selectedUnit.preventation_end_date
        )
      )
    },
    contractInAllocation () {
      const awaitingAllocationForSiteAndUnit = this.prospects.filter(
        (prospect) =>
          prospect.status === 'AwaitingAllocation' &&
          prospect.sites.filter(
            (site) =>
              site.id_site === this.selectedUnit.id_site &&
              site.units.filter((unit) => unit.id_unit === this.selectedUnit.id)
                .length
          ).length
      )
      return Boolean(awaitingAllocationForSiteAndUnit.length)
    },
    forcedPublishingDisabled () {
      const overHalfYearFromInspectionDate = moment(this.currentDate).add(
        179,
        'days'
      )

      const { contract_end_date: endDate, tenant } = this.selectedUnit

      // If not rented
      if (!tenant) {
        return true
      }

      // If no endDate defined it is valid unit further notice contract
      if (!endDate) {
        return false
      }

      const contractEnd = moment(endDate)

      return !(
        this.selectedUnit.contract_end_date &&
        contractEnd.isAfter(overHalfYearFromInspectionDate, 'day')
      )
    },
    publishingForced () {

      const forcedPublishEnd = moment(this.selectedUnit.forced_publish_date).subtract(180, 'days')

      return (
        this.selectedUnit.forced_publish_date &&
        moment(this.currentDate).isBefore(forcedPublishEnd)
      )
    },
    reasoningText () {
      const { reservation_reasoning: reasoning } = this.selectedUnit

      return `${this.$t('Reasoning')}: ${reasoning}`
    },
    reasoningModifierText () {
      const { reservation_modifier: modifier } = this.selectedUnit

      return `${this.$t('Username')}: ${modifier}`
    },
    reasoningModifiedText () {
      const { reservation_modified: modified } = this.selectedUnit

      const modifiedDate = new Date(modified)
      return `${this.$t('Time')}: ${modifiedDate.getDate()}.${modifiedDate.getMonth() + 1}.${modifiedDate.getFullYear()} klo ${modifiedDate.getHours()}:${modifiedDate.getMinutes()}`
    },
  },
  methods: {
    // Prevent button on the ui.
    onPreventButton () {
      if (this.prevented) {
        this.preventVisibility()
      } else {
        this.setModal('PreventVisibility')
      }
    },
    // Confirmation modal confirm button.
    onPreventConfirm () {
      this.preventVisibility(this.reasoning)
      this.setModal(null)
    },
    onForcePublishingButton () {
      if (this.publishingForced) {
        this.forcePublishing()
      } else {
        this.setModal('ForcePublishing')
      }
    },
    onForcePublishingConfirm () {
      this.forcePublishing(this.reasoning)
      this.setModal(null)
    },
    setModal (value) {
      this.currentModal = value
      this.reasoning = ''
    },
    async reserve () {
      var patch = []
      if (this.reserved) {
        patch = [
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: null,
          },
        ]
      } else {
        const endDate = this.getEndDate(30)
        patch = [
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: endDate.date,
          },
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: null,
          },
        ]
        if (endDate.versionEndDate) {
          alert(this.$t('unit.reservation_string'))
        }
      }

      await this.$rambollfmapi.units.patch(
        this.selectedUnit.id,
        this.currentDate,
        patch
      )
      this.$emit('update')
    },
    async preventVisibility (reasoning) {
      var patch = []
      if (this.prevented) {
        patch = [
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: null,
          },
        ]
      } else {
        const endDate = new Date('9999-01-01T00:00:00')
        patch = [
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: endDate,
          },
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: null,
          },
        ]
      }

      if (reasoning) {
        patch.push({
          op: 'replace',
          path: '/reservation_reasoning',
          value: reasoning,
        })
      }

      await this.$rambollfmapi.units.patch(
        this.selectedUnit.id,
        this.currentDate,
        patch
      )
      this.$emit('update')
    },
    getEndDate (days) {
      const date = new Date(this.currentDate)
      const endDate = new Date(date.setDate(date.getDate() + days))

      return this.selectedUnit.end_date &&
        moment(this.selectedUnit.end_date).isBefore(endDate)
        ? { date: this.selectedUnit.end_date, versionEndDate: true }
        : { date: endDate, versionEndDate: false }
    },
    async forcePublishing (reasoning) {
      let patch = []
      if (this.publishingForced) {
        patch = [
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: null,
          },
        ]
      } else {

        let date = this.selectedUnit.contract_end_date
        // Valid until further notice contracts does not have contract end date.
        // Let's assign arbitary maximum for those contracts so that we may enable the force publishing.
        if (!date) {
          date = new Date('9999-01-01T00:00:00')
        }

        patch = [
          {
            op: 'replace',
            path: '/forced_publish_date',
            value: date,
          },
          {
            op: 'replace',
            path: '/preventation_end_date',
            value: null,
          },
          {
            op: 'replace',
            path: '/reservation_end_date',
            value: null,
          },
        ]
      }

      if (reasoning) {
        patch.push({
          op: 'replace',
          path: '/reservation_reasoning',
          value: reasoning,
        })
      }

      await this.$rambollfmapi.units.patch(
        this.selectedUnit.id,
        this.currentDate,
        patch
      )
      this.$emit('update')
    },
  },
}
</script>
<style scoped>
.status_text {
  width: 50%;
  white-space: normal;
  font-weight: normal;
}
.visibility_handler_active {
  width: 45%;
  background-color: rgb(255, 102, 0);
  color: white;
}
.visibility_handler {
  width: 45%;
  border: 1px solid rgb(255, 102, 0) !important;
}
.reasoning-container {
  padding: 6px 24px;
  padding-bottom: 12px;
}

.reasoning-text {
  font-weight: normal;
  padding: 0;
  margin: 0;
}
</style>
