import { render, staticRenderFns } from "./SpaceDataDialog.vue?vue&type=template&id=68db6705&scoped=true"
import script from "./SpaceDataDialog.vue?vue&type=script&lang=js"
export * from "./SpaceDataDialog.vue?vue&type=script&lang=js"
import style0 from "./SpaceDataDialog.vue?vue&type=style&index=0&id=68db6705&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68db6705",
  null
  
)

export default component.exports