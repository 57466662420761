<template>
  <v-row>
    <v-col
      v-if="outdoors"
      class="body-text"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <BaseModal
          @cancel="emit"
        >
          <template #title>
            {{ $t('Edit outdoor information') }}
          </template>
          <template #content>
            <v-card v-if="outdoors.length > 0">
              <v-card 
                v-show="groupUpdate && outdoors.length > 1"
                elevation="0"
              >
                <table
                  class="v-datatable v-data-table v-table them--light table-overflow"
                  style="margin-top: 1em; margin-bottom: 1em"
                >
                  <thead>
                    <th class="column">
                      {{ $t('Group update') }}
                    </th>
                    <th
                      v-for="field in fields.filter(field => field.show)"
                      :key="field.value"
                      class="column"
                      :style="field.style"
                    >
                      {{ $t(field.header) }}
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="column">
                        {{ $t('New values') }}
                      </th>
                      <template v-for="field in fields.filter(field => field.show)">
                        <GenericModifyField
                          :key="field.id"
                          v-model="groupModifiedFields[field.id]"
                          :field-type="field.fieldType"
                          :items="field.items"
                          :value="groupModifiedFields[field.id]"
                        />
                      </template>
                    </tr>
                    <tr>
                      <th />
                      <template v-for="field in fields.filter(field => field.show)">
                        <td
                          :key="field.value"
                          style="border: 0 !important;"
                        >
                          <v-btn
                            outlined
                            rounded
                            block
                            @click="setToAllFields(field)"
                          >
                            {{ $t('Set to all') }}
                          </v-btn>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </v-card>
              <v-card
                elevation="0"
              >
                <table class="v-datatable v-data-table v-table them--light table-overflow">
                  <thead>
                    <th class="column">
                      {{ $t('Outdoor areas') }}
                    </th>
                    <th
                      v-for="field in fields.filter(field => field.show)"
                      :key="field.value"
                      class="column"
                      :style="field.style"
                    >
                      {{ $t(field.header) }}
                    </th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="outdoor in outdoors"
                      :key="outdoor.id_outdoor"
                    >
                      <th class="column">
                        {{ outdoor.id_outdoor }}
                      </th>
                      <template v-for="field in fields.filter(field => field.show)">
                        <GenericModifyField
                          :key="outdoor.id_outdoor + field.id"
                          v-model="modifiedOutdoors[outdoor.id_outdoor][field.id]"
                          :field-type="field.fieldType"
                          :items="field.items"
                          :value="modifiedOutdoors[outdoor.id_outdoor][field.id]"
                        />
                      </template>
                    </tr>
                  </tbody>
                </table>
              </v-card>
            </v-card>
          </template>
          <template #hideCancel>
            <p />
          </template>
          <template #footer>
            <v-btn
              v-if="outdoors.length > 1"
              outlined
              rounded
              :class="{ 'active': groupUpdate}"
              @click="groupUpdate = !groupUpdate"
            >
              {{ $t('Group update') }}
            </v-btn>
            <v-spacer />
            <v-btn
              text
              rounded
              outlined
              @click="emit"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="!valid || isSaving"
              rounded
              depressed
              color="primary"
              @click="validate"
            >
              {{ $t('Save') }}
              <v-progress-circular
                v-if="isSaving"
                size="16"
                indeterminate
                color="primary"
              />
            </v-btn>
          </template>
        </BaseModal>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>

import GenericModifyField from '@/components/FacilityManagement/GenericModifyField'
import BaseModal from '../../components/general/BaseModal.vue'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'OutDoorModifyForm',
  components: {
    GenericModifyField: GenericModifyField,
    BaseModal,
  },
  props: {
    disabled: { type: Boolean, default: null },
    outdoors: { type: Array, default: null },
    outdoorsVisibilityRights: { type: Object, default: null },
  },
  data () {
    return {
      groupUpdate: false,
      groupModifiedFields: {},
      modifiedOutdoors: {},
      valid: true,
      isSaving: false,
      currentVisibility: false,
    }
  },
  computed: {
      ...mapGetters('app', ['definitionsByGroupLabel']),
      ...mapState('app', ['currentDate']),
    fields () {
      return [
        {
          header: 'type',
          fieldType: 'short_text_field',
          id: 'type',
          show: this.showField('type'),
          style: { 'min-width': '22em' }        
        },
        {
          header: 'Surface',
          fieldType: 'short_text_field',
          id: 'surface',
          show: this.showField('surface'),
          style: { 'min-width': '22em' }
        },
        {
          header: 'Maintenance',
          fieldType: 'short_text_field',
          id: 'maintenance',
          show: this.showField('maintenance'),
          style: { 'min-width': '22em' }
        },
        {
          header: 'Area',
          fieldType: 'short_text_field',
          id: 'area',
          show: this.showField('area'),
          value: 'Select',
          style: { 'min-width': '22em' }
        },
        {
          header: 'Snow',
          fieldType: 'dropdown',
          items: this.definitionsByGroupLabel('outdoor.snowworkarea'),
          id: 'snow',
          show: this.showField('snow',),
          value: 'Select',
          style: { 'min-width': '22em' }
          },
        {
          header: 'Maintenance classification',
          fieldType: 'dropdown',
          items: this.definitionsByGroupLabel('outdoor.maintenance'),
          id: 'maintenance_classification',
          show: this.showField('maintenance_classification',),
          value: 'Select',
          style: { 'min-width': '22em' }
        },
      ]
    }
  },
  watch: {
    outdoors: {
      deep: true,
      immediate: true,
      async handler (oldVal, newVal) {
        this.modifiedOutdoors = {}
        oldVal.forEach(element => {
          this.$set(this.modifiedOutdoors, element.id_outdoor, {
            type: element.type,
            surface: element.surface,
            maintenance: element.maintenance,
            area: element.area,
            snow: element.snow,
            dismantle_date: moment(this.$store.state.app.currentDate).format(
              'YYYY-MM-DD'
            ),
            description: element.description
          })
        })
      }
    },
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
  },
  mounted () {
  },
  methods: {
    showField (prop) {
        const metadata = this.outdoorsVisibilityRights[prop]
        if (metadata !== undefined) {
            if(metadata.editType !== "None")
            return true
        } 
        return false 
    },
    emit (event) {
      this.$emit('childToParent', false)
      this.$emit('update')
      this.close()
    },
    close () {
      this.currentVisibility = false
      this.groupModifiedFields = {}
      this.groupUpdate = false
      this.$emit('close')
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedOutdoors)) {
          this.modifiedOutdoors[key][field.id] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.id])
          )
        }
      })
    },
    async validate () {
      this.isSaving = true
      await this.patch()
      this.isSaving = false
      this.$emit('close')
    },
    async patch () {
      for (const outdoor in this.modifiedOutdoors) {

        const outdoorObject = this.outdoors.find(e => {
          return e.id_outdoor === parseInt(outdoor)
        })
        const time = this.currentDate.toISOString().slice(0, 10)

        const outdoorCopy = JSON.parse(JSON.stringify(outdoorObject))
        const outdoorObserver = this.$jsonpatch.observe(outdoorCopy)

        Object.keys(outdoorCopy).forEach(key => {
          if (this.modifiedOutdoors[outdoor][key] !== undefined) {
            outdoorCopy[key] = this.modifiedOutdoors[outdoor][key]
          }
        })

        const outdoorPatch = this.$jsonpatch.generate(outdoorObserver)

        if (outdoorPatch.length) {
          await this.$rambollfmapi.estates.outdoors(undefined).patch(outdoor, time, outdoorPatch)
        }
    }
      this.$emit('update')
    },
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
</style>
