<template>
  <div id="error-card">
    <template v-for="err in getErrors">
      <div
        :key="err.key"
        aria-live="assertive"
        class="error-tile"
        @click="removeError(err.key)"
      >
        <h3>
          <v-row no-gutters>
            <v-icon
              v-if="err.title !== 'err.report_api_failure'"
              :key="err.key"
              class="error-tile-icon"
            >
              error
            </v-icon>
            <ErrorInfo
              v-else
              :info="JSON.stringify(err.info, null, ' ')" 
            />{{ $t(err.title) }}
          </v-row>
        </h3>
      </div>
    </template>
  </div>
</template>

<script>
import ErrorInfo from './ErrorInfo'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ErrorTip',
  components: {
    ErrorInfo
    
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('error', ['getErrors'])
  },
  watch: {
    getErrors: {
      handler: async function (newData, oldData) {},
      deep: true
    }
  },
  methods: {
    ...mapActions('error', ['clearError']),
    removeError (index) {
      this.clearError(index)
    }
  }
}
</script>

<style scoped>
#error-card {
  position: fixed;
  z-index: 300;
  bottom: 0.5em;
  background-color: transparent;
}
.error-tile {
  background-color: var(--r-color-danger-text);
  border-top-right-radius: 5em;
  border-bottom-right-radius: 5em;
  margin-top: 0.5em;
  padding: 0.8em;
  color: white;
  display: block;
  padding-right: 1em;
  cursor: pointer;
}
.error-tile:hover {
  background-color: #df4242;
}
.error-tile-content {
  display: inline-block;
}
.error-tile-icon {
  color: white;
}
</style>
