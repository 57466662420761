var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"my-4 mx-2",attrs:{"id":"esg"}},[_c('v-col',{attrs:{"lg":"4","md":"3","sm":"6","cols":"12"}},[_vm._l((_vm.widgets.filter(w => w.id === 'esg.site')),function(widget){return _c('div',{key:widget.id},[(_vm.currentSite)?_c('v-expansion-panels',{attrs:{"value":widget.maximized[0] === true ? [0] : [],"multiple":""}},_vm._l((Array.isArray(widget.data.items)
            ? widget.data.items
            : widget.data.items !== undefined
              ? [widget.data.items]
              : []),function(source,idx){return _c('v-expansion-panel',{key:widget + '-' + idx,staticClass:"no-padding",model:{value:(widget.maximized),callback:function ($$v) {_vm.$set(widget, "maximized", $$v)},expression:"widget.maximized"}},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"align":"center","justify":"start"}},[(widget.data.icon !== null)?_c('v-icon',[_vm._v(" "+_vm._s(widget.data.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(widget.data.titleSource ? source[widget.data.titleSource] : _vm.$t(widget.id))+" ")],1)],1),_c('v-expansion-panel-content',[(widget.loading)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[_c('v-progress-circular',{staticStyle:{"margin":"3em"},attrs:{"size":"64","indeterminate":"","color":"primary"}})],1),_c('v-spacer')],1):_c('div',[(widget.type === 'MetadataForm')?_c('MetadataForm',{attrs:{"metadata":widget.data.metadata,"data":widget.data.items,"category":widget.data.category,"multiline":widget.data.multiline,"with-status-icon":true,"disabled":!_vm.hasWritePermission},on:{"change":_vm.fieldUpdate}}):_vm._e()],1)],1)],1)}),1):_vm._e()],1)}),_c('draggable',{model:{value:(_vm.smallWidgets),callback:function ($$v) {_vm.smallWidgets=$$v},expression:"smallWidgets"}},_vm._l((_vm.smallWidgets),function(widget){return _c('div',{key:widget.id,staticClass:"widget"},[(_vm.currentSite)?_c('v-expansion-panels',{style:({ 'border-color': _vm.draggableColor })},_vm._l((Array.isArray(widget.data.items)
              ? widget.data.items
              : widget.data.items !== undefined
                ? [widget.data.items]
                : []),function(source,idx){return _c('v-expansion-panel',{key:widget.id + '-' + idx,staticClass:"no-padding",on:{"click":function($event){return _vm.onExpansionPanelClick(widget)}}},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"align":"center","justify":"start"}},[(widget.data.icon !== null)?_c('v-icon',[_vm._v(" "+_vm._s(widget.data.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(widget.data.titleSource ? source[widget.data.titleSource] : _vm.$t(widget.id))+" "),(
                    (Array.isArray(widget.data.items)
                      ? widget.data.items
                      : [widget.data.items]
                    ).length > 1
                  )?_c('span',[_vm._v("("+_vm._s(idx + 1)+" / "+_vm._s(widget.data.items.length)+")")]):_vm._e()],1)],1),_c('v-expansion-panel-content',[(widget.loading)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[_c('v-progress-circular',{staticStyle:{"margin":"3em"},attrs:{"size":"64","indeterminate":"","color":"primary"}})],1),_c('v-spacer')],1):_c('div',[(widget.type === 'MetadataForm')?_c('MetadataForm',{attrs:{"metadata":widget.data.metadata,"data":source,"category":widget.data.category,"multiline":widget.data.multiline,"with-status-icon":true,"disabled":!_vm.hasWritePermission},on:{"change":_vm.fieldUpdate}}):_vm._e()],1)],1)],1)}),1):_vm._e()],1)}),0),_c('v-row',{staticClass:"flex-column",staticStyle:{"right":"0","bottom":"0","position":"sticky","z-index":"1"}},[(_vm.saveButtonVisible)?_c('v-row',[_c('v-spacer'),(!_vm.isSavingChanges)?_c('v-btn',{class:'primary',attrs:{"rounded":"","depressed":""},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(" "+_vm._s(_vm.$t('Save changes'))+" ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)],2),_c('v-col',{attrs:{"lg":"8","md":"9","sm":"12","cols":"12"}},[_c('draggable',{model:{value:(_vm.largeWidgets),callback:function ($$v) {_vm.largeWidgets=$$v},expression:"largeWidgets"}},_vm._l((_vm.largeWidgets),function(widget){return _c('div',{key:widget.id,staticClass:"widget"},[_c('DynamicWidget',{style:({ 'border-color': _vm.draggableColor }),attrs:{"id":widget.id,"type":widget.type,"has-action":widget.action ? true : false,"data":widget.data,"title":_vm.$t(widget.id),"loading":widget.loading,"maximized":widget.maximized},on:{"updateWidget":_vm.updateData}})],1)}),0)],1),_c('Alert',{attrs:{"show":_vm.showIndicator,"result":_vm.operationResult,"message":_vm.indicatorMessage,"y":_vm.yPosition}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }