<template>
  <div>
    <v-dialog
      v-model="photoUploadDialogVisible"
      persistent
      max-width="550"
    >
      <PhotoUploadForm
        v-if="photoUploadDialogVisible"
        :editing="false"
        :data="null"
        :is-in-site="true"
        @updatePhotoList="addOrUpdatePhoto"
        @close="closeForm"
      />
    </v-dialog>
    <v-carousel
      v-model="carouselUpdate"
      height="300"
      style="margin: 0;"
      hide-delimiters
    >
      <v-carousel-item
        v-for="imgUrl in imgUrls"
        :key="imgUrl"
        :src="imgUrl"
      />
      <v-carousel-item v-if="isLoadingImg || imgUrls.length === 0">
        <div class="carousel-title-item">
          <v-progress-circular
            v-if="isLoadingImg"
            size="64"
            indeterminate
            color="primary"
          />
          <div
            v-else
            style="text-align:center"
          >
            <v-icon
              size="100"
              color="blue lighten-2"
            >
              home
            </v-icon>
            <div class="text-h6 font-weight-light">
              {{ $t('No images') }}
            </div>
          </div>
        </div>
      </v-carousel-item>
      <v-btn
        :disabled="!hasWritePermission || isPhotoAddDisabled"
        icon
        outlined
        color="primary"
        style="position:absolute; top: 0; right: 0; background: rgba(255, 255, 255, 0.9) !important;"
        @click="photoUploadDialogVisible = true"
      >
        <v-icon>add</v-icon>
        <span class="d-sr-only">{{ $t('AddPhoto') }}</span>
      </v-btn>
    </v-carousel>
  </div>
</template>

<script>
import PhotoUploadForm from '../../components/PhotoUploadForm'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    PhotoUploadForm,
  },
  props: {
    siteId: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isPhotoAddDisabled: true,
      userHasPhotoEditRights: false,
      files: [],
      photoUploadDialogVisible: false,
      imgUrls: [],
      carouselUpdate: 0,
      isLoadingImg: false
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName', 'photoMetadata']),
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    ...mapState('app', ['photoMetadata', 'userInfo', 'currentDate']),
    hasWritePermission () {
      if (!this.hasApplicationPermissionByName('VALOKUVAN_LISAYS')) {
        return false
      }
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
  },
  watch: {
    siteId: function () {
      this.updateCarousel()
    }
  },
  mounted: async function () {
    await this.getPhotoMetadata()
    await this.update()
  },
  methods: {
    ...mapActions('app', ['getPhotoMetadata']),
    async update () {
      this.files = []
      ;({
          items: this.files,
          count: this.filesCount
        } = await this.$rambollfmapi.sites
          .photos(this.siteId)
          .list(null, null, null, null, this.currentDate)
          )
      if (this.hasApplicationPermissionByName('VALOKUVAN_LISAYS')) {
        this.userHasPhotoEditRights = true
      }
      if (this.files !== undefined) {
        this.addPhotoDisabled()
        await this.updateCarousel()
      }
    },
    addPhotoDisabled () {
      const countInSite = this.files.filter(f => f.inSite).length
      this.isPhotoAddDisabled = countInSite >= 5
    },
    addOrUpdatePhoto (photo) {
      this.update()
    },
    closeForm () {
      this.selectedPhoto = null
      this.photoUploadDialogVisible = false
    },
    async updateCarousel () {
      this.isLoadingImg = true
      this.imgUrls = []
      
      if (this.siteId === undefined) {
        return
      }
      
      this.files.forEach(photo => {
        // Retrieve images
        if (photo.inSite){
          const progress = {
            retrievalProgress: 0
          }
          this.$rambollfmapi.photos
            .download(photo.idPhoto, progress, true)
            .then(res => {
              // Note. carouselUpdate is used to combat the update functionality within v-carousel
              // See https://github.com/vuetifyjs/vuetify/issues/1684
              this.carouselUpdate = null
              const blob = new Blob([res.data], { type: 'octet/stream' })
              const objUrl = URL.createObjectURL(blob)
              this.isLoadingImg = false
              this.imgUrls.push(objUrl)
              this.$nextTick(() => (this.carouselUpdate = 0))
            })
            .catch(() => {
              // Fail
              this.$log.error('Failed to load image!')
              this.isLoadingImg = false
            })
      }
      })

      if (this.imgUrls.length === 0) {
        this.isLoadingImg = false
      }
    }
  }
}
</script>
<style scoped>
.v-window.v-carousel {
  border-radius: 1.5em !important;
}
.carousel-title-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
}
</style>
