<template>
  <v-row
    class="dashboard"
  >
    <!-- left -->
    <v-col
      v-if="hasWidgetPermissionByName('leasing.notebook')"
      lg="3"
      md="6"
      sm="12"
      cols="12"
      class="pt-0"
    >
      <v-row>
        <v-col>
          <Notes />
        </v-col>
      </v-row>
    </v-col>

    <!-- middle -->
    <v-col
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <!-- widgets -->
      <v-row>
        <v-col
          v-if="userNotificationsWithLinks && userNotificationsWithLinks.length > 0 && hasWidgetPermissionByName('leasing.notifications')"
          cols="12"
          class="widget"
        >
          <NotificationList 
            :notifications="userNotificationsWithLinks"
            :loading="widgetLoading('notifications')"
          />
        </v-col>
        <v-col
          v-for="widget in leftWidgets.filter(w => hasWidgetPermissionByName(w.id))"
          :key="widget.id"
          cols="12"
          class="widget"
        >
          <DynamicWidget
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widgetLoading(widget.id)"
          />
        </v-col>
      </v-row>
    </v-col>
    <!-- right -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
    >
      <v-row>
        <v-col
          v-for="widget in rightWidgets.filter(w => hasWidgetPermissionByName(w.id))"
          :key="widget.id"
          class="widget"
          cols="12"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :plugins="widget.plugins"
            :loading="widgetLoading(widget.id)"
            @change-item-set="changeWidgetSet"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DynamicWidget from '../../DynamicWidget'
import Notes from '../Notes'
import NotificationList from '../NotificationList.vue'
import LeasingWidgets from '../../../widgets/leasing'
import { getProspectType } from '../../../helpers/leasing/prospect'
import humanize from '../../../helpers/humanize'
import { loadRentalGoals } from "../../../helpers/leasing/rentalGoals"
import moment from 'moment'
import { prospectTypeColors } from "../../../constants/colors"
import _debounce from 'lodash/debounce'
import { getProcessStage, getRentalProcessTitle } from '../../../helpers/leasing/rentalprocess'

export default {
  name: 'OwnActive',
  components: { 
    DynamicWidget,
    Notes,
    NotificationList
  },
  data () {
    return {
      leftWidgets: [LeasingWidgets.MyRentalGoals(), ...LeasingWidgets.OwnActiveLeft()],
      rightWidgets: [...LeasingWidgets.MyBiggestLeases(), ...LeasingWidgets.OwnActiveRight()],
      selectedStatusBiggestLeases: null,
      loadedWidgets: []
    }
  },
  computed: {
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'userInfo',
    ]),
    ...mapGetters('app', [
      'definitionById',
      'hasWidgetPermissionByName',
    ]),
    ...mapState('leasing', [
      'prospectsLoading',
      'partiesLoading',
      'municipalitiesLoading',
      'leasingUsersLoading',
      'userNotificationsLoading',
      'prospectEventsLoading',
      'municipalities',
      'parties',
      'leasingUsers',
      'prospectEvents',
      'responsibleSites',
      'rentalStatuses',
      'currentRentalStatusesLoading',
      'futureRentalStatuses',
      'futureRentalStatusesLoading'
    ]),
    ...mapState('rentalProcess', [
      'rentalProcessLoading',
      'rentalProcesses',
      ]),
    ...mapGetters('leasing', [
      'userNotificationsWithLinks'
    ]),
    ...mapGetters('rentalProcess', ['filteredRentalProcesses']),
    ...mapState('rentingGoal', ['goals', 'goalsLoading']),
  },
  watch: {
    sites: function () {
      this.loadedWidgets = []
      this.loadWidgetData()
    },
    prospects: {       
      deep: true,
      handler: function () {
        this.loadedWidgets = []
        this.loadWidgetData()
        this.getUserNotifications()
      }
    },
    futureRentalStatuses: function () {
      this.loadWidgetData()
    },
    rentalStatuses: function () {
      this.loadWidgetData()
    },
    selectedStatusBiggestLeases: function () {
      let biggestLeasesWidget = [...this.rightWidgets, ...this.leftWidgets].find(x => x.id === 'leasing.users_biggest_leases')
      if(biggestLeasesWidget !== null)
      {
        biggestLeasesWidget.data.items = this.dataForWidget('leasing.users_biggest_leases')
      }
    },
  }, 
  mounted () {
    this.getUserNotifications()
    this.loadWidgetData()
  },
  methods: {
    ...mapActions('leasing', ['getUserNotifications']),
    loadWidgetData: _debounce(function (e) {
      this.rightWidgets.forEach(widget => {
        if(!this.loadedWidgets.includes(widget.id))
        {
          widget.data.items = this.dataForWidget(widget.id)
          if (widget.customDefaultFilter) {
            widget.data.filter.defaultFilter = this.getDefaultFilter(widget.id, widget.data.items)
          }
        }
		if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }
      })
      this.leftWidgets.forEach(widget => {
        if(!this.loadedWidgets.includes(widget.id))
        {
          widget.data.items = this.dataForWidget(widget.id)
          if (widget.customDefaultFilter) {
            widget.data.filter.defaultFilter = this.getDefaultFilter(widget.id, widget.data.items)
          }
        }
		if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }
      })
    }, 500),
	legendForWidget (id) {
      try {
        switch(id) {
          case 'leasing.users_biggest_leases':
            return this.loadUsersBiggestLeasesLegend()
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    loadUsersBiggestLeasesLegend (){
      return  Object.entries(prospectTypeColors).map(([key, value]) => 
        ({
            text: this.$t(key),
            fillStyle: value,
            hidden: false,
            lineWidth: 1,
            strokeStyle: '#fff',
        })
      )
    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.my_rental_processes':
          return this.sitesLoading || this.rentalProcessLoading || this.partiesLoading || this.municipalitiesLoading || this.leasingUsersLoading || this.prospectEventsLoading
        case 'leasing.my_active_renting_processes':
          return this.sitesLoading || this.rentalProcessLoading 
        case 'notifications':
          return this.sitesLoading || this.prospectsLoading || this.userNotificationsLoading
        case 'leasing.my_yearly_goals':
          return this.sitesLoading || this.rentalProcessLoading || this.partiesLoading || this.currentRentalStatusesLoading || this.futureRentalStatusesLoading || this.goalsLoading
        case 'leasing.users_biggest_leases':
          return this.sitesLoading || this.rentalProcessLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }
        if(!this.loadedWidgets.includes(id))
        {
          this.loadedWidgets.push(id)
        }

        switch(id) {
          case 'leasing.my_rental_processes':
            return this.myRentalEvents(this.filteredRentalProcesses, this.sites, this.parties, this.municipalities, this.userInfo.id, this.leasingUsers, this.prospectEvents)
          case 'leasing.my_active_renting_processes':
            return this.loadActiveRentingprocesses(this.filteredRentalProcesses)
          case 'leasing.my_yearly_goals':
            return loadRentalGoals(this.rentalProcesses, this.responsibleSites, this.goals)
          case 'leasing.users_biggest_leases':
            return this.loadBiggestLeases(this.filteredRentalProcesses)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process data for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
	getDefaultFilter (id, items) {
		try {
        switch(id) {
          case 'leasing.my_rental_processes':
            return this.myRentalEventsFilter(items)
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
	},
	myRentalEventsFilter (items) {
		// Check if any of the process items has Active -status
		const count = items.some((item) => item.prospect_status === 'Active')
		if (count) {
			return {
				text: 'prospect.show_only_actives', value: 'Active'
			}
		}
	},
    myRentalEvents (filteredProcesses, sites, parties, municipalities, currentUser, leasingUsers, processEvents) {
      let processWidgetData = []
      processWidgetData = filteredProcesses.map(process => {
        const events = processEvents.filter(event => event.id_prospect === process.id)
        return this.computeProcess(process, parties, municipalities, sites, leasingUsers, events)
      })

      processWidgetData.sort((prev, next) =>  new Date(next.last_modified) - new Date(prev.last_modified))
      return processWidgetData
    },
    computeProcess (process, parties, municipalities, sites, leasingUsers, events) {
      // Party name
      const foundParty = parties.find(party => party.name === process.corporation)
  
      //  Sites need to be added as subItems
      let subItems = []
      process.sites.forEach(item => {
        const foundSite = sites.find(sitesItem => sitesItem.id_site == item.id_site)
        if (foundSite) subItems.push({site: foundSite.name, unit_area_preference: 0, id_site: item.id_site})
      })
      // String of sites
      const reducedSites = subItems.reduce((acc, value, index) => 
        {
          let next = acc;
          if (index !== 0) next += ', '
          next += value.site
          return next
        }, 
      '')

      let singleSite = ''
      let idSite = null
      if (subItems.length === 1) {
        singleSite = reducedSites
        idSite = subItems[0].id_site
      }
      else if (subItems.length > 1) {
        singleSite = this.$t('prospect.several')
      }

      // String of municipalities
      const cityString = process.cities.reduce((acc, curr, index) => {
        const city = municipalities.find(item => item.id === curr).municipality
        if (index !== 0) return acc + ', ' + city
        else return acc + city;
      }, '')
      
      // Unit usage string
      const unitUsageString = process.usage_types.reduce((acc, curr, index) => {
        const usage = this.getDefinitionLabelById(curr)
        if (index !== 0) return acc + ', ' + usage
        else return acc + usage;
      }, '')
      // Process type (renegotiations/real estate development/new contract)
      const typeObject = getProspectType(process)

      // process stage and stage date
      const stageObject = getProcessStage(process, events)
      const stageDate = humanize.date(stageObject.stageDate)
      // If status is Inactive, add reason
      let stage = stageObject.stage
      if (process.status === "Inactive") {
        stage = stage +  ': ' + this.$t(this.getDefinitionLabelById(process.status_description))
      }

      // process responsibles
      let firstResponsibleName = ''
      let secondaryResponsibleName = ''
      const firstResponsible = leasingUsers.find(item => item.id_user === process.owner)
      if (firstResponsible) {
        firstResponsibleName = firstResponsible.name
      }
      const secondaryResponsible = leasingUsers.find(item => item.id_user === process.secondaryOwner)
      if (secondaryResponsible) {
        secondaryResponsibleName = secondaryResponsible.name
      }

      const returnObject =  {
        id_process: process.id,
        process_title: getRentalProcessTitle(process),
        tenant: foundParty ? foundParty.name : "",
        site: singleSite,
        sitesString: "",
        area_preference: process.area_preference,
        city_preference: cityString,
        unit_type: unitUsageString,
        prospect_status: process.status,
        prospect_fullscreen_status: this.$t(process.status),
        showToggle: subItems.length > 1,
        created: process.created,
        last_modified: process.last_modified,
        prospect_type: typeObject.typeIcon,
        prospect_type_text: typeObject.typeText,
        id_site: idSite,
        stage: stage,
        stageChanged: stageDate,
        first_responsible: firstResponsibleName,
        secondary_responsible: secondaryResponsibleName,
        estimated_completion: process.estimated_completion,
        estimated_value: process.customer_rent_level,

        // Addittional information. Required for tenant links to work correctly.
        id_tenant: foundParty ? foundParty.id : null,
        business_id: foundParty ? foundParty.business_id :  null,
        tenant_corporation: foundParty ? foundParty.tenant_corporation : null
      }

      if (subItems.length > 1) {
        returnObject['subItems'] = subItems
        returnObject['sitesString'] = reducedSites
      }
      else {
        returnObject['sitesString'] = reducedSites
      }
      return returnObject
    },

    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },

    loadActiveRentingprocesses (filteredProcesses) {
      let data = []
      filteredProcesses.forEach(process => {
        let value = {name: process.status, stage: process.stage, value: process.area_preference}

        if (process.status === 'Inactive') {
          value['stage'] = process.status
        }
        data.push(value)
      })
      // Sort data by stage
      data.sort((prev, next) => {
        if(prev.stage === 'Inactive') return 1;
        if(next.stage === 'Inactive') return -1;
        return prev.stage.localeCompare(next.stage, undefined, {numeric: true, sensitivity: 'base'});
      });

      return data
    },
    loadBiggestLeases (processes) {
      let filteredProcesses = processes.filter(process => process.contract_numbers.length > 0)
      const yearAgo = moment().subtract(1, 'year')
      
      if(!(this.selectedStatusBiggestLeases === null || this.selectedStatusBiggestLeases === this.$t('prospect.show_all')))
      {
        filteredProcesses = filteredProcesses.filter(p => this.$t(getProspectType(p).typeText) === this.selectedStatusBiggestLeases)
      }

      return filteredProcesses.filter(process => 
        process.contract_info.some(ci => ci.ContractSigningDate !== null && moment(ci.ContractSigningDate) > yearAgo) 
      ).map(process => {
        return {
          processName: getRentalProcessTitle(process) + process.sites.map(site => '\n ' + site.siteName ),
          color: prospectTypeColors[getProspectType(process).typeText],
          leased_space_area: process.contract_info.reduce((sum, contract) => sum + contract.reportableArea, 0),
          leased_space_eur: process.contract_info.reduce((sum, contract) => sum + contract.capitalRent, 0),
        }
      })
    },
    changeWidgetSet (event)
    {
      //If this page would have more widgets that handle widget set changing in page state
      //get the widget id from the event parameter to define which widget changed the set
      this.selectedStatusBiggestLeases = event.set
    }
  }
}
</script>
<style scoped>
.widget {
  padding-top: 0;
}
</style>
