import format from '../helpers/format'
export default {
  /**
     * Updates widget data from the data imported from the csv file
     *
     * @function updateItems
     * @param {Object} widgetData     - original widget data with headers and items
     * @param {Array} data            - imported csv data
     * @param {Number} primaryId      - identifier (widget data.items array index) to match widget and csv data rows
     * @param {Number} secondaryId    - secondary identifier if needed
     * @returns {Object}              - object with updated widget data.items, boolean success value and specific errors if there were any.
     */
  updateItems (widgetData, data, primaryId, secondaryId = undefined, importingCarparks = false) {
    const primaryHeaderIdentifier = widgetData.headers[primaryId].text
    const primaryKeyIdentifier = widgetData.headers[primaryId].value
    const secondaryHeaderIdentifier = secondaryId ? widgetData.headers[secondaryId].text : undefined
    const secondaryKeyIdentifier = secondaryId ? widgetData.headers[secondaryId].value : undefined

    let success = true
    const errors = { unexisting: [], uneditable: [] }
    // generate new items
    const items = JSON.parse(JSON.stringify(widgetData.items))
    items.forEach((item, index) => {
      // find corresponding data item
      const dataItem = data.find(s => {
        return (s[primaryHeaderIdentifier] === String(item[primaryKeyIdentifier]) &&
          (secondaryId ? s[secondaryHeaderIdentifier] === String(item[secondaryKeyIdentifier]) : true))
      })
      // replace only identifiable row fields
      if (dataItem) {
        widgetData.headers.forEach(header => {
          if ('ignoreHeader' in header && header.ignoreHeader) {
            // bypass this header
            return
          }
          // verify that data has all the same header fields that widget has
          try {
            if (!(Object.prototype.hasOwnProperty.call(dataItem, header.text))) {
              success = false
              errors.unexisting.push(header.text)
            }
          } catch (err) {
            success = false
            return
          }

          // check if wrong fields have been edited
          if (!header.editable) {
            // check only cases where fields are not empty, undefined or null
            if (item[header.value] || dataItem[header.text]) {
              // If the field has not been edited.
              const stillEqual = String(item[header.value]) === dataItem[header.text]
              success = stillEqual ? success : false
              // The uneditable field differs from the original.
              if (!stillEqual) {
                if(importingCarparks) {
                  errors.uneditable.push({ siteName: item.siteName,
                    number: item.number,
                    value: dataItem[header.text],
                    label: header.text })
                } else {
                  errors.uneditable.push({ buildingCode: item.buildingCode,
                    unitName: item.unitName,
                    value: dataItem[header.text],
                    label: header.text })
                }
                
              }
            }
          }
          // replace value if header is defined as editable
          if (header.editable) {
            // update only fields that have content
            if (item[header.value] || dataItem[header.text]) {
              // if header is lease plan (= data type is text) sanitize content
              let value = header.value !== 'lease_plan' ? dataItem[header.text] : format.sanitize(dataItem[header.text])
              value = value === 'FALSE' || value === 'false' ? false : value
              value = value === 'TRUE' || value === 'true' ? true : value
              item[header.value] = value
            }
          }
        })
      }
    })

    // Remove duplicates from errors
    errors.uneditable = Array.from(new Set(errors.uneditable))
    errors.unexisting = Array.from(new Set(errors.unexisting))

    return { items, success, errors }
  },
  modifyItems (widgetData, data, primaryId, secondaryId = undefined) {
    const primaryHeaderIdentifier = widgetData.headers[primaryId].text
    const primaryKeyIdentifier = widgetData.headers[primaryId].value
    const secondaryHeaderIdentifier = secondaryId ? widgetData.headers[secondaryId].text : undefined
    const secondaryKeyIdentifier = secondaryId ? widgetData.headers[secondaryId].value : undefined

    let success = true
    const errors = { uneditable: [], unexisting: [] }
    const modifiedItems = []
    // generate new items
    const items = JSON.parse(JSON.stringify(data))
    items.forEach((item, index) => {
      // item to be returned
      const modifiedItem = {}
      // find corresponding unit row
      const dataItem = widgetData.items.find(s => {
        return (item[primaryHeaderIdentifier] === s[primaryKeyIdentifier] &&
          (secondaryId ? item[secondaryHeaderIdentifier] === s[secondaryKeyIdentifier] : true))
      })
      // replace only identifiable row fields
      if (dataItem) {
        widgetData.headers.forEach(header => {
          if ('ignoreHeader' in header && header.ignoreHeader) {
            // bypass this header
            return
          }
          // verify that data has all the same header fields that widget has
          try {
            if (!(Object.prototype.hasOwnProperty.call(item, header.text))) {
              success = false
              errors.unexisting.push(header.text)
            }
          } catch (err) {
            success = false
            return
          }

          // check if wrong fields have been edited
          if (!header.editable) {
            // if header is not editable copy original values
            modifiedItem[header.value] = dataItem[header.value]
            // check only cases where fields are not empty, undefined or null
            if (dataItem[header.value] || item[header.text]) {
              // If the field has not been edited.
              const stillEqual = dataItem[header.value] === item[header.text]
              success = stillEqual ? success : false
              // The uneditable field differs from the original.
              if (!stillEqual) {
                errors.uneditable.push(header.text)
              }
            }
          }
          // replace value if header is defined as editable
          if (header.editable) {
            // update only fields that have content
            if (dataItem[header.value] || item[header.text]) {
              let value = item[header.text]
              value = value === 'FALSE' || value === 'false' ? false : value
              value = value === 'TRUE' || value === 'true' ? true : value
              modifiedItem[header.value] = value !== '' ? value : null
            } else {
              modifiedItem[header.value] = null
            }
          }
        })
        modifiedItems.push(modifiedItem)
      } else {
        errors.unexisting.push(item[primaryHeaderIdentifier] + "," + item[secondaryHeaderIdentifier])
      }
    })

    // Remove duplicates from errors
    errors.uneditable = Array.from(new Set(errors.uneditable))
    errors.unexisting = Array.from(new Set(errors.unexisting))

    return { items: modifiedItems, success, errors }
  }
}
