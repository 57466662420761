<template>
  <div id="person-allocation">
    <v-dialog
      v-model="xtraInfoDialogOpen"
      persistent
      max-width="800"
    >
      <AriaLive />
      <AllocationCodeExtraInfo
        v-if="xtraInfoDialogOpen"
        :allocation-code="allocationCode"
        :selected-date="selectedDate"
        @close="closeExtraInfoDialog"
      />
    </v-dialog>
    <v-row
      v-if="hasApplicationPermissionByName('SIS_HLOMAARAKOHDISTUS')"
    >
      <v-spacer />
      <v-col
        cols="2"
      >
        <v-select
          v-model="selectedMonth"
          :label="$t('internalrent.inspection_month')"
          :items="months"
          item-text="text"
          item-value="value"
        />
      </v-col>
      <v-col
        cols="1"
      >
        <v-select
          v-model="selectedYear"
          :label="$t('internalrent.inspection_year')"
          :items="inspectionYears"
          item-text="text"
          item-value="value"
        />
      </v-col>
    </v-row>
    <v-col
      class="tables"
      lg="12"
      md="12"
      sm="12"
      cols="12"
    > 
      <v-row>
        <draggable
          v-model="widgets"
          :disabled="!sortableWidgets"
        >
          <v-col
            v-for="widget in widgets"
            :key="widget.id"
            style="padding-top: 1em;"
          >
            <DynamicWidget
              :type="widget.type"
              :title="$t(widget.id)"
              :data="widget.data"
              :loading="widget.loading"
              :style="{ 'border-color': draggableColor }"
              :maximized="widget.maximized"
              :stored-views="widget.data.storedViews"
              @open="widget.maximized = $event"
              @dataUpdate="validateUpdate($event)"
              @drag="dragItem($event)"
              @openDialog="openExtraInfoDialog($event)"
            />
          </v-col>
        </draggable>
      </v-row>
      <v-row style="right: 0; bottom: 0; position: sticky; z-index: 1">
        <v-row v-if="modifiedRows.length > 0 || modifiedCodes.length > 0">
          <v-spacer />
          <v-btn
            outlined
            rounded
            text
            @click="init()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-tooltip
            right
            color="red"
            :disabled="!disableSaving"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  rounded
                  depressed
                  :class="!disableSaving ? 'primary' : null"
                  :disabled="disableSaving"
                  @click="saveChanges()"
                >
                  <span v-if="!savingProgress">
                    {{ $t('Save') }}
                  </span>
                  <v-progress-circular
                    v-else
                    :size="20"
                    :width="3"
                    indeterminate
                    color="primary"
                  />
                </v-btn>
              </div>
            </template>
            <span v-if="disableSaving">{{ $t('Check percentages') }}</span>
          </v-tooltip>
        </v-row>
      </v-row>
    </v-col>          
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import DynamicWidget from '../components/DynamicWidget.vue'
import Alert from '../components/Alert.vue'
import AllocationCodeExtraInfo from '../components/./AllocationCodeExtraInfo'
import AriaLive from '../components/AriaLive.vue'

export default {
  name: 'PersonnelAllocation',
  components: {
    draggable,
    DynamicWidget,
    Alert,
    AllocationCodeExtraInfo,
    AriaLive
  },
  data () {
    return {
      selectedMonth: {},
      months: [
        { text: this.$t('January'), value: 1 },
        { text: this.$t('February'), value: 2 },
        { text: this.$t('March'), value: 3 },
        { text: this.$t('April'), value: 4 },
        { text: this.$t('May'), value: 5 },
        { text: this.$t('June'), value: 6 },
        { text: this.$t('July'), value: 7 },
        { text: this.$t('August'), value: 8 },
        { text: this.$t('September'), value: 9 },
        { text: this.$t('October'), value: 10 },
        { text: this.$t('November'), value: 11 },
        { text: this.$t('December'), value: 12 }
      ],
      selectedYear: null,
      inspectionYears: [],
      selectedDate: null,
      draggableColor: null,
      modifiedRows: [],
      modifiedCodes: [],
      indicatorMessage: '',
      operationResult: '',
      showIndicator: false,
      savingProgress: false,
      draggedItem: null,
      disableSaving: false,
      xtraInfoDialogOpen: false,
      allocationCode: null,
      widgets: [
        {
          id: 'person_allocation.allocated_personnel',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('Site name'), value: 'site_name' },
              { text: this.$t("Site's space allocation (PZ1)"), value: 'purpose_zone_1' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'AreaOrNull' },
              { text: this.$t('Post office code'), value: 'allocation_code', drag: true },
              { text: this.$t('Cost center'), value: 'costcenter_code'},
              { text: this.$t('Cost center name'), value: 'costcenter' },
              { text: this.$t('Number of personnel'), value: 'person_count', format: 'Double'},
              { text: this.$t('Purpose zone 1'), value: 'ppz1' },
              { text: this.$t('Allocation percentage'), value: 'allocation_percentage', modify: true, editType: 'Number' },
              { text: this.$t('Employees'), value: 'person_count_internal', modify: true, editType: 'Number' },
              { text: this.$t('Lock number of personnel'), value: 'person_count_locked', modify: true, editType: 'Boolean' },
              { text: this.$t('Add external personnel'), value: 'person_count_external', modify: true, editType: 'Number' },
              { text: this.$t('Total number of personnel'), value: 'total_person_count', format: 'Double' },
              { text: '', value: 'toggle' }
            ],
            items: [],
            sortBy: 'site_name',
            disablingFields: {
              allocation_percentage: function (rowData) {
                if(rowData.person_count_locked || rowData.net_floor_area === 0) {
                  return true
                } else {
                  return false
                }
              },
              person_count_internal: function (rowData) {
                if(rowData.person_count_locked) {
                  return false
                } else {
                  return true
                }
              },
              person_count_external: function (rowData) {
                if(rowData.net_floor_area === 0) {
                  return true
                } else {
                  return false
                }
              },
              person_count_locked: function (rowData) {
                if(!rowData.person_count_locked && rowData.some_person_count_locked) {
                  return true
                } else {
                  return false
                }
              }
            }
          },
          maximized: [true],
          loading: true 
        },
        {
          id: 'person_allocation.unallocated_personnel',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('Post office code'), value: 'allocation_code', drag: true },
              { text: this.$t('Cost center'), value: 'costcenter_code'},
              { text: this.$t('Cost center name'), value: 'costcenter' },
              { text: this.$t('Number of personnel'), value: 'person_count', format: 'Double'},
              { text: '', value: 'toggle' }
            ],
            items: [],
          },
          maximized: [true],
          loading: true 
        },
        {
          id: 'person_allocation.faulty_person_count_codes',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('Post office code'), value: 'allocation_code', status:true },
              { text: this.$t('Cost center'), value: 'costcenter_code', status:true},
              { text: this.$t('Cost center name'), value: 'costcenter' },
              { text: this.$t('Number of personnel'), value: 'person_count', format: 'Double'},
              { text: '', value: 'toggle' }
            ],
            items: [],
            fieldsStatusIcons: {
              allocation_code: function (rowData) {
                const subItemErrors = rowData.layer === 0 && rowData.subItems.some(subitem => subitem.map(subItemsSubItem => subItemsSubItem.costcenter_code === 0))
                const fieldData = rowData.allocation_code !== null && subItemErrors ? 'subItemFail' : rowData['allocation_code']
                return { name: 'allocation_code',  data: fieldData }
              }
            }
          },
          maximized: [true],
          loading: true
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['currentDate', 'sortableWidgets', 'siteIds', 'buildingIds']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('personnelAllocation', ['dataForWidget']),
  },
  watch: {
    currentDate: function (value) {
      const currentYear = value.getFullYear()
      const currentMonth = value.getMonth()
      this.selectedYear = currentYear
      this.selectedMonth = currentMonth !== 0 ? currentMonth : 12
      this.inspectionYears = [
        { text: `${currentYear - 1}`, value: currentYear - 1 },
        { text: `${currentYear}`, value: currentYear },
        { text: `${currentYear + 1}`, value: currentYear + 1 },
        { text: `${currentYear + 2}`, value: currentYear + 2 }
      ]
    },
    selectedYear: function (value) {
      if (this.selectedMonth) {
        var date = new Date()
        date.setFullYear(value, this.selectedMonth, 1)
        date.setDate(date.getDate() - 1)
        date.setHours(0, 0, 0)
        this.selectedDate = date
      }
    },
    selectedMonth: function (value) {
      if (this.selectedYear) {
        var date = new Date()
        date.setFullYear(this.selectedYear, value, 1)
        date.setDate(date.getDate() - 1)
        date.setHours(0, 0, 0)
        this.selectedDate = date
      }
    },
    selectedDate: function () {
      this.init()
    },
    buildingIds: function () {
      this.init()
    },
    siteIds: function () {
      this.init()
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    draggedItem: function (item) {
      if (item && this.widgets.find(w => w.id === 'person_allocation.unallocated_personnel').data.items.length === 0) {
        this.widgets.find(w => w.id === 'person_allocation.unallocated_personnel').data.items.push({
          allocation_code: null
        })
      } else {
        this.widgets.find(w => w.id === 'person_allocation.unallocated_personnel').data.items = this.widgets.find(w => w.id === 'person_allocation.unallocated_personnel').data.items.filter(i => i.allocation_code)
      }
    }
  },
  mounted () {
    if (!this.hasApplicationPermissionByName('SIS_HLOMAARAKOHDISTUS')) {
      this.$router.push({ name: 'dashboard' })
    }
    else
    {
      const currentYear = this.currentDate.getFullYear()
      const currentMonth = this.currentDate.getMonth()
      this.selectedYear = currentYear
      this.selectedMonth = currentMonth !== 0 ? currentMonth : 12
      this.inspectionYears = [
        { text: `${currentYear - 1}`, value: currentYear - 1 },
        { text: `${currentYear}`, value: currentYear },
        { text: `${currentYear + 1}`, value: currentYear + 1 },
        { text: `${currentYear + 2}`, value: currentYear + 2 }
      ]
    }
  },
  methods: {
    ...mapActions('personnelAllocation', ['loadWidgetData', 'updateAllocationConfiguration', 'updateAllocation']),
    updateWidgetData () {
      for (let w of this.widgets) {
        w.data.items = this.dataForWidget(w.id)
        if (w.data.items === null  || w.data.items === undefined) {
          w.data.items = []
        } else {
          w.loading = false
        }
      }
    },
    async init () {
      this.modifiedRows = []
      this.modifiedCodes = []
      await this.loadWidgetData(this.selectedDate),
      this.updateWidgetData()
    },
    validateUpdate (event) {
      if (event.id === this.$t('person_allocation.allocated_personnel')) {
        var existing = this.modifiedRows.find(row => row.id_code === event.item.id_code && row.id_purposezone === event.item.id_purposezone)
        var isValid = true
        var message = 'Success'

        if (event.item.net_floor_area === 0) {
          isValid = false
          message = this.$t('Can\'t modify row without net floor area')
        }
        if (event.header === 'allocation_percentage') {
          if (event.value < 0 || event.value > 100) {
            isValid = false
            message = this.$t('Select values between 0 and 100')
          }
        }
        else if (event.header === 'person_count_internal') {
          if (!event.item.person_count_locked && (!existing || !existing.person_count_locked)) {
            isValid = false
            message = this.$t('To modify, person count needs to be locked')
          }
        }
        else if (event.header === 'person_count_locked') {
          if (event.level2.subItems.find(ch => ch.id !== event.item.id && ch.person_count_locked == true)) {
            isValid = false
            message = this.$t('Only one can be locked at the time')
          }
        }

        if (isValid) {
          var item = {
            id_code: event.item.id_code,
            id_purposezone: event.item.id_purposezone,
            allocation_percentage: event.header === 'person_count_locked' && event.value === true ? 0 : event.item.allocation_percentage,
            person_count_internal: event.header === 'person_count_locked' && event.value === false ? 0 : event.item.person_count_internal,
            person_count_locked: event.item.person_count_locked,
            person_count_external: event.item.person_count_external ?? 0,
            id_costcenter: event.item.id_costcenter
          }
          item[event.header] = event.value

          if (existing) {
            existing[event.header] = event.value
            if (existing.person_count_locked) {
              existing.allocation_percentage = 0
            } else {
              existing.person_count_internal = 0
            }
          } else {
            if (item.person_count_locked) {
              item.allocation_percentage = 0
            } else {
              item.person_count_internal = 0
            }
            this.modifiedRows.push(item)
          }
        this.updateDataForWidget(event.id, event.level2.costcenter_code, item)
        } else {
          this.indicatorMessage = message
          this.operationResult = 'error'
          this.showIndicator = true
        }

        var summedPercentages = 0
        event.level2.subItems.filter(i => !this.modifiedRows.find(row => row.id_code === i.id_code && row.id_purposezone === i.id_purposezone)).forEach(i => {
          summedPercentages += i.allocation_percentage ?? 0
        })
        this.modifiedRows.forEach(row => {
          summedPercentages += row.allocation_percentage ?? 0
        })
        
        if (summedPercentages !== 100) {
          this.disableSaving = true
        } else {
          this.disableSaving = false
        }
      }
    },
    async saveChanges () {
      this.savingProgress = true
      try {
        if (this.modifiedRows.length > 0) {
          var data = this.modifiedRows.map(row => {
            return {
              idCode: row.id_code,
              idPurposezone: row.id_purposezone,
              percentage: row.person_count_locked ? 0 : row.allocation_percentage,
              personCountInternal: row.person_count_locked ? row.person_count_internal : null,
              lockPersonCount: row.person_count_locked ?? false,
              personCountExternal: row.person_count_external ?? 0,
              idCostcenter: row.id_costcenter
            }
          })
          await this.updateAllocationConfiguration({ rows: data, time: this.selectedDate})
        }
        if (this.modifiedCodes.length > 0) {
          await this.updateAllocation({codes: this.modifiedCodes, time: this.selectedDate})
        }
        this.indicatorMessage = this.$t('Save success')
        this.operationResult = 'success'
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
        this.showIndicator = true
        this.init()
        this.savingProgress = false
      } catch (err) {
        this.savingProgress = false
        this.indicatorMessage = this.$t('Error while saving')
        this.operationResult = 'error'
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
        this.showIndicator = true
      }
    },
    hideIndicator () {
      this.showIndicator = false
    },
    dragItem (event) {
      if (event.action === 'drop') {
        const header = this.draggedItem.object.header
        const draggedItem = this.draggedItem.object.item
        const row = this.widgets.find(w => this.$t(w.id) === event.title).data.items.find(item => item.site_id === event.object.site_id && item[header] === event.object[header])
        
        if (!row.site_id || row.net_floor_area > 0) {
          if (row[header]) {
            // Create new (existing already has code)
            const newRow = Object.keys(row).reduce((acc, cur) => {
              if (cur !== 'showToggle' && cur !== 'subItems' && cur !== 'person_count') {
                acc[cur] = row[cur]
              }
              return acc
            }, [])
            newRow.allocation_code = draggedItem[header]
            this.widgets.find(w => this.$t(w.id) === event.title).data.items.push(newRow)
          } else {
            // Add to existing
            row.allocation_code = draggedItem[header]
          }

          // Remove from starting point
          if (this.draggedItem.title === this.$t('person_allocation.allocated_personnel') && this.widgets.find(w => this.$t(w.id) === this.draggedItem.title).data.items.filter(item => item.site_id === draggedItem.site_id).length === 1) {
            const startingRow = this.widgets.find(w => this.$t(w.id) === this.draggedItem.title).data.items.find(item => item.site_id === draggedItem.site_id && item[header] === draggedItem[header])
            startingRow.showToggle = false
            startingRow[header] = null
          } else {
            this.widgets.find(w => this.$t(w.id) === this.draggedItem.title).data.items = this.widgets.find(w => this.$t(w.id) === this.draggedItem.title).data.items.filter(item => !(item.site_id === draggedItem.site_id && item[header] === draggedItem[header]))
          }

          if (this.modifiedCodes.find(c => c.value === draggedItem[header])) {
            this.modifiedCodes.find(c => c.value === draggedItem[header]).idSite = row.site_id
          } else {
            this.modifiedCodes.push({
              idCode: draggedItem.id_code,
              idSite: row.site_id ? row.site_id : null
            })
          }
        } else {
          this.indicatorMessage = this.$t('Can not insert when target does not have net floor area')
          this.operationResult = 'error'
          this.showIndicator = true
        }
        this.draggedItem = null
      } else if (event.action === 'start') {
        this.draggedItem = {title: event.title, object: event.object}
      }
    },
    updateDataForWidget (widgetId, updatedCostcenterCode, updatedItem) {
      let dataForWidgetToUpdate = this.widgets.find(widget => this.$t(widget.id) === widgetId)
      let layer0ItemToUpdate = dataForWidgetToUpdate.data.items.find(widgetDataItem => widgetDataItem.id_code === updatedItem.id_code)
      // Update allocation percentage and person count locked values for all target purpose zones (rows) under same allocation code
      layer0ItemToUpdate.subItems.map(layer1Item => {
        layer1Item.subItems.map(layer2Item => {
          let rowToUpdate = layer2Item.id_purposezone === updatedItem.id_purposezone
          if(rowToUpdate) {
            layer2Item.allocation_percentage = updatedItem.allocation_percentage
            layer2Item.person_count_locked = updatedItem.person_count_locked
            layer2Item.person_count_internal = updatedItem.person_count_internal
          }
        })
        // Do the check that is there some person count locked in rows under cost center
      const somePersonCountLocked = layer1Item.subItems.some(subItem => subItem.person_count_locked)
      // Update the check result
      layer1Item.subItems.map(subItem => subItem.some_person_count_locked = somePersonCountLocked)
      })
      // Update person count external information only for updated row
      let layer1ItemToUpdate = layer0ItemToUpdate.subItems.find(layer1Item => layer1Item.costcenter_code === updatedCostcenterCode)
      let layer2ItemToUpdate = layer1ItemToUpdate.subItems.find(layer2Item => layer2Item.id_code === updatedItem.id_code && layer2Item.id_purposezone === updatedItem.id_purposezone)
      layer2ItemToUpdate.person_count_external = updatedItem.person_count_external
      const layer2IdxToReplace = layer1ItemToUpdate.subItems.findIndex(layer2Item => layer2Item.id_code === updatedItem.id_code && layer2Item.id_purposezone === updatedItem.id_purposezone)
      layer1ItemToUpdate.subItems.slice(layer2IdxToReplace, 1, layer2ItemToUpdate)
      const layer1IdxToReplace = layer0ItemToUpdate.subItems.findIndex(layer1Item =>layer1Item.subItems.find(layer2Item => layer2Item.id_code === updatedItem.id_code && layer2Item.id_purposezone === updatedItem.id_purposezone))
      layer0ItemToUpdate.subItems.slice(layer1IdxToReplace, 1, layer1ItemToUpdate)
      const layer0IdxToReplace = dataForWidgetToUpdate.data.items.findIndex(dataItem => dataItem.id_code === updatedItem.id_code)
      dataForWidgetToUpdate.data.items.slice(layer0IdxToReplace, 1, layer0ItemToUpdate)
    },
    openExtraInfoDialog (event) {
      if(event.header === 'allocation_code') {
      this.allocationCode = event.allocationCode
      this.xtraInfoDialogOpen = true
      }
    },
    closeExtraInfoDialog () {
      this.xtraInfoDialogOpen = false
      this.allocationCode = null
    }
  }
}
</script>