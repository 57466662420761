<template>
  <!-- Lead origin -->
  <div>
    <v-dialog
      v-model="showAddPartyDialog"
      persistent
      max-width="400"
    >
      <NewPartyModal
        :parties="allParties"
        :type="'mediator'"
        :visible="showAddPartyDialog"
        @close="showAddPartyDialog = false"
        @refreshParties="refreshParties"
      />
    </v-dialog>
    <v-dialog
      v-model="showEditDialog"
      persistent
      max-width="550"
    >
      <v-card>
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
        >
          <BaseModal
            @cancel="showEditDialog = false"
          >
            <template #title>
              {{ $t('prospect.lead_origin') }}
            </template>
            <template #content>
              <v-select
                v-model="copyItem.lead_origin"
                :label="$t('prospect.lead_origin')"
                :items="leadOrigins"
                item-text="label"
                item-value="id"
                class="form-field"
                clearable
                @change="clearLeadInfo"
              >
                <template #selection="{ item }">
                  {{ $t(`${item.label}`) }}
                </template>
                <template #item="{item}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
              <!-- Lead origin comments (for other leads only) -->
              <v-textarea
                v-if="leadOriginLabel && leadOriginLabel === 'Muu liidi'"
                v-model="copyItem.lead_information"
                :label="$t('prospect.lead_comments')"
                rows="4"
                class="form-field"
                maxlength="4000"
                counter
              />
              <!-- Lead mediator -->
              <ValidationProvider
                v-if="leadOriginLabel && leadOriginLabel === 'Liidi välittäjältä'"
                v-slot="{ errors }"
                name="mediator"
                rules="required"
              >
                <v-autocomplete
                  v-model="copyItem.lead_mediator"
                  :label="$t('Mediator company') + ' *'"
                  :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                  :items="mediators"
                  :loading="partiesLoading"
                  :error-messages="$t(errors)"
                  clearable
                  item-text="name"
                  item-value="id"
                  class="form-field"
                >
                  <template
                    #append-outer
                  >
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            color="grey"
                            @click="showAddPartyDialog = true"
                          >
                            person_add
                          </v-icon>
                        </div>
                      </template>
                      <span>{{ $t('Create a new mediator') }}</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
            </template>
            <template #footer>
              <v-btn
                :disabled="invalid || partiesLoading"
                :loading="saving"
                type="submit"
                rounded
                depressed
                color="primary"
                @click="save"
              >
                {{ $t('Save') }}
              </v-btn>
            </template>
          </BaseModal>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-icon @click="showEditDialog = true">
      edit
    </v-icon>
    <span class="px-2 font-weight-bold">{{ $t('prospect.lead_origin') }}:</span>
    <span>{{ valuesAsString }}</span>
  </div>
</template>

<script>
import BaseModal from "../general/BaseModal.vue";
import NewPartyModal from './Modals/NewPartyModal.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from 'vuex'
import lodash from 'lodash'

export default {
  name: 'LeadEdit',
  components: {
    BaseModal,
    ValidationProvider,
    ValidationObserver,
    NewPartyModal
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      copyItem: {
        lead_origin: null,
        lead_information: '',
        lead_mediator: null
      },
      showEditDialog: false,
      showAddPartyDialog: false,
      allParties: [],
      partiesLoading: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionById']),

    mediators () {
      return this.allParties.filter(party => party.mediator_company === true)
    },

    leadOrigins () {
      return this.definitionsByGroupLabel('prospect.leadorigin').filter(item => item.label !== 'Olemassa oleva vuokrasuhde')
    },

    valuesAsString () {
      const originString = this.$t(this.definitionById(this.item?.lead_origin)?.label)
      const comments = this.item.lead_information
      const mediator = this.allParties.find(item => item.id === this.item.lead_mediator)?.name

      return [originString, comments, mediator].filter(Boolean).join(", ")
    },

    leadOriginLabel () {
      return this.definitionById(this.copyItem?.lead_origin)?.label
    },
  },
  watch: {
    showEditDialog: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    }
  },
  mounted () {
    this.getParties()
    this.open()
  },
  methods: {
    open () {
      this.copyItem = lodash.cloneDeep(this.item)
    },
    clearLeadInfo () {
      this.copyItem.lead_information = '',
      this.copyItem.lead_mediator = null
    },
    async getParties () {
      this.partiesLoading = true
      const data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true } })
      this.allParties = data
      this.partiesLoading = false
    },
    async refreshParties (type, id) {
      await this.getParties()
      if (type === 'mediator') {
        this.copyItem.lead_mediator = this.allParties.find(party => party.id === id).id
      }
    },
    save () {
      this.$emit('save', this.copyItem)
      this.showEditDialog = false
    },
  },
  emits: ['save']
}
</script>

<style scoped>
</style>