<template>
  <div>
    <p>{{ $t('leasing.prediction_prospect_hover_1') }}</p>
  </div>
</template>

<script>
export default { name: 'ActiveProcesses', }
</script>

<style scoped>
</style>