import { i18n } from '../plugins/i18n'

export function ConfirmedTable () {
  return {
    id: 'leasing.confirmed',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site identifier', value: 'site_identifier' },
        {
          text: 'Site',
          value: 'site',
        },
        { text: 'Site classification', value: 'site_classification' },
        { text: 'Rental_event', value: 'process_title', leasing: true },
        { text: 'leasing.rental_type', value: 'rental_type_translated', leasing: true, },
        { text: 'Tenant', value: 'tenant', leasing: true },
        {
          text: 'leasing.rentable_area',
          value: 'area',
          format: 'Area',
        },
        { text: 'Percentage of rentable area', value: 'percentage_of_rentable_area', format: 'Percentage' },
        { text: 'Reportable area', value: 'reportable_area', format: 'Area' },
        { text: 'leasing.area_ratio', value: 'area_ratio', format: 'Percentage' },
        { text: 'Capital rent (€/mth)', value: 'rent', format: 'Euro' },
        { text: 'rental_status.discounts', value: 'discounts', format: 'Euro' },
        { text: 'leasing.contract_type', value: 'contractType' },
        { text: 'Validity', value: 'validity'},
        {
          text: 'Contract date',
          value: 'signature_date',
          format: 'Date',
        },
        { text: 'Contract start date', value: 'curPartyStartDate', format: 'Date', },
        { text: 'Contract end date', value: 'curPartyEndDate', format: 'Date', },
        { text: 'create_rent_contract.rent_contract_duration', value: 'contractDuration' },
        { text: 'leasing.industry', value: 'industry' },
        { text: 'Category', value: 'category' },
        { text: 'Credit rating', value: 'credit_rating' },
        { text: 'VAT-responsibility', value: 'vat_status' },
        { text: 'Apartment type', value: 'apartment_type' },
        { text: 'Contract number', value: 'contractNumber' },
        { text: 'Unit code long', value: 'unitCodes' },
        { text: 'Carpark code long', value: 'carparkCodes' },
        { text: 'unit_count', value: 'unitCount' },
        { text: 'Carparks_and_priviledges.count', value: 'carparkCount' },
        { text: 'Landlord', value: 'renter' },
        { text: 'Unit status', value: 'unit_status' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
      ],
      footers: [
        { text: i18n.t('Total') },
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'area', format: 'Area' },
        { value: 'percentage_of_rentable_area', format: 'Percentage', average: true },
        { value: 'reportable_area', format: 'Area' },
        { value: 'area_ratio', format: 'Percentage', average: true },
        { value: 'rent', format: 'Euro' },
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'contractDuration', average: true, format: 'NumberTwoDecimals' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'unitCount', format: 'Amount' },
        { value: 'carparkCount', format: 'Amount' },
        {},
        {},
        {},
        {},
        {},
      ],
      items: [],
      sortBy: 'signature_date',
      sortDesc: true,
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function EndingFixedTable () {
  return {
    id: 'leasing.ending_fixed',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Site classification', value: 'site_classification' },
        { text: 'Rental_event', value: 'process_title', leasing: true },
        { text: 'leasing.status', value: 'prospect_status', leasing: true },
        { text: 'Reason', value: 'status_description' },
        { text: 'Tenant', value: 'tenant', leasing: true },
        { text: 'Contract party', value: 'curPartyName' },
        {
          text: 'leasing.rentable_area',
          value: 'area',
          format: 'Area',
        },
        { text: 'Percentage of rentable area', value: 'percentage_of_rentable_area', format: 'Percentage' },
        {
          text: 'Reportable ending',
          value: 'reportable_ending',
          modify: true,
          editType: 'Boolean',
          booleanProps: [
            { text: i18n.t('Boolean true'), value: 'true' }, 
            { text: i18n.t('Not taken into account'), value: 'false' }
          ]
        },
        {
          text: 'Reportable area',
          value: 'reportable_area',
          modify: true,
          editType: 'Number',
        },
        { text: 'Count of reportable carparks and priviledges' , value: 'reportable_carparks_count' },
        { text: 'Capital rent (€/mth)', value: 'rent', format: 'Euro' },
        { text: 'Contract end date', value: 'curPartyEndDate', format: 'Date', },
        { text: 'Contract start date', value: 'curPartyStartDate', format: 'Date', },
        { text: 'create_rent_contract.rent_contract_duration', value: 'contractDuration' },
        { text: 'leasing.contract_type', value: 'contractType' },
        { text: 'leasing.industry', value: 'industry' },
        { text: 'Apartment type', value: 'apartment_type' },
        { text: 'rental_status.open_bills_total', value: 'open_bills_total', format: 'Euro' },
        { text: 'Contract number', value: 'contractNumber' },
        { text: 'Unit code long', value: 'unitCodes' },
        { text: 'Carpark code long', value: 'carparkCodes' },
        { text: 'unit_count', value: 'unitCount' },
        { text: 'Carparks.area', value: 'carparkArea', format: 'Area' },
        { text: 'Carparks_and_priviledges.count', value: 'carparkCount' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
      ],
      footers: [
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'curPartyName', unique: true, format: 'Amount' },
        { value: 'area', format: 'Area' },
        { value: 'percentage_of_rentable_area', format: 'Percentage', average: true },
        {},
        {},
        {},
        { value: 'rent', format: 'Euro' },
        {},
        {},
        { value: 'contractDuration', average: true, format: 'NumberTwoDecimals' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'unitCount', format: 'Amount' },
        { value: 'carparkArea', format: 'Area' },
        { value: 'carparkCount', format: 'Amount' },
        {},
        {},
        {},
      ],
      items: [],
      sortBy: 'curPartyEndDate',
      sortDesc: true,
      disablingFields: {
        reportable_area: function (rowData) {
          return !rowData.reportable_ending || !rowData.userHasRightsToEdit
        },
        reportable_ending: function (rowData) {
          return !rowData.userHasRightsToEdit
        },
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function TerminatedPermanentTable () {
  return {
    id: 'leasing.terminated_permanent',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Site classification', value: 'site_classification' },
        { text: 'Rental_event', value: 'process_title', leasing: true },
        { text: 'leasing.status', value: 'prospect_status', leasing: true },
        { text: 'Reason', value: 'status_description' },
        { text: 'Tenant', value: 'tenant', leasing: true },
        { text: 'Contract party', value: 'curPartyName' },
        {
          text: 'leasing.rentable_area',
          value: 'area',
          format: 'Area',
        },
        { text: 'Percentage of rentable area', value: 'percentage_of_rentable_area', format: 'Percentage' },
        {
          text: 'Reportable ending',
          value: 'reportable_ending',
          modify: true,
          editType: 'Boolean',
          booleanProps: [
            { text: i18n.t('Boolean true'), value: 'true' }, 
            { text: i18n.t('Not taken into account'), value: 'false' }
          ]
        },
        {
          text: 'Reportable area',
          value: 'reportable_area',
          modify: true,
          editType: 'Number',
        },
        { text: 'Count of reportable carparks and priviledges', value: 'reportable_carparks_count' },
        { text: 'Capital rent (€/mth)', value: 'rent', format: 'Euro' },
        { text: 'Termination date', value: 'termination_date', format: 'Date' },
        { text: 'Contract end date', value: 'curPartyEndDate', format: 'Date', },
        { text: 'Contract first possible end date', value: 'contractFirstPossibleEndDate', format: 'Date', },
        { text: 'Contract start date', value: 'curPartyStartDate', format: 'Date', },
        { text: 'create_rent_contract.rent_contract_duration', value: 'contractDuration' },
        { text: 'leasing.contract_type', value: 'contractType' },
        { text: 'leasing.industry', value: 'industry' },
        { text: 'Apartment type', value: 'apartment_type' },
        { text: 'rental_status.open_bills_total', value: 'open_bills_total', format: 'Euro' },
        { text: 'Contract number', value: 'contractNumber' },
        { text: 'Unit code long', value: 'unitCodes' },
        { text: 'Carpark code long', value: 'carparkCodes' },
        { text: 'unit_count', value: 'unitCount' },
        { text: 'Carparks.area', value: 'carparkArea', format: 'Area' },
        { text: 'Carparks_and_priviledges.count', value: 'carparkCount' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
      ],
      footers: [
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'curPartyName', unique: true, format: 'Amount' },
        { value: 'area', format: 'Area' },
        { value: 'percentage_of_rentable_area', format: 'Percentage', average: true },
        {},
        {},
        {},
        { value: 'rent', format: 'Euro' },
        {},
        {},
        {},
        {},
        { value: 'contractDuration', average: true, format: 'NumberTwoDecimals' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'unitCount', format: 'Amount' },
        { value: 'carparkArea', format: 'Area' },
        { value: 'carparkCount', format: 'Amount' },
        {},
        {},
        {},
      ],
      items: [],
      sortBy: ['curPartyEndDate'],
      sortDesc: true,
      disablingFields: {
        reportable_area: function (rowData) {
          return !rowData.reportable_ending || !rowData.userHasRightsToEdit
        },
        reportable_ending: function (rowData) {
          return !rowData.userHasRightsToEdit
        },
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function ActiveProspectsTable () {
  return {
    id: 'leasing.active_processes',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Rental_event', value: 'prospect_description' },
        { text: 'Site', value: 'site' },
        { text: 'Type', value: 'prospect_type_text', leasing: true },
        { text: 'leasing.renting_stage', value: 'prospect_status' },
        { text: 'Tenant corporation', value: 'tenant', leasing: true },
        {
          text: 'leasing.m2_requirement',
          value: 'unit_area_preference',
          format: 'Area',
        },
        { text: 'leasing.estimatedSign', value: 'estimated_signature_date', format: 'Date' },
        { text: 'leasing.contract_estimated_start', value: 'estimated_completion', format: 'Date' },
        { text: 'leasing.estimated_modification_cost', value: 'estimated_modification_cost', format: 'Euro' },
        { text: 'rental_process.estimated_value_impact', value: 'estimated_value_impact', format: 'Euro'},
        { text: 'rental_process.maturity', value: 'maturity' },
        { text: 'Usage types', value: 'unit_type' },
        { text: 'Location', value: 'rentalProcessLocations' },
        { text: "prospect.lead_origin", value: "lead_origin" },
        { text: 'rental_process.contract_model', value: 'contract_model' },
        { text: 'rental_process.contract_type', value: 'contract_type' },
        { text: 'Credit rating', value: 'credit_rating'},
        { text: 'rental_process.profit_analysis_done', value: 'profit_analysis_done', format: 'Boolean' },
        { text: 'rental_process.written_proposal_done', value: 'written_proposal_done', format: 'Boolean' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
        { text: 'Need', value: 'customer_need' },
        { text: 'leasing.lastUpdatedDay', value: 'last_modified', format: 'Date' },
        { text: 'rental_process.modified_user', value: 'modified_user' },
        { text: 'leasing.createdDay' , value: 'created', format: 'Date' },
        { text: 'rental_process.created_user', value: 'created_user' },
        { text: 'Merkitty sopimusneuvotteluihin', value: 'marked_to_negotiations', format: 'Date' },
     
      ],
      browseHeaders: [
        { text: 'Rental_event', value: 'prospect_description', leasing: true},
        { text: 'Site', value: 'site' },
        { text: 'Type', value: 'prospect_type_text', leasing: true },
        {
          text: 'leasing.renting_stage',
          value: 'prospect_fullscreen_status',
          leasing: true,
        },
        { text: 'Tenant corporation', value: 'tenant', leasing: true },
        {
          text: 'leasing.m2_requirement',
          value: 'unit_area_preference',
          format: 'Area',
        },
        { text: 'leasing.estimatedSign', value: 'estimated_signature_date', format: 'Date' },
        { text: 'leasing.contract_estimated_start', value: 'estimated_completion', format: 'Date' },
        { text: 'leasing.estimated_modification_cost', value: 'estimated_modification_cost', format: 'Euro' },
        { text: 'rental_process.estimated_value_impact', value: 'estimated_value_impact', format: 'Euro' },
        { text: 'rental_process.maturity', value: 'maturity' },
        { text: 'Usage types', value: 'unit_type' },
        { text: 'Location', value: 'rentalProcessLocations' },
        { text: "prospect.lead_origin", value: "lead_origin" },
        { text: 'rental_process.contract_model', value: 'contract_model' },
        { text: 'rental_process.contract_type', value: 'contract_type' },
        { text: 'Credit rating', value: 'credit_rating'},
        { text: 'rental_process.profit_analysis_done', value: 'profit_analysis_done', format: 'Boolean' },
        { text: 'rental_process.written_proposal_done', value: 'written_proposal_done', format: 'Boolean' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
        { text: 'Need', value: 'customer_need' },
        { text: 'leasing.lastUpdatedDay', value: 'last_modified', format: 'Date' },
        { text: 'rental_process.modified_user', value: 'modified_user' },
        { text: 'leasing.createdDay', value: 'created', format: 'Date' },
        { text: 'rental_process.created_user', value: 'created_user' },
        { text: 'Merkitty sopimusneuvotteluihin', value: 'marked_to_negotiations', format: 'Date' },
        
      ],
      footers: [
        { text: i18n.t('Total') },
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'unit_area_preference', unit: 'area', format: 'Area' },
        {},
        {},
        { value: 'estimated_modification_cost', format: 'Euro' },
        { value: 'estimated_value_impact', format: 'Euro' },
        { value: 'maturity', average: true, format: 'Integer' },
        {},
        {},
        {},
        {},
      ],
      items: [],
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function PredictionEndingFixedTable () {
  return {
    id: 'leasing.ending_fixed',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Site classification', value: 'site_classification' },
        { text: 'Rental_event', value: 'process_title', leasing: true },
        { text: 'leasing.status', value: 'prospect_status', leasing: true },
        { text: 'Reason', value: 'status_description' },
        { text: 'Tenant', value: 'tenant', leasing: true },
        { text: 'Contract party', value: 'curPartyName' },
        {
          text: 'leasing.rentable_area',
          value: 'area',
          format: 'Area',
        },
        { text: 'Percentage of rentable area', value: 'percentage_of_rentable_area', format: 'Percentage' },
        {
          text: 'Reportable ending',
          value: 'reportable_ending',
          modify: true,
          editType: 'Boolean',
          booleanProps: [
            { text: i18n.t('Boolean true'), value: 'true' }, 
            { text: i18n.t('Not taken into account'), value: 'false' }
          ]
        },
        {
          text: 'Reportable area',
          value: 'reportable_area',
          modify: true,
          editType: 'Number',
        },
        { text: 'Count of reportable carparks and priviledges', value: 'reportable_carparks_count' },
        { text: 'Capital rent (€/mth)', value: 'rent', format: 'Euro' },
        {
          text: 'Contract end date',
          value: 'curPartyEndDate',
          format: 'Date',
        },
        { text: 'Contract start date', value: 'curPartyStartDate', format: 'Date', },
        { text: 'create_rent_contract.rent_contract_duration', value: 'contractDuration' },
        { text: 'leasing.contract_type', value: 'contractType' },
        { text: 'leasing.industry', value: 'industry' },
        { text: 'rental_status.open_bills_total', value: 'open_bills_total', format: 'Euro' },
        { text: 'Apartment type', value: 'apartment_type' },
        { text: 'Contract number', value: 'contractNumber' },
        { text: 'Unit code long', value: 'unitCodes' },
        { text: 'Carpark code long', value: 'carparkCodes' },
        { text: 'unit_count', value: 'unitCount' },
        { text: 'Carparks_and_priviledges.count', value: 'carparkCount' },
        { text: 'Carparks.area', value: 'carparkArea', format: 'Area' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
      ],
      footers: [
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'curPartyName', unique: true, format: 'Amount' },
        { value: 'area', format: 'Area' },
        { value: 'percentage_of_rentable_area', format: 'Percentage', average: true },
        {},
        {},
        {},
        { value: 'rent', format: 'Euro' },
        {},
        {},
        { value: 'contractDuration', average: true, format: 'NumberTwoDecimals' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'unitCount', format: 'Amount' },
        { value: 'carparkCount', format: 'Amount' },
        { value: 'carparkArea', format: 'Area' },
        {},
        {},
        {},
      ],
      items: [],
      disablingFields: {
        reportable_area: function (rowData) {
          return !rowData.reportable_ending
        }
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function PredictionTerminatedPermanentTable () {
  return {
    id: 'leasing.terminated_permanent',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Site classification', value: 'site_classification' },
        { text: 'Rental_event', value: 'process_title', leasing: true },
        { text: 'leasing.status', value: 'prospect_status', leasing: true },
        { text: 'Reason', value: 'status_description' },
        { text: 'Tenant', value: 'tenant', leasing: true },
        { text: 'Contract party', value: 'curPartyName' },
        {
          text: 'leasing.rentable_area',
          value: 'area',
          format: 'Area',
        },
        { text: 'Percentage of rentable area', value: 'percentage_of_rentable_area', format: 'Percentage' },
        {
          text: 'Reportable ending',
          value: 'reportable_ending',
          modify: true,
          editType: 'Boolean',
          booleanProps: [
            { text: i18n.t('Boolean true'), value: 'true' }, 
            { text: i18n.t('Not taken into account'), value: 'false' }
          ]
        },
        {
          text: 'Reportable area',
          value: 'reportable_area',
          modify: true,
          editType: 'Number',
        },
        { text: 'Count of reportable carparks and priviledges', value: 'reportable_carparks_count' },
        { text: 'Capital rent (€/mth)', value: 'rent', format: 'Euro' },
        { text: 'Termination date', value: 'termination_date', format: 'Date' },
        { text: 'Contract end date', value: 'curPartyEndDate', format: 'Date', },
        { text: 'Contract start date', value: 'curPartyStartDate', format: 'Date', },
        { text: 'create_rent_contract.rent_contract_duration', value: 'contractDuration' },
        { text: 'leasing.contract_type', value: 'contractType' },
        { text: 'leasing.industry', value: 'industry' },
        { text: 'rental_status.open_bills_total', value: 'open_bills_total', format: 'Euro' },
        { text: 'Apartment type', value: 'apartment_type' },
        { text: 'Contract number', value: 'contractNumber' },
        { text: 'Unit code long', value: 'unitCodes' },
        { text: 'Carpark code long', value: 'carparkCodes' },
        { text: 'unit_count', value: 'unitCount' },
        { text: 'Carparks_and_priviledges.count', value: 'carparkCount' },
        { text: 'Carparks.area', value: 'carparkArea', format: 'Area' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'rental_process.reponsibles', value: 'rentalProcessResponsibles' },
      ],
      footers: [
        { value: 'site', unique: true, format: 'Amount' },
        {},
        {},
        {},
        {},
        { value: 'tenant', unique: true, format: 'Amount' },
        { value: 'curPartyName', unique: true, format: 'Amount' },
        { value: 'area', format: 'Area' },
        { value: 'percentage_of_rentable_area', format: 'Percentage', average: true },
        {},
        {},
        {},
        { value: 'rent', format: 'Euro' },
        {},
        {},
        { value: 'contractDuration', average: true, format: 'NumberTwoDecimals' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'unitCount', format: 'Amount' },
        { value: 'carparkCount', format: 'Amount' },
        { value: 'carparkArea', format: 'Area' },
        {},
        {},
        {},
      ],
      items: [],
      disablingFields: {
        reportable_area: function (rowData) {
          return !rowData.reportable_ending
        }
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function CurrentFreeTable () {
  return {
    id: 'leasing.currentFree',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Site identifier', value: 'site_identifier' },
        { text: 'Site classification', value: 'site_classification' },
        { value: 'commercial_name', text: 'Commercial name' },
        { text: 'Site state', value: 'site_state' },
        { text: 'Site status', value: 'site_status' },
        { text: 'Status', value: 'rentalSituation', noDescription: true },
        { text: 'leasing.occupancy_rate_economic', value: 'economic_occupancy', format: 'Percentage' },
        { text: 'leasing.market_rent_free_units', value: 'unit_market_rent_free', format: 'Euro' },
        { text: 'Rentable area', value: 'rentable_floor_area', format: 'Area'},
        { text: 'Free floor area', value: 'free_floor_area', format: 'Area' },
        { text: 'Ending units area', value: 'ending_area', format: 'Area' }, //Päättyvien huoneistojen m2
        { text: 'Starting units area', value: 'future_area', format: 'Area'}, //Alkavien huoneistojen m2
        { text: 'Free & rented in future',value: 'leased_in_future_area', format: 'Area'}, //Vapaa & vuokr. tul.
        { text: 'Rented floor area', value: 'rented_floor_area', format: 'Area', },
        { text: 'Occupancy rate tecnical', value: 'technical_usage_rate', format: 'Percentage' },
        { text: 'leasing.rentable_units_total', value: 'rentable_units_total' },
        { text: 'leasing.rented_units', value: 'rented_units' },
        { text: 'leasing.free_units', value: 'free_units' },
        { text: 'leasing.rented_in_future_units', value: 'leased_in_future_units' },//Vuokrattu tulevaisuudessa lkm
        { text: 'leasing.units_rate', value: 'unitRate', format: 'Percentage' },//Vuokrausaste, huoneisto lkm
        { text: 'leasing.number_of_tenants', value: 'number_of_tenants', format: 'Amount' },
        { text: 'leasing.number_of_contracts', value: 'number_of_contracts', format: 'Amount' },
        { text: 'leasing.net_total_current_year' , value: 'net_total_current_year', format: 'Area' },
        { text: 'leasing.net_total_previous_year', value: 'net_total_previous_year', format: 'Area' },
        { text: 'leasing_active_processes', value: 'active_processes_area_preference', format: 'Area' }, //Aktiiviset prosessit
        { text: 'leasing.confirmed_new_negotiations', value: 'confirmed_new_negotiations_area', format: 'Area' }, //Vahvistetut uudet neuvottelut
        { text: 'Amount', value: 'carpark_count' }, //Autopaikkoja yhteensä
        { text: 'Free car parks', value: 'free_carparks' }, //Vapaita autopaikkoja
        { text: 'Carpark usage rate', value: 'carpark_usage_rate', format: 'Percentage' }, //Autopaikkojen käyttöaste
        { text: 'Maximum number of parking priviledges', value: 'max_parking_priviledges' }, //Maksimimäärä pysäköintioikeuksia
        { text: 'Parking rights given', value: 'parking_rights_given' }, //Pysäköintioikeuksia annettu
        { text: 'Parking rights remain', value: 'parking_rights_remain' }, //Pysäköintioikeuksia jäljellä
        { text: 'Usage rate of parking priviledges', value: 'parking_priviledges_usage_rate', format: 'Percentage' }, //Pysäköintioikeuksien käyttöaste
        { text: 'Carparks in area', value: 'carparks_in_area' }, //Autopaikkoja alueella
        { text: 'Free carparks (area)', value: 'free_carparks_area' }, //Vapaita autopaikkoja (alue)
        { text: 'p_area.usage_rate', value: 'parking_area_usage_rate', format: 'Percentage' }, //Autopaikkojen käyttöaste (alue)
        { text: 'web visibility prevented m2', value: 'prevented_units_area', format: 'Area' }, //nettinäkyvyys estetty m2
        { text: 'Contract type', value: 'contract_type' }, //Sopimustyyppi
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' },
        { text: 'Rental liability', value: 'rental_liability' }, //Vuokravastuu        
        { text: 'Building completed year', value: 'completed_year', average: true }, //Rakennus valmistunut vuosi
      ],
      footers: [
        { text: i18n.t('Total') },
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'economic_occupancy', format: 'Percentage', average: true },
        { value: 'unit_market_rent_free', format: 'Euro'},
        { value: 'rentable_floor_area', format: 'Area' },
        { value: 'free_floor_area', format: 'Area' },
        { value: 'ending_area', format: 'Area' },
        { value: 'future_area', format: 'Area'},
        { value: 'leased_in_future_area', format: 'Area' },
        { value: 'rented_floor_area', format: 'Area' },
        { value: 'technical_usage_rate', format: 'Percentage', average: true },
        { value: 'rentable_units_total', format: 'Amount' },
        { value: 'rented_units', format: 'Amount' },
        { value: 'free_units', format: 'Amount' },
        { value: 'leased_in_future_units', format: 'Amount' },
        { value: 'unitRate', format: 'Percentage', average: true },
        { value: 'number_of_tenants', format: 'Amount' },
        { value: 'number_of_contracts', format: 'Amount' },
        { value: 'net_total_current_year', format: 'Area' },
        { value: 'net_total_previous_year', format: 'Area' },
        { value: 'active_processes_area_preference', format: 'Area' },
        { value: 'confirmed_new_negotiations_area', format: 'Area' },
        { value: 'car_space_count', format: 'Amount' },
        { value: 'free_carparks', format: 'Amount' },
        { value: 'carpark_usage_rate', format: 'Percentage', average: true },
        { value: 'max_parking_priviledges', format: 'Amount' },
        { value: 'parking_rights_given', format: 'Amount' },
        { value: 'parking_rights_remain', format: 'Amount' },
        { value: 'parking_priviledges_usage_rate', format: 'Percentage', average: true },
        { value: 'carparks_in_area', format: 'Amount' },
        { value: 'free_carparks_area', format: 'Amount' },
        { value: 'parking_area_usage_rate', average: true, format: 'Percentage' },
        {},
        {},
        {},
        {},
        {},
        { value: 'completed_year', average: true, format: 'Integer' },
        
      ],
      items: [],
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function TargetTable () {
  return {
    id: 'leasing.target',
    type: 'ObjectTable',
    data: {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'Rent priority', value: 'rent_priority' },
        {
          text: 'Current free area',
          value: 'freeArea',
          format: 'Area',
        },
        {
          text: i18n.t('Current free units'),
          value: 'freeUnits',
        },
        {
          text: i18n.t('New negotiation target'),
          value: 'newNegotiationTarget',
          modify: true,
          editType: 'NullableNumber',
        },
        {
          text: i18n.t('Ending area per year'),
          value: 'endingArea',
          format: 'Area',
        },
        {
          text: i18n.t('Ending contracts per year'),
          value: 'endingContracts'
        },
        {
          text: i18n.t('Renegotiation target area'),
          value: 'renegotiationTarget',
          modify: true,
          editType: 'NullableNumber',
        },
        {
          text: i18n.t('UFN area total'),
          value: 'ufnArea',
          format: 'Area',
        },
        {
          text: i18n.t('UFN contracts total'),
          value: 'ufnContracts'
        },
        {
          text: i18n.t('UFN renewal target area'),
          value: 'renewalTarget',
          modify: true,
          editType: 'NullableNumber',
        },
        {
          text: i18n.t('Usage rate area'),
          value: 'usage',
          format: 'Percentage',
        },
        {
          text: i18n.t('Usage rate EUR'),
          value: 'economicUsage',
          format: 'Percentage',
        },
        {
          text: i18n.t('Usage rate target'),
          value: 'usageRateTarget',
          modify: true,
          editType: 'NullableNumber',
        },
        { text: 'Modified', value: 'modified', format: 'Date' },
        { text: 'prospect.editor', value: 'modifier' },
        { text: 'Site manager', value: 'siteManager' },
        { text: 'leasing.lease_responsible', value: 'leaseResponsible' }
      ],
      footers: [
        { text: i18n.t('Total') },
        {},
        { value: 'freeArea', format: 'Area' },
        { value: 'freeUnits' },
        { value: 'newNegotiationTarget', format: 'Area' },
        { value: 'endingArea', format: 'Area' },
        { value: 'endingContracts' },
        { value: 'renegotiationTarget', format: 'Area' },
        { value: 'ufnArea', format: 'Area' },
        { value: 'ufnContracts'},
        { value: 'renewalTarget', format: 'Area' },
        { value: 'usage', format: 'Percentage', average: true },
        { value: 'economicUsage', format: 'Percentage', average: true },
        { value: 'usageRateTarget', format: 'Percentage', average: true },
        {},
        {},
        {},
        {}
      ],
      items: [],
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}
