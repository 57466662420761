/**
 * Default site filter
 */
export class SiteDefaultFilter {
  /**
    * Default site filter
    * @param {String} filterName - Name of the filter. Normally app.siteFilters object key
    * @param {Any[]} filterValues - List of values which must match.
    */
  constructor (filterName, filterValues) {
    this.filterName = filterName
    this.filterList = filterValues
  }

  /**
   * Filters site list based on the params given on constructor
   * @param {Object[]} sites - Sites list
   * @returns Filtered sites list
   */
  filter (sites) {

    const key = this.filterName === 'sites' ? 'id_site' : this.filterName

    if (this.filterList.length > 0) {
      return sites.filter(site => this.filterList.includes(site[key] ? site[key] : 'NOT DEFINED'))
    }

    return sites
  }
}

export const isValidSiteDefaultFilter = (filterName, filterValues) => {
  return filterValues && filterValues.length > 0
}