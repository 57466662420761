<template>
  <div>
    <v-dialog
      v-if="showNewHandInvoiceRowModal"
      v-model="showNewHandInvoiceRowModal"
      persistent
      width="80%"
    >
      <v-card>
        <NewHandInvoiceRowModal 
          :rent-payment-domains="rentPaymentDomains"
          :invoice-row-to-edit="invoiceRowToEdit"
          :contract-rows="contractRows"
          @close="closeNewHandInvoiceRowModal"
          @addInvoiceRow="onInvoiceRowAdded"
          @updateInvoiceRow="onInvoiceRowUpdated"
        />
      </v-card>
    </v-dialog>
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid }"
    >
      <BaseModal
        @cancel="close()"
      >
        <template #title>
          {{ title }}
        </template>
        <template #content>
          <v-checkbox
            v-model="isNotContractRelated"
            class="form-fields mt-0 mb-4 ml-2"
            hide-details
            required
            :label="$t('Invoice is not related to contract')"
            @change="onChangeIsNotContractRelated"
          />
          <!-- IF CHECKBOX NOT SELECTED -->
          <div v-if="isNotContractRelated === false">
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="contractId"
                    :items="contracts"
                    item-text="contractNumber"
                    item-value="id"
                    class="form-fields my-2 ml-2"
                    :label="$t('Contract')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    @change="onContractChange"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <DatePicker
                  :label="$t('new_handinvoice.contract_startdate')"
                  :value="formatDate(startDate)"
                  :clearable="false"
                  class="my-2 ml-2"
                  :hide-details="true"
                  :display-month-only="true"
                  :calendar-type="'month'"
                  :initial-date="startDate"
                  @dateupdated="startDateUpdated"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  :label="$t('new_handinvoice.contract_enddate')"
                  :value="formatDate(endDate)"
                  :clearable="false"
                  class="my-2 ml-2"
                  :hide-details="true"
                  :display-month-only="true"
                  :calendar-type="'month'"
                  :initial-date="endDate"
                  @dateupdated="endDateUpdated"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="tenant"
                  :label="$t('Tenant')"
                  text="tenant"
                  class="form-fields my-2 ml-2"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="landlord"
                  :label="$t('Landlord')"
                  class="form-fields my-2 ml-2"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedUnits"
                  :items="filteredUnits"
                  item-text="name"
                  item-value="id"
                  class="form-fields ml-2 height-auto"
                  :label="$t('Units')"
                  hide-details
                  multiple
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedStructures"
                  :items="filteredStructures"
                  item-text="name"
                  item-value="id"
                  class="form-fields mt-4 ml-2 height-auto"
                  :label="$t('Structures')"
                  hide-details
                  multiple
                  readonly
                />
              </v-col>
            </v-row>
          </div>
          <!-- IF CHECKBOX SELECTED -->
          <div v-if="isNotContractRelated">
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="tenantId"
                    :items="allParties"
                    item-text="name"
                    item-value="id"
                    class="form-fields my-2 ml-2"
                    :label="$t('Tenant')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="selectedSite"
                    :items="sites"
                    item-text="name"
                    item-value="id_site"
                    class="form-fields my-2 ml-2"
                    :label="$t('Site')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    @change="onChangeSelectedSite(selectedSite)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="selectedSite">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedBuilding"
                  :items="filteredBuildings"
                  item-text="building_name"
                  class="form-fields my-2 ml-2"
                  :label="$t('Building')"
                  return-object
                  @change="onChangeSelectedBuilding(selectedBuilding)"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedStructures"
                  :items="filteredStructures"
                  item-text="name"
                  item-value="id"
                  class="form-fields mt-4 ml-2"
                  :label="$t('Structures')"
                  multiple
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedBuilding">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedFloor"
                  :items="filteredFloors"
                  item-text="floor_name"
                  item-value="id"
                  class="form-fields my-2 ml-2"
                  :label="$t('Floor')"
                  @change="onChangeSelectedFloor(selectedFloor)"
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedFloor">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedUnits"
                  :items="filteredUnits"
                  item-text="name"
                  item-value="id"
                  class="form-fields my-2 ml-2 height-auto"
                  :label="$t('Units')"
                  multiple
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-select
                    v-model="defBillingAddress"
                    :items="billingAddressTypes"
                    item-value="id"
                    class="form-fields my-2 ml-2"
                    :label="$t('Address')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                  >
                    <template #selection="{ item, index }">
                      {{ item.label ? $t(`${item.label}`) : "" }}
                    </template>
                    <template #item="{item, index}">
                      {{ $t(`${item.label}`) }}
                    </template>
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
          <CdsAlert
            v-if="invoiceRows.length < 1"
            size="large"
            :title="$t('Lisää laskutusrivit')"
            :description="$t('OneRowRequired')"
            class="mt-8 mb-4 ml-2"
          >
            <v-btn
              rounded
              depressed
              color="primary"
              @click="openNewHandInvoiceRowModal"
              v-on="on"
            >
              <v-icon left>
                add
              </v-icon>
              {{ $t('Add row to invoice') }}
            </v-btn>
          </CdsAlert>
          <v-row
            v-else
            class="form-fields my-2 ml-2"
          >
            <v-col cols="12">
              <v-expansion-panels
                v-model="panel"
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('Hand invoices') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table
                      class="v-datatable v-data-table v-table table them--light ma-0 pa-0"
                    >
                      <thead>
                        <th class="column">
                          {{ $t('Site') }}
                        </th>
                        <th class="column">
                          {{ $t('Building') }}
                        </th>
                        <th class="column">
                          {{ $t('Unit') }}
                        </th>
                        <th class="column">
                          {{ $t('Structure') }}
                        </th>
                        <th class="column">
                          {{ $t('create_rent_contract.payment_domain') }}
                        </th>
                        <th class="column">
                          {{ $t('create_rent_contract.net_sum') }}
                        </th>
                        <th class="column">
                          {{ $t('create_rent_contract.tax') }}
                        </th>
                        <th class="column">
                          {{ $t('create_rent_contract.payment_legend') }}
                        </th>
                        <th />
                      </thead>
                      <tbody>
                        <tr
                          v-for="(invoiceRow, index) in invoiceRows"
                          :key="index"
                        >
                          <td>{{ invoiceRow.site ? invoiceRow.site.name : '' }}</td>
                          <td>{{ invoiceRow.building ? invoiceRow.building.building_name : '' }}</td>
                          <td>{{ invoiceRow.units ? invoiceRow.units.map(unit => unit.name).join(', ') : '' }}</td>
                          <td>{{ invoiceRow.structures ? invoiceRow.structures.map(structure => structure.name).join(', ') : '' }}</td>
                          <td>{{ invoiceRow.rentPaymentDomain ? invoiceRow.rentPaymentDomain.name : '' }}</td>
                          <td>
                            <v-col
                              class="mr-1 float-right"
                            >
                              {{ format(invoiceRow.netSum, 'NumberTwoDecimals') }}
                            </v-col>
                          </td>
                          <td>
                            <v-col
                              class="mr-1 float-right"
                            >
                              {{ format(invoiceRow.taxSum, 'NumberTwoDecimals') }}
                            </v-col>
                          </td>
                          <td>{{ invoiceRow.legend }}</td>
                          <td>
                            <v-icon
                              small
                              @click="() => editInvoiceRow(invoiceRow, index)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              @click="() => removeInvoiceRow(index)"
                            >
                              delete
                            </v-icon>
                          </td>
                        </tr>
                        <tr v-if="invoiceRows.length > 0">
                          <td>{{ $t("Total") }}</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            <v-col
                              class="mr-1 float-right"
                            >
                              {{ format(totalNetSum, 'NumberTwoDecimals') }}
                            </v-col>
                          </td>
                          <td>
                            <v-col
                              class="mr-1 float-right"
                            >
                              {{ format(totalTaxSum, 'NumberTwoDecimals') }}
                            </v-col>
                          </td>
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>  
          <v-row>
            <v-col
              cols="12"
            >
              <v-btn
                v-if="invoiceRows.length > 0"
                rounded
                depressed
                class="blue-button mt-2 ml-1 table"
                @click="openNewHandInvoiceRowModal"
                v-on="on"
              >
                {{ $t('Add row to invoice') }}
              </v-btn>
            </v-col>
          </v-row>       
          <v-row>
            <v-col cols="12">
              <DatePicker
                :label="$t('BillingMonth')"
                :value="formatDate(billingDate)"
                :hint="$t('Required')"
                :default-hint="false"
                :clearable="false"
                :rules="'required'"
                required
                class="my-2 ml-2"
                :display-month-only="true"
                :initial-date="billingDate"
                :calendar-type="'month'"
                :allowed-dates="allowedBillingDates"
                @dateupdated="onBillingDateChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="legend"
                :label="$t('Invoice legend')"
                class="form-fields my-2 ml-2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="payerReference"
                :label="$t('create_rent_contract.payer_reference')"
                class="form-fields mt-2 ml-2"
              />
            </v-col>
          </v-row>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-col 
            class="text-left validation-error ml-2 mb-4"
          >
            {{ errorText }}
          </v-col>
          <v-col
            cols="4"
            class="text-right align-center"
          >
            <small v-if="invalid || invoiceRows.length < 1"> {{ $t('FillRequiredFields') }}</small>
            <v-btn
              rounded
              depressed
              color="accent"
              :disabled="invalid || invoiceRows.length < 1 || creatingInvoice"
              @click="saveInvoice"
            >
              {{ $t('Accept invoice') }}
            </v-btn>
          </v-col>
        </template>
      </BaseModal>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import BaseModal from '../general/BaseModal'
import CdsAlert from '../CircleDesignSystem/CdsAlert.vue'
import NewHandInvoiceRowModal from './NewHandInvoiceRowModal.vue'
import DatePicker from '../general/DatePicker.vue'
import helpers from  '../../helpers'

export default {
  name: "NewHandInvoice",
  components: {
    BaseModal,
    DatePicker,
    NewHandInvoiceRowModal,
    ValidationProvider,
    ValidationObserver,
    CdsAlert,
  },
  props: {
    invoiceMaterial: {
      type: Object,
      default: function () {
        undefined
      }
    },
    isExistingInvoice : {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      panel: [0],
      contracts: [],
      allParties: [],
      selectedSite: undefined,
      selectedBuilding: undefined,
      selectedFloor: undefined,
      selectedUnits: [],
      selectedStructures: [],
      filteredBuildings: undefined,
      filteredFloors: undefined,
      filteredUnits: [],
      filteredStructures: [],
      isNotContractRelated: false,
      contractId: undefined,
      startDate: undefined,
      endDate: undefined,
      tenantId: undefined,
      tenant: undefined,
      landlordId: undefined,
      landlord: undefined,
      billingDate: undefined,
      legend: undefined,
      invoiceCreator: undefined,
      showNewHandInvoiceRowModal: false,
      rentPaymentDomains: [],
      payerReference: undefined,
      invoiceRows: [],
      defBillingAddress: null,
      invoiceRowToEdit: undefined,
      invoiceRowToEditIndex: undefined,
      contractRows: undefined,
      errorText: undefined,
      creatingInvoice: false
    }
  },
  computed: {
    ...mapState('app', ['sites', 'userInfo']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionByLabel']),

    billingAddressTypes () {
      return this.definitionsByGroupLabel('rent_contracts.billing_address_type').filter(def => def.label !== 'BillingAddressTypeContractSpecific')
    },
    totalNetSum () {
      return this.invoiceRows.reduce((accumulator, currentRow) => accumulator + Number(currentRow.netSum), 0)
    },
    totalTaxSum () {
      return this.invoiceRows.reduce((accumulator, currentRow) => accumulator + Number(currentRow.taxSum), 0)
    },
    title () {
      if (this.isExistingInvoice) 
        return this.$t('Modify hand invoice') 
      else 
        return this.$t('New hand invoice')
    }
  },
  watch: {
    invoiceMaterial () {
      this.initializeInvoice()
    }
  },
  async mounted () {
    this.contracts = await this.$rambollfmapi.contracts.contract.getAllContractIds(true, true, false)

    this.allParties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })

    this.defBillingAddress = this.defBillingAddress ?? this.definitionByLabel('BillingAddressTypeTenanAddress').id
    this.invoiceCreator = await this.userInfo.id
    this.rentPaymentDomains = await this.getAllRentPaymentDomains()

    this.initializeInvoice()
  },
  methods: {
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("MM/YYYY") : undefined
    },
    startDateUpdated (value) {
      this.startDate = value
    },
    endDateUpdated (value) {
      this.endDate = value
    },
    onChangeIsNotContractRelated (isNotContractRelated) {
      this.isNotContractRelated = isNotContractRelated
      this.clearValues()
    },
    async onChangeSelectedSite (selectedSite) {
      if (selectedSite !== undefined) {
        this.selectedSite = selectedSite
        await this.getBuildingsBySiteId()
        await this.getStructuresBySiteId()
      }
      this.selectedBuilding = undefined
      this.selectedFloor = undefined
      this.selectedUnits = undefined
      this.selectedStructures = undefined
    },
    async onChangeSelectedBuilding (selectedBuilding) {
      if (selectedBuilding !== undefined) {
        this.selectedBuilding = selectedBuilding
        await this.getFloorsByBuildingId()
      }
      this.selectedFloor = undefined
      this.selectedUnits = []
    },
    async onChangeSelectedFloor (selectedFloor) {
      if (selectedFloor !== undefined) {
        this.selectedFloor = selectedFloor
        await this.getUnitsByFloorId()
      }
      this.selectedUnits = []
    },
    allowedBillingDates (date) {
      const selectedDate = new Date(date)
      selectedDate.setHours(0, 0, 0, 0);
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0);

      //min date = current month - 1
      const minDate = new Date(currentDate)
      minDate.setDate(0)
      minDate.setDate(1)
      //max date = current month + 10
      const maxDate = new Date(currentDate)
      maxDate.setDate(1)
      maxDate.setMonth(currentDate.getMonth() + 10)
      
      return (selectedDate >= minDate && selectedDate <= maxDate)
    },
    onBillingDateChange (value) {
      this.billingDate = value ?? ""
    },
    async getBuildingsBySiteId () {
      this.filteredBuildings = await this.$rambollfmapi.buildings.list({ query: { siteId: this.selectedSite } })
    },
    async getStructuresBySiteId () {
      this.filteredStructures = []
      this.filteredStructures = await this.$rambollfmapi.structures.list({ query: { siteId: this.selectedSite } })
      for(let filteredStructureIndex in this.filteredStructures)
      {
         this.filteredStructures[filteredStructureIndex].id = this.filteredStructures[filteredStructureIndex].structure_id
         this.filteredStructures[filteredStructureIndex].name = this.filteredStructures[filteredStructureIndex].structure_name
      }
    },
    async getFloorsByBuildingId () {
      this.filteredFloors = await this.$rambollfmapi.buildings.floors(this.selectedBuilding.building_code).list()
    },
    async getUnitsByFloorId () {
      this.filteredUnits = await this.$rambollfmapi.floors.units(this.selectedFloor).list()
      for(let iFilteredUnit in this.filteredUnits) {
        this.filteredUnits[iFilteredUnit].name = this.filteredUnits[iFilteredUnit].unit_code
      }
    },
    async onContractChange () {
      if (!this.contractId) {
        this.clearValues()
        return
      }
      const contract = await this.$rambollfmapi.contracts.contract.getWithRows({ contractId: this.contractId})

      this.selectedUnits = []
      this.selectedStructures = []
      this.filteredUnits = []
      this.filteredStructures = []
      if (contract.rows.length > 0) {
        contract.rows.forEach(r => {
          if (r.unitId) {
            this.selectedUnits.push(r.unitId)
            this.filteredUnits.push({
              name: r.unitName,
              id: r.unitId
            })
          } else {
            this.selectedStructures.push(r.structureId)
            this.filteredStructures.push({
              name: r.structureName,
              id: r.structureId
            })
          }
        })
      }
      this.contractRows = contract.rows

      this.startDate = contract.contract.startDate
      this.endDate = contract.contract.endDate
      this.tenantId = contract.contract.tenantId
      this.landlordId = contract.contract.landlordId

      this.tenant = this.getPersonById(this.tenantId)
      this.landlord = this.getPersonById(this.landlordId)
    },
    getPersonById (id) {
      return this.allParties.find(p => p.id === id)?.name
    },
    getCompanyPartyId () {
      return this.allParties.find(p => p.is_company)?.id
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.clearValues()
        this.$emit('close')
      }
    },
    openNewHandInvoiceRowModal () {
      this.showNewHandInvoiceRowModal = true
    },
    closeNewHandInvoiceRowModal () {
      this.showNewHandInvoiceRowModal = false
      this.invoiceRowToEdit = undefined
      this.invoiceRowToEditIndex = undefined
    },
    async getAllRentPaymentDomains () {
      const result = await this.$rambollfmapi.contracts.contract.getRentPaymentDomains()
      return result.map(r => ({...r, fullName: `${r.name} ${this.$t('ALV')} ${r.vatPercent} %`}))
    },
    onInvoiceRowAdded (invoiceRow) {
      this.invoiceRows.push(invoiceRow)
      this.$store.dispatch('app/addAriaLive', this.$t('Success'))
    },
    editInvoiceRow (invoiceRow, index) {
      this.invoiceRowToEdit = invoiceRow
      this.invoiceRowToEditIndex = index
      this.openNewHandInvoiceRowModal()
    },
    onInvoiceRowUpdated (invoiceRow) {
      this.$set(this.invoiceRows, this.invoiceRowToEditIndex, invoiceRow)
      this.invoiceRowToEdit = undefined
      this.invoiceRowToEditIndex = undefined
    },
    removeInvoiceRow (removeIndex) {
      this.invoiceRows = this.invoiceRows.filter((_, index) => index !== removeIndex )
    },
    format (value, formatter) {
      return helpers.format.formatData(value, formatter)
    },
    async saveInvoice () {
      this.creatingInvoice = true
      const invoiceMaterial = {
        unitIds: this.selectedUnits,
        structureIds: this.selectedStructures,
        siteIds: this.selectedSite ? [ this.selectedSite ] : [],
        buildingIds: this.selectedBuilding ? [ this.selectedBuilding.id_building ] : [],
        floorIds: this.selectedFloor ? [ this.selectedFloor ] : [],
        isNotContractRelated: this.isNotContractRelated,
        landlordId: this.isNotContractRelated ? this.getCompanyPartyId() : this.landlordId,
        tenantId: this.tenantId,
        idContract: this.contractId,
        startDate: this.startDate,
        endDate: this.endDate,
        billingDate: this.billingDate,
        legend: this.legend,
        payerReference: this.payerReference,
        defBillingAddress: this.defBillingAddress,
        id: this.isExistingInvoice ? this.invoiceMaterial.id : 0,
        State: 3,
        invoiceRows: this.invoiceRows.map(row => ({
          ...row,
          siteIds: row.site ? [ row.site.id_site ] : [],
          buildingIds: row.building ? [ row.building.id_building ] : [],
          floorIds: row.floor ? [ row.floor.id ] : [],
          unitIds: row.units?.map(u => u.id),
          structureIds: row.structures?.map(s => s.id)
        })),
      }
      if (this.isNotContractRelated) {
        invoiceMaterial.landlordId = this.getCompanyPartyId()
        invoiceMaterial.tenantId = this.tenantId
      }

      let errors = await this.$rambollfmapi.invoices.createHandInvoice(invoiceMaterial)
      this.creatingInvoice = false
      if (errors.length > 0) {
        let errorType = errors[0].errorType
        this.errorText = `${this.$t("InvoiceGenericError")}: ${this.$t(errorType)}`;
      }
      else {
        this.clearValues()
        this.$store.dispatch('app/addAriaLive', this.$t('InvoiceCreateSuccess'))
        this.$emit('created')
        this.$emit('close')
      }
    },
    clearValues () {
      this.contractId = undefined
      this.startDate = undefined
      this.endDate = undefined
      this.tenant = undefined
      this.tenantId = undefined
      this.landlord = undefined
      this.landlordId = undefined
      this.selectedUnits = []
      this.selectedStructures = []
      this.selectedFloor = undefined
      this.selectedBuilding = undefined
      this.selectedSite = undefined
      this.contractRows = undefined
    },
    async initializeInvoice () {
      if (this.isExistingInvoice) {
        this.contractId = this.invoiceMaterial.idContract
        if (this.contractId) 
          await this.onContractChange()
        else {
          this.isNotContractRelated = true
        }
        this.tenant = await this.invoiceMaterial.tenant
        this.tenantId = await this.invoiceMaterial.tenantId
        this.landlord = await this.invoiceMaterial.landlord
        this.landlordId = await this.invoiceMaterial.landlordId
        this.selectedSite = await this.invoiceMaterial.siteIds[0]
        this.selectedBuilding = undefined
        this.selectedFloor = undefined
        this.selectedUnits = undefined
        if (this.selectedSite !== undefined) {
          await this.getBuildingsBySiteId()
          await this.getStructuresBySiteId()
          this.selectedStructures = await this.invoiceMaterial.structureIds
          let buildingId = await this.invoiceMaterial.buildingIds[0]
          if (buildingId !== undefined) {
            for(let iBuilding = 0; iBuilding < this.filteredBuildings.length; iBuilding++)
              if (this.filteredBuildings[iBuilding].id_building === buildingId)
                this.selectedBuilding = this.filteredBuildings[iBuilding]
            await this.getFloorsByBuildingId()
            this.selectedFloor = await this.invoiceMaterial.floorIds[0]
            if (this.selectedFloor !== undefined) {
              await this.getUnitsByFloorId()
              this.selectedUnits = await this.invoiceMaterial.unitIds
            }
          }
        }

        this.dueDate = await this.invoiceMaterial.dueDate
        this.legend = await this.invoiceMaterial.legend
        this.payerReference = await this.invoiceMaterial.payerReference
        this.billingDate = await this.invoiceMaterial.billingDate
        this.tenantId = await this.invoiceMaterial.tenantId

        this.invoiceMaterial.invoiceRows.forEach((row) => {
          let units = undefined;
          let structures = undefined;
          if (row.unitIds.length > 0) {
             units = [];
             for(let iUnitId=0; iUnitId < row.unitIds.length; iUnitId++)
               units.push({ 'id': row.unitIds[iUnitId], 'name': row.unitNames[row.unitIds[iUnitId]]});
          }
          if (row.structureIds.length > 0) {
             structures = [];
             for(let iStructureId=0; iStructureId < row.structureIds.length; iStructureId++)
               structures.push({ 'id': row.structureIds[iStructureId], 'name': row.structureNames[row.structureIds[iStructureId]]});
          }
          let taxSum = row.grossSum - row.netSum 
          let site = undefined
          if (row.siteIds[0] !== undefined)
            site = { 'id_site' : row.siteIds[0], 'name' : row.siteNames[row.siteIds[0]]}
          let building = undefined
          if (row.buildingIds[0] !== undefined)
            building = { 'id_building': row.buildingIds[0], 'building_name': row.buildingNames[row.buildingIds[0]]}
          let floor = undefined
          if (row.floorIds[0] !== undefined)
            floor = { 'id' : row.floorIds[0], 'floor_name': row.floorNames[row.floorIds[0]]}
          let invoiceRow = {
            'legend': row.legend,
            'rentPaymentDomain': row.rentPaymentDomain,
            'netSum': row.netSum,
            'units': units,
            'structures': structures,
            'building': building,
            'taxSum': taxSum,
            'site': site,
            'floor': floor,
            'grossSum': row.grossSum,
            'id': row.id
          }
          this.invoiceRows.push(invoiceRow);
        })
      }
    }
  }
}
</script>
  
<style scoped>
.form-headers {
  margin: 5px 5px 5px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}

.table {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 1em;
}

.height-auto {
  height: auto !important;
}
</style>
    