<template>
  <v-row
    class="dashboard"
  >
    <v-col
      cols="12"
      class="pt-2"
    >
      <v-select
        v-model="selectedProspectType"
        :label="$t('leasing.prospect_type_choice')"
        return-object
        item-text="text"
        item-value="value"
        :items="prospectTypes"
        class="type-select mb-4"
      />
    </v-col>
    <v-col
      v-for="widget in widgets"
      :key="widget.id"
      :lg="widget.small ? 3 : 6"
      :md="widget.small ? 6 : 12"
      sm="12"
      cols="12"
    >
      <DynamicWidget
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :plugins="widget.plugins"
        :loading="widgetLoading(widget.id)"
        @graphClick="(chartElement) => graphClick(chartElement, widget.id)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LeasingWidgets from '../../../widgets/leasing'
import DynamicWidget from '../../DynamicWidget'
import { getProspectType, computeProspect } from '../../../helpers/leasing/prospect'
import moment from 'moment'
import { prospectTypeColors, prospectLikelihoodColors } from "../../../constants/colors"

export default {
  name: 'Prospects',
  components: { 
    DynamicWidget
  },
  props: {
    userSites: { type: Array, default: () => []},
    showOnlyUserSites: { type: Boolean, default: false}
  },
  data () {
    return {
      widgets: [],
      situationData: null,
      prospectTypes: [
          { text: this.$t('prospect.show_all'), value: 'all' },
          { text: this.$t('leasing.new_negotiation'), value: 'new' },
          { text: this.$t('leasing.renegotiations_fixed'), value: 'renegotiations' },
          { text: this.$t('leasing.renewals_permanent'), value: 'renewals' }
      ],
      selectedProspectType: { name: this.$t('prospect.show_all'), value: 'all' },
    }
  },
  computed: {
    ...mapState('leasing', [
      'prospectsLoading',
      'partiesLoading',
      'municipalitiesLoading',
      'leasingUsersLoading',
      'prospectEventsLoading',
      'prospects',
      'municipalities',
      'parties',
      'allBusinessParties',
      'leasingUsers',
      'prospectEvents',
      'ignoredProspects',
      'ignoredProspectsLoading'
    ]),
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'currentDate'
    ]),
    ...mapGetters('app', [
      'definitionsByGroupLabel',
      'definitionById',
      'siteFiltersByTypes',
      'hasApplicationPermissionByName'
    ]),
    sitesFilter () {
      if(this.showOnlyUserSites){
        return this.userSites.map(site => site.id_site)
      }

      if(this.sites && this.sites.length > 0){
        return this.sites.map(site => site.id_site)
      }

      return null
    }
  },
  watch: {
    prospects: async function () {
      this.loadWidgetData()
    },
    ignoredProspects: async function () {
      this.loadWidgetData()
    },
    sites: function () {
      this.loadWidgetData()
    },
    selectedProspectType: function () {
      this.setWidgets(this.selectedProspectType)
      this.loadWidgetData()
    },
    userSites: function () {
      this.loadWidgetData()
    },
    prospectEvents: function () {
      this.loadWidgetData()
    },
  },
  mounted () {
    this.setWidgets()
    this.loadWidgetData()
  },
  methods: {
    ...mapActions('leasing', ['changeProspectModalState']),
    setWidgets (prospectType){

      let widgets = []

      if(prospectType){
        widgets = [...LeasingWidgets.Prospects(prospectType)]
      } else {
        widgets = [...LeasingWidgets.Prospects()]
      }

      this.widgets = widgets
    },
    loadWidgetData (id) {

      let widgets = this.widgets

      if(id){
        widgets = widgets.filter(widget => widget.id === id)
      }

      widgets.forEach(widget => {
        widget.data.items = this.dataForWidget(widget.id)
        if (widget.data.dynamicHeaders) {
          widget.data.headers = this.dataHeadersForWidget(widget.id)
        }
        if (widget.data.dynamicSets) {
          widget.data.sets = this.dataSetsForWidget(widget.id)
        }
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }
      })
    },
	legendForWidget (id) {
      try {
        switch(id) {
          case 'leasing.biggest_active_prospects':
            return this.loadBiggestActiveProspectsLegend()
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    loadBiggestActiveProspectsLegend (){
      return  Object.entries(prospectLikelihoodColors).map(([key, value]) => 
        ({
            text: this.$t(key),
            fillStyle: value,
            hidden: false,
            lineWidth: 1,
            strokeStyle: '#fff',
        })
      )
    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.all_prospects':
          return this.sitesLoading || this.prospectsLoading || this.partiesLoading || this.municipalitiesLoading || this.leasingUsersLoading || this.prospectEventsLoading || this.ignoredProspectsLoading
        case 'leasing.prospect_origins':
          return this.sitesLoading || this.prospectsLoading || this.partiesLoading
        case 'leasing.site_activity': 
        case 'leasing.biggest_active_prospects':
        case 'leasing.all_active_renting_processes':
        case 'leasing.inactive_prospects':
          return this.sitesLoading || this.prospectsLoading
        case 'leasing.active_prospects_progress':
          return this.sitesLoading || this.prospectsLoading || this.prospectEventsLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }
        switch(id) {
          case 'leasing.all_prospects':
            return this.allProspects(this.getProspectsByType(true), this.userSites, this.parties, this.municipalities, this.leasingUsers, this.prospectEvents)
          case 'leasing.all_active_renting_processes':
            return this.loadActiveRentingprocesses(this.getProspectsByType())
          case 'leasing.active_prospects_progress':
            return this.loadActiveProspectsProgress(this.getProspectsByType(), this.prospectEvents)
          case 'leasing.site_activity': 
            return this.loadSiteActivity(this.getProspectsByType(), this.sites)
          case 'leasing.biggest_active_prospects':
            return this.loadBiggestActiveProspects(this.getProspectsByType(), this.userSites)
          case 'leasing.inactive_prospects':
            return this.inactiveProspectsByReason(this.getProspectsByType(), this.definitionsByGroupLabel('prospect.abandonedreason'))
          case 'leasing.prospect_origins':
            return this.loadProspectOrigins(this.getProspectsByType(), this.allBusinessParties)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process data for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    dataHeadersForWidget (id) {
      try {

        switch(id) {
          case 'leasing.active_prospects_progress':
            return this.loadActiveProspectsProgressHeaders(id)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process headers for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    dataSetsForWidget (id) {
      try {
        switch(id) {
          case 'leasing.biggest_active_prospects':
            return this.loadBiggestActiveProspectSets(this.getProspectsByType())
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process sets for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    allProspects (prospects, sites, parties, municipalities, leasingUsers, prospectEvents) {
      let prospectWidgetData = []

      let filteredProspects = prospects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (sites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      if (this.siteFiltersByTypes('all').length > 0) {
        filteredProspects = filteredProspects.filter(prospect => prospect.sites.length !== 0)
      }

      prospectWidgetData = filteredProspects.map(prospect => {
        const events = prospectEvents.filter(event => event.id_prospect === prospect.id_prospect)
        return computeProspect(prospect, parties, municipalities, sites, leasingUsers, events, (id) => this.getDefinitionLabelById(id))
      })

      prospectWidgetData.sort((prev, next) =>  new Date(next.last_modified) - new Date(prev.last_modified))
      
      return prospectWidgetData
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },

    loadActiveRentingprocesses (prospects) {
      // Filter out prospects based on selected sites
      let filteredProspects = prospects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (this.userSites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      // If any site filters are active, filter out prospects with no sites set
      if (this.siteFiltersByTypes('all').length > 0) {
        filteredProspects = filteredProspects.filter(prospect => prospect.sites.length !== 0)
      }

      let data = []
      filteredProspects.forEach(prospect => {
        let value = {name: prospect.status, value: prospect.unit_area_preference}
        if (prospect.status === 'Active') {
          if (prospect.success_likelihood == null) {
            value['activity'] = 'prospect.activity_not_set'
          }
          else {
            value['activity'] = 'prospect.activity_' + prospect.success_likelihood.toLowerCase()
          }
          
        }
        data.push(value)
      })

      // Sort data first by status and second by activity
      data.sort((prev, next) => {
        let statusCompare = prev.name.localeCompare(next.name)
        let activityCompare = 0
        if (prev.activity == null){
          activityCompare = -1
        }
        else if (next.activity == null) {
          activityCompare = 1
        }
        else {
          activityCompare = prev.activity.localeCompare(next.activity)
        }
        return statusCompare * 2 + activityCompare 
      })
      return data
    },
    loadActiveProspectsProgress (prospects, events) {
      let data = []
      // Filter out prospects based on selected sites
      let filteredProspects = prospects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (this.userSites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      // If any site filters are active, filter out prospects with no sites set
      if (this.siteFiltersByTypes('all').length > 0) {
        filteredProspects = filteredProspects.filter(prospect => prospect.sites.length !== 0)
      }

      // Create quarters based on given date
      let date = moment(this.currentDate)
      let quarter = date.get('quarter')
      let year = date.get('year')
      // Add quarters with empty fields to data
      for (let i = 0; i < 10; i++) {
        let endDate = moment(date.endOf('quarter'))
        let startDate = (moment(date).startOf('quarter'))

        let dateString = 'Q' + quarter + ' ' + year 

        if (i === 0) {
          endDate = moment(this.currentDate).endOf('day')
          dateString = moment(this.currentDate).format('D.M.YYYY')
        }
        data.unshift({
          quarter: dateString,
          startDate,
          endDate,
        })

        quarter--
        if (quarter === 0) {
          year--
          quarter = 4
        }
        date.set('quarter', quarter)
        date.set('year', year)
      }

      filteredProspects.forEach(prospect => {
        let quarterDatas = []
        const prospectEvents = events.filter(event => event.id_prospect === prospect.id_prospect)
        // First add data by created date
        const quarterData = data.find(dataPoint => moment(prospect.created).isBetween(dataPoint.startDate, dataPoint.endDate))
        if (quarterData) {
          this.addProspectProgressQuarterData(quarterData, prospect, 'Created')
        }

        // Then add data by active date
        // Only add prospects that were created before last date
        if (moment(prospect.created).isBefore(data[data.length - 1].endDate)) {
          if (prospect.status === 'Active')
          {
            // Add currently active prospect to all quarters that ended after it was created
            quarterDatas = data.filter((quarter) => moment(prospect.created).isBefore(quarter.endDate))
            quarterDatas.forEach(quarter => this.addProspectProgressQuarterData(quarter, prospect, 'Activedate'))
          }
          else {
            const prospectMarkedPassedEvent = prospectEvents.find(event => event.id_prospect === prospect.id_prospect && event.event_type === 'Prospekti merkitty ohi menneeksi')

            // If prospect creation date is same as the date it's set inactive, prospect was never active
            if ((prospectMarkedPassedEvent && prospect.created === prospectMarkedPassedEvent.modified_date) || prospect.created === prospect.passed_date) {
              quarterDatas = []
            }
            else {
              let lastActiveDate = new Date()
              // In case prospect has both closed and passed event, we choose the more recent of the two
              if (prospect.prospect_marked_won_date && prospect.passed_date) {
                lastActiveDate = prospect.prospect_marked_won_date > prospect.passed_date ? prospect.prospect_marked_won_date : prospect.passed_date
              }
              else if (prospect.prospect_marked_won_date) {
                lastActiveDate = prospect.prospect_marked_won_date
              }
              else if (prospect.passed_date) {
                lastActiveDate = prospect.passed_date
              }
              else if (prospectMarkedPassedEvent?.modifed_date) {
                lastActiveDate = prospectMarkedPassedEvent.modifed_date
              }
              // Not all prospects have these events (event log was implemented after prospects)
              else {
                lastActiveDate = prospect.last_modified
              }
              // We add data to all quarter where prospect was created before end date and was still active after end date
              quarterDatas = data.filter(quarter => moment(prospect.created).isBefore(quarter.endDate) && moment(lastActiveDate).isAfter(quarter.endDate) )
            }
            quarterDatas.forEach(quarter => this.addProspectProgressQuarterData(quarter, prospect, 'Activedate'))
          } 
        }

      })

      // Finally strip away useless values from data objects
      data.forEach(quarterData => {
        delete quarterData.startDate
        delete quarterData.endDate
      })
      return data
    },
    loadSiteActivity (prospects, sites){
      // Prospect types:
      // vihreä = Uudis = leasing.new_negotiation
      // Sininen = Jatko = leasing.renegotiations
      // pinkki = Uusimis = leasing.renewal 
      // xxxxx = xxxxxx = prospect.real_estate_development

      
      // First let's group the sites by the id_site so we can do faster lookup and avoid the n^2 lookup.
      
      const groupedSites = sites.reduce( (acc, site) => {
        acc[site.id_site] = site
        return acc
      }, {})

      let filteredProspects = prospects.filter(prospect => prospect.status === "Active" || prospect.status === "AwaitingAllocation")
      filteredProspects = filteredProspects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (this.userSites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      // We have to calculate how many prospects there is assigned to each site. The HorizontalBarDataGraph will do this for us.
      // We have to flatten the prospect list by the sites. So that we have a list of prospects with id_site field. There will be 
      // multiple same prospects, but it doesn't matter in this case. 
      const flattened = filteredProspects.reduce( (acc, prospect) => {
        if(prospect.sites && prospect.sites.length > 0){
          
          const prospectType = getProspectType(prospect).typeText

          prospect.sites.forEach( site => acc.push({ 
            id_site: site.id_site, 
            siteName: groupedSites[site.id_site].name,
            prospectType: prospectType === "prospect.real_estate_development" ? "leasing.new_negotiation" : prospectType,
            likelihood: this.siteActivityLikelihood(prospect),
            ...prospect 
          }))
        }
        return acc
      }, [])

      return flattened
    },
    siteActivityLikelihood (prospect){
      if( prospect.status === "AwaitingAllocation" ){
        return "AwaitingAllocation"
      }
      else if ( !prospect.success_likelihood){
        return "prospect.success_likelihood_unknown"
      }
      else if ( prospect.success_likelihood === "High"){
        return "prospect.success_likelihood_high"
      }
      else if ( prospect.success_likelihood === "Intermediate"){
        return "prospect.success_likelihood_intermediate"
      }
      else if ( prospect.success_likelihood === "Low"){
        return "prospect.success_likelihood_low"
      }
      return prospect.success_likelihood
    },
    addProspectProgressQuarterData (quarterData, prospect, type) {
      if (this.selectedProspectType.value === 'all') {
        let prospectType = getProspectType(prospect)
        switch (prospectType.typeText) {
          case 'leasing.renegotiations':
            quarterData['areaRenegotiations' + type] ? quarterData['areaRenegotiations' + type] += prospect.unit_area_preference :  quarterData['areaRenegotiations' + type] = prospect.unit_area_preference
            quarterData['amountRenegotiations' + type] ? quarterData['amountRenegotiations' + type]++ :  quarterData['amountRenegotiations' + type] = 1
            break
          case 'leasing.renewal':
            quarterData['areaRenewals' + type] ? quarterData['areaRenewals' + type] += prospect.unit_area_preference :  quarterData['areaRenewals' + type] = prospect.unit_area_preference
            quarterData['amountRenewals' + type] ? quarterData['amountRenewals' + type]++ :  quarterData['amountRenewals' + type] = 1
            break
          default:
            quarterData['areaNew' + type] ? quarterData['areaNew' + type] += prospect.unit_area_preference :  quarterData['areaNew' + type] = prospect.unit_area_preference
            quarterData['amountNew' + type] ? quarterData['amountNew' + type]++ :  quarterData['amountNew' + type]  = 1
            break
        }
      }
      else {
        let prospectSuccessLikelihood = prospect.success_likelihood
        switch (prospectSuccessLikelihood) {
          case 'High':
            quarterData['areaHighLikelihood' + type] ? quarterData['areaHighLikelihood' + type] += prospect.unit_area_preference :  quarterData['areaHighLikelihood' + type] = prospect.unit_area_preference
            quarterData['amountHighLikelihood' + type] ? quarterData['amountHighLikelihood' + type] ++ :  quarterData['amountHighLikelihood' + type]  = 1
            break
          case 'Intermediate':
            quarterData['areaIntermediateLikelihood' + type] ? quarterData['areaIntermediateLikelihood' + type] += prospect.unit_area_preference :  quarterData['areaIntermediateLikelihood' + type] = prospect.unit_area_preference
            quarterData['amountIntermediateLikelihood' + type] ? quarterData['amountIntermediateLikelihood' + type]++ :  quarterData['amountIntermediateLikelihood' + type] = 1
            break
          case 'Low':
            quarterData['areaLowLikelihood' + type] ? quarterData['areaLowLikelihood' + type] += prospect.unit_area_preference :  quarterData['areaLowLikelihood' + type] = prospect.unit_area_preference
            quarterData['amountLowLikelihood' + type] ? quarterData['amountLowLikelihood' + type]++ :  quarterData['amountLowLikelihood' + type] = 1
            break
          default:
            quarterData['areaUnknownLikelihood' + type] ? quarterData['areaUnknownLikelihood' + type] += prospect.unit_area_preference :  quarterData['areaUnknownLikelihood' + type] = prospect.unit_area_preference
            quarterData['amountUnknownLikelihood' + type] ? quarterData['amountUnknownLikelihood' + type]++ :  quarterData['amountUnknownLikelihood' + type] = 1
            break
        }
      }
      // Total data needed for all
      quarterData['areaTotal' + type] ? quarterData['areaTotal' + type] += prospect.unit_area_preference :  quarterData['areaTotal' + type] = prospect.unit_area_preference
      quarterData['amountTotal' + type] ? quarterData['amountTotal' + type] ++ :  quarterData['amountTotal' + type]  = 1
    },
    loadActiveProspectsProgressHeaders (id) {
      if (this.selectedProspectType.value === 'all') {
        return {
          area: [
            {
              text: this.$t('leasing.ending_contracts_permanent'),
              value: 'areaRenewalsCreated',
              color: prospectTypeColors['leasing.renewal'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_fixed_term'),
              value: 'areaRenegotiationsCreated',
              color: prospectTypeColors['leasing.renegotiations'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.new_negotiation'),
              value: 'areaNewCreated',
              color: prospectTypeColors['leasing.new_negotiation'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_permanent'),
              value: 'areaRenewalsActivedate',
              color: prospectTypeColors['leasing.renewal'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_fixed_term'),
              value: 'areaRenegotiationsActivedate',
              color: prospectTypeColors['leasing.renegotiations'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.new_negotiation'),
              value: 'areaNewActivedate',
              color: prospectTypeColors['leasing.new_negotiation'],
              group: 'active',
              showOnTooltip: true,
            },
            // Tooltipheaders
            { 
              text: this.$t('Totals'), 
              value: 'areaTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'created',
            },
            { 
              text: this.$t('Totals'), 
              value: 'areaTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'active'
            },
          ],
          amount: [
          {
              text: this.$t('leasing.ending_contracts_permanent'),
              value: 'amountRenewalsCreated',
              color: prospectTypeColors['leasing.renewal'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_fixed_term'),
              value: 'amountRenegotiationsCreated',
              color: prospectTypeColors['leasing.renegotiations'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.new_negotiation'),
              value: 'amountNewCreated',
              color: prospectTypeColors['leasing.new_negotiation'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_permanent'),
              value: 'amountRenewalsActivedate',
              color: prospectTypeColors['leasing.renewal'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.ending_contracts_fixed_term'),
              value: 'amountRenegotiationsActivedate',
              color: prospectTypeColors['leasing.renegotiations'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('leasing.new_negotiation'),
              value: 'amountNewActivedate',
              color: prospectTypeColors['leasing.new_negotiation'],
              group: 'active',
              showOnTooltip: true,
            },
            // Tooltipheaders
            { 
              text: this.$t('Totals'), 
              value: 'amountTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: '', 
              decimals: 0,
              group: 'created',
            },
            { 
              text: this.$t('Totals'), 
              value: 'amountTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: '', 
              decimals: 0,
              group: 'active'
            },
          ],
        }
      }
      else {
        return {
          area: [
            {
              text: this.$t('prospect.success_likelihood_unknown'),
              value: 'areaUnknownLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_unknown'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_low'),
              value: 'areaLowLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_low'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_intermediate'),
              value: 'areaIntermediateLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_intermediate'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_high'),
              value: 'areaHighLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_high'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_unknown'),
              value: 'areaUnknownLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_unknown'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_low'),
              value: 'areaLowLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_low'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_intermediate'),
              value: 'areaIntermediateLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_intermediate'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_high'),
              value: 'areaHighLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_high'],
              group: 'active',
              showOnTooltip: true,
            },
            // Tooltipheaders
            { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'areaTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'created',
            },
            { 
              text: this.$t('leasing.active_prospects_total'),
              value: 'areaTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'active'
            },
                        { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'amountTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'kpl', 
              decimals: 0,
              group: 'created',
            },
            { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'amountTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'kpl', 
              decimals: 0,
              group: 'active'
            },
          ],
          amount: [
            {
              text: this.$t('prospect.success_likelihood_unknown'),
              value: 'amountUnknownLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_unknown'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_low'),
              value: 'amountLowLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_low'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_intermediate'),
              value: 'amountIntermediateLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_intermediate'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_high'),
              value: 'amountHighLikelihoodCreated',
              color: prospectLikelihoodColors['prospect.success_likelihood_high'],
              group: 'created',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_unknown'),
              value: 'amountUnknownLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_unknown'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_low'),
              value: 'amountLowLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_low'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_intermediate'),
              value: 'amountIntermediateLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_intermediate'],
              group: 'active',
              showOnTooltip: true,
            },
            {
              text: this.$t('prospect.success_likelihood_high'),
              value: 'amountHighLikelihoodActivedate',
              color: prospectLikelihoodColors['prospect.success_likelihood_high'],
              group: 'active',
              showOnTooltip: true,
            },
            { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'areaTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'created',
            },
            { 
              text: this.$t('leasing.active_prospects_total'),
              value: 'areaTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'm\u00B2', 
              decimals: 2,
              group: 'active'
            },
            { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'amountTotalCreated', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'kpl', 
              decimals: 0,
              group: 'created',
            },
            { 
              text: this.$t('leasing.active_prospects_total'), 
              value: 'amountTotalActivedate', 
              color: '#000', 
              tooltipOnly: true, 
              unit: 'kpl', 
              decimals: 0,
              group: 'active'
            },
          ],
        }
      }
    },
    getProspectsByType (ignored = false) {
      let filteredProspects = []
      let prospects = this.prospects
      if (ignored) {
        prospects = prospects.concat(this.ignoredProspects)
      }
      switch(this.selectedProspectType.value) {
        case 'all':
          filteredProspects = prospects
          break
        case 'new':
          filteredProspects = prospects.filter(prospect => prospect.renegotiations === false)
          break
        case 'renegotiations':
          filteredProspects =  prospects.filter(prospect => ( prospect.renegotiations && (prospect.rental_contract_validity === 'määräaikainen' || prospect.rental_contract_validity == null)))
          break
        case 'renewals':
          filteredProspects = prospects.filter(prospect => ( prospect.renegotiations && prospect.rental_contract_validity === 'toistaiseksi voimassaoleva'))
          break
        default:
          filteredProspects =  prospects
      }
      if (this.sitesFilter) {
        return filteredProspects.filter(prospect => {
          if (prospect.sites.length === 0) {
            return false
          }
          const prospectSiteIds = prospect.sites.map(item => item.id_site)
          const result = prospectSiteIds.every(siteId => this.sitesFilter.includes(siteId))
          return result
        })
      }
      else {
        return filteredProspects
      }
    },
    loadBiggestActiveProspects (prospects, sites) {
      let usageDefinitions = this.definitionsByGroupLabel('unit.usage_types')
      let activeProspects = prospects.filter(prospect => prospect.status === 'Active')
      let filteredProspects = activeProspects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (sites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })
      let data =  filteredProspects.map(prospect => {
        let siteName = ''
        if (prospect.sites.length === 1) {
          let foundSite = sites.find(site => site.id_site === prospect.sites[0].id_site)
          siteName = foundSite.name
        }
        else if (prospect.sites.length > 1) {
          siteName = this.$t('leasing.multiple_sites')
        }
        let prospectLikelihoodString = prospect.success_likelihood ?? 'Unknown'
        let prospectData = {
          id: prospect.id_prospect,
          prospectName: prospect.prospect_description + '\n ' + siteName,
          unit_area_preference: prospect.unit_area_preference,
          success_likelihood: prospectLikelihoodString,
          color: prospectLikelihoodColors['prospect.success_likelihood_' + prospectLikelihoodString.toLowerCase()],
        }
        prospect.unit_type_preference.forEach(typePreference => {
          let usageLabel = usageDefinitions.find(definition => definition.id === typePreference).label
          prospectData['unit_area_preference_' + usageLabel] = prospect.unit_area_preference
        })
        return prospectData
      })
      return data
    },
    loadBiggestActiveProspectSets (prospects) {
      let values = []
      let activeProspects = prospects.filter(prospect => prospect.status === 'Active')
      let filteredProspects = activeProspects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (this.userSites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })
      // Add all options
      values.push({
        title: this.$t('prospect.show_all'),
        groupBy: 'prospectName',
        format: 'Area',
        sortField: 'unit_area_preference',
        groups: [
          {
            value: 'unit_area_preference',
            label: this.$t('prospect.area_requirement'),
          },
        ],
      })
      // Add options for prospect unit usage types
      let definitions = this.definitionsByGroupLabel('unit.usage_types')
      for (let i = 0, length = definitions.length; i < length; i++) {
        let definition = definitions[i]
        // Only add unit type if some prospect has that unit type
        let include = filteredProspects.some(prospect => prospect.unit_type_preference.includes(definition.id))
        if (!include) {
          continue
        }
        values.push ({
          title: this.$t(definition.label),
          groupBy: 'prospectName',
          format: 'Area',
          sortField: 'unit_area_preference_' + definition.label,
          groups: [
            {
              value: 'unit_area_preference_' + definition.label,
              label: this.$t('prospect.area_requirement'),
            },
          ],
        })
      }
      return values
    },
    inactiveProspectsByReason (prospects, abandonedReasons){

      const inactiveProspects = prospects.filter(prospect => prospect.status === "Inactive")

      const totalUnitAreaPreference = inactiveProspects.reduce((acc, cur) => acc + cur.unit_area_preference, 0)

      const prospectsByAbandonedReason = inactiveProspects.reduce( (acc, cur ) => {
        
        if(acc[cur.status_description]){
          acc[cur.status_description].unit_area_preference += cur.unit_area_preference
        }
        else {
          acc[cur.status_description] = { 
            unit_area_preference: cur.unit_area_preference,
            status_description: cur.status_description
          } 
        }
        
        return acc
      }, {})


      const data = Object.values(prospectsByAbandonedReason).map(prospect => {
        return {
          unit_area_preference: prospect.unit_area_preference / totalUnitAreaPreference * 100,
          status_description: abandonedReasons.find(reason => reason.id === prospect.status_description).label
        }
      })

      return data
    },
    loadProspectOrigins (prospects, parties){
      /* 
      Specs from ticket
      Lohkoissa:
        Liidi nettisivuilta
        Muu liidi
        Oma liidi
        Neljä suurinta välittäjäyritystä kaikki erikseen
        Muut välittäjäyritykset: kaikki muut välittäjäyritykset yhteensä. 
      */

      // Active, Inactive, Closed, AwaitingAllocation
      const defaultAreas = prospects.reduce((acc, cur) => { 
        if(!acc[cur.status]){
          acc[cur.status] = 0
        }
        return acc
      }, {})

      defaultAreas.All = 0

      // Get the largest four for each set. First we have to calculate totals for each mediator company 
      // The largest four will change depending on the selected set. So we have to calculate totals per mediator per prospect status
      const areasByMeditator = prospects
        .filter(prospect => this.getDefinitionLabelById(prospect.lead_origin) === "Liidi välittäjältä")
        .reduce((acc, prospect) => {

          const { 
            unit_area_preference: area, 
            lead_mediator: mediator, 
            status 
          } = prospect
          
          if(!acc[mediator]){
            acc[mediator] = {...defaultAreas}
          }

          acc[mediator].All += area
          
          if(!acc[mediator][status]){
            acc[mediator][status] = area
          }
          else {
            acc[mediator][status] += area
          }
          
          return acc

      }, {})

      // Now that we have the total values, let's select largest four mediators for each status.
      const areasMediatorList = Object.entries(areasByMeditator)

      // At the moment of writing = ["All", "Active", "Inactive", .....]
      const largestClasses = Object.keys(defaultAreas)

      // Selecting is done by first sorting the list by total area of each status and selecting only 4 largest. 
      const largestMediators = largestClasses.map( classification => {

        const sorted = areasMediatorList.sort((a, b) => a[1][classification] - b[1][classification])

        return { classification, largest: sorted.slice(-4)}
      })

      // Now we have all the required information. Let's form the data.
      const data = prospects.map(prospect => {
        
        const { 
          unit_area_preference: area, 
          lead_origin: origin, // id => definitinos
          lead_mediator: mediator, // id => parties
          status
        } = prospect


        let originLabel = this.getDefinitionLabelById(origin)
        let allOrigin = null

        // If the lead is from mediator, we have to check if the mediator belongs to the largest four of current status and use instead
        // the company name as origin. Otherwise it belongs to the Other category.
        if(originLabel === "Liidi välittäjältä"){
          
          const isAllLargest = largestMediators
            .find( m => m.classification === "All") // Find by status
            .largest // Select list
            .find(l => Number(l[0]) === mediator) // Find by mediator

          // Mediator belongs to the largest four. Let's find the name of the mediator
          if(isAllLargest){
            const party = parties.find( p => p.id === mediator) 
            allOrigin = party.name 
          }
          else {
            allOrigin = "prospect.other_mediators"
          }

          // Same by the status.
          const isLargest = largestMediators
            .find( m => m.classification === status) // Find by status
            .largest // Select list
            .find(l => Number(l[0]) === mediator) // Find by mediator

          if(isLargest){
            const party = parties.find( p => p.id === mediator) 
            originLabel = party.name
          }
          else {
            originLabel = "prospect.other_mediators"
          }
        }
        
        const obj = {
          area,
          status,
          allOrigin: allOrigin || originLabel,
          activeOrigin: "prospect.other_mediators",
          inactiveOrigin: "prospect.other_mediators",
          closedOrigin: "prospect.other_mediators",
          awaitingAllocationOrigin: "prospect.other_mediators",
        }

        if(status === "Active"){
          obj.activeOrigin = originLabel
        }
        else if(status === "Inactive"){
          obj.inactiveOrigin = originLabel
        }
        else if(status === "Closed"){
          obj.closedOrigin = originLabel
        }
        else if(status === "AwaitingAllocation"){
          obj.awaitingAllocationOrigin = originLabel
        }

        return obj
      })
 
      return data
    },
    graphClick (chartElement, widgetId) {
      if (widgetId === 'leasing.biggest_active_prospects') {
        const index = chartElement[0]._index
        const datasetIndex = chartElement[0]._datasetIndex
        const prospectId = chartElement[0]._chart.data.datasets[datasetIndex]?.ids[index]
        if (prospectId) {
           this.changeProspectModalState(prospectId)
        }
      }
    }
  }
}
</script>


<style scoped>
.type-select {
  width: 500px;
}
</style>