<template>
  <div>
    <AriaLive />
    <BaseModal
      @cancel="cancel"
    >
      <template #title>
        {{ $t('Create new unit') }}
      </template>
      <template #content>
        <v-row
          align="center"
          justify="space-between"
        >
          <v-row
            class="align-stretch flex-column"
            justify="space-around"
          >
            <v-row
              align="center"
              justify="space-around"
            >
              <v-col style="text-align: center">
                <h2 class="text--secondary">
                  {{ $t('Spaces to add') }}
                </h2>
              </v-col>
            </v-row>
            <v-row
              v-for="unit in getConnectedUnits()"
              :key="unit.id"
              align="center"
              justify="space-around"
            >
              <v-card
                width="100%"
                class="mx-auto info-card"
                height="100%"
              >
                <v-card-title>
                  <v-icon
                    large
                    left
                  >
                    view_compact
                  </v-icon>
                  <span class="text-h6">{{ unit.unit_code }}</span>
                </v-card-title>
                <div
                  v-for="link in linksByUnit(unit.id)"
                  :key="link.id"
                >
                  <v-card-title>link.id</v-card-title>
                </div>
                <v-card-text>
                  <v-list
                    dense
                    class="info-box"
                  >
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Spaces lost from unit') }}
                      </v-list-item-content>
                    </v-list-item>
                    <table class="v-datatable v-table them--light">
                      <thead>
                        <th class="smallColumn">
                          {{ $t('Space name') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Purpose of use') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Unit area') }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="space in getSpacesByUnit(unit.id)"
                          :key="space.id"
                        >
                          <td>{{ space.name }}</td>
                          <td>{{ space.purpose_of_use }}</td>
                          <td>{{ space.unit_area + ' m²' }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{
                          $t('Change in unit area')
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="tile-content">
                        <span>
                          {{ unit.area + ' m²' }}
                          <v-icon small>arrow_forward_ios</v-icon>
                          {{ getNewAreaForUnit(unit.id) + ' m²' }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="doesUnitLoseAllSpaces(unit.id)"
                      class="tile"
                    >
                      <v-list-item-content class="tile-content">
                        <span>
                          <v-icon color="red">error_outline</v-icon>
                          {{ $t('Unit will be removed') }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="doesUnitHaveActiveTenant(unit.id)"
                      class="tile"
                    >
                      <v-list-item-content
                        class="tile-content"
                        style="background-color: lightpink"
                      >
                        <span>
                          <v-icon color="red">error_outline</v-icon>
                          {{ $t('Unit has a tenant and cannot lose spaces!') }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <div />
            </v-row>
            <!-- SPACES WITHOUT UNITS -->
            <v-row
              v-if="getSpacesByUnit(null).length > 0"
              align="center"
              justify="space-around"
            >
              <v-card
                width="100%"
                class="mx-auto info-card"
                height="100%"
              >
                <v-card-title>
                  <v-icon
                    large
                    left
                  >
                    meeting_room
                  </v-icon>
                  <span class="text-h6">{{ $t('Spaces without unit') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-list
                    dense
                    class="info-box"
                  >
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Spaces taken') }}
                      </v-list-item-content>
                    </v-list-item>
                    <table class="v-datatable v-table them--light">
                      <thead>
                        <th class="smallColumn">
                          {{ $t('Space name') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Purpose of use') }}
                        </th>
                        <th class="smallColumn">
                          {{ $t('Unit area') }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="space in getSpacesByUnit(null)"
                          :key="space.id"
                        >
                          <td>{{ space.name }}</td>
                          <td>{{ space.purpose_of_use }}</td>
                          <td>{{ space.unit_area + ' m²' }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <v-list-item class="tile">
                      <v-list-item-content class="tile-content">
                        {{ $t('Unit area') }}
                      </v-list-item-content>
                      <v-list-item-content class="tile-content">
                        {{
                          getSpacesByUnit(null).reduce(
                            (acc, space) => acc + space.unit_area,
                            0
                          ) + ' m²'
                        }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-row>
          </v-row>
          <v-row
            align="center"
            justify="space-around"
            class="flex-column"
          >
            <v-icon size="64">
              arrow_forward
            </v-icon>
          </v-row>
          <v-row
            align="center"
            justify="space-around"
            class="flex-column"
          >
            <v-row
              align="center"
              justify="start"
            >
              <v-col style="text-align: center">
                <h2 class="text--secondary">
                  {{ $t('New unit') }}
                </h2>
              </v-col>
            </v-row>
            <v-card
              width="100%"
              class="mx-auto info-card"
              height="100%"
            >
              <v-card-title>
                <v-icon
                  large
                  left
                >
                  view_compact
                </v-icon>
                <span
                  class="text-h6"
                  width="100%"
                >
                  <!-- This is disabled because adding custom name was not possible before (bugged) -->
                  <!-- and it might be risky to open that possibility. Remove disabled to enable name editing  -->                  
                  <v-text-field
                    v-model="newUnitName"
                    :disabled="true"
                  />
                </span>
              </v-card-title>
              <v-card-text>
                <v-list
                  dense
                  class="info-box"
                >
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{ $t('Spaces for new unit') }}
                    </v-list-item-content>
                  </v-list-item>
                  <table class="v-datatable v-table them--light">
                    <thead>
                      <th class="smallColumn">
                        {{ $t('Space name') }}
                      </th>
                      <th class="smallColumn">
                        {{ $t('Purpose of use') }}
                      </th>
                      <th class="smallColumn">
                        {{ $t('Unit area') }}
                      </th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="space in selectedSpaces"
                        :key="space.id"
                      >
                        <td>{{ space.name }}</td>
                        <td>{{ space.purpose_of_use }}</td>
                        <td>{{ space.unit_area + ' m²' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{ $t('Unit area') }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      {{
                        newUnitArea + ' m²'
                      }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{
                        $t('VAT')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-select
                        v-model="newUnitVAT"
                        :items="[
                          { text: $t('Yes'), value: true },
                          { text: $t('No'), value: false },
                        ]"
                        small
                        item-text="text"
                        item-value="value"
                        class="borderless"
                        append-outer-icon="edit"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Tenant')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-autocomplete
                        v-model="newUnitTenant"
                        :items="parties"
                        :placeholder="$t('Select')"
                        small
                        item-text="name"
                        item-value="id"
                        class="borderless"
                        clearable
                        append-outer-icon="edit"
                        menu-props="offsetY"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Start date')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-text-field
                        v-model="newUnitStartDate"
                        class="borderless"
                        append-icon="edit"
                        type="date"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Rental end date')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-text-field
                        v-model="newUnitEndDate"
                        class="borderless"
                        clearable
                        append-icon="edit"
                        type="date"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-row>
        </v-row>
      </template>
      <template #footer>
        <template v-if="isDisabled || endDateIsWrong || startDateIsWrong">
          <v-btn
            :disabled="true"
            color="primary"
            text
            rounded
            outlined
          >
            {{ $t('Create new unit') }}
            <v-progress-circular
              v-if="saving"
              size="16"
              indeterminate
            />
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            color="success"
            text
            class="primary"
            rounded
            @click="createUnit()"
          >
            {{ $t('Create new unit') }}
          </v-btn>
        </template>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import BaseModal from '../../components/general/BaseModal.vue'
import AriaLive from '../../components/AriaLive.vue'
export default {
  name: 'UnitManageForm',
  components: { BaseModal, AriaLive },
  props: {
    unitManageDialog: Boolean,
    unit: {
      type: Object,
      default: null
    },
    units: {
      type: Array,
      default: undefined
    },
    spaces: {
      type: Array,
      default: undefined
    },
    selectedSpaces: {
      type: Array,
      default: undefined
    },
    floor: {
      type: Object,
      default: null
    },
    partyLinks: {
      type: Array,
      default: undefined
    },
    parties: {
      type: Array,
      default: undefined
    },
    newUnitPlaceholderName: {
      type: String,
      default: undefined
    },
  },
  data () {
    return {
      saving: false,
      newUnitName: this.newUnitPlaceholderName,
      newUnitTenant: null,
      newUnitStartDate: new Date(this.$store.state.app.currentDate).toISOString().slice(0, 10),
      newUnitEndDate: '',
      newUnitVAT: true
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById', 'definitionByLabel', 'hasApplicationPermissionByName']),
    ...mapState('app', ['currentDate', 'settings']),
    isDisabled () {
      if (this.saving) {
        return true
      }
      const units = this.getConnectedUnits()
      for (const i of units) {
        if (this.doesUnitHaveActiveTenant(i.id) === true) {
          return true
        }
      }
      return false
    },
    newUnitArea () {
      return this.selectedSpaces
        .map(s => Number(s.unit_area))
        .reduce((acc, cur) => acc + cur, 0)
        .toFixed(1)
    },
    endDateIsWrong () {
      return this.newUnitEndDate !== null &&  this.newUnitEndDate != ''  && this.newUnitStartDate >= this.newUnitEndDate
    },
    startDateIsWrong () {
      return this.newUnitStartDate !== null && this.newUnitStartDate !== '' && this.newUnitStartDate < new Date(this.$store.state.app.currentDate).toISOString().slice(0,10) 
    }
  },
  watch: {
    newUnitEndDate: function (value) {
      if (value !== null) {
        if (this.newUnitStartDate >= value && value !== '') {
          alert(
            'Päättymispäivä ei voi olla sama tai aikaisempi kuin aloituspäivä'
          )
        }
      }
    },
    newUnitStartDate: function (value) {
      if (value !== null) {
        if (value < new Date(this.$store.state.app.currentDate).toISOString().slice(0, 10) && value !== '') {
          alert('Huoneiston aloituspäivä ei voi olla aikaisempi kuin nykypäivä')
        }
      }
    },
    newUnitPlaceholderName: function (value) {
       this.newUnitName = value
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
    },
    linksByUnit (id) {
      const unitLinks = this.partyLinks.filter(link => link.id_parent === id)
      unitLinks.forEach(link => {
        link.party = this.parties.find(party => party.id === link.id_party)
      })
      return unitLinks
    },
    doesUnitHaveActiveTenant (id) {
      return this.partyLinks.some(link => link.id_parent === id)
    },

    doesUnitLoseAllSpaces (id) {
      if (
        this.getSpacesByUnit(id).length === this.getAllSpacesByUnit(id).length
      ) {
        return true
      } else {
        return false
      }
    },
    getNewAreaForUnit (id) {
      const currentArea = this.units.find(unit => unit.id === id).area
      const lostArea = this.getSpacesByUnit(id)
        .map(s => Number(s.unit_area))
        .reduce((acc, cur) => acc + cur, 0)
      return (currentArea - lostArea).toFixed(1)
    },
    getConnectedUnits () {
      return this.units.filter(unit => {
        return this.selectedSpaces.find(space => space.id_unit === unit.id)
      })
    },
    getSpacesByUnit (id) {
      return this.selectedSpaces.filter(space => space.id_unit === id)
    },
    getAllSpacesByUnit (id) {
      return this.spaces.filter(space => space.id_unit === id)
    },
    async createUnit () {
      // Disallow multiple clicks
      this.saving = true
      var name = this.newUnitName === '' ? this.newUnitPlaceholderName : this.newUnitName
      // Post new unit
      const unitData = {
        id: null,
        unit_code: name,
        version: null,
        area: this.newUnitArea,
        name: name,
        floor_id: this.floor.id,
        start_date: this.newUnitStartDate,
        not_rentable: this.definitionByLabel(this.settings?.defaultNotRentableStatus ?? 'rentable').id,
        spaces: this.selectedSpaces.map(space => space.id)
      }
      
      // Patch units that lose some space
      const unitsThatLoseSpace = this.getConnectedUnits().filter(
        unit => !this.doesUnitLoseAllSpaces(unit.id)
      )
      for (const unit of unitsThatLoseSpace) {
        // Calculate new area for unit
        const patch = {
          op: 'replace',
          path: '/area',
          value: this.getNewAreaForUnit(unit.id)
        }
        await this.$rambollfmapi.units.patch(unit.id, this.newUnitStartDate, [
          patch
        ])
      }
      const newUnit = await this.$rambollfmapi.units.post(unitData)
      this.newUnitName = ''

      await this.$rambollfmapi.units.assignSpaces(newUnit.id, unitData.spaces, this.currentDate)

      if (this.newUnitTenant) {
        let endDate = null

        if (this.newUnitEndDate !== '' && this.newUnitEndDate !== null) {
          endDate = new Date(this.newUnitEndDate)
          endDate.setDate(endDate.getDate() + 1)
          endDate.toISOString().slice(0, 10)
        }

        const newLink = {
          id: 0,
          id_party: this.newUnitTenant,
          id_annotation: this.definitionByLabel('Tenant').id,
          start_date: this.newUnitStartDate,
          end_date: endDate,
          group: 3,
          order: 0
        }
        await this.$rambollfmapi.units
          .partyLinks(newUnit.id)
          .post(newUnit.id, newLink)
      }

      // Delete units that lose all spaces
      // Moved later in handeling so that it won't cause errors with space-unit relation patches
      const unitsToDismantle = this.getConnectedUnits().filter(unit =>
        this.doesUnitLoseAllSpaces(unit.id)
      )
      for (const unit of unitsToDismantle) {
        await this.$rambollfmapi.units.dismantle(unit.id, this.currentDate)
      }
      this.saving = false
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
      this.$emit('updated')
      this.$emit('close')
    },
    async dismantleUnit () {
      await this.$rambollfmapi.units.dismantle(this.unit.id)
      this.$emit('updated')
    },
    async validate () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.smallColumn {
  padding: 5px;
}
.v-datatable tbody tr td v-text-field {
  width: 10px;
}
.info-card >>> .v-card__title {
  padding: 0 1em;
}
.info-card >>> .v-card__text {
  padding: 0.2em 1em;
}
.info-card {
  margin: 1em;
}
.v-list__tile__content >>> .first-tile-content {
  border-top-left-radius: 2em !important;
  border-top-right-radius: 2em !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
