export default function UnitChangesIgnoredWidget () {
  return {
    id: "dataimport.new_contracts",
    type: "ObjectTable",
    data: {
      headers: [{ text: "Contract number", value: "contractNumber" }],
      items: [],
    },
    maximized: [false],
    loading: false,
    dataFetcher: (context) => {
      if (context.newContracts) {
        return context.newContracts
      }

      return []
    },
    shouldShow: (context) => {
      return context.newContracts && context.newContracts.length
    },
  }
}
