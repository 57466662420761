<template>
  <v-form
    ref="photoUploadForm"
  >
    <BaseModal
      @cancel="cancel"
    >
      <template #title>
        {{ editing ? $t('photos.edit_photo') : $t('Add new photo') }}
      </template>
      <template #content>
        <v-card-text
          v-if="isUploading || hasError"
          style="align-items: center; justify-content: center"
        >
          <v-alert
            :value="hasError"
            type="error"
            dismissible
          >
            {{
              hasError
            }}
          </v-alert>
          <div
            v-show="isUploading"
            style="text-align: center"
          >
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="uploadProgressPercentage"
              :indeterminate="uploadProgressPercentage === 100"
              color="primary"
            >
              {{ uploadProgressPercentage }}
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-card
          v-if="!isUploading"
          elevation="0"
        >
          <v-text-field
            v-if="editing"
            v-model="photoUpload.fileName"
            class="form-field"
            :label="$t('photos.photo_filename')"
            :suffix="fileSuffix"
            :rules="[rules.fileName]"
          />
          <input
            v-if="!editing"
            ref="photoInput"
            type="file"
            style="display: none"
            accept=".jpeg, .jpg, .tif, .gif, .png, .bmp"
            @change="onPhotoPicked"
          >
          <v-text-field
            v-if="!editing"
            :label="$t('Photo to be loaded')"
            :value="rawPhotoName"
            required
            :placeholder="$t('Click here to select file from disk')"
            :hint="$t('Required')"
            persistent-hint
            @click="onPickPhoto"
          />
          <v-select
            v-model="photoUpload.photoCategoryId"
            class="form-field"
            :label="$t('Choose section')"
            :items="photoCategories"
            item-text="label"
            item-value="id"
            :rules="[rules.photoCategory]"
            :hint="$t('Required')"
            persistent-hint
            required
          />
          <v-text-field
            v-model="photoUpload.appendDate"
            class="form-field"
            :label="$t('Date')"
            type="date"
          />
          <v-select
            v-model="photoUpload.photoTypeId"
            class="form-field"
            :label="$t('Photo type')"
            :items="photoTypes"
            item-text="label"
            item-value="id"
          />
          <v-text-field
            v-model="photoUpload.author"
            class="form-field"
            :label="$t('Photographer or author')"
          />
          <v-select
            v-model="photoUpload.photoAccessRightId"
            class="form-field"
            :label="$t('User right')"
            :items="accessRights"
            item-text="label"
            item-value="id"
          />
          <v-combobox
            v-model="photoUpload.partyId"
            class="form-field"
            :label="$t('Tenant')"
            :items="parties"
            item-text="name"
            item-value="id"
          />
          <v-select
            v-if="cemeteriesForCurrentSite.length > 0"
            v-model="selectedOption"
            class="form-field"
            :items="[$t('Site'),$t('Cemetery')]"
            :label="siteCemeteryLabel"
          />
          <div v-if="selectedOption !== $t('Cemetery')">
            <v-text-field
              v-model="currentSite.name"
              class="form-field"
              :label="$t('Site')"
              readonly
              required
            />
            <v-select
              v-model="photoUpload.idBuilding"
              :disabled="buildingsForCurrentSite.length === 0"
              clearable
              class="form-field"
              :label="$t('Building')"
              hide-details
              item-text="building_name"
              item-value="id_building"
              :items="buildingsForCurrentSite"
            />
            <v-select
              v-model="photoUpload.idFloor"
              :disabled="!photoUpload.idBuilding || floorsForCurrentBuilding === undefined || floorsForCurrentBuilding.length === 0"
              clearable
              class="form-field"
              :label="$t('Floor')"
              hide-details
              item-text="floor_name"
              item-value="id"
              :items="floorsForCurrentBuilding"
            />
            <v-select
              v-model="photoUpload.idUnit"
              :disabled="!photoUpload.idFloor || unitsForCurrentBuildingFloor === undefined || unitsForCurrentBuildingFloor.length === 0 || data.toMainImage || data.moodImage || data.logoImage"
              clearable
              class="form-field"
              :label="$t('Unit')"
              hide-details
              item-text="unit_code"
              item-value="id"
              :items="unitsForCurrentBuildingFloor"
            />
          </div>
          <div v-if="selectedOption === $t('Cemetery')"> 
            <v-select
              v-model="photoUpload.idCemetery"
              :disabled="cemeteriesForCurrentSite.length === 0"
              clearable
              class="form-field"
              :label="$t('Cemetery')"
              hide-details
              item-text="name"
              item-value="id"
              :items="cemeteriesForCurrentSite"
            />
            <v-select
              v-model="photoUpload.idSection"
              :disabled="!photoUpload.idCemetery || cemeteriesForCurrentSite.length === 0"
              clearable
              class="form-field"
              :label="$t('Graveyard section')"
              hide-details
              item-text="name"
              item-value="id"
              :items="sectionsForCurrentCemetery"
            />
            <v-select
              v-model="photoUpload.idGrave"
              :disabled="!photoUpload.idCemetery || gravesForCurrentSection === undefined || gravesForCurrentSection.length === 0"
              clearable
              class="form-field"
              :label="$t('Grave')"
              hide-details
              item-text="graveIdentifier"
              item-value="id"
              :items="gravesForCurrentSection"
            />
          </div>
          <v-combobox
            v-model="photoUpload.tags"
            class="form-field"
            multiple
            chips
            :label="$t('Tags')"
            deletable-chips
            :hide-details="true"
            item-text="name"
            item-value="name"
            return-object
            :items="tags"
          />
          <v-textarea
            v-model="photoUpload.alternativeText"
            class="form-field"
            :label="$t('AltText')"
            rows="3"
            counter
            :rules="[rules.counter]"
          >
            <template #append>
              <InfoBall
                :info="$t('AltTextInfo')"
                left
              />
            </template>
          </v-textarea>
          <v-textarea
            v-model="photoUpload.additionalInformation"
            class="form-field"
            :label="$t('Extra info')"
          />
        </v-card>
      </template>
      <template #footer>
        <v-btn
          :disabled="isUploading || formUncompleted || (hasError && hasError.length > 0)"
          rounded
          depressed
          color="primary"
          @click="uploadPhoto"
        >
          {{ $t('Save') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-form>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import humanize from '../helpers/humanize'
import moment from 'moment'
import BaseModal from './general/BaseModal'
import InfoBall from './InfoBall'
export default {
  name: 'PhotoUploadForm',
        components: { BaseModal, InfoBall },
  props: {
    data: {
      type: Object,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    },
    isInSite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rawPhoto: '',
      photoUpload: {
        fileName: '',
        photoCategoryId: '',
        appendDate: moment().format('YYYY-MM-DD'),
        photoTypeId: null,
        photoAccessRightId: null,
        partyId: null,
        idBuilding: null,
        idFloor: null,
        idUnit: null,
        tags: [],
        additionalInformation: '',
        alternativeText: '',
        author: '',
        quality: 'normal',
        idCemetery: null,
        idSection: null,
        idGrave: null,
        size: null,
      },
      selectedOption: null,
      dateSelection: false,
      isUploading: false,
      uploadDate: null,
      uploadProgressPercentage: 0,
      parties: [],
      fileSuffix: null,
      rules: {
        photoCategory: sectionId => {
          const sectionIdFound = sectionId ? this.photoCategories.find(section => section.id === sectionId) : false
          if(sectionIdFound) {
            return true
          } else {
            return this.$t('Select value from list')
          }
        },
        photoType: typeId => {
          const typeIdFound = typeId ? this.photoTypes.find(type => type.id === typeId) : false
          if(typeIdFound) {
            return true
          } else {
            return this.$t('Select value from list')
          }
        },
        accessRight: accessRightId => {
          const accessRightIdFound = accessRightId ? this.accessRights.find(accessRight => accessRight.id === accessRightId) : false
          if(accessRightIdFound) {
            return true
          } else {
            return this.$t('Select value from list')
          }
        },
        fileName: name => {
          // forbidden characters \ / : * ? " < > |
          const testChars =/^[^\\/:*?"<>|]+$/ 
          // cannot start with dot (.)
          const testDot=/^\./ 
          if(testChars.test(name)&&!testDot.test(name)){
            return true
          } else {
            return this.$t('Unallowed type')
          }
        },
        counter: value => {
          if(value === null || value.length <= 140) {
            return true
          }
          else {
            return this.$t('AltTextMaxLengthWarning')
          }
        }
      },
      hasError: null,
      initEditing: false,
      carbageSectionId: null,
      damagesSectionId: null,
      sections: []
    }
  },
  computed: {
    ...mapState('app', ['tags', 'currentDate', 'units', 'availableSiteParties', 'photoMetadata']),
    ...mapGetters('app', ['definitionsByGroupId']),
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite', 'floorsForCurrentBuilding', 'unitsForCurrentBuildingFloor', 'cemeteriesForCurrentSite', 'sectionsForCurrentCemetery', 'gravesForCurrentSection']),
    rawPhotoName () {
      if (this.rawPhoto !== null) {
        return this.rawPhoto.name
      } else {
        return ''
      }
    },
    siteCemeteryLabel () {
      return this.$t('Site'),'/',this.$t('Cemetery')
    },
    photoAppendDateAsString () {
      const currentDate = this.photoUpload.appendDate ?  new Date(this.photoUpload.appendDate) : new Date()
      const date = humanize.date(currentDate.toISOString(), 'date')
      return date
    },
    photoCategories () {
      if( this.photoMetadata.photo_category != null) {
        // define the wanted custom section order
        const sortOrder = ['General','Marketing','Visualization material','Damages','Trash']

        const photoCategoryId = this.photoMetadata.photo_category.definitionGroupId
        let photoSections =  this.definitionsByGroupId(photoCategoryId)
        .sort((a,b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label)) // sort the sections by custom section order
        .map(section => { return {...section, label: this.$t(section.label)}})
        
        if(this.isInSite) {
          photoSections = photoSections.filter(section => section.label !== this.$t('Trash') && section.label !== this.$t('Damages'))
        }
        return photoSections
      } else {
        return null
      }
    },
    photoTypes () {
      if( this.photoMetadata.photo_category != null){
        const photoTypeId = this.photoMetadata.photo_type.definitionGroupId
        const photoTypes = this.definitionsByGroupId(photoTypeId).map(photoType => { return {...photoType, label: this.$t(photoType.label)}})
        return photoTypes
      } else {
        return null
      }
    },
    accessRights () {
      if( this.photoMetadata.photo_access_right != null){
        const accessRightId = this.photoMetadata.photo_access_right.definitionGroupId
        const accessRights = this.definitionsByGroupId(accessRightId).map(accessRight => { return {...accessRight, label: this.$t(accessRight.label)}})
        return accessRights
      }else{
        return null
      }
    },
    formUncompleted () {
      // add more conditions if needed
      const {photoCategoryId, fileName} = this.photoUpload      
      if(fileName && photoCategoryId) {
        return false
      }
     return true
    }
  },
  watch: {
    'photoUpload.idBuilding': async function (selectedBuildingId) {
      if(!selectedBuildingId) {
        this.photoUpload.idFloor = null
        this.photoUpload.idUnit = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
      } else if (selectedBuildingId && !this.initEditing) {
        this.photoUpload.idFloor = null
        this.photoUpload.idUnit = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
          
        const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === selectedBuildingId).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
      }
    },
      'photoUpload.idFloor': function (selectedFloorId) {
      if(!selectedFloorId) {
        this.photoUpload.idUnit = null
        this.setUnitsForCurrentBuildingFloor([])
      } else if (selectedFloorId && !this.initEditing) {
        this.photoUpload.idUnit = null
        this.setUnitsForCurrentBuildingFloor([])
        this.loadUnitsForCurrentBuildingFloor(selectedFloorId)
      }
    },
      'photoUpload.idCemetery': async function (selectedCemeteryId) {
      if(!selectedCemeteryId) {
        this.photoUpload.idSection = null
        this.setSectionsForCurrentCemetery([])
        this.setGravesForCurrentSection([])
      } else if (selectedCemeteryId && !this.initEditing) {
        this.photoUpload.idSection = null
        this.setSectionsForCurrentCemetery([])
        this.setGravesForCurrentSection([])

        const cemeteryId = this.cemeteriesForCurrentSite.find(cemetery => cemetery.id === selectedCemeteryId)
        const params = { time: this.currentDate, cemeteryId: cemeteryId }
        await this.loadSectionsForCurrentCemetery(params)
      }
    },
      'photoUpload.idSection': async function (selectedSectionId) {
         
      if (!selectedSectionId) {
        this.photoUpload.idGrave = null
        this.setGravesForCurrentSection([])
      } else if (selectedSectionId && !this.initEditing) {
        this.photoUpload.idGrave = null
        this.setGravesForCurrentSection([])

        await this.loadGravesForCurrentSection(selectedSectionId)
      }
    },
      'photoUpload.idGrave': function (selectedGraveId) {
        if (selectedGraveId && !this.initEditing) {
        this.gravesForCurrentSection.find(section => section.id === selectedGraveId)
      }
    },
      selectedOption: function (selectedOption) {
        if (selectedOption == this.$t('Site'))
        this.photoUpload.idCemetery = null
        else if (selectedOption == this.$t('Cemetery'))
        this.photoUpload.idBuilding = null
    }
  },
  async mounted () {
    const parties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })
    this.parties = parties.filter(party => party.type === 0 | party.type === 1) // filter parties that type is either company or person
    if(this.editing) { 
      this.initEditing = true
      const {additionalInformation, alternativeText, author, filename, idBuilding, idFloor, idParty, idUnit, tags, uploaded, idCemetery, idSection, idGrave, selectedOption } = this.data      
      const tempFile = filename.split('.')
      this.fileSuffix = "." + tempFile.pop()
      const partyName = idParty ? this.parties.find(party => party.id === idParty).name : null 
      this.photoUpload = {
        fileName: tempFile.join('.'),
        photoCategoryId: this.data.photo_category,
        photoTypeId: this.data.photo_type,
        photoAccessRightId: this.data.photo_access_right,
        partyId: { id: idParty, name: partyName },
        idBuilding,
        idFloor,
        idUnit,
        tags,
        additionalInformation,
        alternativeText,
        author,
        appendDate: moment(uploaded).format('YYYY-MM-DD'),
        idCemetery,
        idSection,
        idGrave,
        selectedOption
      }
      if(idBuilding) {
        const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === idBuilding).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
      }
      if(idFloor) {
        await this.loadUnitsForCurrentBuildingFloor(idFloor)
      }
      if (idCemetery) {
        const cemeteryId = this.cemeteriesForCurrentSite.find(cemetery => cemetery.id === idCemetery)
        const params = { time: this.currentDate, cemeteryId: cemeteryId }
        await this.loadSectionsForCurrentCemetery(params)
        this.selectedOption = this.$t('Cemetery')
      }
        if (idSection) {
        await this.loadGravesForCurrentSection(idSection)
      }
    }
    
    if(!this.editing && this.cemeteriesForCurrentSite.length > 0) {
      this.selectedOption = this.$t('Cemetery')
      this.photoUpload.idCemetery = this.cemeteriesForCurrentSite[0].id
    }
    this.initEditing = false
    // find section ids for carbage and damages sections
    this.carbageSectionId = this.photoCategories.find(section => section.label === this.$t('Trash')).id
    this.damagesSectionId = this.photoCategories.find(section => section.label === this.$t('Damages')).id
 },
  methods: {
    ...mapActions('app', ['saveNewPhoto', 'getTags']),
      ...mapActions('sites', ['loadFloorsForCurrentBuilding', 'loadUnitsForCurrentBuildingFloor', 'loadSectionsForCurrentCemetery', 'loadGravesForCurrentSection']),
      ...mapMutations('sites', ['setFloorsForCurrentBuilding', 'setUnitsForCurrentBuildingFloor', 'setSectionsForCurrentCemetery', 'setGravesForCurrentSection']),
    cancel () {
       this.$emit('close')
    },
    onPickPhoto () {
      this.hasError = null
      this.$refs.photoInput.click()      
    },
    onPhotoPicked (event) {
      // uploads only one file at a time
      const files = event.target.files
      if(!files[0]) {
        this.rawPhoto = null
        return
      }
      this.rawPhoto = files[0]
      if (this.rawPhoto.size > 576716800) {
        this.hasError =
          this.$t('File is too large. Maximum size is:') + ' 550 MB'
        this.rawPhoto = null
      } else {
        this.photoUpload.fileName = this.rawPhoto.name
        this.photoUpload.size = this.rawPhoto.size
        let quality = "high"
        const sizeMt = Math.round(this.rawPhoto.size / 1024 / 1024)
        if(sizeMt <= 2) {
          quality = "low"
        }
        if(sizeMt > 2 && sizeMt <= 5) {
          quality = "normal"
        }
        this.photoUpload.quality = quality
      }
    },
    resetForm () {
      this.isUploading = false
      this.photoUpload = {
        fileName: '',
        photoCategoryId: '',
        appendDate: moment().format('YYYY-MM-DD'),
        photoTypeId: null,
        photoAccessRightId: null,
        partyId: null,
        idBuilding: null,
        idFloor: null,
        idUnit: null,
        tags: [],
        additionalInformation: '',
        alternativeText: '',
        author: '',
        uploaded: '',
        quality: 'normal',
        size: null,
        idCemetery: null,
        idSection: null,
        idGrave: null,
        sections: []
      }
      this.fileSuffix = null
      this.hasError = null
      this.uploadProgressPercentage = 0
      this.$emit('close')  
    }, 
    getCemeteryName () {
      if (this.cemeteriesForCurrentSite.length === 1){
        return this.cemeteriesForCurrentSite[0].name
      }
    },
    async uploadPhoto () {
       this.isUploading = true
      // If form is valid upload can be made
      if(this.$refs.photoUploadForm.validate()) {
        const {photoAccessRightId, appendDate, author, idBuilding, buildingName, fileName, idFloor, floorName, additionalInformation, alternativeText, partyId, photoCategoryId, photoTypeId, quality, tags, size, idUnit, unitName, idCemetery, idSection, idGrave} = this.photoUpload
        let newTags = false

        const tagsForPhoto = tags.map(t => {
        if (typeof t === 'string') {
          newTags = true
          return {
            description: null,
            idTag: null,
            name: t
          }
        }
        return t
      })

        if(this.editing) {
          const prevPhotoCategoryId = this.data.photo_category
          const photo = {...this.data}
          photo.additionalInformation = additionalInformation
          photo.alternativeText = alternativeText
          photo.author = author
          photo.idBuilding = idBuilding
          photo.buildingName = idBuilding ? buildingName : null
          photo.filename = fileName + this.fileSuffix
          photo.idFloor = idFloor
          photo.floorName = idFloor ? floorName : null
          photo.idParty = partyId ? partyId.id : null
          photo.idUnit = idUnit
          photo.unitName = idUnit ? unitName : null
          photo.tags = tagsForPhoto || []
          photo.photo_category = photoCategoryId,
          photo.photo_access_right = photoAccessRightId,
          photo.photo_type = photoTypeId,
          photo.uploaded = moment(appendDate).format('YYYY-MM-DD'),
          photo.idCemetery = idCemetery
          photo.idSection = idSection
          photo.idGrave = idGrave
          // Remove photo from Site gallery carousel, rental portal and unit brochure if moved to carbage or damages category
          if(photo.photo_category === this.carbageSectionId || photo.photo_category === this.damagesSectionId) {
            photo.inSite = false
            photo.toMainImage = false
            photo.toRentalPortal = false
            photo.unitMainImage = false
            photo.unitMoodImage = false
            photo.toOutputImage1 = false
            photo.toOutputImage2 = false
          }
          const differentCategory = prevPhotoCategoryId !== photo.photo_category

          try {
            const result = await this.$rambollfmapi.photos.put(photo.idPhoto, photo)
            if(newTags) {
              this.getTags()
            }        
            this.$emit('updatePhotoList', result, differentCategory)
          } catch (err) {
            this.hasError = this.$t('Upload failed')
            return 
          } 
        } else {    
          const newPhoto = {
            additionalInformation: additionalInformation || "",
            alternativeText: alternativeText || "",
            idKohde: this.currentSite.id_site,
            uploaded: appendDate ? moment(appendDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            tags: tagsForPhoto,
            photo_category: photoCategoryId,
            photo_type: photoTypeId,
            author,
            photo_access_right: photoAccessRightId,
            idParty: partyId ? partyId.id : null,
            idBuilding,
            idFloor,
            idUnit,
            inSite: this.isInSite,
            filename: fileName,
            size,
            quality,
            idCemetery,
            idSection: idSection,
            idGrave
          }
          const result = await this.$rambollfmapi.photos.post(newPhoto)
          
          
          if(typeof this.rawPhoto !== 'undefined' && result) {
              try {
                const uploaded = await this.$rambollfmapi.photos.upload(
                        result.idPhoto,
                        this.rawPhoto,
                        progress => {
                          this.uploadProgressPercentage = progress.percentage
                        }
                      )
                if (uploaded.name === 'Error') {
                  if(uploaded.response && uploaded.response.data.indexOf('not allowed') >= 0) {
                    const error = new Error(uploaded.response.data)
                    error.name = 'UnallowedTypeError'
                    throw error
                  } else if (uploaded.response && uploaded.response.data.indexOf('Invalid file signature') >= 0) {
                    const error = new Error(uploaded.response.data)
                    error.name = 'InvalidSignatureError'
                    throw error
                  } else {
                    throw new Error('Error with Axios')
                  }
                }
                if (newTags) {
                  this.getTags()
                } 
                this.$emit('updatePhotoList', result)
              } catch (err) {
                await this.$rambollfmapi.photos.delete(result.idPhoto)
                this.hasError = this.$t('Upload failed')
                if (err.name === 'UnallowedTypeError' || err.name === 'InvalidSignatureError') {
                  this.hasError += ': ' + this.$t('Unallowed type')
                }
                this.rawPhoto = ''
                this.photoUpload.fileName = ''
                this.isUploading = false
                return 
              }
          }
        }
        this.resetForm()
      } else {
        this.isUploading = false
      }
    }
  }
}
</script>
<style scoped>
</style>
