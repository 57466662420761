import * as THREE from 'three'

// eslint-disable-next-line no-unused-vars
import * as XML2JS from 'xml2js'

// eslint-disable-next-line no-unused-vars
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// eslint-disable-next-line no-unused-vars
import { Interaction } from '../../../node_modules/three.interaction/src/index.js'

// eslint-disable-next-line no-unused-vars
import { Text } from 'troika-three-text'

// eslint-disable-next-line no-unused-vars
import Moment from 'moment'

const VthLayer = Object.freeze({
  Space: "1",
  LoadBearing: "3",
  InteriorWall: "4",
  ExteriorWall: "5",
  Architecture: "6",
  Furniture: "7",
  Other: "8",
  LiftShaft: "9",
  OtherShaft: "11"
})

// eslint-disable-next-line no-unused-vars
class ThreeJSWrapper {
  scene = null
  camera = null
  renderer = null
  interaction = null
  orbitControls = null
  xmlObject = null
  floorData = null
  selectionCallback = null
  modelLoadedCallback = null
  selectedSpaces = []

  // Varibale for detecting mouseout events
  outsideOfScene = false
  drag = false

  spaceClearColor = null
  spaceSelectionColor = null
  multiselectEnabled = false

  resized = false
  targetDiv = null

  // This variable is supposed to keep track if something happened in the view that requires updating (selection,
  // camera movement, resizing, etc.) Not fully working yet and this is temporarily set true in every loop.
  // Getting this to work would (possibly) reduce overhead required to render the viewer contents.
  viewNeedsUpdate = true
  lastUpdated = null

  // Time to wait before next update in ms
  // Updates might be so fast that renderer doesn't have time to update before next update is fired.
  // This can cause undesirable stuttery behaviour in camera movement and uses more resources.
  // For example: 32 means that graphical updates are capped at roughly 30 updates per second.
  updateInterval = 32

  mouseState = {
    leftButton: false,
    skipNextClick: false,
    lastPosition: {
      x: undefined,
      y: undefined
    },
    dragStart: {
      x: undefined,
      y: undefined
    },
    dragEnd: {
      x: undefined,
      y: undefined
    }
  }
  lineMaterial = null

  recolorCategories = false

  viewer = document.getElementById('VTHViewerDiv')

  constructor (targetDiv, selectionCallback = null, modelLoadedCallback = null) {
    this.targetDiv = targetDiv
    this.spaceClearColor = new THREE.Color(0xeeeeee)
    this.spaceSelectionColor = new THREE.Color(0x009DF0)
    this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 })

    window.THREE = THREE
    this.selectionCallback = selectionCallback
    this.modelLoadedCallback = modelLoadedCallback

    this.scene = new THREE.Scene()

    // Create renderer
    this.renderer = new THREE.WebGLRenderer({ antialias:false, powerPreference: "high-performance" })
    this.renderer.setClearColor("#ffffff");

    console.log('*** div dimensions', this.targetDiv.clientWidth, targetDiv.clientHeight)
    this.renderer.setSize(targetDiv.clientWidth, targetDiv.clientHeight)

    this.targetDiv.appendChild(this.renderer.domElement)

    // Create camera
    let left = -targetDiv.offsetWidth / 2
    let right = targetDiv.offsetWidth / 2
    let top = -targetDiv.offsetHeight / 2
    let bottom = targetDiv.offsetHeight / 2
    this.camera = new THREE.OrthographicCamera(left, right, top, bottom, 0.01, 200000)
    this.camera.up = new THREE.Vector3(0, -1,0)

    // TODO: For IFC models 3D perspective camera should be used. There are some issues with perspective
    //       camera and current OrbitalControls settings that need to be sorted out so both perspective
    //       and orthographic camera projections work with OrbitControls at the same time.
    // this.camera = new THREE.PerspectiveCamera(50, 1, 0.01, 200000)

    this.camera.position.z = -100000

    this.camera.zoom = 0.01
    this.camera.updateProjectionMatrix();

    // Create orbit controls
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbitControls.camera = this.camera
    this.orbitControls.enableZoom = true
    this.orbitControls.zoomSpeed = 3.0
    this.orbitControls.enablePan = true
    this.orbitControls.enableRotate = false

    this.orbitControls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.ROTATE
    }
    this.orbitControls.addEventListener('change', () => {
      this.viewNeedsUpdate = true
    })

    this.viewer.addEventListener('mousedown', () => this.drag = false)
    this.viewer.addEventListener('mousemove', () => this.drag = true)

    this.viewer.addEventListener('click', () => this.checkForOutsideClick())

    this.interaction = new Interaction(this.renderer, this.scene, this.camera);

    // eslint-disable-next-line no-unused-vars
    this.scene.on('mousedown', (event) => {
      this.mouseState.leftButton = true;
      this.mouseState.dragStart.x = this.camera.position.x;
      this.mouseState.dragStart.y = this.camera.position.y;
      // console.log('*** Mouse drag started', that.mouseState)
    });

    // eslint-disable-next-line no-unused-vars
    this.scene.on('mouseup', (event) => {
      this.mouseState.leftButton = false;
      // console.log('*** Mouse drag ended', that.mouseState)
    });

    // eslint-disable-next-line no-unused-vars
    this.scene.on('mousemove', (event) => {
      this.mouseState.dragEnd.x = this.camera.position.x;
      this.mouseState.dragEnd.y = this.camera.position.y;
    });

    //eslint-disable-next-line no-unused-vars
    this.scene.on('mouseout', (event) => {
      this.outsideOfScene = true
    });

    const resizeObserver = new ResizeObserver(() => {
      this.resized = true
    });

    resizeObserver.observe(targetDiv);

    const animate = () => {
      requestAnimationFrame(animate)
      this.update()
    }

    animate()
  }

  update () {
    if (this.resized === true) {
      this.handleResize()
    }

    if (this.viewNeedsUpdate === false)
      return

    if (Moment.now() >= this.lastUpdated + this.updateInterval) {
      this.renderer.render(this.scene, this.camera)
      this.orbitControls.update()
      this.viewNeedsUpdate = false
      this.lastUpdated = Moment.now()
    }
  }

  handleResize () {
    // Create camera
    this.camera.left = -this.targetDiv.offsetWidth / 2
    this.camera.right = this.targetDiv.offsetWidth / 2
    this.camera.top = -this.targetDiv.offsetHeight / 2
    this.camera.bottom = this.targetDiv.offsetHeight / 2
    this.camera.aspect = this.targetDiv.clientWidth / this.targetDiv.clientHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.targetDiv.clientWidth, this.targetDiv.clientHeight);

    this.viewNeedsUpdate = true
    this.resized = false
  }

  async loadModel (xmlData, excludedSpaces) {
    this.xmlObject = this.createObjectFromXml(xmlData)
    await this.addObjectsToScene(this.xmlObject, excludedSpaces)

    // TODO: Figure out more robust way to trigger update after texts are loaded
    setTimeout(() => {
        this.viewNeedsUpdate = true
      },500)

    if (typeof this.modelLoadedCallback !== 'undefined') {
      this.modelLoadedCallback(this.xmlObject)
    }
  }

  removeAllModels () {
    while (this.scene.children.length)
    {
      this.scene.remove(this.scene.children[0]);
    }
  }

  enableMultiselect () {
    this.multiselectEnabled = true;
  }

  disableMultiselect () {
    this.multiselectEnabled = false;
  }

  toggleMultiselect () {
    this.multiselectEnabled = !this.multiselectEnabled
  }

  checkForOutsideClick () {
    if (!this.drag && this.outsideOfScene && this.selectedSpaces.length > 0) {
      this.clearAllSpaceSelections()
    }
  }

  selectSpace (revguid) {
    this.outsideOfScene = false
    let that = this
    this.scene.traverse(function (node) {
      if (node instanceof THREE.Mesh && 'revguid' in node.userData) {
        if (node.userData.revguid === revguid && node.userData.selected === false) {
          that.selectedSpaces.push(node)
          node.userData.selected = true
          node.userData.prevColor = node.material.color.getHex()
          node.material.color.setHex(that.spaceSelectionColor.getHex())
        }
        else if (node.userData.revguid === revguid && node.userData.selected === true) {
          let index = that.selectedSpaces.indexOf(node)
          if (index > -1) {
            that.selectedSpaces.splice(index, 1)
            that.clearSpaceSelection(revguid)
          }
        }
        else {
          return false
        }
        that.viewNeedsUpdate = true
        return true
      }
    })
  }

  clearSpaceSelection (revguid) {
    let that = this
    this.scene.traverse(function (node) {
      if (node instanceof THREE.Mesh && 'revguid' in node.userData) {
        if (node.userData.revguid === revguid && node.userData.selected === true) {
          node.userData.selected = false
          node.material.color.setHex(node.userData.prevColor)
          node.userData.prevColor = that.spaceClearColor
          that.viewNeedsUpdate = true
          return true
        }
      }
    })
    return false
  }

  clearAllSpaceSelections (clearData = true) {
    let that = this

    this.scene.traverse(function (node) {
      if (node.userData.selected === true) {
        that.clearSpaceSelection(node.userData.revguid)
      }
    })
    if (clearData) {
      this.selectedSpaces = []
      this.selectionCallback([])
      this.recolorCategories = true
    }
  }

  setSpaceColor (revguid, color) {
    let that = this
    this.scene.traverse(function (node) {
      if (node instanceof THREE.Mesh && 'revguid' in node.userData) {
        if (node.userData.revguid === revguid) {
          if (node.userData.selected === true) {
            node.userData.prevColor = node.material.color.getHex()
            return true
          }
          else {
            node.userData.prevColor = node.material.color
            node.material.color.set(color)
            that.viewNeedsUpdate = true
            return true
          }
        }
      }
    });
    return false
  }

  StringToPastelColor (inputStr) {
    var baseRed = 128
    var baseGreen = 128
    var baseBlue = 128

    // Lazy seed by XORing 3 first characters. Character codes of null and undefined tested
    // to work with XOR for cases where category value is less than 3 characters.
    var seed = inputStr.charCodeAt(0) ^ inputStr.charCodeAt(1) ^ inputStr.charCodeAt(2)
    var rand1 = Math.abs((Math.sin(seed++) * 10000)) % 256
    var rand2 = Math.abs((Math.sin(seed++) * 10000)) % 256
    var rand3 = Math.abs((Math.sin(seed++) * 10000)) % 256

    var red = Math.round((rand1 + baseRed) / 2)
    var green = Math.round((rand2 + baseGreen) / 2)
    var blue = Math.round((rand3 + baseBlue) / 2)

    return 'rgb(' + red + ', ' + green + ', ' + blue + ')'
  }

  // FIXME This is coloring function only for demoing space coloring while there isn't enough DB data
  //       Do not use!
  colorSpacesByUsage () {
    let that = this
    this.scene.traverse(function (node) {
      if ('revguid' in node.userData && 'category' in node.userData) {
        let colorString = that.StringToPastelColor(node.userData.category)
        let color = new THREE.Color(colorString)
        that.setSpaceColor(node.userData.revguid, color)
      }
    });
  }

  clearSpaceColors () {
    let that = this
    this.scene.traverse(function (node) {
      if ('revguid' in node.userData && 'category' in node.userData) {
        that.setSpaceColor(node.userData.revguid, that.spaceClearColor)
      }
    });
  }

  createObjectFromXml (xml) {
    let floorData = null
    let xmlParser = new XML2JS.Parser({
      mergeAttrs : true,
    })
    xmlParser.parseString(xml, (err, result) => {
      console.log('*** result', result)
      floorData = result.optimaze.drawing[0]
    });
    return floorData
  }

  getCommonTranslation (boundingBox) {
    let commonTranslation = {
      x: (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
      y: (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y
    }
    return commonTranslation
  }

  disableAllLayers () {
    this.camera.layers.disableAll()
    this.viewNeedsUpdate = true
  }

  enableAllLayers () {
    this.camera.layers.enableAll()
    this.viewNeedsUpdate = true
  }

  isLayerEnabled (layer) {
    let testLayers = new THREE.Layers()
    testLayers.enable(layer)
    if (this.camera.layers.test(testLayers))
      return true
    return false
  }

  /// Params: layers = array of layer numbers to enable (0-32)
  enableLayers (layers) {
    for (let layer of layers) {
      this.camera.layers.enable(layer)
    }
    this.viewNeedsUpdate = true
  }

  /// Params: layers = array of layer numbers to enable (0-32)
  disableLayers (layers) {
    for (let layer of layers) {
      this.camera.layers.disable(layer)
    }
  }

  toggleLayer (layer) {
    if (this.isLayerEnabled(layer)) {
      this.camera.layers.disable(layer)
    }
    else {
      this.camera.layers.enable(layer)
    }
    this.viewNeedsUpdate = true
  }

  async addObjectsToScene (data, excludedSpaces) {
    let commonTranslation = null

    
    // Origin point for external wall objects
    const originPointVertex = new Float32Array([0.0, 0.0, 0.0,])
    const pointGeometry = new THREE.BufferGeometry()
    const originPointMaterial = new THREE.PointsMaterial({ color: 0xff0000, opacity: 0.0, transparent: true})
    
    pointGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute(originPointVertex, 3 ))
    let ExteriorWallParentObject = new THREE.Points(pointGeometry, originPointMaterial)
    let spaceZ = 10
    
    // Get Exterior wall and handle it separately to get common translation
    let exLayer = data.layer.find(o => { return o.layer_type == VthLayer.ExteriorWall } )
    if(typeof exLayer !== 'undefined') {
      // Exterior walls
      for (let element of exLayer.elements) {
        if (typeof element.reg !== 'undefined') {
          for (let reg of element.reg) {
            const lineloop = this.createShapeLineObject(reg, 5, commonTranslation)
            ExteriorWallParentObject.add(lineloop)
          }
        }
        else {
          for (let element of exLayer.elements) {
            if (typeof element.line !== 'undefined') {
              const lineSegments = this.createLineObject(element, 5, commonTranslation)
              ExteriorWallParentObject.add(lineSegments)
            }
            if (typeof element.pline !== 'undefined') {
              for (let p of element.pline) {
                const polyline = this.createPolylineObject(p, 5, commonTranslation)
                ExteriorWallParentObject.add(polyline)
              }
            }
            if (typeof element.arc !== 'undefined') {
              for (let arc of element.arc) {
                const arcline = this.createArcObject(arc, 5, commonTranslation)
                ExteriorWallParentObject.add(arcline)
              }
            }
            if (typeof element.ell !== 'undefined') {
              for (let ell of element.ell) {
                const ellipse = this.createEllipseObject(ell, 5, commonTranslation)
                ExteriorWallParentObject.add(ellipse)
              }
            }
          }
        }
      }
      
      // TODO Better centering that includes all objects. Now only includes first one
      ExteriorWallParentObject.geometry.boundingBox = ExteriorWallParentObject.children[0].geometry.boundingBox

      

      if (commonTranslation === null) {
        commonTranslation = this.getCommonTranslation(ExteriorWallParentObject.geometry.boundingBox)
      }
      

    }
    else 
    {
      // If XML has no Exterior walls, try to determine commonTranslation via Spaces bounds
      exLayer = data.layer.find(o => { return o.layer_type == VthLayer.Space } )
      
      // First, map down the spaces and their boundary info as far as possible...
      const coordinates = exLayer.space.map((s) => {
        return s.boundary.map((el) => {
          return el.b.map((i) => {
            return i
          })
        })
      })

      // Variables to hold the coordinates...
      let xCoords = []
      let yCoords = []

      // ...then iterate the rest of subArrays via for-iteration and push coordinate values to respective arrays
      for (var i = 0; i < coordinates.length; i++) {
        for (var j = 0; j < coordinates[i].length; j++) {
          for (var d = 0; d < coordinates[i][j].length; d++) {
            xCoords.push(coordinates[i][j][d].x)
            yCoords.push(coordinates[i][j][d].y)
          }         
        }
      }

      // then get the minimun and maximun values of each coordinate...
      const xMax = Math.max(...xCoords)
      const xMin = Math.min(...xCoords)

      const yMax = Math.max(...yCoords)
      const yMin = Math.min(...yCoords)

      // ...and should commonTranslation be null, use the middlepoint between values as translation coordinates
      if (commonTranslation === null) {
        commonTranslation = {
          x: (xMin + xMax) / 2,
          y: (yMin + yMax) / 2
        }
      }

    }

    if(commonTranslation === null){
      commonTranslation = {x:0, y:0} // if all else fails, use 0,0
    }
    ExteriorWallParentObject.position.x -= commonTranslation.x
    ExteriorWallParentObject.position.y -= commonTranslation.y
    ExteriorWallParentObject.layers.disableAll()
    ExteriorWallParentObject.layers.enable(5)

    this.scene.add(ExteriorWallParentObject)

    //const mat = new THREE.LineBasicMaterial( { color: 0xff0000 })

    // Color for Furniture, Other and shaft layers demonstrations
    const furn = new THREE.LineBasicMaterial( { color: 0x007cff })
    const oth = new THREE.LineBasicMaterial( { color: 0x9e00ff })
    const liftShaft = new THREE.LineBasicMaterial( { color: 0xff0000 })
    const otherShaft = new THREE.LineBasicMaterial( { color: 0x00ff00 })

    // Handling of all other layers
    for (let layer of data.layer) {
      if (typeof layer.layer_type === 'undefined')
        continue

      if (layer.layer_type == VthLayer.Space)
      {
        if (layer.space === null || typeof layer.space === 'undefined')
          continue

        for (let space of layer.space) {
          var avgXPoints = [];
          var avgYPoints = [];

          // do not draw restricted spaces
          if (excludedSpaces.includes(space.rev_guid[0])) {
            continue
          }
          
          for (let boundary of space.boundary) {
            let shapePoints = []
            let isVoid = boundary.void[0] !== "0"

            const roomShape = new THREE.Shape()
            let first = true
            for (let bPoint of boundary.b) {
              if (first) {
                roomShape.moveTo(Number(bPoint.x[0]), Number(bPoint.y[0]), 0)
                first = false
              }
              else
                roomShape.lineTo(Number(bPoint.x[0]), Number(bPoint.y[0]), 0)
              shapePoints.push(new THREE.Vector3(Number(bPoint.x[0]), Number(bPoint.y[0]), 0))
            }

            const geometry = new THREE.ShapeGeometry(roomShape)
            geometry.translate(-commonTranslation.x, -commonTranslation.y, spaceZ)

            const mesh = new THREE.Mesh(
              geometry,
              new THREE.MeshBasicMaterial({ color: this.spaceClearColor, side: THREE.DoubleSide })
            )

            // TODO This is a temporary material with red color to indicate that something needs to be'
            //      done about void space regions
            if (isVoid) {
              //mesh.material = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide })
              continue
            }
            mesh.userData.guid = space.guid[0]
            mesh.userData.revguid = space.rev_guid[0]
            mesh.userData.category = space.pset[0].category[0]
            mesh.userData.selected = false
            mesh.userData.layer = Number(layer.layer_type[0])
            mesh.layers.disableAll()
            mesh.layers.enable(0)
            mesh.layers.enable(1)

            let that = this

            // eslint-disable-next-line no-unused-vars
            mesh.on('click', (event) => {
              let xChange = Math.abs(that.mouseState.dragStart.x - that.mouseState.dragEnd.x)
              let yChange = Math.abs(that.mouseState.dragStart.y - that.mouseState.dragEnd.y)
              if (xChange + yChange < 20) {
                if (!that.multiselectEnabled) {
                  for (let space of that.selectedSpaces) {
                    that.clearSpaceSelection(space.userData.revguid)
                  }
                  that.selectedSpaces = [mesh]
                  that.selectSpace(mesh.userData.revguid)
                }
                else {
                  if (!that.selectedSpaces.includes(mesh)) {
                    that.selectSpace(mesh.userData.revguid)
                  } else {
                    this.outsideOfScene = false
                    that.clearSpaceSelection(mesh.userData.revguid)
                    let index = that.selectedSpaces.indexOf(mesh)
                    if (index > -1) {
                      that.selectedSpaces.splice(index, 1)
                    }
                  }
                }

                let selectedSpaceUserdata = []
                for (let space of that.selectedSpaces) {
                  selectedSpaceUserdata.push(JSON.parse(JSON.stringify(space.userData)))
                }
                that.selectionCallback(selectedSpaceUserdata)
              }
            })

            this.scene.add(mesh)

            // find boundary center point
            let avgX = 0
            let avgY = 0
            for (let point of shapePoints) {
              avgX += point.x
              avgY += point.y
            }
            avgX /= shapePoints.length
            avgY /= shapePoints.length
            avgXPoints.push(avgX)
            avgYPoints.push(avgY)

            
          }
          
          // Space text
          const myText = new Text()
          myText.userData.layer = Number(layer.layer_type[0])
          myText.layers.disableAll()
          myText.layers.enable(myText.userData.layer)
          this.scene.add(myText)

          let number = ""
          let category = ""
          let area = ""

          if ('number' in space.pset[0])
          {
            number = space.pset[0].number[0]
          }

          if ('categoryname' in space.pset[0]) {
            category = space.pset[0].categoryname[0]
          } else {
            category = space.pset[0].category[0]
          }
          
          // position space text, use average of all space boundary center points
          var avgX = avgXPoints.reduce((a, b) => a +b ) / avgXPoints.length
          var avgY = avgYPoints.reduce((a, b) => a +b ) / avgYPoints.length
          area = Math.round(Number(space.pset[0].netarea[0] * 2)) / 2
          myText.text = number + '\n' + category + '\n' + area
          myText.anchorX = 'center'
          myText.anchorY = 'middle'
          myText.textAlign = 'center'
          myText.fontSize = 300
          myText.position.x = avgX - commonTranslation.x
          myText.position.y = avgY - commonTranslation.y
          myText.position.z = -10
          myText.color = 0x000000
          myText.sync()
        }
      }
      else if (layer.layer_type == VthLayer.LoadBearing) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.reg !== 'undefined'){
          for (let reg of element.reg) {
            const lineloop = this.createShapeLineObject(reg, layer.layer_type[0], commonTranslation)
            ExteriorWallParentObject.add(lineloop)
          }
        }
        }
      }
      else if (layer.layer_type == VthLayer.InteriorWall) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.reg !== 'undefined') {
            for (let reg of element.reg) {
              const lineloop = this.createShapeLineObject(reg, layer.layer_type[0], null)
              ExteriorWallParentObject.add(lineloop)
            }
          }
          else {
            if (typeof element.line !== 'undefined') {
              const lineSegments = this.createLineObject(element, layer.layer_type[0], null)
              ExteriorWallParentObject.add(lineSegments)
            }
            if (typeof element.pline !== 'undefined') {
              for (let p of element.pline) {
                const polyline = this.createPolylineObject(p, layer.layer_type[0], null)
                ExteriorWallParentObject.add(polyline)
              }
            }
            if (typeof element.arc !== 'undefined') {
              for (let arc of element.arc) {
                const arcline = this.createArcObject(arc, layer.layer_type[0], null)
                ExteriorWallParentObject.add(arcline)
              }
            }
            if (typeof element.ell !== 'undefined') {
              for (let ell of element.ell) {
                const ellipse = this.createEllipseObject(ell, layer.layer_type[0], null)
                ExteriorWallParentObject.add(ellipse)
              }
            }
          }
        }
      }
      else if (layer.layer_type == VthLayer.ExteriorWall) {
        // Skip exterior wall layer because it was handled separately in the beginning
        continue;
      }
      else if (layer.layer_type == VthLayer.Architecture) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.line !== 'undefined') {
            const lineSegments = this.createLineObject(element, layer.layer_type[0], commonTranslation)
            this.scene.add(lineSegments)
          }
          if (typeof element.pline !== 'undefined') {
            for (let p of element.pline) {
              const polyline = this.createPolylineObject(p, layer.layer_type[0], commonTranslation)
              this.scene.add(polyline)
            }
          }
          if (typeof element.arc !== 'undefined') {
            for (let arc of element.arc) {
              const arcline = this.createArcObject(arc, layer.layer_type[0], commonTranslation)
              this.scene.add(arcline)
            }
          }
          if (typeof element.ell !== 'undefined') {
            for (let ell of element.ell) {
              const ellipse = this.createEllipseObject(ell, layer.layer_type[0], commonTranslation)
              this.scene.add(ellipse)
            }
          }
        }
      }
      else if (layer.layer_type == VthLayer.Furniture) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.line !== 'undefined') {
            const lineSegments = this.createLineObject(element, VthLayer.Furniture, commonTranslation, furn)
            this.scene.add(lineSegments)
          }
          if (typeof element.pline !== 'undefined') {
            for (let p of element.pline) {
              const polyline = this.createPolylineObject(p, VthLayer.Furniture, commonTranslation, furn)
              this.scene.add(polyline)
            }
          }
          if (typeof element.arc !== 'undefined') {
            for (let arc of element.arc) {
              const arcline = this.createArcObject(arc, VthLayer.Furniture, commonTranslation, furn)
              this.scene.add(arcline)
            }
          }
          if (typeof element.ell !== 'undefined') {
            for (let ell of element.ell) {
              const ellipse = this.createEllipseObject(ell, VthLayer.Furniture, commonTranslation, furn)
              this.scene.add(ellipse)
            }
          }
        }
      }
      else if (layer.layer_type == VthLayer.Other) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.reg !== 'undefined') {
            for (let reg of element.reg) {
              const lineloop = this.createShapeLineObject(reg, VthLayer.Other, commonTranslation, oth)
              ExteriorWallParentObject.add(lineloop)
            }
          }
            if (typeof element.line !== 'undefined') {
              const lineSegments = this.createLineObject(element, VthLayer.Other, commonTranslation, oth)
              this.scene.add(lineSegments)
            }
            if (typeof element.pline !== 'undefined') {
              for (let p of element.pline) {
                const polyline = this.createPolylineObject(p, VthLayer.Other, commonTranslation, oth)
                this.scene.add(polyline)
              }
            }
            if (typeof element.arc !== 'undefined') {
              for (let arc of element.arc) {
                const arcline = this.createArcObject(arc, VthLayer.Other, commonTranslation, oth)
                this.scene.add(arcline)
              }
            }
            if (typeof element.ell !== 'undefined') {
              for (let ell of element.ell) {
                const ellipse = this.createEllipseObject(ell, VthLayer.Other, commonTranslation, oth)
                this.scene.add(ellipse)
              }
            }
          
          
        }
      }
      else if (layer.layer_type == VthLayer.LiftShaft) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.reg !== 'undefined') {
            for (let reg of element.reg) {
              const lineloop = this.createShapeLineObject(reg, VthLayer.LiftShaft, commonTranslation, liftShaft)
              ExteriorWallParentObject.add(lineloop)
            }
          }
            if (typeof element.line !== 'undefined') {
              const lineSegments = this.createLineObject(element, VthLayer.LiftShaft, commonTranslation, liftShaft)
              this.scene.add(lineSegments)
            }
            if (typeof element.pline !== 'undefined') {
              for (let p of element.pline) {
                const polyline = this.createPolylineObject(p, VthLayer.LiftShaft, commonTranslation, liftShaft)
                this.scene.add(polyline)
              }
            }
            if (typeof element.arc !== 'undefined') {
              for (let arc of element.arc) {
                const arcline = this.createArcObject(arc, VthLayer.LiftShaft, commonTranslation, liftShaft)
                this.scene.add(arcline)
              }
            }
            if (typeof element.ell !== 'undefined') {
              for (let ell of element.ell) {
                const ellipse = this.createEllipseObject(ell, VthLayer.LiftShaft, commonTranslation, liftShaft)
                this.scene.add(ellipse)
              }
            }
          
          
        }
      }
      else if (layer.layer_type == VthLayer.OtherShaft) {
        if (typeof layer.elements === 'undefined')
          continue

        for (let element of layer.elements) {
          if (typeof element.reg !== 'undefined') {
            for (let reg of element.reg) {
              const lineloop = this.createShapeLineObject(reg, VthLayer.OtherShaft, commonTranslation, otherShaft)
              ExteriorWallParentObject.add(lineloop)
            }
          }
            if (typeof element.line !== 'undefined') {
              const lineSegments = this.createLineObject(element, VthLayer.OtherShaft, commonTranslation, otherShaft)
              this.scene.add(lineSegments)
            }
            if (typeof element.pline !== 'undefined') {
              for (let p of element.pline) {
                const polyline = this.createPolylineObject(p, VthLayer.OtherShaft, commonTranslation, otherShaft)
                this.scene.add(polyline)
              }
            }
            if (typeof element.arc !== 'undefined') {
              for (let arc of element.arc) {
                const arcline = this.createArcObject(arc, VthLayer.OtherShaft, commonTranslation, otherShaft)
                this.scene.add(arcline)
              }
            }
            if (typeof element.ell !== 'undefined') {
              for (let ell of element.ell) {
                const ellipse = this.createEllipseObject(ell, VthLayer.OtherShaft, commonTranslation, otherShaft)
                this.scene.add(ellipse)
              }
            }
          
          
        }
      }
      else {
        console.log('unknown layer', layer)
      }
    }
  }

  /**
   * Geometry creation helper functions
   * These functions help create ThreeJS objects from VTH XML data. These functions should take various data from XML
   * hierarchy and output object that is suitable to be added to ThreeJS scene directly (inherits from Object3D).
   */
  createShapeLineObject (data, layer, commonTranslation = null, material = null) {
    let points = []

    for (let shape of data.shape) {
      for (let point of shape.s) {
        points.push(Number(point.x[0]), Number(point.y[0]), 0.0)
      }
    }
    const floats = new Float32Array(points)

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute(floats, 3 ));
    if (commonTranslation === null) {
      geometry.computeBoundingBox()
    }

    // Why isn't this translation needed with layers other than exterior walls? Is this the case with everything
    // or just lucky coincidence?
    // if (commonTranslation !== null) {
    //     geometry.translate(-commonTranslation.x, -commonTranslation.y, -10)
    // }

    const lineloop = new THREE.LineLoop(geometry, material ? material : this.lineMaterial)

    lineloop.layers.enable(layer)
    return lineloop
  }

  createPolylineObject (data, layer, commonTranslation, material = null) {
    let points = []
    for (const point of data.p) {
      if (commonTranslation === null) {
        points.push(Number(point.x[0]), Number(point.y[0]), 0.0)
      }
      else {
        points.push(Number(point.x[0] - commonTranslation.x), Number(point.y[0] - commonTranslation.y), 0.0)
      }
    }

    const floats = new Float32Array(points)
    let geometry = new THREE.BufferGeometry()

    if (commonTranslation !== null) {
      geometry.translate(-commonTranslation.x, -commonTranslation.y, 0)
    }
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute(floats, 3 ));
    if (commonTranslation === null) {
      geometry.computeBoundingBox()
    }

    let lineloop = null
    if (data.closed[0] === "1") {
      lineloop = new THREE.LineLoop(geometry, material ?? this.lineMaterial)
    }
    else {
      lineloop = new THREE.Line(geometry, material ?? this.lineMaterial)
    }
    lineloop.layers.enable(layer)
    return lineloop
  }

  createEllipseObject (data, layer, commonTranslation, material = null) {
    const w = Math.abs(Number(data.x2) - Number(data.x1))
    const h = w
    const x = Number(data.x1) + w / 2
    const y = Number(data.y1)

    const curve = new THREE.EllipseCurve(
      x, y,
      w/2, h/2,
      0,  2 * Math.PI,
      false,
      0
    );
    const points = curve.getPoints( 32 );
    const geometry = new THREE.BufferGeometry().setFromPoints( points );
    const ellipseLine = new THREE.Line(geometry, material ?? this.lineMaterial)

    if (commonTranslation !== null) {
      geometry.translate(-commonTranslation.x, -commonTranslation.y, -10)
    }

    ellipseLine.userData.layer = Number(layer)
    ellipseLine.layers.disableAll()
    ellipseLine.layers.enable(ellipseLine.userData.layer)

    return ellipseLine
  }

  createLineObject (data, layer, commonTranslation, material = null) {
    let lineVerts = []
    let lineIndices = []
    let currentIndex = 0;
    for (let line of data.line) {
      lineVerts.push(Number(line.x1), Number(line.y1), 0)
      lineIndices.push(currentIndex++)
      lineVerts.push(Number(line.x2), Number(line.y2), 0)
      lineIndices.push(currentIndex++)
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setIndex( lineIndices );
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( lineVerts, 3 ) );
    geometry.computeBoundingBox()

    if (commonTranslation !== null) {
      geometry.translate(-commonTranslation.x, -commonTranslation.y, 0)
    }

    const lineSegments = new THREE.LineSegments(geometry, material ?? this.lineMaterial)
    lineSegments.userData.layer = Number(layer)
    lineSegments.layers.disableAll()
    lineSegments.layers.enable(lineSegments.userData.layer)
    return lineSegments
  }

  createArcObject (data, layer, commonTranslation, material = null) {
    let h = Number(data.y2) - Number(data.y1)
    let x = Number(data.x1)
    let y = Number(data.y1) + h / 2
    let r = (Number(data.y2) - Number(data.y1)) / 2
    let a1 = (Number(data.a1) / 180 * Math.PI)
    let a2 = (Number(data.a2) / 180 * Math.PI)
    let arcCurve = new THREE.ArcCurve(x, y, r, a1, a2, false)

    let points = arcCurve.getPoints(50)
    let geometry = new THREE.BufferGeometry().setFromPoints(points)

    if (commonTranslation !== null) {
      geometry.translate(-commonTranslation.x, -commonTranslation.y, 0)
    }

    let arcline = new THREE.Line(geometry, material ?? this.lineMaterial)
    arcline.userData.layer = Number(layer)
    arcline.layers.disableAll()
    arcline.layers.enable(arcline.userData.layer)
    return arcline
  }
}

export default ThreeJSWrapper
