<template>
  <v-stepper
    v-model="step"
    :class="isModifiedStepper ? 'c-stepper-modified' : 'c-stepper'"
    non-linear
    :alt-labels="isModifiedStepper"
  >
    <v-stepper-header>
      <template v-for="s in steps">
        <v-stepper-step
          :key="`${s.id}-step`"
          :step="s.id + 1"
          :rules="[value => s.state === 'success'] || s.rules"
          class="c-stepper__step"
          editable
          error-icon="priority_high"
          tabindex="-1"
          @click="$emit('changeStep', s.id + 1)"
        >
          <button 
            :class="isModifiedStepper ? 'text-center' : 'text-left'"
            @click="emitData(s.id + 1, isModifiedStepper)"
          >
            <span class="d-sr-only">
              {{ $t('Page') + ' ' + (s.id + 1) + ': ' }}
            </span>
          
            {{ $t(s.title) }}

            <span class="d-sr-only">
              {{ currentStep === s.id ? ' — ' + $t('leasing.current_page_tooltip') : '' }} 
              {{ isUpdatingAllowed && isModifiedStepper ? ' — ' + $t(`leasing.${s.state}_tooltip`) : '' }}
            </span>

            <small
              v-if="s.description"
              class="mt-1"
            >
              {{ $t(s.description) }}
            </small>
          </button>
        </v-stepper-step>
        <v-divider
          v-if="!isModifiedStepper && s.id !== steps.length - 1"
          :key="`${s.id}-divider`"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  name: 'CdsStepper',
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    steps: {
      type: Array,
      default: null
    },
/** If this is set to true, stepper will use modified layout. See contracts modal for reference */
    isModifiedStepper: {
      type: Boolean,
      default: false
    },
    isUpdatingAllowed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeStep'],
  data () {
    return {
      step: 1
    }
  },
  watch: {
    currentStep (currentStep) {
      this.step = currentStep + 1
    },
  },
  methods: {
    emitData (id, isModifiedStepper) {
      this.$emit('changeStep', id)
      
      if (isModifiedStepper) {
        window.location = '#tab-content'
      }
    },
  }
}
</script>
<style lang="scss" scoped>
$mobile: 1240px;

.c-stepper {
  margin: 0 -16px;
}
.c-stepper ::v-deep .v-stepper__step__step {
  color: var(--r-color-ocean-100) !important;
  background-color: var(--r-color-cyan-20) !important;
  font-weight: 700;
}
.c-stepper ::v-deep .v-stepper__step__step.primary {
  color: var(--r-color-white) !important;
  background-color: var(--r-color-ocean-100) !important;
}
.c-stepper ::v-deep .v-stepper__label {
  font-weight: 500;
  color: var(--r-color-ocean-100) !important;
}
.c-stepper ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  color: var(--r-color-danger-100) !important;
  background-color: var(--r-color-danger-20) !important;
}
.c-stepper ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: var(--r-color-danger-100) !important;
}
.c-stepper ::v-deep .v-stepper__step__step .v-icon.v-icon {
  font-size: var(--r-font-size-m);
}
.c-stepper ::v-deep.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
.c-stepper ::v-deep .v-stepper__header {
  height: 64px;
}
.c-stepper ::v-deep.v-stepper .v-stepper__step {
  border-radius: var(--c-radius-xl);
  padding: 0 var(--r-spacing-l);
  margin: 0 var(--r-spacing-m);
  transition: all 0.25s ease-in-out;
}


.c-stepper-modified ::v-deep .v-stepper__step__step {
  color: var(--r-color-ocean-100) !important;
  background-color: var(--r-color-cyan-20) !important;
  font-weight: 700;

  @media (max-width: $mobile) {
    margin: 0;
  }
}
.c-stepper-modified ::v-deep .v-stepper__step--active .v-stepper__step__step {
  color: var(--r-color-white) !important;
  background-color: var(--r-color-ocean-100) !important;
}
.c-stepper-modified ::v-deep .v-stepper__label {
  font-weight: 500;
  color: var(--r-color-ocean-100) !important;

  @media (max-width: $mobile) {
    display: none;
  }
}
.c-stepper-modified ::v-deep .v-stepper__step.v-stepper__step--active.v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  color: var(--r-color-white) !important;
  background-color: var(--r-color-danger-100) !important;
}
.c-stepper-modified ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  color: var(--r-color-danger-100) !important;
  background-color: var(--r-color-danger-20) !important;
}
.c-stepper-modified ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: var(--r-color-danger-100) !important;
}
.c-stepper-modified ::v-deep .v-stepper__step__step .v-icon.v-icon {
  font-size: var(--r-font-size-m);
}
.c-stepper-modified ::v-deep.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
.c-stepper-modified ::v-deep .v-stepper__header {
  height: auto;
  flex-wrap: nowrap;
}
.c-stepper-modified ::v-deep.v-stepper .v-stepper__step {
  border-radius: var(--c-radius-xl);
  padding: var(--r-spacing-s) 0;
  margin: 0;
  transition: all 0.25s ease-in-out;
  flex-basis: 110px;
}
</style>
