<template>
  <div class="v3viewer">
    <div
      :id="containerIdFromParent ? containerIdFromParent : containerId"
      style="display: relative"
    />
    <ViewerToolInfo
      v-if="activeTool !== null"
      :active-tool="activeTool"
    />
    <v-row
      v-show="models.length > 0 && activeTool === null"
      class="toolbar"
    >
      <v-dialog
        v-model="markupManagementDialog"
        max-width="900"
        persistent
      >
        <v-card>
          <MarkupManagementForm
            :floor-id="floorId"
            :open="markupManagementDialog"
            @load-markups="markupLoad"
            @close="markupManagementDialog = !markupManagementDialog"
          />
        </v-card>
      </v-dialog>
      <v-row
        justify="start"
      >
        <v-col
          v-if="!displayOnly"
          class="grow"
        >
          <v-btn
            :input-value="isInSelectionMode"
            inline-flex
            text
            rounded
            outlined
            active-class="selection-mode-active"
            @click="toggleSelectionMode"
          >
            <v-icon>add</v-icon>
            &nbsp;{{ $t('v3viewer.chooseMultiple') }}
          </v-btn>
          <v-btn
            :input-value="isInRectangleSelectionMode"
            inline-flex
            text
            rounded
            outlined
            active-class="rectangle-selection-mode-active"
            @click="rectangleSelect"
          >
            <v-icon>crop</v-icon>
            &nbsp;{{ $t('v3viewer.chooseArea') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="!displayOnly"
        justify="end"
        no-gutters
      >
        <v-col
          v-if="!selectedLayerFromParent"
          cols="auto"
        >
          <div
            v-if="wideScreen"
            class="element-layer-panel"
          >
            <v-radio-group
              v-model="selectedElementLayer"
              class="element-layers"
              row
            >
              <v-radio
                v-for="layer in filteredElementLayers.filter(
                  (l) => l !== '101'
                )"
                :key="layer"
                :value="layer"
                :label="layerName(layer)"
              />
            </v-radio-group>
          </div>
          <div
            v-else
            class="shrink"
          >
            <v-menu
              top
              offset-y
            >
              <template
                #activator="{ on }"
              >
                <v-btn
                  inline-flex
                  text
                  icon
                  outlined
                  v-on="on"
                >
                  <v-icon>format_list_bulleted</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-radio-group
                  v-model="selectedElementLayer"
                  row
                >
                  <v-radio
                    v-for="layer in filteredElementLayers.filter(
                      (l) => l !== '101'
                    )"
                    :key="layer"
                    :value="layer"
                    :label="layerName(layer)"
                    class="element-layer"
                  />
                </v-radio-group>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col
          v-if="hasApplicationPermissionByName('POHJAKUVA_KOKI')"
          cols="auto"
        >
          <v-tooltip
            top
          >
            <template #activator="{ on }">
              <v-btn
                text
                icon
                outlined
                @click="updateFloorPlanToKoki(); indicatorRunning = true"  
                v-on="on"
              >
                <v-icon v-if="!saving">
                  send
                </v-icon>
                <v-progress-circular
                  v-if="saving"
                  indeterminate
                  small
                  color="primary"
                />
              </v-btn>
            </template>
            <span>{{ $t('Send data to koki info') }}</span>
          </v-tooltip>  
        </v-col>
        <v-col cols="auto">
          <v-btn
            text
            icon
            outlined
            @click="$emit('print')"
          >
            <span v-if="!isPrinting">
              <v-icon>
                print
              </v-icon>
              <span class="d-sr-only">{{ $t('Print') }}</span>
            </span>
            <v-progress-circular
              v-else
              indeterminate
              small
              color="primary"
            />
          </v-btn>
        </v-col>
        <ViewerToolSelector
          v-if="isLoaded"
          @toolSelected="startMenuAction"
          @colorPicked="setMarkupColor"
        />
        <Alert
          :y="null"
          :show="showIndicator"
          :result="operationResult"
          :message="indicatorMessage"
        />
      </v-row>
    </v-row>
  </div>
</template>

<script>
// We load external script with this component
import scriptjs from 'scriptjs'
import MarkupManagementForm from './MarkupManagentForm'
import Alert from '../../components/Alert'
import ViewerToolSelector from './components/ViewerToolSelector'
import ViewerToolInfo from './components/ViewerToolInfo'
import V3Wrapper from './v3wrapper'
import { mapGetters } from 'vuex'

/* eslint-disable no-console */
export default {
  name: 'V3Viewer',
  components: {
    MarkupManagementForm: MarkupManagementForm,
    Alert: Alert,
    ViewerToolSelector: ViewerToolSelector,
    ViewerToolInfo: ViewerToolInfo,
  },
  props: {
    selectedSpaces: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedUnits: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedCarSpaces: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedOutdoors: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedVegetation: {
      default: () => {
        return []
      },
      type: Array
    },
    floorId: {
      type: Number,
      default: null
    },
    models: {
      default: () => {
        return []
      },
      type: Array
    },
    mapStyle: {
      type: String,
      default: 'None'
    },
    sessionToken: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '1,1,1,1' // white
    },
    hiddenLayers: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedCategory: {
      type: Object,
      default: null
    },
    spaces: {
      type: Object,
      default: null
    },
    addTextPerm: {
      default: false,
      type: Boolean
    },
    spaceTextData: {
      default: () => {
        return []
      },
      type: Array
    },
    displayOnly: {
      default: () => {
        return false
      },
      type: Boolean
    },
    selectedLayerFromParent: {
      default: () => {
        return null
      },
      type: String
    },
    containerIdFromParent: {
      default: () => {
        return null
      },
      type: String
    },
    clickToZoom: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      v3: null, // TODO tämä pitäisi poistaa ja käyttää v3:sta wrapperin kautta
      v3wrapper: null,
      containerId: 'v3container',
      mapStyles: {
        Road: 'MapStyleRoad',
        Satellite: 'MapStyleSatellite',
        'Satellite and 3D': 'MapStyleSatelliteAnd3D',
        None: 'MapStyleNone'
      },
      currentModels: [], // to wrapper?
      defaultColor: [0.9215, 0.9215, 0.9215, 1],
      isInSelectionMode: false, // to wrapper?
      selectedElements: [], // to wrapper?
      elementLayers: [], // to wrapper?
      selectedElementLayer: [], // siirretään wrapperiin autopaikkojen kehityksen yhteydessä?
      selectedRectangleElements: [], // to wrapper?
      isPrinting: false,
      layers: [], // to wrapper?
      defaultSpaceColor: [0.95, 0.95, 0.95, 1], // to wrapper?
      selectedSpaceColor: [0, 0.6156, 0.8784, 1.0], // to wrapper?
      isInRectangleSelectionMode: false, // to wrapper?
      alwaysHiddenLayers: ['3', '4', 'A-WALL-PATT'], // to wrapper?
      allMarkups: [],
      selectedMarkup: [],
      fabIcon: 'menu',
      validElementLayers: ['1', '2', '101', '102', '106'], // to wrapper?
      elementColors: {}, // to wrapper?
      actionMenuOpen: false,
      markupManagementDialog: false,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      selectedColor: '#FF0000',
      activeTool: null,
      currentSpaceElements: [],
      localVektorioEnv: null,
      saving: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    v3cssUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_CSS_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_CSS_URL : process.env.VUE_APP_V3_CSS_URL_GCP
    },
    v3scriptUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_SCRIPT_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_SCRIPT_URL : process.env.VUE_APP_V3_SCRIPT_URL_GCP     
    },
    filteredElementLayers () {
      return Object.keys(this.dwgSpaceData ? this.dwgSpaceData : {})
        .filter(e => this.validElementLayers.includes(e))
        .sort((a, b) => {
          return a - b
        })
    },
    wideScreen () {
      return this.filteredElementLayers.length > 3
        ? this.$vuetify.breakpoint.xlOnly
        : this.$vuetify.breakpoint.lgAndUp
    },
    dwgSpaceData () {
      if (this.v3wrapper) {
        return this.v3wrapper.dwgSpaceData
      } else {
        return null
      }
    }
  },
  watch: {
    clickToZoom: {
      handler: function () {
        this.adjustZoomLevel()
        this.$emit('zoom-changed')
      }
    },
    selectedElementLayer: {
      handler: function (layer) {
        if (
          !this.filteredElementLayers.includes(this.selectedElementLayer) ||
          this.selectedElementLayer === null
        ) {
          this.selectedElementLayer = this.filteredElementLayers[0]
        }
        this.showAllLayers()
        const hiddenLayers = this.filteredElementLayers.filter(
          o => o !== this.selectedElementLayer
        )
        this.hideLayers(this.alwaysHiddenLayers)
        this.hideLayers(this.hiddenLayers)
        this.hideLayers(hiddenLayers)

        // temporary solution until we have added texts for units
        if (this.addTextPerm && layer === '1') {
          this.hideLayers(['ART1100TB', 'M06203T'])
        }

        this.$emit('layer-changed', this.selectedElementLayer)
        if (this.v3wrapper) {
          this.v3.clearSelection()
        }
      }
    },
    models: {
      handler: function () {
        if (this.v3 === null) {
          console.log('V3 is not yet initialized!')
          return
        }
        this.removeModels()
        this.initializeModels()
        this.v3.removeMeasurements()
        this.actionMenuOpen = false
      },
      deep: true
    },
    selectedCategory: {
      handler: function (cat) {
        if (cat === null) {
          this.resetSpaceColors()
        }
      }
    },
    selectedSpaces: {
      handler: function (spacesWithColor) {
        if (spacesWithColor !== undefined && spacesWithColor !== null && this.selectedElementLayer === '1') {
          this.colorSpaces(spacesWithColor)
        }
      },
      deep: true
    },
    selectedUnits: {
      handler: function (unitsWithColor) {
        if (unitsWithColor !== undefined && unitsWithColor !== null && this.selectedElementLayer === '2') {
          this.colorUnits(unitsWithColor)
        }
      },
      deep: true
    },
    selectedCarSpaces: {
      handler: function (carSpacesWithColor) {
        if (carSpacesWithColor !== null) {
          this.colorCarSpaces(carSpacesWithColor)
        }
      },
      deep: true
    },
    selectedOutdoors: {
      handler: function (outdoorsWithColor) {
        if (outdoorsWithColor !== null) {
          this.colorOutdoors(outdoorsWithColor)
        }
      },
      deep: true
    },
    selectedVegetation: {
      handler: function (vegetationWithColor) {
        if (vegetationWithColor !== null) {
          this.colorVegetation(vegetationWithColor)
        }
      },
      deep: true
    },
    hiddenLayers: {
      handler: function (hiddenLayers) {
        if (this.selectedCategory === null) {
          const allHiddenLayers = hiddenLayers.concat(this.alwaysHiddenLayers)

          this.showAllLayers()
          this.hideLayers(allHiddenLayers)

          // temporary solution until we have added texts for units
          if (this.addTextPerm && this.selectedElementLayer === '1') {
            this.hideLayers(['ART1100TB', 'M06203T'])
          }
          this.showSpaceName(this.v3wrapper.loadedData ? this.v3wrapper.loadedData.projectId : null)
        }
      },
      deep: true
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.showIndicator = false
        }, 4000)
      }
    },
    spaceTextData: function () {
      this.showSpaceName(this.v3wrapper.loadedData ? this.v3wrapper.loadedData.projectId : null)
    },
    activeTool: function () {
      if (this.activeTool !== null) {
        this.$emit('tool-activated')
      } else {
        this.$emit('tool-deactivated')
      }
    },
    dwgSpaceData: {
      deep: true,
      handler: async function (dwgData) {
        if (dwgData && this.v3wrapper) {
          const data = this.v3wrapper.loadedData
          this.currentModels.push(data)
          this.layers = await this.v3wrapper.getModelLayers()
          this.$emit('model-loaded', {
            model: this.models[0],
            dwgSpaceData: dwgData,
            layers: this.layers
          })
          if (this.filteredElementLayers.length > 0) {
            // Logig edit - if filtered layers contains an unit layer,
            // we'll set that as the default selected layer.
            // Otherwisely, we take whatever is first on the list
            if (this.filteredElementLayers.includes('2')) {
              this.selectedElementLayer = '2'
            } else {
              this.selectedElementLayer = this.filteredElementLayers[0]
            }           
          }

          this.resetSpaceColors()

          if (this.selectedLayerFromParent) {
            this.leaveSelectionMode()
            if (this.filteredElementLayers.find(l => l === this.selectedLayerFromParent)) {
              this.selectedElementLayer = this.selectedLayerFromParent
            }
          }
        }
      }
    },
    selectedLayerFromParent: function (layer) {
      if (layer) {
        if (this.filteredElementLayers.find(l => l === layer)) {
          this.selectedElementLayer = layer
          this.showAllLayers()
          return
        }
        else {
          this.hideLayers(this.filteredElementLayers)
          return
        }
      }
      this.selectedElementLayer = this.filteredElementLayers[0]
      this.showAllLayers()
    },
    floorId: function () {
      this.currentSpaceElements = []
      this.actionMenuOpen = false
    },
    selectedColor: function () {
      this.v3wrapper.setMarkupColor(this.selectedColor)
    }
  },
  beforeDestroy: function () {
    // We need to destroy v3 before leaving because it will break any further initializations
    if (typeof this.v3 !== 'undefined' && this.v3 !== null) {
      this.v3.clear()
    }
    if (typeof this.v3wrapper !== 'undefined' && this.v3wrapper !== null) {
      this.v3wrapper.clear()
    }
  },
  async mounted () {
    if(window.location.hostname.includes('localhost'))
    {
      this.localVektorioEnv = await this.$rambollfmapi.accounts.vektorio.localenvironment.get()
    }
    const self = this

    // bootstrap V3 viewer
    if (typeof window.V3 === 'undefined' || typeof window.V3 !== 'function') {
      scriptjs(this.v3scriptUrl, () => {
        self.initialize()
      })
    } else {
      self.initialize()
    }
  },
  methods: {
    setColorByInternalId (internalId, color) {
      this.elementColors[internalId.elementId] = color
      this.v3wrapper.setElementColor(internalId, color)
    },
    resetColorByInternalId (internalId) {
      this.elementColors[internalId.elementId] = undefined
      this.resetColorByInternalId(internalId)
    },
    getColorByInternalId (internalId) {
      return this.elementColors[internalId.elementId]
        ? this.elementColors[internalId.elementId]
        : this.defaultSpaceColor
    },
    print (options) {
      const projectId = this.currentModels[0].projectId
      const modelHandle = this.currentModels[0].modelHandle
      const currentColors = []
      this.selectedElements.forEach(e => {
        currentColors.push([
          e.internal_id,
          this.getColorByInternalId(e.internal_id)
        ])
        this.setColorByInternalId(e.internal_id, this.selectedSpaceColor)
      })

      this.isPrinting = true
      const self = this
      this.v3.exportToPdf(projectId, modelHandle, options, function (
        error,
        url
      ) {
        if (typeof error === 'undefined' || error === null) {
          window.open(url, '_blank')
        }
        self.isPrinting = false
      })
      currentColors.forEach(color => {
        self.setColorByInternalId(color[0], color[1])
      })
    },
    initialize () {
      this.isLoaded = false
      this.initializeV3()
      this.initializeModels()
      this.$emit('ready', {
        print: ({ legend, title }) => {
          // convert input to V3 options
          const options = {
            hideScaleLabel: false
          }
          if (legend !== undefined && title !== undefined) {
            options.legend = {
              title: title,
              values: legend,
              fontSize: 9,
              width: 20,
              hideBackgroundRectangle: false,
              version: 2
            }
          }

          this.print(options)
        }
      })
      this.isLoaded = true
    },
    adjustZoomLevel () {
      if (this.clickToZoom !== 0) {
        this.v3wrapper.zoom(this.clickToZoom)
      }
    },
    layerName (layer) {
      if (layer === '1') {
        return this.$t('Space')
      } else if (layer === '2') {
        return this.$t('Unit')
      } else if (layer === '101') {
        return this.$t('Car parks')
      } else if (layer === '102') {
        return this.$t('Outdoor areas')
      } else if (layer === '106') {
        return this.$t('Vegetation areas')
      } else {
        return this.$t('Unknown element layer')
      }
    },
    removeModels () {
      this.currentModels.forEach(handle => {
        this.v3.removeModel(handle.projectId, handle)
      })
      this.v3.clear()
      this.currentModels = []
    },
    allModelsLoaded (models) {
      let hasLoaded = true
      models.forEach(model => {
        if (
          !this.currentModels.find(
            loadedModel => loadedModel.projectId === model.name
          )
        ) {
          hasLoaded = false
        }
      })
      return hasLoaded
    },
    initializeModels () {
      // V3 needs to be defined
      if (this.v3 === null) {
        return
      }
      if (this.models.length === 0) {
        return
      }

      // Only add models one by one
      if (!this.allModelsLoaded(this.models)) {
        this.models.forEach(model => {
          this.v3wrapper.loadModel(model)
        })
      } else {
        console.log('Already adding models...')
      }
    },
    rectangleSelect () {
      this.isInRectangleSelectionMode = true
      this.v3.beginRectangleSelection(res => {
        // We have valid objects
        const validElementIds = this.dwgSpaceData['1'].map(
          e => e.internal_id.elementId
        )
        const elementIdToData = this.dwgSpaceData['1'].reduce((acc, cur) => {
          acc[cur.internal_id.elementId] = cur
          return acc
        }, {})

        // filter elements with validObjects
        const validSelectedElements = res.selectedElements.filter(
          e => validElementIds.indexOf(e.elementId) >= 0
        )
        this.selectedRectangleElements = [
          ...this.selectedRectangleElements,
          ...validSelectedElements.map(e => elementIdToData[e.elementId])
        ]
        this.$emit('select-elements', [
          ...this.selectedElements,
          ...this.selectedRectangleElements
        ])
      })
    },
    async markupLoad (idStoredView) {
      this.markupManagementDialog = false
      this.actionMenuOpen = false

      this.v3wrapper.removeAllMarkups()

      var data = await this.$rambollfmapi.floors.v3markups().get(idStoredView)
      var newMarkups = []

      data.forEach(element => {
        if (element.type === 'StoredView') {
          var sw = {
            id: element.id,
            type: element.type,
            note: element.extraString,
            plane: {
              position: [element.x, element.y, element.z]
            }
          }
          newMarkups.push(sw)
        } else if (element.type === 'Polyline') {
          var pl = {
            id: element.id,
            storedViewId: element.extraString,
            type: element.type,
            color: element.color ?? this.selectedColor,
            points: []
          }
          var points = [element.x, element.y, element.z]
          if (newMarkups.find(m => m.id === pl.id)) {
            newMarkups
              .find(m => m.id === pl.id && m.type === 'Polyline')
              .points.push(points)
          } else {
            pl.points.push(points)
            newMarkups.push(pl)
          }
        }
      })
      newMarkups.forEach(markup => {
        this.v3wrapper.setMarkupColor(markup.color)
        this.v3.addMarkup(markup)
        this.v3wrapper.onMarkup(markup)
      })
      this.v3wrapper.setMarkupColor(this.selectedColor)
    },
    markupsSave () {
      const markups = this.v3wrapper.getAllMarkups()
      const storedViews = markups.filter(x => x.type === 'StoredView')
      const polylines = markups.filter(x => x.type === 'Polyline')

      // We will store all markups under the first found StoredView
      if (storedViews.length > 0) {
        const storedView = storedViews[0]

        var data = [
          {
            id: storedView.id,
            extraString: this.parseDate(storedView.createdAt),
            type: storedView.type,
            X: storedView.plane.position[0],
            Y: storedView.plane.position[1],
            Z: storedView.plane.position[2],
          }
        ]
        polylines.map(m => {
          for (var i = 0; i < m.points.length; i++) {
            var polyline = {
              id: m.id,
              extraString: storedView.id,
              type: m.type,
              color: m.color,
              X: m.points[i][0],
              Y: m.points[i][1],
              Z: m.points[i][2]
            }
            data.push(polyline)
          }
        })

        const response = new Promise((resolve) => {
          resolve(
            this.$rambollfmapi.floors
            .v3markups()
            .patch(parseInt(this.floorId), data)
          ) 
        })
        response.then((res) => {
          if (res.status == 200) {
            this.indicatorMessage = this.$t('Save success')
            this.operationResult = 'success'
          }
          else {
            this.indicatorMessage = this.$t('Error while saving')
            this.operationResult = 'error'
          }
        })
      }
    },
    startMenuAction (action, text) {
      if (action === 'Calculate area') {
        this.v3wrapper.startAreaMeasurementTool()
        this.activeTool = action
      } else if (action === 'Measure distance') {
        this.v3wrapper.startDistanceMeasurementTool()
        this.activeTool = action
      } else if (action === 'Free pen') {
        this.v3wrapper.startPenTool()
        this.activeTool = action
      } else if (action === 'Text input') {
        this.v3wrapper.startRectangleTool(text)
        this.activeTool = action
      } else if (action === 'Remove markup') {
        this.v3wrapper.startEraserTool()
        this.activeTool = action
      } else if (action === 'Download') {
        this.markupManagementDialog = true
      } else if (action === 'Save') {
        this.markupsSave()
        this.showIndicator = true
      } else if (action === 'Clear') {
        this.v3wrapper.removeAllMarkups()
        this.v3wrapper.removeAllMeasurements()
      } else {
        console.log('Undefined action')
      }
    },
    setMarkupColor (color) {
      this.v3wrapper.setMarkupColor(color)
    },
    parseDate (string) {
      string = string.split('.')[0]
      string = string.replace('T', ' ')
      return string
    },
    toggleSelectionMode () {
      if (this.isInSelectionMode) {
        this.leaveSelectionMode()
      } else {
        this.enterSelectionMode()
      }
    },
    enterSelectionMode () {
      this.v3.setMultiselect(true) // TODO olisiko wrapperin parempi pitää kirjaa näistä
      this.isInSelectionMode = true
    },
    leaveSelectionMode () {
      this.v3.setMultiselect(false) // TODO olisiko wrapperin parempi pitää kirjaa näistä
      this.isInSelectionMode = false
    },
    onSelection (element, hitPoint, multiSelectElements) {
      // TODO siirrä element datan käsittely wrapperiin ja emit lähetykset täällä
      
      // For now the wrapper only contains some logic related to markups and measurements.
      // The method return value tells us, if we should do additional handling afterwards
      if (this.v3wrapper.onSelection(element, hitPoint, multiSelectElements)) {
        return
      }

      this.isInRectangleSelectionMode = false

      if (
        typeof multiSelectElements === 'undefined' ||
        multiSelectElements.length < 1
      ) {
        this.selectedRectangleElements = []
        this.selectedElements = []
        this.$emit('select-elements', [])
        return
      }

      if (this.dwgSpaceData !== null && multiSelectElements !== undefined) {
        const elementIdToData = this.dwgSpaceData[
          this.selectedElementLayer
        ].reduce((acc, cur) => {
          acc[cur.internal_id.elementId] = cur
          return acc
        }, {})
        const selectedElements = multiSelectElements
          .map(m => m.InternalId.elementId)
          .map(id => elementIdToData[id])
          .filter(d => typeof d !== 'undefined')
        this.selectedElements = selectedElements
        this.$emit('select-elements', this.selectedElementLayer, [
          ...this.selectedElements,
          ...this.selectedRectangleElements
        ])
      }
    },
    initializeV3 () {
      if (this.floorHasXMLDrawing) {
        return
      }
      var v3options = {
        containerId: this.containerIdFromParent ? this.containerIdFromParent : this.containerId,
        selectedSpaceColor: this.selectedSpaceColor,
        sessionToken: this.sessionToken,
        mapStyle: this.mapStyles[this.mapStyle],
        backgroundColor: this.backgroundColor.split(','),
        onSelection: this.onSelection,
        onRenderingFinished: this.renderFinished,
        elementLayers: this.filteredElementLayers
      }
      this.v3wrapper = new V3Wrapper(window.V3, v3options, undefined, this.localVektorioEnv)
      this.v3 = this.v3wrapper.v3

      this.v3wrapper.setToolFinishedCallback(() => { this.activeTool = null })
      this.v3wrapper.setMarkupColor(this.selectedColor)

      // Fix selection
      document.getElementById(v3options.containerId + 'SelectionRectangle').style.position = 'fixed'

      // let the user know that v3 is ready to be used
      this.$emit('ready', this.v3)
    },
    resetSpaceColors () {
      const objects = []
      if (this.dwgSpaceData) {
        this.filteredElementLayers.forEach(data =>
          objects.push(...this.dwgSpaceData[data])
        )
      }
      objects.forEach(o => {
        this.setColorByInternalId(o.internal_id, this.defaultSpaceColor)
      })
      this.hideLayers(
        this.filteredElementLayers.filter(
          layer => layer !== this.selectedElementLayer
        ), this.v3wrapper.loadedData.projectId
      )
    },
    showAllLayers () {
      this.models.forEach(model => {
        this.v3wrapper.resetElementColors(model.name)
      })
      this.resetSpaceColors()
    },
    getLayers (layerId) {
      // Layer ID can be like ABTEST* => * is wildcard
      const hasWildcard = layerId.indexOf('*') >= 0

      if (hasWildcard) {
        const regex = new RegExp(layerId.replace(/\*/, '.*'), 'gi')

        return this.layers.filter(l => l.match(regex) !== null)
      } else {
        return typeof this.layers[layerId] === 'undefined'
          ? []
          : [this.layers[layerId]]
      }
    },
    getLayerElements (layerId) {
      return this.v3wrapper.getLayerElements(
        layerId,
        this.v3wrapper.loadedData ? this.v3wrapper.loadedData.projectId : null // TODO wrapper voi pitää tämän mielessä koska käytössä on vain yksi 2d model kerrallaan
      )
    },
    async showLayer (layer) {
      // TODO muuta tämä samanlaiseksi kuin hideLayers, eli wrapper hoitaa suurimman työn
      const elements = await this.getLayerElements(layer)
      elements.forEach(element => {
        this.resetColorByInternalId(element)
      })
    },
    async showLayers (layers) {
      // TODO muuta tämä samanlaiseksi kuin hideLayers, eli wrapper hoitaa suurimman työn
      for (const layer of layers) {
        await this.showLayer(layer)
      }
    },
    hideLayers (layers) {
      this.v3wrapper.hideLayers(layers, this.v3wrapper.loadedData ? this.v3wrapper.loadedData.projectId : null)
    },
    toggleLayer (layerId, enabled) {
      const dwgData = this.dwgSpaceData[layerId]
      if (typeof dwgData === 'undefined') {
        console.log('Cannot toggle layer ' + layerId + ". It doesn't exist")
        return
      }

      let color = [1, 1, 1, 0]
      if (enabled) {
        color = [1, 1, 1, 1]
      }
      for (let i = 0; i < dwgData.length; i++) {
        this.v3.setColorByInternalId(dwgData[i].internal_id, color)
      }
    },
    toggleSelectionLayer (layer) {
      if (this.selectedElementLayer === layer) {
        return
      }
      this.showAllLayers()
      this.selectedElementLayer = layer
      const hiddenLayers = this.filteredElementLayers.filter(o => o !== layer)
      this.hideLayers(this.alwaysHiddenLayers)
      this.hideLayers(hiddenLayers)
      this.$emit('layer-changed', layer)
    },
    hideAllLayers () {
      const layers = Object.keys(this.dwgSpaceData)
      layers.forEach(layer => {
        const objects = this.dwgSpaceData[layer]
        objects.forEach(o => {
          this.setColorByInternalId(o.internal_id, [1, 1, 1, 0])
        })
      })
    },
    colorSpaces (spacesWithColor) {
      this.resetSpaceColors()
      // color with internal ids
      spacesWithColor.forEach(i => {
        const color = i.color
        const space = i.identifier
        const foundSpaces = /* Object.keys(this.dwgSpaceData).map(k => this.dwgSpaceData[k]).reduce((acc, cur) => [...acc, ...cur]) */ this.dwgSpaceData[
          '1'
        ].filter(s => s.element_id === space)
        const color4 = color === undefined ? this.selectedSpaceColor : [...color.split(',').map(c => Number(c) / 255), 1]
        foundSpaces.forEach(s => {
          this.setColorByInternalId(s.internal_id, color4)
        })
      })
    },
    colorUnits (unitsWithColor) {
      this.resetSpaceColors()
      // color with internal ids
      unitsWithColor.forEach(i => {
        const color = i.color
        const unit = i.identifier
        const foundUnits = /* Object.keys(this.dwgSpaceData).map(k => this.dwgSpaceData[k]).reduce((acc, cur) => [...acc, ...cur]) */ this.dwgSpaceData[
          '2'
        ].filter(u => u.element_id === unit)
        const color4 =
          color === undefined
            ? this.selectedSpaceColor
            : [...color.split(',').map(c => Number(c) / 255), 1]
        foundUnits.forEach(u => {
          this.setColorByInternalId(u.internal_id, color4)
        })
      })
    },
    colorCarSpaces (carSpacesWithColor) {
      this.resetSpaceColors()
      carSpacesWithColor.forEach(i => {
        const color = i.color
        const carspace = i.identifier
        const foundCarSpaces = this.dwgSpaceData['101'].filter(
          c => c.element_id === carspace
        )
        const color4 = [...color.split(',').map(c => Number(c) / 255), 1]
        foundCarSpaces.forEach(c => {
          this.setColorByInternalId(c.internal_id, color4)
        })
      })
    },
    colorOutdoors (outdoorsWithColor) {
      this.resetSpaceColors()
      outdoorsWithColor.forEach(i => {
        const color = i.color
        const outdoor = i.identifier
        const foundOutdoors = this.dwgSpaceData['102'].filter(
          o => o.element_id === outdoor
        )
        const color4 = [...color.split(',').map(c => Number(c) / 255), 1]
        foundOutdoors.forEach(o => {
          this.setColorByInternalId(o.internal_id, color4)
        })
      })
    },
    colorVegetation (vegetationWithColor) {
      this.resetSpaceColors()
      vegetationWithColor.forEach(i => {
        const color = i.color
        const vegetation = i.identifier
        const foundVegetation = this.dwgSpaceData['106'].filter(
          v => v.element_id === vegetation
        )
        const color4 = [...color.split(',').map(c => Number(c) / 255), 1]
        foundVegetation.forEach(v => {
          this.setColorByInternalId(v.internal_id, color4)
        })
      })
    },
    showSpaceName (internalId) {
      if (internalId !== null && this.spaceTextData.length > 0) {
        const that = this
        if (this.addTextPerm) {
          this.v3.removeTexts()
          if (this.currentSpaceElements.length > 0) {
            this.writeTextsToElements(this.currentSpaceElements)
          } else {
            this.v3.searchElements(
              internalId,
              [['Properties.Pset_SpaceCommon.Reference.value', 'ART1100TB']],
              function (elements) {
                that.currentSpaceElements = elements
                that.writeTextsToElements(elements)
              }
            )
          }

        }
      } else {
        this.v3.removeTexts()
        this.v3.forceUpdate()
      }
    },
    writeTextsToElements (elements) {
      for (var std of this.spaceTextData){
        const el = elements.filter(
          e => e.Properties.Pset_Block.HNENUM.value === std.name
        )
        for (var e of el) {
          this.v3.addStaticTextToElement(std.text,
            e, { fitToBoundingBox: false }
          )
        }
      }
    },
    // This method is used through ref in RambollFMViewer
    async selectElementsFromSelectedCategories (layerId, selectedCategoryOptionsElements) {
      const elements = await this.getLayerElements(layerId)
      const elementsToSelect =
      selectedCategoryOptionsElements.reduce((acc, cur) => {
        if (typeof this.dwgSpaceData[layerId] !== 'undefined') {
          const foundSpaces = this.dwgSpaceData[layerId].filter(s => s.element_id === cur)

          if(foundSpaces) {
            return acc.concat(foundSpaces)
          } 
          else {
            return acc
          }
        } else {
          return acc
        }
      }, [])

      const elementsToSelectByInternalIds =
      elementsToSelect.reduce((acc, cur) => {
        const found = elements.find(el =>  Number(el.elementId) === cur.internal_id.elementId)
        if(found) {
          return acc.concat(found)
        } else {
          return acc
        }
      }, [])

      elementsToSelectByInternalIds.forEach(element => {
      this.v3.selectElementByInternalId(element)
      })
    },
    renderFinished () {
      this.v3.forceUpdate()
      this.$emit('render-finish')
    },
    flyToCamera: function (camera) {
      if (camera) {
        this.v3.flyToCamera(camera, 0, cb => {
          setTimeout(() => {
            this.$emit('camera-ready', true)
          }, 500)
        })
      } else {
        this.$emit('camera-ready', false)
      }
    },
    // This method is used through ref in ZoomLevelSelection
    fetchCamera () {
      return this.v3.getCamera()
    },
    async updateFloorPlanToKoki (){
      this.saving = true
      const floorChangeSave = await this.$rambollfmapi.floors.floor_change(this.floorId)
      if (floorChangeSave.name === 'Error'){
        this.showIndicator = true
        this.operationResult = 'error'
        this.indicatorMessage = this.$t('Unsuccesfull save')
      }
      else {
        let failedChange = floorChangeSave.filter((item) => {
          return (item.id < 1)
        })
        if (failedChange.length > 0 ){
          this.showIndicator = true
          this.operationResult = 'error'
          this.indicatorMessage = this.$t('Unsuccesfull save')
        } else {
          this.showIndicator = true
          this.operationResult = 'success'
          this.indicatorMessage = this.$t('Succesfull save')
         }
      }
      this.saving = false
    }
  }
}
/* eslint-enable */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v3viewer {
  position: relative;
  height: 99%;
}

.v3viewer .toolbar {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: none !important;
}

.v3viewer .toolbar .v-btn {
  background: white;
}

.rectangle-selection-mode-active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.selection-mode-active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-btn.v-btn--text {
  border: 1px solid #ccc !important;
}
.element-layers {
  padding: 0;
  border: 0;
  margin: auto;
}
.element-layer {
  margin-left: 0.5em;
}
.element-layer-panel {
  border: 1px solid #ccc;
  border-radius: 2em;
  margin-top: var(--r-spacing-xs);
  margin-right: var(--r-spacing-xs);
  padding: var(--r-spacing-xxs) var(--r-spacing-m);
  height: 36px;
  background: white;
}
#ramboll-dashboard .v-menu__content {
  border-radius: 1.4em;
}
</style>
