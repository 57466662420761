import RambollFMAPI from '../api/rambollfm'
import { i18n } from '../plugins/i18n'

export default {
  namespaced: true,
  state: {
    currentSiteId: undefined,
    loadedEsgSite: [],
    loadedEsgEstates: [],
    loadedEsgBuildings: [],
    siteEsgMetadata: null,
    estateEsgMetadata: null,
    buildingEsgMetadata: null,
    esgCertificates: [],
    loadedSpecifications: [],
    levelList: [
      {value: 1, text: i18n.t('esg.certificate.site')},
      {value: 2, text: i18n.t('esg.certificate.estate')},
      {value: 3, text: i18n.t('esg.certificate.building')}
    ],
    statusList: [
      {value: 1, text: i18n.t('esg.certificate.construction')},
      {value: 2, text: i18n.t('esg.certificate.standing_investment')}
    ],
    schemeList: [
      {value: 1, text: i18n.t('esg.certificate.leed')},
      {value: 2, text: i18n.t('esg.certificate.breeam')},
      {value: 3, text: i18n.t('esg.certificate.well')},
      {value: 4, text: i18n.t('esg.certificate.dgnb')},
      {value: 5, text: i18n.t('esg.certificate.miljobyggnad')},
      {value: 6, text: i18n.t('esg.certificate.rts')},
      {value: 7, text: i18n.t('esg.certificate.nordic_swan')},
      {value: 8, text: i18n.t('esg.certificate.fitwel')},
      {value: 9, text: i18n.t('esg.certificate.green_key_international')},
      {value: 10, text: i18n.t('esg.certificate.stf')},
      {value: 11, text: i18n.t('esg.certificate.other')}
    ],
    leedList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.certified')},
      {value: 2, text: i18n.t('esg.certificate.scheme.silver')},
      {value: 3, text: i18n.t('esg.certificate.scheme.gold')},
      {value: 4, text: i18n.t('esg.certificate.scheme.platinum')}
    ],
    breeamList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.unclassified')},
      {value: 2, text: i18n.t('esg.certificate.scheme.pass')},
      {value: 3, text: i18n.t('esg.certificate.scheme.good')},
      {value: 4, text: i18n.t('esg.certificate.scheme.very_good')},
      {value: 5, text: i18n.t('esg.certificate.scheme.excellent')},
      {value: 6, text: i18n.t('esg.certificate.scheme.outstanding')}
    ],
    wellList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.silver')},
      {value: 2, text: i18n.t('esg.certificate.scheme.gold')},
      {value: 3, text: i18n.t('esg.certificate.scheme.platinum')}
    ],
    dgnbList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.bronze')},
      {value: 2, text: i18n.t('esg.certificate.scheme.silver')},
      {value: 3, text: i18n.t('esg.certificate.scheme.gold')},
      {value: 4, text: i18n.t('esg.certificate.scheme.platinum')}
    ],
    miljobyggnadList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.bronze')},
      {value: 2, text: i18n.t('esg.certificate.scheme.silver')},
      {value: 3, text: i18n.t('esg.certificate.scheme.gold')}
    ],
    rtsList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.1_star')},
      {value: 2, text: i18n.t('esg.certificate.scheme.2_stars')},
      {value: 3, text: i18n.t('esg.certificate.scheme.3_stars')},
      {value: 4, text: i18n.t('esg.certificate.scheme.4_stars')},
      {value: 5, text: i18n.t('esg.certificate.scheme.5_stars')}
    ],
    fitwellList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.1_star')},
      {value: 2, text: i18n.t('esg.certificate.scheme.2_stars')},
      {value: 3, text: i18n.t('esg.certificate.scheme.3_stars')}
    ],
    greenKeyList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.certified')}
    ],
    stfList: [
      {value: 1, text: i18n.t('esg.certificate.scheme.certified')}
    ],
    widgets: [
      {
        id: 'esg.site',
        type: 'MetadataForm',
        data: {
          icon: 'location_on',
          source: 'siteEsgForCurrentSite',
          titleSource: 'name',
          metadataSource: 'siteEsgMetadata',
          metadata: null,
          category: 'None'
        },
        small: true,
        loading: true,
        maximized: [true]
      },
      {
        id: 'esg.estates',
        type: 'MetadataForm',
        data: {
          icon: 'storage',
          source: 'estatesEsgForCurrentSite',
          titleSource: 'name',
          metadataSource: 'estateEsgMetadata',
          metadata: null,
          category: 'None'
        },
        small: true,
        loading: true,
        maximized: [false]
      }, 
      {
        id: 'esg.buildings',
        type: 'MetadataForm',
        data: {
          icon: 'home',
          source: 'buildingsEsgForCurrentSite',
          titleSource: 'building_name',
          metadataSource: 'buildingEsgMetadata',
          metadata: null,
          category: 'None'
        },
        small: true,
        loading: true,
        statucIcon: false,
        maximized: [false, false, false, false, false]
      },
      {
        id: 'esg.certificates',
        type: 'DataTable',
        loading: false,
        maximazed: [true],
        data: {
          headers: [
            { text: i18n.t('esg.certificate.site_name'), value: 'site_name' },
            { text: i18n.t('esg.certificate.property_name'), value: 'estate_name' },
            { text: i18n.t('esg.certificate.building_name'), value: 'building_name' },
            { text: i18n.t('esg.certificate.level'), value: 'level_name' },
            { text: i18n.t('esg.certificate.status'), value: 'status_name' },
            { text: i18n.t('esg.certificate.scheme'), value: 'scheme_name' },
            { text: i18n.t('esg.certificate.scheme_specification'), value: 'scheme_specification', format: 'List'},
            { text: i18n.t('esg.certificate.grade'), value: 'grade_name'},
            { text: i18n.t('esg.certificate.grade_value'), value: 'grade_value' },
            { text: i18n.t('esg.certificate.date_of_issue'), value: 'issued', format: 'Date' },
            { text: i18n.t('esg.certificate.date_of_expiry'), value: 'expires', format: 'Date' },
            { text: i18n.t('esg.certificate.notes'), value: 'notes' },
            { text: i18n.t('esg.certificate.area'), value: 'area', format: 'Area' },
            { text: i18n.t('Actions'), value: 'esg.certificate.actions' },
          ],
          footers: [{},{},{},{},{},{},{},{},{},{},{},{},{}],
          report: 'getEsgCertificates',
          button: 'addEsgCertificate',
          items: [],
        },
        action: null,
      },
    ]
  },
  getters: {
    getEsgCertificates: (state) => {
      return state.esgCertificates
    },
    getAvailableSpecifications: (state) => {
      return state.loadedSpecifications
    },
    siteEsgForCurrentSite: (state) => {
      return state.loadedEsgSite.find(s => s.id_site === state.currentSiteId)
    },
    estatesEsgForCurrentSite: (state) => {
      return state.loadedEsgEstates.filter(e => e.id_site === state.currentSiteId)
    },
    buildingsEsgForCurrentSite: (state) => {
      return state.loadedEsgBuildings.filter(b => b.id_site === state.currentSiteId)
    },
    getSelectedGradeList: (state) => (schemeId) => {
      switch (schemeId) {
        case 1:
          return state.leedList
        case 2:
          return state.breeamList
        case 3:
          return state.wellList
        case 4:
          return state.dgnbList
        case 5:
          return state.miljobyggnadList
        case 6:
          return state.rtsList
        case 7:
          return null
        case 8:
          return state.fitwellList
        case 9:
          return state.greenKeyList
        case 10:
          return state.stfList
        case 11:
          return null
        default:
          return
      }
    }
  },
  mutations: {
    setCurrentSiteId ( state, siteId ) {
      state.currentSiteId = siteId
    },
    setCertificateData  ( state, certificates) {
      state.esgCertificates = certificates
    },
    setSpecificationData ( state, specifications) {
      state.loadedSpecifications = specifications
    },
    addEsgSites (state, { sites }) {
      sites.forEach(e => {
        const existing = state.loadedEsgSite.findIndex(le => le.site_id === e.site_id)
        if (existing >= 0) {
          state.loadedEsgSite.splice(existing, 1)
        }
      })
      state.loadedEsgSite.push(...sites)
    },
    addEsgEstates (state, { estates }) {
      estates.forEach(e => {
        const existing = state.loadedEsgEstates.findIndex(le => le.estate_id === e.estate_id)
        if (existing >= 0) {
          state.loadedEsgEstates.splice(existing, 1)
        }
      })
      state.loadedEsgEstates.push(...estates)
    },
    addEsgBuildings (state, { buildings }) {
      buildings.forEach(e => {
        const existing = state.loadedEsgBuildings.findIndex(le => le.building_id === e.building_id)
        if (existing >= 0) {
          state.loadedEsgBuildings.splice(existing, 1)
        }
      })
      state.loadedEsgBuildings.push(...buildings)
    },
    setMetadata (state, { 
      siteEsgMetadata, 
      estateEsgMetadata,
      buildingEsgMetadata
     }) {
      state.siteEsgMetadata = siteEsgMetadata === undefined ? state.siteEsgMetadata : siteEsgMetadata
      state.estateEsgMetadata = estateEsgMetadata === undefined ? state.estateEsgMetadata : estateEsgMetadata
      state.buildingEsgMetadata = buildingEsgMetadata === undefined ? state.buildingEsgMetadata : buildingEsgMetadata
    },
  },
  actions: {
    async loadReportData ({ state, commit, rootState, getters }, siteId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      const certificates = await api.reports.postSite('getEsgCertificates', [siteId])
      certificates.forEach(c => {
        const foundSite = rootState.sites.loadedSites.find(s => s.id_site === c.id_site)
        c.site_name = foundSite?.name
      
        const foundScheme = state.schemeList.find(s => s.value === c.scheme)
        c.scheme_name = foundScheme?.text

        if (c.id_estate) {
          const foundEstate = rootState.sites.loadedEstates.find(e => e.estate_id === c.id_estate)
          c.estate_name = foundEstate?.name
        }
        if (c.id_building) {
          const foundBuilding = rootState.sites.loadedBuildings.find(b => b.id_building === c.id_building)
          c.building_name = foundBuilding?.building_name
        }
        if (c.level) {
          const foundLevel = state.levelList.find(l => l.value === c.level)
          c.level_name = foundLevel?.text
        }
        if (c.status) {
          const foundStatus = state.statusList.find(s => s.value === c.status)
          c.status_name = foundStatus?.text
        }
        if (c.grade) {
          let gradeList = getters.getSelectedGradeList(c.scheme)
          const foundGrade = gradeList?.find(g => g.value === c.grade)
          c.grade_name = foundGrade?.text
        }
      })
      commit('setCertificateData', certificates)
    },
    async loadAvailableSpecifications ({ state, commit }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const specifications = await api.esg.certificates().getCertificateSpecifications()
      commit('setSpecificationData', specifications)
    },
    async loadEsgData ({ commit, state, rootState }, siteId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      //Sites data
      const sites = []
      const siteEsgVersion = await api.esg.getData().site(siteId, currentDate)
      if(siteEsgVersion.data !== '') {
        if (siteEsgVersion.id_parking_company !== null) {
          const enterpriseParties = await api.parties.list({ query: { party_type: 0 } })
          const foundParty = enterpriseParties.find(p => p.id === siteEsgVersion.id_parking_company)
          if(foundParty) {
            siteEsgVersion.id_parking_company = foundParty
          }
        }
        sites.push(siteEsgVersion)
      } else {
        const foundSite = rootState.sites.loadedSites.find(ls => ls.id_site === state.currentSiteId)
        const newEsgSite = {
          "site_identifier": foundSite.site_identifier,
          "name": foundSite.name,
          "portfolio": foundSite.portfolio,
          "id_site": foundSite.id_site,
          "site_eu_taxonomy_alignment": false,
          "climate_risk_assessment": null,
          "crrem": null,
          "id_parking_company": null,
          "share_joint_venture": null,
          "esg_notes": null,
          "green_lease": null,
          "property_user_guide": null,
          "tenant_satisfaction_actions": null,
          "non_vegetative_surface": null,
          "version": 0
        }
        sites.push(newEsgSite)
        }
      commit('addEsgSites', { sites })

      //Estates data
      const estates = []
      const estateEsgVersions = await api.esg.getData().estate(siteId, currentDate)

      rootState.sites.loadedEstates.forEach(le => {
        const found = estateEsgVersions.find(esv => esv.id_estate === le.estate_id)
        if (found) {
          estates.push(found)
        } else {
          const newEsgEstate = {
            "name": le.name,
            "estate_identifier": le.estate_identifier,
            "id_site": le.id_site,
            "id_estate": le.estate_id,
            "real_estate_company_name": le.Real_estate_company_name,
            "latest_esg_audit": null,
            "other_waste": null,
            "waste_fractions_collected": [],
            "version": 0
          }
          estates.push(newEsgEstate)
        }
        })
      commit('addEsgEstates', { estates })

      //Buildings data
      const buildings = []
      const buildingEsgVersions = await api.esg.getData().building(siteId, currentDate)

      rootState.sites.loadedBuildings.forEach(lb => {
        const found = buildingEsgVersions.find(bsv => bsv.id_building === lb.id_building)
        if(found) {
          buildings.push(found)
        } else {
          const newEsgBuilding = {
            "building_code": lb.building_code,
            "building_name": lb.building_name,
            "building_usage": lb.building_usage,
            "address": lb.address,
            "id_site": lb.id_site,
            "id_estate": lb.id_estate,
            "id_building": lb.id_building,
            "carbon_free_district_heating_source": null,
            "carbon_free_district_heating": null,
            "carbon_free_electricity": null,
            "scope_purchased_electricity": null,
            "electricity_source": null,
            "solar_panels": null,
            "geothermal_energy_production": null,
            "other_renewable_energy": null,
            "solar_power_size": null,
            "lca_calculation_done": null,
            "energy_consumption_data": null,
            "energy_label": null,
            "epc_energy_demand": null,
            "epc_expires": null,
            "epc_version": null,
            "eu_taxonomy_eligibility": null,
            "eu_taxonomy_alignment": null,
            "refridgerant_type": null,
            "refridgerant_amount": null,
            "refridgerants_last_added_amount": null,
            "refridgerants_replace_plan": null,
            "digital_display": null,
            "version": 0
          }
          buildings.push(newEsgBuilding)
        }
      })
      commit('addEsgBuildings', {buildings})

    },
    async loadEsgMetadata ({ commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      if (state.siteEsgMetadata === null) {
        const siteEsgMetadata = await api.esg.metadata().site()
        commit('setMetadata', {siteEsgMetadata})
      }

      if (state.estateEsgMetadata === null) {
        const estateEsgMetadata = await api.esg.metadata().estate()
        commit('setMetadata', {estateEsgMetadata})
      }

      if (state.buildingEsgMetadata === null) {
        const buildingEsgMetadata = await api.esg.metadata().building()
        commit('setMetadata', {buildingEsgMetadata})
      } 
    },
  }
} 
