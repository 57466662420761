
<template>
  <div>
    <v-tab-item
      :key="0"
    >
      <ValidationObserver ref="observer">
        <v-form
          ref="form1"
          class="pt-2"
        >
          <h3 class="form-headers">
            {{ $t('create_rent_contract.additional_info') }}
          </h3>
          <ValidationProvider
            ref="provider"
            v-slot="{ errors }"
            :rules="`required|uniqueContractNumber: ${contractNumbers}|validContractNumber: ${newContractNumber}, ${settings.contractTemplate}, ${settings.defaultContractTemplate}`"
            :name="$t('Contract number')"
          >
            <v-text-field
              v-if="isNewContract && settings.contractNumberManualInput"
              v-model="contractNumber"
              :label="$t('Contract number')"
              :error-messages="$t(errors)"
              class="form-fields mt-4 ml-2"
              @change="onDataChanged()"
              @input="emitValidation"
            />
          </ValidationProvider>
          <v-autocomplete
            v-model="contractLayout"
            :items="parties"
            :label="$t('ContractLayout')"
            :readonly="contract && contract.isDraft === false"
            item-text="name"
            item-value="id"
            class="form-fields mt-4 ml-2"
            @change="onDataChanged()"
          />
          <ValidationProvider
            v-slot="{ errors }"
            :rules="`${isOutRent ? 'required' : ''}`"
          >
            <v-autocomplete
              v-model="contractType"
              :items="contractTypes"
              :label="$t('ContractType')"
              :error-messages="errors[0]"
              :readonly="contract && contract.isDraft === false"
              item-text="name"
              item-value="id"
              class="form-fields mt-4 ml-2"
              :hint="$t('Required')"
              persistent-hint            
              required
              @change="onDataChanged()"
            />
          </ValidationProvider>
          <v-row
            align="center"
          >
            <v-col
              v-if="isTemplateFieldVisible('contracttype', contractTemplate)"
              cols="6"
            >
              <v-text-field
                v-model="typeForContract"
                :label="$t('leasing.contract_type')"
                :readonly="true"
                type="text"
                class="form-fields mt-4 ml-2"
                @input="onDataChanged()"
              />
            </v-col>
            <v-col cols="2">
              <v-checkbox
                v-model="isSublease"
                :label="$t('Subletting')"
                class="form-fields mt-8 mx-2 px-2"
                @input="onDataChanged()"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="housingModification"
                :items="housingModifications"
                :label="$t('HousingModifications')"
                item-text="text"
                item-value="value"
                class="form-fields mt-4 ml-2"
                @change="onDataChanged()"
              />
            </v-col>
            <v-textarea
              v-if="housingModification === 'contract.housing_modifications_agreed_upon'"
              v-model="housingModificationInfo"
              :label="$t('HousingModificationsInfo')"
              type="text"
              class="form-fields mt-4 ml-2"
              @input="onDataChanged()"
            />
          </v-row>
          <v-text-field 
            v-if="isTemplateFieldVisible('spaceuser', contractTemplate)"
            v-model="spaceuser"
            :label="$t('Space user')"
            type="text"
            class="form-fields mt-4 ml-2"
            @input="onDataChanged()"
          />
          <v-text-field
            v-model="spaceusage"
            :label="$t('SpaceUsage')"
            type="text"
            class="form-fields mt-4 ml-2"
            @input="onDataChanged()"
          />
          <v-row
            align="center"
          >
            <v-col cols="6">
              <v-autocomplete
                v-if="isTemplateFieldVisible('classification', contractTemplate)"
                v-model="classification"
                :items="classificationsItems"
                :label="$t('Contract_Classification')"
                item-text="name"
                item-value="id"
                class="form-fields mt-4 ml-2"
                @change="onDataChanged()"
              />
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                v-if="isTemplateFieldVisible('classification2', contractTemplate)"
                v-model="classification2"
                :items="classificationsTwoItems"
                :label="$t('Contract_ClassificationTwo')"
                item-text="text"
                item-value="value"
                class="form-fields mt-4 ml-2"
                @change="onDataChanged()"
              />
            </v-col>
          </v-row>

          <v-row
            align="center"
          >
            <v-col cols="6">
              <v-text-field
                v-if="isTemplateFieldVisible('rentaldecision', contractTemplate)"
                v-model="rentalDecision"
                :label="$t('RentalDecision')"
                type="text"
                class="form-fields mt-4 ml-2"
                @input="onDataChanged()"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-if="isTemplateFieldVisible('costsharing', contractTemplate)"
                v-model="costshare"
                :items="costSharing"
                :label="$t('CostSharing')"
                item-text="text"
                item-value="value"
                class="form-fields mt-4 ml-2"
                @change="onDataChanged()"
              />
            </v-col>
          </v-row>
          <v-combobox
            v-model="selectedTags"
            :items="availableTags"
            :label="$t('Category')"
            clearable
            multiple
            class="form-fields mt-4 ml-2"
            item-text="name"
            item-value="id"
            small-chips
            deletable-chips
            @change="onDataChanged()"
          />
        </v-form>
      </ValidationObserver>
    </v-tab-item>
  </div>
</template>
  
<script>

  import { mapGetters } from 'vuex'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { i18n } from '../../../../plugins/i18n'
  
  const getContractNumberFormat = (str) => {
    let result = ""
    for(let char of str) {
      if (isNumber(char)) {
        result = result.concat("0")
        continue  
      }
      result = result.concat("A")  
    }
    return result 
  }
      
  const isNumber = (char) => {
    return /[0-9]/.test(char)
  }
  
  extend('uniqueContractNumber', {
    params: ['contractNumbers'],
    message: i18n.t("Existing contract number"),
    validate: (value, { contractNumbers }) => {
      if(!contractNumbers) {
        return true
      }
      contractNumbers = contractNumbers.map(str => str.trim())
      if (contractNumbers.includes(value)) {
        return false
      }
      return true
    } 
  })

  extend('validContractNumber', {
    params: ['newContractNumber', 'contractTemplate', 'defaultContractTemplate'],
    computesRequired: true,
    message: i18n.t("Invalid contract number format") + ' {contractTemplate}',
    validate: (value, { newContractNumber, contractTemplate, defaultContractTemplate }) => {
      if (value.trim() === newContractNumber.trim()) {
        return {
          required: true,
          valid: true
        }
      }

      let template = contractTemplate == null ? defaultContractTemplate.trim() : contractTemplate.trim()
      let inputFormat = getContractNumberFormat(value.trim())

      if (inputFormat === template) {
        return {
          required: true,
          valid: false
        }
      }
      
      return {
        required: true,
        valid: true
      }
    }
  })

  export default {
    name: "RentContractAdditionalInfoTab",
    components: {
      ValidationProvider,
      ValidationObserver
    },    
    
    props: {
      contractId: {
        type: Number,
        default: undefined
      },
      additionalInfo: {
        type: Object,
        default: undefined
      },
      tenantId: {
        type: Number,
        default: undefined
      },
      tenantType: {
        type: String,
        default: undefined
        },
      isOutRent: {
        type: Boolean,
        default: () => undefined
      },
      isNewContract: {
        type: Boolean,
        default: () => undefined
      },
      contract: {
        type: Object,
        default: () => undefined
      },
      settings: {
        type: Object,
        default: () => undefined
      },
      newContractNumber: {
        type: String,
        default: undefined
      },
      contractNumbers: {
        type: Array,
        default: undefined
      },
      allParties: {
        type: Array,
        default: () => []
      },
      tags: {
        type: Array,
        default: () => []
      },
      contractTemplate: {
        type: Object,
        default: undefined
      }
    },
    data () {
      return (
        {
          contractNumber: undefined,    
          parties: undefined,
          contractLayout: undefined,
          contractType: undefined,
          typeForContract: undefined,
          housingModification: undefined,
          housingModificationInfo: undefined,
          spaceuser:undefined,
          spaceusage:undefined,
          classification:undefined,
          classification2:undefined,   
          rentalDecision: undefined,
          costshare:undefined,
          contractTypes: [],
          housingModifications: [],
          classificationsItems: [],
          classificationsTwoItems: [],
          costSharing: [],
          tenantClientele: undefined,
          isSublease: undefined,
          selectedTags: [],
          availableTags: [],
        }
      )
    },
    computed: {
      ...mapGetters('app', ['definitionsByGroupLabel']),
      ...mapGetters('rentContracts', ['isTemplateFieldVisible']),
    },
    watch: {
      newContractNumber () {        
        if(this.newContractNumber) {
          this.contractNumber = this.newContractNumber
        }
      },
      tenantId () {
        if (this.tenantType === 'company') {
          this.defineTypeForContract()
        } else {
          this.typeForContract = this.$t('External')
        }
        this.onDataChanged()
      },
      tenantType () {
        if (this.tenantType === 'company') {
          this.defineTypeForContract()
        } else {
          this.typeForContract = this.$t('External')
        }
        this.onDataChanged()
      },
      isSublease () {
        if(this.isSublease) {
          this.typeForContract = this.$t('Sublease')
        }
        if(!this.isSublease) {
          this.defineTypeForContract()
        }
        this.onDataChanged()
      },
      additionalInfo () {
        if (this.contractId) {
          if (this.additionalInfo) {
            this.contractLayout = this.additionalInfo.contractlayout
            this.contractType = this.additionalInfo.contracttype
            this.typeForContract = this.additionalInfo.typeforcontract
            this.housingModification = this.additionalInfo.housingModification
            this.housingModificationInfo = this.additionalInfo.housingModificationInfo
            this.spaceuser = this.additionalInfo.spaceuser
            this.spaceusage = this.additionalInfo.spaceusage
            this.classification = this.additionalInfo.classification
            this.classification2 = this.additionalInfo.classification2
            this.rentalDecision = this.additionalInfo.rentaldecision
            this.costshare = this.additionalInfo.costshare
            this.isSublease = this.additionalInfo.issublease
          }
        }
      },
      tags: {
        deep: true,
        handler: function (existingSelection) {
          if (this.contractId) {
            this.selectedTags = existingSelection ?? []
          }
        },
      },
    },
    async mounted () {
      this.getTags()
      this.SetupSelectionLists()
      this.housingModification = 'contract.no_housing_modifications'
      if (!this.isOutRent) {
        this.typeForContract = this.$t('Internal')
      }
    },
    methods: {
      async emitValidation () {
        await this.$refs.observer.validate()
        let proResult = await this.$refs.provider.validate()
        let isValid = proResult.valid
        this.$emit("contractNumberValidated", isValid)
      },
      async SetupSelectionLists () {
        const contractTypes = await this.$rambollfmapi.definitions.list('contract.additionalinfo_contracttypes')
        const tempTypes = []
        for (let definition in contractTypes) {
          tempTypes.push({
            id: contractTypes[definition].label, 
            name: this.$t(contractTypes[definition].label)
          })
        }
        this.contractTypes = tempTypes.sort((a, b) => a.name > b.name ? 1 : -1)
        const housingModifications = await this.$rambollfmapi.definitions.list('contract.additionalinfo.housing_modifications')
        for (let definition in housingModifications) {
          this.housingModifications.push(
            {
              text: this.$t(housingModifications[definition].label),
              value: housingModifications[definition].label
            }
          )
        }

        const classifications = await this.$rambollfmapi.definitions.list('contract.additionalinfo_classifications')
        for (let definition in classifications) {
          this.classificationsItems.push(classifications[definition].label)
        }

        const classifications2 = await this.$rambollfmapi.definitions.list('contract.additionalinfo_classifications2')
        for (let definition in classifications2) {
          this.classificationsTwoItems.push(classifications2[definition].label)
        }

        const costSharing = await this.$rambollfmapi.definitions.list('contract.additionalinfo_costsharing')
        for (let definition in costSharing) {
          this.costSharing.push(costSharing[definition].label)
        }
      },
      onDataChanged () {
        const additionalInfo = {
          contractlayout: this.contractLayout,
          contracttype: this.contractType,
          typeforcontract: this.typeForContract,
          housingModification: this.housingModification,
          housingModificationInfo: this.housingModification === 'contract.housing_modifications_agreed_upon' ? this.housingModificationInfo : null,
          spaceuser: this.spaceuser,
          spaceusage: this.spaceusage,
          classification: this.classification,
          classification2: this.classification2,
          rentaldecision: this.rentalDecision,
          costshare: this.costshare,
          tenantId: this.tenantId,
          issublease: this.isSublease
        }

        let tagObjects = []
        if (this.selectedTags != null && this.selectedTags.length > 0) {
          this.selectedTags.forEach(tag => {
            let updatedTag = null
            if (typeof tag === 'string') {
              updatedTag = { name: tag }
            } else {
              updatedTag = { ...tag }
            }

            if (this.contractId) {
              updatedTag.idContract = this.contractId
            }

            tagObjects.push(updatedTag)
          })
        }

        this.$emit("additionalInfoUpdated", { additionalInfo, tags: tagObjects })
        this.$emit("contractNumberUpdated", this.contractNumber)
      },
      async getCustomerGroup () {
        if (typeof this.allParties !== 'undefined'){
          const parties = this.allParties
          const filteredParty = await new Promise(resolve => {
            resolve(parties.filter(p => p.id === this.tenantId));
          });
          this.tenantClientele = filteredParty[0]?.clientele
          this.checkTenantClientele()
          
        }
      },
      checkTenantClientele () {
        const clientele = this.tenantClientele
        if (clientele === null) {
          this.typeForContract = null
        }
        if (clientele === 'Liikelaitos' || clientele === 'Toimiala' || clientele === 'Virasto') {
          this.typeForContract = this.$t('Internal')
        } else { 
          this.typeForContract = this.$t('External')
        }
      },
      defineTypeForContract () {
        if (!this.isNewContract) {
          this.contractNumber = this.contract.contractNumber
        }
        if (!this.isOutRent) {
          this.typeForContract = this.$t('Internal')
        } else {
          
          this.getCustomerGroup()
          this.checkTenantClientele()
          if (this.isSublease === true) {
            this.typeForContract = this.$t('Sublease')
          }
        }

          //definitions for clientele
          //Liikelaitos -> Sisäinen
          //Toimiala -> Sisäinen
          //Virasto -> Sisäinen
          //Tytäryhtiö -> Ulkoinen
          //Muu -> Ulkoinen
      },
      async getTags () {
        this.availableTags = await this.$rambollfmapi.contracts.tags.get()
      },
    }
  }
  </script>
  
  <style scoped>
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
.v-input--checkbox {
  display: inline-flex;
}
</style>