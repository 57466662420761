<template>
  <v-container>
    <v-row
      id="rental-process-info-ball"
      justify="center"
      align="center"
      no-gutters
    >
      <v-col class="shrink">
        <v-row
          class="rental-process-info-container"
          no-gutters
        >
          <info-ball
            :info="infoText"
            :bottom="true"
            attach="#rental-process-info-ball"
          />
        </v-row>
      </v-col>
      <v-col
        v-if="inactive"
        class="shrink"
      >
        <v-btn
          color="red"
          dark
        >
          <v-icon left>
            sentiment_dissatisfied
          </v-icon>
          {{ $t("Inactive") }}
        </v-btn>
      </v-col>
      <v-col
        v-for="(button, index) in buttons"
        :key="index"
        class="shrink"
      >
        <rental-process-button
          v-if="!inactive"
          :id="index"
          :color="index < stageNumber ? button.color : 'grey'"
          :icon="button.icon"
          :text="
            $vuetify.breakpoint.mdAndUp && stageNumber === index + 1 ? button.text : null
          "
          @click="onStageClick(button)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rentalProcessStageColors } from "../../constants/colors";

import InfoBall from "../InfoBall.vue";
import RentalProcessButton from "./RentalProcessButton.vue";

import { mapGetters, mapActions } from 'vuex'

export default {
  name: "RentalProcessStatus",
  components: {
    InfoBall,
    RentalProcessButton,
  },
  props: {
    rentalProcess: { type: Object, default: null}
  },
  emits: ['update'],
  data () {
    return {
      icons: {
        "1. Lead": "lightbulb",
        "2. Prospect": "person_add",
        "3. NeedsAssessment": "map",
        "4. Proposal": "description",
        "5. ContractNegotiation": "handshake",
        "6. Won": "emoji_events",
        "7. RentAdministration": "star_half",
        "8. Closed": "star"
      }
    };
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'settings']),

    inactive () {
      return this.rentalProcess?.status === 'Inactive'
    },
    stage () {
      return this.rentalProcess?.stage
    },
    rentalProcessId () {
      return this.rentalProcess?.id ?? -1
    },

    stages () {
      return this.definitionsByGroupLabel('leasing.rental_process_stage')
    },

    buttons () {
      const buttons = this.stages.map((stage, index) => ({
        id: stage.id,
        label: stage.label,
        color: rentalProcessStageColors[stage.label],
        text: this.$t(stage.label),
        icon: this.icons[stage.label],
        stageNumber: index + 1
      }))
      return buttons
    },


    infoText () {
      let stageText = this.$t("Inactive");

      if (!this.inactive) {
        stageText = this.buttons[this.stageNumber - 1]?.text;
      }
      return `${this.$t("rental_process.renting_at_stage")}: ${stageText}`;
    },
    stageNumber () {
      if (this.stage) {
        return parseInt(this.stage?.charAt(0))
      }
      return -1
    }
  },
  methods: {
    ...mapActions('rentalProcess', ['patchRentalProcess']),
    async onStageClick (button) {
      let openAddMFilesIds = false
      if (this.stageNumber !== button.stageNumber) {
        if (button.stageNumber >= 6 && this.stageNumber < 6 && this.settings.hasMFilesIntegration) {
          openAddMFilesIds = true
        }
        const stage = { ...this.stages.find(s => s.id === button.id), openAddMFilesIds: openAddMFilesIds }
        this.$emit('updateStage', stage)
      }
    },
  },
};
</script>

<style scoped>
</style>
