<template>
  <div>
    <v-dialog
      v-model="showFullscreen"
      fullscreen
      hide-overlay
    >
      <v-card class="rounded">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <div>
                <v-btn
                  large
                  icon
                  absolute
                  right
                  style="margin-top: 0.8em"
                  @click="showFullscreen = false"
                >
                  <v-icon>close</v-icon>
                  <span class="d-sr-only">{{ $t("Close") }}</span>
                </v-btn>
                <v-card-title
                  primary-title
                  style="width: 80%"
                >
                  <h1>
                    {{ title }}
                  </h1>
                </v-card-title>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <filter-group
                :headers="filterHeaders"
                @change="onFilterValuesChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                :value="[0]"
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t("Information") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <kanban-container
                      :data="data"
                      :filter="filter"
                      :general-search="generalSearch"
                      @click="onClick($event)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12">
          <kanban-container
            :data="data"
            :filter="filter"
            @click="onClick($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <filter-button
            v-if="!!filterOptions"
            :options="filterOptions"
            @change="onFilterChange"
          />
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end align-center"
        >
          <v-btn
            class="browse-button"
            small
            outlined
            rounded
            :aria-label="`${$t('Browse')}: ${title}`"
            @click="showFullscreen = true"
          >
            {{ $t("Browse") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import FilterButton from "../components/FilterButton.vue"
import FilterGroup from "../components/FilterGroup.vue"
import KanbanContainer from "./KanbanContainer.vue"

/**
 * Function for selecting which items should be passed to this header. If this function
 * is not provided, the items list wont be filtered at all. Each item in the items list will be
 * fed to this function. This function should return boolean. If the return value is truthy,
 * item will be shown on this column.
 * @typedef {Function} KanbanWidgetDataSelector
 * @param {Object} item - Row item
 * @returns {Boolean} - If true, row will be selected to this header.
 */

/**
 * You can define the footer value by defining function which calculates and returns the value for the footer.
 * List of items shown on the column is passed to this function. If footer is not defined, it will not be shown.
 * @typedef {Function} KanbanWidgetDataFooter
 * @param {Object[]} items - The list of items on this column. All filters are applied here.
 * @returns {String} - What ever you want to render on the footer.
 */

/**
 * Options for controlling columns and what data the column contains.
 * @typedef {Object} KanbanWidgetDataHeader
 * @property {String} text - The title of the column.
 * @property {KanbanWidgetDataSelector} selector - If provided, this function is called with each item in the items list.
 * @property {String} title - The name of the field which is shown on chips title field.
 * @property {String} subTitle - The name of the field which is shown on chips subTitle field.
 * @property {String} icon - The name of the field which is fed to the chip icon. Icon should be object containing icon and color of the icon.
 * @property {KanbanWidgetDataFooter} footer - Function which returns the value for the footer of the column.
 */

/**
 * Kanban widget data object.
 * @typedef {Object} KanbanWidgetData
 * @property {KanbanDataHeader[]} headers - Headers list. Each header in list will represent one column.
 * @property {Object[]} items - The content of the widget
 */

export default {
  components: { KanbanContainer, FilterButton, FilterGroup },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    title: {
      type: String,
      default: function () {
        return ""
      },
    },
    filterHeaders: {
      type: Array,
      default: function () {
        return [
          {
            text: this.$t("Search"),
            value: "generalSearch",
          },
        ]
      },
    }
  },
  data () {
    return {
      showFullscreen: false,
      filter: null, // Filter for the bottom left button.
      generalSearch: null, // Values for the general filter on browse view.
    }
  },
  computed: {
    filterOptions () {
      if (this.data && this.data.filter) {
        return this.data.filter.options
      }

      return null
    },
  },
  methods: {
    onFilterChange (newFilter) {
      this.filter = newFilter
    },
    onFilterValuesChange (newFilterValues) {
      this.generalSearch = newFilterValues.generalSearch
    },
    onClick (event) {
      const { header, row } = event

      if (header.event) {
        this.$emit("triggerCustomEvents", { eventName: header.event, row })
      }
    },
  },
}
</script>
<style scoped>
.browse-button {
  margin-right: 25px;
}
</style>
