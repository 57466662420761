// import loglevel from 'loglevel'
export default {
    // V6 Part 1 = Asset performance
    v6Part1 () {
        return [
          { category: 'part1_health_and_wellbeing' },
          { category: 'part1_energy' },
          { category: 'part1_transport'},
          { category: 'part1_water' },
          { category: 'part1_resources' },
          { category: 'part1_resilience' },
          { category: 'part1_land_use_and_ecology' },
          { category: 'part1_pollution' },
          { category: 'part1_exemplary' },
          ]
    },
    // V6 Part 2 = Management performance
    v6Part2 () {
        return [
          { category: 'part2_management' },
          { category: 'part2_health_and_wellbeing' },
          { category: 'part2_energy' },
          { category: 'part2_water' },
          { category: 'part2_resources' },
          { category: 'part2_resilience' },
          { category: 'part2_land_use_and_ecology' },
          { category: 'part2_pollution' },
          { category: 'part2_exemplary' },
          ]
    },
    v2015Part1 () {
      return [
        { category: 'part1_health_and_wellbeing' },
        { category: 'part1_energy' },
        { category: 'part1_transport'},
        { category: 'part1_water' },
        { category: 'part1_materials' },
        { category: 'part1_waste' },
        { category: 'part1_land_use_and_ecology' },
        { category: 'part1_pollution' }
        ]
    },
    v2015Part2 () {
      return [
        { category: 'part2_management' },
        { category: 'part2_health_and_wellbeing' },
        { category: 'part2_energy' },
        { category: 'part2_water' },
        { category: 'part2_materials' },
        { category: 'part2_land_use_and_ecology' },
        { category: 'part2_pollution' }
        ]
    },
    getDataSetByVersion (part, certificationVersion)
    {
      if(part === 1) {
        if(certificationVersion === 'V6 Commercial' || certificationVersion === 'V6 Residential') {
          return this.v6Part1()
        } else if ( certificationVersion === '2015') {
          return this.v2015Part1()
        } else {
          return
        }
      }
      if(part === 2) {
        if(certificationVersion === 'V6 Commercial' || certificationVersion === 'V6 Residential') {
          return this.v6Part2()
        } else if (certificationVersion === '2015') {
          return this.v2015Part2()
        } else {
          return
        }
      }
    }
}