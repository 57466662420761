<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
  >
    <AriaLive />
    <BaseModal
      @cancel="close()"
    >
      <template #title>
        {{ $t(getModalTitle()) }}
      </template>
      <template #content>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|decimal:2,comma`"
              >
                <v-text-field
                  v-model="originalRepairCost"
                  :label="$t('repair.originalRepairCost')"
                  suffix="€"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  :error-messages="$t(errors[0])"
                  class="form-fields mt-6 ml-2 mr-2"
                  @change="onOriginalRepairCostChange"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`decimal:2,comma`"
              >
                <v-text-field
                  v-model="repairCost"
                  :label="$t('repair.repairCost')"
                  suffix="€"
                  :error-messages="$t(errors[0])"
                  class="form-fields mt-6 ml-2 mr-2"
                  @change="onRepairCostChange"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|maxValue:100|minValue:0|decimal:2,comma`"
              >
                <v-text-field
                  v-model="depreciationRatio"
                  :label="$t('technicalValues.depreciationRatio')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  placeholder="100"
                  :error-messages="$t(errors[0])"
                  class="form-fields mt-6 ml-2 mr-2"
                  @change="onDepreciationRatioChange"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|decimal:0,comma|minValue:0`"
              >
                <v-text-field
                  v-model="depreciationPeriod"
                  :label="$t('technicalValues.depreciationPeriod')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  placeholder="30"
                  :error-messages="$t(errors[0])"
                  class="form-fields mt-6 ml-2 mr-2"
                  @change="onDepreciationPeriodChange"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-menu
                class="mt-6 ml-2"
                offset-y
                :close-on-content-click="false"
                min-width="0"
              >
                <template #activator="{ on }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`required`"
                  >
                    <v-text-field
                      :value="formatDate(repairDate)"
                      :label="$t('repair.repairDate')"
                      :hint="$t('Required')"
                      persistent-hint
                      :error-messages="errors[0]"
                      required
                      class="form-fields mt-6 ml-2 mr-2"
                      prepend-icon="calendar_today"
                      v-on="on"
                      @change="onRepairDateChange"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="repairDate"
                  :allowed-dates="getAllowedRepairDates"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  :locale="$i18n.locale"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="isTotalRepair"
                class="form-fields mt-6 ml-2"
                :label="$t('repair.isTotalRepair')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                rules="maxDescription:500"
              >
                <v-text-field
                  v-model="description"
                  :label="$t('repair.description')"
                  :error-messages="$t(errors[0])"
                  counter="500"
                  class="form-fields mt-6 ml-2 mr-2 mb-4"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-col 
          class="text-left validation-error ml-2 mb-4"
        >
          {{ errorText }}
        </v-col>
        <v-col
          cols="4"
          class="text-right align-center"
        >
          <small v-if="invalid">{{ $t('FillRequiredFields') }}</small>
          <v-btn
            rounded
            depressed
            class="primary"
            :disabled="invalid"
            @click="saveRepair"
          >
            {{ $t('repair.save') }}
          </v-btn>
        </v-col>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max, double } from 'vee-validate/dist/rules'
import moment from 'moment'
import BaseModal from './general/BaseModal'
import AriaLive from './AriaLive.vue'

extend('maxDescription', { ...max, message: 'repair.descriptionError' })

extend('maxValue', {
  validate: (value, { maxValue = 100 }) => {
    return parseFloat(value) <= maxValue
}, message: 'repair.maxDepreciationRatio' })

extend('minValue', {
  validate: (value, { minValue = 0 }) => {
    return parseFloat(value) >= minValue
}, message: 'repair.minValue' })

extend('decimal', { ...double, message: 'leasing.incorrect_input' })

export default {
  name: "NewRepairModal",
  components: {
    BaseModal,
    AriaLive,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    repairToEdit: {
      type: Object,
      default: () => undefined
    },
    building: {
      type: Object,
      default: () => undefined
    },
  },
  data () {
    return {
      originalRepairCost: undefined,
      repairCost: undefined,
      depreciationRatio: undefined,
      depreciationPeriod: undefined,
      repairDate: undefined,
      isTotalRepair: false,
      description: undefined,
      errorText: undefined,
    }
  },
  computed: {
    ...mapState('app', [ 'currentDate' ]),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionByLabel']),
  },
  async mounted () {
    this.initializeForm()
  },
  methods: {
    initializeForm () {
      if (this.repairToEdit) {
        this.onOriginalRepairCostChange(this.repairToEdit.originalRepairCost)
        this.onRepairCostChange(this.repairToEdit.repairCost)
        this.onDepreciationRatioChange(this.repairToEdit.depreciationRatio * 100)
        this.onDepreciationPeriodChange(this.repairToEdit.depreciationPeriod)
        this.onRepairDateChange(this.formatDate(this.repairToEdit.repairDate))
        
        this.isTotalRepair = this.repairToEdit.isTotalRepair
        this.description = this.repairToEdit.description
      }
    },
    getModalTitle () {
      return this.repairToEdit ? 'repair.update' : 'repair.create'
    },
    getAllowedRepairDates (date) {
      const selectedDate = new Date(date)
      selectedDate.setHours(0, 0, 0, 0);
      const buildingDate = new Date(this.building.completed_year, 11, 31)
      buildingDate.setHours(23, 59, 59, 0);
      
      return selectedDate >= buildingDate
    },
    close (withUpdate = false) {
      if (withUpdate) {
        this.$emit('close', withUpdate)
      }
      else if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
      }
    },
    onRepairDateChange (val) {
      this.repairDate = this.getValidDate(val)
    },
    onOriginalRepairCostChange (val) {
      let validatedValue = this.validateFloat(val)
      if (validatedValue) this.originalRepairCost = validatedValue
    },
    onRepairCostChange (val) {
      let validatedValue = this.validateFloat(val)
      if (validatedValue) this.repairCost = validatedValue
    },
    onDepreciationPeriodChange (val) {
      let validatedValue = this.validateInt(val)
      if (validatedValue) this.depreciationPeriod = validatedValue
    },
    onDepreciationRatioChange (val) {
      let validatedValue = this.validateFloat(val)
      if (validatedValue) this.depreciationRatio = validatedValue
    },
    validateFloat (value) {
      let float = parseFloat(value?.toString()?.replace(',', '.'))
      if (isNaN(float)) return null
      return float.toFixed(2).toString().replace('.', ',')
    },
    validateInt (value) {
      let int = parseInt(value)
      return isNaN(int) ? null : int
    },
    getValidDate (val) {
      const date = moment(val, "DD.MM.YYYY");
      return date.isValid() ? date.format("YYYY-MM-DD") : undefined
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    saveRepair () {
      let repair = {
        originalRepairCost: this.originalRepairCost.toString().replace(',', '.'),
        repairCost: this.repairCost?.toString()?.replace(',', '.'),
        depreciationRatio: parseFloat(
            this.depreciationRatio.toString().replace(',', '.')
          ) / 100,
        depreciationPeriod: this.depreciationPeriod,
        repairDate: this.repairDate,
        isTotalRepair: this.isTotalRepair,
        description: this.description,
      }
      if (this.repairToEdit) {
        repair.id = this.repairToEdit.id
        repair.idBuilding = this.repairToEdit.idBuilding
        this.updateRepair(repair)
      } else {
        this.createRepair(repair)
      }
    },
    async updateRepair (repair) {
      let updatedRepair = await this.$rambollfmapi.buildingRepairs.update(this.building.id_building, repair)
      if (updatedRepair?.isAxiosError) {
        this.errorText = this.$t('repair.saveFailed')
      } else {
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
        this.close(true)
      }
    },
    async createRepair (repair) {
      let createdRepair = await this.$rambollfmapi.buildingRepairs.create(this.building.id_building, repair)
      if (createdRepair?.isAxiosError) {
        this.errorText = this.$t('repair.saveFailed')
      } else {
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
        this.close(true)
      }
    },
  }
}
</script>
  
<style scoped>
.form-headers {
  margin: 5px 5px 5px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}

.table {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 1em;
}

.height-auto {
  height: auto !important;
}
</style>
    