import { fetchReport } from "./requests/fetchReport"

export default {
  namespaced: true,
  state: {
    carparkContracts: [],
    carparkContractsLoading: false,

    futureCarparkContracts: [],
    futureCarparkContractsLoading: false,

    carparkOccupancyRates: [],
    carparkOccupancyRatesLoading: false,

    carParksAndParkingZonesWithRent: [],
    carParksAndParkingZonesWithRentLoading: false,

    newCarparkContractsloading: false,
    newCarparkContracts: [],
  },
  getters: {},
  mutations: {
    setCarparkContracts (state, data) {
      state.carparkContracts = data
    },
    setCarparkContractsLoading (state, data) {
      state.carparkContractsLoading = data
    },
    setFutureCarparkContracts (state, data) {
      state.futureCarparkContracts = data
    },
    setFutureCarparkContractsLoading (state, data) {
      state.futureCarparkContractsLoading = data
    },
    setCarparkOccupancyRates (state, data) {
      state.carparkOccupancyRates = data
    },
    setCarparkOccupancyRatesLoading (state, data) {
      state.carparkOccupancyRatesLoading = data
    },
    setCarParksAndParkingZonesWithRent (state, data) { 
      state.carParksAndParkingZonesWithRent = data
    },
    setCarParksAndParkingZonesWithRentLoading (state, data) {
      state.carParksAndParkingZonesWithRentLoading = data
    },
    setNewCarparkContracts (state, data) {
      state.newCarparkContracts = data
    },
    changeNewCarparkContractsLoading (state, data) {
      state.newCarparkContractsLoading = data
    },
  },
  actions: {
    async fetchCarparkContracts ({ commit, rootState }, siteId) {
      commit("setCarparkContractsLoading", true)
      try {
        const data = await fetchReport(rootState, "carparkcontracts", siteId)
        commit("setCarparkContracts", data)
      } catch (err) {
        throw Error(err)
      } finally {
        commit("setCarparkContractsLoading", false)
      }
    },
    async fetchFutureCarparkContracts ({ commit, rootState }, siteId) {
      commit("setFutureCarparkContractsLoading", true)
      try {
        const data = await fetchReport(
          rootState,
          "futurecarparkcontracts",
          siteId
        )
        commit("setFutureCarparkContracts", data)
      } catch (err) {
        throw Error(err)
      } finally {
        commit("setFutureCarparkContractsLoading", false)
      }
    },
    async fetchCarparkOccupancyRates ({ commit, rootState }, siteId, time) {
      try {
        commit("setCarparkOccupancyRatesLoading", true)

        const parks = await fetchReport(rootState, "GetSiteCarparks", siteId, time)
        const areas = await fetchReport(
          rootState,
          "GetSiteCarparksAreas",
          siteId
        )
        const priviledges = await fetchReport(
          rootState,
          "GetSiteCarparksPrivileges",
          siteId
        )

        const all = [...parks, ...areas, ...priviledges]

        commit("setCarparkOccupancyRates", all)
      } catch (err) {
        commit("setCarparkOccupancyRates", [])
      } finally {
        commit("setCarparkOccupancyRatesLoading", false)
      }
    },
    async fetchCarParksAndParkingZonesWithRent ({ commit, rootState }, siteId, time) {
      try {
        commit("setCarParksAndParkingZonesWithRentLoading", true)

        const data = await fetchReport(rootState, "GetCarParksAndParkingZonesWithRent", siteId, time)
        
        commit("setCarParksAndParkingZonesWithRent", data)
      } catch (err) {
        commit("setCarParksAndParkingZonesWithRent", [])
      } finally {
        commit("setCarParksAndParkingZonesWithRentLoading", false)
      }
    },
    async getNewCarparkContracts ({ commit, rootState }, time, siteId) {
      if (time == null) {
        time = rootState.app.currentDate
      }
      try {
        commit('changeNewCarparkContractsLoading', true)

        const data = await fetchReport(rootState, "getnewcarparkcontracts", siteId, time)
  
        commit('setNewCarparkContracts', data)
      } catch (err) {
        commit('setNewCarparkContracts', [])
      } finally {
        commit('changeNewCarparkContractsLoading', false)
      }
    },
  },
}
