<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <BaseModal
      @cancel="close"
    >
      <template #title>
        {{ $t(`Add ${type}`) }}
      </template>
      <template #content>
        <v-form ref="form">
          <v-container
            class="px-2"
          >
            <!-- Name -->
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
              mode="aggressive"
            >
              <v-text-field
                v-model="name"
                :label="`${$t('Name')}*`"
              />
            </validation-provider>
            <!-- Business ID -->
            <validation-provider
              ref="businessId"
              v-slot="{ errors }"
              :rules="`required|${type !== 'mediator' ? 'excludedIds:' + businessIds : ''}`"
              name="businessId"
              mode="aggressive"
            >
              <v-text-field
                v-model="businessId"
                :label="`${$t('Business ID')}*`"
                :hint="businessIdHint"
                :error-messages="$t(errors)"
                class="form-field mb-3"
                persistent-hint
                @change="validateBusinessId($event)"
              />
            </validation-provider>
          </v-container>
        </v-form>
      </template>

      <template #footer>
        <v-btn
          :disabled="invalid"
          :loading="saving"
          type="submit"
          rounded
          depressed
          color="primary"
          @click="save"
        >
          {{ $t('Save') }}
        </v-btn>
      </template>
    </BaseModal>
  </validation-observer>
</template>

<script>
import BaseModal from '../../general/BaseModal.vue'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { mapState } from 'vuex';

extend('excludedIds', {
  message: 'prospect.business_id_exists',
  params: ['businessIds'],
  validate: (value, { businessIds}) => {
    if(businessIds.includes(value)){
      return false
    }
    return true
  }
})

export default {
  name: 'NewPartyModal',
  components: {
    BaseModal,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    parties: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'tenant corporation'
    },
    visible: {
        type: Boolean,
        default: false
    }
  },
  data () {
    return {
      name: '',
      businessId: '',

      saving: false
    }
  },
  computed: {
    ...mapState('app', ['countries']),
    businessIds ()  {
      return this.parties.map(party => party.business_id)
    },
    businessIdHint () {
      if (this.type === 'mediator' && this.businessId?.length > 0) {
        const foundParty = this.parties.find(party => party.business_id === this.businessId)
        if (foundParty) {
          return this.$t('prospect.changing_to_mediator')
        }
      }
      return ''
    }
  },
  watch: {
    visible: {
      handler: function () {
        this.reset()
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.observer.reset()
    },
    async save () {
      this.saving = true

      const mediator = this.type === 'mediator'
      const tenantCorporation = this.type === 'tenant corporation'
      const country = this.countries.find(country => country.name === 'Suomi') || this.countries[0];
      const party = {
        type: 0,
        name: this.name,
        business_id: this.businessId,
        mediator_company: mediator,
        id_country: country.id,
      }

      const newPartyId = await this.$rambollfmapi.leasing.rentalParty.post(party, tenantCorporation)

      this.saving = false
      this.$emit('refreshParties', this.type, newPartyId)
      this.$emit('close')
    },
    validateBusinessId (value) {
      if (value?.length > 0) {
        const foundParty = this.parties.find(party => party.business_id === this.businessId)
        if (foundParty) {
          this.name = foundParty.name
        }
      }
    }
  }
}
</script>

<style>
</style> 