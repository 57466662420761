<template>
  <v-row
    class="dashboard mt-2 px-4"
  >
    <h1 class="d-sr-only">
      {{ $t('Dashboard') }}
    </h1>
    <DataUpdateDialog
      :data-update-dialog="dataUpdateDialog"
      :import-success="importSuccess"
      :rental-status-changes="rentalStatusChanges"
      :saving-failed="savingFailed"
      :errors="errors"
      :is-saving-changes="isSavingChanges"
      :car-parks="importingCarParks"
      @saveChanges="saveRentalStatusChanges"
      @cancel="dataUpdateDialog = false"
      @ok="handleOk"
    />
    <v-dialog
      v-if="hasApplicationPermissionByName('HALYTYKSET')"
      v-model="showAddAlertDialog"
      persistent
      max-width="550"
    >
      <add-alert
        v-if="showAddAlertDialog"
        :edit="true"
        :edit-object="alertToBeEdited"
        @close="showAddAlertDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-if="showCreateRentContractModal"
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <create-rent-contract-modal
          :contract-id="editedContractId"
          :out-rent="outRent"
          :read-only="true"
          @contractIdUpdated="onContractIdUpdated"
          @handleSave="onCreateRentContractModalClosed"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showModifyBuildingPermissionValidityModal"
      v-model="showModifyBuildingPermissionValidityModal"
      persistent
      width="65%"
    >
      <v-card>
        <ModifyBuildingPermissionValidityModal
          :building="editedBuilding"
          @close="closeEditBuildingPermissionValidityModal"
          @updateWidget="updateSingleWidgetData('dashboard.building_permission_validity')"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showModifyUnitPermissionValidityModal"
      v-model="showModifyUnitPermissionValidityModal"
      persistent
      width="65%"
    >
      <v-card>
        <ModifyUnitPermissionValidityModal
          :unit="editedUnit"
          @close="closeEditUnitPermissionValidityModal"
          @updateWidget="updateSingleWidgetData('dashboard.unit_permission_validity')"
        />
      </v-card>
    </v-dialog>

    <!-- Left -->
    <v-col
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <div>
        <Map
          :key="dataForWidget('dashboard.map') === null"
          :loading="dataForWidget('dashboard.map') === null"
          :center="map.mapCenter"
          :center-projection="map.mapProjection"
          :maps="dataForWidget('dashboard.map')"
          :zoom="map.mapZoom"
          class="map"
          map-id="dashboard-map"
          @viewportchange="mapViewportChangeHandler"
          @select="mapSelectHandler"
        />
        <v-btn
          v-if="map.viewportChanged"
          rounded
          outlined
          small
          style="top: -48px; background: rgba(255, 255, 255, 0.8) !important"
          @click="filterByMapViewport"
        >
          {{ $t('Filter by view') + ' (' + sitesToBeSelected + ')' }}
        </v-btn>
      </div>
    </v-col>

    <!-- middle -->
    <!--<v-col xs9 v-if="sites.length === 0" style="text-align: center; padding: 100px">
      <div class="headline" style="margin-bottom: 2em">{{$t('No sites match the current filters')}}</div>
    </v-col>-->

    <v-col
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <!-- widgets -->
      <div>
        <draggable
          v-model="smallWidgets"
          :disabled="!isDraggable"
        >
          <div
            v-for="widget in smallWidgets"
            :key="widget.id"
            class="widget"
          >
            <DynamicWidget
              :id="widget.id"
              :type="widget.type"
              :title="$t(widget.id)"
              :data="{
                ...widget.data,
                items: dataForWidget(widget.id),
                timestamp: new Date(),
              }"
              :loading="dataForWidget(widget.id) === null"
              :style="{ 'border-color': draggableColor }"
              :maximized="widget.maximized"
              @open="widget.maximized = $event"
              @changePproperty="changePproperty"
            />
          </div>
        </draggable>
      </div>
      <v-row style="right: 0; bottom: 0; position: sticky; z-index: 1">
        <v-spacer />
        <v-btn
          v-if="isDraggable"
          outlined
          rounded
          text
          @click="getWidgetOrder()"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="isDraggable"
          rounded
          depressed
          class="primary"
          @click="setWidgetOrder()"
        >
          {{ $t('Save default view') }}
        </v-btn>
      </v-row>
    </v-col>
    <!-- right -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
    >
      <div>
        <draggable
          v-model="largeWidgets"
          :disabled="!isDraggable"
        >
          <div
            v-for="widget in largeWidgets"
            :key="widget.id"
            class="widget"
          >
            <DynamicWidget
              :id="widget.id"
              :type="widget.type"
              :title="$t(widget.id)"
              :data="{
                ...widget.data,
                items: dataForWidget(widget.id),
                timestamp: new Date(),
                headers: Array.isArray(widget.data.headers)
                  ? widget.data.headers
                  : headersForWidget(widget.id),
              }"
              :loading="dataForWidget(widget.id) === null"
              :style="{ 'border-color': draggableColor }"
              :maximized="widget.maximized"
              @dataUpdate="dataUpdated(widget, $event)"
              @open="widget.maximized = $event"
              @changePproperty="changePproperty"
              @triggerCustomEvents="triggerCustomEvents"
              @editRow="openEditPermissionValidityModal(widget.id, $event)"
            >
              <template
                v-if="widget.id == 'dashboard.alerts'"
                #customComponent="{ item }"
              >
                <v-btn
                  v-if="item.createdBySelf || item.alertSelf"
                  icon
                  small
                  class="ma-0 ml-2"
                  :aria-label="$t('Modify')"
                  @click="editAlert(item)"
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.createdBySelf"
                  icon
                  small
                  class="ma-0"
                  :aria-label="$t('Delete')"
                  @click="deleteAlert(item.id)"
                >
                  <v-icon small>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <template 
                v-else-if="widget.id === 'dashboard.building_permission_validity' || widget.id === 'dashboard.unit_permission_validity'"
                #customComponent="{ item }"
              >
                <v-icon
                  v-if="item.editableRow && widget.id === 'dashboard.building_permission_validity'"
                  right
                  @click="openEditBuildingPermissionValidityModal(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="item.editableRow && widget.id === 'dashboard.unit_permission_validity'"
                  right
                  @click="openEditUnitPermissionValidityModal(item)"
                >
                  edit
                </v-icon>
              </template>  
            </DynamicWidget>
          </div>
        </draggable>
      </div>
    </v-col>
    <v-dialog
      :value="shownCorporation && showCorporationModal"
      persistent
      width="700"
    >
      <v-card>
        <CorporationModal 
          :corporation="shownCorporation"
          :rental-processes="rentalProcesses"
          :rental-statuses="allSitesRentalStatuses"
          :is-shown="showCorporationModal"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      :value="shownProspect != null && showProspectModal"
      persistent
      width="700"
    >
      <v-card>
        <ProspectModal 
          :prospect="shownProspect"
          :is-shown="showProspectModal"
          :user-info="userInfo"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import AddAlert from '../components/AddAlert.vue'
import CreateRentContractModal from '../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import Map from '../components/Map.vue'
import helpers from '../helpers'
import DynamicWidget from '../components/DynamicWidget.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4.js'
import { transformExtent } from 'ol/proj'
import draggable from 'vuedraggable'
import DataUpdateDialog from '../components/DataUpdateDialog.vue'
import GraveyardWidgets from '../widgets/graveyard'
import OrganizationWidgets from '../widgets/organization'
import FacilityManagementWidgets from '../widgets/facilitymanagement'
import HistoryWidgets from '../widgets/history'
import CorporationModal from '../components/Leasing/CorporationModal.vue'
import ProspectModal from '../components/Leasing/ProspectModal.vue'
import { RatesByUnitStatusAndTarget } from "../widgets/ratesByUnitStatusAndTarget"
import { AlertsWidget } from "../widgets/alerts"
import { SalesWidget } from "../widgets/salesWidget"
import { DisassembledAndRemovedWidget } from "../widgets/disassembledAndRemoved"
import ModifyUnitPermissionValidityModal from "../components/Leasing/ModifyUnitPermissionValidityModal.vue"
import ModifyBuildingPermissionValidityModal from "../components/Leasing/ModifyBuildingPermissionValidityModal.vue"
import { permissionValidity } from "../widgets/dashboard/permissionValidity"
import { unitPermissionValidity } from "../widgets/dashboard/unitPermissionValidity"

proj4.defs(
  'EPSG:3879',
  '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
)
register(proj4)

export default {
  name: 'Dashboard',
  components: {
    AddAlert: AddAlert,
    Map: Map,
    DynamicWidget: DynamicWidget,
    draggable,
    DataUpdateDialog: DataUpdateDialog,
    CorporationModal: CorporationModal,
    ProspectModal: ProspectModal,
    CreateRentContractModal: CreateRentContractModal,
    ModifyBuildingPermissionValidityModal: ModifyBuildingPermissionValidityModal,
    ModifyUnitPermissionValidityModal: ModifyUnitPermissionValidityModal,
  },
  metaInfo () {
    return {
      title: `${this.$t('Dashboard')} ·`,
    }
  },
  data () {
    return {
      showModifyBuildingPermissionValidityModal: false,
      editedBuilding: undefined,
      showModifyUnitPermissionValidityModal: false,
      editedUnit: undefined,
      rentalContractTypeDefinitions: null,
      showTableDataDialog: false,
      map: {
        mapProjection: 'EPSG:3857',
        mapCenter: [2807128, 9638898],
        mapZoom: 5,
        mapLayers: [
          {
            name: 'Main map',
            title: 'Main map',
            type: 'OSM',
            opacity: 1
          }
        ],
        mapViewport: null,
        viewportChanged: false
      },
      mapFilteredBuildings: null,
      mapSites: 0,
      pagination: {},
      allSpaces: [],
      rentalStatuses: [],
      emptySpace: [],
      buildingUsages: [],
      spaceUtilization: [],
      personnelAssignments: [],
      allRentalStatuses: [],
      allEmptySpace: [],
      allBuildingUsages: [],
      allSpaceUtilization: [],
      allPersonnelAssignments: [],
      widgetOrder: [],
      smallWidgets: [],
      largeWidgets: [],
      wdgtBtnVisible: false,
      draggableColor: null,
      rentalUnits: [],
      isSavingChanges: false,
      sitesToBeSelected: null,
      showAddAlertDialog: false,
      alertToBeEdited: {},
      outRent: false,
      editedContractId: undefined,
      showCreateRentContractModal: false,
      buildingPermissionValidityWidget: null,
      buildingPermissionValidityWidgetLoading: false,
      buildingPermissionValidity: [],
      unitPermissionValidityWidget: null,
      unitPermissionValidityWidgetLoading: false,
      unitsPermissionValidity: [],
      allWidgets: [
        ...GraveyardWidgets.Dashboard(),
        ...OrganizationWidgets.Dashboard(),
        ...FacilityManagementWidgets.Dashboard(),
        ...HistoryWidgets.Dashboard(),
        {
          id: 'dashboard.strategic_statuses',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'status_string',
            label: 'Strategic statuses',
            sets: [
              {
                title: this.$t('Net floor area'),
                groupBy: 'status_string',
                method: 'sum',
                parameters: ['net_floor_area']
              },
              {
                title: this.$t('Count'),
                groupBy: 'status_string',
                method: 'count',
                parameters: []
              }
            ]
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.ownership_type',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'ownership_type_string',
            label: 'Contract types'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.sites_by_class',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'classification_string',
            sets: [
              {
                title: this.$t('Net floor area'),
                groupBy: 'classification_string',
                method: 'sum',
                parameters: ['net_floor_area']
              },
              {
                title: this.$t('Count'),
                groupBy: 'classification_string',
                method: 'count',
                parameters: []
              }
            ],
            label: this.$t('Site class')
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.buildings_by_type',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'building_usage',
            label: 'Rakennustyypit'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.vacant_workstations_by_purpose_of_use',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            label: this.$t('Number of workstations')
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.purpose_zone_percentages',
          type: 'PieDataGraph',
          data: {
            purposeZones: true,
            fullPie: false,
            items: [],
            label: 'Purpose of use zones percentages'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.parties_without_links',
          type: 'PieDataGraph',
          data: {
            items: [],
            label: 'Personnel Assignment'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.rental_contract_type',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'use',
            sets: [
              {
                title: this.$t('Count'),
                groupBy: 'rental_contract_type_string',
                method: 'count',
                parameters: []
              },
              {
                title: this.$t('Net floor area'),
                groupBy: 'rental_contract_type_string',
                method: 'sum',
                parameters: ['net_floor_area']
              }
            ],
            label: 'Vuokrasopimustyypit'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.rentable_area',
          type: 'HorizontalBarDataGraph',
          data: {
            items: [],
            field: 'building_usage',
            label: 'Rakennustyypit'
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.space_utilization_trend',
          type: 'LineChart',
          data: {
            items: [],
            placeholderItems: [
              { date: '2019/1', value: 17 },
              { date: '2019/2', value: 22 },
              { date: '2019/3', value: 29 },
              { date: '2019/4', value: 33 }
            ],
            lines: [{
              xField: 'date',
              yField: 'value',
              fill: true,
            }],
            xAxis: {
              field: 'date',
            },
            field: 'site_status_string',
            label: 'Space efficciency development',
            placeholderOnly: true,
          },
          small: true,
          loading: true
        },
        {
          id: 'dashboard.unit_vacancies_by_unit_usage',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Unit use'), value: 'usage' },
              { text: this.$t('Facility area'), value: 'total_area', format: 'Area' },
              { text: this.$t('Spaces owned by KOY'), value: 'estate_area', format: 'Area' },
              { text: this.$t('Rented floor area'), value: 'leased_area', format: 'Area' },
              { text: this.$t('Free spaces'), value: 'free_area', format: 'Area' },
              {
                text: this.$t('Vacancy rate'),
                value: 'vacancy_percentage',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'total_area', unit: 'area', format: 'Area' },
              { value: 'estate_area', unit: 'area', format: 'Area' },
              { value: 'leased_area', unit: 'area', format: 'Area' },
              { value: 'free_area', unit: 'area', format: 'Area' },
              {
                value: 'vacancy_percentage',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: "leased_area",
                denominatorSum: "total_area_minus_estate_area",
                hundredMinusValue: true
              }
            ],
            items: [],
            sortBy: 'usage',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.ltmp_year_overview_fimx',
          type: 'VerticalStackedGraph',
          data: {
            subtitle: [
              this.$t('Euros for selected sites'),
              this.$t('Euros/Net floor area (€/m2)')
            ],
            cumulative: [
              this.$t('Cumulative euros'),
              this.$t('Average euros/Net floor area')
            ],
            headers: {
              total: [
                { text: this.$t('fimx.investment'), value: 'totalInvestment', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'totalEnergyInvestment', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'totalMaintenance', color: '#FFD042'},
              ],
              suggestion: [
                { text: this.$t('fimx.investment'), value: 'investmentSuggestion', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentSuggestion', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceSuggestion', color: '#FFD042'},
              ],
              inProcess: [
                { text: this.$t('fimx.investment'), value: 'investmentInProcess', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentInProcess', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceInProcess', color: '#FFD042'},
              ],
              completed: [
                { text: this.$t('fimx.investment'), value: 'investmentCompleted', color: '#D84D80'},
                { text: this.$t('fimx.energy_investment'), value: 'energyInvestmentCompleted', color: '#52A5EF'},
                { text: this.$t('fimx.maintenance'), value: 'maintenanceCompleted', color: '#FFD042'},
              ]
            },
            sets: [
              { text: this.$t('fimx_set.total'), value: 'total'},
              { text: this.$t('fimx_set.suggestion'), value: 'suggestion'},
              { text: this.$t('fimx_set.in_process'), value: 'inProcess'},
              { text: this.$t('fimx_set.completed'), value: 'completed'}
            ],
            items: [],
            column: 'year',
            areaColumn: 'area',
            yUnit: ' €',
            report: 'ltmpYearOverview'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        {
          id: 'dashboard.change_selected_sites',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Total facility area'), value: 'total_area' , format: 'Area' },
              { text: this.$t('Spaces owned by KOY'), value: 'estate_area', format: 'Area' },
              { text: this.$t('Rented floor area'), value: 'leased_area', format: 'Area' },
              { text: this.$t('Free spaces'), value: 'free_area', format: 'Area' },
              {
                text: this.$t('Utilization rate'),
                value: 'utilization_percentage',
                format: 'Percentage'
              },
              { text: this.$t('Trend in 6 months'), value: 'trend6m', format: 'TrendPercentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'total_area', unit: 'area' , format: 'Area' },
              { value: 'estate_area', unit: 'area', format: 'Area' },
              { value: 'leased_area', unit: 'area', format: 'Area' },
              { value: 'free_area', unit: 'area', format: 'Area' },
              {
                value: 'utilization_percentage',
                numeratorSum: 'leased_area',
                denominatorSum: 'total_are_minus_estate_area',
                unit: 'percentage',
                format: 'Percentage'
              }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        // {
        //   id: 'dashboard.organization_selected_sites',
        //   type: 'DataTable',
        //   loading: true,
        //   data: {
        //     headers: [
        //       { text: this.$t('User Organization'), value: 'organization'},
        //       { text: this.$t('Site'), value: 'site_name'},
        //       { text: this.$t('Net room area'), value: 'room_area', format: 'Area'},
        //       { text: this.$t('Contract area'), value: 'unit_contract_area', format: 'Area'},
        //       { text: this.$t('Net floor area'), value: 'unit_area', format: 'Area'},
        //       { text: this.$t('Ktv1 Office Area'), value: 'KTV1_office_area', format: 'Area'},
        //       { text: this.$t('Ktv1 Other Areas'), value: 'KTV1_other_area', format: 'Area'}
        //     ],
        //     footers: [
        //       { text: this.$t('Total') },
        //       {},
        //       { value: 'room_area', unit: 'area', format: 'Area'},
        //       { value: 'unit_contract_area', unit: 'area', format: 'Area'},
        //       { value: 'unit_area', unit: 'area', format: 'Area'},
        //       { value: 'KTV1_office_area', unit: 'area', format: 'Area'},
        //       { value: 'KTV1_other_area', unit: 'area', format: 'Area'}
        //     ],
        //     items: []
        //   }
        // },
        {
          id: 'dashboard.spaces_selected_sites',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'name' },
              { text: this.$t('Gross area'), value: 'gross_area', format: 'Area' },
              { text: this.$t('Net room area'), value: 'space_area', format: 'Area' }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'gross_area', unit: 'area', format: 'Area'},
              { value: 'space_area', unit: 'area', format: 'Area' }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.vat_responsibility',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              {
                text: this.$t('Rentable facility area'),
                value: 'rentable_area',
                format: 'Area'
              },
              {
                text: this.$t('Facility area (VAT)'),
                value: 'facility_area_vat',
                format: 'Area'
              },
              {
                text: this.$t('Facility area (Not VAT)'),
                value: 'facility_area_not_vat',
                format: 'Area'
              },
              { text: this.$t('Portion (VAT)'), value: 'percentage_vat', format: 'Percentage' },
              {
                text: this.$t('Portion (Not VAT)'),
                value: 'percentage_not_vat',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'rentable_area', unit: 'area', format: 'Area' },
              { value: 'facility_area_vat', unit: 'area', format: 'Area' },
              { value: 'facility_area_not_vat', unit: 'area', format: 'Area' },
              {
                value: 'percentage_vat',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: "facility_area_vat",
                denominatorSum: "rentable_area"
              },
              {
                value: 'percentage_not_vat',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: "facility_area_not_vat",
                denominatorSum: "rentable_area"
              }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          
          id: 'dashboard.units_selected_sites',
          type: 'GroupTable',
          loading: true,
          data: {
            group: 'siteIdentifier',
            childRowIdentifier: 'unit_name',
            headers: [
              { text: this.$t('Site'), value: 'site_name', onlyParent: true },
              { text: this.$t('Unit'), value: 'unit_name', onlyChild: true, unitLink: true },
              {
                text: this.$t('Total facility area'),
                value: 'total_area',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Rentable floor area'),
                value: 'leasable_area',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Rented floor area'),
                value: 'leased_area',
                sum: true,
                format: 'Area'
              },
              { text: this.$t('Vacant units'), value: 'free_area', sum: true, format: 'Area' },
              {
                text: this.$t('Vacancy rate'),
                value: 'vacancy_rate_percentage',
                sum: true,
                format: 'Percentage'
              },
              { text: '', value: 'toggle' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'total_area', unit: 'area', format: 'Area' },
              { value: 'leasable_area', unit: 'area', format: 'Area' },
              { value: 'leased_area', unit: 'area', format: 'Area' },
              { value: 'free_area', unit: 'area', format: 'Area' },
              {
                value: 'vacancy_rate_percentage',
                unit: 'percentage',
                numeratorSum: 'free_area',
                denominatorSum: 'rentable_site_area',
                format: 'Percentage'
              }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: [true]
          }
        },
        {
          // asiakastaulun id
          id: 'dashboard.free_spaces_selected_sites',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Total facility area'), value: 'total_area', format: 'Area' },
              {
                text: this.$t('Rentable facility area'),
                value: 'leasable_area',
                format: 'Area'
              },
              { text: this.$t('Spaces owned by KOY'), value: 'estate_area', format: 'Area' },
              { text: this.$t('Rented floor area'), value: 'leased_area', format: 'Area' },
              { text: this.$t('Free spaces'), value: 'free_area', format: 'Area' },
              {
                text: this.$t('Utilization rate'),
                value: 'utilization_percentage',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'total_area', unit: 'area', format: 'Area' },
              { value: 'leasable_area', unit: 'area', format: 'Area' },
              { value: 'estate_area', unit: 'area', format: 'Area' },
              { value: 'leased_area', unit: 'area', format: 'Area' },
              { value: 'free_area', unit: 'area', format: 'Area' },
              {
                value: 'utilization_percentage',
                numeratorSum: "leased_area",
                denominatorSum: "total_are_minus_estate_area",
                unit: 'percentage',
                format: 'Percentage'
              }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.empty_units',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Unit use'), value: 'unit_class' },
              {
                text: this.$t('Previous tenant'),
                value: 'previous_tenant_name'
              },
              { text: this.$t('End date'), value: 'previous_tenant_end_date', format: 'Date' },
              { text: this.$t('Next tenant'), value: 'next_tenant_name' },
              { text: this.$t('Start date'), value: 'next_tenant_start_date', format: 'Date' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'unit_area', unit: 'area', format: 'Area' }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.empty_units_from_contract',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Unit use'), value: 'unit_class' },
              {
                text: this.$t('Previous tenant'),
                value: 'previous_tenant_name'
              },
              { text: this.$t('End date'), value: 'previous_tenant_end_date', format: 'Date' },
              { text: this.$t('Next tenant'), value: 'next_tenant_name' },
              { text: this.$t('Start date'), value: 'next_tenant_start_date', format: 'Date' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'unit_area', unit: 'area', format: 'Area' }
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.sites_and_responsibilities',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site identifier'), value: 'site_identifier' },
              { text: this.$t('Site'), value: 'name' },
              { text: this.$t('Address'), value: 'addresses_string' },
              { text: this.$t('Site status'), value: 'site_status_string' },
              { text: this.$t('Class'), value: 'classification_string' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
            ],
            browseHeaders: [
              { text: this.$t('Site identifier'), value: 'site_identifier' },
              { text: this.$t('Site'), value: 'name' },
              { text: this.$t('Address'), value: 'addresses_string' },
              { text: this.$t('Portfolio'), value: 'portfolio_string' },
              { text: this.$t('Site status'), value: 'site_status_string' },
              { text: this.$t('Class'), value: 'classification_string' },
              { text: this.$t('Customer service unit'), value: 'customer_service_unit_string' },
              { text: this.$t('Hosting team'), value: 'hosting_team_string' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
              { text: this.$t('Occupancy rate'), value: 'rental_rate', format: 'Percentage' },
              { text: this.$t('Owner'), value: 'owners_string' },
              { text: this.$t('Maintenance'), value: 'maintenance_string' },
              { text: this.$t('AdministrationContact'), value: 'administrationContact_string' },
              { text: this.$t('marketing.contact'), value: 'marketingContact_string' }
          ],
            items: [],
            sortBy: 'site_identifier',
            sortDesc: false
          }
        },
        {
          id: 'dashboard.vacant_spaces',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Space number'), value: 'space_name' },
              { text: this.$t('Net room area'), value: 'net_room_area', format: 'Area' },
              { text: this.$t('Purpose of use'), value: 'use' },
              {
                text: this.$t('All workstations (PCS)'),
                value: 'workstation_count',
                format: 'Amount'
              },
              {
                text: this.$t('Vacant workstations (PCS)'),
                value: 'vacant_workstations',
                format: 'Amount'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'net_room_area', unit: 'area', format: 'Area' },
              {},
              { value: 'workstation_count', format: 'Amount' },
              { value: 'vacant_workstations', format: 'Amount' }
            ],
            items: []
          }
        },
        {
          id: 'dashboard.lazy_tenants',
          type: 'LazyTable',
          loading: false,
          data: {
            source: 'tenants'
          }
        },
        {
          id: 'dashboard.tenants',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Tenant'), value: 'current_tenant_name' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Net floor area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Units by purpose of use'), value: 'unit_class' },
              {
                text: this.$t('Start date'),
                value: 'start_date',
                format: 'Date'
              },
              {
                text: this.$t('End date'),
                value: 'end_date',
                format: 'Date'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'unit_area', unit: 'area', format: 'Area' }
            ],
            items: [],
            sortBy: 'end_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.sites',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'name' },
              { text: this.$t('Site identifier'), value: 'site_identifier' },
              { text: this.$t('City'), value: 'post_office' },
              { text: this.$t('Class'), value: 'classification_string' },
              { text: this.$t('Last modified'), value: 'modified', format: 'Time' },
              { text: this.$t('Last modified by'), value: 'modified_by' }
            ],
            items: [],
            sortBy: 'name'
          }
        },
        {
          id: 'dashboard.selected_sites_by_unit_status',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'name' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('Total facility area'), value: 'net_floor_area', format: 'Area' },
              { text: this.$t('Rentable area'), value: 'rentable_floor_area', format: 'Area' },
              { text: this.$t('Other floor area'), value: 'other_floor_area', format: 'Area' },
              {
                text: this.$t('Rented floor area'),
                value: 'rented_floor_area',
                format: 'Area'
              },
              { text: this.$t('Free floor area'), value: 'free_floor_area', format: 'Area' },
              {
                text: this.$t('Utilization rate'),
                value: 'utilization_percentage',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'net_floor_area', unit: 'area', format: 'Area' },
              { value: 'rentable_floor_area', unit: 'area', format: 'Area' },
              { value: 'other_floor_area', unit: 'area', format: 'Area' },
              { value: 'rented_floor_area', unit: 'area', format: 'Area' },
              { value: 'free_floor_area', unit: 'area', format: 'Area' },
              {
                value: 'utilization_percentage',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: 'rented_floor_area',
                denominatorSum: 'rentable_floor_area'
              }
            ],
            items: [],
            sortBy: 'net_floor_area',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.space_utilization',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              {
                text: this.$t('All workstations (PCS)'),
                value: 'workstation_count',
                format: 'Amount'
              },
              { text: this.$t('Net room area'), value: 'net_room_area', format: 'Area' },
              { text: this.$t('Personnel'), value: 'personnel_count', format: 'Amount' },
              { text: this.$t('Efficiency'), value: 'space_utilization', format: 'AreaPerPerson' }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'workstation_count', unit: 'number', format: 'Amount' },
              { value: 'net_room_area', unit: 'area', format: 'Area' },
              { value: 'personnel_count', unit: 'number', format: 'Amount' },
              {}
            ],
            items: [],
            sortBy: 'space_utilization',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.spaces_by_purpose_of_use',
          type: 'DataTable',
          loading: true,
          data: {
            headers: 'getSpacesByPurposeOfUseHeaders',
            items: [],
            sortBy: 'created'
          }
        },
        {
          id: 'dashboard.latest_party_changes',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Space number'), value: 'space_name' },
              { text: this.$t('Floor'), value: 'floor_name' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Personnel name'), value: 'name' },
              { text: this.$t('Cost center'), value: 'cost_center_name' },
              { text: this.$t('Last modified'), value: 'start_date', format: 'Date' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'area', unit: 'area', format: 'Area' },
              {},
              {},
              {}
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.tasks',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Vastuuhenkilö'), value: 'responsible' },
              { text: this.$t('Kohde'), value: 'site_name' },
              { text: this.$t('Kaupunki'), value: 'post_office' },
              { text: this.$t('Tehtävätyyppi'), value: 'type' },
              { text: this.$t('Tila'), value: 'status' },
              { text: this.$t('Luotu'), value: 'created' },
              { text: this.$t('Luonut'), value: 'creator' }
            ],
            items: []
          }
        },
        {
          id: 'dashboard.vacant_spaces_from_costcenters',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Space number'), value: 'room_number' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Purpose of use'), value: 'purpose' },
              { text: this.$t('Cost center'), value: 'costcenter' },
              { text: this.$t('Share'), value: 'share', format: 'Percentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'area', unit: 'area', format: 'Area' },
              {},
              {},
              {}
            ],
            items: []
          }
        },
        {
          id: 'dashboard.unit_dwg_changes',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site' },
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Floor'), value: 'floor_name' },
              { text: this.$t('Change date'), value: 'change_date', format: 'Date' }
            ],
            items: []
          }
        },
        {
          id: 'dashboard.rental_units',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Unit use'), value: 'unit_class' },
              { text: this.$t('Tenant'), value: 'tenant' },
              {
                text: this.$t('VAT responsibility'),
                value: 'vat_responsibility'
              },
              { text: this.$t('Unit area (VAT)'), value: 'unit_area_vat', format: 'Area' },
              { text: this.$t('Portion (VAT)'), value: 'percentage_vat', format: 'Percentage' },
              {
                text: this.$t('Unit area (Not VAT)'),
                value: 'unit_area_not_vat',
                format: 'Area'
              },
              {
                text: this.$t('Portion (Not VAT)'),
                value: 'percentage_not_vat',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              {},
              { value: 'unit_area_vat', unit: 'area', format: 'Area' },
              {
                value: 'percentage_vat',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: 'unit_area_vat',
                denominator: 'total_rentable_area_by_unit',
              },
              { value: 'unit_area_not_vat', unit: 'area', format: 'Area' },
              {
                value: 'percentage_not_vat',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: 'unit_area_not_vat',
                denominator: 'total_rentable_area_by_unit',
              }
            ],
            items: [],
            source: 'rentalUnits',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'dashboard.purpose_zone_status',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization'},
              { text: this.$t('Site name'), value: 'site_name' },
              {
                text: this.$t('Purpose zone defined'),
                value: 'purpose_zone_defined_area',
                format: 'Area'
              },
              {
                text: this.$t('Purpose zone undefined'),
                value: 'purpose_zone_undefined_area',
                format: 'Area'
              },
              { text: this.$t('Undefined spaces count'), value: 'undefined_spaces', format: 'Amount'},
              { text: this.$t('Progress'), value: 'progress_percentage', format: 'Percentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'purpose_zone_defined_area', unit: 'area', format: 'Area' },
              { value: 'purpose_zone_undefined_area', unit: 'area', format: 'Area' },
              { value: 'undefined_spaces', unit: 'number', format: 'Amount'}  
            ],
            items: []
          }
        },
        {
          id: 'dashboard.contract_handovers_and_takeovers_within_three_months',
          type: 'ObjectTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Direction'), value: 'auditDirection',  customWidth: '150px' },
              { text: this.$t('audit.status'), value: 'auditStatus' },
              { text: this.$t('audit.score'), value: 'score' },
              { text: this.$t('Tenant'), value: 'tenant', customWidth: '175px'},
              { text: this.$t('Contract number'), value: 'contractNumber'},
              { text: this.$t('leasing.renting_process_stage'), value: 'process_status' },
              { text: this.$t('Rental_event'), value: 'process_title' ,  customWidth: '150px' },
              { text: this.$t('Contract start date'), value: 'startDate', format: 'Date' },
              { text: this.$t('Contract end date'), value: 'endDate', format: 'Date' },
              { text: this.$t('Site'), value: 'site',  customWidth: '150px'},
              { text: this.$t('Units'), value: 'units' },
              { text: this.$t('audit.status_updated'), value: 'auditStatusUpdated', format: 'Date' },
              { text: '', value: 'toggle', type: 'toggle' },
            ],
            browseHeaders: [
              { text: this.$t('Direction'), value: 'auditDirection', customWidth: '150px' },
              { text: this.$t('audit.status'), value: 'auditStatus' },
              { text: this.$t('audit.score'), value: 'score' },
              { text: this.$t('Tenant'), value: 'tenant', leasing: true },
              { text: this.$t('Contract number'), value: 'contractNumber'},
              { text: this.$t('leasing.renting_process_stage'), value: 'process_status', leasing: true },
              { text: this.$t('Rental_event'), value: 'process_title', leasing: true },
              { text: this.$t('Contract start date'), value: 'startDate', format: 'Date' },
              { text: this.$t('Contract end date'), value: 'endDate', format: 'Date' },
              { text: this.$t('Site'), value: 'site'},
              { text: this.$t('Identifier'), value: 'site_identifier' },  
              { text: this.$t('Units'), value: 'joinedUnits' },
              { text: this.$t('audit.status_updated'), value: 'auditStatusUpdated', format: 'Date' },
            ],
            filter: {
              header: ['auditDirection', 'auditStatus'],
              type: ['equal', 'notEqual'],
              options: [
                { text: 'prospect.show_all', value: [null, null] },
                { text: 'handover.all', value: [this.$t('HANDOVER'), null] },
                { text: 'handover.open', value:[this.$t('HANDOVER'), this.$t('COMPLETED')] },
                { text: 'takeover.all', value: [this.$t('TAKEOVER'), null] },
                { text: 'takeover.open', value:[this.$t('TAKEOVER'), this.$t('COMPLETED')] },
              ],
            },
            items: [],
            ignoreSubitemsInTable: true,
          }
        },
        {
          id: 'dashboard.falcony',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('audits.general rating'), value: 'general_rating', format: 'Number' },
              { text: this.$t('audits.report date'), value: 'report_date', format: 'Date' }
            ],
            footers: [
              { text: this.$t('audits.average') },
               {},
              { value: 'general_rating', format: 'Integer' },
              {}
            ],
            items: [],
            averageTotalRow: true,
          }
        },
        {
          id: 'dashboard.purpose_of_use_zones_by_organizations',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Purpose zone level'), value: 'purpose_zone_level' },
              { text: this.$t('Description'), value: 'description' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area'},
              { text: this.$t('HTV'), value: 'htv' },
              { text: this.$t('Htm\u00B2/HTV'), value: 'net_floor_area_by_htv', format: 'Area'},
              { text: '', value: 'toggle' }
            ],
            browseHeaders: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Purpose zone1 string'), value: 'purpose_zone_1' },
              { text: this.$t('Purpose zone2 string'), value: 'purpose_zone_2' },
              { text: this.$t('Purpose zone3 string'), value: 'purpose_zone_3' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area'},
              { text: this.$t('HTV'), value: 'htv' },
              { text: this.$t('Htm\u00B2/HTV'), value: 'net_floor_area_by_htv', format: 'Area'}
            ],
            items: [],
            report: 'PurposeZonesByOrganizations',
          },
          maximized: [true],
          loading: true 
        },
        {
          id: 'dashboard.apartments',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('apartment.total_count'), value: 'totalcount', format: 'Amount' },

              { text: this.$t('apartment.one_space_available_area'), value: '1vacant_area', format: 'Area' },
              { text: this.$t('apartment.one_space_rented_area'), value: '1rented_area', format: 'Area' },
              { text: this.$t('apartment.one_space_count'), value: '1spacecount', format: 'Amount' },
              { text: this.$t('apartment.one_vacant_count'), value: '1vacant_count', format: 'Amount' },
              { text: this.$t('apartment.one_rented_count'), value: '1rented_count', format: 'Amount' },

              { text: this.$t('apartment.two_spaces_available_area'), value: '2vacant_area', format: 'Area' },
              { text: this.$t('apartment.two_spaces_rented_area'), value: '2rented_area', format: 'Area' },
              { text: this.$t('apartment.two_spaces_count'), value: '2spacecount', format: 'Amount' },
              { text: this.$t('apartment.two_vacant_count'), value: '2vacant_count', format: 'Amount' },
              { text: this.$t('apartment.two_rented_count'), value: '2rented_count', format: 'Amount' },

              { text: this.$t('apartment.three_spaces_available_area'), value: '3vacant_area', format: 'Area' },
              { text: this.$t('apartment.three_spaces_rented_area'), value: '3rented_area', format: 'Area' },
              { text: this.$t('apartment.three_spaces_count'), value: '3spacecount', format: 'Amount' },
              { text: this.$t('apartment.three_vacant_count'), value: '3vacant_count', format: 'Amount' },
              { text: this.$t('apartment.three_rented_count'), value: '3rented_count', format: 'Amount' },

              { text: this.$t('apartment.four_spaces_available_area'), value: '4vacant_area', format: 'Area' },
              { text: this.$t('apartment.four_spaces_rented_area'), value: '4rented_area', format: 'Area' },
              { text: this.$t('apartment.four_spaces_count'), value: '4spacecount', format: 'Amount' },
              { text: this.$t('apartment.four_vacant_count'), value: '4vacant_count', format: 'Amount' },
              { text: this.$t('apartment.four_rented_count'), value: '4rented_count', format: 'Amount' },

              { text: this.$t('apartment.five_spaces_available_area'), value: '5vacant_area', format: 'Area' },
              { text: this.$t('apartment.five_spaces_rented_area'), value: '5rented_area', format: 'Area' },
              { text: this.$t('apartment.five_spaces_count'), value: '5spacecount', format: 'Amount' },
              { text: this.$t('apartment.five_vacant_count'), value: '5vacant_count', format: 'Amount' },
              { text: this.$t('apartment.five_rented_count'), value: '5rented_count', format: 'Amount' },

              { text: this.$t('apartment.sauna_count'), value: 'saunacount', format: 'Amount' },
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'totalcount', format: 'Amount' },

              { value: '1vacant_area', format: 'Area' },
              { value: '1rented_area', format: 'Area' },
              { value: '1spacecount', format: 'Amount' },
              { value: '1vacant_count', format: 'Amount' },
              { value: '1rented_count', format: 'Amount' },

              { value: '2vacant_area', format: 'Area' },
              { value: '2rented_area', format: 'Area' },
              { value: '2spacecount', format: 'Amount' },
              { value: '2vacant_count', format: 'Amount' },
              { value: '2rented_count', format: 'Amount' },

              { value: '3vacant_area', format: 'Area' },
              { value: '3rented_area', format: 'Area' },
              { value: '3spacecount', format: 'Amount' },
              { value: '3vacant_count', format: 'Amount' },
              { value: '3rented_count', format: 'Amount' },

              { value: '4vacant_area', format: 'Area' },
              { value: '4rented_area', format: 'Area' },
              { value: '4spacecount', format: 'Amount' },
              { value: '4vacant_count', format: 'Amount' },
              { value: '4rented_count', format: 'Amount' },

              { value: '5vacant_area', format: 'Area' },
              { value: '5rented_area', format: 'Area' },
              { value: '5spacecount', format: 'Amount' },
              { value: '5vacant_count', format: 'Amount' },
              { value: '5rented_count', format: 'Amount' },

              { value: 'saunacount', format: 'Amount' },
            ],
            items: []
          }
        },
        {
          id: 'dashboard.property_tax',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('Site'), value: 'administrative_property' },
              { text: this.$t('Estate identifier'), value: 'property_id' },
              { text: this.$t('tax.base'), value: 'intended_use' },
              { text: this.$t('Name'), value: 'property_name' },
              { text: this.$t('vtj_pbe'), value: 'vtj_pbe' },
              { text: this.$t('Current tax amount'), value: 'current_tax_amount', format: 'Euro' },
              { text: this.$t('Past tax amount'), value: 'past_tax_amount', format: 'Euro' },
              { text: this.$t('Change percentace'), value: 'change_percentace', format: 'Percentage' },
              { text: this.$t('Estate number'), value: 'property_number' },
              { text: this.$t('Estate part number'), value: 'building_part_number' },
              { text: this.$t('Area'), value: 'acreage', format: 'Area'},
              { text: this.$t('Completion date'), value: 'completion_date', format: 'Date'},
              { text: this.$t('Municipality'), value: 'municipality' },
              { text: this.$t('Municipality number'), value: 'municipality_number' },
              { text: this.$t('Region'), value: 'region' },
              { text: this.$t('tax.Status'), value: 'status', format: 'Translation', type: 'dropdown', chooses: this.getptchooses},
              { text: '', value: 'toggle' },

              //{ text: this.$t('tax.number'), value: 'number' },
              //{ text: this.$t('tax.hk'), value: 'administrative_property' },
            ],
            items: [],
            sortDesc: false
          },
          maximized: [true],
          loading: true
        },
        {
          id: 'dashboard.space_utilization_by_government',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('Government'), value: 'government'},
              { text: this.$t('User Organization'), value: 'organization'},
              { text: this.$t('Site'), value: 'site_name'},
              { text: this.$t('Office area by HTV'), value: 'office_area_by_HTV', format: 'Area'},
              { text: this.$t('Office area per person'), value: 'office_area_per_person', format: 'Area'},
              { text: this.$t('Total area by HTV'), value: 'total_area_by_HTV', format: 'Area'},
              { text: this.$t('Total area per person'), value: 'total_area_per_person', format: 'Area'},
              { text: this.$t('Total facility area'), value: 'total_area', format: 'Area'},
              { text: this.$t('KTV1 Governement Office Area'), value: 'office_area', format: 'Area'},
              { text: this.$t('Total HTV'), value: 'total_HTV_count'},
              { text: this.$t('Office HTV'), value: 'office_HTV_count'},
              { text: this.$t('Total person count'), value: 'total_person_count'},
              { text: this.$t('Office person count'), value: 'office_person_count'}
            ],
            footers: [
              {},
              {},
              { text: this.$t('Total')},
              { value: 'office_area_by_HTV', format: 'Area'},
              { value: 'office_area_per_person', format: 'Area'},
              { value: 'total_area_by_HTV', format: 'Area'},
              { value: 'total_area_per_person', format: 'Area'},
              { value: 'total_area', format: 'Area'},
              { value: 'office_area', format: 'Area'},
              { value: 'total_HTV_count'},
              { value: 'office_HTV_count'},
              { value: 'total_person_count'},
              { value: 'office_person_count'}
            ],
            items: []
          },
          maximized: [true],
          loading: [true]
        },
        {
          id: 'dashboard.contract_rows',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Space area'), value: 'area', format: 'Area' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('leasing.rentable_area'), value: 'leasable_area', format: 'Area' },
              { text: this.$t('Contract area'), value: 'contract_area', format: 'Area' },
              { text: this.$t('contract.contract start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('contract.contract end date'), value: 'end_date', format: 'Date' },
              { text: this.$t('contract.tenant term of notice'), value: 'notice_period', format: 'Month' },
              { text: this.$t('contract.landlord term of notice'), value: 'landlord_notice_period', format: 'Month' },
              { text: this.$t('contract.first term of notice day'), value: 'earliest_notice_date', format: 'Date'},

            ],
            items: [],
            sortDesc: false
          },
          maximized: [true],
          loading: true
        },
        {
          id: 'dashboard.rental_contracts_by_organization',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Tenant'), value: 'tenant' },
              { text: this.$t('Contract number'), value: 'contract_number', format: 'Number' },
              { text: this.$t('Contract type'), value: 'contract_type' },
              { text: this.$t('contract.contract start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('contract.contract end date'), value: 'agreement_end_date', format: 'Date' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Unit'), value: 'unit_names' },
              { text: this.$t('Contract area'), value: 'contract_area', format: 'Area' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('contract.monthly rent 0'), value: 'total_net', format: 'Money'},
              { text: this.$t('contract.tenant term of notice'), value: 'notice_period', format: 'Month' },
              { text: this.$t('contract.landlord term of notice'), value: 'landlord_notice_period', format: 'Month' },
              { text: this.$t('contract.tenant first term of notice day'), value: 'earliest_notice_date', format: 'Date'},
              { text: this.$t('contract.landlord first term of notice day'), value: 'landlord_earliest_notice_date', format: 'Date'}
            ],
            items: [],
            sortDesc: false
          },
          maximized: [true],
          loading: true
        },
        {
          id: 'dashboard.capacities',
          type: 'DynamicDataTable',
          loading: true,
          data: {
            headers:  [
              { text: this.$t('User Organization'), value: 'organization'},
              { text: this.$t('Site'), value: 'site_name'}
            ],
            footers: [
              { text: this.$t('Total') },
              {}
            ],
            items: [],
            sortBy: 'start_date',
            sortDesc: true
          }
        },
        {
          id: 'dashboard.breeam_in_use_certifications',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site name'), value: 'site_name' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('Commercial name'), value: 'site_commercial_name' },
              { text: this.$t('Site classification'), value: 'site_classification' },
              { text: this.$t('Rental contract type'), value: 'rental_contract_type' },
              { text: this.$t('Rentable floor area'), value: 'rentable_floor_area', format: 'Area' },
              { text: this.$t('Municipality'), value: 'municipality' },
              { text: this.$t('Part 1 rating'), value: 'part1_rating' },
              { text: this.$t('Part 2 rating'), value: 'part2_rating' }, 
              { text: this.$t('Certification expires'), value: 'certification_expires', format: 'Date' },
              { text: this.$t('Version'), value: 'certification_version' },
              { text: this.$t('Part 1 score'), value: 'part1_score', format: 'Percentage', average: true },
              { text: this.$t('Part 2 score'), value: 'part2_score', format: 'Percentage', average: true },
              { text: this.$t('Certified date'), value: 'certification_date', format: 'Date' },
              { text: this.$t('Certification diploma'), value: 'diploma_document_id' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              { value: 'rentable_floor_area', format: 'Area' },
              {},
              {},
              {},
              {},
              {},
              { value: 'part1_score', format: 'Percentage', average: true },
              { value: 'part2_score', format: 'Percentage', average: true },
              {},
              {}
            ],
            items: [],
            sortBy: 'site_name',
          }
        },
        {
          id: 'dashboard.ltmp_tasks_year_overview_per_site_fimx',
          type: 'DynamicDataTable',
          loading: true,
          small: true,
          data: {
            formats: [
              { text: this.$t('Total for period per net floor area'), format: 'MoneyPerSquare', average: true },
              { text: this.$t('Total suggestion for period per net floor area'), format: 'MoneyPerSquare' },
              { text: this.$t('Total in process for period per net floor area'), format: 'MoneyPerSquare' },
              { text: this.$t('Total completed for period per net floor area'), format: 'MoneyPerSquare' },
              { text: this.$t('Total (for period)'), format: 'Euro' },
              { text: this.$t('Total suggestion (for period)'), format: 'Euro' },
              { text: this.$t('Total in process (for period)'), format: 'Euro' },
              { text: this.$t('Total completed (for period)'), format: 'Euro' },
              { text: this.$t('Energy investment total'), format: 'Euro' },
              { text: this.$t('Investment total/ CAPEX'), format: 'Euro' },
              { text: this.$t('Maintenance total/ OPEX'), format: 'Euro' },
              { text: this.$t('fimx_set.suggestion'), format: 'Euro' },
              { text: this.$t('fimx_set.in_process'), format: 'Euro' },
              { text: this.$t('fimx_set.completed'), format: 'Euro' }
            ],
            headers: [
              { text: this.$t('Site identifier'), value: 'site_identifier' },
              { text: this.$t('Site'), value: 'site_name' },
              { text: this.$t('Building net floor area'), value: 'net_floor_area', format: 'Area' },
            ],
            filter: {
              excludeHeaders: [ 'site_identifier', 'site_name', 'net_floor_area' ],
              excludeItems: [ 'site_identifier', 'id_site', 'site_name', 'net_floor_area' ],
              options: [
                { text: 'fimx_set.total', value: this.$t('fimx_set.total') },
                { text: 'fimx_set.suggestion', value: this.$t('fimx_set.suggestion') },
                { text: 'fimx_set.in_process', value: this.$t('fimx_set.in_process') },
                { text: 'fimx_set.completed', value: this.$t('fimx_set.completed') }                
              ],
              defaultFilter: {
                text: 'fimx_set.total',
                value: 'total'
              },
            },
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'net_floor_area', format: 'Area'}
            ],
            items: [],
            sortBy: 'site_name'
          }
        },
        {
          id: 'dashboard.carparks',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'siteName' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('Number of car parks'), value: 'car_parks', format: 'Amount' },
              { text: this.$t('Free car parks'), value: 'free_car_parks', format: 'Amount' },
              { text: this.$t('Usage rate'), value: 'usage_rate', format: 'Percentage', average: true },
              { text: this.$t('Market rent for rented'), value: 'market_rent_for_rented', format: 'Euro' },
              { text: this.$t('Free market rent'), value: 'free_market_rent', format: 'Euro' },
              { text: this.$t('Economic usage rate'), value: 'economic_usage_rate', format: 'Percentage', average: true },
              { text: this.$t('Electronic cars charge stations'), value: 'ecars_charge_stations', format: 'Amount' },
              { text: this.$t('Landlord'), value: 'landlords', format: '' },
              { text: this.$t('Rental type'), value: 'rental_type', format: '' },
              { text: this.$t('Type'), value: 'carpark_type', format: '' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'car_parks', format: 'Amount' },
              { value: 'free_car_parks', format: 'Amount' },
              { value: 'total_usage_rate', average: true, format: 'Percentage' },
              { value: 'market_rent_for_rented', format: 'Euro' },
              { value: 'free_market_rent', format: 'Euro' },
              { value: 'total_economic_usage_rate', average: true, format: 'Percentage' },
              { value: 'ecars_charge_stations', format: 'Amount' },
              {},
              {},
              {}
            ],
            items: [],
            sortBy: 'siteName'
          }
        },
        {
          id: 'dashboard.carparks_arearent',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'siteName' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('Number of areas'), value: 'carpark_zones', format: 'Amount' },
              { text: this.$t('Number of car spaces in areas'), value: 'carparks', format: 'Amount' },
              { text: this.$t('Free car parks'), value: 'carparks_free', format: 'Amount' },
              { text: this.$t('Usage rate'), value: 'usage_rate', format: 'Percentage' },
              { text: this.$t('Market rent for rented'), value: 'market_rent_rented', format: 'Euro'  },
              { text: this.$t('Free market rent'), value: 'market_rent_free', format: 'Euro'  },
              { text: this.$t('Economic usage rate'), value: 'economic_usage_rate', format: 'Percentage'  },
              { text: this.$t('Electronic cars charge stations'), value: 'charging_spots_amount', format: 'Amount' },
              { text: this.$t('Renter'), value: 'renter' },
              { text: this.$t('Type'), value: 'type' },
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'carpark_zones', format: 'Amount' },
              { value: 'carparks', format: 'Amount' },
              { value: 'carparks_free', format: 'Amount' },
              { value: 'total_usage_rate', average: true, format: 'Percentage' },
              { value: 'market_rent_rented', format: 'Euro' },
              { value: 'market_rent_free', format: 'Euro' },
              { value: 'total_economic_usage_rate', average: true, format: 'Percentage' },
              { value: 'charging_spots_amount', format: 'Amount' },
              {},
              {}
            ],
            items: [],
            sortBy: 'siteName'
          }
        },
        {
          id: 'dashboard.carparks_privileges',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Site'), value: 'siteName' },
              { text: this.$t('Identifier'), value: 'site_identifier' },
              { text: this.$t('Number of privilege areas'), value: 'carpark_zones', format: 'Amount' },
              { text: this.$t('Number of car spaces in areas'), value: 'carparks', format: 'Amount' },
              { text: this.$t('Max parking rights'), value: 'max_parking_rights', format: 'Amount' },
              { text: this.$t('Parking rights given'), value: 'parking_rights_given', format: 'Amount' },
              { text: this.$t('Parking rights remain'), value: 'parking_rights_remain', format: 'Amount' },
              { text: this.$t('Max reservation rate'), value: 'max_reservation_rate', format: 'NumberTwoDecimals' },
              { text: this.$t('Usage rate'), value: 'usage_rate', format: 'Percentage' },
              { text: this.$t('Market rent for rented'), value: 'market_rent_rented', format: 'Euro'  },
              { text: this.$t('Free market rent'), value: 'market_rent_free', format: 'Euro'  },
              { text: this.$t('Economic usage rate'), value: 'economic_usage_rate', format: 'Percentage'  },
              { text: this.$t('Electronic cars charge stations'), value: 'charging_spots_amount', format: 'Amount' },
              { text: this.$t('Renter'), value: 'renter' },
              { text: this.$t('Type'), value: 'type' },
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'carpark_zones', format: 'Amount' },
              { value: 'carparks', format: 'Amount' },
              { value: 'max_parking_rights', format: 'Amount' },
              { value: 'parking_rights_given', format: 'Amount' },
              { value: 'parking_rights_remain', format: 'Amount' },
              { value: 'total_max_reservation_rate', average: true, format: 'NumberTwoDecimals' },
              { value: 'total_usage_rate', average: true, format: 'Percentage' },
              { value: 'market_rent_rented', format: 'Euro' },
              { value: 'market_rent_free', format: 'Euro' },
              { value: 'total_economic_usage_rate', average: true, format: 'Percentage' },
              { value: 'charging_spots_amount', format: 'Amount' },
              {},
              {}
            ],
            items: [],
            sortBy: 'siteName'
          }
        },
        {
          id: 'dashboard.real_student_count',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('School name'), value: 'school_name' },
              { text: this.$t('Permanent building identifier'), value: 'building_identifier' },
              { text: this.$t('Address'), value: 'address' },
              { text: this.$t('Real student count'), value: 'real_student_count' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'real_student_count' }
            ],
            items: [],
            sortBy: 'school_name'
          },
        },
        {
          id: 'dashboard.green_building_certificates',
          type: 'DataTable',
          loading: false,
          maximized: [true],
          data: {
          headers: [
            { text: this.$t('esg.certificate.site_name'), value: 'site_name' },
            { text: this.$t('esg.certificate.property_name'), value: 'estate_name' },
            { text: this.$t('esg.certificate.building_name'), value: 'building_name' },
            { text: this.$t('esg.certificate.level'), value: 'level_name' },
            { text: this.$t('esg.certificate.status'), value: 'status_name' },
            { text: this.$t('esg.certificate.scheme'), value: 'scheme_name' },
            { text: this.$t('esg.certificate.scheme_specification'), value: 'scheme_specification', format: 'List'},
            { text: this.$t('esg.certificate.grade'), value: 'grade_name'},
            { text: this.$t('esg.certificate.grade_value'), value: 'grade_value' },
            { text: this.$t('esg.certificate.date_of_issue'), value: 'issued', format: 'Date'},
            { text: this.$t('esg.certificate.date_of_expiry'), value: 'expires', format: 'Date'},
            { text: this.$t('esg.certificate.notes'), value: 'notes'},
            { text: this.$t('esg.certificate.area'), value: 'area', format: 'Area'}
          ],
          footers: [{},{},{},{},{},{},{},{},{},{},{},{}],
          report: 'GetAllGreenBuildingCertificates',
          items: [],
          }
        },
        RatesByUnitStatusAndTarget(),
        AlertsWidget('dashboard.alerts'),
        SalesWidget(),
        DisassembledAndRemovedWidget(),
        permissionValidity(),
        unitPermissionValidity(),
      ],
      // rental status
      rentalStatusChanges: [],

      // imported csv data
      importSuccess: false,
      dataUpdateDialog: false,
      errors: { unexisting: [], uneditable: [] },
      savingFailed: false,
      ptchooses: null,
      ptchoosesorig: null,
      importingCarParks: false
    }
  },
  computed: {
    ...mapState('app', ['sites', 'userWidgets', 'userInfo', 'buildingRecursionLoadingState', 'siteRecursionLoadingState', 'currentDate']),
    ...mapGetters('app', [
      'definitionById',
      'definitionByLabel',
      'siteFiltersByTypes',
      'hasApplicationPermissionByName'
    ]),
    ...mapState('leasing', [
      'shownProspect', 
      'showProspectModal', 
      'shownCorporation', 
      'showCorporationModal', 
      'allSitesRentalStatuses', 
    ]),
    ...mapState('rentalProcess', ['rentalProcesses']),
    ...mapGetters('dashboard', ['dataForWidget', 'headersForWidget']),
    isDraggable () {
      return this.$store.state.app.sortableWidgets
    },
    buildingsData () {
      return this.dataForWidget('dashboard.map') !== null ? this.dataForWidget('dashboard.map').find(m => m.name === 'Buildings').points.map(p => p.data) : []
    },
    cemeteriesData () {
      return this.dataForWidget('dashboard.map') !== null ? this.dataForWidget('dashboard.map').find(m => m.name === 'Cemeteries').points.map(p => p.data) : []
    }
  },
  watch: {
    sites: function () {
      this.getWidgetOrder()
      this.mapViewportChangeHandler()
    },
    isDraggable: function () {
      if (this.isDraggable === true) {
        this.draggableColor = '#8b8b8b'
      } else {
        this.draggableColor = null
      }
    },
    buildingRecursionLoadingState: {
      handler:  function (bool) {
        if (!bool) {
          this.loadRecursionWidgetData()
        }
      }
    },
    siteRecursionLoadingState: {
      handler:  function (bool) {
        if (bool) {
          this.setAllWidgetsToLoading()
        }
      }
    }
  },
  async mounted () {
    this.getWidgetOrder()
    this.getCemeteries()
    this.loadWidgetDataForSites(this.sites)
  },
  methods: {
    ...mapActions('dashboard', ['updateUnitInformation', 'loadRecursionWidgetData', 'updateSingleWidgetData']),
    ...mapActions('app', ['getSites', 'getRecursiveBuildings', 'getCemeteries']),
    ...mapMutations('app', ['setSiteFilters', 'clearSiteFilters', 'filterSites']),
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    getSiteNameById (id) {
      const map = this.sites.reduce((acc, cur) => {
        acc[cur.id_site] = cur.name
        return acc
      }, {})
      return map[id]
    },
    mapSelectHandler (element) {
      const selectedIdSite = element.selected[0].get('data').data.id_site
      this.$log.debug(selectedIdSite)
      if (typeof selectedIdSite !== 'undefined') {
        this.$router.push({
          name: 'sites.info',
          params: { id_site: selectedIdSite }
        })
      }
    },
    filterByMapViewport () {
      const sites = this.getSitesByMapBuildings(this.buildingsData)

      const cemeterySites = this.getSitesByMapCemeteries(this.cemeteriesData)

      if (sites.length > 0) {
        this.clearSiteFilters()
        this.setSiteFilters({
          type: 'id_site',
          value: sites.map(f => f.id_site)
        })

        // Retrieve sites. Uses filters from store
        this.filterSites()
        this.getRecursiveBuildings()
      }

      if (cemeterySites.length > 0) {
        this.clearSiteFilters()
        this.setSiteFilters({
          type: 'id_site',
          value: cemeterySites.map(f => f.id_site)
        })
        this.filterSites()
        this.getCemeteries()
      }
    },
    mapViewportChangeHandler (viewport = null) {
      if (viewport != null) {
        this.$log.info('Viewport changed!')
        this.map.mapViewport = transformExtent(
          viewport,
          'EPSG:3857',
          'EPSG:3879'
        )
      }

      this.setSitesToBeSelected()
      this.map.viewportChanged = true
    },
    findPastTenant (unitId) {
      const currentDate = this.$store.state.app.currentDate
      const found = this.rentalStatuses
        .filter(
          status =>
            status.unitId === unitId &&
            new Date(status.start_date) < currentDate &&
            new Date(status.end_date) < currentDate
        )
        .sort((a, b) => {
          return new Date(b.end_date) - new Date(a.end_date)
        })
      if (found.length > 0) {
        return found[0]
      } else {
        return { partyName: null, end_date: null }
      }
    },
    findFutureTenant (unitId) {
      const found = this.rentalStatuses
        .filter(
          status =>
            status.unitId === unitId &&
            new Date(status.start_date) > this.$store.state.app.currentDate
        )
        .sort((a, b) => {
          return new Date(b.start_date) - new Date(a.start_date)
        })
      if (found.length > 0) {
        return found[0]
      } else {
        return { partyName: null, start_date: null }
      }
    },
    getHumanReadableDateTime (date) {
      return helpers.humanize.date(date)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    resetWidgetData () {
      this.allWidgets = this.allWidgets.map(w => {
        w.loading = true
        return w
      })
    },
    getSitesByMapBuildings (b) {
      const viewport = this.map.mapViewport
      if (viewport === null) {
        return b
      }

      b = b.filter(b => {
        if (b.coordinate_x === null || b.coordinate_y === null) {
          return false
        }
        return (
          b.coordinate_x > viewport[0] &&
          b.coordinate_x < viewport[2] &&
          b.coordinate_y > viewport[1] &&
          b.coordinate_y < viewport[3]
        )
      })
      return this.sites.filter(s => {
        if (s !== undefined && b.find(b => b.id_site === s.id_site)) {
          return s
        }
      })
    },
    getSitesByMapCemeteries (g) {
      const viewport = this.map.mapViewport
      if (viewport === null) {
        return g
      }

      g = g.filter(g => {
        if (g.xkoord === null || g.yKoord === null) {
          return false
        }
        return (
          g.xkoord > viewport[0] &&
          g.xkoord < viewport[2] &&
          g.ykoord > viewport[1] &&
          g.ykoord < viewport[3]
        )
      })
      return this.sites.filter(s => {
        if (s !== undefined && g.find(g => g.idSite === s.id_site)) {
          return s
        }
      })
    },
    loadWidgetDataForSites (sites) {
      this.setAllWidgetsToLoading()
      if (sites.length) {
        this.loadRecursionWidgetData()
      }
    },
    setAllWidgetsToLoading () {
      this.allWidgets.forEach(w => {
        w.loading = true
      })
    },
    sortWidgets (widgets) {
      this.smallWidgets = widgets.filter(w => w.small === true)
      this.largeWidgets = widgets.filter(w => w.small !== true)
    },
    async getWidgetOrder () {
      this.widgetOrder = this.userWidgets.filter(w => w.id !== -1)
      const filteredWidgets = this.allWidgets.filter(w => this.widgetOrder.find(wo => wo.name === w.id))
      const order = this.widgetOrder.map(wo => wo.name)
      const sortedWidgets = filteredWidgets.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      sortedWidgets.map(sw => {
        if (this.widgetOrder.find(wo => wo.name === sw.id).isOpen !== null) {
          sw.maximized = [!!this.widgetOrder.find(wo => wo.name === sw.id).isOpen]
        } else if (typeof sw.maximized === 'undefined') {
          sw.maximized = [true]
        }
      })


      if (sortedWidgets.length > 0) {
        this.sortWidgets(sortedWidgets)
      }
      this.$store.commit('app/setSortableWidgets', false)
    },
    async setWidgetOrder () {
      const currentChanges = this.largeWidgets.concat(this.smallWidgets)
      const newOrder = currentChanges.map(widget => widget.id)
      const newWidgetOrder = this.widgetOrder.sort((a, b) => {
        return newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
      })
      newWidgetOrder.map(wo => {
        if (currentChanges.find(cc => cc.id === wo.name)){
          wo.isOpen = currentChanges.find(cc => cc.id === wo.name).maximized[0]
        }
      })
      
      await this.$rambollfmapi.accounts.widgets.put(newWidgetOrder)
      this.$store.dispatch('app/getUserInfo')
      this.$store.commit('app/setSortableWidgets', false)
    },
    dataUpdated (widget, data) {
      // append widget items to widgets data
      this.importingCarParks = false
      const fullWidgetObject = widget
      fullWidgetObject.data.items = this.dataForWidget(widget.id)
      let primaryIdentifier = -1
      let secondaryIdentifier = -1
      if (widget.id === 'dashboard.carpark_market_rent') {
        this.importingCarParks = true
        primaryIdentifier = 5
        secondaryIdentifier = undefined
      }

      const items = helpers.dataTable.updateItems(
        fullWidgetObject.data,
        data.data,
        primaryIdentifier,
        secondaryIdentifier,
        this.importingCarParks
      )
      this.importSuccess = items.success
      this.errors = { unexisting: [], uneditable: [] },
      this.rentalStatusChanges = []
      if (this.importSuccess) {
        this.handleUpdate(items.items, widget.id)
      } else {
        this.errors = items.errors
      }
      this.savingFailed = false
      this.isSavingChanges = false
      this.dataUpdateDialog = true
    },
    handleUpdate (importedItems, widgetId) {
      const reports = this.dataForWidget(widgetId)
      const changedReports = []
      // detect changed rows
      reports.forEach(report => {
        // building code and unit name is used to identify changed information
        const modifiedItem = importedItems.find(item => {
          if (this.importingCarParks) {
            return (
              item.carParkId === report.carParkId &&
              ((item.market_rent !== null &&
              (Number(String(item.market_rent).replace(',', '.')) !==
                report.market_rent)) ||
              (item.rent_target !== null &&
              (Number(String(item.rent_target).replace(',', '.')) !==
                report.rent_target)) ||
              (item.lease_plan !== null && item.lease_plan !== report.lease_plan)
              )
            )
          }
          return (
            item.buildingCode === report.buildingCode &&
            item.unitName === report.unitName &&
            ((item.market_rent !== null &&
            (Number(String(item.market_rent).replace(',', '.')) !==
              report.market_rent)) ||
            (item.estimated_ti_expense !== null &&
            (Number(String(item.estimated_ti_expense).replace(',', '.')) !==
              report.estimated_ti_expense)) ||
            (item.clarified_ti_expense !== null &&
            (Number(String(item.clarified_ti_expense).replace(',', '.')) !==
              report.clarified_ti_expense)) ||
            (item.rent_target !== null &&
            (Number(String(item.rent_target).replace(',', '.')) !==
            report.rent_target)) ||
            (item.lease_plan !== null && item.lease_plan !== report.lease_plan)
            )
          )
        })
        if (modifiedItem) {
          let errorObject = {
            buildingCode: modifiedItem.buildingCode,
            unitName: modifiedItem.unitName
          }
          if (this.importingCarParks) {
            errorObject = {
              siteName: modifiedItem.siteName,
              number: modifiedItem.number
            }
            // excel will automatically convert some car park ids so return original value
            modifiedItem.number = report.number
          }
          const widgetHeaders = this.allWidgets.find(
            widget => widget.id === widgetId
          ).data.headers
          let validMarketRent = true
          // validate input fields that they are in correct format
          widgetHeaders.forEach(header => {
            let isNotCorrect
            if (modifiedItem[header.value]) {
              if (header.type === Number) {
                // export function converts all decimal dots to commas, convert them back
                modifiedItem[header.value] = String(
                  modifiedItem[header.value]
                ).replace(',', '.')
                // ignore NaN notice periods
                isNotCorrect = isNaN(Number(modifiedItem[header.value]))
              }

              if (isNotCorrect) {
                this.errors.uneditable.push({
                  ...errorObject,
                  value: modifiedItem[header.value],
                  label: header.text
                })
                // return original value
                modifiedItem[header.value] = report[header.value]
                if (this.importingCarParks) {
                  validMarketRent = false
                }
              }
            }
            if (header.type === Number) {
              // export function converts all numbers to strings, convert them back to numbers if needed
              modifiedItem[header.value] = modifiedItem[header.value]
                ? Number(modifiedItem[header.value])
                : report[header.value]
            }
            // if editable header has same value in modifieditem obj and report obj remove 
            // it to not send it as updated value in saveRentalStatusChanges method
            if (header.editable && modifiedItem[header.value] === report[header.value]) {
              delete modifiedItem[header.value]
            }
          })
          // Add modified items to changed reports array
          if (validMarketRent) {
            changedReports.push(modifiedItem)
          }          
        }
      })
      this.rentalStatusChanges = changedReports
    },
    async saveRentalStatusChanges () {
      this.isSavingChanges = true
      const carParkPatches = []
      for (const rentalStatusChange of this.rentalStatusChanges) {
        const patchObj = []
        if(rentalStatusChange.market_rent) {
          patchObj.push({
            op: 'replace',
            path: '/market_rent',
            value: rentalStatusChange.market_rent
          })
        }
        if(rentalStatusChange.rent_target) {
          patchObj.push({
            op: 'replace',
            path: '/rent_target',
            value: rentalStatusChange.rent_target
          })
        }
        if(rentalStatusChange.lease_plan) {
          patchObj.push({
            op: 'replace',
            path: '/lease_plan',
            value: rentalStatusChange.lease_plan
          })
        }

        try {
          if (this.importingCarParks) {
              const data = {
              carParkId: rentalStatusChange.carParkId,
              patch: patchObj
            }
            carParkPatches.push(data)       
          }      
        } catch {
          this.savingFailed = true
          this.resetChanges()
          this.dataUpdateDialog = true
        }
      }
      if (this.importingCarParks) {
          // send all carParkPatches to API
          try {
            await this.$rambollfmapi.carparks.marketRents().patch(this.$store.state.app.currentDate, carParkPatches)
          } catch {
            this.savingFailed = true
            this.resetChanges()
            this.dataUpdateDialog = true
          }
        }
      if (this.savingFailed === false) {
        this.resetChanges()
        this.loadWidgetDataForSites(this.sites)
      }
    },
    resetChanges () {
      this.isSavingChanges = false
      this.dataUpdateDialog = false
      this.rentalStatusChanges = []
      this.errors = { unexisting: [], uneditable: [] }
    },
    handleOk () {
      this.dataUpdateDialog = false
      this.errors = { unexisting: [], uneditable: [] }
    },
    getptchooses (){
      if( ! this.ptchooses){
        this.$rambollfmapi.definitions.list('pt.status')
          .then(res => {
            this.ptchooses = []
            this.ptchoosesorig = {}
            res.forEach(r => {
              let tlabel = this.$t(r.label)
              this.ptchoosesorig[tlabel] = r.id
              this.ptchooses.push(tlabel)})
            })
        }
      return this.ptchooses
    },
    changePproperty (thing){
      let {title, property, item } = thing
      if( title === this.$t('dashboard.property_tax') && property === 'status') {
        let statusid = this.ptchoosesorig[item.status]
        let pt = { "status": statusid}
        this.$rambollfmapi.taxes.putpropertytaxstatus(item.property_tax_id, pt)
      }
    },
    setSitesToBeSelected () {
      const sites = this.getSitesByMapBuildings(this.buildingsData)
      const cemeterySites = this.getSitesByMapCemeteries(this.cemeteriesData)
      this.sitesToBeSelected = sites.length + cemeterySites.length
    },
    async deleteAlert (alertId) {
      if (confirm(this.$t('alert.confirm_delete'))) {
        await this.$rambollfmapi.alerts.delete(alertId)
        const widgetName = 'dashboard.alerts'
        this.updateSingleWidgetData(widgetName)
      }
    },
    editAlert (item) {
      this.alertToBeEdited = item
      this.showAddAlertDialog = true
    },
    triggerCustomEvents (event) {
      if (event.eventName === 'showCreateRentContractModal') {
        this.openContractModal(event.row)
      } else if (event.eventName === 'goToSite') {
        // site link can only work when there's only one site
        if (event.row.siteIds?.split(',')?.length === 1) {
          this.$router.push(`/sites/${event.row.siteIds?.trim()}`)
        }
      }
    },
    openContractModal (row) {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') 
        || this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
        this.outRent = row.outRent
        this.editedContractId = row.idContract
        this.showCreateRentContractModal = true
      }
    },
    onCreateRentContractModalClosed () {
      this.editedContractId = undefined
      this.showCreateRentContractModal = false
    },
    onContractIdUpdated (contractId) {
      this.editedContractId = contractId
    },
    openEditPermissionValidityModal (widgetId,item) {
      if(widgetId === 'dashboard.building_permission_validity' && item.editableRow ){
       this.openEditBuildingPermissionValidityModal(item)
      }
      if(widgetId === 'dashboard.unit_permission_validity' && item.editableRow ){
       this.openEditUnitPermissionValidityModal(item)
      }
    },
    openEditBuildingPermissionValidityModal (item) {
      this.editedBuilding = item
      this.showModifyBuildingPermissionValidityModal = true
    },
    closeEditBuildingPermissionValidityModal () {
      this.editedBuilding = undefined
      this.showModifyBuildingPermissionValidityModal = false
    },
    openEditUnitPermissionValidityModal (item) {
      this.editedUnit = item
      this.showModifyUnitPermissionValidityModal = true
    },
    closeEditUnitPermissionValidityModal () {
      this.editedUnit = undefined
      this.showModifyUnitPermissionValidityModal = false
    },
  }
}
</script>

<style>
.v-datatable .trend-plus {
  border: 2px solid #d0efda !important;
  color: green;
}
.v-datatable .trend-minus {
  border: 2px solid #f8d5d5 !important;
  color: red;
}
.v-datatable tfoot {
  empty-cells: hide;
}

.dashboard > .col {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}
.map {
  height: 700px;
}
.map .ol-viewport {
  border-radius: 1em;
}

#dashboard-map .ol-control {
  background-color: transparent;
}
#dashboard-map .ol-control .ol-zoom-in {
  border-radius: 1em 1em 0 0;
  background: var(--r-color-white);
  border: 1px solid var(--r-color-mountain-20);
  color: var(--r-color-mountain-80);

}

#dashboard-map .ol-control .ol-zoom-out {
  border-radius: 0 0 1em 1em;
  background: var(--r-color-white);
  border: 1px solid var(--r-color-mountain-20);
  color: var(--r-color-mountain-80);
}
.pagination_page {
  margin: 0;
}
</style>
