
import { i18n } from "../../../../../../plugins/i18n";
import helpers from "../../../../../../helpers";
import moment from 'moment'


export default {
  getTargetUnits (payment, units) {
    if (payment.wholeContractPayment) {
      return i18n.t('create_rent_contract.single_payment_whole_contract')
    }

    const findUnitName = (id) => {
      const foundUnit = units.find(unit => unit.id === id && !!unit.unitId)
      return foundUnit ? foundUnit.unitCode :  i18n.t('create_rent_contract.unit_not_found')
    }

    const findStructureName = (id) => {
      const foundStructure = units.find(unit => unit.structureId === id && !!unit.structureId)
      return foundStructure ? foundStructure.unitCode :  i18n.t('create_rent_contract.unit_not_found')
    }

    const findParkingSpaceName = (id) => {
      const foundParkingSpace = units.find(unit => unit.id === id && !!unit.parkingSpaceId)
      return foundParkingSpace ? foundParkingSpace.unitCode : i18n.t('create_rent_contract.unit_not_found')
    }

    const unitNames = payment.targetUnits ? payment.targetUnits.map(target => findUnitName(target)) : ""
    const structureNames = payment.targetStructures ? payment.targetStructures.map(target => findStructureName(target)) : ""
    const parkingSpaceNames = payment.targetParkingSpaces ? payment.targetParkingSpaces.map(target => findParkingSpaceName(target)) : ""

    return [...unitNames, ...structureNames, ...parkingSpaceNames].join(", ")
  },
  getDate (paymentDate) {
    return paymentDate ? helpers.humanize.date(moment(paymentDate).format(), 'datetime') : ""
  },
  getTotalArea (payment, selectedUnits) {
    const unitAgreedAreas = selectedUnits
      ?.filter(unit =>
        payment.wholeContractPayment ||
        payment.targetUnits?.some(targetUnit => targetUnit === unit.id && !unit.isStructure && !unit.isParkingSpace) ||
        payment.targetStructures?.some(targetStructure => targetStructure === unit.id && unit.isStructure) ||
        payment.targetParkingSpaces?.some(targetParkingSpace => targetParkingSpace === unit.id && unit.isParkingSpace)
      )
      .map(unit => unit.agreedArea);

    if (unitAgreedAreas && unitAgreedAreas.length > 0) {
      let selected = unitAgreedAreas.map(area => {
        const cleanedArea = typeof area === 'string' ? area.replace(',', '.') : area.toString();
        return parseFloat(cleanedArea);
      });

      let agreedAreaSum = selected.reduce((a, b) => a + b);
      return agreedAreaSum.toFixed(2);
    }

    return 0;
  },
};
