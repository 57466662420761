import lodash from 'lodash'

export default {
    cloneDeep (toBeCloned) {
    if (toBeCloned == null || toBeCloned === undefined) {
        return {}
    }
    return lodash.cloneDeep(toBeCloned)
    }
}
