import RambollFMAPI from "../api/rambollfm"

export default {
  namespaced: true,
  state: {
    endingContracts: [],
    endingContractsLoading: false,
  },
  getters: {},
  mutations: {
    setEndingContracts (state, data) {
      state.endingContracts = data
    },
    setEndingContractsLoading (state, data) {
      state.endingContractsLoading = data
    },
  },
  actions: {
    async fetchEndingContracts ({ commit, rootState }, { months }) {
      commit("setEndingContractsLoading", true)
      try {
        const {
          app: { currentDate, apiEndpoint, apiToken },
        } = rootState

        const api = new RambollFMAPI(apiEndpoint, apiToken)

        const data = await api.contracts.contract.getEndingContracts(
          currentDate,
          months
        )

        commit("setEndingContracts", data)
        commit("setEndingContractsLoading", false)
      } catch (err) {
        commit("setEndingContractsLoading", false)
        throw Error(err)
      }
    },
    async patchContract ({commit, rootState, state}, {id, changes}){      
      const patch = Object.entries(changes).map(([path, value]) => {
        return { op: "replace", path, value}
      })

      const {
        app: { apiEndpoint, apiToken },
      } = rootState

      const { endingContracts } = state

      // Update ending contracts. Doing this so that the widgets update automatically.
      const newEndingContracts = endingContracts.map(contract => {
        if(contract.id === id){
          return {...contract, ...changes}
        }  
        return contract
      })

      commit("setEndingContracts", newEndingContracts)
      // We don't want to block UI so we do the patch operation after changing UI state. 
      // Also do the patch on background without spinner for better user experience.
      const api = new RambollFMAPI(apiEndpoint, apiToken)
      await api.contracts.contract.patchContract(id, patch)

    }
  },
}
