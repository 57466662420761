<template>
  <div>
    <v-list
      v-if="data"
      dense
    >
      <v-list-item
        v-for="field in fields"
        :key="field.name"
        class="litem"
        :class="{
          tile: field.editType !== 'TextArea' || !multiline,
          'tile tile-full-height': field.editType === 'TextArea' && multiline,
        }"
      >
        <v-list-item-content class="tile-content">
          <v-text-field
            v-if="field.editType === 'TextField' && !disabled"
            v-model="data[field.name]"
            :rules="[v => !!v || !field.isRequired]"
            :label="getFieldName(field.name, field.isRequired)"
            :required="field.isRequired"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
       
          <v-menu
            v-else-if="field.editType === 'Date' && !disabled"
            min-width="0"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-text-field
                :value="formatDate(data[field.name])"
                :label="getFieldName(field.name, field.isRequired)"
                prepend-icon="calendar_today"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="data[field.name]"
              no-title
              scrollable
              first-day-of-week="1"
              :locale="$i18n.locale"
              @change="$emit('change', { data, field, value: data[field.name] })"
            />
          </v-menu>

          <v-text-field
            v-else-if="field.editType === 'Number' &&
              field.type !== 'Boolean' &&
              field.format === 'Area' &&
              !disabled"
            v-model="data[field.name]"
            type="number"
            :rules="[v => (!!v || !field.isRequired) && requiredIfZero(data[field.name])]"
            :required="field.isRequired"
            hide-details
            item-text="area"
            :label="$t(getFieldName(field.name), field.isRequired)"
          /> 

          <NumericField
            v-else-if="
              field.editType === 'Number' && field.type !== 'Boolean' && !disabled
            "
            v-model="data[field.name]"
            :label="$t(getFieldName(field.name), field.isRequired)"
            :field="field"
            :placeholder="data[field.name]"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-select
            v-else-if="field.editType === 'Checkbox' && !disabled"
            v-model="data[field.name]"
            small
            :label="$t(getFieldName(field.name), field.isRequired)"
            :items="checkboxItems"
            item-text="text"
            item-value="value"
            class="borderless"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />

          <v-combobox
            v-else-if="field.name === 'tags'"
            v-model="data[field.name]"
            :items="availableTags"
            :label="$t('Category')"
            :placeholder="$t('Category')"
            clearable
            multiple
            small-chips
            deletable-chips
            item-text="name"
            item-value="id"
          />

          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && field.name"
            v-model="data[field.name]"
            :label="$t(getFieldName(field.name), field.isRequired)"
            small
            :items="
              getDefinitionOptionsById(field.definitionGroupId, field.isRequired)
            "
            item-text="label"
            item-value="id"
            :disabled="getDefinitionOptionsById(field.definitionGroupId, field.isRequired).length===0"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
        
          <template v-else-if="field.editType === 'TextArea' && field.category !== 'Description' && !disabled">
            <v-textarea
              v-model="data[field.name]"
              :single-line="field.category === 'Description'"
              :label="$t(getFieldName(field.name), field.isRequired)"
              hide-details
              :disabled="disabled"
              :rows="getRows()"
              class="mt-2 mb-0"
              @change="$emit('change', { data, field, value: data[field.name] })"
            />
          </template>

          <v-textarea
            v-else-if="field.editType === 'TextArea' && !disabled"
            v-model="data[field.name]"
            :single-line="field.category === 'Description'"
            class="borderless"
            :label="$t(getFieldName(field.name), field.isRequired)"
            hide-details
            :disabled="disabled"
            :rows="getRows()"
            @change="$emit('change', { data, field, value: data[field.name] })"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters} from 'vuex'
import Alert from '../components/Alert'
import NumericField from '../components/NumericField'
export default {
  components: {
    Alert,
    NumericField,
  },
  props: {
    metadata: {
      type: Object,
      default: null
    },
    data: {
      type: [Array, Object],
      default: null
    },
    category: {
      type: String,
      default: 'None'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    },
    availableTags: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      checkboxItems: [
        { text: this.$t('Yes'), value: true },
        { text: this.$t('No'), value: false },
        { text: this.$t('Undefined'), value: null }
      ],
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      localMeta: null
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById']),
    ...mapState('app', ['cleaningAreas']),
    cleaningAreaChoices () {
      return this.cleaningAreas
    },
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    fields () {
      if (this.localMeta === null) {
        return []
      }
      return Object.keys(this.localMeta)
        .map(key => {
          return { ...this.localMeta[key], name: key }
        })
        .filter(v => v.editType !== 'None')
        .filter(v => v.category === this.category)
        .filter(v => v.name !== 'name')
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1
          }
          if (a.order > b.order) {
            return 1
          }
          return 0
        })
    }
  },
  watch: {
    data: function () {},
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  mounted: async function () {
    this.localMeta = JSON.parse(JSON.stringify(this.metadata))
  },
  methods: {
    onlyNumeric (value) {
      if (value !== '') {
        if (!/^\d*(\.\d{1,2})?$/.test(value)) {
          this.indicatorRunning = true
          this.indicatorMessage = this.$t('Field accepts only numeric values')
          this.operationResult = 'error'
          this.showIndicator = true
        }
      }
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    getFieldName: function (field, isRequired) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)

      if (Object.keys(this.data).indexOf('unit_code') > -1) {
        if (field === 'Id') {
          field = this.$t('Unit') + ' ' + field
        }
      }
      if (Object.keys(this.data).indexOf('rental_type') > -1) {
        if (field === 'Market rent car Unit') {
          field = field.replace('car', '€/car')
        }
      }
      if (field === 'Permission validity date') {
        field = this.$t('Permission validity')
      }
      if (field === 'Not rentable') {
        field = this.$t('Unit rental status')
      }
      if (field === 'Area') {
          field = 'Unit area'        
      } else if (field === 'Usage') {
          field = 'Unit use'        
      }
      field = this.$t(field)
      
      if (isRequired === true) {
        field += ' *'
      }
      return field
    },
    getDefinitionOptionsById (definitionId, isRequired) {
      if (definitionId === 0) {
        return []
      }
      var defs = this.definitionsByGroupId(definitionId).map(d => {
        return {
          id: d.id,
          annotationGroup: d.annotationGroup,
          label: this.$t(d.label)
        }
      })

      if (isRequired === false) {
        defs.unshift({
          id: null,
          annotationGroup: null,
          label: this.$t('undefined')
        })
      }

      return defs
    },
    update () {},
    getRows () {
      if (this.multiline) {
        return 5
      } else {
        return 1
      }
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    requiredIfZero (value) {
      return value <= 0 ? this.$t('Value must be positive') : true
    },
  }
}
</script>
<style scoped>
.litem {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>