<template>
  <div>
    {{ $t('Status') }} {{ getDate() }}
    <br>
    {{ $t(allItems.name) }}
    <br>
    {{ loadedSites[0].name }}
    <span v-if="floor">
      <br> ( {{ floor.floor_name }} )
    </span>
    <span v-else>
      {{ $t('Estate').toUpperCase() }}
    </span>

    <table
      v-for="(item, index) in selectedItems"
      :key="item.key"
    >
      <span v-if="index < 29 && allItems.name === 'Spaces'">
        <tr>
          <th>
            <div
              class="box" 
              :style="{ backgroundColor: item.rgba }"
            />
          </th>
          <th>
            <div
              class="text"
            >
              &nbsp;{{ item.name }} ({{ item.value.toFixed(2) }} {{ allItems.unit }} )
            </div>
          </th>
        </tr>
        <br>
      </span>
        
      <tr v-else-if="index == 29 && allItems.name === 'Spaces'">
        ...
      </tr>
      <span v-else-if="index < 17 && allItems.name !== 'Spaces'">
        <tr>
          <th>
            <div
              class="box" 
              :style="{ backgroundColor: item.rgba }"
            />
          </th>
          <th>
            <div
              class="text"
            >
              &nbsp;{{ item.name }} <br> ({{ item.value.toFixed(2) }} {{ allItems.unit }} )
            </div>
          </th>
        </tr>
        <br>
      </span>
        
      <tr v-else-if="index == 17 && allItems.name !== 'Spaces'">
        &nbsp;...
      </tr>
    </table>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
   name: 'VTHPrintLegend',
   props: {
    selectedItems: {
       default: () => {
        return []
      },
      type: Array
    },
    allItems: {
      type: Object,
      default: null
    },
    floor: {
      type: Object,
      default: null
    }
   },
   data () {
       return {
       }
   },
   computed: {
       ...mapState('app', ['currentDate']),
       ...mapState('sites', ['loadedSites'])
   },
   methods: {
    getDate ()
    {
        return moment(this.currentDate).format('DD.MM.YYYY')
    }
   }

}
</script>
<style>
.box {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  vertical-align: top;
  border-radius: 3px;
}
.text {
  font-weight: 400;
}
th {
  vertical-align: top;
  text-align: left;
}
</style>
