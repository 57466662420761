<template>
  <v-row>
    <v-col
      :class="{ linked: props.item.id_process != null }"
      @click="additionalAction(props.item)"
    >
      {{ formatData(props.item, header.value, header.format, true) }}
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../../../helpers'
import { mapActions } from 'vuex'

export default {
  name: 'RentingProcessTitleColumn',
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
  },
  methods: {
    ...mapActions('rentalProcess', ['openRentalProcess']),
    formatData (row, header, format, isLink = false) {
      const value = isLink
        ? helpers.format.escapeHtml(row[header])
        : row[header]
      return helpers.format.formatData(value, format)
    },

    additionalAction (item) {
      if (item.id_process) {
        this.openRentalProcess({ id: item.id_process })
      }
    },
  },
}
</script>

<style scoped>
</style>
