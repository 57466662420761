<template>
  <v-menu
    v-if="
      title === $t('leasing.my_prospects') &&
        props.item.prospect_status === 'Active'
    "
    :offset-y="true"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        text
        outlined
        :ripple="false"
        v-bind="attrs"
        class="success_selector"
        v-on="on"
      >
        <v-btn
          fab
          small
          elevation="0"
          class="no-hover"
          :class="getSuccessLikelihoodClass(props.item.success_likelihood)"
        >
          <v-icon
            :size="getSuccessLIkelihoodSize(props.item.success_likelihood)"
            :color="
              getSuccessLikelihoodIconColor(props.item.success_likelihood)
            "
          >
            {{ getSuccessLikelihoodIcon(props.item.success_likelihood) }}
          </v-icon>
        </v-btn>
        <v-icon right>
          expand_more
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="option in likelihoodItemsFiltered"
          :key="option.value"
          class="text-center"
          @click="additionalAction(props.item, option.value)"
        >
          <v-list-item-title>
            <v-btn
              fab
              small
              elevation="0"
              :class="getSuccessLikelihoodClass(option.value)"
            >
              <v-icon size="48px">
                {{ option.icon }}
              </v-icon>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
  <v-btn
    v-else-if="props.item.success_likelihood"
    fab
    small
    elevation="0"
    class="no-hover"
    :class="getSuccessLikelihoodClass(props.item.success_likelihood)"
  >
    <v-icon
      :size="getSuccessLIkelihoodSize(props.item.success_likelihood)"
      :color="getSuccessLikelihoodIconColor(props.item.success_likelihood)"
    >
      {{ getSuccessLikelihoodIcon(props.item.success_likelihood) }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'
import { prospectLikelihoodColors } from '../../../constants/colors'
export default {
  name: 'SuccessLikelihoodColumn',
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
    title: { type: String, default: null },
  },
  data () {
    return {
      likelihoodItems: [
        { value: 'High', icon: 'sentiment_satisfied_alt' },
        { value: 'Intermediate', icon: 'sentiment_neutral' },
        { value: 'Low', icon: 'sentiment_very_dissatisfied' },
      ],
    }
  },
  computed: {
    likelihoodItemsFiltered () {
      return this.likelihoodItems.filter(
        (likelihoodItem) =>
          likelihoodItem.value !== this.props.item.success_likelihood
      )
    },
  },
  methods: {
    ...mapActions('leasing', ['changeProspectSuccessLikelihood']),
    getSuccessLIkelihoodSize (value) {
      switch (value) {
        case 'Won':
        case 'ActiveWithoutLikelihood':
          return '52px'
        default:
          return '48px'
      }
    },
    getSuccessLikelihoodClass (value) {
      switch (value) {
        case 'AwaitingAllocation':
        case 'High':
          return 'green_background'
        case 'Intermediate':
          return 'yellow_background'
        case 'Low':
          return 'red_background'
        case 'ActiveWithoutLikelihood':
        case 'Won':
          return 'black_background'
        default:
          return 'transparent'
      }
    },
    getSuccessLikelihoodIcon (value) {
      /**
       * Odottaa kohdistusta: tähtisilmät, samalla värillä kun toinenkin vihreä (sentiment_very_satisfied)
       * Aktiivinen, johon ei ole asetettu todennäköisyyttä: Harmaa pallo jossa kysymysmerkki (help)
       * Todennäköisyys ei ole relevantti (kaikki muut status-arvot paitsi aktiivinen ja odottaa kohdistusta): Harmaa pallo (circle)
       * Voitettu: Vihreä tähti
       */

      switch (value) {
        case 'AwaitingAllocation':
          return 'sentiment_very_satisfied'
        case 'High':
          return 'sentiment_satisfied_alt'
        case 'Intermediate':
          return 'sentiment_neutral'
        case 'Low':
          return 'sentiment_very_dissatisfied'
        case 'Won':
          return 'stars'
        case 'NotRelevant':
          return 'circle'
        case 'ActiveWithoutLikelihood':
          return 'help'
        default:
          return ''
      }
    },
    getSuccessLikelihoodIconColor (value) {
      switch (value) {
        case 'ActiveWithoutLikelihood':
        case 'NotRelevant':
          return prospectLikelihoodColors['prospect.success_likelihood_unknown']
        case 'Won':
          return prospectLikelihoodColors['prospect.success_likelihood_high']
        default:
          return ''
      }
    },
    additionalAction (item, newLikelihood) {
      this.changeProspectSuccessLikelihood({
        prospectId: item.id_prospect,
        data: newLikelihood,
      })
    },
  },
}
</script>

<style scoped>
.success_selector {
  height: 50px !important;
}
.green_background {
  background-color: #bddaaa !important;
}
.yellow_background {
  background-color: #ffe682 !important;
}
.red_background {
  background-color: #f27d7d !important;
}
.transparent {
  background-color: transparent !important;
}
.black_background {
  background-color: black !important;
}
</style>
