<template>
  <v-menu
    offset-y
  >
    <template #activator="{ on }">
      <v-btn
        outlined
        large
        icon
        color="primary"
        class="mr-2"
        v-on="on"
      >
        <v-icon>add</v-icon>
        <span class="d-sr-only">{{ $t('Add') }}</span>
      </v-btn>
    </template>
    <v-list
      nav
      dense
    >
      <v-list-item
        v-if="hasWritePermission"
        @click="() => $emit('open', 'newDocument')"
      >
        <v-list-item-content>
          <v-list-item-title>{{ $t('Document.newDocument') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasWritePermission"
        @click="$emit('open', 'multipleNewDocuments')"
      >
        <v-list-item-content>
          <v-list-item-title>{{ $t('Document.multipleNewDocuments') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default
{
  props: {
    hasWritePermission: {
      type: Boolean
    }
  },
  emits: ['open']
}
</script>

<style scoped>
</style>
