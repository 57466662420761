<template>
  <v-btn
    small
    outlined
    rounded
    :disabled="disabled"
    @click="openDialog = true"
  >
    {{ $t('Import from spreadsheet') }}
    <v-dialog
      v-if="openDialog"
      v-model="openDialog"
      max-width="550"
      persistent
    >
      <BaseModal
        @cancel="close"
      >
        <template #title>
          {{ $t('Import from spreadsheet') }}
        </template>
        <template #content>
          <h3>{{ $t('dataimport.editable_fields') }}</h3>
          <ul>
            <li
              v-for="header in editableHeaders"
              :key="header.value"
            >
              {{ headerText(header.text) }}
            </li>
          </ul>
          <br>
          <p v-if="requiredFields.length > 0">
            {{ $t('dataimport.required_field') }}
          </p>            
          <br>
          <p v-if="editableHeadersContainsDate">
            {{ dateFormat }}
          </p>
          <p v-if="checkHeader('market_rent')">
            {{
              $t('dataimport.required_market_rent_format') +
                ': ' +
                $t('dataimport.market_rent_format')
            }}
          </p>
          <p v-if="checkHeader('estimated_ti_expense')">
            {{
              $t('dataimport.required_estimated_ti_expense_format')
            }}
          </p>
          <p v-if="checkHeader('vacancy_rate_assumption')">
            {{
              $t('dataimport.required_vacancy_rate_assumption_format')
            }}
          </p>
          <p v-if="checkHeader('clarified_ti_expense')">
            {{
              $t('dataimport.required_clarified_ti_expense_format')
            }}
          </p>
          <p v-if="checkHeader('list_rent_apartment')">
            {{
              $t('dataimport.required_list_rent_apartment')
            }}
          </p>
          <p v-if="checkHeader('rent_target')">
            {{
              $t('dataimport.required_rent_target_format')
            }}
          </p>
          <p v-if="checkHeader('lease_plan')">
            {{
              $t('dataimport.required_lease_plan_format')
            }}
          </p>
          <div v-if="error">
            <h4>{{ $t('dataimport.import_failed') }}</h4>
            <p>{{ error }}</p>
          </div>
        </template>
        <template #footer>
          <v-btn
            rounded
            depressed
            :disabled="loading"
            color="primary"
            @click="$refs.fileInput.click()"
          >
            <span v-if="!loading">{{ $t('Choose file') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      accept=".csv"
      @change="handleFile"
    >
  </v-btn>
</template>
<script>
import helpers from '../helpers'
import BaseModal from './general/BaseModal'
export default {
  name: 'ImportButton',
  components: { BaseModal },
  props: {
    headers: {
      type: Array,
      default: null
    },
    additionalDateFormat: {
      type: Boolean,
      default: false
    },
    requiredFields: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      openDialog: false,
      loading: false,
      error: null,
    }
  },
  computed: {
    editableHeaders () {
      if (this.headers.find(header => header.value === "carParkId")) {
        return this.headers.filter(header => header.editable && header.value !== "number")
      }
      return this.headers.filter(header => header.editable)
    },
    editableHeadersContainsDate () {
      const containsDate = this.editableHeaders.filter(header => {
        return header.value.includes('Date')
      })

      return !!containsDate.length
    },
    dateFormat () {
      return `${this.$t('dataimport.required_date_format')}: 
              ${this.$t('dataimport.date_format')}${this.additionalDateFormat ? this.$t('dataimport.date_format_additional'): ""}`
    }
  },
  methods: {
    async handleFile (event) {
      this.error = null
      this.loading = true
      const data = event.target.files[0]
      // simple check that file is csv type
      if (!data || !data.name || !data.name.includes('.csv')) {
        this.error = this.$t('dataimport.file_type_error_csv')
      } else if (data instanceof Blob) {
        const results = await helpers.csv.import(data, this.format)
        if (results.error) {
          const format = this.format ? this.$t(this.format) : this.$t('utf-8')
          this.error = `${this.$t(results.error)} ${format}`
        } else {
          this.openDialog = false
          this.$emit('dataUpdate', results)
        }
      }
      this.loading = false
      // reset value so same file can be chosen again if needed
      event.target.value = null
    },
    close () {
      this.openDialog = false
      this.loading = false
      this.error = null
    },
    headerText (text) {
      return `${text} ${this.requiredFields.includes(text) ? "*" : ""}`
    }, 
    checkHeader (headerValue) {
      const containsHeader =  this.editableHeaders.filter(header => {
        return header.value.includes(headerValue)
      })
      return containsHeader.length
    }
  }
}
</script>
<style>
</style>
