<template>
  <v-container>
    <v-row
      class="section fill-height"
    >
      <v-col>
        <h4 class="text--secondary">
          {{ $t('Basic information') }}
        </h4>
      </v-col>
    </v-row>
    <v-row
      class="section fill-height"
    >
      <v-col cols="12">
        <v-select
          v-model="selectedSite"
          :label="$t('Site name')"
          :items="sites"
          item-text="name"
          item-value="id_site"
          :readonly="!!task.id"
          :clearable="!!task.id === false"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          ref="buildingName"
          v-model="basicInfo.buildingId"
          :label="$t('Building name')"
          :items="filteredBuildings"
          item-text="building_name"
          item-value="building_code"
          :readonly="!!task.id"
          :rules="[rules.buildingName]"
          required
          @change="$emit('information', basicInfo); selectSiteForBuilding()"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model.number="basicInfo.projectNumber"
          :label="$t('Project number')"
          :readonly="task.finished"
          @change="$emit('information', basicInfo)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          ref="taskName"
          v-model="basicInfo.taskName"
          :label="$t('Task name')"
          :rules="[rules.taskName]"
          required
          :readonly="task.finished"
          @change="$emit('information', basicInfo)"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="basicInfo.taskSourceId"
          :label="$t('taskBasicInfo.taskSource')"
          :items="sourceCodes"
          item-text="text"
          item-value="value"
          :clearable="!task.finished"
          :readonly="task.finished"
          @change="$emit('information', basicInfo)"
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="basicInfo.taskCategory"
          :label="$t('Task category')"
          :items="categories"
          item-text="name"
          item-value="code"
          :clearable="!task.finished"
          :readonly="task.finished"
          @change="$emit('information', basicInfo)"
        >
          <template #selection="{ item }">
            <span v-text="item.name" />
          </template>
          <template #item="{ item }">
            <span v-text="item.name" />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="basicInfo.taskClassId"
          :label="$t('Task class')"
          :items="classes"
          :filter="classFilter"
          item-text="name"
          item-value="id"
          :rules="[rules.taskClass]"
          :readonly="task.finished"
          clearable
        >
          <template #selection="{ item }">
            <span v-text="item.code + ' - ' + item.name" />
          </template>
          <template #item="{ item }">
            <span v-text="item.code + ' - ' + item.name" />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          ref="taskCode"
          v-model="basicInfo.taskCodeId"
          :label="$t('Task code')"
          :items="codes"
          item-text="name"
          item-value="id"
          :readonly="task.finished"
          clearable
        >
          <template #selection="{ item }">
            <span v-text="item.code + ' - ' + item.name" />
          </template>
          <template #item="{ item }">
            <span v-text="item.code + ' - ' + item.name" />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="basicInfo.taskDescription"
          :label="$t('Task instruction')"
          :readonly="task.finished"
          @change="$emit('information', basicInfo)"
        />
      </v-col>
    </v-row>
    <v-spacer />
    <v-divider />
    <v-spacer />
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import helpers from '../../helpers'
export default {
  name: 'TaskBasicInformation',
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      categories: [],
      classes: [],
      codes: [],
      code: null,
      basicInfo: {
        buildingId: this.task.buildingId,
        taskName: this.task.taskName,
        taskSourceId: this.task.taskSourceId,
        taskCategory: this.task.taskCategory,
        taskCode: this.task.taskCode,
        taskCodeId: this.task.taskCodeId,
        taskClass: this.task.taskClass,
        taskClassId: this.task.taskClassId,
        taskDescription: this.task.taskDescription,
        projectNumber: this.task.projectNumber
      },
      sourceCodes: helpers.ltpConstants.taskSourceCodes(),
      selectedSite: null,
    }
  },
  computed: {
    ...mapState('app', ['sites']),
    ...mapState('ltp', [
      'ltpBuildings',
      'esgCategories',
      'esgClasses',
      'esgCodes',
      'capexCategories',
      'capexClasses',
      'capexCodes'
      ]),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    esgPermission () {
      return this.hasApplicationPermissionByName('PTS_ESG_TEHTAVAT')
    },
    filteredBuildings () {
      if (this.selectedSite !== null) {
        return this.ltpBuildings.filter(x => x.id_site === this.selectedSite)
      }
      return this.ltpBuildings
    }
  },
  watch: {
    'basicInfo.taskCategory': function () {
      this.classes = this.getClasses(this.basicInfo.taskCategory)
      // Validate data
      if (!this.classes.map(c => c.id).includes(this.basicInfo.taskClassId)) {
        this.basicInfo.taskClassId = null;
      } else {
        this.codes = this.getCodes(this.basicInfo.taskClassId)
      }

      this.$emit('information', this.basicInfo)
    },
    'basicInfo.taskClassId': function (classId) {
      // Clearing class Id clears code as well
      if (classId === null) {
        this.basicInfo.taskCodeId = null;
      }

      this.codes = this.getCodes(classId)

      // Validate data
      if (!this.codes.map(c => c.id).includes(this.basicInfo.taskCodeId)) {
        this.basicInfo.taskCodeId = null;
      }

      this.basicInfo.taskClass = [...this.capexClasses, ...this.esgClasses].find(c => c.id === classId)?.code
      this.basicInfo.taskClassId = classId
      this.$emit('information', this.basicInfo)
    },
    'basicInfo.taskCodeId': function (codeId) {
      this.basicInfo.taskCode = [...this.capexCodes, ...this.esgCodes].find(c => c.id === codeId)?.code
      this.basicInfo.taskCodeId = codeId 
      this.$emit('information', this.basicInfo)
    },
    selectedSite: function (siteId) {
      const currentlySelectedBuilding = this.ltpBuildings.find(x => x.building_code === this.basicInfo.buildingId)
      if (currentlySelectedBuilding) {
        if (currentlySelectedBuilding.id_site !== siteId) {
          this.basicInfo.buildingId = ""
        }
      }
    }
  },
  async mounted () {
    this.selectedSite = this.ltpBuildings.find(x => x.building_code === this.basicInfo.buildingId)?.id_site ?? null
    this.categories = this.getCategories()
    this.classes = this.getClasses(this.basicInfo.taskCategory)
    if (this.basicInfo.taskClassId) {
      this.codes = this.getCodes(this.basicInfo.taskClassId)
    }
    this.$emit('information', this.basicInfo)
  },
  methods: {
    classFilter (item, queryText) {
      const textOne = item.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1
    },
    getSelectedClass (code) {
      return code.startsWith('ESG') ? 'ESG' : code.slice(0,1)
    },
    getCategories () {
      if (!this.esgPermission) {
        return this.capexCategories
      }
      if (this.esgPermission) {
        return [
          ...this.capexCategories,
          ...this.esgCategories
        ]
      }
    },
    getClasses (taskCategory = undefined) {
      const classesCapex = this.capexClasses
      const classesEsg = this.esgClasses
      if (this.esgPermission) {
        if (taskCategory === 'ESG') {
          return classesEsg
        }
        if (!taskCategory || taskCategory === 'Capex & ESG') {
          return classesCapex.concat(classesEsg)
        }
      }
      return classesCapex
    },
    getCodes (taskClassId = undefined) {
      const codesCapex = this.capexCodes
      const codesEsg = this.esgCodes
      const codes = this.esgPermission ? [...codesCapex, ...codesEsg] : [...codesCapex]

      if (taskClassId !== undefined) {
        return codes.filter(c => c.classId === taskClassId)
      }
      return codesCapex
    },
    selectSiteForBuilding () {
      const currentlySelectedBuilding = this.ltpBuildings.find(x => x.building_code === this.basicInfo.buildingId)
      if (currentlySelectedBuilding) {
        this.selectedSite = currentlySelectedBuilding.id_site
      }
    }
  },
}
</script>
<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>
