<template>
  <div
    class="chip-container"
    style="display: block; max-width: 100%"
  >
    <v-chip
      v-for="tag in items"
      :key="tag.id_tag"
      small
      outlined
    >
      {{ tag.name }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ChipField',
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style>
.chip-container {
  color: #4d99cd !important;
}
.chip-container .v-chip--outline {
  max-width: inherit;
}
.chip-container .v-chip.v-chip.v-chip--outline {
  border: 1px solid #4d99cd !important;
}
.chip-container .v-chip__content {
  color: #4d99cd !important;
  overflow: hidden;
}
.chip-container .v-btn.v-btn--flat {
  background-color: #4d99cd !important;
  color: white !important;
}
</style>
