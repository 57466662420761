<template>
  <div>
    <div
      v-if="contractsLoading"
      style="text-align: center; padding: 2em;"
    >
      <v-progress-circular
        size="32"
        indeterminate
        color="primary"
      />
    </div>
    <v-expansion-panels
      v-else-if="renting && contracts.length > 0 && hasApplicationPermissionByName('VSOP_POHJAKUVA')"
      class="my-2"
      multiple
      :value="[0]"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="py-1 pl-2">
          {{ $t('site.rental_contracts') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ContractDataForm
            :data="contractsWithCapitalRentPerSquare"
            :metadata="getContractMetadataForUnit()"
          /> 
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      v-if="renting"
      class="my-2"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="py-1 pl-2">
          {{ $t('leasing.commercial_info') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :data="getCommercialDataForUnit()"
            :metadata="getCommercialMetadataForUnit()"
            :with-status-icon="true"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hasApplicationPermissionByName('VSOP_POHJAKUVA_VAJAAKAYTTO')">
        <v-expansion-panel-header class="py-1 pl-2">
          {{ $t('leasing.vacancy_rate_assumption') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :data="getVacancyAssumptionDataForUnit"
            :metadata="getVacancyAssumptionMetadataForUnit"
            :with-status-icon="true"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="hasApplicationPermissionByName('LEASING_PROSPEKTIT')">
      <!-- Widgets -->
      <Notes 
        :single-unit="unit"
        :single-site="currentSite"
      />
    </div>
    <DynamicWidget
      v-if="hasApplicationPermissionByName('LEASING')"
      :type="rentalProcessWidget.type"
      :title="$t(rentalProcessWidget.id)"
      :data="{...rentalProcessWidget.data, items: getUnitRentalProcesses()}"
      :loading="rentalProcessWidget.loading"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MetadataForm from '../MetadataForm.vue'
import ContractDataForm from '../../components/Leasing/ContractDataForm.vue'
import DynamicWidget from '../DynamicWidget.vue'
import Notes from '../../components/Leasing/Notes.vue'
import { CapitalRentCalculator } from '../../helpers/leasing/capitalRent'
import { getRentalProcessTitle } from "../../helpers/leasing/rentalprocess"

import humanize from './../../helpers/humanize'

export default {
  name: "RentingUnit",
  components: { MetadataForm, ContractDataForm, DynamicWidget, Notes },
  props: {
    unit: { type: Object,default: null },
    unitVisibilityRights: { type: Object, default: null },
    layer:{ type: String,  default: '' },
    contracts: { type: Array, default: () => [] },
    contractsLoading: { type: Boolean, default: false },
    renting: { type: Boolean, default: false },
    parties: { type: Array, default: null }
  },
  data () {
    return  {
      expanded: [0, 0, 1],
      rentalProcessWidget: {       
        id: 'leasing.unit_rental_processes',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: "Name", value: "rentingProcessTitle" }, // Vuokrauksen nimi
            { text: "leasing.renting_stage", value: "prospect_status" }, // Vuokrauksen vaihe
            { text: "Tenant corporation", value: "tenant" }, //Vuokralaiskonserni
          ],
          footers: [],
          items: [],
          noBrowse: true
        },
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('sites', ['currentSite']),
    ...mapState('rentalProcess', ['rentalProcesses']),
    contractsWithCapitalRentPerSquare () {
      const capitalRentCalculator = new CapitalRentCalculator()
      return this.contracts.map(contract => ({
        ...contract,
        capitalRentPerSquare: capitalRentCalculator.calculatePerSquare(contract)
      }))
    },
    getVacancyAssumptionDataForUnit () {
      return {
        vacancy_rate_assumption: this.unit.vacancy_rate_assumption,
        vacancy_rate_assumption_updated: this.unit.vacancy_rate_assumption_date ? this.unit.vacancy_rate_assumption_user + ", " + humanize.date(this.unit.vacancy_rate_assumption_date) : '',
      }
    },
    getVacancyAssumptionMetadataForUnit () {
      return {
        vacancy_rate_assumption: {
          ...this.showUnitProp('vacancy_rate_assumption'),
        },
        vacancy_rate_assumption_updated: {
          ...this.showUnitProp('vacancy_rate_assumption_user'),
          groupLast: true
        },
      }
    },
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    showUnitProp (prop) {
      const metadata = this.unitVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
      } else {
        return { isShown: false, category: 'None', order: 999 }
      }
    },
    getBasicDataForUnit () {
      return {
        unit_rental_status:
        this.unit.not_rentable !== null
          ? this.getUnitRentalStatus(this.unit.not_rentable) : this.$t('Undefined'),
        unit_area: this.getUnitAreaValue(this.unit),
        premise_type: this.unit.usage !== null ? 
          this.$t(this.unit.usage) : this.$t('Undefined'),
        unit_version_end_date: this.unit.end_date,
      }
    },
    getBasicMetadataForUnit () {
      return {
        unit_rental_status: {
          ...this.showUnitProp('not_rentable')
        },
        unit_area: {
          ...this.showUnitProp('area'),
          format: 'Area'
        },
        premise_type: {
            ...this.showUnitProp('usage')
        },
        unit_version_end_date: {
          ...this.showUnitProp('end_date'),
          isShown: this.unit.end_date != null ? true : false,
          format: 'Date'
        },
      }
    },
    getCommercialDataForUnit () {
      return {
        market_rent: this.unit.market_rent ? this.unit.market_rent : 0,
        unit_market_rent: this.unit.unit_market_rent ? this.unit.unit_market_rent : 0,
        upkeep_rent_budget_vat: this.currentSite.upkeep_rent_budget_vat,
        upkeep_rent_budget: this.currentSite.upkeep_rent_budget,
        upkeep_rent_actualization_vat: this.currentSite.upkeep_rent_actualization_vat,
        upkeep_rent_actualization: this.currentSite.upkeep_rent_actualization,
        estimated_ti_expense: this.unit.estimated_ti_expense ?? 0,
        clarified_ti_expense: this.unit.clarified_ti_expense ?? 0,
        list_rent_apartment: this.unit.list_rent_apartment,
        rent_target: this.unit.rent_target,
        lease_plan: this.unit.lease_plan,
        potential_yield: this.unit.potential_yield,
        // Next 2 values should always have user if they have date so we only check date
        lease_plan_updated: this.unit.lease_plan_date ? this.unit.lease_plan_user + ", " + humanize.date(this.unit.lease_plan_date) : '',
        commercial_info_updated: this.unit.commercial_info_date ? this.unit.commercial_info_user + ", " + humanize.date(this.unit.commercial_info_date) : ''
      }
    },
    getCommercialMetadataForUnit () {
      return {
        market_rent: {
          ...this.showUnitProp('market_rent'),
          format: 'MoneyPerSquare'
        },
        unit_market_rent: {
          ...this.showUnitProp('unit_market_rent'),
          format: 'Euro'
        },
        upkeep_rent_budget_vat: {
          ...this.showUnitProp('upkeep_rent_budget_vat'),
        },
        upkeep_rent_budget: {
          ...this.showUnitProp('upkeep_rent_budget'),
        },
        upkeep_rent_actualization_vat: {
          ...this.showUnitProp('upkeep_rent_actualization_vat'),
          format: 'MoneyPerSquare'
        },
        upkeep_rent_actualization: {
          ...this.showUnitProp('upkeep_rent_actualization'),
          groupLast: true
        },
        estimated_ti_expense: {
          ...this.showUnitProp('estimated_ti_expense'),
          format: 'MoneyPerSquare'
        },
        clarified_ti_expense: {
          ...this.showUnitProp('clarified_ti_expense'),
          groupLast: true
        },
        rent_target: {
          ...this.showUnitProp('rent_target'),
        },
        list_rent_apartment: {
          ...this.showUnitProp('list_rent_apartment'),
          groupLast: true,
        },
        lease_plan: {
          ...this.showUnitProp('lease_plan'),
          editType: 'ReadOnlyTextAreaw',
          noEdit: true
        },
        lease_plan_updated: {
          ...this.showUnitProp('lease_plan_user'),
          groupLast: true
        },
        potential_yield: {
          ...this.showUnitProp('potential_yield'),
          groupLast: true
        },
        commercial_info_updated: {
          ...this.showUnitProp('commercial_info_user'),
        }
      }
    },
    getContractMetadataForUnit () {
      return {
        contractUnits: {
          name: 'Units',
          format: 'None'
        },
        contract_party: {
          format: 'None',
          name: 'Contract party'
        },
        validity: {
          name: 'Type of contract validity',
          format: 'None',
          groupLast: true
        },
        actualArea: { 
          name: 'Rented Actual area',
          format: 'Area'
        },
        contract_area: {
          format: 'Area',
          name: 'Total contract area',
          groupLast: true
        },
        signature_date: {
          name: 'Date of signing the contract',
          format: 'Date'
        },
        curPartyStartDate: {
          name: 'Start date',
          format: 'Date'
        },
        contractFirstPossibleEndDate: {
          name: 'Contract first possible end date',
          format: 'Date'
        },
        curPartyEndDate: {
          name: 'End date',
          format: 'Date',
          groupLast: true
        },
        tenantNotice: {
          name: 'Agreement notice period (Tenant)',
          format: 'None'
        },
        landlordNotice: {
          name: 'Agreement notice period (Lanlord)',
          format: 'None'
        },
        huom: {
          name: 'Validity notice',
          format:'TruthValue'
        },
        vat: {
          name: 'VAT-responsibility',
          format: 'YesNoUnknown',
          groupLast: true
        },
        capitalRent: {
          name: 'Capital rent',
          format: 'EuroPerMonth',
        },
        capitalRentPerSquare: {
          name: 'Capital rent (€/m2/mth)',
          format: 'MoneyPerSquare'
        },
        maintenanceRent: {
          name: 'Maintenance rent',
          format: 'EuroPerMonth',
        },
        changeoverRent: {
          name: 'rental_status.changeover_rent',
          format: 'EuroPerMonth',
        },
        separateAllowances: {
          name: 'rental_status.separate_allowances',
          format: 'EuroPerMonth',
        },
        discounts: {
          name: 'rental_status.discounts',
          format: 'EuroPerMonth',
        },
        openBillsTotal: {
          name: 'rental_status.open_bills_total',
          format: 'Euro',
        },
        indexType: {
          name: 'rental_status.index_type',
        },
        minimumIncrease: {
          name: 'rental_status.minimum_increase',
          format: 'Percentage',
          groupLast: true,
        },
        contract_note: {
          name: 'Comments',
          format: 'None'
        }
      }
    },
    getUnitRentalStatus (defId) {
      if (defId === null) {
        return this.$t('rentable')
      } else {
        return this.$t(defId)
      }
    },
    getUnitAreaValue (unit) {
      // If we have Huone layer active...
      if (this.layer === '1') {
        // ...return the value of summed unit area value from connected spaces
        return unit.area_from_spaces
      }
      // Otherwise, return unit area as usual
      return unit.area
    },
    getUnitRentalProcesses () {
      const unitProcesses = this.rentalProcesses.filter(p => 
        p.sites.some(s => 
          s.units.some(u => u.id_unit === this.unit.id
          )
        )
      )
      return unitProcesses.map(process => ({
        id_process: process.id,
        rentingProcessTitle: getRentalProcessTitle(process),      
        prospect_status: process.status === 'Inactive' ? process.status : process.stage,
        tenant: process.corporation,
        id_corporation: process.id_corporation
      }))
    }
  }
}
</script>

<style scoped>
</style>