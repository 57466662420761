import { i18n } from "../../../plugins/i18n"

export function expiringGuarantees (contractStates) {  
    return {
    id: "contract.expiring_guarantees",
    type: "LazyTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contractNumber",
          event: "showCreateRentContractModal",
        },
        { text: "tenantName", value: "tenantName" },
        { text: "Landlord contact person", value: "landlordContactPerson" },
        { text: "Contract status", value: "contractStatus", format: "Array" },
        { text: "contract.contract end date", value: "contractEndDate", format: "Date" },
        { text: "GuaranteeType", value: "guaranteeType" },
        { text: "GuaranteeDescription", value: "guaranteeDescription" },
        { text: "GuaranteeSum", value: "guaranteeSum", format: "Euro" },
        {
          text: "GuaranteeDateOfExpiration",
          value: "dateOfExpiration",
          format: "Date",
        },
      ],
      customFormatters: {
        contractStatus: (row) => {
          return i18n.t(row.contractStatus)
        },
      },
      source: "contractexpiringguarantees",
      items: [],
      triggerGetAllData: 0,
      customPagination: {
        sortBy: ["GuaranteeDateOfExpiration"],
        sortDesc: [false],
      },
      customFilterContent: {
        contractStatus: contractStates.map((state) => ({
          text: i18n.t(state.name),
          value: state.name,
        })),
      },
    },
  }
}
