<template>
  <v-card v-if="carParks">
    <BaseModal
      @cancel="close"
    >
      <template #title>
        {{ $t('Edit car parks') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card
            v-if="carParks.length > 1"
            elevation="0"
          >
            <v-row
              justify="center"
              align="center"
            >
              <v-row
                justify="space-around"
                class="flex-column"
              >
                <h2 class="text-body font-weight-bold">
                  {{ $t('Place value for column to all rows') }}
                </h2>
              </v-row>
              <v-spacer />
              <v-row>
                <v-btn
                  outlined
                  :class="{ 'active': groupUpdate}"
                  @click="groupUpdate = !groupUpdate"
                >
                  {{ $t('Group update') }}
                </v-btn>
              </v-row>
            </v-row>
            <v-card
              v-show="groupUpdate"
              elevation="0"
            >
              <table
                class="v-datatable v-data-table v-table them--light table-overflow"
                style="margin-top: 1em; margin-bottom: 1em"
              >
                <thead>
                  <!--th class="column">{{$t('Group update')}}</th-->
                  <th
                    v-for="field in shwonFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <template v-for="field in shwonFields">
                      <CarParkModifyField
                        :key="field.value"
                        v-model="groupModifiedFields[field.value]"
                        :field="field"
                        :disabled="isDisabled(field.value)"
                        :item-selections="getItemSelections(field)"
                      />
                    </template>
                  </tr>
                  <tr>
                    <template v-for="field in shwonFields">
                      <td
                        :key="field.value"
                        style="border: 0 !important;"
                      >
                        <v-btn
                          v-if="!isDisabled(field.value)"
                          outlined
                          block
                          @click="setToAllFields(field)"
                        >
                          {{ $t('Set to all') }}
                        </v-btn>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-card>
          <v-card
            elevation="0"
          >
            <table class="v-datatable v-data-table v-table them--light table-overflow">
              <thead>
                <th
                  v-for="field in shwonFields"
                  :key="field.value"
                  class="column"
                  :style="field.style"
                >
                  {{ $t(field.header) }}
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="carPark in carParks"
                  :key="carPark.id_carspace"
                >
                  <template v-for="field in shwonFields">
                    <CarParkModifyField
                      :key="field.value"
                      v-model="modifiedCarParks[carPark.id_carspace][field.value]"
                      :field="field"
                      :placeholder="carPark[field.value]"
                      :item-selections="getItemSelections(field)"
                      :show-disabled="field.value === 'amount' && modifiedCarParks[carPark.id_carspace].rental_type === 'Single'"
                      :disabled="isDisabled(field.value, modifiedCarParks[carPark.id_carspace])"
                    />
                  </template>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="!valid || isSaving"
          rounded
          depressed
          color="primary"
          @click="validate"
        >
          {{ $t('Save') }}
          <v-progress-circular
            v-if="isSaving"
            size="16"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
</template>
<script>
import CarParkModifyField from './CarParkModifyField'
import { mapGetters } from 'vuex'
import BaseModal from '../../components/general/BaseModal.vue'
export default {
  name: 'CarParkModifyForm',
  components: {
    CarParkModifyField,
    BaseModal,
  },
  props: {
    carParks: { type: Array, default () { return [] } }
  },
  data () {
    return {
      valid: true,
      groupUpdate: false,
      modifiedCarParks: {},
      isSaving: false,
      groupModifiedFields: {}
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName']),
    fields () {
      return [
        {
          header: 'Identifier',
          value: 'nro',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Type',
          value: 'type',
          show: true,
          definitionGroupLabel: 'carpark.type',
          style: { 'min-width': '20em' }
        }, {
          header: 'Rental type',
          value: 'rental_type',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Renter',
          value: 'renter',
          show: true,
          definitionGroupLabel: 'carpark.renter',
          style: { 'min-width': '20em' }
        }, {
          header: 'Number of car parks',
          value: 'amount',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Max parking rights',
          value: 'max_parking_rights',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Plug in',
          value: 'plug_in',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Charge in', // === Sähköauton latauspaikka
          value: 'charge_in',
          show: true,
          definitionGroupLabel: 'carpark.charge',
          style: { 'min-width': '20em' }
        }, {
          header: 'Charging spots', // === Sähköautojen latauspaikkoja
          value: 'charging_spots_amount',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Handicap spot',
          value: 'invalid_spot',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Market rent €/car space',
          value: 'market_rent',
          show: this.hasApplicationPermissionByName('MARKKINAVUOKRAT'),
          style: { 'min-width': '20em' }
        }, {
          header: 'Extra info',
          value: 'extra_info',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Charging info',
          value: 'charging_info',
          show: true,
          style: { 'min-width': '20em' }
        }, {
          header: 'Charging power',
          value: 'charging_power',
          show: true,
          style: { 'min-width': '20em' }
        }
      ]
    },
    disabledFields () {
      return [
        {
          rentStyle: ['Single', 'AreaRent'],
          value: 'max_parking_rights'
        },
        {
          rentStyle: ['AreaRent', 'Privilege'],
          value: 'plug_in'
        },
        {
          rentStyle: ['AreaRent', 'Privilege'],
          value: 'invalid_spot'
        },
        {
          rentStyle: ['Single', 'Privilege'],
          value: 'charging_spots_amount'
        },
        {
          rentStyle: ['AreaRent'],
          value: 'charge_in'
        }
      ]
    },
    shwonFields () {
      return this.fields.filter(field => field.show && !this.isDisabled(field.value))
    }
  },
  watch: {
    carParks: {
      deep: true,
      immediate: true,
      handler (val) {
        this.modifiedCarParks = {}
        val.forEach(v => {
          this.$set(this.modifiedCarParks, v.id_carspace, {
            rental_type: v.rental_type
          })
        })
      }
    }
  },
  methods: {
    async validate () {
      this.isSaving = true
      await this.patch()
      this.isSaving = false
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    },
    isDisabled (value, carPark) {
      if (carPark) {
        if (this.disabledFields.find(f => f.value === value && f.rentStyle.find(rs => rs === carPark.rental_type))) {
          return true
        }
        return false

      }
      // if any of selected carparks has field disabled, field is disabled in group update
      if (this.disabledFields.find(f => f.value === value && f.rentStyle.find(rs => Object.values(this.modifiedCarParks).find(cp => cp.rental_type === rs)))) {
        return true
      } else if (value === 'amount' && Object.values(this.modifiedCarParks).find(park => park.rental_type === 'Single')) {
        return true
      }

      return false

    },
    getItemSelections (field) {
      var selections = []
      if (field.value === 'rental_type') {
        var rentalTypes = ['Single', 'AreaRent', 'Privilege']
        rentalTypes.forEach(value => {
          selections.push({
            label: this.$t(value),
            value: value
          })
        })
      } else if (field.definitionGroupLabel) {
        selections = this.definitionsByGroupLabel(field.definitionGroupLabel).map(def => {
          return {
            label: def.label,
            value: def.id
          }
        })
        selections.push({
          label: this.$t('Undefined'),
          value: null
        })
      }
      return selections
    },
    async patch () {
      for (const carPark in this.modifiedCarParks) {
        const carParkObject = this.carParks.find(cp => {
          return cp.id_carspace === parseInt(carPark)
        })
        const carParkCopy = JSON.parse(JSON.stringify(carParkObject))
        const carParkObserver = this.$jsonpatch.observe(carParkCopy)

        Object.keys(carParkCopy).forEach(key => {
          if (this.modifiedCarParks[carPark][key] !== undefined) {
            carParkCopy[key] = this.isDisabled(key, this.modifiedCarParks[carPark]) ? null : this.modifiedCarParks[carPark][key]
            if (key === 'rental_type' && this.modifiedCarParks[carPark].rental_type === 'Single') {
              carParkCopy.amount = 1
              carParkCopy.charging_spots_amount = null
            }
          }
        })
        const carParkPatch = this.$jsonpatch.generate(carParkObserver)

        if (carParkPatch.length > 0) {
          await this.$rambollfmapi.carparks.patch(carPark, this.currentDate, carParkPatch)
        }
      }
      this.$emit('update')
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedCarParks)) {
          this.modifiedCarParks[key][field.value] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.value])
          )
        }
      })
    }
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
</style>
