var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"billing"}},[_c('Alert',{attrs:{"show":_vm.showIndicator,"result":_vm.operationResult,"message":_vm.indicatorMessage,"y":_vm.yPosition}}),_c('v-dialog',{attrs:{"persistent":"","width":"90%"},model:{value:(_vm.showContractModal),callback:function ($$v) {_vm.showContractModal=$$v},expression:"showContractModal"}},[_c('v-card',[(_vm.showContractModal)?_c('CreateRentContractModal',{attrs:{"contract-id":_vm.editedContractId,"out-rent":_vm.outRent},on:{"handleSave":function($event){return _vm.onCreateRentContractModalClosed()}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"90%"},model:{value:(_vm.showCreateInvoicesModal),callback:function ($$v) {_vm.showCreateInvoicesModal=$$v},expression:"showCreateInvoicesModal"}},[_c('v-card',[(_vm.showCreateInvoicesModal)?_c('CreateInvoicesModal',{attrs:{"draft-invoices":_vm.draftInvoices,"new-invoice-errors":_vm.newInvoiceErrors},on:{"close":_vm.closeCreateInvoicesModal,"sent":function($event){_vm.showIndicator = true
          _vm.operationResult = 'sent'},"refreshData":_vm.refreshData}}):_vm._e()],1)],1),_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-dialog',{attrs:{"persistent":"","eager":"","width":"90%"},model:{value:(_vm.showRentReviewModal),callback:function ($$v) {_vm.showRentReviewModal=$$v},expression:"showRentReviewModal"}},[_c('v-card',[(_vm.showRentReviewModal)?_c('RentReviewModal',{on:{"save-success":_vm.rentReviewSaveSuccess,"close":function($event){(_vm.showRentReviewModal = false)}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","eager":"","width":"90%"},model:{value:(_vm.showNewHandInvoiceModal),callback:function ($$v) {_vm.showNewHandInvoiceModal=$$v},expression:"showNewHandInvoiceModal"}},[_c('v-card',[(_vm.showNewHandInvoiceModal)?_c('NewHandInvoiceModal',{attrs:{"invoice-material":_vm.invoiceMaterialForHandInvoice,"is-existing-invoice":_vm.existingHandInvoice},on:{"close":_vm.onCloseHandmadeModal,"created":function($event){_vm.showIndicator = true
            _vm.operationResult = 'created'}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","eager":"","width":"90%"},model:{value:(_vm.showNewRefundInvoiceModal),callback:function ($$v) {_vm.showNewRefundInvoiceModal=$$v},expression:"showNewRefundInvoiceModal"}},[_c('v-card',[_c('NewRefundInvoiceModal',{attrs:{"invoice-material":_vm.invoiceMaterialForCreditInvoice,"is-existing-invoice":_vm.existingCreditInvoice},on:{"close":_vm.onCloseRefundModal,"created":function($event){_vm.showIndicator = true
            _vm.operationResult = 'created'}}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('BaseView',{scopedSlots:_vm._u([{key:"selections",fn:function(){return [_c('h1',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('Billing'))+" ")]),_c('v-select',{attrs:{"label":_vm.$t('Billing month'),"items":_vm.billingMonths,"item-text":"text","item-value":"value"},model:{value:(_vm.billingMonth),callback:function ($$v) {_vm.billingMonth=$$v},expression:"billingMonth"}})]},proxy:true},{key:"buttons",fn:function(){return [(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS'))?_c('v-btn',{attrs:{"disabled":_vm.loading,"small":"","outlined":"","rounded":""},on:{"click":function($event){(_vm.showRentReviewModal = true)}}},[_vm._v(" "+_vm._s(_vm.$t('StartRentReview'))+" ")]):_vm._e(),(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS'))?_c('v-btn',{attrs:{"disabled":_vm.loading,"small":"","outlined":"","rounded":""},on:{"click":function($event){(_vm.showNewHandInvoiceModal = true)}}},[_vm._v(" "+_vm._s(_vm.$t('New hand invoice'))+" ")]):_vm._e(),(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS'))?_c('v-btn',{attrs:{"disabled":_vm.loading,"small":"","outlined":"","rounded":""},on:{"click":function($event){(_vm.showNewRefundInvoiceModal = true)}}},[_vm._v(" "+_vm._s(_vm.$t('New refund invoice'))+" ")]):_vm._e()]},proxy:true}])})],1),_vm._l((_vm.allowedWidgets),function(widget){return _c('div',{key:widget.id,staticClass:"widget px-4"},[_c('DynamicWidget',{attrs:{"id":widget.id,"type":widget.type,"data":widget.data,"title":_vm.$t(widget.id),"loading":widget.loading,"maximized":widget.maximized,"date":_vm.selectedYearAndMonth,"hide-default-buttons":widget.hideDefaultButtons},on:{"open":function($event){widget.maximized = $event},"triggerCustomEvents":_vm.triggerCustomEvents,"getAllFilteredData":function($event){widget.id == 'billing.unbilled_contracts'
        ? _vm.getFilteredUnbilledContracts($event)
        : undefined},"getSentInvoices":function($event){widget.id == 'billing.sent_invoices'
        ? _vm.getSentInvoices($event)
        : undefined},"getAllData":_vm.getAllData,"dataUpdate":_vm.updateArchiveData},scopedSlots:_vm._u([{key:"table-buttons",fn:function(props){return [(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS') && widget.id === 'billing.unbilled_contracts')?_c('v-btn',{attrs:{"loading":_vm.openCreateInvoicesModalLoading,"disabled":_vm.openCreateInvoicesModalLoading,"small":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.openCreateInvoicesModal(props.getAllFilteredData)}}},[_vm._v(" "+_vm._s(_vm.$t('CreateInvoices'))+" ")]):_vm._e()]}},(widget.id === 'billing.unbilled_handmade_invoices_and_credit_notes')?{key:"customComponent",fn:function({ item }){return [(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS'))?_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.deleteInvoice(item)}}},[_vm._v(" delete ")]):_vm._e(),(_vm.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS'))?_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.modifyInvoice(item)}}},[_vm._v(" edit ")]):_vm._e()]}}:(widget.id === 'billing.rent_review_bundles')?{key:"customComponent",fn:function({ item }){return [(item.layer == 0 && item.documentId != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"right":""},on:{"click":function($event){return _vm.downloadReviewPdf(item.documentId)}}},on),[_vm._v(" download ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('rentReview.download_letters')))])]):_vm._e()]}}:null],null,true)})],1)}),(_vm.loading)?_c('div',{staticStyle:{"text-align":"center","padding":"100px"}},[_c('div',{staticClass:"text-h5",staticStyle:{"margin-bottom":"2em"}},[_vm._v(" "+_vm._s(_vm.$t('Gathering information. Wait a second...'))+" ")]),_c('v-progress-circular',{attrs:{"size":"64","indeterminate":"","color":"primary"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }