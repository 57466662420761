export const contractsColors = {
  'Vapaana & tulevaisuudessa vuokrattu': '#89f27d',
  'Vuokrattu & sopimus alkanut': '#4cae4f',
  Vapaana: '#ff5252',
}

export const prospectLikelihoodColors = {
  'prospect.success_likelihood_high': '#bddaaa',
  'prospect.success_likelihood_intermediate': '#ffe682',
  'prospect.success_likelihood_low': '#f27d7d',
  'prospect.success_likelihood_inactive': 'hsl(0, 100%, 66%)',
  'prospect.success_likelihood_unknown': '#acabab',
}

export const prospectActivityColors = {
  'prospect.activity_high': '#bddaaa',
  'prospect.activity_intermediate': '#ffe682',
  'prospect.activity_low': '#f27d7d',
}

export const prospectStatusColors = {
  Closed: 'hsl(122, 39%, 49%)',
  Inactive: 'hsl(0, 100%, 66%)',
}

export const renewalStatusColors = {
  ProspectPending: '#707070',
  NotRelevant: '#acabab',
}

export const prospectTypeColors = {
  'leasing.new_negotiation': '#4caf50',
  'leasing.renegotiations': '#6fbcf1',
  'leasing.renewal': '#f389d6',
}

export const generalColors = {
  greyLine: '#777777',
  greyBar: '#D3D3D3', // Used in bar chart
}

export const ownedFreeUnitsProgressColors = {
  rented: '#4cae4f',
  rentedOrEndingInFuture: '#89f27d',
  free: '#f6b90f',
  renovations: '#ff6600',
  visibilityPrevented: '#ffe682'
}

export const contractValidityColors = {
  Permanent: '#f389d6',
  'Fixed term': '#6fbcf1'
}

export const activeProcessesColor = {
	"leasing.active_processes": generalColors.greyBar
}
export const stageColors = {
  "1. Lead": "#FFE682",
  "2. Prospect": "#E3F586",
  "3. NeedsAssessment": "#BEE385",
  "4. Proposal": "#97CF8A",
  "5. ContractNegotiation": "#4CAF50",
  "6. Won": "#237426",
  "7. RentAdministration": "#0D4D0F",
  "8. Closed": "#053306",
  "Inactive": "#EB0B0B",
}
export const rentalProcessStageColors = {
  ...stageColors,
  "Passed" : "#EB0B0B",
  "Inactive": "#EB0B0B",
}

export const circleBlue = "#6fbcf1"
