<template>
  <v-dialog
    :value="isShown"
    persistent
    width="1400"
  >
    <BaseModal @cancel="$emit('onClose')">
      <template #title>
        {{ $t(title) }}
      </template>
      <template #content>
        <div class="widget">
          <DynamicWidget
            v-if="isShown"
            type="DataTable"
            :loading="false"
            :data="data"
            :title="title"
          />
        </div>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-btn
          text
          rounded
          outlined
          @click="$emit('onClose')"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>

<script>
import BaseModal from './general/BaseModal'

export default {
  name: 'DetailsModal',
  components: {
    BaseModal,
    // async import because of circular reference between components
    DynamicWidget: () => import('./DynamicWidget.vue'),
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      
    }
  },
  computed: {
    title () {
      return this.data?.title ?? ''
    },
  },
}
</script>

