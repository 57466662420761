<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    plugins: {
      type: Array,
      default: function () { return [] }
    }
  },
  watch: {
    data: function () {
      this.update()
    },
    options: {
      deep: true,
      handler (newValue) {
        this.allOptions = {
          ...newValue
        }
        this.update()
      }
    }
  },
  mounted () {
    // Overwriting base render method with actual data.

    this.allOptions = {
      ...this.options
    }

    this.update()
  },
  methods: {
    update () {
      if (this.data !== null) {
        const dataLabels = this.data.labels.map(d => {
          return this.$t(d)
        })
        this.renderChart(
          {
            datasets: this.data.datasets,
            labels: dataLabels
          },
          this.allOptions
        )
      }
    }
  }
}
</script>
