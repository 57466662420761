<template>
  <td v-if="type === 'Number'">
    <v-text-field
      v-model="modifiedItem"
      type="number"
      clearable
      :placeholder="'0'"
      :disabled="disabled"
      @change="dataChanged(modifiedItem)"
    />
  </td>
  <td v-else-if="type === 'Boolean'">
    <v-select
      v-model="modifiedItem"
      :items="booleanItems"
      item-text="text"
      item-value="value"
      :disabled="disabled"
      @change="dataChanged(modifiedItem)"
    />
  </td>
  <td v-else-if="type === 'NullableNumber'">
    <v-text-field
      v-model="modifiedItem"
      type="number"
      clearable
      :disabled="disabled"
      @change="dataChanged(modifiedItem)"
    />
  </td>
  <td v-else>
    {{ value }}
  </td>
</template>

<script>
export default {
  name: 'ObjectTableModifyField',
  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    booleanProps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      booleanItems: [
        { text: this.$t('Boolean true'), value: 'true' }, 
        { text: this.$t('Boolean false'), value: 'false' }
      ],
      modifiedItem: null
    }
  },
  computed: {},
  watch: {
    value: function (v) {
      this.modifiedItem = v
    },
    modifiedItem: function (newValue) {
      // This watch is made for Number type of fields and data change is only made when value is differs from mount
      // --> No active save button after mounted
      if (this.type === 'Number' && newValue === null) {
        newValue = 0
      }
      if ((this.type === 'Number' || this.type === 'NullableNumber') && newValue !== this.value) {
        this.dataChanged(newValue)
      }
    }
  },
  mounted () {
    if (this.booleanProps.length > 0) {
      this.booleanItems = this.booleanProps
    }
    this.modifiedItem = this.value
  },
  methods: {
    dataChanged (value) {
      var returnValue = null
      if (this.type === 'Number') {
        returnValue = parseInt(value)
      } else if (this.type === 'Boolean') {
        returnValue = value === 'true' ? true : false
      } else if (this.type === 'NullableNumber') {
        returnValue = (value == null || isNaN(value)) ? null : parseInt(value)
      }
      else {
        returnValue = value
      }
      this.$emit('change', returnValue)
    }
  }
}
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-input--is-disabled {
  background: #f5f5f5 !important;
  border: #8b8b8b 1px solid !important;
}
</style>
