<template>
  <div>
    <v-icon
      :color="titleIconColor"
      class="pr-2"
    >
      {{ titleIcon }}
    </v-icon>
    <span>{{ titleBase }}</span>
    <span
      :class="showLink ? 'linked' : null"
      @click="showLink ? openCorporationModal() : null"
    >  
      {{ titleCorporate }}
    </span>
    <span>{{ titleExtension }}</span>
  </div>
</template>

<script>
    import { prospectTypeColors } from "../../constants/colors";
    import { getProspectType } from '../../helpers/leasing/prospect'
    import { i18n } from '../../plugins/i18n'
    import { mapState, mapActions, mapGetters} from 'vuex'

    export default {
        name: "RentalProcessTitle",
        props: {
            rentalProcess: {
                type: Object,
                default: null
            },
        },
        data () {
            return {
                titleBase: i18n.t("Renting"),
                titleCorporate: null,
                titleExtension: null,
                data: null,
                selectedCorporationId: 0
            }
        },
        computed: {
            ...mapState('rentalProcess', ['rentalProcessLoading']),
            showLink () {
                return this.rentalProcess && this.settings.hasMFilesIntegration && !this.rentalProcess.nameless_search;
            },
            titleIcon () {
                return getProspectType(this.rentalProcess)?.typeIcon 
            },
            titleIconColor () {
                return prospectTypeColors[(getProspectType(this.rentalProcess)?.typeText)]
            },
            ...mapGetters('app', ['settings']),
        },
        watch: { 
            rentalProcess: {
                handler: function (stage) {
                    if (stage) {
                        this.constructTitle()
                    }
                }
            },
        },
        async mounted () {
            await this.getCorporations()
            this.constructTitle()
        },
        methods: {
            ...mapActions('leasing', ['getCorporationExtraById']),

            openCorporationModal () {
                this.getCorporationExtraById({id: this.selectedCorporationId})
            },
            async getCorporations () {
                this.data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true, tenant_corporation: true } })
            },
            constructTitle () {
                const type = i18n.t(getProspectType(this.rentalProcess)?.typeText)
                const stage = i18n.t(this.rentalProcess?.stage)
                const areaString = this.rentalProcess?.area_preference ? `${this.rentalProcess?.area_preference.toString()} m\u00B2` : ''
                const corporation = this.rentalProcess?.corporation

                const selectedCorporation = this.data.filter(party => party.name === corporation)
                this.selectedCorporationId = selectedCorporation.map(i => i.id)

                if(areaString) {
                    this.titleExtension = ", " + areaString
                }
                this.titleCorporate = corporation ?? this.rentalProcess?.tag

                this.titleBase = type + ", " + stage + ": "
            }
        }
    }
</script>
<style scoped>
</style>