<template>
  <div>
    <p>
      {{ $t('leasing.target_info_text_1') }} {{ year }}, {{ $t('leasing.target_info_text_2') }}
    </p>
    <p>
      {{ $t('leasing.target_info_text_3') }}
    </p>
    <p>
      {{ $t('leasing.target_info_text_4') }}
    </p>
    <p>
      {{ $t('leasing.target_info_text_5') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TargetInfoText',
  props: {
    year: { type: Number, default: 0 },
  },
}
</script>

<style scoped>
p {
  font-style: italic;
  font-size: 16px;
}

.right-margin {
  margin-right: 10px;
}

.line_break {
  max-width: 75%;
}

.short {
  width: 65px;
}
</style>
