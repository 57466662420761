export default {
  partyClassifications () {
    return [
      {
        id: 0,
        name: 'Legal structure'
      },
      {
        id: 1,
        name: 'Responsibility'
      },
      {
        id: 2,
        name: 'Acquisition'
      },
      {
        id: 3,
        name: 'Tenant (Unit)'
      },
      {
        id: 4,
        name: 'Vacant (Unit)'
      },
      {
        id: 5,
        name: 'Non-rentable (Unit)'
      },
      {
        id: 6,
        name: 'Tenant (Room Portion)'
      },
      {
        id: 7,
        name: 'Vacant (Room Portion)'
      },
      {
        id: 8,
        name: 'Non-rentable (Room Portion)'
      }
    ]
  }
}
