<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('Change language') }}
    </template>
    <template #content>
      <v-select
        v-model="selected"
        :label="$t('App language')"
        :items="languages"
        item-text="text"
        item-value="value"
      />
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        color="primary"
        @click="save"
      >
        {{ $t('Save') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from './general/BaseModal'

export default {
  components: {
    BaseModal,
  },
  props: {
    languages: {
      type: Array,
      default: undefined,
    },
  },
  data () {
    return {
      selected: this.$i18n.locale,
    }
  },
  methods: {
    close () {
      this.$emit('closeLanguageForm', false)
    },
    save () {
      this.$emit('changeLanguage', this.selected)
      this.close()
    }
  }
}
</script>
<style scoped>
</style>
