<template>
  <BaseModal
    :is-loading="isLoading"
    @cancel="close()"
  >
    <template #title>
      {{ $t("prospect.history") }}
    </template>
    <template #content>
      <div
        v-for="event in events"
        :key="event.id"        
      > 
        <p
          v-if="event.eventType == 'contract.event.note_added'"
        >
          {{ $t(event.eventType) }}: "{{ (event.comment) }}" {{ getHumanreadableDate(event.eventDate) }} {{ $t(event.userName) }}
          <button
            v-if="userInfo.id === event.userId && addingNewComment === false && editingComment === false"
            @click="createOrUpdateEvent(true ,false), selectedComment = event.comment,selectedEvent = event.id, newComment = event.comment"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="d-sr-only">{{ $t('history.edit_note') }}</span>
          </button>
          <button
            v-if="userInfo.id === event.userId && addingNewComment === false && editingComment === false"
            @click="showNewCommentField = false, removeEvent(event.id)"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="d-sr-only">{{ $t('history.delete_note') }}</span>
          </button>
        </p>
        <p
          v-else-if="event.eventType == 'contract.event.has_signed_the_contract'"
        >
          {{ getPartyNames(event.signerId) }}  {{ $t(event.eventType) }} {{ getHumanreadableDate(event.eventDate) }} {{ $t(event.userName) }}
        </p>
        <p
          v-else
        >
          {{ $t(event.eventType) }} {{ getHumanreadableDate(event.eventDate) }} {{ $t(event.userName) }}
        </p>
      </div>
      <v-form class="new-comment pa-0 ma-0">
        <v-card
          :class="!showNewCommentField ? 'comment-no-border mt-12' : 'comment-card mx-1' "
          elevation="0"
        >
          <v-card-title class="ma-0 pa-1">
            <v-textarea
              v-if="showNewCommentField"
              ref="newCommentTextArea"
              v-model="newComment"
              :aria-label="$t('history.add_note')"
              rows="4"
              solo
              flat
              hide-details
              auto-grow
              class="ma-0 pa-1 comment-no-border"
              @keydown.enter.exact.prevent
            />
            <v-btn
              v-if="contractId != undefined && contractId != 0 && contractId != null && isUpdatingAllowed"
              :loading="addingComment"
              medium
              rounded
              class="add-comment-btn ma-0 comment-no-border"
              @submit="createOrUpdateEvent(false,false)"
              @click="createOrUpdateEvent(false,true)"
            >
              <div v-if="showNewCommentField && newComment.length > 0 && addingNewComment">
                {{ $t("history.add_note") }}
              </div>
              <div v-else-if="showNewCommentField && newComment.length > 0 && editingComment">
                {{ $t("history.save_note") }}
              </div>
              <span v-else-if="showNewCommentField && newComment.length === 0">
                <v-icon>close</v-icon>
                <span class="d-sr-only">{{ $t('history.close_note') }}</span>
              </span>
              <span v-else>
                <v-icon>add</v-icon>
                <span class="d-sr-only">{{ $t('history.add_note') }}</span>
              </span>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-form>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-btn
        text
        rounded
        outlined
        depressed
            
        @click="close()"
      >
        {{ $t('Close') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
      
      <script>
      import BaseModal from '../../../general/BaseModal'    
      import humanize from '../../../../helpers/humanize'
      
      export default {
        name: "AllowAutomaticBillingModal",
        components: {
            BaseModal,
        },
        props: {
          userInfo: {
            type: Object,
            default: () => {}
          },
          allParties: {
            type: Array,
            default: () => []
          },
          contractId: {
            type: Number,
            default: undefined,
          },
          isUpdatingAllowed: {
            type: Boolean,
            default: false,
          },
        },
        data () {
          return {
            historyEvents : {
              commentAdded: 9,
            },    
            showNewCommentField: false,
            newComment: "",
            events: [],
            isLoading: true,
            addingComment: false,
            editingComment: false,
            addingNewComment: false,
            selectedComment:"",
            selectedEvent: undefined,
            parties: undefined,
          }
        },
        async mounted () {
        this.events = await this.$rambollfmapi.contracts.contract.GetContractEvents(this.contractId);
        this.isLoading = false;
        },
        methods: {
          getPartyNames (signerId) {
            const name = this.allParties.find(p => p.id === signerId )?.name
          return name
          },
          async createOrUpdateEvent (edit,create) {
            if (!this.showNewCommentField) {
          this.showNewCommentField = true
          this.editingComment = edit;
          this.addingNewComment = create;
          this.$nextTick(() => this.$refs.newCommentTextArea.focus())
        }
        else if (this.newComment) {
          this.addingComment = true;
          if (this.newComment.length > 4000) {
            alert(this.$t("prospect.comment_too_long"))
            this.addingComment = false;
          }else {
            if (this.addingNewComment && !this.editingComment) {
            await this.addEvent()
            }
            else if (!this.addingNewComment && this.editingComment) {
            await this.updateEvent(this.newComment, this.selectedEvent)
            }
            this.addingComment = false;
            this.editingComment = false;
            this.addingNewComment = false;
            this.newComment = "";
            this.showNewCommentField = false;
            this.selectedEvent = undefined;
            await this.getEvents();
          }
        }
        else {
          this.showNewCommentField = false;
          this.editingComment = false;
          this.addingNewComment = false;
          this.selectedEvent = undefined;
        }
          },
          async removeEvent (eventId) {
            if(confirm(this.$t('prospect.delete_note_confirmation'))) {
            await this.$rambollfmapi.contracts.contract.DeleteContractEvent(eventId);
            this.getEvents();
            }
          },
          async updateEvent (comment, id) {
            await this.$rambollfmapi.contracts.contract.UpdateContractEvent({comment, id});  
          },
          async addEvent () {
            await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.commentAdded, [this.contractId],this.newComment)
          },
          async getEvents () {
            this.isLoading = true;
            this.events = await this.$rambollfmapi.contracts.contract.GetContractEvents(this.contractId);
            this.isLoading = false;
          },
          close () {
            this.$emit('close')
          },
          getHumanreadableDate (date) {
            return humanize.date(date, 'time')
        }
        }
      }
      </script>
      <style scoped>
.comment-no-border {
  border: none !important;
  word-break: normal !important;
}
.add-comment-btn {
  width: 100%;
  padding: 20px;
  background-color: var(--c-color-accent) !important;
  color: white !important;
}
.add-comment-btn:disabled {
  background-color: #eeeff5 !important;
}
.new-comment {
  margin-bottom: auto;
}
.comment-card {
  border: 2px solid var(--c-color-accent) !important;
  border-radius: 20px !important;
}
</style>