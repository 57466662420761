<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ tableData.title }}
            </h1>
          </v-card-title>
        </div>
        <Table
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="createFooters(generatedHeaders, items)"
          :footer-definitions="tableData.footers"
          :pagination.sync="viewDataOptions"
        />
      </v-card>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="generatedHeaders"
      :items="items"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="dataMissing"
      class="elevation-0"
      mobile-breakpoint="0"
    >
      <template
        slot="item"
        slot-scope="props"
      >
        <tr>
          <template v-for="header in props.headers">
            <td  
              v-if="(header.value === 'site_name' || header.value === 'site' || header.value === 'siteName' || (header.value === 'name' && header.text === $t('Site'))) && (props.item.id_site || props.item.siteIdentifier)"
              :key="header.value"
              :class="{ 'col-align-right': isRightAligned(header.format) }"
              class="linked"
              @click="linkTo(props.item, 'sites.info')"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value === 'unit_name' || header.value === 'unitName'"
              :key="header.value"
              :class="getLinkStyle(props.item)"
              @click="linkTo(props.item, 'sites.facilitymanagement')"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value === 'january' || 
                header.value === 'march' ||
                header.value === 'april' ||
                header.value === 'may' || 
                header.value === 'june' || 
                header.value === 'july' ||
                header.value === 'august' || 
                header.value === 'september' || 
                header.value === 'october' || 
                header.value === 'november' || 
                header.value === 'december'"
              :key="header.value"
              :style="{backgroundColor: colorDerived(props.item, header.value) }"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value !== 'general_rating' &&
                header.value !== 'report_date' &&
                header.value !== 'projectState' &&
                header.value !== 'damageState' &&
                header.value !== 'adjustmentState' &&
                header.value !== 'costEstimate'"
              :key="header.value"
              :class="{ 'col-align-right': isRightAligned(header.format) }"
            >
              {{ formatData(props.item, header.value, header.format) }}
            </td>
               
            <td
              v-else
              :key="header.value"
              class="col-align-left"
            >
              <v-row>
                <v-row>
                  <span
                    :key="header.value"
                  >
                    {{ formatData(props.item, header.value, header.format) }}
                  </span>
                </v-row>
                <v-row
                  class="col-align-right icon_padding flex-column"
                >
                  <StatusIcon
                    :field="
                      getObjectWithUnit(props.item[header.value], header.value)
                    "
                    :data="
                      formatData(props.item, header.value, header.format)
                    "
                  />
                </v-row>
              </v-row>
            </td>
          </template>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td
            v-for="(footer, idx) in footers"
            :key="idx"
            :class="{ 'col-align-right': isRightAligned(footer.format) }"
          >
            {{ 
              getFooterValue(
                items,
                footer.value,
                footer.text,
                footer.average,
                footer.format
              ) 
            }}
          </td>
        </tr>
      </template>
      <!-- Slot for filtering the contents. Filter options can be added in the widget object (see widgets/leasing f.ex.) -->
      <template
        v-if="data.filter"
        #footer
      >
        <div
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-menu
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-card
                elevation="0"
                class="filterBox"
              >
                <v-btn
                  v-bind="attrs"
                  outlined
                  x-small
                  fab
                  rounded
                  class="filterButton"
                  v-on="on"
                >
                  <v-icon>
                    filter_list
                  </v-icon>
                  <span
                    v-if="filter != null"
                    class="text-subtitle-2"
                  >
                    {{ $t(data.filter.options[filter].text) }}
                  </span>
                </v-btn>
              </v-card>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filter"
                active-class="active"
                return-object
              >
                <v-list-item
                  v-for="listItem in data.filter.options"
                  :key="listItem.value"
                >
                  <v-list-item-title>
                    {{ $t(listItem.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else
          style="margin: 0px 0px -45px 15px"
        >
          <v-menu
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                outlined
                x-small
                fab
                rounded
                v-on="on"
              >
                <v-icon>
                  filter_list
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filter"
                active-class="active"
                return-object
              >
                <v-list-item
                  v-for="listItem in data.filter.options"
                  :key="listItem.value"
                >
                  <v-list-item-title>
                    {{ $t(listItem.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <span
            v-if="filter != null"
            class="text-subtitle-1"
          >{{ $t(data.filter.options[filter].text) }}</span>
        </div>
      </template>
      <template 
        slot="footer.page-text"
        slot-scope="item"
      >
        <ImportButton
          v-if="importing"
          :headers="data.headers"
          @dataUpdate="$emit('dataUpdated', $event)"
        />&nbsp;
        <v-btn
          v-if="importing"
          small
          outlined
          rounded
          @click="exportAsCsv(data.headers, data.items)"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>&nbsp;
        <v-btn
          small
          outlined
          rounded
          @click="viewData(title, generatedHeaders, items)"
        >
          {{ $t('Browse') }}
        </v-btn>
        {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
        {{ item.itemsLength.toLocaleString('fi-FI') }}      
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helpers from '../../helpers'
import Table from '../Table'
import moment from 'moment'
import ImportButton from '../../components/ImportButton'
import StatusIcon from '../../components/StatusIcon'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    Table,
    ImportButton,
    StatusIcon
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    widgetId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      colorAreasRed: false,
      showTableDataDialog: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        page: 1,
        itemsPerPage: this.data.rowsPerPage ? this.data.rowsPerPage : 5,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
        itemsPerPage: this.data.browseRowsPerPage
          ? this.data.browseRowsPerPage
          : this.data.rowsPerPage
            ? this.data.rowsPerPage
            : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      importing: this.data.importing ? this.data.importing : false,
      filter: null,
      dataMissing: ''
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapState('dashboard', ['widgetFilters']),
    // generate headers from data with dynamically created columns
    generatedHeaders: function () {
      let dataCopy = {...this.data}
      let headers = [...this.data.headers]
      let allHeaders = []
      let uniqueHeaders = []
      let items = dataCopy.items
      if(this.data.filter != null && this.data.filter != undefined && this.filter != null && items.length > 0) { 
        const filteredColumns = Object.keys(items[0]).filter(column => column.includes(this.data.filter.options[this.filter].value) || this.data.filter.excludeHeaders.includes(column))
        const filteredItems = items.map(item => { 
          let rowData = {}
          filteredColumns.map(column => rowData[column] = item[column]) 
          return rowData
          })
        items = filteredItems
      }
      
      // get preset headers from dasboard or sites to set them first and remove from generated headers, remove also site_identifier
      // (add other unwanted values)
      let presets = dataCopy.headers.map(x => Object.values(x)[1])
      presets.push("siteIdentifier")
      presets.push("buildingIdentifier")
      
      // get all headers and remove duplicates
      for (let i = 0 ; i < items.length ; i++)
      {
        let oneHeaders = Object.keys(items[i])
        allHeaders = allHeaders.concat(oneHeaders)
        uniqueHeaders = [... new Set(allHeaders)]        
      }
      // remove presets which are already in data.headers
      uniqueHeaders = uniqueHeaders.filter( ( el ) => !presets.includes( el ) )
      // add dynamic headers and footers after presets, preset footers must align with preset headers
      for(let i = 0 ; i < uniqueHeaders.length ; i++)
      {
        const headerHasFormat = this.data.formats != null && this.data.formats != undefined ? this.data.formats.find(f => uniqueHeaders[i].includes(f.text)) : ''
        let newHeader = { text : uniqueHeaders[i], value: uniqueHeaders[i], format: headerHasFormat && headerHasFormat.format ? headerHasFormat.format : '' }
        if (newHeader.text !== 'id_site' && dataCopy.items.some(e => e.hasOwnProperty(newHeader.text))) 
        {
          headers.push(newHeader)
        }
      }
      // remove columns with no capacities 
      for(let i=0; i < dataCopy.headers.length ; i++)
      {
        if (!dataCopy.items.some(e => e.hasOwnProperty(dataCopy.headers[i].value)))
        {
          headers.splice(i,1)
        }
      }
      return headers
    },
    items: function () {
      return this.data.items.map(item => {
        let filteredItem = item
        if(this.data.filter != null && this.filter != null)
        {
          if(this.data.filter.options[this.filter].value)
          {
            filteredItem = this.filterColumnsBySelectedFilter(item, this.filter)
          }
        }
        
        const keys = Object.keys(filteredItem)
        const thisItem = JSON.parse(JSON.stringify(filteredItem))
        keys.forEach(key => {
          if (Array.isArray(item[key])) {
            // No need to duplicate things
            const list = [... new Set(item[key]) ]
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [
                ...list.slice(0, 4).map(v => this.getValueWithUnit(v, key))].join(', ') + '...'
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, key))
                .filter(v => v !== null && v !== '')
                .join(', ')
            }
            thisItem[key] = itemStr
          }
        })
        return thisItem
      })
    },
    footers: function () {
      let footers = [...this.data.footers]
      let items = [...this.data.items]
      if(this.data.filter != null && this.data.filter != undefined && this.filter != null && items.length > 0) {      
        const filteredColumns = Object.keys(items[0]).filter(column => column.includes(this.data.filter.options[this.filter].value))
        filteredColumns.map(column => {
          // Lets check does column have pre defined format
          const columnHasFormat = this.data.formats.find(f => column.includes(f.text))
          // append column to footers with possbile format and average values
          footers.push({ value: column, format: columnHasFormat && columnHasFormat.format ? columnHasFormat.format : '', average: columnHasFormat && columnHasFormat.average ? columnHasFormat.average : false })        
        })
      }
      return footers
    },
    siteId () {
      return Number(this.$route.params.id_site)
    }
  },
  watch: {
    filter: function (newFilter) {
      if(this.widgetId === 'dashboard.ltmp_tasks_year_overview_per_site_fimx') {
        this.setWidgetDataFilter({ widget: 'fimx', value: newFilter })
      }
    },
    widgetFilters: {
      handler: function () {
      if(this.widgetId === 'dashboard.ltmp_tasks_year_overview_per_site_fimx') {
        this.filter = this.widgetFilters['fimx']
      }
      },
      deep: true
    }
  },
  mounted () {
    this.colorAreasRed = this.hasNonDWGSAreas()
    if(this.data.filter && this.data.filter != undefined && this.data.filter.defaultFilter != null) {
      if(this.widgetId === 'dashboard.ltmp_tasks_year_overview_per_site_fimx') {
        this.filter = this.widgetFilters['fimx']
      } else {
        this.filter = this.data.filter.options.findIndex(option => option.value === this.data.filter.defaultFilter.value)
      }
    }

    const date = this.$store.state.app.currentDate
    const today = new Date()

    if (!moment(date, 'day').isSame(today, 'day')) {
      this.dataMissing = this.$t('No data available or not time related')
    } else {
      this.dataMissing = this.$t('No data available')
    }
  },
  methods: {
    ...mapMutations('dashboard', ['setWidgetDataFilter']),
    hasNonDWGSAreas () {
      const buildingsWithDwgs = this.buildingsForCurrentSite.filter(x => x.has_dwg)
      if (buildingsWithDwgs.length > 0) {
        const buildings = buildingsWithDwgs.map(b => {
          return b.building_name
        })
        const itemBuildings = this.data.items.map(i => {
          if (i.building_name) {
            return i.building_name
          }
          if (i.buildingName) {
            return i.buildingName
          }
        })
        if (itemBuildings !== undefined && itemBuildings.length > 0) {
          const set = [...new Set(itemBuildings)]
          // Logic here is that if the datatable contains buildings that are not among
          // the ones that have dwgs available and thus the length of the arrays differs,
          // we return true as it means widget contains area values that have no dwgs as a base
          if (set !== undefined && set.length === buildings.length) {
            return false
          } else if (set !== undefined && set.length > buildings.length){
            return true
          }
        }
      } else {
        return true
      }
    },
    async linkTo (destination, path) {
      let target = undefined
      if(destination.siteIdentifier !== undefined ){
          target = destination.siteIdentifier
      }
      else if (destination.id_site !== undefined){
        target = destination.id_site
      }
       else if (destination.siteId !== undefined){
        target = destination.siteId
      }
      if(target !== undefined){   
        if(path === 'sites.info')
        {
          this.$router.push({
          name: 'sites.info',
          params: { id_site: target}
          })
        }
        if(path === 'sites.facilitymanagement')
        { 
          let unitparam = undefined
          // dashboard link
          if(destination.unitIdentifier !== undefined ){ 
            unitparam = destination.unitIdentifier
          }
          // site link
          else if (destination.unitId !== undefined){
            unitparam = destination.unitId
          }
          let plan = await this.$rambollfmapi.sites.get(target)
          if(plan.has_dwg === true)
          {
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: target},
            query: { suite_id: unitparam}
          })}
          else
          {
            this.$router.push({
            name: 'sites.info',
            params: { id_site: target}
          })
          }
        
        }
      }
      
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    getValueWithUnit (value, header) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }
      if (header === 'general_rating') {
        return helpers.format.valueWithUnit(value, header)
      }
      if (this.siteId !== undefined && this.siteId !== null) {
        if (!isNaN(this.siteId)) {
            if (header.indexOf('area') >= 0 && this.colorAreasRed === true) {
              return helpers.format.markValueAsRed(value, header)
            } 
        }
      }
      return helpers.format.valueWithUnit(value, header)
    },
    // link exceptions
    getLinkStyle (value)
    {
      if(value.priority !== undefined)
        return null
      else
        return 'linked'
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    getFooterValue (data, headerName, text, average, format) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (headerName === 'general_rating') {
        var totalCount = 0
        var averageRating = 0
        var sitesWithAudit = 0
        for (let i = 0; i < data.length; i++) {
          var singleRate = data[i].general_rating
          if (singleRate !== null) {
            totalCount += singleRate
            sitesWithAudit += 1
          }
        }
        averageRating = totalCount / sitesWithAudit
        if (isNaN(averageRating)) {
          averageRating = 0
          }
        value = helpers.format.formatData(averageRating, format)
        return value
      }
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      value = helpers.format.formatData(value, format)
      
      return value
    },
    viewData (title, headers, data) {
      // this.$root.$emit('view-data', title, headers, data)
      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          return h
        }),
        rows: this.items,
        footers: this.footers
      }
      this.viewDataOptions = {
        sortDesc: this.options.sortDesc,
        sortBy: this.options.sortBy,
        itemsPerPage: -1
      }
      this.showTableDataDialog = true
    },
    createFooters (headers, rows) {
      return helpers.table.createFooters(headers, rows, this.data.browseFooters, this.data.footers)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    exportAsCsv (originalHeaders, originalData) {
      const headers = [...JSON.parse(JSON.stringify(originalHeaders))]
      const data = [...JSON.parse(JSON.stringify(originalData))]

      const csvHeaders = headers.map(h => this.$t(h.text))
      const csvData = data.map(d => {
        const row = []
        headers.forEach(h => {
          let val = d[h.value]
          val = val === undefined ? '' : val
          val = this.getValueWithUnitForCsvExport(val, h.value, false)
          row.push(val)
        })
        return row
      })
      helpers.csv.export(csvHeaders, csvData)
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      return value
    },
    getTruncatedStringFromArray (value, header, encode) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header, encode)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }

      return itemStr
    },
    formatData (row, header, format, isLink = false) {
      const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
      return helpers.format.formatData(value, format)
    },
    colorDerived (row, header)
    {
      if ((header === 'february' && row.derived_month <= 2)||
        (header === 'march' && row.derived_month <= 3)||
        (header === 'april' && row.derived_month <= 4)||
        (header === 'may' && row.derived_month <= 5)||
        (header === 'june' && row.derived_month <= 6)||
        (header === 'july' && row.derived_month <= 7)||
        (header === 'august' && row.derived_month <= 8)||
        (header === 'september' && row.derived_month <= 9)||
        (header === 'october' && row.derived_month <= 10)||
        (header === 'november' && row.derived_month <= 11)||
        (header === 'december' && row.derived_month <= 12)
      )
      {
        return '#fce4d6'
      }  
      else return '#ffffff'
    },
    filterColumnsBySelectedFilter (item, filter) {
      const value = this.data.filter.options[filter].value
      const filteredColumns = Object.keys(item).filter(column => column.includes(value) || this.data.filter.excludeItems.includes(column))
      let rowData = {}
      filteredColumns.map(column => rowData[column] = item[column])
      return rowData
    }
  }
}
</script>
<style scoped>
.icon_padding {
  padding-right: 0.5em;
}

@media screen and (max-width: 760px) {
  .filterButton {
    margin: 0px;
    border-radius: 2em;
    width: 100%;
    justify-content: start;
  }
  .filterBox {
    margin: 1em 1.5em;
    display: flex;
    justify-content: center;
    opacity: 0.87;
  }
}
</style>
