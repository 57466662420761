<template>
  <div>
    <v-dialog
      v-model="uploadDialogVisible"
      persistent
      max-width="550"
    >
      <FileUploadForm
        v-if="uploadDialogVisible"
        :document="document"
        :site="currentSite"
        :sites="[currentSite]"
        :mode="uploadMode"
        @cancel="uploadDialogVisible = false"
        @created="created()"
        @updated="
          update()
          uploadDialogVisible = false
        "
      />
    </v-dialog>
    <v-dialog
      v-model="historyDialogVisible"
      persistent
      max-width="800"
    >
      <v-card>
        <BaseModal
          @cancel="closeHistoryDialog"
        >
          <template #title>
            {{ $t('Changes') }}
          </template>
          <template #content>
            <div
              class="doc-table"
            >
              <v-expansion-panels
                :value="[0]"
                multiple
              >
                <v-expansion-panel            
                  class="no-padding"
                >
                  <v-expansion-panel-header>
                    <div
                      style="margin-bottom: 0.35rem !important"
                    >
                      <span 
                        class="header.class"
                        align-center
                        justify-center
                      >{{ $t('Change history') }}</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="isLoading"
                      style="text-align: center; padding: 2em;"
                    >
                      <v-progress-circular
                        size="64"
                        indeterminate
                        color="primary"
                      />
                    </div>
                    <v-data-table
                      v-else
                      :header-props="dataTableHeaderDefinitions"
                      :headers="versionHeaders"
                      :items="versions"
                      :options.sync="options"
                      :footer-props="{
                        itemsPerPageOptions: [],
                      }"
                      :no-data-text="$t('No data available')"
                      class="elevation-0 scrollable"
                      mobile-breakpoint="0"
                    >
                      <template
                        slot="item"
                        slot-scope="props"
                      >
                        <tr>
                          <td>{{ props.item.version }}</td>
                          <td>{{ props.item.creator }}</td>
                          <td>{{ props.item.versionDescription }}</td>
                          <td>{{ props.item.docFileType }}</td>
                          <td>{{ getDocumentSize(props.item.size) }}</td>
                          <td>{{ getHumanReadableDate(props.item.uploaded) }}</td>
                        </tr>
                      </template>
                      <template
                        slot="footer.page-text"
                        slot-scope="item"
                      >
                        <v-btn
                          v-if="item.itemsLength > 5"
                          small
                          outlined
                          rounded
                          @click="changeHistoryShowAll()"
                        >
                          {{
                            options.itemsPerPage === 5 ? $t('Show all') : $t('Show less')
                          }}
                        </v-btn>
                  
                        {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                        {{ item.itemsLength.toLocaleString('fi-FI') }}
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
          <template #hideCancel>
            <p />
          </template>
        </BaseModal>
      </v-card>
    </v-dialog>
    <v-row
      align="center"
      class="pa-4 flex-nowrap"
      no-gutters
    >
      <h1
        id="site-content"
        class="mr-2"
      >
        {{ $t('Documents') }}
      </h1>
      <v-select
        v-model="pagination.itemsPerPage"
        :items="pagination.itemsPerPageOptions"
        :label="$t('ItemsPerPage')"
        flat
        depressed
        class="mr-2"
        hide-details
        @input="getDocuments()"
      >
        <template
          slot="selection"
          slot-scope="{ item }"
        >
          {{ item }} {{ $t('items per page') }}
        </template>
      </v-select>
      <v-select 
        v-model="selectedDocumentClass"
        :items="documentClasses"
        :placeholder="$t('Document class')"
        :label="$t('Document class')"
        style="flex: 0 1 auto; width: 300px; "
        class="mr-2"
        return-object
        @change="getDocuments()"
      />
      <v-select
        v-model="selectedDocumentFileType"
        :items="documentFileTypes"
        :placeholder="$t('Type')"
        :label="$t('Type')"
        style="flex: 0 1 auto; width: 300px"
        class="mr-2"
        return-object
        @change="getDocuments()"
      />
      <v-text-field
        v-model="searchValue"
        :placeholder="$t('Search...')"
        color="primary"
        single-line
        hide-details
        append-icon="search"
        style="max-width: 300px"
        class="mr-2"
      />
      <v-btn-toggle
        v-model="showVersionlessDocuments"
        rounded
        @change="filterDocumentsBySearchValue"
      >
        <v-btn
          :value="true"
          icon
          large
          class="mr-2"
          color="primary"
        >
          <v-icon>file_copy</v-icon>
          <span class="d-sr-only">{{ $t('ShowVersionlessDocuments') }} {{ showVersionlessDocuments ? $t('On') : $t('Off') }}</span>
        </v-btn>
      </v-btn-toggle>
      <v-btn
        outlined
        large
        icon
        color="primary"
        class="mr-2"
        @click="update()"
      >
        <v-icon>refresh</v-icon>
        <span class="d-sr-only">{{ $t('Refresh') }}</span>
      </v-btn>
      <AddDocumentsMenu
        v-if="hasWritePermission"
        :has-write-permission="hasWritePermission"
        @open="openModal"
      />
    </v-row>
    <div
      v-if="isLoading"
      style="text-align: center; padding: 2em;"
    >
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
    <!-- List of uploads -->
    <div
      class="doc-table"
      style="margin: 1rem"
    >
      <v-expansion-panels
        v-show="!isLoading"
        :value="[!isLoading && filteredFiles.length ? 0 : null]"
        multiple
      >
        <v-expansion-panel
          v-show="!isLoading"
          class="no-padding"
        >
          <v-expansion-panel-header>
            {{ $t('Documents') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="isLoading"
              style="text-align: center; padding: 2em;"
            >
              <v-progress-circular
                size="64"
                indeterminate
                color="primary"
              />
            </div>
            <v-data-table
              v-else
              :header-props="dataTableHeaderDefinitions"
              :headers="headers"
              :items="filteredFiles"
              :options.sync="pagination"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              class="elevation-0 scrollable"
              mobile-breakpoint="0"
              hide-default-footer
            >
              <template
                slot="item"
                slot-scope="props"
              >
                <tr>
                  <td>
                    {{ props.item.title }}
                  </td>
                  <td>
                    {{ getLinkingInfo(props.item) }}
                  </td>
                  <td>
                    <span style="display: block">{{ props.item.description }}</span>
                    <span>
                      Ver.{{ props.item.version }} {{ props.item.creator }} -
                      {{
                        props.item.versionDescription === null
                          ? $t('No description')
                          : props.item.versionDescription
                      }}</span>
                  </td>
                  <td>
                    {{ $t(props.item.type) }}
                  </td>
                  <td>
                    {{ $t(props.item.docFileType) }}
                  </td>
                  <td>
                    {{ getHumanReadableDate(props.item.uploaded) }}
                  </td>
                  <td>
                    {{ props.item.created ? getHumanReadableDate(props.item.created) : '' }}
                  </td>
                  <td>
                    {{ props.item.expires ? getHumanReadableDate(props.item.expires) : '' }}
                  </td>
                  <td class="doc-tags">
                    <div
                      style="display: block; max-width: 100%"
                    >
                      <v-chip
                        v-if="props.item.filename !== null"
                        small
                        outlined
                        @click="extend(props.item.filename)"
                      >
                        {{ props.item.filename.split('.')[0] }}
                      </v-chip>
                      <v-chip
                        v-for="tag in props.item.tags"
                        :key="tag.id_tag"
                        small
                        outlined
                      >
                        {{ tag.name }}
                      </v-chip>
                    </div>
                  </td>
                  <td
                    style="width: 150px;"
                  >
                    <v-btn
                      v-if="props.item.filename!=null"
                      small
                      depressed
                      rounded
                      color="primary"
                      @click="download(props.item.idDocument)"
                    >
                      {{ $t('Open') }}
                    </v-btn>
                    <v-btn
                      v-if="hasWritePermission"
                      icon
                      @click="edit(props.item.idDocument)"
                    >
                      <v-icon>edit</v-icon>
                      <span class="d-sr-only">{{ $t('Modify') }}</span>
                    </v-btn>
                    <v-btn
                      v-if="hasWritePermission"
                      icon
                      @click="upload(props.item.idDocument)"
                    >
                      <v-icon>add</v-icon>
                      <span class="d-sr-only">{{ $t('Add') }}</span>
                    </v-btn>
                    <v-btn
                      icon
                      @click="openHistoryDialog(props.item.idDocument)"
                    >
                      <v-icon>history</v-icon>
                      <span class="d-sr-only">{{ $t('Change history') }}</span>
                    </v-btn>
                    <v-btn
                      v-if="hasWritePermission"
                      small
                      icon
                      @click="remove(props.item.idDocument)"
                    >
                      <v-icon>delete</v-icon>
                      <span class="d-sr-only">{{ $t('Delete') }}</span>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="footer">
                <v-btn
                  v-if="filteredFiles.length > 0 && !isLoading"
                  small
                  outlined
                  rounded
                  @click="exportToCsv()"
                >
                  <span v-if="!isExporting">{{ $t('Export to spreadsheet') }}</span>
                  <v-progress-circular
                    v-else
                    :size="20"
                    :width="3"
                    indeterminate
                    color="primary"
                  />
                </v-btn>&nbsp;
              </template>
              <template
                slot="footer.page-text"
                slot-scope="item"
              >
                {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                {{ item.itemsLength.toLocaleString('fi-FI') }}
              </template>
              <template #no-data>
                <v-icon>info</v-icon>
                {{ $t('No documents available') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col v-if="filteredFiles.length > 0 && !isLoading">
          <div class="text-right">
            <v-pagination
              v-model="pagination.currentPage"
              :length="totalPages"
              rounded
              depressed
              @input="getDocuments()"
            />
          </div>
        </v-col>
      </v-row>
      <div
        v-if="filteredFiles.length > 0 && !isLoading"
        class="document-count"
      >
        <v-col style="margin-top: 0.5em; margin-right: 0.5em">
          <div class="text-right">
            {{ $t('Total file count') + ' ' + filesCount + ' ' + $t('pcs')+ '.' }}
          </div>
        </v-col>
      </div>
    </div>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Alert from '../components/Alert'
import FileUploadForm from '../components/FileUploadForm'
import helpers from '../helpers'
import BaseModal from '../components/general/BaseModal.vue'
import AddDocumentsMenu from '../components/AddDocumentsMenu.vue'
export default {
  name: 'Documents',
  components: {
    Alert,
    FileUploadForm,
    BaseModal,
    AddDocumentsMenu,
  },
  metaInfo () {
    return {
      title: `${this.$t('Documents')} ${this.currentSite?.name ? ' · ' + this.currentSite?.name : ''} · ${this.$t('Sites')} ·`,
    }
  },
  data () {
    return {
      headers: [
        { text: this.$t('Name'), value: 'title'},
        { text: this.$t('Linking'), value: 'idBuilding'},
        { text: this.$t('Description'), value: 'version'},
        { text: this.$t('Document type'), value: 'type'},
        { text: this.$t('DocFileType'), value: 'docFileType'},
        { text: this.$t('Document.dateAdded'), value: 'uploaded'},
        { text: this.$t('Document.dateOfCreation'), value: 'created'},
        { text: this.$t('Document.expiryDate'), value: 'expires'},
        { text: this.$t('Tags'), value: 'tags'},
        { text: this.$t('Actions'), value: 'actions', width: '17%' }
      ],
      versionHeaders: [
        { text: this.$t('#'), value: 'version' }, 
        { text: this.$t('Author'), value: 'creator' },
        { text: this.$t('Description'), value: 'versionDescription' },
        { text: this.$t('DocFileType'), value: 'docFileType' },
        { text: this.$t('Size'), value: 'size'},
        { text: this.$t('Date'), value: 'uploaded' }
      ],
      uploadMode: 'new',
      isLoading: true,
      isExporting: false,
      currentSite: null,
      buildings: [],
      document: {},
      files: [],
      filteredFiles: [],
      searchValue: '',
      uploadDialogVisible: false,
      historyDialogVisible: false,
      versions: [],
      showVersionlessDocuments: true,
      pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        itemsPerPageOptions: [10, 20, 40, 100],
        sortBy: ['document_title'],
        sortDesc: [ false ]
      },
      filesCount: 0,
      searchLastPress: null,
      searchInProgress: false,
      documentFileTypes: [],
      documentClasses: [],
      selectedDocumentFileType: null,
      selectedDocumentClass: null,
      userHasEditRights: false,
      paginated: true,
      selectedDocument: null,
      options: {
        itemsPerPage: 5,
        sortBy: ['version'],
        sortDesc: [ true ]
      },
      currentCemeteryId: 0,

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
    }
  },
  computed: {
    totalPages () {
      if (this.filesCount > this.pagination.itemsPerPage) {
        return Math.floor(this.filesCount / this.pagination.itemsPerPage) + 1
      }

      return 1
    },
    ...mapGetters('app', ['hasApplicationPermissionByName', 'dataTableHeaderDefinitions']),
    ...mapGetters('sites', ['cemeteriesForCurrentSite', 'linkedSectionsAndGraves', 'buildingsForCurrentSite', 'buildingHierarchyInformations']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    hasWritePermission () {
      if (!this.userHasEditRights) {
        return false
      }
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    }
  },
  watch: {
    searchValue (val) {
      // user is typing
      this.searchLastPress = new Date().getTime()

      if (val.length > 3 || this.files.length > 0 || val.length === 0) {
        const self = this
        setTimeout(async function () {
          const currentTime = new Date().getTime()
          if (self.searchLastPress === null) {
            this.$log.debug('set time out: return')
            return
          }
          const time = currentTime - self.searchLastPress

          if (time > 450) {
            self.searchInProgress = true
            self.pagination.currentPage = 1
            await self.getDocuments()
            self.searchInProgress = false
          } else {
            self.$log.debug('else time: ' + time)
          }
        }, 700)
      } else {
        this.searchLastPress = null
        this.searchInProgress = false
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
      }
    }
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA')) {
      this.$router.push({ name: 'sites.info'})
    } else {
    let basicFileType = { text: this.$t('Any file type'), value: null }
    this.documentFileTypes.push(basicFileType)

    let basicClass = {text: this.$t('Any document class'), value: null }
    this.documentClasses.push(basicClass)

    let { filetypes, classes } = await this.$rambollfmapi.sites.documentFilters(this.currentSiteId);

    if (filetypes) {
      for (let i = 0; i < filetypes.length; i++) {
      let fileType = filetypes[i]
      if (fileType) this.documentFileTypes.push({ text: fileType, value: '.' + fileType })
      }
    this.selectedDocumentFileType = this.documentFileTypes[0]
    }

    if (classes) {
      for (let i = 0; i < classes.length; i++) {
      let classType = classes[i]
      if (classType) this.documentClasses.push({ text: this.$t(classType), value: classType })
      }
    this.selectedDocumentClass = this.documentClasses[0]
    }

    this.currentSite = await this.$rambollfmapi.sites.get(this.currentSiteId)
    this.update()
    this.userHasEditRights = true
    }
  },
  methods: {
    ...mapActions('sites', ['loadLinkedSectionsAndGraves', 'loadBuildingsHierarchyInformations']),
    async getDocuments () {
      this.isLoading = true
      const docs = await this.$rambollfmapi.sites
        .documents(this.currentSiteId)
        .list(
          this.pagination.itemsPerPage,
          (this.pagination.currentPage - 1) * this.pagination.itemsPerPage,
          this.searchValue,
          this.selectedDocumentClass.value,
          this.pagination.sortBy[0]
            ? (this.pagination.sortDesc[0] ? '-' : '') + this.pagination.sortBy[0]
            : undefined,
          this.selectedDocumentFileType.value
        )
      this.files = docs.items
      this.filesCount = docs.count

      if (
        this.filesCount <
        (this.pagination.currentPage - 1) * this.pagination.itemsPerPage
      ) {
        this.pagination.currentPage = 1
      }
      this.filterDocumentsBySearchValue()
      this.filteredFiles = this.files

      this.isLoading = false
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    filterDocumentsBySearchValue () {
      this.filteredFiles = []

      let sourceSet = this.files

      if (this.showVersionlessDocuments === undefined) {
        sourceSet = sourceSet.filter(file => file.version !== 0)
      }

      this.filteredFiles = sourceSet
    },
    getLinkingInfo (rowData) {
      if (this.buildings.length) {
        const building = this.buildings.find(b => b.id_building === rowData.idBuilding)
        const floorName = rowData.floorName
        const unitName = rowData.unitName
        const spaceName = rowData.spaceName

        if (typeof building !== 'undefined' && building !== null) {
          return this.$t('Building') + ': ' + building.building_name + (floorName ? ', ' + this.$t('Floor') + ': ' + floorName : '') + (unitName ? ', ' +this.$t('Unit') + ': ' + unitName : '') + (spaceName ? ', ' + this.$t('Space') + ': ' + spaceName : '')
        }
      }
      if (this.cemeteriesForCurrentSite.length && rowData.idCemetery) {
        // If multiple cemeteries exist, and currently loaded cemetery is different, do a new API call
        // I don't know if this situation ever happens
        if (this.currentCemeteryId !== rowData.idCemetery) {
          this.currentCemeteryId = rowData.idCemetery
          this.loadLinkedSectionsAndGraves(rowData.idCemetery)
        }
        const cemetery = this.cemeteriesForCurrentSite.find(c => c.id === rowData.idCemetery)

        if (typeof cemetery !== 'undefined' && cemetery !== null) {
          const cemetaryName = cemetery.name
          let sectionName = this.linkedSectionsAndGraves.find(({ idDocument }) => idDocument === rowData.idDocument).sectionIdentifier ?? ""
          let graveName = this.linkedSectionsAndGraves.find(({ idDocument }) => idDocument === rowData.idDocument).graveIdentifier ?? ""

          return this.$t('Cemetery') + ": " + cemetaryName + (sectionName === "" ? "" : (", " + this.$t('Graveyard section') + ": " + sectionName)) + (graveName === "" ? "" : (", " + this.$t('Grave') + ": " + graveName))
        }
      }
      
      return rowData.buildingId
    },
    async exportToCsv () {
      this.isExporting = true
      const result = await this.$rambollfmapi.sites
        .documents(this.currentSiteId)
        .list(
          -1,
          0,
          this.searchValue,
          this.selectedDocumentClass.value,
          this.pagination.sortBy[0]
            ? (this.pagination.sortDesc[0] ? '-' : '') + this.pagination.sortBy[0]
            : undefined,
          this.selectedDocumentFileType.value
        )
        const csvHeaders = this.headers.map(h => this.$t(h.text))
        const csvItems = result.items.map(item => this.formatDataRow(item))
        helpers.csv.export(csvHeaders, csvItems)
        this.isExporting = false
    },
    formatDataRow (item) {
      const formatted = [
        item.title,
        this.getLinkingInfo(item),
        item.description,
        this.$t(item.type),
        item.docFileType,
        this.getHumanReadableDate(item.uploaded),
      ]

      const tags = item.tags.map(x => x.name)
      
      if(item.filename !== null) {
        tags.unshift(item.filename.split('.')[0])
      }

      formatted.push(tags.join(', '))
      formatted.push('')

      return formatted
    },
    created () {
      this.showIndicator = true
      this.operationResult = 'success'
      this.update('-document_uploaded')
    },
    async update (sortby = undefined) {
      const search = undefined
      const type = undefined
      const filename = undefined
      this.filteredFiles = []
      this.files = []
      this.isLoading = true
      
      this.$rambollfmapi.buildings
        .list({ query: { siteId: this.currentSiteId } })
        .then(buildings => {
          this.buildings = buildings
        })
      this.currentSite = await this.$rambollfmapi.sites.get(this.currentSiteId)
      const docs = await this.$rambollfmapi.sites
        .documents(this.currentSiteId)
        .list(this.pagination.itemsPerPage, 0, search, type, sortby, filename)
      this.files = docs.items
      this.filesCount = docs.count
      await this.$store.dispatch('app/getTags')
      if(this.buildingsForCurrentSite.length) {
        await this.loadBuildingsHierarchyInformations(this.buildingsForCurrentSite.map(b => b.id_building))
      }
      if (this.cemeteriesForCurrentSite.length) {
        this.currentCemeteryId = this.cemeteriesForCurrentSite[0].id
        await this.loadLinkedSectionsAndGraves(this.cemeteriesForCurrentSite[0].id) 
      }
      this.filterDocumentsBySearchValue()
      this.isLoading = false
      this.pagination.currentPage = 1
    },
    download (id, version) {
      if (typeof version === 'undefined') {
        version = 0
      }

      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          this.isRetrievingFile = false
          this.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const file = this.files.find(file => {
            return file.idDocument === id
          })
          helpers.saveAs(blob, file.filename)
        })
        .catch(() => {
          this.isRetrievingFile = false
          // this.showFileInfo('Dokumenttia ei pystytä lataamaan', 'error')
        })
    },
    newDocument (param) {
      this.document = {
        idBuilding: null,
        fileName: '',
        title: '',
        description: '',
        type: '',
        versionDescription: '',
        tags: [],
        created: null,
        expires: null,
        idCemetery: null,
        idSection: null,
        idGrave: null,
        idFloor: null,
        idUnit: null,
        idSpace: null,
        idSite: this.currentSiteId
      }
      this.uploadMode = param === 'newDocument' ? 'new' : 'multiple'
      this.uploadDialogVisible = true
    },
    edit (id) {
      this.document = this.files.find(f => f.idDocument === id)
      this.uploadMode = 'update'
      this.uploadDialogVisible = true
    },
    remove (id) {
      if (confirm(this.$t('Are you sure you wish to delete this document?'))) {
        this.$rambollfmapi.documents.delete(id).then(() => this.update())
      }
    },
    upload (id) {
      this.document = this.files.find(f => f.idDocument === id)
      this.uploadMode = 'version'
      this.uploadDialogVisible = true
    },
    openHistoryDialog (id) {
      this.historyDialogVisible = true
      this.selectedDocument = this.files.find(f => f.idDocument === id)
      this.history (id)
    },
    closeHistoryDialog () {
      this.historyDialogVisible = false
      this.versions = []
      this.selectedDocument = null
      this.options.itemsPerPage = 5
    },
    async history (id) {
      this.versions = []
      this.versions = await this.$rambollfmapi.documents.versions(id).list()
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date)
    },
    extend (id) {
      let element = document.getElementById(id)
      if (element.style.maxWidth === '100%') {
        element.style.maxWidth = '200%'
      } else {
        element.style.maxWidth = '100%'
      }
    },
    getDocumentSize (size) {
      const sizekB = size / 1024
      return sizekB > 1024? `${Math.round(sizekB/ 1024)} ${this.$t("photos.Abbr_megabyte")}` : `${Math.round(sizekB)} ${this.$t("photos.Abbr_kilobyte")}`
    },
    changeHistoryShowAll () {
      if(this.options.itemsPerPage === 5) {
        this.options.itemsPerPage = -1
      } else {
        this.options.itemsPerPage = 5
      }
    },
    openModal (param) {
        this.newDocument(param)
    }
  }
}
</script>
<style>
.toolbar-text {
  color: var(--c-color-accent);
  font-weight: bold;
}
.document-count {
  font-weight: bold;
}
.doc-headers {
  flex-direction: row;
  display: flex;
}
.doc-headers span {
  padding: 0.5em;
  background: #eef8ff;
  margin: 1px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(0, 0, 90, 0.7);
}
.doc-headers span:first-child {
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  background: #efefef;
  padding-left: 2em;
  border-top-left-radius: 2em;
}
.doc-headers span:last-child {
  border-top-right-radius: 2em;
}
.no-documents {
  background-color: #eef8ff !important;
  color: #111 !important;
  padding: 1em;
  vertical-align: baseline;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.doc-row {
  flex-direction: row;
  display: flex;
  width: 100%;
  border: 2px solid #eef8ff;
}
.doc-row:last-child {
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.doc-tags {
  color: var(--c-color-accent) !important;
}
.doc-tags .v-chip--outline {
  max-width: inherit;
}
.doc-tags .v-chip.v-chip.v-chip--outline {
  border: 1px solid var(--c-color-accent) !important;
}
.doc-tags .v-chip__content {
  color: var(--c-color-accent) !important;
  overflow: hidden;
}
.doc-tags .v-btn.v-btn--flat {
  background-color: var(--c-color-accent) !important;
  color: white !important;
}
.doc-row > span:first-child {
  padding-left: 2em;
}
.doc-row > span {
  vertical-align: middle;
  margin: 1px;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: rgba(0, 0, 90, 0.7);
}
.doc-column {
  width: 100px;
  flex-grow: 2;
}
.doc-column-narrow {
  width: 50px;
  flex-grow: 1;
}
.doc-column-wide {
  width: 200px;
  flex-grow: 3;
}
.doc-headers .doc-column,
.doc-column-narrow,
.doc-column-wide {
  cursor: pointer;
}
</style>
