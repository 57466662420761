<template>
  <div id="contactlayers-dashboard">
    <v-dialog
      v-if="showCreateRentContractModal"
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          :contract-id="editedContractId"
          :out-rent="outRent"
          @contractIdUpdated="onContractIdUpdated"
          @handleSave="onCreateRentContractModalClosed"
        />
      </v-card>
    </v-dialog>
    
    <v-row style="padding: 1em">
      <v-col
        cols="12"
        md="6"
        class="widget-container"
      >
        <v-row
          v-for="widget in allowedWidgets.left"
          :key="widget.id"
          class="widget cols"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :maximized="widget.maximized"
            :date="currentDate"
            @open="widget.maximized = $event"
            @triggerCustomEvents="triggerCustomEvents"
            @showFilterDialog="showColumnsFilterDialog"
            @changePproperty="changeProperty"
          />
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="widget-container"
      >
        <v-row
          v-for="widget in allowedWidgets.right"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :maximized="widget.maximized"
            :date="currentDate"
            @open="widget.maximized = $event"
            @triggerCustomEvents="triggerCustomEvents"
            @showFilterDialog="showColumnsFilterDialog"
            @changePproperty="changeProperty"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import DynamicWidget from "../components/DynamicWidget.vue"
import CreateRentContractModal from "../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue"
import { guarantees as createGuarantees } from "../widgets/contracts/dashboard/guarantees"
import { guaranteesAwaitingDelivery as createGuaranteesAwaitingDelivery } from "../widgets/contracts/dashboard/guaranteesAwaitingDelivery"
import { waitingForSigning as createWaitingForSigning } from "../widgets/contracts/dashboard/waitingForSigning"
import { endingFixedTerm as createEndingFixedTerm } from "../widgets/contracts/dashboard/endingFixedTerm"
import { endingPermanent as createEndingPermanent } from "../widgets/contracts/dashboard/endingPermanent"
import { refundableGuarantees as createRefundableGuarantees } from "../widgets/contracts/dashboard/guaranteesRefundable"
import { expiringGuarantees as createExpiringGuarantees } from "../widgets/contracts/dashboard/expiringGuarantees"

export default {
  name: "ContractlayersDashboard",
  components: {
    DynamicWidget,
    CreateRentContractModal
  },
  props: {
    contractStates: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      showCreateRentContractModal: false,
      editedContractId: undefined,
      showModifyBuildingPermissionValidityModal: false,
      editedBuilding: undefined,
      showModifyUnitPermissionValidityModal: false,
      editedUnit: undefined,
      outRent: undefined,
      // We have to persist endingContracts12mo widget because of default filter
      // ObjectTable has watcher for the default filter and if we create
      // the widget each time data updates, the selected filter will reset
      // which is bad user experience.
      guaranteesWidget: null,
      guaranteesAwaitingDeliveryWidget: null,
      expiringGuaranteesWidget: null,
      waitingForSigningWidget: null,
      endingFixedTermWidget: null,
      endingPermanentWidget: null,
      waitingForSigningContracts: [],
      waitingForSigningContractsLoading: false,
      refundableGuaranteesWidget: null,
    }
  },
  computed: {
    ...mapState("app", ["currentDate", "userWidgets"]),
    ...mapState("contracts", ["endingContracts", "endingContractsLoading"]),
    ...mapGetters('app', ['definitionsByGroupLabel']),
    allowedWidgets () {
      // This way the widgets are updated automatically to the template.
      // Also if we create computed function for each widget, the widget is updated automatically
      // and other widgets on this page do not affect the peroformance because those are cached
      // automatically by the computed value.
      const allWidgets = [
        this.endingFixedTerm,
        this.endingPermanent,
        this.waitingForSigning,
        this.guaranteesWidget,
        this.guaranteesAwaitingDeliveryWidget,
        this.refundableGuaranteesWidget,
        this.expiringGuaranteesWidget,
      ].filter(widget => widget !== null)

      // We have to split the widgets into two columns. Let's lay the widgets from left to right into two pairs.
      return allWidgets.reduce(
        (widgets, currentWidget, idx) => {
          if (idx % 2 == 0) {
            widgets.left.push(currentWidget)
          } else {
            widgets.right.push(currentWidget)
          }

          return widgets
        },
        { left: [], right: [] }
      )
    },
    // By creating computed value for each widget, we don't have to add watchers for loaders or data changes.
    // The computed will consider all dependencies automagically. 
    endingPermanent () {
      const widget = this.endingPermanentWidget

      if(!widget){
        return null
      }

      widget.data.items = widget.dataFetcher({ endingContracts: this.endingContracts })
      widget.loading = widget.isLoading({ endingContractsLoading: this.endingContractsLoading })
      return widget
    },
    endingFixedTerm (){

      const widget = this.endingFixedTermWidget

      if(!widget){
        return null
      }
      
      widget.data.items = widget.dataFetcher({ endingContracts: this.endingContracts })
      widget.loading = widget.isLoading({ endingContractsLoading: this.endingContractsLoading })
      return widget
    },
    waitingForSigning () {

      const widget = this.waitingForSigningWidget

      if(!widget){
        return null
      }

      widget.data.items = widget.dataFetcher({ waitingForSigningContracts: this.waitingForSigningContracts })
      widget.loading = widget.isLoading({ waitingForSigningContractsLoading: this.waitingForSigningContractsLoading })
      return widget
    }, 
    
  },
  async mounted () {  
    this.createWidgets()    
  },
  methods: {
    ...mapActions("contracts", ["fetchEndingContracts", "patchContract", "patchGuarantee"]),
    triggerCustomEvents (event) {
      if (event.eventName === "showCreateRentContractModal") {
        this.openContractModal(event.row)
      } else if (event.eventName === "goToSite") {
        // site link can only work when there's only one site
        if (event.row.siteIds?.split(",")?.length === 1) {
          this.$router.push(`/sites/${event.row.siteIds?.trim()}`)
        }
      }
    },
    createWidgets (){
      // Create widgets in mounted so we can have dynamic selections for filter and action buttons. Some ugly ifs here because each widget don't need all the same data.
      // There could probably be some better approach to this... Something like in the main dashboard except with functions. Main dashboard uses reports endpoint
      // which ties us to certain datasource. So probably some kind of dictionary with keys and fetchers. Have to see where this dashboard evolves. 
      const widgetIds = this.userWidgets.reduce((prev, current) => {
          prev[current.name] = current.id
          return prev
        }, {})


      const guaranteesWidget = createGuarantees(this.contractStates) 

      if(widgetIds[guaranteesWidget.id]){
        this.guaranteesWidget = guaranteesWidget
      }

      const guaranteesAwaitingDeliveryWidget = createGuaranteesAwaitingDelivery(this.contractStates)

      if (widgetIds[guaranteesAwaitingDeliveryWidget.id]){
        this.guaranteesAwaitingDeliveryWidget = guaranteesAwaitingDeliveryWidget
      }

      const refundOptions = this.definitionsByGroupLabel("contract.guarantee_refund_action")
      const refundableGuaranteesWidget = createRefundableGuarantees(refundOptions, this.contractStates)

      if (widgetIds[refundableGuaranteesWidget.id]){
        this.refundableGuaranteesWidget = refundableGuaranteesWidget
      }

      const expiringGuaranteesWidget = createExpiringGuarantees(this.contractStates)

      if (widgetIds[expiringGuaranteesWidget.id]){
        this.expiringGuaranteesWidget = expiringGuaranteesWidget
      }
      // Some widgets require same dataset. We want to only make one API call for those. 
      const requiredData = new Set()

      const followupOptions = this.definitionsByGroupLabel("contract.followup_action")
      const endingFixedTermWidget = createEndingFixedTerm(followupOptions)

      if(widgetIds[endingFixedTermWidget.id]){
        this.endingFixedTermWidget = endingFixedTermWidget
        requiredData.add("fetchEndingContracts")
      }

      const endingPermanentWidget = createEndingPermanent(followupOptions)

      if(widgetIds[endingPermanentWidget.id]){
        this.endingPermanentWidget = endingPermanentWidget
        requiredData.add("fetchEndingContracts")
      }

      const waitingForSigningWidget = createWaitingForSigning()

      if(widgetIds[waitingForSigningWidget.id]){
        this.waitingForSigningWidget = waitingForSigningWidget
        requiredData.add("fetchWaitingForSigningContracts")
      }


      const fetchers = this.fetchers()

      requiredData.forEach( fn => {
        const fetcher = fetchers[fn]
        if(fetcher){
          fetcher()
        }
      })
    },
    fetchers () {
      return {
        fetchEndingContracts: () => this.fetchEndingContracts({ months: 12 }),
        fetchWaitingForSigningContracts: () => this.fetchWaitingForSigningContracts(),
      }

    },
    openContractModal (row) {
      this.outRent = row.isOutRent
      this.editedContractId = row.contractId
      this.showCreateRentContractModal = true
    },
    onCreateRentContractModalClosed () {
      this.editedContractId = undefined
      this.showCreateRentContractModal = false
    },
    onContractIdUpdated (contractId) {
      this.editedContractId = contractId
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    changeProperty (event){

      const { property, item } = event

      if(property === "followupAction") {
        this.onUpdateFollowupAction(item.id, item.followupAction)
      }
      
      if(property === 'refundAction') {
        this.onUpdateRefundAction(item.guaranteeId, item.refundAction)
      }
    },
    /**
     * Updates vuex state and sends patch request to update followupAction.
     * @param {Number} id - Id of the contract
     * @param {String} value - Followup action definition label or null. 
     */
    onUpdateFollowupAction (id, value){

      const followupOptions = this.definitionsByGroupLabel("contract.followup_action")

      const defFollowupAction = followupOptions.find(option => option.label === value)?.id || null

      // Avoid await so we don't block the UI. 
      this.patchContract({ id, changes: { defFollowupAction }})
    },
    async fetchWaitingForSigningContracts (){
      try {
        this.waitingForSigningContractsLoading = true

        const states = await this.$rambollfmapi.contracts.contract.getAllContractStates()

        const validStateNames = [
          "ContractStateSigningRequestSent",
          "ContractStateRequestFailed",
          "ContractStateSigningRequestExpired"
        ]
  
        const validStateIds = states.filter(state => validStateNames.includes(state.name)).map(state => state.id)
  
        const queryBody = {
          offset: 0,
          fetch: 100000,
          descending: false,
          time: this.currentDate,
          sortby: "contract_number",
          contractStates: validStateIds
        }
  
        this.waitingForSigningContracts = await this.$rambollfmapi.contracts.contract.contractreports.get(queryBody)

        this.waitingForSigningContractsLoading = false
      } catch(err){
        this.waitingForSigningContracts = []
        this.waitingForSigningContractsLoading = false
      }
    },
    async onUpdateRefundAction (id, value){
      const refundOptions = this.definitionsByGroupLabel("contract.guarantee_refund_action")
      const defRefundAction = refundOptions.find(option => option.label === value)?.id || null
      
      const patch = [{ op: "replace", path: "defRefundAction", value: defRefundAction}]
      const result = await this.$rambollfmapi.contracts.guarantees.update(id, patch)

      if (result.name === 'Error') {
        // force data reload by reloading the widget
        this.refundableGuaranteesWidget = {}
        setTimeout(() => {
          this.refundableGuaranteesWidget = createRefundableGuarantees(refundOptions, this.contractStates)
        }, 500);
      }  
    }
  }
}
</script>

<style scoped>
.widget-container {
  padding: 1.5em;
}
</style>
