<template>
  <div
    id="app"
  >
    <AllocationInfo
      v-if="infoOpen"
      :item="selectedItem"
      :modify="modifyInfo"
      :parents="nodeListBFS"
      :expenses="expenses"
      :date="date"
      @close="closeInfo"
      @save="saveInfo"
      @delete="deleteSelectedItem"
      @addNewCost="addNewCost"
      @addNewExpense="addNewExpense"
      @addCostToBeRemoved="addCostToBeRemoved"
      @addModifiedCost="addModifiedCost"
    />
    <div
      id="container"
    >
      <svg
        id="svg"
        class="svg-container"
        preserveAspectRatio="xMinYMid slice"
      >
        <g
          v-for="(item, i) in nodeListBFS"
          :key="i"
        >
          <g v-if="item.parent !== null && item.parentLevel !== 0">
            <line
              :x1="calculateXPos(i) + calculateWidth(i) / 2"
              :y1="calculateYPos(i)"
              :x2="calculateXPos(i) + calculateWidth(i) / 2"
              :y2="calculateYPos(i) - NODE_YSEP"
              stroke="black"
            />
          </g>
        </g>
      </svg> 
      <div
        :style="{height: 90 + 'px', top: '0px', left: '100px', width: 6.1 / 1.66 * NODE_WIDTH + 3 * NODE_XSEP + 'px'}"
        class="fixedtop"
      >
        <fieldset
          class="costdiv pa-1 pr-4 dropfield"
        >
          <legend>
            {{ $t('Organization costs') }}
          </legend>
          <div
            id="unallocated_codes"
            class="dropcodesource"
            @dragover="dragOver($event)"
            @drop="drop($event)"
          >
            <div
              v-for="(item, i) in allocationCodes"
              :id="item.label + '_code'"
              :key="i"
              :style="{'border':'1px', 'border-style': 'solid', 'background-color': (item.parentId || !item.value) ? '#89c8f4' : 'red' }"
              draggable="true"
              class="pa-1 mx-5 my-1 dragcodediv text-no-wrap rounded-pill"
              @dragstart="startDrag($event, 1, item)"
              @click="openInfo(item, 'code')"
            >
              {{ item.label }}
            </div>
          </div>
          <div
            class="verticalcenter"
          >
            <AddAllocationCodes
              :allocation-codes="allocationCodes"
              @addCode="addCode"
            />
          </div>
        </fieldset>
      </div>
      <div
        v-if="tree.root !== null"
        :style="{ width: '100%' }"
      >  
        <span
          v-for="(item, i) in nodeListBFS"
          :key="item.id"
        >
          <div
            v-if="item.level !== 0"
            :style="{ width: calculateWidth(i) + 'px', height: NODE_HEIGHT + 'px', top: calculateYPos(i) + 'px', left: calculateXPos(i) + 'px', position: 'absolute' }"   
            class="no-padding"
            @dragover="dragOver($event)"
            @drop="drop($event)"
          >
            <v-card
              class="rounded"
              height="100%"
            >
              <v-card-title
                class="grey lighten-2 py-0 my-0 mx-0"
              >
                <span
                  class="text_small px-3"
                >
                  {{ item.value }}
                </span>
                <v-row no-gutters>
                  <v-spacer />
                  <v-icon
                    @click="openModify(item, 'node')"
                  >edit</v-icon>
                  <v-icon
                    @click="openInfo(item, 'node')"
                  >remove_red_eye</v-icon>
                </v-row>
              </v-card-title>
              <div
                :id="item.id + '_allocation'"
                :class="item.level === 4 ? 'droptarget' : 'dropall'"
              />
            </v-card>
          </div>
        </span>
      </div>
      <div
        v-if="levelItems(4) > 0"
        :style="{ height: NODE_HEIGHT + 'px', top: 5 * (NODE_HEIGHT + NODE_YSEP + TREE_TOPMARGIN) - 100 + 'px', left: '100px', width: 6.1 / 1.666 * NODE_WIDTH + 3 * NODE_XSEP + 'px' }"
        class="fixed"
      >
        <fieldset
          class="portfoliodiv dropsource"
        >
          <legend>
            {{ $t('Unallocated portfolios') }}
          </legend>
          <div
            id="unallocated_portfolios"
            class="dropsource"
            @dragover="dragOver($event)"
            @drop="drop($event)"
          >
            <div
              v-for="(item, i) in portfolios"
              :id="item.label + '_portfolio'"
              :key="i"
              draggable="true"
              class="text-no-wrap rounded-pill dragdiv"
              @dragstart="startDrag($event, 1, item)"
              @click="openInfo(item, 'portfolio')"
            >
              {{ item.label }}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="rightnavigation">
      <div
        v-for="n in 4"
        :key="n"
        :style="{width:'20px', height: '20px', top: n * (NODE_HEIGHT+NODE_YSEP) - 120 + TREE_TOPMARGIN + 'px'}"
        class="no-padding"   
      >
        <AddAllocations
          v-if="n <= maxLevel"
          :allocation="n"
          :parents="getAllChildsFromParent(tree.root)"
          @addNode="addNode"
        />
      </div>
    </div>
    <v-row
      v-if="modified"
      style="right: 0; bottom: 0; z-index: 1;"
      no-gutters
    >
      <v-spacer />
      <v-btn 
        outlined
        rounded
        text
        @click="init"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn 
        class="primary" 
        rounded
        depressed
        @click="saveChanges"
      >
        <span v-if="!saving">{{ $t('Save') }}</span>
        <v-progress-circular
          v-else
          :size="20"
          :width="3"
          indeterminate
          color="primary"
        />
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import AddAllocations from './AddAllocations'
import AddAllocationCodes from './AddAllocationCodes'
import AllocationInfo from './AllocationInfo.vue'
import {Tree, TreeNode} from '../../helpers/tree'
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  components: {
    AddAllocations,
    AddAllocationCodes,
    AllocationInfo
  },
  data () {
    return {
      keyId: 1,
      NODE_XSEP: 10, 
      NODE_WIDTH: 500,
      NODE_YSEP: 20,
      NODE_HEIGHT: 150,
      TREE_TOPMARGIN: 80,
      tree: new Tree(),
      nodeListBFS: [],
      rootlessBFS: [],
      visible: false,
      tmpName: '',
      maxLevel: 1,
      dragged: null,
      portfolios: [],
      allocationCodes: [],
      draggedItem: null,
      modified: false,
      userOrganizations: [],
      selectedOrganization: null,
      infoOpen: false,
      selectedItem: {},
      hasEndDate: false,
      date: null,
      modifyInfo: false,
      newCosts: [],
      modifiedCosts: [],
      newExpenses: [],
      costsToBeRemoved: [],
      saving: false
    };
  },
  computed: {
    ...mapState('internalRent', ['inspectionYear', 'allocationMonth', 'portfolio', 'expenses', 'targetingCodes']),
    ...mapGetters('internalRent', ['getAllPortfolios', 'getAllTargetingCodes']),
    ...mapGetters('app', ['hasApplicationPermissionByName'])
  },
  watch: {
    inspectionYear: function () {
      this.getTargetingCodes(this.selectedOrganization.id)
      this.init()
    },
    allocationMonth: function () {
      this.getTargetingCodes(this.selectedOrganization.id)
      this.init()
    }
  },
  async mounted () {
    document.querySelector('#svg').setAttribute('width', 200)
    document.querySelector('#container').style.height = "810px"
    this.userOrganizations = await this.$rambollfmapi.userOrganizations.user()
    this.selectedOrganization = this.userOrganizations[0]
    this.getTargetingCodes(this.selectedOrganization.id)

    this.init()
  },
  methods: {
    ...mapActions('internalRent', ['getTargetingCodes', 'getPortfolio']),
    async init () {
      this.tree = new Tree()
      this.tree.root = new TreeNode("ground")  // invisible base root for all trees
      this.tree.root.level = 0
      this.tree.root.id = 0
      this.nodeListBFS = this.tree.traverseBFS()
      this.keyId = 1,

      this.allocationCodes = []
      this.portfolios = []

      this.date = new Date(this.inspectionYear, this.allocationMonth, 1)

      await this.getTree()
      await this.getTargetingCodes(this.selectedOrganization.id)
      await this.getAllocationCodesForTree()
      await this.getAllocationPortfoliosForTree()
      this.organizeAllocationCodes()
      this.organizeAllocationPortfolios()
      this.modified = false
      this.getPortfolioCosts()
    },
    calculateXPos (i) {
      let x = this.nodeListBFS[i].X * (this.NODE_WIDTH + this.NODE_XSEP) + 100
      return x
    },
    calculateYPos (i) {
      let y = this.nodeListBFS[i].level * (this.NODE_HEIGHT + this.NODE_YSEP) - 130 + this.TREE_TOPMARGIN
      return y
    },
    calculateWidth (i) {
      let gaps = this.nodeListBFS[i].width - 1;
      if (gaps < 0)
      {
        gaps = 0
      }
      let width = this.nodeListBFS[i].width * this.NODE_WIDTH + gaps * this.NODE_XSEP
      return width
    },
    levelItems (level)
    {
      const countOccurrences = (arr, val) => arr.reduce((a, v) => (v.level === val ? a + 1 : a), 0)
      let a = countOccurrences (this.nodeListBFS, level)
      return a
    },
    addNode (name, parent, lvl, loading = false, keyId = this.keyId) {
      if (!loading) {
        this.modified = true
      }
      if (this.nodeListBFS.find(element => element.value === name && element.level === lvl) || name === '' || name === null)
      {
        return
      }
      // find parent and add node as child
      this.nodeListBFS = this.tree.traverseBFS()  // flatten tree to list with breadth first
      let found = null
      if (lvl === 1)
      {
        found = this.tree.root
      }
      else
      {
        found = this.nodeListBFS.find(element => element.value === parent && element.level === lvl - 1);
      }
      if (found !== undefined && found.level === lvl - 1)
      {
        let newNode = {
          value: name,
          parent: found,
          width: 1,
          level: lvl,
          children: [],
          id: keyId
        }
        found.children.push(newNode)
        this.widenParent(newNode)
        this.nodeListBFS = this.tree.traverseBFS()
        if (this.nodeListBFS.length > 0)
        {
          this.setXlocation(this.nodeListBFS)
        }
        this.keyId++
      }
      if (lvl >= this.maxLevel && found !== undefined)
      {
        this.maxLevel = lvl + 1
      }
      var svg = document.querySelector('#svg');
      var bbox = svg.getBBox();
      {
        svg.setAttribute('width', bbox.width + 600)
        svg.setAttribute('height', bbox.height + 200)
      }
      if (this.maxLevel >= 4)
      {
        document.getElementById("container").style.height = 5 * (this.NODE_HEIGHT + this.NODE_YSEP) + this.TREE_TOPMARGIN + 'px';
      }
    },
    widenParent (node)
    { 
      while (node.parent !== null)
      {
        let width = 0
        node.parent.children.forEach(item =>
        {
          width += item.width
        })
        node.parent.width = width
        node = node.parent
      }
    },
    setXlocation (node)
    {
      node.forEach(item =>
      {
        item.children.forEach ((child, index) =>
        {
          if(index === 0 && child.parent !== null)
          {
            child.X = child.parent.X
          }
          else
          {
            child.X = child.parent.children[index-1].X + child.parent.children[index-1].width
          }
        })
      })
    },
    checkName (nodetmp)
    {
      let newValue=nodetmp.value
      nodetmp.value = this.tmpName
      const found = this.nodeListBFS.find(element => element.value === newValue && element.level === nodetmp.level)
      if(found===undefined)
      {
        nodetmp.value = newValue
      }
    },
    startDrag (event, portfolioId, item) {
      this.dragged = event.target
      event.dataTransfer.effectAllowed = 'move'

      this.draggedItem = item

    },
    drop (event) {
      event.preventDefault();
      // move dragged elem to the selected drop target
      // portfolios
      if(this.dragged.className.includes("dragdiv"))
      {
        if ( event.target.className.includes("droptarget") || event.target.className.includes("dropsource")) {
            this.dragged.parentNode.removeChild( this.dragged );
            event.target.appendChild( this.dragged );

            this.portfolios.map(portfolio => {
              if (portfolio.label === this.draggedItem.label) {
                portfolio.parentId = (event.target.id && event.target.id !== 'unallocated_portfolios') ? event.target.id.split('_allocation')[0] : null
              }
            })
            this.draggedItem = null
        }
        else if (event.target.parentNode.className.includes("droptarget") || event.target.parentNode.className.includes("dropsource")) {
            this.dragged.parentNode.removeChild( this.dragged );
            event.target.parentNode.appendChild( this.dragged );
            
            this.portfolios.map(portfolio => {
              if (portfolio.label === this.draggedItem.label) {
                portfolio.parentId = (event.target.parentNode.id && event.target.parentNode.id !== 'unallocated_portfolios') ? event.target.parentNode.id.split('_allocation')[0] : null
              }
            })
            this.draggedItem = null
        }
        if(this.dragged.parentNode.className.includes("droptarget"))
        {
          this.dragged.style.borderColor = "#009de0"
        }
        else if (this.dragged.parentNode.className.includes("dropsource"))
        {
          this.dragged.style.borderColor = "red"
        }
      }
      // allocation codes
      if (this.dragged.className.includes("dragcodediv")) {
        if ( event.target.className.includes("dropall") || event.target.className.includes("dropcodesource") || event.target.className.includes("droptarget")) {
            this.dragged.parentNode.removeChild( this.dragged );
            event.target.appendChild( this.dragged );

            this.allocationCodes.map(code => {
              if (code.label === this.draggedItem.label) {
                code.parentId = (event.target.id && event.target.id !== 'unallocated_codes') ? event.target.id.split('_allocation')[0] : null
              }
            })
            this.draggedItem = null
        } else if (event.target.parentNode.className.includes("dropall") || event.target.parentNode.className.includes("dropcodesource") || event.target.parentNode.className.includes("droptarget")) {
            this.dragged.parentNode.removeChild( this.dragged );
            event.target.parentNode.appendChild( this.dragged );

            this.allocationCodes.map(code => {
              if (code.label === this.draggedItem.label) {
                code.parentId = (event.target.parentNode.id && event.target.parentNode.id !== 'unallocated_codes') ? event.target.parentNode.id.split('_allocation')[0] : null
              }
            })
            this.draggedItem = null
        }
      }
      this.modified = true
      this.getPortfolioCosts()
    },
    dragOver (event) {
      event.preventDefault();
    },
    rootless (tree) {
      let a = tree.slice(1) 
      return a
    },
    addCode (code) {
      this.modified = true
      this.allocationCodes.push({parentId: null, label: code})
    },
    async saveChanges () {
      var overWrite = false
      this.saving = true

      const future = await this.$rambollfmapi.internalrent.allocations.future(this.selectedOrganization.id, this.date)
      if (future.data !== false) {
        overWrite = confirm(this.$t('confirm.overwrite_allocation'))
      }

      var treeItemList = this.getAllChildsFromParent(this.tree.root)

      const allocationCodes = this.allocationCodes
      allocationCodes.map(code => {
        code.uniteParentId = code.parentId ? code.parentId + '_' + this.selectedOrganization.id : null
      })
      
      const portfolios = this.portfolios
      portfolios.map(portfolio => {
        portfolio.uniteParentId = portfolio.parentId ? portfolio.parentId + '_' + this.selectedOrganization.id : null
      })
      await this.$rambollfmapi.internalrent.allocations.put(this.selectedOrganization.id, treeItemList, this.date, overWrite)
      await this.$rambollfmapi.internalrent.allocations.codes.put(this.selectedOrganization.id, allocationCodes, this.date, overWrite)
      await this.$rambollfmapi.internalrent.allocations.portfolios.put(this.selectedOrganization.id, portfolios, this.date, overWrite)

      if (this.portfolio) {
        this.getPortfolio({
          portfolioId: this.portfolio.id,
          current_year: this.inspectionYear,
          from_allocation_code: this.hasApplicationPermissionByName('SIS_KULUKOHDISTUS') ? true : false
        })
      }
      this.init()
      this.modified = false
      this.saving = false
    },
    getAllChildsFromParent (parent) {
      var treeItems = []

      if (parent && parent.children) {
        parent.children.forEach(child => {
          const treeItem = {
            label: child.value,
            uniteParentId: child.parent.id ? child.parent.id + '_' + this.selectedOrganization.id : null,
            level: child.level,
            uniteId: child.id + '_' + this.selectedOrganization.id,
            id: child.id
          }

          treeItems.push(treeItem)
          if (child.children.length > 0) {
            treeItems.push(...this.getAllChildsFromParent(child))
          }
        })
      }
      return treeItems
    },
    getLastChildsById (itemList, id) {
      var lastChilds = []
      const childList = itemList.filter(i => i.uniteParentId === id)
      if (childList.length > 0) {
        childList.forEach(ch => {
          lastChilds.push(...this.getLastChildsById(itemList, ch.uniteId))
        })
      } else {
        return itemList.filter(i => i.uniteId === id)
      }
      return lastChilds
    },
    async getTree () {
      const allocations = await this.$rambollfmapi.internalrent.allocations.get(this.selectedOrganization.id, this.date)
      
      allocations.forEach(a => {
        const parent = allocations.find(allo => allo.id === a.parentId) ? allocations.find(allo => allo.id === a.parentId).label : null
        const branchId = parseInt(a.uniteId.split('_')[0])
        if (this.keyId < branchId) {
          this.keyId = branchId
        }

        this.addNode(a.label, parent, a.level, true, branchId)
      })
    },
    async getAllocationCodesForTree () {
      const allocationCodes = await this.$rambollfmapi.internalrent.allocations.codes.get(this.selectedOrganization.id, this.date)
      allocationCodes.map(code => {
        if (code.uniteParentId) {
          code.parentId = code.uniteParentId.split('_')[0]
        } else {
          code.parentId = null
        }
      })

      allocationCodes.push(...this.getAllTargetingCodes.filter(tc => !allocationCodes.find(ac => ac.label === tc.code)).map(tc => {
        return {
          label: tc.code,
          parentId: null,
          uniteParentId: null,
          value: tc.value
        }
      }))

      this.allocationCodes = allocationCodes
    },
    organizeAllocationCodes () {
      this.allocationCodes.forEach(c => {
        const parentDoc = document.getElementById(c.parentId + '_allocation')
        const currentDoc = document.getElementById(c.label + '_code')

        if (currentDoc) {
          currentDoc.parentNode.removeChild(currentDoc)
        }
        if (c.parentId && parentDoc) {
          parentDoc.appendChild(currentDoc)
        } else {
          document.getElementById('unallocated_codes').appendChild(currentDoc)
        }
      })
    },
    async getAllocationPortfoliosForTree () {
      var portfolios = this.getAllPortfolios.map(p => {
        return {
          parentId: null,
          portfolioId: p.id,
          label: p.name,
          area: p.area
        }
      })
      const allocated = await this.$rambollfmapi.internalrent.allocations.portfolios.get(this.selectedOrganization.id, this.date)

      portfolios.forEach(p => {
        const parentIdString = allocated.find(a => a.portfolioId === p.portfolioId) ? allocated.find(a => a.portfolioId === p.portfolioId).uniteParentId : null
        if (parentIdString) {
          p.parentId = parentIdString.split('_')[0]
        }
      })

      this.portfolios = portfolios
    },
    organizeAllocationPortfolios () {
      this.portfolios.forEach(c => {
        const parentDoc = document.getElementById(c.parentId + '_allocation')
        const currentDoc = document.getElementById(c.label + '_portfolio')
        const unAllocatedDoc = document.getElementById('unallocated_portfolios')

        if (currentDoc) {
          currentDoc.parentNode.removeChild(currentDoc)
        }
        if (c.parentId && parentDoc) {
          currentDoc.style.borderColor = "#009de0"
          parentDoc.appendChild(currentDoc)
        } else if (unAllocatedDoc) {
          currentDoc.style.borderColor = "red"
          unAllocatedDoc.appendChild(currentDoc)
        }
      })
    },
    async getPortfolioCosts () {
      const codesInTargetingCodes = new Set()
      this.targetingCodes.forEach((obj) => {
        const key = obj["code"];
        codesInTargetingCodes.add(key);
      });
      const codes = this.allocationCodes.filter(code => codesInTargetingCodes.has(code.label))
      const items = this.getAllChildsFromParent(this.tree.root)
      this.portfolios.forEach(p => {
        p.costs = this.getPortfolioCostTree()
      })

      const targetingCodeLookup = new Map();
      for (const obj of this.targetingCodes) {
        const key = obj["code"];
        targetingCodeLookup.set(key, obj);
      }

      const nodeLookup = new Map();
      for (const obj of items) {
        const key = obj["id"].toString();
        nodeLookup.set(key, obj);
      }
      
      //Portfolios can have same parentIds so its map values has to be an array of portfolios
      const portfolioLookup = new Map();
      for (const obj of this.portfolios)
      {
        if (portfolioLookup.has(obj.parentId)) 
        {
          const existingArray = portfolioLookup.get(obj.parentId);
          existingArray.push(obj);
          portfolioLookup.set(obj.parentId, existingArray);
        } else {
          portfolioLookup.set(obj.parentId, [obj]);
        }
      }
      
      codes.filter(x => x.parentId != null).forEach(code => {
        var portfoliosWithSameParentAsCode = portfolioLookup.get(code.parentId)
        if(portfoliosWithSameParentAsCode && portfoliosWithSameParentAsCode.length > 0)
        {
          let totalCost = this.calculateTotalCost(targetingCodeLookup, code)
          this.addPortfolioCosts(portfoliosWithSameParentAsCode, 1, totalCost)
        } 
        else if (nodeLookup.get(code.parentId).level != 4) {
          let totalCost = this.calculateTotalCost(targetingCodeLookup, code)
          const item = nodeLookup.get(code.parentId)
          const childsForAllocation = this.getLastChildsById(items, item.uniteId)
          const portfoliosFromNodeChildren = this.portfolios.filter(p => p.parentId != null && childsForAllocation.find(ch => ch.id.toString() === p.parentId))
          this.addPortfolioCosts(portfoliosFromNodeChildren, 0, totalCost)
        }
      })
    },
    calculateTotalCost (targetingCodeLookup, code)
    {
      var totalCost = 0
      targetingCodeLookup.get(code.label).expenses.forEach(e => {
          e.costs.forEach(c => {
            totalCost += c.value
          })
      })
      return totalCost
    },
    //costType can be 0 = Shared costs OR 1 = Own costs
    addPortfolioCosts (portfolios, costType, totalCost)
    {
      let totalArea = portfolios.reduce((acc, obj) => acc + obj.area, 0)

      portfolios.forEach(p => {
        p.costs.children[costType].cost += totalCost * (p.area / totalArea)
        p.costs.cost += totalCost * (p.area / totalArea)
      });
    },
    getPortfolioCostTree ()
    {
      // Create costs tree for portfolios
      return { 
          id: 1,
          level: 0,
          name: this.$t('Costs'),
          cost: 0,
          children: [
            {
              id: 2,
              level: 1,
              name: this.$t('Shared costs'),
              cost: 0
            },
            {
              id: 3,
              level: 1,
              name: this.$t('Own costs'),
              cost: 0
            }
          ]
        }
    },
    openModify (item, type) {
      this.modifyInfo = true
      this.selectedItem = this.generateInfoItemByType(item, type)
      this.infoOpen = !this.infoOpen
    },
    openInfo (item, type) {
      this.selectedItem = this.generateInfoItemByType(item, type)
      this.infoOpen = !this.infoOpen
    },
    closeInfo () {
      this.infoOpen = false
      this.modifyInfo = false
      this.newCosts = []
      this.costsToBeRemoved = []
      this.newExpenses = []
      this.modifiedCosts = []
    },
    generateInfoItemByType (item, type) {
      if (type === 'code') {
        const targetCode = this.getAllTargetingCodes.find(tc => tc.code === item.label)
        return {
          code: item.label,
          label: targetCode ? targetCode.name : null,
          type: 'Allocation code',
          costs: this.getInfoCosts(targetCode),
        }
      } else if (type === 'node') {
        const extra = this.getCostForNode(item)
        return {
          id: item.id,
          level: item.level,
          id_parent: item.parent.id,
          label: item.value,
          type: 'Allocation',
          area: extra.area,
          cost: extra.cost
        }
      } else if (type === 'portfolio') {
        const portfolio = this.portfolios.find(p => p.portfolioId === item.portfolioId)
        return {
          label: item.label,
          type: 'Portfolio',
          area: portfolio ? portfolio.area : 0,
          costs: portfolio ? [portfolio.costs] : 0
        }
      } else {
        return {}
      }
    },
    getCostForNode (item) {
      const items = this.getAllChildsFromParent(this.tree.root)
      const lastChilds = this.getLastChildsById(items, (item.id + '_' + this.selectedOrganization.id))

      var totalCost = 0
      var totalArea = 0

      this.portfolios.filter(p => lastChilds.find(lc => lc.uniteId.split('_')[0] === p.parentId)).forEach(p => {
        totalCost += p.costs ? p.costs.cost : 0
        totalArea += p.area ? p.area : 0
      })
      return { cost: totalCost, area: totalArea }
    },
    async saveAllocationCode () {
      for (const expense of this.newExpenses) {
        let result = await this.$rambollfmapi.internalrent.allocations.expenseCost.get(expense.code, this.date)
        
        if(result.code) {        
          await this.$rambollfmapi.internalrent.allocations.expenseCost.put(expense, this.date)
        } else {
          await this.$rambollfmapi.internalrent.allocations.expenseCost.post(expense, this.date)
        }
      }

      if (this.newCosts.length > 0) {
        await this.$rambollfmapi.internalrent.allocations.targetingCodes.put(this.newCosts, this.date)
      }

      if(this.modifiedCosts. length > 0) {
        await this.$rambollfmapi.internalrent.allocations.targetingCodes.put(this.modifiedCosts, this.date)
      }

      if (this.costsToBeRemoved.length > 0) {
        for (let code of this.costsToBeRemoved) {
          await this.$rambollfmapi.internalrent.allocations.targetingCodes.delete(code.costCode, code.realizationMonth)
        }
      }

      this.modified = true
      this.closeInfo()
    },
    async saveInfo (item) {
      if (item.type === "Allocation code") {
        await this.saveAllocationCode()
        await this.getTargetingCodes(this.selectedOrganization.id)
        
        return
      }
      this.nodeListBFS = this.tree.traverseBFS()  // flatten tree to list with breadth first
      let found = this.nodeListBFS.find(element => element.id === item.id)
      
      if (item.id_parent !== found.parent.id && item.type !== 'Allocation Code') {
        let originalParent = null
        originalParent = this.nodeListBFS.find(element => element.id === found.parent.id)
        let newParent = null
        newParent = this.nodeListBFS.find(element => element.id === item.id_parent)

        found.parent = newParent
        newParent.children.push(found)
        originalParent.children = originalParent.children.filter(element => element.id !== item.id)

        this.widenParent(found)
        this.nodeListBFS = this.tree.traverseBFS()
        if (this.nodeListBFS.length > 0)
        {
          this.setXlocation(this.nodeListBFS)
        }
      }
      found.value = item.label
      
      this.updateCodesAndPortfolios()

      this.nodeListBFS = this.tree.traverseBFS()
      this.modified = true
      
      this.closeInfo()
    },
    deleteSelectedItem (item) {
      if (item.type === 'Allocation code') {
        this.allocationCodes = this.allocationCodes.filter(code => code.label !== item.code)
      }
      this.nodeListBFS = this.tree.traverseBFS()  // flatten tree to list with breadth first
      let parent = null
      parent = this.nodeListBFS.find(element => element.id === item.id_parent && element.level === (item.level - 1))
      if (parent) {
        parent.children = parent.children.filter(element => element.id !== item.id)
      }
      this.nodeListBFS = this.tree.traverseBFS()

      this.updateCodesAndPortfolios()
      
      this.modified = true
      this.closeInfo()
    },
    updateCodesAndPortfolios () {
      this.portfolios.map(p => {
        if (!this.nodeListBFS.find(bfs => bfs.id.toString() === p.parentId)) {
          p.parentId = null
        }
      })
      this.organizeAllocationPortfolios()

      this.allocationCodes.map(p => {
        if (!this.nodeListBFS.find(bfs => bfs.id.toString() === p.parentId)) {
          p.parentId = null
        }
      })
      this.organizeAllocationCodes()
    },
    getInfoCosts (item) {
      if (!item) {
        return [{
          id: 1,
          level: 0,
          cost: 0,
          name: this.$t('Costs'),
          children: []
        }]
      }

      const object = {
        id: 1,
        level: 0,
        name: this.$t('Costs'),
        cost: 0,
        children: []
      }

      var index = 1
      item.expenses.forEach(exp => {
        index++
        const expense = {
          id: index,
          level: 1,
          name: exp.name,
          cost: 0,
          children: []
        }
        exp.costs.forEach(c => {
          index++
          const cost = {
            id: index,
            level: 2,
            name: c.label,
            cost: c.value,
            costCode: c.costCode
          }

          expense.cost += c.value
          expense.children.push(cost)
        })
        object.cost += expense.cost
        object.children.push(expense)
      })

      return [object]
    },
    addNewCost (newCost) {
      this.newCosts.push(newCost)
    },
    addNewExpense (newExpense) {
      this.newExpenses.push(newExpense)
    },
    addCostToBeRemoved (cost) {
      this.costsToBeRemoved.push(cost)
    },
    addModifiedCost (modifiedCost) {
      this.modifiedCosts.push(modifiedCost)
    }
  }
}
</script>

<style scoped>
.item {
  stroke: #ccc;
  stroke-width: 5;
}
path {
  stroke: #5bbaa1;
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
}
text {
  font-size: 28px;
  text-anchor: middle;
}
.text_small {
  font-size: 16px;
  font-weight: bold;
}
#container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 95%;
  overflow-x: auto;
  overflow-y: auto;
}
#config {
  padding: 10px;
  margin: 5px;
  height: fit-content;
  background: #82e5cb;
  border-radius: 5px;
}
.rightnavigation {
  top: 10px;
  bottom: 10px;
  width: 60px;
  float: right;
}
.svg-container {
  position: absolute;
  width: 100%;
  overflow: visible;
}
.title {
  font-size: 40px;
}
.item {
  border: 1px solid red;
  padding: 5px;
}
.action {
  padding: 10px;
}
.circle {
  stroke-width: 5;
  stroke: #5bbaa1;
  fill: none;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
  border: 0px;
}
.undernone >>> .v-input__slot::before {
  border-style: none !important;
}
.no-padding {
  position: absolute;
}
.droptarget {
  position: absolute;
  width: 100%;
  height: 65%;
  overflow-y: auto;
}
.dropall {
  position: absolute;
  width: 100%;
  height: 65%;
  overflow-y: auto;
}
.dropsource {
  height: 100%;
  overflow-y: auto;
}
.dropfield {
  height: 100%;
}
.dropfield legend {
  padding: 0 6px 0 6px;
  margin-left: 17px;
}
.dropcodesource {
  height: 100%;
  width: 96%;
  overflow-y: auto;
  float: left;
}
.v-card__text {
  padding: 0px;
}
.borderless {
  overflow-y: auto;
}
.dragdiv {
  border: 2px solid red;
  float: left;
  text-align: center;
  padding: 5px;
  margin: 5px 5px 5px 15px;
}
.dragcodediv {
  min-width: 150px;
  background-color: #89c8f4;
  float: left;
  color: white;
  text-align: center;
}
.portfoliodiv {
  border: 2px solid red;
  border-radius: 25px;
  margin-top: 20px;
  height: 80%;
}
.portfoliodiv legend {
  padding: 0 6px 0 6px;
  margin-left: 20px;
}
.costdiv {
  border: 2px solid #89c8f4;
  border-radius: 25px;
}
.fixed {
  position: sticky;
  z-index: 2;
}
.fixedtop {
  position: sticky;
  top: 0px;
}
.verticalcenter {
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  float: right;
}
#overflow {
  overflow-x: scroll;
  height: 100%;
}
#inner_remaining {
  background-color: #dddddd;
  position: relative;
}
#addcodebutton {
  float: right;
}
#app {
  width: 100%;
}
</style>