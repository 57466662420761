<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('Set zoom level') }}
    </template>
    <template #content>
      <div :class="documentType === 'unit-brochure' ? 'unit-brochure' : 'unit-contract-attachement'">      
        <V3Viewer
          v-if="zoomSelectionActive"
          ref="v3viewerZoomLevelSetting"
          :session-token="sessionToken"
          :models="models"
          :floor-id="floorId"
          :selected-units=" selectedUnitsForViewer"
          :selected-spaces="selectedSpacesForViewer"
          :hidden-layers="hiddenLayers"
          :display-only="true"
          :container-id-from-parent="'zoomSelection'"
          background-color="0.984,0.984,0.984,1"
          @render-finish="color"
        />
      </div>
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        class="primary"
        @click="openPrinting"
      >
        {{ $t('Print') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import V3Viewer from './V3Viewer'
import BaseModal from '../../components/general/BaseModal.vue'
export default {
  name: 'ZoomLevelSelection',
  components: {
    V3Viewer: V3Viewer,
    BaseModal,
  },
  props: {
    sessionToken: {
      default: '',
      type: String
    },
     models: {
      default: () => {
        return []
      },
      type: Array
    },
    floorId: {
      default: null,
      type: Number
    },
    selectedUnits: {
      default: () => {
        return []
      },
      type: Array
    },
    selectedSpaces: {
      default: () => {
        return []
      },
      type: Array
    },
    hiddenLayers: {
      default: () => {
        return []
      },
      type: Array
    },
    zoomSelectionActive: {
      default: false,
      type: Boolean
    },
    documentType: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      selectedUnitsForViewer: [],
      selectedSpacesForViewer: [],
      zoomViewerReady: false
    }
  },
  methods: {
    close () {
      this.zoomViewerReady = false
      this.$emit('close')
    },
    async openPrinting () {
      const camera = await this.$refs.v3viewerZoomLevelSetting.fetchCamera()
      this.$emit('openPrinting', camera)
      this.zoomViewerReady = false
    },
    color () {
      if(!this.zoomViewerReady) {
        this.selectedUnitsForViewer = []
        this.selectedUnitsForViewer = this.selectedUnits.map(unit => {
          return {
            identifier: unit.unit_code
          }
        })

        this.selectedSpacesForViewer = []
        this.selectedSpacesForViewer = this.selectedSpaces.map( unit => {
          return {
            identifier: unit.hnro
          }
        })

        this.zoomViewerReady = true
      }
    }
  }
}
</script>
<style scoped>
.unit-brochure {
  height: 75mm;
  width: 184.7mm;
  padding-right: 12px;
}
.unit-contract-attachement {
  width: 165mm;
  height: 166mm;
}
</style>