import csv from './csv'
import saveAs from './saveAs'
import humanize from './humanize'
import hash from './hash'
import uuid from './uuid'
import format from './format'
import ltpConstants from './ltpConstants'
import requestTypes from './requestTypes'
import * as VeeValidate from 'vee-validate' // new plugin/add-on to help with form validations
import dataTable from './dataTable'
import breeamInUseCertification from './breeamInUseCertification'
import variableLists from './variableLists'
import codeUtility from './codeUtility'
import tableFilter from './tableFilter'
import clone from './clone'
import table from './table'
import parties from './parties'

export default {
  csv: csv,
  uuid: uuid,
  saveAs: saveAs,
  humanize: humanize,
  format: format,
  hash: hash,
  ltpConstants: ltpConstants,
  VeeValidate: VeeValidate,
  requestTypes: requestTypes,
  dataTable: dataTable,
  breeamInUseCertification: breeamInUseCertification,
  variableLists: variableLists,
  codeUtility: codeUtility,
  tableFilter: tableFilter,
  clone: clone,
  table: table,
  parties: parties
}
