<template>
  <v-row
    class="dashboard"
  >
    <v-col
      md="6"
      cols="12"
    >
      <v-row>
        <v-col
          lg="6"
          md="12"
          sm="12"
          cols="12"
        >
          <Notes 
            note-type="customers"
          />
        </v-col>
        <v-col
          v-for="widget in leftWidgets"
          :key="widget.id"
          :lg="widget.small ? 6 : 12"
          md="12"
          sm="12"
          cols="12"
        >
          <DynamicWidget
            v-if="widget.id != null"
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widgetLoading(widget.id)"
            @change-item-set="changeWidgetSet"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      md="6"
      cols="12"
    >
      <v-row>
        <v-col
          v-for="widget in rightWidgets"
          :key="widget.id"
          :lg="widget.small ? 6 : 12"
          md="12"
          sm="12"
          cols="12"
        >
          <DynamicWidget
            v-if="widget.id != null"
            :type="widget.type"
            :title="$t(widget.id)"
            :data="widget.data"
            :loading="widgetLoading(widget.id)"
            @change-item-set="changeWidgetSet"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DynamicWidget from '../../DynamicWidget'
import LeasingWidgets from '../../../widgets/leasing'
import moment from 'moment'
import { CorporationLinker } from '../../../helpers/leasing/corporations'
import { CapitalRentCalculator } from '../../../helpers/leasing/capitalRent'
import Notes from './../Notes'

export default {
  name: 'Customers',
  components: {
    DynamicWidget,
    Notes
  },
  data () {
    return {
      leftWidgets: [...LeasingWidgets.CustomersLeft()],
      rightWidgets: [...LeasingWidgets.CustomersRight()],
      largestCustomerSet: 'Show all'
    }
  },
  computed: {
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'buildingIds',
      'buildingRecursionLoadingState'
    ]),
    ...mapState('leasing', [
      'rentalStatuses',
      'currentRentalStatusesLoading',
      'corporations',
      'corporationsLoading'
    ]),
    ...mapState('rentalProcess', [
      'rentalProcesses',
      'rentalProcessLoading',
      ]),
    ...mapState('openBills', ['quarterlyOpenBills', 'quarterlyOpenBillsLoading']),
    ...mapGetters('app', [
      'siteFiltersByTypes'
    ]),
    widgets () {
      return this.leftWidgets.concat(this.rightWidgets)
    },
    // Aggregate corporation data for faster access
    corporationLinker () {
      return new CorporationLinker(this.corporations)
    },
  },
  watch: {
    buildingRecursionLoadingState (newValue) {

      if (!newValue) {
        this.fetchQuarterlyOpenBills()
      }
    },
    sites: function () {  
      this.loadWidgetData()
    },
    rentalProcesses: function () {  
      this.loadWidgetData()
    },
    rentalStatuses: function (){
      this.loadWidgetData()
    },
    largestCustomerSet: function () {
      this.loadWidgetData(['leasing.largest_customers'])
    },
    quarterlyOpenBills () {
      this.loadWidgetData(['leasing.open_bills_progress'])
    }
  }, 
  mounted () {

    const { buildings } = this.buildingIds

    if (buildings && buildings.length > 0) {
      this.fetchQuarterlyOpenBills()
    }

    this.loadWidgetData()
  },
  methods: {
    ...mapActions('openBills', ['fetchQuarterlyOpenBills']),
    loadWidgetData (selectedWidgets = []) {
      // Let's wait that all data is finished loading first.
      if(this.sitesLoading || this.currentRentalStatusesLoading || this.rentalProcessLoading){
        return
      }
      let loadedWidgets = this.widgets
      if (selectedWidgets.length > 0) {
        loadedWidgets = this.widgets.filter(widget => selectedWidgets.includes(widget.id))
      }
      loadedWidgets.forEach(widget => {
        widget.data.items = this.dataForWidget(widget.id)
        widget.data.filter = this.filterForWidget(widget.id)
        if (widget.data.dynamicSetTypes) {
          widget.data.itemSetTypes = this.dataSetsForWidget(widget.id)
        }
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }
        
        // widget second items set
        if (widget.data.lineItems) {
          widget.data.lineItems = this.lineDataForWidget(widget.id)
        }
      })

    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.customer_register':
        case 'leasing.customers_by_status':
          return this.sitesLoading || this.currentRentalStatusesLoading || this.rentalProcessLoading || this.corporationsLoading
        case 'leasing.largest_customers':
          return this.sitesLoading || this.currentRentalStatusesLoading || this.corporationsLoading
        case 'leasing.open_bills_progress':
          return this.buildingRecursionLoadingState || this.sitesLoading || this.quarterlyOpenBillsLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }
        switch(id) {
          case 'leasing.customer_register':
            return this.customerRegisterData(this.rentalStatuses, this.corporations, this.rentalProcesses, this.sites)
          case 'leasing.customers_by_status':
            return this.customersByStatusData(this.rentalStatuses, this.corporations, this.sites)
          case 'leasing.largest_customers':
            return this.largestCustomers(this.rentalStatuses, this.corporations, this.sites)
          case 'leasing.open_bills_progress':
            return this.openBillsProgressData(this.quarterlyOpenBills)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process data for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    dataSetsForWidget (id) {
      try {
        switch(id) {
          case 'leasing.largest_customers':
            return this.loadLargestCustomersSets(this.corporations)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error('Error trying to process sets for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    filterForWidget (id) {
      try {
        switch(id) {
          case 'leasing.customer_register':
            return this.customerRegisterFilter(this.corporations)
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    legendForWidget (id) {
      try {
        switch(id) {
          case 'leasing.largest_customers':
            return this.loadLargestCustomersLegend(this.corporations)
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error('Error trying to process filters for widget: "' + id + '"', err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    lineDataForWidget (id) {
      try {
        switch (id) {
          case 'leasing.open_bills_progress':
            return this.openBillsProgressLineData(this.quarterlyOpenBills)
          default:
            return null
        }
      } catch (err) {
        this.$log.error(`Error trying to process filters for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    customerRegisterData (rentalStatuses, corporations, rentalProcesses, sites) {
      return this.getCorporationsWithNewFields(rentalStatuses, corporations, sites, rentalProcesses)
    },
    customerRegisterFilter (corporations) {
      // Get unique statuses and map them to objects for filter
      const statuses = corporations.map(item => item.tenant_status)
      const optionsSet = Array.from(new Set(statuses)).filter(item => item != null && item.length > 0)
      const options = optionsSet.map(item => {return {text: item, value: item}})
      // Also add show all option
      options.push({text: 'prospect.show_all', value: null})
      // Add default
      const defaultFilter = options.find(item => item.text === 'prospect.show_all')
      return {
        header: 'tenant_status',
        type: 'equal',
        options: options,
        defaultFilter: defaultFilter
      }
    },
    customersByStatusData (rentalStatuses, corporations, sites) {
      let corporationsWithNewFields = this.getCorporationsWithNewFields(rentalStatuses, corporations, sites)
      const data = corporationsWithNewFields.map(c => {
        if(!c.tenant_status) {
          c.tenant_status = this.$t('Corporation without informed status')
        }
        c.corporation_contract_area = c.valid_contracts_area
        return c
      })

      return data
    },

    largestCustomers (rentalStatuses, corporations, sites) {
      let corporationsWithNewFields = this.getCorporationsWithNewFields(rentalStatuses, corporations, sites, null, true)
      // The widget needs to know total amounts for calculations, so we add filtered set values to their own variables
      if (this.largestCustomerSet !== 'Show all') {
        corporationsWithNewFields.forEach(corporation => {
          if (corporation.tenant_category === this.largestCustomerSet) {
            corporation.shown_contract_area = corporation.valid_contracts_area
            corporation.shown_current_sites = corporation.current_sites
            corporation.shown_market_rent = corporation.valid_contracts_market_rent
            corporation.corporation_name = corporation.key_account_customer ? '\u2B50' + ' ' + corporation.corporation_name : corporation.corporation_name
            // Yearly capital rent
            corporation.shown_capital_rent = corporation.capital_rent
          }
        })
      } else {
        corporationsWithNewFields = corporationsWithNewFields.map(corporation => ({
          ...corporation,
          // Yearly capital rent
          shown_capital_rent: corporation.capital_rent,
          shown_contract_area: corporation.valid_contracts_area,
          shown_current_sites: corporation.current_sites,
          shown_market_rent: corporation.valid_contracts_market_rent,
          corporation_name: corporation.key_account_customer ? '\u2B50' + ' ' + corporation.corporation_name : corporation.corporation_name
        }))
      }
      // Get number of unique categories
      const uniqueCategories = this.getUniqueCategories(corporations)
      // Create color for each category
      const colors = {}
      uniqueCategories.forEach((category, index) => {
        colors[category] = this.getThemeColor(index)
      })
      // Add colors to corporations data
      const corporationsWithColors = corporationsWithNewFields.map(item => ({ ...item, color: colors[item.tenant_category] ?? colors.Unknown }))
      return corporationsWithColors
    },

    loadLargestCustomersSets (corporations) {
      let uniqueCategories = this.getUniqueCategories(corporations)
      uniqueCategories.unshift('Show all')
      return uniqueCategories
    },

    changeWidgetSet (set) {
      if (set.set === 'Unknown') {
        this.largestCustomerSet = ''
      }
      else {
        this.largestCustomerSet = set.set
      }
    },

    loadLargestCustomersLegend (corporations) {
      const uniqueCategories = this.getUniqueCategories(corporations)
      const colors = {}
      uniqueCategories.forEach((category, index) => {
          colors[category] = this.getThemeColor(index)
      })
      let categoryLegends = uniqueCategories.map(category => 
        ({
            text: this.$t(category),
            fillStyle: colors[category],
            hidden: false,
            lineWidth: 1,
            strokeStyle: '#fff',
        })
      )
      return categoryLegends
    },

    getUniqueCategories (corporations) {
      let categories = corporations.map(corpo => {
        if (corpo.tenant_category == null || corpo.tenant_category.length === 0) {
          return 'Unknown'
        }
        else {
          return corpo.tenant_category
        }  
      })
      let uniqueCategories = [...new Set(categories)];
      return uniqueCategories
    },

    getCorporationsWithNewFields (rentalStatuses, corporations, sites, rentalProcesses = null, calculateMarketRent = false)  {
      let corporationsWithNewFields = corporations.map(corporation => ({
        ...corporation,
        process_amount: 0,
        valid_contracts_area: 0,
        current_sites: 0,
        valid_contracts_market_rent: 0,
        capital_rent: 0
      }))

      // Add rental processes to corporations by id
      if (rentalProcesses != null) {
        let processesByParty = {}
        const activeStages = ['2','3','4','5']; 
        rentalProcesses.filter(process => {return activeStages.includes(process.stage.charAt(0))}).forEach(process => {
          if (process.id_corporation) {
            if (!processesByParty[process.id_corporation]) {
              processesByParty[process.id_corporation] = 0
            }
            processesByParty[process.id_corporation]++
          }
        })
        for (const key in processesByParty) {
          let foundCorporation = corporationsWithNewFields.find(corporation => corporation.id == key)
          if (foundCorporation) {
            foundCorporation.process_amount = processesByParty[key]
          }
        }
      }

      // Add rental contract areas and site links to corporation.
      const rentalAreaByParty = {}
      const siteLinksByParty = {}
      const marketRentByParty = {}
      const capitalRentByParty = {}

      const capitalRentCalculator = new CapitalRentCalculator()

      // Using for-loop for performance
      for (let i = 0, n = rentalStatuses.length; i < n; i++) {
        // Add to rented area for corporation

        const corporation = this.corporationLinker.find({ 
          businessId: rentalStatuses[i].business_id, 
          contractNumber: rentalStatuses[i].contractNumber,
          partyId: rentalStatuses[i].curPartyId
        })
          
        const partyId = corporation?.id


        if (!rentalAreaByParty[partyId]) {
          rentalAreaByParty[partyId] = rentalStatuses[i].area
          if (calculateMarketRent) {
            const marketRent = rentalStatuses[i].market_rent ?? 0
            marketRentByParty[partyId] = rentalStatuses[i].area * marketRent
          }
        } else {
          rentalAreaByParty[partyId] += rentalStatuses[i].area
          if (calculateMarketRent) {
            const marketRent = rentalStatuses[i].market_rent ?? 0
            marketRentByParty[partyId] += rentalStatuses[i].area * marketRent
          }
        }

        if (!siteLinksByParty[rentalStatuses[i].curPartyId]) {
          siteLinksByParty[rentalStatuses[i].curPartyId] = [rentalStatuses[i].siteId]
        } else if (!siteLinksByParty[rentalStatuses[i].curPartyId].includes(rentalStatuses[i].siteId)) {
          siteLinksByParty[rentalStatuses[i].curPartyId].push(rentalStatuses[i].siteId)
        }

        // Calculating yearly capital rent
        if (capitalRentByParty[partyId]) {
          capitalRentByParty[partyId] += capitalRentCalculator.calculate(rentalStatuses[i]) * 12
        } else {
          capitalRentByParty[partyId] = capitalRentCalculator.calculate(rentalStatuses[i]) * 12
        }
      }

      corporationsWithNewFields = corporationsWithNewFields.map(corpo => {

        const { id } = corpo

        return {
          ...corpo,
          id_corporation: id,
          valid_contracts_area: rentalAreaByParty[id] ?? 0,
          current_sites: siteLinksByParty[id] ? siteLinksByParty[id].length : 0,
          valid_contracts_market_rent: marketRentByParty[id] ?? 0,
          capital_rent: capitalRentByParty[id] ?? 0
        }

      })

      // Filter out corporations based on site selection if any site filters are active
      if (sites != null && this.siteFiltersByTypes('all').length > 0) {
        return corporationsWithNewFields.filter(corporation => Object.keys(siteLinksByParty).map(item => parseInt(item)).includes(corporation.id))
      }

      return corporationsWithNewFields
    },

    getThemeColor (index) {
      return `hsl(${204 + 42 * index}, 82%, 72%)`
    },
    openBillsProgressData (quarterlyOpenBills) {
      const data = quarterlyOpenBills.map((quarterValues, i) => {

        const {
          quarter,
          start_date: startDate,
          end_date: endDate,
          open_bills: openBills
        } = quarterValues

        const end = new Date(endDate)
        const start = new Date(startDate)

        let dateString = `Q${quarter} ${end.getFullYear()}`

        if ((i + 1) === quarterlyOpenBills.length) {
          dateString = moment(endDate).format('D.M.YYYY')
        }

        const billsObject = openBills.reduce((acc, cur) => {

          const { open_bills: openBills, tenant_category: tenantCategory } = cur

          if (!tenantCategory || tenantCategory === '') {
            acc.Unknown = openBills
          } else {
            acc[tenantCategory] = openBills
          }

          return acc
        }, {})

        return {
          quarter: dateString,
          startDate: start,
          endDate: end,
          ...billsObject
        }
      })

      return data
    },
    openBillsProgressLineData (quarterlyOpenBills) {

      const data = quarterlyOpenBills.map((quarterValues, i) => {

        const {
          quarter,
          end_date: endDate,
          percent_of_capital: percentOfCapital
        } = quarterValues

        const end = new Date(endDate)

        let dateString = `Q${quarter} ${end.getFullYear()}`

        if ((i + 1) === quarterlyOpenBills.length) {
          dateString = moment(endDate).format('D.M.YYYY')
        }

        return {
          quarter: dateString,
          openBills: 100 * percentOfCapital
        }
      })

      return data
    }
  }
}
</script>
<style scoped>
.widget {
  padding-top: 0;
}
.type-select {
  width: 500px;
}
</style>
