<template>
  <td class="column vertical-align">
    <template v-if="field.value === 'unit_name'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="field.value === 'usage'">
      <v-autocomplete
        v-model="model"
        :items="getNullableValues(unitUsages)"
        item-text="label"
        item-value="id"
        hide-details
      />
    </template>

    <template v-else-if="field.value === 'not_rentable_info'">
      <v-textarea
        v-model="model"
        hide-details
        rows="3"
        class="mb-0"
      />
    </template>

    <!-- <template v-else-if="field.value === 'contract_number'">
      <v-text-field
        clearable
        type="text"
        :placeholder="getPlaceholderValue(placeholder)"
        v-model="model"
      />
    </template>-->

    <!-- <template v-else-if="field.value === 'contract_area'">
      <v-text-field
        clearable
        type="text"
        :placeholder="getPlaceholderValue(placeholder)"
        v-model="model"
      />
    </template>-->

    <template v-else-if="field.value === 'not_rentable'">
      <v-select
        v-model="model"
        :items="unitRentStates"
        item-text="label"
        item-value="id"
      />
    </template>

    <template v-else-if="field.value === 'alv'">
      <v-combobox
        v-model="model"
        clearable
        :items="vatStatuses"
        item-text="label"
        item-value="value"
        :placeholder="getVATStatus(placeholder)"
      />
    </template>

    <template v-else-if="field.value === 'market_rent'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
      />
    </template>

    <template v-else-if="field.value === 'upkeep_rent'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
      />
    </template>

    <template v-else-if="field.value === 'marketing'">
      <v-select
        v-model="model"
        :items="onlineMarketingStatuses"
        item-text="label"
        item-value="value"
        :placeholder="getOnlineMarketingStatus(placeholder)"
      />
    </template>

    <template v-else-if="field.value === 'unit_code'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>

    <template v-else-if="field.value === 'description'">
      <v-row>
        <v-menu
          :close-on-content-click="false"
          class="unit-note-area"
        >
          <v-btn
            slot="activator"
            text
            outlined
            class="v-btn--outline unit-note-btn"
          >
            <v-tooltip
              bottom
              max-width="400"
            >
              <template #activator="{ on }">
                <div
                  class="text-truncate"
                  style="max-width: 150px; color: black; font-weight: normal;"
                  v-on="on"
                >
                  {{ model && model.length > 10 ? getPlaceholderValue(model).slice(0,10)+'...' : getPlaceholderValue(model) }}
                </div>
              </template>

              <span>{{ getPlaceholderValue(model) }}</span>
            </v-tooltip>
          </v-btn>
          <v-textarea
            v-model="model"
            name="unit-note-input"
            class="unit-note-area"
            append-outer-icon="edit"
            auto-grow
            outlined
            type="text"
          />
        </v-menu>
      </v-row>
    </template>

    <template
      v-else-if="field.value === 'contract_start_date'"
    >
      {{ placeholder ? getHumanreadableDate(placeholder) : '-' }}
    </template>
    <template
      v-else-if="field.value === 'contract_end_date'"
    >
      {{ placeholder ? getHumanreadableDate(placeholder) : '-' }}
    </template>
    <template v-else-if="field.value === 'apartment'">
      <v-select
        v-model="model"
        :items="getNullableValues(unitApartments)"
        item-text="label"
        item-value="id"
        :placeholder="definitionLabelById(placeholder)"
      />
    </template>
    <template v-else-if="field.value === 'kitchen'">
      <v-select
        v-model="model"
        :items="getNullableValues(unitKitchens)"
        item-text="label"
        item-value="id"
        :placeholder="definitionLabelById(placeholder)"
      />
    </template>
    <template v-else-if="field.value === 'sauna'">
      <v-select
        v-model="model"
        :items="booleanItems"
        item-text="label"
        item-value="value"
        :placeholder="placeholder ? $t('Boolean true') : $t('Boolean false')"
      />
    </template>
    <template v-else-if="field.value === 'balcony'">
      <v-select
        v-model="model"
        :items="unitBalconies"
        item-text="label"
        item-value="id"
        :placeholder="definitionLabelById(placeholder)"
      />
    </template>
    <template v-else-if="field.value === 'additional_info_1'">
      <v-select
        v-model="model"
        :items="getNullableValues(unitAdditionalInfos)"
        item-text="label"
        item-value="id"
        :placeholder="definitionLabelById(placeholder)"
      />
    </template>
    <template v-else-if="field.value === 'additional_info_2'">
      <v-select
        v-model="model"
        :items="getNullableValues(unitAdditionalInfos)"
        item-text="label"
        item-value="id"
        :placeholder="definitionLabelById(placeholder)"
      />
    </template>
    <template v-else-if="field.value === 'clarified_ti_expense'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
      />
    </template>
    <template v-else-if="field.value === 'unit_identifier'">
      <v-text-field
        v-model.number="model"
        item-text="label"
        item-value="value"
      />
    </template>
    <template v-else-if="field.value === 'empty_space_contract_area'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
      />
    </template>
    <template v-else-if="field.value === 'area'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
        :rules="[requiredIfEmpty, requiredIfZero]"
      />
    </template>
    <template v-else-if="field.value === 'target_goal'">
      <v-select
        v-model="model"
        :items="getNullableValues(targetGoal)"
        item-text="label"
        item-value="id"
      />
    </template>
    <template v-else-if="field.value === 'permission_validity_date'">
      <DatePicker
        :initial-date="model"
        class="date-picker"
        @dateupdated="permissionValidityDateUpdated"
      />
    </template>
    <!-- TODO Working unit start- and end date modifications -->
    <!-- <template v-else-if="field.value === 'start_date'">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="model"
            :placeholder="placeholder ? getHumanreadableDate(placeholder) : '-' "
            readonly
            clearable
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="model"
          no-title
          scrollable
          first-day-of-week="1"
          locale="fi"
        />
      </v-menu>
    </template>
    <template v-else-if="field.value === 'end_date'">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="model"
            :placeholder="placeholder ? getHumanreadableDate(placeholder) : '-' "
            readonly
            clearable
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="model"
          no-title
          scrollable
          first-day-of-week="1"
          locale="fi"
        />
      </v-menu>
    </template> -->
    <!-- below current static end- and start date fields -->
    <template
      v-else-if="field.value === 'version_one_start_date'"
    >
      {{ placeholder ? getHumanreadableDate(placeholder) : '-' }}
    </template>
    <template
      v-else-if="field.value === 'end_date'"
    >
      {{ placeholder ? getHumanreadableDate(placeholder) : '-' }}
    </template>
    <template v-else-if="field.value === 'permission_definition'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="field.value === 'min_stocknumber'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
        @input="($val) => (model = $val || null)"
      />
    </template>
    <template v-else-if="field.value === 'max_stocknumber'">
      <v-text-field
        v-model.number="model"
        clearable
        type="number"
        @input="($val) => (model = $val || null)"
      />
    </template>
    <template v-else-if="field.value === 'description'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>
    <template v-else-if="field.value === 'tags'">
      <v-combobox
        v-model="model"
        :items="tags"
        clearable
        multiple
        small-chips
        deletable-chips
        item-text="name"
        item-value="id"
        hide-details=""
      />
    </template>
    <template v-else-if="field.value === 'additional_info'">
      <v-textarea
        v-model="model"
        hide-details
        rows="3"
        class="mb-0"
      />
    </template>
    <template v-else>
      {{ field.value }}
    </template>
  </td>
</template>

<script>
import { mapGetters } from 'vuex'
import humanize from '../../helpers/humanize'
import moment from 'moment'
import DatePicker from '../../components/general/DatePicker.vue'

export default {
  name: 'UnitModifyField',
  components: {
    DatePicker,
  },
  props: {
    value: null,
    field: Object,
    placeholder: null,
    unitUsages: Array,
    unitRentStates: Array,
    unitApartments: Array,
    unitKitchens: Array,
    unitBalconies: Array,
    unitAdditionalInfos: Array,
    targetGoal: Array,
    tags: {
      type: Array,
      default: () => [],
    },
    data: null,
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById', 'definitionsByGroupLabel']),
    model: {
      get () {
        return this.data
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    vatStatuses () {
      const vatStatuses = [
        { value: false, label: this.$t('Not VAT responsible') },
        { value: true, label: this.$t('Yes, VAT responsible') },
        { value: undefined, label: this.$t('Undefined') }
      ]
      return vatStatuses
    },
    onlineMarketingStatuses () {
      return [
        { value: 0, label: this.$t('Allowed') },
        { value: 1, label: this.$t('Disallowed') },
        { value: 2, label: this.$t('Disallowed for fixed period') }
      ]
    },
    booleanItems () {
      return [
        {
          value: 1,
          label: this.$t('Boolean true')
        },
        {
          value: 0,
          label: this.$t('Boolean false')
        }/*,
        {
          value: undefined,
          label: this.$t('Undefined')
        }*/
      ]
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.model = this.value
      }
    },
    data: {
      deep: true,
      immediate: true,
      handler () {
        this.model = this.data
      }
    }
  },
  methods: {
    requiredIfEmpty (value) {
      return value == '' ? this.$t('Required') : true;
    },
    requiredIfZero (value) {
      return value <= 0 ? this.$t('Value must be positive') : true;
    },
    getPlaceholderValue (value) {
      if (value === null || value === undefined || value.length === 0) {
        return '-'
      }
      if (this.field.placeholderProperty && Array.isArray(value)) {
        return value.map(v => v[this.field.placeholderProperty]).join(', ')
      }
      return String(value)
    },
    getDefinitionLabel (label) {
      return this.$t(label)
    },
    getVATStatus (status) {
      let vatStatus
      status === true
        ? (vatStatus = this.$t('Yes, VAT responsible'))
        : status === false
          ? (vatStatus = this.$t('Not VAT responsible'))
          : (vatStatus = this.$t('Undefined'))

      return vatStatus
    },
    getOnlineMarketingStatus (status) {
      if (status === 0) {
        return this.$t('Allowed')
      } else if (status === 1) {
        return this.$t('Disallowed')
      } else if (status === 2) {
        return this.$t('Disallowed for fixed period')
      } else {
        return '-'
      }
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'date')
    },
    getNullableValues (values) {
      var result = [{label: this.$t('Undefined'), id: null}]
      result.push(...values)
      
      return result
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    permissionValidityDateUpdated (val) {
      if(val === undefined){
        this.model = null
      }else {
        this.model = val
      }
    }
  }
}
</script>

<style scoped>
.costcenter-add {
  padding-top: 1em;
  color: var(--c-color-accent);
}
.unit-note-btn {
  width: 100%;
  color: var(--c-color-accent);
  padding: 1.6px 16px 0px;
}
.unit-note-area {
  background-color: white;
  justify-content: flex-end;
  align-content: flex-end;
}
.vertical-align {
  vertical-align: top !important;
}
.date-picker >>> .form-field {
  margin-top: var(--r-spacing-xxs) !important;
}
</style>
