<template>
  <v-col>
    <v-container v-if="task.id">
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('Acknowledgement') }}
          </h4>
        </v-col>
      </v-row>

      <v-checkbox
        v-model="confirmation.finished"
        :label="$t('Task Acknowledged')"
        :disabled="taskFinished"
        @change="$emit('confirmation', confirmation)"
      />

      <v-row
        class="section fill-height"
      >
        <v-col cols="12">
          <v-text-field
            v-model="confirmation.finishedTime"
            :readonly="taskFinished || !confirmation.finished"
            :label="$t('Acknowledged year')"
            :required="confirmation.finished === true"
            :rules="[rules.finishedTime]"
            @change="$emit('confirmation', confirmation)"
          />
        </v-col>
        <v-col cols="12">
          <CurrencyField
            :readonly="!confirmation.finished"
            :label="$t('Actual costs')"
            :currency="currency"
            :value="confirmation.actualCosts"
            @change="actualCostsChanged"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="confirmation.feedback"
            :readonly="!confirmation.finished"
            :label="$t('Feedback note')"
            :required="confirmation.finished === true"
            :rules="[rules.feedbackUser]"
            @change="$emit('confirmation', confirmation)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="confirmation.finished"
            readonly
            :label="$t('UserId')"
            :value="showUserName"
          />
        </v-col>
        <v-spacer />
        <v-divider />
        <v-spacer />
      </v-row>
    </v-container>
  </v-col>
</template>
<script>
import { mapState } from 'vuex'
import CurrencyField from '../CurrencyField'
export default {
  name: 'TaskConfirmation',
  components: {
    CurrencyField,
  },
  props: {
    task: {
      type: Object,
       default : () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      confirmation: {
        finished: this.task.finished,
        finishedTime: this.task.finishedTime
          ? new Date(this.task.finishedTime).getFullYear()
          : undefined,
        actualCosts: this.task.actualCosts,
        feedback: this.task.feedback,
        feedbackUser: undefined
      },
      taskFinished: false
    }
  },
  computed: {
    ...mapState('app', ['userInfo']),
    showUserName: function () {
      return this.confirmation.feedbackSubmitter ? this.confirmation.feedbackSubmitter : this.userInfo.name
    }
  },
  watch: {
    'confirmation.finished': function () {
      if (!this.confirmation.finished) {
        this.confirmation.finishedTime = undefined
        this.confirmation.actualCosts = undefined
        this.confirmation.feedback = undefined
      }
    }
  },
  mounted () {
    this.confirmation.feedbackUser = this.task.feedbackUser ? this.task.feedbackUser : this.userInfo.id
    this.taskFinished = this.task.finished
  },
  methods: {
    actualCostsChanged (value) {
      this.confirmation.actualCosts = value
      this.$emit('confirmation', this.confirmation)
    }
  },
}
</script>
<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>
