var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{on:{"cancel":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Edit tenants'))+" ")]},proxy:true},{key:"content",fn:function(){return [(_vm.carparks)?_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-row',{staticClass:"flex-column",attrs:{"justify":"space-around"}},[_c('h3',{staticClass:"text-body font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Start date for the changes'))+" ")])]),_c('v-row',[_c('v-text-field',{attrs:{"type":"date"},on:{"change":function($event){return _vm.checkDate()}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('br'),(_vm.getCarparksOfType('Single').length > 0)?[_c('h2',{staticClass:"text-body font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Single'))+" ")]),_c('table',{staticClass:"v-datatable v-data-table v-table them--light table-overflow"},[_c('thead',[_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Car park number'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Tenant and parking spots'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Reserved / spots total'))+" ")])]),_c('tbody',_vm._l((_vm.getCarparksOfType('Single')),function(carpark){return _c('tr',{key:carpark.id_carspace},[_c('th',[_vm._v(_vm._s(carpark.nro))]),_c('td',[_c('v-row',{attrs:{"align":"space-between","justify":"end"}},[_c('v-row',_vm._l((_vm.tenantsForCarpark[
                        carpark.id_carspace
                      ]),function(tenant){return _c('v-row',{key:tenant.id},[_c('v-autocomplete',{staticStyle:{"width":"20em"},attrs:{"items":_vm.parties,"item-text":"name","item-value":"id"},model:{value:(tenant.idTenant),callback:function ($$v) {_vm.$set(tenant, "idTenant", $$v)},expression:"tenant.idTenant"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"5em"},attrs:{"readonly":"","type":"number","placeholder":'-'},model:{value:(tenant.amount),callback:function ($$v) {_vm.$set(tenant, "amount", $$v)},expression:"tenant.amount"}}),_c('v-btn',{staticClass:"row-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.clearTenantRowForCarpark(
                            carpark.id_carspace,
                            tenant.id
                          )}}},[_c('v-icon',[_vm._v("delete")])],1)],1)}),1),_c('v-row',[_c('v-btn',{class:_vm.tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon',attrs:{"disabled":_vm.tenantsForCarpark[carpark.id_carspace].length !== 0,"icon":""},on:{"click":function($event){return _vm.addTenantRowForCarpark(carpark.id_carspace)}}},[_c('v-icon',[_vm._v(" add ")])],1)],1)],1)],1),_c('td',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"sum-column grow flex-column"},[_vm._v(" "+_vm._s(_vm.amountAssignedForCarpark(carpark.id_carspace))+" / "+_vm._s(carpark.amount)+" ")]),(
                      _vm.amountAssignedForCarpark(carpark.id_carspace) >
                        carpark.amount
                    )?_c('v-row',{staticClass:"shrink flex-column"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" error ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Maximum number of carparks exceeded')))])])],1):_vm._e()],1)],1)])}),0)])]:_vm._e(),(_vm.getCarparksOfType('AreaRent').length > 0)?[_c('h2',{staticClass:"text-body font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('AreaRent'))+" ")]),_c('table',{staticClass:"v-datatable v-data-table v-table them--light table-overflow"},[_c('thead',[_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Car park number'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Tenant and parking spots'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Reserved / spots total'))+" ")])]),_c('tbody',_vm._l((_vm.getCarparksOfType('AreaRent')),function(carpark){return _c('tr',{key:carpark.id_carspace},[_c('th',[_vm._v(_vm._s(carpark.nro))]),_c('td',[_c('v-row',{attrs:{"align":"space-between","justify":"end"}},[_c('v-row',_vm._l((_vm.tenantsForCarpark[
                        carpark.id_carspace
                      ]),function(tenant){return _c('v-row',{key:tenant.id},[_c('v-autocomplete',{staticStyle:{"width":"20em"},attrs:{"items":_vm.parties,"item-text":"name","item-value":"id"},model:{value:(tenant.idTenant),callback:function ($$v) {_vm.$set(tenant, "idTenant", $$v)},expression:"tenant.idTenant"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"5em"},attrs:{"type":"number","placeholder":'-'},model:{value:(tenant.amount),callback:function ($$v) {_vm.$set(tenant, "amount", $$v)},expression:"tenant.amount"}}),_c('v-btn',{staticClass:"row-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.clearTenantRowForCarpark(
                            carpark.id_carspace,
                            tenant.id
                          )}}},[_c('v-icon',[_vm._v("delete")])],1)],1)}),1),_c('v-row',[_c('v-btn',{class:_vm.tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon',attrs:{"icon":""},on:{"click":function($event){return _vm.addTenantRowForCarpark(carpark.id_carspace)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)],1),_c('td',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"sum-column grow flex-column"},[_vm._v(" "+_vm._s(_vm.amountAssignedForCarpark(carpark.id_carspace))+" / "+_vm._s(carpark.amount)+" ")]),(
                      _vm.amountAssignedForCarpark(carpark.id_carspace) >
                        carpark.amount
                    )?_c('v-row',{staticClass:"shrink flex-column"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" error ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Maximum number of carparks exceeded')))])])],1):_vm._e()],1)],1)])}),0)])]:_vm._e(),(_vm.getCarparksOfType('Privilege').length > 0)?[_c('h2',{staticClass:"text-body font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Privilege'))+" ")]),_c('table',{staticClass:"v-datatable v-data-table v-table them--light table-overflow"},[_c('thead',[_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Car park number'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Tenant and parking privileges'))+" ")]),_c('th',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.$t('Reserved / privileges total'))+" ")])]),_c('tbody',_vm._l((_vm.getCarparksOfType('Privilege')),function(carpark){return _c('tr',{key:carpark.id_carspace},[_c('th',[_vm._v(_vm._s(carpark.nro))]),_c('td',[_c('v-row',{attrs:{"align":"space-between","justify":"end"}},[_c('v-row',_vm._l((_vm.tenantsForCarpark[
                        carpark.id_carspace
                      ]),function(tenant){return _c('v-row',{key:tenant.id},[_c('v-autocomplete',{staticStyle:{"width":"20em"},attrs:{"items":_vm.parties,"item-text":"name","item-value":"id"},model:{value:(tenant.idTenant),callback:function ($$v) {_vm.$set(tenant, "idTenant", $$v)},expression:"tenant.idTenant"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"5em"},attrs:{"type":"number","placeholder":'-'},model:{value:(tenant.amount),callback:function ($$v) {_vm.$set(tenant, "amount", $$v)},expression:"tenant.amount"}}),_c('v-btn',{staticClass:"row-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.clearTenantRowForCarpark(
                            carpark.id_carspace,
                            tenant.id
                          )}}},[_c('v-icon',[_vm._v("delete")])],1)],1)}),1),_c('v-row',[_c('v-btn',{class:_vm.tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon',attrs:{"icon":""},on:{"click":function($event){return _vm.addTenantRowForCarpark(carpark.id_carspace)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)],1),_c('td',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"sum-column grow flex-column"},[_vm._v(" "+_vm._s(_vm.amountAssignedForCarpark(carpark.id_carspace))+" / "+_vm._s(carpark.max_parking_rights)+" ")]),(
                      _vm.amountAssignedForCarpark(carpark.id_carspace) >
                        carpark.max_parking_rights
                    )?_c('v-row',{staticClass:"shrink flex-column"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" error ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Maximum number of carparks exceeded')))])])],1):_vm._e()],1)],1)])}),0)])]:_vm._e()],2):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"disabled":!_vm.allowSaving || _vm.isSaving,"rounded":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),(_vm.isSaving)?_c('v-progress-circular',{attrs:{"size":"16","indeterminate":"","color":"primary"}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }