<template>
  <div style="position: relative">
    <v-menu
      v-if="data.sets? data.sets.length > 1 : false"
      left
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          small
          absolute
          right
          icon
          style="background: rgba(255, 255, 255, 0.8) !important"
          :aria-label="`${$t('MenuChooseCriterion')}: ${title}`"
          v-on="on"
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="set in sets"
          :key="set.title"
          :class="{ 'active': currentSet === set}"
          @click="selectSet(set)"
        >
          <v-list-item-title>{{ set.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <GradeAndStarRating 
      v-if="data.starRating && data.items.length && data.items[0].part_overall_score && data.items[0].part_star_rating"
      :source-data="data.items[0]"
    />
    <HorizontalBarChart
      v-if="GraphData !== null && data.items.length !== 0"
      :data="GraphData"
      :options="barOptions"
      :chart-id="chartId"
      class="graph"
      style="height: 200px"
    />
    <InformationFieldWithStatusIcon
      v-if="data.items.length && data.items[0].certification_version"
      :source-data="data.items[0].certification_version"
    />
    <InformationFieldWithStatusIcon
      v-if="data.items.length && data.items[0].certification_date"
      :source-data="data.items[0].certification_date"
    />
    <InformationFieldWithStatusIcon
      v-if="data.items.length && data.items[0].certification_expires"
      :source-data="data.items[0].certification_expires"
    />
    <BreeAmCertificationAdCard 
      v-if="!data.items.length && data.report === 'SiteBreeamInUsePart1Certifications' || !data.items.length && data.report === 'SiteBreeamInUsePart2Certifications'"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import uuid from '../../helpers/uuid'
import HorizontalBarChart from '../HorizontalBarChart'
import GradeAndStarRating from '../general/GradeAndStarRating'
import InformationFieldWithStatusIcon from '../general/InformationFieldWithStatusIcon'
import BreeAmCertificationAdCard from '../BreeAmCertificationAdCard'
export default {
  components: {
    HorizontalBarChart: HorizontalBarChart,
    GradeAndStarRating,
    InformationFieldWithStatusIcon,
    BreeAmCertificationAdCard
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentSet: null,
      ariaLabel: '',
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          display: false,
          labels: {
            fontColor: '#273943'
          }
        },
        tooltips: {
          callbacks: {
            // Only round the decimals if the data has any decimals
            label: function (tooltipItem, data) {
              if (
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] %
                  1 !==
                0
              ) {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ],1
                  )
                )
              } else {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ]
                  )
                )
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: 'white'
              },
              ticks: {
                fontColor: '#273943',
                fontSize: 10,
                callback: function (value, index, values) { 
                  return helpers.humanize.thousand_separator(value)
                }
              },
              stacked: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: 'white',
                zeroLineColor: 'white'
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#273943'
              },
              stacked: true
            }
          ]
        }
      }
    }
  },
  computed: {
    getUniqueId: function () {
      return uuid.v4()
    },
    sets: function () {
      // Check for sets
      if (typeof this.data.sets === 'undefined' || this.data.sets.length === 0) {
        return []
      }
      return this.data.sets.filter(set => {
        const param = set.parameters.length > 0 ? set.parameters[0] : null
        // count is an exception as it does not require parameters
        if (param === null && set.method === 'count') {
          return true
        }

        const data = this.data.items.length > 0 ? this.data.items[0] : null

        if (data === null || typeof data[param] === 'undefined') {
          return false
        }
        return true
      })
    },
    objects: function () {
      if (Array.isArray(this.data.items)) {
        if (this.currentSet !== null && this.currentSet.method !== 'Breeam In-Use Certification') {
          // If set is defined we do the following:
          // 1. First, we group the data by using groupBy field
          // 2. Now the grouped data contains an object in which keys are values found using the groupBy field
          // 3. For each group, data is aggregated using the provided 'method'. It can be 'count' or 'sum' at this point
          // 3.1. Aggregation uses the 'method' with 'parameters'. The values are extracted using the parameter fields into tuples
          // 3.2. The current method 'count' is basically the length of the parameter array
          // 3.3. The current method 'sum' is just summing the array values together. Each tuple contains only one value.
          // 4. Now we have an object where each grouped value has the result and can be fed to the graph

          const groupedData = this.data.items.reduce((acc, cur) => {
            // group
            const key = cur[this.currentSet.groupBy]
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)

            return acc
          }, {})
          const items = Object.keys(groupedData).reduce((acc, key) => {
            const dataSet = groupedData[key]
            const paramValues = dataSet.map(d => {
              const values = []
              this.currentSet.parameters.forEach(param => {
                values.push(d[param])
              })
              return values
            })
            const method = this.currentSet.method

            if (method === 'count') {
              acc[key] = paramValues.length
            } else if (method === 'sum') {
              acc[key] = paramValues.reduce((acc, cur) => acc + cur[0], 0)
            }

            return acc
          }, {})

          return this.getLargestValues(items)
        } else if (this.currentSet !== null && this.currentSet.method === 'Breeam In-Use Certification') {
          if(!this.data.items.length) {
            return {}
          }
          const certificationVersion = this.data.items[0] ? this.data.items[0].certification_version.value : null
          const dataSet = helpers.breeamInUseCertification.getDataSetByVersion(this.currentSet.part, certificationVersion)
          if(dataSet) {
          // Create empty array for summary data based to parameters given in widgets dataSet
          const emptyResArray = dataSet.reduce((total, classification) => {
            total[classification.category] = 0
            return total
            }, [])
          // Summarize dataset values for different items (sites)
          const items = this.data.items.reduce((total, currentItem) => {
            dataSet.map(param => {
              total[param.category] = currentItem[param.category]
            })
            return total  
          }, emptyResArray)
          return items
          } else {
            return {}
          } 
        } else {
          // No sets defined. Using the default approach (counting)
          const result = this.data.items.reduce((acc, cur) => {
            const dataField = this.data.field
            if (cur[dataField] === null) {
              return acc
            }
            if (typeof acc[cur[dataField]] === 'undefined') {
              acc[cur[dataField]] = 0
            }
            acc[cur[dataField]] += 1
            return acc
          }, {})

          return this.getLargestValues(result)
        }
      } else if (this.data.items === null || this.data.items === undefined) {
        return {}
      } else {
        return this.data.items
      }
    },
    GraphData: function () {
      const objectKeysArray = [...Object.keys(this.objects)]
      return {
        labels: [...Object.keys(this.objects).filter(o => {
          if (this.objects[o] > 0) {
            return o
          }
        })],
        datasets: [
          {
            label: this.data.label,
            backgroundColor: this.getThemeColorArray(objectKeysArray.length),
            borderColor: 'white',
            data: [
              ...Object.keys(this.objects).filter(o => {
                if (this.objects[o] > 0) {
                  return o
                }
              }).map(
                key => this.objects[key] /* .count */
              )
            ]
          }
        ]
      }
    },
    chartId: function () {
      return `horizontalbar-chart-${this.getUniqueId}`
    },
  },
  watch: {
    GraphData: function () {
      this.updateAriaLabel()
    },
  },
  mounted: function () {
    if (typeof this.data.sets !== 'undefined') {
      this.selectSet(this.data.sets[0])
    }
    // showProgress property is to show percentual progress in HorizontalBarDataGraph
    if (this.data.showProgress) {
      // Set the x axes max scale to 100
      this.barOptions.scales.xAxes[0].ticks.max = 100
    }
  },
  methods: {
    updateAriaLabel () {
      // Graph data for screen readers
      this.ariaLabel = this.GraphData.labels.map((i, d) => (
        ` ${i} ${helpers.humanize.thousand_separator(this.GraphData.datasets[0].data[d])}`
      ))
      const canvas = document.getElementById(this.chartId)
      canvas.setAttribute("role", "img")
      canvas.setAttribute("aria-label", this.ariaLabel)
    },
    selectSet (set) {
      this.currentSet = set
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${204 + 45 * index}, 82%, 72%)`
    },
    getLargestValues (object, limit = 13) {
      // Sort the entries by value from highest to lowest...
      let sortedEntries = Object.entries(object).sort((a, b) => b[1] - a[1]);
      // ...then slice it based on given or default limit parameter (default is 13).
      // Determines how many values we showcase in bargraph
      sortedEntries = sortedEntries.slice(0, limit)
      // Lastly, turn the sliced and sorted array into an object
      const result = Object.fromEntries(sortedEntries)
      return result
    },
    getFieldName: function (field) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)
      return field
    },
  }
}
</script>

<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>
