import { i18n } from '../../plugins/i18n'

export default function unitsWithoutFloorPlan () {
  return {
    id: 'site.units_without_dwg',
    type: 'MetaDataTable',
    data: {
      headers: [
        { text: i18n.t('Building'), value: 'building_name', alwaysVisible: true },
        { text: i18n.t('Floor'), value: 'floor_name', alwaysVisible: true },
        { text: i18n.t('Unit code'), value: 'unit_code' },
        { text: i18n.t('Unit use'), value: 'usage' },
        { text: i18n.t('Apartment type'), value: 'apartment', isDefinition: true },
        { text: i18n.t('Unit rental status'), value: 'not_rentable', format: 'Translation' },
        { text: i18n.t('Not rentable info'), value: 'not_rentable_info', textOverflow: true },
        { text: i18n.t('Target goal'), value: 'target_goal', isDefinition: true },
        { text: i18n.t('Net floor area'), value: 'area', format: 'Area' },
        { text: i18n.t('Rented unit area'), value: 'rented_unit_area', format: 'Area' },
        { text: i18n.t('Free unit area'), value: 'free_unit_area', format: 'Area' },
        { text: i18n.t('Empty space contract area'), value: 'empty_space_contract_area', format: 'Area' },
        { text: i18n.t('Tenant'), value: 'contract_tenant' },
        { text: i18n.t('Category'), value: 'tags', type: 'chipField', keepDataStructure: true },
        { text: i18n.t('Unit description'), value: 'description' },
        { text: i18n.t('Additional info'), value: 'additional_info', hideOverflow: true },
        { text: i18n.t('Permission validity'), value: 'permission_validity_date', format: "Date"},
        { text: i18n.t('Permission definition'), value: 'permission_definition', hideOverflow: true },
        { text: i18n.t('Min stocknumber'), value: 'min_stocknumber', format: 'Number' },
        { text: i18n.t('Max stocknumber'), value: 'max_stocknumber', format: 'Number' },
        { text: i18n.t('Unit start date'), value: 'version_one_start_date', format: "Date", alwaysVisible: true },
        { text: i18n.t('Unit version end'), value: 'end_date', format: "Date", alwaysVisible: true }
      ],
      footers: [
        { value: 'area', format: 'Area', headerValue: 'area' },
        { value: 'empty_space_contract_area', format: 'Area', headerValue: 'empty_space_contract_area' },
      ],
      items: [],
      source: 'unitsForCurrentSiteWithoutSpace',
      descending: true,
      filterByCostcenter: true,
      button: 'addUnit',
      alwaysShowEdit: true,
      shouldGenerateFooters: true,
    },
    action: 'openUnitModifyDialog',
    loading: true,
    maximized: [false]
  }
}


