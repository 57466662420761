<template>
  <div style="padding: 0.5em">
    <v-expansion-panels
      :value="[0]"
      multiple
    >
      <v-expansion-panel
        expand
        class="no-padding"
      >
        <v-expansion-panel-header>
          {{ data.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <v-data-table
            v-else
            :header-props="dataTableHeaderDefinitions"
            :headers="data.headers"
            :items="data.items"
            class="elevation-0"
            mobile-breakpoint="0"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: [],
            }"
            :no-data-text="$t('No data available')"
          >
            <template
              slot="item"
              slot-scope="props"
            >
              <tr>
                <td>
                  <div
                    class="d-flex justify-start"
                  >
                    <v-text-field
                      v-if="edit && !props.item.systemDefault"
                      v-model="names[props.item.id]"
                      :label="$t('Cost type')"
                      :style="{width: '60%'}"
                      append-icon="edit"
                      class="borderless"
                      @change="changeItem(props.item)"
                    />
                    <div v-else>
                      {{ props.item[data.headers[0].value] }}
                    </div>

                    <v-icon
                      v-if="edit && !props.item.systemDefault && props.item.id "
                      class="pa-2"
                      color="red"
                      right
                      absolute
                      @click="deleteRow(props.item)"
                    >
                      delete
                    </v-icon>
                    <span class="d-sr-only">{{ $t('Delete') }}</span>
                  </div>
                </td>
                <td>
                  <v-switch
                    v-if="props.item.systemDefault"
                    v-model="toggledItems[props.item.id]"
                    :readonly="!edit"
                    :disabled="!edit"
                    :label="`${props.item[data.headers[0].value]}`"
                    class="slim without-bg"
                    dense
                    @change="toggleItem(props.item.id)"
                  />
                  <v-switch
                    v-else
                    v-model="selectedItems[props.item.id]"
                    :readonly="!edit"
                    :disabled="!edit"
                    :label="`${props.item[data.headers[0].value]}`"
                    class="slim without-bg"
                    dense
                    @change="changeItem(props.item)"
                  />
                </td>
              </tr>
            </template>
            <template
              slot="footer.page-text"
              slot-scope="item"
            >
              <v-btn
                v-if="edit"
                small
                outlined
                rounded
                @click="addRow"
              >
                {{ $t('Add cost type') }}
              </v-btn>
              {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
              {{ item.itemsLength.toLocaleString('fi-FI') }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row
      justify="end"
      style="right: 0; bottom: 0; position: sticky; z-index: 1"
      no-gutters
    >
      <v-btn
        v-if="saveButtonVisible"
        rounded
        class="primary"
        @click="saveChanges"
      >
        <span v-if="!saving">{{ $t('Save changes') }}</span>
        <v-progress-circular
          v-else
          :size="20"
          :width="3"
          indeterminate
          color="primary"
        />
      </v-btn>&nbsp;
      <Alert
        :show="showIndicator"
        :result="'error'"
        :message="$t('internalrent.expense_has_been_used')"
      />
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '../Alert'
export default {
  name: 'CostTypeTable',
  components: {
    Alert: Alert
    },
  props: {
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        sortDesc: this.data.sortDesc ? this.data.sortDesc : [false],
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 5,
        sortBy: this.data.sortBy ? this.data.sortBy : [],
        totalItems: 0
      },
      names: {},
      selectedItems: {},
      toggledItems: {},
      saving: false,
      changedIds: [],
      newIds: [],
      toggledIds: [],
      defaultName: this.$t('internalrent.name_the_cost'),
      deleteApplied: false,   
      showIndicator: false,
    }
  },
  computed: {
  ...mapGetters('app', ['dataTableHeaderDefinitions']),
    saveButtonVisible: function () {
      if (this.edit && (this.changedIds.length || this.toggledIds.length || this.deleteApplied) && !this.saving ) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    loading: function () {
      if (!this.loading) {
        this.updateSwitchData()
      }
    },
    edit: function () {
      if (!this.edit) {
        this.toggledIds = []
        this.changedIds = []
        this.deleteApplied = false
      }   
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.showIndicator = false
        }, 4000)
      }
    },
  },
  methods: {
    ...mapActions('internalRent', [
      'getExpenses',
      'saveNewExpense',
      'updateExpense',
      'deleteExpense'
    ]),
    updateSwitchData () {
      this.names = {}
      this.selectedItems = {}
      this.toggledItems = {}
      this.data.items.forEach(item => {
        this.names[item.id] = item.name
        if (item.systemDefault) {
          this.toggledItems[item.id] = item.selectable
        }
        else {
          this.selectedItems[item.id] = item.selectable
        }
      })
    },
    async saveChanges () {
      this.saving = true
      const promises = []
      this.toggledIds.forEach(id => {
        const patch = {
          op: 'replace',
          value: this.toggledItems[id],
          path: '/selectable'
        }
        const params = { expenseId: id, data: [patch] }
        promises.push(this.updateExpense(params))
      })

      this.changedIds.forEach(id => {

        const item = this.data.items.find(s => s.id === id)
        if (this.newItemChange(item)) {
          // post new expense and selection
          const expense = {
            name: this.names[item.id],
            tripleNet: item.tripleNet,
            systemDefault: false,
            selectable: item.selectable
          }
          promises.push(this.saveNewExpense(expense))
        } else if (this.updateChange(item) || this.selectChange(item)) {
          // update expense name and selection
          const expense = {
            id: item.id,
            name: this.names[item.id],
            tripleNet: item.tripleNet, 
            systemDefault: false,
            selectable: this.selectedItems[item.id]
          }
          const data = this.$jsonpatch.compare(item, expense)
          const params = { expenseId: item.id, data }
          promises.push(this.updateExpense(params))
        }
      })

      await Promise.all(promises)

      this.$emit('savedUpdate')
      this.saving = false
      this.changedIds = []
      this.newIds = []
    },
    // Deletes a row permanently
    async deleteRow (item) {
      if (!this.newIds.includes(item.id) && this.data.items.filter(i => i.id !== item.id)) {
        if (confirm(this.$t('internalrent.delete_cost_type'))) {

        const result = await this.deleteExpense(item.id)

          if (result !== undefined && result.status === 201) {
            this.showIndicator = true
          }
          else {
            this.data.items = this.data.items.filter(i => i.id !== item.id)
            this.deleteApplied = true
          }
        }
      }
      else {
        this.data.items = this.data.items.filter(i => i.id !== item.id)
        // remove item from changes
        this.changedIds = this.changedIds.filter(id => id !== item.id)
        this.newIds = this.newIds.filter(id => id !== item.id)
      }
    },
    // Adds a new temporary UI row with default data
    addRow () {
      let dataId = this.data.allDataIds.length
      // Checks if id is already in use and adds 1 until existing item with same id is not found
      while (this.data.allDataIds.filter(id => id === dataId).length > 0) {
        dataId++
      }

      this.$emit('pushId', dataId)

      this.data.items.push({
          id: dataId,
          name: this.defaultName,
          selectable: true,
          tripleNet: this.data.title === this.$t('internalrent.triple_net_rent') ? true : false,
          systemDefault: false
        })
      this.selectedItems[dataId] = true
      this.names[dataId] = this.defaultName
      this.newIds.push(dataId)
    },
    // Handles changes on switches and text fields
    changeItem (item) {
      if (this.newItemChange(item)) {
        // new item!!!
        this.addToChanges(item.id)
        if(this.updateChange(item)) item.selectable = this.selectedItems[item.id]
      } else if (!this.newIds.includes(item.id)) {

        if (this.updateChange(item)) {
          // item changed
          this.addToChanges(item.id)
        } else if (this.selectChange(item)) {
          this.addToChanges(item.id)
          // item selected value changed
        } else {
          // remove item from changes
          this.changedIds = this.changedIds.filter(id => id !== item.id)
        }
      }
    },
    toggleItem (id) {
      if (!this.toggledIds.includes(id)) {
        this.toggledIds.push(id)
      }
    },
    addToChanges (id) {
      if (!this.changedIds.includes(id)) {
        this.changedIds.push(id)
      }
    },
    newItemChange (item) {
      return (
        item.name === this.defaultName &&
        this.names[item.id] !== this.defaultName
      )
    },
    selectChange (item) {
      return this.selectedItems[item.id] !== item.selectable &&
        this.names[item.id] !== this.defaultName
    },
    updateChange (item) {
      return (
        item.name !== this.names[item.id] &&
        this.names[item.id] !== this.defaultName
      )
    },
  }  
}
</script>
<style scoped>
.slim {
  margin-top: 0px;
}
</style>
