import RambollFMAPI from './rambollfm'

function install (Vue, options) {
  const api = new RambollFMAPI(options.endpoint, options.token)
  Vue.api = api
  Vue.prototype.$rambollfmapi = api
}

export default {
  install: install
}
