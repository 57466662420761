<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    data: function () {
      this.update()
    }
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.allOptions = {
      ...this.options,
    }

    this.update()
  },
  methods: {
    update () {
      this.allOptions.title.text = this.data.title
      this.renderChart(this.data, this.allOptions)
    }
  }
}
</script>
