<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      <v-container
        v-if="!modifying"
      >
        {{ $t('Create new portfolio') }}
      </v-container>
      <v-container
        v-else
      >
        {{ $t('Modify portfolio') }}
      </v-container>
    </template>
    <template #content>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="portfolioLabel"
          :placeholder="$t('Portfolio name')"
          required
          class="form-field"
        />
      </v-form>
    </template>
    <template #footer>
      <v-btn
        v-if="modifying"
        rounded
        depressed
        color="error"
        @click="confirmDelete"
      >
        {{ $t('Delete') }}
      </v-btn>
      <v-btn
        :disabled="portfolioLabel === null || !portfolioLabel.length > 0 || portfolioLabel === original.label"
        rounded
        depressed
        color="primary"
        @click="modifying ? patch() : save()"
      >
        {{ $t('Save') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseModal from './general/BaseModal'
export default {
  name: 'PortfolioModificationForm',
  components: { BaseModal },
  props: {
    modifying: {
      default () {
        return false
      },
      type: Boolean
    },
    original: {
      default () {
        return {
          label: null,
          id: null
        }
      },
      type: Object
    }
  },
  data () {
    return {
      portfolioLabel: null,
      valid: true,
      deleteConfirmed: false
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName'])
  },
  watch: {
    original: function (o) {
      if (o.label !== null) {
        this.portfolioLabel = o.label
      }
    },
    deleteConfirmed: function (bool) {
      if (bool === true) {
        this.deleteConfirmed = false
        try {
          this.$rambollfmapi.definitions.portfolio().delete(this.original.id)
          this.close('success')
        } catch (error) {
          this.close('error')
        }
      }
    }
  },
  methods: {
    save () {
      if (!this.hasApplicationPermissionByName('SALKKUHALLINTA')) {
        alert(this.$t('Forbidden'))
        this.$router.push({ name: 'dashboard' })
      } else {
        try {
          this.$rambollfmapi.definitions.portfolio().put({ name: this.portfolioLabel })
          this.close('success')
        } catch (error) {
          this.close('error')
        }
      }
    },
    patch () {
      if (!this.hasApplicationPermissionByName('SALKKUHALLINTA')) {
        alert(this.$t('Forbidden'))
        this.$router.push({ name: 'dashboard' })
      } else {
        try {
          this.$rambollfmapi.definitions.portfolio().patch({ name: this.portfolioLabel, id: this.original.id })
          this.close('success')
        } catch (error) {
          this.close('error')
        }
      }
    },
    confirmDelete () {
      this.deleteConfirmed = confirm(this.$t('Confirm portfolio deletion') + '\n' + this.$t('Portfolio\'s sites wont have portfolio anymore'))
    },
    close (status) {
      if (!this.hasApplicationPermissionByName('SALKKUHALLINTA')) {
        alert(this.$t('Forbidden'))
        this.$router.push({ name: 'dashboard' })
      } else {
        this.portfolioLabel = null
        if (status) {
          this.$emit('refresh', status)
        } else {
          this.$emit('close_dialog')
        }
      }
    }
  }
}
</script>
<style>
</style>
