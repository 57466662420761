export function getApartmentsForSites (report) {
  const result = report.reduce((acc, cur) => {
    const key = cur.id_site

    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        totalcount: 0,
        saunacount: 0,

        '1vacant_area': 0,
        '1rented_area': 0,
        '1spacecount': 0,
        '1vacant_count': 0,
        '1rented_count': 0,

        '2vacant_area': 0,
        '2rented_area': 0,
        '2spacecount': 0,
        '2vacant_count': 0,
        '2rented_count': 0,

        '3vacant_area': 0,
        '3rented_area': 0,
        '3spacecount': 0,
        '3vacant_count': 0,
        '3rented_count': 0,

        '4vacant_area': 0,
        '4rented_area': 0,
        '4spacecount': 0,
        '4vacant_count': 0,
        '4rented_count': 0,

        '5vacant_area': 0,
        '5rented_area': 0,
        '5spacecount': 0,
        '5vacant_count': 0,
        '5rented_count': 0,
      }
    }
    const spaceCount = parseInt(cur.type)

    acc[key].site_name = cur.site
    acc[key].id_site = cur.id_site
    acc[key].site_identifier = cur.site_identifier

    acc[key].totalcount += 1

    acc[key][`${spaceCount}spacecount`] += 1
    if (cur.vacancy) {
      acc[key][`${spaceCount}vacant_area`] += cur.area
      acc[key][`${spaceCount}vacant_count`] += 1
    } else {
      acc[key][`${spaceCount}rented_area`] += cur.area
      acc[key][`${spaceCount}rented_count`] += 1
    }

    if (cur.sauna) {
      acc[key].saunacount += 1
    }
    return acc
  }, {})

  return Object.keys(result).map(res => result[res])
}