export class AndFilterController {
  /**
   * Takes in list and filters does intersection filtering on the list based on the addded filters
   * @param {Object[]} list - List of values which are to be filtered
   */
  constructor (list) {
    this.filters = []
    this.list = list
  }

  /**
   * Add new filter to internal filters list
   * @param {FilterClass} filter - Filter class eg. DefaultFilter || PartyFilter
   */
  addFilter (filter) {
    this.filters.push(filter)
  }

  /**
   * Filters the provided list using intersection of provided filters
   * @returns Filtered list
   */
  filter () {
    return this.filters.reduce((acc, filter) => {
      return filter.filter(acc)
    }, this.list)
  }

}