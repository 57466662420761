<template>
  <v-text-field
    v-model="model"
    small
    type="date"
    :label="label"
    :aria-label="ariaLabel"
    :append-icon="appendIcon"
    @input="emitInput"
    @change="emitChange"
  />
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateField',
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      model: null
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler (oldVal, newVal) {
        const date = moment(this.value)
        if (date.isValid) {
          this.model = date.format('YYYY-MM-DD')
        } else {
          this.model = oldVal
        }
      }
    }
  },
  methods: {
    getValueOrNull () {
      const date = moment(this.model, 'YYYY-MM-DD')
      if (date.isValid()) {
        return date.toISOString(true)
      }
      return null
    },
    emitInput () {
      this.$emit('input', this.getValueOrNull())
    },
    emitChange () {
      this.$emit('change', this.getValueOrNull())
    }
  }
}
</script>
<style scoped>
</style>
