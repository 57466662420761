<template>
  <v-container
    fluid
  >
    <v-dialog
      v-if="showBuildingRatingModal"
      v-model="showBuildingRatingModal"
      persistent
      width="1200"
    >
      <CreatePortfolioModal
        v-if="showBuildingRatingModal"
        :initial-rating="initialRating"
        :building-rating-id="ratingIdToEdit"
        :building-rating-classes="buildingRatingClasses"
        @updateWidget="getPageData(selectedBuilding)"
        @close="showBuildingRatingModal = false"
      />
    </v-dialog>
    <h1 class="d-sr-only">{{ $t('buildingRating') }}</h1>
    <BaseView class="mb-4">
      <template #selections>
        <v-select
          v-model="selectedBuilding"
          :items="buildingsForCurrentSite"
          :item-text="(b) => b.building_code + ' — ' + b.building_name"
          return-object
          :loading="!selectedBuilding"
          :disabled="!selectedBuilding"
          :label="$t('Building')"
        />
      </template>
      <template #buttons>
        <CdsButton
          icon-left="add"
          type="accent"
          :disabled="creationDisabled"
          @click="newBuildingRating"
        >
          {{ $t('buildingRating.newBuildingRating') }}
        </CdsButton>
      </template>
    </BaseView>
    <v-row
      v-if="loading || items.length > 0" 
      class="px-4"
    >
      <v-col
        lg="3"
        md="5"
        cols="12"
      >
        <v-expansion-panels
          :value="[ 0 ]"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("buildingRating.summaryTitle") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <BuildingRatingsChart
                :items="items"
                :loading="loading"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col
        lg="9"
        md="7"
        cols="12"
        align-self="start"
      >
        <div
          v-for="widget in widgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            @open="widget.maximized = $event"
          >
            <template
              v-if="widget.id === 'buildingRatings'"
              #customComponent="{ item }"
            >
              <CdsButton
                type="secondary"
                icon-left="edit"
                hide-text
                @click="modifyRating(item)"
              >
                {{ $t('Modify') }}
              </CdsButton>
            </template>
          </DynamicWidget>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="px-4"
    >
      <v-col>
        <div>
          <CdsAlert
            size="large"
            :title="alertTitle"
          >
            <CdsButton
              v-if="!creationDisabled"
              icon-left="add"
              type="accent"
              @click="newBuildingRating"
            >
              {{ $t('buildingRating.newBuildingRating') }}
            </CdsButton>
          </CdsAlert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import BaseView from '../components/general/BaseView.vue'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import CdsAlert from '../components/CircleDesignSystem/CdsAlert.vue'
import CreatePortfolioModal from './CreateBuildingRatings/CreateBuildingRatingModal.vue'
import BuildingRatingsChart from './CreateBuildingRatings/BuildingRatingsChart.vue'
import DynamicWidget from '../components/DynamicWidget'

export default {
  name: 'BuildingRatings',
  components: {
    BaseView,
    CdsAlert,
    CdsButton,
    CreatePortfolioModal,
    DynamicWidget,
    BuildingRatingsChart,
  },
  metaInfo () {
    return {
      title: `${this.$t('buildingRating')} ·`,
    }
  },
  data () {
    return {
      selectedBuilding: null,
      showBuildingRatingModal: false,
      ratingIdToEdit: undefined,
      items: undefined,
      loading: true,
      buildingRatingClasses: [],
      initialRating: {},
      widgets: [
      {
        id: 'buildingRatings',
        name: 'buildingratings',
        type: 'DataTable',
        loading: true,
        data: {
          headers: [
            { text: 'buildingRating.date', value: 'start_date', format: 'Date' },
            { text: 'Approval date', value: 'approval_date', format: 'Date' },
            { text: 'Rating class proposal name', value: 'rating_class_proposal_name', format: 'Translation' },
            { text: 'Rating class', value: 'rating_class_name', format: 'Translation' },
            { text: 'Characteristics', value: 'characteristics', format: 'Double' },
            { text: 'Performance level', value: 'performance_level', format: 'Double' },
            { text: 'Average', value: 'average', format: 'Double' },
            { text: 'Answered questions count', value: 'answered_questions_count' },
            { text: this.$t('Actions'), value: 'customComponent' },
          ],
          customFormatters: {
            answered_questions_count: (row) => {
              return row.answered_questions_count + '/11'
            },
          },
          items: [],
          noBrowse: true,
          sortDesc: true
          }
        },
      ]
    }
  },
  computed: {
    ...mapState('app', [ 'currentDate' ]),
    ...mapGetters('app', ['hasApplicationPermissionByName', 'dataTableHeaderDefinitions']),
    ...mapGetters('sites', ['buildingsForCurrentSite', 'buildingHierarchyInformations']),
    ...mapState('sites', ['currentSiteId', 'sitePermissions']),
    creationDisabled () {
      // Disable creation if the building is too small or the user does not have the required permission
      return this.loading || this.initialRating && this.initialRating.official_gross_area < 50 || !this.hasApplicationPermissionByName('SALKUTUS_OIKEUS')
    },
    alertTitle () {
      if (this.initialRating.official_gross_area < 50) {
        return this.$t('buildingRating.smallBuilding')
      }
      return this.$t('buildingRating.noBuildingRating')
    },
  },
  watch: {
    selectedBuilding: {
      deep: true,
      handler: function (building) {
        if (building) {
          this.getPageData(building)
        }
      },
    },
    buildingsForCurrentSite: function (buildings) {
      if (!buildings || buildings.length == 0) {
        return
      }
      if (!this.selectedBuilding) {
        this.selectedBuilding = buildings[0]
        this.getPageData(this.selectedBuilding)
      }
    },
  },
  async mounted () {
    if (this.buildingsForCurrentSite?.length > 0) {
      this.selectedBuilding = this.buildingsForCurrentSite[0]
    }
  },
  methods: {
    async getPageData (building) {
      const result = await this.$rambollfmapi.buildingRating.getBuildingRatingClasses()

      this.initialRating = await this.$rambollfmapi.buildingRating.getInitialData(building.id_building)

      this.buildingRatingClasses = result.filter(r => r.id >= 0).map((item) => {
        return {
          ...item,
          name: this.$t(item.name),
        }
      })
      this.loadWidgets(building.id_building, 'buildingRatings')
    },
    async loadWidgets (idBuilding, widgetId = null) {
      this.widgets.forEach(widget => {
        if (widgetId != null && widgetId !== widget.id) {
          return
        }
        if (this.selectedBuilding) {
          widget.loading = true
          this.loading = true
          this.$rambollfmapi.reports.post(widget.name, [ idBuilding ], this.currentDate)
            .then((result) => this.setWidgetData(widget, result))
        }
      });
    },
    setWidgetData (updatedWidget, result) {
      let widget = this.widgets.find(w => w.id == updatedWidget.id)
      
      widget.data.items = result.map((item) => {
          return {
            ...item,
            rating_class_proposal_name: this.buildingRatingClasses.find(c => c.id == item.rating_class_proposal)?.name,
            rating_class_name: this.buildingRatingClasses.find(c => c.id == item.rating_class)?.name,
          }
        }, [])

      this.items = widget.data.items

      widget.loading = false
      this.loading = false
    },
    newBuildingRating () {
      this.ratingIdToEdit = undefined
      this.initialRating = this.initialRating
      this.showBuildingRatingModal = true
    },
    modifyRating (rating) {
      this.ratingIdToEdit = rating.id
      this.showBuildingRatingModal = true
    },
  },
}
</script>
<style scoped>
</style>
