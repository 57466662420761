var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{style:({ 'border-color': _vm.draggableColor }),attrs:{"value":_vm.widget.maximized[0] === true ? [0] : [],"multiple":""}},_vm._l((Array.isArray(_vm.widget.data.items)
      ? _vm.widget.data.items
      : _vm.widget.data.items !== undefined
        ? [_vm.widget.data.items]
        : []),function(source,idx){return _c('v-expansion-panel',{key:_vm.widget.id + '-' + idx,staticClass:"no-padding",on:{"click":function($event){return _vm.onExpansionPanelClick(_vm.widget)}}},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"align":"center","justify":"start","no-gutters":""}},[(_vm.widget.data.icon !== null)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.widget.data.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.widget.data.titleSource ? source[_vm.widget.data.titleSource] : _vm.$t(_vm.widget.id))+" "),(
            (Array.isArray(_vm.widget.data.items)
              ? _vm.widget.data.items
              : [_vm.widget.data.items]
            ).length > 1
          )?_c('span',[_vm._v("("+_vm._s(idx + 1)+" / "+_vm._s(_vm.widget.data.items.length)+")")]):_vm._e()],1)],1),_c('v-expansion-panel-content',[(_vm.widget.loading)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[_c('v-progress-circular',{staticStyle:{"margin":"3em"},attrs:{"size":"64","indeterminate":"","color":"primary"}})],1),_c('v-spacer')],1):_c('div',[(_vm.widget.type === 'MetadataForm')?_c('MetadataForm',{attrs:{"metadata":_vm.widget.data.metadata,"data":source,"category":_vm.widget.data.category,"disabled":!_vm.hasWritePermission,"multiline":_vm.widget.data.multiline,"with-status-icon":true}}):_vm._e()],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }