<template>
  <BaseModal
    @cancel="cancel"
  >
    <template #title>
      {{ $t('Add structure') }}
    </template>
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-text-field
          v-model="newStructure.structure_name"
          required
          :rules="[(v) => !!v || $t('Required')]"
          class="form-field"
          :label="$t('Name')"
          :hint="$t('Required')"
          persistent-hint
        />
        <v-select
          v-model="newStructure.structure_type"
          required
          :rules="[(v) => !!v || $t('Required')]"
          :value="newStructure.structure_type"
          :items="typeDefs"
          item-text="label"
          item-value="id"
          class="form-field"
          :label="$t('Structure type')"
          :hint="$t('Required')"
          persistent-hint
        />
        <numeric-field
          v-model="newStructure.structure_area"
          :is-required="false"
          class="form-field"
          :placeholder="newStructure.structure_area"
          :label="$t('Area')"
        />
        <v-textarea 
          v-model="newStructure.extra_info"
          :label="$t('Extra info')"
          class="form-field"
        />
      </v-form>
    </template>
    <template #footer>
      <v-btn
        :disabled="!valid || saving"
        depressed
        rounded
        color="primary"
        @click="save"
      >
        <span v-if="!saving">{{ $t('Save') }}</span>
        <v-progress-circular
          v-else
          :size="20"
          :width="3"
          indeterminate
          color="primary"
        />
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import { mapGetters } from 'vuex'
import NumericField from '../components/NumericField'
import BaseModal from './general/BaseModal'
export default {
  components: {
    BaseModal,
    NumericField,
  },
  props: {
    estateId: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      valid: false,
      saving: false,
      newStructure: {
        structure_id: 0,
        structure_name: '',
        structure_type: null,
        structure_area: null,
        extra_info: null
      }
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
    typeDefs () {
      return this.definitionsByGroupLabel('structure.type')
    }
  },
  methods: {
    clear () {
      this.$refs.form.resetValidation()
      this.valid = false
      this.newStructure.structure_name = ''
      this.newStructure.structure_type = null
      this.newStructure.structure_area = null
      this.newStructure.extra_info = null
    },
    cancel () {
      this.saving = false
      this.clear()
      this.$emit('close')
    },
    save () {
      this.saving = true
      this.newStructure.start_date = this.$store.state.app.currentDate
      this.$rambollfmapi.estates
        .structures(this.estateId)
        .post(this.newStructure)
        .then(res => {
          this.cancel()
          this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
          this.$emit('saved')
        })
        .catch(function (error) {
          this.$log.error(error)
          this.cancel()
        })
    }
  }
}
</script>
<style scoped>
</style>
