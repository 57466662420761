<template>
  <CommercialInfoModifyForm
    :units="items"
    @update="postMessageUpdate"
    @close="close"
  />
</template>
<script>
import { mapMutations } from 'vuex'
import CommercialInfoModifyForm from './../components/FacilityManagement/CommercialInfoModifyForm.vue'

export default {
  name: 'CommercialInfoModifyPopup',
  components: {
    CommercialInfoModifyForm: CommercialInfoModifyForm
  },
  data: function () {
    return {
        items: []
    }
  },
  computed: {},
  mounted () {
    this.setPopupOpen(true)
    window.addEventListener('message', this.receiveMessage)
    window.opener.postMessage("READY_COMMERCIAL", "*");
  },
  methods: {
    ...mapMutations('app', ['setPopupOpen']),
    receiveMessage (items) {
      if (event.data.units) {
        this.items = event.data.items
      }
    },
    postMessageUpdate () {
      window.opener.postMessage("UPDATE", "*");
      window.close()
    },
    close () {
      window.close()
    }
  }
}
</script>
<style scoped>
</style>


