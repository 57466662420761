import helpers from '../helpers'
import { i18n } from '../plugins/i18n'
import unitsWithFloorPlan from './site/unitsWithFloorPlan'
import unitsWithoutFloorPlan from './site/unitsWithoutFloorPlan'

export {
  unitsWithFloorPlan,
  unitsWithoutFloorPlan,
}

export function InformationForRentingFromMFilesCostcenter () {
  return {
    id: 'site.information_for_renting_from_mfiles_costcenter',
    type: 'MetadataForm',
    data: {
      icon: null,
      source: 'rentingForCurrentSite',
      titleSource: null,
      metadataSource: null,
      metadataLabels: [
        { value: 'rent_priority' },
        { value: 'wwwClass' },
        { value: 'rental_contract_type', isDefinition: true },
        { value: 'new_lease' },
        { value: 'upkeep_rent' },
        { value: 'upkeep_rent_budget_vat' },
        { value: 'upkeep_rent_budget' },
        { value: 'upkeep_rent_actualization_vat' },
        { value: 'upkeep_rent_actualization' },
        { value: 'upkeep_rent_budget_date', format: 'Date' },
        { value: 'renting_comment' },
        { value: 'parking_company' },
        { value: 'car_space_management' },
      ],
      metadata: null,
      category: 'None',
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function Services () {
  return {
    id: 'site.services',
    type: 'EditableMetadataForm',
    data: {
      icon: null,
      source: 'siteServices',
      titleSource: null,
      metadataSource: null,
      metadata: null,
      category: 'None',
      isDisabled: function (context) {
        return context.sitePermissions.includes(
          (p) => Number(p.id) === context.currentSiteId && p.accessLevel === 1
        )
      },
      getData: async function (context) {
        return context.$rambollfmapi.sites
          .services(context.currentSiteId)
          .list(context.currentDate)
      },
      getMetadata: async function (context) {
        return context.$rambollfmapi.services.metadata()
      },
      putData: async function (context) {
        return context.$rambollfmapi.sites
          .services(context.currentSiteId)
          .put(context.editedObject, context.currentDate)
      },
      validate (editedObject) {
        if (editedObject !== undefined && editedObject.campaign_active) {
          if (
            editedObject.campaign_last_date === null ||
            editedObject.campaign_last_date === undefined ||
            editedObject.campaign_description === null ||
            editedObject.campaign_description === ''
          ) {
            alert(i18n.t('Campaign must have all fields filled'))
            return false
          }
        }
        return true
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function TrafficCommunication () {
  return {
    id: 'site.traffic_communication',
    type: 'EditableMetadataForm',
    data: {
      icon: null,
      source: 'siteTraffic',
      titleSource: null,
      metadataSource: 'trafficMetadata',
      metadata: null,
      category: 'None',
      isDisabled: function (context) {
        return context.sitePermissions.includes(
          (p) => Number(p.id) === context.currentSiteId && p.accessLevel === 1
        )
      },
      getData: async function (context) {
        return context.$rambollfmapi.sites.traffic(context.currentSiteId).list()
      },
      getMetadata: async function (context) {
        return context.$rambollfmapi.traffic.metadata()
      },
      putData: async function (context) {
        return context.$rambollfmapi.sites
          .traffic(context.currentSiteId)
          .put(context.editedObject, context.currentDate)
      },
    },
    small: false,
    loading: true,
    maximized: [false],
  }
}

export function EmptyRentalSpaces () {
  return {
    id: 'site.empty_rental_spaces',
    type: 'DataTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Building'), value: 'building_name' },
        { text: i18n.t('Unit'), value: 'unit_name', unitLink: true },
        { text: i18n.t('Unit area'), value: 'unit_area', format: 'Area' },
        { text: i18n.t('Unit use'), value: 'unit_class' },
        {
          text: i18n.t('Previous tenant'),
          value: 'previous_tenant_name',
        },
        {
          text: i18n.t('End date'),
          value: 'previous_tenant_end_date',
          format: 'Date',
        },
        { text: i18n.t('Next tenant'), value: 'next_tenant_name' },
        {
          text: i18n.t('Start date'),
          value: 'next_tenant_start_date',
          format: 'Date',
        },
        { text: i18n.t('Apartment type'), value: 'apartment_type' },
      ],
      footers: [
        { text: i18n.t('Total') },
        {},
        { value: 'unit_area', format: 'Area' },
      ],
      items: [],
      report: 'emptyUnits',
      sortBy: 'start_date',
      sortDesc: true
    },
    small: false,
    maximized: [false],
  }
}

export function UnitMarketRentStatus () {
  return {
    id: 'site.unit_market_rent_status',
    type: 'DataTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Building'), value: 'buildingName' },
        { text: i18n.t('Unit code long'), value: 'unit_code_long' },
        { text: i18n.t('Unit'), value: 'unitName', unitLink: true },
        { text: i18n.t('Tenant'), value: 'curPartyName' },
        { text: i18n.t('Previous tenant'), value: 'prevPartyName' },
        { text: i18n.t('Net floor area'), value: 'area', format: 'Area' },
        {
          text: i18n.t('Market rent'),
          value: 'market_rent',
          format: 'MoneyPerSquare',
        },
        {
          text: i18n.t('Total market rent'),
          value: 'unitMarketRent',
          format: 'Money',
        },
        {
          text: i18n.t('Estimated TI Expense'),
          value: 'estimated_ti_expense',
          format: 'MoneyPerSquare',
        },
        {
          text: i18n.t('Clarified TI Expense'),
          value: 'clarified_ti_expense',
          format: 'MoneyPerSquare',
        },
        {
          text: i18n.t('Vacancy rate assumption'),
          value: 'vacancy_rate_assumption',

        },
        {
          text: i18n.t('Vacancy rate assumption updated'),
          value: 'vacancy_rate_assumption_date',
          format: 'Date',
        },
        { text: i18n.t('Unit use'), value: 'unitUsage' },
        {
          text: i18n.t('Rental Status'),
          value: 'rental_status_desc',
          format: 'Translation',
        },
        {
          text: i18n.t('Unit rental status'),
          value: 'unit_status',
          format: 'Translation',
        },
        {
          text: i18n.t('Market rent updated date'),
          value: 'marketRentDate',
          format: 'Date',
        },
        {
          text: i18n.t('Rent target'),
          value: 'rent_target',
          format: 'MoneyPerSquare',
        },
        {
          text: i18n.t('List rent apartment'),
          value: 'list_rent_apartment',
          format: 'Euro',
        },
        {
          text: i18n.t('Unit version start'),
          value: 'curUnitStartDate',
          format: 'Date',
        },
        {
          text: i18n.t('Unit version end'),
          value: 'curUnitEndDate',
          format: 'Date',
        },
        {
          text: i18n.t('Apartment'),
          value: 'apartment',
        },
        {
          text: i18n.t('Web visibility status'),
          value: 'web_visibility',
          format: 'Translation',
        },
        {
          text: i18n.t('Contract number'),
          value: 'contractNumber',
        },
        {
          text: i18n.t('Unit extensibility'),
          value: 'unit_extensibility',
          format: 'Translation',
        },
        {
          text: i18n.t('Unit divisibility'),
          value: 'unit_divisibility',
          format: 'Translation',
        },
        {
          text: i18n.t('Unit condition'),
          value: 'unit_condition',
          format: 'Translation',
        },
        {
          text: i18n.t('Move readiness'),
          value: 'move_readiness',
          format: 'Boolean',
        },
        {
          text: i18n.t('Reservation status'),
          value: 'reservation_status',
          format: 'Translation',
        },
        {
          text: i18n.t('Advertising phrase'),
          value: 'advertising_phrase',
        },
        {
          text: i18n.t('Lease plan'),
          value: 'lease_plan',
        },
        {
          text: i18n.t('Unit description'),
          value: 'unit_description',
        },
      ],
      footers: [
        { text: i18n.t('Total') },
        {},
        {},
        {},
        {},
        { value: 'area', format: 'Area' },
        {},
        { value: 'unitMarketRent', format: 'Money' },
        {},
        {},
        {},
      ],
      items: [],
      report: 'rentalStatus',
      sortDesc: true
    },
    maximized: [false],
    small: false,
  }
}

export function CarParksAndParkingZonesWithRent () {
  return {
    id: 'site.carparksandparkingzoneswithrent',
    type: 'DataTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Car park ID'), value: 'number' },
        { text: i18n.t('Carpark code long'), value: 'carpark_code_long' },
        { text: i18n.t('Type'), value: 'type' },
        {
          text: i18n.t('Renting Type'),
          value: 'rentingtype',
          format: 'Translation',
        },
        { text: i18n.t('Contract number'), value: 'contract_number' },
        { text: i18n.t('Renter'), value: 'renter' },
        {
          text: i18n.t('Tenant'),
          value: 'tenant',
        },
        {
          text: i18n.t('Type of contract validity'),
          value: 'validity_type',
        },
        {
          text: i18n.t('Start date'),
          value: 'contract_start_date',
          format: 'Date',
        },
        {
          text: i18n.t('Contract first possible end date'),
          value: 'first_possible_termination_date',
          format: 'Date',
        },
        {
          text: i18n.t('End date'),
          value: 'contract_end_date',
          format: 'Date',
        },
        {
          text: i18n.t('Previous tenant'),
          value: 'previous_tenant',
        },
        {
          text: i18n.t('Num of car spaces'),
          value: 'num_of_car_spaces',
          format: 'Number',
        },
        {
          text: i18n.t('Available car spaces'),
          value: 'num_of_free_car_spaces',
          format: 'Number',
        },
        {
          text: i18n.t('Num of parking privileges'),
          value: 'num_of_parking_rights',
          format: 'Number',
        },
        {
          text: i18n.t('Available parking privileges'),
          value: 'num_of_free_parking_rights',
          format: 'Number',
        },
        {
          text: i18n.t('Area'),
          value: 'area',
          format: 'Area',
        },
        {
          text: i18n.t('Market rent €/car space'),
          value: 'market_rent_per_car_space',
          format: 'Money',
        },
        {
          text: i18n.t('Sum of market rent'),
          value: 'market_rent_sum',
          format: 'Money',
        },
        {
          text: i18n.t('Vacancy rate assumption'),
          value: 'vacancy_rate_assumption',          
        },
        {
          text: i18n.t('Vacancy rate assumption updated'),
          value: 'vacancy_rate_assumption_date',
          format: 'Date',
        },
        { text: i18n.t('Charge in'), value: 'charge_in' },
        { 
          text: i18n.t('Charging spots amount'),
          value: 'charging_spots_amount',
          format: 'Number'
        },
        { 
          text: i18n.t('Charging power'),
          value: 'charging_power',
          format: 'Number'
        },
        { text: i18n.t('Charging info'), value: 'charging_info' },
        { 
          text: i18n.t('Carpark rent target single'),
          value: 'rent_target',
          format: 'Money'
        },
        { text: i18n.t('Lease plan'), value: 'lease_plan' },
        { 
          text: i18n.t('Lease plan updated'),
          value: 'lease_plan_date',
          format: 'Date'
        },
      ],
      footers: [
        { text: i18n.t('Total') },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { value: 'num_of_car_spaces', format: 'Number' },
        { value: 'num_of_free_car_spaces', format: 'Number' },
        { value: 'num_of_parking_rights', unit: 'number', format: 'Number' },
        { value: 'num_of_free_parking_rights', format: 'Number' },
        { value: 'area', format: 'Area' },
        { value: 'marker_rent_per_car_space', format: 'Money' },
        { value: 'market_rent_sum', format: 'Money' },
        { text: '' },
        { text: '' },
        { text: '' },
        { value: 'charging_spots_amount', format: 'Number' },
        { text: '' },
        { text: '' },
        { value: 'rent_target', format: 'Money' },
        { text: '' },
        { text: '' },
      ],
      items: [],
      report: 'carparksandparkingzoneswithrent',
      sortBy: 'number',
      sortDesc: true
    },
    small: false,
    maximized: [false],
  }
}

export function Legal () {
  return {
    id: 'site.legal',
    type: 'MetadataForm',
    data: {
      icon: null,
      source: 'currentSite',
      titleSource: null,
      metadataSource: 'siteMetadata',
      metadata: null,
      category: 'Legal',
    },
    small: true,
    loading: true,
    maximized: [false],
  }
}

export function Acquisition () {
  return {
    id: 'site.acquisition',
    type: 'MetadataForm',
    data: {
      icon: null,
      source: 'currentSite',
      titleSource: null,
      metadataSource: 'siteMetadata',
      metadata: null,
      category: 'Acquisition',
    },
    small: true,
    loading: true,
    maximized: [false],
  }
}

export function Apartments () {
  return {
    id: 'site.apartments',
    type: 'DataTable',
    loading: true,
    data: {
      report: 'GetApartmentsStatuses',
      headers: [
        { text: i18n.t('Site'), value: 'site_name' },
        { text: i18n.t('apartment.total_count'), value: 'totalcount', format: 'Amount' },

        { text: i18n.t('apartment.one_space_available_area'), value: '1vacant_area', format: 'Area' },
        { text: i18n.t('apartment.one_space_rented_area'), value: '1rented_area', format: 'Area' },
        { text: i18n.t('apartment.one_space_count'), value: '1spacecount', format: 'Amount' },
        { text: i18n.t('apartment.one_vacant_count'), value: '1vacant_count', format: 'Amount' },
        { text: i18n.t('apartment.one_rented_count'), value: '1rented_count', format: 'Amount' },

        { text: i18n.t('apartment.two_spaces_available_area'), value: '2vacant_area', format: 'Area' },
        { text: i18n.t('apartment.two_spaces_rented_area'), value: '2rented_area', format: 'Area' },
        { text: i18n.t('apartment.two_spaces_count'), value: '2spacecount', format: 'Amount' },
        { text: i18n.t('apartment.two_vacant_count'), value: '2vacant_count', format: 'Amount' },
        { text: i18n.t('apartment.two_rented_count'), value: '2rented_count', format: 'Amount' },

        { text: i18n.t('apartment.three_spaces_available_area'), value: '3vacant_area', format: 'Area' },
        { text: i18n.t('apartment.three_spaces_rented_area'), value: '3rented_area', format: 'Area' },
        { text: i18n.t('apartment.three_spaces_count'), value: '3spacecount', format: 'Amount' },
        { text: i18n.t('apartment.three_vacant_count'), value: '3vacant_count', format: 'Amount' },
        { text: i18n.t('apartment.three_rented_count'), value: '3rented_count', format: 'Amount' },

        { text: i18n.t('apartment.four_spaces_available_area'), value: '4vacant_area', format: 'Area' },
        { text: i18n.t('apartment.four_spaces_rented_area'), value: '4rented_area', format: 'Area' },
        { text: i18n.t('apartment.four_spaces_count'), value: '4spacecount', format: 'Amount' },
        { text: i18n.t('apartment.four_vacant_count'), value: '4vacant_count', format: 'Amount' },
        { text: i18n.t('apartment.four_rented_count'), value: '4rented_count', format: 'Amount'},

        { text: i18n.t('apartment.five_spaces_available_area'), value: '5vacant_area', format: 'Area' },
        { text: i18n.t('apartment.five_spaces_rented_area'), value: '5rented_area', format: 'Area' },
        { text: i18n.t('apartment.five_spaces_count'), value: '5spacecount', format: 'Amount' },
        { text: i18n.t('apartment.five_vacant_count'), value: '5vacant_count', format: 'Amount' },
        { text: i18n.t('apartment.five_rented_count'), value: '5rented_count', format: 'Amount' },

        { text: i18n.t('apartment.sauna_count'), value: 'saunacount', format: 'Amount' },
      ],
      items: []
    }
  }
}

export function Comments () {
  return {
    id: 'site.description',
    type: 'MetadataForm',
    data: {
      icon: null,
      source: 'currentSite',
      titleSource: null,
      metadataSource: 'siteMetadata',
      metadata: null,
      category: 'Description',
      multiline: true
    },
    small: false,
    loading: true,
    maximized: [true]
  }
}

export function LeasePlan () {
  return {
    id: 'site.lease_plan',
    type: 'MetadataForm',
    data: {
      icon: null,
      source: 'currentSite',
      titleSource: null,
      metadataSource: 'siteMetadata',
      metadata: null,
      category: 'LeasePlan',
      multiline: true,
    },
    small: false,
    loading: true,
    maximized: [true]
  }
}

export function RentingProcesses () {
  return {
    id: "site.renting_processes",
    type: "ObjectTable",
    data: {
      headers: [
        { text: "leasing.renting_stage", value: "stage" }, // Vuokrauksen vaihe
        { text: "Name", value: "rentingProcessTitle" }, // Vuokrauksen nimi
        { text: "Area", value: "area", format: "Area" }, //Pinta-ala
        { text: "Tenant corporation", value: "tenant" }, //Vuokralaiskonserni
        {
          text: 'Sites',
          value: 'sites',
          leasing: true,
          multiple: true,
          sort: helpers.table.sortByObjectName, // Custom sorter for sites
        }, //Kohteet
        { text: "leasing.responsible1", value: "primaryUser" }, // 1. Vuokravastuullinen
        { text: "leasing.responsible2", value: "primaryUser2" }, // 2. Vuokravastuullinen
        { text: "leasing.assetManager", value: "assetManager" }, // Asset Manager / kohdevastaava
        { text: "leasing.estimatedStart", value: "estimatedStart", format: "Date" }, // Sopimuksen arvioitu alku pvm
        { text: "leasing.estimatedSign", value: "estimated_signature_date", format: "MonthAndYear" }, // Ennustettu allekirjoitusajankohta
        { text: "leasing.estimatedValue", value: "estimated_value_impact", format: 'Euro' }, // Ennustettu arvovaikutus
      ],
      browseHeaders: [
        { text: "leasing.renting_stage", value: "stage" }, // Vuokrauksen vaihe
        { text: "Name", value: "rentingProcessTitle", leasing: true }, // Vuokrauksen nimi
        { text: "Area", value: "area", format: 'Area' }, //Pinta-ala
        { text: "Tenant corporation", value: "tenant", leasing: true }, //Vuokralaiskonserni
        {
          text: 'Sites',
          value: 'sites',
          leasing: true,
          multiple: true,
          sort: helpers.table.sortByObjectName, // Custom sorter for sites
        }, //Kohteet
        { text: "leasing.responsible1", value: "primaryUser" }, // 1. Vuokravastuullinen
        { text: "leasing.responsible2", value: "primaryUser2" }, // 2. Vuokravastuullinen
        { text: "leasing.assetManager", value: "assetManager" }, // Asset Manager / kohdevastaava
        { text: "leasing.estimatedStart", value: "estimatedStart", format: "Date" }, // Sopimuksen arvioitu alku pvm
        { text: "leasing.estimatedSign", value: "estimated_signature_date", format: "MonthAndYear" }, // Ennustettu allekirjoitusajankohta
        { text: "leasing.estimatedValue", value: "estimated_value_impact", format: "Euro" }, // Ennustettu arvovaikutus
        { text: "leasing.market_rent_total", value: "totalMarketRent", format: "Euro" }, // Markkinavuokra yht. €/kk
        { text: "prospect.location_municipalities", value: "municipalities" }, // Sijaintikunnat
        { text: "Usage types", value: "usageTypes" }, // Käyttötyyppi
        { text: "prospect.lead_origin", value: "leadOrigin" }, // Liidin alkuperä
        { text: "leasing.contractModel", value: "contractModel" }, // Sopimusmalli
        { text: "ContractType", value: "type" }, // Sopimustyyppi
        { text: "leasing.createdDay", value: "createdDay", format: "Date" }, // Luotu pvm
        { text: "leasing.lastUpdatedDay", value: "updatedDay", format: "Date" }, // Viimeksi päivitetty pvm
      ],
      footers: [
        { text: i18n.t("Total") },
        {},
        { value: 'area', format: 'Area' },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { value: 'estimated_value_impact', format: 'Euro' },
      ],
      browseFooters: [
      {},
      { value: 'area', format: 'Area' },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      { value: 'estimated_value_impact', format: 'Euro' },
      { value: 'totalMarketRent', format: 'Euro' },
      ],
      dynamicFilterOptions: true,
      filter: {
        header: "leasing.renting_stage",
        type: 'equal',
        options: [
          { text: 'prospect.show_all', value: null },
        ],
        defaultFilter: {
          text: 'prospect.show_all',
          value: null,
        },
      },
      items: [],
      itemsPerPage: 10,
      source: 'rentingProcesses'
    },
    small: false,
    maximized: [true],
    loading: true,
  }
}

export function RentingProcessWorkQueue () {
  return {
    id: 'site.renting_process_work_queue',
    type: 'KanbanWidget',
    data: {
      headers: [],
      items: [],
      dynamicHeaders: true,
      source: 'rentingProcessQueue',
      filterHeaders: [
        { text: 'Search corporations', value: "generalSearch" },
      ]
    },
    small: false,
    maximized: [false],
    loading: true,
  }
}