<template>
  <div>
    <v-dialog
      v-model="showGuaranteeDeliveryModal"
      persistent
      eager
    >
      <GuaranteeDeliveryModal
        :delivery="guaranteeDeliveryToEdit"
        :visible="showGuaranteeDeliveryModal"
        :selected-currency="selectedCurrency"
        @guaranteeDeliveryAdded="onGuaranteeDeliveryAdded"
        @guaranteeDeliveryUpdated="onGuaranteeDeliveryUpdated"
        @close="onSingleGuaranteeDeliveryModalClosed"
      />
    </v-dialog>
    <v-dialog
      v-model="showGuaranteeReturnModal"
      persistent
      eager
      class="v-dialog--custom"
    >
      <GuaranteeReturnModal
        :return="guaranteeReturnToEdit"
        :visible="showGuaranteeReturnModal"
        :selected-currency="selectedCurrency"
        @guaranteeReturnAdded="onGuaranteeReturnAdded"
        @guaranteeReturnUpdated="onGuaranteeReturnUpdated"
        @close="onSingleGuaranteeReturnModalClosed"
      />
    </v-dialog>
    <BaseModal
      @cancel="close()"
    >
      <template #title>
        {{ $t('GuaranteeAdding') }}
      </template>
      <template #content>
        <ValidationObserver>
          <v-row>
            <v-col>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
              >
                <v-autocomplete
                  v-model="guaranteeType"
                  :items="guaranteeTypes"
                  :error-messages="errors[0]"
                  item-text="name"
                  item-value="id"
                  class="form-fields"
                  :label="$t('GuaranteeType')"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="guaranteeDescription"
                type="text"
                :label="$t('GuaranteeDescription')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="storagePlace"
                type="text"
                :label="$t('GuaranteeStoragePlace')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
              >                       
                <CurrencyField
                  :value="guaranteeSum"
                  :label="$t('GuaranteeSum')"
                  :error-messages="errors"
                  :currency="selectedCurrency"
                  @change="onGuaranteeSumChange"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DatePicker
                :label="$t('GuaranteeDateOfExpiration')"
                :aria-label="$t('GuaranteeDateOfExpiration') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :hint="$t('Required')"
                :clearable="false"
                :rules="'required'"
                required
                class="form-fields"
                :initial-date="dateOfExpiration"
                @dateupdated="dateOfExpirationUpdate"
              />
            </v-col>
            <v-col>
              <DatePicker
                :label="$t('GuaranteeAgreedDateOfDelivery')"
                :aria-label="$t('GuaranteeAgreedDateOfDelivery') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :hint="$t('Required')"
                :clearable="false"
                class="form-fields"
                :initial-date="agreedDateOfDelivery"
                @dateupdated="agreedDateOfDeliveryUpdate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="guaranteeAdditionalInfo"
                hide-details
                class="form-fields"
                :label="$t('GuaranteeAdditionalInfo')" 
              />
            </v-col>
          </v-row>
        </ValidationObserver>
        <v-row>
          <v-col>
            <h3 class="mb-2">
              {{ $t('guarantees.tenantDelivered') }}
            </h3>
            <c-list
              :items="deliveryItems"
              :empty-text="$t('guarantees.notDelivered')"
              @editItem="editDelivery($event)"
              @deleteItem="deleteDelivery($event)"
            >
              <template #emptyContent>
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  @click="onAddNewDelivery"
                >
                  <v-icon left>
                    add
                  </v-icon>
                  {{ $t('guarantees.newDeliver') }}
                </v-btn>
              </template>
              <template #footer>
                <h4>
                  {{ `${$t('Totals')}  ${getTotalDeliverySum()}` }}
                </h4>
              </template>
            </c-list>
            <v-btn
              v-if="deliveryItems.length > 0"
              rounded
              depressed
              color="secondary"
              @click="onAddNewDelivery"
            >
              <v-icon left>
                add
              </v-icon>
              {{ $t('guarantees.newDeliver') }}
            </v-btn>
          </v-col>
          <v-col>
            <h3 class="mb-2">
              {{ $t('guarantees.landlordReturned') }}
            </h3>
            <c-list
              :items="returnItems"
              :empty-text="$t('guarantees.notReturned')"
              @editItem="editReturn($event)"
              @deleteItem="deleteReturn($event)"
            >
              <template #emptyContent>
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  @click="onAddNewReturn"
                >
                  <v-icon left>
                    add
                  </v-icon>
                  {{ $t('guarantees.newReturn') }}
                </v-btn>
              </template>
              <template #footer>
                <h4>
                  {{ `${$t('Totals')}  ${getTotalReturnSum()}` }}
                </h4>
              </template>
            </c-list>
            <v-btn
              v-if="returnItems.length > 0"
              rounded
              depressed
              color="secondary"
              @click="onAddNewReturn"
            >
              <v-icon left>
                add
              </v-icon>
              {{ $t('guarantees.newReturn') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template
        #footer
      >
        <v-btn
          rounded
          depressed
          color="primary"
          @click="onAddGuarantee"
        >
          {{ addButtonText }}
        </v-btn>
      </template>
    </Basemodal>
  </div>
</template>
  
<script>
import helpers from './../../../../helpers'
import BaseModal from '../../../general/BaseModal'
import CList from '../../../general/CList'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import GuaranteeDeliveryModal from './GuaranteeDeliveryModal.vue'
import GuaranteeReturnModal from './GuaranteeReturnModal.vue'
import CurrencyField from '../../../CurrencyField'
import DatePicker from '../../../general/DatePicker.vue'

export default {
  name: "GuaranteeModal",
  components: {
    BaseModal,
    CList,
    GuaranteeDeliveryModal,
    GuaranteeReturnModal,
    ValidationProvider,
    ValidationObserver,
    CurrencyField,
    DatePicker
  },
  props: {
    contractId: {
      type: Number,
      default: () => undefined
    },
    guarantee: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
        guaranteeType: undefined,
        guaranteeDescription: undefined,
        storagePlace: undefined,
        guaranteeSum: undefined,
        guaranteeAdditionalInfo: undefined,
        agreedDateOfDelivery: undefined,
        dateOfExpiration: undefined,
        agreedDateOfDeliveryFormatted: undefined,
        dateOfExpirationFormatted: undefined,
        showGuaranteeDeliveryModal: false,
        showGuaranteeReturnModal: false,
        guaranteeDeliveryToEdit: undefined,
        guaranteeReturnToEdit: undefined,
        defRefundAction: undefined,
        delivered: [],
        returned: [],
    }
  },
  
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById']),
    
    addButtonText () {
      return this.guarantee ? this.$t('GuaranteeSave') : this.$t('GuaranteeAdd')
    },
    guaranteeTypes () {
      const guaranteeTypes = this.definitionsByGroupLabel('contract.guaranteetype')
      const tempTypes = []
        for (let definition in guaranteeTypes) {
          tempTypes.push({
            id: guaranteeTypes[definition].label,
            name: this.$t(guaranteeTypes[definition].label)
        })
        } 
      return tempTypes.sort((a, b) => a.name > b.name ? 1 : -1)
    },
    deliveryItems () {
      return this.delivered?.map(d => ({
        ...d,
        headline: this.$t('guarantees.tenantDelivered'),
        title: this.formatSum(d.deliverySum),
        subtitle: this.formatDate(d.dateOfDelivery),
      }))
    },
    returnItems () {
      return this.returned?.map(d => ({
        ...d,
        headline: this.$t('guarantees.tenantDelivered'),
        title: this.formatSum(d.returnSum),
        subtitle: this.formatDate(d.dateOfReturn),
      }))
    },
  },
  
  watch: {
    visible () {
      if (this.visible) {
        this.copyDataFromGuarantee()
      }
    },
    guarantee () {
      this.copyDataFromGuarantee()
    },
    agreedDateOfDelivery () {
      this.agreedDateOfDeliveryFormatted = this.formatDate(this.agreedDateOfDelivery)
    },
    dateOfExpiration () {
      this.dateOfExpirationFormatted = this.formatDate(this.dateOfExpiration)
    },
  },
  methods: {
    copyDataFromGuarantee () {
      this.guaranteeType = this.guarantee?.guaranteeType
      this.guaranteeDescription = this.guarantee?.guaranteeDescription
      this.storagePlace = this.guarantee?.storageplace
      this.guaranteeSum = this.guarantee?.guaranteeSum
      this.agreedDateOfDelivery = this.guarantee?.agreedDateOfDelivery
      this.dateOfExpiration = this.guarantee?.dateOfExpiration
      this.guaranteeAdditionalInfo = this.guarantee?.guaranteeAdditionalInfo
      this.defRefundAction = this.guarantee?.defRefundAction,
      this.delivered = this.guarantee?.delivered ? this.guarantee.delivered : []
      this.returned = this.guarantee?.returned ? this.guarantee.returned : []
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
      }
    },
    dateOfExpirationUpdate (value) {
      this.dateOfExpiration = value
    },
    agreedDateOfDeliveryUpdate (value) {
      this.agreedDateOfDelivery = value
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    formatSum (sum) {
      return helpers.humanize.amount_long(Number(sum).toFixed(2)) + ' ' + this.selectedCurrency.symbol
    },
    
    async onAddGuarantee () {

      if (this.guaranteeSum != undefined && this.guaranteeSum.toString().includes(",")) {
        this.guaranteeSum = parseFloat(
          this.guaranteeSum.replace(",", ".")
        ).toFixed(2);
      }

      const modifiedGuarantee = {
          id: this.guarantee?.id,
          contractId: this.contractId,
          guaranteeType: this.guaranteeType,
          guaranteeDescription: this.guaranteeDescription,
          storageplace: this.storagePlace,
          guaranteeSum: this.guaranteeSum,
          agreedDateOfDelivery: this.agreedDateOfDelivery,
          dateOfExpiration: this.dateOfExpiration,
          guaranteeAdditionalInfo: this.guaranteeAdditionalInfo,
          defRefundAction: this.defRefundAction,
          delivered: this.delivered,
          returned: this.returned,
      }

      if (this.guarantee) {
        this.$emit('guaranteeUpdated', modifiedGuarantee)
      } else {
        this.$emit('guaranteeAdded', modifiedGuarantee)
      }
    },

    // Delivery
    getTotalDeliverySum () {
      return this.formatSum(this.delivered.reduce(function (acc, obj) { return acc + Number(obj.deliverySum); }, 0));
    },
    onSingleGuaranteeDeliveryModalClosed () {
      this.showGuaranteeDeliveryModal = false
    },
    onGuaranteeDeliveryAdded (delivery) {
      // create pseudo id
      delivery.id = Math.floor(Math.random() * 1000000000)
      this.delivered.push(delivery)
      this.showGuaranteeDeliveryModal = false
      this.$emit("guaranteesDeliveryUpdated", this.delivered)
    },
    onGuaranteeDeliveryUpdated (delivery) {
      const deliveryIdx = this.delivered.findIndex(d => d.id === delivery.id)      
      this.delivered.splice(deliveryIdx, 1, delivery)
      this.$emit("guaranteesDeliveryUpdated", this.delivered)
      this.showGuaranteeDeliveryModal = false
    },
    async deleteDelivery (delivery) {
      if (confirm(this.$t('Confirm remove'))) {
        const deliveryIdx = this.delivered.findIndex(p => p.id === delivery.id)
        this.delivered.splice(deliveryIdx, 1)
        this.$emit("guaranteesDeliveryUpdated", this.delivered)
      }
    },
    editDelivery (delivery) {
      this.guaranteeDeliveryToEdit = delivery
      this.showGuaranteeDeliveryModal = true
    },
    onAddNewDelivery () {
      this.guaranteeDeliveryToEdit = undefined
      this.showGuaranteeDeliveryModal = true
    },

    // Return
    getTotalReturnSum () {
      return this.formatSum(this.returned.reduce(function (acc, obj) { return acc + Number(obj.returnSum); }, 0));
    },
    onSingleGuaranteeReturnModalClosed () {
      this.showGuaranteeReturnModal = false
    },
    onGuaranteeReturnAdded (ret) {
      // create pseudo id
      ret.id = Math.floor(Math.random() * 1000000000)
      this.returned.push(ret)
      this.showGuaranteeReturnModal = false
      this.$emit("guaranteesReturnUpdated", this.returned)
    },
    onGuaranteeReturnUpdated (ret) {
      const returnIdx = this.returned.findIndex(d => d.id === ret.id)      
      this.returned.splice(returnIdx, 1, ret)
      this.$emit("guaranteesReturnUpdated", this.returned)
      this.showGuaranteeReturnModal = false
    },
    async deleteReturn (ret) {
      if (confirm(this.$t('Confirm remove'))) {
        const returnIdx = this.returned.findIndex(p => p.id === ret.id)
        this.returned.splice(returnIdx, 1)
        this.$emit("guaranteesReturnUpdated", this.returned)
      }
    },
    editReturn (ret) {
      this.guaranteeReturnToEdit = ret
      this.showGuaranteeReturnModal = true
    },
    onAddNewReturn () {
      this.guaranteeReturnToEdit = undefined
      this.showGuaranteeReturnModal = true
    },
    onGuaranteeSumChange (value) {
      this.guaranteeSum = value
    }
  }
}

</script>

<style scoped>
>>> .v-dialog {
  width: 100%;
}

@media screen and (min-width: 768px) {
  >>> .v-dialog {
    width: 30%;
  }
}
</style>
  