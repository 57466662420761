<template>
  <v-btn
    text
    outlined
    rounded
    @click.stop="toggleAll"
  >
    <span v-if="isAllSelected">
      <span class="d-sr-only">{{ `${$t('select_all.all_selected')}. ` }}</span>
      {{ $t('select_all.deselect_all') }}
      <span class="d-sr-only">{{ ariaLabel }}</span>
    </span>
    <span v-else>
      <span
        v-if="isNoneSelected"
        class="d-sr-only"
      >
        {{ `${$t('select_all.all_deselected')}. ` }}
      </span>
      {{ $t('select_all.select_all') }}
      <span class="d-sr-only">{{ ariaLabel }}</span>
    </span>
  </v-btn>
</template>
<script>
export default {
  name: 'ToggleAll',
  props: {
    selected: { 
      type: Array,
      default: undefined,
      required: true
    },
    all: { 
      type: Array,
      default: undefined,
      required: true
    },
    ariaLabel: {
      type: String,
      default: ''
    },
  },
  emits: ['toggleUpdated'],
  computed: {
    isNoneSelected () {
      return this.selected.length === 0
    },
    isAllSelected () {
      return this.selected.length === this.all.length 
    },
  },
  methods: {
    toggleAll () {
      this.$emit('toggleUpdated', this.isAllSelected)
    },
  }
}
</script>
<style scoped>
</style>
