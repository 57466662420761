<template>
  <v-form ref="addSiteDialogForm">
    <BaseModal @cancel="emit">
      <template #title>
        {{ $t('Add site') }}
      </template>
      <template #content>
        <!-- SITES -->
        <div>
          <v-row v-if="!hideInfo">
            <p>{{ $t("add_site_dialog_info_text") }}</p>
          </v-row>
          <v-autocomplete
            v-model="selectedSite"
            :label="$t('Site')"
            :items="sites"
            :placeholder="$t('Site')"
            :loading="sites.length === 0"
            clearable
            item-text="name"
            item-value="id_site"
            hide-details
            return-object
            class="location-selector form-field"
          />
          <!-- BUILDINGS -->
          <v-autocomplete
            v-if="allBuildings.length > 0 && sites.length > 0"
            v-model="selectedBuilding"
            :label="$t('Building')"
            :items="allBuildings"
            :placeholder="$t('Building')"
            clearable
            item-text="building_name"
            hide-details
            return-object
            class="location-selector form-field"
          />
          <div
            v-for="(floor, i) in selectedFloors"
            :key="floor+i"
            class="flex-column"
          >
            <!-- FLOORS -->
            <v-autocomplete
              v-if="allFloors.length > 0 && selectedBuilding !== null"
              v-model="selectedFloors[i]"
              :label="$t('Floor')"
              :items="selectableFloors.concat(selectedFloors[i])"
              :placeholder="$t('Floor')"
              clearable
              item-text="floor_name"
              hide-details
              return-object
              class="location-selector form-field"
              @change="() => onFloorChange(i)"
            />

            <!-- UNITS -->
            <v-autocomplete
              v-if="floor && floor.units"
              v-model="floor.selectedUnits"
              :label="$t('Units')"
              :items="floor.units"
              :placeholder="$t('Units')"
              item-text="unit_code"
              multiple
              small-chips
              hide-details
              return-object
              class="location-selector form-field"
            >
              <template #selection="data">
                <v-chip
                  :key="JSON.stringify(data.id)"
                  class="v-chip--select-multi"
                  close
                  @click.stop
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <span>
                    <span class="font-weight-bold">{{ data.item.unit_code }}</span> / 
                    {{ getUnitString(data.item) }}
                  </span>
                </v-chip>
              </template>
              <template #item="data">
                <span
                  :key="JSON.stringify(data.id)"
                >
                  <span class="font-weight-bold">{{ data.item.unit_code }}</span> /
                  {{ getUnitString(data.item) }}
                </span>
              </template>
            </v-autocomplete>
          </div>
          <v-btn
            v-if="allBuildings.length > 0 && selectableFloors.length > 0"
            text
            rounded
            class="add_floor_btn"
            @click="addFloor"
          >
            {{ $t("Add floor") }}
          </v-btn>
          <v-progress-linear
            v-if="fetching"
            indeterminate
            color="primary"
            class="mt-2"
          />
        </div>
      </template>
      <template #footer>
        <v-btn
          :disabled="selectedSite === null || fetching === true"
          type="submit"
          depressed
          rounded
          color="primary"
          @click="add"
        >
          {{ isEdit ? $t('Save') : $t('Add') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-form>
</template>
<script>
import moment from 'moment';
import BaseModal from '../general/BaseModal'
import { mapState, mapGetters } from 'vuex'
import helpers from '../../helpers'

export default {
  name: 'AddSiteDialog',
  components: {
    BaseModal,
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    editSite: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    sites: {
      type: Array,
      default: () => [],
    },
    hideInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedSite: null,
      selectedBuilding: null,
      selectedFloors: [null],
      allBuildings: [],
      allFloors: [],
      allUnits: [],
      fetching: false,
      isEditing: false,
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapGetters('app', ['definitionById']),
    selectableFloors () {
      const selectedFloorIds = this.selectedFloors.map(floor => floor?.id)
      return this.allFloors.filter(floor => !selectedFloorIds.includes(floor.id))
    },
  },
  watch: {
    isShown (shown) {
      if (!shown) {
        this.selectedSite = null
        this.selectedBuilding = null
        this.selectedFloors = []

        this.allBuildings = []
        this.allFloors = []

        this.isEditing = false
      } else if (this.editSite) {
        this.setEditSite()
      }
    },
    selectedSite (newSite) {
      this.allBuildings = []
      if (!this.isEditing) {
        this.selectedBuilding = null
      }
      if (newSite) {
        this.fetching = true
        this.$rambollfmapi.buildings
          .list({ query: { siteId: newSite.id_site, time: this.currentDate } })
          .then((res) => {
            this.allBuildings = res
            this.fetching = false
          })
      }
    },
    selectedBuilding (newBuilding) {
      this.allFloors = []

      if (!this.isEditing) {
        this.selectedFloors = [null]
      }

      if (newBuilding) {
        this.fetching = true
        this.$rambollfmapi.buildings
          .floors(newBuilding.building_code)
          .list(this.currentDate)
          .then((res) => {
            this.allFloors = res
            this.fetching = false
          })
      }
    },
  },
  mounted () {
    if (this.editSite) {
      this.setEditSite()
    }
  },
  methods: {
    async setEditSite () {
      const { site, building, floors, units } = this.editSite

      this.isEditing = true
      this.selectedSite = site
      this.selectedBuilding = building

      const floorsPromises = floors.map(async (floor) => {
        const { id } = floor

        const apiUnits = await this.fetchUnits(id)

        return {
          ...floor,
          units: apiUnits,
          selectedUnits: units.filter(u => u && u.floor_id === id)
        }
      })

      this.selectedFloors = await Promise.all(floorsPromises)
    },
    emit () {
      this.$emit('close')
    },
    add (event) {
      event.preventDefault()
      
      let selectedUnits = this.selectedFloors.reduce((acc, cur) => {
          if (cur && cur.selectedUnits ) {
            acc = acc.concat(cur.selectedUnits)
          }
          return acc
       }, [])

      const newSite = {
        site: this.selectedSite,
        building: this.selectedBuilding,
        floors: this.selectedFloors,
        units: selectedUnits,
      }

      if (this.editSite) {
        this.$emit('add', newSite, this.isEdit, this.editSite.site.id_site)
      } else {
        this.$emit('add', newSite, this.isEdit)
      }
    },
    async onFloorChange (index) {
      this.isEditing = false

      const selectedFloor = this.selectedFloors[index]

      // If floor was deleted, it will be null in the floors list.
      if (!selectedFloor) {
        this.selectedFloors = this.selectedFloors.filter(floor => floor !== null)
        return
      }

      const { id } = selectedFloor

      selectedFloor.units = await this.fetchUnits(id)
    },
    async fetchUnits (id) {
      this.fetching = true

      const units = await this.$rambollfmapi.floors.units(id, this.currentDate).listWithFuture()

      this.fetching = false
      return units
    },
    addFloor () {
      this.selectedFloors.push(null)
    },
    getUnitString (unit) {
      const unitStartsInFuture = moment(unit.start_date) > this.currentDate ? 'unitStatus.future_unit_start_date_description' : 'Valid unit'
      const unitOccupied = unit.tenant !== null ? 'Leased' : 'Vacant unit' 
      return `${helpers.humanize.area(unit.area)} m\u00B2 / ${this.getDefinitionLabelById(unit.usage)} / ${this.$t(unitOccupied)} / ${this.$t(unitStartsInFuture)}`
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
  },
}
</script>
<style scoped>
.location-selector {
  margin-top: 1em !important;
  height: auto !important;
}

.add_floor_btn {
  margin-top: 3em !important;
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>
