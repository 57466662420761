<template>
  <v-dialog 
    v-model="dialog"
    persistent
    max-width="550"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        :aria-label="$t('New user organization')"
        v-bind="attrs"
        @click.stop="dialog = true"
        v-on="on"
      >
        <v-icon>add_business</v-icon>
      </v-btn>
    </template>
    <v-card>
      <BaseModal
        @cancel="emit"
      >
        <template #title>
          {{ $t('New user organization') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field 
              v-model="name"
              :label="$t('Name')"
            />
            <v-text-field 
              v-model="description"
              :label="$t('Description')"
            />
          </v-form>
        </template>
        <template #footer>
          <v-btn
            :disabled="indicatorRunning"
            rounded
            depressed
            color="primary"
            this.dialog="true"
            @click="saveOrganization(); dialog = false; indicatorRunning = true"
          >
            {{ $t("Save") }}
          </v-btn>
        </template>
      </BaseModal>
      <Alert
        :show="showIndicator"
        :result="operationResult"
        :message="indicatorMessage"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import Alert from '../components/Alert'
import BaseModal from './general/BaseModal'
export default {
  components: {
    Alert,
    BaseModal,
  },
  props: { },
  data () {
    return {
      childMessage: null,
      editable: false,
      currentVisibility: false,
      name: null,
      description: null,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      dialog: false,
    }
  },
  computed: {

  },
  watch: {
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  beforeMount () {
    
  },
  methods: {
    emit (event) {
      this.$emit('childToParent', false)
      this.dialog = false
      this.$refs.form.reset()
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    close () {
      this.currentVisibility = false
      this.$emit('close')
    },
    async saveOrganization () {
      let data = { name: this.name, comment: this.description }
      
      await this.$rambollfmapi.userOrganizations
        .post(data)
        .then(response => {
          this.$emit('saved')
          this.indicatorRunning = false
          this.close()
        })
        .catch(() => {
          this.showIndicator = true
          this.indicatorMessage = this.$t('Unsuccesfull save')
          this.operationResult = 'error'
        })
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}

.checkbox-width {
  min-width: 25% !important;
}

.noPadding {
  padding: 0;
  margin: 8px;
}
</style>
