<template>
  <v-row
    align="center"
    justify="center"
  >
    {{ props.item[header.value] }}
    <div
      v-if="showAlert"
      class="ml-1"
    >
      <info-ball
        icon="cancel"
        color="error"
        :info="$t('unit_version_has_ending_date')"
        :bottom="true"
      />
    </div>
  </v-row>
</template>

<script>
import InfoBall from "../../InfoBall.vue"

export default {
  name: "UnitCodeTimeAlertColumn",
  components: { InfoBall },
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
  },
  computed: {
    showAlert () {
      const { unit_end_date } = this.props.item

      if (unit_end_date && new Date(unit_end_date) > new Date()) {
        return true
      }

      return false
    },
  },

  methods: {},
}
</script>

<style scoped></style>
