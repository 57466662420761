<template>
  <v-dialog
    v-model="addUnitDialog"
    persistent
    max-width="550"
  >
    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ $t('Create unit') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-select
            v-model="addUnit.idBuilding"
            class="form-field"
            :rules="[v => !!v || $t('Required')] && [BuildingHasFloors ? $t('create_unit.no_floors') : true]"
            :label="$t('Building')"
            :hint="$t('Required')"
            persistent-hint
            item-text="building_name"
            item-value="id_building"
            :items="buildingsForCurrentSite"
            required
          />
          <v-select
            v-model="addUnit.idFloor"
            :disabled="floorsDisabled || BuildingHasFloors"
            :rules="[v => !!v || $t('Required')]"
            class="form-field"
            :label="$t('Floor')"
            :hint="$t('Required')"
            persistent-hint
            item-text="floor_name"
            item-value="id"
            :items="floorsForCurrentBuilding"
            required
            @change="setFloorStartDate"
          />           
          <UnitDataDialog
            :metadata="filteredMetaData"
            :data="saveData"
            :available-tags="availableTags"
          />
          <ValidationObserver ref="validationObserver">
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="formatDate(startDate)"
                  :label="$t('Unit start date')"
                  :disabled="!floorStartDate"
                  :hint="$t('Required') + ' ' + floorStartDate !== undefined ? $t('create_unit.earliest') + ' ' + formatDate(floorStartDate) : '' "
                  required
                  persistent-hint
                  :messages="indicatorMessage"
                  :rules="[v => !!v || $t('Required')]"
                  class="form-field pb-8 mb-8 mt-auto"
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                :min="floorStartDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template #activator="{ on }">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`validEndDate: ${startDate}, ${endDate}`"
                >
                  <v-text-field
                    :value="formatDate(endDate)"
                    :label="$t('Unit end date')"
                    :error-messages="errors[0]"
                    :disabled="!floorStartDate"
                    class="form-field pt-2 mb-4"
                    prepend-icon="calendar_today"
                    v-on="on"
                  />
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
          </ValidationObserver>
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="!valid"
          depressed
          rounded
          color="primary"
          @click="saveUnit()"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="disabled === false"
        small
        outlined
        rounded
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('Create unit') }}
      </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import UnitDataDialog from './UnitDataDialog'
import BaseModal from './general/BaseModal'
import moment from 'moment'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { i18n } from '../plugins/i18n'

extend('validEndDate', {
  message: i18n.t("create_unit.start_after_end"),
  params: ['startDate', 'endDate'],
  validate: (_, { startDate, endDate }) => {
    if(endDate < startDate){
      return false
    }
    return true
  }
});

export default {
  components: { 
    UnitDataDialog, 
    BaseModal, 
    ValidationProvider, 
    ValidationObserver
  },
  props: {
    headers: {
      type: Array,
      default: () => null
    },
  },
  data () {
    return {
      disabled: true,
      loading: false,
      saving: false,
      valid: true,
      addUnitDialog: false,
      floorsDisabled: true,
      BuildingHasFloors: false,
      startDate: undefined,
      endDate: undefined,
      floorStartDate: undefined,
      indicatorMessage: '',
      currentUnit: {},
      addUnit: {
        idBuilding: null,
        idFloor: null,
        idUnit: null,
        area: null,
      },
      saveData: {
        building_code: '',
        name: '',
        area: '',
        start_date: undefined,
        end_date: undefined
      },
      availableTags: [],
    }
  },
  
  computed: {
    ...mapState('app', ['currentDate', 'unitMetaData', 'purposeZones', 'currentDate']),
    ...mapState('internalRent', ['purposesOfUse']),
    ...mapGetters('app', ['unitMetaData', 'hasApplicationPermissionByName']),
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite', 'floorsForCurrentBuilding', 'unitsForCurrentBuildingFloor',]),

    selectedDate () {
      let date = new Date(this.currentDate)
      date = new Date(date.setUTCHours(0,0,0,0)).toISOString().split('.')[0]
      return date
    },
    filteredMetaData () {
      let updatedMetaData = this.unitMetaData
      if (this.headers != null) {
        updatedMetaData = {}
        this.headers.forEach(header => {
          updatedMetaData[header.value] = this.unitMetaData[header.value]
        });
      }
      return updatedMetaData
    },
  },
  watch: {
    'addUnit.idBuilding': async function (selectedBuildingId) {
      if(this.addUnitDialog === false){
        this.addUnit.idBuilding = null
        this.floorsDisabled = true
        this.BuildingHasFloors = false
        return
      }
      if (selectedBuildingId!=='') {
        this.addUnit.idFloor = null
        this.addUnit.idUnit = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
        const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === selectedBuildingId).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
        this.BuildingHasFloors = !(this.floorsForCurrentBuilding && this.floorsForCurrentBuilding.length) ? true : false
        this.floorsDisabled = false
        this.floorStartDate = undefined
      }
    },
    'addUnit.idFloor': function (selectedFloorId) {
      if (this.selectedFloorId!=='') {
        this.addUnit.idUnit = null
        this.setUnitsForCurrentBuildingFloor([])
        if (selectedFloorId !== null) {
          this.loadUnitsForCurrentBuildingFloor(selectedFloorId)
        }
      }
    },
    startDate () {
      this.showMessage()
    }
  },
  async mounted () {
    if (this.hasApplicationPermissionByName('HUONEISTON_LISAYS')) {
      this.disabled = false
      }
      await this.getUnitMetaData()
        
      let date = this.selectedDate

      if(this.startDate === undefined || this.startDate === null){
        this.startDate = date
      }
    this.getTags()
  },
  methods: {
    ...mapActions('app', ['getUnitMetaData']),
    ...mapActions('sites', ['loadFloorsForCurrentBuilding', 'loadUnitsForCurrentBuildingFloor']),
    ...mapMutations('sites', ['setFloorsForCurrentBuilding', 'setUnitsForCurrentBuildingFloor']),
    setFloorStartDate () {
      this.floorStartDate = this.floorsForCurrentBuilding.find(floor => floor.id === this.addUnit.idFloor).start_date
      if (this.startDate < this.floorStartDate) {
        this.startDate = undefined;
      }
      this.showMessage()
    },
    cancel () {
      this.addUnit.idFloor = undefined
      this.addUnitDialog = false
      this.startDate = undefined
      this.endDate = undefined
      this.indicatorMessage = ''
      this.floorStartDate = undefined
      this.$refs.form.reset()
    },
    clearForm () {
      this.$refs.form.resetValidation()
      this.addUnit.idBuilding=''
      this.addUnit.idUnit=''
      this.addUnit.idFloor= null
      this.addUnit.area= null
      this.startDate = null
      this.endDate = undefined
      this.indicatorMessage = ''
      this.floorStartDate = undefined
      this.saveData = {}
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    showMessage () {
      let date = this.selectedDate
      this.indicatorMessage = ''

      if (date < this.startDate) {
        this.indicatorMessage = this.$t("create_unit.future")
      } 
      if (date > this.startDate) {
        this.indicatorMessage = this.$t("create_unit.past")
      } 
    },
    saveUnit ()
    {
      if (this.saving) {
        return
      }
      this.saveData.building_code = this.addUnit.idBuilding
      this.saveData.floor_id = this.addUnit.idFloor
      this.saveData.name = this.addUnit.idUnit
      this.saveData.start_date = this.startDate
      this.saveData.end_date = this.endDate

      if (this.filteredMetaData?.tags) {
        let tags = []
        if (this.saveData.tags != null && this.saveData.tags.length > 0) {
          this.saveData.tags.forEach(tag => {
            let updatedTag = null
            if (typeof tag === 'string') {
              updatedTag = { name: tag }
            } else {
              updatedTag = { ...tag }
            }

            if (updatedTag != null) {
              tags.push(updatedTag)
            }
          })
        }
        this.saveData.tags = tags
      }

      if (this.$refs.form.validate()) {
        const unitToSave = JSON.parse(JSON.stringify(this.saveData))
        this.saving = true
        this.$rambollfmapi.units
          .post(unitToSave)
          .then(res => {
              this.addUnitDialog = false
              this.clearForm()
              this.saving = false
              this.getTags()
              this.$emit('saved', true)
          })
          .catch(function (error) {
              // eslint-disable-next-line no-console
              console.log(error)
          })
      }
    },
    async getTags () {
      if (this.filteredMetaData?.tags) {
        this.availableTags = await this.$rambollfmapi.units.tags.get()
      }
    },
  }
}
</script>
<style scoped>
</style>
