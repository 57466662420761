export default {
    Dashboard () {
        return [
            {
                id: 'dashboard.organization_selected_sites',
                type: 'LazyTable',
                loading: true,
                data: {
                    headers: [
                        { text: 'User Organization', value: 'organization' },
                        { text: 'Site', value: 'site_name'},
                        { text: 'Net room area', value: 'room_area', format: 'Area'},
                        { text: 'Contract area', value: 'unit_contract_area', format: 'Area'},
                        { text: 'Net floor area', value: 'unit_area', format: 'Area'},
                        { text: 'Ktv1 Office Area', value: 'KTV1_office_area', format: 'Area'},
                        { text: 'Ktv1 Other Areas', value: 'KTV1_other_area', format: 'Area'}
                    ],
                    customStyles: null,
                    customFormatters: null,
                    icons: null,
                    links: {
                        site_name: function (row) {
                            return {
                                name: 'sites.info',
                                params: { id_site: row.id_site }
                            }
                        }
                    },
                    source: 'OrganizationSites'
                }
            }
        ]
    }
}