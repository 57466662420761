!<template>
  <div>
    <div v-if="corporation != null">
      <v-overlay :value="corporationRentalStatusesLoading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <BaseModal
          @cancel="close"
        >
          <template #title>
            <v-row align="center">
              <v-col>
                {{ corporation.corporation_name }}
                <v-icon 
                  v-if="corporation.key_account_customer" 
                  color="warning"
                >
                  star
                </v-icon>
              </v-col>
            </v-row>
          </template>
          <template #content>
            <p class="text-subtitle-2">
              {{ $t('leasing.corporation_card_info') }}
            </p>
            <div class="section-textfield">
              <h4 class="text--secondary">
                {{ $t('leasing.corporation_info_mfiles') + ':' }}
              </h4>
            </div>
            <v-text-field
              :value="
                corporation.corporation_name ? corporation.corporation_name : ' '
              "
              :label="$t('Name')"
              readonly
              class="form-field"
            />
            <v-text-field
              :value="corporation.business_id ? corporation.business_id : ' '"
              :label="$t('Business ID')"
              readonly
              class="form-field"
            />
            <v-text-field
              :value="corporation.tenant_status ? corporation.tenant_status : ' '"
              :label="$t('leasing.status')"
              readonly
              class="form-field"
            />
            <v-text-field
              :value="
                corporation.leasing_correspondent
                  ? corporation.leasing_correspondent
                  : ' '
              "
              :label="$t('Leasing correspondent')"
              readonly
              class="form-field"
            />
            <div class="my-5">
              <div class="section-textfield">
                <h4 class="text--secondary">
                  {{ $t('leasing.corporation_contact_details_mfiles') + ':' }}
                </h4>
              </div>
              <v-text-field
                :value="
                  corporation.corporation_contact
                    ? corporation.corporation_contact
                    : ' '
                "
                :label="$t('Corporation contact')"
                readonly
                class="form-field"
              />
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t('leasing.corporation_extra') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MetadataForm
                    :metadata="getCorporationExtra()"
                    :data="corporation"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div
              v-for="widget in widgets"
              :key="widget.id"
              :loading="false"
              class="mt-5"
            >
              <Notes
                v-if="widget.id === 'leasing.corporation_notebook'"
                :single-corporation="corporation"
                :maximized="widget.maximized"
              />
              <v-expansion-panels
                v-else-if="widget.id === 'site.rental_contracts'"
                v-model="contractExpand"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="py-1 pl-2">
                    {{ $t('site.rental_contracts') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ContractDataForm
                      :data="getWidgetContracts()"
                      :metadata="getContractMetadataForUnit()"
                      :site-title="true"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <DynamicWidget
                v-else
                :type="widget.type"
                :title="$t(widget.id)"
                :data="{
                  ...widget.data,
                  items: getWidgetData(widget.id),
                }"
                :loading="false"
                :maximized="widget.maximized"
              />
            </div>
          </template>
          <template #footer />
        </BaseModal>
      </v-card>
    </div>
    <!-- Empty modal card to make closing smoother -->
    <div v-else>
      <v-card>
        <v-btn
          large
          icon
          right
          absolute
          style="margin-top: 1em"
          @click="close"
        >
          <v-icon>close</v-icon>
          <span class="d-sr-only">{{ $t('Close') }}</span>
        </v-btn>
        <v-card-title
          class="text-h5 grey lighten-2 py-8"
          primary-title
        />
        <v-card-text class="empty-div">
          <v-progress-circular
            :size="50"
            style="margin: auto"
            color="primary"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { endingContractsGraph } from "../../helpers/leasing/contracts"
import MetadataForm from '../MetadataForm'
import ContractDataForm from '../Leasing/ContractDataForm'
import DynamicWidget from '../DynamicWidget'
import LeasingWidgets from '../../widgets/leasing'
import Notes from "./Notes.vue"
import BaseModal from '../general/BaseModal'
import { CapitalRentCalculator } from '../../helpers/leasing/capitalRent'
import { getRentalProcessTitle } from '../../helpers/leasing/rentalprocess'

export default {
  name: 'CorporationModal',
  components: { BaseModal, MetadataForm, ContractDataForm, DynamicWidget, Notes },
  props: {
    corporation: { type: Object, default: null },
    rentalProcesses: { type: Array, default: () => [] },
    isShown: { type: Boolean, default: false },
  },
  data () {
    return {
      contractExpand: false,
      widgets: [...LeasingWidgets.CorporationModal()],
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
    ...mapState('leasing', [
      'corporationRentalStatusesLoading',
      'corporationRentalStatuses'
    ]),
    ...mapState('app', ['userInfo', 'currentDate']),
    negotiationDefs () {
      return this.definitionsByGroupLabel('contract.negotiation_status')
    },
  },
  watch: {
    isShown: function (newValue) {
      // Modal is set to hidden.
      if (!newValue) {
        this.reset()
      }
    },
    corporation: async function (newValue) {
      if (newValue && newValue.id) {
        this.getCorporationRentalStatuses({corporationId: newValue.id, time: this.currentDate})
      }
    },
  },
  mounted () {

    if(this.corporation && this.corporation.id){
      this.getCorporationRentalStatuses({corporationId: this.corporation.id, time: this.currentDate})
    }

  },
  // We need to make sure corporation modal state is set to null when navigating from the modal to somewhere else on site
  beforeDestroy () {
    this.setShownCorporation(null)
  },
  methods: {
    ...mapActions('leasing', [
      'setShownCorporation',
      'getCorporationRentalStatuses',
    ]),
    close () {
      this.setShownCorporation(null)
    },
    reset () {
      Object.assign(this.$data, this.$options.data())
    },
    getContractMetadataForUnit () {
      return {
        contractUnits: {
          name: 'Units',
          format: 'None',
        },
        contract_party: {
          format: 'None',
          name: 'Contract party',
        },
        validity: {
          name: 'Type of contract validity',
          format: 'None',
          groupLast: true,
        },
        actualArea: {
          name: 'Rented Actual area',
          format: 'Area',
        },
        contract_area: {
          format: 'Area',
          name: 'Total contract area',
          groupLast: true,
        },
        signature_date: {
          name: 'Date of signing the contract',
          format: 'Date',
        },
        curPartyStartDate: {
          name: 'Start date',
          format: 'Date',
        },
        contractFirstPossibleEndDate: {
          name: 'Contract first possible end date',
          format: 'Date',
        },
        curPartyEndDate: {
          name: 'End date',
          format: 'Date',
          groupLast: true,
        },
        tenantNotice: {
          name: 'Agreement notice period (Tenant)',
          format: 'None',
        },
        landlordNotice: {
          name: 'Agreement notice period (Lanlord)',
          format: 'None',
        },
        huom: {
          name: 'Validity notice',
          format: 'TruthValue',
        },
        vat: {
          name: 'VAT-responsibility',
          format: 'YesNoUnknown',
          groupLast: true,
        },
        capitalRent: {
          name: 'Capital rent',
          format: 'EuroPerMonth',
        },
        capitalRentPerSquare: {
          name: 'Capital rent (€/m2/mth)',
          format: 'MoneyPerSquare'
        },
        maintenanceRent: {
          name: 'Maintenance rent',
          format: 'EuroPerMonth',
        },
        changeoverRent: {
          name: 'rental_status.changeover_rent',
          format: 'EuroPerMonth',
        },
        separateAllowances: {
          name: 'rental_status.separate_allowances',
          format: 'EuroPerMonth',
        },
        discounts: {
          name: 'rental_status.discounts',
          format: 'EuroPerMonth',
        },
        openBillsTotal: {
          name: 'rental_status.open_bills_total',
          format: 'Euro',
        },
        indexType: {
          name: 'rental_status.index_type',
        },
        minimumIncrease: {
          name: 'rental_status.minimum_increase',
          format: 'Percentage',
          groupLast: true,
        },
        contract_note: {
          name: 'Comments',
          format: 'None',
        },
      }
    },
    getCorporationExtra () {
      if (this.corporation.corporations_business_id) {
        this.corporation.corporations_business_id =
          this.corporation.corporations_business_id.replace(/&/g, ',')
      }
      if (this.corporation.corporation_contract_numbers) {
        this.corporation.corporation_contract_numbers =
          this.corporation.corporation_contract_numbers.replace(/&/g, ',')
      }
      // get selection
      return this.getDefaultMetadata(this.corporation)
    },
    getDefaultMetadata (obj) {
      return Object.keys(obj)
        .filter(
          (key) =>
            ![
              'id',
              'corporation_name',
              'business_id',
              'leasing_correspondent',
              'corporation_contact',
              'tenant_status',
            ].includes(key)
        )
        .reduce((obj, key) => {
          obj[key] = {
            category: 'None',
            isShown: 'true',
            type: 'String',
          }
          if (key === 'key_account_customer' || key === 'customership_secret') {
            obj[key].type = 'Boolean'
          }
          return obj
        }, {})
    },
    getWidgetData (widgetId) {
      const filteredProcesses = this.getWidgetProcesses()
      switch (widgetId) {
        case 'leasing.corporation_current_sites':
          return this.getWidgetSites()
        case 'leasing.corporation_processes':
          return filteredProcesses
        case 'leasing.corporation_contracts':
          return this.getRentalUnitsViewData()
        case 'site.leasing.ending_contracts_graph':
          return endingContractsGraph(this.corporationRentalStatuses, [], [], filteredProcesses, new Date(), null, this.negotiationDefs)
        default:
          break
      }
    },
    getWidgetProcesses () {
      const filteredProcesses = this.rentalProcesses.filter(
        (process) => process.id_corporation === this.corporation.id
      )
      return filteredProcesses.map((process) => {
        return {
          ...process,
          id_process: process.id_process ?? process.id,
          prospect_status: process.status === 'Inactive' ? process.status : process.stage,
          process_title: getRentalProcessTitle(process),
        }
      })
    },
    getWidgetSites () {
      const corpoContracts = this.corporationRentalStatuses

      const allContracts = corpoContracts
        .filter(rs => {
          const time = new Date(this.currentDate)
          const start = new Date(rs.curPartyStartDate)
          const end = new Date(rs.curPartyEndDate)
          return start <= time && (rs.curPartyEndDate === null || end >= time)
        })
        .map((rs) => {
          return {
            site_name: rs.siteName,
            unit_name: rs.unitName,
            unit_area: rs.area,
            unit_class: rs.unitUsage,
            current_tenant_name: rs.curPartyName,
            start_date: rs.curPartyStartDate,
            end_date: rs.curPartyEndDate,
            siteIdentifier: rs.siteId,
            unitIdentifier: rs.unitId,
          }
        })

      // Group by Site and current tenant
      const groupdContracts = allContracts.reduce((acc, cur) => {
        const key = cur.site_name + cur.current_tenant_name
        if (typeof acc[key] === 'undefined') {
          acc[key] = {
            site_name: cur.site_name,
            unit_name: [],
            unit_area: 0,
            unit_class: [],
            current_tenant_name: cur.current_tenant_name,
            start_date: [],
            end_date: [],
            siteIdentifier: cur.siteIdentifier,
            unitIdentifier: cur.unitIdentifier,
          }
        }

        acc[key].unit_name.push(cur.unit_name)
        acc[key].unit_class.push(cur.unit_class)
        acc[key].unit_area += cur.unit_area
        acc[key].start_date.push(cur.start_date)
        acc[key].end_date.push(cur.end_date)

        return acc
      }, {})

      return Object.keys(groupdContracts).map((k) => {
        const val = groupdContracts[k]
        val.unit_name = [...new Set(val.unit_name)]
        val.unit_class = [...new Set(val.unit_class)]
        if (val.start_date.length === 1) {
          val.start_date = val.start_date[0]
        }
        if (val.end_date.length === 1) {
          val.end_date = val.end_date[0]
        }
        return val
      })
    },
    getWidgetContracts () {
      const corpoContracts = this.corporationRentalStatuses
      const capitalRentCalculator = new CapitalRentCalculator()


      const corporationContracts = corpoContracts.map((rs) => {
        return {
          contractNumber: rs.contractNumber,
          contract_party: rs.curPartyName,
          contract_area: rs.contract_area,
          curPartyStartDate: rs.curPartyStartDate,
          contractFirstPossibleEndDate: rs.contractFirstPossibleEndDate,
          curPartyEndDate: rs.curPartyEndDate,
          tenantNotice: rs.tenantNotice,
          landlordNotice: rs.landlordNotice,
          huom: rs.huom,
          vat: rs.vat,
          capitalRent: rs.capitalRent,
          maintenanceRent: rs.maintenanceRent,
          changeoverRent: rs.changeoverRent,
          separateAllowances: rs.separateAllowances,
          discounts: rs.discounts,
          openBillsTotal: rs.openBillsTotal,
          indexType: rs.indexType,
          minimumIncrease: rs.minimumIncrease,
          contract_note: rs.contract_note,
          signature_date: rs.signature_date,
          validity: rs.validity,
          siteName: rs.siteName,
          unitName: rs.unitName,
          area: rs.area,
        }
      })

      let rows = []

      corporationContracts.forEach((contract) => {
        if (contract.contractNumber) {
          const index = rows.findIndex(
            (row) => row.contractNumber === contract.contractNumber
          )
          if (index >= 0) {
            rows[index].actualArea += contract.area
            rows[index].contractUnits += contract.unitName + ', '
          } else {
            let newContract = contract
            newContract.actualArea = contract.area
            newContract.contractUnits = contract.unitName + ', '
            rows.push(newContract)
          }
        }
      })

      rows.forEach((row) => {
        row.contractUnits = row.contractUnits.slice(0, -2)
        row.vat ? (row.vat = true) : (row.vat = false)
        row.capitalRentPerSquare = capitalRentCalculator.calculatePerSquare(row)
      })

      // Sort by contract number
      rows.sort((prev, next) =>
        prev.contractNumber.localeCompare(next.contractNumber)
      )
      return rows
    },
    getRentalUnitsViewData () {
      const rentalStatus = this.corporationRentalStatuses

      const data = rentalStatus.map(rs => {
        rs.signatureDate = rs.signature_date
        rs.vat === 1
          ? (rs.vat_status = 'Kyllä')
          : rs.vat === 0
            ? (rs.vat_status = 'Ei')
            : (rs.vat_status = '-')
        rs.huom = rs.huom ? this.$t('Considerations') : null
        return rs
      })

      const rows = []
      data.forEach(d => {
        if (d.contractNumber) {
          const index = rows.findIndex(row => row.contractNumber === d.contractNumber)
          if (index >= 0) {          
            const buildingIndex = rows[index].unitNames.findIndex(b => b.buildingCode === d.buildingCode)
            if (buildingIndex >= 0) {
              rows[index].unitNames[buildingIndex].units.push(d.unitName)
            } else {
              rows[index].unitNames.push({buildingCode: d.buildingCode, units: [d.unitName]})
            }

            
            if(d.unit_code_long && d.unit_code_long !== ""){
              rows[index].longUnitCodeList.push(d.unit_code_long)
            }

          } else {
            const newRow = {...d}
            newRow.unitNames = [{buildingCode: d.buildingCode, units: [d.unitName]}]
            newRow.longUnitCodeList = [d.unit_code_long]
            rows.push(newRow)
          }
        }
      })

      rows.forEach(row => {
        row.unitNameList = row.unitNames.reduce((a,b) => {
          let text = a
      
          const buildingCodeText = b.buildingCode ? b.buildingCode + ":" : ""

          return `${text}${buildingCodeText} ${b.units.join(', ')}`
        }, '')
        
        row.longUnitCodes = row.longUnitCodeList.join(",\n")

      })
      return rows
    },
  },
}
</script>

<style scoped>
.empty-div {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
