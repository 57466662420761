var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":""},model:{value:(_vm.showTableDataDialog),callback:function ($$v) {_vm.showTableDataDialog=$$v},expression:"showTableDataDialog"}},[_c('v-card',[_c('div',[_c('v-btn',{staticStyle:{"margin-top":"0.8em"},attrs:{"large":"","icon":"","absolute":"","right":""},on:{"click":function($event){_vm.showTableDataDialog = false}}},[_c('v-icon',[_vm._v("close")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t('Close')))])],1),_c('v-card-title',{staticStyle:{"width":"80%"},attrs:{"primary-title":""}},[_c('h1',[_vm._v(" "+_vm._s(_vm.tableData.title)+" ")])])],1),_c('Table',{attrs:{"rows":_vm.tableData.rows,"headers":_vm.tableData.headers,"footers":_vm.createFooters,"footer-definitions":_vm.tableData.footers,"pagination":_vm.viewDataOptions},on:{"update:pagination":function($event){_vm.viewDataOptions=$event}}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"header-props":_vm.dataTableHeaderDefinitions,"headers":_vm.data.headers.map(h => {
      return {
        text: _vm.$t(h.text),
        value: h.value
      }
    }),"items":_vm.items,"options":_vm.options,"item-key":'parentId',"footer-props":{
      itemsPerPageOptions: [],
    },"no-data-text":_vm.$t('No data available'),"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_vm._l((_vm.data.headers),function(header){return [(header.value === 'site_name' || header.value === 'name' || header.value === 'site' || header.value === 'siteName')?_c('td',{key:header.value,staticClass:"linked",class:{ 'col-align-right': _vm.isRightAligned(header.format) },on:{"click":function($event){return _vm.linkTo(props.item, 'sites.info')}}},[_vm._v(" "+_vm._s(_vm.formatData(props.item, header.value, header.format, true))+" ")]):(header.value !== 'toggle')?_c('td',{key:header.value,class:{ 'col-align-right': _vm.isRightAligned(header.format) }},[_vm._v(" "+_vm._s(_vm.formatData(props.item, header.value, header.format))+" ")]):_c('td',{key:header.value,staticClass:"no-padding"},[(props.item.showToggle === true)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){props.item.expanded = !props.item.expanded}}},[(props.item.expanded)?_c('span',[_c('v-icon',[_vm._v("keyboard_arrow_up")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(`${_vm.$t('Show less')}`))])],1):_c('span',[_c('v-icon',[_vm._v("keyboard_arrow_down")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(`${_vm.$t('Show more')}`))])],1)]):_vm._e()],1)]})],2),_vm._l((_vm.childItems.filter(child => child.parentId === props.item.parentId)),function(child,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(props.item.expanded),expression:"props.item.expanded"}],key:child.id + index,staticClass:"no-padding"},_vm._l((_vm.data.headers),function(header){return _c('td',{key:header.value + child.id + props.item.id,staticClass:"sub-item",class:{
            'col-align-right': _vm.isRightAligned(header.format),
            'linked': header.unitLink,
          },on:{"click":function($event){{ if (header.unitLink) _vm.subitemLinkTo(child, 'unit') }}}},[_vm._v(" "+_vm._s(header.value !== 'toggle' ? _vm.formatData( child, header.value, header.format) : '')+" ")])}),0)})]}},{key:"footer.page-text",fn:function(item){return [_c('v-btn',{attrs:{"small":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.viewData(_vm.title, _vm.data.headers, _vm.data.items)}}},[_vm._v(" "+_vm._s(_vm.$t('Browse'))+" ")]),_vm._v(" "+_vm._s(item.pageStart)+"-"+_vm._s(item.pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(item.itemsLength.toLocaleString('fi-FI'))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.data.footers),function(footer,idx){return _c('td',{key:idx,class:{ 'col-align-right': _vm.isRightAligned(footer.format) }},[_vm._v(" "+_vm._s(footer.numeratorSum === undefined ? _vm.getFooterValue(_vm.data.items, footer.value, footer.text, footer.format, footer.average) : _vm.getGroupedQuotientValue(_vm.data.items, footer))+" ")])}),0)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }