!
<template>
  <!-- View for creating a renegotiations prospect -->
  <v-card v-if="contract && !contract.no_renegotiations_modal">
    <BaseModal @cancel="close">
      <template #title>
        <span v-if="contract && !contract.type_renewal">{{
          $t("leasing.begin_renegotiations")
        }}</span>
        <span v-else>{{ $t("leasing.begin_renewal") }}</span>
      </template>
      <template #content>
        <validation-observer ref="observer">
          <v-autocomplete
            v-model="selectedUnits"
            :label="$t('Units')"
            item-text="unit_code"
            item-value="id"
            :items="allUnits"
            multiple
            small-chips
            hide-details
            return-object
            class="my-6 location-selector"
            :loading="unitsLoading"
            :disabled="unitsLoading"
          >
            <template #selection="data">
              <v-chip
                :key="JSON.stringify(data.id)"
                class="v-chip--select-multi"
                close
                @click.stop
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item.unit_code }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="prospectResponsible"
            :label="$t('prospect.primary_responsible')"
            :items="
              leasingUsers.filter(
                (user) => user != secondaryProspectResponsible
              )
            "
            item-text="name"
            item-value="id_user"
            return-object
            :loading="usersLoading"
            class="my-5"
          />
          <v-autocomplete
            v-model="secondaryProspectResponsible"
            :label="$t('prospect.secondary_responsible')"
            :items="leasingUsers.filter((user) => user != prospectResponsible)"
            item-text="name"
            item-value="id_user"
            return-object
            clearable
            class="form-fields mb-5"
          />
          <validation-provider
            v-slot="{ errors }"
            name="Description"
            rules="required"
          >
            <v-autocomplete
              v-model="stage"
              :label="$t('leasing.rental_process_stage') + ' *'"
              :items="
                stages.map((stage) => ({ ...stage, label: $t(stage.label) }))
              "
              :error-messages="$t(errors)"
              item-text="label"
              item-value="id"
              class="form-fields mb-5"
            />
          </validation-provider>
        </validation-observer>
        <v-progress-linear
          v-if="partiesLoading"
          indeterminate
          color="primary"
        />
        <p
          v-if="leaseResponsible != null && partiesLoading === false"
          class="text-subtitle-1"
        >
          <span class="font-weight-bold">{{ $t("leasing.site_lease_responsible") }}:</span>
          {{ leaseResponsible }}
        </p>
      </template>
      <template #footer>
        <v-btn
          rounded
          depressed
          class="primary"
          :disabled="usersLoading || unitsLoading || stage === null"
          :loading="prospectSaving"
          @click="save"
        >
          {{ $t("Yes") }}
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
  <!-- View for choosing no renegotiations -->
  <v-card v-else>
    <no-renegotiations-modal
      type="RENTAL_PROCESS"
      :contract="contract"
      :prospect-saving="prospectSaving"
      @close="close"
      @save="save($event)"
    />
  </v-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { mapActions, mapGetters, mapState } from "vuex"
import BaseModal from "../general/BaseModal"
import NoRenegotiationsModal from "./NoRenegotiationsModal.vue"

export default {
  name: "RenegotiationsModalProcess",
  components: { BaseModal, ValidationObserver, NoRenegotiationsModal, ValidationProvider },
  props: {
    contract: {
      type: Object,
      default: null,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      prospectResponsible: null,
      secondaryProspectResponsible: null,
      allUnits: [],
      selectedUnits: [],
      unitsLoading: false,
      usersLoading: false,
      partiesLoading: false,
      prospectSaving: false,
      unitPromise: null,
      stage: null,
    }
  },
  computed: {
    ...mapGetters("leasing", ["leasingUsers"]),
    ...mapGetters("app", ["definitionByLabel", "definitionsByGroupLabel"]),
    ...mapState("app", ["sites", "currentDate"]),
    ...mapState("sites", ["loadedParties", "loadedPartyLinks"]),
    leaseResponsible () {
      const resposibleDefId = this.definitionByLabel(
        "Vuokravastuu (työntekijä)"
      )?.id
      const partyLinks = this.loadedPartyLinks
        .filter((partyLink) => partyLink.id_annotation === resposibleDefId)
        .map((partyLink) => partyLink.id_party)
      if (partyLinks.length > 0) {
        return this.loadedParties
          .filter((party) => partyLinks.includes(party.id))
          .map((party) => party.name)
          .join(", ")
      }
      return null
    },
    stages () {
      const stages = this.definitionsByGroupLabel(
        "leasing.rental_process_stage"
      )

      return stages.filter((stage) => stage.label[0] < 6)
    },
  },
  watch: {
    isShown: async function (shown) {
      if (!shown) {
        this.reset()
      } else {
        this.init()
      }
    },
    leasingUsers: function (newValue) {
      this.prospectResponsible = newValue.find(
        (item) => item.id_user === this.userInfo.id
      )
    },
  },
  async mounted () {
    this.init()
  },
  methods: {
    ...mapActions("leasing", [
      "changeRenegotiationsModalState",
      "getLeasingUsers",
      "saveNewProspect",
      "updateRentalProcess",
      "updateContractData",
    ]),
    ...mapActions("rentalProcess", ["updateRentalProcess"]),
    ...mapActions("sites", ["loadSiteParties"]),

    init () {
      this.unitsLoading = true
      this.usersLoading = true
      this.getLeasingUsers().finally(() => (this.usersLoading = false))
      this.partiesLoading = true
      this.loadSiteParties(this.contract.id_site).finally(
        () => (this.partiesLoading = false)
      )
      this.unitPromise = this.getUnitsForSite()
    },

    close () {
      this.changeRenegotiationsModalState(null)
    },
    reset () {
      if (this.$refs.observer) {
        this.$refs.observer.reset()
      }
      Object.assign(this.$data, this.$options.data())
    },
    async save (data) {
      this.prospectSaving = true
      await this.unitPromise

      // If tenant is not a corporation, we have to fetch the actual tenant corporation based on contract number
      let osapuoli
      if (this.contract.tenant_corporation) {
        osapuoli = this.contract.id_tenant
      } else {
        const tenant =
          await this.$rambollfmapi.parties.tenantCorporation.tenantCorporationByContract(
            this.contract.contractNumber
          )
        if (tenant.id == null) {
          alert(
            `${this.$t("Error!")} ${this.$t(
              "leasing.tenant_corporation_not_found"
            )}`
          )
          this.close()
          return
        }
        osapuoli = tenant.id
      }

      const prospect = {
        corporation_id: osapuoli,
        lead_origin: this.definitionByLabel("Olemassa oleva vuokrasuhde").id,
        responsible: this.prospectResponsible
          ? this.prospectResponsible.id_user
          : this.userInfo.id,
        secondary_responsible: this.secondaryProspectResponsible
          ? this.secondaryProspectResponsible.id_user
          : null,
        renegotiations: true,
        stage: this.stage,
        contract_id: this.contract.contractId,
      }

      if(this.contract.endDate){
        prospect.estimated_completion = this.contract.endDate
      } else if (this.contract.contractFirstPossibleEndDate) {
        prospect.estimated_completion = this.contract.contractFirstPossibleEndDate
      }

      if (!prospect.stage) {
        prospect.stage = this.stages[0].id
      }

      // In case we're choosing no renegotiations, also add passed status, reason and passed date
      if (this.contract.no_renegotiations_modal) {
        prospect.status = 1
        prospect.status_description = data.inactivationReasonChoice.id
        prospect.passed_date = new Date(data.passedDate)
      }

      await this.addProspect(prospect)
    },
    async getUnitsForSite () {
      let siteUnits = []

      const buildings = await this.$rambollfmapi.buildings.list({
        query: { siteId: this.contract.id_site },
      })

      for (let i = 0; i < buildings.length; i++) {
        const floors = await this.$rambollfmapi.buildings
          .floors(buildings[i].building_code)
          .list()

        for (let j = 0; j < floors.length; j++) {
          const units = await this.$rambollfmapi.floors
            .units(floors[j].id, this.currentDate)
            .extra()

          siteUnits = siteUnits.concat(units)
        }
      }

      const newSelectedUnits = []

      if (this.contract) {
        this.contract.units.forEach((unit) => {
          const foundUnit = siteUnits.find((item) => item.id == unit.id)
          if (foundUnit) {
            newSelectedUnits.push(foundUnit)
          }
        })
        this.selectedUnits = newSelectedUnits
      }

      this.allUnits = siteUnits
      if (newSelectedUnits.length > 0) {
        this.unitsLoading = false
      }
    },
    getProcessSites () {
      const siteUnits = this.selectedUnits.map((unit) => {
        return { id_unit: unit.id }
      })

      return [
        {
          id_site: this.contract.id_site,
          units: siteUnits,
        },
      ]
    },

    async addProspect (prospect) {
      try {
        const processId = await this.$rambollfmapi.leasing.rentalProcesses.post(
          prospect
        )

        if (!processId) {
          throw Error("Creating process failed")
        }

        const [rentalProcess] =
          await this.$rambollfmapi.leasing.rentalProcesses.get(
            null,
            processId,
            null
          )

        // Let's also update the sites.
        rentalProcess.sites = this.getProcessSites()

        const cities = this.sites.find(
          (site) => site.id_site === this.contract.id_site
        )?.municipality
        rentalProcess.cities = [cities]
        rentalProcess.estimated_completion = prospect.estimated_completion

        await this.updateRentalProcess({ rentalProcess })

        this.updateContractData({
          contractId: this.contract.contractId,
          prospect: { id_prospect: processId },
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        alert(this.$t("error.unexpected"))
      }
      this.$emit("update")
      this.prospectSaving = false
      this.close()
    },
  },
}
</script>

<style scoped>
.location-selector {
  height: auto !important;
}
::v-deep .v-select__selections {
  min-height: 75px !important;
}
</style>
