<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid }"
    >
      <AriaLive />
      <BaseModal
        @cancel="close()"
      >
        <template #title>
          {{ modalTitle }}
        </template>
        <template #content>
          <v-form>
            <v-row>
              <v-col cols="12">
                <div 
                  v-if="loading"
                  class="is-loading"
                >
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  />
                </div>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="selectedSite"
                    :items="sites"
                    item-text="name"
                    class="form-fields my-2"
                    :label="$t('Site')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    return-object
                    clearable
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="selectedSite">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedBuilding"
                  :items="filteredBuildings"
                  item-text="building_name"
                  class="form-fields my-2"
                  :label="$t('Building')"
                  return-object
                  clearable
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedStructures"
                  :items="filteredStructures"
                  item-text="name"
                  item-value="id"
                  class="form-fields my-2"
                  :label="$t('Structures')"
                  clearable
                  multiple
                  return-object
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedBuilding">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedFloor"
                  :items="filteredFloors"
                  item-text="floor_name"
                  class="form-fields my-2"
                  :label="$t('Floor')"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedFloor">
              <v-col cols="12">
                <v-select
                  v-model="selectedUnits"
                  :items="filteredUnits"
                  item-text="unit_code"
                  class="form-fields my-2 height-auto"
                  :label="$t('Units')"
                  return-object
                  multiple
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="selectedPaymentDomain"
                    :items="rentPaymentDomains"
                    item-text="fullName"
                    class="form-fields my-2 mb-2"
                    :label="$t('create_rent_contract.payment_domain')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    :return-object="true"
                    @change="onSumsShouldChange"
                  >
                    <template #selection="{ item, index }">
                      {{ item.name ? $t(`${item.name}`): "" }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                    </template>
                    <template #item="{item, index}">
                      {{ $t(`${item.name}`) }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-text-field
                    v-model="netSum"
                    class="form-fields my-2"
                    :label="$t('Payments net sum')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    @input="onSumsShouldChange"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="grossSum"
                  :label="$t('create_rent_contract.gross_sum')"
                  class="form-fields my-2"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="taxSum"
                  :label="$t('create_rent_contract.tax')"
                  class="form-fields my-2"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="isDiscountPayment"
                  :label="$t('create_rent_contract.payment_discount')"
                  class="form-fields mt-2"
                  @change="onSumsShouldChange"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="paymentLegend"
                  :label="$t('create_rent_contract.payment_legend')"
                  class="form-fields mt-0 mb-2"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-col
            cols="4"
            class="text-right align"
          >
            <small v-if="invalid">{{ $t('FillRequiredFields') }}</small>
            <v-btn
              rounded
              depressed
              color="accent"
              :disabled="invalid"
              @click="addInvoiceRow"
            >
              {{ addButtonText }}
            </v-btn>
          </v-col>
        </template>
      </BaseModal>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BaseModal from '../general/BaseModal'
import AriaLive from '../AriaLive.vue'

export default {
  name: "NewHandInvoiceRowModal",
  components: {
    BaseModal,
    AriaLive,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    contractId: {
      type: Number,
      default: undefined,
    },
    invoiceRowToEdit: {
      type: Object,
      default: undefined,
    },
    rentPaymentDomains: {
      type: Array,
      default: function () {
        return []
      }
    },
    contractRows: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data () {
    return {
      selectedSite: undefined,
      selectedBuilding: undefined,
      selectedFloor: undefined,
      selectedUnits: [],
      selectedStructures: [],
      filteredBuildings: undefined,
      filteredFloors: undefined,
      filteredUnits: [],
      filteredStructures: [],
      selectedPaymentDomain: undefined,
      netSum: undefined,
      grossSum: undefined,
      taxSum: undefined,
      isDiscountPayment: false,
      paymentLegend: undefined,
      loading: false,
    }
  },
  computed: {
    ...mapState('app', ['sites', 'userInfo']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionByLabel']),
    modalTitle () {
      return this.invoiceRowToEdit ? this.$t('Edit hand invoice row') : this.$t('New hand invoice row')
    },
    addButtonText () {
      return this.invoiceRowToEdit ? this.$t('Save') : this.$t('Add')
    },
  },
  watch: {
    selectedSite (newId) {
      if (newId !== undefined) {
        this.getBuildingsBySiteId()
        this.getStructuresBySiteId()
      }
      this.selectedBuilding = undefined
      this.selectedFloor = undefined
      this.selectedUnits = undefined
    },
    selectedBuilding (newId) {
      if (newId !== undefined) {
        this.getFloorsByBuildingId()
      }
      this.selectedFloor = undefined
      this.selectedUnits = []
    },
    selectedFloor (newId) {
      if (newId !== undefined) {
        this.getUnitsByFloorId()
      }
      this.selectedUnits = []
    },
  },
  mounted () {
    if (this.contractRows?.length > 0 && !this.invoiceRowToEdit) {
      this.loadContractData()
    }
    if (this.invoiceRowToEdit) 
      this.copyDataFromInvoiceRow()
  },
  methods: {
    async getBuildingsBySiteId () {
      if (this.selectedSite) {
        this.filteredBuildings = await this.$rambollfmapi.buildings.list({ query: { siteId: this.selectedSite.id_site } })
      }
    },
    async getFloorsByBuildingId () {
      if (this.selectedBuilding) {
        this.filteredFloors = await this.$rambollfmapi.buildings.floors(this.selectedBuilding.building_code).list()
      }
    },
    async getUnitsByFloorId () {
      if (this.selectedFloor) {
        this.filteredUnits = await this.$rambollfmapi.floors.units(this.selectedFloor.id).list()
      }
    },
    async getStructuresBySiteId () {
      this.filteredStructures = await this.$rambollfmapi.structures.list({ query: { siteId: this.selectedSite.id_site } })
      for(let filteredStructureIndex in this.filteredStructures)
      {
        this.filteredStructures[filteredStructureIndex].id = this.filteredStructures[filteredStructureIndex].structure_id
        this.filteredStructures[filteredStructureIndex].name = this.filteredStructures[filteredStructureIndex].structure_name
      }
    },
    close () {
      this.$emit('close')
    },
    async onSumsShouldChange () {
      await this.calculateSums()
    },
    async calculateSums () {
      if (this.selectedPaymentDomain === undefined || this.netSum === undefined) {
        this.grossSum = undefined
        this.taxSum = undefined
        return
      }

      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === this.selectedPaymentDomain.id)
      
      if (paymentDomain?.[0] && this.netSum != '') {
        if (this.netSum != undefined && this.netSum.toString().includes(",")) {
          this.netSum = this.netSum.replace(",", ".")
        }

        let netSum = this.isDiscountPayment ? -Math.abs(this.netSum) : this.netSum
        this.netSum = netSum

        if (!isNaN(netSum)) {
          const sums = await this.$rambollfmapi.rentpayments.payments.calculateSums(paymentDomain[0], netSum, [0], false)
          this.grossSum = sums.grossSum
          this.taxSum = sums.tax
        } else {
          this.grossSum = 0;
          this.taxSum = 0;
        }
      } else {
        this.grossSum = 0
        this.taxSum = 0
      }
    },
    clearData () {
      this.loading = true
      this.selectedSite = undefined
      this.selectedBuilding = undefined
      this.selectedFloor = undefined
      this.selectedUnits = []
      this.filteredBuildings = undefined
      this.filteredFloors = undefined
      this.filteredUnits = []
      this.selectedPaymentDomain = undefined
      this.netSum = undefined
      this.grossSum = undefined
      this.taxSum = undefined
      this.isDiscountPayment = false
      this.paymentLegend = undefined
      this.loading = false
    },
    async copyDataFromInvoiceRow () {
      this.loading = true
      this.selectedSite = await this.sites?.find(s => s.id_site === this.invoiceRowToEdit.site?.id_site)
      await this.getBuildingsBySiteId()
      this.selectedBuilding = await this.filteredBuildings?.find(b => b.id_building === this.invoiceRowToEdit.building?.id_building)
      await this.getFloorsByBuildingId()
      this.selectedFloor = await this.filteredFloors?.find(f => f.id === this.invoiceRowToEdit.floor?.id)
      await this.getUnitsByFloorId()
      this.selectedUnits = undefined
      if (this.invoiceRowToEdit.units !== undefined) {
        this.selectedUnits = await this.filteredUnits.filter(u1 => this.invoiceRowToEdit.units.some(u2 => u1.id === u2.id))
        for(let iSelectedUnit in this.selectedUnits) {
          this.selectedUnits[iSelectedUnit].name = this.selectedUnits[iSelectedUnit].unit_code
        }
      }
      if (this.invoiceRowToEdit.structures !== undefined)
        this.selectedStructures = await this.filteredStructures.filter(u1 => this.invoiceRowToEdit.structures.some(u2 => u1.id === u2.id))
      this.selectedPaymentDomain = this.rentPaymentDomains.filter(d => d.id === this.invoiceRowToEdit.rentPaymentDomain.id )[0]
      this.netSum = this.invoiceRowToEdit.netSum
      this.grossSum = this.invoiceRowToEdit.grossSum
      this.taxSum = this.invoiceRowToEdit.taxSum
      this.isDiscountPayment = this.invoiceRowToEdit.isDiscountPayment
      this.paymentLegend = this.invoiceRowToEdit.legend
      this.loading = false
    },
    addInvoiceRow () {
      for(let iSelectedUnit in this.selectedUnits) {
        this.selectedUnits[iSelectedUnit].name = this.selectedUnits[iSelectedUnit].unit_code
      }
      const invoiceRow = {
        site: this.selectedSite,
        building: this.selectedBuilding,
        floor: this.selectedFloor,
        units: this.selectedUnits,
        structures: this.selectedStructures,
        rentPaymentDomain: this.selectedPaymentDomain,
        netSum: this.netSum,
        grossSum: this.grossSum,
        taxSum: this.taxSum,
        isDiscountPayment: this.isDiscountPayment,
        legend: this.paymentLegend,
      }
      if (this.invoiceRowToEdit) {
        this.$emit('updateInvoiceRow', invoiceRow)
      } else {
        this.$emit('addInvoiceRow', invoiceRow)
      }
      this.close()
    },
    async loadContractData () {
      if (this.contractRows.length > 0) {
        this.loading = true
        this.selectedSite = this.sites?.find(s => s.id_site === this.contractRows[0].siteId) ?? undefined
        await this.getBuildingsBySiteId()
        this.selectedBuilding = this.filteredBuildings?.find(b => this.contractRows.some(
          r => b.id_building === r.buildingId)) ?? undefined
        await this.getFloorsByBuildingId()
        this.selectedFloor = this.filteredFloors?.find(f => this.contractRows.some(r => f.id === r.floorId)) ?? undefined
        await this.getUnitsByFloorId()
        const floorUnits = this.contractRows.filter(row => row.floorId === this.selectedFloor?.id)
        this.selectedUnits = this.filteredUnits.filter(u1 => floorUnits.some(u2 => u1.id === u2.unitId))
        this.selectedStructures = this.filteredStructures.filter(s1 => this.contractRows.some(s2 => s1.id === s2.structureId))
        this.loading = false
      }
    }
  }
}
</script>
  
<style scoped>
.is-loading {
  position: absolute;
  inset: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.height-auto {
  height: auto !important;
}
</style>
    