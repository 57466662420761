<template>
  <div 
    :class="`c-alert c-alert--${size} c-alert--${type}`"
    aria-live="polite"
  >
    <template v-if="size === 'large'">
      <v-icon 
        class="c-alert__icon"
        size="40"
      >
        {{ icon }}
      </v-icon>
      <div class="c-alert__content">
        <strong
          v-if="title"
          class="c-alert__title"
        >
          {{ title }}
        </strong>
        <span 
          v-if="description"
          class="c-alert__description"
        >
          {{ description }}
        </span>
      </div>
      <slot />
    </template>
    
    <v-alert
      v-if="size === 'medium'"
      :icon="icon"
      :dismissible="dismissible"
    >
      <v-row align="center">
        <v-col class="grow">
          <div class="c-alert__content">
            <strong
              v-if="title"
              class="c-alert__title"
            >
              {{ title }}
            </strong>
            <span 
              v-if="description"
              class="c-alert__description"
            >
              {{ description }}
            </span>
            <slot />
          </div>
        </v-col>
        <v-col class="shrink">
          <slot name="action" />
        </v-col>
      </v-row>
    </v-alert>

    <template v-if="size === 'small'">
      <v-icon 
        class="c-alert__icon"
        size="16"
      >
        {{ icon }}
      </v-icon>
      <div class="c-alert__content">
        <strong
          v-if="title"
          class="c-alert__title"
        >
          {{ title }}
        </strong>
        <span 
          v-if="description"
          class="c-alert__description"
        >
          {{ description }}
        </span>
      </div>
      <slot />
    </template>
  </div>
</template>
<script>
export default {
  name: 'CdsAlert',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'medium'
    },
    icon: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    dismissible: {
      type: Boolean,
      default: false
    },
  },
}
</script>
<style lang="scss" scoped>
// Alert
.c-alert {
  border-radius: var(--r-radius-large);
  display: flex;
}

.c-alert ::v-deep .v-alert__dismissible {
  margin: var(--r-spacing-xxs);
}

.c-alert ::v-deep .v-btn.v-btn--icon,
.c-alert ::v-deep .v-icon {
  color: inherit;
}

.c-alert__content {
  display: flex;
  gap: var(--r-spacing-xs);
}

// Primary
.c-alert--primary {
  color: var(--r-color-ocean-100);
}

// Tertiary
.c-alert--tertiary {
  color: var(--r-color-mountain-100);
}
.c-alert--tertiary ::v-deep .v-icon {
  color: var(--c-color-accent);
}

// Danger
.c-alert--danger {
  color: var(--r-color-heath-100);
}

// Large
.c-alert--large {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--r-spacing-xxl);
  margin-bottom: var(--r-spacing-m);
  font-size: var(--r-font-size-m);
}
.c-alert--large.c-alert--primary {
  background-color: var(--r-color-cyan-10);
  color: var(--r-color-ocean-100);
  border: none;
}
.c-alert--large.c-alert--tertiary {
  background-color: var(--r-color-mountain-5);
  border: none;
}
.c-alert--large.c-alert--danger {
  background-color: var(--r-color-field-20);
  border: none;
}
.c-alert--large .c-alert__content {
  display: flex;
  flex-direction: column;
  margin: var(--r-spacing-s) 0;
}

// Medium
.c-alert .v-alert {
  border-left: var(--r-sizing-xxs) solid var(--r-color-ocean-100);
  width: 100%;
}
.c-alert--primary .v-alert {
  background-color: var(--r-color-cyan-10);
  border-color: var(--r-color-ocean-100);
  color: var(--r-color-ocean-100);
}
.c-alert--tertiary .v-alert {
  background-color: var(--r-color-mountain-5);
  border-color: var(--r-color-mountain-100);
  color: var(--r-color-mountain-100);
}
.c-alert--danger .v-alert {
  background-color: var(--r-color-field-20);
  border-color: var(--r-color-heath-100);
  color: var(--r-color-heath-100);
}

// Small
.c-alert--small {
  display: flex;
  align-items: center;
  gap: var(--r-spacing-xs);
  font-size: var(--r-font-size-s);
  margin: var(--r-spacing-s) 0;
}
.c-alert--small.c-alert--primary {
  background-color: transparent;
  color: var(--r-color-ocean-100);
}
.c-alert--small.c-alert--tertiary {
  background-color: transparent;
  color: var(--r-color-mountain-100);
}
.c-alert--small.c-alert--danger {
  background-color: transparent;
  color: var(--r-color-heath-100);
}
.c-alert--small .v-icon {
  align-self: flex-start;
  height: var(--r-sizing-l);
}

</style>
