<template>
  <v-container class="px-4 py-5 font-weight-regular">
    <v-row
      justify="start"
      align="start"
    >
      <v-col
        v-for="(key, index) in Object.keys(metadata)"
        :key="index"
        :md="metadata[key].cols"
        class="px-2 py-2 d-flex justify-start align-center"
        sm="12"
      >
        <v-text-field
          v-if="metadata[key].editType === 'TextField'"
          :value="values[key]"
          :label="$t(metadata[key].name)"
          :maxlength="metadata[key].maxlength"
          @blur="event => dataChanged(event.target.value, key)"
        />
        <v-text-field
          v-if="metadata[key].editType === 'Number'"
          :value="values[key]"
          :readonly="metadata[key].readonly"
          :suffix="metadata[key].suffix"
          :label="$t(metadata[key].name)"
          type="number"
          @blur="event => dataChanged(event.target.value, key)"
        />
        <AreaField
          v-if="metadata[key].editType === 'Area'"
          :value="values[key]"
          :label="$t(metadata[key].name)"
          :readonly="metadata[key].readonly"
          :suffix="metadata[key].suffix"
          @blur="value => dataChanged(value, key)"
        />
        <CurrencyField 
          v-if="metadata[key].editType === 'Currency'"
          :value="values[key]"
          :label="$t(metadata[key].name)"
          :readonly="metadata[key].readonly"
          :per-unit="metadata[key].suffix"
          :currency="selectedCurrency"
          @blur="value => dataChanged(value, key)"
        />
        <v-autocomplete
          v-if="metadata[key].editType === 'Multiselect'"
          :value="values[key]"
          :items="metadata[key].items"
          :label="$t(metadata[key].name)"
          multiple
          clearable
          small-chips
          hide-details
          item-text="label"
          item-value="id"
          @change="value => dataChanged(value, key)"
        >
          <template #prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="selectAll(key)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Choose all') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
          <template #selection="selectItem">
            <v-chip
              :key="JSON.stringify(selectItem.id)"
              class="v-chip--select-multi"
              close
              @click.stop
              @click:close="selectItem.parent.selectItem(selectItem.item)"
            >
              <span class="truncate">{{ selectItem.item.label }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
        <DateSelector
          v-if="metadata[key].editType === 'Date'"
          :label="metadata[key].name"
          :info="metadata[key].info"
          :value="values[key]"
          :type="metadata[key].dateType"
          :status="metadata[key].status"
          :disabled="metadata[key].readonly"
          @change="value => dataChanged(value, key)"
        />
        <v-autocomplete
          v-if="metadata[key].editType === 'Select'"
          :value="values[key]"
          :items="metadata[key].items"
          :label="$t(metadata[key].name)"
          :readonly="metadata[key].readonly"
          hide-details
          item-text="label"
          item-value="id"
          @change="value => dataChanged(value, key)"
        >
          <template #selection="{ item }">
            {{ $t(`${item.label}`) }}
          </template>
          <template #item="{ item }">
            {{ $t(`${item.label}`) }}
          </template>
        </v-autocomplete>
        <v-checkbox
          v-if="metadata[key].editType === 'Checkbox'"
          :value="values[key]"
          :label="$t(metadata[key].name)"
          hide-details="true"
          class="ma-2"
          @change="value => dataChanged(value == null ? false : true, key)"
        />
        <v-textarea
          v-if="metadata[key].editType === 'TextArea'"
          :value="values[key]"
          :label="$t(metadata[key].name)"
          :maxlength="metadata[key].maxlength"
          :rows="metadata[key].rows"
          @blur="event => dataChanged(event.target.value, key)"
        />
        <DateSelector
          v-if="metadata[key].child != null && values[key]"
          :value="values[metadata[key].child.key]"
          :label="$t(metadata[key].child.name)"
          @change="value => dataChanged(value, metadata[key].child.key)"
        />
        <RangeSlider  
          v-if="metadata[key].editType === 'RangeSlider'"
          :values="[values[metadata[key].lowerValue], values[metadata[key].upperValue]]"
          :metadata="metadata[key]"
          @change="value => sliderDataChanged(value, metadata[key])" 
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateSelector from './DateSelector.vue'
import RangeSlider from './RangeSlider.vue'
import CurrencyField from '../CurrencyField.vue'
import AreaField from '../AreaField.vue'

export default {
  name: 'FieldGroup',
  components: {
    DateSelector,
    RangeSlider,
    CurrencyField,
    AreaField,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    metadata: {
      type: Object,
      default: () => {}
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
    index: {
      type: Number,
      default: null
    }
  },
  emits: ['changeValue'],
  data () {
    return {
      values: {}
    }
  },
  watch: {
    item: {
      handler: function (newValue) {
        this.values = {...newValue}
      },
      deep: true
    }
  },
  mounted () {
    this.values = {...this.item}
  },
  methods: {
    dataChanged (value, key) {
      if (this.metadata[key]?.child != null) {
        this.$emit('changeValue', {value: null, key: this.metadata[key].child.key})
      }
      this.$emit('changeValue', {value, key, index: this.index})
    },
    sliderDataChanged (value, metadata) {
      this.$emit('changeValue', {value: value[0], key: metadata.lowerValue})
      this.$emit('changeValue', {value: value[1], key: metadata.upperValue})
    },
    selectAll (key) {
      const ids = this.metadata[key].items.map(item => item.id)
      this.dataChanged(ids, key)
    }
  }
}
</script>

<style scoped>
.v-autocomplete {
  height: auto !important;
}
.truncate {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.color-correct {
  color: rgba(0, 0, 0, 0.75);
}
.v-autocomplete >>> .v-select__slot {
  padding: 2px 0px !important;
}
</style>