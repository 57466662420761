<template>
  <td class="column">
    <template v-if="field.value === 'nro'">
      <v-text-field
        v-if="!disabled"
        v-model="model"
        clearable
        type="text"
        :placeholder="placeholder ? getPlaceholderValue(placeholder) : '-'"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'type'">
      <v-select
        v-model="model"
        :items="itemSelections"
        clearable
        item-text="label"
        item-value="value"
        :placeholder="placeholder ? definitionLabelById(placeholder) : '-'"
      />
    </template>
    <template v-if="field.value === 'rental_type'">
      <v-select
        v-model="model"
        :items="itemSelections"
        item-text="label"
        item-value="value"
        :placeholder="placeholder ? getPlaceholderValue(placeholder) : '-'"
      />
    </template>
    <template v-if="field.value === 'renter'">
      <v-select
        v-model="model"
        :items="itemSelections"
        clearable
        item-text="label"
        item-value="value"
        :placeholder="placeholder ? definitionLabelById(placeholder) : '-'"
      />
    </template>
    <template v-if="field.value === 'amount'">
      <v-text-field
        v-if="!disabled"
        v-model="model"
        clearable
        type="number"
        :placeholder="showDisabled ? '1' : (placeholder ? stringifyPlaceholder(placeholder) : '-')"
        :readonly="showDisabled"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'max_parking_rights'">
      <v-text-field
        v-if="!disabled"
        v-model="model"
        clearable
        type="number"
        :placeholder="placeholder ? stringifyPlaceholder(placeholder) : '-'"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'plug_in'">
      <v-select
        v-if="!disabled"
        v-model="model"
        :items="itemBoolean"
        clearable
        item-text="label"
        item-value="value"
        :placeholder="getPlaceholderBoolean(placeholder !== undefined ? placeholder : null)"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'charge_in'">
      <v-select
        v-if="!disabled"
        v-model="model"
        :items="itemSelections"
        clearable
        item-text="label"
        item-value="value"
        :placeholder="placeholder ? definitionLabelById(placeholder) : '-'"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'invalid_spot'">
      <v-select
        v-if="!disabled"
        v-model="model"
        :items="itemBoolean"
        clearable
        item-text="label"
        item-value="value"
        :placeholder="getPlaceholderBoolean(placeholder !== undefined ? placeholder : null)"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'market_rent'">
      <v-text-field
        v-model="model"
        clearable
        type="number"
        :placeholder="placeholder ? stringifyPlaceholder(placeholder) : '-'"
      />
    </template>
    <template v-if="field.value === 'extra_info'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
        :placeholder="placeholder || '-'"
      />
    </template>
    <template v-if="field.value === 'charging_spots_amount'">
      <v-text-field
        v-if="!disabled"
        v-model="model"
        clearable
        type="number"
        :placeholder="showDisabled ? '1' : (placeholder ? stringifyPlaceholder(placeholder) : '-')"
        :readonly="showDisabled"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
    <template v-if="field.value === 'charging_info'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
        :placeholder="placeholder || '-'"
      />
    </template>
    <template v-if="field.value === 'charging_power'">
      <v-text-field
        v-if="!disabled"
        v-model="model"
        clearable
        type="number"
        :placeholder="showDisabled ? '1' : (placeholder ? stringifyPlaceholder(placeholder) : '-')"
        :readonly="showDisabled"
      />
      <v-text-field
        v-else
        :disabled="true"
        :class="'secondary'"
      />
    </template>
  </td>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CarParkModifyField',
  props: {
    value: null,
    field: Object,
    placeholder: null,
    showDisabled: { type: Boolean, default () { return false } },
    disabled: { type: Boolean, default () { return false } },
    itemSelections: { type: Array, default () { return [] } }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (oldVal, newVal) {
        this.model = this.value
      }
    }
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById', 'definitionsByGroupLabel']),
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    itemBoolean () {
      return [{ value: true, label: this.$t('Yes') }, { value: false, label: this.$t('No') }, { value: null, label: this.$t('Undefined') }]
    }
  },
  methods: {
    getPlaceholderValue (value) {
      return this.$t(value)
    },
    stringifyPlaceholder (placeholder) {
      if (placeholder) {
        return placeholder.toString()
      }
      return placeholder
    },
    getPlaceholderBoolean (value) {
      return this.itemBoolean.filter(b => b.value === value)[0].label
    }
  }
}
</script>

<style scoped>
</style>
