<template>
  <div>
    <div
      class="printscreen-offer"
    >
      <PrintHeader 
        :site="site" 
        :title="$t('Unit brochure')"
        :notice="$t('This is not obligatory rental offer')"
      />
      <v-row
        class="print-info-row flex-nowrap"
        no-gutters
      >
        <v-col>
          <v-row
            class="print-info-box flex-column"
            no-gutters
          >
            <v-col
              v-if="true"
              class="align-items-center"
            >
              <div
                class="text-h5"
                style="padding-left:1mm"
              >
                {{ $t('Units') }}
              </div>
              <div
                v-for="data in unitData.filter(data => !data.disabled)"
                :key="data.header"
                style="padding-left:2mm"
              >
                <v-row
                  class="flex-nowrap"
                  no-gutters
                >
                  <v-col>{{ $t(data.header) }}</v-col>
                  <v-col>{{ data.value }}</v-col>
                </v-row>
              </div>
            </v-col>
            <v-col v-else>
              <div class="text-h5">
                {{ $t('Undefined') }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row
            class="print-info-box flex-column"
            no-gutters
          >
            <v-col
              v-if="true"
              class="align-items-center"
            >
              <div
                class="text-h5"
                style="padding-left:1mm"
              >
                {{ $t('Site services') }}
              </div>
              <div
                v-for="data in serviceData"
                :key="data.header"
                style="padding-left:2mm"
              >
                <span>{{ $t(data) }}</span><br>
              </div>
            </v-col>
            <v-col v-else>
              <div>
                <span class="text-h5">{{ $t('Undefined') }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row
            class="print-info-box-last flex-column"
            no-gutters
          >
            <v-col
              v-if="true"
              class="align-items-center"
            >
              <div
                class="text-h5"
                style="padding-left:1mm"
              >
                {{ $t('Detailed site information') }}
              </div>
              <div
                v-for="data in siteData.filter(data => !data.disabled)"
                :key="data.header"
                style="padding-left:2mm"
              >
                <v-row
                  class="flex-nowrap"
                  no-gutters
                >
                  <v-col>{{ $t(data.header) }}</v-col>
                  <v-col>{{ data.value }}</v-col>
                </v-row>
              </div>
            </v-col>
            <v-col v-else>
              <div>
                <span class="text-h5">{{ $t('Undefined') }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        class="print-v3viewer-row flex-nowrap"
        justify="space-between"
        no-gutters
      >
        <v-col
          cols="8"
          class="large-viewer"
        >
          <V3Viewer
            ref="leftviewer"
            class="v3-viewer-padding"
            :session-token="sessionToken"
            :models="models"
            :floor-id="floor.id"
            :selected-units="selectedUnitsForViewer"
            :hidden-layers="hiddenLayers"
            :display-only="true"
            :container-id-from-parent="'leftviewer'"
            @render-finish="color"
            @camera-ready="cameraReady"
          />
        </v-col>
        <v-col
          cols="4"
          class="small-viewer"
        >
          <V3Viewer
            class="v3-viewer-padding"
            :session-token="sessionToken"
            :models="models"
            :floor-id="floor.id"
            :selected-units="selectedUnitsForViewer"
            :hidden-layers="hiddenLayers"
            :display-only="true"
            :container-id-from-parent="'rightviewer'"
          />
        </v-col>
      </v-row>
      <v-row
        class="flex-column"
        no-gutters
      >
        <v-col class="print-area-verification notice-text">
          <span>{{ $t('Notice') + ' ' }}</span>
          <span>{{ $t('Areas have not been verified') }}</span>
        </v-col>
        <v-col class="print-rb-text">
          <span>{{ $t('Provided by Ramboll Circle') }}</span>
        </v-col>
      </v-row>
    </div>
    <div
      class="printscreen-offer flex-column"
    >
      <PrintHeader
        :site="site"
        :title="$t('Unit brochure')"
        :notice="$t('This is not obligatory rental offer')"
      />
      <div>
        <v-row
          class="first-photo flex-nowrap"
          no-gutters
        >
          <v-col>
            <img
              v-if="imgUrls1"
              id="imgUrls1"
              :src="imgUrls1"
              :alt="'First facade image from site documents'"
              class="image-fit"
              @load="imageLoaded"
            >
            <div
              v-else
              class="image-fit"
              style="text-align:center"
            >
              <v-icon
                size="100"
                color="blue lighten-2"
              >
                no_photography
              </v-icon>
            </div>
          </v-col>
          <v-col class="print-map">
            <Map
              :key="site.id_site"
              map-id="rental-offer-map"
              :center="map.mapCenter"
              :center-projection="map.mapProjection"
              :maps="map.mapLayers"
              :zoom="map.mapZoom"
              :over-write-map-style="mapStyle"
              @render-finish="print"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import V3Viewer from './V3Viewer'
import PrintHeader from './PrintHeader'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Map from '../../components/Map'
import humanize from '../../helpers/humanize'
export default {
  name: 'PrintUnitBrochure',
  components: {
    V3Viewer,
    PrintHeader,
    Map
  },
  props: {
    selectedUnits: {
      default: () => {
        return []
      },
      type: Array
    },
    models: {
      default: () => {
        return []
      },
      type: Array
    },
    sessionToken: {
      default: '',
      type: String
    },
    hiddenLayers: {
      default: () => {
        return []
      },
      type: Array
    },
    camera: {
      default: () => {
        return undefined
      },
      type: Object
    },
    site: {
      default: () => {
        return {}
      },
      type: Object
    },
    building: {
      default: () => {
        return {}
      },
      type: Object
    },
    floor: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      firstReady: false,
      secondReady: false,
      selectedUnitsForViewer: [],
      loaded: false,
      siteServices: {},
      map: {
        mapProjection: 'EPSG:3857',
        mapCenter: [2807128, 9638898],
        mapZoom: 5,
        mapLayers: [
          {
            name: 'Main map',
            title: 'Main map',
            type: 'OSM',
            opacity: 1
          }
        ],
        mapViewport: null
      },
      mapStyle: {
        height: '153mm !important',
        width: '134.4mm !important',
        'margin-top': '2mm',
        'margin-left': '2mm'
      },
      imgUrls1: null,
      imgUrls2: null,
      printReady: false,
      img1Loaded: false,
      img2Loaded: false
    }
  },
  computed: {
    ...mapState('sites', ['currentSiteId']),
    ...mapGetters('app', ['definitionLabelById']),
    ...mapGetters('sites', ['estatesForCurrentSite']),
    unitData: function () {
      return [
        {
          header: 'Type',
          value: this.getUnitType()
        },
        {
          header: 'Total unit area',
          value: this.getTotalUnitArea()
        },
        {
          header: 'Building total net floor area',
          value: humanize.thousand_separator(this.building.building_net_floor_area) + 'm\u00B2'
        },
        {
          header: 'Floor',
          value: this.getFloorNumber()
        },
        {
          header: 'Building floor count',
          value: this.building.floor_count
        },
        {
          header: 'Building completed year',
          value: this.building.completed_year
        },
        {
          header: 'Building improvement year',
          value: this.building.improvement_year,
          disabled: !this.building.improvement_year
        },
        {
          header: 'Building expansion year',
          value: this.building.expansion_year,
          disabled: !this.building.expansion_year
        }
      ]
    },
    serviceData: function () {
      return Object.keys(this.siteServices).filter(key => this.siteServices[key] === true).map(value => {
        var string = value.split('_').join(' ')
        return string.charAt(0).toUpperCase() + string.slice(1)
      })
    },
    siteData: function () {
      return [
        {
          header: 'Energy class',
          value: this.definitionLabelById(this.building.energy_class),
          disabled: !this.building.energy_class
        },
        {
          header: 'Green heating',
          value: this.building.green_heating ? this.$t('Yes') : this.$t('No'),
          disabled: !this.building.green_heating
        },
        {
          header: 'Heating',
          value: this.definitionLabelById(this.building.heating),
          disabled: !this.building.heating
        },
        {
          header: 'Solar plant',
          value: this.definitionLabelById(this.site.solar_plant),
          disabled: !this.site.solar_plant
        },
        {
          header: 'Geothermal energy',
          value: this.$t(this.site.geothermal_energy),
          disabled: !this.site.geothermal_energy
        },
        {
          header: 'Cooling',
          value: this.definitionLabelById(this.building.cooling),
          disabled: !this.building.cooling
        },
        {
          header: 'Estate model',
          value: this.estate.development_plan_notation?.split(',')[0],
          disabled: !this.estate.development_plan_notation
        },
        {
          header: 'Personnel elevator count',
          value: this.building.personnel_elevator_count,
          disabled: !this.building.personnel_elevator_count
        },
        {
          header: 'Freight elevator count',
          value: this.building.freight_elevator_count,
          disabled: !this.building.freight_elevator_count
        }
      ]
    },
    estate () {
      return this.estatesForCurrentSite.filter(estate => estate.estate_id === this.building.id_estate)[0]
    }
  },
  methods: {
    ...mapMutations('app', ['setPrintingClass']),
    close () {
      this.printing = false
      this.$emit('close', 'unit-brochure')
    },
    color () {
      if (!this.secondReady) {
        this.selectedUnitsForViewer = []
        this.selectedUnitsForViewer = this.selectedUnits.map(unit => {
          return {
            identifier: unit.unit_code
          }
        })
        this.secondReady = true
        this.setPrintingClass('a4landscape-rental-offer')
        this.$refs.leftviewer.flyToCamera(this.camera)
      }
    },
    async print () {
      this.siteServices = await this.$rambollfmapi.sites.services(this.currentSiteId).list()
      if (this.printReady && this.imagesLoaded) {
        if (!this.printing) {
          this.printing = true
          this.printReady = false
          await window.print()
        }
        this.setPrintingClass(null)
        this.close()
      }
    },
    async cameraReady () {
      await this.getImagesPhotos()
      this.getImagesMaps()
    },
    async getImagesPhotos () {
      let photoFiles = []
      ;({
          items: photoFiles,
        } = await this.$rambollfmapi.sites
          .photos(this.site.id_site)
          .list()
       )
        for( const photo of photoFiles) {
          // Retrieve images
          if (photo.toOutputImage1 || photo.toOutputImage2){
            const progress = {
              retrievalProgress: 0
            }
            try{
            const res = await this.$rambollfmapi.photos.download(photo.idPhoto, progress, false)
              const blob = new Blob([res.data], { type: 'octet/stream' })
              const objUrl = URL.createObjectURL(blob)
              if (photo.toOutputImage1){
                this.imgUrls1 = objUrl
              }
              if (photo.toOutputImage2){
                this.imgUrls2 = objUrl
              }
            } catch (err) {
              this.$log.error('Failed to load image!')
            }
        }
        }
    },
    async getImagesMaps () {
      const layer = {
        name: 'Buildings',
        title: 'Buildings',
        type: 'Points',
        opacity: 1,
        points: [{
          x: this.building.coordinate_x,
          y: this.building.coordinate_y,
          projection: 'EPSG:3879'
        }]
      }
      this.map.mapLayers.push(layer)
      this.map.mapCenter = [this.building.coordinate_x, this.building.coordinate_y]
      this.map.mapProjection = 'EPSG:3879'
      this.printReady = true
      this.map.mapZoom = 14
    },
    getTotalUnitArea () {
      var totalArea = 0
      this.selectedUnits.forEach(unit => {
        totalArea += unit.area ? unit.area : 0
      })
      return humanize.thousand_separator(totalArea) + 'm\u00B2'
    },
    getFloorNumber () {
      if (this.floor) {
        return !this.floor.shortname ? this.floor.floor_number : this.floor.shortname
      }
      return '-'
    },
    getUnitType () {
      return this.selectedUnits.sort((a, b) => b.area - a.area)[0].usage
    },
    imageLoaded () {
      this.img1Loaded = true
      this.imagesLoaded()
    },
    image2Loaded () {
      this.img2Loaded = true
      this.imagesLoaded()
    },
    imagesLoaded () {
      if(this.imgUrls1 && this.image1Loaded && !this.imgUrls2) {
      return true
      } else if (!this.imgUrls1 && this.imgUrls2 && this.img2Loaded) {
        return true
      } else if( this.imgUrls1 && this.img1Loaded && this.imgUrls2 && this.img2Loaded) {
      return true
      } else if( !this.imgUrls1 && !this.imgUrls2) {
        return true
      } else {
        return false
      }
      
    }
  }
}
</script>
<style scoped>
.print-info-box {
  height: 82.9mm !important;
  width: 92.3mm !important;
  border-right: black solid 0.1mm;
  line-height: 140%;
}
.print-info-box-last {
  height: 82.9mm !important;
  width: 92.3mm !important;
  line-height: 140%;
}
.print-info-row {
  border-bottom: black solid 0.1mm;
}
.v3-viewer-padding {
  padding-top: 0.5mm;
  padding-left: 0.25mm;
  padding-right: 0.25mm;
}
.print-rb-text {
  height: 9mm !important;
}
.small-viewer {
  width: 92.2mm !important;
  min-height: 75mm !important;
  max-height: 75mm !important;
}
.large-viewer {
  width: 184.5mm !important;
  min-height: 75mm !important;
  max-height: 75mm !important;
  border-right: black solid 0.1mm;
}
.first-photo {
  width: 138.5mm !important;
  border-bottom: black solid 0.1mm;
}
.second-photo {
  width: 138.5mm !important;
}
.print-map {
  height: 157mm !important;
  width: 138.4mm !important;
  border-left: black solid 0.1mm;
}
.image-fit {
  width: 134mm !important;
  margin-left: 2mm;
  margin-top: 2mm;
  object-fit: cover;
  overflow: hidden;
  border-radius: 3mm;
}
.print-area-verification {
  height: 9mm !important;
}
</style>