<template>
  <v-expansion-panels
    v-if="carpark"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
        >
          <div
            style="text-align: right"
          >
            {{ $t('site.rental_contracts') }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ContractDataForm
          :data="getContractData()"
          :metadata="getContractMetadata()"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import ContractDataForm from '../../../components/Leasing/ContractDataForm.vue'

export default {
  name: 'CarparkContractInfo',
  components: {
    ContractDataForm
  },
  props: {
    carpark: { type: Object, default: null },
    contracts: { type: Array, default: null },
  },
  methods: {
    getContractData () {
      return this.contracts
    },
    getContractMetadata () {
      return {
        carparkCodes: { name: 'Car parks', format: 'None' },
        contract_party: {
          format: 'None',
          name: 'Contract party',
        },
        validity: {
          name: 'Type of contract validity',
          format: 'None',
          groupLast: true,
        },
        area: {
          name: 'Rented Actual area',
          format: 'Area',
        },
        contract_area: {
          format: 'Area',
          name: 'Total contract area',
          groupLast: true,
        },
        signature_date: {
          name: 'Date of signing the contract',
          format: 'Date',
        },
        start_date: {
          name: 'Start date',
          format: 'Date',
        },
        first_possible_end_date: {
          name: 'Contract first possible end date',
          format: 'Date',
        },
        end_date: {
          name: 'End date',
          format: 'Date',
          groupLast: true,
        },
        tenant_notice: {
          name: 'Agreement notice period (Tenant)',
          format: 'None',
        },  
        landlord_notice: {
          name: 'Agreement notice period (Lanlord)',
          format: 'None',
        },
        validity_note: {
          name: 'Validity notice',
          format: 'TruthValue',
        },
        vat_responsibility: {
          name: 'VAT-responsibility',
          format: 'YesNoUnknown',
          groupLast: true,
        },
        capital_rent: {
          name: 'Capital rent',
          format: 'EuroPerMonth',
        },
        maintenance_rent: {
          name: 'Maintenance rent',
          format: 'EuroPerMonth',
        },
        changeover_rent: {
          name: 'rental_status.changeover_rent',
          format: 'EuroPerMonth',
        },
        separate_allowances: {
          name: 'rental_status.separate_allowances',
          format: 'EuroPerMonth',
        },
        discounts: {
          name: 'rental_status.discounts',
          format: 'EuroPerMonth',
        },
        open_bills_total: {
          name: 'rental_status.open_bills_total',
          format: 'Euro',
        },
        index_type: {
          name: 'rental_status.index_type',
        },
        minimum_increase: {
          name: 'rental_status.minimum_increase',
          format: 'Percentage',
          groupLast: true,
        },
      }
    }
  }
}
</script>
<style scoped>
</style>
