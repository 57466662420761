<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('Select Columns to Show') }}
    </template>
    <template #content>
      <v-row no-gutters>
        <v-col
          v-for="(column) in getColumns"
          :key="column.value"
          cols="6"
          sm="3"
          md="3"
          lg="3"
        >
          <v-checkbox
            v-model="columns"
            :label="column.text"
            :value="column.value"
            :disabled="column.value === 'buildingName'"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="esgPermission"
        class="fill-height"
        no-gutters
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('ESG') }}
          </h4>
        </v-col>
      </v-row> 
      <v-row
        v-if="esgPermission"
        no-gutters
      >
        <v-col
          v-for="(column) in getEsgColumns"
          :key="column.value"
          cols="6"
          sm="3"
          md="3"
          lg="3"
        >
          <v-checkbox
            v-model="esgColumns"
            :label="column.text"
            :value="column.value"
          />
        </v-col>
      </v-row>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-row
        v-if="!showSaveSelectionDialog"
        no-gutters
        class="flex-nowrap"
      >
        <v-select
          v-if="showSaveSelection"
          v-model="selectedViewName"
          :label="$t('Stored views')"
          :items="storedViews"
          :disabled="storedViews.length === 0"
          item-text="name"
          item-value="name"
          clearable
          style="margin-bottom: 5px"
        />
        <v-btn
          v-if="selectedViewName"
          text
          outlined
          rounded
          color="error"
          @click.stop="deleteView"
        >
          {{ $t('Delete view') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          outlined
          rounded
          @click.stop="selectAll"
        >
          {{ $t('Choose all') }}
        </v-btn>
        <v-btn
          text
          outlined
          rounded
          @click.stop="clearSelections"
        >
          {{ $t('Remove all') }}
        </v-btn>
        <v-btn
          v-if="showSaveSelection"
          class="primary"
          style="margin-bottom: 4px"
          depressed
          rounded
          @click.stop="showSaveSelectionDialog = true"
        >
          {{ $t('Save selection') }}
        </v-btn>
        <v-btn
          text
          rounded
          class="primary"
          @click="saveFilter"
        >
          {{ $t('Show') }}
        </v-btn>
      </v-row>
      <v-row
        v-else
        no-gutters
        class="flex-nowrap"
      >
        <v-text-field
          v-if="showSaveSelection"
          v-model="nameOfSelection"
          :placeholder="$t('Give a name for selection')"
          autofocus
          clearable
          style="border: 0px; margin-bottom: 10px"
        />
        <v-spacer />
        <v-btn
          v-if="showSaveSelection"
          center
          outlined
          rounded
          text
          @click.stop="
            showSaveSelectionDialog = false
            needsConfirm = false
          "
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="!needsConfirm"
          :class="
            nameOfSelection && nameOfSelection.length > 0 ? 'primary' : null
          "
          :disabled="!nameOfSelection || nameOfSelection.length < 1"
          rounded
          depressed
          @click.stop="saveSelection"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn
          v-else
          class="primary"
          outlined
          rounded
          @click.stop="confirmSaveSelection"
        >
          {{ $t('Confirm') }}
        </v-btn>
        <v-btn
          text
          rounded
          class="primary"
          @click="saveFilter"
        >
          {{ $t('Ok') }}
        </v-btn>
      </v-row>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import helpers from '../../helpers'
import BaseModal from '../general/BaseModal'

export default {
  name: 'TaskListTableFilter',
  components: { BaseModal },
  props: {
    storedViews: {
      default: () => {
        return []
      },
      type: Array
    },
    showSaveSelection: {
      default: () => {
        return false
      },
      type: Boolean
    },
    selectedViewString: {
      default: () => {
        return null
      },
      type: String
    },
  },
  data () {
    return {
      columns: [],
      esgColumns: [],
      nameOfSelection: null,
      selectedViewName: null,
      selectedView: null,
      showSaveSelectionDialog: false,
      needsConfirm: false
    }
  },
  computed: {
    ...mapState('ltp', ['selectedYear', 'yearRange', 'columnFilter']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    esgPermission () {
      return this.hasApplicationPermissionByName('PTS_ESG_TEHTAVAT')
    },
    getColumns: function () {
      const years = []
      for (let i = 0; i < this.yearRange; i++) {
        years.push({
          text: (this.selectedYear + i).toString(),
          value: `year${i}`
        })
      }
      const columns = [
        ...helpers.ltpConstants.startHeaders(),
        ...years,
        ...helpers.ltpConstants.endHeaders()
      ]
      return columns
    },
    getEsgColumns: function () {
      return helpers.ltpConstants.esgHeaders()
    }
  },
  watch: {
    selectedViewName: function (name) {
      if (name) {
        this.selectedView = this.storedViews.find(sw => sw.name === name)
      } else {
        this.selectedView = null
      }
    },
    selectedView: function (selectedView) {
      if (typeof selectedView !== 'undefined' && selectedView !== null) {
       this.columns = this.getColumns
          .filter(dh =>
            selectedView.stored_fields.find(sf => sf.field === dh.value)
          )
          .map(dh => {
            return dh.value
          })
        if (this.esgPermission) {
          this.esgColumns = this.getEsgColumns
          .filter(dh =>
            selectedView.stored_fields.find(sf => sf.field === dh.value)
          )
          .map(dh => {
            return dh.value
          })
        }
        this.nameOfSelection = selectedView.name
        this.selectedViewName = selectedView.name 
      } else {
        this.columns = this.getColumns.map(dh => {
          return dh.value
        })
        if (this.esgPermission) {
          this.esgColumns = this.getEsgColumns.map(dh => {
          return dh.value
        })
        }

        this.nameOfSelection = null
      }
      this.$emit('select-view', selectedView)
    },
    selectedViewString: function (string) {
      this.selectedViewName = string
    },
    nameOfSelection: function () {
      this.needsConfirm = false
    },
  },
  async mounted () {
    this.resetColumns()
  },
  methods: {
    ...mapActions('ltp', ['setColumnFilter']),

    close () {
      this.$emit('close')
      this.resetColumns()
    },
    saveFilter () {
      // We have to check here if we have active filter. If user has selected all the values, let's default to
      // empty array. This makes it easier to detect if we have active filter or not.
      if (this.columns.length === this.getColumns.length && this.esgColumns.length === this.getEsgColumns.length) {
        this.setColumnFilter([])
        this.selectedView = null
        this.selectedViewName = null
      } else {
        this.setColumnFilter([...this.columns, ...this.esgColumns])
      }

      this.$emit('close')
    },
    resetColumns () {
      this.columns = []
      this.esgColumns = []

      if (this.columnFilter.length > 0) {
        this.columnFilter.forEach(element => {
          if (this.getColumns.find(column => column.value === element )){
            this.columns.push(element)
          } else if (this.esgPermission) {
            this.esgColumns.push(element)
          }
        })
      } else {
        this.columns = this.getColumns.map(column => column.value)
        if (this.esgPermission) {
          this.esgColumns = this.getEsgColumns.map(column => column.value)
        }
      }
    },
    saveSelection () {
      if (!this.storedViews.find(sv => sv.name === this.nameOfSelection)) {
        this.$emit(
        'save-selection',
        this.nameOfSelection,
        this.esgPermission ? [...this.columns, ...this.esgColumns] : this.columns,
        false
      )
        this.showSaveSelectionDialog = false
        this.saveFilter()
      } else {
        this.needsConfirm = true
        this.$emit(
        'save-selection',
        this.nameOfSelection,
        this.esgPermission ? [...this.columns, ...this.esgColumns] : this.columns,
        false
      )
      } 
    },
    deleteView: function () {
      this.selectedViewName = null
      this.$emit('delete-view', this.selectedView.id)
    },
    selectAll () {
      this.columns = this.getColumns.map(h => h.value)
      if (this.esgPermission) {
        this.esgColumns = this.getEsgColumns.map(h => h.value)
      }
    },
    clearSelections () {
      this.columns = ['buildingName']
      if (this.esgPermission) {
        this.esgColumns = []
      }
    },
    confirmSaveSelection () {
      this.needsConfirm = false
      this.$emit(
        'save-selection',
        this.nameOfSelection,
        this.esgPermission ? [...this.columns, ...this.esgColumns] : this.columns,
        true
      )
      this.showSaveSelectionDialog = false
      this.saveFilter()
    },
  },
}
</script>
<style>
</style>
