import JsPDF from "jspdf"
import 'jspdf-autotable'
import { PDFDocument } from 'pdf-lib'
import helpers from '../../../../../../helpers'
import { i18n } from '../../../../../../plugins/i18n'

export async function customPdfCreator (template, data, blob = false) {
  const { 
    contractNumber,  
    header,
    landlord, 
    tenant, 
    otherTenant, 
    uniqueSites,
    selectedUnitsAndStructures,
    selectedParkingSpaces, 
    additionalInfoUnits,
    additionalInfoParking,
    rentPeriod, 
    noticePeriod, 
    housingModification, 
    billingInfo,
    rent,
    contractPayments,
    separatePayments,
    singlePayments,
    additionalInfoPayments,
    rentReviews,
    guarantees,
    noGuarantees,
    contractConditions,
    signees, 
    attachments, 
    getAttachmentArrayBuffer 
  } = data
   
  // document
  const doc = new JsPDF('portrait')
  doc.setFontSize(12)
  addHeader(doc, header)
  
  let yPosition = 40
  template.sections.forEach(section => {
    if (Object.keys(section).length === 0 || section?.template?.length === 0) {
      return
    }
    addSection(doc, section, yPosition, {
      contractNumber, 
      landlord, 
      tenant, 
      otherTenant, 
      uniqueSites,
      selectedUnitsAndStructures,
      selectedParkingSpaces,
      additionalInfoUnits,
      additionalInfoParking,
      rentPeriod, 
      noticePeriod, 
      housingModification, 
      billingInfo, 
      rent,
      contractPayments,
      separatePayments,
      singlePayments,
      additionalInfoPayments,
      rentReviews,
      guarantees,
      noGuarantees,
      contractConditions,
      signees, 
    });
    yPosition = doc.lastAutoTable.finalY + 10
  })
  
  addAttachments(doc, attachments, yPosition)
  
  addFooter(doc)
  
  if (attachments?.length > 0) {
    return await addAttachmentsMerge(doc, attachments, contractNumber, getAttachmentArrayBuffer, blob)
  } else {
    return blob ? doc.output('blob') : doc.save(`${i18n.t("pdf.contract")}-${contractNumber}.pdf`)
  }
}

function addSection (doc, section, yPosition, data) {
  const columnStyles = section.columnStyles === 'default' ? { 0: { cellWidth: 50 } } : section.columnStyles
  
  if (section.type === 'table') {
    // datatable    
    let sourceData = data[section.source];

    if (sourceData !== undefined) {
      // table body
      let body = []
      if (section.sourceDataType === 'object') {
        body = section.template.map(t => {
          return [
            t.header,
            sourceData[t.value] !== undefined && sourceData[t.value] !== null && sourceData[t.value] !== '' ? sourceData[t.value] : ''
          ]
        })

      } else if (section.sourceDataType === 'string') {
        body.push([ [sourceData] ])

      } else if (section.sourceDataType === 'array') {
        // sections with headers on top of columns and data is mapped on rows eg. units and parking spaces, payments
        if (section.direction === 'row') {
          body = sourceData.map(s => {
            return section.template.map(t => {
              return [
                s[t.value] !== undefined && s[t.value] !== null && s[t.value] !== '' ? s[t.value] : ''
              ]
            })
          })

          if (section.hasSumRow) {
            if (section.id === 'selectedUnitsAndStructures') {
              const area = sourceData.reduce((acc, obj) => acc + obj.agreedArea , 0)
              body.push(
                [
                  i18n.t('pdf.totalArea'),
                  null,
                  `${helpers.humanize.amount_long(area, 1).replace('.',',')} m²`
                ]
              )
            } else if (section.id === 'selectedParkingSpaces') {
              const amount = sourceData.reduce((acc, obj) => acc + obj.agreedAmount , 0)
              body.push(
                [
                  i18n.t('pdf.totalAmount'),
                  null,
                  `${amount} ${i18n.t('pcs')}`
                ]
              )
            }
          }
        } else {
          // sections with headers on the left side columns and data is from an array eg. rent reviews, sites, guarantees
            const foundItem = sourceData.find(s => s.id === Number(section.id?.split('-')[1]))
            if (foundItem !== undefined) {
              body = section.template.map(t => {
                return [
                  t.header,
                  foundItem[t.value] !== undefined && foundItem[t.value] !== null && foundItem[t.value] !== '' ? foundItem[t.value] : ''
                ]
              })
            }
        }
      }
      // SECTION OUTPUT
      
      // table title
      if (section.title !== '') {
        doc.text(i18n.t(section.title), 10, yPosition)
        yPosition += 10
      }

      // if first row is one column with info
      if (section.headerInfoRow !== undefined) {
        doc.autoTable({
          startY: yPosition,
          body: [ [i18n.t(section.headerInfoRow)] ],
          theme: 'grid',
          margin: { left: 10, right: 10 },
        })
        yPosition = doc.lastAutoTable.finalY
      }

      // table
      doc.autoTable({
        startY: yPosition,
        head: section.direction === 'column' ? null : [section.template.map(t => t.header)],    
        body,
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        styles: {
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
        },
      })
      if (section.subInfoRow !== undefined) {
        yPosition = doc.lastAutoTable.finalY
        doc.autoTable({
          startY: yPosition,
          body: [ [i18n.t(section.subInfoRow)] ],
          theme: 'grid',
          margin: { left: 10, right: 10 },
        })
      }
    }
  } 
}

function addHeader (doc, header) {

  if (header.logo) {
    var imgData = 'data:image/png;base64,' + header.logo
    doc.addImage(imgData, 'PNG', 10, 10, 28, 12)
  }
  doc.text(`${header.contractType ? i18n.t(header.contractType) : i18n.t("pdf.Contract")} ${header.contractNumber}`, 80, 22)
}

function addFooter (doc) {

  const pageCount = doc.internal.getNumberOfPages()
  for (let i = 1; i <= pageCount; i++) {
    doc.setFontSize(9)
    doc.setPage(i)
    const pageSize = doc.internal.pageSize
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const footer = `${i18n.t("pdf.page")} ${i} / ${pageCount}`
    doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 10, { baseline: 'bottom' })
  }
}

function addAttachments (doc, attachments, yPosition) {

  if (attachments?.length === 0) return

  const table = attachments.map((attachment, i) => attachmentTable(attachment, i))

  doc.text(i18n.t("Contract attachments"), 10, doc.lastAutoTable.finalY + 15)
  yPosition = doc.lastAutoTable.finalY + 20

  doc.autoTable({
    startY: yPosition,
    body: table,
    theme: 'grid',
    margin: { left: 10, right: 10 },
    columnStyles: {
      0: { cellWidth: 50 },
    },
    styles: {
      lineColor: [200, 200, 200],
      lineWidth: 0.1,
    },
  })
}

function attachmentTable (attachment, i) {

  return [
    `${i18n.t("pdf.Attachment")} ${i + 1}`,
    attachment?.title,
  ]
}

async function addAttachmentsMerge (doc, attachments, contractNumber, getAttachmentArrayBuffer, blob) {
  
  const contractArrayBuffer = doc.output('arraybuffer')
  const mergedPdfDoc = await PDFDocument.create()

  // Contract pages
  const contractDoc = await PDFDocument.load(contractArrayBuffer)
  const firstPage = await mergedPdfDoc.copyPages(contractDoc, contractDoc.getPageIndices())
  firstPage.forEach((page) => mergedPdfDoc.addPage(page))

  // Attachment pages
  for (let i = 0; i < attachments.length; i++) {
    const attachmentBuffer = attachments[i].idDocument ? await getAttachmentArrayBuffer(attachments[i].idDocument) : await attachments[i].file.arrayBuffer()
    const attachmentDoc = await PDFDocument.load(attachmentBuffer)
    const attachmentPage = await mergedPdfDoc.copyPages(attachmentDoc, attachmentDoc.getPageIndices())
    attachmentPage.forEach((page) => mergedPdfDoc.addPage(page))
  }

  const pdfBytes = await mergedPdfDoc.save()
  const fileBlob = new Blob([pdfBytes], { type: 'application/pdf' })
  
  return blob ? fileBlob : helpers.saveAs(fileBlob, `${i18n.t("pdf.contract")}-${contractNumber}.pdf`)
}
