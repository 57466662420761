<template>
  <BaseModal
    @cancel="close"
  >
    <template
      #title
    >
      {{ $t('Calculator') }}
    </template>
    <template #content>
      <ValidationObserver ref="observer">
        <v-row
          align="center"
        >
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedCalculationBasis"
              :items="calculationBasesItems"
              item-text="label"
              item-value="id"
              class="form-fields mt-4 ml-2"
              :label="$t('CalculationBasis')"
              @input="ChangeSelectedCalculationBasis"
            />
          </v-col>
        </v-row>
        <v-autocomplete
          v-model="selectedBuildingProject"
          :items="buildingProjects"
          :item-text="item => `${item.buildingproject_number + ' ' + item.name}`"
          item-value="id_buildingproject"
          class="form-fields mt-4 ml-2"
          :label="$t('site.building_project')"
          @input="ChangeSelectedBuildingProject"
        /> 
        <v-row
          align="center"
        >
          <v-col cols="6">
            <v-text-field
              v-model="totalFundingProvision"
              :label="$t('TotalFundingProvision')"
              class="form-fields mt-4 ml-2"
              :readonly="isCalculationBasis(selectedCalculationBasis, 'Vuokraus ilman investointia') || isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="netFloorArea"
              :label="$t('Net floor area')"
              class="form-fields mt-4 ml-2"
              :readonly="isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
        >
          <v-col cols="6">
            <v-text-field
              v-model="zonePriceValue"
              :label="$t('ZonePrice')"
              class="form-fields mt-4 ml-2"
              :readonly="isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="balanceValue"
              :label="$t('Balance')"
              class="form-fields mt-4 ml-2"
              :readonly="isCalculationBasis(selectedCalculationBasis, 'Uudisrakennus') || isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
            />
          </v-col>
        </v-row>
        <v-row 
          v-if="isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila')"
          align="center"
        >
          <v-col cols="6">
            <v-text-field
              v-model="spaceRent"
              :label="$t('SpaceRent')"
              class="form-fields mt-4 ml-2"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="maintenanceRent"
              :label="$t('OutPaidMaintenanceRent')"
              class="form-fields mt-4 ml-2"
            />
          </v-col> 
        </v-row>
        <h3 
          v-if="tenantIds.length >0"
          class="form-headers mt-4"
        >
          {{ $t('Tenants') }}
        </h3>
        <div
          v-for="(tenantId, index) in tenantIds"
          :key="index"
        >
          <v-row
            align="center"
          >
            <v-col cols="6">
              <v-text-field
                :value="getTenantName(tenantId)" 
                :label="$t('create_rent_contract.tenant')"
                class="form-fields mt-4 ml-2"
                readonly
              />
            </v-col>
            <v-col
              v-if="canAddMoreTenants == false"
              cols="6"
            >
              <v-text-field
                v-model="tenantRentedAreas[index]"
                :label="$t('Rented unit area')"
                class="form-fields mt-4 ml-2"
              />
            </v-col>
            <v-col
              v-if="canAddMoreTenants == true"
              cols="5"
            >
              <v-text-field
                v-model="tenantRentedAreas[index]"
                :label="$t('Rented unit area')"
                class="form-fields mt-4 ml-2"
              />
            </v-col>
            <v-col
              v-if="canAddMoreTenants == true"
              cols="1"
            >
              <v-btn
                small
                icon
                class="mt-5 ml-2 form-fields"
                @click="removeTenant(index)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row
          v-if="canAddMoreTenants == true"
          align="center"
        >
          <v-col cols="6">
            <v-autocomplete
              v-model="newTenantId"
              :items="parties"
              item-text="name"
              item-value="id"
              class="form-fields mt-4 ml-2"
              :label="$t('Tenant')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              rounded
              depressed
              class="mt-5 ml-2 form-fields blue-button"
              @click="addTenant"
            >
              {{ $t('Add tenant') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row 
          v-if="isCalculationBasis(selectedCalculationBasis, 'Vuokraus ilman investointia') || isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
          align="center"
        >
          <v-col cols="6">
            <v-checkbox
              v-model="isExtraRent"
              class="form-fields mt-4 ml-2"
              :label="$t('AdditionalRent')"
              :readonly="isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
            />
          </v-col>  
        </v-row>
        <div
          v-if="(isCalculationBasis(selectedCalculationBasis, 'Vuokraus ilman investointia')&& isExtraRent == true) || ((isCalculationBasis(selectedCalculationBasis, 'Ulkoavuokrattu tila') && isExtraRent == true)) || isCalculationBasis(selectedCalculationBasis, 'Toiminnallinen muutostyö')"
        >
          <div 
            v-for="(tenantId, index) in tenantIds"
            :key="index"
          >
            <v-row 
              align="center"
            >
              <v-col cols="4">
                <v-text-field
                  :value="getTenantName(tenantId)" 
                  :label="$t('create_rent_contract.tenant')"
                  class="form-fields mt-4 ml-2"
                  readonly
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="modificationsCosts[index]"
                  :label="$t('ModificationCost')"
                  class="form-fields mt-4 ml-2"
                  :readonly="!isExtraRent"
                  @change="onModificationsCostsChange(index)"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="paymentPeriods[index]"
                  :label="$t('PaymentPeriodYears')"
                  class="form-fields mt-4 ml-2"
                  readonly
                />
              </v-col>
            </v-row>
          </div>
        </div>   
        <v-row
          align="center"
        >
          <v-col cols="3">
            <v-btn
              class="blue-button mt-4 ml-2"
              rounded
              depressed
              @click="Calculate()"
            >
              {{ $t('Calculate') }}
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="showTable">
          <v-data-table
            v-for="(tenantTableData, index) in calculationResultsArray"
            :key="index"
            :headers="tenantTableHeaders(index)"
            :items="tenantTableData"
            hide-default-footer
            class="table-with-space"
          />
        </div>
      </ValidationObserver>
      <v-btn
        v-if="showTable"
        rounded
        depressed
        @click="handlePdfDownload"
      >
        {{ $t('Export to PDF') }}
      </v-btn>
      <v-btn
        rounded
        depressed
        @click="clear"
      >
        {{ $t('Clear') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
  
  <script>
  import BaseModal from './general/BaseModal'
  import { ValidationObserver, } from 'vee-validate'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import CalculatorPdfCreator from './CalculatorPdfCreator'

  export default {
    name: "CalculatorComponent",
      components: {
        BaseModal,
        ValidationObserver
      },
    props: {
      selectedBuildings: {
        type: Array,
        default: () => []
      },
    },
    data () {
      return {
        calculationBaseItems: [],
        columnHeaders: [this.$t('€/htm2/kk'), this.$t('ProcessingModeMonth'), this.$t('€/year')],
        rowHeaders: [
            this.$t('Investment Charge'),
            this.$t('Return Requirement'),
            this.$t('Ground Rent'),
            this.$t('Maintenance rent'),
            this.$t('Overhead Cost'),
            this.$t('portfolios.gross_rent')
        ],
        tableData: [],
        selectedCalculationBasis: undefined,
        selectedBuildingProject: undefined,
        totalFundingProvision: undefined,
        netFloorArea: undefined,
        zonePriceValue: this.zonePrice ? this.zonePrice.zonePrice : undefined,
        balanceValue: this.balance ? this.balance : undefined,
        showTable: false,
        tenantRentedAreas: [],
        calculationResultsArray: [],
        isExtraRent: false,
        modificationsCosts: [],
        paymentPeriods: [],
        spaceRent: undefined,
        maintenanceRent: 0,
        newTenantId: undefined
        }
    },
    computed: {
    ...mapState("calculator", [
      "zonePrice",
      "balance",
      "buildingProjects",
      "internalRentCalculationValues",
      "calculationBasesItems",
      "tenantIds",
      "personParties",
      "parties",
      "sites",
      "buildings",
      "selectedUnits",
      "canChangeZonePriceAndBalance",
      "singleBuildingId",
      "canAddMoreTenants"
    ]),
    ...mapGetters("app", ["customerLogo"]),
    },
    watch: {
      zonePrice: {
        immediate: true,
        handler (zonePrice) {
          if (zonePrice == null) return
          if (zonePrice && (this.internalRentCalculationValues == undefined || this.canChangeZonePriceAndBalance == true)) { 
            this.zonePriceValue = zonePrice.zonePrice
          }
          else {
            this.zonePriceValue = this.internalRentCalculationValues.zonePrice.zonePrice
          }
        },
      },
      balance: {
        immediate: true,
        handler (balance) {
          if (balance == null) return
          if (this.internalRentCalculationValues == undefined || this.canChangeZonePriceAndBalance == true) {
            this.balanceValue = balance
          }
        },
      },
      calculationBasesItems: {
        immediate: true,
        handler (items) {
          if (this.internalRentCalculationValues == undefined) {
            this.calculationBaseItems = items
          }
        },
      },
      internalRentCalculationValues () {
        if(this.internalRentCalculationValues != undefined) {
          this.setupExistingData()
        }
      },
    },
    methods: {
      ...mapActions("calculator", ["setZonePrice", "setBalance", "setInternalRentCalculationValues","resetCanChangeZonePriceAndBalance"]),
      async handlePdfDownload () {
        let building;
        if (this.selectedBuildings.length > 0) {
          building = this.buildings.find(b => b.id_building === this.selectedBuildings[0]);
        } else if (this.singleBuildingId != undefined) {
          building = this.buildings.find(b => b.id_building === this.singleBuildingId);
        }        
        const site = building === undefined ? this.sites[0] : this.sites.find(s => s.id_site == building?.id_site)
        await CalculatorPdfCreator.createPdf({
          logo: this.customerLogo,
          baseData: {
            // Base data
            siteName: site === undefined ? '' : site.name,
            buildingName: building === undefined ? '' : building.building_name,
            calculationBasis: this.selectedCalculationBasis,
            buildingProject: this.selectedBuildingProject,
            totalFundingProvision: this.totalFundingProvision,
            totalArea: this.netFloorArea,
            zonePrice: this.zonePrice?.zonePrice,
            balance: this.balance,
          },
          isExtraRent: this.isExtraRent,
          tenants: this.tenantIds.map((tenantId, i) => ({
            name: this.getTenantName(tenantId),
            rentedArea: this.tenantRentedAreas[i],
            modificationsCost: this.modificationsCosts[i],
            paymentPeriod: this.paymentPeriods[i],
          })),
          responseData: this.calculationResultsArray,
        });
      },
      close () {
          this.$emit('close')
      },
      onModificationsCostsChange (value) {
        const division = this.modificationsCosts[value]/this.tenantRentedAreas[value]
        if (division<200) {
          this.$set(this.paymentPeriods, value, 5);
        }
        else {
          this.$set(this.paymentPeriods, value, 10);
        }
      },
      isCalculationBasis (selectedCalculationBasis, enumValue) {
        const calculationBaseEnum = {
          "Perusparannus": 1,
          "Vuokraus ilman investointia": 2,
          "Uudisrakennus": 3,
          "Ulkoavuokrattu tila": 4,
          "Toiminnallinen muutostyö": 5,
        };
        return calculationBaseEnum[enumValue] === selectedCalculationBasis;
      },
      ChangeSelectedCalculationBasis () {
        const calculationBase = this.calculationBasesItems.find(item => item.id === this.selectedCalculationBasis);

        //TODO Refactor this kind of resetting in later ticket
        if (calculationBase.label == "Perusparannus") {
          this.isExtraRent = false
        }
        else if (calculationBase.label == "Vuokraus ilman investointia") {
          this.totalFundingProvision = 0
        }
        else if (calculationBase.label == "Uudisrakennus") {
          this.isExtraRent = false
          this.balanceValue = 0
        }
        else if (calculationBase.label == "Ulkoavuokrattu tila") {
          //Maybe something here in next ticket or will be removed in refactor
        }
        else if (calculationBase.label == "Toiminnallinen muutostyö") {
          this.isExtraRent = true
        }
        else {
          this.isExtraRent = false
        }
      },
      tenantTableHeaders (index) {
        return [
          { text: this.getTenantName(this.tenantIds[index]), value: 'rowHeaders' },
          { text: this.$t('€/htm2/kk'), value: 'col1', sortable: false },
          { text: this.$t('ProcessingModeMonth'), value: 'col2', sortable: false },
          { text: this.$t('€/year'), value: 'col3', sortable: false },
        ];
      },
      getTenantName (tenantId) {
        if (this.tenantIds.length > 0) {
          let tenant = this.personParties.find(p => p.id === tenantId);
          if (tenant == undefined) {
            tenant = this.parties.find(p => p.id === tenantId);
            if (tenant == undefined) {
              return this.$t('fimx_set.total')
            }
          }
          return tenant ? tenant.name : ''; 
        }
      },
      ChangeSelectedBuildingProject () {
        const selectedBuildingProject = this.buildingProjects.find(buildingProject => buildingProject.id_buildingproject === this.selectedBuildingProject);
        if (selectedBuildingProject) {
          this.totalFundingProvision = selectedBuildingProject.total_financing_provision
          this.netFloorArea = selectedBuildingProject.unit_area
        }
      },
      setupExistingData () {
        this.totalFundingProvision = this.validateNumber(this.internalRentCalculationValues.totalFundingProvision);
        this.netFloorArea = this.validateNumber(this.internalRentCalculationValues.totalArea);
        this.zonePriceValue = this.validateNumber(this.internalRentCalculationValues.zonePrice?.zonePrice);
        this.balanceValue = this.validateNumber(this.internalRentCalculationValues.balance);
        this.selectedCalculationBasis = this.validateNumber(this.internalRentCalculationValues.calculationBasis);
        this.selectedBuildingProject = this.internalRentCalculationValues.buildingProject;
        this.tenantRentedAreas = this.internalRentCalculationValues.tenantRentedAreas;
        this.calculationResultsArray = this.internalRentCalculationValues.calculationResultsArray;
        this.modificationsCosts = this.internalRentCalculationValues.modificationsCosts;
        this.paymentPeriods = this.internalRentCalculationValues.paymentPeriods;
        this.isExtraRent = this.internalRentCalculationValues.isExtraRent;
        this.spaceRent = this.validateNumber(this.internalRentCalculationValues.spaceRent);
        this.maintenanceRent = this.internalRentCalculationValues.maintenanceRent

        this.ChangeSelectedCalculationBasis();
        this.showTable = true;
      },
      validateNumber (value) {
        return value !== undefined && !isNaN(value) ? Number(value) : undefined;
      },
      setTableData (calculationResult) {
        const formatValue = (value) => parseFloat(value).toFixed(2);

        const columns = [];
        const propertiesToCheck = [
          { property: calculationResult.investmentCharge, propertyPerMonth: calculationResult.investmentChargePerMonth, propertyPerYear: calculationResult.investmentChargePerYear, rowHeaders: this.$t('Investment Charge') },
          { property: calculationResult.returnRequirement, propertyPerMonth: calculationResult.returnRequirementPerMonth, propertyPerYear: calculationResult.returnRequirementPerYear, rowHeaders: this.$t('Return Requirement') },
          { property: calculationResult.groundRent, propertyPerMonth: calculationResult.groundRentPerMonth, propertyPerYear: calculationResult.groundRentPerYear, rowHeaders: this.$t('Ground Rent') },
          { property: calculationResult.spaceRent, propertyPerMonth: calculationResult.spaceRentPerMonth, propertyPerYear: calculationResult.spaceRentPerYear, rowHeaders: this.$t('SpaceRent') },
          { property: calculationResult.maintenanceRent, propertyPerMonth: calculationResult.maintenanceRentPerMonth, propertyPerYear: calculationResult.maintenanceRentPerYear, rowHeaders: this.$t('Maintenance rent') },
          { property: calculationResult.overheadCost, propertyPerMonth: calculationResult.overheadCostPerMonth, propertyPerYear: calculationResult.overheadCostPerYear, rowHeaders: this.$t('Overhead Cost') },
        ];

        propertiesToCheck.forEach(({ property, propertyPerMonth, propertyPerYear, rowHeaders }) => {
          if (property !== null) {
            columns.push({
              rowHeaders,
              col1: formatValue(property),
              col2: formatValue(propertyPerMonth),
              col3: formatValue(propertyPerYear)
            });
          }
        });

        if (calculationResult.extraRent !== null) {
          columns.push({ rowHeaders: "Lisävuokra", col1: formatValue(calculationResult.extraRent), col2: formatValue(calculationResult.extraRentPerMonth), col3: formatValue(calculationResult.extraRentPerYear) });
        }
        if (!this.isCalculationBasis(this.selectedCalculationBasis, 'Toiminnallinen muutostyö')) {
          columns.push({ rowHeaders: this.$t('portfolios.gross_rent'), col1: formatValue(calculationResult.totalRent), col2: formatValue(calculationResult.totalRentPerMonth), col3: formatValue(calculationResult.totalRentPerYear)})
        }

        this.calculationResultsArray.push(columns)

        this.showTable = true;
      },
      async Calculate () {
        this.calculationResultsArray = []
        const newZonePriceObject = { ...this.zonePrice, zonePrice: this.zonePriceValue };

        this.resetCanChangeZonePriceAndBalance()
        this.setZonePrice(newZonePriceObject);
        this.setBalance(this.balanceValue);

        let modificationCost = 0
        let paymentPeriod = 0

        const sumOfRentedAreas = this.tenantRentedAreas.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
        //Vuokralaisille taulukko
        for (let index = 0; index < this.tenantIds.length; index++) {
          const areaForTenant = this.tenantRentedAreas[index];

          modificationCost = this.modificationsCosts[index]
          paymentPeriod = this.paymentPeriods[index]

          const requestData = {
            buildingId: this.selectedBuildings[0] !== undefined
                ? this.selectedBuildings[0]
                : this.buildings[0].id_building,
            totalFundingProvision: this.totalFundingProvision,
            totalArea: this.netFloorArea,
            netFloorArea: areaForTenant,
            zonePrice: this.zonePrice,
            balance: this.balance,
            calculationBasis: this.selectedCalculationBasis,
            modificationsCost: modificationCost !== undefined ? modificationCost : 0,
            paymentPeriod: paymentPeriod !== undefined ? paymentPeriod : 0,
            isExtraRent: this.isExtraRent,
            siteId: this.selectedUnits[0] !== undefined
                ? this.selectedUnits[0].id_site
                : (this.sites.length > 0 ? this.sites[0].id : undefined),            
            spaceRent: this.spaceRent,
            maintenanceRent: this.maintenanceRent
          };

          let calculationResult = await this.$rambollfmapi.contracts.calculator.calculate(requestData);
          this.setTableData(calculationResult);
        }

        //Yhteensä taulukko
        const requestData = {
            buildingId: this.selectedBuildings[0] !== undefined
                ? this.selectedBuildings[0]
                : this.buildings[0].id_building,
            totalFundingProvision: this.totalFundingProvision,
            totalArea: this.netFloorArea,
            netFloorArea: sumOfRentedAreas,
            zonePrice: this.zonePrice,
            balance: this.balance,
            calculationBasis: this.selectedCalculationBasis,
            modificationsCost: modificationCost !== undefined ? modificationCost : 0,
            paymentPeriod: paymentPeriod !== undefined ? paymentPeriod : 0,
            isExtraRent: this.isExtraRent,
            siteId: this.selectedUnits[0] !== undefined
                ? this.selectedUnits[0].id_site
                : (this.sites.length > 0 ? this.sites[0].id : undefined),
            spaceRent: this.spaceRent,
            maintenanceRent: this.maintenanceRent
        };
        let calculationResult = await this.$rambollfmapi.contracts.calculator.calculate(requestData)
        
        //Calculate extra rent for total table
        if (this.isExtraRent == true) {
          let extraRentPerSquare = 0
          let extraRentPerMonth = 0
          let extraRentPerYear = 0
          for (const result of this.calculationResultsArray) {
            for (const row of result) {
              if (row.rowHeaders == "Lisävuokra") {
                extraRentPerMonth = extraRentPerMonth + Number(row.col2)
                extraRentPerYear = extraRentPerYear + Number(row.col3)
              }
            }
          }
          extraRentPerSquare = extraRentPerMonth/sumOfRentedAreas
         
          calculationResult.totalRent = calculationResult.totalRent - calculationResult.extraRent
          calculationResult.totalRentPerMonth = calculationResult.totalRentPerMonth - calculationResult.extraRentPerMonth
          calculationResult.totalRentPerYear = calculationResult.totalRentPerYear - calculationResult.extraRentPerYear

          calculationResult.extraRent = extraRentPerSquare
          calculationResult.extraRentPerMonth = extraRentPerMonth
          calculationResult.extraRentPerYear = extraRentPerYear

          calculationResult.totalRent = calculationResult.totalRent + calculationResult.extraRent
          calculationResult.totalRentPerMonth = calculationResult.totalRentPerMonth + calculationResult.extraRentPerMonth
          calculationResult.totalRentPerYear = calculationResult.totalRentPerYear + calculationResult.extraRentPerYear
        }
        this.setTableData(calculationResult)

        const combinedData = {
          buildingProject:this.selectedBuildingProject,
          totalFundingProvision: this.totalFundingProvision,
          totalArea: this.netFloorArea,
          zonePrice: this.zonePrice,
          balance: this.balance,
          calculationBasis: this.selectedCalculationBasis,
          tenantRentedAreas: this.tenantRentedAreas,
          calculationResultsArray: this.calculationResultsArray,
          modificationsCosts: this.modificationsCosts,
          paymentPeriods: this.paymentPeriods,
          isExtraRent: this.isExtraRent,
          spaceRent: this.spaceRent,
          maintenanceRent: this.maintenanceRent
        }
        this.setInternalRentCalculationValues(combinedData)
      },
      clear () {
        this.selectedCalculationBasis = undefined
        this.selectedBuildingProject = undefined
        this.totalFundingProvision = undefined
        this.netFloorArea = undefined
        this.zonePriceValue = undefined
        this.balanceValue = undefined
        this.tenantRentedAreas = []
        this.calculationResultsArray = []
        this.isExtraRent = false
        this.modificationsCosts = []
        this.paymentPeriods = []
        this.spaceRent = undefined
        this.maintenanceRent = 0
      },
      addTenant () {
        if (this.newTenantId != undefined) {
          this.tenantIds.push(this.newTenantId)
          this.newTenantId = undefined
        }        
      },
      removeTenant (index) {
        this.tenantIds.splice(index, 1)
      },
    }
  }
  </script>
  
  <style>
  .table-with-space th,
  .table-with-space td {
    padding: 8px; /* Adjust the padding value as needed */
    margin-bottom: 20px;
  }
  .table-with-space {
  margin-bottom: 40px; /* Adjust the value to set the desired space */
  }
  .form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
  }
  </style>
  
  
  
  
  
  