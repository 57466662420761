!<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t("leasing.add_units_to_process") }}
    </template>
    <template #content>
      <v-row no-gutters>
        <v-col cols="6">
          <h3 class="text-body-2 font-weight-bold">
            {{ $t('leasing.brochure_units') }}
          </h3>
        </v-col>
        <v-col
          cols="6"
          class="text-body-2"
        >
          {{ selectedUnitsString }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <h3 class="text-body-2 font-weight-bold">
            {{ $t("leasing.total_area") }}
          </h3>
        </v-col>
        <v-col
          cols="6"
          class="text-body-2"
        >
          {{ getValueWithUnit(totalArea, 'Area') }}
        </v-col>
      </v-row>
      <v-row
        v-if="selectedProcess!=null"
        no-gutters
      >
        <v-col cols="6">
          <h3 class="text-body-2 font-weight-bold">
            {{ $t("Rental event") }}
          </h3>
        </v-col>
        <v-col
          cols="6"
          class="text-body-2"
        >
          {{ getRentalProcessTitle(selectedProcess) }}
        </v-col>
      </v-row>
      <v-autocomplete
        v-else        
        v-model="processChoice"
        :items="selectableProcesses"
        :item-text="getRentalProcessTitle"
        :label="$t('leasing.select_process') + ' *'"
        :loading="processesFetching"
        return-object
        class="form-fields"
      />
      <p
        v-if="selectedProcess!=null"
        class="pa-5 text-h6 text-center font-weight-bold"
      >
        {{ $t("leasing.units_missing_from_process") }}
      </p>
    </template>
    <template #footer>
      <v-btn
        type="submit"
        rounded
        depressed
        :color="selectedProcess == null ? 'primary' : ''"
        :class="selectedProcess == null ? '' : 'blue-button'"
        :disabled="processChoice == null && selectedProcess == null"
        :loading="addingUnits"
        @click="addUnitsToRentalProcess"
      >
        {{ selectedProcess == null ? $t("leasing.add") : $t('leasing.add_and_continue') }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import format from '../../helpers/format'
import { getRentalProcessTitle } from '../../helpers/leasing/rentalprocess'
import BaseModal from '../general/BaseModal'

export default {
  name: 'AttachUnitToRentalProcessForm',
  components: {
    BaseModal,
  },
  props: {
    currentSite: {
      type: Object,
      default: () => {}
    },
    isShown: {
      type: Boolean,
      default: false
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    totalArea: {
      type: Number,
      default: 0
    },
    selectedUnitsString: {
      type: String,
      default: ''
    },
    selectedProcess: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      processesFetching: false,
      processChoice: null,
      addingUnits: false
    }
  },
  computed: {
    ...mapState('rentalProcess', ['rentalProcesses']),

    selectableProcesses () {
      const currentSite = this.$route.params.id_site;
      if (!currentSite) {
        return [];
      }

      const validStages = ['1', '2', '3', '4', '5'];

      // Filter the processes based on the status and stage
      const filteredProcesses = this.rentalProcesses.filter(
        process =>
          process.status === 'Active' && validStages.includes(process.stage.charAt(0))
      );

      return filteredProcesses.map(process => {
        const site = process.sites.find(site => site.id_site === currentSite);

        // If the site exists and not all selected unit IDs are included in the site's unit IDs, return the process
        if (site && !this.selectedUnitsIds.every(id => site.units.map(item => item.id_unit).includes(id))) {
          return process;
        }

        // If the site doesn't exist or all selected unit IDs are included in the site's unit IDs, return the process
        return process;
      });
    },

    selectedUnitsIds () {
      return this.selectedUnits.map(unit => unit.id)
    }
  },
  watch: {
    isShown: function (shown) {
      if (shown && this.selectedProcess == null) {
        this.processChoice = null;
        this.processesFetching = true
        this.getRentalProcess({}).then(() => this.processesFetching = false)
      }
    }
  },
  async mounted () {
    this.processesFetching = true
    this.getRentalProcess({}).then(() => this.processesFetching = false)
  },
  methods: {
    ...mapActions('rentalProcess', ['getRentalProcess', 'updateRentalProcess']),

    close () {
      this.$emit('close', false)
    },
    getRentalProcessTitle (process) {
      return getRentalProcessTitle(process)
    },
    addUnitsToRentalProcess () {
      let process = null
      if (this.selectedProcess) {
        process = this.selectedProcess
      } else {
        process = this.processChoice  
      }
      if (!this.currentSite || !process) {
        return
      }
      this.addingUnits = true
      const units = this.selectedUnits.map(unit => {return {id_unit: unit.id}})
      const siteObject = {
        id_site: this.currentSite.id_site,
        siteName: this.currentSite.name, 
        units
      }

      const siteIndex = process.sites.findIndex(site => site.id_site === this.currentSite.id_site);
      if (siteIndex !== -1) {
        process.sites[siteIndex].units = process.sites[siteIndex].units.concat(units);
      } else {
        process.sites.push(siteObject);
      }
      this.$rambollfmapi.leasing.rentalProcesses.put(process)
        .then(res => {
          this.addingUnits = false
          this.$emit('showSuccessTooltip', res)
        })
        .finally(() => {
          this.$emit('close', true)
        })
    },
    getValueWithUnit (value, formatting) {
      return format.formatData(value, formatting)
    },
  }
}
</script>

<style scoped>
.form-fields {
  margin-top: 40px !important;
  display: flex;
}
.large-padding {
  padding: 50px !important;
}
</style>