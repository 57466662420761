<template>
  <v-btn
    color="secondary"
    depressed
    rounded
  >
    <v-row
      v-if="sitesLoading" 
      justify="center"
      align="center"
      no-gutters
    >
      <v-progress-circular
        :size="20"
        :width="3"
        indeterminate
        color="white"
      />
    </v-row>
    <div
      v-else
    >
      <v-row
        justify="center"
        align="center"
        no-gutters
      >
        <v-icon color="white">
          filter_list
        </v-icon>
        <div style="color: white">
          {{ sites.length + ' ' + $t('filtered sites') }}
        </div>
        <v-icon
          color="white"
          @click="clearSiteSelections"
        >
          close
        </v-icon>
      </v-row>
    </div>
  </v-btn>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  components: {},
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('app', ['sites', 'sitesLoading'])
  },
  mounted () {
  },
  methods: {
    ...mapActions('app', ['getCemeteries']),
    ...mapMutations('app', ['clearSiteFilters']),
    clearSiteSelections () {
      this.clearSiteFilters()
      this.$store.dispatch('app/getRecursiveSites')
      this.getCemeteries()
    }
  }
}
</script>
<style scoped>
</style>