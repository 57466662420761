/**
 * Site party filter
 */
export class SitePartyFilter {
  /**
    * Initializes filter
    * @param {String} filterName - Filter name eg. key of the siteFilters object
    * @param {Object} filterValues - Values which are used for filtering
    * @param {Object} params - Additional params
    * @param {Object[]} params.allSitesParties - List of available site parties
    */
  constructor (filterName, filterValues, params) {
    this.filterName = filterName
    this.partyFilters = filterValues
    this.allSitesParties = params.allSitesParties
  }

  /**
   * Takes in a list of sites and filters the list based on the provided params
   * @param {Object[]} sites - List of sites object
   * @returns Filtered sites list
   */

  filter (sites) {
    if (!this.partyFilters || Object.keys(this.partyFilters).length === 0) {
      return sites
    }

    const permittedSites = Object.entries(this.partyFilters)
      .reduce((acc, [group, partyFilter]) => {

        this.allSitesParties
          .filter(party => partyFilter.includes(party.id) && party.annotationLabel === group)
          .forEach(siteParty => {
            siteParty.sites.forEach(site => {
              acc[site] = site
            })
          })
        return acc

      }, {})

    return sites.filter(site => permittedSites[site.id_site])
  }

}

export const isValidSitePartyFilter = (filterName, filterValues) => {

  if (filterName !== 'partyFilters') {
    return false
  }

  return Object.keys(filterValues).length > 0

}