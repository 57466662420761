<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="750"
  >
    <BaseModal @cancel="cancel()">
      <template #title>
        {{ certificate.id ? $t('esg.certificate.edit') : $t('esg.certificate.add_new') }}
      </template>
      <template #content>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  :value="currentSiteName"
                  :label="$t('esg.certificate.site')"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="certificate.level"
                  :label="$t('esg.certificate.level')"
                  :items="levelList"
                  item-value="value"
                  item-text="text"
                  clearable
                  @change="certificate.idEstate = null, certificate.idBuilding = null"
                />
              </v-col>
            </v-row>
            <v-row v-if="certificate.level === 2 || certificate.level === 3">
              <v-col>
                <v-select
                  v-if="certificate.level === 2"
                  v-model="certificate.idEstate"
                  :label="$t('esg.certificate.estate')"
                  :items="estatesForCurrentSite"
                  item-value="estate_id"
                  item-text="name"
                  clearable
                />
                <v-select
                  v-if="certificate.level === 3"
                  v-model="certificate.idBuilding"
                  :label="$t('esg.certificate.building')"
                  :items="buildingsForCurrentSite"
                  item-value="id_building"
                  item-text="building_name"
                  clearable
                  @change="changeBuildingId"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="certificate.status"
                  :label="$t('esg.certificate.status')"
                  :items="statusList"
                  item-value="value"
                  item-text="text"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="certificate.scheme"
                  :label="$t('esg.certificate.scheme') + ' *'"
                  :items="schemeList"
                  item-value="value"
                  item-text="text"
                  :rules="[required]"
                  @change="gradeList = getSelectedGradeList(certificate.scheme), certificate.grade = null"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                  v-model="selectedSpecifications"
                  multiple
                  small-chips
                  :items="getAvailableSpecifications"
                  :label="$t('esg.certificate.scheme_specification')"
                  class="multi-select-field"
                >
                  <template #selection="data">
                    <v-chip
                      :key="data.item"
                      class="v-chip--select-multi"
                      close
                      @click.stop
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="certificate.grade"
                  :label="$t('esg.certificate.grade')"
                  :items="gradeList"
                  item-value="value"
                  item-text="text"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="certificate.gradeValue"
                  :label="$t('esg.certificate.grade_value')"
                  clearable
                  :rules="[validGradeValue]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  ref="issued"
                  v-model="issued"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(certificate.issued)"
                      :label="$t('esg.certificate.date_of_issue')"
                      append-icon="calendar_today"
                      clearable
                      :rules="[validIssued]"
                      v-bind="attrs"
                      @click:clear="certificate.issued = null"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="certificate.issued"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="expires"
                  v-model="expires"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(certificate.expires)"
                      :label="$t('esg.certificate.date_of_expiry')"
                      append-icon="calendar_today"
                      clearable
                      :rules="[validExpires]"
                      v-bind="attrs"
                      @click:clear="certificate.expires = null"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="certificate.expires"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="certificate.notes"
                  :label="$t('esg.certificate.notes')"
                  clearable
                  :rules="[validNotes]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="certificate.area"
                  type="number"
                  :label="$t('esg.certificate.area')"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="!valid"
          depressed
          rounded
          color="primary"
          @click="saveCertificate()"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    <template #activator="{ on, attrs }">
      <v-btn
        small
        outlined
        rounded
        v-bind="attrs"
        v-on="on"
        @click="addNew"
      >
        {{ $t('esg.certificate.add_new') }}
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
  import BaseModal from './general/BaseModal'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import moment from 'moment'
  export default {
    components: { BaseModal },
    props:{ 
      value: {
        type: Boolean,
        default: false
      },
      siteId: {
        type: Number,
        default: null
      },
      certificateId: {
        type: Number,
        default: null
      }
    },
    data () {
        return {
          certificate: {
            idSite: null,
            idEstate: null,
            idBuilding: null,
            level: null,
            scheme: 1,
            grade: null,
            gradeValue: null,
            issued: null,
            expires: null,
            notes: null,
            area: null
          },
          valid: false,
          saving: false,
          issued: false,
          expires: false,
          gradeList: null,
          currentSiteName: null,
          selectedSpecifications: [],
        }
    },
    computed: {
      ...mapGetters('sites', [ 
        'currentSite',
        'estatesForCurrentSite',
        'buildingsForCurrentSite' 
      ]),
      ...mapGetters('esg', [ 
        'getAvailableSpecifications', 
        'getSelectedGradeList',
        ]),
      ...mapState('esg', [ 
        'levelList',
        'statusList',
        'schemeList',
      ]),
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    async updated () {
      await this.loadAvailableSpecifications()
      this.currentSiteName = this.currentSite.name
      if (this.certificateId !== null && this.show) {
        const result = await this.$rambollfmapi.esg.certificates().getCertificateById(this.certificateId)
        this.certificate = this.mapCertificateData(result)
        this.certificate.schemeSpecifications?.forEach(s => this.selectedSpecifications.push(s.name))
        this.gradeList = this.getSelectedGradeList(this.certificate.scheme) 
      }
    },
    methods: {
      ...mapActions('esg', [ 'loadAvailableSpecifications' ]),
      required (v) {
        return !!v || this.$t('Required')
      },
      validIssued (v) {
        if (this.certificate.expires && v) {
          const splitIssued = v.split('.')
          const splitExpires = this.certificate.expires.split('-')
          const issued = new Date(`${splitIssued[2]}-${splitIssued[1]}-${splitIssued[0]}`)
          const expires = new Date(`${splitExpires[0]}-${splitExpires[1]}-${splitExpires[2]}`)

          if (issued > expires) {
            return this.$t('esg.certificate.notValidIssued')
          }
        }
        return true
      },
      validExpires (v) {
        if (this.certificate.issued && v) {
          const splitExpires = v.split('.')
          const splitIssued = this.certificate.issued.split('-')
          const expires = new Date(`${splitExpires[2]}-${splitExpires[1]}-${splitExpires[0]}`)
          const issued = new Date(`${splitIssued[0]}-${splitIssued[1]}-${splitIssued[2]}`)

          if (expires < issued) {
            return this.$t('esg.certificate.notValidExpires')
          } 
        }
        return true
      },
      validNotes (v) {
        if (v && v.length > 1000) {
          return this.$t('esg.certificate.notValidNotes')
        }
        return true
      },
      validGradeValue (v) {
        if (v && v.length > 500) {
          return this.$t('esg.certificate.notValidGradeValue')
        }
        return true
      },
      cancel () {
        this.show = false
        this.resetModal()
        this.$emit('close')
      },
      resetModal () {
        this.certificate = {
          idSite: this.currentSite.id_site,
          idEstate: null,
          idBuilding: null,
          level: null,
          scheme: 1,
          grade: null,
          gradeValue: null,
          issued: null,
          expires: null,
          notes: null,
          area: null
        }
        this.gradeList = this.getSelectedGradeList(1)
        this.selectedSpecifications = []
      },
      async addNew () {
        this.certificate.idSite = this.currentSite.id_site
        this.gradeList = this.getSelectedGradeList(1)
      },
      async saveCertificate () {
        this.saving = true

        if (this.certificate.id) {
          const editedCertificate = this.mapEditedCertificateData(this.certificate)
          await this.$rambollfmapi.esg.certificates().updateCertificate(this.certificate.id, editedCertificate)
        } else {
          const specificationsStr = this.selectedSpecifications.join(',')
          await this.$rambollfmapi.esg.certificates().addNewCertificate(this.certificate, specificationsStr)
        }
        
        this.$emit('updateWidget')
        this.saving = false
        this.cancel()
      },
      formatDate (date) {
        const formatted = moment(date, "YYYY-MM-DD")
        return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
      },
      changeBuildingId () {
        if (this.certificate.idEstate === null && this.certificate.idBuilding !== null) {
          this.certificate.idEstate = this.buildingsForCurrentSite.find(b => b.id_building === this.certificate.idBuilding).id_estate
        }
      },
      mapCertificateData (item) {
        return {
          id: item.id,
          idSite: item.id_site,
          idEstate: item.id_estate,
          idBuilding: item.id_building,
          status: item.status,
          level: item.level,
          scheme: item.scheme,
          grade: item.grade,
          gradeValue: item.grade_value,
          issued: item.issued,
          expires: item.expires,
          notes: item.notes,
          area: item.area,
          added: item.added,
          addedBy: item.added_by,
          modified: item.modified,
          modifiedBy: item.modified_by,
          schemeSpecifications: item.scheme_specification
        }
      },
      mapEditedCertificateData (item) {
        return {
          added: item.added,
          added_by: item.addedBy,
          area: item.area,
          expires: item.expires,
          grade: item.grade,
          grade_value: item.gradeValue,
          id_building: item.idBuilding,
          id_estate: item.idEstate,
          id_site: item.idSite,
          id: item.id,
          issued: item.issued,
          level: item.level,
          modified: item.modified,
          modified_by: item.modifiedBy,
          notes: item.notes,
          scheme: item.scheme,
          status: item.status,
          scheme_specification: this.selectedSpecifications.map(s => { return { "idCertificate": item.id, "name": s } })
        }
      },
    }
  }
  
  </script>
<style scoped>
form {
  padding-bottom: 30px;
}

.multi-select-field {
  height: auto !important;
}
</style>
  