<template>
  <v-text-field
    v-model="model"
    type="number"
    :required="isRequired"
    :rules="rules"
    :placeholder="getPlaceholderValue(placeholder)"
    :label="label"
    :aria-label="ariaLabel"
    :append-icon="appendIcon"
    @change="emitChange"
    @input="emitInput"
  />
</template>
<script>
export default {
  name: 'NumericField',
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: null,
    placeholder: null,
    field: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      model: null
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler (oldVal, newVal) {
        const number = Number(this.value)
        if (!isNaN(number)) {
          this.model = this.value
        } else {
          this.model = oldVal
        }
      }
    }
  },
  methods: {
    inspection (isInput = false) {
      let feed = this.model
      if (isInput === true) {
        if(feed !== null){
          feed = feed.replace(',', '.')
        }
      }
      if (!isNaN(feed) && feed !== '.') {
        if (feed === '') {
          feed = null
        }
        if (this.field !== null) {
          if (this.field.type === 'Int32' && feed !== null) {
            feed = Math.round(feed)
          }
        }
      }
      return feed
    },
    emitInput () {
      this.$emit('input', this.inspection(true))
    },
    emitChange () {
      this.$emit('change', this.field, this.inspection())
    },
    getPlaceholderValue (value) {
      if (value === undefined || value === null) {
        return ''
      }

      if (isNaN(value)) {
        return ''
      }

      return String(value)
    },
  }
}
</script>
