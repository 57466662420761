<template>
  <div
    v-if="ariaLive"
    class="d-sr-only"
    aria-live="assertive"
  >
    {{ ariaLive }}
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AriaLive',
  components: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('app', ['ariaLive']),
  },
}
</script>

<style scoped>
</style>
