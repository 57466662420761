<template>
  <v-row class="flex-nowrap">
    <v-col
      cols="3"
      align-self="center"
      class="text-left flex-nowrap"
    >
      <span class="rating">{{ sourceData.part_star_rating.grade }}</span>
    </v-col>
    <v-col
      cols="3"
      align-self="center"
      class="text-right flex-nowrap"
    >
      <span class="score">{{ sourceData.part_overall_score + ' %' }}</span>
    </v-col>
    <v-col
      cols="6"
      class="text-right flex-nowrap"
    >
      <span
        v-for="star in 6"
        :key="star"
      >
        <v-icon
          v-if="star <= sourceData.part_star_rating.stars"
          large
          color="primary"
        >
          star
        </v-icon>
        <v-icon
          v-else
          large
          color="primary"
        >
          star_outline
        </v-icon>
      </span>
    </v-col>
  </v-row>
</template>
<script>

export default {
    name: 'GradeAndStarRating',
    components: {
    },
    props: {
        sourceData: {
            type: Object,
            default: null
        }
    },
}
</script>
<style scoped>
.rating {
  font-size: 14px;
  font-weight: normal;
}
.score {
  font-size: 14px;
  font-weight: normal;
}
</style>