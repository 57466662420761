import { i18n } from "../../../plugins/i18n"

export function waitingForSigning () {
  return {
    id: "contract.waiting_for_signing",
    type: "ObjectTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contract_number",
          event: "showCreateRentContractModal",
        },
        { text: "tenantName", value: "tenantName" },
        { text: "Site name", value: "siteNameWithoutLink" },
        { text: "Landlord contact person", value: "landlord_contact_person" },
        {
          text: "new_handinvoice.contract_startdate",
          value: "start_date",
          format: "Date",
        },
        { text: "Contract status", value: "contractStateTr" },
      ],
      sortBy: "contractStateTr",
      sortDesc: false,
      items: [],
    },
    dataFetcher: (context) => {
      const { waitingForSigningContracts } = context
      return waitingForSigningContracts.map((contract) => {
        return {
          ...contract,
          tenantName: contract.tenant, // Tenant is reserved value in ObjectTable
          siteNameWithoutLink: contract.target_name[0],
          contractStateTr: i18n.t(contract.contract_state),
          // Information for contract modal
          contractId: contract.id,
        }
      })
    },
    isLoading: (context) => {
      const { waitingForSigningContractsLoading } = context

      return waitingForSigningContractsLoading
    },
  }
}
