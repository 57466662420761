<template>
  <td>
    <div
      :class="isTooLong(item, header.value)"
      class="hide-overflow-column"
    >
      <div class="hide-overflow-column__text">
        {{ formatData(item, header.value, header.format) }}
      </div>
      <button
        v-if="hideOverflow"
        class="hide-overflow-column__button linked"
        @click="isHidden = !isHidden"
      >
        {{ toggleText }}
      </button>
    </div>
  </td>
</template>
<script>
  import helpers from '../../helpers'
  
  export default {
    name: 'HideOverflowColumn',
    components: {
    },
    props: {
      header: { 
        type: Object,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      }
    },
    data: function () {
      return {
        hideOverflow: true,
        isHidden: true,
      }
    },
    computed: {
      toggleText () {
        return this.isHidden ? this.$t('Show more') : this.$t('Show less')
      },
    },
    methods: {
      formatData (row, header, format) {
        return helpers.format.formatData(row[header], format)
      },
      isTooLong (row, header) {
        if (row[header]?.length < 50 || row[header] === null) {
          this.hideOverflow = false
          return ''
        }
        this.hideOverflow = true
        return this.isHidden ? 'is-hidden' : ''
      },
    },
  }
</script>
<style scoped>
.hide-overflow-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.is-hidden {
  position: relative;
  max-width: 200px;
}
.is-hidden .hide-overflow-column__text {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
}
</style>