<template>
  <v-menu
    :disabled="disabled"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-btn
        v-if="value === null"
        slot="activator"
        small
        icon
        outlined
        v-on="on"
      >
        <v-icon>format_paint</v-icon>
      </v-btn>
      <v-btn
        v-else
        slot="activator"
        small
        icon
        text
        :style="{ background: `rgb(${red}, ${green}, ${blue})` }"
        v-on="on"
      >
        <span class="d-sr-only">{{ `${$t('Color')}: rgb(${red}, ${green}, ${blue})` }}</span>
      </v-btn>
    </template>
    <v-card class="color-card">
      <v-card-text>
        <v-row justify="end">
          <v-spacer :style="{ background: `rgb(${red}, ${green}, ${blue})` }" />
          <v-btn
            text
            outlined
            rounded
            @click="clearInput"
          >
            {{ $t('Clear') }}
          </v-btn>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-slider
            v-model="red"
            height="0"
            :max="255"
            label="R"
            @change="handleInput"
          />
          <v-text-field
            v-model="red"
            class="borderless color-selector"
            type="number"
            @change="handleInput"
          />
        </v-row>
        <v-row>
          <v-slider
            v-model="green"
            height="0"
            :max="255"
            label="G"
            @change="handleInput"
          />
          <v-text-field
            v-model="green"
            class="borderless color-selector"
            type="number"
            @change="handleInput"
          />
        </v-row>
        <v-row>
          <v-slider
            v-model="blue"
            height="0"
            :max="255"
            label="B"
            @change="handleInput"
          />
          <v-text-field
            v-model="blue"
            class="borderless color-selector"
            type="number"
            @change="handleInput"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      red: 0,
      green: 0,
      blue: 0
    }
  },
  computed: {},
  watch: {
    value: function (newValue) {
      this.assignValue(newValue)
    }
  },
  mounted () {
    this.assignValue(this.value)
  },
  methods: {
    assignValue (newValue) {
      if (newValue === null) {
        return
      }
      const split = this.value.split(',')
      this.red = split[0]
      this.green = split[1]
      this.blue = split[2]
    },
    handleInput (e) {
      this.$emit('input', `${this.red},${this.green},${this.blue}`)
    },
    clearInput (e) {
      this.red = 0
      this.green = 0
      this.blue = 0
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
.color-card {
  width: 28em;
  height: 18em;
}
.color-selector {
  margin: 0;
  padding: 0;
  margin-left: 2em;
  width: 3em;
}
</style>
