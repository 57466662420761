<template>
  <div
    class="rb-search"
    style="width: 100%; margin: 0px 4px"
  >
    <v-row
      v-if="selectedResult !== null && selectionReady"
      class="horizontal"
    >
      <v-col
        class="text-sm-left grow align-content-center"
        style="line-height: 40px"
      >
        <div v-if="multiple">
          {{ $t('Selected {n} items', {n:selectedResult.length}) }}
        </div>
        <slot
          v-else
          :result="selectedResult"
          name="row"
        >
          {{ selectedResult.address }}, {{ selectedResult.zipCode }} {{ selectedResult.post_office }} ({{ selectedResult.buildingId }})
        </slot>
      </v-col>
      <v-col class="shrink">
        <v-btn
          v-if="selectedResult !== null"
          small
          icon
          @click="resetSeach(); searchDone()"
        >
          <v-icon>close</v-icon>
          <span class="d-sr-only">{{ $t('Close') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-menu
      v-else
      v-model="searchResults"
      :close-on-content-click="!persistent || !multiple"
      offset-y
      style="width: 100%"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="searchField"
          :loading="searchInProgress"
          :placeholder="placeholder"
          :label="label"
          style="padding-top: 0; width: 100%"
          prepend-icon="search"
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-list v-if="searchResults.length > 0">
        <v-list-item
          v-if="!noTitle"
          :ripple="false"
          @click.stop
        >
          <slot
            :results="searchResults"
            name="title"
          />
        </v-list-item>
        <v-list-item
          v-for="(result, index) in searchResults"
          :key="index"
          @click="focusToSearchResult(result)"
        >
          <v-list-item-title
            v-if="multiple"
            @click="toggleSelection(result)"
            @click.stop
          >
            <v-checkbox :value="isSelected(result)" />
          </v-list-item-title>
          <v-list-item-title>
            <slot
              :result="result"
              name="row"
            >
              {{ result.address }}, {{ result.zipCode }} {{ result.post_office }} ({{ result.buildingId }})
            </slot>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="multiple">
          <v-list-item-title>{{ $t('Total {n} results', {n: totalResults}) }}</v-list-item-title>
          <v-list-item-title>
            <v-btn
              small
              icon
              @click="moveToPage(currentPage - 1)"
            >
              <v-icon>arrow_left</v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-title style="text-align: center">
            {{ $t('Page {n}', {n: currentPage}) }}
          </v-list-item-title>
          <v-list-item-title>
            <v-btn
              small
              icon
              @click="moveToPage(currentPage + 1)"
            >
              <v-icon>arrow_right</v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-title>
            <v-btn
              small
              outlined
              @click="searchDone()"
            >
              {{ $t('Done') }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  props: {
    label: {
      default: '',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    endpoint: {
      default: null,
      type: String
    },
    data: {
      default: () => {
        return []
      },
      type: Array
    },
    searchFrom: {
      default: () => {
        return []
      },
      type: Array
    },
    clearAfterSelect: {
      default: false,
      type: Boolean
    },
    noTitle: {
      default: false,
      type: Boolean
    },
    persistent: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    levels: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      totalResults: 0,
      searchResults: [],
      searchInProgress: false,
      searchField: '',
      showResults: false,
      selectedResult: null,
      selectionReady: false,
      currentPage: 1,
      selectedDate: null
    }
  },
  computed: {
    ...mapState('app', ['currentDate'])  
  },
  watch: {
    searchField (val) {
      // user is typing
      this.currentPage = 1
      this.searchLastPress = new Date().getTime()
      if (val.length > 3 || this.data.length > 0) {
        const self = this
        setTimeout(function () {
          const currentTime = new Date().getTime()
          if (self.searchLastPress === null) {
            self.$log.debug('set time out: return')
            return
          }
          const time = currentTime - self.searchLastPress

          if (time > 450) {
            self.retrieveSearchResults(val)
          } else {
            self.$log.debug('else time: ' + time)
          }
        }, 700)
      } else {
        this.searchLastPress = null
        this.searchResults = []
        this.searchInProgress = false
      }
    },
    currentDate: function (value) {
      this.selectedDate = value
    },
  },
  mounted () {
    this.selectedDate = this.currentDate
  },
  methods: {
    isSelected (result) {
      if (this.selectedResult === null) {
        return false
      }
      const idKeys = Object.keys(result).filter(k =>
        k.toLowerCase().startsWith('id')
      )
      const existing = this.selectedResult.findIndex(r => {
        for (let k = 0; k < idKeys.length; k++) {
          const key = idKeys[k]

          if (r[key] !== result[key]) {
            return false
          }
        }
        return true
      })

      if (existing >= 0) {
        return true
      }
      return false
    },
    toggleSelection (result) {
      if (this.selectedResult === null) {
        this.selectedResult = []
      }

      const idKeys = Object.keys(result).filter(k =>
        k.toLowerCase().startsWith('id')
      )
      const existing = this.selectedResult.findIndex(r => {
        for (let k = 0; k < idKeys.length; k++) {
          const key = idKeys[k]

          // this.$log.debug(r[key], result[key])
          if (r[key] !== result[key]) {
            return false
          }
        }
        return true
      })

      if (existing >= 0) {
        this.selectedResult.splice(existing, 1)
      } else {
        this.selectedResult.push(result)
      }
      return false
    },
    searchDone () {
      this.$emit('select', {
        query: this.searchField,
        results: this.selectedResult
      })
      this.selectionReady = true
      if (this.clearAfterSelect) {
        this.resetSeach()
      }
    },
    focusToSearchResult (result) {
      if (!this.multiple) {
        this.selectedResult = result
        this.searchDone()
      } else {
        this.toggleSelection(result)
      }
    },
    resetSeach () {
      this.searchResults = []
      this.searchInProgress = false
      this.searchField = ''
      this.selectedResult = null
      this.selectionReady = false
    },
    moveToPage (page) {
      if (page < 1) {
        page = 1
      }

      if (page > this.totalResults / page) {
        page = Math.floor(this.totalResults / page)
      }
      this.currentPage = page
      this.retrieveSearchResults(this.searchField)
    },
    retrieveSearchResults (val) {
      const self = this
      self.searchInProgress = true
      // If endpoint is defined, use it to provide results
      if (typeof self.endpoint !== 'undefined' && self.endpoint !== null) {
        axios
          .get(self.endpoint, {
            params: {
              time: self.selectedDate,
              search: val,
              limit: 10,
              searchLevels: self.levels.toString(),
              offset: (self.currentPage - 1) * 5
            }
          })
          .then(res => {
            if (typeof res.headers['x-total-count'] !== 'undefined') {
              self.totalResults = res.headers['x-total-count']
            } else {
              self.totalResults = res.data.length
            }
            self.searchResults = res.data
            self.searchInProgress = false
          })
      } else {
        // no endpoint, use data to search results
        const data = self.data
        const query = val.toLowerCase()
        /* if (data === null || data === undefined || data.length === 0) {
                self.searchInProgress = false
                return
        } */
        let columns = Object.keys(data[0]).filter(
          c => typeof data[0][c] === 'string'
        )
        if (self.searchFrom.length > 0) {
          columns = self.searchFrom
        }
        const results = []

        data.forEach(row => {
          let found = false
          let score = 0
          for (let c = 0; c < columns.length; c++) {
            const column = columns[c]
            const rowVal = row[column] + ''
            const matchIdx = rowVal.toLowerCase().indexOf(query)
            if (matchIdx >= 0) {
              found = true
              score += (query.length - matchIdx) / query.length
            }
          }

          if (found) {
            row._score = score
            results.push(row)
          }
        })

        self.searchResults = results
          .sort((a, b) => (a._score < b._score ? 1 : -1))
          .slice(0, 10)
        self.searchInProgress = false
      }
    }
  }
}
</script>
<style>
</style>
