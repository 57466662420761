<template>
  <div id="VTHViewerParent">
    <div
      class="outer"
    >
      <div
        ref="legend"
        class="inner"
      >
        <VTHPrintLegend 
          v-if="activeCategory !== null"
          :all-items="activeCategory"
          :selected-items="selectedCategoryOptions"
          :floor="floor"
        />
      </div>
    </div>
    <div id="VTHViewerDiv" />
    <div
      id="VTHViewerButtons"
    >
      <div
        id="VTHViewerButtons"
      >
        <v-row>
          <v-col cols="11">
            <v-btn
              text
              rounded
              outlined
              :input-value="multiSelectActive"
              class="bottom_button"
              active-class="selection-mode-active"
              @click="toggleMultiselect()"
            >
              Monivalinta
            </v-btn>
          </v-col>
          <v-col
            class="text-right"
            cols="1"
          >
            <v-btn
              text
              icon
              outlined
              class="bottom_button"
              @click="printDrawing()"
            >
              <v-icon v-if="!isPrinting">
                print
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                small
                color="primary"
              />
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import ThreeJSWrapper from '@/modules/v3viewer/threejswrapper'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import VTHPrintLegend from './VTHPrintLegend'

/* eslint-disable no-console */
export default {
  name: 'VTHViewer',
  components: {
    VTHPrintLegend
  },
  props: {
    floorId: {
      type: Number,
      default: null
    },
    selectedCategory: {
      type: Object,
      default: null
    },
    selectedSpaces: {
      default: () => {
        return []
      },
      type: Array
    },
    categorySelectedSpaces: {
      default: () => {
        return []
      },
      type: Array 
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    selectedCategoryOptions: {
      default: () => {
        return []
      },
      type: Array
    },
    floor: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      multiSelectActive: false,
      threeJsWrapper: null,
      camera: null,
      scene: null,
      renderer: null,
      geometry: null,
      material: null,
      mesh: null,
      visibility: false,
      isPrinting: false,
      activeCategory: null
      // downloadingProgress: {
      //   retrievalProgress: 0
      // },
    }
  },
  watch: {
    selectedCategory: {
      handler: function (cat) {
        this.activeCategory = this.selectedCategory
        this.threeJsWrapper.clearSpaceColors()
        this.threeJsWrapper.clearAllSpaceSelections()
      }
    },
    selectedSpaces: {
      handler: function (spacesWithColor) {
        this.threeJsWrapper.clearSpaceColors()
        if (spacesWithColor !== null) {
          for (let category of spacesWithColor) {
            let revguid = category.identifier
            let color = 'rgb(' + category.color + ")"
            this.threeJsWrapper.setSpaceColor(revguid, color)
          }
        }
      },
      deep: true
    },
    categorySelectedSpaces: {
      handler: function (spaces){
        if (spaces !== null) {
          this.threeJsWrapper.clearAllSpaceSelections(false)
          this.threeJsWrapper.clearSpaceColors()
          for (let space of spaces) {
            this.threeJsWrapper.selectSpace(space.identifier)
          } 
        }  
      }  
    },
    'threeJsWrapper.recolorCategories': {
      handler: function (bool){
        if (bool === true) {
          this.threeJsWrapper.clearSpaceColors()
          if (this.selectedSpaces !== null && this.activeCategory !== null) {
            for (let category of this.selectedSpaces) {
              let revguid = category.identifier
              let color = 'rgb(' + category.color + ")"
              this.threeJsWrapper.setSpaceColor(revguid, color)
            }
          }
          this.threeJsWrapper.recolorCategories = false
          this.deleteSelections(this.selectedSpaces)
        }
      },
    },
    floorId: {
      immediate: true,
      async handler (val, oldVal) {
        if (oldVal === undefined) {
          return
        }

        if (typeof(this.threeJsWrapper) === 'undefined') {
          return
        }

        this.threeJsWrapper.removeAllModels()
        const currentDate = this.$store.state.app.currentDate
        let excludedSpaces = []
        if (!this.isAdmin) {
          const spaces = await this.$rambollfmapi.floors.spaces(this.floorId, currentDate).list()        
          excludedSpaces = spaces.filter(space => space.is_shared_space).map(space => space.space_rev_guid)
        }
        
        try {
          const idSite = this.$route.params.id_site
          const res = await this.$rambollfmapi.floors.xml(val, currentDate, progress => {}, idSite)
          if (res.data !== undefined) {
            let xmlText = await res.data.text()
            this.threeJsWrapper.loadModel(xmlText, excludedSpaces)
          } else {
              this.$emit('model-failed')
          }
          this.threeJsWrapper.disableAllLayers()
          this.threeJsWrapper.enableLayers([1, 3, 4, 5, 6, 7, 8, 9, 11])
        } catch (err) {
          console.error('Error loading XML data from API', err)
          this.$emit('model-failed')
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    toggleMultiselect () {
      if (this.multiSelectActive === true) {
        this.threeJsWrapper.disableMultiselect()
      }
      else {
        this.threeJsWrapper.enableMultiselect()
      }
      this.multiSelectActive = !this.multiSelectActive
    },
    init () {
      let container = document.querySelector('#VTHViewerDiv')
      this.threeJsWrapper = new ThreeJSWrapper(container, this.spaceSelection, this.modelLoaded)
    },
    spaceSelection (selectedSpaces) {
      console.log('*** element selected', selectedSpaces)
      this.$emit('select-elements', "1", selectedSpaces, "xml")
    },
    modelLoaded (result) {
      console.log('*** model loaded', result)
      this.$emit('model-loaded', {
        xmlData: result,
      })
    },
    deleteSelections (selectedSpaces) {
      this.$emit('delete-selections', selectedSpaces)
    },
    // resize () {
    //   // let container = document.getElementById('container')
    //   if (this.threeJsWrapper.container == null)
    //     return;
    //
    //   const width = this.threeJsWrapper.container.clientWidth
    //   const height = this.threeJsWrapper.container.clientHeight
    //   //const height = this.$el.scrollHeight
    //   // const width = container.clientWidth
    //   // const height = this.$parent.$el.clientHeight
    //
    //   // console.log('*** viewer resolution', width, height)
    //
    //   this.threeJsWrapper.camera.aspect = width / height;
    //   this.threeJsWrapper.camera.updateProjectionMatrix();
    //   this.threeJsWrapper.renderer.setSize( width, height );
    // }
    async printDrawing ()
    {
      this.isPrinting = true
      this.visibility = true
      await this.$nextTick()
      let renderer = this.threeJsWrapper.renderer
      let canvas = renderer.domElement
      var element = this.$refs.legend
     
      let canvas2 = await html2canvas(element, {scale:2, backgroundColor: null})
      this.visibility = false
          
      let camera = this.threeJsWrapper.camera
      let scene = this.threeJsWrapper.scene
      let zoom = camera.zoom
       
      let cwidth = canvas.width
      let cheight = canvas.height
      // 300 dpi on A4
      let width = 3508
      let height = 2480

      let canvasRatio = cwidth / cheight
      let printRatio = width / height
      let zoomFactor = printRatio / canvasRatio
      
      // scale up the picture temporarily for printing
      camera.zoom = zoomFactor * zoom
      camera.updateProjectionMatrix()
      renderer.setSize(width, height, false)
      renderer.render(scene, camera)

      // render canvas to png
      var imgData = renderer.domElement.toDataURL('image/png', 1.0);   
      var img = new Image()
      img.src = imgData
     
      // set size of print
      var printWidth = 297
      var printHeight = 210
    
      // create new pdf
      var doc = new jsPDF("l", "mm", [printWidth, printHeight])
      let ratio = printWidth / printHeight
      let cratio = cwidth / cheight
      //if width to height ratio of canvas is different than document's, scale and center
      if(ratio <= cratio)
      {
        let scaledw=printHeight*(cwidth/cheight)
        let centerw = (scaledw - printWidth) / 2
        // add image to pdf scaled and centered
        doc.addImage(imgData, 'PNG', -centerw, 0, scaledw, printHeight)
      }
      else if (ratio > cratio)
      {
        let scaledh=printWidth*(cheight/cwidth)
        let centerh = (scaledh - printHeight) / 2
        doc.addImage(imgData, 'PNG', 0, -centerh, printWidth, scaledh)
      }
      // if category selected add legend
      if (this.selectedCategory !== null)
      {
        var imgData2 = canvas2.toDataURL('image/png', 1.0);
        var img2 = new Image()
        img2.src = imgData2
        let legendWidth = element.offsetWidth / 6
        let legentdHeighth = element.offsetHeight / 6
        doc.addImage(imgData2, 'PNG', 5, 5, legendWidth, legentdHeighth)
      }
      // open a tab for pdf
      window.open(URL.createObjectURL(doc.output("blob")))
      // set canvas back to original size
      camera.zoom = zoom
      camera.updateProjectionMatrix()
      renderer.setSize(cwidth, cheight, false)
      renderer.render(scene, camera)
     
      this.isPrinting = false
      this.activeCategory = null
    },
  }
}
/* eslint-enable */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v3viewer {
  position: relative;
  height: 99%;
}

.v3viewer .toolbar {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: none !important;
}

.v3viewer .toolbar .v-btn {
  background: white;
}

#VTHViewerDiv {
  background-color: cornflowerblue;
  height: 100%;
}

#VTHViewerButtons {
  position: absolute;
  text-align: left;
  width: 90%;
}

#VTHViewerParent {
  height: 100%;
}

.dummy {
  height: 100vh;
}

.bottom_button {
  position: absolute;
  bottom: 10px;
}

.selection-mode-active {
  background: var(--c-color-accent) !important;
  color: white !important;
}

.VTHViewer {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: none !important;
}
div.inner {
  margin: auto;
  border: 1px solid #000000;
  z-index: -10;
  position: relative;
  max-width: 300px;
  padding: 5px;
  top: -1000px;
  left: -1000px;
  background: rgba(184, 184, 184, 0.8);
  border-radius: 8px;
}
div.outer {
  overflow: hidden;
  position: absolute;
}
</style>
