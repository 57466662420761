<template>
  <BaseModal
    @cancel="$emit('dismiss')"
  >
    <template #title>
      {{ $t('Forbidden') }}
    </template>
    <template #content>
      {{ $t('You do not have access to this resource! Contact your administrator for access! Logout or go back to previous page.') }}
      <br>
      <br>
      {{ $t('You belong to the following groups:') }}
      <ul>
        <li
          v-for="group in userGroups"
          :key="group.groupId"
        >
          {{ group.groupName }}
        </li>
      </ul>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-btn
        text
        rounded
        outlined
        color="primary"
        @click="$emit('logout')"
      >
        {{ $t('Logout') }}
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import BaseModal from './general/BaseModal'
export default {
  name: 'Forbidden',
  components: { BaseModal},
  data () {
    return {}
  },
  computed: {
    ...mapState('app', ['userGroups']),
    ...mapGetters('app', ['applicationPermissions'])
  }
}
</script>
<style scoped>
</style>
