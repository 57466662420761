<template>
  <v-dialog
    v-if="dataUpdateDialog"
    :value="dataUpdateDialog"
    persistent
    max-width="600"
  >
    <BaseModal @cancel="close">
      <template #title>
        {{ $t("Import from spreadsheet") }}
      </template>
      <template #content>
        <v-col
          v-if="loading"
          style="text-align: center; padding: 100px"
        >
          <v-progress-circular
            size="64"
            indeterminate
            color="primary"
          />
        </v-col>
        <v-col
          v-else-if="updatingItems || savingNewContracts"
          style="text-align: center; padding: 100px"
        >
          <div
            class="text-h6"
            style="margin-bottom: 2em"
          >
            {{
              `${
                savingNewContracts
                  ? $t("dataimport.saving_rows")
                  : $t("dataimport.handling_rows")
              }`
            }}
            <p>{{ `${itemsLength}` }}</p>
          </div>
        </v-col>
        <div v-else-if="importSuccess">
          <div v-if="hasChanges || errors">
            <import-changes-view
              :widgets="changesWidgets"
              :items-length="itemsLength"
              :items-not-changed="itemsNotChanged"
              :errors="errors"
              :changes-list="changesList"
            />
          </div>
          <div v-else-if="savingFailed">
            <h3>{{ $t("dataimport.saving_failed") }}</h3>
            <br>
            <h4>
              {{ $t("dataimport.successful_rows") + " " + savedContracts }}
            </h4>
            <h4>
              {{ $t("dataimport.successful_changes") + " " + updatedContracts }}
            </h4>
            <h4>
              {{ $t("dataimport.failed_rows") + " " + savingErrorsCount }}
            </h4>
            <h4>{{ $t("dataimport.failed_updates") + " " + failedUpdates }}</h4>
            <div v-if="overlapErrors.length > 0">
              <br>
              <span>{{ $t("dataimport.overlapping_contracts") }}</span>
              <ul>
                <li
                  v-for="(error, idx) in overlapErrors"
                  :key="idx"
                >
                  {{ viewErrorText(error) }}
                </li>
              </ul>
            </div>
            <div v-if="savingErrors.length > 0">
              <br>
              <span>{{ $t("dataimport.saving_errors") }}</span>
              <ul>
                <li
                  v-for="(error, idx) in savingErrors"
                  :key="idx"
                >
                  {{ viewErrorText(error) }}
                </li>
              </ul>
            </div>
            <div v-if="otherErrors.length > 0">
              <br>
              <span>{{ $t("dataimport.other_errors") }}</span>
              <ul>
                <li
                  v-for="(error, idx) in otherErrors"
                  :key="idx"
                >
                  {{ viewErrorText(error) }}
                </li>
              </ul>
            </div>
          </div>
          <span v-else>{{ $t("dataimport.no_changes") }}</span>
        </div>
        <div v-else>
          <h3>{{ $t("dataimport.import_failed") }}</h3>
          <h4
            v-if="importErrors.uneditable && importErrors.uneditable.length > 0"
          >
            {{
              $t("dataimport.not_editable") +
                ": " +
                importErrors.uneditable.join(", ")
            }}
          </h4>
          <h4
            v-if="importErrors.unexisting && importErrors.unexisting.length > 0"
          >
            {{
              $t("dataimport.not_existing") +
                ": " +
                importErrors.unexisting.join(", ")
            }}
          </h4>
        </div>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-btn
          v-if="!savingFailed"
          rounded
          outlined
          text
          @click="close"
        >
          {{ updatingItems || isSavingChanges ? $t("Abort") : $t("Cancel") }}
        </v-btn>
        <v-btn
          v-if="importSuccess && hasChanges"
          rounded
          depressed
          :disabled="isSavingChanges"
          color="primary"
          @click="save"
        >
          <span v-if="!isSavingChanges">
            {{ $t("Save changes") }}
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-btn>
        <v-btn
          v-else
          rounded
          depressed
          :disabled="updatingItems || isSavingChanges || loading"
          class="primary"
          @click="close"
        >
          <span v-if="!updatingItems && !isSavingChanges && !loading">
            {{ $t("OK") }}
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>

<script>
import BaseModal from "../../general/BaseModal.vue"
import ImportChangesView from "./Views/ImportChangesView"

import UnitChangesDetectedWidget from "./Widgets/UnitChangesDetectedWidget"
import UnitChangesIgnoredWidget from "./Widgets/UnitChangesIgnoredWidget"
import UnitkNewItemsWidget from "./Widgets/UnitNewItemsWidget"

export default {
  name: "ImportReportModal",
  components: {
    BaseModal,
    ImportChangesView,
  },
  event: ["close", "save"],
  props: {
    // Handels the visibility of the modal
    dataUpdateDialog: { type: Boolean, default: false },

    // Activity indicators
    updatingItems: { type: Boolean, default: false },
    savingNewContracts: { type: Boolean, default: false },
    isSavingChanges: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },

    // List lengths
    itemsLength: { type: Number, default: 0 },
    itemsNotChanged: { type: Number, default: 0 },
    hasChanges: { type: Number, default: 0 },

    // Result indicators
    importSuccess: { type: Boolean, default: false },

    errors: { type: Array, default: () => [] },
    importErrors: {
      type: Object,
      default: () => ({ unexisting: [], uneditable: [] }),
    },

    // These are from the saving process. Aka sending data to the API.
    overlapErrors: { type: Array, default: () => [] },
    savingErrors: { type: Array, default: () => [] },
    otherErrors: { type: Array, default: () => [] },

    // Result indicators
    savingFailed: { type: Boolean, default: false },

    // List lengths
    savedContracts: { type: Number, default: 0 },
    updatedContracts: { type: Number, default: 0 },
    failedUpdates: { type: Number, default: 0 },
    savingErrorsCount: { type: Number, default: 0 },

    // Changes list
    changesList: {
      type: Array,
      default: () => [
        { changes: [], text: this.$t("dataimport.list_changes") },
        { changes: [], text: this.$t("dataimport.new_contracts") },
      ],
    },
  },
  data () {
    return {
      changesWidgets: [
        UnitChangesDetectedWidget(),
        UnitChangesIgnoredWidget(),
        UnitkNewItemsWidget(),
      ]
    }
  },
  methods: {
    save () {
      this.$emit("save")
    },
    close () {
      this.$emit("close")
    },
    viewErrorText (error) {
      const { buildingCode, unitName, label, value } = error
      if (!buildingCode) {
        return label
      }
      return `${buildingCode} ${unitName} - ${label}: ${value}`
    },
  },
}
</script>

<style></style>
