<template>
  <div>
    <v-tab-item
      :key="9"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <h3 class="form-headers">
          {{ $t('create_rent_contract.attachments') }}
        </h3>
        <v-expansion-panels
          v-model="panels"
          class="my-2"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('Documents') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <table
                v-if="attachments.length > 0 || !!signedContractPdf"
                class="v-data-table theme--light"
                :style="{ width: '100%'}"
              >
                <thead>
                  <th class="column">
                    {{ $t('Attachment_name') }}
                  </th>
                  <th class="column">
                    {{ $t('Type') }}
                  </th>
                  <th class="column">
                    {{ $t('File for contract') }}
                  </th>
                  <th class="column">
                    {{ $t('Attachment exportdate') }}
                  </th>
                  <th
                    class="column"
                    width="17%"
                  >
                    {{ $t('create_rent_contract.actions') }}
                  </th>
                </thead>
                <tbody>
                  <tr 
                    v-for="(item, index) in attachments"
                    :key="index"
                  >
                    <td>
                      {{ item.title }}
                    </td>
                    <td>
                      {{ item.typeAttachment }}
                    </td>
                    <td>
                      {{ item.isForContract === true ? $t('Yes') : $t('No') }}
                    </td>
                    <td>
                      {{ item.uploaded ? formatTimestamp(item.uploaded) : undefined }}
                    </td>
                    <td>
                      <v-btn
                        v-if="isUpdatingAllowed || (!isUpdatingAllowed && tenantType == 'company')"
                        icon
                        x-small
                        class="action-button"
                        @click="downloadDocument(index)"
                      >
                        <v-icon>download</v-icon>
                        <span class="d-sr-only">{{ $t('Download') }}</span>
                      </v-btn>
                      <v-btn
                        v-if="isUpdatingAllowed"
                        icon
                        x-small
                        class="action-button"
                        @click="editDocument(index)"
                      >
                        <v-icon>edit</v-icon>
                        <span class="d-sr-only">{{ `${$t('Modify')}` }}</span>
                      </v-btn>
                      <v-btn
                        v-if="isUpdatingAllowed"
                        icon
                        x-small
                        class="action-button"
                        @click="removeDocument(index)"
                      >
                        <v-icon>delete</v-icon>
                        <span class="d-sr-only">{{ `${$t('Delete')}` }}</span>
                      </v-btn>
                      <v-btn
                        v-if="isUpdatingAllowed && attachments.length > 1"
                        icon
                        x-small
                        class="action-button"
                        @click="moveDocument(index, 'up')"
                      >
                        <v-icon>keyboard_arrow_up</v-icon>
                        <span class="d-sr-only">{{ `${$t('Move up')}` }}</span>
                      </v-btn>
                      <v-btn
                        v-if="isUpdatingAllowed && attachments.length > 1"
                        icon
                        x-small
                        class="action-button"
                        @click="moveDocument(index, 'down')"
                      >
                        <v-icon>keyboard_arrow_down</v-icon>
                        <span class="d-sr-only">{{ `${$t('Move down')}` }}</span>
                      </v-btn>
                    </td>
                  </tr>
                  <tr 
                    v-if="!!signedContractPdf"
                  >
                    <td>
                      {{ $t("Signed contract") + " " + contractNumber }}
                    </td>
                    <td>
                      {{ signedContractPdf.typeAttachment }}
                    </td>
                    <td>
                      {{ $t('Yes') }}
                    </td>
                    <td>
                      {{ signedContractPdf.uploaded ? formatTimestamp(signedContractPdf.uploaded) : undefined }}
                    </td>
                    <td>
                      <v-btn
                        v-if="isUpdatingAllowed || (!isUpdatingAllowed && tenantType == 'company')"
                        icon
                        x-small
                        class="action-button"
                        @click="downloadDocument(null, signedContractPdf.idDocument)"
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="attachments.length < 1 && !signedContractPdf"
                class="d-flex justify-center align-center mt-4"
              >
                <v-icon class="mr-1">
                  info
                </v-icon>
                {{ $t('create_rent_contract.no_documents') }}
              </div>
              <div class="text-right mt-3 mr-3">
                <v-btn
                  v-if="isUpdatingAllowed"
                  class="blue-button"
                  rounded
                  depressed
                  @click="openAddAttachmentModal()"
                >
                  {{ $t('Add attachment') }}
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-dialog
        v-model="showAddAttachmentModal"
        persistent
        eager
        width="65%"
      >
        <RentContractAddAttachmentModal
          v-if="showAddAttachmentModal"
          :is-editing="isEditing"
          :editing-data="editingData"
          @close="closeAddAttachmentsModal($event, 'add')"
          @edit="closeAddAttachmentsModal($event, 'edit')"
        />
      </v-dialog>
    </v-tab-item>
  </div>
</template>

<script>
import helpers from '../../../../helpers'
import RentContractAddAttachmentModal from './RentContractAddAttachmentModal.vue'
export default {
  name: "RentContractAttachmentsTab",
  components: {
    RentContractAddAttachmentModal
  },
  props: {
    attachments: {
      type: Array,
      default: undefined
    },
    signedContractPdf: {
      type: Object,
      default: undefined
    },
    contractNumber: {
      type: String,
      default: undefined
    },
    isUpdatingAllowed: {
      type: Boolean,
      default: false
    },
    tenantType: {
        type: String,
        default: undefined
    }
  },
  data () {
    return (
      {
        panels: [0, 2],
        showAddAttachmentModal: false,
        isEditing: false,
        isRetrievingFile: false,
        retrievalProgress: 0,
        editingData: {}
      }
    )
  },
  methods: {
    openAddAttachmentModal () {
      this.showAddAttachmentModal = true
    },
    closeAddAttachmentsModal (data, action) {
      this.showAddAttachmentModal = false
      this.isEditing = false
      const modifiedAttachments = [...this.attachments] 

      if (data && action == 'add') {
        const newAttachment = {
          file: data.file,
          title: data.attachmentName,
          type: data.type,
          typeAttachment: data.type,
          isForContract: data.attachmentToContract,
          isNewAttachment: true
        }
        modifiedAttachments.push(newAttachment)
      }
      else if (data && action == 'edit') {
        modifiedAttachments[data.index] = {
          title: data.attachmentName,
          file: data.file,
          type: data.type,
          typeAttachment: data.type,
          isForContract: data.attachmentToContract,
          idContract: data.idContract,
          idDocument: data.idDocument,
          isNewAttachment: false
        }
      }
      this.$emit('updated', modifiedAttachments)
    },
    downloadDocument (index, documentId = null) {
      const id = documentId ?? this.attachments[index].idDocument
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      const self = this
      this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          self.isRetrievingFile = false
          self.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const filename = res.headers["content-disposition"].match(/filename\s*=\s*(.+);/i)[1].replaceAll('"', '');
          helpers.saveAs(blob, filename)
        })
        .catch(() => {
          this.isRetrievingFile = false
          // this.showFileInfo('Dokumenttia ei pystytä lataamaan', 'error')
        })
    },
    removeDocument (index) {
      const modifiedAttachments = JSON.parse(JSON.stringify(this.attachments));
      modifiedAttachments.splice(index, 1)
      this.$emit('updated', modifiedAttachments)
    },
    editDocument (index) {
      this.showAddAttachmentModal = true
      this.isEditing = true
      this.editingData = this.attachments[index]
      this.editingData.index = index
    },
    moveDocument (index, direction) {
      const modifiedAttachments = JSON.parse(JSON.stringify(this.attachments));
      const document = modifiedAttachments[index]

      if ((index == 0 && direction == 'up') || (index == modifiedAttachments.length - 1 && direction == 'down')) {
        return
      }
      else if (direction == 'up') {
        const oldDocument = modifiedAttachments[index - 1]
        modifiedAttachments.splice(index, 1, oldDocument)
        modifiedAttachments.splice(index - 1, 1, document)
      }
      else if (direction == 'down') {
        const oldDocument = modifiedAttachments[index + 1]
        modifiedAttachments.splice(index, 1, oldDocument)
        modifiedAttachments.splice(index + 1, 1, document)
      }

      this.$emit('updated', modifiedAttachments)
    },
    formatTimestamp (timestamp) {
      if (timestamp === null){return}
      const date = new Date(timestamp);
      return date.toLocaleDateString('fi-FI');
    },
  }
}
</script>

<style>
.action-button {
  padding: 0em;
}
</style>