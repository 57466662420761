<template>
  <BaseModal
    @cancel="cancel()"
  >
    <template #title>
      {{ $t('internalrent.select_portfolio') }}
    </template>
    <template #content>
      <v-col>
        <v-text-field
          v-model="searchValue"
          color="primary"
          single-line
          hide-details
          clearable
          append-icon="search"
          :placeholder="$t('Search...')"
        />
      </v-col>
      <br>
      <v-divider />
      <br>
      <h4 class="text--secondary">
        {{ $t('Status portfolios selected') }}
      </h4>
      <br>
      <v-row
        v-if="multipleSelect"
      >
        <v-col
          v-for="statusPortfolio in selectedStatusPortfolios"
          :key="statusPortfolio.id"
        >
          <v-btn
            class="selected ma-0"
            block
            outlined
            rounded
            @click="setStatusPortfolioSelection(statusPortfolio.id)"
          >
            {{ statusPortfolio.name }}
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <h4 class="text--secondary">
        {{ $t('Status portfolios') }}
      </h4>
      <br>
      <v-row>
        <v-col
          v-for="filteredStatusPortfolio in filteredStatusPortfolios"
          :key="filteredStatusPortfolio.id"
        >
          <v-btn
            :class="
              statusPortfolioSelections[filteredStatusPortfolio.id] === true
                ? 'selected'
                : ''
            "
            class="ma-0"
            block
            outlined
            rounded
            @click="setStatusPortfolioSelection(filteredStatusPortfolio.id)"
          >
            {{ filteredStatusPortfolio.name }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-row
        v-if="multipleSelect"
        justify="end"
      >
        <v-btn
          :disabled="selectedPortfoliosLength === portfolios.length"
          color="secondary"
          text
          rounded
          outlined
          @click="selectAllStatusPortfolios()"
        >
          {{ $t('Choose all') }}
        </v-btn>
        <v-btn
          :disabled="!selectedPortfoliosLength"
          color="secondary"
          text
          rounded
          outlined
          @click="deSelectAllStatusPortfolios()"
        >
          {{ $t('Clear') }}
        </v-btn>
      
        <v-btn
          :disabled="!selectedPortfoliosLength"
          color="primary"
          text
          rounded
          outlined
          @click="selectStatusPortfolios()"
        >
          {{ $t('Select') }} ({{ selectedPortfoliosLength }})
        </v-btn>
      </v-row>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '../general/BaseModal'
export default {
  name: 'StatusPortfolioSelection',
  components: {
    BaseModal,
  },
  props: {
    portfolios: {
      type: Array,
      default: () => []
    },
    multipleSelect: {
        type: Boolean,
        default: true
    },
    selectedPortfolios: {
        type: Array,
        default: () => []
    }
  },
  data () {
      return {
          statusPortfolioSelections: {},
          selectedPortfoliosLength: 0,
          searchValue: '',
          filteredStatusPortfolios: [],
          sortedStatusPortfolios: [],
          selectedStatusPortfolios: []
      }
  },
  computed: {
    
  },
  watch: {
    searchValue: function () {
      this.filterStatusPortfoliosBySearchValue()
    },
    statusPortfolioSelections: {
      handler: function () {
        this.getSelectedStatusPortfolios()
      },
      deep: true
    }
  },
  mounted () {
    this.statusPortfolioSelections = {}
    this.sortedStatusPortfolios = this.portfolios.sort((a,b) => {
      return a.name.toLowerCase() - b.name.toLowerCase()
    })
    this.filteredStatusPortfolios = this.sortedStatusPortfolios
    this.selectedPortfolios.forEach(portfolioId => { this.statusPortfolioSelections[portfolioId] = true })
    this.selectedPortfoliosLength = this.selectedPortfolios.length
  },
  methods: {
    setStatusPortfolioSelection (statusPortfolioId) {
      if(this.multipleSelect) {
        this.statusPortfolioSelections[statusPortfolioId] = !this.statusPortfolioSelections[statusPortfolioId]
        this.getSelectedStatusPortfolios()
        this.selectedPortfoliosAmount()
      } else {
        this.statusPortfolioSelections = {}
        this.statusPortfolioSelections[statusPortfolioId] = true 
        this.selectStatusPortfolios()
      }
    },
    selectAllStatusPortfolios () {
      this.statusPortfolioSelections = {}
      this.sortedStatusPortfolios.forEach(portfolio => { this.statusPortfolioSelections[portfolio.id] = true })
      this.selectedPortfoliosAmount()
    },
    deSelectAllStatusPortfolios () {
      this.statusPortfolioSelections = {}
      this.selectedPortfoliosAmount()
    },
    selectStatusPortfolios () {
      const selectedPortfolios = Object.keys(this.statusPortfolioSelections)
        .filter(portfolioId => this.statusPortfolioSelections[portfolioId] === true)
        .map(portfolioId => Number(portfolioId))

      this.$emit('updatePortfolioSelections', selectedPortfolios)
    },
    selectedPortfoliosAmount () {
      const selectedPortfolios = Object.keys(this.statusPortfolioSelections).filter(portfolioId => this.statusPortfolioSelections[portfolioId] === true)
      this.selectedPortfoliosLength = selectedPortfolios.length
    },
    filterStatusPortfoliosBySearchValue () {
      this.filteredStatusPortfolios = []
      
      let sourceSet = this.sortedStatusPortfolios
      if(this.searchValue) {
        sourceSet = this.sortedStatusPortfolios.filter(portfolio => {
        return portfolio.name.toLowerCase().includes(this.searchValue.toLowerCase())     
        })
      }
      this.filteredStatusPortfolios = sourceSet
    },
    getSelectedStatusPortfolios () {
      this.selectedStatusPortfolios = this.sortedStatusPortfolios
        .filter(portfolio => this.statusPortfolioSelections[portfolio.id] === true)
    },
    cancel () {
      this.$emit('cancel')
    },
  },
  
}
</script>
<style scoped>
.v-btn.selected {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>
