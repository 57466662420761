<template>
  <v-row
    align="center"
    no-gutters
  >
    <v-col
      cols="8"
      :class="settings.hasMFilesIntegration? 'linked px-0' : 'px-0'"
      @click="settings.hasMFilesIntegration ? additionalAction(props.item) : null"
    >
      {{ formatData(props.item, header.value, header.format) }}
    </v-col>
    <v-col
      v-if="props.item.key_account_customer"
      cols="4"
      class="px-0 text-left"
    >
      <v-icon color="warning">
        grade
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../../../helpers'
import { mapActions, mapState, mapGetters } from 'vuex'

function findByContractNumber (contractNumber, corporations) {
  const corporation = corporations.find((corpo) => {
    if (corpo.corporation_contract_numbers) {
      const hasContract =
        corpo.corporation_contract_numbers.includes(contractNumber)
      if (hasContract) {
        return true
      }
    }

    return false
  })

  if (corporation) {
    return corporation
  }

  return null
}

function findByBusinessNumber (businessId, corporations) {
  const corporation = corporations.find((corpo) => {
    if (corpo.corporations_business_id) {
      const hasContract = corpo.corporations_business_id.includes(businessId)
      if (hasContract) {
        return true
      }
    }

    return false
  })

  if (corporation) {
    return corporation
  }

  return null
}

export default {
  name: 'TenantColumn',
  props: {
    header: { type: Object, default: null },
    props: { type: Object, default: null },
  },
  computed: {
    ...mapState('leasing', ['corporations']),
    ...mapGetters('app', ['settings']),
  },
  methods: {
    ...mapActions('leasing', ['getCorporationExtraById']),
    formatData (row, header, format, isLink = false) {
      const value = isLink
        ? helpers.format.escapeHtml(row[header])
        : row[header]
      return helpers.format.formatData(value, format)
    },

    additionalAction (item) {
      let id = item.id_tenant ?? item.id_corporation
      // If column is empty, don't try to open at all
      if (id == null) {
        return
      }
      // If we have corporations loaded, let's show the corresponding corporation instead. This only in case  of
      // tenants which are not tenant corporations. If we cannot find any tenant coroporations, we will show the
      // tenant information.
      if (!item.tenant_corporation && this.corporations) {
        // We have two ways of finding correct corporation. Via contract id or via business id. Let's use the business id
        // as priority and if it was not found, let's try via contract id. If that also fails, let's just show current tenant.

        // Try to find a corpo
        let corpo = null

        if (!corpo && item.business_id) {
          corpo = findByBusinessNumber(item.business_id, this.corporations)
        }

        if (!corpo && item.contractNumber) {
          corpo = findByContractNumber(item.contractNumber, this.corporations)
        }

        // If corpo was found, let's use it's id
        if (corpo) {
          id = corpo.id
        }
      }

      // Should check here where we may find the correct tenant. In this case it's the "vuokralaiskonserni"
      this.getCorporationExtraById({ id })
    },
  },
}
</script>

<style scoped>
</style>
