<template>
  <v-menu
    v-if="hasApplicationPermissionByName('LEASING') || hasApplicationPermissionByName('KOHTEIDEN_LISAYS') || hasApplicationPermissionByName('HALYTYKSET')"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :icon="!wideScreen"
        depressed
        class="primary"
        rounded
        v-bind="attrs"
        v-on="on"
      >
        <v-icon 
          :left="wideScreen"
        >
          add
        </v-icon>
        <span :class="!wideScreen ? 'd-sr-only' : ''">{{ $t('Add') }}</span>
      </v-btn>
    </template>
    <v-list
      nav
      dense
    >
      <v-list-item
        v-if="hasApplicationPermissionByName('LEASING')"
        @click="$emit('open', 'add_rental')"
      >
        <v-list-item-title>{{ $t('leasing.new_rental') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="hasApplicationPermissionByName('KOHTEIDEN_LISAYS')"
        @click="() => $emit('open', 'add_site')"
      >
        <v-list-item-title>{{ $t('site.new_site') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="hasApplicationPermissionByName('HALYTYKSET')"
        @click="() => $emit('open', 'add_alert')"
      >
        <v-list-item-title>{{ $t('alert.add_alert') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default
{
  emits: ['open'],
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    wideScreen () {
      return this.$vuetify.breakpoint.lgAndUp
    },
  }
}
</script>

<style scoped>
</style>
