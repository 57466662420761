export default {
  async calculatePaymentSum (contractPayments, rentPaymentDomains, selectedUnits, api) {
    const calculatedPayments = []
    for (const payment of contractPayments) {
      if (payment.squareBased !== null && payment.squareBased) {
        if (selectedUnits.length > 0){
          const unitAreas = selectedUnits
            .filter(unit => payment.wholeContractPayment || payment.targetUnits?.some(targetUnit => targetUnit === unit.id) || payment.targetStructures?.some(targetStructure => targetStructure === unit.structureId))
            .map(unit => unit.agreedArea !== null ? unit.agreedArea : unit.area ?? 0)
          if (typeof rentPaymentDomains !== 'undefined') {
            let paymentDomain = rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
            const sums = await api.rentpayments.payments.calculateSums(
            paymentDomain[0], payment.rent, unitAreas, payment.squareBased)
            payment.netSum = sums.netSum
          }
          if (payment.pcsCount > 0) {
            let paymentDomain = rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
            const sums = await api.rentpayments.payments.calculateSumsPcs(paymentDomain[0], payment.rent, payment.pcsCount, payment.squareBased)
            payment.netSum = sums.netSum
          }
        }
      } else {
        payment.netSum = Number(payment.rent)
      }
      calculatedPayments.push(payment)
    }

    return calculatedPayments
  }
}
  