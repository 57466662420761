<template>
  <v-row
    class="dashboard"
  >
    <v-dialog
      v-if="renegotiations && hasApplicationPermissionByName('LEASING')"
      :value="renegotiations != null"
      persistent
      width="550"
    >
      <v-card>
        <renegotiations-modal-process
          :contract="renegotiations"
          :is-shown="renegotiations !== null"
          :user-info="userInfo"
        />
      </v-card>
    </v-dialog>
    <v-col
      v-for="widget in widgets"
      :key="widget.id"
      :lg="widget.small ? 3 : 6"
      md="12"
      sm="12"
      cols="12"
    >
      <DynamicWidget
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :plugins="widget.plugins"
        :loading="widgetLoading(widget.id)"
        :default-item-set="selectedItemSet"
        @change-item-set="(e) => changeWidgetSet(e, widget.id)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import DynamicWidget from '../../DynamicWidget'
import LeasingWidgets from '../../../widgets/leasing'
import moment from 'moment'
import _debounce from 'lodash/debounce'
import { endingContractsGraph, isInQuarterSelection } from "../../../helpers/leasing/contracts"
import { rentableAreaUsageRate } from "../../../helpers/leasing/rentableAreaUsageRate"
import { renewalStatusColors, rentalProcessStageColors, stageColors } from "../../../constants/colors"
import RenegotiationsModalProcess from '../RenegotiationsModalProcess.vue'
import { transformToProspect } from "../../../helpers/leasing/rentalprocess"

export default {
  name: 'RenegotiationsAndRenewals',
  components: { 
    DynamicWidget,
    RenegotiationsModalProcess
  },
  props: {
    userSites: { type: Array, default: () => []},
    lowerBoundQuarterDates: { type: Array, default: () => []},
    upperBoundQuarterDates: { type: Array, default: () => []}
  },
  data () {
    return {
      widgets: [...LeasingWidgets.RenegotiationsAndRenewals()],
      selectedItemSet: null,
      largestEndingContractsSet: this.$t('prospect.show_all'),

    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionById', 'hasApplicationPermissionByName']),
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'userInfo',
      'currentDate',
      'buildings'
    ]),
    ...mapState('leasing', [
      'prospectsLoading',
      'prospects',
      'renegotiations',
      'inspectionRentalStatuses',
      'inspectionRentalStatusesLoading',
      'ignoredProspects',
      'ignoredProspectsLoading',
      'rentalStatuses',
      'futureRentalStatuses',
      'pastRentalStatuses',
      'futureUsages',
      'currentRentalStatusesLoading',
      'futureRentalStatusesLoading',
      'pastRentalStatusesLoading',
      'futureUsagesLoading',
    ]),
    ...mapState('rentalProcess', ['rentalProcesses', 'rentalProcessLoading']),
    rentalDefs () {
      return this.definitionsByGroupLabel('unit.notrentable')
    },
    negotiationDefs () {
      return this.definitionsByGroupLabel('contract.negotiation_status')
    },

  },
  watch: {
    userSites: function () {
      this.loadWidgetData()
    },
    prospects: function () {
      this.loadWidgetData()
    },
    rentalProcesses: function () {
      this.loadWidgetData()
    },
    ignoredProspects: function () {
      this.loadWidgetData()
    },
    inspectionRentalStatuses: {
      deep: true,
      handler: function () {
        this.loadWidgetData()
      }
    },
    futureUsages: function () {
      this.loadWidgetData()
    },
    // If inspection year or quarter changes we call debounced function for getting rental-statuses
    lowerBoundQuarterDates: function () {
      this.getEndingContractsDebounce(this)
    },
    rentalStatuses: function () {
      this.loadWidgetData()
    },
    selectedItemSet: function () {
      const widget = this.widgets.find(x => x.id === 'leasing.ending_contracts_based_on_status')
      if(widget) {
        widget.data.items = this.dataForWidget(widget.id)
      }
    },
    largestEndingContractsSet: function () {
      const widget = this.widgets.find(x => x.id === "leasing.largest_ending_contracts")

      if(widget){
        widget.data.items = this.dataForWidget(widget.id)
      }
    }
  }, 
  mounted () {
    const startOfYear = new Date(moment().startOf('year'))
    this.setDefaultItemSet('leasing.ending_contracts_based_on_status')
    this.getInspectionRentalStatuses(startOfYear)
    this.loadWidgetData()
  },
  methods: {
    ...mapActions('leasing', ['getInspectionRentalStatuses']),
    loadWidgetData () {
      this.widgets.forEach(widget => {
        widget.data.items = this.dataForWidget(widget.id)
        
        if (widget.data.lineItems) {
          widget.data.lineItems = this.extraDataForWidget(widget.id)
        }
        
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }

      })
    },
    legendForWidget (id) {
      try {
        switch(id) {
          case 'leasing.largest_ending_contracts':
            return this.loadLargestEndingContractsLegend()
          default:
            return null
        }
      }
      catch (err) {
        this.$log.error(`Error trying to process filters for widget: "${  id  }"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    loadLargestEndingContractsLegend (){
      return Object.entries({...stageColors, ...renewalStatusColors}).map(([key, value]) => 
        ({
            text: this.$t(key),
            fillStyle: value,
            hidden: false,
            lineWidth: 1,
            strokeStyle: '#fff',
        })
      )
    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.ending_contracts_fixed_term':
          return this.sitesLoading || this.currentRentalStatusesLoading || this.futureRentalStatusesLoading || this.pastRentalStatusesLoading || this.prospectsLoading || this.ignoredProspectsLoading || this.rentalProcessLoading
        case 'leasing.ending_contracts_permanent':
          return this.sitesLoading || this.currentRentalStatusesLoading || this.futureRentalStatusesLoading || this.pastRentalStatusesLoading || this.prospectsLoading || this.ignoredProspectsLoading || this.rentalProcessLoading
        case 'site.leasing.ending_contracts_graph':
          return this.sitesLoading || this.prospectsLoading || this.currentRentalStatusesLoading || this.futureRentalStatusesLoading || this.pastRentalStatusesLoading || this.futureUsagesLoading
        case 'leasing.largest_ending_contracts':
        case 'leasing.ending_contracts_based_on_status':
          return this.sitesLoading || this.inspectionRentalStatusesLoading || this.prospectsLoading || this.ignoredProspectsLoading || this.rentalProcessLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }

        const rentalProcesses = this.rentalProcesses.map(transformToProspect)

        const allProspects = [
          ...this.prospects, 
          ...rentalProcesses
        ]
        const allRentalStatuses = [
          ...this.rentalStatuses, 
          ...this.futureRentalStatuses, 
          ...this.pastRentalStatuses
        ]
        switch(id) {
          case 'leasing.ending_contracts_fixed_term':
            return this.contractData(allRentalStatuses, allProspects, this.ignoredProspects, this.userSites, false)
          case 'leasing.ending_contracts_permanent':
            return this.contractData(allRentalStatuses, allProspects, this.ignoredProspects, this.userSites, true,)
          case 'site.leasing.ending_contracts_graph':
            return endingContractsGraph(this.rentalStatuses, this.futureRentalStatuses, this.pastRentalStatuses, this.rentalProcesses, this.currentDate, this.userSites, this.negotiationDefs)
          case 'leasing.ending_contracts_based_on_status':
            return this.endingContractPieGraph(this.inspectionRentalStatuses, allProspects, this.ignoredProspects, this.userSites)
          case 'leasing.largest_ending_contracts':
            return this.largestEndingContracts(this.inspectionRentalStatuses, allProspects, this.userSites, this.largestEndingContractsSet) 
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error(`Error trying to process data for widget: "${  id  }"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
      }
    },
    contractData (rentalStatuses, prospects, ignoredProspects, sites, permanent = false) {
      const renegotiationsProspects = prospects.concat(ignoredProspects).filter(prospect => prospect.renegotiations)

      const rows = []
      
      let filteredRentalStatuses = []

      if (permanent) {
        filteredRentalStatuses = rentalStatuses.filter(status => status.validity === 'toistaiseksi voimassaoleva')
      }
      else {
        filteredRentalStatuses = rentalStatuses.filter(status => status.validity === 'määräaikainen')
      }

      // Filter out dates based on quarter and year choice
      filteredRentalStatuses = this.filterDates(filteredRentalStatuses, permanent)
      
      // Filter out based on sites
      const siteIds = sites.map(site => site.id_site)
      filteredRentalStatuses = filteredRentalStatuses.filter(rs => siteIds.includes(rs.siteId))

      filteredRentalStatuses.forEach(rs => {

        if (rs.contractNumber) {
          const index = rows.findIndex(row => row.contractNumber === rs.contractNumber)

          const negotiationStatus = this.definitionById(rs.defNegotiationStatus)

          if (index >= 0) {          
            rows[index].totalArea += rs.area
            rows[index].units.push({id: rs.unitId, unit_code: rs.unitName})
            rows[index].contractTotalMarketRent += this.calculateErv(rs)
          } 
          else {
            const newRow = {
              tenant: rs.curPartyName, 
              siteName: rs.siteName,
              site_identifier: rs.site_identifier,
              totalArea: rs.area, 
              endDate: rs.curPartyEndDate,
              contractNumber: rs.contractNumber,
              id_site: rs.siteId,
              units: [{id: rs.unitId, unit_code: rs.unitName}],
              id_tenant: rs.curPartyId,
              unitUsage: rs.unitUsage,
              tenant_corporation: rs.tenant_corporation,
              no_renegotiations: rs.no_renegotiations,
              contractId: rs.contractId,
              validity: rs.validity,
              contractFirstPossibleEndDate: rs.contractFirstPossibleEndDate,
              contractTotalMarketRent: this.calculateErv(rs),
              // Needed for patching rental status
              unitId: rs.unitId,
              curUnitPartyId: rs.curUnitPartyId
            }


            if (rs.id_prospect_renegotiations) {
              const foundProspect = renegotiationsProspects.find(item => item.id_prospect === rs.id_prospect_renegotiations)
  
              if (foundProspect) {
                // Tells us if linked object is rental process.
                // This is how we can open the correct modal on user click.
                newRow.id_process = foundProspect.id_process 
                newRow.id_prospect = foundProspect.id_prospect
                newRow.prospect_description = foundProspect.prospect_description              

                if (permanent) {
                  newRow.renewal = foundProspect.prospect_description
                }
                else {
                  newRow.renegotiations = foundProspect.prospect_description
                }
                if (foundProspect.status === 'Inactive') {
                  newRow.prospect_status = foundProspect.status
                } else {
                  newRow.prospect_status = foundProspect.stage ?? foundProspect.status
                }
              }
              else {
                newRow.prospect_status = 'ProspectPending'
              }
            }
            else if (negotiationStatus) {
              newRow.prospect_status = negotiationStatus.label
            }
            else {
              newRow.prospect_status = 'ProspectPending'
            }
            newRow.totalArea = rs.area
            newRow.prospect_fullscreen_status = this.$t(newRow.prospect_status)
            rows.push(newRow)
          }
        }
      })

      if (permanent) {
        rows.sort((a,b) => new Date(a.contractFirstPossibleEndDate) - new Date(b.contractFirstPossibleEndDate))
      }
      else {
        rows.sort((a,b) => new Date(a.endDate) - new Date(b.endDate))
      }

      return rows
    },
    getEndingContractsDebounce: _debounce((that) => {
      
      // If only year is selected, select start of the year
      // If quarters are selected, select the earliest quarters start date
      // If period is selected, select the start date of period
      let time = new Date(moment().startOf('year'))

      if(that.lowerBoundQuarterDates.length > 0){
        time = that.lowerBoundQuarterDates[0]
      }

      that.getInspectionRentalStatuses(time)
      
    }, 1000),
    /**
     * Checks if given value is in the range of time selection. 
     * @param {Date} value - Date compatible value, which is checked against this.lowerBoundQuarterDates and this.upperBoundQuarterDates
     * @return true if value is in range, false otherwise
     */
    isInTimeRange (value){
      return isInQuarterSelection(value, this.lowerBoundQuarterDates, this.upperBoundQuarterDates)
    },
    filterDates (rentalStatuses, permanent = false) {
      // If lower bound quarters exists, filter by quarters
      // Changed this function to use new compare function 1.9.2022
      return rentalStatuses.filter(rs => {
        if(permanent){
          if(rs.curPartyEndDate){
            return this.isInTimeRange(rs.curPartyEndDate)
          }
          
          return this.isInTimeRange(rs.contractFirstPossibleEndDate)
          
        }
        
        if(rs.curPartyEndDate){
          return this.isInTimeRange(rs.curPartyEndDate)
        }

        return false
      })
    },
    calculateErv (rentalStatus){
      const { area = 0, market_rent: marketRent = 0 } = rentalStatus

      return area * marketRent
    },
    extraDataForWidget (id) {
      switch(id) {
        case 'site.leasing.ending_contracts_graph':
          return rentableAreaUsageRate(this.futureUsages, this.userSites)
        default:
          return []
      }
    },
    endingContractPieGraph (rentalStatuses, prospects, ignoredProspects, sites) {
      const renegotiationsProspects = prospects.concat(ignoredProspects).filter(prospect => prospect.renegotiations)

      // Filter out based on sites
      const siteIds = sites.map(site => site.id_site)

      const filteredRentalStatuses = rentalStatuses.filter(rs => siteIds.includes(rs.siteId))
      
      // Filter out dates based on quarter and year choice
      const RentalStatusesForPermanent = this.filterDates(filteredRentalStatuses, true)
      const RentalStatusesForFixedTerm = this.filterDates(filteredRentalStatuses, false)
      
      const dividedRentalStatusesPermanent = this.divideAndSumRentalStatusesByType(RentalStatusesForPermanent, renegotiationsProspects)
      const dividedRentalStatusesFixedTerm = this.divideAndSumRentalStatusesByType(RentalStatusesForFixedTerm, renegotiationsProspects)

      const pieObjects = [
        { key: 'NotRelevant' }, 
        { key: 'ProspectPending' }, 
        { key: '1. Lead' },
        { key: '2. Prospect'},
        { key: '3. NeedsAssessment' },
        { key: '4. Proposal' },
        { key: '5. ContractNegotiation' },
        { key: '6. Won' },
        { key: '7. RentAdministration' }, 
        { key: '8. Closed' },
        { key: 'Inactive' }, 
        ]
    
      pieObjects.forEach(o => {
        const text = this.$t(o.key)
        o.nameAll = text
        o.nameFixedTerm = text
        o.namePermanent = text

        const values = {
          permanent: this.getRentalStatusTotalArea(dividedRentalStatusesPermanent[o.key], 'toistaiseksi voimassaoleva'),
          fixed_term: this.getRentalStatusTotalArea(dividedRentalStatusesFixedTerm[o.key], 'määräaikainen') 
        }
        values.all = values.fixed_term + values.permanent

        Object.assign(o, values)
      });
      return pieObjects
    },
    largestEndingContracts (rentalStatuses, prospects, sites, set) {
      
      // Filter out based on sites
      const siteIds = sites.map(site => site.id_site)

      const filteredRentalStatuses = rentalStatuses.filter(rs => siteIds.includes(rs.siteId))
      
      // Now let's filter by selected set:
      const validityFiltered = filteredRentalStatuses.filter( (rs) => {
        
        const { validity = "" } = rs
        
        if(!validity){
          return false
        }

        switch(set){
          case this.$t('prospect.show_all'):
            return true
          case this.$t('Fixed term'): 
            return validity.toLowerCase() === "määräaikainen"
          case this.$t('Permanent'):
          case this.$t('Permanent (termination date)'):
            return validity.toLowerCase() === "toistaiseksi voimassaoleva"
          default:
            return false
        }
      })

      // We use different date value depending on the set and contract type. 

      // Filter out dates based on quarter and year choice. All these contracts has either end date or first end date in time selection.
      const timeFiltered = validityFiltered.filter( rs => {
        
        const { 
          curPartyEndDate, 
          contractFirstPossibleEndDate, 
          termination_date: terminationDate,
          validity
        } = rs

        if(!validity){
          return false
        }

        // Case until further notice by termination date
        if(set === this.$t('Permanent (termination date)')){
          return this.isInTimeRange(terminationDate)
        }

        // Case until further notice without termination date
        if(set === this.$t('Permanent')){
          return this.isInTimeRange(curPartyEndDate) || this.isInTimeRange(contractFirstPossibleEndDate)
        }

        // Case Until further notice in all set.
        if(validity.toLowerCase() === "toistaiseksi voimassaoleva"){
          return this.isInTimeRange(terminationDate) || this.isInTimeRange(curPartyEndDate) || this.isInTimeRange(contractFirstPossibleEndDate)
        }
        
        // Case fixed term contracts && all set
        if(validity.toLowerCase() === "määräaikainen"){
          return this.isInTimeRange(curPartyEndDate)
        }
      })

      // Group by id so we don't have to do linear search for every rental status. 
      const prospectsById = prospects.reduce((acc, cur) => {
        acc[cur.id_prospect] = {...cur}
        return acc
      }, {})

      // Let's sum the values under contract
      const data = timeFiltered.reduce((acc, cur) => {
        
        const { 
          id_prospect_renegotiations: prospectId, 
          curPartyName, 
          siteName, 
          contractNumber, 
          area = 0, 
          market_rent: marketRent = 0,
          defNegotiationStatus
        } = cur

        const negotiationStatus = this.definitionById(defNegotiationStatus)

        const prospect = prospectsById[prospectId]

        const label = `${curPartyName} (${contractNumber})\n ${siteName}`

        const totalMarketRent = area * marketRent

        let color = renewalStatusColors.ProspectPending

        if (prospect){
          color = rentalProcessStageColors[prospect.status === 'Inactive' ? prospect.status : prospect.stage]
        }
        else if(negotiationStatus?.label === "Ei ajankohtainen"){
          color = renewalStatusColors.NotRelevant
        }

        if(acc[label]){
          acc[label].area += area
          acc[label].marketRent += totalMarketRent
        }
        else{
          acc[label] = {
            label,
            area,
            marketRent: totalMarketRent,
            color
          }
        }

        return acc
      }, {})

      return Object.values(data)
    },
    divideAndSumRentalStatusesByType (rentalStatuses, prospects) {
      const dividedRentalStatusTotals = {}

      for (const rs of rentalStatuses) {
        let found = false
        for (const drs of Object.values(dividedRentalStatusTotals)) {
          const existingTotalValue = drs.find(x => x.contractNumber === rs.contractNumber)
          if(existingTotalValue)
          {
            existingTotalValue.area += rs.area
            existingTotalValue.rent += rs.unitMarketRent
            found = true
          }
        }

        const negotiationStatus = this.definitionById(rs.defNegotiationStatus)

        if(!found) {
          let RentalStatusType
          if (rs.id_prospect_renegotiations != null) {
            const foundProspect = prospects.find(item => item.id_prospect === rs.id_prospect_renegotiations)  
            if (foundProspect) {
              if (foundProspect.status === 'Inactive' ) {
                RentalStatusType = foundProspect.status
              }
              else {
                RentalStatusType = foundProspect.stage
              }
            }
          }
          else if (negotiationStatus?.label === "Ei ajankohtainen") {
            RentalStatusType = 'NotRelevant'
          }
          else {
            RentalStatusType = 'ProspectPending'
          }

          const newRentalStatusTotal = {
            contractNumber: rs.contractNumber,
            validity: rs.validity,
            area: rs.area,
            rent: rs.unitMarketRent,
            amount: 1,
          }
          if(dividedRentalStatusTotals[RentalStatusType]) {
            dividedRentalStatusTotals[RentalStatusType].push(newRentalStatusTotal)
          }
          else {
            dividedRentalStatusTotals[RentalStatusType] = [newRentalStatusTotal]
          }
        }
      }
      return dividedRentalStatusTotals
    },
    getRentalStatusTotalArea (rentalStatuses, validity) {
      if(rentalStatuses) {
        return rentalStatuses.filter(x => x.validity === validity).reduce((a,b) => { 
          return a + b[this.selectedItemSet.value] }, 0)
      }
      return 0
    },
    setDefaultItemSet (widgetID) {
      const widget = this.widgets.find(x => x.id === widgetID)
      if(widget) {
        if(!this.selectedItemSet) {
          this.selectedItemSet = widget.data.itemSets[0]
        }
      }
    },
    changeWidgetSet (event, id) {
      if(id === "leasing.ending_contracts_based_on_status"){
        this.selectedItemSet = event.itemSet
      }
      else if (id === "leasing.largest_ending_contracts"){
        this.largestEndingContractsSet = event.set
      }

      
    },   
  }
}
</script>
<style scoped>
.widget {
  padding-top: 0;
}
</style>
