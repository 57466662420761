<template>
  <div>
    <v-dialog
      v-model="addEstateDialog"
      persistent
      max-width="550"
    >
      <AriaLive />
      <BaseModal
        @cancel="cancel()"
      >
        <template #title>
          {{ $t('Add estate') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newEstate.estate_identifier"
                  :rules="[v => !!v || $t('Required')]"
                  :label="$t('Estate identifier')"
                  :hint="$t('Required')"
                  class="ma-0"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col 
                v-if="!factaDisabled"
                cols="auto"
              >
                <v-btn
                  rounded
                  depressed
                  allign-right
                  class="primary ma-0"
                  @click="getEstateData"
                >
                  {{ $t('Get from facta') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-text-field
              v-model="newEstate.name"
              :rules="[v => !!v || $t('Required')]"
              :label="$t('Name')"
              :hint="$t('Required')"
              persistent-hint
              required
              class="form-field"
            />
            <template v-if="facta_estate && !factaDisabled">
              <v-text-field
                v-model="factaEstate.street_address"
                :label="$t('Address')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="regDate"
                :label="$t('Registration date')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.registry_type"
                :label="$t('Registry type')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.usage"
                :label="$t('Usage')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.estate_area"
                :label="$t('Estate area')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.efficiency"
                :label="$t('Efficiency')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.right_to_build"
                :label="$t('BuildingRight')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.used_right_to_build"
                :label="$t('BuildingRightUsed')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.buildingRightLeft"
                :label="$t('BuildingRightLeft')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaEstate.pending_right_to_build"
                :label="$t('BuildingRightPending')"
                disabled
                class="form-field"
              />
            </template>
          </v-form>
        </template>
        <template #footer>
          <Alert
            :show="showIndicator"
            :result="operationResult"
            :message="$t(indicatorMessage)"
          />
          <v-btn
            text
            rounded
            class="primary"
            @click="save"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-spacer />
    <v-btn
      v-if="disabled === false"
      small
      outlined
      rounded
      block
      class="ma-0"
      @click="showAddEstateDialog()"
    >
      <span>{{ $t('Add estate') }}</span>
    </v-btn>
    <div class="block">
      <EndHierarchyItemDialog
        level="site"
        :id-from-parent="siteId"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import BaseModal from './general/BaseModal'
import Alert from '../components/Alert'
import EndHierarchyItemDialog from './EndHierarchyItemDialog'
import AriaLive from './AriaLive.vue'
export default {
  components: {
    Alert,
    AriaLive,
    BaseModal,
    EndHierarchyItemDialog
  },
  props: ['siteId'],
  data () {
    return {
      loading: false,
      facta_estate: false,
      saving: false,
      valid: true,
      addEstateDialog: false,
      disabled: true,
      factaDisabled: true,
      showModal: true,
      regDate: null,
      factaEstate: {
        street_address: null,
        registration_date: null,
        registry_type: null,
        estate_area: null,
        usage: null,
        efficiency: null,
        right_to_build: null,
        used_right_to_build: null,
        pending_right_to_build: null,
        buildingRightLeft: null,
      },
      newEstate: {
        managed_by_intg: false,
        estate_identifier: null,
        name: null,
        start_date: new Date().toISOString().substr(0, 10)
      },
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
    }
  },
  watch: {
    addEstateDialog (value) {
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.resetValidation()
      }
    });
      this.loading = true
      if (value === true) {
        this.$rambollfmapi.sites
          .estates(this.siteId)
          .list()
          .then(result => {
            result.unshift(this.newEstate)
          })
        this.loading = false
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
  },
  mounted () {
    this.$rambollfmapi.accounts.currentgroups().then(res => {
      res.forEach(group => {
        if (
          group.applicationPermissions.find(app => {
            return app.id === '04.02.011'
          })
        ) {
          this.disabled = false
        }
        if (
          group.applicationPermissions.find(app => {
            return app.id === '9011'
          })
        ) {
          this.factaDisabled = false
        }
      })
    })
  },
  methods: {
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    showAddEstateDialog () {
      this.addEstateDialog = true
    },
    cancel () {
      this.addEstateDialog = false
      this.facta_estate = false;
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.reset();
      }
    });
    },
    clearForm () {
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.resetValidation()
      }
    });
      this.facta_estate = false;
      this.regDate = null
      this.newEstate.estate_identifier = null
      this.newEstate.name = null
      this.newEstate.managed_by_intg = false
      this.newEstate.start_date = new Date().toISOString().substr(0, 10)
    },
    formatDate (date) {
      if (!date) return null
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    async getEstateData () {
      if ( this.newEstate.estate_identifier === null) {
        return
      }
      this.loading = true;
      const estate = await this.$rambollfmapi.estates.facta(this.newEstate.estate_identifier)
      this.createdDate = null
      this.facta_estate = false
      Object.keys(this.factaEstate).forEach(key => {
      delete this.factaEstate[key];
    })
      if (estate.estate_identifier) {
      this.newEstate.name = estate.name
      this.newEstate.managed_by_intg = true
      this.factaEstate = {
        street_address: estate.street_address,
        registration_date: estate.registration_date,
        registry_type: estate.registry_type,
        estate_area: estate.estate_area,
        usage: estate.usage,
        efficiency: estate.efficiency,
        right_to_build: estate.right_to_build,
        used_right_to_build: estate.used_right_to_build,
        pending_right_to_build: estate.pending_right_to_build,
        buildingRightLeft: estate.buildingRightLeft,
      }
      this.regDate = this.formatDate(estate.registration_date)
      this.facta_estate = true;
      }
      else {
        this.newEstate.estate_identifier = null
        this.newEstate.name = null
        this.showIndicator = true
        this.operationResult = 'error'
        this.indicatorMessage = estate
        this.newEstate.managed_by_intg = false
      }
      this.loading = false;
    },
    save () {
      if (this.saving) {
        return
      }

      if (this.$refs.form.validate()) {
        this.saving = true

        let obj = {}
        Object.keys(this.newEstate).forEach(key => {
          obj[key] = this.newEstate[key];
        })
        
        Object.keys(this.factaEstate).forEach(key => {
          obj[key] = this.factaEstate[key];
        })
        const toSave = JSON.parse(JSON.stringify(obj))
        
        if (toSave.start_date !== null) {
          toSave.start_date = new Date(toSave.start_date)
            .toISOString()
            .replace('Z', '')
        }

        this.$rambollfmapi.sites
          .estates(this.siteId)
          .post(toSave)
          .then(res => {
            this.addEstateDialog = false
            this.clearForm()
            this.saving = false
            this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
            this.$emit('saved', true)
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          })
      }
    }
  }
}
</script>
<style scoped>
.block >>> .v-btn {
  width: 100%;
}
</style>
